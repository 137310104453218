import apolloClient from '../apolloClient';

export const refetchQueryByName = (queryName, doubleRefetch = false) => {
    for (const [, query] of apolloClient.queryManager.queries) {
        const observableQuery = query.observableQuery;

        if (observableQuery && observableQuery.queryName === queryName) {
            const promise = observableQuery.refetch();

            doubleRefetch ? promise.finally(() => observableQuery.refetch()) : promise;
        }
    }

    return null;
};
