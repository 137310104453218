import React from "react";
import PropTypes from "prop-types";
import Link from "../../../app/components/Link";

export const RenderEventPresetsInfoLink = (props) => {
    const {eventPresets, propertyLicenceId} = props;

    return (
        <span>
            {1 === eventPresets.length
                ? 'This event is the reference event for the preset '
                : 'This event is the reference event for the following presets: '
            }
            {eventPresets.map(eventPreset => (
                <Link
                    key={eventPreset.id}
                    name="propertyLicences.eventPresets.edit"
                    params={{
                        id: propertyLicenceId,
                        eventPresetId: eventPreset.id
                    }}
                >
                    {`"${eventPreset.name}"`}
                </Link>
            )).reduce((prev, curr) => [prev, ', ', curr])}
            .
        </span>
    )
};
RenderEventPresetsInfoLink.propTypes = {
    eventPresets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    propertyLicenceId: PropTypes.number.isRequired,
};
RenderEventPresetsInfoLink.displayName = 'RenderEventPresetsInfoLink';
