import {validate as clientProductValidate} from './validatorClientProduct';
import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, clientProductValidate(values));

    Object.assign(errors, validators.required(
        values.third_party_client_types,
        'third_party_client_types',
        'Choose at least one type'
    ));

    if (new Date(values.client_product_start_date) > new Date(values.client_product_end_date)) {
        Object.assign(errors, {client_product_end_date: "End date must be later than the Start date"});
    }

    Object.assign(errors, validators.date(values.client_product_start_date, "client_product_start_date"));
    Object.assign(errors, validators.date(values.client_product_end_date, "client_product_end_date"));
    Object.assign(errors, validators.required(values.client_product_start_date, "client_product_start_date"));
    Object.assign(errors, validators.required(values.client_product_end_date, "client_product_end_date"));

    return errors;
};

export default validate;
