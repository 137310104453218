import {get as _get} from "lodash"

import RtmpOriginStreamNameModel from "./RtmpOriginStreamNameModel";

export default function RtmpOriginStreamNameModelEdit() {
    const rtmpOriginStreamNameModelEdit = RtmpOriginStreamNameModel();

    rtmpOriginStreamNameModelEdit.title = (data) => {
        const rtmpOriginStreamName = _get(data, "rtmpOriginStreamName.name", null);

        return rtmpOriginStreamName ? `RTMP origin stream name "${rtmpOriginStreamName}"` : '';
    };

    return rtmpOriginStreamNameModelEdit;
}
