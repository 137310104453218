import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {createForm, renderModalError} from '@utils/forms';
import {convertToInt,isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import LicenseeForm from '../forms/LicenseeForm';
import LicenseeModel from '../forms/LicenseeModel';

const LicenseeEdit = ({match: {params: {id = null}}}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Licensee', routes.licensees.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(LicenseeModel, LicenseeForm, {id: convertToInt(id)})),
        [id]
    );

    return <Form/>;
};

LicenseeEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

export default withRouter(LicenseeEdit);
