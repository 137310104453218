import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {PROPERTY_LICENCE_TECH_SETUP} from '@constants/resources';
import {STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION} from '@constants/variables';
import {
    GetPropertyLicenceTechSetupForForm,
    GetPropertyLicenceTechSetupFormOptions,
} from '@graphql/propertyLicenceTechSetup/query';
import {UpdatePropertyLicenceTechSetup} from '@graphql/propertyLicenceTechSetup/mutation';
import {CreatePropertyLicenceTechSetupSr} from '@graphql/propertyLicenceTechSetupSr/mutation';

import {renderHeader, renderTvStreamingCheckbox} from '../utils/technicalSetupModelHelper';
import validate from './validatorTechnicalSetup';

export default function TechnicalSetupModel() {
    return {
        entityDataMapKey: 'technicalSetups.0',
        entityLabel: 'technical setup',
        formName: 'technicalSetup',
        label: 'technical setup',
        resources: PROPERTY_LICENCE_TECH_SETUP,
        showChangelog: true,
        title: null,
        validate: validate,
        dataMap: {
            technicalSetup: {
                id: '',
                contribution_type: {
                    id: '',
                },
                encoding_datacenter: {
                    id: '',
                },
                encoding_redundancy_type: {
                    id: '',
                },
                origin_stream_type: {
                    id: '',
                },
                encoder_type: {
                    id: '',
                },
                client_cdn_configurations: [],
                property_licence_tech_setup_sr: {
                    id: '',
                    has_scoreboard_overlay_support: true,
                    start_encoding_with_scoreboard_overlay: true,
                    scoreboard_overlay_position: {
                        id: STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION,
                    },
                    has_slate_support: true,
                    slate: {
                        id: null,
                    },
                    has_overlay_support: false,
                    start_encoding_with_overlay: false,
                    overlay: {
                        id: null,
                    },
                    has_ad_support: false,
                    ad_type: {
                        id: null,
                    },
                    has_drm_support: false,
                    client_drm_setups: [],
                    akamai_rtmp_distribution_regions: [],
                    client_product_third_parties: [],
                    has_cc: false,
                    stream_language: null,
                },
                property_licence_tech_setup_manual: {
                    id: '',
                    playback_path: null,
                },
            },
        },
        messages: {
            boxName: {
                delete: 'propertyLicenceMessage',
                error: 'propertyLicenceMessage',
                success: 'propertyLicenceMessage',
            },
            text: {
                ...MESSAGES,
                SAVED: () => 'The general settings of the technical setup have been saved successfully.',
                FORM_ERROR: () => 'The general settings of the technical setup have not been saved. Please correct the highlighted fields below.',
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'technicalSetups.0.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            header_input: {
                props: {
                    component: renderHeader,
                    name: 'header_input',
                    defaultValue: {
                        name: 'Input',
                        description: 'These settings will be used by default when creating a new event content',
                    },
                },
            },
            contribution_type: {
                optionsKey: 'contributionTypes',
                dataMapKey: 'technicalSetups.0.contribution_type.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Contribution type',
                    name: 'contribution_type',
                    allowClear: true,
                },
            },
            encoding_datacenter: {
                optionsKey: 'encodingDatacenters',
                dataMapKey: 'technicalSetups.0.encoding_datacenter.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Encoding datacenter',
                    name: 'encoding_datacenter',
                    allowClear: true,
                },
            },
            encoding_redundancy_type: {
                optionsKey: 'encodingRedundancyTypes',
                dataMapKey: 'technicalSetups.0.encoding_redundancy_type.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Encoding redundancy type',
                    name: 'encoding_redundancy_type',
                    allowClear: true,
                },
            },
            origin_stream_type: {
                optionsKey: 'originStreamTypes',
                dataMapKey: 'technicalSetups.0.origin_stream_type.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Origin stream type',
                    name: 'origin_stream_type',
                    allowClear: true,
                },
            },
            is_tv_streaming: {
                dataMapKey: 'technicalSetups.0.is_tv_streaming',
                props: {
                    component: renderTvStreamingCheckbox,
                    label: 'TV Streaming',
                    name: 'is_tv_streaming',
                    type: 'checkbox',
                },
            },
            header_targets: {
                props: {
                    component: renderHeader,
                    name: 'header_targets',
                    defaultValue: {
                        name: 'Targets',
                        description: 'These settings will be used by default when creating a new target',
                    },
                },
            },
            encoder_type: {
                optionsKey: 'encoderTypes',
                dataMapKey: 'technicalSetups.0.encoder_type.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Encoders',
                    name: 'encoder_type',
                    required: true,
                },
            },
            scoreboard_overlay: [
                {
                    has_scoreboard_overlay_support: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.has_scoreboard_overlay_support',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Scoreboard overlay support?',
                            name: 'has_scoreboard_overlay_support',
                            type: 'toggle',
                            hidden: true,
                        },
                    },
                },
                {
                    start_encoding_with_scoreboard_overlay: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.start_encoding_with_scoreboard_overlay',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Start encoding with scoreboard overlay',
                            name: 'start_encoding_with_scoreboard_overlay',
                            type: 'checkbox',
                            hidden: true,
                        },
                    },
                },
                {
                    scoreboard_overlay_position: {
                        optionsKey: 'scoreboardOverlayPositions',
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.scoreboard_overlay_position.id',
                        props: {
                            component: Form.SemanticSelect,
                            defaultValue: STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION,
                            label: 'Position',
                            name: 'scoreboard_overlay_position',
                            required: true,
                            hidden: true,
                            allowClear: false,
                        },
                    },
                },
            ],
            slate: [
                {
                    has_slate_support: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.has_slate_support',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Slate support?',
                            name: 'has_slate_support',
                            type: 'toggle',
                            hidden: true,
                        },
                    },
                },
                {
                    slate: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.slate.id',
                        props: {
                            component: Form.SemanticSelect,
                            label: 'Start encoding with the following slate',
                            name: 'slate',
                            placeholder: 'No slate',
                            hidden: true,
                            allowClear: true,
                        },
                    },
                },
            ],
            overlay: [
                {
                    has_overlay_support: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.has_overlay_support',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Overlay support?',
                            name: 'has_overlay_support',
                            type: 'toggle',
                            hidden: true,
                        },
                    },
                },
                {
                    start_encoding_with_overlay: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.start_encoding_with_overlay',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Start encoding with overlay',
                            name: 'start_encoding_with_overlay',
                            type: 'checkbox',
                            hidden: true,
                        },
                    },
                },
                {
                    overlay: {
                        optionsKey: 'overlay',
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.overlay.id',
                        props: {
                            component: Form.SemanticSelect,
                            label: 'Overlay',
                            name: 'overlay',
                            required: true,
                            allowClear: true,
                            hidden: true,
                        },
                    },
                },
            ],
            ad: [
                {
                    has_ad_support: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.has_ad_support',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Ad support?',
                            name: 'has_ad_support',
                            type: 'toggle',
                            hidden: true,
                        },
                    },
                },
                {
                    ad_type: {
                        optionsKey: 'adTypes',
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.ad_type.id',
                        props: {
                            component: Form.SemanticSelect,
                            label: 'Ad type',
                            name: 'ad_type',
                            hidden: true,
                            allowClear: true,
                            required: true,
                        },
                    },
                },
            ],
            drm: [
                {
                    has_drm_support: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.has_drm_support',
                        props: {
                            component: Form.SemanticInput,
                            label: 'DRM support?',
                            name: 'has_drm_support',
                            type: 'toggle',
                            hidden: true,
                        },
                    },
                },
                {
                    drm_clients: {
                        props: {
                            component: Form.SemanticSelect,
                            defaultValue: [],
                            label: 'Clients',
                            multiple: true,
                            name: 'drm_clients',
                            hidden: true,
                            allowClear: false,
                        },
                    },
                },
            ],
            captioning: [
                {
                    close_captioning: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.has_cc',
                        props: {
                            component: Form.SemanticInput,
                            type: 'toggle',
                            label: 'Close captioning?',
                            name: 'has_cc',
                            hidden: true,
                        },
                    },
                },
                {
                    language: {
                        dataMapKey: 'technicalSetups.0.property_licence_tech_setup_sr.cc_stream_language.id',
                        props: {
                            component: Form.SemanticSelect,
                            name: 'stream_language',
                            label: 'Language',
                            placeholder: 'No Language',
                            hidden: true,
                            allowClear: true,
                        },
                    },
                },
            ],
            akamai_rtmp_distr_regions: {
                optionsKey: 'akamaiRtmpDistrRegions',
                props: {
                    component: Form.SemanticCheckboxList,
                    defaultValue: [],
                    label: 'Akamai RTMP distribution regions',
                    name: 'akamai_rtmp_distr_regions',
                    inline: true,
                    hidden: true,
                },
            },
            playback_path: {
                dataMapKey: 'technicalSetups.0.property_licence_tech_setup_manual.playback_path',
                props: {
                    component: Form.SemanticInput,
                    label: 'Playback path',
                    name: 'playback_path',
                    hidden: true,
                },
            },
            client_product_third_parties: {
                props: {
                    component: Form.SemanticSelect,
                    defaultValue: [],
                    label: 'Third party clients (push/pull)',
                    multiple: true,
                    name: 'client_product_third_parties',
                    hidden: true,
                    allowClear: false,
                },
            },
            client_cdn_configurations: {
                props: {
                    component: Form.SemanticSelect,
                    defaultValue: [],
                    label: 'Client CDN configurations',
                    multiple: true,
                    name: 'client_cdn_configurations',
                    allowClear: false,
                },
            },
        },
        mutation: {
            updateEntity: UpdatePropertyLicenceTechSetup,
            createEntity: CreatePropertyLicenceTechSetupSr,
        },
        query: {
            getEntity: GetPropertyLicenceTechSetupForForm,
            getOptions: GetPropertyLicenceTechSetupFormOptions,
        },
    };
}
