import React from 'react';
import PropTypes from 'prop-types';

import {CompetitorsAndTypeWrapper} from '@utils/CompetitorsAndTypeWrapper';

import {CompetitorsName} from '../../../events/views/common/CompetitorsName';
import {EventContentType} from '../../../events/views/common/EventContentType';

const FailoverContentsMessageBoxList = ({failoverContents}) => {
    return (
        <ul>
            {
                failoverContents.map((failoverContent) => (
                    <li key={failoverContent.id}>
                        <CompetitorsAndTypeWrapper>
                            <CompetitorsName event={failoverContent} renderLink={false}/>
                            <EventContentType event={failoverContent}/>
                        </CompetitorsAndTypeWrapper>
                    </li>
                ))
            }
        </ul>
    );
};

FailoverContentsMessageBoxList.propTypes = {
    failoverContents: PropTypes.array,
};

export default FailoverContentsMessageBoxList;
