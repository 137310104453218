import React from 'react';
import PropTypes from "prop-types";
import {Segment} from "semantic-ui-react";
import {withRouter} from "react-router";

import MatchSelectionPeriodEdit from "./MatchSelectionPeriodEdit";
import MatchSelectionPeriodIndex from "./MatchSelectionPeriodIndex";

import {routes} from "../../app/constants/routes";

const MatchSelectionPeriods = ({match: {params: {id}, path}}) => {
    const matchSelectionPeriodsRoutes = routes.propertyLicences.matchSelectionPeriods;
    let viewToRender = <MatchSelectionPeriodIndex propertyLicenceId={id}/>;

    if (matchSelectionPeriodsRoutes.add.path === path || matchSelectionPeriodsRoutes.edit.path === path) {
        viewToRender = <MatchSelectionPeriodEdit/>
    }

    return (
        <Segment basic className="--table">
            {viewToRender}
        </Segment>
    );
};

MatchSelectionPeriods.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired
    })
};

export default withRouter(MatchSelectionPeriods);
