import EventEventBookingBlackoutZonesEditModel from '@modules/eventBooking/forms/EventBookingBlackoutZonesEditModel';

export default function ClientEventBookingBlackoutZonesModel() {
    let clientEventBookingBlackoutZonesModel = EventEventBookingBlackoutZonesEditModel();

    clientEventBookingBlackoutZonesModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    clientEventBookingBlackoutZonesModel.editForm = true;
    clientEventBookingBlackoutZonesModel.mutationOptions = {
        delete: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
        update: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
    };

    return clientEventBookingBlackoutZonesModel;
}
