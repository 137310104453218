import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint import/no-unresolved: 0 */
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Header, Icon, Popup, Segment} from 'semantic-ui-react';

import {gql, graphql, withApollo} from 'react-apollo';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link, {getLink} from '@appComponents/Link';
import SegmentHeader from '@appComponents/SegmentHeader';

import ApiKeyTable from '../components/ApiKeyTable';
import ClientAvApiKeyForm from '../forms/ClientAvApiKeyForm';
import ClientAvApiKeyModel from '../forms/ClientAvApiKeyModel';
import ClientAvApiKeyModelEdit from '../forms/ClientAvApiKeyModelEdit';
import {
    ClientApiKeyDeleteMutation,
    ClientApiKeyRevokeMutation,
} from '../../../graphql/clientAvApiKey/mutation.graphql';
import {ClientApiKeysQuery} from '../../../graphql/clientAvApiKey/query.graphql';
import {clientApiKeyUpdateMutation} from '../../../graphql/clients/index';

export class ApiKeyIndex extends React.Component {
    static propTypes = {
        apiKey: PropTypes.object,
        client: PropTypes.object.isRequired,
        ClientApiKeysQuery: PropTypes.object.isRequired,
        clientData: PropTypes.object.isRequired,
        Entity: PropTypes.object.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.object.isRequired,
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
        modal: PropTypes.object.isRequired,
    };

    static defaultProps = {
        apiKey: null,
    };

    state = {
        clientApiKeys: [],
        clientData: [],
        loading: false,
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
    };

    constructor(props) {
        super(props);

        this.loadModal(props.match.path)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.clients.apiKey.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        const {
            ClientApiKeysQuery: {clientApiKeys},
            clientData,
        } = nextProps;

        let nextState = {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        };

        if (clientApiKeys && clientApiKeys !== prevState.clientApiKeys) {
            nextState.clientApiKeys = clientApiKeys;
        }

        if (clientData && clientData !== prevState.clientData) {
            nextState.clientData = clientData;
        }

        return nextState;
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        }
    }

    componentDidUpdate() {
        if (this.state.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (this.state.modalWasClosed) {
            this.props.history.push(getLink("clients.apiKey.index", {id: this.props.match.params.id}));
        }
    }

    openLCOModal = (e) => {
        e.preventDefault();

        let header = <Header content="Generate new API key" />,
            onYes = this.saveApiKey,
            text = (
                <span>
                    Are you sure you want to generate a new API key for this client?
                    {this.state.clientData.api_key ? <p>The current API key will not be usable any more.</p> : null}
                </span>
            );

        this.openModal(header, onYes, text);
    };

    editClick = (row) => {
        let header = <Header content="Revoke API key" />,
            onYes = this.revokeApiKey,
            text = (
                <span>
                    Are you sure you want to revoke this API key?<br />This will make the API key not usable any more.
                </span>
            );

        if (false === row.status) {
            header = <Header content="Remove API key" />;
            onYes = this.removeApiKey;
            text = <span>Are you sure you want to remove this API key?</span>;
        }

        this.props.Modal.setModalConfirmation({
            header: header,
            onYes: row ? () => onYes(row) : onYes,
            text: text
        });
    };

    openModal = (header, onYes, text, row = null) => {
        this.props.Modal.setModal({
            content: null,
            isVisible: true,
            header: header,
            onYes: row ? () => onYes(row) : onYes,
            text: text
        });
    };

    removeApiKey = (row) => {
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: "Removing the API key...",
        });

        this.props.client.mutate({
            mutation: ClientApiKeyDeleteMutation,
            variables: {
                id: convertToInt(row.id),
            },
        }).then(() => {
            setModal({
                isVisible: false,
                hideButtons: false,
            });

            addMessage(
                "clientMessage",
                "The API key has been removed successfully.",
                null,
                "success"
            );
            refetchQueryByName("ClientApiKeysQuery");
        }).catch((error) => {
            setModal({
                isVisible: false,
                hideButtons: false,
            });

            addMessage(
                "clientMessage",
                "The API key could not be removed.",
                `${error}`,
                "error"
            );
        });
    };

    revokeApiKey = (row) => {
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: "Revoking the API key..."
        });

        this.props.client.mutate({
            mutation: ClientApiKeyRevokeMutation,
            variables: {
                id: convertToInt(row.id),
            },
        }).then((response) => {
            setModal({
                isVisible: false,
                hideButtons: false,
            });

            if (response.data.revokeClientApiKey.status === null) {
                addMessage(
                    "clientMessage",
                    "The API key could not be revoked.",
                    null,
                    "error"
                );
            } else {
                addMessage(
                    "clientMessage",
                    "The API key has been revoked successfully.",
                    null,
                    "success"
                );
            }

            refetchQueryByName("ClientApiKeysQuery");
        }).catch((error) => {
            setModal({
                isVisible: false,
                hideButtons: false,
            });

            addMessage(
                "clientMessage",
                "The API key could not be revoked.",
                `${error}`,
                "error"
            );
        });
    };

    saveApiKey = () => {
        this.props.Modal.setModal({isVisible: false});

        this.setState(() => ({loading: true}));

        let apiKey = this.generateApiKey();

        this.props.client.mutate({
            mutation: gql(clientApiKeyUpdateMutation),
            variables: {
                api_key: apiKey,
                id: convertToInt(this.props.match.params.id),
            },
        }).then(() => {
            this.props.MessageBox.addMessage(
                "clientMessage",
                "The new API key has been generated successfully.",
                null,
                "success"
            );

            this.props.Entity.setEntity({
                name: 'client',
                data: Object.assign({}, {...this.state.clientData}, {
                    api_key: apiKey,
                }),
            });

            this.setState(() => ({loading: false}));
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                "clientMessage",
                "The API key could not be saved.",
                `${error}`,
                "error"
            );

            this.setState(() => ({loading: false}));
        });
    };

    generateApiKey = () => {
        const possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        let apiKey = "";

        for (let i = 0; i < 40; i++) {
            apiKey += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
        }

        return apiKey;
    };

    renderLcoApiKey = () => (!_isEmpty(this.state.clientData.api_key)
        ? (
            <span>
                {this.state.clientData.api_key}
                <Authorization
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    resources={RESOURCES.CLIENT_API_KEY}
                >
                    <Popup
                        content={"Generate a new one now"}
                        position="right center"
                        trigger={<Icon name="refresh" onClick={this.openLCOModal} size="large" />}
                    />
                </Authorization>
            </span>
        )
        : <span>
            {'No API key. '}
            <Authorization
                privileges={CONST.SECURITY_PRIVILEGES_CREATE}
                resources={RESOURCES.CLIENT_API_KEY}
            >
                <a onClick={this.openLCOModal}>Generate one now</a>
                .
            </Authorization>
        </span>
    );

    renderGenerateNewApiKey = () => (
        <Authorization
            privileges={CONST.SECURITY_PRIVILEGES_CREATE}
            resources={RESOURCES.CLIENT_API_KEY}
        >
            <Link name={'clients.apiKey.add'} params={{id: this.props.match.params.id}}>
                <ButtonAdd onClick={this.openAVClientModal}>{'Generate new API key'}</ButtonAdd>
            </Link>
        </Authorization>
    );

    loadModal(path, params = {}) {
        let clientData = (0 < this.state.clientData.length)
            ? this.state.clientData
            : this.props.clientData;

        if (path === routes.clients.apiKey.add.path) {
            if (null === clientData.bookmaker) {
                return this.props.Modal.setModal({
                    isVisible: true,
                    content: renderModalError(
                        null,
                        getLink("clients.apiKey.index", {id: this.props.match.params.id}),
                        <p>{
                            'This client has no Bookmaker ID. API key generation is not possible without Bookmaker ID.'
                        }</p>
                    ),
                });
            }

            return this.loadModalForm(createForm(
                ClientAvApiKeyModel,
                ClientAvApiKeyForm,
                {
                    clientId: this.props.match.params.id,
                    bookmakerId: clientData.bookmaker.id,
                }
            ), params);
        } else if (path === routes.clients.apiKey.edit.path) {
            if (!isUrlParamValid(this.props.match.params.clientApiKeyId)) {
                return showModal({
                    isVisible: true,
                    content: renderModalError('API key', getLink(`clients.apiKey.index`, {
                        id: this.props.match.params.id,
                    })),
                });
            }

            return this.loadModalForm(createForm(
                ClientAvApiKeyModelEdit,
                ClientAvApiKeyForm,
                {
                    clientId: this.props.match.params.id,
                    dataRequest: true,
                    id: this.props.match.params.clientApiKeyId || params.clientApiKeyId,
                    optionsVariables: {
                        id: convertToInt(this.props.match.params.id),
                    },
                }
            ), params);
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data} />,
        });
    };

    render() {
        if (this.state.clientData.length === 0 || this.props.ClientApiKeysQuery.loading) {
            return <Segment basic loading={true} />;
        }

        return (
            <div>
                <Segment basic className="apiKey --table" loading={this.state.loading}>
                    <SegmentHeader dividing={true}>
                        LCO Client API
                    </SegmentHeader>
                    <b>API key</b>
                    {this.renderLcoApiKey()}
                </Segment>
                <Segment basic className="clientApiKey --table" loading={this.state.loading}>
                    <SegmentHeader
                        buttons={this.renderGenerateNewApiKey()}
                        dividing={true}
                    >
                        AV Client API
                    </SegmentHeader>
                    <ApiKeyTable
                        clientApiKeys={this.state.clientApiKeys}
                        clientId={this.props.match.params.id}
                        editClick={this.editClick}
                    />
                </Segment>
            </div>
        );
    }
}

const ApiKeyIndexWithQuery = graphql(ClientApiKeysQuery, {
    options: (props) => ({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            client: convertToInt(props.match.params.id),
        }
    }),
    name: "ClientApiKeysQuery",
})(ApiKeyIndex);

const mapDispatchToProps = mapModulesToProps(['Entity', 'MessageBox', 'Modal']);

const mapStateToProps = (state) => {
    return {
        clientData: state.app.entities.client,
        modal: state.modal,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ApiKeyIndexWithQuery)));
