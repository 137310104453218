
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"StreamPlaybackData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"StreamPlayback"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"productShortName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"distributionTypeName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"streamDelayTypeName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"streamProtocolName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bitrate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isOriginStream"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isManifest"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"avPlayerParams"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bufferTime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"useSuggestedPresentationDelay"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"trackLiveEdge"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"enableCmaf"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"swf"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":354}};
    doc.loc.source = {"body":"fragment StreamPlaybackData on StreamPlayback {\n    id\n    url\n    productShortName\n    distributionTypeName\n    streamDelayTypeName\n    streamProtocolName\n    bitrate\n    isOriginStream\n    isManifest\n    avPlayerParams {\n        type\n        bufferTime\n        useSuggestedPresentationDelay\n        trackLiveEdge\n        enableCmaf\n        swf\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
