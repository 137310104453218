import React from 'react';
import {isEmpty as _empty} from 'lodash';

import Link from '@appComponents/Link';

export const BlackoutZonesForCountrySubdivisionFragment = (row) => {
    const bZonesRendered = [];

    if (!_empty(row.blackout_zones)) {
        let elementKey = '';

        for(let bzIndex = 0, bzLen = row.blackout_zones.length; bzIndex < bzLen; bzIndex++) {
            elementKey = (row.blackout_zones[bzIndex].key)
                ? row.blackout_zones[bzIndex].key.toString()
                : 'BlackoutZonesForCountrySubdivisionFragmentLoop_' + bzIndex;

            bZonesRendered.push(
                <Link name='blackoutZones.edit.generalInformation' params={{id: row.blackout_zones[bzIndex].id}}>
                    {row.blackout_zones[bzIndex].name}
                </Link>
            );

            if ((bzIndex + 1) < bzLen) {
                bZonesRendered.push(
                    <React.Fragment key={elementKey + '_notfirst'}>, </React.Fragment>
                );
            }
        }
    }

    return bZonesRendered;
};
