import PropTypes from "prop-types";

import {getContentTierName, getProperty} from "../common";

export const thirdLevelCategory = (event) => {
    const eventData = {
        data: event,
        nonSr: ['content_category'],
    };

    return getProperty(eventData) + getContentTierName(event);
};

thirdLevelCategory.propTypes = {
    event: PropTypes.object.isRequired,
};
