import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Button, Dropdown, Icon} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {ButtonDropdown} from '@appComponents/ButtonCollection';
import Link, {getLink} from '@appComponents/Link';
import {CLIENT_PRODUCT} from '@constants/resources';
import {
    PRODUCT_LCI,
    PRODUCT_LCO,
    PRODUCT_LCPP,
    PRODUCT_LCR,
    PRODUCT_LCT,
    PRODUCT_QB,
    PRODUCT_SPOTT,
    PRODUCT_THIRD_PARTY,
    SECURITY_PRIVILEGES_CREATE,
} from '@constants/variables';

import TriggerAutobookerButton from '@modules/autobooker/components/TriggerAutobookerButton';

class ClientTabsButtons extends React.Component {
    static propTypes = {
        clientId: PropTypes.number.isRequired,
        history: PropTypes.object,
        loading: PropTypes.bool,
        clientProductsIds: PropTypes.array,
    };

    static defaultProps = {
        loading: false
    };

    getAvailableClientProducts = () => {
        const clientProducts = [
            {
                id: PRODUCT_LCR,
                name: "Live Channel Retail",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.lcr.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_LCO,
                name: "Live Channel Online",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.lco.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_LCPP,
                name: "Live Channel Promotion Premium",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.lcpp.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_LCT,
                name: "Live Channel Trading",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.lct.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_SPOTT,
                name: "SpOTT",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.spott.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_THIRD_PARTY,
                name: "Third party",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.thirdParty.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_LCI,
                name: "Live Channel Internal",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.lci.add', {id: this.props.clientId})
                    );
                },
            },
            {
                id: PRODUCT_QB,
                name: "QuickBets",
                onChange: () => {
                    this.props.history.push(
                        getLink('clients.products.qb.add', {id: this.props.clientId})
                    );
                },
            },
        ];

        return clientProducts.filter((clientProduct) =>
            this.props.clientProductsIds.indexOf(clientProduct.id) === -1
        );
    };

    renderProductsDropdownItems = () => {
        return this.getAvailableClientProducts().map((productItem) => (
            <Dropdown.Item
                key={productItem.id}
                onClick={productItem.onChange}
                text={productItem.name}
            />
        ));
    };

    renderDropDown = () => {
        if (!this.props.loading && this.getAvailableClientProducts().length > 0 && !isNaN(this.props.clientId)) {
            return (
                <Authorization privileges={SECURITY_PRIVILEGES_CREATE} resources={CLIENT_PRODUCT}>
                    <ButtonDropdown secondIcon="triangle" icon='add circle' text='Add new product'>
                        {this.renderProductsDropdownItems()}
                    </ButtonDropdown>
                </Authorization>
            );
        } else {
            return <div/>;
        }
    };

    render() {
        return (
            <div className="indexButtons">
                {this.renderDropDown()}
                <TriggerAutobookerButton
                    id={this.props.clientId}
                    entity="client"
                    messageBoxName="clientMessage"
                    popupProps={{position: "bottom right"}}
                />
                <Link name="clients.index">
                    <Button className="button-back">
                        <Icon className="arrow circle left"/> Back to list
                    </Button>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let clientProductsIds = [];

    if (state.app.entities.client) {
        const clientProducts = state.app.entities.client.clientProducts;

        if (clientProducts) {
            clientProductsIds = clientProducts.map((clientProduct) => parseInt(clientProduct.product.id, 10));
        }
    }

    return {
        clientProductsIds: clientProductsIds,
    };
};

export default withRouter(connect(mapStateToProps, null)(ClientTabsButtons));
