import React from 'react';
import PropTypes from 'prop-types';
import {Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {graphql} from 'react-apollo';
import {GetRolesForListingPage} from '@graphql/role/query';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import navigation from '@constants/navigation';
import mapModulesToProps from '@utils/mapModulesToProps';

import RoleIndexButtons from '../components/RoleIndexButtons';
import RoleTable from '../components/RoleTable';
import {exportExcel as roleExportExcel} from '../utils/export/roleExcel';

export function RoleIndex ({
    DataRoles: {
        loading,
        roles = [],
    },
    Menu,
}) {
    React.useEffect(() => {
        Menu.storeMenu(navigation.settings.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={RoleIndexButtons}
                exportExcelParams={roleExportExcel(roles)}
                loading={loading}
                pageTitle='Roles'
            />
            <Segment basic className='--table'>
                <RoleTable
                    roles={roles}
                    loading={loading}
                />
            </Segment>
        </div>
    );
}

RoleIndex.propTypes = {
    DataRoles: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        roles: PropTypes.array,
    }),
    Menu: PropTypes.object,
};

export default graphql(GetRolesForListingPage, {
    name: 'DataRoles',
    options: () => ({
        fetchPolicy: 'network-only',
    }),
})(connect(null, mapModulesToProps(['Menu']))(RoleIndex));
