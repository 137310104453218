import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";

export class PropertyLicencePricingForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        return Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data)
    };

    renderDeleteButton = () => null;

    renderCancelButton = () => null;
}

export default withRouter(PropertyLicencePricingForm)
