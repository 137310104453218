import PropTypes from 'prop-types';
import moment from 'moment';

import {generateBlackoutZonesRow, getBlackoutZonesFromGeoRestrictions} from '@utils/blackoutZones';
import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions,
} from '@utils/countryHelpers';
import {renderContentCategoryLevelColumn} from '@modules/client/utils/clientBookingTable';
import {formattedPriceOrEmptyValue, getYesOrNo} from '@utils/helpers';
import {EMPTY_VALUE_DASH} from '@constants/variables';

export const exportExcel = (dataExport, clientId) => (
    {
        dataExport: dataExport,
        filename: `client_${clientId}_offerings`,
        parseData: clientOffering => [
            clientOffering.offering_id.toString(),
            clientOffering.event_id.toString(),
            clientOffering.match_id ? clientOffering.match_id.toString() : '',
            clientOffering.event_content_id,
            clientOffering.distribution_id,
            (
                (null === clientOffering.start_datetime || undefined === clientOffering.start_datetime)
                    ? ''
                    : moment(clientOffering.start_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')
            )
            +
            (
                (null === clientOffering.end_datetime || undefined === clientOffering.end_datetime)
                    ? ''
                    : ` - ${moment(clientOffering.end_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')}`
            ),
            renderContentCategoryLevelColumn(clientOffering, 'sport', 1),
            renderContentCategoryLevelColumn(clientOffering, 'tournament_category', 2),
            renderContentCategoryLevelColumn(clientOffering, 'tournament', 3),
            clientOffering.content_tier_name,
            clientOffering.competitors,
            clientOffering.event_description || '',
            `${clientOffering.event_content_type_name}${clientOffering.event_content_name ? ` - ${clientOffering.event_content_name}` : ''}`,
            clientOffering.event_content_variant_name,
            clientOffering.product_short_name,
            clientOffering.distribution_type_name,
            getListOfDeviceCategoriesForGeoRestrictions(clientOffering.offering_geo_restrictions).join(', '),
            getListOfCodesForDeviceCategories(clientOffering.offering_geo_restrictions),
            getListOfCountryCodesForDeviceCategories(clientOffering.offering_geo_restrictions).length.toString(),
            generateBlackoutZonesRow(
                getBlackoutZonesFromGeoRestrictions(clientOffering.offering_geo_restrictions),
                true
            ),
            clientOffering.client_package_name,
            getYesOrNo(clientOffering.is_hq),
            formattedPriceOrEmptyValue(clientOffering.price, EMPTY_VALUE_DASH),
            clientOffering.booking_status,
            clientOffering.offering_creation_datetime,
        ],
        path: 'clients.offerings.index.path',
        titles: [
            'ID',
            'Event ID',
            'Match ID',
            'Content ID',
            'Distribution ID',
            'Date/Time',
            'Sport / 1st level category',
            'Category / 2nd level category',
            'Tournament / 3rd level category',
            'Tier',
            'Competitors',
            'Description',
            'Content',
            'Content variant',
            'Product',
            'Distribution type',
            'Device categories',
            'Countries',
            '#Countries',
            'Blackout Zones',
            'Package',
            'HQ',
            'Price',
            'Booking status',
            'Time',
        ],
    }
);

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired,
};
