import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router";

import {EXPORT_EXCEL_URL_FRAGMENT} from "../../../constants/variables";

const ExcelRedirect = ({pathname}) => {
    const newPathname = pathname.replace(EXPORT_EXCEL_URL_FRAGMENT, '');

    return (
        <Redirect to={{pathname: newPathname}} />
    )
};

ExcelRedirect.propTypes = {
    pathname: PropTypes.string.isRequired
};

export default ExcelRedirect;
