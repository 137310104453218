import ClipConfigurationTournamentLevelModel
    from '@modules/clipConfiguration/forms/ClipConfigurationTournamentLevelModel';

export default function ClipConfigurationTournamentLevelEditModel() {
    const clipConfigurationTournamentLevelEditModel = ClipConfigurationTournamentLevelModel();

    clipConfigurationTournamentLevelEditModel.entityLabel = 'clip configuration';

    return clipConfigurationTournamentLevelEditModel;
}
