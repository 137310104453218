import React from 'react';
import PropTypes from 'prop-types';
import {Image} from "semantic-ui-react";
import {get as _get} from "lodash";

import {getProperty} from "../../../../../utils/EventsTabsLayout/Details/EventDetails/fields/common";

import urls from "../../../../../../app/constants/urls";

const getImage = (sportId) => {
    const imageUrl = `${urls.sportsImages.small}${sportId}.png`;

    return <Image src={imageUrl} className={'sportImage'}/>
};

/**
 * Display value for Sport
 */
export const SportImage = ({event}) => {
    if (event.is_master && event.sr_event) {
        const sportId = _get(event, 'sr_event.master_match.sport.id', null);

        return getImage(sportId);
    }

    if (!event.sr_event) {
        return null;
    }

    const eventData = {
            data: event,
            srWithMatchID: ['match', 'tournament', 'tournament_category', 'sport'],
            srWithoutMatchID: ['tournament', 'tournament_category', 'sport']
        },
        sportId = getProperty(eventData, 'id');

    return getImage(sportId);
};

SportImage.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            master_match: PropTypes.shape({
                sport: PropTypes.shape({
                    id: PropTypes.string
                })
            })
        }),
        is_master: PropTypes.bool
    }).isRequired
};

SportImage.defaultProps = {
    event: {
        sr_event: {
            master_match: {
                sport: {
                    id: null
                }
            }
        },
        is_master: false,
    }
}
