import PropTypes from "prop-types";
import {has as _has} from "lodash";

/**
 * Display value for "Round" field
 */
export const round = (event) => {
    if (event.sr_event && event._hasMatchID && !event.is_master) {
        /**
         * If match.tournament_round_id (DB) IS NOT NULL, display tournament_round.name
         */
        if (_has(event, 'sr_event.match.tournament_round.id')) {
            return event.sr_event.match.tournament_round.name;
        /**
         * If match.league_round_number IS NOT NULL (DB), display match.league_round_number
         */
        } else if (_has(event, 'sr_event.match.league_round_number')) {
            return event.sr_event.match.league_round_number;
        }
    }

    return "-";
};

round.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                tournament_round: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string
                }),
                league_round_number: PropTypes.number
            })
        }),
        _hasMatchID: PropTypes.bool.isRequired,
        is_master: PropTypes.bool
    }).isRequired
};

round.defaultProps = {
    event: {
        sr_event: {
            match: {
                tournament_round: {
                    id: null,
                    name: null,
                },
                league_round_number: null,
            }
        },
        is_master: false
    }
}
