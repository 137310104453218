import React from 'react';
import PropTypes from 'prop-types';
import writeXlsxFile from 'write-excel-file';
import {Button} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {
    isEmpty as _isEmpty,
    isObject as _isObject,
    max as _max,
    zip as _zip,
} from 'lodash';

import {createExportExcelProps} from '@utils/export/excel';
import {showErrorModal} from '@utils/modal';

import ExcelRedirect from './Export/Excel/ExcelRedirect';


export class ExcelExportButton extends React.Component {
    static propTypes = {
        exportExcelParams: PropTypes.shape({
            dataExport: PropTypes.array,
            filename: PropTypes.string,
            parseData: PropTypes.func,
            path: PropTypes.string,
            titles: PropTypes.array,
        }),
        loading: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired,
        url: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        floated: PropTypes.string,
    };

    static defaultProps = {
        exportExcelParams: {
            dataExport: [],
            filename: null,
            parseData: (row) => (row),
            path: null,
            titles: [],
        },
        url: '',
        floated: null,
    };

    setColumnsWidth(data, columns) {
        if(data.length) {
            const arrayOfColumns = _zip.apply(null, data);
            const longestValues = [];

            arrayOfColumns.map(column => longestValues.push(_max(column.map(el => {
                if (null === el || undefined === el) {
                    return 0;
                } else if (Array.isArray(el)) {
                    return el.join().length;
                } else if (_isObject(el)) {
                    return el.value.toString().length;
                } else {
                    return el.toString().length;
                }
            }))));

            longestValues.forEach((value, index) => {
                const length = (value < columns[index].title.length) ? columns[index].title.length : value;

                columns[index] = {...columns[index], width: {wch: length}};
            });
        }
    }

    /**
     * Check if URL and settings (eg. data for export) allow
     * download export directly by URL https://AVCMP/export/excel
     */
    canBeExportByUrl = (url) => (!this.props.loading && url === this.props.match.path);

    render() {
        const noExportParameters = (
            _isEmpty(this.props.exportExcelParams)
            || _isEmpty(this.props.exportExcelParams.filename)
        );

        if (noExportParameters) {
            return null;
        }


        const exportParameters = createExportExcelProps(this.props.exportExcelParams),
            {dataSet, filename, url} = exportParameters,
            renderElements = [];

        {dataSet.map(el => this.setColumnsWidth(el.data, el.columns));}

        /**
         * Export by URL: AVCMP/somepath/export/excel
         */
        if (this.canBeExportByUrl(url)) {
            this.getExcel(dataSet, filename);

            /**
             * Redirect from URL/somepath/export/excel to URL/somepath
             */
            renderElements.push(
                <ExcelRedirect
                    key='ExcelExportRedirect'
                    pathname={this.props.location.pathname}
                />
            );
        }

        if (!_isEmpty(this.props.exportExcelParams.dataExport)) {
            /**
             * Button for export excel
             */
            renderElements.push(
                <Button key='ExcelExportDownloadOnDemand' icon='file excel outline' content='Export' floated={this.props.floated}
                    onClick={() => this.getExcel(dataSet, filename)}/>
            );
        }

        return renderElements;
    }

    getExcel(dataSets, filename) {
        const {data, columns} = this.prepareDataSets(dataSets);
        writeXlsxFile(data, {
            columns,
            stickyRowsCount: 1,
            fileName: `${filename}.xlsx`,
            sheet: filename.substring(0, 31),
            fontFamily: 'Calibri',
            fontSize: 12,
        }).catch(() => {
            showErrorModal({
                header: 'Excel export failed',
                text: `There was an error while exporting '${filename}' to excel.`,
                size: 'large',
            });
        });
    }
    prepareDataSets(dataSets) {
        const data = [];
        const columns = [];

        dataSets.map((dataSet) => {
            const headerRow = [];
            dataSet.columns.map((column) => {
                headerRow.push({
                    value: column.title,
                    type: String,
                    fontWeight: 'bold',
                });
                columns.push({width: column.width.wch + 1});
            });
            data.push(headerRow);

            dataSet.data.map((rowFields) => {
                const dataRow = [];

                rowFields.map((field) => {
                    let type = String;
                    let extraCellAttributes = {};
                    field = String(undefined === field || null === field ? '' : field);

                    if ('Yes' === field || 'No' === field) {
                        extraCellAttributes = {align: 'center'};
                    } else if ('' !== field && !isNaN(field)) {
                        type = Number;
                        field = Number(field);
                    } else if (field.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/)) {
                        type = Date;
                        field = new Date(field + ' UTC');
                        extraCellAttributes = {format: 'yyyy-mm-dd hh:mm:ss'};
                    } else if (field.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/)) {
                        type = Date;
                        field = new Date(field + ' UTC');
                        extraCellAttributes = {format: 'yyyy-mm-dd hh:mm'};
                    } else if (field.match(/^(\d{4})-(\d{2})-(\d{2})$/)) {
                        type = Date;
                        field = new Date(field + ' UTC');
                        extraCellAttributes = {format: 'yyyy-mm-dd'};
                    }

                    dataRow.push({...{value: field, type: type}, ...extraCellAttributes});
                });
                data.push(dataRow);
            });
        });

        return {data, columns};
    }
}

export default withRouter(connect(null, null)(ExcelExportButton));
