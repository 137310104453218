import Form from "../../app/components/ReduxFormControls";

import validate from "./validator";
import * as MESSAGES from "../../app/constants/messages";
import {RTMP_ORIGIN_ENTRY_POINT} from "../../app/constants/resources";

import {
    GetRtmpOriginEntryPointForForm,
    GetRtmpOriginEntryPointForFormDropdowns
} from "../../../graphql/rtmpOriginEntryPoint/query.graphql";
import {
    CreateRtmpOriginEntryPoint,
    DeleteRtmpOriginEntryPoint,
    UpdateRtmpOriginEntryPoint
} from "../../../graphql/rtmpOriginEntryPoint/mutation.graphql";

export default function RtmpOriginEntryPointModel() {
    return {
        entityDataMapKey: "rtmpOriginEntryPoint",
        entityLabel: "RTMP origin entry point",
        formName: "RTMP origin entry point",
        label: "RTMP origin entry point",
        resources: RTMP_ORIGIN_ENTRY_POINT,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            rtmpOriginEntryPoint: {
                id : "",
                name: "",
                description: "",
                host: "",
                public_host: "",
                location: "",
                rtmp_origin_entry_point_type: {id: ""},
                origin_hosting_provider: {id: ""},
                amazon_instance: "",
                amazon_region: "",
                is_active: true,
                start_time: "",
                stop_time: "",
            },
        },
        messages: {
            boxName: {
                delete: "rtmpOriginEntryPointMessage",
                error: "rtmpOriginEntryPoint",
                success: "rtmpOriginEntryPoint",
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('RTMP origin entry point')
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "rtmpOriginEntryPoint.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "rtmpOriginEntryPoint.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            description: {
                dataMapKey: "rtmpOriginEntryPoint.description",
                props: {
                    component: Form.SemanticInput,
                    label: "Description",
                    name: "description",
                    type: "input",
                },
            },
            host: {
                dataMapKey: "rtmpOriginEntryPoint.host",
                props: {
                    component: Form.SemanticInput,
                    label: "Host",
                    name: "host",
                    required: true,
                    type: "input",
                },
            },
            public_host: {
                dataMapKey: "rtmpOriginEntryPoint.public_host",
                props: {
                    component: Form.SemanticInput,
                    label: "Public Host",
                    name: "public_host",
                    type: "input",
                },
            },
            location: {
                dataMapKey: "rtmpOriginEntryPoint.location",
                props: {
                    component: Form.SemanticInput,
                    label: "Location",
                    name: "location",
                    required: true,
                    type: "input",
                },
            },
            rtmp_origin_entry_point_type: {
                dataMapKey: "rtmpOriginEntryPoint.rtmp_origin_entry_point_type.id",
                optionsKey: "rtmpOriginEntryPointTypes",
                props: {
                    component: Form.SemanticSelect,
                    label: "Type",
                    name: "rtmp_origin_entry_point_type",
                    required: true,
                    placeholder: "Select type",
                },
            },
            origin_hosting_provider: {
                dataMapKey: "rtmpOriginEntryPoint.origin_hosting_provider.id",
                optionsKey: "originHostingProviders",
                props: {
                    component: Form.SemanticSelect,
                    label: "Hosting provider",
                    name: "origin_hosting_provider",
                    required: true,
                    placeholder: "Select hosting provider",
                },
            },
            amazon_instance: {
                dataMapKey: "rtmpOriginEntryPoint.amazon_instance_id",
                props: {
                    component: Form.SemanticInput,
                    label: "Amazon instance ID",
                    name: "amazon_instance",
                    hidden: true,
                    type: "input",
                },
            },
            amazon_region: {
                dataMapKey: "rtmpOriginEntryPoint.amazon_region",
                props: {
                    component: Form.SemanticInput,
                    label: "Amazon region",
                    name: "amazon_region",
                    hidden: true,
                    type: "input",
                },
            },
            is_active: {
                dataMapKey: "rtmpOriginEntryPoint.is_active",
                props: {
                    component: Form.SemanticInput,
                    label: "Active",
                    name: "is_active",
                    type: "checkbox",
                },
            },
            start_time: {
                dataMapKey: "rtmpOriginEntryPoint.start_time",
                props: {

                    component: Form.SemanticInput,
                    label: "Start time",
                    name: "start_time",
                }
            },
            stop_time: {
                dataMapKey: "rtmpOriginEntryPoint.stop_time",
                props: {
                    component: Form.SemanticInput,
                    label: "Stop time",
                    name: "stop_time",
                }
            },
        },
        mutation: {
            createEntity: CreateRtmpOriginEntryPoint,
            deleteEntity: DeleteRtmpOriginEntryPoint,
            updateEntity: UpdateRtmpOriginEntryPoint,
        },
        query: {
            getEntity: GetRtmpOriginEntryPointForForm,
            getOptions: GetRtmpOriginEntryPointForFormDropdowns,
        },
    }
}
