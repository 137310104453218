import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';

import validatorTableFilters from './helpers/validatorTableFilters';

export const UserTableFiltersComponent = ({
    loading,
}) => {
    return (
        <div className='user_add__filters filter__controls__container'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
            />
        </div>
    );
};

UserTableFiltersComponent.propTypes = {
    loading: PropTypes.bool.isRequired,
};

const UsersAddTableFilters = FiltersWrapper(UserTableFiltersComponent, {
    name: 'UserAddSearch',
    validate: validatorTableFilters,
    excluded: ['search'],
    removeResetButton: true,
});

export default UsersAddTableFilters;
