import React from "react";

import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";

import {renderModalError} from "../../../utils/forms";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

export const lintToAllContent = (contentCount, id) => {
    return (
        <LinkWithAuthorization
            resources={RESOURCES.PROPERTY_LICENCE}
            privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
            link={{
                name: "propertyLicences.content.index",
                params: {id : id}
            }}
            newWindow={true}
        >
            Show all ({contentCount} more)
        </LinkWithAuthorization>
    );
};

export const renderPropertyLicenceModalError = () => (
    renderModalError('Property licence', '/property-licences')
);
renderPropertyLicenceModalError.displayName = 'renderPropertyLicenceModalError';

export const getPreparedBasicDataForEntity = (propertyLicence) => ({
    name: "propertyLicence",
    data: {
        id: propertyLicence.id,
        name: propertyLicence.name,
        content_category_id: propertyLicence.content_category ? propertyLicence.content_category.id : null,
        content_tier_id: propertyLicence.content_tier.id,
        property_licence_type_id: propertyLicence.property_licence_type.id,
        sport_id: propertyLicence.sport ? propertyLicence.sport.id : null,
        has_accreditation: propertyLicence.has_accreditation,
        accreditation_contact_person_id: propertyLicence.accreditation_contact_person
            ? propertyLicence.accreditation_contact_person.id
            : null,
        is_archived: propertyLicence.is_archived,
    },
});
