import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {RIGHT_SCOPE} from '@constants/resources';
import {
    CreateRightsScope,
    DeleteRightsScope,
    UpdateRightsScope
} from '../../../graphql/rightsScope/mutation.graphql';
import {GetRightsScopeForForm, GetRightsScopeFormOptions} from '../../../graphql/rightsScope/query.graphql';

import validate from './validatorRightsScope';

export default function RightsScopeModel() {
    return {
        entityDataMapKey: "rightsScope",
        entityLabel: "rights scope",
        formName: "rightsScope",
        label: "rights scope",
        resources: RIGHT_SCOPE,
        showChangelog: true,
        title: null,
        validate: validate,
        dataMap: {
            rightsScope: {
                id: "",
                includes_all_content: true,
                licenseeDropdown: {
                    id: ""
                },
                property_licence: {
                    id: ""
                },
                start_date: "",
                end_date: "",
                rights_scope_exclusivity: {
                    id: ""
                },
                rights_scope_exclusivity_notes: "",
                rights_scope_sub_licensing :{
                    id: ""
                },
                rights_scope_sub_licensing_notes: "",
                contents: [],
                usages: [],
                usage_types: [],
                content_variants: [],
            },
        },
        messages: {
            boxName: {
                delete: "propertyLicenceMessage",
                error: "propertyLicenceMessage",
                success: "propertyLicenceMessage",
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "rightsScope.id",
                props: {
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            licensee: {
                dataMapKey: "rightsScope.licensee.id",
                optionsKey: "licenseeDropdown",
                props: {
                    component: Form.SemanticSelect,
                    label: "Licensee",
                    name: "licensee",
                    required: true,
                },
            },
            includes_all_content: {
                dataMapKey: "rightsScope.includes_all_content",
                props: {
                    component: Form.SemanticInput,
                    defaultValue: true,
                    label: "Includes all content",
                    name: "includes_all_content",
                    type: "checkbox",
                },
            },
            contents: {
                props: {
                    component: Form.SemanticCheckboxList,
                    hidden: true,
                    label: "Content",
                    name: "contents",
                    required: true,
                }
            },
            usages: {
                props: {
                    component: Form.SemanticCheckboxTree,
                    label: "Usage",
                    name: "usages",
                    required: true,
                    returnParsedTreeValues: true,
                },
            },
            usage_types: {
                props: {
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Usage types",
                    name: "usage_types",
                    required: true,
                    returnParsedTreeValues: true,
                },
            },
            content_variants: {
                props: {
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Content variants",
                    name: "content_variants",
                    required: true,
                    returnParsedTreeValues: true,
                },
            },
            start_date: {
                dataMapKey: "rightsScope.start_date",
                props: {
                    component: Form.SemanticInput,
                    label: "Start date",
                    name: "start_date",
                    required: true,
                    type: "date",
                },
            },
            end_date: {
                dataMapKey: "rightsScope.end_date",
                props: {
                    component: Form.SemanticInput,
                    label: "End date",
                    name: "end_date",
                    required: true,
                    type: "date",
                },
            },
            rights_scope_exclusivity: {
                dataMapKey: "rightsScope.rights_scope_exclusivity.id",
                optionsKey: "rightsScopeExclusivities",
                props: {
                    allowClear: true,
                    component: Form.SemanticSelect,
                    label: "Exclusivity",
                    name: "rights_scope_exclusivity",
                },
            },
            rights_scope_exclusivity_notes: {
                dataMapKey: "rightsScope.rights_scope_exclusivity_notes",
                props: {
                    component: Form.SemanticTextArea,
                    hidden: true,
                    label: "Exclusivity notes",
                    name: "rights_scope_exclusivity_notes",
                    rows: 2,
                }
            },
            rights_scope_sub_licensing: {
                dataMapKey: "rightsScope.rights_scope_sub_licensing.id",
                optionsKey: "rightsScopeSubLicensings",
                props: {
                    allowClear: true,
                    component: Form.SemanticSelect,
                    label: "Sub-licensing",
                    name: "rights_scope_sub_licensing",
                },
            },
            rights_scope_sub_licensing_notes: {
                dataMapKey: "rightsScope.rights_scope_sub_licensing_notes",
                props: {
                    component: Form.SemanticTextArea,
                    hidden: true,
                    label: "Sub-licensing notes",
                    name: "rights_scope_sub_licensing_notes",
                    rows: 2,
                }
            },
        },
        mutation: {
            createEntity: CreateRightsScope,
            deleteEntity: DeleteRightsScope,
            updateEntity: UpdateRightsScope,
        },
        query: {
            getEntity: GetRightsScopeForForm,
            getOptions: GetRightsScopeFormOptions,
        },
    }
}
