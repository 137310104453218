import React from 'react';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {COUNTRY} from '@constants/resources';
import {GetCountryFullDetails} from '@graphql/country/query';
import {IconBan, IconCheckmark} from '@appComponents/IconCollection';

const renderLabeledText = (text, label) => {
    return (
        <div>
            <label>{label}</label>
            <div>{text}</div>
        </div>
    );
};

renderLabeledText.propTypes = {
    text: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default function CountryGeneralInformationModel() {
    const FORM_NAME = 'Country';

    return {
        entityDataMapKey: 'country',
        title: null,
        entityLabel: FORM_NAME,
        formName: FORM_NAME,
        label: FORM_NAME,
        resources: COUNTRY,
        dataMap: {
            country: {
                id: null,
                name: null,
                full_name: null,
                iso_alpha2_code: null,
                iso_alpha3_code: null,
                iso_numeric_code: null,
                continent: null,
                handle_geo_restrictions_on_subdivision_level: false,
            },
        },
        messages: {},
        fields: {
            id: {
                dataMapKey: 'country.name',
                props: {
                    component: (props) => {
                        return renderLabeledText(
                            _get(props, 'data.country.name', ''),
                            props.label
                        );
                    },
                    label: 'Name',
                    name: 'name',
                    disabled: true,
                },
            },
            full_name: {
                dataMapKey: 'country.full_name',
                props: {
                    component: (props) => {
                        return renderLabeledText(
                            _get(props, 'data.country.full_name', ''),
                            props.label
                        );
                    },
                    label: 'Full Name',
                    name: 'full_name',
                    disabled: true,
                },
            },
            iso_alpha2_code: {
                dataMapKey: 'country.iso_alpha2_code',
                props: {
                    component: (props) => {
                        return renderLabeledText(
                            _get(props, 'data.country.iso_alpha2_code', ''),
                            props.label
                        );
                    },
                    label: 'ISO alpha-2 code',
                    name: 'iso_alpha2_code',
                    disabled: true,
                },
            },
            iso_alpha3_code: {
                dataMapKey: 'country.iso_alpha3_code',
                props: {
                    component: (props) => {
                        return renderLabeledText(
                            _get(props, 'data.country.iso_alpha3_code', ''),
                            props.label
                        );
                    },
                    label: 'ISO alpha-3 code',
                    name: 'iso_alpha3_code',
                    disabled: true,
                },
            },
            iso_numeric_code: {
                dataMapKey: 'country.iso_numeric_code',
                props: {
                    component: (props) => {
                        return renderLabeledText(
                            _get(props, 'data.country.iso_numeric_code', ''),
                            props.label
                        );
                    },
                    label: 'ISO numeric code',
                    name: 'iso_numeric_code',
                    disabled: true,
                },
            },
            continent: {
                dataMapKey: 'country.continent',
                props: {
                    component: (props) => {
                        let continentCode = '';

                        if (props.data && !props.data.loading) {
                            continentCode = _get(props, 'data.country.continent.code', '');

                            if (continentCode) {
                                continentCode = ' (' + continentCode + ')';
                            }
                        }

                        return renderLabeledText(
                            _get(props, 'data.country.continent.name', '') + continentCode,
                            props.label
                        );
                    },
                    label: 'Continent',
                    name: 'continent',
                    disabled: true,
                },
            },
            handle_geo_restrictions_on_subdivision_level: {
                optionsKey: 'country.handle_geo_restrictions_on_subdivision_level',
                props: {
                    className: 'inline',
                    component: (props) => {
                        const propertyName = 'data.country.handle_geo_restrictions_on_subdivision_level',
                            message = _get(props, propertyName, false)
                                ? <span><IconCheckmark/> Yes</span>
                                : <span><IconBan/> No</span>;

                        return renderLabeledText(message, props.label);
                    },
                    label: 'Subdivision support',
                    name: 'handle_geo_restrictions_on_subdivision_level',
                },
            },
        },
        mutation: {},
        query: {
            getEntity: GetCountryFullDetails,
        },
    };
}
