import PropTypes from 'prop-types';

import {convertToInt} from "../../../../../../utils/helpers";

/**
 * Generate Map object for state (required to checked previous selected checkboxes)
 * (key - product.id, value - array with rights_scope_usage_type.id)
 * after click on "usages type" checkbox tree
 * @param {Object[]} usageTypesSelected Usages type selected
 * @returns {Map<int, int[]>}
 */
export const getCurrentSelectedUsageTypes = (usageTypesSelected) => {
    let selectedMap = new Map();

    if (0 === usageTypesSelected.length) {
        return selectedMap;
    }

    usageTypesSelected.forEach((productUsageTypeSelected) => {
        /**
         * productUsageTypeSelected.value = product.id
         * @type {null|number}
         */
        const productId = convertToInt(productUsageTypeSelected.value);

        let usageTypesInProduct = [];

        productUsageTypeSelected.children.forEach((usageTypeSelected) => {
            /**
             * usageTypeId => usageTypeSelected.value - rights_scope_usage_type.id
             * @type {null|number}
             */
            const usageTypeId = convertToInt(usageTypeSelected.value);

            usageTypesInProduct.push(usageTypeId);
        });

        selectedMap.set(productId, usageTypesInProduct);
    });

    return selectedMap;
};

getCurrentSelectedUsageTypes.propTypes = {
    usageTypesSelected: PropTypes.object.isRequired
}
