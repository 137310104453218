import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {createForm, renderModalError} from '@utils/forms';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {GetVenue} from '@graphql/venue/query';

import VenueForm from '../forms/VenueForm';
import VenueModel from '../forms/VenueModel';
import {RenderBackToListButton} from '../utils/venueEdit';

const VenueEdit = ({
    match: {
        params: {
            id = null,
        },
    },
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Venue', routes.venues.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(VenueModel, VenueForm, {id: convertToInt(id)})),
        []
    );

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                messagesBoxNames='venueMessageError'
                pageTitle={
                    <TitleFromQuery
                        query={GetVenue}
                        variables={{id}}
                        errorBackLink={routes.venues.index.path}
                        path={'venue.name'}
                        entity={'venue'}
                    />
                }
            />
            <Form/>
        </div>
    );
};

VenueEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

export default withRouter(VenueEdit);
