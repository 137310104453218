import {convertToInt} from "../../../utils/helpers";
import validators from '../../../utils/validators';

import {ENCODER_TYPE_SPORTRADAR} from "../../app/constants/variables";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.encoder_type, 'encoder_type'));

    if (ENCODER_TYPE_SPORTRADAR === convertToInt(values.encoder_type)) {
        Object.assign(errors, validators.required(values.scoreboard_overlay_position, 'scoreboard_overlay_position'));

        if (values.has_overlay_support) {
            Object.assign(errors, validators.required(values.overlay, 'overlay'));
        }

        if (values.has_ad_support) {
            Object.assign(errors, validators.required(values.ad_type, 'ad_type'));
        }
    }

    return errors;
};

export default validate;
