import React from 'react';

import {convertToInt} from '@utils/helpers';
import {PreFillGeoRestrictions} from '@graphql/eventBooking/query';
import {EventBookingGeoRestrictionsForm as BaseEventBookingGeoRestrictionsForm}
    from '@modules/eventGeoRestriction/form/EventBookingGeoRestrictionsForm';
import {createForm} from '@utils/forms';
import {
    getAdditionalBackButton,
    getDeviceNameById,
    getEventBookingDataFromAllSteps,
} from '@modules/events/utils/eventsBookingGeoRestriction';

import EventBookingGeoRestrictionsBlackoutZonesModel
    from './EventBookingGeoRestrictionsBlackoutZonesModel';
import EventBookingGeoRestrictionsBlackoutZonesForm
    from './EventBookingGeoRestrictionsBlackoutZonesForm';
import {toArray as _toArray} from 'lodash';

export class EventBookingGeoRestrictionsForm extends BaseEventBookingGeoRestrictionsForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;

    prepareDataForSubmit = () => {
        return getEventBookingDataFromAllSteps(this.props.stepsMethods, convertToInt(this.props.formParams.id));
    };

    onFormSubmit = (data) => {
        if (!this.props.Model.editForm) {
            this.setState(() => ({
                defaultForm_errorShowed: false,
            }));

            const Form = createForm(
                    EventBookingGeoRestrictionsBlackoutZonesModel,
                    EventBookingGeoRestrictionsBlackoutZonesForm,
                    {
                        id: data.id,
                        optionsVariables: {
                            event: this.props.formParams.optionsVariables.event,
                            isArchived: false,
                        },
                    }),
                dataForNextStep = Object.assign(
                    {},
                    data,
                    {blackoutZonesPrefilled: this.state.blackoutZonesPrefilled}
                );

            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
            this.props.stepsMethods.setStep({
                id: 'step3',
                menuItem: {disabled: false},
                pane: {content: <Form previousStepData={dataForNextStep}/>},
            });
            this.props.stepsMethods.showStep('step3');
        } else {
            return super.onFormSubmit(data);
        }
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);
        const {
            formValues: {
                desktop_switch,
                mobile_switch,
                smart_tv_switch,
            },
        } = nextProps;

        if (this.props.Model.editForm) {
            this.props.stepsMethods.setStep({
                id: 'step3',
                pane: {
                    props: {
                        timestamp: Date.now(),
                        switches: {
                            desktop_switch,
                            mobile_switch,
                            smart_tv_switch,
                        },
                    },
                },
            });
        }
    }

    getPreFillGeoRestrictions = (distributionId, clientPackageId, data) => {
        this.setState(() => ({defaultForm_loading: true}));

        return this.props.client.query({
            query: PreFillGeoRestrictions,
            fetchPolicy: 'network-only',
            variables: {
                distribution: distributionId,
                clientPackage: clientPackageId,
            },
        }).then((response) => {
            const blackoutZonesPrefilled = [],
                preFillGeoRestrictions = response.data.preFillGeoRestrictions;

            preFillGeoRestrictions.forEach((geoRestriction) => {
                this.setCountryEntityGeoRestrictions(convertToInt(geoRestriction.device_category), {
                    continents: data.continents,
                    geoRestrictions: _toArray(geoRestriction.countries),
                });

                if (this.checkSubdivisionsAnyCountry(data.countries, _toArray(geoRestriction.countries))) {
                    this.setSubdivisionsEntityGeoRestrictions(convertToInt(geoRestriction.device_category), {
                        countries: data.countries,
                        countriesGeoRestrictions: _toArray(geoRestriction.countries),
                        subdivisionsGeoRestrictions: geoRestriction.subdivisions || [],
                    });
                }

                blackoutZonesPrefilled.push({
                    device_category: geoRestriction.device_category,
                    blackout_zones: geoRestriction.blackout_zones,
                });
            });

            this.setState(() => ({blackoutZonesPrefilled}));
        }).catch((response) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    "Can't get pre-fill geo restrictions for selected distribution and client package.",
                    response.message, 'error');
        }).finally(
            () => {
                this.setState(() => ({defaultForm_loading: false}));
            }
        );
    };

    handleSwitchChanged = (deviceId, value) => {
        super.handleSwitchChanged(deviceId, value);

        const deviceName = getDeviceNameById(deviceId);
        const switchFieldName = `${deviceName}_switch`;

        if (this.props.Model.editForm) {
            const step2FormData = this.props.stepsMethods.getForms()['step2'].formValues,
                combinedData = Object.assign({}, step2FormData, {[switchFieldName]: !value});

            this.props.stepsMethods.setStep({
                id: 'step3',
                pane: {
                    props: {
                        timestamp: Date.now(),
                        switches: {
                            desktop_switch: combinedData.desktop_switch,
                            mobile_switch: combinedData.mobile_switch,
                            smart_tv_switch: combinedData.smart_tv_switch,
                        },
                    },
                },
            });
        }
    };

    renderAdditionalButtons = () => (
        getAdditionalBackButton(this.props.Model.editForm, this.props.stepsMethods.showPreviousStep)
    );

    renderSaveButton = (props) => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                ...props,
                content: 'Next',
                icon: 'angle double right',
                labelPosition: 'right',
            });
        }

        return super.renderSaveButton();
    };
}

export default EventBookingGeoRestrictionsForm;
