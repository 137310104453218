import PropTypes from 'prop-types';

import loadModal from '@modules/events/utils/EventEventBooking/modalHelper';

const EventsEventBookingContentModal = (props, setMutationSuccessCallback) => {
    loadModal(props, {}, setMutationSuccessCallback);
};

EventsEventBookingContentModal.propTypes = {
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default EventsEventBookingContentModal;
