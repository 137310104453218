import {getLink} from '@appComponents/Link';

export default function (allowedPersonTypes, personTypeFromURL, personType, isAdd, collection, parentId) {
    let shouldRedirect = false;

    if (isAdd) {
        shouldRedirect = personTypeFromURL && !allowedPersonTypes.includes(personTypeFromURL);
    }

    if (personType) {
        shouldRedirect = personType !== personTypeFromURL || !allowedPersonTypes.includes(personType);
    }

    if (shouldRedirect) {
        window.location.href = getLink(`${collection}.contactPerson.index`, {id: parentId});
    }
}
