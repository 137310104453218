import {get as _get, forEach as _forEach, isEmpty as _isEmpty} from 'lodash';

import {getLink} from '@appComponents/Link';
import {renderArchivingButton} from '@utils/archiving';
import {convertToInt} from '@utils/helpers';
import {ToggleArchiveStateRtmpOriginStreamName} from '@graphql/rtmpOriginStreamName/mutation';
import DefaultForm from '@appComponents/DefaultForm';
import {showMessageBox} from '@utils/messageBox';

export class RtmpOriginStreamNameForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            const errors = _get(response, 'data.addMultipleRtmpOriginStreamNames.errors', null);

            if (errors) {
                const invalidNames = [];

                _forEach(errors, (error) => {
                    invalidNames.push(error.broken_entity.name);
                });

                if (!_isEmpty(invalidNames)) {
                    showMessageBox(
                        'productionCompany',
                        'Could not save the following RTMP origin stream names.',
                        `The following stream names are too long. It should have 200 characters or less: ${invalidNames.join(', ')}`,
                        'error',
                        true
                    );
                }
            } else {
                this.props.MessageBox
                    .addMessage(
                        'productionCompany',
                        'The rtmp origin stream names have been added successfully.',
                        null,
                        'success'
                    );
            }

            this.setMutationSuccess();
        });
        this.setDeleteSuccessCallback(() => {
            this.setMutationSuccess();
        });
        this.setUpdateSuccessCallback(() => {
            this.setMutationSuccess();
        });

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });
    }

    prepareDataForSubmit = (data) => {
        return Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {production_company_id: convertToInt(this.props.formParams.productionCompanyId)}
        );
    };

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            'RTMP origin stream name',
            getLink('productionCompanies.rtmpOriginStreamNames.index', {
                id: this.props.formParams.productionCompanyId || null,
            }),
            {size: 'tiny'}
        );
    }

    renderArchiveButton = () => (
        renderArchivingButton(
            {
                ...this.props,
                callback: this.setMutationSuccess,
                messageBoxName: 'productionCompany',
            },
            ToggleArchiveStateRtmpOriginStreamName,
            {
                id: convertToInt(this.props.formParams.id),
                name: _get(this.props, 'GraphQLEntityData.rtmpOriginStreamName.name', null),
                production_company_id: convertToInt(this.props.formParams.productionCompanyId),
            }
        )
    );

    setMutationSuccess = () => {
        this.props.formParams.setMutationSuccessSubmit(Date.now());
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );
}

export default RtmpOriginStreamNameForm;
