import {find as _find, has as _has, isUndefined as _isUndefined} from "lodash";
import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {graphql, withApollo} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {Header, Modal, Segment} from "semantic-ui-react";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import {getLink} from "../../app/components/Link";
import ThirdPartyPullOriginsIndexButtons from "../components/ThirdPartyPullOriginsIndexButtons";
import ThirdPartyPullOriginsTable from "../components/ThirdPartyPullOriginsTable";
import ThirdPartyPullOriginsForm from "../forms/ThirdPartyPullOriginsForm";

import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import {exportExcel as thirdPartyPullOriginsIndexExportExcel} from "../utils/export/thirdPartyPullOriginsIndexExcel";

import {routes} from "../../app/constants/routes";
import {PRODUCT_THIRD_PARTY} from "../../app/constants/variables";

import {GetThirdPartyClientPullOrigins, GetThirdPartyClientPullOrigin} from "../../../graphql/thirdPartyClientPullOrigin/query.graphql";

export class ThirdPartyPullOriginsIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientId: PropTypes.number.isRequired,
        DataThirdPartyPullOrigins: PropTypes.shape({
            loading: PropTypes.bool,
            thirdPartyClientPullOrigins: PropTypes.array,
        }),
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        prepareThirdPartyClientPullOriginsForStore: PropTypes.func.isRequired,
        thirdParty: PropTypes.number,
    };

    static defaultProps = {
        DataThirdPartyPullOrigins: {
            loading: false,
            thirdPartyClientPullOrigins: [],
        },
    };

    constructor() {
        super();

        this.state = {
            loadingModal: false,
            modalOpenedOnPageLoad: false,
            thirdPartyClientPullOrigins: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataThirdPartyPullOrigins: {thirdPartyClientPullOrigins},
        } = nextProps;

        const nextState = {};

        if (thirdPartyClientPullOrigins && thirdPartyClientPullOrigins !== this.state.thirdPartyClientPullOrigins) {
            nextState.thirdPartyClientPullOrigins = thirdPartyClientPullOrigins;
            nextProps.prepareThirdPartyClientPullOriginsForStore(thirdPartyClientPullOrigins);
        }

        this.setState(nextState, () => {
            if (this.state.modalOpenedOnPageLoad) {
                return false;
            }

            const pullOriginRoutes = routes.clients.products.thirdParty.pullOrigins;

            if (nextProps.match.path === pullOriginRoutes.add.path
                && !nextProps.DataThirdPartyPullOrigins.loading
            ) {
                this.prepareDataForModal({});
            } else if (nextProps.match.path === pullOriginRoutes.edit.path
                && !nextProps.DataThirdPartyPullOrigins.loading
            ) {
                const pullOrigin = thirdPartyClientPullOrigins.filter(
                    (item) => (convertToInt(nextProps.match.params.thirdPartyClientPullOriginId) === convertToInt(item.id))
                ).pop();

                if (!_isUndefined(pullOrigin)) {
                    this.prepareDataForModal(pullOrigin);
                } else {
                    nextProps.Modal.setModal({
                        content : (
                            <div>
                                <Header icon="warning" color="red" content="Pull origin was not found"/>
                                <Modal.Content>
                                    <p>Pull origin was not found</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Link to={getLink("clients.products.thirdParty.pullOrigins.index", {
                                        id: nextProps.match.params.id,
                                    })}>
                                        Back to pull origins
                                    </Link>
                                </Modal.Actions>
                            </div>
                        ),
                        isVisible : true,
                    });
                }
            }
        });
    }

    resetIndexUrl = () => {
        window.history.pushState({}, '', getLink('clients.products.thirdParty.pullOrigins.index', {
            id: this.props.clientId,
        }));
    };

    queryDataForModal = (id) => {
        const modalLink = getLink('clients.products.thirdParty.pullOrigins.edit', {
            id: this.props.clientId,
            thirdPartyClientPullOriginId: id,
        });

        this.setState(() => ({
            loadingModal: true,
        }));

        this.props.client.query({
            query: GetThirdPartyClientPullOrigin,
            variables: {
                id: id,
            },
        }).then((result) => {
            this.setState(() => ({
                loadingModal: false,
            }));

            this.openModal(result.data.thirdPartyClientPullOrigin, modalLink);
        });
    };

    prepareDataForModal = (id) => {
        this.setState(() => ({
            modalOpenedOnPageLoad: true,
        }));

        const modalLink = getLink('clients.products.thirdParty.pullOrigins.add', {
            id: this.props.clientId,
        });

        if (!isNaN(id)) {
            this.queryDataForModal(id);
        } else if (_has(id, 'id')) {
            this.queryDataForModal(id.id);
        } else {
            this.openModal({}, modalLink);
        }
    };

    openModal = (data, modalLink) => {
        const {
            Modal: {setModal},
        } = this.props;

        window.history.pushState({}, '', modalLink);

        setModal({
            content: <ThirdPartyPullOriginsForm
                clientProductThirdPartyId={this.props.thirdParty}
                formData={{
                    thirdPartyPullOrigin: data,
                }}
                resetIndexUrl={this.resetIndexUrl}
            />,
            loading: true,
            isVisible: true,
            onClose: this.resetIndexUrl,
        });
    };

    render() {
        return (
            <Segment basic loading={this.state.loadingModal} className="--marginTopNegative-15 --paddingTop-0">
                <HeaderRenderer
                    buttons={ThirdPartyPullOriginsIndexButtons}
                    buttonsProps={{prepareDataForModal: this.prepareDataForModal}}
                    exportExcelParams={thirdPartyPullOriginsIndexExportExcel(
                        this.state.thirdPartyClientPullOrigins,
                        this.props.match.params.id
                    )}
                    loading={this.props.DataThirdPartyPullOrigins.loading}
                />
                <ThirdPartyPullOriginsTable
                    editClick={this.prepareDataForModal}
                    loading={this.props.DataThirdPartyPullOrigins.loading}
                    thirdPartyClientPullOrigins={this.state.thirdPartyClientPullOrigins}
                />
            </Segment>
        );
    }
}

const ThirdPartyPullOriginsWithGraphQL = compose(
    graphql(GetThirdPartyClientPullOrigins, {
        skip: props => (null === props.thirdParty),
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    clientProductThirdParty: props.thirdParty,
                }
            }
        },
        name: "DataThirdPartyPullOrigins",
    })
)(ThirdPartyPullOriginsIndex);

const mapStateToProps = (state) => {
    let thirdPartyClientProduct = null;

    if (state.app.entities.client.clientProducts) {
        thirdPartyClientProduct = _find(state.app.entities.client.clientProducts, function (clientProduct) {
            return PRODUCT_THIRD_PARTY === convertToInt(clientProduct.product.id);
        });
    }

    return {
        client: state.app.entities.client,
        thirdParty: thirdPartyClientProduct
            ? convertToInt(thirdPartyClientProduct.third_party_client_product.id)
            : null,
    };
};
const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox']);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ThirdPartyPullOriginsWithGraphQL)));
