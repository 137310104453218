import {intersectionBy as _intersectionBy} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {getNonMasterEvents, GetRightScopesWithMediaRightsForEvents, GetRightScopesWithMediaRightsForMatches} from '@graphql/events/query';

import client from '../../../../apolloClient';
import {
    showErrorNoEventsForPresetSelected, showErrorNoEventsForSetCoverage, showErrorOnSetCoverage,
    showErrorWrongEventsForPresetSelected,
} from '../../utils/multipleEventActions';



export const getIdsFromChildEventsSelectionForMultiplePresetApply = (eventsDataStrings = []) => {
    return getIdsFromEventsSelectionForMultiplePresetApply(eventsDataStrings, true);
};

export const getIdsFromEventsSelectionForMultiplePresetApply = (
    eventsDataStrings = [],
    isChildEventsSelection = false,
    isSetCoverage = false
) => {
    if (0 === eventsDataStrings.length) {
        isSetCoverage ? showErrorNoEventsForSetCoverage() : showErrorNoEventsForPresetSelected();

        return null;
    }

    const eventsIds = [],
        isEventsListCorrect = !eventsDataStrings.some((eventString) => {
            let eventsSplitted = [];

            if (!isChildEventsSelection) {
                eventsSplitted = eventString.split('_');

                if ('event' !== eventsSplitted[0] && true !== eventsSplitted[2]) {
                    return true;
                }
            } else {
                eventsSplitted = eventString.split('-');

                if (!eventsSplitted[1]) {
                    return true;
                }
            }

            eventsIds.push(convertToInt(eventsSplitted[1]));

            return false;
        });

    if (!isEventsListCorrect) {
        isSetCoverage ? showErrorOnSetCoverage() : showErrorWrongEventsForPresetSelected();

        return null;
    }

    return eventsIds;
};

export const getNonMasterSrEventsWithSamePropertyLicence = (eventsIds, callback = null, isSetCoverage = false) => {
    client.query({
        query: getNonMasterEvents,
        fetchPolicy: 'network-only',
        variables: {
            id: eventsIds,
        },
    }).then(({data}) => {
        let eventsPropertyLicence = null;

        if (0 === data.events.length) {
            isSetCoverage ? showErrorNoEventsForSetCoverage() : showErrorNoEventsForPresetSelected();

            return;
        }

        for(const event of data.events) {
            if (!eventsPropertyLicence) {
                eventsPropertyLicence = event.property_licence.id;
            }

            if (eventsPropertyLicence !== event.property_licence.id) {
                isSetCoverage ? showErrorOnSetCoverage() : showErrorWrongEventsForPresetSelected();

                return;
            }
        }

        callback && callback(data);
    }).catch(() => {
        isSetCoverage ? showErrorOnSetCoverage() : showErrorWrongEventsForPresetSelected();
    });
};

export const getCommonProductsWithBookingSupport = (data) => {
    const productsWithBookingSupport = data.map(event => {
        const rightScopes = event.right_scopes_for_event || event.right_scopes_for_match;

        return rightScopes.flatMap(rightScope => {
            return rightScope.usages
                .filter(usage => usage.product.has_event_booking_support)
                .map(usage => usage.product);
        });
    });

    if (productsWithBookingSupport.some(products => 0 === products.length)) {
        return [];
    }

    const commonProducts = _intersectionBy(...productsWithBookingSupport, 'id');

    if (0 === commonProducts.length) {
        return [];
    }

    return commonProducts;
};

export const  getRightScopesWithMediaRights = async (client, eventsIds, callback) => {
    try {
        const { data } = await client.query({
            query: GetRightScopesWithMediaRightsForEvents,
            fetchPolicy: 'network-only',
            variables: {
                events: eventsIds,
            },
        });
        callback(data);
    } catch {
        showErrorOnSetCoverage();
    }
};

export const getRightScopesWithMediaRightsForMatches = async (matchIds, propertyLicenceId) => {
    try {
        const { data } = await client.query({
            query: GetRightScopesWithMediaRightsForMatches,
            variables: {
                matches: matchIds,
                property_licence: propertyLicenceId,
            },
        });

        return data;
    } catch (error) {
        showErrorOnSetCoverage();
    }
};
