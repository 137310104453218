import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.external_name, 'external_name'));
    Object.assign(errors, validators.required(values.event_types, 'event_types'));
    Object.assign(errors, validators.required(values.distribution_types, 'distribution_types'));
    Object.assign(errors, validators.required(values.products, 'products'));

    return errors;
};

export default validate;
