import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {get as _get} from "lodash";

import Table from "../../app/components/Table";

import {rowRenderer} from "../utils/RightsScopesTableRowRenderer";
import useQuery from "../../../utils/hooks/useQuery";

import {columns} from "../constants/RightsScopesTableColumns";

import {GetMappedIsoCodes} from "../../../graphql/country/query.graphql";

const RightsScopesTable = ({rightsScopes = [], loading, match: {params: {id}}}) => {
    const {
        loading: isDataLoading = true,
        data = {},
    } = useQuery({
        query: GetMappedIsoCodes,
    });

    return (
        <div className="property_licence_rights_scopes">
            <Table
                name="propertyLicenceRightScopesList"
                columns={columns}
                data={rightsScopes}
                loading={loading || isDataLoading}
                rowRenderer={rowRenderer}
                rowRendererProps={{mappedIsoCodes: _get(data, 'mappedIsoCodes', {}), propertyLicenceId: id}}
                noDataText="No rights scope found."
                pagination={false}
            />
        </div>
    );
};

RightsScopesTable.propTypes = {
    rightsScopes: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(RightsScopesTable);
