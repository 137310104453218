import React from "react";
import {get as _get} from "lodash";

export const renderEventBookingUserInformation = (params = {}) => {
    const editModeEventBookingId = _get(params, 'formValues.id', null);

    if (!editModeEventBookingId) {
        return null;
    }

    const ssoUserId = _get(params, 'defaultValue.id', '');

    if (!ssoUserId) {
        return null;
    }

    const username = _get(params, 'defaultValue.username', ''),
        name = _get(params, 'defaultValue.name', ''),
        bookmakerName = _get(params, 'defaultValue.bookmaker.name', ''),
        bookmakerId = _get(params, 'defaultValue.bookmaker.id', '');

    return (
        <div>
            <label>{params.label}</label>
            {getEventBookingUserInformationText(username, name, ssoUserId, bookmakerName, bookmakerId)}
        </div>
    )
};

export const getEventBookingUserInformationForExcelExport = (eventBookingInformation) => (
    (eventBookingInformation && eventBookingInformation.id)
        ? getEventBookingUserInformationText(
            _get(eventBookingInformation, 'username', ''),
            _get(eventBookingInformation, 'name', ''),
            eventBookingInformation.id,
            _get(eventBookingInformation, 'bookmaker.name', ''),
            _get(eventBookingInformation, 'bookmaker.id', ''),
        )
        : ''
);

const getEventBookingUserInformationText = (username, name, ssoUserId, bookmakerName, bookmakerId) => (
    `${username} - ${name} (${ssoUserId}) - ${bookmakerName} (${bookmakerId})`
);
