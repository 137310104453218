import React from "react";
import {Popup} from "semantic-ui-react";

import Authorization from '@appComponents/Authorization';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';
import {IconEdit, IconUsers, IconVideoCamera} from '@appComponents/IconCollection';

import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {isNil} from "lodash";

const productionCompanyAuthorizationObject = {
    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ
};

const contactPersonAuthorizationObject = {
    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ,
};

const rtmpOriginStreamNameAuthorizationObject = {
    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
    [RESOURCES.RTMP_ORIGIN_STREAM_NAME]: CONST.SECURITY_PRIVILEGES_READ,
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case "id":
        case "name":
            return renderLinkToEdit(row, column);
        case "country" :
            return (
                <div>
                    {isNil(row.country)
                        ? ""
                        : row.country.name.capitalizeFirstLetter()
                    }
                </div>
            );
        case "client" :
            return (
                <div>
                    {isNil(row.client)
                        ? ""
                        : row.client.name
                    }
                </div>
            );
        case "actions" :
            return (
                <div className="actions-list-icon">
                    <Authorization authorization={productionCompanyAuthorizationObject}>
                        <Popup
                            content="General information"
                            position='top center'
                            size="mini"
                            trigger={<IconEdit
                                link={getLink("productionCompanies.edit", {id: row.id})}
                                size="large"
                                resource={RESOURCES.PRODUCTION_COMPANY}
                            />}
                            inverted
                        />
                    </Authorization>
                    <Authorization authorization={contactPersonAuthorizationObject}>
                        <Popup
                            content="Contact persons"
                            position='top center'
                            size="mini"
                            trigger={<IconUsers
                                link={getLink("productionCompanies.contactPersons.index", {id: row.id})}
                                size="large"
                            />}
                            inverted
                        />
                    </Authorization>
                    <Authorization authorization={rtmpOriginStreamNameAuthorizationObject}>
                        <Popup
                            content="RTMP origin stream names"
                            position='top center'
                            size="mini"
                            trigger={<IconVideoCamera
                                link={getLink("productionCompanies.rtmpOriginStreamNames.index", {id: row.id})}
                                size="large"
                            />}
                            inverted
                        />
                    </Authorization>
                </div>
            );
        default:
            return null;
    }
};

const renderLinkToEdit = (row, column) => {
    return (
        <LinkWithAuthorization
            authorization={productionCompanyAuthorizationObject}
            link={{
                name: "productionCompanies.edit",
                params: {id: row.id}
            }}
        >
            {row[column]}
        </LinkWithAuthorization>
    );
};
