import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";

import {sortByTextValue} from '../../../utils/sorters';

const SdiOriginEntryPointsFilters = ({
    data: {
        encodingDatacenters = [],
    },
    loading,
    filters,
    restoreFilterValues,
}) => (
    <div className="sdiOriginEntryPoints__filters filter__controls__container">
        <Field
            component={Form.ReduxInput}
            name="search"
            placeholder="Search..."
            setValue={restoreFilterValues(filters.sdiOriginEntryPoints, 'search')}
            disabled={loading}
        />
        <Field
            component={Form.ReduxSelect}
            name="encodingDatacenters"
            options={sortByTextValue(encodingDatacenters)}
            disabled={loading}
            placeholder="Encoding datacenter"
            loading={loading}
            setValue={restoreFilterValues(filters.sdiOriginEntryPoints, 'encodingDatacenters')}
            search
            multiple
            selection
        />
    </div>
);

SdiOriginEntryPointsFilters.propTypes = {
    data: PropTypes.shape({
        encodingDatacenters: PropTypes.array,
    }),
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(SdiOriginEntryPointsFilters, {name: "sdiOriginEntryPoints"});
