import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import {IconCheckmark, IconEdit} from '@appComponents/IconCollection';
import Table from '@appComponents/Table';
import {EVENT_INCIDENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ, EVENT_CONTENT_VARIANT_STANDARD_FEED} from '@constants/variables';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {Authorization} from '@appComponents/Authorization';
import {getLink} from '@appComponents/Link';


import {convertToInt} from '@utils/helpers';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';


const authorizationObject = {
    [EVENT_INCIDENT]: SECURITY_PRIVILEGES_READ,
};

const EventIncidentsTable = (props) => {
    const columns = {
        'id': {
            label: 'ID',
            sorting: (row) => parseInt(row.id, 10),
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        'event_incident_location': {
            label: 'Location',
            sorting: (row) => _get(row, 'event_incident_reason.event_incident_causer.event_incident_location.name', null),
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'event_incident_causer': {
            label: 'Causer',
            sorting: (row) => _get(row, 'event_incident_reason.event_incident_causer.name', null),
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'event_incident_reason': {
            label: 'Reason',
            sorting: (row) => _get(row, 'event_incident_reason.name', null),
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'event_contents': {
            label: 'Contents',
            sorting: false,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'event_incident_scopes': {
            label: 'Scope',
            sorting: false,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'lasted_entire_duration': {
            label: 'Duration',
            sorting: false,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'count_as_downtime': {
            label: 'Downtime',
            sorting: true,
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
        'actions': {
            label: 'Actions',
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
    };

    const renderEventIncidentScopes = (column, row) => {
        let encodingTargets = [];

        row[column].map((scope) => {
            let encodingTarget = encodingTargets.find((encodingTarget) => (
                encodingTarget.id === parseInt(scope.encoding_target.id, 10)
            ));

            if (encodingTarget) {
                let streamProtocolFound = encodingTarget.streamProtocolNames.find((streamProtocolName) => (
                    streamProtocolName === scope.stream_protocol.name
                ));

                if (!streamProtocolFound) {
                    encodingTarget.streamProtocolNames.push(scope.stream_protocol.name);
                }
            } else {
                let distribution = scope.encoding_target.distribution,
                    eventContent = row.event_contents.find((eventContent) => (
                        parseInt(eventContent.id, 10) === parseInt(distribution.event_content.id, 10)
                    ));

                encodingTargets.push({
                    'id': parseInt(scope.encoding_target.id, 10),
                    'eventContentTypeName': eventContent.event_content_type.name,
                    'eventContentName': distribution.event_content.name,
                    'eventContentVariant': convertToInt(eventContent.event_content_variant.id) !== EVENT_CONTENT_VARIANT_STANDARD_FEED
                        ? eventContent.event_content_variant.short_name
                        : null,
                    'distributionTypeName': distribution.distribution_type.name,
                    'productShortName': distribution.product.short_name,
                    'streamDelayTypeName': scope.encoding_target.stream_delay_type
                        ? scope.encoding_target.stream_delay_type.name
                        : null,
                    'streamProtocolNames': [scope.stream_protocol.name],
                });
            }
        });

        return (
            <div>
                {encodingTargets.map((encodingTarget) => (
                    <div key={encodingTarget.id}>{
                        `${encodingTarget.eventContentTypeName
                        + (encodingTarget.eventContentName ? ` - ${encodingTarget.eventContentName}` : '')
                        + (encodingTarget.eventContentVariant ? ` (${encodingTarget.eventContentVariant})` : '')
                        } - ${encodingTarget.productShortName}`
                        + ` > ${encodingTarget.distributionTypeName}${
                            encodingTarget.streamDelayTypeName ? ` > ${encodingTarget.streamDelayTypeName}` : ''
                        } (${encodingTarget.id})`
                        + ` - ${encodingTarget.streamProtocolNames.sort().join(' + ')}`
                    }</div>
                ))}
            </div>
        );
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
                return (
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'events.incidents.edit',
                            params: {
                                incidentId: row.id,
                                id: props.eventId,
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'event_incident_location':
                return row.event_incident_reason.event_incident_causer.event_incident_location.name;
            case 'event_incident_causer':
                return row.event_incident_reason.event_incident_causer.name;
            case 'event_incident_reason':
                return row.event_incident_reason.name;
            case 'event_contents':
                return (
                    <div>
                        {row[column].map((eventContent) => (
                            <div key={eventContent.id}>
                                <EventContentVariantPopupWithName eventContent={eventContent}/>
                            </div>
                        ))}
                    </div>
                );
            case 'event_incident_scopes':
                return row.affects_all_output_streams
                    ? 'All output streams affected'
                    : renderEventIncidentScopes(column, row);
            case 'lasted_entire_duration':
                return row[column]
                    ? 'Entire duration'
                    : `${moment(row.end_datetime).diff(moment(row.start_datetime), 'minutes')} minutes`;
            case 'count_as_downtime':
                return row[column] ? <IconCheckmark/> : '';
            case 'actions':
                return (
                    <Authorization
                        authorization={authorizationObject}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit size='large' link={
                                getLink('events.incidents.edit',
                                    {incidentId: row.id, id: props.eventId})
                            }
                            resource={EVENT_INCIDENT}
                            />
                        </div>
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className='event_incidents__table'>
            <Table
                name='eventIncidentsList'
                columns={columns}
                data={props.eventIncidents}
                loading={props.loading}
                noDataText='No incidents found'
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

EventIncidentsTable.propTypes = {
    eventId: PropTypes.number.isRequired,
    eventIncidents: PropTypes.array,
    loading: PropTypes.bool,
};

EventIncidentsTable.defaultProps = {
    data: {
        eventIncidents: [],
        loading: false,
    },
};

export default withRouter(EventIncidentsTable);
