import {AD_SPLICE_CONFIG} from '@constants/resources';
import {UpdateAdSpliceConfig, CreateAdSpliceConfig, DeleteAdSpliceConfig} from '@graphql/adSplice/mutation';
import {GetOptionsForForm, GetAdSplicesForForm} from '@graphql/adSplice/query';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';

import {renderAdSpliceFormErrorMessages} from './modelHelpers';
import validate from './validator';

export default function AdSpliceModel() {
    return {
        entityDataMapKey: 'adSpliceConfig',
        entityLabel: 'Ad splice config',
        formName: 'adSpliceConfig',
        resources: AD_SPLICE_CONFIG,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            adSplice: {
                id: '',
                name: '',
                adSpliceId: {id: ''},
                duration: '',
                adStartOffset: '',
                adDurationOffset: '',
            },
        },
        messages: {
            boxName: {
                delete: 'adSpliceMessage',
                error: 'adSpliceMessageError',
                success: 'adSpliceMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: renderAdSpliceFormErrorMessages,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'adSpliceConfig.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'adSpliceConfig.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                    className: 'adSpliceConfigForm',
                },
            },
            adSpliceId: {
                dataMapKey: 'adSpliceConfig.ad_splice.id',
                optionsKey: 'adSplices',
                props: {
                    allowClear: false,
                    component: Form.SemanticSelect,
                    label: 'Ad splice ID',
                    name: 'ad_splice',
                    placeholder: 'Ad splice ID',
                    required: true,
                },
            },
            duration: {
                dataMapKey: 'adSpliceConfig.duration',
                props: {
                    component: Form.SemanticInput,
                    label: 'Duration',
                    labelDescription: {content: 'seconds'},
                    name: 'duration',
                    type: 'number',
                },
            },
            adStartOffset: {
                dataMapKey: 'adSpliceConfig.ad_start_offset',
                props: {
                    component: Form.SemanticInput,
                    label: 'Ad start offset',
                    labelDescription: {content: 'milliseconds'},
                    name: 'ad_start_offset',
                    type: 'number',
                },
            },
            adDurationOffset: {
                dataMapKey: 'adSpliceConfig.ad_duration_offset',
                props: {
                    component: Form.SemanticInput,
                    label: 'Ad duration offset',
                    labelDescription: {content: 'milliseconds'},
                    name: 'ad_duration_offset',
                    type: 'number',
                },
            },
        },
        mutation: {
            createEntity: CreateAdSpliceConfig,
            deleteEntity: DeleteAdSpliceConfig,
            updateEntity: UpdateAdSpliceConfig,
        },
        query: {
            getEntity: GetAdSplicesForForm,
            getOptions: GetOptionsForForm,
        },
    };
}
