import React from 'react';
import PropTypes from "prop-types";
import {DropdownItem} from "semantic-ui-react";

const ActionDropdownItem = (props) => {
    const handleClick = () => {
        if (props.onClick) {
            if (undefined !== props.actionParams) {
                return props.onClick(props.actionParams);
            }

            return props.onClick();
        }
    };


    return <DropdownItem {...props.item} onClick={handleClick}/>;
};

ActionDropdownItem.propTypes = {
    onClick: PropTypes.func,
    actionParams: PropTypes.object,
    item: PropTypes.object,
};

export default ActionDropdownItem;
