import {changelogData} from "../changelogData";

export const productFragment = `
    id
    name
    short_name
    external_name
    cdn {
      id
      name
    }
    akamai_cdn_ingest_method {
      id
      name
    }
    has_akamai_rtmp_distr_region_support
    has_scoreboard_overlay_support
    has_slate_support
    ${changelogData}
`;

export const productsListQuery = `query products {
  products {
    ${productFragment}
  }
}`;

export const productUpdateMutation = `mutation updateProduct(
        $id: ID!,
        $cdn: Int!,
        $akamai_cdn_ingest_method: Int!,
        $has_akamai_rtmp_distr_region_support: Boolean!
        $has_scoreboard_overlay_support: Boolean!
        $has_slate_support: Boolean!
        $update_existing_encoding_targets: Boolean
) {
    updateProduct(
        id: $id,
        cdn: $cdn,
        akamai_cdn_ingest_method: $akamai_cdn_ingest_method,
        has_akamai_rtmp_distr_region_support: $has_akamai_rtmp_distr_region_support,
        has_scoreboard_overlay_support: $has_scoreboard_overlay_support,
        has_slate_support: $has_slate_support,
        update_existing_encoding_targets: $update_existing_encoding_targets
    ) {
        ${productFragment}
    }
}`;

export const cdnsListForDropdown = `cdns {
    key: id
    value: id
    text: name
}`;

export const akamaiCdnIngestMethodsListForDropdown = `akamaiCdnIngestMethods{
    key: id
    value: id
    text: name
}`;

export const productQuery = `query productsForForm($id: ID!) {
    product (id: $id) {
        ${productFragment}
    }
    ${cdnsListForDropdown}
    ${akamaiCdnIngestMethodsListForDropdown}
}`;

export const productsForDropdown = `
        products {
            id
            name: short_name
        }
`;

export const FragmentProductBasicData = `
    fragment ProductBasicData on Product {
        id
        name
        short_name
    }
`;
