import {get as _get} from "lodash";
import PropTypes from "prop-types";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'competitors',
    parseData: competitor => [
        competitor.id,
        competitor.name,
        _get(competitor, 'country.name', ''),
    ],
    path: 'competitors.index.path',
    titles: [
        "ID",
        "Name",
        "Country",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
