import {GetClientProductLcppForGeoRestrictionsForm} from "../../../graphql/clientProductLcpp/query.graphql";
import {UpdateGeoRestrictionsClientProductLcpp} from "../../../graphql/clientProductLcpp/mutation.graphql";

import ProductLctGeoRestrictionsModel from "./ProductLctGeoRestrictionsModel";

export default function ProductLcppGeoRestrictionsModel() {
    let productLcppGeoRestrictionsModel = ProductLctGeoRestrictionsModel();

    productLcppGeoRestrictionsModel.entityDataMapKey = "clientProductLcpp";
    productLcppGeoRestrictionsModel.formName = "product LCP-P";
    productLcppGeoRestrictionsModel.fields.id.dataMapKey = "clientProductLcpp.id";
    productLcppGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductLcpp,
    };
    productLcppGeoRestrictionsModel.query.getEntity = GetClientProductLcppForGeoRestrictionsForm;

    return productLcppGeoRestrictionsModel;
}
