import React from "react";
import PropTypes from "prop-types";

const LabelColored = (props) => (
    <div className="ui label mini" style={{backgroundColor: `#${props.color}`}}>{props.content}</div>
);

LabelColored.propTypes = {
    color: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default LabelColored;
