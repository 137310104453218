import React from 'react';
import PropTypes from 'prop-types';
import {Header} from "semantic-ui-react";

import {ModalHeaderEventContentInformation} from "../../events/views/common/ModalHeaderEventContentInformation";

const ModalHeaderEventContentLiveControl = ({event, title}) => {
    return (
        <React.Fragment>
            <Header>{title}</Header>
            <ModalHeaderEventContentInformation event={event} />
        </React.Fragment>
    )
};

ModalHeaderEventContentLiveControl.propTypes = {
    event: PropTypes.object.isRequired,
    title: PropTypes.string
};

ModalHeaderEventContentLiveControl.defaultProps = {
    title: ''
};

export default ModalHeaderEventContentLiveControl;
