import { Icon, Popup } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

import { ORIGIN_STREAM_TYPE_UDP } from '@constants/variables';

import { isWarningVisible } from '../../utils/helpers';

export const UdpOriginEntryPointFailoverWarning = ({row = {}}) => {
    if (ORIGIN_STREAM_TYPE_UDP === row.origin_stream_type_id
        && row.udp_entry_point_name
        && !row.failover_udp_entry_point_id
        && !isWarningVisible(row)
    ) {
        return (
            <Popup
                key={`udp-icon-${row.id}`}
                trigger={
                    <Icon color='red' name='warning sign' className='--height-100-percent --marginLeft-0-5-em' />
                }
                flowing
                content='This UDP origin entry point has no failover defined!'
                hoverable
            />
        );
    }

    return null;
};

UdpOriginEntryPointFailoverWarning.propTypes = {
    row: PropTypes.object.isRequired,
};
