import {GetClientProductLcrForGeoRestrictionsForm} from "../../../graphql/clientProductLcr/query.graphql";
import {UpdateGeoRestrictionsClientProductLcr} from "../../../graphql/clientProductLcr/mutation.graphql";

import ProductLctGeoRestrictionsModel from "./ProductLctGeoRestrictionsModel";

export default function ProductLcrGeoRestrictionsModel() {
    let productLcrGeoRestrictionsModel = ProductLctGeoRestrictionsModel();

    productLcrGeoRestrictionsModel.entityDataMapKey = "clientProductLcr";
    productLcrGeoRestrictionsModel.formName = "product LCR";
    productLcrGeoRestrictionsModel.fields.id.dataMapKey = "clientProductLcr.id";
    productLcrGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductLcr,
    };
    productLcrGeoRestrictionsModel.query.getEntity = GetClientProductLcrForGeoRestrictionsForm;

    return productLcrGeoRestrictionsModel;
}
