import PropTypes from "prop-types";

import {getYesOrNo} from "../../../../utils/helpers";

export const exportExcel = (dataExport, productionCompanyId) => ({
    dataExport: dataExport,
    filename: `production_company_${productionCompanyId}_rtmp_origin_stream_names`,
    parseData: rtmpOriginStreamName => [
        rtmpOriginStreamName.id,
        rtmpOriginStreamName.name,
        getYesOrNo(rtmpOriginStreamName.is_archived),
    ],
    path: 'productionCompanies.rtmpOriginStreamNames.index.path',
    titles: [
        "ID",
        "Name",
        "Archived?",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
