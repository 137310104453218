import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';

import {graphql} from 'react-apollo';
import {GetEventContentTypesForTable} from '@graphql/eventContentType/query';
import {FiltersEventContentTypes} from '@graphql/filter/query';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';

import EventContentTypesFilters from '../components/EventContentTypesFilters';
import {exportExcel} from '../utils/export/eventContentTypesIndexExcel';
import EventContentTypesIndexButtons from '../components/EventContentTypesIndexButtons';
import EventContentTypesTable from '../components/EventContentTypesTable';

export class EventContentTypesIndex extends React.Component {
    static propTypes = {
        Menu: PropTypes.object,
        DataEventContentTypes: PropTypes.shape({
            eventContentTypes: PropTypes.array,
            loading: PropTypes.bool,
        }),
        DataForFilters: PropTypes.shape({
            distributionTypes: PropTypes.array,
            products: PropTypes.array,
            contentCategories: PropTypes.array,
            loading: PropTypes.bool,
            sports: PropTypes.array,
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        filters: PropTypes.object,
        areFiltersVisible: PropTypes.bool,
    };

    static defaultProps = {
        filters: {
            search: null,
            eventType: [],
            products: [],
            sport: [],
            firstLevelCategory: [],
            distributionType: [],
        },
    };

    constructor() {
        super();
        this.SPORTRADAR = 1;
        this.NON_SPORTRADAR = 2;

        this.state = {
            eventTypes: [
                {name: 'Sportradar', id: this.SPORTRADAR},
                {name: 'Non-Sportradar', id: this.NON_SPORTRADAR},
            ],
            products: [],
            sports: [],
            firstLevelCategories: [],
            distributionTypes: [],
        };
    }

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.eventAdministration.key);
    }

    componentDidUpdate(prevProps) {
        const {contentCategories, distributionTypes, products, sports} = this.props.DataForFilters;
        const {
            contentCategories: prevContentCategories,
            distributionTypes: prevDistributionTypes,
            products: prevProducts,
            sports: prevSports,
        } = prevProps.DataForFilters;

        if (contentCategories !== prevContentCategories ||
            distributionTypes !== prevDistributionTypes ||
            products !== prevProducts ||
            sports !== prevSports
        ) {
            this.setState({
                contentCategories: contentCategories || [],
                distributionTypes: distributionTypes || [],
                products: products || [],
                sports: sports || [],
            });
        }
    }

    render() {
        const loading = (
                this.props.DataEventContentTypes.loading
                || this.props.DataForFilters.loading
                || this.props.loadingRefetch
            ),
            filtersProps = {
                data: {
                    isVisible: this.props.areFiltersVisible,
                    eventTypes: this.state.eventTypes,
                    sports: this.state.sports,
                    firstLevelCategories: this.state.contentCategories,
                    distributionTypes: this.state.distributionTypes,
                    products: this.state.products,
                },
                loading: loading,
            };

        return (
            <div>
                <HeaderRenderer
                    buttons={EventContentTypesIndexButtons}
                    exportExcelParams={exportExcel(this.props.DataEventContentTypes.eventContentTypes)}
                    filters={EventContentTypesFilters}
                    filtersProps={filtersProps}
                    filtersButtonName='eventContentTypes'
                    loading={loading}
                    messagesBoxNames='eventContentTypeMessage'
                    pageTitle='Event content types'
                />
                <EventContentTypesTable
                    types={this.props.DataEventContentTypes.eventContentTypes}
                    loading={loading}
                />
            </div>
        );
    }
}

const EventContentTypesWithQuery = compose(
    graphql(GetEventContentTypesForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    search: props.filters.search || EventContentTypesIndex.defaultProps.filters.search,
                    products: props.filters.products || EventContentTypesIndex.defaultProps.filters.products,
                    eventTypes: props.filters.eventType || EventContentTypesIndex.defaultProps.filters.eventType,
                    firstLevelCategories: props.filters.firstLevelCategory || EventContentTypesIndex.defaultProps.filters.firstLevelCategory,
                    sports: props.filters.sport || EventContentTypesIndex.defaultProps.filters.sport,
                    distributionTypes: props.filters.distributionType || EventContentTypesIndex.defaultProps.filters.distributionType,
                },
            };
        },
        name: 'DataEventContentTypes',
    }),
    graphql(FiltersEventContentTypes, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    level: 1,
                },
            };
        },
        name: 'DataForFilters',
    })
)(FilterQueryWrapper(EventContentTypesIndex, {
    queryForRefresh: 'DataEventContentTypes',
    filterUrls: ['eventContentTypes.index'],
}));

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'eventContentTypes', EventContentTypesIndex.defaultProps.filters),
});

export default connect(
    mapStateToProps,
    mapModulesToProps(['MessageBox', 'Modal', 'Menu'])
)(
    FilterUrlParamsWrapper(EventContentTypesWithQuery, EventContentTypesIndex.defaultProps.filters)
);
