import * as React from "react";
import PropTypes from "prop-types";
import {Form as SemanticForm} from "semantic-ui-react";
import {Field} from "redux-form";

import {ReduxComponent} from "./ReduxComponent";

/**
 * Render file input for Redux
 */
class SemanticFileInput extends ReduxComponent {
    static propTypes = {
        defaultValue: PropTypes.string,
        input: PropTypes.object,
        meta: PropTypes.object,
        onClick: PropTypes.func,
    };

    constructor() {
        super();

        this.fileInput = this.fileInput.bind(this);
    }

    adaptFileEventToValue = delegate => e => {
        delegate(e.target.files[0]);
    };

    fileInput({
        input: {
            onChange,
            onBlur,
        },
        meta: omitMeta,
    }) {
        return (
            <div>
                <SemanticForm.Input
                    onChange={this.adaptFileEventToValue(onChange)}
                    onBlur={this.adaptFileEventToValue(onBlur)}
                    type="file"
                />
                <div className="error">{omitMeta.touched && !omitMeta.valid ? omitMeta.error : ''}</div>
            </div>
        );
    }

    /**
     * @ignore
     */
    render() {
        return (
            <Field
                component={this.fileInput}
                name={this.props.input.name}
            />
        );
    }
}

export default SemanticFileInput;
