import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {find as _find} from 'lodash';

import Tabs from '@appComponents/Tabs';
import mapModulesToProps from '@utils/mapModulesToProps';
import {routes} from '@constants/routes';
import {
    PRODUCT_LCI,
    PRODUCT_LCO,
    PRODUCT_LCPP,
    PRODUCT_LCR,
    PRODUCT_LCT,
    PRODUCT_SPOTT,
    PRODUCT_QB,
} from '@constants/variables';

import ProductEdit from './ProductEdit';
import ProductLciGeoRestrictionsEdit from './ProductLciGeoRestrictionsEdit';
import ProductLcrGeoRestrictionsEdit from './ProductLcrGeoRestrictionsEdit';
import ProductLcoGeoRestrictionsEdit from './ProductLcoGeoRestrictionsEdit';
import ProductLcppGeoRestrictionsEdit from './ProductLcppGeoRestrictionsEdit';
import ProductLctGeoRestrictionsEdit from './ProductLctGeoRestrictionsEdit';
import ProductSpottGeoRestrictionsEdit from './ProductSpottGeoRestrictionsEdit';
import ProductQbGeoRestrictionsEdit from './ProductQbGeoRestrictionsEdit';
import ClientProductLciForm from '../forms/ClientProductLciForm';
import ClientProductLciModel from '../forms/ClientProductLciModel';
import ClientProductLcoForm from '../forms/ClientProductLcoForm';
import ClientProductLcoModel from '../forms/ClientProductLcoModel';
import ClientProductLcppForm from '../forms/ClientProductLcppForm';
import ClientProductLcppModel from '../forms/ClientProductLcppModel';
import ClientProductLcrForm from '../forms/ClientProductLcrForm';
import ClientProductLcrModel from '../forms/ClientProductLcrModel';
import ClientProductLctForm from '../forms/ClientProductLctForm';
import ClientProductLctModel from '../forms/ClientProductLctModel';
import ClientProductSpottForm from '../forms/ClientProductSpottForm';
import ClientProductSpottModel from '../forms/ClientProductSpottModel';
import ClientProductQbForm from '../forms/ClientProductQbForm';
import ClientProductQbModel from '../forms/ClientProductQbModel';
import ProductGeolocations from  '../components/ProductGeolocations';
import NotificationHistoryIndex from '../views/LcppClientNotificationLogIndex';
import {showErrorModal} from '@utils/modal';

const ProductTab = (props) => {
    const getTabRender = () => {
        const generalGeoRestrictionsTitle = "Geo restrictions",
            generalSettingsTitle = "Settings",
            generalGeolocationTitle = "Geolocation",
            productsRoutes = routes.clients.products,
            urlKeys = props.match.path.split("/"),
            productUrlKey = urlKeys[4].replace('-', '');
        let matchPath = props.match.path,
            tabs = {};

        if (matchPath === productsRoutes.lcr.edit.path
            || matchPath === productsRoutes.lcr.editGeoRestrictions.path
            || matchPath === productsRoutes.lcr.geolocation.index.path
            || matchPath === productsRoutes.lcr.geolocation.add.path
            || matchPath === productsRoutes.lcr.geolocation.edit.path
        ) {
            tabs = {
                [productsRoutes.lcr.edit.path]: {
                    key: productsRoutes.lcr.edit.path,
                    url: "clients.products.lcr.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductLcrForm}
                        model={ClientProductLcrModel}
                        productId={PRODUCT_LCR}
                    />
                },
                [productsRoutes.lcr.editGeoRestrictions.path]: {
                    key: productsRoutes.lcr.editGeoRestrictions.path,
                    url: "clients.products.lcr.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductLcrGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.lcr.geolocation.index.path]: {
                    key: productsRoutes.lcr.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.lcr.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={productUrlKey}
                    />
                },
            };
        } else if (matchPath === productsRoutes.lco.edit.path
            || matchPath === productsRoutes.lco.editGeoRestrictions.path
            || matchPath === productsRoutes.lco.geolocation.index.path
            || matchPath === productsRoutes.lco.geolocation.add.path
            || matchPath === productsRoutes.lco.geolocation.edit.path
        ) {
            tabs = {
                [productsRoutes.lco.edit.path]: {
                    key: productsRoutes.lco.edit.path,
                    url: "clients.products.lco.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductLcoForm}
                        model={ClientProductLcoModel}
                        productId={PRODUCT_LCO}
                    />
                },
                [productsRoutes.lco.editGeoRestrictions.path]: {
                    key: productsRoutes.lco.editGeoRestrictions.path,
                    url: "clients.products.lco.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductLcoGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.lco.geolocation.index.path]: {
                    key: productsRoutes.lco.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.lco.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={productUrlKey}
                    />
                },
            };
        } else if (matchPath === productsRoutes.lcpp.edit.path
            || matchPath === productsRoutes.lcpp.editGeoRestrictions.path
            || matchPath === productsRoutes.lcpp.geolocation.index.path
            || matchPath === productsRoutes.lcpp.geolocation.add.path
            || matchPath === productsRoutes.lcpp.geolocation.edit.path
            || matchPath === productsRoutes.lcpp.notificationHistory.path
        ) {
            tabs = {
                [productsRoutes.lcpp.edit.path]: {
                    key: productsRoutes.lcpp.edit.path,
                    url: "clients.products.lcpp.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductLcppForm}
                        model={ClientProductLcppModel}
                        productId={PRODUCT_LCPP}
                    />
                },
                [productsRoutes.lcpp.editGeoRestrictions.path]: {
                    key: productsRoutes.lcpp.editGeoRestrictions.path,
                    url: "clients.products.lcpp.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductLcppGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.lcpp.geolocation.index.path]: {
                    key: productsRoutes.lcpp.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.lcpp.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={"lcpp"}
                    />
                },
                [productsRoutes.lcpp.notificationHistory.path]: {
                    key: productsRoutes.lcpp.notificationHistory.path,
                    url: 'clients.products.lcpp.notificationHistory',
                    urlParams: {id: props.match.params.id},
                    text: 'Notification history',
                    render: <NotificationHistoryIndex />,
                },
            };
        } else if (matchPath === productsRoutes.lct.edit.path
            || matchPath === productsRoutes.lct.editGeoRestrictions.path
            || matchPath === productsRoutes.lct.geolocation.index.path
            || matchPath === productsRoutes.lct.geolocation.add.path
            || matchPath === productsRoutes.lct.geolocation.edit.path
        ) {
            tabs = {
                [productsRoutes.lct.edit.path]: {
                    key: productsRoutes.lct.edit.path,
                    url: "clients.products.lct.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductLctForm}
                        model={ClientProductLctModel}
                        productId={PRODUCT_LCT}
                    />
                },
                [productsRoutes.lct.editGeoRestrictions.path]: {
                    key: productsRoutes.lct.editGeoRestrictions.path,
                    url: "clients.products.lct.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductLctGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.lct.geolocation.index.path]: {
                    key: productsRoutes.lct.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.lct.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={productUrlKey}
                    />
                },
            };
        } else if (matchPath === productsRoutes.spott.edit.path
            || matchPath === productsRoutes.spott.editGeoRestrictions.path
            || matchPath === productsRoutes.spott.geolocation.index.path
            || matchPath === productsRoutes.spott.geolocation.add.path
            || matchPath === productsRoutes.spott.geolocation.edit.path
        ) {
            tabs = {
                [productsRoutes.spott.edit.path]: {
                    key: productsRoutes.spott.edit.path,
                    url: "clients.products.spott.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductSpottForm}
                        model={ClientProductSpottModel}
                        productId={PRODUCT_SPOTT}
                    />
                },
                [productsRoutes.spott.editGeoRestrictions.path]: {
                    key: productsRoutes.spott.editGeoRestrictions.path,
                    url: "clients.products.spott.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductSpottGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.spott.geolocation.index.path]: {
                    key: productsRoutes.spott.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.spott.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={productUrlKey}
                    />
                },
            };
        } else if (matchPath === productsRoutes.lci.edit.path
            || matchPath === productsRoutes.lci.editGeoRestrictions.path
            || matchPath === productsRoutes.lci.geolocation.index.path
            || matchPath === productsRoutes.lci.geolocation.add.path
            || matchPath === productsRoutes.lci.geolocation.edit.path
        ) {
            tabs = {
                [productsRoutes.lci.edit.path]: {
                    key: productsRoutes.lci.edit.path,
                    url: "clients.products.lci.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductLciForm}
                        model={ClientProductLciModel}
                        productId={PRODUCT_LCI}
                    />
                },
                [productsRoutes.lci.editGeoRestrictions.path]: {
                    key: productsRoutes.lci.editGeoRestrictions.path,
                    url: "clients.products.lci.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductLciGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.lci.geolocation.index.path]: {
                    key: productsRoutes.lci.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.lci.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={productUrlKey}
                    />
                },
            };
        } else if (matchPath === productsRoutes.qb.edit.path
            || matchPath === productsRoutes.qb.editGeoRestrictions.path
            || matchPath === productsRoutes.qb.geolocation.index.path
            || matchPath === productsRoutes.qb.geolocation.add.path
            || matchPath === productsRoutes.qb.geolocation.edit.path
        ) {
            tabs = {
                [productsRoutes.qb.edit.path]: {
                    key: productsRoutes.qb.edit.path,
                    url: "clients.products.qb.edit",
                    urlParams: {id: props.match.params.id},
                    text: generalSettingsTitle,
                    render: <ProductEdit
                        form={ClientProductQbForm}
                        model={ClientProductQbModel}
                        productId={PRODUCT_QB}
                    />
                },
                [productsRoutes.qb.editGeoRestrictions.path]: {
                    key: productsRoutes.qb.editGeoRestrictions.path,
                    url: "clients.products.qb.editGeoRestrictions",
                    urlParams: {id: props.match.params.id},
                    text: generalGeoRestrictionsTitle,
                    render: <ProductQbGeoRestrictionsEdit clientProductId={props.clientProductId}/>
                },
                [productsRoutes.qb.geolocation.index.path]: {
                    key: productsRoutes.qb.geolocation.index.path,
                    hidden: !props.hasGeolocationSupport,
                    url: "clients.products.qb.geolocation.index",
                    urlParams: {id: props.match.params.id},
                    text: generalGeolocationTitle,
                    render: <ProductGeolocations
                        clientProductId={props.clientProductId}
                        productShortName={productUrlKey}
                    />
                },
            };
        }

        if ('geolocation' === urlKeys[5]) {
            matchPath = productsRoutes[productUrlKey].geolocation.index.path;

            if (!props.hasGeolocationSupport) {
                showErrorModal({
                    header: 'This product does not have geolocation support',
                    errorButtonText: 'Back to settings',
                    redirectLink: props.match.url.replace('/geolocation', ''),
                    errorButtonIcon: 'arrow circle left',
                });
            }
        }

        return <div>
            <Tabs items={tabs} active={matchPath} level={2}/>
        </div>;
    };

    return (
        <Segment basic>
            {getTabRender()}
        </Segment>
    )
};

ProductTab.propTypes = {
    clientProductId: PropTypes.number.isRequired,
    hasGeolocationSupport: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
};
ProductTab.defaultProps = {
    hasGeolocationSupport: false,
};
ProductTab.displayName = 'ProductTab';

const mapStateToProps = (state, props) => ({
    hasGeolocationSupport: _find(state.app.entities.client.clientProducts, {id: props.clientProductId.toString()})
        .product.has_geolocation_support
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(ProductTab));
