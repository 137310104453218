import React from "react";

import { ButtonAdd } from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link"
import Authorization from "../../app/components/Authorization";
import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";

const RtmpOriginEntryPointIndexButtons = () => {
    const rtmpAuthorizationObject = {
        [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="rtmpOriginEntryPoint__indexButtons content__buttons">
            <Authorization authorization={rtmpAuthorizationObject}>
                <Link name="rtmpOriginEntryPoint.add">
                    <ButtonAdd>{"Add new RTMP origin entry point"}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

export default RtmpOriginEntryPointIndexButtons;
