import React from 'react';
import {get as _get} from 'lodash';
import {Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {IconInfoCircle, IconEdit} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';

import {formatPopupContent, formatTimeDiff} from '../../utils/eventsConfigurationEncodingInformation';
import ContentConfigurationLink from './ContentConfigurationLink';

function StartDatetime(props) {
    let timeToShow = 'Manual';
    let shouldShowEditIcon = true;
    let popupContent = null;

    const encodingStartDatetime = _get(props.content, 'event_content_input.encoding_start_datetime', null);
    const encodingStartMargin = _get(props.content, 'event_content_input.encoding_start_margin', null);
    const encodingFirstStartDatetime = _get(props.content, 'event_content_input.encoding_first_start_datetime', null);
    const eventContentStartTime = _get(props.content, 'start_datetime', null);

    if (encodingStartDatetime) {
        let encodingStartTime = encodingStartDatetime;
        shouldShowEditIcon = new Date(encodingStartDatetime) > new Date();

        if (encodingStartMargin) {
            encodingStartTime += ` (-${encodingStartMargin} min)`;

            popupContent = (formatPopupContent('getStartDatetime', {encodingStartMargin: encodingStartMargin}));
            timeToShow = encodingStartTime;
        } else {
            timeToShow = encodingStartDatetime;
        }
    } else if (encodingStartMargin && eventContentStartTime) {
        const diffMinutes = formatTimeDiff(eventContentStartTime, encodingStartMargin, 'subtract');

        timeToShow = `${diffMinutes} (-${encodingStartMargin} min)`;
        popupContent = formatPopupContent('getStartDatetime', {encodingStartMargin: encodingStartMargin});
    }

    const contentData = (
        <div>
            {timeToShow}
            &nbsp;&nbsp;
            {
                shouldShowEditIcon &&
                <ContentConfigurationLink
                    contentId={convertToInt(props.content.id)}
                    eventId={convertToInt(props.eventData.id)}
                    linkName="changeEncodingStartTime"
                    icon={<IconEdit className={'black'}/>}
                    text=""

                />
            }
            {encodingFirstStartDatetime && encodingFirstStartDatetime !== encodingStartDatetime &&
                <IconInfoCircle tooltip={`First started at ${encodingFirstStartDatetime}.`}/>
            }
        </div>
    );

    return (popupContent)
        ? (
            <Popup
                flowing
                hoverable
                trigger={contentData}
            >
                {popupContent}
            </Popup>
        )
        : contentData;
}

StartDatetime.propTypes = {
    content: PropTypes.object,
    eventData: PropTypes.object,
};

export default StartDatetime;
