import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";

import {THIRD_PARTY_CLIENT_VOD_STORAGE} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE} from "../../app/constants/variables";

const ThirdPartyVodStoragesIndexButtons = (props) => (
    <div className="content__buttons">
        <Authorization
            privileges={SECURITY_PRIVILEGES_CREATE}
            resources={THIRD_PARTY_CLIENT_VOD_STORAGE}
        >
            <Link
                name={'clients.products.thirdParty.vodStorages.add'}
                params={{id: props.clientId}}
            >
                <ButtonAdd>{'Add new VOD storage'}</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

ThirdPartyVodStoragesIndexButtons.propTypes = {
    clientId: PropTypes.number.isRequired,
};

export default ThirdPartyVodStoragesIndexButtons;
