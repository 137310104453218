import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {getContent} from '@utils/helpers';
import {getResolution} from '@utils/stream';

export const exportExcel = (dataExport, eventId) => ({
    dataExport: dataExport,
    filename: `event_${eventId}_streams`,
    parseData: stream => [
        stream.id,
        stream.target.id,
        getContent(stream.target.distribution.event_content),
        stream.target.distribution.event_content.event_content_variant.name,
        stream.target.distribution.product.short_name,
        stream.target.distribution.distribution_type.name,
        _get(stream, 'target.stream_delay_type.name', ''),
        stream.device_category.name,
        stream.format.name,
        stream.protocol.name,
        stream.bitrate_video,
        stream.bitrate_audio,
        stream.frame_rate,
        getResolution(stream),
        _get(stream, 'duration', ''),
        stream.stream_name,
        stream.akamai_cp_code || '',
        _get(stream, 'stream_status.name', ''),
    ],
    path: 'events.streams.index.path',
    titles: [
        'ID',
        'Encoding target',
        'Content',
        'Content variant',
        'Product',
        'Distribution type',
        'Stream delay type',
        'Device category',
        'Format',
        'Protocol',
        'Video bitrate',
        'Audio bitrate',
        'Frame rate',
        'Resolution',
        'Duration (ms)',
        'Stream name',
        'Akamai CP code',
        'Status',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    eventId: PropTypes.number.isRequired,
};
