import {isUndefined as _isUndefined} from "lodash";
import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {gql, graphql} from "react-apollo";
import {withRouter} from "react-router";
import {compose} from "redux";
import {reduxForm} from "redux-form";
import {Button, Header} from "semantic-ui-react";

import Authorization from "../../app/components/Authorization";
import {ButtonCancel} from "../../app/components/ButtonCollection";
import {FormWrapper} from "../../app/components/HOCFormWrapper";
import MessageBox from "../../app/components/MessageBox";
import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorProductThirdPartyStreamNamesPush";
import * as MESSAGES from "../../app/constants/messages";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

import * as thirdPartyStreamNamesPushGraphQl from "../../../graphql/clients/thirdPartyStreamNamesPush";
import {refetchQueryByName} from "../../../utils/apollo";

export class ProductThirdPartyStreamNamesPushForm extends React.Component {
    static propTypes = {
        CreateThirdPartyStreamNamesPush: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        DeleteThirdPartyStreamNamesPush: PropTypes.func,
        formData: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func,
        history: PropTypes.object,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        resetIndexUrl: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        UpdateThirdPartyStreamNamesPush: PropTypes.func,
    };

    formSubmit = (data) => {
        const dataToSave = Object.assign({}, this.props.dataMap.thirdPartyStreamName, data, {
            third_party_client_cdn: parseInt(data.third_party_client_cdn, 10),
        });

        let dataSaved;

        if (0 === dataToSave.id.length) {
            dataSaved = this.props.CreateThirdPartyStreamNamesPush({
                variables: {...dataToSave},
            }).then(() => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED(`stream name (push) "${data.stream_name}"`),
                    null,
                    "success",
                );
                refetchQueryByName("ThirdPartyStreamNamesPushIndex");
                this.props.Modal.setModal({isVisible: false});
                this.props.resetIndexUrl();
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "thirdPartyClientProductStreamNamesPushMessage",
                    MESSAGES.NOT_SAVED(`stream name (push) "${data.stream_name}"`),
                    `${error}`,
                    "error",
                );
            });
        } else {
            dataSaved = this.props.UpdateThirdPartyStreamNamesPush({
                variables: {...dataToSave},
            }).then(() => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED(`stream name (push) "${data.stream_name}"`),
                    null,
                    "success",
                );
                refetchQueryByName("ThirdPartyStreamNamesPushIndex");
                this.props.Modal.setModal({isVisible: false});
                this.props.resetIndexUrl();
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "thirdPartyClientProductStreamNamesPushMessage",
                    MESSAGES.NOT_SAVED(`stream name (push) "${data.stream_name}"`),
                    `${error}`,
                    "error",
                );
            });
        }

        return dataSaved.then();
    };

    deleteThirdPartyStreamName = (id) => {
        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING(`stream name (push) "${this.props.dataForForm.thirdPartyStreamName.stream_name}"`),
        });

        return this.props.DeleteThirdPartyStreamNamesPush({
            variables: {
                id: id,
            },
        }).then(() => {
            this.props.MessageBox.addMessage(
                "clientMessage",
                MESSAGES.DELETED(`stream name (push) "${this.props.dataForForm.thirdPartyStreamName.stream_name}"`),
                null,
                "success",
            );
            refetchQueryByName("ThirdPartyStreamNamesPushIndex");
            this.props.Modal.setModal({isVisible: false});
            this.props.resetIndexUrl();
        }).catch((error) => {
            this.props.Modal.setModal({isVisible: false});
            this.props.MessageBox.addMessage(
                "clientMessage",
                MESSAGES.NOT_DELETED(`stream name (push) "${this.props.dataForForm.thirdPartyStreamName.stream_name}"`),
                `${error}`,
                "error",
            );
        });
    };

    handleThirdPartyStreamNameDelete = () => {
        const data = this.props.dataForForm.thirdPartyStreamName;

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={MESSAGES.DELETE_HEADER(`stream name (push)`)}/>,
            onYes: () => (this.deleteThirdPartyStreamName(parseInt(data.id, 10))),
            text: MESSAGES.DELETE_CONFIRMATION(`stream name (push) "${this.props.dataForForm.thirdPartyStreamName.stream_name}"`),
            size: 'tiny',
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (
            !_isUndefined(this.props.dataForForm.thirdPartyStreamName.id)
            && ("" !== this.props.dataForForm.thirdPartyStreamName.id)
        ) {
            deleteButton =
                <Button
                    color="red"
                    content="Delete"
                    disabled={this.props.submitting}
                    icon="trash"
                    onClick={this.handleThirdPartyStreamNameDelete}
                />;
        }

        return deleteButton;
    };

    closeModal = (e) => {
        e.preventDefault();

        this.props.Modal.setModal({
            content: null,
            isVisible : false,
        });
        this.props.resetIndexUrl();
    };

    render() {
        const thirdPartyStreamName = this.props.dataForForm.thirdPartyStreamName;

        return (
            <div>
                <Header as="h3" dividing>
                    {(thirdPartyStreamName.id)
                        ? `Stream name (push) "${thirdPartyStreamName.stream_name}"`
                        : 'Add new stream name (push)'
                    }
                </Header>
                <MessageBox name="thirdPartyClientProductStreamNamesPushMessage"/>
                <Form.Create
                    loading={this.props.formData.loading}
                    onSubmit={this.props.handleSubmit(this.formSubmit)}
                >
                    <Form.IdField
                        defaultValue={thirdPartyStreamName.id}
                    />
                    <Form.FormRow
                        allowClear={false}
                        component={Form.SemanticSelect}
                        defaultValue={thirdPartyStreamName.third_party_client_cdn.id}
                        label="CDN"
                        name="third_party_client_cdn"
                        options={this.props.dataForForm.cdns}
                        placeholder='Select CDN'
                        required
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={thirdPartyStreamName.stream_name}
                        label="Stream name"
                        name="stream_name"
                        required
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={thirdPartyStreamName.description}
                        label="Description"
                        name="description"
                    />
                    <Form.FormRowChangelog
                        resources={RESOURCES.THIRD_PARTY_CLIENT_PUSH_STREAM_NAME}
                        data={ (this.props.formData || {}).thirdPartyStreamName }
                    />
                    <div className="formRow form__footer">
                        <label/>
                        <Button
                            color="blue"
                            content="Save"
                            disabled={this.props.submitting}
                            icon="save"
                            loading={this.props.submitting}
                            type="submit"
                        />
                        <Authorization
                            combinedResources={false}
                            privileges={CONST.SECURITY_PRIVILEGES_DELETE}
                            resources={RESOURCES.THIRD_PARTY_CLIENT_PUSH_STREAM_NAME}
                        >
                            {this.renderDeleteButton()}
                        </Authorization>
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={this.closeModal}
                        >
                            Cancel
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </div>
        );
    }
}

const ProductThirdPartyStreamNamesPushFormRedux = reduxForm({
    form: 'ThirdPartyClientProductStreamNamesPush',
    validate,
})(withRouter(ProductThirdPartyStreamNamesPushForm));

export default compose(
    graphql(gql(thirdPartyStreamNamesPushGraphQl.createThirdPartyStreamNamesPush), {
        name: 'CreateThirdPartyStreamNamesPush',
    }),
    graphql(gql(thirdPartyStreamNamesPushGraphQl.deleteThirdPartyStreamNamesPush), {
        name: 'DeleteThirdPartyStreamNamesPush',
    }),
    graphql(gql(thirdPartyStreamNamesPushGraphQl.updateThirdPartyStreamNamesPush), {
        name: 'UpdateThirdPartyStreamNamesPush',
    }),
)(FormWrapper({
    cdns: [],
    thirdPartyStreamName: {
        description: "",
        id: "",
        stream_name: "",
        third_party_client_cdn: {id: ""},
    },
}, (ProductThirdPartyStreamNamesPushFormRedux)));
