import * as actionTypes from '../actions/actionTypes';
import {MODAL_TYPE_CONFIRMATION, MODAL_TYPE_ERROR} from '@constants/modals';

export const initialState = {
    isVisible : false,
    component : false,
    content: null,
    header : '',
    size: 'tiny',
    text : '',
    className: null,
    hideButtons : false,
    inProgress: false,
    loading: false,
    onClose: null,
    onYes: null,
    onNo: null,
    type: null,
};

/**
 * Common method for return updated state for:
 * -setModal()
 * -setModalConfirmation()
 */
const setStateModal = (state, action) => {
    return Object.assign({}, state, {...action.data});
};

/**
 * Set default params if not passed by action:
 * - hideButtons = false (we can display buttons Yes/No only if content not exist - only for confirm dialog)
 * - inProgress = false (for disable buttons in Yes/No modal)
 * - loading = false (for loading status on Yes button in Yes/No modal)
 */
const setNotPassedParams = (action) => {
    if (action.data && undefined === action.data.hideButtons) {
        action.data.hideButtons = false;
    }

    if (action.data && undefined === action.data.inProgress) {
        action.data.inProgress = false;
    }

    if (action.data && undefined === action.data.loading) {
        action.data.loading = false;
    }
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MODAL : {
            setNotPassedParams(action);

            return setStateModal(state, action);
        }

        /**
         * setModalConfirmation for modal with Yes/No buttons (confirm modal)
         */
        case actionTypes.SET_MODAL_CONFIRMATION : {
            setNotPassedParams(action);

            action.data.type = MODAL_TYPE_CONFIRMATION;

            /**
             * By default, always display confirm modal (if not passed param)
             */
            if (undefined === action.data.isVisible) {
                action.data.isVisible = true;
            }

            // We can pass only text/header for confirmation modal
            action.data.content = null;

            return setStateModal(state, action);
        }

        case actionTypes.SET_MODAL_ERROR : {
            setNotPassedParams(action);

            action.data.type = MODAL_TYPE_ERROR;

            return setStateModal(state, action);
        }

        default:
            return state;
    }
};

export default modalReducer;
