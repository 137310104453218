import validators from '../../../utils/validators';
import {FIELD_REQUIRED} from "../../app/constants/messages";

export const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.origin_stream_status, 'origin_stream_status'));

    return errors;
};

export const validateMultipleInput = (value) => {
    if (!value) {
        return FIELD_REQUIRED;
    }
};
