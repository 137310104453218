import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {Field} from 'redux-form';
import {isEmpty as _isEmpty} from 'lodash';

import {GetContinentsForDropdown} from '@graphql/continent/query';
import Form from '@appComponents/ReduxFormControls';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {showModal} from '@utils/modal';

import {renderCountryModalError} from '../utils/utils';

const CountryFilters = ({
    restoreFilterValues,
    filters,
    loading: isCountriesDataLoading,
}) => {
    const {
        data: {
            continents,
        } = {},
        error: errorFetchingContinentData,
        loading: isContinentDataLoading = true,
    } = useQuery(GetContinentsForDropdown);

    const isLoading = isCountriesDataLoading || isContinentDataLoading;

    if (!_isEmpty(errorFetchingContinentData)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(errorFetchingContinentData.message),
        });
    }

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={isLoading}
                setValue={restoreFilterValues(filters.Countries, 'search') || ''}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={isLoading}
                loading={isLoading}
                multiple
                name='continent'
                options={continents}
                placeholder='Continent'
                search
                setValue={restoreFilterValues(filters.Countries, 'continent')}
            />
        </div>
    );
};

CountryFilters.propTypes = {
    filters: PropTypes.shape({
        Countries: PropTypes.object.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(CountryFilters, {name: 'Countries', initialValues: {search: null, continent: []}});
