import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";
import {REGEX_SVG_SELECTOR} from "../../app/constants/variables";
import {routes} from "../../app/constants/routes";

class SlatesForm extends DefaultForm {
    componentDidMount() {
        this.setFallbackRoute(getLink("propertyLicences.technicalSetup.slates.index", {
            id: this.props.match.params.id
        }));

        if (routes.propertyLicences.technicalSetup.slates.edit.path === this.props.match.path) {
            this.setField('download_svg', {hidden: false});

            this.setField('file', {
                onRemoveFileCallback: () => {
                    if (this.state.defaultForm_data.slate) {
                        this.setField('svg', {defaultValue: this.state.defaultForm_data.slate.svg});
                        this.setField('svg', {hidden: false});
                        this.setField('download_svg', {hidden: false});
                    }
                },
                onErrorCallback: () => {
                    this.setField('svg', {defaultValue: null});
                    this.setField('svg', {hidden: true});
                    this.setField('download_svg', {hidden: true});
                },
            });
        } else if (routes.propertyLicences.technicalSetup.slates.add.path === this.props.match.path) {
            this.setField('svg', {hidden: true});
        }
    }

    componentWillMount() {
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink("propertyLicences.technicalSetup.slates.index", {
                id: this.props.match.params.id
            }));
        });

        this.setCreateSuccessCallback(() => {
            this.props.history.push(getLink("propertyLicences.technicalSetup.slates.index", {
                id: this.props.match.params.id
            }));
        });

        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink("propertyLicences.technicalSetup.slates.index", {
                id: this.props.match.params.id
            }));
        });

        this.setField('file', {
            onUpdateFilesCallback: (files) => {
                const svg = files[0];

                if (svg && svg.type === "image/svg+xml") {
                    const reader = new FileReader();
                    reader.onloadend = (e) => {
                        this.setField('svg', {defaultValue: e.target.result})

                        if (
                            routes.propertyLicences.technicalSetup.slates.edit.path === this.props.match.path
                            && window.btoa(this.state.defaultForm_data.slate.svg) !== window.btoa(e.target.result)
                        ) {
                            this.setField('download_svg', {hidden: true});
                        }
                    };
                    reader.readAsText(svg);
                }
            },
        });

        if (this.props.match.path === routes.propertyLicences.technicalSetup.slates.add.path) {
            this.setField('file', {
                required: true,
                onRemoveFileCallback: () => {
                    this.setField('svg', {defaultValue: null});
                    this.setField('svg', {hidden: true});
                },
                onAddFileCallback: () => {
                    this.setField('svg', {hidden: false});
                },
            });
        }
    }

    prepareDataForSubmit = (data) => {
        const svg = data.svg.match(REGEX_SVG_SELECTOR);

        return Object.assign({}, data, {
            property_licence_tech_setup_id: this.props.formParams.optionsVariables.technicalSetupId,
            svg: svg ? svg[0] : null,
        });
    };

    renderErrors(errorData) {
        const link = getLink("propertyLicences.technicalSetup.slates.index", {
            id: this.props.match.params.id
        });

        super.renderErrors(errorData, 'Slate', link);
    }

    renderCancelButton() {
        return null;
    }
}

export default withRouter(SlatesForm);
