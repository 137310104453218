import PropTypes from 'prop-types';

import {prepareExcelExportData, prepareTitlesForExcelExport} from '@modules/client/utils/lcppClientNotificationLog';

export const exportExcel = (dataExport, eventId) => ({
    dataExport: dataExport,
    filename: `event_${eventId}_lcpp_client_notifications`,
    parseData: row => prepareExcelExportData(row, true),
    path: 'clients.products.lcpp.notificationHistory.path',
    titles: prepareTitlesForExcelExport(true),
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    eventId: PropTypes.number.isRequired,
};
