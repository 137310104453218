import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {BROADCAST_STATUS_NAME_KEY} from '../../utils/eventContentDistributionBroadcastStatusChangelog';

export const exportExcel = (dataExport, distributionId) => ({
    dataExport: dataExport,
    filename: `distribution_${distributionId}_broadcast_status_changelog`,
    parseData: broadcastStatusChangelogExcel => [
        _get(broadcastStatusChangelogExcel, 'datetime', ''),
        _get(broadcastStatusChangelogExcel, BROADCAST_STATUS_NAME_KEY, ''),
        _get(broadcastStatusChangelogExcel, 'user.name', ''),
        _get(broadcastStatusChangelogExcel, 'ip', ''),
    ],
    path: 'events.configuration.content.broadcastStatusChangelog.path',
    titles: [
        'Date/time',
        'Broadcast status',
        'User',
        'IP',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    distributionId: PropTypes.string.isRequired,
};
