import PropTypes from 'prop-types';
import React from 'react';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Button, Grid} from 'semantic-ui-react';

import {FormWrapper} from '@appComponents/HOCFormWrapper';
import * as Buttons from '@appComponents/ButtonCollection';
import Form from '@appComponents/ReduxFormControls';
import {generateLanguageSelectOptions, generateTracksOptions} from '@utils/fieldsGenerator';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import validate from '../forms/EventMultiAudioValidator';

class EventContentMultiAudioForm extends React.Component {
    static propTypes = {
        dataForForm: PropTypes.object,
        formData: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        onAdd: PropTypes.func,
        onDeactivateEditableCell: PropTypes.func,
        onUpdate: PropTypes.func,
        submitting: PropTypes.bool,
        form: PropTypes.string.isRequired,
        currentTrackAdd: PropTypes.number,
        currentStreamLanguageValueAdd: PropTypes.number,
        currentTrackUpdate: PropTypes.number,
        currentStreamLanguageValueUpdate: PropTypes.number,
        tracks: PropTypes.array,
        channels: PropTypes.array,
    };

    static defaultProps = {
        tracks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        channels: [1, 2, 3, 4],
    };

    constructor() {
        super();

        this.state = {
            inputRefreshTimestamp: Date.now(),
        };
    }

    onFormSubmit(data) {
        if (data.id) {
            this.props.onUpdate();
            this.props.onDeactivateEditableCell();
        } else {
            this.props.onAdd();
            this.setState(() => ({
                inputRefreshTimestamp: Date.now(),
            }));
        }
    }

    channelsSelectedOptions = (data) => {
        const result = [];

        data.map((channel) => {
            result.push(parseInt(channel));
        });

        return result;
    };

    languageColumnDefaultValue = () => {
        if (this.props.onUpdate) {
            if (this.props.currentStreamLanguageValueUpdate) {
                return this.props.currentStreamLanguageValueUpdate.toString();
            }

            return this.props.dataForForm.audios.streamLanguages.id.toString() || null;

        }

        if (this.props.onAdd) {
            return this.props.currentStreamLanguageValueAdd.toString();
        }

        return this.props.currentStreamLanguageValueUpdate.toString() || null;
    }

    trackColumnDefaultValue = () => {
        if (this.props.onUpdate) {
            if (this.props.currentTrackUpdate) {
                return this.props.currentTrackUpdate;
            }

            return this.props.dataForForm.audios.trackNumber.toString();

        }

        if (this.props.onAdd) {
            return this.props.currentTrackAdd;
        }

        return this.props.currentTrackUpdate.toString() || null;
    }

    renderTrackColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                allowClear={false}
                timestamp={this.state.inputRefreshTimestamp}
                name='trackNumber'
                defaultValue={this.trackColumnDefaultValue()}
                options={generateTracksOptions(this.props.tracks)}
                placeholder='Select'
                required
            />
        );
    };

    renderChannelsColumn = () => {
        return (
            <Form.FormRow
                className='channels-inline'
                component={Form.SemanticCheckboxList}
                defaultValue={this.props.onUpdate
                    ? this.channelsSelectedOptions(this.props.dataForForm.audios.channels)
                    : []}
                label=''
                name='channels'
                options={this.props.channels.map((channel) => {
                    return {
                        value: channel,
                        key: channel,
                    };
                })}
                inline
                required
            />
        );
    };

    renderLanguageColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                allowClear={true}
                timestamp={this.state.inputRefreshTimestamp}
                name='streamLanguage'
                defaultValue={this.languageColumnDefaultValue()}
                options={generateLanguageSelectOptions(this.props.dataForForm.streamLanguages)}
                placeholder='-- Default --'
                required
            />
        );
    };

    renderButtons = () => {
        if ('form_ema_add' === this.props.form) {
            return (
                <Authorization authorization={{
                    [RESOURCES.AUDIO]: CONST.SECURITY_PRIVILEGES_CREATE,
                }}>
                    <Buttons.ButtonAdd disabled={this.props.submitting}
                        loading={this.props.submitting}>Add</Buttons.ButtonAdd>
                </Authorization>
            );
        } else {
            return (
                <Button.Group>
                    <Button
                        data-id={this.props.dataForForm.audios.id}
                        disabled={this.props.submitting}
                        icon='checkmark'
                        key='update-button'
                        loading={this.props.submitting}
                        positive
                    />
                    <Button
                        icon='ban'
                        key='cancel-button'
                        negative
                        onClick={this.props.onDeactivateEditableCell}
                        type='button'
                    />
                </Button.Group>
            );
        }
    };

    render() {
        return (
            <Form.Create onSubmit={this.props.handleSubmit((values) => {
                return this.onFormSubmit(values);
            })}
            >
                <Form.IdField
                    defaultValue={this.props.dataForForm.audios.id}
                    name='id'
                />
                <Grid verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {this.renderTrackColumn()}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {this.renderChannelsColumn()}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {this.renderLanguageColumn()}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className='actions-list-icon'>
                                {this.renderButtons()}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form.Create>
        );
    }
}

const selectorAdd = formValueSelector('form_ema_add');
const selectorUpdate = formValueSelector('form_ema_update');

const EventContentMultiAudioWithRedux = reduxForm({
    form: 'EventContentMultiAudio',
    validate,
})(EventContentMultiAudioForm);

const EventContentMultiAudioWithForm = connect(state => {

    const currentTrackAdd = parseInt(selectorAdd(state, 'trackNumber'));
    const currentStreamLanguageValueAdd = parseInt(selectorAdd(state, 'streamLanguage'));

    const currentTrackUpdate = parseInt(selectorUpdate(state, 'trackNumber'));
    const currentStreamLanguageValueUpdate = parseInt(selectorUpdate(state, 'streamLanguage'));

    return {
        currentTrackAdd,
        currentStreamLanguageValueAdd,
        currentTrackUpdate,
        currentStreamLanguageValueUpdate,
    };
})(EventContentMultiAudioWithRedux);

export default FormWrapper({
    encodingTargetId: null,
    streamLanguages: [],
    encodingTargetsSr: [],
    audios: {
        id: '',
        trackNumber: '',
        channels: '',
        streamLanguages: {
            id: '',
            name: '',
        },
    },
}, EventContentMultiAudioWithForm);
