import PropTypes from "prop-types";
import {has as _has} from "lodash";

import {addHasMatchIdToStructure} from "../../../../../../utils/eventObject";
import {getFieldsList as getFieldsListColumn1} from "./fieldsList/column1";
import {getFieldsList as getFieldsListColumn2} from "./fieldsList/column2";
import {getFieldsList as getFieldsListColumn3} from "./fieldsList/column3";
import {getFieldsList as getFieldsListColumn4} from "./fieldsList/column4";

export const matchFieldsList = (event, isTvStreaming) => {
    /**
     * We can display information only for Sportradar Event with Match ID
     */
    if (!_has(event, 'sr_event.match.id')) {
        return [];
    }

    const eventData = addHasMatchIdToStructure(event),
        columns = [
            getFieldsListColumn1(eventData, isTvStreaming),
            getFieldsListColumn2(eventData),
            getFieldsListColumn3(eventData),
            getFieldsListColumn4(eventData)
        ];

    // Do not display last column if notes is empty in "Event details" section to avoid unnecessary margin
    if (null === event.notes) {
        return columns;
    }

    // Add empty column to prepare the same margin in the "Event details" section if notes exists
    columns.push([]);

    return columns;
};

matchFieldsList.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string
            })
        })
    }),
    isTvStreaming: PropTypes.bool,
};

matchFieldsList.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null
            }
        }
    },
    isTvStreaming: false,
}
