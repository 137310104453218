import React from 'react';
import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {queryGetClipConfigForEventBooking, queryGetPrefillClipConfigForEventBooking} from '@graphql/eventEventBooking/query';
import {convertToInt} from '@utils/helpers';
import {createForm, getFormMessageForWizard} from '@utils/forms';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {generateTree, getAdditionalBackButton} from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';
import {onCloseModal, prepareClipsTree} from '@modules/eventBooking/forms/helpers/helpers';
import {showMessageBox} from '@utils/messageBox';

import EventEventBookingGeoRestrictionsModel from './EventEventBookingGeoRestrictionModel';
import EventEventBookingGeoRestrictionsForm from './EventEventBookingGeoRestrictionForm';

export class EventBookingClipsForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        return Object.assign({}, {
            clips: data.clips,
            eventId: this.props.previousStepData.eventId,
            product: this.props.previousStepData.product,
            client: this.props.previousStepData.client,
            client_package: this.props.previousStepData.client_package ? this.props.previousStepData.client_package : null,
            auto_update_geo_restrictions: this.props.previousStepData.auto_update_geo_restrictions || false,
        });
    };

    onFormSubmit = (data) => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', ''))
                    .toLocaleLowerCase()
            ));
        }

        this.setState(() => ({
            defaultForm_errorShowed: false,
        }));

        const setMutationSuccessCallback = this.props.formParams.setMutationSuccessCallback;
        const Form = createForm(
            EventEventBookingGeoRestrictionsModel,
            EventEventBookingGeoRestrictionsForm,
            {
                setMutationSuccessCallback,
                id: data.id,
                optionsVariables: {
                    event: this.props.previousStepData.eventId,
                    product: convertToInt(this.props.previousStepData.product),
                },
            });
        const generalInformationFormData = this.prepareDataForSubmit(data);

        if (0 === generalInformationFormData.clips.length) {
            showMessageBox(
                'formInnerErrorMessage',
                '',
                'Can\'t go to next step. Please correct the highlighted fields below.',
                'error',
                true
            );

            return false;
        }

        this.props.stepsMethods.setStep({
            id: 'step3',
            menuItem: {disabled: false},
            pane: {content: <Form previousStepData={generalInformationFormData}/>},
        });

        this.props.stepsMethods.showStep('step3');
    };

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    componentDidMount() {
        this.setOnChangeCallback({
            clips: () => {
                this.setField('clips', {
                    defaultValue: null,
                    value: null,
                });
            },
        });

        prepareClipsTree(
            this.props.previousStepData.eventId,
            this.props.previousStepData.product,
            this.state,
            (state) => this.setState(state),
            this.setField,
            this.runApolloRequest,
            queryGetClipConfigForEventBooking,
            this.props.MessageBox
        );
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        this.props.stepsMethods.setForm('step2', prevProps);

        if (this.state.clipConfigsForEventBooking) {
            if (this.props.previousStepData.client_package) {
                this.prefillClipsTree(this.props.previousStepData.eventId, this.props.previousStepData.client_package);
            } else if (!this.state.generatedClipConfigTreeEventBooking) {
                const tree = generateTree(
                    this.state.clipConfigsForEventBooking,
                    []
                );

                if (!_isEmpty(tree)) {
                    this.setField('clips', {
                        required: true,
                        options: tree,
                    });
                }

                this.setState(() => ({generatedClipConfigTreeEventBooking: true}));
            }
        }
    }

    queryGetPreFillClipConfigForEventBooking = (event, client_package) => {
        this.setState(() => ({queryGetPreFillClipConfigForEventBooking: true}));

        return this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: queryGetPrefillClipConfigForEventBooking,
            variables: {
                event: event,
                client_package: convertToInt(client_package),
            },
        }).catch(() => {
            return [];
        });
    };

    prefillClipsTree(event, client_package) {
        if (!this.state.queryGetPreFillClipConfigForEventBooking) {
            this
                .queryGetPreFillClipConfigForEventBooking(event, client_package)
                .then((result) => {
                    let prefillTree = [];

                    if (!_isEmpty(_get(result, 'data.clipConfigsPrefillForEventBooking', null))) {
                        prefillTree = result.data.clipConfigsPrefillForEventBooking.map((selectedClip) => {
                            const selectedEvent = selectedClip.event_content_type.id;
                            const selectedMatch = selectedClip.match_event_types.map(({id}) => id);

                            return {
                                firstLevelTree: selectedEvent,
                                secondLevelTree: selectedMatch,
                            };
                        });
                    }

                    const tree = generateTree(
                        this.state.clipConfigsForEventBooking,
                        prefillTree
                    );

                    if (!_isEmpty(tree)) {
                        this.setField('clips', {
                            required: true,
                            options: tree,
                        });
                    }

                    this.setState(() => ({
                        apolloRequests: {isRunningLoadingForm: false},
                    }));
                });
        }
    }

    onCancel = () => {
        onCloseModal(
            this.props.formParams.optionsVariables.event,
            this.props.formParams.optionsVariables.client,
            this.props.Modal.setModal,
            this.props
        );
    };

    renderCancelButton = (props) => super.renderCancelButton({
        ...props,
        type: 'button',
        onClick: this.onCancel,
    });

    renderAdditionalButtons = () => (
        getAdditionalBackButton(this.props.Model.editForm, this.props.stepsMethods.showPreviousStep)
    );

    renderSaveButton = (props) => {
        return super.renderSaveButton({
            ...props,
            content: 'Next',
            icon: 'angle right',
            labelPosition: 'right',
        });
    };
}

export default withRouter(EventBookingClipsForm);
