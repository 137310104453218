import classnames from "classnames";
import React from "react";
import {Grid} from "semantic-ui-react";

import Form from "../../app/components/ReduxFormControls";

import {convertToInt, getProductShortNameWithOnlyLettersAndDigits} from "../../../utils/helpers";

import {distributions, distributionLabels} from "../constants/distributions";
import * as VARIABLES from "../../app/constants/variables";

export const renderProducts = (products, renderDivider) => {
    let fields = {};

    products.forEach((product) => {
        let key = (VARIABLES.PRODUCT_THIRD_PARTY === convertToInt(product.id))
            ? "thirdParty"
            : getProductShortNameWithOnlyLettersAndDigits(product.short_name);

        fields[key] = {
            dataMapKey: "event.property_licence.id",
            props: {
                component: renderProductHeader,
                hidden: true,
                label: product.short_name,
                name: `product_${product.id}`,
            },
            wizardKey: "distribution",
        };

        fields = Object.assign({}, fields, renderDistributionFields(
            product.id,
            [`${key}_live`, `${key}_recording`, `${key}_vod`],
        ));

        fields[`${key}_divider`] = {
            props: {
                component: renderDivider,
                hidden: true,
                name: `product_${product.id}_divider`,
            },
            wizardKey: "distribution",
        };
    });

    return fields;
};

const renderBroadcastStatus = (data) => {
    return {
        optionsKey: data.optionsKey,
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 2,
        props: {
            component: Form.SemanticSelect,
            name: `${data.name}_broadcast_status`,
            hidden: true,
            required: true,
            className: "broadcast_status",
            defaultValue: VARIABLES.BROADCAST_STATUS_APPROVED.toString(),
        }
    };
};

const renderDistributionFields = (productId, groupKeys) => {
    let fields = {};

    groupKeys.forEach((groupKey, index) => {
        let distributionId = distributions[index];

        fields[`${groupKey}_distribution_usage`] = renderDistributionUsage({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
            label: distributionLabels[distributionId],
        });
        fields[`${groupKey}_product_status`] = renderProductStatus({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
            optionsKey: "productStatuses",
        });
        fields[`${groupKey}_synchronise_times`] = renderSynchroniseTimes({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
        });
        fields[`${groupKey}_start_datetime`] = renderStartDate({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
        });
        fields[`${groupKey}_end_datetime`] = renderEndDate({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
        });
        fields[`${groupKey}_name`] = renderName({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
        });
        fields[`${groupKey}_client_selection_type`] = renderClientSelectionType({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
            optionsKey: "selectionTypes",
        });
        fields[`${groupKey}_broadcast_status`] = renderBroadcastStatus({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
            optionsKey: "broadcastStatuses",
        });
        fields[`${groupKey}_clients`] = renderClients({
            name: `${productId}_${distributionId}`,
            wizardKey: "distribution",
            groupKey: groupKey,
            groupSize: 9,
            optionsKey: "clients",
        });
    });

    return fields;
};

const renderDistributionUsage = (data) => {
    return {
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 1,
        props: {
            component: Form.SemanticInput,
            name: `${data.name}_distribution_usage`,
            hidden: true,
            className: "product",
            description: data.label,
            type: 'checkbox'
        }
    };
};

const renderEndDate = (data) => {
    return {
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 2,
        props: {
            component: Form.SemanticInput,
            name: `${data.name}_end_datetime`,
            hidden: true,
            className:"end_time",
            configuration: {
                calendarClassName: "inlineDatepicker",
                showTimeSelect: true,
                timeFormat: "HH:mm:ss",
                timeIntervals: 5,
                updateOnExternalChange: true,
            },
            restorePreviousValue: true,
            type: 'date',
        }
    };
};

const renderName = (data) => {
    return {
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 2,
        props: {
            component: Form.SemanticInput,
            name: `${data.name}_name`,
            hidden: true,
            className: "name"
        }
    }
};

const renderClientSelectionType = (data) => ({
    optionsKey: data.optionsKey,
    wizardKey: data.wizardKey,
    groupKey: data.groupKey,
    groupSize: data.groupSize,
    groupWidth: 2,
    props: {
        component: Form.SemanticSelect,
        name: `${data.name}_client_selection_type`,
        hidden: true,
        required: true,
        className: "client_selection_type",
        defaultValue: VARIABLES.CLIENT_SELECTION_TYPE_ALL.toString(),
    },
});

const renderClients = (data) => ({
    optionsKey: data.optionsKey,
    wizardKey: data.wizardKey,
    groupKey: data.groupKey,
    groupSize: data.groupSize,
    groupWidth: 4,
    rowNumber: 2,
    props: {
        component: Form.SemanticSelect,
        name: `${data.name}_clients`,
        hidden: true,
        className: "clients",
        multiple: true,
        allowClear: true,
    },
});

const renderProductHeader = (data) => {
    if (data.renderDisabledHeader) {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <Form.SemanticInput
                            {...data}
                            type="toggle"
                            disabled={true}
                            defaultValue={false}
                            checked={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        No distribution types are available for this product
                        (based on event content type and media-rights)
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    } else {
        const hideTitles = (!data.checked) ? 'hidden' : '';

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column className="column_product">
                        <Form.SemanticInput {...data} type="toggle"/>
                    </Grid.Column>
                    <Grid.Column className={classnames('column_status', hideTitles)}>Status*</Grid.Column>
                    <Grid.Column textAlign={'center'}  className={classnames('column_sync', hideTitles)}>Synch. times</Grid.Column>
                    <Grid.Column className={classnames('column_start_time', hideTitles)}>Start time*</Grid.Column>
                    <Grid.Column className={classnames('column_end_time', hideTitles)}>End time</Grid.Column>
                    <Grid.Column className={classnames('column_name', hideTitles)}>Name</Grid.Column>
                    <Grid.Column className={classnames('column_client_selection_type', hideTitles)}>Clients*</Grid.Column>
                    <Grid.Column className={classnames('column_broadcast_status', hideTitles)}>Broadcast status*</Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
};

const renderProductStatus = (data) => {
    return {
        optionsKey: data.optionsKey,
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 2,
        props: {
            component: Form.SemanticSelect,
            name: `${data.name}_product_status`,
            hidden: true,
            required: true,
            className: "status"
        }
    };
};

const renderStartDate = (data) => {
    return {
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 2,
        props: {
            component: Form.SemanticInput,
            name: `${data.name}_start_datetime`,
            hidden: true,
            required: true,
            className:"start_time",
            configuration: {
                calendarClassName: "inlineDatepicker",
                showTimeSelect: true,
                timeFormat: "HH:mm:ss",
                timeIntervals: 5,
                updateOnExternalChange: true,
            },
            restorePreviousValue: true,
            type: 'date',
        }
    };
};

const renderSynchroniseTimes = (data) => {
    return {
        wizardKey: data.wizardKey,
        groupKey: data.groupKey,
        groupSize: data.groupSize,
        groupWidth: 1,
        props: {
            component: Form.SemanticInput,
            name: `${data.name}_synchronise_times`,
            hidden: true,
            type: 'checkbox',
            defaultValue: true,
            className: "sync"
        }
    };
};

