import React from 'react';

import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {BLACKOUT_ZONE} from '@constants/resources';
import {AddBlackoutZonePostalCodes} from '@graphql/blackoutZonePostalCodes/mutation';
import {GetOptionsForPostalCodesForm} from '@graphql/country/query';

import validationBlackoutZonePostalCodes from './validationBlackoutZonePostalCodes';

export default function BlackoutZonePostalCodesModel({setIsRefetching, redirectUrl, Modal}) {
    const renderInfoRow = () => (<div className='infoRow'>
        Enter a list of postal codes separated by commas or line breaks.
    </div>);

    return {
        entityDataMapKey: 'blackoutZone',
        entityLabel: 'Blackout Zone',
        formName: 'blackoutZonePostalCodes',
        label: 'Blackout Zone Postal Codes',
        resources: BLACKOUT_ZONE,
        validate: validationBlackoutZonePostalCodes,
        title: null,
        renderLabels: false,
        showChangeLog: false,
        messages: {
            boxName: {
                error: null,
                success: null,
            },
            text: {
                ...MESSAGES,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'blackoutZone.id',
                props: {
                    hidden: true,
                    name: 'id',
                },
            },
            country: {
                dataMapKey: 'clientProductGeolocationCountry.country.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Country',
                    name: 'country',
                    required: true,
                },
            },
            info: {
                props: {
                    component: renderInfoRow,
                    name: null,
                },
            },
            postalCodes: {
                dataMapKey: 'blackoutZone.postalCodes',
                props: {
                    component: Form.SemanticTextArea,
                    name: 'postalCodes',
                    required: true,
                },
            },
        },
        query: {
            getOptions: GetOptionsForPostalCodesForm,
        },
        mutation: {
            updateEntity: AddBlackoutZonePostalCodes,
        },
        mutationOptions: {},
        setIsRefetching: setIsRefetching,
        indexUrl: redirectUrl,
        Modal: Modal,
    };
}
