import React from "react";
import {Icon} from "semantic-ui-react";
import PropTypes from "prop-types";

export class Checkbox extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        selected: PropTypes.bool,
        data: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            classNameForIcon: this.setClassNameForIcon(props.selected)
        }
    }

    componentWillReceiveProps(props) {
        this.setState(() => ({
            classNameForIcon: this.setClassNameForIcon(props.selected)
        }))
    }

    setClassNameForIcon(active) {
        if (active) {
            return "checkmark box"
        }

        return "square outline"
    }

    onClick = (event) => {
        event.stopPropagation();
        this.props.onClick(this.props.data);
    };

    render() {
        return (
            <Icon name={this.state.classNameForIcon} onClick={this.onClick} className="large"/>
        )
    }
}

export default Checkbox
