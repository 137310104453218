export const INDEX_BUTTONS_ADD = 'Add new client';
export const CLIENT_TABLE_NO_DATA = 'No clients found';
export const DELETE_MODAL_HEADER = 'Delete Client';
export const DELETE_MODAL_TEXT = (name) => (`Are you sure to delete "${name}" Client`);
export const DELETE_MODAL_PROGRESS = 'Client deleting...';
export const DELETE_MODAL_SUCCESS = 'Client was deleted!';
export const DELETE_MODAL_ERROR = 'Client information was NOT deleted!';

export const CONTACT_PERSON_INDEX_BUTTONS_ADD = 'Add new contact person';

export const PRODUCT_THIRD_PARTY_STREAM_NAMES_INDEX_BUTTONS_ADD = (type) => `Add new stream name (${type})`;

export const CLIENT_ANALYTICS_SETUP_REPORT_ACCESSIBILITY_DATE = 'Date from which reports are accessible to the client.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_OVERALL_FIGURES = 'Basic overall statistics. ' +
    'Monthly / daily peak charts, total number of sessions and so on. ' +
    'No breakdown by streams, countries or referrers.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_TOP_COUNTRIES = 'Traffic breakdown for the top X countries.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_ALL_COUNTRIES = 'Same as above, ' +
    'except that this one gives access to the full list of countries instead of top X.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_UNIKEYS_SUPPORT = 'To be enabled for (some) Unas clients.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_LABELS_SUPPORT = 'To be enabled for (some) Unas clients.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_SESSION_DURATION = 'Session duration information.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_TOP_REFERRERS = 'Traffic figures broken down by referrer URL.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_TOP_STREAMS = 'Traffic figures broken down by stream.';
export const CLIENT_ANALYTICS_SETUP_TOOLTIP_STREAM_DETAILS = 'Detailed figures for individual streams. ' +
    'Gives access to a breakdown by country for a single stream for example.';
export const CLIENT_ANALYTICS_SETUP_LABELS_INDEX_BUTTONS_ADD = 'Add new label';
export const CLIENT_ANALYTICS_SETUP_LABLES_TABLE_NO_DATA = 'No labels found';
export const CLIENT_ANALYTICS_SETUP_LABLES_TABLE_MAX_NUMBER = (maxNumberOfClientLabels) => `
    ${maxNumberOfClientLabels} labels are already defined. It is not possible to add more labels.
`;
export const CLIENT_BOOKINGS_TABLE_INVOICE_STATUS_TEXT = 'The invoice status has been set specifically for this booking.';
export const CLIENT_BOOKING_BULK_PACKAGE_MSG = 'If you modify the client package of autobooked bookings ' +
    'for contents with a start time greater than one hour ago,' +
    'it may be changed automatically again if the Autobooker is triggered for these bookings.';

