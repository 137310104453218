import validators from '@utils/validators';

const validationBlackoutZonePostalCodes = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.country, 'country'));
    Object.assign(errors, validators.required(values.postalCodes, 'postalCodes'));
    Object.assign(errors, validators.alphanumericWithCommasAndWhitespaces(values.postalCodes, 'postalCodes'));

    return errors;
};

export default validationBlackoutZonePostalCodes;
