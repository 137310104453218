import PropTypes from "prop-types";
import React from "react";

const MatrixTable = (props) => (
    <table className="tableMatrix">
        <tbody>
            <tr>
                <td></td>
                {Object.keys(props.columns).map((columnId) => (
                    <td key={`column${columnId}`}>{props.columns[columnId]}</td>
                ))}
            </tr>
            {props.rows.map((rowObject) => (
                <tr key={`row_${rowObject.id}`}>
                    <td>{rowObject.name}</td>
                    {Object.keys(props.columns).map((columnId) => (
                        <td key={`matrixOption_${rowObject.id}_${columnId}`} className="tableMatrix__option">
                            {props.renderCheckbox(rowObject, columnId)}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
);

MatrixTable.propTypes = {
    columns: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    renderCheckbox: PropTypes.func.isRequired
};

export default MatrixTable
