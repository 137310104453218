export const tournamentsDropdown = `
query Tournaments($tournamentCategory: [Int]) {
  tournaments(filters: {tournamentCategory: $tournamentCategory}) {
    id
    name
    key: id
    value: id
    text: name
    parent {
      id
    }
  }
}
`;

export const tournamentsFragment = `
        id
        name
        parent {
            id
            name
        }
        tournament_category{
          id
          name
          sport {
            id
            name
          }
        }
`;
