import React from 'react';
import {Header} from 'semantic-ui-react';
import {get as _get} from 'lodash';

import Authorization from '@appComponents/Authorization';
import {getLink} from '@appComponents/Link';
import {IconDelete, IconEdit} from '@appComponents/IconCollection';
import {LCR_CHANNEL} from '@constants/resources';
import {
    SECURITY_PRIVILEGES_READ_UPDATE,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import * as MESSAGES from '@constants/messages';
import {
    DeleteLcrChannelSport,
    DeleteLcrChannelTournamentCategory,
    UpdateLcrChannelTournament,
} from '@graphql/lcr/mutation';
import {GetLcrChannelTournamentContent} from '@graphql/lcr/query';
import {hideModal, showModal} from '@utils/modal';
import {showMessageBox} from '@utils/messageBox';
import {refetchQueryByName} from '@utils/apollo';

import apolloClient from '../../../apolloClient';

const actionsColumn = {
    actions: {
        label: 'Actions',
        header: {
            align: 'center',
            className: '--width-50px',
        },
        content: {align: 'center'},
    },
};

const getContentTiers = (row) => {
    return row.content_tiers.map((item) => (
        item.name
    )).join(', ');
};

export const sportsTableColumns = {
    sport: {
        label: 'Sport',
        sorting: row => row.sport.name,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
            singleLine: true,
        },
    },
    content_tiers: {
        label: 'Tiers',
        sorting: (row) => getContentTiers(row),
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    ...actionsColumn,
};

export const sportsRowRenderer = (column, row, props) => {
    switch (column) {
        case 'sport':
            return row.sport.name;
        case 'content_tiers':
            return getContentTiers(row);
        case 'actions':
            return (
                <div className='actions-list-icon'>
                    <Authorization authorization={{
                        [LCR_CHANNEL]: SECURITY_PRIVILEGES_READ_UPDATE,
                    }}>
                        <IconEdit
                            size='large'
                            link={getLink(
                                'lcrChannel.edit.contentRestrictions.sports.edit',
                                {id: props.id, sportId: row.id}
                            )}
                            resource={LCR_CHANNEL}
                        />
                    </Authorization>
                    <Authorization authorization={{
                        [LCR_CHANNEL]: SECURITY_PRIVILEGES_UPDATE,
                    }}>
                        <IconDelete icon='trash'
                            size='large'
                            onClick={deleteSportConfirmationModal}
                            onClickElement={{id: row.id, name: row.sport.name}}
                        />
                    </Authorization>
                </div>

            );
        default: {
            return null;
        }
    }
};

export const categoriesTableColumns = {
    category: {
        label: 'Category',
        sorting: row => `${row.tournament_category.sport.name} > ${row.tournament_category.name}`,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
            singleLine: true,
        },
    },
    content_tiers: {
        label: 'Tiers',
        sorting: (row) => getContentTiers(row),
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    ...actionsColumn,
};

export const categoriesRowRenderer = (column, row, props) => {
    switch (column) {
        case 'category':
            return `${row.tournament_category.sport.name} > ${row.tournament_category.name}`;
        case 'content_tiers':
            return getContentTiers(row);
        case 'actions':
            return (
                <div className='actions-list-icon'>
                    <Authorization authorization={{
                        [LCR_CHANNEL]: SECURITY_PRIVILEGES_READ_UPDATE,
                    }}>
                        <IconEdit
                            size='large'
                            link={getLink(
                                'lcrChannel.edit.contentRestrictions.categories.edit',
                                {id: props.id, categoryId: row.id}
                            )}
                            resource={LCR_CHANNEL}
                        />
                    </Authorization>
                    <Authorization authorization={{
                        [LCR_CHANNEL]: SECURITY_PRIVILEGES_UPDATE,
                    }}>
                        <IconDelete icon='trash'
                            size='large'
                            onClick={deleteTournamentCategoryConfirmationModal}
                            onClickElement={{id: row.id, name: `${row.tournament_category.sport.name} > ${row.tournament_category.name}`}}
                        />
                    </Authorization>
                </div>
            );
    }
};

const deleteContentRestriction = (mutation, queryToRefetch, id, name, label, type) => {
    showModal({
        text: MESSAGES.DELETING(label, name),
    });

    return  apolloClient.mutate({
        mutation: mutation,
        variables: {
            id: id,
        },
    }).then(() => {
        showMessageBox(
            `lcrChannelContentRestriction-${type}`,
            MESSAGES.DELETED(label, name),
            null,
            'success'
        );
        refetchQueryByName(queryToRefetch);
        hideModal();
    }).catch((error) => {
        hideModal();
        showMessageBox(
            `lcrChannelContentRestriction-${type}`,
            MESSAGES.NOT_DELETED(label, name),
            `${error}`,
            'error'
        );
    });
};

const deleteSportConfirmationModal = (data) => {
    showModal({
        isVisible: true,
        header: <Header icon='trash' content={`Sport - ${data.name}`}/>,
        onYes: () => deleteContentRestriction(
            DeleteLcrChannelSport,
            'GetLcrChannelSportContent',
            data.id,
            data.name,
            'sport',
            'sports'
        ),
        text: MESSAGES.DELETE_CONFIRMATION('sport', data.name),
    });
};

export const deleteTournament = (data) => {
    try {
        apolloClient.query({
            query: GetLcrChannelTournamentContent,
            variables: {
                lcrChannelId: data.lcrId,
            },
        }).then((response) => {
            const tournaments = _get(response, 'data.lcrChannel.tournaments', []);
            const tournamentsToSave = tournaments.filter(tournament => tournament.id !== data.id).map((item) => item.id);

            apolloClient.mutate({
                mutation: UpdateLcrChannelTournament,
                variables: {
                    id: data.lcrId,
                    tournaments: tournamentsToSave,
                },
            }).then(() => {
                hideModal();
                showMessageBox(
                    'lcrChannelContentRestriction-tournaments',
                    MESSAGES.DELETED('tournament'),
                    null,
                    'success'
                );
                refetchQueryByName('GetLcrChannelTournamentContent');
            });

        });
    } catch(exception) {
        showMessageBox(
            'lcrChannelContentRestriction-tournaments',
            MESSAGES.NOT_DELETED('tournament'),
            null,
            'error'
        );
    }
};

const deleteTournamentConfirmationModal = (data) => {
    showModal({
        isVisible: true,
        header: <Header icon='trash' content={`Tournament - ${data.name}`}/>,
        onYes: () => {
            deleteTournament(data);
        },
        text: MESSAGES.DELETE_CONFIRMATION('tournament', data.name),
    });
};

const deleteTournamentCategoryConfirmationModal = (data) => {
    showModal({
        isVisible: true,
        header: <Header icon='trash' content={`Category - ${data.name}`}/>,
        onYes: () => deleteContentRestriction(
            DeleteLcrChannelTournamentCategory,
            'GetLcrChannelTournamentCategoryContent',
            data.id,
            data.name,
            'category',
            'categories'
        ),
        text: MESSAGES.DELETE_CONFIRMATION('category', data.name),
    });
};

export const tournamentsTableColumns = {
    tournament: {
        label: 'Tournament',
        sorting: (row) => `${row.tournament_category.sport.name} > ${row.tournament_category.name} > ${row.name}`,
        header: {
            align: 'left',
        },
        className: '--width-100-percent',
        content: {
            align: 'left',
        },
    },
    ...actionsColumn,
};

export const tournamentsRowRenderer = (column, row, props) => {
    switch (column) {
        case 'tournament':
            return `${row.tournament_category.sport.name} > ${row.tournament_category.name} > ${row.name}`;
        case 'actions':
            return (
                <Authorization authorization={{
                    [LCR_CHANNEL]: SECURITY_PRIVILEGES_UPDATE,
                }}>
                    <div className='actions-list-icon'>
                        <IconDelete icon='trash'
                            size='large'
                            onClick={deleteTournamentConfirmationModal}
                            onClickElement={{
                                id: row.id,
                                name: `${row.tournament_category.sport.name} > ${row.tournament_category.name} > ${row.name}`,
                                lcrId: props.id,
                            }}
                        />
                    </div>
                </Authorization>
            );
    }
};
