import {get as _get} from "lodash";
import PropTypes from "prop-types";

import {getYesOrNo} from "../../../../utils/helpers";

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_cdn_configurations`,
    parseData: cdnConfiguration => [
        cdnConfiguration.id,
        cdnConfiguration.name,
        cdnConfiguration.client_product.product.short_name,
        cdnConfiguration.cdn.name,
        _get(cdnConfiguration, 'akamai_cdn_ingest_method.name', ''),
        getYesOrNo(cdnConfiguration.has_external_storage),
        _get(cdnConfiguration, 'live_encoding_job_profile.name', ''),
        _get(cdnConfiguration, 'recording_encoding_job_profile.name', ''),
    ],
    path: 'clients.clientCdnConfiguration.index.path',
    titles: [
        "ID",
        "Name",
        "Product",
        "CDN",
        "Akamai CDN ingest method",
        "External storage?",
        "Live encoding job profile",
        "Recording encoding job profile",
    ],
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired
};
