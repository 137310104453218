import PropTypes from "prop-types";
import React from "react";

import ClientDrmSetupForm from "../forms/ClientDrmSetupForm";
import ClientDrmSetupModel from "../forms/ClientDrmSetupModel";

import {createForm} from "../../../utils/forms";
import {ClientDrmSetupNoDrmMessage} from "./ClientDrmSetupNoDrmMessage";

export function ClientDrmSetupIndex(props) {
    let id = props.clientDrmSetup ? props.clientDrmSetup.id : null,
        Form = createForm(ClientDrmSetupModel, ClientDrmSetupForm, {
            clientId: props.clientId,
            id: id,
        });

    let message = id
        ? null
        : (<ClientDrmSetupNoDrmMessage/>);

    return (
        <React.Fragment>
            {message}
            <Form />
        </React.Fragment>
    );
}

ClientDrmSetupIndex.propTypes = {
    clientDrmSetup: PropTypes.shape({
        id: PropTypes.string,
    }),
    clientId: PropTypes.number.isRequired,
};

export default React.memo(ClientDrmSetupIndex);
