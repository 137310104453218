import {Portal, Segment} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

import {removeInfoBox, useState as InfoBoxUseState} from '@reducers/InfoBoxes';

const InfoBox = ({content, type, marginMultiple, id, header}) => {
    let timeoutHandler = null;

    const removeBox = () => {
        removeInfoBox(id);
        clearTimeout(timeoutHandler);
    };

    if ('success' === type) {
        timeoutHandler = setTimeout(() => {
            removeInfoBox(id);
        }, 10000);
    }

    return (
        <Portal open={true}>
            <Segment
                className={`ui message InfoBox ${type}`}
                style={{
                    top: `${(marginMultiple*70 + 10)}px`,
                }}
            >
                {
                    ('error' === type) && <span className={'InfoBox__closeItem'} onClick={removeBox}>x</span>
                }
                {header && <b>{header}<br/></b>}
                {content}
            </Segment>
        </Portal>
    );
};

InfoBox.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    header: PropTypes.string,
    type: PropTypes.string,
    marginMultiple: PropTypes.number,
    id: PropTypes.string,
};

const InfoBoxes = () => {
    const boxesToRender = [];

    let infoBoxes = InfoBoxUseState((state) => (state)),
        counter = 0;

    if (!infoBoxes) {
        infoBoxes = {};
    }

    for (const box of Object.values(infoBoxes)) {
        boxesToRender.push(
            <InfoBox
                key={box.name}
                id={box.name}
                header={box.header}
                content={box.content}
                type={box.type}
                marginMultiple={counter}
            />
        );

        counter++;
    }

    return (
        <div>
            {boxesToRender}
        </div>
    );
};

export default InfoBoxes;
