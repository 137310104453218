import PropTypes from 'prop-types';
import React from 'react';

import Link from '@appComponents/Link';

export const cidrOverlapDetailsRenderer = (data) => {
    return data.map((element) => {
        const {cidr, description, client: {id, name}} = element;
        const linkText = `${name} - ${cidr} (${description})`;

        return (
            <li key={element.id}>
                {
                    <Link
                        newWindow={true}
                        name={'clients.whitelistedIps.edit'}
                        params={{id: id, whitelistedIpId: element.id}}
                    >
                        {linkText}
                    </Link>
                }
            </li>
        );
    });
};
cidrOverlapDetailsRenderer.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        cidr: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        client: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }),
};
