import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {LICENSOR} from '@constants/resources';
import {CreateLicensor, DeleteLicensor, UpdateLicensor} from '@graphql/licensor/mutation';
import {GetLicensorForForm} from '@graphql/licensor/query';
import {GetCountries} from '@graphql/country/query';
import {generateContactInformationFieldsByDataMapKey} from '@utils/fieldsGenerator';

import {renderFormErrorMessages, renderTitle} from './helpers/licensorModel';
import validate from './validator';

export default function LicensorModel() {
    return {
        entityDataMapKey: 'licensor',
        entityLabel: 'Licensor',
        formName: 'licensor',
        label: 'Licensor',
        resources: LICENSOR,
        showChangelog: true,
        validate: validate,
        title: renderTitle,
        dataMap: {
            licensor: {
                id : '',
                name: '',
                country_id: null,
                address: '',
                email_address: '',
                phone_number: '',
                fax_number: '',
                vat_number: '',
            },
        },
        messages: {
            boxName: {
                delete: 'licensorMessage',
                error: 'licensorMessageError',
                success: 'licensorMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: renderFormErrorMessages,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'licensor.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'licensor.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            address: {
                dataMapKey: 'licensor.address',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Address',
                    name: 'address',
                    rows: 2,
                },
            },
            ...generateContactInformationFieldsByDataMapKey('licensor'),
        },
        mutation: {
            createEntity: CreateLicensor,
            deleteEntity: DeleteLicensor,
            updateEntity: UpdateLicensor,
        },
        mutationOptions: {},
        query: {
            getEntity: GetLicensorForForm,
            getOptions: GetCountries,
        },
    };
}
