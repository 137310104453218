import ClientAvApiKeyModel from "./ClientAvApiKeyModel";

import {SAVED} from "../../app/constants/messages";

export default function ClientAvApiKeyModelEdit() {
    let clientAvApiKeyModelEdit = ClientAvApiKeyModel();

    clientAvApiKeyModelEdit.title = () => ('Edit API key');
    clientAvApiKeyModelEdit.messages.text.SAVED = () => SAVED('API key');

    delete clientAvApiKeyModelEdit.fields.environment;

    return clientAvApiKeyModelEdit;
}
