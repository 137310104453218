import {
    find as _find,
    get as _get,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';
import {
    SECURITY_PRIVILEGES_UPDATE,
    TERRITORY_SELECTION_TYPE_BLACKLIST,
    TERRITORY_SELECTION_TYPE_WHITELIST,
} from '@constants/variables';
import {GetTournamentCategoriesFilteredBySports} from '@graphql/tournamentCategory/query';

import client from '../../../apolloClient';

class CategoryForm extends DefaultForm {
    renderDeleteButton = () => null

    getContentTiersOptions  = (contentTiers, lcrChannel, value) => {
        const lcrChannelSports = _find(lcrChannel.lcr_channel_sports, (lcrChannelSport) => (lcrChannelSport.sport.id === value)),
            restrictedTiersId = lcrChannelSports ? lcrChannelSports.content_tiers.map((contentTier) => contentTier.id) : [],
            sportSelectionTypeId = lcrChannel.sport_selection_type.id;

        let options = contentTiers;

        if(TERRITORY_SELECTION_TYPE_WHITELIST === convertToInt(sportSelectionTypeId)) {
            options = contentTiers.filter(
                (contentTier) => (0 <= restrictedTiersId.indexOf(contentTier.id))
            );
        }

        if(TERRITORY_SELECTION_TYPE_BLACKLIST === convertToInt(sportSelectionTypeId)) {
            options = contentTiers.filter(
                (contentTier) => (-1 === restrictedTiersId.indexOf(contentTier.id))
            );
        }

        return options;
    }

    prepareDataForSubmit(data) {
        return {...data,
            lcr_channel: this.props.formParams.optionsVariables.lcrId,
            id: this.props.formParams.id,
        };
    }

    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelTournamentCategoryContent');
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelTournamentCategoryContent');
        });

        if (this.props.formParams.id) {
            this.setField('sport', {disabled: true});
        }

        this.setValuesParsers({
            'content_tiers': (data) => (
                data ? data.map((contentTier) => {
                    return contentTier.id ? contentTier.id : contentTier;
                }) : []
            ),
        });

        this.setField(['tournament_category', 'content_tiers'], {
            disabled: true,
        });

        this.setOnChangeCallback({
            'sport': (data) => {
                this.setField('sport', {disabled: true});
                this.setField('tournament_category', {loading: true});

                client.query({
                    query: GetTournamentCategoriesFilteredBySports,
                    variables: {
                        sports: [convertToInt(data.value)],
                    },
                }).then((response) => {
                    const lcrChannel = _get(this.props, 'GraphQLOptionsData.lcrChannel', {}),
                        restrictedCategories = lcrChannel.lcr_channel_tournament_categories.map(
                            (category) => category.tournament_category.id
                        ),
                        tournamentCategoriesOptions = response.data.tournamentCategories.filter(
                            (category) => (-1 === restrictedCategories.indexOf(category.value))
                        );

                    this.setField('tournament_category',{options: tournamentCategoriesOptions});
                    this.setField('content_tiers', {
                        options: this.getContentTiersOptions(
                            _get(this.props, 'GraphQLOptionsData.contentTiers', []),
                            lcrChannel,
                            data.value
                        )}
                    );

                }).finally(() => {
                    this.setField(['sport', 'tournament_category', 'content_tiers'], {disabled: false, loading: false});
                });
            },
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelTournamentCategoryContent');
        });
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const lcrChannel = _get(nextProps, 'GraphQLOptionsData.lcrChannel', null);

        let options = [];

        if (lcrChannel) {
            const sportSelectionType = _get(lcrChannel, 'sport_selection_type.id', null);

            switch (convertToInt(sportSelectionType)) {
                case TERRITORY_SELECTION_TYPE_WHITELIST:
                    options = _get(lcrChannel, 'lcr_channel_sports', []).map((lcrChannelSport) => lcrChannelSport.sport);
                    break;
                case TERRITORY_SELECTION_TYPE_BLACKLIST: {
                    const contentTiers = _get(nextProps, 'GraphQLOptionsData.contentTiers', []),
                        lcrChannelSportBlacklist = [];

                    _get(lcrChannel, 'lcr_channel_sports', []).forEach((lcrChannelSport) => {
                        if (contentTiers.length === lcrChannelSport.content_tiers.length) {
                            lcrChannelSportBlacklist.push(lcrChannelSport.sport.id);
                        }
                    });

                    options = _get(nextProps, 'GraphQLOptionsData.sports', []).filter(
                        (sport) => (-1 === lcrChannelSportBlacklist.indexOf(sport.id))
                    );
                }
                    break;
                default:
                    options = _get(nextProps, 'GraphQLOptionsData.sports', []);
                    break;
            }

            this.setField('sport', {
                options,
            });

            if (this.props.formParams.id) {
                const tournamentCategory = _get(nextProps, 'GraphQLEntityData.lcrChannelTournamentCategory.tournament_category', {});

                this.setField('tournament_category', {
                    options: [{
                        id: tournamentCategory.id,
                        text: tournamentCategory.name,
                        value: tournamentCategory.id,
                        key: tournamentCategory.id,
                    }],
                    defaultValue: tournamentCategory.id,
                });

                this.setField('content_tiers', {
                    options: this.getContentTiersOptions(
                        _get(nextProps, 'GraphQLOptionsData.contentTiers', []),
                        lcrChannel,
                        _get(nextProps, 'GraphQLEntityData.lcrChannelTournamentCategory.tournament_category.sport.id', null)
                    ),
                    disabled: false,
                });
            }
        }
    }

    customAuthorization = (userResourcePrivilages) => (
        (userResourcePrivilages & SECURITY_PRIVILEGES_UPDATE) > 0
    )
}

export default CategoryForm;
