import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {isEmpty as _isEmpty} from 'lodash';
import {useQuery} from '@apollo/client';

import {GetCountrySubdivisionsForDropdown} from '@graphql/countrySubdivision/query';
import Form from '@appComponents/ReduxFormControls';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {showModal} from '@utils/modal';
import {renderCountryModalError} from '@modules/country/utils/utils';

const CountryPostalCodesFilters = ({
    filters,
    loading,
    additionalFilterId,
    restoreFilterValues,
}) => {
    const {
        data: {
            country = { country_subdivisions: [] },
        } = {},
        error: errorFetchingCountrySubdivisionData,
        loading: isCountrySubdivisionDataLoading = true,
    } = useQuery(GetCountrySubdivisionsForDropdown,
        {
            variables: {
                countryId: additionalFilterId,
            },
        }
    );

    const BASE_FILTER_NAME = 'PostalCodesWithBlackoutZones',
        FILTER_NAME = BASE_FILTER_NAME + additionalFilterId,
        isLoading = loading || isCountrySubdivisionDataLoading;

    if (!_isEmpty(errorFetchingCountrySubdivisionData)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(errorFetchingCountrySubdivisionData.message),
        });
    }

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={isLoading}
                setValue={ restoreFilterValues(filters[FILTER_NAME], 'search', '') }
            />
            <Field
                component={Form.ReduxSelect}
                disabled={isLoading}
                loading={isLoading}
                multiple
                name='country_subdivision'
                options={country.country_subdivisions}
                placeholder='Subdivision'
                selection
                search
                setValue={ restoreFilterValues(filters[FILTER_NAME], 'country_subdivision') }
            />
        </div>
    );
};

CountryPostalCodesFilters.propTypes = {
    filters: PropTypes.shape({
        PostalCodesWithBlackoutZones: PropTypes.object.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    additionalFilterId: PropTypes.number.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

export default FiltersWrapper(CountryPostalCodesFilters, {name: 'PostalCodesWithBlackoutZones', initialValues: {}});
