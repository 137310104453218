import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {EVENT_BOOKING} from '@constants/resources';
import validate from '@modules/events/forms/validatorEventsEventBookings';

export default function EventBookingClipsModel() {
    return {
        entityDataMapKey: 'eventEventBooking',
        entityLabel: 'event booking',
        formName: 'EventBookingClips',
        label: 'Event Booking',
        editForm: false,
        resources: EVENT_BOOKING,
        validate: validate,
        showChangeLog: true,
        title: null,
        dataMap : {
            eventBooking: {
                id: '',
                clips: null,
            },
        },
        messages: {
            boxName: {
                delete: 'event',
                success: 'event',
                error: 'formInnerErrorMessage',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventEventBooking.event_booking_event_content_types.id',
                props: {
                    label: '',
                    name: 'id',
                },
            },
            clips: {
                props: {
                    label: 'Clips',
                    component: Form.SemanticCheckboxTree,
                    required: true,
                    name: 'clips',
                    hidden: false,
                    allowChildless: true,
                },
            },
        },
        query: {
            getOptions: null,
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            update: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            delete: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
        },
    };
}
