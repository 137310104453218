import Form from '@appComponents/ReduxFormControls';

export const adSupportSCTEField = (fieldName) => (
    {
        component: Form.SemanticInput,
        label: 'Ad support (SCTE-35)',
        name: fieldName,
        type: 'toggle',
    }
);

export const adFeedSpliceInformationField = (fieldName) => (
    {
        component: Form.SemanticInput,
        label: 'Feed splice information (SCTE-104)',
        name: fieldName,
        type: 'toggle',
    }
);

export const adSpliceConfigField = (fieldName) => (
    {
        allowClear: true,
        component: Form.SemanticSelect,
        label: 'Ad splice config',
        name: fieldName,
        placeholder: 'Select ad splice',
        required: true,
        hidden: true,
    }
);
