import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {
    PROPERTY_LICENCE_TYPE_OTHER,
    PROPERTY_LICENCE_TYPE_SPORTRADAR,
} from '@constants/variables';
import Form from '@appComponents/ReduxFormControls';

import validate from './validatePropertyLicenceEventsFilters';

export class PropertyLicenceEventsFiltersComponent extends React.Component {
    static propTypes = {
        contentCategoriesLevel3: PropTypes.array,
        data: PropTypes.object.isRequired,
        eventStatuses: PropTypes.array,
        filters: PropTypes.object.isRequired,
        form: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        originStreamStatuses: PropTypes.array,
        products: PropTypes.array,
        propertyLicenceTypeId: PropTypes.number,
        tournaments: PropTypes.array,
        restoreFilterValues: PropTypes.func.isRequired,
        issueTypes: PropTypes.array,
    };

    static defaultProps = {
        contentCategoriesLevel3: [],
        eventStatuses: [],
        originStreamStatuses: [],
        products: [],
        tournaments: [],
        issueTypes: [],
    };

    constructor() {
        super();

        this.state = {
            fromMaxDate: null,
            fromMaxDateDefault: null,
            fromMaxTime: null,
            toMinDate: null,
            toMinDateDefault: null,
            toMinTime: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        let fromMaxDate = nextProps.restoreFilterValues(nextProps.filters.PropertyLicenceEvents, 'to', null),
            toMinDate = nextProps.restoreFilterValues(nextProps.filters.PropertyLicenceEvents, 'from', null),
            nextState = {};

        fromMaxDate = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
        toMinDate = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);

        if (fromMaxDate && !moment(this.state.fromMaxDateDefault).isSame(fromMaxDate)) {
            nextState.fromMaxDateDefault = fromMaxDate;
            nextState.fromMaxDate = fromMaxDate;
        }

        if (toMinDate && !moment(this.state.toMinDateDefault).isSame(toMinDate)) {
            nextState.toMinDateDefault = toMinDate;
            nextState.toMinDate = toMinDate;
        }

        if (
            nextState.fromMaxDateDefault &&
            nextState.toMinDateDefault &&
            nextState.fromMaxDateDefault.format('YYYY-MM-DD') === nextState.toMinDateDefault.format('YYYY-MM-DD')
        ) {
            nextState.fromMaxTime = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
            nextState.toMinTime = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);
        }

        this.setState(() => (nextState));
    }

    onChange = (date, name) => {
        let nextState = {};

        nextState.fromMaxTime = null;
        nextState.toMinTime = null;

        if ('from' === name) {
            nextState.toMinDate = date;

            if (date.format('YYYY-MM-DD') === this.state.fromMaxDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = this.state.fromMaxDate;
                nextState.toMinTime = date;
            }
        } else if ('to' === name) {
            nextState.fromMaxDate = date;

            if (date.format('YYYY-MM-DD') === this.state.toMinDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = date;
                nextState.toMinTime = this.state.toMinDate;
            }
        }

        this.setState(() => (nextState));
    };

    render() {
        const {
                contentCategoriesLevel3,
                eventStatuses,
                originStreamStatuses,
                products,
                propertyLicenceTypeId,
                tournaments,
                issueTypes,
            } = this.props.data,
            loading = this.props.loading;

        return (
            <div className='propertyLicenceEvents__filters filter__controls__container'>
                <Field
                    component={Form.ReduxInput}
                    name='search'
                    placeholder='Search...'
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'search')}
                />
                <Field
                    component={Form.ReduxInput}
                    className='small'
                    disabled={loading}
                    name='from'
                    placeholder='From...'
                    setValue={this.props.restoreFilterValues(
                        this.props.filters.PropertyLicenceEvents,
                        'from',
                        moment().subtract(7, 'd').format('YYYY-MM-DD 00:00')
                    )}
                    type='date'
                    configuration={{
                        onChange: this.onChange,
                        maxDate: this.state.fromMaxDate || this.state.fromMaxDateDefault,
                        maxTime: this.state.fromMaxTime || moment().hours(23).minutes(59),
                        minTime: moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    }}
                />
                <Field
                    component={Form.ReduxInput}
                    className='small'
                    disabled={loading}
                    name='to'
                    placeholder='To...'
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'to')}
                    type='date'
                    configuration={{
                        onChange: this.onChange,
                        minDate: this.state.toMinDate || this.state.toMinDateDefault,
                        maxTime: moment().hours(23).minutes(59),
                        minTime: this.state.toMinTime || moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    }}
                />
                <Field
                    component={Form.ReduxSelect}
                    hidden={propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_SPORTRADAR}
                    className='medium'
                    loading={loading}
                    multiple
                    name='tournament'
                    options={tournaments}
                    placeholder='Tournament'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'tournament')}
                />
                <Field
                    component={Form.ReduxSelect}
                    hidden={propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_OTHER}
                    className='medium'
                    loading={loading}
                    multiple
                    name='contentCategoryLevel3'
                    options={contentCategoriesLevel3}
                    placeholder='3rd level category'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'contentCategoryLevel3')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='eventStatus'
                    options={eventStatuses}
                    placeholder='Event status'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'eventStatus')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='product'
                    options={products}
                    placeholder='Product'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'product')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='originStreamStatus'
                    options={originStreamStatuses}
                    placeholder='Origin stream status'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'originStreamStatus')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='issueTypes'
                    options={issueTypes}
                    placeholder='Issue types'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicenceEvents, 'issueTypes')}
                />
            </div>
        );
    }
}

const PropertyLicenceEventsFilters = FiltersWrapper(PropertyLicenceEventsFiltersComponent, {
    name: 'PropertyLicenceEvents',
    validate,
    initialValues: {
        from: moment().subtract(7, 'd').format('YYYY-MM-DD 00:00'),
    },
});

export default PropertyLicenceEventsFilters;
