import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));

    return errors;
};

export default validate;
