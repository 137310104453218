import React from 'react';
import PropTypes from 'prop-types';
import {Header, Loader} from 'semantic-ui-react';

class Timezone extends React.PureComponent {
    static propTypes = {
        timezone: PropTypes.string,
    };

    renderTimezone = () => {
        return this.props.timezone ? '(' + this.props.timezone + ')' : <Loader active inline='centered' size="mini" />;
    };

    render() {
        return (
            <Header as="h5" className="page-header__widget page-header__widget--timezone">
                {this.renderTimezone()}
            </Header>
        );
    }
}

export default Timezone;
