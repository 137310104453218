import React from 'react';

import {IconInfoCircle} from '@appComponents/IconCollection';
import {GetEventBookingForForm} from '@graphql/eventBooking/query';
import {UpdateBooking, DeleteBooking} from '@graphql/booking/mutation';

import EventBookingGeoRestrictionsGeneralInformationModel from './EventBookingGeoRestrictionsGeneralInformationModel';

export default function EventBookingGeoRestrictionsGeneralInformationEditModel() {
    const eventBookingGeoRestrictionsGeneralInformationEditModel = EventBookingGeoRestrictionsGeneralInformationModel();

    eventBookingGeoRestrictionsGeneralInformationEditModel.fields.client_package.props.description =
        <IconInfoCircle tooltip={'If you modify the client package of an autobooked booking ' +
        'for a content with a start time greater than one hour ago, it may be changed automatically again if ' +
        'the Autobooker is triggered for this booking.'}/>;

    eventBookingGeoRestrictionsGeneralInformationEditModel.deleteId = 'booking.id';
    eventBookingGeoRestrictionsGeneralInformationEditModel.editForm = true;
    eventBookingGeoRestrictionsGeneralInformationEditModel.mutation = {
        updateEntity: UpdateBooking,
        deleteEntity: DeleteBooking,
    };
    eventBookingGeoRestrictionsGeneralInformationEditModel.query.getEntity = GetEventBookingForForm;

    return eventBookingGeoRestrictionsGeneralInformationEditModel;
}
