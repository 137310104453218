import PropTypes from 'prop-types';
import React from 'react';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {CLIENT_WHITELISTED_IP} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

export const WhitelistedIpIndexButtons = ({client}) => (
    <Authorization authorization={{[CLIENT_WHITELISTED_IP]: SECURITY_PRIVILEGES_CREATE}}>
        <Link name={'clients.whitelistedIps.geoRestrictions.add'} params={{id: client}}>
            <div className='content__buttons'>
                <ButtonAdd>Add new CIDR</ButtonAdd>
            </div>
        </Link>
    </Authorization>
);

WhitelistedIpIndexButtons.propTypes = {
    client: PropTypes.number.isRequired,
};

export default WhitelistedIpIndexButtons;
