const validate = (values) => {
    const errors = {};

    if (values.is_default && values.is_disabled) {
        Object.assign(errors, {is_disabled: "A default encoding job profile cannot be disabled"});
    }

    return errors;
};

export default validate;
