import PropTypes from 'prop-types';
import React from 'react';
import {Popup} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {IconDownload, IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {convertToInt} from '@utils/helpers';
import {SLATE} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: true,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};

const SlatesTable = (props) => {
    const {slates, loading, propertyLicenceId} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'name':
                return (
                    <LinkWithAuthorization
                        resources={SLATE}
                        privileges={SECURITY_PRIVILEGES_READ}
                        link={{
                            name: 'propertyLicences.technicalSetup.slates.edit',
                            params: {
                                id: propertyLicenceId,
                                slateId: convertToInt(row.id),
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'actions': {
                return (
                    <div className='actions-list-icon'>
                        <IconEdit
                            link={getLink('propertyLicences.technicalSetup.slates.edit', {
                                id: propertyLicenceId,
                                slateId: convertToInt(row.id),
                            })}
                            size='large'
                            resource={SLATE}
                        />
                        <Authorization
                            privileges={SECURITY_PRIVILEGES_READ}
                            resources={SLATE}
                        >
                            <Popup
                                content='Download the SVG file'
                                position='left center'
                                trigger={
                                    <a
                                        href={`data:application/octet-stream;base64,${window.btoa(row.svg)}`}
                                        download={`slate_${row.id}.svg`}
                                    >
                                        <IconDownload size='large'/>
                                    </a>
                                }
                            />
                        </Authorization>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className='slates__table'>
            <Table
                name='slatesList'
                columns={columns}
                data={slates}
                loading={loading}
                noDataText='No slates found'
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

SlatesTable.propTypes = {
    propertyLicenceId: PropTypes.number,
    slates: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

SlatesTable.defaultProps = {
    slates: [],
};

export default SlatesTable;
