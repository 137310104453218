import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dimmer, Loader} from 'semantic-ui-react';
import {useParams} from 'react-router-dom';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {ContentRestrictionButtons} from '@modules/lcr/components/ContentRestrictionAddButton';
import TerritorySelectionModel from '@modules/lcr/forms/TerritorySelectionModel';
import TerritorySelectionForm from '@modules/lcr/forms/TerritorySelectionForm';
import {ContentRestrictionTable} from '@modules/lcr/components/ContentRestrictionTable';
import {createForm} from '@utils/forms';
import {convertToInt, uppercaseFirstLetter} from '@utils/helpers';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from '@constants/variables';

export function ContentRestriction({
    loading: restrictionLoading,
    territorySelection,
    territorySelectionOptions,
    type,
}) {
    const [selectedType, setSelectedType] = useState(territorySelection),
        {id: lcrChannelId} = useParams();

    const ContentRestrictionForm = React.useMemo(
        () => (
            createForm(TerritorySelectionModel(type), TerritorySelectionForm, {
                id: lcrChannelId,
                setSelectedType,
                dataRequest: false,
            })),
        ['territorySelection']
    );

    return (
        <>
            <HeaderRenderer
                messagesBoxNames={`lcrChannelContentRestriction-${type}`}
                pageTitle={uppercaseFirstLetter(type)}
            />
            {
                restrictionLoading && (<Dimmer inverted active={restrictionLoading}>
                    <Loader/>
                </Dimmer>)
            }
            {
                !restrictionLoading && (<ContentRestrictionForm
                    setSelectedType={setSelectedType}
                    territorySelectionOptions={territorySelectionOptions}
                    territorySelection={territorySelection}
                />)
            }
            {!(null === selectedType
                || TERRITORY_SELECTION_TYPE_WORLDWIDE === convertToInt(selectedType)
            ) && (<div>
                <div className={'ContentRestrictionButtonsContainer'}>
                    <ContentRestrictionButtons id={lcrChannelId} type={type} />
                </div>
                <div className='--paddingTop-10px --relative'>
                    <ContentRestrictionTable
                        type={type}
                        lcrChannelId={lcrChannelId}
                    />
                </div>
            </div>)}
        </>
    );
}

ContentRestriction.propTypes = {
    loading: PropTypes.bool,
    territorySelection: PropTypes.string,
    territorySelectionOptions: PropTypes.array,
    type: PropTypes.string.isRequired,
};

ContentRestriction.displayName = 'ContentRestriction';

export default ContentRestriction;
