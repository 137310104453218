import PropTypes from "prop-types";

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_third_party_pull_origins`,
    parseData: thirdPartyPullOrigin => [
        thirdPartyPullOrigin.id,
        thirdPartyPullOrigin.name,
        thirdPartyPullOrigin.encoding_datacenter.name,
        thirdPartyPullOrigin.encoding_job_profile.name,
    ],
    path: 'clients.products.thirdParty.pullOrigins.index.path',
    titles: [
        "ID",
        "Name",
        "Encoding datacenter",
        "Encoding job profile",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired
};
