import PropTypes from 'prop-types';
import React from 'react';
import {Segment} from "semantic-ui-react";
import {withRouter} from "react-router";

import RightsScopeGeoRestrictionForm from "../forms/RightsScopeGeoRestrictionForm";
import RightsScopeGeoRestrictionModel from "../forms/RightsScopeGeoRestrictionModel";

import {convertToInt} from "../../../utils/helpers";
import {createForm} from "../../../utils/forms";

const RightsScopeGeoRestrictionsEdit = (props) => {
    const {match} = props;

    const renderForm = () => {
        const Form = createForm(
            RightsScopeGeoRestrictionModel,
            RightsScopeGeoRestrictionForm, {
                dataRequest: true,
                id: convertToInt(match.params.rightsScopeId),
            });

        return <Form/>;
    };

    return (
        <div className="property_licence_rights_scope_geo_restrictions">
            <Segment basic>
                {renderForm()}
            </Segment>
        </div>
    );
};

RightsScopeGeoRestrictionsEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            rightsScopeId: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(RightsScopeGeoRestrictionsEdit)
