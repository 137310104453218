import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";
import moment from "moment";
import {
    get as _get
} from "lodash"

import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";
import Form from "../../app/components/ReduxFormControls";

let ChangelogFiltersComponent = (props) => {
    let loading = props.loading;

    return (
        <div className="changelog__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                className="--minimalWidth --datetimeWithoutSeconds"
                name="startDatetime"
                placeholder="From..."
                disabled={loading}
                type="date"
                configuration={{
                    timeFormat: "HH:mm",
                    showTimeSelect: true,
                    calendarClassName: "filterDatepicker",
                    shouldCloseOnSelect: false,
                }}
                defaultValue={moment().format("YYYY-MM-DD 00:00")}
                setValue={props.restoreFilterValues(
                    props.filters.Changelog, 'startDatetime', moment().format("YYYY-MM-DD 00:00")
                )}
            />
            <Field
                component={Form.ReduxInput}
                className="--minimalWidth --datetimeWithoutSeconds"
                name="endDatetime"
                placeholder="To..."
                type="date"
                configuration={{
                    timeFormat: "HH:mm",
                    showTimeSelect: true,
                    calendarClassName: "filterDatepicker",
                    shouldCloseOnSelect: false,
                }}
                disabled={loading}
                defaultValue={moment().add(1, 'days').format("YYYY-MM-DD 00:00")}
                setValue={props.restoreFilterValues(
                    props.filters.Changelog, 'endDatetime', moment().add(1, 'days').format("YYYY-MM-DD 00:00")
                )}
            />
            <Field
                component={Form.ReduxSelect}
                name="resources"
                options={_get(props, "data.securedResources", [])}
                placeholder="Resource"
                disabled={loading}
                search={true}
                multiple={true}
                setValue={props.restoreFilterValues(props.filters.Changelog, 'resources')}
            />
            <Field
                component={Form.ReduxInput}
                name="elementId"
                placeholder="Element ID"
                disabled={loading}
                setValue={props.restoreFilterValues(
                    props.filters.Changelog, 'elementId'
                )}
            />
            <Field
                component={Form.ReduxInput}
                name="elementName"
                placeholder="Element name"
                disabled={loading}
                setValue={props.restoreFilterValues(
                    props.filters.Changelog, 'elementName'
                )}
            />
            <Field
                component={Form.ReduxSelect}
                name="actions"
                placeholder="Action"
                options={_get(props, "data.changelogActionTypes", [])}
                disabled={loading}
                multiple={true}
                setValue={props.restoreFilterValues(props.filters.Changelog, 'actions')}
            />
            <Field
                component={Form.ReduxSelect}
                name="users"
                placeholder="User"
                disabled={loading}
                search={true}
                options={_get(props, "data.changelogUsers", [])}
                multiple={true}
                setValue={props.restoreFilterValues(props.filters.Changelog, 'users')}
            />
        </div>
    )
};

ChangelogFiltersComponent.propTypes = {
    filters: PropTypes.shape({
        Changelog: PropTypes.object,
    }),
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object,
    restoreFilterValues: PropTypes.func
};

const ChangelogFilters = FiltersWrapper(ChangelogFiltersComponent, {name: "Changelog"});

export default ChangelogFilters
