import PropTypes from 'prop-types';
import React from 'react';

import {renderModalError} from '@utils/forms';
import {CONTACT_PERSON, PRODUCTION_COMPANY, RTMP_ORIGIN_STREAM_NAME} from '@constants/resources';
import {routes} from '@constants/routes';

import ContactPersonIndex from '../views/ContactPersonIndex';
import ProductionCompanyEdit from '../views/ProductionCompanyEdit';
import RtmpOriginStreamNameIndex from '../views/RtmpOriginStreamNameIndex';

export const createTabs = (id, path) => {
    const contactPersonsRoutes = routes.productionCompanies.contactPersons,
        rtmpOriginStreamNamesRoutes = routes.productionCompanies.rtmpOriginStreamNames;

    return (routes.productionCompanies.add.path === path)
        ? {
            [routes.productionCompanies.add.path]: {
                key: routes.productionCompanies.add.path,
                url: 'productionCompanies.add',
                text: 'General information',
                render: <ProductionCompanyEdit/>,
                resource: PRODUCTION_COMPANY,
            },
        }
        : {
            [routes.productionCompanies.edit.path]: {
                key: routes.productionCompanies.edit.path,
                url: 'productionCompanies.edit',
                urlParams: {id: id},
                text: 'General information',
                render: <ProductionCompanyEdit/>,
                resource: PRODUCTION_COMPANY,
            },
            [contactPersonsRoutes.index.path]: {
                key: contactPersonsRoutes.index.path,
                url: 'productionCompanies.contactPersons.index',
                urlParams: {id: id},
                text: 'Contact persons',
                render: <ContactPersonIndex/>,
                resource: CONTACT_PERSON,
            },
            [rtmpOriginStreamNamesRoutes.index.path]: {
                key: rtmpOriginStreamNamesRoutes.index.path,
                url: 'productionCompanies.rtmpOriginStreamNames.index',
                urlParams: {id: id},
                text: 'RTMP origin stream names',
                render: <RtmpOriginStreamNameIndex/>,
                resource: RTMP_ORIGIN_STREAM_NAME,
            },
        };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createProductionCompanyTabs';

export const renderProductionCompanyModalError = () => {
    return renderModalError('Production company', routes.productionCompanies.index.path);
};
renderModalError.displayName = 'renderProductionCompanyModalError';
