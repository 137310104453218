import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import mapModulesToProps from '@utils/mapModulesToProps';
import {PackagesForTableQuery} from '@graphql/clientPackage/query';

import PackagesIndexButtons from '../components/PackagesIndexButtons';
import PackagesTable from '../components/PackagesTable';
import {exportExcel} from '../utils/export/packagesIndexExcel';

export class PackagesIndex extends React.Component {
    static propTypes = {
        DataPackages: PropTypes.object.isRequired,
        clientId: PropTypes.number,
    };

    constructor() {
        super();

        this.state = {};
    }

    static getDerivedStateFromProps(nextProps) {
        const {DataPackages: {packages}} = nextProps;

        if (packages) {
            return {packages: packages};
        }

        return null;
    }

    render() {
        return (
            <div>
                <HeaderRenderer
                    buttons={PackagesIndexButtons}
                    buttonsProps={{clientId: this.props.clientId}}
                    exportExcelParams={exportExcel(
                        this.state.packages,
                        this.props.clientId
                    )}
                    loading={this.props.DataPackages.loading}
                />
                <PackagesTable
                    clientId={this.props.clientId}
                    packages={this.state.packages}
                    loading={this.props.DataPackages.loading}
                    editClick={this.openModal}
                />
            </div>
        );
    }
}

const PackagesWithGraphQL = compose(
    graphql(PackagesForTableQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    clientId: [props.clientId],
                },
            };
        },
        name: 'DataPackages',
    })
)(PackagesIndex);

const mapDispatchToProps = mapModulesToProps(['MessageBox']);

export default connect(null, mapDispatchToProps)(PackagesWithGraphQL);
