import {get as _get, has as _has} from "lodash";
import React from "react";

import {convertToInt} from "../../../../utils/helpers";

import {SPORT_BADMINTON, SPORT_TENNIS} from "../../../app/constants/variables";

export const StadiumField = (props) => {
    const sportId = _get(
        props, "data.match.tournament.tournament_category.sport.id",
        _get(
            props, "data.event.sr_event.match.tournament.tournament_category.sport.id",
            _get(props, "data.event.sr_event.tournament.tournament_category.sport.id", false)
        )
    );
    const label = [SPORT_BADMINTON, SPORT_TENNIS].includes(convertToInt(sportId)) ? 'Court' : 'Venue';

    let stadiumName = '---',
        cityName = false;

    if (_has(props, 'data.match.stadium.id')) {
        stadiumName = _get(props, "data.match.stadium.name", "---");
        cityName = _get(props, 'data.match.stadium.city.name', false);
    } else if (_has(props, 'data.event.sr_event.match.stadium.id')) {
        stadiumName = _get(props, "data.event.sr_event.match.stadium.name", "---");
        cityName = _get(props, 'data.event.sr_event.match.stadium.city.name', false);
    } else if (_has(props, 'data.event.sr_event.stadium.id')) {
        stadiumName = _get(props, "data.event.sr_event.stadium.name", "---");
        cityName = _get(props, 'data.event.sr_event.stadium.city.name', false);
    }

    if (sportId) {
        return (
            <div>
                <label>{label}</label>
                <span>{stadiumName + (cityName ? ` - ${cityName}` : '')}</span>
            </div>
        );
    }

    return null;
};
