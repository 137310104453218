import React from "react";
import PropTypes from "prop-types";

import Authorization from "../../app/components/Authorization";
import {IconDelete} from "../../app/components/IconCollection";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import Table from "../../app/components/Table";
import {ENCODING_TARGET, THIRD_PARTY_CLIENT_VOD_STORAGE} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ_UPDATE, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';

const authorizationEncodingTargetReadUpdateObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ_UPDATE,
};

const columns = {
    "id": {
        isHidden: true,
    },
    "client": {
        label: "Client",
        sorting: (row) => (row.client.name),
    },
    "name": {
        label: "Name",
        sorting: true,
    },
    "type": {
        label: "Type",
        sorting: true,
    },
    "encodingTarget": {
        label: "Encoding target",
        sorting: true,
        header: {
            align: "center",
        },
        content: {
            align: "center",
        },
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
};

const EventsConfigurationThirdPartyClientVodStoragesTable = (props) => {
    const {data, loading} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "encodingTarget":
            case "type":
                return row[column];
            case "name":
                return (
                    <LinkWithAuthorization
                        link={{
                            name: "clients.products.thirdParty.vodStorages.edit",
                            params: {
                                id: row.client.id,
                                thirdPartyClientVodStorageId: row.id,
                            },
                        }}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        resources={THIRD_PARTY_CLIENT_VOD_STORAGE}
                    >
                        {row.name}
                    </LinkWithAuthorization>
                );
            case "client":
                return row.client.name;
            case "actions":
                return (
                    <Authorization
                        authorization={authorizationEncodingTargetReadUpdateObject}
                    >
                        <div className="actions-list-icon">
                            <IconDelete size="large" onClick={props.onDelete} onClickElement={row} />
                        </div>
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className="event_configuration_thirdy_party_client_vod_storages_table">
            <Table
                className="mini"
                columns={columns}
                data={data}
                loading={loading}
                name="eventConfigurationThirdPartyClientVodStoragesList"
                noDataText="No third party clients defined."
                noMessageBox
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    )
};

EventsConfigurationThirdPartyClientVodStoragesTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
};

EventsConfigurationThirdPartyClientVodStoragesTable.defaultProps = {
    data: [],
};

export default EventsConfigurationThirdPartyClientVodStoragesTable;
