import {
    get as _get,
    find as _find,
    filter as _filter,
    unionBy as _unionBy
} from "lodash";
import PropTypes from 'prop-types';
import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Button, Grid} from 'semantic-ui-react';

import Form from "../../app/components/ReduxFormControls";
import {FormWrapper} from "../../app/components/HOCFormWrapper";

import validate from '../forms/EventStreamConstraintsValidator';

class EventStreamConstraintsForm extends React.Component {
    static propTypes = {
        dataForForm: PropTypes.object,
        formData: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func,
        onAdd: PropTypes.func,
        onDeactivateEditableCell: PropTypes.func,
        onUpdate: PropTypes.func,
        submitting: PropTypes.bool,
        form: PropTypes.string.isRequired,
        currentProductValueAdd: PropTypes.number,
        currentProductValueUpdate : PropTypes.number,
        currentDeviceCategoryValueAdd: PropTypes.number,
        currentDeviceCategoryValueUpdate: PropTypes.number,
        renderMaxBitrateColumn: PropTypes.func,
        renderMaxPlayerSizeColumn: PropTypes.func,
    };

    constructor() {
        super();

        this.state = {
            inputRefreshTimestamp: Date.now()
        }
    }

    onFormSubmit(data) {
        if (data.id) {
            this.props.onUpdate();
            this.props.onDeactivateEditableCell();
        } else {
            this.props.onAdd().then(() => {
                this.setState(() => ({
                    inputRefreshTimestamp: Date.now()
                }))
            });
        }
    }

    deviceCategoryOptions = () => {
        let currentProduct = null;
        let alreadyUsed = null;
        let result = [];

        if (this.props.form === "form_esc_add"){
            currentProduct = parseInt(_get(this.props, "currentProductValueAdd", 0), 10);
        } else {
            currentProduct = parseInt(_get(this.props, "currentProductValueUpdate", 0), 10);
        }

        if (currentProduct){
            alreadyUsed = _filter(
                this.props.dataForForm.usedProductsAndCategories,
                (item) => {return parseInt(item.product.id, 10) === currentProduct});
        }

        this.props.dataForForm.deviceCategories.forEach((category) => {
            if (!alreadyUsed ||
                !_find(alreadyUsed,
                    (item) => parseInt(item.device_category.id, 10) === parseInt(category.id, 10)
                )){

                result.push({
                    text: category.name, value: parseInt(category.id, 10), key: "dc_" + category.id
                });
            }
        });

        if (this.props.form === "form_esc_update"
            && !_find(alreadyUsed,
                (item) => (parseInt(item.product.id, 10) === currentProduct
                    && currentProduct !== parseInt(this.props.dataForForm.eventStreamConstraints.product.id, 10)
                    && parseInt(item.device_category.id, 10) === parseInt(this.props.dataForForm.eventStreamConstraints.device_category.id, 10))
            )) {
            result = _unionBy([...result, {
                text: this.props.dataForForm.eventStreamConstraints.device_category.name,
                value: parseInt(this.props.dataForForm.eventStreamConstraints.device_category.id, 10),
                key: "dc_" + this.props.dataForForm.eventStreamConstraints.device_category.id
            }], (item) => (item.value));
        }

        return result;
    };

    productOptions = () => {
        let currentDeviceCategory = null;
        let alreadyUsed = null;
        let result = [];

        if (this.props.form === "form_esc_add"){
            currentDeviceCategory = parseInt(_get(this.props, "currentDeviceCategoryValueAdd", 0), 10);
        } else {
            currentDeviceCategory = parseInt(_get(this.props, "currentDeviceCategoryValueUpdate", 0), 10);
        }

        if (currentDeviceCategory){
            alreadyUsed = _filter(
                this.props.dataForForm.usedProductsAndCategories,
                (item) => parseInt(item.device_category.id, 10) === currentDeviceCategory);
        }

        this.props.dataForForm.products.forEach((product) => {
            if (!alreadyUsed ||
                !_find(alreadyUsed,
                    (item) => parseInt(item.product.id, 10) === parseInt(product.id, 10))){
                result.push({
                    text: product.name, value: parseInt(product.id, 10), key: "p_" + product.id
                });
            }
        });

        if (this.props.form === "form_esc_update"
            && !_find(alreadyUsed,
                (item) => (parseInt(item.device_category.id, 10) === currentDeviceCategory
                    && currentDeviceCategory !== parseInt(this.props.dataForForm.eventStreamConstraints.device_category.id, 10)
                    && parseInt(item.product.id, 10) === parseInt(this.props.dataForForm.eventStreamConstraints.product.id, 10))
            )) {
            result = _unionBy([...result, {
                text: this.props.dataForForm.eventStreamConstraints.product.name,
                value: parseInt(this.props.dataForForm.eventStreamConstraints.product.id, 10),
                key: "p_" + this.props.dataForForm.eventStreamConstraints.product.id
            }], (item) => (item.value))
        }

        return result;
    };

    renderDeviceCategoryColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                allowClear={false}
                defaultValue={this.props.onUpdate
                    ? parseInt(this.props.dataForForm.eventStreamConstraints.device_category.id, 10)
                    : this.props.currentDeviceCategoryValueAdd}
                timestamp={this.state.inputRefreshTimestamp}
                name="device_category"
                options={this.deviceCategoryOptions()}
                placeholder="Select device category"
                required
            />
        );
    };

    renderProductColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                defaultValue={this.props.onUpdate
                    ? parseInt(this.props.dataForForm.eventStreamConstraints.product.id, 10)
                    : this.props.currentProductValueAdd}
                timestamp={this.state.inputRefreshTimestamp}
                name="product"
                options={this.productOptions()}
                placeholder="Select product"
                required
            />
        );
    };

    renderMaxBitrateColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticInput}
                defaultValue={this.props.onUpdate
                    ? this.props.dataForForm.eventStreamConstraints.max_bitrate
                    : ""}
                name="max_bitrate"
                type="number"
                labelDescription={{content: 'Kbps'}}
            />
        );
    };

    renderMaxResolutionColumn = () => {
        return (
            <div className="editableTable__resolution">
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.onUpdate
                        ? this.props.dataForForm.eventStreamConstraints.max_resolution_width
                        : ""}
                    name="max_resolution_width"
                    size="small"
                    type="number"
                    labelDescription={{content: 'px'}}
                />
                <span>x</span>
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.onUpdate
                        ? this.props.dataForForm.eventStreamConstraints.max_resolution_height
                        : ""}
                    name="max_resolution_height"
                    size="small"
                    type="number"
                    labelDescription={{content: 'px'}}
                />
            </div>
        );
    };

    renderMaxPlayerSizeColumn = () => {
        return (
            <Form.FormRow
                className="max_player_size"
                component={Form.SemanticInput}
                defaultValue={this.props.onUpdate
                    ? this.props.dataForForm.eventStreamConstraints.max_player_size
                    : ""}
                name="max_player_size"
                type="number"
                min={0}
                max={100}
                labelDescription={{content: '%'}}
            />
        );
    };

    renderButtons = () => {
        if (this.props.form === "form_esc_add"){
            return this.renderButtonsForAddForm();
        } else {
            return this.renderButtonsForEditForm();
        }
    };

    renderButtonsForAddForm = () => {
        return (
            <Button
                disabled={this.props.submitting}
                icon="plus"
                size="small"
                loading={this.props.submitting}
            />
        );
    };

    renderButtonsForEditForm = () => {
        return (
            <Button.Group>
                <Button
                    data-id={this.props.dataForForm.eventStreamConstraints.id}
                    data-product={this.props.dataForForm.eventStreamConstraints.product.id}
                    data-device={this.props.dataForForm.eventStreamConstraints.device_category.id}
                    disabled={this.props.submitting}
                    icon="checkmark"
                    key="update-button"
                    loading={this.props.submitting}
                    positive
                />
                <Button
                    icon="ban"
                    key="cancel-button"
                    negative
                    onClick={this.props.onDeactivateEditableCell}
                    type="button"
                />
            </Button.Group>
        );
    };

    render() {
        return (
            <Form.Create onSubmit={this.props.handleSubmit((values) => {
                return this.onFormSubmit(values)
            })}
            >
                <Form.IdField
                    defaultValue={this.props.dataForForm.eventStreamConstraints.id}
                    name="id"
                />
                <Grid verticalAlign="top">
                    <Grid.Row>
                        <Grid.Column width={2}>
                            {this.renderProductColumn()}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.renderDeviceCategoryColumn()}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.renderMaxBitrateColumn()}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {this.renderMaxResolutionColumn()}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.renderMaxPlayerSizeColumn()}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <div className="actions-list-icon">
                                {this.renderButtons()}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form.Create>
        );
    }
}

const selectorAdd = formValueSelector('form_esc_add');
const selectorUpdate = formValueSelector('form_esc_update');

const EventStreamConstraintsWithRedux = reduxForm({
    form: 'EventStreamConstraints',
    validate
})(EventStreamConstraintsForm);

const EventStreamConstraintsWithForm = connect(state => {
    let currentProductValueAdd = parseInt(selectorAdd(state, 'product'), 10);
    let currentDeviceCategoryValueAdd = parseInt(selectorAdd(state, 'device_category'), 10);

    let currentProductValueUpdate = parseInt(selectorUpdate(state, 'product'), 10);
    let currentDeviceCategoryValueUpdate = parseInt(selectorUpdate(state, 'device_category'), 10);

    return {
        currentProductValueAdd,
        currentDeviceCategoryValueAdd,
        currentProductValueUpdate,
        currentDeviceCategoryValueUpdate
    };
})(EventStreamConstraintsWithRedux);

export default FormWrapper({
    deviceCategories: [],
    products: [],
    usedProductsAndCategories: [],
    eventStreamConstraints: {
        id: "",
        product: {
            id: "",
            name: ""
        },
        device_category: {
            id: "",
            name: ""
        },
        max_bitrate: "",
        max_player_size: "",
        max_resolution_width: "",
        max_resolution_height: ""
    }
}, EventStreamConstraintsWithForm);
