import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {gql, graphql} from 'react-apollo';
import {setModal} from '@actions/index';
import * as clientGraphQl from '@graphql/clients/index';

import ClientForm from './ClientForm';

class ClientEditForm extends React.PureComponent {
    static propTypes =  {
        data: PropTypes.object,
        Modal: PropTypes.object,
    };

    render() {
        return (
            <div>
                <ClientForm formData={this.props.data}/>
            </div>
        );
    }
}

let clientQuery = gql`${clientGraphQl.client}`;

const mapDispatchToProps = (dispatch) => {
    return {
        Modal: {
            setModal: (params) => {
                dispatch(setModal(params));
            },
        },
    };
};

export default withRouter(graphql(clientQuery, {
    options: (props) => {
        return {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: {
                id: props.match.params.id,
            },
        };
    },
})(connect(null, mapDispatchToProps)(ClientEditForm)));
