import {connect} from "react-redux";

import DefaultForm from "../../app/components/DefaultForm";

import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import {DRM_PROVIDER_BUY_DRM} from "../../app/constants/variables";

export class ClientDrmSetupForm extends DefaultForm {
    componentDidMount() {
        const entityName = "client";

        this.setCreateSuccessCallback((response) => {
            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientDrmSetup: {
                        id: response.data.createClientDrmSetup.id,
                    },
                }),
            });
        });

        this.setDeleteSuccessCallback(() => {
            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientDrmSetup: null,
                }),
            });
        });

        this.setOnChangeCallback({
            drm_provider: (data) => {
                this.handleDrmProviderChanged(convertToInt(data.value));
            },
        });
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        let drmProviderId = nextProps.formValues.drm_provider;

        if (drmProviderId) {
            this.handleDrmProviderChanged(convertToInt(drmProviderId));
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        let prevDrmProviderId = prevProps.formValues.drm_provider,
            drmProviderId = this.props.formValues.drm_provider;

        if (drmProviderId && drmProviderId !== prevDrmProviderId) {
            this.handleDrmProviderChanged(convertToInt(drmProviderId));
        }
    }

    handleDrmProviderChanged = (value) => {
        if (DRM_PROVIDER_BUY_DRM === value) {
            this.setField('server_key', {hidden: false});
            this.setField('user_key', {hidden: false});
        }
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;

    prepareDataForSubmit(data) {
        let drmProviderId = convertToInt(data.drm_provider);

        let dataToSave = {
            client: this.props.formParams.clientId,
            drm_provider: drmProviderId,
            client_drm_setup_buy_drm: null,
        };

        if (data.id) {
            dataToSave.id = convertToInt(data.id);
        }

        if (DRM_PROVIDER_BUY_DRM === drmProviderId) {
            dataToSave.client_drm_setup_buy_drm = {
                server_key: data.server_key,
                user_key: data.user_key,
            };
        }

        return dataToSave;
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity']),
    mapStateToProps = (state) => ({clientData: state.app.entities.client || null});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDrmSetupForm);
