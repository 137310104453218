import PropTypes from "prop-types"
import React from "react";
/* eslint import/no-unresolved: 0 */
import {gql, graphql} from "react-apollo";
import {withRouter} from "react-router";
import {compose} from "redux";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Button, Header, Segment} from "semantic-ui-react";

import {ButtonCancel, ButtonSave} from "../../app/components/ButtonCollection"
import {FormWrapper} from "../../app/components/HOCFormWrapper";
import MessageBox from "../../app/components/MessageBox";
import Form from "../../app/components/ReduxFormControls";
import Authorization from "../../app/components/Authorization";

import {refetchQueryByName} from "../../../utils/apollo";

import validate from "./validatorContactPerson";
import * as MESSAGES from "../../app/constants/messages";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

import * as contactPersonGraphQl from "../../../graphql/clients/contactPerson";
import {getLink} from "../../app/components/Link";

const CONTACT_PERSON = 'contact person';

export class ContactPersonForm extends React.Component {
    static propTypes = {
        clientId: PropTypes.number.isRequired,
        CreateContactPerson: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        DeleteContactPerson: PropTypes.func,
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        history: PropTypes.object,
        initialize: PropTypes.func,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        resetIndexUrl: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        UpdateContactPerson: PropTypes.func
    };

    formSubmit = (data) => {
        const dataToSave = Object.assign({}, this.props.dataMap.contactPerson, data, {
            contact_person_type: parseInt(data.contact_person_type, 10)
        });

        let dataSaved;

        if (dataToSave.id.length === 0) {
            dataToSave.clientId = this.props.clientId;

            dataSaved = this.props.CreateContactPerson({
                variables: {...dataToSave}
            }).then(() => {
                this.props.MessageBox.addMessage("clientMessage", MESSAGES.SAVED(CONTACT_PERSON, data.name), null, "success");
                refetchQueryByName("ContactPersons");
                this.props.Modal.setModal({isVisible: false});
                this.props.history.push(getLink('clients.contactPersons.index', {id: this.props.clientId}))
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "clientContactPersonMessage",
                    MESSAGES.NOT_SAVED(CONTACT_PERSON, data.name),
                    `${error}`,
                    "error"
                );
            });
        } else {
            dataSaved = this.props.UpdateContactPerson({
                variables: {...dataToSave}
            }).then(() => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED(CONTACT_PERSON, data.name), null, "success"
                );
                refetchQueryByName("ContactPersons");
                this.props.Modal.setModal({isVisible: false});
                this.props.history.push(getLink('clients.contactPersons.index', {id: this.props.clientId}))
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "clientContactPersonMessage",
                    MESSAGES.NOT_SAVED(CONTACT_PERSON, data.name),
                    `${error}`,
                    "error"
                );
            });
        }

        return dataSaved.then();
    };

    deleteContactPerson = (id) => {
        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING(CONTACT_PERSON, this.props.dataForForm.contactPerson.name)
        });

        return this.props.DeleteContactPerson({
            variables: {
                id: id
            }
        }).then(() => {
            this.props.MessageBox.addMessage("clientMessage", MESSAGES.DELETED(CONTACT_PERSON, this.props.dataForForm.contactPerson.name), null, "success");
            refetchQueryByName("ContactPersons");
            this.props.Modal.setModal({isVisible: false, header: null});
            this.props.resetIndexUrl();
        }).catch((error) => {
            this.props.Modal.setModal({isVisible: false, header: null});
            this.props.MessageBox.addMessage(
                "clientMessage",
                MESSAGES.NOT_DELETED(CONTACT_PERSON, this.props.dataForForm.contactPerson.name),
                `${error}`,
                "error"
            );
        });
    };

    handleContactPersonDelete = () => {
        const data = this.props.dataForForm.contactPerson;

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={MESSAGES.DELETE_HEADER(CONTACT_PERSON)}/>,
            onYes: () => (this.deleteContactPerson(parseInt(data.id))),
            text: `Are you sure you want to delete the contact person "${data.name}"?`
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (this.props.dataForForm.contactPerson.id !== undefined && this.props.dataForForm.contactPerson.id !== "") {
            deleteButton =
                <Button onClick={this.handleContactPersonDelete}
                    color="red"
                    content="Delete"
                    disabled={this.props.submitting}
                    icon="trash"
                />
        }

        return deleteButton;
    };

    closeModal = (e) => {
        e.preventDefault();
        this.props.Modal.setModal({isVisible: false});
        this.props.history.push(getLink('clients.contactPersons.index', {id: this.props.clientId}));
    };

    render() {
        return (
            <Segment basic className='--form'>
                <Header as="h3" dividing>
                    {(this.props.dataForForm.contactPerson.id)
                        ? `Contact person "${this.props.dataForForm.contactPerson.name}"`
                        : 'Add new contact person'
                    }
                </Header>
                <MessageBox name="clientContactPersonMessage"/>
                <Form.Create
                    loading={(this.props.formData !== undefined) ? this.props.formData.loading : false}
                    onSubmit={this.props.handleSubmit(this.formSubmit)}
                >
                    <Form.IdField
                        defaultValue={this.props.dataForForm.contactPerson.id}
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.contactPerson.name}
                        label="Name"
                        name="name"
                        required
                        autoFocus
                    />
                    <Form.FormRow
                        allowClear={false}
                        component={Form.SemanticSelect}
                        defaultValue={this.props.dataForForm.contactPerson.contact_person_type.id}
                        label="Type"
                        name="contact_person_type"
                        options={this.props.dataForForm.contactPersonTypes}
                        placeholder='Select type'
                        required
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.contactPerson.position}
                        label="Position"
                        name="position"
                    />
                    <Form.FormRow
                        component={Form.SemanticTextArea}
                        defaultValue={this.props.dataForForm.contactPerson.address}
                        label="Address"
                        name="address"
                    />
                    <Form.FormRow
                        label="Email address"
                        name="email_address"
                        defaultValue={this.props.dataForForm.contactPerson.email_address}
                        component={Form.SemanticInput}
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.contactPerson.phone_number_office}
                        label="Phone number (office)"
                        name="phone_number_office"
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.contactPerson.phone_number_mobile}
                        label="Phone number (mobile)"
                        name="phone_number_mobile"
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.contactPerson.skype_name}
                        label="Skype name"
                        name="skype_name"
                    />
                    <Form.FormRow
                        component={Form.SemanticTextArea}
                        defaultValue={this.props.dataForForm.contactPerson.notes}
                        label="Notes"
                        name="notes"
                    />
                    <Form.FormRowChangelog
                        resources={RESOURCES.CONTACT_PERSON}
                        data={this.props.dataForForm.contactPerson}
                    />
                    <div className="formRow form__footer">
                        <label/>
                        <ButtonSave
                            disabled={this.props.submitting}
                            loading={this.props.submitting}
                        >
                            Save
                        </ButtonSave>
                        <Authorization
                            privileges={CONST.SECURITY_PRIVILEGES_DELETE}
                            resources={RESOURCES.CONTACT_PERSON}
                        >
                            {this.renderDeleteButton()}
                        </Authorization>
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={ this.closeModal }
                        >
                            {'Cancel'}
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </Segment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            contact_person_type: ownProps.dataForForm.contactPerson.contact_person_type.id
        }
    };
};

const ContactPersonFormRedux = reduxForm({
    form: 'ClientContactPerson',
    validate
})(withRouter(ContactPersonForm));

export default compose(
    graphql(gql(contactPersonGraphQl.createContactPersonForClient), {name: 'CreateContactPerson'}),
    graphql(gql(contactPersonGraphQl.updateContactPersonForClient), {name: 'UpdateContactPerson'}),
    graphql(gql(contactPersonGraphQl.deleteContactPersonForClient), {name: 'DeleteContactPerson'})
)(FormWrapper({
    contactPerson: {
        id: "",
        name: "",
        contact_person_type: {id: ""},
        my_type: "",
        position: "",
        address: "",
        email_address: "",
        phone_number_office: "",
        phone_number_mobile: "",
        skype_name: "",
        notes: "",
        creation_datetime: "",
        creation_user: {
            id: "",
            name: "",
        },
        update_datetime: "",
        update_user: {
            id: "",
            name: "",
        }
    },
    contactPersonTypes: []
}, connect(mapStateToProps)(ContactPersonFormRedux)));
