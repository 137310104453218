import Form from '@appComponents/ReduxFormControls';

/**
 * Use for generating fields. Will return fields: language name (language code)
 */
export const generateLanguageSelectOptions = (languages) => {
    return languages.map((option) => {
        const languageCodeString = option.language.code ? `(${option.language.code})` : '';
        const text = `${option.name} ${languageCodeString}`;

        return {
            id: option.id,
            key: option.id,
            value: option.id,
            text,
        };
    });
};

/**
 * Use for generating fields. Will return fields: tracks for multi audio and subtitles modal
 */
export const generateTracksOptions = (tracks) => {
    return tracks.map((track) => {
        return {
            id: track,
            key: track,
            value: track,
            text: track,
        };
    });
};

/**
 * Use for generating fields. Will return fields: id, name
 * @param dataMapKeyPart
 * @returns object
 */
export const generateIdNameFieldsByDataMapKey = (dataMapKeyPart) => {
    return {
        id: {
            component: Form.IdField,
            dataMapKey: `${dataMapKeyPart}.id`,
            props: {
                hidden: true,
                label: '',
                name: 'id',
            },
        },
        name: {
            dataMapKey: `${dataMapKeyPart}.name`,
            props: {
                component: Form.SemanticInput,
                label: 'Name',
                name: 'name',
                required: true,
                type: 'input',
                autoFocus: true,
            },
        },
    };
};

/**
 * Use for generating fields. Will return fields: country, email_address, phone_number, fax_number, vat_number
 * @param dataMapKey
 * @returns object
 */
export const generateContactInformationFieldsByDataMapKey = (dataMapKey) => {
    return {
        country: {
            dataMapKey: `${dataMapKey}.country.id`,
            optionsKey: 'countries',
            props: {
                component: Form.SemanticSelect,
                label: 'Country',
                name: 'country_id',
                placeholder: 'Select country',
            },
        },
        email_address: {
            dataMapKey: `${dataMapKey}.email_address`,
            props: {
                component: Form.SemanticInput,
                label: 'Email address',
                name: 'email_address',
                type: 'input',
            },
        },
        phone_number: {
            dataMapKey: `${dataMapKey}.phone_number`,
            props: {
                component: Form.SemanticInput,
                label: 'Phone number',
                name: 'phone_number',
                type: 'input',
            },
        },
        fax_number: {
            dataMapKey: `${dataMapKey}.fax_number`,
            props: {
                component: Form.SemanticInput,
                label: 'Fax number',
                name: 'fax_number',
                type: 'input',
            },
        },
        vat_number: {
            dataMapKey: `${dataMapKey}.vat_number`,
            props: {
                component: Form.SemanticInput,
                label: 'VAT number',
                name: 'vat_number',
                type: 'input',
            },
        },
    };
};
