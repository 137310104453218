import React from "react";

import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";
import { ButtonAdd } from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link"
import Authorization from "../../app/components/Authorization";

const EventContentTypesIndexButtons = () => {
    const authorizationObject = {
        [RESOURCES.EVENT_CONTENT_TYPE]: CONST.SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="__indexButtons content__buttons">
            <Authorization authorization={authorizationObject}>
                <Link name="eventContentTypes.add">
                    <ButtonAdd>{"Add new event content type"}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

export default EventContentTypesIndexButtons;
