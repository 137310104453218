import PropTypes from 'prop-types';

import {USER_ACCESS_INFORMATION, USER} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {SECURITY_PRIVILEGES_READ_UPDATE, SECURITY_PRIVILEGES_READ} from '@constants/variables';
import { hasPrivileges } from '@appComponents/Authorization';
import { GetUser, GetUserWithAccessInformation } from '@graphql/user/query';
import {UpdateUserGeneralInformation, UpdateUserGeneralInformationWithAccessInfo} from '@graphql/user/mutation';
import {GetRolesForCheckboxTree} from '@graphql/role/query';
import Form from '@appComponents/ReduxFormControls';

import validatorAddUserFromExistingIamAccount from './helpers/validatorAddUserFromExistingIamAccount';
import { getUserFieldsCommonProps, getUserAccessInformationCommonProps } from './helpers/userFields';
import {MESSAGE_BOX_USERS_AFTER_EDIT_NAME, MESSAGE_BOX_USERS_AFTER_EDIT_ERROR_NAME} from '../utils/variables';

export default function EditUserGeneralInformationModel({ id }) {

    const userAccessInfoUpdatePrivilege = {
        [USER] : SECURITY_PRIVILEGES_READ_UPDATE,
        [USER_ACCESS_INFORMATION] : SECURITY_PRIVILEGES_READ_UPDATE,
    };

    const userAccessInfoReadPrivilege = {
        [USER] : SECURITY_PRIVILEGES_READ,
        [USER_ACCESS_INFORMATION] : SECURITY_PRIVILEGES_READ,
    };

    const canUpdateUserAccessInfo = hasPrivileges(userAccessInfoUpdatePrivilege);
    const canReadUserAccessInfo = hasPrivileges(userAccessInfoReadPrivilege) || canUpdateUserAccessInfo;

    const userAccessInformationFieldSettings = {
        hidden: !canReadUserAccessInfo,
        disabled: !canUpdateUserAccessInfo,
    };

    return {
        entityDataMapKey: 'user',
        entityLabel: 'user',
        formName: 'user_edit_general_information',
        resources: USER,
        showChangeLog: true,
        validate: validatorAddUserFromExistingIamAccount,
        title: null,
        dataMap: {
            user: {
                roles: [],
                has_ava_admin_access: false,
                access_request_reference: '',
                job_description: '',
                manager: '',
                additional_info: '',
            },
        },
        messages: {
            boxName: {
                error: MESSAGE_BOX_USERS_AFTER_EDIT_ERROR_NAME,
                success: MESSAGE_BOX_USERS_AFTER_EDIT_NAME,
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('user'),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'user.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                    defaultValue: id,
                },
            },
            iam_first_name_text: {
                dataMapKey: 'user.iam_user.firstName',
                props: {
                    ...getUserFieldsCommonProps('iam_first_name_text'),
                },
            },
            iam_surname_text: {
                dataMapKey: 'user.iam_user.surname',
                props: {
                    ...getUserFieldsCommonProps('iam_surname_text'),
                },
            },
            iam_nickname_text: {
                dataMapKey: 'user.iam_user.nickname',
                props: {
                    ...getUserFieldsCommonProps('iam_nickname_text'),
                },
            },
            iam_email_text: {
                dataMapKey: 'user.email',
                props: {
                    ...getUserFieldsCommonProps('iam_email_text'),
                },
            },
            roles: {
                dataMapKey: 'user.roles',
                optionsKey: 'roles',
                props: {
                    ...getUserFieldsCommonProps('roles'),
                },
            },
            has_ava_admin_access: {
                dataMapKey: 'user.has_ava_admin_access',
                props: {
                    ...getUserFieldsCommonProps('has_ava_admin_access'),
                },
            },
            access_request_reference: {
                dataMapKey: 'user.access_request_reference',
                props: {
                    ...getUserAccessInformationCommonProps('access_request_reference', userAccessInformationFieldSettings),
                },
            },
            job_description: {
                dataMapKey: 'user.job_description',
                props: {
                    ...getUserAccessInformationCommonProps('job_description', userAccessInformationFieldSettings),
                },
            },
            manager: {
                dataMapKey: 'user.manager',
                props: {
                    ...getUserAccessInformationCommonProps('manager', userAccessInformationFieldSettings),
                },
            },
            additional_info: {
                dataMapKey: 'user.additional_info',
                props: {
                    ...getUserAccessInformationCommonProps('additional_info', userAccessInformationFieldSettings),
                },
            },
            last_login_ip: {
                props: {
                    ...getUserFieldsCommonProps('last_login_ip'),
                },
            },
        },
        mutation: {
            updateEntity: canUpdateUserAccessInfo ? UpdateUserGeneralInformationWithAccessInfo : UpdateUserGeneralInformation,
        },
        mutationOptions: {},
        query: {
            getEntity: canReadUserAccessInfo ? GetUserWithAccessInformation : GetUser,
            getOptions: GetRolesForCheckboxTree,
        },
    };
}

EditUserGeneralInformationModel.propTypes = {
    id: PropTypes.string.isRequired,
};
