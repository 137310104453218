import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';

import EncodingJobProfileForm from '../forms/EncodingJobProfileForm';
import EncodingJobProfileModel from '../forms/EncodingJobProfileModel';

const EncodingJobProfileEdit = ({
    match: {
        params: {id = null},
    },
}) => {
    const [pageTitle, setPageTitle] = React.useState('Encoding job profile');

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Encoding Job Profile', routes.encodingJobProfile.index.path),
        });
    }

    const Form = React.useMemo(() => (
        createForm(
            EncodingJobProfileModel,
            EncodingJobProfileForm,
            {
                id: convertToInt(id),
                setPageTitle: setPageTitle,
            }
        )
    ), []);

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                buttonsProps={{name: 'encodingJobProfile.index'}}
                messagesBoxNames='encodingJobProfileMessageError'
                pageTitle={pageTitle}
            />
            <Form/>
        </div>
    );
};
EncodingJobProfileEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};
EncodingJobProfileEdit.displayName = 'EncodingJobProfileEdit';

export default withRouter(EncodingJobProfileEdit);
