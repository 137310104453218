import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames"
import isUndefined from 'lodash/isUndefined';
import { Header } from "semantic-ui-react";

const SegmentHeader = (props) => {
    const dividing = (!isUndefined(props.dividing) ? props.dividing : true),
        // In "compact" style, top margin must be remove
        isCompactStyle = (!isUndefined(props.style) && 'compact' === props.style),
        headerClass = classNames({
            'segmentHeader' : true,
            'helper__removeTopMargin': isCompactStyle
        }),
        segmentHeaderTitleClass = classNames({
            'segmentHeader' : true,
            'helper__removeTopMargin': isCompactStyle
        });

    return (
        <Header as="h3" { ...{dividing} } className={headerClass}>
            <div className={segmentHeaderTitleClass}>
                {props.children}
            </div>
            <div className="segmentHeader__buttons">
                {props.buttons}
            </div>
        </Header>
    );
};

SegmentHeader.propTypes = {
    style: PropTypes.oneOf(['compact']),
    buttons: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    dividing: PropTypes.bool
};

export default SegmentHeader;
