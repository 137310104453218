import * as React from "react";
import PropTypes from "prop-types";
import { ReduxComponent } from "./ReduxComponent";
import classnames from "classnames";

import {Form as SemanticForm} from "semantic-ui-react";

/**
 * Render textarea for Redux
 */
export class SemanticTextArea extends ReduxComponent {
    /**
     *  @property {Number} [rows=3] Number of rows of textarea
     */
    static propTypes = {
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        input: PropTypes.object,
        meta: PropTypes.object,
        rows: PropTypes.number,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);
        this.options = {"rows": props.rows ? props.rows : 3}
    }

    /**
     * @ignore
     */
    componentWillMount() {
        this.props.input.onChange(this.props.defaultValue)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            this.props.input.onChange(nextProps.defaultValue)
        }
    }

    /**
     * @ignore
     */
    componentWillUpdate(props) {
        if ((props.input.value.length === 0 || props.input.value !== props.defaultValue ) && !props.meta.visited) {
            this.props.input.onChange(props.defaultValue)
        }
    }

    /**
     * @ignore
     */
    render() {
        return (
            <div className={classnames({
                'hidden': this.props.hidden,
                'error': this.isError(this.props)
            })}>
                {this.renderLabel(this.props)}
                <div className="input-container">
                    <SemanticForm.TextArea
                        {...this.props.input}
                        disabled={this.props.meta.submitting || this.props.disabled}
                        rows={this.options.rows}
                        error={this.isError(this.props)}
                        className={'--' + this.props.input.name}
                    />
                    <div className="error info">{this.isError(this.props) ? this.props.meta.error : ''}</div>
                </div>
            </div>
        )
    }
}

export default SemanticTextArea;
