import PropTypes from "prop-types";
import React from 'react';

import {connect} from "react-redux";

import mapModulesToProps from "../../../utils/mapModulesToProps";

class Dashboard extends React.Component {
    static propTypes = {
        Menu: PropTypes.object
    }

    componentDidMount() {
        this.props.Menu.storeMenu('dashboard');
    }

    render() {
        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Menu']);

export default connect(null, mapDispatchToProps)(Dashboard);
