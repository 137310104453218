import React from "react";
import PropTypes from "prop-types";
import {Grid, Segment} from "semantic-ui-react";

import ListingInfo from "../../../../app/components/ListingInfo";
import SegmentHeader from "../../../../app/components/SegmentHeader";

/**
 * Render section with "Event details"
 */
const EventDetails = ({event}) => {
    return (
        <Segment basic className="--table eventPageDetails">
            <SegmentHeader>Event details</SegmentHeader>
            <Grid columns={event.length}>
                {event.map((columnFields, columnNumber) => (
                    <Grid.Column key={columnNumber}>
                        <ListingInfo data={columnFields}/>
                    </Grid.Column>
                ))}
            </Grid>
        </Segment>
    )
};

EventDetails.propTypes = {
    event: PropTypes.array.isRequired
};

export default EventDetails;
