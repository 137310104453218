import validators from "../../../../utils/validators";

const validatorTableFilters = (values) => {
    const errorMessage = 'Type at least 3 characters';
    let errors = {};

    Object.assign(errors, validators.required(
        values.search,
        'search',
        errorMessage
    ));

    Object.assign(errors, validators.minLength({
        value: values.search,
        minLength: 3,
        errorMessage
    }, 'search'));

    return errors;
};

export default validatorTableFilters;
