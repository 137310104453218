import {isEmpty as _isEmpty} from "lodash";

const validate = (values) => {
    const errors = {};

    if (!_isEmpty(values)) {
        Object.entries(values).forEach(entry => {
            const [key, value] = entry;

            if (null !== value && null !== key.match(/privilege_\d+/) && 0 === value.length) {
                let toggleKey = "toggle_" + key.match(/\d+/)[0];

                if (true === values[toggleKey]) {
                    Object.assign(errors, {[key]: 'At least one checkbox should be selected.'});
                    Object.assign(errors, {[toggleKey]: ' '});
                }
            }
        });
    }

    return errors;
};

export default validate;
