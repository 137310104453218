import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.client_types, 'client_types'));

    if (values.crm_id !== undefined && values.crm_id !== null) {
        Object.assign(errors, validators.digitsOnly(values.crm_id, 'crm_id'));
    }

    if (values.notification_email_recipients !== undefined && values.notification_email_recipients !== null) {
        values.notification_email_recipients.forEach((email) => {
            Object.assign(errors, validators.email(email, 'notification_email_recipients'))
        });
    }

    return errors;
};

export default validate;
