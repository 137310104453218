import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";

class ProductEdit extends React.PureComponent {
    static propTypes = {
        clientProduct: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
        form: PropTypes.func.isRequired,
        model: PropTypes.func.isRequired,
        productId: PropTypes.number.isRequired,
    };

    render() {
        let Form = createForm(this.props.model, this.props.form, {
            id: convertToInt(this.props.clientProduct.id),
            dataRequest: true,
            optionsVariables: {
                product: this.props.productId,
            },
        });

        return (
            <Form/>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let props = {clientProduct: null};

    if(state.app.entities.client && state.app.entities.client.clientProducts) {
        let clientProduct = state.app.entities.client.clientProducts.find(clientProduct => (
            ownProps.productId === convertToInt(clientProduct.product.id)
        ));

        props = Object.assign({}, {
            clientProduct: clientProduct || null,
        });
    }

    return props;
};

const mapDispatchToProps = mapModulesToProps(['Entity']);

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit);
