import React from 'react';
import {get as _get} from 'lodash';

import Authorization from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {convertToInt} from '@utils/helpers';

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name,
    },
    'resources_count': {
        label: '#Resources',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'users_count': {
        label: '#Users',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const authorizationObject = {
    [RESOURCES.ROLE]: CONST.SECURITY_PRIVILEGES_READ,
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: 'roles.edit',
                        params: {id : _get(row, 'id', null)},
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case 'actions' :
            return (
                <Authorization
                    authorization={authorizationObject}
                >
                    <div className='actions-list-icon'>
                        <IconEdit resource={RESOURCES.ROLE} size='large' link={
                            getLink('roles.edit', {id: row.id})
                        } />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};
