import React from 'react';
import {IconAngleRight} from "../../app/components/IconCollection";
import {isNil as _isNil, sortBy as _sortBy} from "lodash";

const UdpOriginEntryPointContentsRow = (row) => {
    if (_isNil(row.content_categories) && _isNil(row.tournaments)) {
        return null;
    }

    const contentRows = row.content_categories.map(function (category) {
        let lvl3 = "",
            lvl2 = "",
            lvl1 = "";

        if (!_isNil(category)) {
            lvl3 = category.name;

            if (!_isNil(category.parent)) {
                lvl2 = category.parent.name;

                if (!_isNil(category.parent.parent)) {
                    lvl1 = category.parent.parent.name;
                }
            }
        } else {
            return null;
        }

        return {
            type: 'Category',
            id: category.id,
            lvl1,
            lvl2,
            lvl3
        };
    });

    const tournamentRows = row.tournaments.map(function (tournament) {
        let lvl3 = "",
            lvl2 = "",
            lvl1 = "";

        if (!_isNil(tournament)) {
            lvl3 = tournament.name;

            if (!_isNil(tournament.tournament_category)) {
                lvl2 = tournament.tournament_category.name;

                if (!_isNil(tournament.tournament_category.sport)) {
                    lvl1 = tournament.tournament_category.sport.name;
                }
            }
        } else {
            return null;
        }

        return {
            type: 'Tournament',
            id: tournament.id,
            lvl1,
            lvl2,
            lvl3
        };
    });

    const rows = _sortBy([...contentRows, ...tournamentRows].filter(Boolean), ['lvl1', 'lvl2', 'lvl3']).map(row => (
        <div key={`UdpOriginEntryPoint${row.type}_${row.id}`}>
            {row.lvl1} <IconAngleRight/> {row.lvl2} <IconAngleRight/> {row.lvl3}
        </div>
    ));

    return <div>{rows}</div>;
};

export default UdpOriginEntryPointContentsRow;
