import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";

const LicenseeFilters = (props) => {
    const { countries } = props.data,
        loading = props.loading;

    return (
        <div className="licensees__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                name="search"
                placeholder="Search..."
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.Licensees, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                name="countries"
                placeholder="Select country"
                options={countries}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.Licensees, 'countries')}
            />
        </div>
    )
};

LicenseeFilters.defaultProps = {
    countries: []
};

LicenseeFilters.propTypes = {
    countries: PropTypes.array.isRequired,
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired
};

export default FiltersWrapper(LicenseeFilters, {name: "Licensees"});
