export const deleteEncodingTargetFromCdnConfiguration = `
mutation deleteEncodingTargetFromCdnConfiguration (
  $id: ID!
  $client_cdn_configurations: [Int]
) {
  updateEncodingTarget (
    id: $id
    client_cdn_configurations: $client_cdn_configurations
  ) {
    id
    distribution {
      id
      product {
        id
        name: short_name
      }
      distribution_type {
        id
        name
      }
    }
    is_default
    device_categories {
      id
      name
    }
    stream_delay_type {
      id
      name
    }
    encoder_type {
      id
      name
    }
    cdn {
      id
      name
    }
    ingest_method {
      id
    }
    akamai_cdn_ingest_method {
      id
    }
    encoding_target_sr {
      id
      language {
        id
        name
      }
      encoding_job_profile {
        id
        name
      }
      stream_name_prefix
      has_scoreboard_overlay_support
      scoreboard_overlay_position {
        id
      }
      has_slate_support
      akamai_rtmp_distribution_regions {
        id
        name
      }
    }
    client_cdn_configurations {
      id
      name
      client_product {
        id
        client {
          id
          name
        }
        product {
          id
          short_name
        }
      }
    }
  }
}
`;

export const addClientCdnConfigurationMutation = `
mutation addClientCdnConfigurationMutation (
  $eventContent: Int!
  $clientCdnConfigurations: [Int!]
) {
  addClientCdnConfiguration (
    event_content: $eventContent
    client_cdn_configurations: $clientCdnConfigurations
  ) {
    status
    message
  }
}
`;

export const getOptionsForCdnConfigurationForm = `
query getOptionsForCdnConfigurationForm {
  clientCdnConfigurations {
    id
    name
    client_product {
      client {
        id
        name
      }
      product {
        id
        short_name
      }
    }
    live_encoding_job_profile {
      id
    }
    recording_encoding_job_profile {
      id
    }
  }
}
`;
