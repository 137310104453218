import {
    eq as _eq,
    get as _get,
    has as _has
} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {Dimmer, Form as SemanticForm, Loader} from "semantic-ui-react";

import MatrixTable from "./MatrixTable";

class EventTournamentProductMatrix extends React.PureComponent {
    static propTypes = {
        columns: PropTypes.object,
        input: PropTypes.object,
        label: PropTypes.string,
        loading: PropTypes.bool,
        meta: PropTypes.object,
        rows: PropTypes.array,
        selected: PropTypes.object,
        onChangeCallback: PropTypes.func
    };

    componentDidUpdate(prevProps) {
        if (!_eq(prevProps.selected, this.props.selected)) {
            this.props.input.onChange(this.props.selected);

            this.setState(() => ({
                selected: this.props.selected
            }));
        }
    }

    onChangeCheckbox = (event) => {
        const value = event.currentTarget.dataset.value;

        let valueSplit = value.split("_"),
            inputData = {...this.state.selected},
            key = `${valueSplit[0]}_${valueSplit[1]}`;

        if (_has(inputData, key)) {
            delete inputData[key];
        } else {
            inputData[key] = valueSplit[2]
        }

        this.setState(() => ({
            selected: inputData
        }));

        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(inputData);
        }

        this.props.input.onFocus();
        this.props.input.onChange(inputData);
        this.props.input.onBlur();
    };

    renderCheckbox = (tournament, productId) => {
        let isSelected = _has(this.state, `selected.${tournament.id}_${productId}`),
            initialId = _get(this.props, `selected.${tournament.id}_${productId}`, null);

        return _has(tournament, `products.${productId}`)
            ? <SemanticForm.Checkbox
                checked={isSelected}
                data-value={`${tournament.id}_${productId}_${initialId}`}
                name={`${name}[${tournament.id}_${productId}]`}
                onChange={this.onChangeCheckbox}
            />
            : null
    };

    render() {
        let message = (this.props.rows && 0 === _get(this.props, "columns", []).length)
            ? "There are no products for current tournaments"
            : "At least one product needs to be selected.";

        return  <div>
            <label>{this.props.label}*</label>
            <div className="tableMatrix__container">
                <Dimmer inverted active={this.props.loading || this.props.meta.submitting} className="--relative --noPadding">
                    {this.props.loading  && <Loader size="small" active inline/>}
                </Dimmer>
                {(this.props.columns && this.props.rows)
                    ? <MatrixTable
                        rows={this.props.rows}
                        columns={this.props.columns}
                        renderCheckbox={this.renderCheckbox}
                    />
                    : (!this.props.loading && <div>Please select a property licence first.</div>)
                }
                {!this.props.meta.valid && this.props.meta.touched && !this.props.loading
                && <div className="error">{message}</div>
                }
            </div>
        </div>
    }
}

export default EventTournamentProductMatrix
