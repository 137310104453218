import React from "react";
import PropTypes from "prop-types";

const GlobalError = (props) => (
    <div>
        <h2>Error occured!</h2>
        <h3>{props.error}</h3>
    </div>
);

GlobalError.propTypes = {
    error: PropTypes.string
};

export default GlobalError;
