import {changelogData} from "../changelogData";

const clientLabelResponseFragment = `
  id
  code
  name
  ${changelogData}
`;

const clientLabelMutationVariableDeclaration = `
  $client: ID!
  $code: String!
  $name: String!
`;

const clientLabelMutationVariableDefinition = `
  client: $client
  code: $code
  name: $name
`;

export const clientLabelsQuery = `
  query ClientLabelsQuery (
    $client: Int
  ) {
    clientLabels (filters: {
      client: $client
    }) {
      ${clientLabelResponseFragment}
    }
  }
`;

export const clientLabelQuery = `
  query ClientLabelQuery (
    $id: ID!
  ) {
    clientLabel (
      id: $id
    ) {
      ${clientLabelResponseFragment}
    }
  }
`;

export const clientLabelUpdateMutation = `
  mutation clientLabelUpdateMutation (
    $id: ID!
    ${clientLabelMutationVariableDeclaration}
  ) {
    updateClientLabel (
      id: $id
      ${clientLabelMutationVariableDefinition}
    ) {
      ${clientLabelResponseFragment}
    }
  }
`;

export const clientLabelCreateMutation = `
  mutation clientLabelCreateMutation (
    ${clientLabelMutationVariableDeclaration}
  ) {
    createClientLabel (
      ${clientLabelMutationVariableDefinition}
    ) {
      ${clientLabelResponseFragment}
    }
  }
`;

export const clientLabelDeleteMutation = `
  mutation clientLabelDeleteMutation (
    $id: ID!
  ) {
    deleteClientLabel (
      id: $id
    )
  }
`;
