import React from 'react';
import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {graphql} from 'react-apollo';
import {createForm, renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetIamUser} from '@graphql/user/query';
import {getLink} from '@appComponents/Link';

import AddUserFromExistingIamAccountForm from '../forms/AddUserFromExistingIamAccountForm';
import AddUserFromExistingIamAccountModel from '../forms/AddUserFromExistingIamAccountModel';

const AddUserFromExistingIamAccount = ({
    DataIamUser: {
        iamUser,
        loading,
    },
    Modal,
    history,
}) => {
    if (true === loading) {
        return null;
    }
    else if (false === loading && _isEmpty(iamUser)) {
        Modal.setModal({
            isVisible : true,
            content : renderModalError('Common-IAM user', routes.users.index.path),
        });

        return null;
    }
    else if (false === loading && null !== iamUser.avcmpID) {
        history.replace(getLink('users.edit', { id: iamUser.avcmpID}));
    }

    const Form = React.useMemo(() => (
        createForm(
            AddUserFromExistingIamAccountModel,
            AddUserFromExistingIamAccountForm,
            {
                settingsIamUser: {...iamUser, has_ava_admin_access: false},
            }
        )
    ),
    [iamUser]
    );

    return <Form data={{loading}}/>;
};

AddUserFromExistingIamAccount.propTypes = {
    DataIamUser: PropTypes.shape({
        iamUser: PropTypes.object,
        loading: PropTypes.bool.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.object,
};

const UsersAddFromExistingIamAccountWithQuery = compose(
    graphql(GetIamUser, {
        options: ({
            match: {
                params: {
                    email,
                },
            },
        }) => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            variables: {
                email: email,
            },
        }),
        name: 'DataIamUser',
    })
)(AddUserFromExistingIamAccount);

const mapDispatchToProps = mapModulesToProps(['Modal']);

export default withRouter(connect(null, mapDispatchToProps)(UsersAddFromExistingIamAccountWithQuery));
