import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Dropdown, Button, Popup} from 'semantic-ui-react';

import {Authorization} from '@appComponents/Authorization';

import Link from './Link';

/**
 *
 * @namespace Buttons
 *
 */
export const ButtonAlert = (props) => {
    return (
        <Button {...props} color='red'>
            {props.children}
        </Button>
    );
};
ButtonAlert.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
ButtonAlert.displayName = 'ButtonAlert';

export const CircleButtonAdd = (props) => {
    return (
        <Icon className='add circle' {...props} />
    );
};
CircleButtonAdd.displayName = 'CircleButtonAdd';

export const ButtonAdd = (props) => {
    return (
        <Button size='small' {...props} >
            <Icon className='add circle' />
            {props.children}
        </Button>
    );
};
ButtonAdd.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.array,
    ]),
};
ButtonAdd.displayName = 'ButtonAdd';


export const ButtonEdit = (props) => {
    return (
        <Button size='small' {...props} >
            <Icon className='pencil' />
            {props.children}
        </Button>
    );
};
ButtonEdit.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
ButtonEdit.displayName = 'ButtonEdit';

export const ButtonCancel = (props) => {
    return (
        <Button {...props} >
            <Icon className='cancel' />
            {props.children}
        </Button>
    );
};
ButtonCancel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
ButtonCancel.displayName = 'ButtonCancel';

export const ButtonSave = (props) => {
    return (
        <Button {...props} primary >
            <Icon className='save circle' />
            {props.children}
        </Button>
    );
};
ButtonSave.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
ButtonSave.displayName = 'ButtonSave';

export const ButtonSearch = (props) => {
    return (
        <Button {...props} icon >
            <Icon className='search' />
        </Button>
    );
};
ButtonSearch.displayName = 'ButtonSearch';

export const LinkButtonSettings = (props) => {
    return (
        <Authorization authorization={props.authorizationObject}>
            <Link name={props.name} params={props.params} >
                <Icon name='setting' />
            </Link>
        </Authorization>
    );
};
LinkButtonSettings.propTypes = {
    name: PropTypes.string.isRequired,
    params: PropTypes.object,
    authorizationObject: PropTypes.object,
};
LinkButtonSettings.defaultProps = {
    params: {},
};
LinkButtonSettings.displayName = 'LinkButtonSettings';

export const ButtonDropdown = (props) => {
    const {rightIcon, secondIcon, ...dropdownProps} = props;

    return (
        <Dropdown {...dropdownProps} labeled button simple className={rightIcon ? 'rightIcon' : ('icon ' + (secondIcon || ''))}>
            <Dropdown.Menu>
                {props.children}
            </Dropdown.Menu>
        </Dropdown>
    );
};
ButtonDropdown.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    secondIcon: PropTypes.string,
    rightIcon: PropTypes.bool,
};
ButtonDropdown.displayName = 'ButtonDropdown';

export const ButtonExport = () => {
    return (
        <ButtonDropdown icon='download' text='Export'>
            <Dropdown.Menu>
                <Dropdown.Item icon='file excel outline' text='Excel' />
            </Dropdown.Menu>
        </ButtonDropdown>
    );
};
ButtonExport.displayName = 'ButtonExport';

export const ButtonLinkDropdown = (props) => {
    return (
        <Dropdown {...props} button>
            <Dropdown.Menu>
                {props.children}
            </Dropdown.Menu>
        </Dropdown>
    );
};
ButtonLinkDropdown.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
};
ButtonLinkDropdown.displayName = 'ButtonLinkDropdown';

export const ButtonBackToList = (props) => {
    return (
        <div className='indexButtons'>
            <Link {...props}>
                <Button className='button-back'>
                    <Icon className='arrow circle left'/>{props.text || ' Back to list'}
                </Button>
            </Link>
        </div>
    );
};
ButtonBackToList.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string,
};
ButtonBackToList.displayName = 'ButtonBackToList';

export const RenderBackToListButton = React.memo((props) => {
    return <ButtonBackToList {...props} />;
});
RenderBackToListButton.displayName = 'RenderBackToListButton';

export const ButtonExpandCollapse = ({onClick, shouldExpand, position}) => (
    <Popup
        content={(shouldExpand) ? 'Expand All' : 'Collapse All'}
        position={position}
        trigger={
            <Button icon onClick={onClick}>
                <Icon
                    className={(shouldExpand) ? 'plus square outline' : 'minus square outline'}
                />
            </Button>
        }
    />
);
ButtonExpandCollapse.displayName = 'ButtonExpandCollapse';
ButtonExpandCollapse.propTypes = {
    onClick: PropTypes.func.isRequired,
    shouldExpand: PropTypes.bool.isRequired,
    position: PropTypes.string,
};
ButtonExpandCollapse.defaultProps = {
    position: 'top right',
};
