export const attachmentsBody = `
id
description
filename
server_path
`;

export const propertyLicenceAttachmentsList = `
query PropertyLicenceAttachments($propertyLicenceId : ID!) {
    propertyLicenceAttachments(
        filters: {
          propertyLicence: $propertyLicenceId
        }
    ) {
      ${attachmentsBody}
    }
}`;

export const propertyLicenceAttachmentsCreateMutation = `
mutation CreatePropertyLicenceAttachment (
    $property_licence_id: Int!
    $description: String!
    $filename: String!
    $server_path: String!
  ) {
    createPropertyLicenceAttachment (
      property_licence: $property_licence_id
      description: $description
      filename: $filename
      server_path: $server_path
    ) {
      ${attachmentsBody}
    }
  }
`;

export const propertyLicenceAttachmentsUpdateMutation = `
mutation UpdatePropertyLicenceAttachment (
    $property_licence_id: Int!
    $description: String!
    $filename: String!
    $id: ID
  ) {
    updatePropertyLicenceAttachment (
      property_licence: $property_licence_id
      description: $description
      filename: $filename
      id: $id
    ) {
      ${attachmentsBody}
    }
  }
`;

export const propertyLicenceAttachmentsDeleteMutation = `
mutation DeletePropertyLicenceAttachment($id:ID) {
    deletePropertyLicenceAttachment(id:$id) 
  }
`;
