import {useEffect} from 'react';
import {PropTypes} from 'prop-types';

function useEffectForFilters(data, lastClickSubmit, refetch, setIsRefetching) {
    useEffect(() => {
        if (!data) {
            return;
        }

        setIsRefetching(true);
        refetch().then(() => setIsRefetching(false));
    }, [lastClickSubmit]);
}
useEffectForFilters.propTypes = {
    data: PropTypes.object.isRequired,
    lastClickSubmit: PropTypes.number.isRequired,
    refetch: PropTypes.func.isRequired,
    setIsRefetching: PropTypes.func.isRequired,
};

export default useEffectForFilters;
