import * as ENV from '../constants/environments';

export const getCurrentEnvironmentByHostname = (hostname) => {
    const environmentName = ENV.ENVIRONMENT_TO_HOSTNAME_MATCHERS
        .find(matcher => (
            matcher.matchAllHosts
            || matcher.environmentIndicators.some(indicator => hostname.includes(indicator))
        )).environmentName;

    return {
        environmentName: environmentName,
        isProduction: (ENV.PRODUCTION === environmentName),
        isStaging: (ENV.STAGING === environmentName),
        isDev: (ENV.DEVELOPMENT === environmentName),
    };
};
