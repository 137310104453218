import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {
    DEVICE_CATEGORY_DESKTOP,
    DEVICE_CATEGORY_DESKTOP_LABEL,
    DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_MOBILE_LABEL,
    DEVICE_CATEGORY_SMART_TV, DEVICE_CATEGORY_SMART_TV_LABEL,
} from '@constants/variables';
import {getDeviceNameById} from '@modules/events/utils/eventsBookingGeoRestriction';
import {onCloseModal, setMutationSuccess} from '@modules/eventBooking/forms/helpers/helpers';
import {
    getEventBookingDataFromAllSteps,
    getAdditionalBackButton,
} from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';


export class EventBookingBlackoutZonesForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            setMutationSuccess(this.props);
            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        const {
                GraphQLEntityData,
                GraphQLOptionsData,
            } = this.props,
            isLoading = (GraphQLEntityData && GraphQLEntityData.loading) || GraphQLOptionsData.loading;

        if (this.state.switchesStatus !== this.props.switches) {
            this.setField('desktop_blackoutZones', {
                hidden: !this.props.switches.desktop_switch,
            });
            this.setField('mobile_blackoutZones', {
                hidden: !this.props.switches.mobile_switch,
            });
            this.setField('smart_tv_blackoutZones', {
                hidden: !this.props.switches.smart_tv_switch,
            });

            this.setState(() => ({
                switchesStatus: this.props.switches,
            }));
        }

        if (!isLoading && !this.state.dataLoaded) {
            let assignedBlackoutZonesIds = {};
            const eventBookingGeoRestrictions = _get(GraphQLEntityData, 'eventBooking.event_booking_geo_restrictions', []),
                blackoutZonesOptions = _get(GraphQLOptionsData, 'blackoutZones', []),
                preparedBlackoutZonesOptions = {};

            eventBookingGeoRestrictions.forEach((eventBookingGeoRestriction) => {
                assignedBlackoutZonesIds = Object.assign(
                    assignedBlackoutZonesIds,
                    {
                        [eventBookingGeoRestriction.device_category.id]: eventBookingGeoRestriction.blackout_zones.map((blackoutZone) => {
                            return blackoutZone.id;
                        }),
                    }
                );
            });

            for (const [key, blackoutZonesIds] of Object.entries(assignedBlackoutZonesIds)) {
                preparedBlackoutZonesOptions[key] = blackoutZonesOptions.filter(blackoutZone => {
                    if (blackoutZone.is_archived && blackoutZonesIds.includes(blackoutZone.id)) {
                        return true;
                    } else {
                        return !blackoutZone.is_archived;
                    }
                });
            }

            [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV].forEach((deviceCategoryId) => {
                const deviceName = getDeviceNameById(deviceCategoryId);

                this.setField(`${deviceName}_blackoutZones`, {
                    hidden: assignedBlackoutZonesIds[deviceCategoryId]
                        ? false
                        : this.props.previousStepData && !this.props.previousStepData[`${deviceName}_switch`],
                    defaultValue: assignedBlackoutZonesIds[deviceCategoryId]
                        ? assignedBlackoutZonesIds[deviceCategoryId].map(String)
                        : [],
                    options: preparedBlackoutZonesOptions[deviceCategoryId]
                        ? preparedBlackoutZonesOptions[deviceCategoryId]
                        : blackoutZonesOptions.filter(blackoutZone => !blackoutZone.is_archived),
                });
            });
            this.setState(() => ({dataLoaded: true}));
        }

        if (this.props.Model.editForm) {
            const mapping = {
                DEVICE_CATEGORY_DESKTOP_LABEL: 'desktop_blackoutZones',
                DEVICE_CATEGORY_MOBILE_LABEL: 'mobile_blackoutZones',
                DEVICE_CATEGORY_SMART_TV_LABEL: 'smart_tv_blackoutZones',
            };
            const selectedEventGeoRestrictions = _get(this.props.GraphQLEntityData, 'eventEventBooking');
            const selectedGeoRes = _get(selectedEventGeoRestrictions, 'event_booking_geo_restrictions');
            let arrayOfDevicesNames = [];
            const arrayOfMappingKey = Object.keys(mapping);

            if (selectedGeoRes && this.props && this.props.GraphQLEntityData && this.props.GraphQLEntityData?.eventEventBooking
                && this.props.GraphQLEntityData?.eventEventBooking?.event_booking_geo_restrictions
                && this.props.GraphQLEntityData.eventEventBooking.event_booking_geo_restrictions
                !== prevProps?.GraphQLEntityData?.eventEventBooking?.event_booking_geo_restrictions) {
                selectedGeoRes.map((item) => {
                    arrayOfDevicesNames.push(item.device_category.name);

                    this.setField(mapping[item.device_category.name], {
                        hidden: false,
                        defaultValue: item.blackout_zones.map(({id}) => id),
                    });

                    return arrayOfDevicesNames;

                });
                const noSelectedDevices = arrayOfMappingKey.filter(x => !arrayOfDevicesNames.includes(x));
                noSelectedDevices.forEach((noSelectedDevice) => {

                    if (DEVICE_CATEGORY_DESKTOP_LABEL === noSelectedDevice) {
                        this.setField('desktop_blackoutZones', {hidden: true});
                    }

                    if (DEVICE_CATEGORY_MOBILE_LABEL === noSelectedDevice) {
                        this.setField('mobile_blackoutZones', {hidden: true});
                    }

                    if (DEVICE_CATEGORY_SMART_TV_LABEL === noSelectedDevice) {
                        this.setField('smart_tv_blackoutZones', {hidden: true});
                    }
                });
            }
        }
    }

    prepareDataForSubmit = () => {
        const stepsData = Object.assign(
            {},
            this.props.formValues,
            this.props.previousStepData
        );

        return getEventBookingDataFromAllSteps(stepsData, this.props.formParams.id);
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (!_isEmpty(this.props.stepsMethods)) {
            this.props.stepsMethods.setForm('step4', nextProps);
        }
    }

    onCancel = () => {
        onCloseModal(
            this.props.formParams.optionsVariables.event,
            this.props.formParams.optionsVariables.client,
            this.props.Modal.setModal,
            this.props
        );
    };

    renderCancelButton = (props) => super.renderCancelButton({
        ...props,
        type: 'button',
        onClick: this.onCancel,
    });

    renderAdditionalButtons = () => (
        getAdditionalBackButton(this.props.Model.editForm, this.props.stepsMethods.showPreviousStep)
    );
}

export default withRouter(EventBookingBlackoutZonesForm);
