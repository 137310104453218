import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {GeoRestrictionsDropdownOptionsForCountries} from '@graphql/eventGeoRestriction/query';
import {updateBlackoutZoneCountriesAndSubdivisions} from '@graphql/blackoutZone/mutation';
import {GetBlackoutZoneCountriesAndSubdivisions} from '@graphql/blackoutZone/query';
import Form from '@appComponents/ReduxFormControls';
import {BLACKOUT_ZONE} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import validators from '@utils/validators';

const Subdivisions = (props) => {
    if (0 === props.options.length) {
        return (<div className={'geoRestrictions'}>
            <label>Subdivisions</label>
            <div style={{padding: '3px 0px'}}>
                None of the selected countries has geo restrictions handled on subdivision level.
            </div>
        </div>);
    }

    return <Form.SemanticCheckboxTree {...props}/>;
};

Subdivisions.propTypes = {
    options: PropTypes.object,
};

const CountriesAndSubdivisionsModel = () => (
    {
        formName: 'BlackoutZone-countriesAndSubdivisions',
        title: null,
        entityDataMapKey: 'blackoutZone',
        label: 'Blackout zone',
        entityLabel: 'Blackout zone',
        resources: BLACKOUT_ZONE,
        editForm: true,
        validate: (values, props) => {
            const requiredSubdivisionCountries = _get(props.GraphQLOptionsData, 'countries', []),
                errors = {};

            if (!_isEmpty(requiredSubdivisionCountries)
                && _get(values, 'countries', []).length
            ) {
                Object.assign(errors, validators.requiredAtLeastOneSubdivisionPerCountry({
                    countries: values['countries'],
                    subdivisions: values['country_subdivisions'],
                    required: requiredSubdivisionCountries.map((country) => {
                        return country.value;
                    }),
                }, 'country_subdivisions'));
            }

            return errors;
        },
        dataMap: {
            blackoutZone: {
                id: null,
                countries: [],
                subdivisions: [],
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventGeoRestriction.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            countries: {
                optionsKey: 'continents',
                props: {
                    className: 'geoRestrictions --no-border',
                    component: Form.SemanticCheckboxTree,
                    required: false,
                    label: 'Countries',
                    name: 'countries',
                },
            },
            subdivisions: {
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Subdivisions,
                    label: 'Subdivisions',
                    required: true,
                    name: 'country_subdivisions',
                    hidden: true,
                },
            },
        },
        messages: {
            boxName: {
                success: 'BlackoutZoneEdit',
                error: 'BlackoutZoneEdit',
            },
            text: {
                ...MESSAGES,
            },
        },
        mutation: {
            updateEntity: updateBlackoutZoneCountriesAndSubdivisions,
        },
        query: {
            getEntity: GetBlackoutZoneCountriesAndSubdivisions,
            getOptions: GeoRestrictionsDropdownOptionsForCountries,
        },
    }
);

export default CountriesAndSubdivisionsModel;
