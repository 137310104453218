import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    get as _get,
    includes as _includes,
} from 'lodash';

import {withApollo} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import Tabs from '@appComponents/Tabs';
import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import {getUserAuthorizationData} from '@appComponents/Authorization';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {routes} from '@constants/routes';
import {EXPORT_EXCEL_URL_FRAGMENT} from '@constants/variables';
import {digitsOnly} from '@utils/helpers';

import {createTabs, renderRtmpOriginEntryPointModalError} from '../utils/rtmpOriginEntryPointTabsLayoutHelper';
import {GetRtmpOriginEntryPointForApplications} from '../../../graphql/rtmpOriginEntryPoint/query.graphql';

export const RtmpOriginEntryPointTabsLayout = ({
    client,
    data = {},
    rtmpOriginEntryPointName = '',
    match: {
        path,
        params: {
            id = null,
        },
    },
    Menu,
    Modal,
}) => {
    const activePath = React.useMemo(() => (
        (_includes([
            routes.rtmpOriginEntryPoint.applications.index.path,
            routes.rtmpOriginEntryPoint.applications.add.path,
            routes.rtmpOriginEntryPoint.applications.edit.path,
            `${routes.rtmpOriginEntryPoint.applications.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
        ], path))
            ? routes.rtmpOriginEntryPoint.applications.index.path
            : path
    ), [path]);

    const [rtmpOriginEntryPointData, setRtmpOriginEntryPointData] = useState({});

    React.useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);

        if (routes.rtmpOriginEntryPoint.add.path !== activePath) {
            client.query({
                fetchPolicy: 'network-only',
                query: GetRtmpOriginEntryPointForApplications,
                variables: {
                    id: digitsOnly(id) && id,
                },
            }).then((response) => {
                setRtmpOriginEntryPointData(_get(response, 'data', ''));
            }).catch(() => {

                Modal.setModal({
                    isVisible: true,
                    content: renderRtmpOriginEntryPointModalError(),
                });
            });
        }
    }, []);

    React.useEffect(() => {
        if (data.error !== undefined) {
            Modal.setModal({
                isVisible: true,
                content: renderRtmpOriginEntryPointModalError(),
            });
        }
    }, [data.error]);

    return (
        rtmpOriginEntryPointName
        || (`${routes.rtmpOriginEntryPoint.applications.index.path}${EXPORT_EXCEL_URL_FRAGMENT}` !== path)
    )
        ? (
            <div>
                <HeaderRenderer
                    buttons={RenderBackToListButton}
                    buttonsProps={{name: 'rtmpOriginEntryPoint.index'}}
                    messagesBoxNames='rtmpOriginEntryPoint'
                    pageTitle={
                        (id)
                            ? <TitleFromQuery
                                query={GetRtmpOriginEntryPointForApplications}
                                variables={{id}}
                                errorBackLink={routes.rtmpOriginEntryPoint.index.path}
                                path={'rtmpOriginEntryPoint.name'}
                                entity={'RTMP origin entry point'}
                            />
                            : 'Add new RTMP origin entry point'
                    }
                />
                <Tabs items={createTabs(
                    id,
                    path,
                    getUserAuthorizationData(),
                    _get(rtmpOriginEntryPointData, 'rtmpOriginEntryPoint.host', '')
                )} active={activePath}/>
            </div>
        )
        : null;
};

RtmpOriginEntryPointTabsLayout.propTypes = {
    client: PropTypes.object.isRequired,
    data: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
    rtmpOriginEntryPointName: PropTypes.string,
};
RtmpOriginEntryPointTabsLayout.displayName = 'RtmpOriginEntryPointTabsLayout';

export default connect(null, mapModulesToProps(['Menu', 'Modal']))(withApollo(RtmpOriginEntryPointTabsLayout));
