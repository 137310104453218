import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {filterDisabledEncodingJobProfiles} from '@utils/helpers';

export class ThirdPartyCdnsForm extends DefaultForm {
    static propTypes = {
        formParams: PropTypes.shape({
            clientId: PropTypes.number.isRequired,
            dataRequest: PropTypes.bool.isRequired,
            id: PropTypes.number,
            optionsVariables: PropTypes.shape({
                products: PropTypes.arrayOf(PropTypes.number).isRequired,
            }),
            setMutationSuccessCallback: PropTypes.func.isRequired,
            thirdPartyId: PropTypes.number,
        }),
    };

    static defaultProps = {
        formParams: {
            id: null,
            thirdPartyId: null,
        },
    };

    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.setMutationSuccess();
        });

        this.setCreateErrorCallback((error) => {
            this.setMutationSuccess();
            this.setFormMessage(`${error}`);
        });

        this.setUpdateSuccessCallback(() => {
            this.setMutationSuccess();
        });

        this.setDeleteSuccessCallback(() => {
            this.setMutationSuccess();
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const {
                GraphQLEntityData,
                GraphQLOptionsData: {
                    encodingJobProfiles,
                    loading,
                },
            } = this.props,
            isLoading = (GraphQLEntityData && GraphQLEntityData.loading) || loading;

        if (!isLoading && !this.state.dataLoaded) {
            const assignedEncodingJobProfile = _get(
                this.props,
                'GraphQLEntityData.thirdPartyClientCdn.encoding_job_profile.id',
                null
            );

            if (encodingJobProfiles) {
                this.setField('encoding_job_profile', {
                    defaultValue: assignedEncodingJobProfile,
                    options: filterDisabledEncodingJobProfiles(encodingJobProfiles, assignedEncodingJobProfile),
                });
            }

            this.setState(() => ({dataLoaded: true}));
        }
    }

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            this.props.Model.label,
            getLink('clients.products.thirdParty.cdns.index', {id: this.props.formParams.clientId}),
            {size: 'tiny'}
        );
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                client_product_third_party: this.props.formParams.thirdPartyId,
            }
        )
    );

    setMutationSuccess = () => {
        this.props.formParams.setMutationSuccessCallback(Date.now());
    };
}

export default ThirdPartyCdnsForm;
