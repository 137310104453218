import PropTypes from 'prop-types';
import React from 'react';

import EnvironmentLabel from './EnvironmentLabel';
import Logo from './Logo';

import {getCurrentEnvironmentByHostname} from '../utils/environmentHelper';
import {wrapWithEnvironmentPopup} from '../utils/environmentPopupHelper';

const EnvironmentAwareLogo = ({isExpanded = false, sidebarWidthCollapsed}) => {
    const hostname = window.location.hostname.toLowerCase(),
        {environmentName, isProduction} = React.useMemo(
            () => getCurrentEnvironmentByHostname(hostname),
            [hostname]
        );

    if (isProduction) {
        return <Logo isProduction={isProduction}/>;
    }

    const logo = (
        <div className='sidebar__environment'>
            <Logo isProduction={isProduction}>
                <EnvironmentLabel
                    isExpanded={isExpanded}
                    environmentName={environmentName}
                />
            </Logo>
        </div>
    );

    if (isExpanded) {
        return logo;
    }

    return wrapWithEnvironmentPopup(logo, environmentName, sidebarWidthCollapsed);
};

EnvironmentAwareLogo.propTypes = {
    isExpanded: PropTypes.bool,
    sidebarWidthCollapsed: PropTypes.number.isRequired
};

export default EnvironmentAwareLogo;
