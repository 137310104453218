import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {getLink} from '@appComponents/Link';
import DefaultForm from '@appComponents/DefaultForm';

export class AddUserFromExistingIamAccountForm extends DefaultForm {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('users.index', {
                id: response.data.createUser.id,
            }));
        });
    }

    prepareDataForSubmit(data) {
        return super.prepareDataForSubmit(data).iam_user;
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderCancelButton = () => null;

    renderDeleteButton = () => null;
}

export default withRouter(AddUserFromExistingIamAccountForm);
