import PropTypes from "prop-types";
import React from "react";
import {Grid, Segment} from "semantic-ui-react";

import ListingInfo from "../../../../app/components/ListingInfo";
import SegmentHeader from "../../../../app/components/SegmentHeader";

const MatchDetails = ({match, loading}) => {
    if (loading) {
        return (
            <div className="eventPageDetails">
                <SegmentHeader style={'compact'}>Match details</SegmentHeader>
                <Segment basic loading padded="very"/>
            </div>
        );
    }

    if (!match.length) {
        return null;
    }

    return (
        <div className="eventPageDetails">
            <SegmentHeader style={'compact'}>Match details</SegmentHeader>
            <Grid columns={match.length}>
                {match.map((columnFields, columnNumber) => (
                    <Grid.Column key={columnNumber}>
                        <ListingInfo data={columnFields}/>
                    </Grid.Column>
                ))}
            </Grid>
        </div>
    )
};

MatchDetails.propTypes = {
    match: PropTypes.array,
    loading: PropTypes.bool,
};

MatchDetails.defaultProps = {
    match: [],
    loading: true,
};

export default MatchDetails;
