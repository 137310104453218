import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {get as _get} from "lodash";
import {graphql} from "react-apollo";

import {IconEdit, IconGlobe} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import Table from "../../app/components/Table";

import {convertToInt} from "../../../utils/helpers";
import {
    parseDeviceCategories,
    getTerritoriesWithTooltipForDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from "../../../utils/countryHelpers";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

import {GetMappedIsoCodes} from "../../../graphql/country/query.graphql";

const columns = {
    "master_booking_id": {
        label: "ID",
        sorting: (row) => convertToInt(row.master_booking_id),
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
    "event_id": {
        label: "Event ID",
        sorting: (row) => convertToInt(row.event_id),
        header: {
            align: "center",
        },
        content: {
            align: "center",
        },
    },
    "master_match_id": {
        label: "Master match ID",
        sorting: (row) => convertToInt(row.master_match_id),
        header: {
            align: "center",
        },
        content: {
            align: "center",
        },
    },
    "start_datetime": {
        label: "Date/Time",
        sorting: true,
        header: {
            align: "center",
        },
        content: {
            align: "center",
            singleLine: true,
        },
    },
    "sport_name": {
        label: "Sport",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "tournament_category_names": {
        label: "Categories",
        sorting: (row) => renderTournamentsAndCategories(row.tournament_category_names, true),
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "tournament_names": {
        label: "Tournaments",
        sorting: (row) => renderTournamentsAndCategories(row.tournament_names, true),
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "event_description": {
        label: "Description",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "product_short_name": {
        label: "Product",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "device_categories": {
        label: "Device categories",
        sorting: row => sortDeviceCategories(row),
    },
    "countries": {
        label: "Countries",
        sorting: false,
        className: "noPadding"
    },
    "number_of_countries": {
        label: "#Countries",
        sorting: false,
        header: {
            align: "left"
        },
        content: {
            align: "left"
        },
        className: "noPadding"
    },
    "client_package": {
        label: "Package",
        sorting: row => row.client_package ? row.client_package.name : '',
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "booking_type_name": {
        label: "Type",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left",
        },
    },
    "master_booking_creation_datetime": {
        label: "Time",
        sorting: true,
        header: {
            align: "center",
        },
        content: {
            align: "center",
            singleLine: true,
        },
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
        },
        content: {
            align: "center",
        },
    },
};

export const renderTournamentsAndCategories = (names, noHtml = false) => {
    if (noHtml) {
        return names.join(', ');
    }

    return (
        <div>
            {names.map((name, index) => (<div key={index}>{name}</div>))}
        </div>
    );
};

const sortDeviceCategories = (rowData) => {
    if (rowData.master_booking_geo_restrictions.length) {
        return rowData.master_booking_geo_restrictions.map(function(masterBookingGeoRestriction){
            return _get(masterBookingGeoRestriction, 'device_category.name', '');
        }).join('');
    }

    return '';
};

const ClientMasterBookingsTable = (props) => {
    const {data, clientId, clientMasterBookings, loading} = props;

    const rowRenderer = (column, row) => {
        const bookingGeoRestrictions = _get(row, "master_booking_geo_restrictions", []),
            deviceCategoriesCountries = parseDeviceCategories(data.mappedIsoCodes, bookingGeoRestrictions);

        switch (column) {
            case "master_booking_id":
            case "event_id":
            case "master_match_id":
            case "sport_name":
            case "product_short_name":
            case "booking_type_name":
            case "master_booking_creation_datetime":
                return row[column];
            case "start_datetime":
                return (
                    <div>
                        <div>{moment(row[column]).format("YYYY-MM-DD HH:mm") }</div>
                        <div>
                            {(null === row.end_datetime || undefined === row.end_datetime)
                                ? ""
                                : moment(row.end_datetime).format("YYYY-MM-DD HH:mm")
                            }
                        </div>
                    </div>
                );
            case "tournament_category_names":
            case "tournament_names":
                return renderTournamentsAndCategories(row[column]);
            case "event_description":
                return (
                    <LinkWithAuthorization
                        resources={RESOURCES.EVENT}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: "events.configuration.index",
                            params: {id : row.event_id},
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case "client_package":
                return row.client_package
                    ? (
                        <LinkWithAuthorization
                            resources={RESOURCES.CLIENT_PACKAGE}
                            privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                            link={{
                                name: "clients.packages.edit",
                                params: {
                                    id : clientId,
                                    packagesId : convertToInt(row.client_package.id),
                                },
                            }}
                        >
                            {row.client_package.name}
                        </LinkWithAuthorization>
                    )
                    : "";
            case "device_categories":
                return renderDeviceCategories(deviceCategoriesCountries);
            case "countries":
                return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesCountries, data.mappedIsoCodes);
            case "number_of_countries":
                return renderNumberOfCountries(deviceCategoriesCountries);
            case "actions":
                return (
                    <div className="actions-list-icon">
                        <IconEdit size="large" link={
                            getLink('clients.masterBookings.edit', {
                                id: clientId,
                                eventId: row.event_id,
                                masterBookingId: row.master_booking_id,
                            })}
                        />
                        <IconGlobe size="large" link={
                            getLink('clients.masterBookings.editGeoRestrictions',
                                {id : clientId, masterBookingId: convertToInt(row.master_booking_id)}
                            )}
                        />
                    </div>
                );
        }
    };

    return (
        <div className="clientMasterBookings__table">
            <Table
                className="mini"
                columns={columns}
                data={clientMasterBookings}
                loading={loading}
                name="clientMasterBookingsList"
                noDataText="No master bookings found"
                padded={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ClientMasterBookingsTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    clientMasterBookings: PropTypes.array,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        })
    }),
    loading: PropTypes.bool.isRequired,
};
ClientMasterBookingsTable.defaultProps = {
    clientMasterBookings: [],
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {}
        }
    },
};
ClientMasterBookingsTable.displayName = 'ClientMasterBookingsTable';

export default graphql(GetMappedIsoCodes)(ClientMasterBookingsTable);
