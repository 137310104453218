import {convertToInt} from '@utils/helpers';
import validators from '@utils/validators';
import {IP_VERSION_IP_V4} from '@constants/variables';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.ip_version, 'ip_version'));

    if (IP_VERSION_IP_V4 === convertToInt(values.ip_version)) {
        Object.assign(errors, validators.cidrIPv4Range(values.cidr, 'cidr'));
        Object.assign(errors, validators.cidrIPv4(values.cidr, 'cidr'));
    } else {
        Object.assign(errors, validators.cidrIPv6Range(values.cidr, 'cidr'));
        Object.assign(errors, validators.cidrIPv6(values.cidr, 'cidr'));
    }

    Object.assign(errors, validators.required(values.description, 'description'));
    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));

    return errors;
};

export default validate;
