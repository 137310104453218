import React from 'react';
import PropTypes from 'prop-types';

import {showModal} from '@utils/modal';

import {ApplicationError} from './ApplicationError';

import Modal from '@appComponents/Modal';
import Sidebar from '@appComponents/Sidebar';
import Header from '@appComponents/Header';
import Footer from '@appComponents/Footer';

import {ENV_ERROR_REPORT} from "@constants/variables";

import {reportError} from '../../../actions';

class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    clearError = () => {
        this.setState(() => ({
            hasError: false,
        }));

        window.location.href = '/';
    }

    componentDidCatch(error, errorInfo) {
        if (ENV_ERROR_REPORT.includes(process.env.NODE_ENV)) {
            // eslint-disable-next-line
            console.error(`APOLLO ERROR: ${error}`);
        }

        showModal({
            header: 'Error',
            content: <ApplicationError clearError={this.clearError}/>,
            isVisible: true,
            size: 'large',
        });

        reportError({
            url: window.location.href,
            error: error + errorInfo.componentStack,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='avcmp__wrapper'>
                    <Modal/>
                    <Sidebar className='avcmp__sidebar'/>
                    <main className='avcmp__main'>
                        <div className='avcmp__header'>
                            <Header/>
                        </div>
                        <div className='avcmp__content content'>
                        </div>
                        <div className='avcmp__footer'>
                            <Footer/>
                        </div>
                    </main>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
