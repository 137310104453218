import {get as _get, has as _has} from 'lodash';
import * as React from 'react';
import PropTypes from 'prop-types';
import {Form as SemanticForm, Input} from 'semantic-ui-react';

import Datepicker from './Datepicker';
import {InputMultiple} from './InputMultiple';
import { ReduxComponent } from './ReduxComponent';

/**
 * Render input for Redux
 */
class SemanticInput extends ReduxComponent {
    static propTypes = {
        addItemShortcuts: PropTypes.arrayOf(PropTypes.string),
        configuration: PropTypes.object,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        defaultValueMultiple: PropTypes.arrayOf(PropTypes.string),
        input: PropTypes.object,
        hidden: PropTypes.bool,
        labelDescription: PropTypes.shape({
            basic: PropTypes.bool,
            content: PropTypes.string,
        }),
        meta: PropTypes.object,
        min: PropTypes.number,
        max: PropTypes.number,
        notice: PropTypes.string,
        placeholder: PropTypes.string,
        onClick: PropTypes.func,
        onlyDigitsAllowed: PropTypes.bool,
        renderLabelParams: PropTypes.shape({
            className: PropTypes.string,
            condition: PropTypes.func,
        }),
        restorePreviousValue: PropTypes.bool,
        step: PropTypes.string,
        type: PropTypes.string,
    };

    static defaultProps = {
        onlyDigitsAllowed: false,
        restorePreviousValue: false,
        step: 'any',
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        this.state = {
            defaultValue: this.props.defaultValue,
        };

        this.elementRef = React.createRef();
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    /**
     * @ignore
     */
    componentWillMount() {
        this.props.input.onChange(this.props.defaultValue);
    }

    componentDidMount() {
        if (this.props.hasAutoFocus) {
            this.elementRef.current.focus();
        }
    }

    /**
     * @ignore
     */
    componentWillReceiveProps(nextProps) {
        if ('date' !== nextProps.type) {
            if (this.props.defaultValue !== nextProps.defaultValue) {
                this.props.input.onChange(nextProps.defaultValue);
            }
        }
    }

    componentWillUpdate(props) {
        if (
            'date' !== props.type
            && !props.meta.visited
            && ((0 === props.input.value.length) || (props.input.value !== props.defaultValue))
            && !(('multiple' === props.type) && (undefined === props.defaultValue))
        ) {
            this.props.input.onChange(props.defaultValue);
        }
    }

    /**
     * @ignore
     */
    isInputHidden() {
        return 'id' === this.props.input.name;
    }

    /**
     * @ignore
     */
    onChangeCheckbox(event, data) {
        this.props.input.onFocus();
        this.props.input.onChange(data.checked);

        if (this.props.onCheckboxChange) {
            this.props.onCheckboxChange(data, this.props);
        }
    }

    /**
     * do not allow enter signs: "-", ".", "e", "E", "+"
    **/
    onlyDigitsAllowed = (e) => {
        const regex = /[e,E.\-+]/;
        const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);

        if (key && regex.test(key)) {
            e.preventDefault();
        }
    };

    prepareCheckboxField = (props) => {
        let checked;

        if ('boolean' === typeof this.props.input.checked) {
            checked = this.props.input.checked;
        } else if('boolean' === typeof this.props.input.value) {
            checked = this.props.input.value;
        } else if ('undefined' !== (typeof this.props.checked)) {
            checked = this.props.checked;
        } else if ('undefined' !== (typeof this.props.defaultValue)) {
            checked = this.props.defaultValue;
        }

        const checkedOption = 'undefined' !== typeof checked ? { checked: checked} : {};

        return (
            <SemanticForm.Checkbox
                {...props}
                toggle={'toggle' === this.props.type}
                disabled={this.props.meta.submitting || this.props.disabled}
                error={this.isError(this.props)}
                {...checkedOption}
                onClick={this.props.onClick}
                onChange={this.onChangeCheckbox}
                id={this.getElementComponentId(this.props)}
            />
        );
    };

    prepareDateField = (extendedProps) => (
        <Datepicker
            timestamp={this.props.timestamp}
            {...extendedProps}
            placeholderText={this.props.placeholder}
            configuration={this.props.configuration}
            defaultValue={this.props.defaultValue}
            disabled={this.props.disabled ? this.props.disabled : this.props.meta.submitting}
            error={this.isError(this.props)}
            selected={this.props.selected}
            onChangeDate={this.props.onChangeDate}
            className={this.props.className}
            restorePreviousValue={this.props.restorePreviousValue || false}
            meta={this.props.meta}
        />
    );

    prepareInputMultipleField = (extendedProps) => (
        <InputMultiple
            {...extendedProps}
            addItemShortcuts={this.props.addItemShortcuts}
            className={`--${this.props.input.name}`}
            defaultValueMultiple={this.props.defaultValueMultiple}
            disabled={this.props.disabled ? this.props.disabled : this.props.meta.submitting}
            error={this.isError(this.props)}
            input={this.props.input}
            meta={this.props.meta}
            onChangeMultipleValue={this.props.onChangeMultipleValue}
            renderLabelParams={this.props.renderLabelParams}>
        </InputMultiple>
    );

    prepareInputField = (extendedProps, shouldIgnoreLastPass) => (
        <SemanticForm.Field
            className={
                (this.isInputHidden() || this.props.hidden) ? 'hidden' : `--${this.props.input.name}`
            }
        >
            <Input
                ref={this.elementRef}
                icon={this.props.icon}
                disabled={this.props.meta.submitting}
                { ...extendedProps }
                error={this.isError(this.props)}
                placeholder={this.props.placeholderText || ''}
                type={this.props.type || 'text'}
                size={this.props.size || 'small'}
                onKeyPress={this.props.onlyDigitsAllowed ? this.onlyDigitsAllowed : null}
                autoComplete='off'
                className=''
            >
                {shouldIgnoreLastPass ? <input data-lpignore='true' /> : null}
            </Input>
        </SemanticForm.Field>
    );

    /**
     * @ignore
     */
    renderContent() {
        const props = {
                disabled: this.props.meta.submitting,
                error: this.isError(this.props),
            },
            shouldIgnoreLastPass = _get(this.props, 'shouldIgnoreLastPass', false);

        if (this.props.autoFocus) {
            props.autoFocus = true;
        }

        const extendedProps = Object.assign({},
            props,
            {min: this.props.min},
            {max: this.props.max},
            this.props.input,
            {className: this.isInputHidden() || this.props.hidden ? 'hidden' : `--${this.props.input.name}`}
        );

        if (this.props.disabled) {
            extendedProps.disabled = this.props.disabled;
        }

        if (this.props.loading) {
            extendedProps.loading = this.props.loading;
        }

        if (this.props.labelDescription) {
            extendedProps.label = Object.assign(
                {basic: this.props.labelDescription.basic === undefined ? true : this.props.labelDescription.basic},
                this.props.labelDescription
            );

            extendedProps.labelPosition = this.props.labelPosition || 'right';
        }

        if (_has(this.props, 'customRef')) {
            extendedProps.ref = this.props.customRef;
        }

        if ('number' === this.props.type) {
            extendedProps.step = this.props.step;
        }

        switch (this.props.type) {
            case 'checkbox':
            case 'toggle': {
                return this.prepareCheckboxField(props);
            }
            case 'date':
                return this.prepareDateField(extendedProps);
            case 'multiple':
                return this.prepareInputMultipleField(extendedProps);
            default:
                return this.prepareInputField(extendedProps, shouldIgnoreLastPass);
        }
    }

    renderClassNames() {
        const classNames = [];

        if (this.props.hidden) {
            classNames.push('hidden');
        }

        if (this.isError(this.props)) {
            classNames.push('error');
        }

        if (this.props.divClassName) {
            classNames.push(this.props.divClassName);
        }

        return classNames.join(' ');
    }

    /**
     * Render description for INPUT:
     * For element type checkbox/toggle:
     * - if description is defined as string (not icon component), description is clickable (label not clickable)
     * - other cases - display normal description
     * @returns {*}
     */
    renderDescription() {
        if (this.isDescriptionClickable(this.props)) {
            return (
                <div className='input_description'>
                    <label className='--withPointer labelDescription' htmlFor={this.getElementComponentId(this.props)}>
                        {this.props.description}
                    </label>
                </div>
            );
        }

        return <div className='input_description'>{this.props.description}</div>;
    }

    /**
     * @ignore
     */
    render() {
        let labelForInput,
            descriptionClassName = '';

        if (!this.isInputHidden()) {
            labelForInput = this.renderLabel(this.props);
        }

        if (this.props.description || this.props.notice) {
            if ('checkbox' === this.props.type) {
                descriptionClassName = '--description-checkbox';
            } else {
                descriptionClassName = '--description';
            }
        }

        descriptionClassName += ` ${(this.props.className || '')}`;

        return (
            <div className={this.renderClassNames()}>
                {labelForInput}
                <div className={`input-container ${descriptionClassName}`}>
                    <div className='form__field-wrapper'>
                        {this.renderContent()}
                        {this.renderDescription()}
                        {this.props.notice && <div className='input_notice'>{this.props.notice}</div>}
                    </div>
                    <div className='error info'>{this.isError(this.props) ? this.props.meta.error : ''}</div>
                </div>
            </div>
        );
    }
}

export default SemanticInput;
