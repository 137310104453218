import validators from '../../../../utils/validators';

const validatorAddUserFromExistingIamAccount = (values) => {
    let errors = {};

    Object.assign(errors, validators.required(values.roles, 'roles'));

    return errors;
};

export default validatorAddUserFromExistingIamAccount;
