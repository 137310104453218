import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {Button, Grid} from 'semantic-ui-react';

import Form from '../ReduxFormControls';

export function getEditableGridFormWithRedux(mainProps) {
    if (mainProps === undefined) return null;

    class EditableGridForm extends React.Component {
        static propTypes = {
            handleSubmit: PropTypes.func.isRequired,
        };

        renderField = (key) => {
            return (
                <Form.FormRow
                    component={mainProps.columns[key].component}
                    {...mainProps.columns[key].componentProps}
                    defaultValue={mainProps.onUpdate && mainProps.formData[key] ? mainProps.formData[key] : ''}
                />
            );
        };

        renderButtons = () => {
            if (undefined !== mainProps.onCreate) {
                return this.renderButtonsForAddForm();
            } else {
                return this.renderButtonsForEditForm();
            }
        };

        renderButtonsForAddForm = () => {
            return (
                <Button
                    disabled={mainProps.submitting}
                    icon='plus'
                    type='submit'
                />
            );
        };

        processUpdate = (data) => {
            mainProps.onDeactivateEditableCell();
            mainProps.onUpdate(data);
        };

        renderButtonsForEditForm = () => {
            return (
                <Button.Group>
                    <Button
                        icon='checkmark'
                        key='update-button'
                        positive
                        type='submit'
                    />
                    <Button
                        icon='ban'
                        key='cancel-button'
                        negative
                        onClick={mainProps.onDeactivateEditableCell}
                        type='button'
                    />
                </Button.Group>
            );
        };

        onFormSubmit(data) {
            if (mainProps.onCreate !== undefined) {
                mainProps.onCreate(data);
            } else {
                this.processUpdate(data);
            }
        }

        rowRenderer = () => {
            return Object.keys(mainProps.columns).map((key) => {
                return (
                    <Grid.Column key={key} width={mainProps.columns[key].width}>
                        {'actions' === key ? this.renderButtons() : this.renderField(key)}
                    </Grid.Column>
                );
            });
        };

        render() {
            return (
                <Form.Create
                    onSubmit={this.props.handleSubmit((values) => {
                        return this.onFormSubmit(values);
                    })}
                >
                    <Form.IdField
                        defaultValue={mainProps.formData && mainProps.formData.id ? mainProps.formData.id : ''}
                        name='id'
                    />
                    <Grid columns={mainProps.columns.length} verticalAlign='middle'>
                        <Grid.Row>
                            {this.rowRenderer()}
                        </Grid.Row>
                    </Grid>
                </Form.Create>
            );
        }
    }

    return React.createElement(reduxForm({form: mainProps.formName, validate: mainProps.validator})(EditableGridForm));
}
