import Form from "../../../app/components/ReduxFormControls";
import validatorClientPackageGeoRestriction from "../validatorClientPackageGeoRestriction";

import {CLIENT_PACKAGE_GEO_RESTRICTION} from "../../../app/constants/resources";
import * as MESSAGES from "../../../app/constants/messages";

import {GeoRestrictionsDropdownsOptions} from "../../../../graphql/clientPackageGeoRestriction/query.graphql"

export default function GeoRestrictionsGeneralInformationModel() {
    return {
        editForm: false,
        entityDataMapKey: "clientPackageGeoRestriction",
        entityLabel: "geo restriction",
        formName: "ClientPackageGeoRestrictionGeneralInformation",
        label: "geo restriction",
        resources: CLIENT_PACKAGE_GEO_RESTRICTION,
        title: null,
        showChangeLog: true,
        validate: validatorClientPackageGeoRestriction,
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'clientMessage',
                success: 'clientMessage',
            },
            text: MESSAGES
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "clientPackageGeoRestriction.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                }
            },
            usage: {
                props: {
                    component: Form.SemanticCheckboxTree,
                    label: "Usage",
                    name: "usages",
                    noDataText: "All usages have already been overwritten.",
                    required: true,
                    showNoDataText: false,
                }
            },
            notes: {
                dataMapKey: "clientPackageGeoRestriction.notes",
                props: {
                    component: Form.SemanticInput,
                    label: "Notes",
                    name: "notes",
                }
            }
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetClientPackageGeoRestrictionsForTable'],
            },
            update: {
                refetchQueries: ['GetClientPackageGeoRestrictionsForTable'],
            },
            delete: {
                refetchQueries: ['GetClientPackageGeoRestrictionsForTable'],
            },
        },
        query: {
            getOptions: GeoRestrictionsDropdownsOptions
        }
    }
}
