import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isEmpty as _isEmpty} from 'lodash';

import {renderEventStatusName} from '@utils/status';
import {isValidMatchStatusId} from '@modules/liveControl/utils/helpers';

const ListingInfo = ({data}) => {
    const renderItems = (items) => {
        return items.map((item) => {
            const matchStatus = isValidMatchStatusId(item.id);

            return (<div className={classnames('listingInfo__item', item.classname)} key={item.name}>
                <label className='listingInfo__name'>{item.name}</label>
                <div className={`--flex --alignCenter ${item.image ? '--paddingLeft-0-5rem' : ''}`}>
                    {item.image}
                    {'Status' === item.name && !_isEmpty(item.id) ?
                        renderEventStatusName(item.id, item.value, matchStatus)
                        : <div className='listingInfo__value'>{!_isEmpty(item.value) ? item.value : '-'}</div>
                    }
                </div>
            </div>);
        });
    };

    return (
        <div className='listingInfo'>
            {renderItems(data)}
        </div>
    );
};

ListingInfo.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.node.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.node,
        ]),
        classname: PropTypes.string,
        image: PropTypes.node,
    })),
};
ListingInfo.defaultProps = {
    data: [],
    image: '',
};

export default ListingInfo;
