import React from "react";
import PropTypes from "prop-types";
import {Header} from "semantic-ui-react";

import Form from "../../app/components/ReduxFormControls";
import {IconWithTooltip} from "../../app/components/IconCollection";
import EventContentAlertClientsTable from "../components/EventContentAlertClientsTable";

import * as MESSAGES from "../../app/constants/messages";
import {EVENT_CONTENT_ALERT} from "../../app/constants/resources";
import {
    EVENT_CONTENT_BOOKED_BY_CLIENT,
    EVENT_CONTENT_OFFERED_BY_CLIENT,
} from "../../app/constants/variables";

import {sendAlert} from "../../../graphql/liveControl/alerts";
import {eventContentAlertOptions} from "../../../graphql/eventContent/query.graphql";

import validate from "./validator";
import {validatorTypes} from "../../../utils/validators";

export default function EventContentAlertModel() {
    const INFO_CONTENT = <span>Placeholders:<br/>
        [EC] will be replaced by the event content name.<br/>
        [T] will be replaced by the event content start time.<br/>
        [HC] will be replaced by the home competitor name.<br/>
        [AC] will be replaced by the away competitor name.
    </span>;

    const renderTextareaRow = (props) => (
        <div className="formRow --relative">
            <Form.SemanticTextArea {...props}/>
            <IconWithTooltip
                name="info circle"
                tooltip={INFO_CONTENT}
                popupProps={{
                    wide: "very",
                    position: "top left"
                }}
                iconProps={{
                    className: "tooltipForPlaceholders"
                }}
            />
        </div>
    );

    const renderRecipients = (props) => (<EventContentAlertClientsTable {...props} clients={props.options}/>);

    renderRecipients.propTypes = {
        options: PropTypes.array
    };

    const renderTemplateMessage = (data) => (
        <div>
            <label>
                <span className="form-input-required"> </span>
            </label>
            <span className="input-container --preLine">{data.text}</span>
        </div>
    );

    const recipientsHeader = () => (<Header>Recipients</Header>);

    return {
        entityLabel: "Alert",
        formName: "sendAlert",
        resources: EVENT_CONTENT_ALERT,
        showChangeLog: false,
        validate: validate,
        title: () => ("New alert"),
        messages: {
            boxName: {
                success: "formInnerMessage",
                error: "formInnerErrorMessage"
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => "The alert could not be sent. Please correct the highlighted fields below."
            }
        },
        fields: {
            type: {
                optionsKey: "eventContentAlertTypes",
                props: {
                    component: Form.SemanticSelect,
                    name: "type",
                    allowClear: false,
                    label: "Type",
                    required: true,
                    type: "select",
                    className: "--small-width"
                }
            },
            template: {
                optionsKey: "eventContentAlertTemplates",
                props: {
                    component: Form.SemanticSelect,
                    name: "template",
                    required: true,
                    disabled: true,
                    label: "Template",
                    type: "select",
                }
            },
            message: {
                props: {
                    component: renderTextareaRow,
                    rows: 3,
                    disabled: true,
                    name: "message",
                    label: "Message",
                    required: true
                }
            },
            template_message: {
                props: {
                    name: "template_message",
                    component: renderTemplateMessage,
                    label: " ",
                    hidden: true
                }
            },
            recipients_header: {
                props: {
                    name: "recipients_header",
                    component: recipientsHeader
                }
            },
            recipient_type: {
                props: {
                    name: "recipient_type",
                    label: "Type",
                    component: Form.SemanticCheckboxList,
                    props: {
                        options: [
                            {
                                key: "type1",
                                value: EVENT_CONTENT_BOOKED_BY_CLIENT,
                                text: "Event content is booked by the client"
                            },
                            {
                                key: "type2",
                                value: EVENT_CONTENT_OFFERED_BY_CLIENT,
                                text: "Event content is offered to the client"
                            },
                        ]
                    }
                }
            },
            distributions: {
                props: {
                    label: "Distributions",
                    name: "distributions",
                    component: Form.SemanticCheckboxList,
                }
            },
            recipients: {
                optionsKey: "clients",
                props: {
                    name: "recipients",
                    component: renderRecipients
                }
            },
            additional_recipients: {
                props: {
                    name: "additional_recipients",
                    validate: validatorTypes.multipleEmail.validate,
                    component: Form.SemanticInput,
                    type: "multiple",
                    addItemShortcuts: [',', ';'],
                    className: "additionalRecipients",
                    renderLabelParams: {
                        className: 'invalid',
                        condition: validatorTypes.email.validate
                    },
                    label: "Additional recipients"
                }
            }
        },
        mutation: {
            createEntity: sendAlert
        },
        query: {
            getOptions: eventContentAlertOptions
        },
    }
}
