
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MasterBookingTypeListData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MasterBooking"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"client"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"product"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProductBasicShortData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"client_package"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"booking_type"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"master_booking_geo_restrictions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MasterBookingGeoRestrictionDetailedData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"creation_datetime"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":495}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n#import \"../masterBookingGeoRestriction/fragment.graphql\"\n#import \"../product/fragment.graphql\"\n\nfragment MasterBookingTypeListData on MasterBooking {\n    id\n    client {\n        ...BasicData\n    }\n    product {\n        ...ProductBasicShortData\n    }\n    client_package {\n        ...BasicData\n    }\n    booking_type {\n        ...BasicData\n    }\n    master_booking_geo_restrictions {\n        ...MasterBookingGeoRestrictionDetailedData\n    }\n    creation_datetime\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../masterBookingGeoRestriction/fragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../product/fragment.graphql").definitions));


      module.exports = doc;
    
