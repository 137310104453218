import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {routes} from "../../modules/app/constants/routes";
import {EXPORT_EXCEL_URL_FRAGMENT} from "../../modules/app/constants/variables";

export const createExportExcelProps = (exportParams) => {
    if ({} === exportParams) {
        return {};
    }

    const {filename, titles, parseData, path, dataExport} = exportParams;

    return {
        dataExport,
        dataSet: [{
            columns: titles ? titles.map(title => ({title: title})): [],
            data: (dataExport && 0 < dataExport.length)
                ? dataExport.map(row => parseData(row))
                : [titles.map((() => ''))]
        }],
        filename,
        url: `${_get(routes, path)}${EXPORT_EXCEL_URL_FRAGMENT}`
    }
};

createExportExcelProps.propTypes = {
    exportParams: PropTypes.shape({
        dataExport: PropTypes.array.isRequired,
        filename: PropTypes.string.isRequired,
        parseData: PropTypes.func.isRequired,
        path: PropTypes.string.isRequired,
        titles: PropTypes.array.isRequired
    }).isRequired,
    routes: PropTypes.object.isRequired,
};
