import React from 'react';
import {sortBy as _sortBy} from 'lodash';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';

/* eslint import/no-unresolved: 0 */
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {Link} from '@appComponents/Link';
import {Authorization} from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import SegmentHeader from '@appComponents/SegmentHeader';
import {convertToInt} from '@utils/helpers';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {GetOverlaysForTable} from '@graphql/overlay/query';

import OverlayTable from '../components/OverlayTable';

const authorizationObject = {
    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
    [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
    [RESOURCES.OVERLAY]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

export const OverlayIndex = (props) => {
    const {DataOverlays, match} = props;

    const addButton = () => {
        return (
            <Authorization authorization={authorizationObject}>
                <Link
                    name={'propertyLicences.technicalSetup.overlays.add'}
                    params={{id: convertToInt(match.params.id)}}>
                    <ButtonAdd>Add new overlay</ButtonAdd>
                </Link>
            </Authorization>
        );
    };

    let overlays = _sortBy(DataOverlays.overlays, 'name');

    return (
        <div>
            <SegmentHeader buttons={addButton()} dividing={false} />
            <Segment basic className='--table'>
                <OverlayTable
                    loading={DataOverlays.loading}
                    propertyLicenceId={convertToInt(match.params.id)}
                    overlays={overlays}
                />
            </Segment>
        </div>
    );
};

const OverlaysWithGraphQL = compose(
    graphql(GetOverlaysForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    propertyLicenceTechSetup: props.technicalSetupId,
                },
            };
        },
        name: 'DataOverlays',
    })
)(OverlayIndex);

OverlayIndex.propTypes = {
    DataOverlays: PropTypes.shape({
        loading: PropTypes.bool,
        overlays: PropTypes.arrayOf(PropTypes.object),
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    technicalSetupId: PropTypes.number.isRequired,
};

OverlayIndex.defaultProps = {
    DataOverlays: {
        loading: false,
        overlays: [],
    },
};

export default withRouter(OverlaysWithGraphQL);
