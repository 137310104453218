import {get as _get} from 'lodash';
import {withRouter} from 'react-router';

import {convertToInt} from '@utils/helpers';

import ProductGeoRestrictionsForm from './ProductGeoRestrictionsForm';

export class ProductQbGeoRestrictionsForm extends ProductGeoRestrictionsForm {
    getEntityClientProductId = () => {
        return convertToInt(_get(this.props.GraphQLEntityData, 'clientProductsQb[0].id', 0));
    };

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'clientProductsQb[0].client_product', {});
    };
}

export default withRouter(ProductQbGeoRestrictionsForm);
