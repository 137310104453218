import {isEmpty as _isEmpty} from "lodash";
import React from "react";
import {Loader} from "semantic-ui-react";

import DefaultForm from "../../app/components/DefaultForm";
import {IconInfoCircle} from "../../app/components/IconCollection";

import {prepareTimezones, prepareUserIp} from "../utils/formHelper";
import {ipInfoDataForTooltip, getUserCountryByIp, getClientIpV4, getClientIpV6} from "./helpers/userSettingsLocation";

export default class UserSettingsForm extends DefaultForm {
    static AUTO_TIMEZONE_TEXT = 'Set automatically based on your location';

    componentDidMount() {
        this.setField('timezone', {
            onChange: this.onTimezoneDropdownChange,
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        if (!this.state.isUserIpPrepared && (!_isEmpty(this.props.GraphQLEntityData.userSettings))) {
            const userIp = prepareUserIp(
                    this.props.GraphQLEntityData.userSettings.detected_country,
                    this.props.GraphQLEntityData.userSettings.detected_ip
                ),
                timezoneDescription = this.prepareTimezoneDescription(null === this.props.GraphQLEntityData.userSettings.timezone),
                selectedTimeZone = this.props.GraphQLEntityData.userSettings.timezone || UserSettingsForm.AUTO_TIMEZONE_TEXT;

            this.setField('timezone', {
                defaultValue: selectedTimeZone,
                description: timezoneDescription,
            });

            const ipData = (
                <React.Fragment>
                    Your IP is {userIp} <Loader active inline size='tiny' className='--marginLeft-0-3-rem' />
                </React.Fragment>
            );

            this.setField('ip', {defaultValue: ipData});
            this.getClientLocationData(userIp);
            this.setState(() => ({isUserIpPrepared: true}));
        }

        if (!this.state.areTimezonesPrepared && (!_isEmpty(this.props.GraphQLEntityData.timezones))) {
            const timezones = prepareTimezones(this.props.GraphQLEntityData.timezones);

            this.setField('timezone', {options: timezones});

            this.setState(() => ({areTimezonesPrepared: true}));
        }
    }

    async getClientLocationData(userIp) {
        const ipData = {
            ipV4: {
                ipAddress: "",
                country: "",
                isErrorOccurred: false,
            },
            ipV6: {
                ipAddress: "",
                country: "",
                isErrorOccurred: false,
            },
        };

        try {
            ipData.ipV4.ipAddress = await getClientIpV4();

            if (ipData.ipV4.ipAddress) {
                const countryIpv4Response = await getUserCountryByIp(ipData.ipV4.ipAddress);
                ipData.ipV4.country = countryIpv4Response.data.locationData;
            }
        } catch (err) {
            ipData.ipV4.isErrorOccurred = true;
        }

        try {
            ipData.ipV6.ipAddress = await getClientIpV6();

            if (ipData.ipV6.ipAddress) {
                const countryIpv6Response = await getUserCountryByIp(ipData.ipV6.ipAddress);
                ipData.ipV6.country = countryIpv6Response.data.locationData;
            }
        } catch (err) {
            ipData.ipV6.isErrorOccurred = true;
        }

        const ipContent = (
            <React.Fragment>
                Your IP is {userIp}
                {
                    <IconInfoCircle
                        header="IPs detected on Akamai:"
                        tooltip={ipInfoDataForTooltip(ipData.ipV4, ipData.ipV6)}
                        className="--marginLeft-0-3-rem"
                        hoverable
                    />
                }
            </React.Fragment>
        );

        this.setField('ip', {defaultValue: ipContent});
    }

    shouldComponentUpdate(nextProps) {
        return !_isEmpty(nextProps.GraphQLEntityData.userSettings);
    }

    onTimezoneDropdownChange = (event, value) => {
        const timezoneDescription = this.prepareTimezoneDescription(UserSettingsForm.AUTO_TIMEZONE_TEXT === value);

        this.setField('timezone', {
            description: timezoneDescription,
        });
    };

    prepareTimezoneDescription = (condition) => (
        (condition)
            ? `The time zone of your location is: ${this.props.GraphQLEntityData.userSettings.autodetected_timezone}`
            : ''
    );

    renderCancelButton = () => null;

    renderDeleteButton = () => null;

    prepareDataForSubmit = (data) => Object.assign({}, data)
}
