import {
    get as _get,
    has as _has,
    intersection as _intersection,
    isEqual as _isEqual,
    sortBy as _sortBy,
    union as _union,
    unionWith as _unionWith,
} from 'lodash';
import moment from 'moment';
import React from 'react';
import {withRouter} from 'react-router';
import {Header, Modal} from 'semantic-ui-react';

import DefaultForm from '@appComponents/DefaultForm';
import Link, {getLink} from '@appComponents/Link';
import {transformMomentDatetimeToDate} from '@utils/date';
import {convertToInt} from '@utils/helpers';
import {setErrorModal} from '@utils/modal';
import {getDataForMasterEventByPropertyLicence} from '@graphql/events/query';
import {EVENT_SPORTRADAR_TYPE, SPORTS_COURT_LIST} from '@constants/variables';

import {renderModalErrorObject, setCanDeleteState} from '../utils/eventContentHelper';
import {CANT_DELETE_EVENT} from '../constants/messages';

export class SportradarMasterEventForm extends DefaultForm {
    constructor(props) {
        super(props);

        this.state['tournamentProductsDates'] = [];
        this.state['eventStartDateRanges'] = [];
    }

    filterStartDatetime = (date) => {
        for ( let i = 0; i < this.state.eventDates.length; i++) {
            let dateValid = this.checkDateRange(date, this.state.eventDates[i]);

            if (dateValid) {
                return true
            }
        }

        return false;
    };

    checkDateRange = (date, datesObject) => {
        date = transformMomentDatetimeToDate(date);

        return (date.isSameOrAfter(datesObject.start_datetime) && date.isSameOrBefore(datesObject.end_datetime))
    };

    filterEndDatetime = (date) => {
        date = transformMomentDatetimeToDate(date);

        return date.isSameOrAfter(_get(this.props, "formValues.start_datetime", null))
    };

    setPlaceRow = (masterMatch) => {
        let label = "Venue",
            stadiumCity = _get(masterMatch, "stadium.city.name", false),
            text = _get(masterMatch, "stadium.name", "-") + (stadiumCity ? ` - ${stadiumCity}` : '');

        if (SPORTS_COURT_LIST.includes(convertToInt(masterMatch.sport.id))) {
            label = "Court";
        }

        this.setField("place", {
            label: label,
            data: {text: text}
        })
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (nextProps.GraphQLOptionsData.error) {
            this.props.Modal.setModal({
                content :
                    (<div>
                        <Header icon="warning" color="red" content="Master match not found!"/>
                        <Modal.Content>
                            <p>Master match not found.</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Link name="events.index">
                                Back to events list
                            </Link>
                        </Modal.Actions>
                    </div>)
            });

            return
        }

        if (
            !this.state.optionParsed
            && nextProps.GraphQLOptionsData.masterMatch
            && (
                (
                    !this.props.Model.editForm
                )
                || (
                    this.props.Model.editForm
                    && _has(nextProps.GraphQLEntityData.event, 'sr_event')
                )
            )
        ) {
            let masterMatch = _get(nextProps, "GraphQLOptionsData.masterMatch");

            this.setField("matchDescription", {
                data:{ text:  masterMatch.description}
            });

            this.setField("sport", {
                data:{ text:  _get(masterMatch, "sport.name", "")}
            });

            if (!this.props.Model.editForm) {
                this.setField("start_datetime", {
                    defaultValue: masterMatch.start_datetime
                });
                this.setField("end_datetime", {
                    defaultValue: masterMatch.end_datetime
                });
                this.setField("description", {
                    defaultValue:  masterMatch.description,
                });
            }

            this.setPlaceRow(masterMatch);

            this.setState(() => ({
                optionParsed: true,
                masterMatchId: masterMatch.id,
                masterMatchStartDate: moment(masterMatch.start_datetime).format("YYYY-MM-DD HH:mm"),
                masterMatchEndDate: moment(masterMatch.end_datetime).format("YYYY-MM-DD HH:mm"),
                masterMatchTournaments: masterMatch.master_match_tournaments
            }));

            if (this.props.Model.editForm) {
                let selectedTournamentProducts = {};
                _get(
                    nextProps.GraphQLEntityData.event.sr_event,
                    "master_event_tournament_products",
                    []
                ).forEach((item) => {
                    selectedTournamentProducts[`${item.master_match_tournament.id}_${item.product.id}`] = item.id;
                });

                this.getDataForTournamentFieldByPropertyLicenceAndDate(
                    _get(nextProps, "GraphQLEntityData.event.property_licence.id", null),
                    masterMatch.start_datetime,
                    selectedTournamentProducts
                );

                let syncTimes = _get(
                    nextProps.GraphQLEntityData.event.sr_event,
                    "synchronise_times",
                    true
                );

                this.setField("synchronise_times", {
                    defaultValue: syncTimes
                });

                this.setField(["start_datetime", "end_datetime"], {
                    disabled: syncTimes,
                });
            }
        }
    }

    setStateHandler = (stateObject) => {
        this.setState(() => (stateObject));
    };

    componentDidMount() {
        const eventId = _get(
            this.props,
            'formParams.id',
            null
        );

        'undefined' == typeof this.state.canDelete
        && eventId
        && setCanDeleteState(eventId, this.setStateHandler, true);
    }

    getDataForTournamentFieldByPropertyLicenceAndDate = (propertyLicenceId, date, selected = {}) => {
        this.setField("tournament_products", {
            loading: true
        });

        let isEditForm = this.props.Model.editForm;

        this.runApolloRequest('query', {
            fetchPolicy: "network-only",
            query: getDataForMasterEventByPropertyLicence,
            variables: {
                propertyLicenceId:propertyLicenceId,
            }
        }).then((response) => {
            let rightsScopes = _get(response, 'data.rightsScopes', []),
                propertyLicenceContents = _get(response, 'data.propertyLicenceContents', []),
                tournaments = {},
                products = {};

            this.state.masterMatchTournaments.forEach((tournamentObject) => {
                let tournament = tournamentObject.tournament;

                tournaments[tournament.id] = {
                    id: tournamentObject.id,
                    name: tournament.tournament_category.name + " > " + tournament.name,
                    products: {},
                    matchTournamentId: tournamentObject.id
                }
            });

            let masterMatchTournamentIds = Object.keys(tournaments),
                eventDates = [];

            rightsScopes.forEach((rightScope) => {
                let rightScopeTournamentIds = [],
                    rightScopeContents = propertyLicenceContents;

                if (!rightScope.includes_all_content) {
                    rightScopeContents = rightScope.contents;
                }

                rightScopeTournamentIds = _union(rightScopeTournamentIds,
                    rightScopeContents.map((rightScopeContent) => {
                        return rightScopeContent.tournament.id
                    })
                );

                const masterMatchesTournamentsWithRightScopeTournaments =
                        _intersection(masterMatchTournamentIds, rightScopeTournamentIds);

                masterMatchesTournamentsWithRightScopeTournaments.forEach((tournamentId) => {
                    const matchTournamentId = tournaments[tournamentId].matchTournamentId;

                    eventDates = _unionWith(eventDates, [{
                        start_datetime: rightScope.start_date,
                        end_datetime: rightScope.end_date
                    }], _isEqual);

                    if (!moment(date).isBetween(moment(rightScope.start_date), moment(rightScope.end_date))) {
                        return
                    }

                    rightScope.usages.forEach((usage) => {
                        products[usage.product.id] = usage.product.short_name;
                        tournaments[tournamentId].products[usage.product.id] = null;

                        let tournamentProductKey = `${matchTournamentId}_${usage.product.id}`;

                        if (!isEditForm) {
                            selected[tournamentProductKey] = null;
                        }
                    });
                });
            });

            tournaments = _sortBy(tournaments, 'name');

            this.setField("tournament_products", {
                columns: products,
                rows: tournaments,
                loading: false,
                selected: selected,
            });

            this.setState(() => ({
                products: products,
                tournaments: tournaments,
                eventDates: eventDates
            }));
        })
    };

    componentWillMount() {
        super.componentWillMount();

        this.setValuesParsers({
            color: (value) => {
                if (value) {
                    return "#" + value
                }

                return null
            }
        });

        this.setField("start_datetime", {
            configuration: {
                ...this.props.Model.fields.start_datetime.props.configuration,
                filterDate: this.filterStartDatetime,
            }
        });

        this.setField("end_datetime", {
            configuration: {
                ...this.props.Model.fields.end_datetime.props.configuration,
                filterDate: this.filterEndDatetime,
            }
        });

        this.setOnChangeCallback({
            property_licence: (data) => {
                this.setField("tournament", {
                    loading: true
                });

                this.getDataForTournamentFieldByPropertyLicenceAndDate(
                    parseInt(data.value, 10),
                    this.state.masterMatchStartDate
                );

                if (!this.props.formValues.synchronise_times) {
                    this.setField(["start_datetime", "end_datetime"], {
                        disabled: false,
                    });
                }
            },
            synchronise_times: (data) => {
                let isPropertyLicenceSelected = !!this.props.formValues.property_licence;

                if (!isPropertyLicenceSelected) {
                    this.setField(["start_datetime", "end_datetime"], {
                        disabled: true
                    });

                    return
                }

                if (!data.value) {
                    this.setField("start_datetime", {
                        selected: this.state.masterMatchStartDate,
                        disabled: true
                    });
                    this.setField("end_datetime", {
                        selected: this.state.masterMatchEndDate,
                        disabled: true
                    });
                } else {
                    this.setField(["start_datetime", "end_datetime"], {
                        disabled: false
                    });
                }
            },
        });

        this.setOptionParsers({
            property_licence: (data) => {
                let propertyLicencesList = [];

                for (let i = 0; i < data.length; i++) {
                    const propertyLicence = data[i];

                    propertyLicencesList.push({
                        id: propertyLicence.id,
                        key: propertyLicence.id,
                        text: `${propertyLicence.name} - ${propertyLicence.licensor.name}`,
                        value: propertyLicence.id
                    })
                }

                return _sortBy(propertyLicencesList, (item) => item.text.toLocaleLowerCase());
            }
        });

        this.setCreateErrorCallback(() => {

            this.props.Modal.setModal({
                isVisible: false
            })
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink("events.configuration.index", {id: response.data.createSrEvent.event.id}));
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink("events.index"));
        });

        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink("events.configuration.index", {id: this.props.formParams.id}));
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({
                isVisible: false
            })
        });
    }

    prepareDataForSubmit(data) {
        const dataForSubmit = {
            event: {
                start_datetime: data.start_datetime,
                end_datetime: data.end_datetime ? data.end_datetime : null,
                description: data.description,
                color: data.color.substring(1),
                event_status: convertToInt(data.event_status),
                notes: _get(data, 'notes', null),
            },
            master_event_tournament_products: Object.keys(data.tournament_products).map((tournamentProductKey) => {
                const tournamentProductsSplit = tournamentProductKey.split('_');

                return {
                    id: 'null' === data.tournament_products[tournamentProductKey]
                        ? null : data.tournament_products[tournamentProductKey],
                    master_match_tournament: convertToInt(tournamentProductsSplit[0]),
                    product: convertToInt(tournamentProductsSplit[1]),
                };
            }),
            id: data.id,
            synchronise_times: data.synchronise_times,
        };

        if (!this.props.Model.editForm) {
            dataForSubmit.event.property_licence = convertToInt(data.property_licence);
            dataForSubmit.event.event_type = EVENT_SPORTRADAR_TYPE;
            dataForSubmit.event.is_master = true;
            dataForSubmit.master_match = convertToInt(this.state.masterMatchId);

            delete dataForSubmit.id;
        }

        return dataForSubmit;
    }

    redirectToEventEditPage = () => {
        window.location.href = getLink('events.edit', {
            id: _get(this.props, 'formParams.id', null),
        });
    };

    renderDeleteButton = () => {
        return this.state.canDelete
            ? super.renderDeleteButton()
            : super.renderDeleteButton({
                onClick: () => setErrorModal(
                    renderModalErrorObject(CANT_DELETE_EVENT, this.redirectToEventEditPage)
                ),
            });
    };
}


export default withRouter(SportradarMasterEventForm);
