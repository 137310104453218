import {CreateWnsStream, DeleteWnsStream, UpdateWnsStream} from '@graphql/wnsStream/mutation';
import {GetWorldNumberServiceForForm} from '@graphql/wnsStream/query';
import {WNS_STREAM} from '@constants/resources';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import validate from '@modules/wnsStream/forms/validator';

export default function WnsStreamModel() {
    return {
        entityDataMapKey: 'worldNumberService',
        entityLabel: 'World Number Service stream',
        formName: 'World Number Service Stream',
        label: 'World Number Service Stream',
        resources: WNS_STREAM,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            worldNumberService: {
                id: null,
                name: '',
                stream_name: '',
            },
        },
        messages: {
            boxName: {
                delete: 'wnsMessageBox',
                error: 'wnsMessageBox',
                success: 'wnsMessageBox',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'worldNumberService.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'worldNumberService.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            stream_name: {
                dataMapKey: 'worldNumberService.stream_name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Stream name',
                    name: 'stream_name',
                    required: true,
                    type: 'input',
                    className: '--name',
                },
            },
        },
        mutation: {
            createEntity: CreateWnsStream,
            deleteEntity: DeleteWnsStream,
            updateEntity: UpdateWnsStream,
        },
        query: {
            getEntity: GetWorldNumberServiceForForm,
        },
    };
}
