import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dimmer, Divider, Grid, Loader} from 'semantic-ui-react';
import {includes as _includes} from 'lodash';

import {hasPrivileges} from '@appComponents/Authorization';

import PropertyLicenceContentForm from '../forms/PropertyLicenceContentForm';

import {convertToInt} from '@utils/helpers';

import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

const columns = {
    tournament_category: {label: 'Category', showWithPropetyLicenceType: [1, 2], width: 6},
    tournament: {label: 'Tournament', showWithPropetyLicenceType: [1, 2], width: 6},
    content_category_level_2: {label: '2nd level category', showWithPropetyLicenceType: [3], width: 6},
    content_category_level_3: {label: '3rd level category', showWithPropetyLicenceType: [3], width: 6},
    content_tier: {label: 'Tier', showWithPropetyLicenceType: [1, 2, 3], width: 2},
    actions: {label: '', showWithPropetyLicenceType: [1, 2, 3], width: 2},
};

class PropertyLicenceContentTable extends React.Component {
    static defaultProps = {
        addFormData: {},
        contentTiers: [],
        propertyLicence: {},
        propertyLicenceContent: [],
        tournamentCategories: [],
    };

    static propTypes = {
        addFormData: PropTypes.object,
        contentCategoriesSecondLevel: PropTypes.array,
        contentCategoriesThirdLevel: PropTypes.array,
        contentCategoriesThirdLevelLoading: PropTypes.bool,
        contentCategoryThirdLevelValue: PropTypes.string,
        contentTiers: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        onAdd: PropTypes.func,
        onContentCategorySecondLevelChange: PropTypes.func,
        onContentCategoryThirdLevelChange: PropTypes.func,
        onDelete: PropTypes.func,
        onTournamentCategoryChange: PropTypes.func,
        onTournamentChange: PropTypes.func,
        onUpdate: PropTypes.func,
        propertyLicence: PropTypes.object.isRequired,
        propertyLicenceContent: PropTypes.array.isRequired,
        tournamentCategories: PropTypes.array.isRequired,
        tournaments: PropTypes.array,
        tournamentsLoading: PropTypes.bool,
        tournamentValue: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeEditableFields: null,
            authorization: {
                read: hasPrivileges({[RESOURCES.PROPERTY_LICENCE_CONTENT]: CONST.SECURITY_PRIVILEGES_READ}),
                create: hasPrivileges({[RESOURCES.PROPERTY_LICENCE_CONTENT]: CONST.SECURITY_PRIVILEGES_CREATE}),
                update: hasPrivileges({[RESOURCES.PROPERTY_LICENCE_CONTENT]: CONST.SECURITY_PRIVILEGES_UPDATE}),
                delete: hasPrivileges({[RESOURCES.PROPERTY_LICENCE_CONTENT]: CONST.SECURITY_PRIVILEGES_DELETE}),
            },
        };
    }

    activateEditableCell = (event) => {
        this.setState({
            activeEditableFields: event.currentTarget.dataset.id,
        });
    };

    deactivateEditableCell = () => {
        this.setState({
            activeEditableFields: null,
        });
    };

    renderHeader = () => {
        const type = (undefined !== this.props.propertyLicence.property_licence_type_id)
            ? this.props.propertyLicence.property_licence_type_id
            : 0;

        let cells = Object.keys(columns).map((keys) => {
            if (_includes(columns[keys].showWithPropetyLicenceType, convertToInt(type))) {
                return (
                    <Grid.Column key={keys} width={columns[keys].width}>
                        <strong>{columns[keys].label}</strong>
                    </Grid.Column>
                );
            } else {
                return false;
            }
        });

        return (
            <Grid columns={4}>
                <Grid.Row>
                    {cells}
                </Grid.Row>
            </Grid>
        );
    };

    renderButtonsInViewMode = (propertyLicenceContentId) => (
        <Button.Group>
            {this.state.authorization.delete && <Button
                icon='trash'
                onClick={this.props.onDelete}
                type='button'
                data-id={propertyLicenceContentId}
            />}
        </Button.Group>
    );

    renderEditableGrids = () => (
        this.props.propertyLicenceContent.map((data, index) => {
            return (
                [
                    <Divider key={`divider_${index}`}/>,
                    this.renderFormOrEditableGrid(data),
                ]
            );
        })
    );

    renderFormOrEditableGrid = (data) => {
        if (true !== this.state.authorization.update) {
            return this.renderEditableGrid(data);
        }

        return (this.state.activeEditableFields === data.id)
            ? this.renderEditForm(data)
            : this.renderEditableGrid(data);
    }

    renderEditableGrid = (data) => {
        const gridClass = this.state.authorization.update ? '--clickable' : '',
            onClickCallback = this.state.authorization.update ? this.activateEditableCell : null;
        let isStandardSport = false, isOther = false;

        switch (convertToInt(this.props.propertyLicence.property_licence_type_id)) {
            case CONST.PROPERTY_LICENCE_TYPE_SPORTRADAR:
            case CONST.PROPERTY_LICENCE_TYPE_RACE:
                isStandardSport = true;
                break;
            case CONST.PROPERTY_LICENCE_TYPE_OTHER:
                isOther = true;
                break;
        }

        return (
            <Grid className={gridClass} key={data.id} columns={4} verticalAlign='middle'>
                <Grid.Row>
                    {isStandardSport &&
                    <Grid.Column data-id={data.id} onClick={onClickCallback}
                        width={columns.tournament_category.width}>
                        {data.tournament.tournament_category.name}
                    </Grid.Column>
                    }
                    {isStandardSport &&
                    <Grid.Column data-id={data.id} onClick={onClickCallback}
                        width={columns.tournament.width}>
                        {data.tournament.name}
                    </Grid.Column>
                    }

                    {isOther &&
                    <Grid.Column data-id={data.id} onClick={onClickCallback}
                        width={columns.content_category_level_2.width}>
                        {data.content_category.parent.name}
                    </Grid.Column>
                    }
                    {isOther &&
                    <Grid.Column data-id={data.id} onClick={onClickCallback}
                        width={columns.content_category_level_3.width}>
                        {data.content_category.name}
                    </Grid.Column>
                    }

                    <Grid.Column data-id={data.id} onClick={onClickCallback}
                        width={columns.content_tier.width}>
                        {data.content_tier.name}
                    </Grid.Column>
                    <Grid.Column width={columns.actions.width}>
                        {this.renderButtonsInViewMode(data.id)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    renderAddForm = () => (
        <PropertyLicenceContentForm
            addFormData={this.props.addFormData}
            contentTiers={this.props.contentTiers}
            formData={{
                content_tier: {id: this.props.propertyLicence.content_tier_id},
            }}
            onAdd={this.props.onAdd}
            onContentCategorySecondLevelChange={this.props.onContentCategorySecondLevelChange}
            onContentCategoryThirdLevelChange={this.props.onContentCategoryThirdLevelChange}
            onTournamentCategoryChange={this.props.onTournamentCategoryChange}
            onTournamentChange={this.props.onTournamentChange}
            tournamentCategories={this.props.tournamentCategories}
            tournaments={this.props.tournaments}
            tournamentsLoading={this.props.tournamentsLoading}
            tournamentValue={this.props.tournamentValue}
            propertyLicence={this.props.propertyLicence}
            propertyLicenceContent={this.props.propertyLicenceContent}
            contentCategoriesSecondLevel={this.props.contentCategoriesSecondLevel}
            contentCategoriesThirdLevel={this.props.contentCategoriesThirdLevel}
            contentCategoriesThirdLevelLoading={this.props.contentCategoriesThirdLevelLoading}
            contentCategoryThirdLevelValue={this.props.contentCategoryThirdLevelValue}
            columns={columns}
            authorization={this.state.authorization}
        />
    );

    renderEditForm = (data) => (
        <PropertyLicenceContentForm
            form={'form_' + data.id}
            contentTiers={this.props.contentTiers}
            formData={{
                property_licence_content: {id: data.id},
                content_tier: {id: data.content_tier.id},
            }}
            onTournamentCategoryChange={null}
            onUpdate={this.props.onUpdate}
            staticData={{
                tournament: {name: null !== data.tournament ? data.tournament.name : null},
                tournament_category: {name: null !== data.tournament ? data.tournament.tournament_category.name : null},
                content_category_level_3: {name: null !== data.content_category ? data.content_category.name : null},
                content_category_level_2: {name: null !== data.content_category ? data.content_category.parent.name : null},
            }}
            tournamentCategories={null}
            tournaments={null}
            onDeactivateEditableCell={this.deactivateEditableCell}
            propertyLicence={this.props.propertyLicence}
            contentCategoriesSecondLevel={null}
            contentCategoriesThirdLevel={null}
            columns={columns}
            authorization={this.state.authorization}
        />
    );

    render() {
        if (!this.state.authorization.read) {
            return 'Not Authorized';
        }

        return (
            <div>
                <Dimmer inverted active={this.props.loading}>
                    <Loader/>
                </Dimmer>
                {this.renderHeader()}
                {this.state.authorization.create && this.renderAddForm()}
                {this.renderEditableGrids()}
            </div>
        );
    }
}

export default PropertyLicenceContentTable;
