import {get as _get} from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import Table from "../../app/components/Table";

import {AKAMAI_LIVE_STREAM_NAME} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_UPDATE} from "../../app/constants/variables";
import {convertToInt, getParsedId} from "../../../utils/helpers";

const columns = {
    "id": {
        label: "ID",
        sorting: row => getParsedId(row),
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
    "encoding_datacenter": {
        label: "Encoding datacenter",
        sorting: true,
    },
    "stream_name": {
        label: "Stream name",
        sorting: true,
    },
    "stream_protocol": {
        label: "Stream protocol",
        sorting: true,
    },
    "description": {
        label: "Description",
        sorting: true,
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
};

const AkamaiLiveStreamNamesTable = (props) => {
    const {clientId, clientCdnConfigurationId, akamaiLiveStreamNames, loading} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "stream_name":
                return (
                    <LinkWithAuthorization
                        resources={AKAMAI_LIVE_STREAM_NAME}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: "clients.clientCdnConfiguration.akamaiLiveStreamName.edit",
                            params: {
                                id: clientId,
                                clientCdnConfigurationId: clientCdnConfigurationId,
                                akamaiLiveStreamNameId: convertToInt(row.id),
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case "encoding_datacenter":
                return row.encoding_datacenter.name;
            case "stream_protocol":
                return _get(row, 'stream_protocol.name', '-');
            case "description":
                return row[column];
            case "actions":
                return (
                    <Authorization
                        resources={AKAMAI_LIVE_STREAM_NAME}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                    >
                        <div className="actions-list-icon">
                            <IconEdit
                                link={getLink('clients.clientCdnConfiguration.akamaiLiveStreamName.edit', {
                                    id: clientId,
                                    clientCdnConfigurationId: clientCdnConfigurationId,
                                    akamaiLiveStreamNameId: convertToInt(row.id),
                                })}
                                size="large"
                            />
                        </div>
                    </Authorization>
                );
            default:
                return null;

        }
    };

    return (
        <div className="akamai_live_stream_names__table">
            <Table
                name="akamaiLiveStreamNameList"
                noDataText="No Akamai Live stream names found."
                columns={columns}
                data={akamaiLiveStreamNames}
                loading={loading}
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

AkamaiLiveStreamNamesTable.defaultProps = {
    akamaiLiveStreamNames: [],
};

AkamaiLiveStreamNamesTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    clientCdnConfigurationId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    akamaiLiveStreamNames: PropTypes.array,
};

export default AkamaiLiveStreamNamesTable;
