import {get as _get, isEmpty as _isEmpty} from 'lodash';
import moment from 'moment';

import {convertToInt} from '@utils/helpers';
import {CLIENT_AV_INTERNAL} from '@constants/variables';
import {showErrorModal} from '@utils/modal';

const ALLOWED_DAYS_AV_CLIENT = 7;
const ALLOWED_DAYS_REST_CLIENTS = 31;

const getAllowedDays = (clientId) => {
    if (convertToInt(clientId) === CLIENT_AV_INTERNAL) {
        return ALLOWED_DAYS_AV_CLIENT;
    }

    return ALLOWED_DAYS_REST_CLIENTS;
};

const dateEmpty = (from, to) => {
    if (!from || !to) {
        return true;
    }

    return false;
};

const noRequiredFilters = (from, to, clientId, filters) => {
    const fromDate = moment(from, 'YYYY-MM-DD');
    const toDate = moment(to, 'YYYY-MM-DD'),
        differenceDays = moment.duration(toDate.diff(fromDate)).asDays(),
        propertyLicences = _get(filters, 'propertyLicences', []),
        tournamentCategories = _get(filters, 'tournamentCategories', []),
        contentCategoryLevel2 = _get(filters, 'contentCategoryLevel2', []);

    if (differenceDays > getAllowedDays(clientId)
        && _isEmpty(propertyLicences)
        && _isEmpty(tournamentCategories)
        && _isEmpty(contentCategoryLevel2)
    ) {
        return true;
    }

    return false;
};

export const getTodayDate = () => (
    moment().format('YYYY-MM-DD 00:00')
);

export const getTomorrowDate = () => (
    moment().add(1, 'day').format('YYYY-MM-DD 00:00')
);

export const getSixMonthsAgoDate = () => {
    return moment().subtract(6, 'months').format('YYYY-MM-DD 00:00');
};

export const getOneHourAgoDateTime = () => {
    return moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
};

export const getLastMinuteOfToday = () => {
    return moment().hours(23).minutes(59);
};

export const skipSearch = (props) => {
    const from = _get(props, 'filters.from', null),
        to = _get(props, 'filters.to', null),
        clientId = convertToInt(props.match.params.id);

    if (dateEmpty(from, to) || noRequiredFilters(from, to, clientId, props.filters)) {
        return true;
    }

    return false;
};

export const showConstraintsModal = (data) => {
    const headerText = 'Error with filters';

    if (dateEmpty(data.from, data.to)) {
        showErrorModal({
            header: headerText,
            text: 'You must enter a start time and an end time.',
        });
    }

    if (noRequiredFilters(data.from, data.to, data.client, data)) {
        const allowedDays = getAllowedDays(data.client);

        showErrorModal({
            header: headerText,
            text: 'If you use a time range larger than ' + allowedDays + ' days, you also have to select an option '
                + 'in at least one of the following filters: "Property licence", "Category", "2nd level category".',
        });
    }
};
