import {has as _has} from 'lodash';
import React from 'react';

import {IconInfoCircle} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import {ENCODING_TARGET} from '@constants/resources';
import {
    AKAMAI_RTMP_DISTRIBUTION_REGION_ASIA,
    AKAMAI_RTMP_DISTRIBUTION_REGION_EUROPE,
    ENCODER_TYPE_SPORTRADAR,
} from '@constants/variables';
import {DeleteEncodingTargetMutation} from '@graphql/events/encodingTarget';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {
    CreateEncodingTargetManual,
    CreateEncodingTargetSr,
    UpdateEncodingTarget,
} from '@graphql/encodingTarget/mutation';
import {GetEncodingTargetOptionsForForm} from '@graphql/encodingTarget/query';

import validate from './validatorEventContentTarget';
import {DEFAULT_TARGET_FIELD_HINT} from '../constants/messages';

export default function EventContentTargetModel() {
    const renderAkamaiRtmpDistrRegion = (data) => {
        const defaultValues = data.defaultValue;

        return (
            <div className='checkboxInlineContainer'>
                <label>{'Akamai RTMP distribution regions'}</label>
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={(defaultValues)
                        && (-1 < defaultValues.indexOf(AKAMAI_RTMP_DISTRIBUTION_REGION_EUROPE)) || null}
                    description={'Europe'}
                    label={null}
                    name={`akamai_rtmp_distr_regions._${AKAMAI_RTMP_DISTRIBUTION_REGION_EUROPE}`}
                    type='checkbox'
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={(defaultValues)
                        && (-1 < defaultValues.indexOf(AKAMAI_RTMP_DISTRIBUTION_REGION_ASIA)) || null}
                    description={'Asia'}
                    label={null}
                    name={`akamai_rtmp_distr_regions._${AKAMAI_RTMP_DISTRIBUTION_REGION_ASIA}`}
                    type='checkbox'
                />
            </div>
        );
    };

    const renderDeviceCategories = (params) => {
        return (
            <div>
                <label>{params.label}*</label>
                <div>{params.message}</div>
            </div>
        );
    };

    return {
        entityDataMapKey: 'encodingTarget',
        entityLabel: 'encoding target',
        formName: 'EncodingTarget',
        resources: ENCODING_TARGET,
        showChangelog: true,
        validate: validate,
        title: 'Add Encoding Target',
        label: 'encoding target',
        dataMap: {
            encodingTarget: {
                id: '',
                distribution: {
                    id: '',
                },
                device_categories: [],
                is_default: false,
                is_computer_vision: false,
                stream_delay_type: {
                    id: '',
                },
                delay: null,
                cdn: {
                    id: null,
                },
                ingest_method: {
                    id: null,
                },
                akamai_cdn_ingest_method: {
                    id: null,
                },
                has_playback_authentication: true,
                token_ttl: null,
                encoder_type: {
                    id: null,
                },
                encoding_target_manual: {
                    playback_path: '',
                },
                encoding_target_sr: {
                    language: {
                        id: null,
                    },
                    encoding_job_profile: {
                        id: '',
                    },
                    stream_name_prefix: null,
                    has_scoreboard_overlay_support: false,
                    has_scoreboard_overlay: false,
                    scoreboard_overlay_position: {
                        id: null,
                    },
                    has_slate_support: false,
                    has_overlay_support: false,
                    start_encoding_with_overlay: false,
                    overlay: {
                        id: null,
                    },
                    has_ad_support: false,
                    ad_type: {
                        id: null,
                    },
                    has_drm_support: false,
                    has_cc: false,
                    cc_stream_language: {
                        id: null,
                    },
                    client_drm_setups: [],
                    akamai_rtmp_distribution_regions: [],
                },
            },
        },
        messages: {
            boxName: {
                delete: 'targetsInfo',
                error: 'formInnerErrorMessage',
                success: 'targetsInfo',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'encodingTarget.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            distribution: {
                dataMapKey: 'encodingTarget.distribution.id',
                optionsKey: 'distributions',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Distribution',
                    required: true,
                    name: 'distribution',
                },
            },
            distribution_type: {
                props: {
                    component: Form.SemanticInput,
                    label: false,
                    name: 'distribution_type',
                    hidden: true,
                },
            },
            stream_delay_type: {
                dataMapKey: 'encodingTarget.stream_delay_type.id',
                optionsKey: 'streamDelayTypes',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'Stream delay type',
                    name: 'stream_delay_type',
                    required: true,
                },
            },
            is_default: {
                dataMapKey: 'encodingTarget.is_default',
                props: {
                    component: Form.SemanticInput,
                    defaultValue: false,
                    description: <IconInfoCircle tooltip={DEFAULT_TARGET_FIELD_HINT}/>,
                    label: 'Default target?',
                    name: 'is_default',
                    type: 'checkbox',
                },
            },
            is_computer_vision: {
                dataMapKey: 'encodingTarget.is_computer_vision',
                props: {
                    component: Form.SemanticInput,
                    defaultValue: false,
                    hidden: true,
                    label: 'Computer Vision?',
                    name: 'is_computer_vision',
                    type: 'checkbox',
                },
            },
            device_categories: {
                dataMapKey: 'encodingTarget.device_categories',
                props: {
                    component: renderDeviceCategories,
                    deviceCategories: [],
                    label: 'Device categories',
                    message: 'Select distribution to load device categories.',
                    name: 'device_categories',
                    required: true,
                },
            },
            delay: {
                dataMapKey: 'encodingTarget.delay',
                props: {
                    component: Form.SemanticInput,
                    description: 'seconds',
                    hidden: true,
                    label: 'Delay',
                    name: 'delay',
                    type: 'input',
                },
            },
            cdn: {
                dataMapKey: 'encodingTarget.cdn.id',
                optionsKey: 'cdns',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'CDN',
                    name: 'cdn',
                },
            },
            ingest_method: {
                dataMapKey: 'encodingTarget.ingest_method.id',
                optionsKey: 'ingestMethods',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'Ingest method*',
                    name: 'ingest_method',
                    placeholder: 'Select ingest method',
                },
            },
            akamai_cdn_ingest_method: {
                dataMapKey: 'encodingTarget.akamai_cdn_ingest_method.id',
                optionsKey: 'akamaiCdnIngestMethods',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    required: true,
                    label: 'Akamai CDN ingest method',
                    name: 'akamai_cdn_ingest_method',
                },
            },
            encoder_type: {
                dataMapKey: 'encodingTarget.encoder_type.id',
                optionsKey: 'encoderTypes',
                props: {
                    component: Form.SemanticSelect,
                    disabled: true,
                    hidden: false,
                    required: true,
                    label: 'Encoders',
                    name: 'encoder_type',
                },
            },
            playback_path: {
                dataMapKey: 'encodingTarget.encoding_target_manual.playback_path',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Playback path',
                    name: 'playback_path',
                    type: 'text',
                },
            },
            language: {
                dataMapKey: 'encodingTarget.encoding_target_sr.language.id',
                optionsKey: 'languages',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'Language',
                    name: 'language',
                    placeholder: '- Default -',
                    allowClear: true,
                },
            },
            encoding_job_profile: {
                dataMapKey: 'encodingTarget.encoding_target_sr.encoding_job_profile.id',
                optionsKey: 'encodingJobProfiles',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'Encoding job profile',
                    name: 'encoding_job_profile',
                },
            },
            stream_name_prefix: {
                dataMapKey: 'encodingTarget.encoding_target_sr.stream_name_prefix',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Stream name prefix',
                    name: 'stream_name_prefix',
                    type: 'text',
                },
            },
            inlineTokenTtl: [
                {
                    has_playback_authentication: {
                        dataMapKey: 'encodingTarget.has_playback_authentication',
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            label: 'Playback authentication?',
                            name: 'has_playback_authentication',
                            type: 'toggle',
                        },
                    },
                },
                {
                    token_ttl: {
                        dataMapKey: 'encodingTarget.token_ttl',
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            name: 'token_ttl',
                            label: 'Token TTL',
                            labelDescription: {content: 'seconds'},
                            type: 'input',
                        },
                    },
                },
            ],
            has_scoreboard_overlay_support: {
                dataMapKey: 'encodingTarget.encoding_target_sr.has_scoreboard_overlay_support',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Scoreboard overlay support?',
                    name: 'has_scoreboard_overlay_support',
                    type: 'toggle',
                },
            },
            has_scoreboard_overlay: {
                dataMapKey: 'encodingTarget.encoding_target_sr.has_scoreboard_overlay',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Start encoding with scoreboard overlay',
                    name: 'has_scoreboard_overlay',
                    type: 'checkbox',
                },
            },
            scoreboard_overlay_position: {
                dataMapKey: 'encodingTarget.encoding_target_sr.scoreboard_overlay_position.id',
                optionsKey: 'scoreboardOverlayPositions',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    required: true,
                    label: 'Position',
                    name: 'scoreboard_overlay_position',
                },
            },
            inline: [
                {
                    has_slate_support: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.has_slate_support',
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            label: 'Slate support?',
                            name: 'has_slate_support',
                            type: 'toggle',
                        },
                    },
                },
                {
                    slate: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.slate.id',
                        props: {
                            component: Form.SemanticSelect,
                            allowClear: true,
                            hidden: true,
                            label: 'Start encoding with the following slate',
                            name: 'slate',
                            placeholder: 'No slate',
                        },
                    },
                },
            ],
            inlineOverlay: [
                {
                    has_overlay_support: {
                        dataMapKey: "encodingTarget.encoding_target_sr.has_overlay_support",
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            label: "Overlay support?",
                            name: "has_overlay_support",
                            type: "toggle",
                        }
                    }
                },
                {
                    start_encoding_with_overlay: {
                        dataMapKey: "encodingTarget.encoding_target_sr.start_encoding_with_overlay",
                        props: {
                            component: Form.SemanticInput,
                            allowClear: true,
                            hidden: true,
                            label: "Start encoding with overlay",
                            name: "start_encoding_with_overlay",
                            type: "checkbox",
                        }
                    }
                },
                {
                    overlay: {
                        dataMapKey: "encodingTarget.encoding_target_sr.overlay",
                        props: {
                            component: Form.SemanticSelect,
                            allowClear: true,
                            hidden: true,
                            label: "Overlay",
                            name: "overlay",
                            required: true,
                        }
                    }
                }
            ],
            inlineAdType: [
                {
                    has_ad_support: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.has_ad_support',
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            label: 'Ad support?',
                            name: 'has_ad_support',
                            type: 'toggle',
                        },
                    },
                },
                {
                    ad_type: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.ad_type.id',
                        optionsKey: 'adTypes',
                        props: {
                            component: Form.SemanticSelect,
                            allowClear: true,
                            hidden: true,
                            label: 'Ad type',
                            name: 'ad_type',
                        },
                    },
                },
            ],
            inlineClientDrmSetups: [
                {
                    has_drm_support: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.has_drm_support',
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            label: 'DRM support?',
                            name: 'has_drm_support',
                            type: 'toggle',
                        },
                    },
                },
                {
                    client_drm_setups: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.client_drm_setups',
                        props: {
                            component: Form.SemanticSelect,
                            hidden: true,
                            label: 'Clients',
                            multiple: true,
                            name: 'client_drm_setups',
                            required: false,
                        },
                    },
                },
            ],
            inlineCloseCaption: [
                {
                    has_cc: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.has_cc',
                        props: {
                            component: Form.SemanticInput,
                            hidden: true,
                            label: 'Close Captioning?',
                            name: 'has_cc',
                            type: 'toggle',
                        },
                    },
                },
                {
                    cc_stream_language: {
                        dataMapKey: 'encodingTarget.encoding_target_sr.cc_stream_language.id',
                        props: {
                            component: Form.SemanticSelect,
                            allowClear: true,
                            hidden: true,
                            label: 'Language',
                            name: 'cc_stream_language',
                            placeholder: 'No Language',
                        },
                    },
                },
            ],
            akamai_rtmp_distribution_regions: {
                dataMapKey: 'encodingTarget.encoding_target_sr.akamai_rtmp_distribution_regions',
                props: {
                    component: renderAkamaiRtmpDistrRegion,
                    hidden: true,
                    name: 'akamai_rtmp_distr_regions',
                },
            },
        },
        mutation: {
            createEntity: (data) => {
                const encoderType = (_has(data, 'encoding_target.encoder_type'))
                    ? convertToInt(data.encoding_target.encoder_type)
                    : convertToInt(data.encoder_type);

                return (encoderType === ENCODER_TYPE_SPORTRADAR)
                    ? CreateEncodingTargetSr
                    : CreateEncodingTargetManual;
            },
            updateEntity: UpdateEncodingTarget,
            deleteEntity: DeleteEncodingTargetMutation
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetEncodingTargetsForDistributions', 'GetEventContentEncodingConfigurationChanged'],
            },
            update: {
                refetchQueries: ['GetEventContentEncodingConfigurationChanged'],
            },
            delete: {
                refetchQueries: [
                    'GetEncodingTargetsForDistributions',
                    'GetThirdPartyClientsForList',
                    'GetEventContentEncodingConfigurationChanged',
                ],
            },
        },
        query: {
            getOptions: GetEncodingTargetOptionsForForm,
        },
    };
}
