import React from "react";
import PropTypes from "prop-types";

import Link from "../../../../app/components/Link";

/**
 * Open popup with form to:
 * - linking event with match
 * or
 * - edit match id on event
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkingEventWithMatchOpenPopup = (props) => {
    return (
        <Link
            name="events.linkToSportradarMatch"
            params={{id: props.eventId}}
        >
            {props.children}
        </Link>
    )
};

LinkingEventWithMatchOpenPopup.propTypes = {
    eventId: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired
};

export default LinkingEventWithMatchOpenPopup;
