import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Dimmer, Grid, Header, Loader, Segment} from 'semantic-ui-react';

import {graphql, withApollo} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import mapModulesToProps from '@utils/mapModulesToProps';
import {convertToInt, digitsOnly} from '@utils/helpers';
import {getSuffixWithNumber} from '@utils/ordinalNumberSuffix';
import {renderModalError} from '@utils/forms';

import navigation from '@constants/navigation';
import {DeleteContentCategory as DeleteContentCategoryMutation} from '@graphql/contentCategory/mutation';
import {GetContentCategoriesForView} from '@graphql/contentCategory/query';
import {routes} from '@constants/routes';
import {showModal, showModalConfirmation, hideModal} from '@utils/modal';
import {showMessageBox} from '@utils/messageBox';

import ContentCategoryLevel from '../components/ContentCategoryLevel';
import ContentCategoryEdit from './ContentCategoryEdit';

export class ContentCategoriesIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        data: PropTypes.object,
        DeleteContentCategory: PropTypes.func,
        Menu: PropTypes.object,
        match: PropTypes.object,
        ModalObject: PropTypes.object,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            contentCategoriesLevel0: null,
            contentCategoriesLevel1: null,
            contentCategoriesLevel2: undefined,
            contentCategoriesLevel3: undefined,
            level1parentId: null,
            level2parentId: 0,
            level3parentId: 0,
            loading1level: true,
            loading2level: false,
            loading3level: false,
        };

        this.props.Menu.storeMenu(navigation.mediaRights.key);
    }

    getDataForTable(level, parentId, added) {
        if (!parentId) {
            parentId = this.state[`level${level}parentId`];
        }

        this.setState(() => ({ [`loading${level}level`] : true,
            [`level${level}parentId`]: parentId,
            [`contentCategoriesLevel${level}`] :
                (this.state[`contentCategoriesLevel${level}`] === undefined)
                    ? null : this.state[`contentCategoriesLevel${level}`],
        }));

        if (added) {
            this.setState(() => ({
                [`contentCategoriesLevel${level}`]: [],
                [`loading${level}level`]: false,
            }
            ));
        } else {
            this.props.client.query({
                query: GetContentCategoriesForView,
                fetchPolicy: 'network-only',
                variables: {
                    level: level,
                    parent: [parentId],
                },
            }).then((result) => {
                this.setState(() => ({
                    [`contentCategoriesLevel${level}`]: result.data.contentCategories,
                    [`loading${level}level`]: false,
                }
                ));
                this.refreshTable(level, parentId);

                if (this.state.isOpenedFormUrl) {
                    this.prepareCategories(this.state.data, this.state.dataCategory, this.state.categoryIndex);
                }
            });
        }
    }

    refreshTable(level, selectedRowId) {
        if (1 === level) {
            return;
        }

        const itemsInParentTable = this.state[`contentCategoriesLevel${level-1}`],
            items = [...itemsInParentTable],
            index = items.findIndex(
                (obj) => convertToInt(obj.id) === selectedRowId
            );
        let children = [];

        if (this.state[`contentCategoriesLevel${level}`].length) {
            children = [1];
        }

        items[index] = Object.assign({}, items[index], {children: children});

        this.setState(function(){
            return {[`contentCategoriesLevel${level-1}`]: items}
        })
    }

    selectContentCategory = (row) => {
        let data = row.data,
            level = convertToInt(data.level),
            parentId;

        if (!data.id) {
            parentId = 0;
        } else {
            parentId = convertToInt(data.id);
        }

        if (1 === level) {
            this.setState(() => ({contentCategoriesLevel3 : undefined, level3parentId: 0}));
        }

        this.getDataForTable(level+1, parentId, data.add);
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {contentCategories} = nextProps.data;
        let nextState = {};

        if (contentCategories && contentCategories !== prevState.contentCategoriesLevel1) {
            nextState.contentCategoriesLevel1 = contentCategories;
            nextState.loading1level = false;
        }

        return nextState;
    }

    componentDidMount() {
        const level = this.props.match.params?.levelId?.split('-')[1];

        if (this.isEditPath()) {
            const contentId = this.props.match.params.id,
                data = {
                    level: level,
                    id: contentId,
                    isContentLoading: true,
                };

            this.setState(() => ({
                isOpenedFormUrl: true,
                level: level,
                id: contentId,
            }));

            if (digitsOnly(contentId.toString())) {
                this.openEditCategory(data);
            } else {
                this.showModalError();
            }
        } else if (this.isAddPath()) {
            const parentId = new URLSearchParams(window.location.search).get('parentId'),
                data = {
                    level: level,
                    parentId: parentId,
                    isContentLoading: true,
                };

            this.setState(() => ({
                [`level${level}parentId`]: convertToInt(parentId),
                isOpenedFormUrl: true,
                level: level,
                parentId: parentId,
            }));

            this.openAddCategory(data);
        }
    }

    isAddPath = () => {
        return this.props.match.path === routes.content_categories.add.path;
    }

    isEditPath = () => {
        return this.props.match.path === routes.content_categories.edit.path;
    }

    showModalError = () => (
        showModal({
            isVisible: true,
            content: renderModalError('Content Categories', routes.content_categories.index.path),
        })
    )

    componentDidUpdate(prevProps, prevState) {
        const
            modalChanged = prevProps.ModalObject.isVisible !== this.props.ModalObject.isVisible,
            modalIsNotVisible = !this.props.ModalObject.isVisible,
            modalIsClosed = modalChanged && modalIsNotVisible;

        if (modalIsClosed) {
            this.props.history.push(routes.content_categories.index.path);

            return;
        }

        if (this.state.isOpenedFormUrl && prevState.loading1level !== this.state.loading1level) {
            const data = {
                level: this.state.level,
                isContentLoading: false,
                ...(this.isEditPath()) && {id: this.state.id},
                ...(this.isAddPath()) && {parentId: this.state.parentId},

            };
            this.isEditPath() && digitsOnly(data.id.toString()) && this.openEditCategory(data);
            this.isAddPath() && this.openAddCategory(data);
        }
    }

    refreshData = (data) => {
        let dataCategory = {};

        if (data.updateContentCategory) {
            dataCategory = data.updateContentCategory;
        }

        if (data.createContentCategory) {
            dataCategory = data.createContentCategory;
        }

        const categoryIndex = 'contentCategoriesLevel' + dataCategory.level;

        if (!this.state[categoryIndex]) {
            if (3 === dataCategory.level) {
                let level1Id = null;
                this.state.contentCategoriesLevel1.forEach(category => {
                    category.children.forEach(child => {
                        if (child.id === dataCategory.parent.id) {
                            level1Id = convertToInt(category.id);
                        }
                    });
                });
                this.getDataForTable(2, level1Id, dataCategory.add);
                this.getDataForTable(
                    3,
                    convertToInt(dataCategory.parent.id),
                    dataCategory.add);
            } else {
                this.getDataForTable(dataCategory.level, convertToInt(dataCategory.parent.id), dataCategory.add);
            }

            this.setState(() => ({
                data: data,
                dataCategory: dataCategory,
                categoryIndex: categoryIndex,
            }));
        }

        if (!this.state.isOpenedFormUrl) {
            this.prepareCategories(data, dataCategory, categoryIndex);
        }

        this.setState(() => ({
            isOpenedFormUrl: null,
            level: null,
            parentId: null,
        }));

        hideModal();
    };

    prepareCategories = (data, dataCategory, categoryIndex) => {
        let categories = this.state[categoryIndex],
            categoriesToReturn = categories.map((category) => {
                if (category.id ===  dataCategory.id) {
                    return dataCategory;
                }

                return category;
            });

        if (data.updateContentCategory) {
            this.setState(() => ({
                [categoryIndex]: categoriesToReturn,
            }));
        }

        if (data.createContentCategory) {
            categoriesToReturn = [...categories];
            categoriesToReturn.push(dataCategory);

            categoriesToReturn.sort((a, b) => {
                let valueA = a.name.toLowerCase(),
                    valueB = b.name.toLowerCase();

                if(null === valueB || valueA > valueB) {
                    return 1;
                }

                if (valueA < valueB) {
                    return -1;
                }

                return 0;
            });

            let parents = null;

            if (1 < dataCategory.level && dataCategory.parent.id) {
                parents = this.state[`contentCategoriesLevel${dataCategory.level - 1}`];

                parents.map((category) => {
                    const updatedCategory = {...category};

                    if (dataCategory.parent && category.id === dataCategory.parent.id) {
                        updatedCategory.children = [...updatedCategory.children, dataCategory];
                    }

                    return updatedCategory;
                });
            }

            this.setState(() => ({
                [`contentCategoriesLevel${dataCategory.level - 1}`]: parents,
                [categoryIndex]: categoriesToReturn,
                [`level${dataCategory.level + 1}parentId`]: convertToInt(dataCategory.id),
                [`contentCategoriesLevel${dataCategory.level + 1}`]: [],
            }));
        }
    }

    openEditCategory = (data) => {
        showModal({
            isVisible: true,
            content: data.isContentLoading ? <Loader/> : <ContentCategoryEdit
                id={convertToInt(data.id)}
                level={convertToInt(data.level)}
                parentId={this.state[`level${data.level}parentId`]}
                refreshData={this.refreshData}
            />,
        });
    };

    openAddCategory = (data) => {
        showModal({
            isVisible: true,
            content: data.isContentLoading ? <Loader/> : <ContentCategoryEdit
                level={convertToInt(data.level)}
                parentId={convertToInt(data.parentId)}
                refreshData={this.refreshData}
            />,
        });
    };

    deleteCategory = (data) => {
        const id = data.id,
            level = data.level;

        const {DeleteContentCategory} = this.props;

        showModalConfirmation({text: `The ${getSuffixWithNumber(level)} level category is being deleted...`});

        return DeleteContentCategory({
            variables: {
                id: id,
            },
        }).then(() => {
            showMessageBox('contentCategoriesMessage', `The ${getSuffixWithNumber(level)} level category "${data.name}"
                has been deleted successfully.`, null, 'success');
            hideModal();

            if (this.state[`level${level+1}parentId`] === id) {
                this.setState(() => ({[`contentCategoriesLevel${level+1}`] : undefined}));
            }

            this.selectContentCategory({data : {level: level-1, id: this.state[`level${level}parentId`]}});

            if (1 === convertToInt(level) && this.state.level2parentId === convertToInt(data.id)) {
                this.setState(() => ({
                    contentCategoriesLevel2: undefined,
                }));
            }

        }).catch((error) => {
            hideModal();
            showMessageBox('contentCategoriesMessage', 'The content category could not be deleted.', `${error}`, 'error');
        });
    };

    deleteCategoryClick = (row) => {
        showModalConfirmation({
            header: <Header icon='trash' content={`Delete ${row.level} level category`}/>,
            text: `Are you sure you want to delete the ${row.level} level category "${row.name}"?`,
            onYes: () => (this.deleteCategory(row)),
        });
    };

    isContentLoading = () => {
        return this.state.loading1level || this.state.loading2level || this.state.loading3level;
    }

    renderCategories() {
        let content = [];

        for(let i = 1; i < 4; i++) {
            if (this.state['contentCategoriesLevel'+i] === undefined) {
                continue;
            }

            content.push(
                <ContentCategoryLevel key={`category_column_${i}`}
                    data={this.state['contentCategoriesLevel'+i]}
                    deleteCategory={this.deleteCategoryClick}
                    editCategory={this.openEditCategory}
                    level={i}
                    loading={this.state[`loading${i}level`]}
                    parent={this.state[`level${i}parentId`]}
                    rowOnClick={this.selectContentCategory}
                    selected={[this.state[`level${i+1}parentId`]+ '']}
                />
            );
        }

        return content;
    }

    render() {
        return (
            <div>
                <HeaderRenderer
                    messagesBoxNames='contentCategoriesMessage'
                    pageTitle={'Content categories'}
                />
                <Segment basic>
                    <Grid columns={3} className='helper__removeTopMargin'>
                        <Grid.Row>
                            <Dimmer inverted active={this.isContentLoading()}>
                                <Loader/>
                            </Dimmer>
                            {this.renderCategories()}
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Menu']),
    ContentCategoriesWithData = compose(graphql(GetContentCategoriesForView,{
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    level: 1,
                    parent: [],
                },
            };
        }}),
    graphql(DeleteContentCategoryMutation, {name: 'DeleteContentCategory'})
    )(ContentCategoriesIndex);

const mapStateToProps = (state) => {
    return {
        ModalObject: state.modal,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ContentCategoriesWithData)));
