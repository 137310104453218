import {sortBy as _sortBy} from "lodash";
import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {connect} from "react-redux";
import {Header, Segment} from 'semantic-ui-react';
import {withRouter} from "react-router";

import {graphql, withApollo} from "react-apollo";
import {getLink, Link} from '@appComponents/Link';
import {
    DISTRIBUTION_TYPE_RECORDING,
    PRODUCT_THIRD_PARTY,
    SECURITY_PRIVILEGES_READ,
    SECURITY_PRIVILEGES_READ_UPDATE,
} from '@constants/variables';
import {ENCODING_TARGET} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';

import EventsConfigurationThirdPartyClientVodStoragesTable from "../components/EventsConfigurationThirdPartyClientVodStoragesTable";
import EventContentThirdPartyClientVodStorageForm from "../forms/EventContentThirdPartyClientVodStorageForm";
import EventContentThirdPartyClientVodStorageModel from "../forms/EventContentThirdPartyClientVodStorageModel";
import {CircleButtonAdd} from "../../app/components/ButtonCollection";
import SegmentHeader from "../../app/components/SegmentHeader";
import {refetchQueryByName} from "../../../utils/apollo";
import {convertToInt} from "../../../utils/helpers";
import {createForm} from "../../../utils/forms";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import {routes} from "../../app/constants/routes";
import {RemoveVodStorageFromEncodingTarget} from "../../../graphql/thirdPartyClientVodStorage/mutation.graphql";
import {GetEncodingTargetThirdPartyVodStoragesData} from "../../../graphql/thirdPartyClientVodStorage/query.graphql";

const authorizationEncodingTargetReadUpdateObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ_UPDATE,
};

const authorizationEncodingTargetReadObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ,
};

class EventsConfigurationThirdPartyClientVodStorages extends React.Component {
    static propTypes = {
        client: PropTypes.shape({
            mutate: PropTypes.func.isRequired,
        }),
        DataEncodingTargetVodStorages: PropTypes.shape({
            encodingTargets: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool.isRequired,
        }),
        distributions: PropTypes.array,
        eventContentId: PropTypes.number.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                contentId: PropTypes.string,
                id: PropTypes.string,
            }),
            path: PropTypes.string.isRequired,
        }),
        MessageBox: PropTypes.shape({
            addMessage: PropTypes.func.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
            setModalConfirmation: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {
        DataEncodingTargetVodStorages: {
            encodingTargets: [],
        },
        distributions: [],
        match: {
            params: {
                contentId: null,
                id: null,
            },
        },
    };

    state = {
        encodingTargets: [],
        modalShouldOpen: (
            this.props.match.path === routes.events.configuration.content.thirdPartyClientVodStorage.add.path
        ),
        tableData: [],
    };

    componentDidUpdate(prevProps, prevState) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.events.configuration.index.path,
            shouldOpenModal = (urlChanged || prevState.modalShouldOpen) && urlIsNotIndex;

        shouldOpenModal && !this.props.DataEncodingTargetVodStorages.loading && this.loadModal(this.props);

        const modalChanged = this.props.modal.isVisible !== prevProps.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(
            getLink("events.configuration.index", {id: this.props.match.params.id})
        );

        let {DataEncodingTargetVodStorages: {encodingTargets}} = this.props;

        if (encodingTargets && this.state.encodingTargets !== encodingTargets) {
            this.setState(() => ({
                encodingTargets: encodingTargets,
                tableData: this.prepareTableData(encodingTargets),
            }));
        }
    }

    prepareTableData = (encodingTargets) => {
        let tableData = [];

        encodingTargets.forEach((encodingTarget) => {
            encodingTarget.third_party_client_vod_storages.forEach((vodStorage) => {
                tableData.push({
                    client: vodStorage.client_product_third_party.client_product.client,
                    encodingTarget: convertToInt(encodingTarget.id),
                    id: convertToInt(vodStorage.id),
                    name: vodStorage.name,
                    type: vodStorage.upload_target_type.name,
                });
            });
        });

        return _sortBy(tableData, ['client', 'name']);
    };

    loadModal(props) {
        if (!props) {
            props = this.props;
        }

        if (
            routes.events.configuration.content.thirdPartyClientVodStorage.add.path === props.match.path
            && convertToInt(props.match.params.contentId) === props.eventContentId
        ) {
            this.setState(() => ({modalShouldOpen: false}));

            return this.loadModalForm(createForm(
                EventContentThirdPartyClientVodStorageModel,
                EventContentThirdPartyClientVodStorageForm,
                {
                    optionsVariables: {
                        eventContentId: props.eventContentId,
                    },
                    isAddForm: true,
                    encodingTargets: this.state.encodingTargets,
                    thirdPartyRecordingDistribution: this.props.distributions.find((distribution) => (
                        PRODUCT_THIRD_PARTY === convertToInt(distribution.product.id)
                        && DISTRIBUTION_TYPE_RECORDING === convertToInt(distribution.distribution_type.id)
                    )),
                }
            ));
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            header: false,
            isVisible: true,
            content: <Form
                formData={data}
                onSaveCallback={this.onSaveCallback}
            />,
        });
    };

    handleDeleteVodStorage = (data) => {
        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={"Remove third party client VOD storage"}/>,
            text: 'Are you sure you want to remove this third party client VOD storage for this target?',
            onYes: () => (this.deleteVodStorage(data.id, data.encodingTarget)),
        });
    };

    deleteVodStorage = (vodStorageId, encodingTargetId) => {
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        let vodStorageIds = this.state.encodingTargets.find((target) => (
            convertToInt(target.id) === encodingTargetId
        )).third_party_client_vod_storages.filter((vodStorage) => (
            convertToInt(vodStorage.id) !== vodStorageId
        )).map((vodStorage) => (convertToInt(vodStorage.id)));

        this.props.client.mutate({
            mutation: RemoveVodStorageFromEncodingTarget,
            refetchQueries: ['GetEventContentEncodingConfigurationChanged'],
            variables: {
                id: convertToInt(encodingTargetId),
                third_party_client_vod_storages: vodStorageIds,
            },
        }).then(() => {
            setModal({isVisible: false});
            addMessage(
                "eventsConfiguration",
                "The third party client VOD storage has been removed successfully for the given target.",
                null,
                "success"
            );
            refetchQueryByName("encodingTargetsQuery");
        }).catch((error) => {
            setModal({isVisible: false});
            addMessage(
                "eventsConfiguration",
                "The third party client VOD storage could not be removed for the given target.",
                `${error}`,
                "error"
            );
        });
    };

    renderAddButton = () => (
        <Authorization authorization={authorizationEncodingTargetReadUpdateObject}>
            <Link
                name='events.configuration.content.thirdPartyClientVodStorage.add'
                params={{
                    id : this.props.match.params.id,
                    contentId : this.props.eventContentId,
                }}>
                <CircleButtonAdd/>
            </Link>
        </Authorization>
    );

    render() {
        return (
            <div>
                <Authorization authorization={authorizationEncodingTargetReadObject}>
                    <SegmentHeader buttons={this.renderAddButton()}>
                        Third party clients (VOD storages)
                    </SegmentHeader>
                    <Segment basic className="--table">
                        <EventsConfigurationThirdPartyClientVodStoragesTable
                            loading={this.props.DataEncodingTargetVodStorages.loading}
                            data={this.state.tableData}
                            onDelete={this.handleDeleteVodStorage}
                        />
                    </Segment>
                </Authorization>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({modal: state.modal});
const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal']);

export default graphql(GetEncodingTargetThirdPartyVodStoragesData, {
    options : (props) => {
        let distributions = props.distributions.filter((distribution) => (
            PRODUCT_THIRD_PARTY === convertToInt(distribution.product.id)
            && DISTRIBUTION_TYPE_RECORDING === convertToInt(distribution.distribution_type.id)
        )).map((distribution) => (convertToInt(distribution.id)));

        return {
            fetchPolicy : "cache-and-network",
            notifyOnNetworkStatusChange: true,
            variables: {
                distributions: distributions || null,
            },
        };
    },
    name: "DataEncodingTargetVodStorages",
})(withRouter(
    (connect(mapStateToProps, mapDispatchToProps)(withApollo(EventsConfigurationThirdPartyClientVodStorages)))
));
