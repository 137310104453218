import React from 'react';
import PropTypes from 'prop-types';

import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import {Icon, IconEdit} from '@appComponents/IconCollection';
import {LinkWithAuthorization} from '@appComponents/LinkWithAuthorization';
import Table from '@modules/app/components/Table';

const authorizationObject = {
    [RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_READ,
};

const wnsStreamTableColumns = {
    id: {
        label: 'ID',
        sorting: row => convertToInt(row.id),
        header: {
            align: 'center',
        },
        content: {align: 'center'},
    },
    name: {
        label: 'Name',
        sorting: row => row.name,
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    stream_name: {
        label: 'Stream name',
        sorting: row => row.stream_name,
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    actions: {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {align: 'center'},
    },
};

const wnsStreamTableRenderer = (column, row) => {
    const numId = convertToInt(row.id);

    switch (column) {
        case 'id': {
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'wnsStreams.edit', params: {id: numId}}}
                >{row.id}</LinkWithAuthorization>
            );
        }
        case 'name': {
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'wnsStreams.edit', params: {id: numId}}}
                >{row.name}</LinkWithAuthorization>
            );
        }
        case 'stream_name': {
            return row.stream_name;
        }
        case 'actions': {
            return (
                <div className={'actions-list-icon'}>
                    <IconEdit size='large'
                        resource={RESOURCES.WNS_STREAM}
                        link={getLink('wnsStreams.edit', {id: numId})}
                    />
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{name: 'wnsStreams.play', params: {id: numId}}}>
                        <Icon className={'large play circle outline'}/>
                    </LinkWithAuthorization>
                </div>
            );
        }
    }
};

const WnsStreamTable = ({data, loading}) => {
    return (
        <Table
            name='wnsStreamsTable'
            data={data}
            loading={loading}
            defaultSort={'name'}
            noDataText='No World Number Service streams found'
            columns={wnsStreamTableColumns}
            rowRenderer={wnsStreamTableRenderer}
            pagination={false}
        />
    );
};

WnsStreamTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

export default WnsStreamTable;
