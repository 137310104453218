import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";

import {PRODUCT_THIRD_PARTY_STREAM_NAMES_INDEX_BUTTONS_ADD} from "../constants/messages";
import {THIRD_PARTY_CLIENT_PULL_STREAM_NAME, THIRD_PARTY_CLIENT_PUSH_STREAM_NAME} from "../../app/constants/resources";
import {PUSH, SECURITY_PRIVILEGES_CREATE} from "../../app/constants/variables";

const ProductThirdPartyStreamNamesIndexButtons = (props) => (
    <div className="content__buttons">
        <Authorization
            privileges={SECURITY_PRIVILEGES_CREATE}
            resources={PUSH === props.type ? THIRD_PARTY_CLIENT_PUSH_STREAM_NAME : THIRD_PARTY_CLIENT_PULL_STREAM_NAME}
        >
            <ButtonAdd onClick={props.prepareDataForModal}>
                {PRODUCT_THIRD_PARTY_STREAM_NAMES_INDEX_BUTTONS_ADD(props.type)}
            </ButtonAdd>
        </Authorization>
    </div>
);

ProductThirdPartyStreamNamesIndexButtons.propTypes = {
    prepareDataForModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default ProductThirdPartyStreamNamesIndexButtons;
