import React from 'react';

import {AvcmpRouterWithRedux as AvcmpRouter} from './modules/app/components/AvcmpRouter';

export const App = () => (
    <div className="avcmp">
        <AvcmpRouter/>
    </div>
);

export default App;
