import PropTypes from "prop-types";

import * as MESSAGES from "../../app/constants/messages";
import {ROLE} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_READ_UPDATE} from "../../app/constants/variables";

import validate from "./validatorRoleResource";

import {generateFields, generateDataMapFields} from "./helpers/roleResourceModelHelper";

import {UpdateRoleResource} from "../../../graphql/role/mutation.graphql";

export default function RoleResourceModel(roleResources, securedResources, roleName) {
    return {
        entityDataMapKey: 'roleResources',
        entityLabel: "resources",
        formName: "resources of the role",
        authorization: {[ROLE]: SECURITY_PRIVILEGES_READ_UPDATE},
        showChangeLog: false,
        validate: validate,
        title: null,
        dataMap: {
            roleResources: generateDataMapFields(roleResources)
        },
        messages: {
            boxName: {
                error: 'roleResourcesErrorMessage',
                success: 'roleMessage',
            },
            text: {
                ...MESSAGES,
                SAVED: () => `The resources of the role "${roleName}" have been saved successfully.`,
                FORM_ERROR: () => `The resources of the role "${roleName}" have not been saved. Please correct the highlighted fields below.`,
            },
        },
        fields: {
            ...generateFields(roleResources, securedResources)
        },
        mutation: {
            updateEntity: UpdateRoleResource,
        },
        mutationOptions: {
            update: {
                refetchQueries: ['GetRolesForListingPage'],
            }
        },
        query: {
        },
    };
}

RoleResourceModel.propTypes = {
    roleResources: PropTypes.object.isRequired,
    securedResources: PropTypes.object.isRequired
};
