import React from 'react';
import {isEmpty as _isEmpty, orderBy as _orderBy} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {createForm} from '@utils/forms';
import {GetClipConfigNameAndTournaments, GetClipsTreeForForm} from '@graphql/clipConfiguration/query';
import {getLink} from '@appComponents/Link';

import apolloClient from '../../../apolloClient';

export const createLinkToIndexPage = () => (
    getLink('clipConfiguration.index')
);

export const runQuery = (runApolloRequest, query, variables) => {
    return runApolloRequest('query', {
        fetchPolicy: 'network-only',
        query: query,
        variables: variables,
    });
};

export const displayFormModal = (props, title, model, form, params) => {
    const Form = createForm(model, form, params);

    props.Modal.setModal({
        isVisible: true,
        onClose: () => window.history.pushState({}, '', getLink('clipConfiguration.index')),
        size: 'large',
        content: <Form/>,
        header: title,
    });
};

export const getClipConfigName = (id) => {
    return apolloClient.query({
        query: GetClipConfigNameAndTournaments,
        variables: {id: id},
        fetchPolicy: 'network-only',
    });
};

export const setClipsTree = (form, productIds, selectedClips) => {
    if (productIds) {
        apolloClient.query({
            fetchPolicy: 'network-only',
            query: GetClipsTreeForForm,
            variables: {
                products: [convertToInt(productIds)],
            },
        }).then((response) => {
            let treeSelectedObject = [];

            if (selectedClips) {
                treeSelectedObject = selectedClips.map((selectedClip) => {
                    const selectedEvent = selectedClip.event_content_type.id;
                    const selectedMatch = selectedClip.match_event_types.map(({id}) => id);

                    return {
                        firstLevelTree: selectedEvent,
                        secondLevelTree: selectedMatch,
                    };
                });
            }

            if (!_isEmpty(response.data.eventContentTypes) && !_isEmpty(response.data.matchEventTypes)) {
                const tree = generateTree(
                    _orderBy(response.data.eventContentTypes, 'name', ['asc']),
                    _orderBy(response.data.matchEventTypes, 'name', ['asc']),
                    treeSelectedObject
                );

                form.setField('eventContentTypes', {
                    options: tree,
                    disabled: 0 === tree.length,
                });
            }
        });
    }
};

export const generateTree = (eventContentTypes, matchEventTypes, selectedValues) => {
    let treeData = [];

    eventContentTypes.forEach((eventContentType) => {
        const eventContentTypeId = eventContentType.id;
        const selectedSecondLevelValues = selectedValues
            .find((item) => {
                if (item.firstLevelTree === eventContentTypeId) {
                    return item;
                }
            });

        treeData.push({
            value: eventContentTypeId,
            selected: selectedSecondLevelValues,
            label: eventContentType.name,
            name: eventContentType.name,
            children: eventContentType.has_match_event_type_selection ? !selectedSecondLevelValues ?
                generateSecondLevelTree(matchEventTypes, []) :
                generateSecondLevelTree(matchEventTypes, selectedSecondLevelValues.secondLevelTree) : [],
        });
    });

    return treeData;
};

export const generateSecondLevelTree = (matchEventTypes, selectedValues) => {
    const secondLevel = [];
    matchEventTypes.forEach((matchEventType) => {
        const matchEventTypeId = matchEventType.id;

        secondLevel.push({
            id: matchEventTypeId,
            value: matchEventTypeId,
            label: matchEventType.name,
            name: 'match_event_type',
            selected: isMatchItemsSelected2ndLevel(matchEventTypeId, selectedValues),
        });
    });

    return secondLevel;
};

const isMatchItemsSelected2ndLevel = (matchEventTypeId, selectedValues) => {
    return !!(selectedValues.includes(matchEventTypeId));
};

export const formatToSaveClipsOptions = (eventContentTypes) => {
    let relationsToSave = [];

    eventContentTypes.forEach((eventContentType) => {
        let matchEventTypes = [];

        eventContentType.children.forEach((matchEventType) => {
            matchEventTypes.push(convertToInt(matchEventType.id));
        });

        relationsToSave.push({
            'event_content_type': convertToInt(eventContentType.value),
            'match_event_types': matchEventTypes,
        });
    });

    return relationsToSave;
};
