import ContactPersonModel from "../../contactPerson/forms/ContactPersonModel";

export default function AccreditationModel() {
    const accreditationModel = ContactPersonModel();

    accreditationModel.title = null;
    accreditationModel.entityLabel = "accreditation";
    accreditationModel.formName = "accreditation";
    accreditationModel.label = "Accreditation";
    accreditationModel.messages.boxName.error = "propertyLicenceMessage";
    accreditationModel.messages.boxName.success = "propertyLicenceMessage";

    return accreditationModel;
}
