import {ApolloProvider} from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import {
    escape as _escape,
} from 'lodash';
import Provider from 'react-redux/lib/components/Provider';

import * as actions from '@actions';
import App from './App';
import configuredStore from './store';
import apolloClient from './apolloClient';

import './scss/style.css';
import '@utils/prototypes';

import HelperSession from '@utils/HelperSession';
import {setInfoBoxError} from '@reducers/InfoBoxes';

configuredStore.dispatch(actions.validateAuthentication());

let prevError = null;

window.addEventListener('error', function(event) {
    const currentError = event.filename + event.colno + event.lineno;

    if (prevError !== currentError) {
        actions.reportError({
            url: window.location.href,
            error: `<br/>
             * error: <br/>
             ${event.error.message} \n ${event.error.stack}<br/><br/>
             * element: ${_escape(JSON.stringify(event.currentTarget.document.activeElement.outerHTML).replace())}`,

        });

        setInfoBoxError({
            content: 'There was an error in action. Log has been sent.',
        });
    }

    prevError = currentError;
});

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <Provider store={configuredStore}>
            <App />
        </Provider>
    </ApolloProvider>,
    document.getElementById('root')
);

const helperSession = new HelperSession();

if ('development' !== process.env.NODE_ENV) {
    helperSession.startListener();
}
