import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Authenticate from "./Authenticate";

const AuthenticationView = () => {
    return (
        <Router>
            <Switch>
                <Route component={ Authenticate }/>
            </Switch>
        </Router>
    )
};

export default AuthenticationView;
