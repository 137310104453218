import {get as _get} from 'lodash';

import {gql, withApollo} from 'react-apollo';
import {optionsForEditEventIncident} from '@graphql/events/eventsIncident';
import {getLink} from '@appComponents/Link';

import {EventsIncidentForm} from './EventsIncidentForm';

class EventsIncidentEditForm extends EventsIncidentForm {
    componentDidMount() {
        super.componentDidMount();

        this.setFallbackRoute(
            getLink('events.report', {
                id: _get(this.props, 'formParams.optionsVariables.eventId', null),
            })
        );
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const nextProps = this.props.GraphQLEntityData;

        if (nextProps.eventIncident && !this.state.dataReceived) {
            const eventIncident = _get(nextProps, 'eventIncident', {});

            this.getDropdownFieldsOptions(this.props, eventIncident);

            if (!eventIncident.affects_all_output_streams) {
                this.setField('affects_all_output_streams', {
                    defaultValue: false,
                });
                this.setField('event_incident_scopes', {
                    hidden: false,
                    disabled: false,
                });
            }

            if (eventIncident.event_contents) {
                const values = eventIncident.event_contents.map(
                    (eventContent) => eventContent.id
                );

                this.setField('event_contents', {
                    defaultValue: values,
                    disabled: true,
                    loading: true,
                });

                let selectedScopes = {};

                eventIncident.event_incident_scopes.forEach((scope) => {
                    selectedScopes[`sp-${scope.encoding_target.id}-${scope.stream_protocol.id}`] = true;
                });

                this.setState(() => ({
                    selectedScopes: selectedScopes,
                }));

                this.getScopes(values, selectedScopes);
            }

            if (eventIncident.lasted_entire_duration) {
                this.setField(['start_datetime', 'end_datetime'], {
                    hidden: true,
                    disabled: true,
                });
            }

            this.setState(() => ({
                dataReceived: true,
            }));
        }
    }

    getScopes(values, selected = this.state.selectedScopes) {
        super.getScopes(values, selected);
    }

    getDropdownFieldsOptions = (props, entity) => {
        const causerObject = _get(entity, 'event_incident_reason.event_incident_causer', {
            event_incident_location: {
                id: null,
            },
            id: null,
        });

        this.setState(() => ({
            defaultForm_loading: true,
        }));

        this.runApolloRequest('query', {
            query: gql(optionsForEditEventIncident),
            variables: {
                eventId: _get(props, 'formParams.optionsVariables.eventId', null),
                locationId: parseInt(causerObject.event_incident_location.id, 10),
                causerId: parseInt(causerObject.id, 10),
            },
        }).then((data) => {
            this.setField('event_incident_reason', {
                options: _get(data, 'data.eventIncidentReasons', []),
            });

            this.setField('causer', {
                options: _get(data, 'data.eventIncidentCausers', []),
            });

            this.setState(() => ({
                defaultForm_loading: false,
            }));
        }).catch((error) => {
            this.setFormMessage(error);
        });
    };
}

export default withApollo(EventsIncidentEditForm);
