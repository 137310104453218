import {get as _get} from "lodash"

import ProductGeolocationsModel from "./ProductGeolocationsModel";

import {GetOptionsForProductGeolocationsFormEdit} from "@graphql/country/query";

export default function ProductGeolocationsModelEdit() {
    const productGeolocationsModelEdit = ProductGeolocationsModel();

    productGeolocationsModelEdit.title = (data) => {
        const countryName = _get(data, "clientProductGeolocationCountry.country.name", null);

        return countryName ? `Country "${countryName}"` : '';
    };

    productGeolocationsModelEdit.fields.country.optionsKey = "countries";
    productGeolocationsModelEdit.query.getOptions = GetOptionsForProductGeolocationsFormEdit;

    return productGeolocationsModelEdit;
}
