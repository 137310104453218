import React from "react";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";

import {Renderer} from "../../app/components/TabsComponents/Renderer";
import {TitleFromQuery} from "../../app/components/HeaderWithQuery";

import {
    createTabs,
    RenderBackToListButton,
} from "../utils/roleTabsLayoutHelper";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import navigation from "../../app/constants/navigation";
import {routes} from "../../app/constants/routes";

import {GetRoleForForm} from "../../../graphql/role/query.graphql";

export const RoleTabsLayout = ({
    match: {
        path,
        params: {
            id = null
        }
    },
    Menu
}) => {
    React.useEffect(() => {
        Menu.storeMenu(navigation.settings.key);
    }, []);

    let messageBoxesNames = ["resources", 'roleMessageError', 'roleResourcesErrorMessage'];

    if (routes.roles.add.path !== path) {
        messageBoxesNames.push("roleMessage");
    }

    return (
        <Renderer
            active={path}
            buttons={RenderBackToListButton}
            items={createTabs(id, path)}
            messagesBoxNames={messageBoxesNames}
            pageTitle={
                <TitleFromQuery
                    query={GetRoleForForm}
                    variables={{id}}
                    errorBackLink={routes.roles.index.path}
                    path={"role.name"}
                    entity={"role"}
                />
            }
        />
    );
};
RoleTabsLayout.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        params: PropTypes.shape({
            id: PropTypes.string
        }),
    }),
    Menu: PropTypes.object.isRequired,
};

export default connect(null, mapModulesToProps(['Menu']))(RoleTabsLayout);
