import PropTypes from 'prop-types';
import {get as _get} from "lodash";

import {getProperty} from "../common";

/**
 * Display value for Sport
 */
export const sport = (event) => {
    if (event.is_master && event.sr_event) {
        return _get(event, "sr_event.master_match.sport.name", {});
    }

    const eventData = {
        data: event,
        srWithMatchID: ['match', 'tournament', 'tournament_category', 'sport'],
        srWithoutMatchID: ['tournament', 'tournament_category', 'sport']
    };

    return getProperty(eventData, 'name');
};

sport.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            master_match: PropTypes.shape({
                sport: PropTypes.shape({
                    name: PropTypes.string
                })
            })
        }),
        is_master: PropTypes.bool
    }).isRequired
};

sport.defaultProps = {
    event: {
        sr_event: {
            master_match: {
                sport: {
                    name: null
                }
            }
        },
        is_master: false
    }
}
