import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
/* eslint import/no-unresolved: 0 */
import {graphql} from "react-apollo";

import CdnConfigurationsIndexButtons from "../components/CdnConfigurationsIndexButtons";
import ClientCdnConfigurationTable from "../components/ClientCdnConfigurationTable";
import HeaderRenderer from "../../app/components/HeaderRenderer";

import {convertToInt} from "../../../utils/helpers";
import {exportExcel} from "../utils/export/cdnConfigurationsIndexExcel";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {GetClientCdnConfigurationsForTable} from "../../../graphql/clientCdnConfiguration/query.graphql";

const CdnConfigurationsIndex = (props) => {
    const {clientId, DataClientCdnConfigurations} = props;

    return (
        <div>
            <HeaderRenderer
                buttons={CdnConfigurationsIndexButtons}
                buttonsProps={{clientId: clientId}}
                exportExcelParams={exportExcel(
                    DataClientCdnConfigurations.clientCdnConfigurations,
                    clientId
                )}
                loading={DataClientCdnConfigurations.loading}
            />
            <ClientCdnConfigurationTable
                clientId={clientId}
                clientCdnConfigurations={DataClientCdnConfigurations.clientCdnConfigurations}
                loading={DataClientCdnConfigurations.loading}
            />
        </div>
    )
};

const ClientCdnConfigurationWithQuery = compose(
    graphql(GetClientCdnConfigurationsForTable, {
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: convertToInt(props.match.params.id),
                },
            };
        },
        name: "DataClientCdnConfigurations",
    })
)(CdnConfigurationsIndex);

CdnConfigurationsIndex.propTypes = {
    clientId: PropTypes.number.isRequired,
    DataClientCdnConfigurations: PropTypes.shape({
        clientCdnConfigurations: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
    }),
};

CdnConfigurationsIndex.defaultProps = {
    DataClientCdnConfigurations: {
        clientCdnConfigurations: [],
        loading: false,
    },
};

const mapDispatchToProps = mapModulesToProps(['MessageBox']);

export default withRouter(connect(mapDispatchToProps)(ClientCdnConfigurationWithQuery));
