import {GetEventBookingForGeoRestrictionsForm} from '@graphql/eventBooking/query';
import {UpdateBooking, DeleteBooking} from '@graphql/booking/mutation';

import EventBookingGeoRestrictionsModel from './EventBookingGeoRestrictionsModel';

export default function EventBookingGeoRestrictionsEditModel() {
    const eventBookingGeoRestrictionsEditModel = EventBookingGeoRestrictionsModel();

    eventBookingGeoRestrictionsEditModel.deleteId = 'booking.id';
    eventBookingGeoRestrictionsEditModel.editForm = true;
    eventBookingGeoRestrictionsEditModel.mutation = {
        updateEntity: UpdateBooking,
        deleteEntity: DeleteBooking,
    };
    eventBookingGeoRestrictionsEditModel.query.getEntity = GetEventBookingForGeoRestrictionsForm;

    return eventBookingGeoRestrictionsEditModel;
}
