import moment from 'moment/moment';
import {debounce as _debounce, map as _map} from 'lodash';

import {showConstraintsModal} from '@modules/client/utils/constraintsTables';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {showMessageBox} from '@utils/messageBox';
import {GetSportsForDropdown} from '@graphql/sport/query';
import {GetContentCategoriesForDropdown} from '@graphql/contentCategory/query';
import {GetTournamentCategoriesFilteredBySports} from '@graphql/tournamentCategory/query';
import {GetTournamentsByTournamentCategories} from '@graphql/tournament/query';
import {contentCategoriesFilteredByLevelAndParents} from '@graphql/content';
import {EVENT_NON_SPORTRADAR_TYPE, EVENT_SPORTRADAR_TYPE} from '@constants/variables';
import {gql} from 'react-apollo';
import {getSelectedValues} from '@appComponents/HOCFiltersWrapper';

import apolloClient from '../../../apolloClient';

const DATE_FORMAT = 'YYYY-MM-DD';

export function updateFilterState(prevState, {fromMaxDate, toMinDate}, setState) {
    const nextState = {...prevState};

    fromMaxDate = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
    toMinDate = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);

    if (fromMaxDate && !moment(prevState.fromMaxDateDefault).isSame(fromMaxDate)) {
        nextState.fromMaxDateDefault = fromMaxDate;
        nextState.fromMaxDate = fromMaxDate;
    }

    if (toMinDate && !moment(prevState.toMinDateDefault).isSame(toMinDate)) {
        nextState.toMinDateDefault = toMinDate;
        nextState.toMinDate = toMinDate;
    }

    if (
        nextState.fromMaxDateDefault &&
        nextState.toMinDateDefault &&
        nextState.fromMaxDateDefault.format(`${DATE_FORMAT}`) === nextState.toMinDateDefault.format(`${DATE_FORMAT}`)
    ) {
        nextState.fromMaxTime = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
        nextState.toMinTime = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);
    }

    setState(() => nextState);
}

export const onSubmit = (data) => {
    const from = moment(data.from).format(`${DATE_FORMAT} HH:mm`),
        to = moment(data.to).format(`${DATE_FORMAT} HH:mm`);

    if (from !== data.from && to !== data.to) {
        data.from = moment().format(`${DATE_FORMAT} 00:00`);
        data.to = null;
    } else if (from !== data.from) {
        data.from = moment(data.to).subtract(1, 'd').format(`${DATE_FORMAT} HH:mm`);
    } else if (to !== data.to) {
        data.to = null;
    }

    showConstraintsModal(data);

    return data;
};

export const getSports = (propsForm, setState) => {
    apolloClient.query({
        query: GetSportsForDropdown,
    }).then((result) => {
        const {sports} = result.data;
        const sportsData = sortDropdownOptionsAlphanumerically(sports.mapDataForDropdownWithIntVal());

        setState(() => ({
            sports: sportsData,
            sportsLoading: false,
        }));

        const stateUpdateObject = {
            sports: sportsData,
            sportsLoading: false,
        };

        debouncedStateUpdate(setState, stateUpdateObject);

    }).catch((error) => {
        setState(() => ({
            sports: [],
            sportsLoading: false,
        }));

        showMessageBox(
            'clientMessage',
            'Failed to fetch sports filter data',
            `${error}`,
            'error'
        );
    });
};

export const getTournamentCategories = (propsForm, sportIds, selectedTournamentCategories, setState) => {
    apolloClient.query({
        query: GetTournamentCategoriesFilteredBySports,
        variables: {
            sports: sportIds,
        },
    }).then((result) => {
        const tournamentCategories = sortDropdownOptionsAlphanumerically(
            result.data.tournamentCategories.mapDataForDropdownWithIntVal()
        );

        setState(() => ({
            tournamentCategories: tournamentCategories,
            tournamentCategoriesLoading: false,
        }));

        const stateUpdateObject = {
            tournamentCategories: tournamentCategories,
            tournamentCategoriesLoading: false,
        };

        debouncedStateUpdate(setState, stateUpdateObject);

        propsForm.changeValue(
            'tournamentCategories',
            getSelectedValues(selectedTournamentCategories, tournamentCategories)
        );

    }).catch((error) => {
        setState(() => ({
            tournamentCategories: [],
            tournamentCategoriesLoading: false,
        }));

        showMessageBox(
            'clientMessage',
            'Failed to fetch tournament categories filter data',
            `${error}`,
            'error'
        );
    });
};

export const getTournaments = (propsForm, tournamentCategoryIds, selectedTournaments, setState) => {
    apolloClient.query({
        query: GetTournamentsByTournamentCategories,
        variables: {
            tournamentCategories: tournamentCategoryIds,
        },
    }).then((result) => {
        const tournaments = sortDropdownOptionsAlphanumerically(
            result.data.tournaments.mapDataForDropdownWithIntVal()
        );

        setState(() => ({
            tournaments: tournaments,
            tournamentsLoading: false,
        }));

        const stateUpdateObject = {
            tournaments: tournaments,
            tournamentsLoading: false,
        };

        debouncedStateUpdate(setState, stateUpdateObject);

        propsForm.changeValue('tournaments',
            getSelectedValues(selectedTournaments, tournaments)
        );

    }).catch((error) => {
        setState(() => ({
            tournaments: [],
            tournamentsLoading: false,
        }));

        showMessageBox(
            'clientMessage',
            'Failed to fetch tournaments filter data',
            `${error}`,
            'error'
        );
    });
};

export const getContentCategoriesLevel1 = (propsForm, setState) => {
    apolloClient.query({
        query: GetContentCategoriesForDropdown,
        variables: {
            level: 1,
        },
    }).then((result) => {
        const {contentCategories} = result.data;
        const contentCategoriesData = sortDropdownOptionsAlphanumerically(contentCategories.mapDataForDropdownWithIntVal());

        setState(() => ({
            contentCategoriesLevel1: contentCategoriesData,
            contentCategoriesLevel1Loading: false,
        }));

        const stateUpdateObject = {
            contentCategoriesLevel1: contentCategoriesData,
            contentCategoriesLevel1Loading: false,
        };

        debouncedStateUpdate(setState, stateUpdateObject);

    }).catch((error) => {
        setState(() => ({
            contentCategoriesLevel1: [],
            contentCategoriesLevel1Loading: false,
        }));

        showMessageBox(
            'clientMessage',
            'Failed to fetch content categories filter data',
            `${error}`,
            'error'
        );
    });
};

export const getContentCategoriesLevel2 = (propsForm, contentCategoryLevel2Ids, selectedContentCategoriesLevel2, setState) => {
    apolloClient.query({
        query: gql(contentCategoriesFilteredByLevelAndParents),
        variables: {
            level: 2,
            parents: contentCategoryLevel2Ids,
        },
    }).then((result) => {
        const contentCategoriesLevel2 = sortDropdownOptionsAlphanumerically(
            result.data.contentCategories.mapDataForDropdownWithIntVal()
        );

        setState(() => ({
            contentCategoriesLevel2: contentCategoriesLevel2,
            contentCategoriesLevel2Loading: false,
        }));

        const stateUpdateObject = {
            contentCategoriesLevel2: contentCategoriesLevel2,
            contentCategoriesLevel2Loading: false,
        };

        debouncedStateUpdate(setState, stateUpdateObject);

        propsForm.changeValue('contentCategoryLevel2',
            getSelectedValues(selectedContentCategoriesLevel2, contentCategoriesLevel2)
        );

    }).catch((error) => {
        setState(() => ({
            contentCategoriesLevel2: [],
            contentCategoriesLevel2Loading: false,
        }));

        showMessageBox(
            'clientMessage',
            'Failed to fetch 2nd level content categories filter data',
            `${error}`,
            'error'
        );
    });
};

export const getContentCategoriesLevel3 = (propsForm, contentCategoryLevel3Ids, selectedContentCategoriesLevel3, setState) => {
    apolloClient.query({
        query: gql(contentCategoriesFilteredByLevelAndParents),
        variables: {
            level: 3,
            parents: contentCategoryLevel3Ids,
        },
    }).then((result) => {
        const contentCategoriesLevel3 = sortDropdownOptionsAlphanumerically(
            result.data.contentCategories.mapDataForDropdownWithIntVal()
        );

        setState(() => ({
            contentCategoriesLevel3: contentCategoriesLevel3,
            contentCategoriesLevel3Loading: false,
        }));

        const stateUpdateObject = {
            contentCategoriesLevel3: contentCategoriesLevel3,
            contentCategoriesLevel3Loading: false,
        };

        debouncedStateUpdate(setState, stateUpdateObject);

        propsForm.changeValue('contentCategoryLevel3',
            getSelectedValues(selectedContentCategoriesLevel3, contentCategoriesLevel3)
        );

    }).catch((error) => {
        setState(() => ({
            contentCategoriesLevel3: [],
            contentCategoriesLevel3Loading: false,
        }));

        showMessageBox(
            'clientMessage',
            'Failed to fetch 3rd level content categories filter data',
            `${error}`,
            'error'
        );
    });
};

const debouncedStateUpdate = (setStateFunc, stateUpdateObject) => {
    const debouncedUpdate = _debounce(() => {
        setStateFunc(stateUpdateObject);
    }, 1000);

    debouncedUpdate();
};

export const getMaxMinDate = (filters, restoreFilterValues) => {
    const {ClientBookings} = filters;
    const fromMaxDate = restoreFilterValues(ClientBookings, 'to');
    const toMinDate = restoreFilterValues(ClientBookings, 'from', moment());

    return {
        fromMaxDate,
        toMinDate,
    };
};

export const getSportsIds = (value) => {
    return _map(value, (sport) => (
        convertToInt(sport, 10)
    ));
};

export const getTournamentCategoryIds = (value) => {
    return _map(value, (tournamentCategory) => (
        convertToInt(tournamentCategory, 10)
    ));
};

export const getContentCategoryLevel2Ids = (value) => {
    return _map(value, (contentCategoryLevel2) => (
        convertToInt(contentCategoryLevel2, 10)
    ));
};

export const getContentCategoryLevel3Ids = (value) => {
    return _map(value, (contentCategoryLevel3) => (
        convertToInt(contentCategoryLevel3, 10)
    ));
};

export const updateDateState = (prevState, date, name) => {
    const fromMaxDate = prevState.fromMaxDate || moment();
    const toMinDate = prevState.toMinDate || moment();

    return {
        fromMaxTime: null,
        toMinTime: null,
        ...('from' === name && {
            toMinDate: date,
            ...(date.format(DATE_FORMAT) === fromMaxDate.format(DATE_FORMAT) && {
                fromMaxTime: fromMaxDate,
                toMinTime: date,
            }),
        }),
        ...('to' === name && {
            fromMaxDate: date,
            ...(date.format(DATE_FORMAT) === toMinDate.format(DATE_FORMAT) && {
                fromMaxTime: date,
                toMinTime: toMinDate,
            }),
        }),
    };
};

export const handleOnEventTypeChange = (event, value, setState) => {
    const type = value ? convertToInt(value) : '';
    const hasSport = (type === EVENT_SPORTRADAR_TYPE);
    const hasContentCategoryLevel1 = (type === EVENT_NON_SPORTRADAR_TYPE);

    const state = {
        hasSelectedContentCategoryLevel1: false,
        hasSelectedSport: false,
        hasContentCategoryLevel1: hasContentCategoryLevel1,
        hasSport: hasSport,
    };

    setState(state);
};

export const fetchSports = (
    selectedEventType,
    propsForm,
    setState
) => {
    const type = selectedEventType ? convertToInt(selectedEventType) : '';
    const isSportRadarEvent = (type === EVENT_SPORTRADAR_TYPE);

    if (!isSportRadarEvent) {
        propsForm.changeValue('sports', null);
        propsForm.changeValue('tournamentCategories', null);
        propsForm.changeValue('tournaments', null);

        return;
    }

    getSports(propsForm, setState);
};

export const fetchContentCategoriesLevel1 = (
    selectedEventType,
    propsForm,
    setState
) => {
    const type = selectedEventType ? convertToInt(selectedEventType) : '';
    const isNonSportRadarEvent = (type === EVENT_NON_SPORTRADAR_TYPE);

    if (!isNonSportRadarEvent) {
        propsForm.changeValue('contentCategoryLevel1', null);
        propsForm.changeValue('contentCategoryLevel2', null);
        propsForm.changeValue('contentCategoryLevel3', null);

        return;
    }

    getContentCategoriesLevel1(propsForm, setState);
};

export const handleOnSportChange = (event, value, setState, fetchTournamentCategories) => {
    const hasSelectedSport = 0 < value.length;

    const state = {
        hasSelectedSport,
        tournamentCategories: [],
        tournamentCategoriesLoading: hasSelectedSport,
    };

    setState(state, fetchTournamentCategories);
};

export const fetchTournamentCategories = (
    selectedSports,
    propsForm,
    selectedTournamentCategories,
    setState
) => {
    if (0 < selectedSports.length) {
        const sportIds = getSportsIds(selectedSports);

        getTournamentCategories(
            propsForm,
            sportIds,
            selectedTournamentCategories,
            setState
        );
    } else {
        propsForm.changeValue('tournamentCategories', null);
    }
};

export const handleOnTournamentCategoryChange = (event, value, setState, fetchTournaments) => {
    const hasSelectedTournamentCategory = 0 < value.length;

    const state = {
        hasSelectedTournamentCategory,
        selectedTournamentCategories: value,
        tournaments: [],
        tournamentsLoading: hasSelectedTournamentCategory,
    };

    setState(state, fetchTournaments);
};

export const fetchTournaments = (
    selectedTournamentCategories,
    propsForm,
    selectedTournaments,
    setState
) => {
    if (0 < selectedTournamentCategories.length) {
        const tournamentCategoryIds = getTournamentCategoryIds(selectedTournamentCategories);

        getTournaments(
            propsForm,
            tournamentCategoryIds,
            selectedTournaments,
            setState
        );
    } else {
        propsForm.changeValue('tournaments', null);
    }
};

export const handleOnContentCategoryLevel1Change = (event, value, setState, fetchContentCategoryLevel2) => {
    const hasSelectedContentCategoryLevel1 = 0 < value.length;

    const state = {
        hasSelectedContentCategoryLevel1,
        contentCategoriesLevel2: [],
        contentCategoriesLevel2Loading: hasSelectedContentCategoryLevel1,
    };

    setState(state, fetchContentCategoryLevel2);
};

export const fetchContentCategoryLevel2 = (
    selectedContentCategory1,
    propsForm,
    selectedContentCategoriesLevel2,
    setState
) => {
    if (0 < selectedContentCategory1.length) {
        const contentCategoryLevel2Ids = getContentCategoryLevel2Ids(selectedContentCategory1);

        getContentCategoriesLevel2(
            propsForm,
            contentCategoryLevel2Ids,
            selectedContentCategoriesLevel2,
            setState
        );
    } else {
        propsForm.changeValue('contentCategoryLevel2', null);
    }
};

export const handleOnContentCategoryLevel2Change = (event, value, setState, fetchContentCategoryLevel3) => {
    const hasSelectedContentCategoryLevel2 = 0 < value.length;

    const state = {
        hasSelectedContentCategoryLevel2,
        selectedContentCategoriesLevel2 : value,
        contentCategoriesLevel3: [],
        contentCategoriesLevel3Loading: hasSelectedContentCategoryLevel2,
    };

    setState(state, fetchContentCategoryLevel3);
};

export const fetchContentCategoryLevel3 = (
    selectedContentCategory2,
    propsForm,
    selectedContentCategoriesLevel3,
    setState
) => {
    if (0 < selectedContentCategory2.length) {
        const contentCategoryLevel3Ids = getContentCategoryLevel3Ids(selectedContentCategory2);

        getContentCategoriesLevel3(
            propsForm,
            contentCategoryLevel3Ids,
            selectedContentCategoriesLevel3,
            setState
        );
    } else {
        propsForm.changeValue('contentCategoryLevel3', null);
    }
};
