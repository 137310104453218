import React from "react";
import PropTypes from "prop-types";

import ContentHeader from "./ContentHeader";
import ExcelExportButton from "./ExcelExportButton";
import FilterButton from "./FilterButton";

const HeaderRenderer = (props) => {
    return (
        <div>
            <ContentHeader
                messagesBoxNames={props.messagesBoxNames}
                title={props.pageTitle}
            >
                <div className="content__buttons">
                    {
                        /* We need EXCEL EXPORT and DATA IN EXPORT EXCEL PARAMETERS to render button */
                        (undefined !== props.contentButtons
                            && props.exportExcelParams.dataExport
                            && 0 < props.exportExcelParams.dataExport.length
                        )
                            ? (
                                React.createElement(
                                    props.contentButtons,
                                    props.contentButtonsProps,
                                )
                            )
                            : null
                    }
                    <ExcelExportButton exportExcelParams={props.exportExcelParams} loading={props.loading} />
                    {props.filtersButtonName ? <FilterButton name={props.filtersButtonName}/> : null}
                </div>
                {
                    (undefined !== props.buttons && null !== props.buttons)
                        ? (
                            React.createElement(
                                props.buttons,
                                props.buttonsProps,
                            )
                        )
                        : null
                }
            </ContentHeader>
            {
                (undefined !== props.hintBox) ?
                    (
                        React.createElement(
                            props.hintBox,
                            props.hintBoxProps,
                        )
                    )
                    : null
            }
            <div className="clearfix"/>
            {
                (undefined !== props.filters)
                    ? (
                        React.createElement(
                            props.filters,
                            props.filtersProps,
                        )
                    )
                    : null
            }
            <div className="clearfix"/>
        </div>
    );
};

HeaderRenderer.propTypes = {
    buttons: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.object.isRequired,
    ]),
    buttonsProps: PropTypes.object,
    exportExcelParams: PropTypes.object,
    contentButtons: PropTypes.func,
    contentButtonsProps: PropTypes.object,
    filters: PropTypes.func,
    filtersButtonName: PropTypes.string,
    filtersProps: PropTypes.object,
    hintBox: PropTypes.func,
    hintBoxProps: PropTypes.object,
    loading: PropTypes.bool,
    messagesBoxNames: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    pageTitle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
HeaderRenderer.defaultProps = {
    buttonsProps: {},
    exportExcelParams: {},
    filtersProps: {},
    hintBox: () => null,
    hintBoxProps: {},
    contentButtons: () => null,
    contentButtonsProps: {},
    exportProps: {},
    loading: false,
};

export default HeaderRenderer;
