import PropTypes from "prop-types";

export const getFieldsList = (event) => {
    return [
        {
            name: 'Notes',
            value: event.notes,
            classname: '--notes'
        }
    ];
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        notes: PropTypes.string
    }).isRequired
};

getFieldsList.defaultProps = {
    event: {
        notes: null
    }
}
