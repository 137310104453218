import React from 'react';
import PropTypes from 'prop-types';
import {isFunction as _isFunction, isEmpty as _isEmpty} from 'lodash';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginFileValidateType);

class FileUpload extends React.Component {
    static propTypes = {
        extensions: PropTypes.array,
        input: PropTypes.object,
        label: PropTypes.string,
        labelFileTypeExpected: PropTypes.string,
        labelIdle: PropTypes.string,
        meta: PropTypes.object,
        onAddFileCallback: PropTypes.func,
        onErrorCallback: PropTypes.func,
        onRemoveFileCallback: PropTypes.func,
        onUpdateFilesCallback: PropTypes.func,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        labelFileTypeExpected: 'Expects {allTypes}',
        labelIdle: 'Choose file',
        required: false,
    };

    state = {error: ''};

    /**
     * @ignore
     */
    renderLabel = () => {
        let label = '',
            labelFor;
        const errorClass = this.renderErrorInfo() ? 'error' : '';

        if (this.props.label) {
            labelFor = undefined !== this.props.input ? this.props.input.name : '';

            if (this.props.required) {
                label = <label htmlFor={labelFor} className={errorClass}>{this.props.label}<span
                    className='form-input-required'>*</span></label>;
            } else {
                label = <label htmlFor={labelFor} className={errorClass}>{this.props.label}</label>;
            }
        }

        return label;
    };

    /**
     * @ignore
     */
    renderErrorInfo = () => {
        let info = '';

        if (this.state.error) {
            info = this.state.error;
        } else if (this.props.meta.touched && this.props.meta.error) {
            info = this.props.meta.error;
        }

        return info;
    };

    onAddFileCallback = (error, file) => {
        if (!error && _isFunction(this.props.onAddFileCallback)) {
            this.props.onAddFileCallback(error, file);
        }
    };

    onErrorCallback = (file, error) => {
        const errorText = error.sub ? `${error.main}. ${error.sub}.` : `${error.main}.`;

        this.setState(() => ({
            error: errorText,
        }));

        if (_isFunction(this.props.onErrorCallback)) {
            this.props.onErrorCallback(file, error);
        }
    };

    onRemoveFileCallback = (file) => {
        if (_isFunction(this.props.onRemoveFileCallback)) {
            this.props.onRemoveFileCallback(file);
        }
    };

    onUpdateFilesCallback = (fileItems) => {
        const files = fileItems.map(fileItem => fileItem.file);

        if(_isFunction(this.props.onUpdateFilesCallback)) {
            this.props.onUpdateFilesCallback(files);
        }

        this.setState(() => ({
            error: '',
        }));
    };

    render() {
        return (
            <div>
                {this.renderLabel()}
                <div className='input-container'>
                    <div className='form__field-wrapper'>
                        <FilePond
                            allowFileTypeValidation={!_isEmpty(this.props.extensions) || false}
                            acceptedFileTypes={this.props.extensions}
                            fileValidateTypeLabelExpectedTypes={this.props.labelFileTypeExpected}
                            labelIdle={this.props.labelIdle}
                            onaddfile={this.onAddFileCallback}
                            onerror={this.onErrorCallback}
                            onremovefile={this.onRemoveFileCallback}
                            onupdatefiles={this.onUpdateFilesCallback}
                            disabled={this.props.disabled}
                        >
                        </FilePond>
                    </div>
                    <div className='error info'>{this.renderErrorInfo()}</div>
                </div>
            </div>
        );
    }
}

export default FileUpload;
