import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react'

export default class EventsContentPanel extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        setContentExpandedState: PropTypes.func.isRequired,
        shouldExpand: PropTypes.bool.isRequired,
        shouldExpandTimestamp: PropTypes.number.isRequired,
        subparts: PropTypes.array,
        title: PropTypes.object
    };

    static defaultProps = {
        subparts: []
    };

    constructor(props) {
        super(props);

        this.state = {
            active: true
        };
    }

    componentDidUpdate(prevProps) {
        const {
                shouldExpandTimestamp: prevShouldExpandTimestamp,
            } = prevProps,
            {
                shouldExpand,
                shouldExpandTimestamp,
            } = this.props;

        if (shouldExpandTimestamp !== prevShouldExpandTimestamp) {
            this.setState(() => ({active: shouldExpand}), () => {
                this.props.setContentExpandedState(this.props.id, this.state.active);
            });
        }
    }

    toggle = () => {
        this.setState(() => ({active: !this.state.active}), this.toggleSetStateCallback);
    };

    toggleSetStateCallback = () => {
        this.props.setContentExpandedState(this.props.id, this.state.active);
    }

    render() {
        return (
            <Accordion fluid styled className="eventContent" id={`content-${this.props.id}`}>
                <Accordion.Title active={this.state.active} onClick={this.toggle}>
                    <Icon name='dropdown' />
                    {this.props.title}
                </Accordion.Title>
                <Accordion.Content active={this.state.active}>
                    {this.props.subparts.map(part => part)}
                </Accordion.Content>
            </Accordion>
        );
    }
}
