import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';

import {convertToInt} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Authorization from '@appComponents/Authorization';
import {IconCheckmark, IconEdit} from '@appComponents/IconCollection';
import {LCR_CHANNEL} from '@constants/resources';
import {
    SECURITY_PRIVILEGES_READ,
    TERRITORY_SELECTION_TYPE_WORLDWIDE,
    TERRITORY_SELECTION_TYPE_WHITELIST,
    TERRITORY_SELECTION_TYPE_BLACKLIST,
} from '@constants/variables';
import {showMessageBox} from '@utils/messageBox';
import {renderModalError} from '@utils/forms';
import {
    getSortingTerritories,
    getTerritoriesWithTooltip,
    renderDivWrappedCountriesTableCell,
} from '@utils/countryHelpers';

const authorizationObject = {
    [LCR_CHANNEL]: SECURITY_PRIVILEGES_READ,
};

export const prepareStringForExcelSorting = (row) => (row.name);

export const renderLcrCountriesForExcelExport = (row) => {

    const territorySelectionType = row.territory_selection_type ? convertToInt(row.territory_selection_type.id) : null;

    const subdivisions = {};

    for (const subdivision of row.country_subdivisions) {
        if (!subdivisions[subdivision.country.id]) {
            subdivisions[subdivision.country.id] = [];
        }
        subdivisions[subdivision.country.id].push(subdivision.name);
    }

    const countriesNames = row.countries.map(country => country.name + (
        subdivisions[country.id]
            ? ` - (${subdivisions[country.id].join(', ')})`
            : ''
    ));

    if (TERRITORY_SELECTION_TYPE_WORLDWIDE === territorySelectionType) {
        return 'Worldwide';
    } else if (null === territorySelectionType) {
        return 'No geo restrictions';
    } else if (TERRITORY_SELECTION_TYPE_BLACKLIST === territorySelectionType) {
        return 'Worldwide except ' + countriesNames.join(', ');
    }

    return countriesNames.join(', ');
};

export const renderLcrContentsAsText = (row) => {
    const contentData = getCombinedContentData(row);

    if (0 === Object.keys(contentData).length) {
        return 'All';
    }

    return Object.values(
        getCombinedContentData(row)
    ).filter(contentData => contentData.length).join(', ');
};

export const showErrorNotificationMessage = () => {
    showMessageBox(
        'LcrChannelIndex',
        'Failed to fetch filter data',
        'There was some error while fetching data for filters',
        'error',
        true
    );
};

export const renderLcrChannelsModalError = (message) => (
    renderModalError(
        'LCR Channels',
        getLink('lcrChannels.index'),
        message
    )
);
renderLcrChannelsModalError.displayName = 'renderLcrChannelsModalError';

const noPaddingClassName = '--noPadding';

export const lcrChannelsColumns = {
    'id': {
        label: 'ID',
        sorting: row => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
            className: noPaddingClassName,
        },
        content: {align: 'center'},
    },
    'name': {
        label: 'Name',
        sorting: row => row.name,
        header: {
            align: 'left',
            isCollapsed: true,
        },
        content: {align: 'left'},
    },
    'lmt': {
        label: 'LMT',
        sorting: row => row.has_lmt_support,
        header: {
            align: 'center',
            isCollapsed: true,
            className: noPaddingClassName,
        },
        content: {align: 'center'},
    },
    'territories': {
        label: 'Territories',
        sorting: (row) => getSortingTerritories(row),
        header: {
            align: 'left',
            isCollapsed: true,
        },
        content: {align: 'left'},
    },
    'content': {
        label: 'Content',
        sorting: (row) => renderLcrContentsAsText(row),
        header: {
            align: 'left',
            isCollapsed: true,
        },
        content: {align: 'left'},
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
            className: noPaddingClassName,
        },
        content: {align: 'center'},
    },
};

export const renderLinkToEditAndDescription = (row, isName = false) => {
    return (
        <div>
            <LinkWithAuthorization
                authorization={authorizationObject}
                link={{
                    name: 'lcrChannel.edit.generalInformation',
                    params: {id: row.id},
                }}
            >
                {isName ? row.name : row.id}
            </LinkWithAuthorization>
            {isName && !_isEmpty(row.description) ? <p className='muted'>{row.description}</p> : null}
        </div>
    );
};

const prepareNamesAndTiers = (data) => data.map((element) => {
    if (element.content_tiers) {
        const tiers = element.content_tiers.map((tier) => tier.name);

        return element.entity.name + (0 < tiers.length ? ` (${tiers.sort().join(', ')})` : '');
    }

    return element.name;
}).sort();

const getContent = (data, selectionType, category, maxElements = 0) => {
    if (0 === maxElements || 0 === data.length) {
        data = data.length;
    } else {
        data = prepareNamesAndTiers(data).slice(0, maxElements).join(', ') + (
            (maxElements < data.length) ? '...' : ''
        );
    }

    return formatContentBySelectionType(data, category, convertToInt(selectionType));
};

const formatContentBySelectionType = (content, category, selectionType) => {
    if (0 === content) {
        return null;
    }

    if (TERRITORY_SELECTION_TYPE_WHITELIST === selectionType) {
        if ('sports' !== category) {
            return `${content} ` + formatCategoryByNumberOfElements(category, content);
        }

        return content;
    }

    if (TERRITORY_SELECTION_TYPE_BLACKLIST === selectionType) {
        return `All ${category} except: ` + content;
    }

    return null;
};

const formatCategoryByNumberOfElements = (category, numberOfElements) => {
    if (0 === numberOfElements || 1 < numberOfElements) {
        return category;
    }

    if ('categories' === category) {
        return 'category';
    }

    return category.slice(0, -1);
};

const getCombinedContentData = (row) => {
    const data = {},
        sportsContent = getContent(row.lcr_channel_sports, row.sport_selection_type.id, 'sports', 10),
        tournamentsContent = getContent(row.tournaments, row.tournament_selection_type.id, 'tournaments'),
        tournamentCategoriesContent = getContent(
            row.lcr_channel_tournament_categories,
            row.tournament_category_selection_type.id,
            'categories'
        );

    if (sportsContent) {
        data.sports = sportsContent;
    }

    if (tournamentCategoriesContent) {
        data.tournamentCategories = tournamentCategoriesContent;
    }

    if (tournamentsContent) {
        data.tournaments = tournamentsContent;
    }

    return data;
};

export const renderContentData = (row) => {
    const contentData = getCombinedContentData(row);

    if (0 === Object.keys(contentData).length) {
        return renderDivWrappedCountriesTableCell(row.id, 'All');
    }

    return renderDivWrappedCountriesTableCell(row.id, (
        <div>
            {
                Object.values(contentData).map((item, index) => (
                    <div key={`${row.id}-${index}`}>{item}</div>
                ))
            }
        </div>
    ));
};


export const rowRenderer = (column, row, props) => {
    const {mappedIsoCodes} = props;

    switch (column) {
        case 'id': {
            return renderLinkToEditAndDescription(row);
        }
        case 'name': {
            return renderLinkToEditAndDescription(row, true);
        }
        case 'lmt': {
            return row.has_lmt_support ? <IconCheckmark/> : '';
        }
        case 'territories': {
            return row.territory_selection_type
                ? getTerritoriesWithTooltip(row, mappedIsoCodes)
                : renderDivWrappedCountriesTableCell(row.id, 'No geo restrictions');
        }
        case 'content': {
            return renderContentData(row);
        }
        case 'actions': {
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            link={getLink('lcrChannel.edit.generalInformation', {id: row.id})}
                            resource={LCR_CHANNEL}
                        />
                    </div>
                </Authorization>
            );
        }
        default:
            return null;
    }
};
