import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';

/* eslint import/no-unresolved: 0 */
import {graphql} from 'react-apollo';
import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import {FilterQueryWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import {setFilter} from '@actions';
import HeaderRenderer from '@appComponents/HeaderRenderer';

import {GetIamUsers} from '../../../graphql/user/query.graphql';
import UsersTableMessage from '../views/UserTable/UsersTableMessage';
import UsersTable from '../views/UsersTable';
import UsersAddTableFilters from '../forms/UsersAddTableFilters';

const UsersAddIndex = ({
    filters: {
        search = '',
    },
    DataIamUsers: DataIamUsers = {
        iamUsers: [],
        loading: false,
        networkStatus: null,
    },
    setFilter,
}) => {
    useEffect(() => {
        // To avoid initial search based on value in the store
        setFilter({
            name: 'UserAddSearch',
            data: {
                search: null,
            },
        });
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                buttonsProps={{name: 'users.index'}}
                filters={UsersAddTableFilters}
                filtersProps={{ loading: DataIamUsers.loading }}
                hintBox={UsersTableMessage}
                pageTitle={'Add new user'}
            />
            <UsersTable
                search={search}
                data={DataIamUsers.iamUsers}
                loading={DataIamUsers.loading}
            />
        </div>
    );
};

/**
 * @ignore
 */
const mapDispatchToProps = (dispatch) => {
    return {
        setFilter: (data) => {
            dispatch(setFilter(data));
        },
    };
};

const UsersAddIndexWithDispatch = connect(null, mapDispatchToProps)(UsersAddIndex);

UsersAddIndex.propTypes = {
    DataIamUsers: PropTypes.shape({
        loading: PropTypes.bool,
        iamUsers: PropTypes.array,
        networkStatus: PropTypes.number,
    }),
    filters: PropTypes.shape({
        search: PropTypes.string,
    }),
    setFilter: PropTypes.func.isRequired,
};

const UsersAddIndexWithQuery = compose(
    graphql(GetIamUsers, {
        skip: props => (undefined === props.filters.search || 3 > props.filters.search.length),
        options: ({
            filters: {
                search = '',
            },
        }) => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            variables: {
                search: search,
            },
        }),
        name: 'DataIamUsers',
    })
)(FilterQueryWrapper(
    UsersAddIndexWithDispatch,
    {
        queryForRefresh: 'DataIamUsers',
        filterUrls: ['users.add'],
        excluded: ['search'],
    }
));

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'UserAddSearch', {}),
});

export default connect(mapStateToProps, null)(UsersAddIndexWithQuery);
