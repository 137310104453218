import {get as _get} from "lodash";

import {convertToInt} from "../../../utils/helpers";

export const columns = {
    "id": {
        label: "ID",
        sorting: row => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        }
    },
    "name": {
        label: "Name",
        sorting: true,
    },
    "contact_person_type": {
        label: "Type",
        sorting: row => _get(row, 'contact_person_type.name', null),
    },
    "position": {
        label: "Position",
        sorting: true,
    },
    "email_address": {
        label: "Email address",
        sorting: true,
    },
    "phone_number_office": {
        label: "Phone number (office)",
        sorting: true,
    },
    "phone_number_mobile": {
        label: "Phone number (mobile)",
        sorting: true,
    },
    "skype_name": {
        label: "Skype name",
        sorting: true,
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
        },
        content: {
            align: "center",
        },
    },
};
