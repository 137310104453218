import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.product, 'product'));
    Object.assign(errors, validators.required(values.device_category, 'device_category'));

    if (!values.max_bitrate && !values.max_player_size) {
        Object.assign(errors, validators.digitsOnly(values.max_resolution_width, 'max_resolution_width'));
        Object.assign(errors, validators.digitsOnly(values.max_resolution_height, 'max_resolution_height'));
    }

    if (!values.max_resolution_width && !values.max_resolution_height && !values.max_bitrate) {
        Object.assign(errors, validators.digitsOnly(values.max_player_size, 'max_player_size'));
        Object.assign(errors, validators.range({value: values.max_player_size, min: 0, max: 100}, 'max_player_size'));
    }

    if (!values.max_resolution_width && !values.max_resolution_height && !values.max_player_size) {
        Object.assign(errors, validators.digitsOnly(values.max_bitrate, 'max_bitrate'));
    }

    if (!values.max_resolution_width && values.max_resolution_height) {
        Object.assign(errors, validators.digitsOnly(values.max_resolution_width, 'max_resolution_width'));
    }

    if (values.max_resolution_width && !values.max_resolution_height) {
        Object.assign(errors, validators.digitsOnly(values.max_resolution_height, 'max_resolution_height'));
    }

    return errors;
};

export default validate;
