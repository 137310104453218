import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 200}, 'name'));
    Object.assign(errors, validators.urlOptional(values.preview_url, 'preview_url', undefined, '.+'));

    return errors;
};

export default validate;
