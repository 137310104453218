import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters, convertBooleanValueForFilters} from '@utils/filters';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {getBookingStatusForOfferingsDefaultValues} from '@utils/booking';
import {GetDropdownData, GetEventOfferingsTableData} from '@graphql/eventOffering/query';
import {getEventContentText} from '@utils/eventContentVariant/eventContentVariant';
import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';

import {exportExcel} from '../utils/export/eventsOfferingsExcel';
import EventsOfferingsTable from '../components/EventsOfferingsTable';
import EventsOfferingsFilters from '../components/EventsOfferingsFilters';

export class EventsOfferings extends React.Component{
    static propTypes = {
        areFiltersVisible: PropTypes.bool,
        countries: PropTypes.array,
        DataDropDown: PropTypes.shape({
            clients: PropTypes.arrayOf(PropTypes.object),
            countries: PropTypes.arrayOf(PropTypes.object),
            deviceCategories: PropTypes.arrayOf(PropTypes.object),
            distributionTypes: PropTypes.arrayOf(PropTypes.object),
            eventContents: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool.isRequired,
            products: PropTypes.arrayOf(PropTypes.object),
            eventContentTypes: PropTypes.arrayOf(PropTypes.object),
            eventContentVariants: PropTypes.arrayOf(PropTypes.object),
        }),
        DataEventOfferings: PropTypes.shape({
            eventOfferings: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool.isRequired,
        }),
        filters: PropTypes.shape({
            clients: PropTypes.arrayOf(PropTypes.string),
            countries: PropTypes.arrayOf(PropTypes.string),
            deviceCategories: PropTypes.arrayOf(PropTypes.string),
            distributionTypes: PropTypes.arrayOf(PropTypes.string),
            eventContents: PropTypes.arrayOf(PropTypes.number),
            products: PropTypes.arrayOf(PropTypes.string),
            bookingStatus: PropTypes.arrayOf(PropTypes.string),
            eventContentTypes: PropTypes.arrayOf(PropTypes.string),
            eventContentVariants: PropTypes.arrayOf(PropTypes.string),
            isHq: PropTypes.number,
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
        }),
    };

    static defaultProps = {
        areFiltersVisible: false,
        countries: [],
        DataDropDown: {
            clients: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventContents: [],
            loading: false,
            products: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
        DataEventOfferings: {
            eventOfferings: [],
        },
        filters: {
            bookingStatus: getBookingStatusForOfferingsDefaultValues(),
            clients: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventContents: [],
            products: [],
            eventContentTypes: [],
            eventContentVariants: [],
            isHq: null,
        },
    };

    state = {
        eventContents: [],
        eventContentsOriginal: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {DataDropDown: {eventContents}} = nextProps,
            nextState = {};

        if (eventContents && eventContents !== prevState.eventContentsOriginal) {
            nextState.eventContentsOriginal = eventContents;
            nextState.eventContents = [];

            eventContents.map((eventContent) => {
                nextState.eventContents.push({
                    key: eventContent.id,
                    text: getEventContentText(eventContent),
                    value: convertToInt(eventContent.id),
                });
            });
        }

        return nextState;
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }
    }

    render() {
        const loading = (
                this.props.DataEventOfferings.loading
                || this.props.DataDropDown.loading
                || this.props.loadingRefetch
            ),
            loadingTable = (this.props.DataEventOfferings.loading || this.props.loadingRefetch),
            defaultProps = EventsOfferings.defaultProps.DataDropDown,
            filtersProps = {
                data: {
                    clients: this.props.DataDropDown.clients || defaultProps.clients,
                    countries: this.props.DataDropDown.countries || defaultProps.countries,
                    deviceCategories: this.props.DataDropDown.deviceCategories || defaultProps.deviceCategories,
                    distributionTypes: this.props.DataDropDown.distributionTypes || defaultProps.distributionTypes,
                    eventContents: this.state.eventContents,
                    isVisible: this.props.areFiltersVisible,
                    products: this.props.DataDropDown.products || defaultProps.products,
                    eventContentTypes: this.props.DataDropDown.eventContentTypes || defaultProps.eventContentTypes,
                    eventContentVariants: this.props.DataDropDown.eventContentVariants || defaultProps.eventContentVariants,
                },
                loading: loading,
            };

        return (
            <div>
                <HeaderRenderer
                    exportExcelParams={exportExcel(
                        this.props.DataEventOfferings.eventOfferings,
                        convertToInt(this.props.match.params.id)
                    )}
                    filters={EventsOfferingsFilters}
                    filtersProps={filtersProps}
                    loading={loading}
                />
                <Segment basic className='--table'>
                    <EventsOfferingsTable
                        className={'eventOfferings__table'}
                        countries={this.props.countries}
                        eventOfferings={this.props.DataEventOfferings.eventOfferings}
                        loading={loadingTable}
                    />
                </Segment>
            </div>
        );
    }
}

const EventsOfferingsWithQuery = withRouter(compose(
    graphql(GetEventOfferingsTableData, {
        options: (props) => {
            const defaultFilters = EventsOfferings.defaultProps.filters;

            let clients = props.filters.clients || defaultFilters.clients,
                products = props.filters.products || defaultFilters.products,
                distributions = props.filters.distributionTypes || defaultFilters.distributionTypes,
                devices = props.filters.deviceCategories || defaultFilters.deviceCategories,
                eventContentTypes = props.filters.eventContentTypes || defaultFilters.eventContentTypes,
                eventContentVariants = props.filters.eventContentVariants || defaultFilters.eventContentVariants;

            if (props.filters.clients) {
                clients = props.filters.clients.map(id => convertToInt(id));
            }

            if (props.filters.products) {
                products = props.filters.products.map(id => convertToInt(id));
            }

            if (props.filters.distributionTypes) {
                distributions = props.filters.distributionTypes.map(id => convertToInt(id));
            }

            if (props.filters.deviceCategories) {
                devices = props.filters.deviceCategories.map(id => convertToInt(id));
            }

            if (props.filters.eventContentVariants) {
                eventContentVariants = props.filters.eventContentVariants.map(id => convertToInt(id));
            }

            if (props.filters.eventContentTypes) {
                eventContentTypes = props.filters.eventContentTypes.map(id => convertToInt(id));
            }

            return {
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: {
                    event: convertToInt(props.match.params.id),
                    eventContentTypes: eventContentTypes,
                    eventContentVariants: eventContentVariants,
                    clients: clients,
                    countries: props.filters.countries || defaultFilters.countries,
                    contents: props.filters.eventContents || defaultFilters.eventContents,
                    products: products,
                    distributions: distributions,
                    devices: devices,
                    bookingStatus: props.filters.bookingStatus,
                    isHq: convertBooleanValueForFilters(props.filters.isHq, null),
                },
            };
        },
        name: 'DataEventOfferings',
    }),
    graphql(GetDropdownData, {
        options: (props) => {
            return {
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables: {
                    event: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataDropDown',
    })
)(FilterQueryWrapper(EventsOfferings, {
    queryForRefresh: 'DataEventOfferings',
    filterUrls: ['events.offerings.index'],
})));

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'EventsOfferings', EventsOfferings.defaultProps.filters),
});

export default withRouter(connect(mapStateToProps)(
    FilterUrlParamsWrapper(EventsOfferingsWithQuery, EventsOfferings.defaultProps.filters)
));
