import PropTypes from 'prop-types';
import React from 'react';
import {get as _get, orderBy as _orderBy} from 'lodash';
import {Field} from 'redux-form';

import Form from '@appComponents/ReduxFormControls';
import {convertToInt} from '@utils/helpers';

const formatClipOptions = (clipConfigs, selectedOptions) => {

    if (!clipConfigs || !clipConfigs.length) {
        return [];
    }
    const selectedOptionsMap = new Map();

    if (selectedOptions && selectedOptions.length) {

        for (const selectedEventContentType of selectedOptions) {
            const eventContentType = convertToInt(selectedEventContentType.event_content_type.id);

            if (!selectedOptionsMap.has(eventContentType)) {
                selectedOptionsMap.set(eventContentType, []);
            }

            for (const selectedMatchEventType of selectedEventContentType.match_event_types) {
                const matchEventType = convertToInt(selectedMatchEventType.id);

                if (!selectedOptionsMap.get(eventContentType).includes(matchEventType)) {
                    selectedOptionsMap.get(eventContentType).push(matchEventType);
                }
            }
        }
    }

    return _orderBy(clipConfigs[0].event_content_type_configs.map(ect => {
        const eventContentTypeId = convertToInt(ect.event_content_type.id);

        return {
            selected: selectedOptionsMap.has(eventContentTypeId),
            key: eventContentTypeId,
            value: ect.event_content_type.id,
            name: ect.event_content_type.name,
            children: _orderBy(ect.match_event_types.map(met => {
                const matchEventTypeId = convertToInt(met.id);

                return {
                    selected: selectedOptionsMap.get(eventContentTypeId)?.includes(matchEventTypeId),
                    key: matchEventTypeId,
                    value: met.id,
                    name: met.name,
                };
            }), ['name'], ['asc']),
        };
    }), ['name'], ['asc']);
};

const EventContentTypesField = (props) => {
    if (_get(props, 'ClipConfigOptions.loading', false)) {
        return ('Loading...');
    }

    const optionsForContentTypes =
        (
            5 === _get(props, 'level', null)
                ? props.ClipConfigOptions?.preferredClipConfigs?.length
                    ? props.ClipConfigOptions?.preferredClipConfigs
                    : props.ClipConfigOptions?.fallbackClipConfigs
                : props.ClipConfigOptions?.fallbackClipConfigs
        ) || [];

    const formattedClipOptions = formatClipOptions(optionsForContentTypes, props.selectedOptions);

    return (
        <Field
            component={Form.SemanticCheckboxTree}
            required
            options={formattedClipOptions}
            name='event_content_type_configs'
            type='checkbox'
            className='--width-100-percent'
            showNoDataText={!formattedClipOptions.length}
            noDataText='There is no clip configuration for this sport.'
            allowChildless={true}
        />
    );
};

EventContentTypesField.propTypes = {
    ClipConfigOptions: PropTypes.object,
    selectedOptions: PropTypes.array,
    level: PropTypes.number,
};

export default EventContentTypesField;
