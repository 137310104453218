import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';

export function UserFiltersComponent({
    data: {
        roles = [],
    },
    filters: {
        Users = {},
    },
    loading = true,
    restoreFilterValues,
}) {
    return (
        <div className='users__filters filter__controls__container'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={restoreFilterValues(Users, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                name='roles_id'
                placeholder='Role'
                options={roles}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={restoreFilterValues(Users, 'roles_id')}
            />
            <Field
                className='--minimalWidth --status'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='is_disabled'
                options={[
                    {text: 'Enabled', value: 0},
                    {text: 'Disabled', value: 1},
                ]}
                placeholder='Status'
                setValue={restoreFilterValues(Users, 'is_disabled')}
            />
        </div>
    );
}

UserFiltersComponent.propTypes = {
    data: PropTypes.shape({
        roles: PropTypes.array,
    }),
    filters: PropTypes.shape({
        Users: PropTypes.object,
    }),
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
};

const UserFilters = FiltersWrapper(UserFiltersComponent, {name: 'Users'});

export default UserFilters;
