import React from "react";
import {Image} from "semantic-ui-react";
import PropTypes from "prop-types";

import {SportradarEvent} from "./AvatarTournamentCategory/SportradarEvent";
import {NonSportradarEvent} from "./AvatarTournamentCategory/NonSportradarEvent";

import {EVENT_TYPE_SPORTRADAR} from "../../../app/constants/variables";
import urls from "../../../app/constants/urls";

/**
 * Display
 * - AVATAR
 * AND
 * - Tournament (for SPORTRADAR EVENT)
 * OR
 * - Content category (for NON-SPORTRADAR EVENT)
 * @param event
 * @returns {*}
 */
export const AvatarTournamentCategory = ({ event }) => {
    let sportImage = null;

    if (event.sport_id) {
        const sportImageUrl = `${urls.sportsImages.small}${event.sport_id}.png`;
        sportImage = <Image src={sportImageUrl} className="sportImage" avatar/>;
    }

    if (EVENT_TYPE_SPORTRADAR === event.event_type_id) {
        return (
            <SportradarEvent
                event={event}
                sportImage={sportImage}
            />
        )
    } else {
        return (
            <NonSportradarEvent
                event={event}
                sportImage={sportImage}
            />
        )
    }
};

AvatarTournamentCategory.propTypes = {
    event: PropTypes.shape({
        event_type_id: PropTypes.number.isRequired,
        sport_id: PropTypes.number
    }).isRequired
};

AvatarTournamentCategory.defaultProps = {
    event: {
        sport_id: null
    }
};
