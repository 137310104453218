import React from 'react';

import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import { ButtonAdd } from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import Authorization from '@appComponents/Authorization';

const LicensorIndexButtons = () => {
    return (
        <div className='content__buttons'>
            <Authorization resources={RESOURCES.LICENSOR} privileges={CONST.SECURITY_PRIVILEGES_CREATE}>
                <Link name='licensors.add'>
                    <ButtonAdd>{'Add new licensor'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default LicensorIndexButtons;
