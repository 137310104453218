import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';

export const LicensorFiltersComponent = (props) => {
    const { countries } = props.data,
        loading = props.loading;

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.Licensors, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                name='countryId'
                placeholder='Select country'
                options={countries}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.Licensors, 'countryId')}
            />
        </div>
    );

};

LicensorFiltersComponent.propTypes = {
    data: PropTypes.shape({
        countries: PropTypes.array.isRequired,
    }),
    filters: PropTypes.shape({
        Licensors: PropTypes.object,
    }),
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
};

LicensorFiltersComponent.defaultProps = {
    countries: [],
};

const LicensorFilters = FiltersWrapper(LicensorFiltersComponent, {name: 'Licensors'});

export default LicensorFilters;
