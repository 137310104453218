import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import Table from '@appComponents/Table';
import {cidrColumns, prepareStringForCidrSorting} from '@utils/CidrHelpers';

import {rowRenderer} from './IpBlacklistingTableRowRenderer';

export const IpBlacklistingTable = (props) => {
    const {blacklistedIps = [], loading, timezone} = props;
    delete cidrColumns.products;

    return (
        <Table
            columns={cidrColumns}
            data={blacklistedIps}
            defaultSort={prepareStringForCidrSorting}
            loading={loading}
            name='blacklistedIpsList'
            noDataText='No CIDRs found'
            pagination={false}
            rowRenderer={rowRenderer}
            rowRendererProps={{...props}}
            timezone={timezone}
        />
    );
};

const mapStateToProps = state => {
    return {
        timezone: state.timezone.timezone,
    };
};

IpBlacklistingTable.propTypes = {
    blacklistedIps: PropTypes.array,
    disableClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    timezone: PropTypes.string.isRequired,
};

IpBlacklistingTable.displayName = 'IpBlacklistingTable';

export default connect(mapStateToProps)(IpBlacklistingTable);
