import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

export class ContentCategoryForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            this.refreshData(response.data);
        });
        this.setUpdateSuccessCallback((response) => {
            this.refreshData(response.data);
        });
    }

    prepareDataForSubmit = (data) => {
        return Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {level: this.props.formParams.level, parent: this.props.formParams.parentId}
        );
    };

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            this.props.Model.label,
            getLink("content_categories.index"),
            {size: 'tiny'}
        );
    }

    refreshData = (data) => {
        this.props.formParams.refreshData(data);
    };

    renderDeleteButton = () => null;

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );
}

export default withRouter(ContentCategoryForm);
