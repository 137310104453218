import React from 'react';
import {get as _get, isEmpty as _isEmpty, sortBy as _sortBy, findIndex as _findIndex} from 'lodash';
import {withRouter} from 'react-router-dom';

import {createForm} from '@utils/forms';
import {getDeviceNameById} from '@modules/events/utils/eventsBookingGeoRestriction';
import {getCountryTreeOptions, getSubdivisionTreeOptions} from '@modules/events/utils/eventGeoRestriction';
import {
    DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_DESKTOP_LABEL,
    DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_MOBILE_LABEL,
    DEVICE_CATEGORY_SMART_TV, DEVICE_CATEGORY_SMART_TV_LABEL,
} from '@constants/variables';
import DefaultForm from '@appComponents/DefaultForm';
import {queryPrefillGeoRestrictionsForEventBooking, GetSelectedProduct} from '@graphql/eventEventBooking/query';
import {convertToInt} from '@utils/helpers';
import {onCloseModal, setMutationSuccess} from '@modules/eventBooking/forms/helpers/helpers';
import {getAdditionalBackButton} from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';

import EventBookingBlackoutZonesModel from './EventBookingBlackoutZonesModel';
import EventBookingBlackoutZonesForm from './EventBookingBlackoutZonesForm';

export class EventEventBookingGeoRestrictionsForm extends DefaultForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;
    DEVICE_CATEGORIES = [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV];

    prepareDataForSubmit = (data) => {
        return Object.assign({}, {
            data,
            eventId: this.props.previousStepData.eventId,
            product: this.props.previousStepData.product,
            client: this.props.previousStepData.client,
            client_package: this.props.previousStepData.client_package ? this.props.previousStepData.client_package : null,
            auto_update_geo_restrictions: this.props.previousStepData.auto_update_geo_restrictions || false,
        });
    };

    onFormSubmit = (data) => {
        this.setState(() => ({
            defaultForm_errorShowed: false,
        }));

        const setMutationSuccessCallback = this.props.formParams.setMutationSuccessCallback;
        const Form = createForm(
                EventBookingBlackoutZonesModel,
                EventBookingBlackoutZonesForm,
                {
                    setMutationSuccessCallback,
                    id: data.id,
                    optionsVariables: {
                        event: this.props.formParams.optionsVariables.event,
                        isArchived: false,
                    },
                }),
            dataForNextStep = Object.assign(
                {},
                data,
                this.props.previousStepData
            );

        this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
        this.props.stepsMethods.setStep({
            id: 'step4',
            menuItem: {disabled: false},
            pane: {content: <Form previousStepData={dataForNextStep}/>},
        });
        this.props.stepsMethods.showStep('step4');
    };

    componentDidMount() {
        if (!this.props.Model.editForm) {
            this.queryGetSelectedProduct();
        }
        this.setUpdateSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
        this.setDeleteSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });

    }

    queryGetSelectedProduct = () => {
        this.setState(() => ({queryGetSelectedProductLoading: true}));

        return this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: GetSelectedProduct,
            variables: {
                product: this.props.formParams.optionsVariables.product,
            },
        }).then((response) => {
            this.setState(() => ({
                selectedProduct: response.data,
            }));
        });
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);
        const {
                GraphQLOptionsData: {
                    continents,
                    countries,
                },
                formValues: {
                    desktop_switch: desktopSwitchValues,
                    mobile_switch: mobileSwitchValue,
                    smart_tv_switch: smartTvSwitchValue,
                },
            } = nextProps,
            entityData = _get(nextProps, 'GraphQLEntityData', {}),
            restrictions = !_isEmpty(entityData) ? this.getEntityGeoRestrictions(entityData) : {},
            desktopRestrictions = this.getDeviceCategoryRestrictions(restrictions, DEVICE_CATEGORY_DESKTOP.toString()),
            mobileRestrictions = this.getDeviceCategoryRestrictions(restrictions, DEVICE_CATEGORY_MOBILE.toString()),
            smartTvRestrictions = this.getDeviceCategoryRestrictions(restrictions, DEVICE_CATEGORY_SMART_TV.toString());

        if (entityData) {
            if (!this.state.desktopCountriesGenerated
                && continents
                && -1 < desktopRestrictions
                && 0 < restrictions[desktopRestrictions].country_iso_alpha2_codes.length
            ) {
                this.setCountryEntityGeoRestrictions(DEVICE_CATEGORY_DESKTOP, {
                    continents: continents,
                    geoRestrictions: restrictions[desktopRestrictions].country_iso_alpha2_codes,
                });

                this.setState(() => ({desktopCountriesGenerated: true}));
            }

            if (!this.state.mobileCountriesGenerated
                && continents
                && -1 < mobileRestrictions
                && 0 < restrictions[mobileRestrictions].country_iso_alpha2_codes.length
            ) {
                this.setCountryEntityGeoRestrictions(DEVICE_CATEGORY_MOBILE, {
                    continents: continents,
                    geoRestrictions: restrictions[mobileRestrictions].country_iso_alpha2_codes,
                });

                this.setState(() => ({mobileCountriesGenerated: true}));
            }

            if (!this.state.smartTvCountriesGenerated
                && continents
                && -1 < smartTvRestrictions
                && 0 < restrictions[smartTvRestrictions].country_iso_alpha2_codes.length
            ) {
                this.setCountryEntityGeoRestrictions(DEVICE_CATEGORY_SMART_TV, {
                    continents: continents,
                    geoRestrictions: restrictions[smartTvRestrictions].country_iso_alpha2_codes,
                });

                this.setState(() => ({smartTvCountriesGenerated: true}));
            }

            this.DEVICE_CATEGORIES.forEach((deviceCategoryId) => {
                let deviceName = '';
                const deviceByRestriction = {
                    [DEVICE_CATEGORY_DESKTOP]: desktopRestrictions,
                    [DEVICE_CATEGORY_MOBILE]: mobileRestrictions,
                    [DEVICE_CATEGORY_SMART_TV]: smartTvRestrictions,
                };

                if (DEVICE_CATEGORY_SMART_TV === deviceCategoryId) {
                    deviceName = 'smartTv';
                } else {
                    deviceName = getDeviceNameById(deviceCategoryId);
                }

                if (!this.state[`${deviceName}SubdivisionsGenerated`]
                    && countries
                    && -1 < deviceByRestriction[deviceCategoryId]
                    && this.checkSubdivisionsAnyCountry(
                        countries,
                        restrictions[deviceByRestriction[deviceCategoryId]].country_iso_alpha2_codes
                    )
                ) {
                    this.setSubdivisionsEntityGeoRestrictions(deviceCategoryId, {
                        countries: countries,
                        countriesGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_iso_alpha2_codes,
                        subdivisionsGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_subdivision_iso_codes,
                    });

                    this.setState(() => ({[`${deviceName}SubdivisionsGenerated`]: true}));
                }

                if (this.state[`${deviceName}SubdivisionsGenerated`]
                    && !this.state[`${deviceName}SubdivisionsLoaded`]
                    && !_isEmpty(
                        _get(
                            restrictions[deviceByRestriction[deviceCategoryId]],
                            'country_subdivision_iso_codes',
                            []
                        )
                    )
                ) {
                    this.setSubdivisionsEntityGeoRestrictions(deviceCategoryId, {
                        countries: countries,
                        countriesGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_iso_alpha2_codes,
                        subdivisionsGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_subdivision_iso_codes,
                    });

                    this.setState(() => ({[`${deviceName}SubdivisionsLoaded`]: true}));
                }
            });
        }

        if (!this.state.deviceCategoriesGenerated) {
            this.setState(() => ({deviceCategoriesGenerated: true}));
        }

        if (!this.state.preFillGeoRestrictionsGenerated
            && !nextProps.formParams.id
            && countries
            && continents
            && nextProps.previousStepData
            && nextProps.previousStepData.eventId
            && nextProps.previousStepData.client_package) {

            this.getPreFillGeoRestrictions(
                convertToInt(nextProps.previousStepData.eventId),
                convertToInt(nextProps.previousStepData.client_package),
                {continents: continents, countries: countries}
            );

            this.setState(() => ({preFillGeoRestrictionsGenerated: true}));
        }

        this.setOnChangeCallback({
            desktop_switch: (data) => {
                this.handleSwitchChanged(DEVICE_CATEGORY_DESKTOP, data.value);
            },
            mobile_switch: (data) => {
                this.handleSwitchChanged(DEVICE_CATEGORY_MOBILE, data.value);
            },
            smart_tv_switch: (data) => {
                this.handleSwitchChanged(DEVICE_CATEGORY_SMART_TV, data.value);
            },
        });

        this.setField('geo_restrictions_error_content', {
            hidden: [desktopSwitchValues, mobileSwitchValue, smartTvSwitchValue].every((value) => {
                return false !== value;
            }),
        });

        this.setCountriesOnChangeCallback(
            countries, 'desktop_countries', 'desktop_subdivisions'
        );
        this.setCountriesOnChangeCallback(
            countries, 'mobile_countries', 'mobile_subdivisions'
        );
        this.setCountriesOnChangeCallback(
            countries, 'smart_tv_countries', 'smart_tv_subdivisions'
        );

        this.props.stepsMethods.setForm('step3', nextProps);
    }

    setCountryEntityGeoRestrictions(deviceId, data) {
        const deviceName = getDeviceNameById(deviceId);

        this.setField(`${deviceName}_switch`, {
            checked: true,
            defaultValue: true,
        });
        this.setField(`${deviceName}_countries`, {
            hidden: false,
            options: getCountryTreeOptions({
                continents: data.continents,
                selectedCountries: data.geoRestrictions,
            }),
        });
    }

    setSubdivisionsEntityGeoRestrictions(deviceId, data) {
        const deviceName = getDeviceNameById(deviceId);

        this.setField(`${deviceName}_subdivisions`, {
            hidden: false,
            options: getSubdivisionTreeOptions({
                countries: data.countries,
                selectedCountries: data.countriesGeoRestrictions || [],
                selectedSubdivisions: data.subdivisionsGeoRestrictions || [],
            }),
        });
    }

    getPreFillGeoRestrictions = (eventId, clientPackageId, data) => {
        this.setState(() => ({defaultForm_loading: true}));

        return this.props.client.query({
            query: queryPrefillGeoRestrictionsForEventBooking,
            fetchPolicy: 'network-only',
            variables: {
                event: eventId,
                client_package: clientPackageId,
            },
        }).then((response) => {
            const preFillGeoRestrictions = response.data.prefillGeoRestrictionsForEventBooking;

            preFillGeoRestrictions.forEach((geoRestriction) => {
                this.setCountryEntityGeoRestrictions(convertToInt(geoRestriction.device_category), {
                    continents: data.continents,
                    geoRestrictions: geoRestriction.country_iso_alpha2_codes,
                });

                if (this.checkSubdivisionsAnyCountry(data.countries, geoRestriction.country_iso_alpha2_codes)) {
                    this.setSubdivisionsEntityGeoRestrictions(convertToInt(geoRestriction.device_category), {
                        countries: data.countries,
                        countriesGeoRestrictions: geoRestriction.country_iso_alpha2_codes,
                        subdivisionsGeoRestrictions: geoRestriction.country_subdivision_iso_codes || [],
                    });
                }
            });
        }).catch((response) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    "Can't get pre-fill geo restrictions for selected distribution and client package.",
                    response.message, 'error');
        }).finally(
            () => {
                this.setState(() => ({defaultForm_loading: false}));
            }
        );
    };

    componentDidUpdate(prevProps, prevState) {
        const selectedGeoRestrictionsQueryData = _get(this.state, 'selectedGeoRestrictions'),
            selectedEventEventBookingForGeoRestrictions = _get(selectedGeoRestrictionsQueryData, 'eventEventBooking'),
            selectedGeoRestrictions = _get(selectedEventEventBookingForGeoRestrictions, 'event_booking_geo_restrictions'),
            availableDeviceCategories = _get(this.state.selectedProduct, 'product.device_categories', []);

        if (selectedGeoRestrictionsQueryData
            || (this.props.formParams.optionsVariables.client && this.props.GraphQLOptionsData.continents)) {
            if ((this.props?.GraphQLOptionsData?.continents && this.props.GraphQLOptionsData.continents !== prevProps.GraphQLOptionsData.continents)
                || (this.state.selectedGeoRestrictions && this.state.selectedGeoRestrictions !== prevState.selectedGeoRestrictions)
                && this.props.GraphQLOptionsData.continents
            ) {
                this.addSelectedGeoRestrictions(selectedGeoRestrictions);
            }
        }

        if (this.props.GraphQLOptionsData !== prevProps.GraphQLOptionsData && this.state.selectedProduct) {
            availableDeviceCategories.map(deviceCategory => deviceCategory.id).forEach((deviceCategoryId) => {
                const deviceName = getDeviceNameById(parseInt(deviceCategoryId));

                this.setField(`${deviceName}_switch`, {
                    hidden: false,
                });
            });
        }
    }

    addSelectedGeoRestrictions = (selectedGeoRestrictions) => {
        const switches = {
            [DEVICE_CATEGORY_DESKTOP_LABEL]: 'desktop_switch',
            [DEVICE_CATEGORY_MOBILE_LABEL]: 'mobile_switch',
            [DEVICE_CATEGORY_SMART_TV_LABEL]: 'smart_tv_switch',
        };
        const devices = {
            [DEVICE_CATEGORY_DESKTOP_LABEL]: 'desktop_countries',
            [DEVICE_CATEGORY_MOBILE_LABEL]: 'mobile_countries',
            [DEVICE_CATEGORY_SMART_TV_LABEL]: 'smart_tv_countries',

        };
        const sub = {
            [DEVICE_CATEGORY_DESKTOP_LABEL]: 'desktop_subdivisions',
            [DEVICE_CATEGORY_MOBILE_LABEL]: 'mobile_subdivisions',
            [DEVICE_CATEGORY_SMART_TV_LABEL]: 'smart_tv_subdivisions',
        };

        selectedGeoRestrictions && selectedGeoRestrictions.map((selectedGeoRestriction) => {
            const isCountrySelected = (country, countries) => {
                return !!countries.find(i => i === country);
            };

            const tree = this.props.GraphQLOptionsData.continents.map((item) => {
                const countries = item.children.map(i => {
                    return {
                        id: i.id,
                        value: i.value,
                        name: i.text,
                        label: i.text,
                        selected: isCountrySelected(i.value, selectedGeoRestriction.country_iso_alpha2_codes),
                    };
                });

                return {value: item.value, name: item.text, label: item.text, children: [...countries]};
            });

            this.setField(switches[selectedGeoRestriction.device_category.name], {
                hidden: false,
                checked: true,
                defaultValue: true,
            });

            this.setField(devices[selectedGeoRestriction.device_category.name], {
                hidden: false,
                options: tree,
            });

            if (selectedGeoRestriction.country_subdivision_iso_codes) {
                const tree = this.props.GraphQLOptionsData.countries.map((item) => {
                    const lands = item.children.map(i => {
                        return {
                            id: i.id,
                            value: i.value,
                            name: i.text,
                            label: i.text,
                            selected: isCountrySelected(i.value, selectedGeoRestriction.country_subdivision_iso_codes),
                        };
                    });

                    return {value: item.value, name: item.text, label: item.text, children: [...lands]};
                });

                this.setField(sub[selectedGeoRestriction.device_category.name], {
                    hidden: false,
                    options: tree,
                });
            }
        });
    }

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'eventBooking.event_booking_geo_restrictions', {});
    };
    getDeviceCategoryRestrictions = (restrictions, deviceCategory) => {
        return _findIndex(restrictions, {'device_category': {'id': deviceCategory}});
    };

    checkSubdivisionsAnyCountry = (countries = [], selectedCountries = []) => {
        for (const item of countries) {
            if (selectedCountries.includes(item.value)) {
                return true;
            }
        }

        return false;
    };

    setCountriesOnChangeCallback(optionsCountries, countriesFieldName = 'countries', subdivisionsFieldName = 'subdivisions') {
        this.setField(countriesFieldName, {
            onChangeCallback: (event, selectedOptions) => {
                let generalHidden = true;

                const countryOptions = [],
                    selectedCountries = [],
                    selectedSubdivisions = [];

                selectedOptions.forEach((option) => {
                    const country = option.split('.');

                    if (country[1]) {
                        selectedCountries.push(country[1].split('-')[1]);
                    }
                });

                this.props.formValues[subdivisionsFieldName].forEach((country) => {
                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        country.children.forEach((subdivision) => {
                            selectedSubdivisions.push(subdivision.value);
                        });
                    }
                });

                optionsCountries.forEach((country) => {
                    let hidden = true;

                    const subdivisions = [];

                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        hidden = false;
                        generalHidden = false;
                    }

                    country.children.forEach((subdivision) => {
                        subdivisions.push({
                            ...subdivision,
                            selected: -1 < selectedSubdivisions.indexOf(subdivision.value.replace(/-/g, '')),
                        });
                    });

                    if (!hidden) {
                        countryOptions.push({
                            ...country,
                            children: _sortBy(subdivisions, 'name'),
                            hidden: hidden,
                        });
                    }
                });

                this.setField(subdivisionsFieldName, {
                    hidden: generalHidden,
                    options: countryOptions,
                });
            },
        });
    }

    prepareForHandleSwitchChanged(deviceId, value) {
        const {
            GraphQLOptionsData: {
                continents,
                countries,
            },
        } = this.props;

        const deviceName = getDeviceNameById(deviceId);
        const switchFieldName = `${deviceName}_switch`;
        const dropdownCountriesFieldName = `${deviceName}_countries`;
        const dropdownSubdivisionsFieldName = `${deviceName}_subdivisions`;
        let continentsList = [],
            countriesList = [];

        if (!value) {
            if (!this.countryTreeOptions) {
                this.countryTreeOptions = getCountryTreeOptions({
                    continents: continents,
                    selectedCountries: [],
                });
            }

            if (!this.subdivisionTreeOptions) {
                this.subdivisionTreeOptions = getSubdivisionTreeOptions({
                    countries: countries,
                    selectedCountries: [],
                    selectedSubdivisions: [],
                });
            }

            continentsList = this.countryTreeOptions;
            countriesList = this.subdivisionTreeOptions;
        }

        this.setField(switchFieldName, {
            checked: !value,
            defaultValue: !value,
        });
        this.setField(dropdownCountriesFieldName, {
            hidden: value,
            options: continentsList,
        });
        this.setField(dropdownSubdivisionsFieldName, {
            hidden: true,
            options: countriesList,
        });
    }

    handleSwitchChanged = (deviceId, value) => {
        this.prepareForHandleSwitchChanged(deviceId, value);

        const deviceName = getDeviceNameById(deviceId);
        const switchFieldName = `${deviceName}_switch`;

        if (this.props.Model.editForm) {
            const step2FormData = this.props.stepsMethods.getForms()['step3'].formValues,
                combinedData = Object.assign({}, step2FormData, {[switchFieldName]: !value});

            this.props.stepsMethods.setStep({
                id: 'step4',
                pane: {
                    props: {
                        timestamp: Date.now(),
                        switches: {
                            desktop_switch: combinedData.desktop_switch,
                            mobile_switch: combinedData.mobile_switch,
                            smart_tv_switch: combinedData.smart_tv_switch,
                        },
                    },
                },
            });
        }
    };

    renderAdditionalButtons = () => (
        getAdditionalBackButton(this.props.Model.editForm, this.props.stepsMethods.showPreviousStep)
    );

    renderDeleteButton(props) {
        return super.renderDeleteButton({
            ...props,
            content: 'Delete',
        });
    }

    onCancel = () => {
        onCloseModal(
            this.props.formParams.optionsVariables.event,
            this.props.formParams.optionsVariables.client,
            this.props.Modal.setModal,
            this.props
        );
    };

    renderCancelButton = (props) => super.renderCancelButton({
        ...props,
        type: 'button',
        onClick: this.onCancel,
    });

    renderSaveButton = (props) => {
        return super.renderSaveButton({
            ...props,
            content: 'Next',
            icon: 'angle right',
            labelPosition: 'right',
        });
    };
}

export default withRouter(EventEventBookingGeoRestrictionsForm);
