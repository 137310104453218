import PropTypes from "prop-types";

import {PROPERTY_LICENCE_TYPE_OTHER} from "../../../app/constants/variables";

export const exportExcel = (dataExport, propertyLicenceId, propertyLicenceTypeId) => {
    const getContents = (contents) => (
        (PROPERTY_LICENCE_TYPE_OTHER === propertyLicenceTypeId)
            ? contents.map(content => `${content.content_category.parent.name} > ${content.content_category.name} (${content.content_tier.name})`).join('; ')
            : contents.map(content => `${content.tournament.tournament_category.name} > ${content.tournament.name} (${content.content_tier.name})`).join('; ')
    );

    return {
        dataExport: dataExport,
        filename: `property_licence_${propertyLicenceId}_match_selection_periods`,
        parseData: matchSelectionPeriod => [
            matchSelectionPeriod.id,
            matchSelectionPeriod.name,
            matchSelectionPeriod.includes_all_content ? 'All' : getContents(matchSelectionPeriod.contents),
            matchSelectionPeriod.start_date,
            matchSelectionPeriod.end_date,
            matchSelectionPeriod.num_matches_agreed,
            matchSelectionPeriod.num_matches_commitment,
            matchSelectionPeriod.num_matches_budget,
        ],
        path: 'propertyLicences.matchSelectionPeriods.index.path',
        titles: [
            "ID",
            "Name",
            "Content",
            "From",
            "To",
            "Matches agreed",
            "Match commitment",
            "Match budget",
        ],
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    propertyLicenceId: PropTypes.number.isRequired,
    propertyLicenceTypeId: PropTypes.number.isRequired,
};
