import {Button, Header} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {reduxForm} from 'redux-form';
import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import {convertToInt, getIdsFromChildren} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import {hideModal, showModalConfirmation} from '@utils/modal';
import {FormWrapper} from '@appComponents/HOCFormWrapper';
import {getClientPackageContentAndGeorestrictions} from '@graphql/clientPackage/query';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {GeoRestrictionsDropdownOptionsForCountries} from '@graphql/eventGeoRestriction/query';
import {DeleteClientPackageMutationGlobeIcon} from '@graphql/clientPackage/mutation';
import {refetchQueryByName} from '@utils/apollo';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from '@constants/variables';

import GeoRestrictionsForm from '../../geoRestriction/forms/GeoRestrictionsForm';
import validate from './validatorClientContentPackage';

export class PackageContentTierGeoRestrictionsForm extends GeoRestrictionsForm {
    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string.isRequired,
        }),
        entityName: PropTypes.string.isRequired,
        client_package: PropTypes.number,
        DeleteClientPackageContentGlobeIcon: PropTypes.func,
        UpdateClientPackageContentGlobeIcon: PropTypes.func,
        reloadTable: PropTypes.func,
        treeActiveStatus: PropTypes.object,
        dataForForm: PropTypes.object,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }),
        backToIndexUrl: PropTypes.func,
    };

    prepareDataForSubmit = () => {
        const countries = getIdsFromChildren(this.props.formValues.countries),
            subdivisions = getIdsFromChildren(this.props.formValues.subdivisions),
            territory_selection_type = convertToInt(this.props.formValues.selection_type);

        return Object.assign({}, {
            id: this.props.formParams?.idOfRow,
            territory_selection_type: territory_selection_type,
            countries: countries,
            country_subdivisions: subdivisions,
            client_package: this.props.formParams.client_package,
            sport: convertToInt(this.props.formParams?.sport),
            content_tier: convertToInt(this.props.formParams?.content_tier),
            tournament_category: convertToInt(this.props.formParams?.tournament_category),
            property_licence: convertToInt(this.props.formParams?.property_licence),
            tournament: convertToInt(this.props.formParams?.tournament),
            content_category: convertToInt(this.props.formParams?.content_category),
            booking_type: convertToInt(this.props.formParams?.booking_type),
            event_booking_type: convertToInt(this.props.formParams?.event_booking_type),
            num_bookable_events: convertToInt(this.props.formParams?.num_bookable_events),
            event_price: convertToInt(this.props.formParams?.event_price),
            is_active: this.props.formParams.is_active,
        });
    };

    setFormMessage = (message, boxName) => {
        super.setFormMessage(
            `The geo-restrictions have not been saved. Please correct the highlighted fields below.`
            , boxName);
    };

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);

        if (TERRITORY_SELECTION_TYPE_WORLDWIDE.toString() !== this.props.formValues.selection_type
            && prevProps.formValues.selection_type !== this.props.formValues.selection_type) {
            this.setField('countries', {
                required: true,
            });

            if (!this.props.Model.fields.subdivisions.props.hidden) {
                this.setField('subdivisions', {
                    required: false,
                });
            }

            if (this.state.countriesGenerated !== prevState.countriesGenerated) {
                this.setField('countries', {
                    hidden: false,
                    options: this.state.countriesGenerated,
                });
            }
        }
    }

    onFormSubmit = (data) => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', '')).toLocaleLowerCase()
            ));
        } else {
            return super.onFormSubmit(data);
        }
    };

    componentDidMount() {
        this.getCountriesToTree();

        this.setField('selection_type', {
            defaultValue: TERRITORY_SELECTION_TYPE_WORLDWIDE.toString(),
        });

        this.setCreateSuccessCallback(() => {
            this.props.formParams.reloadTable();
        });
        this.setUpdateSuccessCallback(() => {
            this.props.formParams.reloadTable();
        });
        this.setDeleteSuccessCallback(() => {
            this.props.formParams.reloadTable();
        });

        this.setState({openEditGlobeModal: true});
    }

    getCountriesToTree = () => {
        return this.runApolloRequest('query', {
            fetchPolicy: 'network-and-cache',
            query: GeoRestrictionsDropdownOptionsForCountries,
        }).then((response) => {
            const continents = response.data.continents,
                countries = response.data.countries;

            this.setState(() => ({
                continents: continents,
                countries: countries,
            }));

            if (_get(this.props.formParams, 'idOfRow')) {
                this.setClientPackageContentAndGeorestrictions();
            }
        });
    }

    setClientPackageContentAndGeorestrictions = () => {
        return this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: getClientPackageContentAndGeorestrictions,
            variables: {
                id: this.props.formParams?.idOfRow,
            },
        }).then((response) => {
            const selectedTerritoryType = response.data.clientPackageContent?.territory_selection_type?.id,
                selectedCountries = response.data.clientPackageContent.countries,
                selectedCountrySubdivisions = response.data.clientPackageContent.country_subdivisions;

            if (selectedTerritoryType) {
                this.setField('selection_type', {
                    defaultValue: selectedTerritoryType.toString(),
                });
                this.setState(() => ({
                    selectedTerritoryType: selectedTerritoryType,
                }));

                if (0 !== selectedCountrySubdivisions.length) {
                    this.setField('subdivisions', {
                        hidden: false,
                        options: this.getSubdivisionTreeOptions({
                            countries: this.state.countries,
                            selectedCountries: selectedCountries,
                            selectedSubdivisions: selectedCountrySubdivisions,
                        }),
                    });
                }

                const countriesGenerated = this.getCountryTreeOptions({
                    continents: this.state.continents,
                    selectedCountries: selectedCountries,
                });
                this.setState(()=>({
                    countriesGenerated: countriesGenerated,
                }));

                if (selectedTerritoryType !== TERRITORY_SELECTION_TYPE_WORLDWIDE.toString()) {
                    this.setField('countries', {
                        hidden: false,
                        options: countriesGenerated,
                    });
                }
            }
        });
    }

    renderErrors(errorData) {
        const link = getLink('clients.edit', {
            id: this.props.formParams.id || null,
        });

        super.renderErrors(
            errorData,
            this.props.Model.formName.capitalizeFirstLetter() + ' geo restrictions',
            link,
            {size: 'tiny'}
        );
    }

    renderAdditionalButtons = () => {
        return <Button
            color='blue'
            type='submit'
            loading={this.props.submitting}
            disabled={this.props.submitting}
            icon='save'
            content='Save'/>;
    };

    onCancel = () => {
        hideModal();
        this.props.formParams.backToIndexUrl();
    };

    renderCancelButton = (props) => super.renderCancelButton(
        {...props, type: 'button', onClick: this.onCancel});

    deleteGeorestrictions = () => (
        this.runApolloRequest('mutate', {
            mutation: DeleteClientPackageMutationGlobeIcon,
            variables: {
                id: this.props.formParams.idOfRow,
            },
        }).then(
            () => {
                hideModal();
                refetchQueryByName('clientPackageContentTree');
                this.props.formParams.reloadTable();
            }
        )
    );

    onDeleteContentPackage = () => {
        showModalConfirmation({
            header: <Header icon='trash' content='Remove geo restrictions'/>,
            onYes: this.deleteGeorestrictions,
            text: `Are you sure you want to remove the geo restrictions for '${this.props.formParams.entityName}'?`,
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (this.state.selectedTerritoryType) {
            deleteButton = <Button onClick={this.onDeleteContentPackage}
                color='red'
                disabled={this.props.submitting}
                icon='trash'
                content='Remove geo restrictions'/>;
        }

        return deleteButton;
    };

    renderSaveButton = () => {
        return null;
    };
}
const ContentPackageFormWithRedux = reduxForm({form: 'ContentPackageForm', validate})(PackageContentTierGeoRestrictionsForm);

export default withRouter(compose()(FormWrapper({id: ''}, ContentPackageFormWithRedux)));
