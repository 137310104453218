import React from 'react';

import {EVENT_GEO_RESTRICTION} from '@constants/resources';
import {IconInfoCircle} from '@appComponents/IconCollection';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {GeoRestrictionsDropdownOptionsForCountries} from '@graphql/eventGeoRestriction/query';

import validatorEventGeoRestriction from '../validatorEventGeoRestriction';

export default function EventGeoRestrictionsFormModel() {
    return {
        entityDataMapKey: 'eventGeoRestriction',
        entityLabel: 'geo restriction',
        formName: 'EventGeoRestriction',
        label: 'geo restriction',
        resources: EVENT_GEO_RESTRICTION,
        showChangeLog: false,
        title: null,
        validate: validatorEventGeoRestriction,
        dataMap: {
            eventGeoRestriction: {
                selection_type: {
                    id: '1',
                },
            },
        },
        messages: {
            boxName: {
                delete: 'event',
                error: 'event',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventGeoRestriction.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            selection_type: {
                optionsKey: 'territorySelectionTypes',
                props: {
                    component: Form.SemanticSelect,
                    allowClear: true,
                    label: 'Selection type',
                    name: 'selection_type',
                    description: <IconInfoCircle
                        tooltip={"Leave empty if you don't want to define any geo restriction."}
                    />,
                },
            },
            countries: {
                optionsKey: 'continents',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Countries',
                    name: 'countries',
                    required: true,
                },
            },
            subdivisions: {
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Subdivisions',
                    name: 'subdivisions',
                    required: true,
                },
            },
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            update: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            delete: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
        },
        query: {
            getOptions: GeoRestrictionsDropdownOptionsForCountries,
        },
    };
}
