import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import EditUserGeneralInformationForm from '../forms/EditUserGeneralInformationForm';
import EditUserGeneralInformationModel from '../forms/EditUserGeneralInformationModel';

const EditUserGeneralInformation = ({
    match: {
        params: {
            id,
        },
    },
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('User', routes.users.index.path),
        });
    }

    const Form = React.useMemo(() => (
        createForm(
            EditUserGeneralInformationModel,
            EditUserGeneralInformationForm,
            {
                id: convertToInt(id),
            }
        )
    ),
    []
    );

    return <Form/>;
};

EditUserGeneralInformation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(EditUserGeneralInformation);
