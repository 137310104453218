import {withRouter} from "react-router";
import {isEmpty as _isEmpty} from "lodash";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";
import {renderModalError} from "../../../utils/forms";

export class CompetitorForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.props.history.push(getLink('competitors.index'));
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('competitors.index'));
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink('competitors.index'));
        });
    }

    prepareDataForSubmit = (data) => {
        let dataToSave = Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);

        if (!_isEmpty(data)) {
            dataToSave.country = convertToInt(data.country) || null;
        }

        return dataToSave;
    };

    renderErrors(errorData) {
        const errors = errorData.graphQLErrors;

        if (1 === errors.length && "AvCompetitor not found." === errors[0].message) {
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError('Competitor', getLink('competitors.index')),
            });
        } else {
            super.renderErrors(errorData);
        }
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(CompetitorForm);
