import Form from '@appComponents/ReduxFormControls';

import validate from "./validator";

import * as MESSAGES from '@constants/messages';
import {PRODUCTION_COMPANY} from '@constants/resources';

import {GetProductionCompanyForForm, GetProductionCompanyOptionsForForm} from '@graphql/productionCompany/query';
import {
    CreateProductionCompany,
    DeleteProductionCompany,
    UpdateProductionCompany
} from '@graphql/productionCompany/mutation';

export default function ProductionCompanyModel() {
    return {
        entityDataMapKey: "productionCompany",
        entityLabel: "production company",
        formName: "production company",
        label: "production company",
        resources: PRODUCTION_COMPANY,
        showChangelog: true,
        title: null,
        validate: validate,
        dataMap: {
            productionCompany: {
                id : "",
                name: "",
                address: "",
                country: {id: ""},
                email_address: "",
                phone_number: "",
                fax_number: "",
                vat_number: "",
                client: {id: ""},
                notes: "",
            },
        },
        messages: {
            boxName: {
                delete: "productionCompanyMessage",
                error: "productionCompany",
                success: "productionCompany",
            },
            text: MESSAGES
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "productionCompany.id",
                props: {
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            name: {
                dataMapKey: "productionCompany.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    autoFocus: true,
                },
            },
            address: {
                dataMapKey: "productionCompany.address",
                props: {
                    component: Form.SemanticTextArea,
                    label: "Address",
                    name: "address",
                    row: 3,
                },
            },
            country: {
                dataMapKey: "productionCompany.country.id",
                optionsKey: "countries",
                props: {
                    component: Form.SemanticSelect,
                    label: "Country",
                    name: "country",
                    allowClear: true,
                    placeholder: "Select country",
                },
            },
            email_address: {
                dataMapKey: "productionCompany.email_address",
                props: {
                    component: Form.SemanticInput,
                    label: "Email address",
                    name: "email_address",
                },
            },
            phone_number: {
                dataMapKey: "productionCompany.phone_number",
                props: {
                    component: Form.SemanticInput,
                    label: "Phone number",
                    name: "phone_number",
                },
            },
            fax_number: {
                dataMapKey: "productionCompany.fax_number",
                props: {
                    component: Form.SemanticInput,
                    label: "Fax number",
                    name: "fax_number",
                },
            },
            vat_number: {
                dataMapKey: "productionCompany.vat_number",
                props: {
                    component: Form.SemanticInput,
                    label: "VAT number",
                    name: "vat_number",
                },
            },
            client: {
                dataMapKey: "productionCompany.client.id",
                optionsKey: "clients",
                props: {
                    component: Form.SemanticSelect,
                    label: "Client",
                    name: "client",
                    allowClear: true,
                    placeholder: "Select client",
                },
            },
            notes: {
                dataMapKey: "productionCompany.notes",
                props: {
                    component: Form.SemanticTextArea,
                    label: "Notes",
                    name: "notes",
                    row: 3,
                },
            },
        },
        mutation: {
            createEntity: CreateProductionCompany,
            deleteEntity: DeleteProductionCompany,
            updateEntity: UpdateProductionCompany,
        },
        query: {
            getEntity: GetProductionCompanyForForm,
            getOptions: GetProductionCompanyOptionsForForm,
        },
    }
}
