import PropTypes from "prop-types";
import React from "react";
import {graphql} from "react-apollo";
import {compose} from "redux";

import EventsConfigurationCdnConfigurations from "../components/EventsConfigurationCdnConfigurations";
import EventsConfigurationTargets from "../components/EventsConfigurationTargets";

import {convertToInt} from "../../../utils/helpers";

import {PRODUCT_SPOTT, SECURITY_PRIVILEGES_READ} from '../../app/constants/variables';

import {GetEncodingTargetsForDistributions} from "../../../graphql/encodingTarget/query.graphql";
import {Authorization} from '@appComponents/Authorization';
import {ENCODING_TARGET} from '@constants/resources';

const authorizationEncodingTargetReadObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ,
};

class EventsConfigurationTargetsCdnConfigurationsWrapper extends React.Component {
    static propTypes = {
        DataEventContentTargets: PropTypes.object.isRequired,
        content: PropTypes.object.isRequired,
        eventId: PropTypes.number.isRequired,
        loading: PropTypes.bool,
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
        setEncodingTargets: PropTypes.func.isRequired,
    };

    static defaultProps = {
        loading: false,
    };

    constructor() {
        super();

        this.state = {
            encodingTargets: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataEventContentTargets: {encodingTargets},
        } = nextProps;

        if (encodingTargets && this.state.encodingTargets !== encodingTargets) {
            this.props.setEncodingTargets(encodingTargets)
            this.setState(() => ({encodingTargets: encodingTargets}));
        }
    }

    hasSpottDistribution = (distributions) => (
        distributions.filter((distribution) => (
            parseInt(distribution.product.id, 10) === PRODUCT_SPOTT
        )).length > 0
    );

    render() {
        return (
            <Authorization authorization={authorizationEncodingTargetReadObject}>
                <React.Fragment>
                    {
                        <EventsConfigurationTargets
                            content={this.props.content}
                            encodingTargets={this.state.encodingTargets}
                            eventId={this.props.eventId}
                            loading={this.props.DataEventContentTargets.loading}
                            Modal={this.props.Modal}
                            MessageBox={this.props.MessageBox}
                        />
                    }
                    {
                        this.hasSpottDistribution(this.props.content.distributions) && <EventsConfigurationCdnConfigurations
                            distributions={this.props.content.distributions}
                            encodingTargets={this.state.encodingTargets}
                            eventContentId={parseInt(this.props.content.id, 10)}
                            eventId={this.props.eventId}
                            loading={this.props.DataEventContentTargets.loading}
                            Modal={this.props.Modal}
                            MessageBox={this.props.MessageBox}
                        />
                    }
                </React.Fragment>
            </Authorization>
        );
    }
}

export default compose(
    graphql(GetEncodingTargetsForDistributions, {
        options : (props) => {
            const distributions = props.content.distributions.map((d) => convertToInt(d.id));

            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy : "network-only",
                variables: {
                    distributions: distributions,
                }
            };
        },
        name: "DataEventContentTargets",
    }),
)(EventsConfigurationTargetsCdnConfigurationsWrapper);
