import {productsForDropdown} from "../products/index";

export const liveControlIndexQuery = `
query LiveControl(
      $search: String,
      $sport: [ID], 
      $tvChannels: [ID],
      $tournamentCategory: [ID],
      $tournament: [ID],
      $court: [ID],
      $propertyLicence: [ID],
      $eventType: ID,
      $contentCategoryLevel1: [ID],
      $contentCategoryLevel2: [ID],
      $contentCategoryLevel3: [ID],
      $encodingStatus: [ID],
      $product: [ID],
      $preBuffer: Int,
      $postBuffer: Int,
      $distributionType: [ID],
      $encodingDatacenter: [ID],
      $startBroadcastAutomatically: Boolean,
      $eventContentType: [Int],
      $eventContentVariant: [Int],
      $broadcastStatus: [ID]
){
    liveControlList(
        filters: {
            search: $search,
            sport: $sport,
            tvChannels: $tvChannels,
            tournamentCategory: $tournamentCategory,
            tournament: $tournament,
            court: $court,
            propertyLicence: $propertyLicence,
            eventType: $eventType,
            contentCategoryLevel1: $contentCategoryLevel1,
            contentCategoryLevel2: $contentCategoryLevel2,
            contentCategoryLevel3: $contentCategoryLevel3,
            encodingStatus: $encodingStatus,
            product: $product,
            preBuffer: $preBuffer,
            postBuffer: $postBuffer,
            distributionType: $distributionType,
            encodingDatacenter: $encodingDatacenter,
            startBroadcastAutomatically: $startBroadcastAutomatically,
            eventContentType: $eventContentType,
            eventContentVariant: $eventContentVariant,
            broadcastStatus: $broadcastStatus
        }    
    )
}`;


export const liveControlFilterQuery = `
query LiveControlFilters{
    broadcastStatuses {
        id
        key: id
        text: name
        value: id
    }
    encodingStatuses {
        id
        name
        value: id 
        text: name
    }
    propertyLicencesForDropdown {
        id
        name
        licensor{
          id
          name
        }
    }
    eventTypes {
        id
        name
    }
    ${productsForDropdown}
    distributionTypes {
        id
        name
        value: id
        text: name
    }
    encodingDatacenters {
        id
        name
        value: id
        text: name
    }
    tvChannels {
        id
        name
        value: id
        text: name
    }
    eventContentTypes {
        id
        key: id
        value: id
        text: name
    }
    eventContentVariants {
        id
        key: id
        text: name
        value: id
    }
}
`;

export const updateEventContent = `
mutation updateEventContent (
        $id: ID,
        $eventContentInput: EventContentInputInput
    ) {
        updateEventContent(
            id: $id,
            event_content_input: $eventContentInput
        ) {
            id
            event_content_input {
                encoding_end_datetime
                encoding_end_margin
                encoding_start_datetime
                encoding_start_margin
            }
    }
}
`;

export const getMatchHasEnded = `
query getMatchHasEnded (
  $id: ID!
) {
  match (id: $id) {
    has_ended
  }
}
`;

export const getEventContentInputEncodingStatus = `
query getEventContentInputEncodingStatus (
  $id: ID!
) {
  eventContentInput (id: $id) {
    encoding_status {
      id
    }
  }
}
`;
