import validators from "../../../utils/validators";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.environment, 'environment'));

    return errors;
};

export default validate;
