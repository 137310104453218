import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import Link from "../../../../../../app/components/Link";

/**
 * Display value for "Applied preset" field
 */
export const AppliedPreset = ({event}) => {
    if (_isEmpty(event.event_preset)) {
        return '-';
    }

    return (
        <Link
            name="propertyLicences.eventPresets.edit"
            params={{
                id: event.property_licence.id,
                eventPresetId: event.event_preset.id
            }}
        >
            {`${event.event_preset.name}`}
        </Link>
    )
};

AppliedPreset.propTypes = {
    event: PropTypes.shape({
        event_preset: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        }),
        property_licence: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired,
    }).isRequired
};

AppliedPreset.defaultProps = {
    event: {
        event_preset: {
            id: null,
            name: null
        }
    }
}
