import * as MESSAGES from '@constants/messages';
import {CLIENT_PRODUCT} from '@constants/resources';
import {UpdateGeoRestrictionsClientProductQb} from '@graphql/clientProductQb/mutation';
import {GetClientProductQbForGeoRestrictionsForm} from '@graphql/clientProductQb/query';

import validatorProductGeoRestriction from './validatorProductGeoRestriction';
import GeoRestrictionsFormEditModel from '../../geoRestriction/forms/GeoRestrictionsFormEditModel';

export default function ProductQbGeoRestrictionsModel() {
    let productQbGeoRestrictionsModel = GeoRestrictionsFormEditModel();

    productQbGeoRestrictionsModel.entityDataMapKey = 'clientProductQb';
    productQbGeoRestrictionsModel.entityLabel = 'geo restrictions';
    productQbGeoRestrictionsModel.formName = 'product QB';
    productQbGeoRestrictionsModel.label = 'geo restrictions';
    productQbGeoRestrictionsModel.resources = CLIENT_PRODUCT;
    productQbGeoRestrictionsModel.validate = validatorProductGeoRestriction;
    productQbGeoRestrictionsModel.messages = {
        boxName: {
            delete: 'clientMessage',
            error: 'clientMessage',
            success: 'clientMessage',
        },
        text: {
            ...MESSAGES,
            SAVED: () => 'The geo restrictions have been saved successfully.',
            FORM_ERROR: () => 'The geo restrictions have not been saved. Please correct the highlighted fields below.',
        },
    };
    productQbGeoRestrictionsModel.fields.id.dataMapKey = 'clientProductLci.id';
    productQbGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductQb,
    };
    productQbGeoRestrictionsModel.query.getEntity = GetClientProductQbForGeoRestrictionsForm;

    delete productQbGeoRestrictionsModel.deleteId;
    delete productQbGeoRestrictionsModel.mutationOptions;

    return productQbGeoRestrictionsModel;
}
