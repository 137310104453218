import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {
    UpdateOverlay,
    CreateOverlay,
    DeleteOverlay,
} from '@graphql/overlay/mutation';
import {GetOverlayForForm, GetOverlayTypesForDropdown} from '@graphql/overlay/query';
import {OVERLAY} from '@constants/resources';

import validate from './validatorOverlay';

export default function OverlayModel() {
    return {
        entityDataMapKey: 'overlay',
        entityLabel: 'Overlay',
        formName: 'Overlay',
        label: 'Overlay',
        showChangeLog: true,
        resources: OVERLAY,
        validate: validate,
        title: () => null,
        dataMap: {
            overlay: {
                id: null,
                overlay_type: {
                    id: null,
                    name: null,
                },
                name: null,
                url: null,
            },
        },
        messages: {
            boxName: {
                delete: 'propertyLicenceMessage',
                error: 'propertyLicenceMessage',
                success: 'propertyLicenceMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('Overlay'),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'overlay.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            overlay_type: {
                dataMapKey: 'overlay.overlay_type.id',
                optionsKey: 'overlayTypes',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Type',
                    name: 'overlay_type',
                    required: true,
                    className: '--small-width',
                },
            },
            name: {
                dataMapKey: 'overlay.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            url: {
                dataMapKey: 'overlay.url',
                props: {
                    className: '--overlayUrlWidth',
                    component: Form.SemanticInput,
                    label: 'URL',
                    name: 'url',
                    required: true,
                    type: 'input',
                },
            },
        },
        mutation: {
            createEntity: CreateOverlay,
            deleteEntity: DeleteOverlay,
            updateEntity: UpdateOverlay,
        },
        query: {
            getEntity: GetOverlayForForm,
            getOptions: GetOverlayTypesForDropdown,
        },
    };
}
