import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Header, Modal} from 'semantic-ui-react';

import {gql, graphql} from 'react-apollo';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {isUrlParamValid} from '@utils/helpers';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import Link, {getLink} from '@appComponents/Link';
import mapModulesToProps from '@utils/mapModulesToProps';
import {sortByTextValue} from '@utils/sorters';

import {exportExcel} from '../utils/export/clientAnalyticsSetupLabelsIndexExcel';
import * as clientAnalyticsSetupLabelsGraphQl from '../../../graphql/clients/clientLabels';
import {CLIENT_ANALYTICS_SETUP_LABLES_TABLE_MAX_NUMBER} from "../constants/messages";
import ClientAnalyticsSetupLabelForm from '../forms/ClientAnalyticsSetupLabelForm';
import ClientAnalyticsSetupLabelModel from '../forms/ClientAnalyticsSetupLabelModel';
import ClientAnalyticsSetupLabelModelEdit from '../forms/ClientAnalyticsSetupLabelModelEdit';
import ClientAnalyticsSetupLabelsIndexButtons from '../components/ClientAnalyticsSetupLabelsIndexButtons';
import ClientAnalyticsSetupLabelsTable from '../components/ClientAnalyticsSetupLabelsTable';

export class ClientAnalyticsSetupLabelsIndex extends React.Component {
    static propTypes = {
        clientId: PropTypes.number.isRequired,
        DataClientAnalyticsSetupLabels: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
        modal: PropTypes.object.isRequired,
    };

    constructor() {
        super();

        this.state = {
            clientLabelsOriginal: [],
            clientLabels: [],
        };

        this.maxNumberOfClientLabels = 50;
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataClientAnalyticsSetupLabels: {clientLabels},
        } = nextProps;

        const nextState = {};

        if (clientLabels && clientLabels !== this.state.clientLabelsOriginal) {
            nextState.clientLabelsOriginal = clientLabels;
            nextState.clientLabels = sortByTextValue(clientLabels, 'code');

            this.loadModal(this.props.match.path, {clientLabels: clientLabels});
        }

        const urlChanged = this.props.match.path !== nextProps.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.clients.clientAnalyticsSetup.labels.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex;

        shouldOpenModal && this.loadModal(nextProps.match.path, Object.assign({}, nextProps.match.params, {
            clientLabels: clientLabels,
        }));

        const modalChanged = nextProps.modal.isVisible !== this.props.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(
            getLink("clients.clientAnalyticsSetup.labels.index", {id: this.props.match.params.id})
        );

        this.setState(() => (nextState));
    }

    showMaxNumberOfClientLabelsModal = () => {
        this.props.Modal.setModal({
            content: (
                <div>
                    <Header
                        color="red"
                        content={"Error"}
                        icon="warning"
                    />
                    <Modal.Content>
                        <p>
                            {CLIENT_ANALYTICS_SETUP_LABLES_TABLE_MAX_NUMBER(this.maxNumberOfClientLabels)}
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Link
                            name="clients.clientAnalyticsSetup.labels.index"
                            params={{id: this.props.match.params.id}}
                        >
                            Back to the list of labels
                        </Link>
                    </Modal.Actions>
                </div>
            ),
            isVisible: true,
        });
    };

    loadModal(path, params = {}) {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        }

        if (!isUrlParamValid(this.props.match.params.clientLabelId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Label', getLink(`clients.clientAnalyticsSetup.labels`, {
                    id: this.props.match.params.id,
                })),
            });
        }

        switch (path) {
            case routes.clients.clientAnalyticsSetup.labels.add.path:
                if (params.clientLabels.length >= this.maxNumberOfClientLabels) {
                    this.showMaxNumberOfClientLabelsModal();

                    return false;
                }

                return this.loadModalForm(createForm(
                    ClientAnalyticsSetupLabelModel,
                    ClientAnalyticsSetupLabelForm,
                    {
                        optionsVariables: {
                            id: parseInt(this.props.match.params.id, 10),
                        },
                    }
                ), params);
            case routes.clients.clientAnalyticsSetup.labels.edit.path:
                return this.loadModalForm(createForm(
                    ClientAnalyticsSetupLabelModelEdit,
                    ClientAnalyticsSetupLabelForm,
                    {
                        id: this.props.match.params.clientLabelId || params.clientLabelId,
                        dataRequest: true,
                        optionsVariables: {
                            id: parseInt(this.props.match.params.id, 10),
                        },
                    }
                ), params);
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });
    };

    render() {
        let header = this.state.clientLabels.length >= this.maxNumberOfClientLabels
            ? (
                <p className="__tableNote">
                    {CLIENT_ANALYTICS_SETUP_LABLES_TABLE_MAX_NUMBER(this.maxNumberOfClientLabels)}
                </p>
            )
            : (
                <HeaderRenderer
                    buttons={ClientAnalyticsSetupLabelsIndexButtons}
                    buttonsProps={{id: parseInt(this.props.match.params.id, 10)}}
                    exportExcelParams={exportExcel(this.state.clientLabels, this.props.match.params.id)}
                    loading={this.props.DataClientAnalyticsSetupLabels.loading}
                />
            );

        return (
            <div>
                {header}
                <div className="clearfix"/>
                <ClientAnalyticsSetupLabelsTable
                    clientId={parseInt(this.props.match.params.id, 10)}
                    clientLabels={this.state.clientLabels}
                    loading={this.props.DataClientAnalyticsSetupLabels.loading}
                />
            </div>
        );
    }
}

const ClientAnalyticsSetupLabelsQuery = gql(clientAnalyticsSetupLabelsGraphQl.clientLabelsQuery);

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

const mapDispatchToProps = mapModulesToProps(['Modal']);

export default compose(
    graphql(ClientAnalyticsSetupLabelsQuery, {
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: props.clientId,
                },
            };
        },
        name: "DataClientAnalyticsSetupLabels",
    }),
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientAnalyticsSetupLabelsIndex)));
