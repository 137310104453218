import PropTypes from "prop-types";
import React from "react";
import {Header} from "semantic-ui-react";

export const renderHeader = (props) => (
    <Header as="h3" className="subFormHeader" dividing>{props.defaultValue}</Header>
);

renderHeader.propTypes = {
    defaultValue: PropTypes.string.isRequired,
};
