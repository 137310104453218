import React from 'react';

import {IconInfoCircle} from '@appComponents/IconCollection';
import {UpdateEventBooking, DeleteEventBooking} from '@graphql/eventEventBooking/mutation';
import {
    GetEventEventBookingForForm,
    GetClientAndProductForGeneralInformationEventBookingEditForm,
} from '@graphql/eventEventBooking/query';

import EventBookingGeneralInformationCommonModel from './EventBookingGeneralInformationCommonModel';

export default function EventBookingGeneralInformationEditModel() {
    const eventBookingGeneralInformationEditModel = EventBookingGeneralInformationCommonModel();

    eventBookingGeneralInformationEditModel.fields.client_package.props.description =
        <IconInfoCircle tooltip={'If you modify the client package of an autobooked event booking ' +
            'for a content with a start time greater than one hour ago, it may be changed automatically again if ' +
            'the Autobooker is triggered for this event booking.'}/>;

    eventBookingGeneralInformationEditModel.fields.client.props.disabled = true;
    eventBookingGeneralInformationEditModel.fields.product.props.disabled = true;
    eventBookingGeneralInformationEditModel.fields.client_package.props.disabled = false;

    eventBookingGeneralInformationEditModel.deleteId = 'eventEventBooking.id';
    eventBookingGeneralInformationEditModel.editForm = true;
    eventBookingGeneralInformationEditModel.showChangeLog = true;
    eventBookingGeneralInformationEditModel.mutation = {
        updateEntity: UpdateEventBooking,
        deleteEntity: DeleteEventBooking,
    };
    eventBookingGeneralInformationEditModel.query.getEntity = GetEventEventBookingForForm;
    eventBookingGeneralInformationEditModel.query.getOptions = GetClientAndProductForGeneralInformationEventBookingEditForm;

    return eventBookingGeneralInformationEditModel;
}
