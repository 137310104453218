import moment from 'moment';
import React from 'react';
import {filter as _filter, isUndefined as _isUndefined, map as _map, isNil as _isNil} from 'lodash';
import {Icon, Label, List, Popup} from 'semantic-ui-react';

import {convertToInt} from '@utils/helpers';
import {IconAdd, IconBookmarkOutline, IconCheckmark, IconEdit, IconLinkify} from '@appComponents/IconCollection';
import Authorization from '@appComponents/Authorization';
import Link, {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {renderEventStatusName} from '@utils/status';

import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup'

import { CompetitorsBlacklistedStatus } from '../views/common/EventScheduleTableColumns';

const eventAddAuthorizationObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE_READ
};

const eventReadAuthorizationObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ
};

const rowContainsColumn = (column, row) => {
    return !_isUndefined(row[column]) && null !== row[column];
};

const renderEventIdColumn = (column, row) => {
    return (
        <div>
            {(row.is_master || row.is_master_match) ? <IconBookmarkOutline/> : ""}
            {(_isUndefined(row[column]) || null === row[column])
                ? ""
                : convertToInt(row[column])
            }
        </div>
    );
};

const renderMatchIdColumn = (column, row) => {
    const matchIdColumnContent = (row.is_sr_event && !row.is_master)
        ? <IconLinkify color="red"/>
        : "";

    return (
        <div>
            {(_isUndefined(row[column]) || null === row[column])
                ? matchIdColumnContent
                : convertToInt(row[column])
            }
        </div>
    );
};

const renderStartDatetimeColumn = (column, row) => {
    return (
        <div>
            <div>
                {(_isUndefined(row[column]) || null === row[column])
                    ? ""
                    : moment(row[column]).format("YYYY-MM-DD HH:mm")
                }
            </div>
            <div>
                {(_isUndefined(row.end_datetime) || null === row.end_datetime)
                    ? ""
                    : moment(row.end_datetime).format("YYYY-MM-DD HH:mm")
                }
            </div>
        </div>
    );
};

export const renderCompetitorsColumn = (column, row) => {
    const competitorsWarningsExist = row.competitors_warnings && row.competitors_warnings.length !== 0,
        tvStreamingInfoExist = row.is_tv_streaming && null !== row.tv_streaming_encoder_id,
        marginClass = (row.description && (competitorsWarningsExist || tvStreamingInfoExist))
            ? "marginBetweendDescriptionAndWarnings"
            : "";

    return (
        <div>
            <div>
                {(_isUndefined(row[column]) || null === row[column])
                    ? ""
                    : row[column]
                }
            </div>
            <div>
                {(_isUndefined(row.description) || null === row.description)
                    ? ""
                    : <Label style={{backgroundColor: `#${row.color}`}} size="mini">{row.description}</Label>
                }
            </div>
            <div className={marginClass}>
                {tvStreamingInfoExist && <Label color="grey" size="mini">TV Streaming</Label>}
                {competitorsWarningsExist && _map(row.competitors_warnings, (warning, index) => (
                    <Label key={index} color="red" size="mini">{warning}</Label>
                ))}
                <CompetitorsBlacklistedStatus
                    ac_blacklisted={row.is_ac_blacklisted}
                    hc_blacklisted={row.is_hc_blacklisted} />
            </div>
        </div>
    )
};

export const renderStatusColumn = (column, row) => {
    const renderedStatus = rowContainsColumn(column, row)
        ? renderEventStatusName(row.status_id, row.status)
        : "";

    return <div>{renderedStatus}</div>;
};

const renderEventContentWarning = (content) => (
    <Popup
        trigger={<Icon className="warningSign" name="warning sign" color="red"/>}
        content={content.eventContentWarning.content}
        size="small"
        hoverable
        key={`content.popup.${content.event_content_id}`}
    />
);

const renderContentsColumn = (column, row) => {
    return (
        (!row[column] || 0 === row[column].length) ? "" :
            <List>
                {_map(row[column], (content) => {
                    const products = _filter(row.products, ["event_content_id", content.event_content_id]);

                    return (
                        <List.Item key={`content.list.item.${content.event_content_id}`}>
                            <List.Content key={`content.list.content.${content.event_content_id}`}>
                                {0 === products.length
                                    ? <p key={`content.p.${content.event_content_id}`} className={'--marginBottom-2'}>
                                        {content.name}
                                        {
                                            content.eventContentWarning !== null
                                            && renderEventContentWarning(content)
                                        }
                                        <EventContentVariantPopup eventContentVariant={content.eventContentVariant}/>
                                    </p>
                                    : _map(products, (product) => (
                                        <p key={`content.p.${product.product_id}`} className={'--marginBottom-2'}>
                                            {content.name}
                                            {
                                                content.eventContentWarning !== null
                                                && renderEventContentWarning(content)
                                            }
                                            <EventContentVariantPopup eventContentVariant={content.eventContentVariant}/>
                                        </p>
                                    ))
                                }
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderContributionTypesColumn = (column, row) => {
    return (
        (!row[column] || 0 === row[column].length) ? "" :
            <List>
                {_map(row.contents, (content) => {
                    const contributionTypes = _filter(row[column], ["event_content_id", content.event_content_id]),
                        products = _filter(row.products, ["event_content_id", content.event_content_id]);

                    return (
                        <List.Item key={`contribution.list.item.${content.event_content_id}`}>
                            <List.Content key={`contribution.list.content.${content.event_content_id}`}>
                                {_map(contributionTypes, (contributionType) => (
                                    0 === products.length
                                        ? <p key={`contribution.p.${contributionType.id}`}>
                                            {contributionType.name}
                                        </p>
                                        : _map(products, (product) => (
                                            <p key={`contribution.p.${product.product_id}`}>{contributionType.name}</p>
                                        ))
                                ))}
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderEncodingDatacentersColumn = (column, row) => {
    return (!row[column] || 0 === row[column].length)
        ? ""
        : (
            <List>
                {row.contents.map(content => {
                    const encodingDatacenters = _filter(row[column], ["event_content_id", content.event_content_id]),
                        products = _filter(row.products, ["event_content_id", content.event_content_id]);

                    return (
                        <List.Item key={`datacenter.list.item.${content.event_content_id}`}>
                            <List.Content key={`datacenter.list.content.${content.event_content_id}`}>
                                {encodingDatacenters.map(encodingDatacenter => (
                                    (0 === products.length)
                                        ? (
                                            <p key={`datacenter.p.${encodingDatacenter.id}`}>
                                                {encodingDatacenter.name}
                                            </p>
                                        )
                                        : products.map(product => (
                                            <p key={`datacenter.p.${product.product_id}`}>
                                                {encodingDatacenter.name}
                                            </p>
                                        ))
                                ))}
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        );
};

const renderProductsColumn = (column, row) => {
    return (
        (!row[column] || 0 === row[column].length) ? "" :
            <List>
                {_map(row.contents, (content) => {
                    const products = _filter(row[column], ["event_content_id", content.event_content_id]);

                    return (
                        <List.Item key={`product.list.item.${content.event_content_id}`}>
                            <List.Content key={`product.list.content.${content.event_content_id}`}>
                                {0 === products.length
                                    ? <p key={`product.p.${content.event_content_id}`}>&nbsp;</p>
                                    : _map(products, (product) => (
                                        <p key={`product.p.${product.product_id}`}>{product.short_name}</p>
                                    ))
                                }
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderDistributionTypesColumn = (column, row) => {
    return (
        (!row[column] || 0 === row[column].length) ? "" :
            <List>
                {_map(row.contents, (content) => {
                    const products = _filter(row.products, ["event_content_id", content.event_content_id]);

                    return (
                        <List.Item key={`distributionType.list.item.${content.event_content_id}`}>
                            <List.Content key={`distributionType.list.content.${content.event_content_id}`}>
                                {0 === products.length
                                    ? <p key={`distributionType.p.${content.event_content_id}`}>&nbsp;</p>
                                    : _map(products, (product) => {
                                        const distributionTypes = _filter(row[column], {
                                            event_content_id: content.event_content_id,
                                            product_id: product.product_id
                                        });

                                        if (0 === distributionTypes.length) {
                                            return <p key={`product.p.${product.product_id}`}>&nbsp;</p>;
                                        }

                                        return (
                                            <p key={`product.p.${product.product_id}`}>
                                                {_map(distributionTypes, (distributionType) => (
                                                    distributionType.name
                                                ))}
                                            </p>
                                        );
                                    })
                                }
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderColumnWithList = (column, row, defaultValue = 0, fieldName = false) => {
    let field = column;

    if (fieldName) {
        field = fieldName
    }

    return (
        (!row[column] || 0 === row[column].length) ? "" :
            <List>
                {_map(row.contents, (content) => {
                    const products = _filter(row.products, ["event_content_id", content.event_content_id]);

                    return (
                        <List.Item key={`${field}.list.item.${content.event_content_id}`}>
                            <List.Content key={`${field}.list.content.${content.event_content_id}`}>
                                {0 === products.length
                                    ? <p key={`${field}.p.${content.event_content_id}`}>&nbsp;</p>
                                    : _map(products, (product) => {
                                        const columnValues = _filter(row[column], {
                                            event_content_id: content.event_content_id,
                                            product_id: product.product_id
                                        });

                                        if (0 === columnValues.length) {
                                            return <p key={`product.p.${product.product_id}`}>0</p>;
                                        }

                                        return (
                                            <p key={`product.p.${product.product_id}`}>
                                                {columnValues[0][field] || defaultValue}
                                            </p>
                                        );
                                    })
                                }
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderCategoryOrTournament = (column, row) => {
    return Array.isArray(row[column]) && row[column].length > 0
        ? <List>
            {_map(row[column], (data, index) => {
                return (
                    <List.Item key={`tournament.list.item.${index}`}>
                        <List.Content key={`tournament.list.content.${index}`}>
                            <p key={`tournament.p.${index}`}>
                                {data}
                            </p>
                        </List.Content>
                    </List.Item>
                );
            })}
        </List>
        : row[column] || null;
};

const renderVenueOrCourtAndTvChannelColumn = (column, row) => {
    return(
        <div>
            <div>
                {_isNil(row[column])
                    ? ""
                    : row[column]
                }
            </div>
            <div>
                {_isNil(row.tv_channel)
                    ? ""
                    : row.tv_channel
                }
            </div>
        </div>
    )
};

const renderAction = (row) => {
    if (null !== row.event_id) {
        return (
            <Authorization authorization={eventReadAuthorizationObject}>
                <IconEdit
                    size="large"
                    link={getLink("events.configuration.index", {id: row.event_id})}
                    resource={RESOURCES.EVENT}
                    editIconName="setting"
                />
            </Authorization>
        );
    } else if (row.is_master_match) {
        return (
            <Authorization authorization={eventAddAuthorizationObject}>
                <Link name="events.add.sportradarMaster" params={{masterMatchId: row.match_id}}>
                    <IconAdd size="large"/>
                </Link>
            </Authorization>
        );
    }

    return (
        <Authorization authorization={eventAddAuthorizationObject}>
            <Link name="events.add.sportradarExisting" params={{matchId: row.match_id}}>
                <IconAdd size="large"/>
            </Link>
        </Authorization>
    );
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case "event_id":
            return renderEventIdColumn(column, row);
        case "match_id":
            return renderMatchIdColumn(column, row);
        case "start_datetime":
            return renderStartDatetimeColumn(column, row);
        case "category_or_content_category_level_2":
        case "tournament_or_content_category_level_3":
            return renderCategoryOrTournament(column, row);
        case "sport_or_content_category_level_1":
            return (
                <div>
                    {_isNil(row[column])
                        ? ""
                        : row[column]
                    }
                </div>
            );
        case "venue_or_court":
            return renderVenueOrCourtAndTvChannelColumn(column, row);
        case "notes":
            return (
                <div>
                    {(_isUndefined(row[column]) || null === row[column])
                        ? ""
                        : row[column]
                    }
                </div>
            );
        case "competitors":
            return renderCompetitorsColumn(column, row);
        case "status":
            return renderStatusColumn(column, row);
        case "contents":
            return renderContentsColumn(column, row);
        case "contribution_types":
            return renderContributionTypesColumn(column, row);
        case "encoding_datacenters":
            return renderEncodingDatacentersColumn(column, row);
        case "products":
            return renderProductsColumn(column, row);
        case "distribution_types":
            return renderDistributionTypesColumn(column, row);
        case "product_statuses":
            return renderColumnWithList(column, row, "", "name");
        case "targets":
            return renderColumnWithList(column, row);
        case "bookings":
            return renderColumnWithList(column, row);
        case "has_event_report":
            return row[column] ? <IconCheckmark/> : null;
        case "actions" :
            return (
                <div className="actions-list-icon">{renderAction(row)}</div>
            );
        default:
            return null;
    }
};
