import React from 'react';
import * as PropTypes from 'prop-types';
import {get as _get, isEmpty as _isEmpty} from 'lodash';

import Form from '@appComponents/ReduxFormControls';
import {NO_STREAM_NAMES_TEXT} from '@constants/messages';

class ToggleListWithDropdown extends React.Component {
    static propTypes = {
        inputsData: PropTypes.array,
        streamType: PropTypes.string,
        initialValues: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {
            initialValues: {},
            inputsData: [],
            switches: {},
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {};

        if (nextProps.inputsData !== prevState.inputsData) {
            newState.inputsData = nextProps.inputsData;
        }

        if (nextProps.initialValues !== prevState.initialValues) {
            let switches = {...prevState.switches};

            nextProps.initialValues && Object.keys(nextProps.initialValues).forEach((index) => {
                let stream = nextProps.initialValues[index];

                switches[stream.switchId] = true;
            });

            newState.initialValues = nextProps.initialValues;
            newState.switches = switches;
        }

        return newState;
    }

    handleDropdownChange = (data, props) => {
        let initialValues = {...this.state.initialValues};

        if (!_isEmpty(props.value) && !_isEmpty(props.name)) {
            const dropdownValue = props.value,
                dropdownData = props.name.split('.'),
                type = dropdownData[0].replace('Streams', ''),
                streamData = dropdownData[1].split('_'),
                streamId = streamData[0],
                clientId = streamData[2];
            let streams = {};

            if (!_isEmpty(initialValues[clientId])) {
                streams = initialValues[clientId].streams;
            }

            streams[streamId] = dropdownValue;
            initialValues[clientId] = {switchId: `${type}${clientId}`, streams: streams};
        }

        this.setState(() => ({
            initialValues: initialValues,
        }));
    };

    handleToggleChange = (data, props) => {
        let initialValues = this.state.initialValues;

        delete initialValues[props.input.name.replace(/[a-z]/g, '')];

        this.setState(() => ({
            initialValues: initialValues,
            switches: {
                ...this.state.switches,
                [props.input.name]: data.checked,
            },
        }));
    };

    renderDropdowns = (client = {}, data = []) => {
        let dropdowns = [];

        data.forEach(function (stream) {
            let initialValue = _get(this.state, `initialValues[${client.id}].streams[${stream.id}]`, null);
            const streams = stream.streams.map(el => ({
                ...el,
                text: `${el.text} ${el.description ? `(${el.description})` : ''}`,
            }));

            if (0 < streams.length) {
                dropdowns.push(<div key={stream.id} hidden={!this.state.switches[client.key]}>
                    {'push' === this.props.streamType ? 'CDN: ' : 'Pull origin: '}{stream.name}
                    <Form.FormField
                        className='streamFormField'
                        component={Form.SemanticSelect}
                        options={streams}
                        defaultValue={initialValue}
                        value={initialValue}
                        name={`${this.props.streamType}Streams.${stream.id}_${stream.encoding_job_profile.id}_${client.id}`}
                        allowClear={true}
                        onChangeSelect={this.handleDropdownChange}
                    />
                </div>);
            }
        }.bind(this));

        return dropdowns;
    };

    renderInputs = () => {
        let contentToRender = [];

        this.state.inputsData && this.state.inputsData.forEach(function (stream) {
            let clientDisplay = false;

            stream.options.forEach((option) => {
                if (0 < option.streams.length) {
                    clientDisplay = true;
                }
            });

            if (clientDisplay) {
                let row = <div key={stream.client.key} className='thirdPartyClientFormRow'>
                    <label className='--withPointer' htmlFor={stream.client.key}>{stream.client.name}</label>
                    <div className='fieldsContainer'>
                        <Form.FormField
                            component={Form.SemanticInput}
                            type='toggle'
                            defaultValue={this.state.switches[stream.client.key]}
                            checked={this.state.switches[stream.client.key]}
                            name={stream.client.key}
                            onCheckboxChange={this.handleToggleChange}
                        />
                        <div>{this.renderDropdowns(stream.client, stream.options)}</div>
                    </div>
                </div>;

                contentToRender.push(row);
            }
        }.bind(this));

        return 0 === contentToRender.length ? NO_STREAM_NAMES_TEXT :
            <div className='formRow field'>{contentToRender}</div>;
    };

    render() {
        return this.renderInputs();
    }
}

export default ToggleListWithDropdown;
