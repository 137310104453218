export const getStadiumsWithCities = `
query stadiums($search: String) {
    stadiums(filters: {search: $search}) {
        value: id,
        id
        city {
            id
            name
        }
        text: name
    }
}
`;
