import React from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
/* eslint import/no-unresolved: 0 */
import {gql, graphql} from "react-apollo";
import {Header} from "semantic-ui-react";
import {find as _find, get as _get, sortBy as _sortBy} from 'lodash';
import {connect} from "react-redux";

import {refetchQueryByName} from "../../../utils/apollo";
import mapModulesToProps from '../../../utils/mapModulesToProps';

import * as MESSAGES from "../../app/constants/messages";
import * as propertyLicenceAttachmentsGraphQl from "../../../graphql/propertyLicences/attachments";

import validate from "../forms/validatorPropertyLicenceAttachments";
import Form from "../../app/components/ReduxFormControls";
import EditableGridTable from "../../app/components/EditableGridTableComponents/EditableGridTable";
import {PROPERTY_LICENCE} from "@constants/resources";
import {SECURITY_PRIVILEGES_UPDATE} from "@constants/variables";

class PropertyLicenceAttachments extends React.Component {
    static propTypes = {
        propertyLicenceId: PropTypes.number.isRequired,
        messageBoxName: PropTypes.string.isRequired,
        Modal: PropTypes.object.isRequired,
        MessageBox: PropTypes.object.isRequired,
        Form: PropTypes.object.isRequired,
        PropertyLicenceAttachmentsCreateMutation: PropTypes.func,
        PropertyLicenceAttachmentsDeleteMutation: PropTypes.func,
        PropertyLicenceAttachmentsUpdateMutation: PropTypes.func,
        DataPropertyLicenceAttachments: PropTypes.object,
    };

    constructor() {
        super();

        const updateauthorizationObjects = {
            [PROPERTY_LICENCE]: SECURITY_PRIVILEGES_UPDATE,
        };

        this.state = {
            propertyLicenceLinks: undefined,
            authorizationObjects: {
                create: updateauthorizationObjects,
                update: updateauthorizationObjects,
                delete: updateauthorizationObjects,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        const {DataPropertyLicenceAttachments: {propertyLicenceAttachments}} = nextProps;

        if (propertyLicenceAttachments) {
            this.setState(() => ({propertyLicenceAttachments: _sortBy(propertyLicenceAttachments, "description")}));
        }
    }

    handlePropertyLicenceAttachmentsAdd = (data) => {
        this.setState(() => ({loading: true}));

        const filename = _get(data, 'filename.name', null);

        const dataToSave = Object.assign({}, {
            property_licence_id: this.props.propertyLicenceId,
            description: data.description,
            filename: filename,
            server_path: '/server/path/to/be/defined/' + filename
        });
        const {
            MessageBox: {addMessage},
            Form: {clearForm}
        } = this.props;

        this.props.PropertyLicenceAttachmentsCreateMutation({
            variables: {...dataToSave}
        }).then(() => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.SAVED("property licence attachment"),
                null,
                "success"
            );
            refetchQueryByName("PropertyLicenceAttachments");
            clearForm('PropertyLicenceAttachments');

            this.setState(() => ({loading: false}));
        }).catch((error) => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.NOT_SAVED("property licence attachment"),
                `${error}`,
                "error"
            );

            this.setState(() => ({loading: false}));
        });
    };

    handlePropertyLicenceAttachmentsDelete = (event, data) => {
        event.stopPropagation();

        const attachment = _find(this.state.propertyLicenceAttachments, {'id': data['data-id']});

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={MESSAGES.DELETE_HEADER(`property licence attachment`)}/>,
            text: MESSAGES.DELETE_CONFIRMATION(attachment.description),
            onYes: () => (this.deletePropertyLicenceAttachment(data['data-id']))
        });
    };

    handlePropertyLicenceAttachmentsUpdate = (data) => {
        this.setState(() => ({loading: true}));

        const attachment = _find(this.state.propertyLicenceAttachments, {'id': data.id});
        const filename = (data.filename && data.filename.name) ? data.filename.name : attachment.filename,
            server_path = '/server/path/to/be/defined/' + filename;
        const dataToUpdate = {
            id: data.id,
            property_licence_id: this.props.propertyLicenceId,
            description: data.description,
            filename: filename,
            server_path: server_path
        };

        this.props.PropertyLicenceAttachmentsUpdateMutation({
            variables: {...dataToUpdate}
        }).then(() => {
            this.props.MessageBox.addMessage(
                this.props.messageBoxName,
                MESSAGES.SAVED("property licence attachment"),
                null,
                "success"
            );
            refetchQueryByName("PropertyLicenceAttachments");
            this.setState(() => ({loading: false}));
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                this.props.messageBoxName,
                MESSAGES.NOT_SAVED("property licence attachment"),
                `${error}`,
                "error"
            );

            this.setState(() => ({loading: false}));
        });
    };

    deletePropertyLicenceAttachment(propertyLicenceAttachmentId) {
        const {
            Modal: {setModal},
            MessageBox: {addMessage}
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING(`property licence attachment`)
        });

        this.props.PropertyLicenceAttachmentsDeleteMutation({
            variables: {
                id: propertyLicenceAttachmentId
            }
        }).then(() => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.DELETED(`property licence attachment`),
                null,
                "success"
            );
            refetchQueryByName("PropertyLicenceAttachments");
            setModal({isVisible: false});
        }).catch((error) => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.NOT_DELETED(`property licence attachment`),
                `${error}`,
                "error"
            );
            setModal({isVisible: false});
        });
    }

    render() {
        const loading = this.props.DataPropertyLicenceAttachments.loading || this.state.loading,
            columns = {
                description: {
                    component: Form.SemanticInput,
                    componentProps: {
                        name: "description",
                        required: true,
                        placeholder: "Description",
                        type: "text"
                    },
                    label: "Description",
                    width: 6
                },
                filename: {
                    component: Form.SemanticFileInput,
                    componentProps: {
                        name: "filename",
                        required: true,
                    },
                    label: "Filename",
                    width: 8
                },
                actions: {label: "", width: 2}
            };

        return (
            <EditableGridTable
                loading={loading}
                onCreate={this.handlePropertyLicenceAttachmentsAdd}
                onDelete={this.handlePropertyLicenceAttachmentsDelete}
                onUpdate={this.handlePropertyLicenceAttachmentsUpdate}
                rows={this.state.propertyLicenceAttachments}
                columns={columns}
                validator={validate}
                headerText="Attachments"
                tableClassName="propertyLicenceAttachments"
                formName="PropertyLicenceAttachments"
                authorizationObjects={this.state.authorizationObjects}
            />
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox', 'Form']);

const PropertyLicenceAttachmentsListQuery =
    gql(propertyLicenceAttachmentsGraphQl.propertyLicenceAttachmentsList);
const PropertyLicenceAttachmentsCreateMutation =
    gql(propertyLicenceAttachmentsGraphQl.propertyLicenceAttachmentsCreateMutation);
const PropertyLicenceAttachmentsDeleteMutation =
    gql(propertyLicenceAttachmentsGraphQl.propertyLicenceAttachmentsDeleteMutation);
const PropertyLicenceAttachmentsUpdateMutation =
    gql(propertyLicenceAttachmentsGraphQl.propertyLicenceAttachmentsUpdateMutation);

const PropertyLicenceAttachmentsWithStore = connect(null, mapDispatchToProps)(PropertyLicenceAttachments);

export default compose(
    graphql(PropertyLicenceAttachmentsListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "network-only",
                variables: {
                    propertyLicenceId: props.propertyLicenceId
                }
            }
        },
        name: "DataPropertyLicenceAttachments"
    }),
    graphql(PropertyLicenceAttachmentsCreateMutation, {name: 'PropertyLicenceAttachmentsCreateMutation'}),
    graphql(PropertyLicenceAttachmentsDeleteMutation, {name: 'PropertyLicenceAttachmentsDeleteMutation'}),
    graphql(PropertyLicenceAttachmentsUpdateMutation, {name: 'PropertyLicenceAttachmentsUpdateMutation'})
)(PropertyLicenceAttachmentsWithStore);
