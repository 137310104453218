import {get as _get, isEmpty as _isEmpty} from "lodash";
import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {prepareContentsOptions} from "../utils/formHelpers";
import {convertToInt} from "../../../utils/helpers";

export class MatchSelectionPeriodForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {this.pushHistory()});
        this.setDeleteSuccessCallback(() => {this.pushHistory()});
        this.setUpdateSuccessCallback(() => {this.pushHistory()});

        this.setOnChangeCallback({
            includes_all_content: (data) => {
                const hideContents = !data.value;

                this.setField("contents", {
                    hidden: hideContents,
                    required: !hideContents,
                    defaultValue: this.state.selectedContents || [],
                });
            },
            contents: (event) => {
                this.setState(() => ({selectedContents: event.data}));
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {GraphQLOptionsData: {propertyLicenceContents}} = nextProps,
            contents = _get(nextProps, 'GraphQLEntityData.matchSelectionPeriod.contents', []);

        if (!this.state.contentsOptionsGenerated && !_isEmpty(propertyLicenceContents)) {
            this.setField("contents", {
                options: prepareContentsOptions(propertyLicenceContents),
            });
            this.setState(() => ({contentsOptionsGenerated: true}));
        }

        if (!this.state.contentsGenerated && !_isEmpty(contents)) {
            const selectedContents = contents.map((content) => convertToInt(content.id));

            this.setField("includes_all_content", {defaultValue: false});
            this.setField("contents", {
                hidden: false,
                defaultValue: selectedContents,
            });
            this.setState(() => ({
                contentsGenerated: true,
                selectedContents,
            }));
        }
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            data ? {
                num_matches_agreed: convertToInt(data.num_matches_agreed),
                num_matches_commitment: convertToInt(data.num_matches_commitment),
                num_matches_budget: convertToInt(data.num_matches_budget),
                contents: data.includes_all_content ? [] : data.contents,
                property_licence: convertToInt(this.props.match.params.id),
            } : null
        )
    );

    renderErrors = (errorData) => {
        super.renderErrors(
            errorData,
            'Match selection period',
            getLink("propertyLicences.matchSelectionPeriods.index", {
                id: convertToInt(this.props.match.params.id),
            }),
            {size: 'tiny'}
        );
    }

    pushHistory = () => {
        this.props.history.push(getLink("propertyLicences.matchSelectionPeriods.index", {
            id: convertToInt(this.props.match.params.id),
        }));
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(MatchSelectionPeriodForm);
