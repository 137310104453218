import PropTypes from 'prop-types';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'ip_blacklisting',
    parseData: ipBlacklisting => [
        ipBlacklisting.ip_version.name,
        ipBlacklisting.cidr,
        ipBlacklisting.description,
        ipBlacklisting.creation_datetime,
        ipBlacklisting.disable_datetime || '',
    ],
    path: 'ipBlacklisting.index.path',
    titles: [
        'IP version',
        'CIDR',
        'Description',
        'Added',
        'Disabled',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
