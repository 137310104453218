import React from 'react';
import {Header} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
    merge as _merge,
} from 'lodash';

import {createForm} from '@utils/forms';

import {EventsSimpleTable} from './EventsMultiple/EventsSimpleTable';
import SetCoverageModel from '../forms/SetCoverageModel';
import SetCoverageForm from '../forms/SetCoverageForm';

const SetCoverageModalContent = ({data, queryForRefetch, modelObjectExtension = {}, products}) => {
    const Form = createForm(
        () => (_merge(SetCoverageModel(), modelObjectExtension)),
        SetCoverageForm,
        {
            optionsVariables: {
                propertyLicence: data.events[0].property_licence.id,
                eventsIds: data.events.map((event) => event.id),
                products: products,
            },
            queryForRefetch: queryForRefetch,
        }
    );

    return (
        <div>
            <Header content='Set coverage'/>
            <div className='ui form'>
                <EventsSimpleTable
                    events={data.events}
                />
            </div>
            <Form />
        </div>
    );
};

SetCoverageModalContent.propTypes = {
    data: PropTypes.object,
    queryForRefetch: PropTypes.string,
    modelObjectExtension: PropTypes.object,
    products: PropTypes.array,
};

export default SetCoverageModalContent;
