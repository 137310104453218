import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';
import Authorization from '@appComponents/Authorization';
import {IconCheckmark, IconDownload, IconEdit} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import {renderProductColumnForComputerVision} from '@utils/tableHelpers';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import {getDuration, getResolution} from '@utils/stream';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';

import columns from '../constants/eventStreamsTableColumns';


const authorizationStreamObject = {
    [RESOURCES.STREAM]: CONST.SECURITY_PRIVILEGES_READ,
};

const authorizationLicensorObject = {
    [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_READ,
};

const authorizationEncodingTargetObject = {
    [RESOURCES.ENCODING_TARGET]: CONST.SECURITY_PRIVILEGES_READ,
};


const EventStreamsTable = (props) => {
    const renderDownloadIcon = (row) => {
        const distributionTypeId = convertToInt(row.target.distribution.distribution_type.id),
            productId = convertToInt(row.target.distribution.product.id);

        if (CONST.DISTRIBUTION_TYPE_RECORDING === distributionTypeId
            && CONST.PRODUCT_SPOTT !== productId
            && null !== row.stream_status
            && CONST.STREAM_STATUS_UPLOADED === convertToInt(row.stream_status.id)
            && null !== row.stream_url
        ) {
            return (
                <a href={row.stream_url} rel='noopener noreferrer' target='_blank'>
                    <IconDownload size='large' />
                </a>
            );
        }

        return null;
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id' :
                return (
                    <LinkWithAuthorization
                        authorization={authorizationStreamObject}
                        link={{
                            name: 'events.streams.edit',
                            params: {id: props.eventId, streamId: parseInt(row.id, 10)},
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'target_id' :
                return (
                    <LinkWithAuthorization
                        authorization={authorizationEncodingTargetObject}
                        link={{
                            name: 'events.configuration.content.target.edit',
                            params: {
                                id: props.eventId,
                                contentId: row.target.distribution.event_content.id,
                                targetId: parseInt(row.target.id, 10),
                            },
                        }}
                    >
                        {row.target.id}
                    </LinkWithAuthorization>
                );
            case 'content' : {
                return <EventContentVariantPopupWithName eventContent={row.target.distribution.event_content}/>;
            }
            case 'product' :
                return renderProductColumnForComputerVision(
                    row.target.distribution.product.short_name,
                    row.target.is_computer_vision
                );
            case 'distribution_type' :
                return (
                    row.target.distribution.distribution_type.name
                );
            case 'stream_delay_type' :
                return (
                    row.target.stream_delay_type ? row.target.stream_delay_type.name : ''
                );
            case 'has_playback_authentication':
                return row.target.has_playback_authentication ? <IconCheckmark/> : '';
            case 'frame_rate':
                return row.frame_rate;
            case 'resolution':
                return getResolution(row);
            case 'duration':
                return getDuration(row.duration);
            case 'actions' :
                return (
                    <div className='actions-list-icon'>
                        <Authorization
                            authorization={authorizationLicensorObject}
                        >
                            <IconEdit size='large' link={
                                getLink('events.streams.edit', {id: props.eventId, streamId: convertToInt(row.id)})
                            }
                            resource={RESOURCES.STREAM}/>
                        </Authorization>
                        <Authorization
                            authorization={authorizationStreamObject}
                        >
                            {renderDownloadIcon(row)}
                        </Authorization>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className='event_configuration_target__table'>
            <Table
                className='mini'
                name='eventStreamsList'
                columns={columns}
                data={props.streams}
                loading={props.loading}
                noDataText='No streams found'
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

EventStreamsTable.propTypes = {
    eventId: PropTypes.number,
    streams: PropTypes.array,
    loading: PropTypes.bool,
};
EventStreamsTable.defaultProps = {
    data: {
        loading: false,
        streams: [],
    },
};

export default EventStreamsTable;
