import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const entities = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENTITY_STORE: {
            return Object.assign({}, state, {[action.data.name]: action.data.data});
        }

        case actionTypes.ENTITY_REMOVE: {
            state = Object.assign({}, state);

            delete state[action.name];

            return state
        }

        default:
            return state
    }
};

export default entities;
