export const MESSAGE_BOX_USERS_NAME = 'usersMessage';
export const MESSAGE_BOX_USERS_AFTER_ADD_NAME = 'usersMessageAfterAdd';
export const MESSAGE_BOX_USERS_AFTER_ADD_ERROR_NAME = 'usersMessageAfterAddError';

export const MESSAGE_BOX_USERS_AFTER_EDIT_NAME = 'usersMessageAfterEdit';
export const MESSAGE_BOX_USERS_AFTER_EDIT_ERROR_NAME = 'usersMessageAfterEditError';

export const MESSAGE_BOX_USERS_EDIT_WARNING_NAME = 'usersMessageEditWarning';

export const MESSAGE_BOX_USERS_AFTER_GRANT_NAME = 'usersMessageAfterGrant';
export const MESSAGE_BOX_USERS_AFTER_GRANT_ERROR_NAME = 'usersMessageAfterGrantError';

export const MESSAGE_BOX_USERS_IAM_STATUS_NAME = 'usersMessageIamStatus';
export const MESSAGE_BOX_USERS_IAM_STATUS_ERROR_NAME = 'usersMessageIamStatusError';
