import {has as _has, isUndefined as _isUndefined} from "lodash";
import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {gql, graphql, withApollo} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {Header, Modal, Segment} from "semantic-ui-react";

import ProductThirdPartyStreamNamesIndexButtons from "../components/ProductThirdPartyStreamNamesIndexButtons";
import ProductThirdPartyStreamNamesPushTable from "../components/ProductThirdPartyStreamNamesPushTable";
import HeaderRenderer from "../../app/components/HeaderRenderer";
import {getLink} from "../../app/components/Link";

import ProductThirdPartyStreamNamesPushForm from "../forms/ProductThirdPartyStreamNamesPushForm";

import {convertToInt} from "../../../utils/helpers";
import {exportExcel} from "../utils/export/productThirdPartyStreamNamesIndexExcel";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {routes} from "../../app/constants/routes";
import {PUSH} from "../../app/constants/variables";
import {sortByTextValue} from "../../../utils/sorters";

import * as thirdPartyStreamNamesPushGraphQl from "../../../graphql/clients/thirdPartyStreamNamesPush";

export class ProductThirdPartyStreamNamesPushIndex extends React.Component {
    static propTypes = {
        cdns: PropTypes.array.isRequired,
        client: PropTypes.object.isRequired,
        DataProductThirdPartyStreamNamesPush: PropTypes.object.isRequired,
        DeleteProductThirdPartyStreamNamesPush: PropTypes.func,
        loading: PropTypes.bool,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {
            loadingModal: false,
            modalOpenedOnPageLoad: false,
            productThirdPartyStreamNamesPush: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataProductThirdPartyStreamNamesPush: {thirdPartyClientStreamNamesPush},
        } = nextProps;

        let nextState = {};

        if (thirdPartyClientStreamNamesPush &&
            thirdPartyClientStreamNamesPush !== this.state.productThirdPartyStreamNamesPush
        ) {
            nextState.productThirdPartyStreamNamesPush = sortByTextValue(thirdPartyClientStreamNamesPush, 'stream_name');
        }

        this.setState(nextState, () => {
            if (this.state.modalOpenedOnPageLoad) {
                return false;
            }

            const streamNamesPushRoutes = routes.clients.products.thirdParty.streamNamesPush;

            if (nextProps.match.path === streamNamesPushRoutes.add.path
                && !nextProps.DataProductThirdPartyStreamNamesPush.loading
            ) {
                this.openModal({});
            } else if (nextProps.match.path === streamNamesPushRoutes.edit.path
                && !nextProps.DataProductThirdPartyStreamNamesPush.loading
            ) {
                const streamNamesPush = thirdPartyClientStreamNamesPush.filter(
                    item => parseInt(item.id, 10) === parseInt(nextProps.match.params.thirdPartyClientPushStreamNameId, 10)
                ).pop();

                if (!_isUndefined(streamNamesPush)) {
                    this.openModal(streamNamesPush);
                } else {
                    nextProps.Modal.setModal({
                        isVisible : true,
                        content : (
                            <div>
                                <Header icon="warning" color="red" content="Stream name was not found"/>
                                <Modal.Content>
                                    <p>Stream name was not found</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Link to={getLink("clients.products.thirdParty.streamNamesPush.index", {
                                        id: nextProps.match.params.id,
                                    })}>
                                        Back to stream names push
                                    </Link>
                                </Modal.Actions>
                            </div>
                        )
                    });
                }
            }
        });
    }

    resetIndexUrl = () => {
        window.history.pushState({}, '', getLink('clients.products.thirdParty.streamNamesPush.index', {
            id: this.props.match.params.id,
        }));
    };

    queryDataForModal = (id) => {
        let modalLink = getLink('clients.products.thirdParty.streamNamesPush.edit', {
            id: this.props.match.params.id,
            thirdPartyClientPushStreamNameId: id,
        });

        this.setState(() => ({
            loadingModal: true,
        }));

        this.props.client.query({
            query: ProductThirdPartyStreamNamePushQuery,
            variables: {
                id: id,
            },
        }).then((result) => {
            this.setState(() => ({
                loadingModal: false,
            }));

            this.openModal(result.data.thirdPartyClientStreamNamePush, modalLink);
        });
    };

    prepareDataForModal = (id) => {
        this.setState(() => ({
            modalOpenedOnPageLoad: true,
        }));

        let modalLink = getLink('clients.products.thirdParty.streamNamesPush.add', {
            id: this.props.match.params.id,
        });

        if (!isNaN(id)) {
            this.queryDataForModal(id);
        } else if (_has(id, 'id')) {
            this.queryDataForModal(id.id);
        } else {
            this.openModal({}, modalLink);
        }
    };

    openModal = (data, modalLink) => {
        const {
            Modal: {setModal},
        } = this.props;

        window.history.pushState({}, '', modalLink);

        setModal({
            content: <ProductThirdPartyStreamNamesPushForm
                formData={{
                    cdns: this.props.cdns.map((cdn) => ({
                        key: cdn.id,
                        text: cdn.name,
                        value: cdn.id,
                    })),
                    thirdPartyStreamName: data,
                }}
                resetIndexUrl={this.resetIndexUrl}
            />,
            isVisible: true,
            onClose: this.resetIndexUrl,
            size: "small",
        });
    };

    render() {
        return (
            <Segment basic loading={this.state.loadingModal}>
                <HeaderRenderer
                    buttons={ProductThirdPartyStreamNamesIndexButtons}
                    buttonsProps={{
                        prepareDataForModal: this.prepareDataForModal,
                        type: PUSH,
                    }}
                    exportExcelParams={exportExcel(
                        this.state.productThirdPartyStreamNamesPush,
                        this.props.match.params.id,
                        PUSH
                    )}
                    loading={this.props.DataProductThirdPartyStreamNamesPush.loading}
                />
                <ProductThirdPartyStreamNamesPushTable
                    editClick={this.prepareDataForModal}
                    loading={this.props.DataProductThirdPartyStreamNamesPush.loading}
                    productThirdPartyStreamNamesPush={this.state.productThirdPartyStreamNamesPush}
                />
            </Segment>
        );
    }
}

const ProductThirdPartyStreamNamesPushQuery = gql(thirdPartyStreamNamesPushGraphQl.thirdPartyStreamNamesPushIndex);
const ProductThirdPartyStreamNamePushQuery = gql(thirdPartyStreamNamesPushGraphQl.thirdPartyStreamNamePush);

const ProductThirdPartyStreamNamesPushWithGraphQL = compose(
    graphql(ProductThirdPartyStreamNamesPushQuery, {
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    thirdPartyClientCdn: props.cdns.map((cdn) => convertToInt(cdn.id)),
                }
            }
        },
        name: "DataProductThirdPartyStreamNamesPush",
    }),
)(ProductThirdPartyStreamNamesPushIndex);

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal']),
    mapStateToProps = (state) => {
        return {
            cdns: state.app.entities.thirdPartyClientCdns.cdns,
        };
    };

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withApollo(ProductThirdPartyStreamNamesPushWithGraphQL))
);
