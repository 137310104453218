import {get as _get, uniq as _uniq} from 'lodash';
import PropTypes from 'prop-types';

import {getSortingTerritories} from '@utils/countryHelpers';
import {convertToInt} from '@utils/helpers';
import {PROPERTY_LICENCE_TYPE_SPORTRADAR} from '@constants/variables';

export const exportExcel = (dataExport, propertyLicenceId, propertyLicenceTypeId) => {
    const getContents = (contents) => (
        (PROPERTY_LICENCE_TYPE_SPORTRADAR === convertToInt(propertyLicenceTypeId))
            ? contents.map(content => `${content.tournament.tournament_category.name} > ${content.tournament.name} (${content.content_tier.name})`).join(', ')
            : contents.map(content => `${content.content_category.parent.name} > ${content.content_category.name} (${content.content_tier.name})`).join(', ')
    );

    const getProducts = (usages) => (_uniq(usages.map(usage => usage.product.short_name)).join(', '));

    return {
        dataExport: dataExport,
        filename: `property_licence_${propertyLicenceId}_rights_scopes`,
        parseData: rightsScope => [
            rightsScope.id,
            rightsScope.licensee.name,
            rightsScope.includes_all_content ? 'All' : getContents(rightsScope.contents),
            getProducts(rightsScope.usages),
            rightsScope.start_date,
            rightsScope.end_date,
            getSortingTerritories(rightsScope),
            _get(rightsScope, 'rights_scope_exclusivity.name', ''),
            _get(rightsScope, 'rights_scope_sub_licensing.name', ''),
            0 < rightsScope.rights_scope_stream_constraints.length ? 'Yes' : 'No',
        ],
        path: 'propertyLicences.rightsScopes.index.path',
        titles: [
            'ID',
            'Licensee',
            'Content',
            'Products',
            'From',
            'To',
            'Territories',
            'Exclusivity',
            'Sub-licensing',
            'Stream constraints?',
        ],
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
