import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.rtmp_origin_stream_names, 'rtmp_origin_stream_names'));

    return errors;
};

export default validate;
