import PropTypes from 'prop-types';

import {getProperty} from "../common";

/**
 * Display value for 2nd level category
 */
export const secondLevelCategory = (event) => {
    const eventData = {
        data: event,
        nonSr: ['content_category', 'parent']
    };

    return getProperty(eventData);
};

secondLevelCategory.propTypes = {
    event: PropTypes.object.isRequired
}
