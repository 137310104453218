import React from 'react';

import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {RTMP_ORIGIN_STREAM_NAME} from '@constants/resources';
import {GetRtmpOriginStreamNameForForm} from '@graphql/rtmpOriginStreamName/query';
import {
    CreateRtmpOriginStreamName,
    DeleteRtmpOriginStreamName,
    UpdateRtmpOriginStreamName,
} from '@graphql/rtmpOriginStreamName/mutation';
import {IconInfoCircle} from '@appComponents/IconCollection';

import validate from './validatorRtmpOriginStreamName';

export default function RtmpOriginStreamNameModel() {
    return {
        entityDataMapKey: "rtmpOriginStreamName",
        entityLabel: "RTMP origin stream name",
        formName: "rtmp_origin_stream_name",
        label: "RTMP origin stream name",
        resources: RTMP_ORIGIN_STREAM_NAME,
        showChangelog: true,
        title: () =>  "Add new RTMP origin stream name",
        validate: validate,
        dataMap: {
            rtmpOriginStreamName: {
                id: "",
                name: "",
                preview_url: "",
            },
        },
        messages: {
            boxName: {
                delete: "productionCompany",
                error: "productionCompany",
                success: "productionCompany",
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('RTMP origin stream name')
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "rtmpOriginStreamName.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: 'rtmpOriginStreamName.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                    autoFocus: true,
                    description: <IconInfoCircle
                        tooltip='Placeholder [MATCH_ID] will be replaced by the Sportradar match ID.'/>,
                },
            },
            preview_url: {
                dataMapKey: "rtmpOriginStreamName.preview_url",
                props: {
                    component: Form.SemanticInput,
                    label: "Preview URL",
                    name: "preview_url",
                    type: "input",
                },
            },
        },
        mutation: {
            createEntity: CreateRtmpOriginStreamName,
            deleteEntity: DeleteRtmpOriginStreamName,
            updateEntity: UpdateRtmpOriginStreamName,
        },
        query: {
            getEntity: GetRtmpOriginStreamNameForForm,
        },
    }
}
