import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {convertToInt} from '@utils/helpers';
import {IconCheckmark} from '@appComponents/IconCollection';

import {CENTERED_HEADLINE_AND_BODY} from '../utils/utils';

const cityTableColumns = {
    id: {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    name: {
        label: 'Name',
        sorting: (row) => row.name.toLocaleLowerCase(),
    },
    full_name: {
        label: 'Full Name',
        sorting: (row) => row.full_name.toLocaleLowerCase(),
    },
    iso_alpha2_code: {
        label: 'ISO alpha-2 code',
        sorting: (row) => row.iso_alpha2_code.toUpperCase(),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    iso_alpha3_code: {
        label: 'ISO alpha-3 code',
        sorting: (row) => row.iso_alpha3_code.toUpperCase(),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    iso_numeric_code: {
        label: 'ISO numeric code',
        sorting: (row) => convertToInt(row.iso_numeric_code),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    continent_id: {
        label: 'Continent',
        sorting: (row) => (row.continent && row.continent.name) ? row.continent.name.toLowerCase() : '',
    },
    handle_geo_restrictions_on_subdivision_level: {
        label: 'Subdivision support',
        sorting: (row) => Number(row.handle_geo_restrictions_on_subdivision_level),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    country_subdivisions: {
        label: 'Subdivisions',
        sorting: (row) => row.country_subdivisions.length,
        ...CENTERED_HEADLINE_AND_BODY,
    },
};

const CountryTable = ({data, loading}) => {
    const authorizationObject = {
        [RESOURCES.COUNTRY]: CONST.SECURITY_PRIVILEGES_READ,
    };

    const renderLink = (link, id, content) => (
        <LinkWithAuthorization
            authorization={authorizationObject}
            link={{
                name: link,
                params: {id: id},
            }}
        >
            {content}
        </LinkWithAuthorization>
    );

    const renderSingleRow = (column, row) => {
        switch (column) {
            case 'id':
                return renderLink('countries.generalInformation', row.id, row.id);
            case 'name':
                return renderLink('countries.generalInformation', row.id, row.name);
            case 'full_name':
                return row.full_name;
            case 'iso_alpha2_code':
                return row.iso_alpha2_code;
            case 'iso_alpha3_code':
                return row.iso_alpha3_code;
            case 'iso_numeric_code':
                return row.iso_numeric_code;
            case 'continent_id':
                return _get(row, 'continent.name', '');
            case 'handle_geo_restrictions_on_subdivision_level':
                return row.handle_geo_restrictions_on_subdivision_level ? <IconCheckmark/> : '';
            case 'country_subdivisions':
                return row.country_subdivisions.length
                    ? renderLink('countries.subdivisions', row.id, row.country_subdivisions.length)
                    : '';
            default:
                return '';
        }
    };

    return (
        <div className='cities__table'>
            <Table
                name='Countries'
                columns={cityTableColumns}
                data={data}
                defaultSort={'name'}
                loading={loading}
                noDataText='No countries found'
                rowRenderer={renderSingleRow}
                pagination={false}
            />
        </div>
    );

};

CountryTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

export default CountryTable;
