import {
    get as _get,
    isEmpty as _isEmpty
} from "lodash";

import validators from '../../../utils/validators';

const validate = (values, props) => {
    const errors = {};

    if ("MasterBookingGeneralInformation" === props.Model.formName) {
        Object.assign(errors, validators.required(values.client, 'client'));
        Object.assign(errors, validators.required(values.product, 'product'));
    } else if ("MasterBookingGeoRestriction" === props.Model.formName) {
        const deviceCategories = ['desktop', 'mobile', 'smart_tv'],
            requiredSubdivisionCountries = _get(props.GraphQLOptionsData, 'countries', []);

        Object.assign(errors, validators.requiredAtLeastOneSwitch(
            [values.desktop_switch, values.mobile_switch, values.smart_tv_switch],
            'geo_restrictions_error_content')
        );

        if (values.desktop_switch) {
            Object.assign(errors, validators.required(values.desktop_countries, 'desktop_countries'));
        }

        if (values.mobile_switch) {
            Object.assign(errors, validators.required(values.mobile_countries, 'mobile_countries'));
        }

        if (values.smart_tv_switch) {
            Object.assign(errors, validators.required(values.smart_tv_countries, 'smart_tv_countries'));
        }

        deviceCategories.forEach((deviceCategory) => {
            if (!_isEmpty(requiredSubdivisionCountries)
                && _get(values, `${deviceCategory}_countries`, []).length
            ) {
                Object.assign(errors, validators.requiredAtLeastOneSubdivisionPerCountry({
                    countries: values[`${deviceCategory}_countries`],
                    subdivisions: values[`${deviceCategory}_subdivisions`],
                    required: requiredSubdivisionCountries.map((country) => {
                        return country.value;
                    })
                }, `${deviceCategory}_subdivisions`));
            }
        });
    }

    return errors;
};

export default validate;
