import React from "react";
import {
    differenceWith as _differenceWith,
    find as _find,
    get as _get,
    isEmpty as _isEmpty,
    isEqual as _isEqual
} from "lodash";

import DefaultForm from "../../../app/components/DefaultForm";
import {getLink} from "../../../app/components/Link";

import GeoRestrictionsForm from "./GeoRestrictionsForm";
import GeoRestrictionsModel from "./GeoRestrictionsModel";

import {convertToInt, getIdsFromChildren, prepareClientPackageUsages} from "../../../../utils/helpers";
import {createForm, getFormMessageForWizard} from "../../../../utils/forms";

import {WIZARD_TABS_VALIDATION_TEXT} from "../../../app/constants/messages";

export class GeoRestrictionsGeneralInformationForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        return Object.assign({}, data, {
            client_package: convertToInt(this.props.formParams.optionsVariables.client_package),
            notes: data.notes || "",
            usages: prepareClientPackageUsages(data.usages),
        });
    };

    onFormSubmit = (data) => {
        if (!this.props.Model.editForm) {
            this.setState(() => ({
                defaultForm_errorShowed: false
            }));

            let Form = createForm(
                GeoRestrictionsModel,
                GeoRestrictionsForm,
                {
                    id: data.id,
                    optionsVariables: {
                        client_package: this.props.formParams.optionsVariables.client_package,
                    }
                });

            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
            this.props.stepsMethods.setStep({
                id: "step2",
                menuItem: {disabled: false},
                pane: {content: <Form previousStepData={data}/>}
            });
            this.props.stepsMethods.showStep("step2");
        } else {
            if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
                this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                    _get(this.props, "Model.label", _get(this.props, "Model.formName", ""))
                        .toLocaleLowerCase()
                ));
            } else {
                const step2Data = this.props.stepsMethods.getForms()["step2"].formValues;

                return super.onFormSubmit(Object.assign({}, data, {
                    territory_selection_type: convertToInt(step2Data.selection_type),
                    countries: getIdsFromChildren(step2Data.countries),
                    country_subdivisions: getIdsFromChildren(step2Data.subdivisions),
                }));
            }
        }
    };

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {
            GraphQLOptionsData: {
                clientPackageGeoRestrictions,
                clientProductsUsages,
            },
        } = nextProps;
        const basicUsageTreeOptions = {
                clientProductUsages: clientProductsUsages,
                geoRestrictionsUsages: clientPackageGeoRestrictions || [],
                selected: [],
            },
            restriction = _get(nextProps, 'GraphQLEntityData.clientPackageGeoRestriction', {});

        if (!this.state.usageOptionsTreeGenerated && clientProductsUsages) {
            this.setUsages(this.getUsageTreeOptions(basicUsageTreeOptions));
            this.setState(() => ({usageOptionsTreeGenerated: true}));
        }

        if (!this.state.entityUsageOptionsTreeGenerated && clientProductsUsages && !_isEmpty(restriction.usages)) {
            this.setUsages(this.getUsageTreeOptions(Object.assign(
                basicUsageTreeOptions,
                {selected: restriction.usages}
            )));
            this.setState(() => ({entityUsageOptionsTreeGenerated: true}));
        }

        this.props.stepsMethods.setForm("step1", nextProps);
    }

    getUsageTreeOptions(data) {
        let clientProductUsages = [],
            currentUsages = [],
            selectedOptions = {},
            usagesInUse = [];

        if (!_isEmpty(data.selected)) {
            data.selected.forEach((option) => {
                if (option) {
                    selectedOptions[
                        `device_category-${option.device_category.id}.way_of_transmissions-${option.way_of_transmission.id}`
                    ] = option.id;
                }
            });
        }

        if (!_isEmpty(data.clientProductUsages)) {
            this.prepareUsages(data.clientProductUsages, clientProductUsages);
        }

        if (!_isEmpty(data.geoRestrictionsUsages)) {
            data.geoRestrictionsUsages.map((geoRestriction) => {
                this.prepareUsages(geoRestriction.usages, usagesInUse);
            });
        }

        if (!_isEmpty(data.selected)) {
            this.prepareUsages(data.selected, currentUsages);

            usagesInUse = usagesInUse.map((deviceCategory) => {
                let deviceCategoryInUse = _find(currentUsages, {id: deviceCategory.id});

                if (deviceCategoryInUse) {
                    deviceCategoryInUse.way_of_transmissions = _differenceWith(
                        deviceCategory.way_of_transmissions,
                        deviceCategoryInUse.way_of_transmissions,
                        _isEqual
                    );

                    return deviceCategoryInUse;
                }

                return deviceCategory;
            });
        }

        return clientProductUsages.filter((deviceCategory) => {
            let deviceCategoryInUse = _find(usagesInUse, {id: deviceCategory.id});

            return (!(deviceCategoryInUse
                && deviceCategoryInUse.way_of_transmissions.length === deviceCategory.way_of_transmissions.length));
        }).map((deviceCategory) => {
            let deviceCategoryInUse = _find(usagesInUse, {id: deviceCategory.id});

            return {
                children: deviceCategory.way_of_transmissions.filter((wayOfTransmission) => {
                    return (deviceCategoryInUse)
                        ? !_find(deviceCategoryInUse.way_of_transmissions, {id: wayOfTransmission.id})
                        : true;
                }).map((wayOfTransmission) => {
                    let id = _get(selectedOptions,
                        `device_category-${deviceCategory.id}.way_of_transmissions-${wayOfTransmission.id}`,
                        null
                    );

                    return {
                        id: id,
                        label: wayOfTransmission.name,
                        name: "way_of_transmissions",
                        selected: null !== id,
                        value: wayOfTransmission.id
                    };
                }),
                label: deviceCategory.name,
                name: "device_category",
                value: deviceCategory.id
            };
        });
    }

    prepareUsages(usages, usagesInUse) {
        usages.map((usage) => {
            let deviceCategory = _find(usagesInUse, usage.device_category);

            (deviceCategory)
                ? deviceCategory.way_of_transmissions.push(usage.way_of_transmission)
                : usagesInUse.push({
                    ...usage.device_category,
                    way_of_transmissions: [{...usage.way_of_transmission}]
                });
        });
    }

    setUsages(usages) {
        this.setField("usages", {
            options: usages,
            showNoDataText: _isEmpty(usages),
        });
    }

    renderErrors(errorData) {
        const link = getLink("clients.packages.geoRestrictions.index", {
            id: this.props.formParams.optionsVariables.client || null,
            packagesId: this.props.formParams.optionsVariables.client_package || null,
        });

        super.renderErrors(errorData, 'Client package geo restrictions', link, {size: 'tiny'});
    }

    renderSaveButton = (props) => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                ...props,
                content: "Next",
                icon: "angle double right",
                labelPosition: "right",
            });
        }

        return super.renderSaveButton();
    };
}

export default GeoRestrictionsGeneralInformationForm;
