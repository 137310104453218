import {changelogData} from "../changelogData";

const thirdPartyStreamNamesPullIndexResponseFragment = `
  id
  third_party_client_pull_origin {
    id
    name
  }
  stream_name
  description
`;

const thirdPartyStreamNamesPullFullResponseFragment = `
  ${thirdPartyStreamNamesPullIndexResponseFragment}
  ${changelogData}
`;

const thirdPartyStreamNamesPullMutationVariablesDefinition = `
  $stream_name: String!
  $description: String
  $third_party_client_pull_origin: Int!
`;

const thirdPartyStreamNamesPullMutationVariablesFragment = `
  stream_name: $stream_name
  description: $description
  third_party_client_pull_origin: $third_party_client_pull_origin
`;

export const thirdPartyStreamNamesPullIndex = `
query ThirdPartyStreamNamesPullIndex (
  $thirdPartyClientPullOrigin: [Int]
) {
  thirdPartyClientStreamNamesPull (filters: {
    thirdPartyClientPullOrigin: $thirdPartyClientPullOrigin
  }) {
    ${thirdPartyStreamNamesPullIndexResponseFragment}
  }
}`;

export const thirdPartyStreamNamePull = `query thirdPartyStreamNamePull ($id: ID!) {
    thirdPartyClientStreamNamePull(id: $id) {
      ${thirdPartyStreamNamesPullFullResponseFragment}
    }
  }
`;

export const createThirdPartyStreamNamesPull = `
mutation CreateThirdPartyStreamNamesPull (
  ${thirdPartyStreamNamesPullMutationVariablesDefinition}
) {
  createThirdPartyClientStreamNamePull (
    ${thirdPartyStreamNamesPullMutationVariablesFragment}
  ) {
    ${thirdPartyStreamNamesPullFullResponseFragment}
  }
}
`;

export const updateThirdPartyStreamNamesPull = `
mutation UpdateThirdPartyStreamNamesPull (
  $id: ID!
  ${thirdPartyStreamNamesPullMutationVariablesDefinition}
) {
  updateThirdPartyClientStreamNamePull (
    id: $id
    ${thirdPartyStreamNamesPullMutationVariablesFragment}
  ) {
    ${thirdPartyStreamNamesPullFullResponseFragment}
  }
}
`;

export const deleteThirdPartyStreamNamesPull = `
mutation DeleteThirdPartyStreamNamesPull (
  $id: ID!
) {
  deleteThirdPartyClientStreamNamePull (
    id: $id
  )
}
`;
