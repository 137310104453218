import {isEmpty as _isEmpty} from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {Segment} from "semantic-ui-react";

import TechnicalSetupProductsModel from "../forms/TechnicalSetupProductsModel";
import {TechnicalSetupProductsForm} from "../forms/TechnicalSetupProductsForm";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";
import useQuery from "../../../utils/hooks/useQuery";

import {GetProductsWithStatuses} from "../../../graphql/product/query.graphql";

export const TechnicalSetupProducts = ({propertyLicenceId, technicalSetupId}) => {
    const {
        data = {},
    } = useQuery({
        query: GetProductsWithStatuses,
        options: {
            variables: {id: propertyLicenceId}
        }
    });

    const renderForm = () => {
        if (_isEmpty(data)) {
            return <Segment basic loading={true}/>;
        }

        const model = TechnicalSetupProductsModel(data, technicalSetupId);

        const Form = createForm(
            () => model,
            TechnicalSetupProductsForm,
            {id: convertToInt(technicalSetupId)}
        )

        return <Form/>;
    }

    return renderForm();
};
TechnicalSetupProducts.propTypes = {
    propertyLicenceId: PropTypes.number.isRequired,
    technicalSetupId: PropTypes.number.isRequired,
};
