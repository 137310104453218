import {connect} from "react-redux";
import {withRouter} from "react-router";

import ClientProductForm from "./ClientProductForm";
import {getLink} from "../../app/components/Link";

import {deleteSuccessCallback, setClientProductsEntity} from "../utils/clientProducts";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {PRODUCT_THIRD_PARTY} from "../../app/constants/variables";

class ClientProductThirdPartyForm extends ClientProductForm {
    componentDidMount() {
        super.componentDidMount();

        const entityName = "client",
            params = {
                clientData: this.props.clientData,
                entityName,
                setEntity: this.props.Entity.setEntity,
            };

        this.setCreateSuccessCallback((response) => {
            const responseData = response.data.createClientProductThirdParty,
                clientProductThirdParty = Object.assign({}, responseData.client_product, {
                    third_party_client_product: {
                        id: responseData.id,
                        third_party_client_types: responseData.third_party_client_types,
                    },
                });

            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientProducts: this.props.clientData.clientProducts.concat(clientProductThirdParty),
                }),
            });

            this.props.history.push(getLink("clients.products.thirdParty.editGeoRestrictions", {
                id: this.props.match.params.id,
            }));
        });

        this.setUpdateSuccessCallback((response) => {
            const responseData = response.data.updateClientProductThirdParty,
                clientProductThirdPartyIndex = this.props.clientData.clientProducts.findIndex(clientProduct =>
                    PRODUCT_THIRD_PARTY === convertToInt(clientProduct.product.id)
                ),
                clientProducts = {};

            clientProducts[clientProductThirdPartyIndex] = Object.assign({}, responseData.client_product, {
                third_party_client_product: Object.assign({}, {
                    id: responseData.id,
                    third_party_client_types: responseData.third_party_client_types,
                }),
            });
            params.clientProducts = clientProducts;

            setClientProductsEntity(params);
        });

        this.setDeleteSuccessCallback(() => {
            params.formParamsId = this.props.formParams.id;
            params.historyPush = this.props.history.push;

            deleteSuccessCallback(params);
        });
    }

    componentDidUpdate() {
        const {
            formParams: {isAddForm},
            GraphQLEntityData: {
                clientProductsThirdParty,
                loading: entityLoading,
            } = {
                entityLoading: false,
            },
            GraphQLOptionsData: {
                thirdPartyClientTypes,
                loading: optionsLoading,
            },
        } = this.props;

        let nextProps;

        if (!optionsLoading && !entityLoading && !this.state.dataReceived) {
            if (!isAddForm) {
                nextProps = Object.assign({}, this.props, {
                    clientProduct: clientProductsThirdParty[0].client_product,
                });

                this.setField("id", {defaultValue: convertToInt(clientProductsThirdParty[0].id)});
                this.setField("third_party_client_types", {
                    defaultValue: clientProductsThirdParty[0].third_party_client_types.map(thirdPartyClientType =>
                        convertToInt(thirdPartyClientType.id)
                    ),
                    options: thirdPartyClientTypes,
                });
            }

            super.componentDidUpdate(nextProps);
        }
    }

    prepareDataForSubmit(data) {
        data = Object.assign({}, data, {product: PRODUCT_THIRD_PARTY});

        const dataToSubmit = super.prepareDataForSubmit(data);

        return Object.assign({}, dataToSubmit, {third_party_client_types: data.third_party_client_types});
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity']),
    mapStateToProps = (state) => ({clientData: state.app.entities.client || null});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientProductThirdPartyForm));
