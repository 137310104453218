import {find as _find, get as _get, isEmpty as _isEmpty, isUndefined as _isUndefined, omit as _omit} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Header, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {gql, withApollo} from 'react-apollo';
import {ENCODING_TARGET} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ_UPDATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';
import {getLink, Link} from '@appComponents/Link';
import {CircleButtonAdd} from '@appComponents/ButtonCollection';
import SegmentHeader from '@appComponents/SegmentHeader';
import {createForm} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {deleteEncodingTargetFromCdnConfiguration} from '@graphql/events/eventContentCdnConfiguration';

import EventContentCdnConfigurationModel from '../forms/EventContentCdnConfigurationModel';
import EventContentCdnConfigurationForm from '../forms/EventContentCdnConfigurationForm';
import EventsConfigurationCdnConfigurationsTable from '../components/EventsConfigurationCdnConfigurationsTable';

const authorizationEncodingTargetReadUpdateObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ_UPDATE,
};

class EventsConfigurationCdnConfigurations extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
        distributions: PropTypes.array.isRequired,
        encodingTargets: PropTypes.array.isRequired,
        eventContentId: PropTypes.number.isRequired,
        eventId: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired,
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
        propertyLicenceId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        const modalShouldOpen = (this.props.match.path === routes.events.configuration.content.cdnConfiguration.add.path);

        this.state = {
            modalShouldOpen: modalShouldOpen,
        };
    }

    componentWillReceiveProps(nextProps) {
        const urlChanged = this.props.match.path !== nextProps.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.events.configuration.index.path,
            shouldOpenModal = (urlChanged || this.state.modalShouldOpen) && urlIsNotIndex;

        shouldOpenModal && this.loadModal(nextProps);

        const modalChanged = nextProps.Modal.isVisible !== this.props.Modal.isVisible,
            modalIsNotVisible = !nextProps.Modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(
            getLink('events.configuration.index', {id: this.props.match.params.id})
        );
    }

    parseCdnConfigurations = (distributions, encodingTargets) => {
        if (_isEmpty(distributions) || _isEmpty(encodingTargets)) {
            return [];
        }

        const parsed = [];
        const clientCdnConfigurations = [];

        encodingTargets.map((target) => {
            target.client_cdn_configurations.map((cdnConfiguration) => {
                const existingCdnConfiguration = _find(clientCdnConfigurations, {'id': cdnConfiguration.id});

                if (!_isUndefined(existingCdnConfiguration)) {
                    existingCdnConfiguration.encoding_targets.push(_omit(target, 'client_cdn_configurations'));
                } else {
                    clientCdnConfigurations.push({
                        ...cdnConfiguration,
                        encoding_targets: [_omit(target, 'client_cdn_configurations')],
                    });
                }
            });
        });

        clientCdnConfigurations.forEach((cdnConfiguration) => {
            const targets = Object.assign([], cdnConfiguration.encoding_targets);
            let lastDistributionType = null;

            targets.sort((a, b) => (
                a.distribution.distribution_type.name > b.distribution.distribution_type.name ? 1 : -1
            )).map((target) => {
                if (distributions.find((distribution) => (distribution.id === target.distribution.id))) {
                    const rowSpanClient = targets.filter((element) => (
                        distributions.find((distribution) => (distribution.id === element.distribution.id))
                    )).length;

                    const rowSpanDistributionType = targets.filter((element) => (
                        convertToInt(element.distribution.distribution_type.id) === convertToInt(target.distribution.distribution_type.id) &&
                        distributions.find((distribution) => (distribution.id === element.distribution.id))
                    )).length;

                    const liveIngestMethodPath = 'live_ingest_method.name';
                    const recordingIngestMethodPath = 'recording_ingest_method.name';
                    const akamaiCdnIngestMethodPath = 'akamai_cdn_ingest_method.name';

                    if (null === lastDistributionType) {
                        parsed.push({
                            id: {value: `${cdnConfiguration.id}_${target.distribution.distribution_type.id}_${target.id}`},
                            cdnConfigurationName: {rowSpan: rowSpanClient, value: cdnConfiguration.name},
                            clientName: {rowSpan: rowSpanClient, value: cdnConfiguration.client_product.client.name},
                            productName: {
                                rowSpan: rowSpanClient,
                                value: cdnConfiguration.client_product.product.short_name,
                            },
                            distributionTypeName: {
                                rowSpan: rowSpanDistributionType,
                                value: target.distribution.distribution_type.name,
                            },
                            encodingTargetId: {value: convertToInt(target.id)},
                            cdnName: {rowSpan: rowSpanClient, value: cdnConfiguration.cdn.name},
                            liveIngestMethod: {
                                rowSpan: rowSpanClient,
                                value: _get(cdnConfiguration, liveIngestMethodPath, null),
                            },
                            recordingIngestMethod: {
                                rowSpan: rowSpanClient,
                                value: _get(cdnConfiguration, recordingIngestMethodPath, null),
                            },
                            akamaiCdnIngestMethodName: {
                                rowSpan: rowSpanClient,
                                value: _get(cdnConfiguration, akamaiCdnIngestMethodPath, null),
                            },
                        });

                        lastDistributionType = convertToInt(target.distribution.distribution_type.id);
                    } else if (convertToInt(target.distribution.distribution_type.id) !== lastDistributionType) {
                        parsed.push({
                            id: {value: `${cdnConfiguration.id}_${target.distribution.distribution_type.id}_${target.id}`},
                            cdnConfigurationName: {hidden: true, value: cdnConfiguration.name},
                            clientName: {hidden: true, value: cdnConfiguration.client_product.client.name},
                            productName: {hidden: true, value: cdnConfiguration.client_product.product.short_name},
                            distributionTypeName: {
                                rowSpan: rowSpanDistributionType,
                                value: target.distribution.distribution_type.name,
                            },
                            encodingTargetId: {value: convertToInt(target.id)},
                            cdnName: {hidden: true, value: cdnConfiguration.cdn.name},
                            liveIngestMethod: {
                                hidden: true,
                                value: _get(cdnConfiguration, liveIngestMethodPath, null),
                            },
                            recordingIngestMethod: {
                                hidden: true,
                                value: _get(cdnConfiguration, recordingIngestMethodPath, null),
                            },
                            akamaiCdnIngestMethodName: {
                                hidden: true,
                                value: _get(cdnConfiguration, akamaiCdnIngestMethodPath, null),
                            },
                        });

                        lastDistributionType = convertToInt(target.distribution.distribution_type.id);
                    } else {
                        parsed.push({
                            id: {value: `${cdnConfiguration.id}_${target.distribution.distribution_type.id}_${target.id}`},
                            cdnConfigurationName: {hidden: true, value: cdnConfiguration.name},
                            clientName: {hidden: true, value: cdnConfiguration.client_product.client.name},
                            productName: {hidden: true, value: cdnConfiguration.client_product.product.short_name},
                            distributionTypeName: {hidden: true, value: target.distribution.distribution_type.name},
                            encodingTargetId: {value: convertToInt(target.id)},
                            cdnName: {hidden: true, value: cdnConfiguration.cdn.name},
                            liveIngestMethod: {
                                hidden: true,
                                value: _get(cdnConfiguration, liveIngestMethodPath, null),
                            },
                            recordingIngestMethod: {
                                hidden: true,
                                value: _get(cdnConfiguration, recordingIngestMethodPath, null),
                            },
                            akamaiCdnIngestMethodName: {
                                hidden: true,
                                value: _get(cdnConfiguration, akamaiCdnIngestMethodPath, null),
                            },
                        });
                    }
                }
            });
        });

        return parsed;
    };

    renderAddButton = () => {
        return (
            <Authorization authorization={authorizationEncodingTargetReadUpdateObject}>
                <Link
                    name='events.configuration.content.cdnConfiguration.add'
                    params={{
                        id: this.props.match.params.id,
                        contentId: this.props.eventContentId,
                    }}>
                    <CircleButtonAdd/>
                </Link>
            </Authorization>
        );
    };

    loadModal(props) {
        if (_isUndefined(props)) {
            props = this.props;
        }

        if (
            routes.events.configuration.content.cdnConfiguration.add.path === props.match.path
            && convertToInt(props.match.params.contentId) === props.eventContentId
            && isUrlParamValid(props.match.params.id)
            && isUrlParamValid(props.match.params.contentId)
        ) {
            this.setState(() => ({
                modalShouldOpen: false,
            }));

            return this.loadModalForm(createForm(
                EventContentCdnConfigurationModel,
                EventContentCdnConfigurationForm,
                {
                    optionsVariables: {
                        eventId: convertToInt(props.match.params.id),
                        eventContent: props.eventContentId,
                        eventContentId: props.eventContentId,
                        propertyLicenceId: props.propertyLicenceId,
                    },
                    isAddForm: true,
                    distributions: props.distributions,
                    encodingTargets: props.encodingTargets,
                }
            ));
        }

        return null;
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            header: false,
            isVisible: true,
            content: <Form
                formData={data}
                onSaveCallback={this.onSaveCallback}
            />,
        });
    };

    handleDeleteCdnConfiguration = (data) => {
        const ids = data.id.value.split('_');

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={'Remove CDN configuration'}/>,
            text: 'Are you sure you want to remove this CDN configuration for this target?',
            onYes: () => (this.deleteCdnConfiguration(ids[0], ids[2])),
        });
    };

    deleteCdnConfiguration = (cdnConfigurationId, encodingTargetId) => {
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: 'Removing the CDN configuration for the given target...'
        });

        const cdnConfigurationIds = this.props.encodingTargets.find((target) => (
            convertToInt(target.id) === convertToInt(encodingTargetId)
        )).client_cdn_configurations.filter((cdnConfiguration) => (
            convertToInt(cdnConfiguration.id) !== convertToInt(cdnConfigurationId)
        )).map((cdnConfiguration) => (convertToInt(cdnConfiguration.id)));

        this.props.client.mutate({
            mutation: gql(deleteEncodingTargetFromCdnConfiguration),
            refetchQueries: ['GetEncodingTargetsForDistributions', 'GetEventContentEncodingConfigurationChanged'],
            variables: {
                id: convertToInt(encodingTargetId),
                client_cdn_configurations: cdnConfigurationIds,
            },
        }).then(() => {
            setModal({isVisible: false});
            addMessage(
                'eventsConfiguration',
                'The CDN configuration has been removed successfully for the given target.',
                null,
                'success'
            );
        }).catch((error) => {
            setModal({isVisible: false});
            addMessage(
                'eventsConfiguration',
                'The CDN configuration could not be removed for the given target.',
                `${error}`,
                'error'
            );
        });
    };

    render() {
        return (
            <div>
                <SegmentHeader buttons={this.renderAddButton()}> Client CDN configurations </SegmentHeader>
                <Segment basic className="--table">
                    <EventsConfigurationCdnConfigurationsTable
                        eventContentId={this.props.eventContentId}
                        eventId={this.props.eventId}
                        loading={this.props.loading}
                        cdnConfigurations={this.parseCdnConfigurations(
                            this.props.distributions,
                            this.props.encodingTargets
                        )}
                        onDelete={this.handleDeleteCdnConfiguration}
                    />
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        propertyLicenceId: convertToInt(state.app.entities.event.property_licence.id),
    });
};

export default withRouter(connect(mapStateToProps)(withApollo(EventsConfigurationCdnConfigurations)));
