import React from 'react';
import PropTypes from 'prop-types';

import Link from "../../../../../../app/components/Link";

/**
 * Display value for "Property licence" field
 */
export const PropertyLicence = ({event}) => (
    <Link
        name="propertyLicences.edit"
        params={{id: event.property_licence.id}}
    >
        {`${event.property_licence.name}`}
    </Link>
);

PropertyLicence.propTypes = {
    event: PropTypes.shape({
        property_licence: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            licensor: PropTypes.shape({
                name: PropTypes.string.isRequired
            })
        })
    }).isRequired
}
