import React from "react";

import {EventContentVariantPopup} from "./EventContentVariantPopup";
import {getEventContentNameWithTypeName} from "./eventContentVariant";
import PropTypes from "prop-types";

export const EventContentVariantPopupWithName = ({eventContent}) => {
    return (
        <>
            {getEventContentNameWithTypeName(eventContent)}
            <EventContentVariantPopup eventContentVariant={eventContent.event_content_variant}/>
        </>
    );
};


EventContentVariantPopupWithName.propTypes = {
    eventContent: PropTypes.shape({
        name: PropTypes.string,
        event_content_type: PropTypes.shape({
            name: PropTypes.string,
        }),
        event_content_variant: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
            short_name: PropTypes.string,
        }),
    }),
};
