import React from "react";
import PropTypes from "prop-types";

import Table from "../../app/components/Table";

import {sortThirdParties} from "../utils/thirdPartyClients";

const columns = {
    "type": {
        label: "Push/Pull",
        sorting: true
    },
    "client": {
        label: "Client",
        sorting: true
    },
    "cdnOrOrigin": {
        label: "CDN/Pull origin",
        sorting: true
    },
    "stream_name": {
        label: "Stream name",
        sorting: true
    },
    "target": {
        label: "Encoding target",
        sorting: true,
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
};

const EventsConfigurationThirdPartyTable = (props) => {
    return (
        <div className="event_configuration_thirdy_party_table">
            <Table
                name="eventConfigurationThirdPartyList"
                columns={columns}
                data={props.thirdParties}
                loading={props.loading}
                defaultSort={sortThirdParties}
                noMessageBox
                noDataText="No third party clients defined."
                pagination={false}
                className="mini"
            />
        </div>
    )
};

EventsConfigurationThirdPartyTable.defaultProps = {
    thirdParties: []
};

EventsConfigurationThirdPartyTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    thirdParties: PropTypes.array,
};

export default EventsConfigurationThirdPartyTable;
