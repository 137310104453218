import {Popup} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

import Link from '@appComponents/Link';

function ContentConfigurationLink(props) {
    return (<Link
        name={`events.configuration.content.${props.linkName}`}
        params={{
            id: props.eventId,
            contentId: props.contentId,
        }}
    >
        {props.text ? (
            <Popup
                position={'top right'}
                content={props.text}
                trigger={props.icon}
            />
        ) : (
            props.icon
        )}
    </Link>
    );
}

ContentConfigurationLink.propTypes = {
    contentId: PropTypes.number,
    eventId: PropTypes.number,
    linkName: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.object,
};

export default ContentConfigurationLink;
