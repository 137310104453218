import EventBookingGeoRestrictionsEditModel
    from "../../../events/forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsEditModel";

export default function ClientBookingGeoRestrictionsEditModel() {
    let ClientBookingGeoRestrictionsEditModel = EventBookingGeoRestrictionsEditModel();

    ClientBookingGeoRestrictionsEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };

    ClientBookingGeoRestrictionsEditModel.mutationOptions = {
        delete: {
            refetchQueries: ['clientBookings'],
        },
        update: {
            refetchQueries: ['clientBookings'],
        },
    };

    return ClientBookingGeoRestrictionsEditModel;
}
