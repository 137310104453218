import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {Button, Divider, Segment} from 'semantic-ui-react';
import {get as _get, sortBy as _sortBy} from 'lodash';

import {GetEventContentDistributions} from '@graphql/eventContent/query';
import {GetBroadcastStatusChangelogByDistributionId} from '@graphql/broadcastStatusChangelog/query';
import Table from '@appComponents/Table';
import {hideModal, showModal} from '@utils/modal';
import Form from '@appComponents/ReduxFormControls';
import ExcelExportButton from '@appComponents/ExcelExportButton';
import {getLink} from '@appComponents/Link';
import {renderModalError} from '@utils/forms';
import responseStatus from '@constants/responseStatuses';
import {convertToInt} from '@utils/helpers';

import {headerContent} from '../utils/eventsConfigurationEncodingInformation';
import {columns, rowRenderer} from '../utils/eventContentDistributionBroadcastStatusChangelog';
import {exportExcel} from '../utils/export/eventContentDistributionBroadcastStatusChangelogExcel';

const EventContentDistributionBroadcastStatusChangelog = ({contentId, distributionId, eventId}) => {
    const indexLink = getLink('events.configuration.index', {id: eventId}),
        {
            data: {eventContent} = {},
            error: eventContentDistributionsError,
            loading: isEventContentDistributionsDataLoading = true,
        } = useQuery(GetEventContentDistributions,
            {
                variables: {
                    id: contentId,
                },
                fetchPolicy: 'network-only',
            }
        );

    if (eventContentDistributionsError || !isEventContentDistributionsDataLoading) {
        if (!eventContent?.distributions.some(d => distributionId === convertToInt(d.id))) {
            showModal({
                isVisible: true,
                content: renderModalError('Distribution', indexLink, 'Distribution does not belong to event content'),
            });
        }
    }

    const
        {
            data: {broadcastStatusChangelogs = [], eventContentDetailsForStreamsOutput = {}, distribution = []} = {},
            error: broadcastStatusChangelogsError,
            loading: isBroadcastStatusChangelogsDataLoading = true,
        } = useQuery(GetBroadcastStatusChangelogByDistributionId,
            {
                variables: {
                    distributionId: distributionId,
                    eventContentId: contentId,
                },
                fetchPolicy: 'no-cache',
            }
        ),
        isLoading = isBroadcastStatusChangelogsDataLoading || isEventContentDistributionsDataLoading,
        queryError = broadcastStatusChangelogsError || eventContentDistributionsError,
        productShortName = _get(distribution, 'product.short_name', null),
        distributionType = _get(distribution, 'distribution_type.name', null);

    if (queryError) {
        let content;
        const errorsArray = _get(queryError, 'graphQLErrors', []);

        if (responseStatus.HTTP_NOT_FOUND === errorsArray[0].code) {
            content = renderModalError('Distribution', indexLink);
        } else {
            content = renderModalError(
                'Broadcast Status Changelog',
                indexLink,
                'There was some error while fetching data'
            );
        }

        showModal({
            isVisible: true,
            content: content,
        });
    }

    return (
        <React.Fragment>
            {headerContent('Broadcast status changelog', eventContentDetailsForStreamsOutput, false)}
            <div className='--marginTop-10 --marginLeft-1-5em --marginBottom-10 small --auto_width --text-left'>
                Distribution: {productShortName} {distributionType} ({distributionId})
            </div>
            <Divider fitted/>
            {0 < broadcastStatusChangelogs.length && <Segment basic>
                <ExcelExportButton
                    exportExcelParams={exportExcel(_sortBy(broadcastStatusChangelogs, ['datetime']), distributionId)}
                    floated='right'
                    loading={isLoading}
                />
            </Segment>}
            <Form.Create>
                <Table
                    loading={isLoading}
                    columns={columns}
                    data={broadcastStatusChangelogs}
                    defaultSort={'datetime'}
                    noDataText='No broadcast status changelog'
                    name='EventContentDistributionBroadcastStatusChangelog'
                    pageTitle={'Broadcast status changelog'}
                    rowRenderer={rowRenderer}
                />
                <Segment className='formRow form__footer'>
                    <Button onClick={hideModal} icon='close' content='Close'/>
                </Segment>
            </Form.Create>
        </React.Fragment>
    );
};

EventContentDistributionBroadcastStatusChangelog.propTypes = {
    contentId: PropTypes.number.isRequired,
    distributionId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
};

EventContentDistributionBroadcastStatusChangelog.displayName = 'EventContentDistributionBroadcastStatusChangelog';

export default EventContentDistributionBroadcastStatusChangelog;
