import {withRouter} from 'react-router';
import {isEmpty as _isEmpty, trim as _trim} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {renderModalError} from '@utils/forms';

class WnsStreamForm extends DefaultForm {
    getIndexLink = () => (
        getLink('wnsStreams.index')
    );

    setLinkToIndexPage = () => (
        this.props.history.push(this.getIndexLink())
    );

    handleErrorCallback = (error) => {
        showModal({
            isVisible: true,
            content: renderModalError(this.props.Model.entityLabel, this.getIndexLink(), error.message, 'Back to list'),
        });
    };

    componentDidMount = () => {
        this.setCreateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setDeleteSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setUpdateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });

        this.setCreateErrorCallback((error) => {
            this.handleErrorCallback(error);
        });

        this.setUpdateErrorCallback((error) => {
            this.handleErrorCallback(error);
        });

        this.setDeleteErrorCallback((error) => {
            this.handleErrorCallback(error);
        });
    };

    prepareDataForSubmit = (data) => {
        const dataToSave = Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);

        if (!_isEmpty(data)) {
            dataToSave.id = convertToInt(data.id) || null;
            dataToSave.name = _trim(data.name) || null;
            dataToSave.stream_name = _trim(data.stream_name) || null;
        }

        return dataToSave;
    };

    renderCancelButton = () => {
        return null;
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );
}

export default withRouter(WnsStreamForm);
