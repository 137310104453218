import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {get as _get, isEmpty as _isEmpty, isUndefined as _isUndefined} from 'lodash';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import ChangeLogElementDetails from '@modules/changelog/views/ChangeLogElementDetails';
import {
    extractChangelogChanges,
    formatChangelogElementRevisionsData,
    useUrlIds,
} from '@modules/changelog/utils/changelogHelper';
import {GetChangelogElementDetails} from '@graphql/changeLog/query';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import ChangeLogElementTable from '@modules/changelog/components/ChangeLogElementTable';
import {changelogElementExcel} from '@modules/changelog/utils/export/changelogElementExcel';

const ChangelogElement = (props) => {
    const [resourceId, elementId] = useUrlIds(props.match.url);
    const {data: {changelogElement} = {}, error, loading} = useQuery(GetChangelogElementDetails, {
        variables: {
            resourceId: convertToInt(resourceId),
            elementId: convertToInt(elementId),
        },
        skip: !resourceId || !elementId,
    });

    useEffect(() => {
        if (!loading && !_isEmpty(changelogElement)) {
            document.title = `Changelog - ${changelogElement[0].secured_resource.name} ${elementId} - AV-CMP`;
        }

        return () => {
            document.title = 'AVCMP';
        };
    }, [changelogElement]);

    if (!loading && (_isEmpty(changelogElement) && !_isUndefined(changelogElement)) || error) {
        showErrorModal({
            header: 'Page not found',
            redirectLink: getLink('dashboard'),
            text: 'No changelog found for the requested resource and element.',
            errorButtonIcon: 'home',
            errorButtonText: 'Back to dashboard',
        });

        return null;
    }

    const changelogElementData = formatChangelogElementRevisionsData(changelogElement);
    const parsedChangelogChangeData = extractChangelogChanges(changelogElement);

    return (
        !loading && !_isEmpty(changelogElementData) &&
        <>
            <HeaderRenderer
                pageTitle={
                    `Changelog - ${_get(changelogElementData, 'secured_resource.name', '')} ${elementId}`
                }
            />
            <ChangeLogElementDetails
                resourceID={resourceId}
                elementID={elementId}
                changelogElementData={changelogElementData}
            />
            <HeaderRenderer
                messagesBoxNames='ChangelogOfAnElement'
                exportExcelParams={changelogElementExcel(parsedChangelogChangeData, changelogElementData)}
            />
            <ChangeLogElementTable
                data={parsedChangelogChangeData}
            />
        </>
    );
};

ChangelogElement.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
};

export default ChangelogElement;
