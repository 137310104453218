import PropTypes from "prop-types";
import React from "react";
import {graphql} from "react-apollo";

import ClientPackageContentForm from "../forms/ClientPackageContentForm";

import {convertToInt} from "../../../utils/helpers";

import {ClientPackageQuery} from "../../../graphql/clientPackage/query.graphql";

const ClientPackageContentFormEdit = (props) => (
    <ClientPackageContentForm
        formData={props.data}
        loading={(!props.loading) ? props.data.loading : false}
        setLoading={props.setLoading}
    />
);

ClientPackageContentFormEdit.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
};

export default graphql(ClientPackageQuery, {
    options: (props) => ({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
        variables: {
            id: props.packageId,
            clientId: convertToInt(props.clientId),
        },
    }),
})(ClientPackageContentFormEdit);
