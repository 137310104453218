import PropTypes from "prop-types";
import React from "react";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {Segment} from "semantic-ui-react";

import ProductTable from "../components/ProductTable";
import HeaderRenderer from "../../app/components/HeaderRenderer";

import {exportExcel} from "../utils/export/productIndexExcel";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import navigation from "../../app/constants/navigation";

import {GetProductsForTable} from "../../../graphql/product/query.graphql";

export function ProductIndex ({
    DataProduct: {
        products,
        loading,
    },
    Menu: {
        storeMenu,
    },
}) {
    React.useEffect(() => {
        storeMenu(navigation.eventAdministration.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(products)}
                loading={loading}
                messagesBoxNames={"productMessage"}
                pageTitle="Products"
            />
            <Segment basic className="--table">
                <ProductTable
                    products={products}
                    loading={loading}
                />
            </Segment>
        </div>
    )
}

ProductIndex.propTypes = {
    DataProduct: PropTypes.object,
    Menu: PropTypes.shape({
        storeMenu: PropTypes.func.isRequired,
    }),
};

export default graphql(GetProductsForTable, {
    name: 'DataProduct'
})(connect(null, mapModulesToProps(['Menu']))(ProductIndex));
