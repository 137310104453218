import PropTypes from 'prop-types';

import {EVENT_CONTENT_BOOKED_BY_CLIENT, EVENT_CONTENT_OFFERED_BY_CLIENT} from '@constants/variables';

import NotificationForm from './NotificationForm';

export default class EventGroupNotificationForm extends NotificationForm {
    static propTypes = {
        ...super.propTypes,
        eventDescription: PropTypes.string,
    };

    componentDidMount() {
        super.componentDidMount();

        this.setField('description', {
            defaultValue: this.props.eventDescription,
        });
    }

    setRecipientType = () => {
        let preselectTypes = [EVENT_CONTENT_BOOKED_BY_CLIENT, EVENT_CONTENT_OFFERED_BY_CLIENT];

        this.setField('recipient_type', {
            defaultValue: preselectTypes,
        });
    };

    prepareDataForSubmit = (formData) => {
        let data = super.prepareDataForSubmit(formData);

        data.events = this.props.formParams.optionsVariables.eventsIds;

        return data;
    }
}
