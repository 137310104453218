import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';

export const columns = {
    'id': {
        label: 'ID',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'client': {
        label: 'Client',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'product': {
        label: 'Product',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'clips': {
        label: 'Clips',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'deviceCategories': {
        label: 'Device categories',
        ...columnObjectAlignLeft,
        className: 'noPadding',
    },
    'countries': {
        label: 'Countries',
        ...columnObjectAlignLeft,
    },
    '#countries': {
        label: '#Countries',
        ...columnObjectAlignLeft,
    },
    'blackoutZones': {
        label: 'Blackout Zones',
        ...columnObjectAlignLeft,
    },
    'package': {
        label: 'Package',
        sorting: (row) => row.client_package ? row.client_package : null,
        ...columnObjectAlignLeft,
    },
    'type': {
        label: 'Type',
        sorting: (row) => row.booking_type ? row.booking_type : null,
        ...columnObjectAlignLeft,
    },
    'time': {
        label: 'Time',
        sorting: (row) => row.creation_datetime ? row.creation_datetime : null,
        ...columnObjectAlignCenter,
    },
    'actions': {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },
};
