import React from 'react';
import {get as _get} from 'lodash';

import {columnObjectAlignCenter} from '@utils/tableHelpers';
import {CLIENT_WHITELISTED_IP_AV_API_ACCESS} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import Authorization from '@appComponents/Authorization';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {IconEdit} from '@appComponents/IconCollection';
import AvapiWhitelistingDisableCidrIcon from '@utils/AvapiWhitelistingDisableCidrIcon';
import {ToggleIsDisabledClientAvapiWhitelistedIp} from '@graphql/clientAvapiWhitelistedIp/mutation';

export const columns = {
    'ip_version': {
        label: 'IP version',
        sorting: row => _get(row, 'ip_version.name', null),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {align: 'center'},
    },
    'cidr': {label: 'CIDR', sorting: true},
    'description': {label: 'Description', sorting: true},
    'creation_datetime': {
        label: 'Added',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'disable_datetime': {
        label: 'Disabled',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {align: 'center'},
    },
};

export const rowRenderer = (column, row, {clientId, setMutationSuccessCallback}) => {
    switch (column) {
        case 'ip_version':
            return row[column].name;
        case 'actions': {
            return (
                <div className='actions-list-icon'>
                    <LinkWithAuthorization
                        authorization={{[CLIENT_WHITELISTED_IP_AV_API_ACCESS]: SECURITY_PRIVILEGES_READ}}
                        link={{name: 'clients.whitelistedIps.edit', params: {id: clientId, whitelistedIpId: row.id}}}
                    >
                        <IconEdit resource={CLIENT_WHITELISTED_IP_AV_API_ACCESS} size='large'/>
                    </LinkWithAuthorization>
                    <Authorization authorization={{[CLIENT_WHITELISTED_IP_AV_API_ACCESS]: SECURITY_PRIVILEGES_UPDATE}}>
                        <AvapiWhitelistingDisableCidrIcon
                            clientId={clientId}
                            cidrData={
                                {
                                    id: row.id,
                                    cidr: row.cidr,
                                }
                            }
                            isDisabled={row.is_disabled}
                            messageBoxName='whitelistedIpMessage'
                            successCallback={() => {
                                setMutationSuccessCallback(Date.now());
                            }}
                            mutationChangeDisableStatus={ToggleIsDisabledClientAvapiWhitelistedIp}
                        />
                    </Authorization>
                </div>
            );
        }
        default: return null;
    }
};
