import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';

import {columnsForAvEvents, columnsForSrEvents, eventRowRenderer} from '../../utils/EventMultipleCreation/tableHelper';

export const EventsSimpleTable = ({events = []}) => {
    let columns;

    if (events[0].sr_event) {
        columns = columnsForSrEvents(events);
    } else {
        columns = columnsForAvEvents(events);
    }

    return <Table
        name='eventsPresetApply'
        columns={columns}
        data={events}
        rowRenderer={eventRowRenderer}
        pagination={false}
        size='small'
        className={'mini'}
        defaultSort={'start_datetime'}
    />;
};

EventsSimpleTable.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object)
};

export default EventsSimpleTable;
