import {get as _get} from "lodash"

import AkamaiLiveStreamNameModel from "./AkamaiLiveStreamNameModel";

export default function AkamaiLiveStreamNameModelEdit() {
    const akamaiLiveStreamNameModelEdit = AkamaiLiveStreamNameModel();

    akamaiLiveStreamNameModelEdit.title = (data) => {
        const name = _get(data, "akamaiLiveStreamName.stream_name", null);

        return `Akamai Live stream name ${name ? `"${name}"` : ''}`;
    };

    return akamaiLiveStreamNameModelEdit;
}
