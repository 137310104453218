import React from 'react';
import PropTypes from "prop-types";
import {Dropdown} from "semantic-ui-react";

import {EVENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_DELETE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {ButtonLinkDropdown} from "../../../../app/components/ButtonCollection";
import TriggerAutobookerButton from "../../../../autobooker/components/TriggerAutobookerButton";
import ButtonLinkToMatch from "./Items/ButtonLinkToMatch";
import ButtonEditLinkMatch from "./Items/ButtonEditLinkMatch";
import OpenUsageTypesPopup from "./Items/OpenUsageTypesPopup";
import MatchLiveDataClient from "./Items/MatchLiveDataClient";
import MatchInScoutManagementPlatform from "./Items/MatchInScoutManagementPlatform";

const authorizationEventDeleteObject = {
    [EVENT]: SECURITY_PRIVILEGES_DELETE,
};

/**
 * Button "More" with dropdown list on main event page above Event details
 */
const DropdownMoreButton = ({ event, handleDeleteEvent, handleOpenLinkToMatchIDModal, handleOpenPopupEventUsageTypes }) => {
    /**
     * - "Link to a Sportradar match" button match (if match id NOT linked with event)
     * - "Link to another Sportradar match" button match (if match linked with event)
     * - Open popup for "usage types"
     * - Open URL with match live data client
     * - Open URL for match scout live manager
     * - "Delete"
     */
    return (
        <ButtonLinkDropdown text='More'>
            <ButtonLinkToMatch
                event={event}
                handleOpenLinkToMatchIDModal={handleOpenLinkToMatchIDModal}
            />
            <ButtonEditLinkMatch
                event={event}
                handleOpenLinkToMatchIDModal={handleOpenLinkToMatchIDModal}
            />
            <TriggerAutobookerButton
                entity="event"
                id={event.id}
                messageBoxName="event"
                uiType="dropdown"
            />
            <OpenUsageTypesPopup
                handleOpenPopupEventUsageTypes={handleOpenPopupEventUsageTypes}
            />
            <MatchLiveDataClient
                event={event}
            />
            <MatchInScoutManagementPlatform
                event={event}
            />
            <Authorization authorization={authorizationEventDeleteObject}>
                <Dropdown.Item
                    icon="trash alternate outline"
                    text="Delete"
                    onClick={handleDeleteEvent}
                />
            </Authorization>
        </ButtonLinkDropdown>
    );
};

DropdownMoreButton.propTypes = {
    event: PropTypes.object.isRequired,
    handleDeleteEvent: PropTypes.func.isRequired,
    handleOpenLinkToMatchIDModal: PropTypes.func.isRequired,
    handleOpenPopupEventUsageTypes: PropTypes.func.isRequired
};

export default DropdownMoreButton;
