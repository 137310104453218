import PropTypes from "prop-types";
import React from "react";

import Table from "../../app/components/Table";
import {columns} from "../constants/ProductionCompanyTableColumns";
import {rowRenderer} from "../utils/ProductionCompanyTableRowRenderer";

const ProductionCompanyTable = (props) => {
    const {productionCompanies, loading} = props;

    return (
        <div className="production_companies__table">
            <Table
                columns={columns}
                data={productionCompanies}
                loading={loading}
                name="productionCompaniesList"
                rowRenderer={rowRenderer}
                noDataText="No production companies found."
            />
        </div>
    );
};

ProductionCompanyTable.defaultProps = {
    productionCompanies: [],
};

ProductionCompanyTable.propTypes = {
    loading: PropTypes.bool,
    productionCompanies: PropTypes.array,
};

export default ProductionCompanyTable;
