import {withRouter} from "react-router";
import { findIndex as _findIndex, get as _get, isEmpty as _isEmpty} from "lodash";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";
import {sortByTextValue} from "../../../utils/sorters";

import {EVENT_TYPE_NON_SPORTRADAR, EVENT_TYPE_SPORTRADAR} from "../../app/constants/variables";

export class EventContentTypeForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.props.history.push(getLink('eventContentTypes.index'));
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('eventContentTypes.index'));
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink('eventContentTypes.index'));
        });
        this.setOnChangeCallback({
            event_types: (event) => {
                const sportsInvisibility = (-1 === event.data.indexOf(EVENT_TYPE_SPORTRADAR)),
                    contentCategoriesInvisibility = (-1 === event.data.indexOf(EVENT_TYPE_NON_SPORTRADAR));

                this.setField("sports", {hidden: sportsInvisibility});
                this.setField("content_categories", {hidden: contentCategoriesInvisibility});

                if (sportsInvisibility) {
                    const sports =
                        (this.props.formParams.id && !_isEmpty(this.props.GraphQLEntityData.eventContentType.sports))
                            ? this.props.GraphQLEntityData.eventContentType.sports.map((sport) => sport.id)
                            : [];

                    this.setField("sports", {
                        defaultValue: sports,
                    });
                }

                if (contentCategoriesInvisibility) {
                    const contentCategories =
                        (this.props.formParams.id && !_isEmpty(this.props.GraphQLEntityData.eventContentType.content_categories))
                            ? this.props.GraphQLEntityData.eventContentType.content_categories
                                .map((contentCategory) => contentCategory.id)
                            : [];

                    this.setField("content_categories", {
                        defaultValue: contentCategories,
                    });
                }
            },
            has_match_event_type_selection: (event) => {
                this.setField("has_match_event_type_selection", {
                    defaultValue: !event.has_match_event_type_selection,
                });
            }
        })
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const eventContentType = _get(this.props, 'GraphQLEntityData.eventContentType', {}),
            {GraphQLOptionsData: {
                sports,
                contentCategories,
                distributionTypes,
                products,
            }} = this.props;

        const prepareProductsOptions = (productsList) => {
            const unsortedProducts = productsList.map((product) => {
                return {
                    id: product.id,
                    key: `product_${product.id}`,
                    value: product.id,
                    text: product.short_name,
                }
            });

            return sortByTextValue(unsortedProducts);
        };

        if (!this.state.sportsOptionsGenerated && !_isEmpty(sports)) {
            this.setField("sports", {
                options: sortByTextValue(sports),
            });
            this.setState(() => ({sportsOptionsGenerated: true}));
        }

        if (!this.state.contentCategoriesOptionsGenerated && !_isEmpty(contentCategories)) {
            this.setField("content_categories", {
                options: sortByTextValue(contentCategories),
            });
            this.setState(() => ({contentCategoriesOptionsGenerated: true}));
        }

        if (!this.state.distributionTypesOptionsGenerated && !_isEmpty(distributionTypes)) {
            this.setField("distribution_types", {
                options: distributionTypes,
            });
            this.setState(() => ({distributionTypesOptionsGenerated: true}));
        }

        if (!this.state.productsOptionsGenerated && !_isEmpty(products)) {
            this.setField("products", {
                options: prepareProductsOptions(products),
            });
            this.setState(() => ({productsOptionsGenerated: true}));
        }

        if (!this.state.dataLoaded && !_isEmpty(eventContentType)) {
            this.setField("event_types", {
                defaultValue: _get(eventContentType, 'event_types', [])
                    .map((eventType) => convertToInt(eventType.id)),
            });

            this.setField("sports", {
                hidden: -1 === _findIndex(eventContentType.event_types, {id: EVENT_TYPE_SPORTRADAR.toString()}),
                defaultValue: _get(eventContentType, 'sports', [])
                    .map((sport) => sport.id),
            });

            this.setField("content_categories", {
                hidden: -1 === _findIndex(eventContentType.event_types, {id: EVENT_TYPE_NON_SPORTRADAR.toString()}),
                defaultValue: _get(eventContentType, 'content_categories', [])
                    .map((contentCategory) => contentCategory.id),
            });

            this.setField("distribution_types", {
                defaultValue: _get(eventContentType, 'distribution_types', [])
                    .map((distributionType) => convertToInt(distributionType.id)),
            });
            this.setField("products", {
                defaultValue: _get(eventContentType, 'products', [])
                    .map((product) => product.id),
            });
            this.setField("has_match_event_type_selection", {
                defaultValue: _get(eventContentType, 'has_match_event_type_selection', [])
            });

            this.setState(() => ({dataLoaded: true}));
        }
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                sports: (data && data.sports && -1 !== data.event_types.indexOf(EVENT_TYPE_SPORTRADAR))
                    ? data.sports.map(Number)
                    : [],
                content_categories: (data && data.content_categories && -1 !== data.event_types.indexOf(EVENT_TYPE_NON_SPORTRADAR))
                    ? data.content_categories.map(Number)
                    : [],
                products: (data && data.products) ? data.products.map(Number) : [],
                has_match_event_type_selection: (data && data.has_match_event_type_selection)
                    ? data.has_match_event_type_selection
                    : false,
            }
        )
    );

    renderErrors(errorData) {
        const link = getLink('eventContentTypes.index');

        super.renderErrors(errorData, 'Event content type', link);
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(EventContentTypeForm);
