import PropTypes from "prop-types";
import React from "react";
import {Message} from "semantic-ui-react";

import {ENCODING_STATUS_ENDED} from "../../app/constants/variables";

export const EventContentEncodingWarningMessage = ({encodingStatus = null}) => {
    return (
        <Message
            error={ENCODING_STATUS_ENDED !== encodingStatus}
            warning={ENCODING_STATUS_ENDED === encodingStatus}
        >
            The encoding configuration of this content has been modified since its last encoding was started.
            {
                (ENCODING_STATUS_ENDED === encodingStatus)
                    ? `
                        You can reset and restart the encoding in the Live Control page to encode with the 
                        new configuration.
                    `
                    : `
                        To encode with the new configuration, you need to stop the encoding, reset it and start it 
                        again in the Live Control page.
                    `
            }
        </Message>
    )
};

EventContentEncodingWarningMessage.propTypes = {
    encodingStatus: PropTypes.string,
};

EventContentEncodingWarningMessage.displayName = 'EventContentEncodingWarningMessage';
