import React from "react";

import {IconEdit} from "../../app/components/IconCollection";
import {LinkWithAuthorization} from "../../app/components/LinkWithAuthorization";

import {THIRD_PARTY_CLIENT_CDN} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_READ} from "../../app/constants/variables";

export const thirdPartyColumns = {
    "id": {
        label: "ID",
        sorting: true,
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "third_party_client_cdn_type": {
        label: "Type",
        sorting: (row) => (!row.third_party_client_cdn_type.name)
            ? ""
            : row.third_party_client_cdn_type.name.capitalizeFirstLetter()
    },
    "encoding_job_profile": {
        label: "Encoding job profile",
        sorting: (row) => (!row.encoding_job_profile.name)
            ? ""
            : row.encoding_job_profile.name.capitalizeFirstLetter()
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    }
};

export const renderNameOfFirstLevelDependency = (row, column) => (
    <div>
        {(undefined === row[column] || null === row[column]) ? "" : row[column].name}
    </div>
);

export const editButton = (clientId, column, row, isLink = true) => (
    <div className="actions-list-icon">
        <LinkWithAuthorization
            authorization={{[THIRD_PARTY_CLIENT_CDN]: SECURITY_PRIVILEGES_READ}}
            link={{
                name: "clients.products.thirdParty.cdns.edit",
                params: {id: clientId, thirdPartyClientCdnId: row.id},
            }}
        >
            {
                isLink
                    ? <p className="link-to-modal" data-id={row.id}>{row[column]}</p>
                    : <IconEdit resource={THIRD_PARTY_CLIENT_CDN} size="large"/>
            }
        </LinkWithAuthorization>
    </div>
);

export const thirdPartyRowRenderer = (column, row, {clientId}) => {
    switch (column) {
        case "id":
        case "name":
            return editButton(clientId, column, row);
        case "third_party_client_cdn_type":
        case "encoding_job_profile":
            return renderNameOfFirstLevelDependency(row, column);
        case "actions": {
            return editButton(clientId, column, row, false);
        }
        default: {
            return null;
        }
    }
};
