import {Header} from "semantic-ui-react";
import React from "react";
import PropTypes from "prop-types";

import {EventsMultipleCreation} from "../EventsMultipleCreation";

const MultipleEventCreationModalContent = ({data = {}, matchesIds = [], queryForRefetch = "", modelObjectExtension = {}}) => (
    <div>
        <Header content="Create Sportradar events"/>
        <EventsMultipleCreation
            data={data}
            matchesIds={matchesIds}
            queryForRefetch={queryForRefetch}
            modelObjectExtension={modelObjectExtension}
        />
    </div>
);

MultipleEventCreationModalContent.propTypes = {
    data: PropTypes.object,
    matchesIds: PropTypes.array,
    modelObjectExtension: PropTypes.object,
    queryForRefetch: PropTypes.string
}

export default MultipleEventCreationModalContent;
