import * as React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';

import {ReduxComponent} from "./ReduxComponent";

import {REGEX_SVG_SELECTOR} from "../../constants/variables";

/**
 * Render file preview for Redux
 */
class FilePreview extends ReduxComponent {
    static propTypes = {
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        defaultValue: PropTypes.string,
        input: PropTypes.object,
        meta: PropTypes.object,
        type: PropTypes.string,
    };

    /**
     * @ignore
     */
    componentWillMount() {
        this.props.input.onChange(this.props.defaultValue)
    }

    /**
     * @ignore
     */
    componentWillReceiveProps(nextProps) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            this.props.input.onChange(nextProps.defaultValue)
        }
    }

    /**
     * @ignore
     */
    prepareSvgType() {
        let svg = this.props.defaultValue.match(REGEX_SVG_SELECTOR);

        return svg ? svg[0] : null;
    }

    /**
     * @ignore
     */
    renderContent() {
        let content = <div>{this.props.defaultValue}</div>;

        if (this.props.defaultValue && this.props.type === "svg") {
            // eslint-disable-next-line
            content = <div dangerouslySetInnerHTML={{__html: this.prepareSvgType()}}/>
        }

        return content;
    }

    /**
     * @ignore
     */
    render() {
        const errorInfo = this.isError(this.props) ? this.props.meta.error : '';

        return (
            <div className={classnames(
                {'error': errorInfo},
                this.props.className
            )}>
                {this.renderLabel(this.props)}
                <div className={classnames(
                    'input-container',
                    this.props.containerClassName
                )}>
                    <div className="form__field-wrapper">
                        {this.renderContent()}
                    </div>
                    <div className="error info">{errorInfo}</div>
                </div>
            </div>
        )
    }
}

export default FilePreview;
