import PropTypes from "prop-types";

import {UserResourceField} from "../components/UserResourceField";

import {generateSwitches} from "../../role/forms/helpers/roleResourceModelHelper";

export function generateFieldsForUserResources({user: {user_resources, roles_resources}, securedResources}) {
    if ("undefined" === typeof user_resources ||
        "undefined" === typeof roles_resources ||
        "undefined" === typeof securedResources) {
        return {};
    }

    const userResourcesForModel = {},
        switches = generateSwitches(roles_resources);

    securedResources.forEach((securedResource) => {
        Object.assign(userResourcesForModel, {
            [`resource_${securedResource.id}`]: {
                dataMapKey: `resource_${securedResource.id}`,
                props: {
                    component: UserResourceField,
                    data: {
                        user_resources: user_resources,
                        roles_resources: roles_resources,
                        securedResource: securedResource,
                        switches: switches,
                    },
                    id: securedResource.id,
                    name: `resource_${securedResource.id}`,
                }
            }
        })
    });

    return userResourcesForModel;
}
generateFieldsForUserResources.prototype = {
    user: PropTypes.shape({
        user_resources: PropTypes.object,
        roles_resources: PropTypes.array,
    }).isRequired,
    securedResource: PropTypes.array.isRequired,
};
