import React from 'react';
import PropTypes from 'prop-types';
import {isArray, toUpper} from 'lodash';
import {has, map, join, sortBy} from 'lodash/fp';

import {getLink} from '@appComponents/Link';
import Authorization from '@appComponents/Authorization';
import { IconEdit, IconCheckmark } from '@appComponents/IconCollection';
import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import {columnObjectAlignCenter} from '@utils/tableHelpers';

const authorizationObject = {
    [RESOURCES.ENCODING_JOB_PROFILE]: CONST.SECURITY_PRIVILEGES_READ,
};

export const columns =  {
    id: {
        label: 'ID',
        content: { align: 'center' },
        sorting: row => parseInt(row.id, 10),
        header: { align: 'center', isCollapsed: true },
    },

    name: {
        label: 'Name',
        sorting: row => toUpper(row.name) || '',
    },

    product: {
        label: 'Product',
        sorting: row => (! has('product.short_name', row)) ? '' : toUpper(row.product.short_name),
    },

    distribution_type: {
        label: 'Distribution type',
        sorting: row => (! row.name) ? '' : row.distribution_type.name.toUpperCase(),
    },

    has_drm_support: {
        label: 'DRM?',
        ...columnObjectAlignCenter,
        sorting: row => JSON.stringify(!! row.has_drm_support),
    },

    has_multi_audio_support: {
        label: 'Multi audio?',
        ...columnObjectAlignCenter,
        sorting: row => JSON.stringify(!! row.has_multi_audio_support),
    },

    is_default: {
        label: 'Default?',
        ...columnObjectAlignCenter,
        sorting: row => JSON.stringify(!! row.is_default),
    },

    clients: {
        label: 'Clients',
        sorting: row => false === hasClients(row) ? 'All' : namesOfClients(row.clients),
    },

    actions: {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },
};

const renderCheckbox = (value) => {
    return value ? <IconCheckmark/> : '';
};

const hasClients = (row) => {
    if (true === has('clients', row)) {
        return true === isArray(row.clients) && 0 < row.clients.length;
    }

    return false;
};

const namesOfClients = (clients, prop = 'name', separator = ', ') => {
    return join(separator, map(prop, sortBy(client => toUpper(client.name), clients)));
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'encodingJobProfile.edit', params: {id: row.id}}}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );

        case 'product' :
            return (
                <div>
                    {(! row.product) ? '' : row.product.short_name}
                </div>
            );

        case 'distribution_type':
            return (
                <div>
                    {(! row.distribution_type) ? '' : row.distribution_type.name}
                </div>
            );
        case 'has_drm_support':
        case 'has_multi_audio_support':
        case 'is_default':
            return renderCheckbox(row[column]);

        case 'clients':
            return (
                <div>
                    {(false === hasClients(row)) ? 'All' : namesOfClients(row.clients)}
                </div>
            );

        case 'actions' :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={getLink('encodingJobProfile.edit', {id: row.id})}
                            resource={RESOURCES.ENCODING_JOB_PROFILE}/>
                    </div>
                </Authorization>
            );

        default:
            return null;
    }
};

const EncodingJobProfileTable = (props) => {
    const {encodingJobProfiles, loading} = props;

    return (
        <div className='encoding_job_profiles__table'>
            <Table
                columns={columns}
                data={encodingJobProfiles}
                loading={loading}
                name='encodingJobProfilesList'
                noDataText='No encoding job profiles found'
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

EncodingJobProfileTable.defaultProps = {
    encodingJobProfiles: [],
};

EncodingJobProfileTable.propTypes = {
    encodingJobProfiles: PropTypes.array,
    loading: PropTypes.bool,
};

export default EncodingJobProfileTable;
