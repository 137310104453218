import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import Authorization from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {DisableCidrIcon} from '@utils/DisableCidrIcon';
import {BLACKLISTED_IP} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';

export const rowRenderer = (column, row, props) => {
    const {disableClick, timezone} = props;

    switch (column) {
        case 'ip_version':
            return row[column].name;
        case 'disable_datetime':
            return row.disabled_datetime
                ? moment(row.disabled_datetime).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
                : null;
        case 'actions': {
            return (
                <div className='actions-list-icon'>
                    <LinkWithAuthorization
                        authorization={{[BLACKLISTED_IP]: SECURITY_PRIVILEGES_READ}}
                        link={{
                            name: 'ipBlacklisting.edit',
                            params: {id: row.id},
                        }}
                    >
                        <IconEdit resource={BLACKLISTED_IP} size='large'/>
                    </LinkWithAuthorization>
                    <Authorization authorization={{[BLACKLISTED_IP]: SECURITY_PRIVILEGES_UPDATE}}>
                        {!row.is_disabled ? <DisableCidrIcon onClick={disableClick} onClickElement={row}/> : null}
                    </Authorization>
                </div>
            );
        }
        default: {
            return null;
        }
    }
};

rowRenderer.propTypes = {
    disableClick: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
};

rowRenderer.displayName = 'rowRenderer';
