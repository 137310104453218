import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {Field} from 'redux-form';
import {sortBy as _sortBy} from 'lodash';
import {useQuery} from '@apollo/client';

import Form from '@appComponents/ReduxFormControls';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {GetCountriesHavingPostalCodesWithSubdivisions} from '@graphql/country/query';

import {showErrorNotificationMessage} from '../utils/blackoutZone';

export const DEFAULT_FILTERS_VALUES = {
    search: '',
    country: [],
    countrySubdivision: [],
};

export const BlackoutZonePostalCodesFilters = ({
    restoreFilterValues: getValue,
    form: {changeValue: setValue},
    filters: {BlackoutZonePostalCodes: filters},
}) => {
    const search = getValue(filters, 'search', ''),
        [country, setCountry] = useState(getValue(filters, 'country', [])),
        [countrySubdivision, setCountrySubdivision] = useState(getValue(filters, 'countrySubdivision', [])),
        [countrySubdivisionOptions, setCountrySubdivisionOptions] = useState([]);

    const onCountryChange = (e, newCountry) => {
        const newCountrySubdivisionOptions = _sortBy(
                countryOptions.filter(co => newCountry.includes(co.id)).flatMap(co => co.country_subdivisions),
                [cs => cs.name]
            ),
            newCountrySubdivision = countrySubdivision.filter(
                csId => newCountrySubdivisionOptions.find(cso => csId === cso.id)
            );

        setCountry(newCountry);
        setCountrySubdivision(newCountrySubdivision);
        setCountrySubdivisionOptions(newCountrySubdivisionOptions);
        setValue('countrySubdivision', newCountrySubdivision);
    };

    const {
        data: {
            countriesHavingPostalCodes: countryOptions = [],
        } = {},
        error: errorFetchingData,
        loading: dataLoading = true,
    } = useQuery(GetCountriesHavingPostalCodesWithSubdivisions, {
        onCompleted: () => onCountryChange(null, country),
    });

    useMemo(() => {
        (errorFetchingData) && showErrorNotificationMessage();
    }, [errorFetchingData]);

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={dataLoading}
                setValue={search}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={dataLoading}
                loading={dataLoading}
                multiple
                name='country'
                options={countryOptions}
                placeholder='Country'
                search
                setValue={country}
                onChangeValue={onCountryChange}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={dataLoading}
                loading={dataLoading}
                hidden={0 === country.length || 0 === countrySubdivisionOptions.length}
                multiple
                name='countrySubdivision'
                options={countrySubdivisionOptions}
                placeholder='Subdivision'
                search
                setValue={countrySubdivision}
                onChangeValue={(e, newCountrySubdivision) => setCountrySubdivision(newCountrySubdivision)}
            />
        </div>
    );
};

BlackoutZonePostalCodesFilters.propTypes = {
    filters: PropTypes.shape({
        BlackoutZonePostalCodes: PropTypes.shape({
            search: PropTypes.string,
            country: PropTypes.array,
            countrySubdivision: PropTypes.array,
        }).isRequired,
    }).isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
};

export default FiltersWrapper(BlackoutZonePostalCodesFilters, {
    name: 'BlackoutZonePostalCodes',
    initialValues: DEFAULT_FILTERS_VALUES,
});
