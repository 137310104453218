import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from "react-router";

import Table from "../../app/components/Table";

import {rowRenderer} from "../utils/ContactPersonsTableRowRenderer";

import {columns} from "../constants/ContactPersonsTableColumns";

const ContactPersonsTable = ({contactPersons = [], loading, match: {params: {id}}}) => (
    <div className="contact_persons__table">
        <Table
            columns={columns}
            data={contactPersons}
            loading={loading}
            name="contactPersonsList"
            noDataText="No contact persons found"
            pagination={false}
            rowRenderer={rowRenderer}
            rowRendererProps={{id}}
        />
    </div>
);

ContactPersonsTable.propTypes = {
    contactPersons: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(ContactPersonsTable);
