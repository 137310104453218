import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown} from 'semantic-ui-react';

import ExcelExportButton from '@appComponents/ExcelExportButton';
import {routes} from '@constants/routes';

const renderDropdownItem = (type, key, action) => (
    <Dropdown.Item
        text={routes.clients[type][key].label}
        key={routes.clients[type][key].path}
        data-key={routes.clients[type][key].path}
        data-idkey={key}
        onClick={action}
    />
);

const ClientBookingsButtons = (props) => (
    <div className='content__buttons'>
        <Dropdown button text='Selection'>
            <Dropdown.Menu>
                {renderDropdownItem('bookings', 'bulkChange', props.onClickHandler)}
                {renderDropdownItem('bookings', 'bulkCancel', props.onClickHandler)}
            </Dropdown.Menu>
        </Dropdown>
        <ExcelExportButton exportExcelParams={props.excelExport} loading={props.loading}/>
    </div>
);

ClientBookingsButtons.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    excelExport: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ClientBookingsButtons;
