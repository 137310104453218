import {get as _get} from 'lodash';

import validators from '@utils/validators';
import {DISTRIBUTION_TYPE_LIVE, DISTRIBUTION_TYPE_RECORDING} from '@constants/variables';
import {isDistributionPartOfProduct} from '@utils/product';

const validate = (values, props) => {
    const errors = {};
    const {GraphQLOptionsData} = props;
    const isLoadingData = GraphQLOptionsData.loading;

    Object.assign(errors, validators.required(values.cdn, 'cdn'));
    Object.assign(errors, validators.required(values.akamai_cdn_ingest_method, 'akamai_cdn_ingest_method'));

    if (!isLoadingData) {
        const productDistributionTypes = _get(GraphQLOptionsData, 'productDistributionTypes', []);

        if (isDistributionPartOfProduct(props.formParams.id, DISTRIBUTION_TYPE_LIVE, productDistributionTypes)) {
            Object.assign(errors, validators.required(values.live_ingest_method, 'live_ingest_method'));
        }

        if (isDistributionPartOfProduct(props.formParams.id, DISTRIBUTION_TYPE_RECORDING, productDistributionTypes)) {
            Object.assign(errors, validators.required(values.recording_ingest_method, 'recording_ingest_method'));
        }
    }

    return errors;
};

export default validate;
