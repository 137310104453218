import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {ROLE} from "../../app/constants/resources";

import {renderFormErrorMessages, renderTitle} from "./helpers/roleModel";

import validate from "./validator";

import {CreateRole, UpdateRole} from "../../../graphql/role/mutation.graphql";
import {GetRoleForForm} from "../../../graphql/role/query.graphql";

export default function RoleModel() {
    return {
        entityDataMapKey: "role",
        entityLabel: "role",
        formName: "role",
        label: "role",
        resources: ROLE,
        showChangelog: true,
        validate: validate,
        title: renderTitle,
        dataMap: {
            role: {
                id : "",
                name: "",
            }
        },
        messages: {
            boxName: {
                error: "roleMessageError",
                success: "roleMessage",
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: renderFormErrorMessages,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "role.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "role.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
        },
        mutation: {
            createEntity: CreateRole,
            updateEntity: UpdateRole,
        },
        query: {
            getEntity: GetRoleForForm,
        },
    }
}
