import {GetClientProductLcoForGeoRestrictionsForm} from "../../../graphql/clientProductLco/query.graphql";
import {UpdateGeoRestrictionsClientProductLco} from "../../../graphql/clientProductLco/mutation.graphql";

import ProductLctGeoRestrictionsModel from "./ProductLctGeoRestrictionsModel";

export default function ProductLcoGeoRestrictionsModel() {
    let productLcoGeoRestrictionsModel = ProductLctGeoRestrictionsModel();

    productLcoGeoRestrictionsModel.entityDataMapKey = "clientProductLco";
    productLcoGeoRestrictionsModel.formName = "product LCO";
    productLcoGeoRestrictionsModel.fields.id.dataMapKey = "clientProductLco.id";
    productLcoGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductLco,
    };
    productLcoGeoRestrictionsModel.query.getEntity = GetClientProductLcoForGeoRestrictionsForm;

    return productLcoGeoRestrictionsModel;
}
