import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorClientDrmSetup";

import * as MESSAGES from "../../app/constants/messages";
import {DRM_SETUP} from "../../app/constants/resources";

import {
    CreateClientDrmSetup,
    DeleteClientDrmSetup,
    UpdateClientDrmSetup
} from "../../../graphql/clientDrmSetup/mutation.graphql";
import {GetClientDrmSetupForForm, GetClientDrmSetupOptions} from "../../../graphql/clientDrmSetup/query.graphql";

export default function ClientDrmSetupModel() {
    return {
        entityLabel: "DRM setup",
        formName: "clientDrmSetup",
        deleteId: "clientDrmSetup.id",
        entityDataMapKey: "clientDrmSetup",
        resources: DRM_SETUP,
        showChangeLog: true,
        title: () => (null),
        validate: validate,
        dataMap: {
            clientDrmSetup: {
                id: "",
                client: {
                    id: "",
                },
                drm_provider: {
                    id: "",
                },
                client_drm_setup_buy_drm: {
                    id: "",
                    server_key: "",
                    user_key: "",
                },
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('DRM setup'),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "clientDrmSetup.id",
                props: {
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            drm_provider: {
                dataMapKey: "clientDrmSetup.drm_provider.id",
                optionsKey: "drmProviders",
                props: {
                    className: "--small-width",
                    component: Form.SemanticSelect,
                    label: "DRM provider",
                    name: "drm_provider",
                    required: true,
                },
            },
            server_key: {
                dataMapKey: "clientDrmSetup.client_drm_setup_buy_drm.server_key",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Server key',
                    name: 'server_key',
                    required: true,
                },
            },
            user_key: {
                dataMapKey: "clientDrmSetup.client_drm_setup_buy_drm.user_key",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'User key',
                    name: 'user_key',
                    required: true,
                },
            },
        },
        query: {
            getEntity: GetClientDrmSetupForForm,
            getOptions: GetClientDrmSetupOptions,
        },
        mutation: {
            createEntity: CreateClientDrmSetup,
            deleteEntity: DeleteClientDrmSetup,
            updateEntity: UpdateClientDrmSetup,
        },
        mutationOptions: {
            update: {
                refetchQueries: ['GetClientDrmSetupForForm'],
            },
        },
    };
}
