import React from 'react';
import PropTypes from 'prop-types';

import ButtonAdd from '@appComponents/ButtonsCollection/ButtonAdd';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';
import {STREAM} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';

const authorizationObject = {
    [STREAM]: SECURITY_PRIVILEGES_CREATE,
};

const EventStreamsIndexButtons = (props) => {
    return (
        <div className='eventStreams__indexButtons content__buttons'>
            <Authorization authorization={authorizationObject}>
                <ButtonAdd
                    label={'Add new stream'}
                    link={{
                        name: 'events.streams.add',
                        params: {id: props.eventId},
                    }}
                />
            </Authorization>
        </div>
    );
};

EventStreamsIndexButtons.propTypes = {
    eventId: PropTypes.number,
};

export default EventStreamsIndexButtons;
