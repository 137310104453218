import React from 'react';

import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {Authorization} from '@appComponents/Authorization';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

const MediaConnectIndexButtons = () => {
    const authorizationObject = {
        [RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_CREATE,
    };

    return (
        <div className='content__buttons'>
            <Authorization authorization={authorizationObject}>
                <Link name='mediaConnect.add'>
                    <ButtonAdd>{'Add new MediaConnect origin entry point'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default MediaConnectIndexButtons;
