import {get as _get} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {Button, Header, Icon} from "semantic-ui-react";

import {convertToInt} from "./helpers";
import {showMessageBox} from "./messageBox";
import {parseProducts} from "../modules/client/utils/whitelistingHelper";

import * as MESSAGES from "../modules/app/constants/messages";

export const errorMessage = `
    An error occurred while trying to save the CIDR. It might be that the CIDR is overlapping another CIDR
`;

export const CidrFormInfoMessage = () => (
    <div className="--block --paddingBottom-14">
        <p>
            Find out more about CIDR
            <a
                href={"https://en.wikipedia.org/wiki/Classless_Inter-Domain_Routing"}
                rel="noopener noreferrer"
                target={"_blank"}
            >
                &nbsp;here
            </a>.
        </p>
        <p>
            Make sure that the IP included in the CIDR is equal to the lower boundary of the CIDR block
            (first IP of the range). To do that, use
            <a href={"https://www.ipaddressguide.com/cidr"} rel="noopener noreferrer" target={"_blank"}>
                &nbsp;this tool for IPv4
            </a>
            &nbsp;and
            <a href={"https://www.ipaddressguide.com/ipv6-cidr"} rel="noopener noreferrer" target={"_blank"}>
                &nbsp;this tool for IPv6
            </a>.
        </p>
    </div>
);

export const overlapConfirmationModal = (description, overlappingIps, confirmationText, onClickYes, onClickNo, isLoading) => {
    return (
        <div>
            <Header content="CIDR overlap" />
            <div className="content">
                <div>
                    {description}
                    <ul>
                        {overlappingIps}
                    </ul>
                    <p>
                        Whitelisted IPs have priority over blacklisted IPs, which means that an IP which is both
                        whitelisted and blacklisted will be whitelisted.
                    </p>
                    <p>{confirmationText}</p>
                </div>
            </div>
            <div className="actions">
                <Button
                    icon
                    labelPosition="right"
                    color="green"
                    onClick={onClickYes}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    Yes
                    <Icon name={"checkmark"} key={0}/>
                </Button>
                <Button
                    icon
                    labelPosition="right"
                    color="red"
                    onClick={onClickNo}
                    disabled={isLoading}
                >
                    No
                    <Icon name={"remove"} key={1}/>
                </Button>
            </div>
        </div>
    );
};
overlapConfirmationModal.propTypes = {
    description: PropTypes.string.isRequired,
    overlappingIps: PropTypes.object.isRequired,
    confirmationText: PropTypes.string.isRequired,
    onClickYes: PropTypes.func.isRequired,
    onClickNo: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export const cidrFormTabs = (content) => {
    return [
        {
            id: "step1",
            key: "step1",
            menuItem: {content: "Add CIDR", hidden: true},
            pane: {
                content: content,
            },
        },
        {
            id: "step2",
            key: "step2",
            menuItem: {content: "Confirmation", hidden: true},
            pane: {
                content: null,
            },
        },
    ];
};
cidrFormTabs.propTypes = {
    content: PropTypes.func.isRequired,
};

export const prepareStringForCidrSorting = (row) => (
    `${Boolean(row.is_disabled)}-${convertToInt(row.ip_version.id)}-${row.cidr}`
);

export const cidrColumns = {
    "ip_version": {
        label: "IP version",
        sorting: row => _get(row, "ip_version.name", null),
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {align: "center"},
    },
    "cidr": {label: MESSAGES.CIDR, sorting: true},
    "products": {
        label: "Products",
        sorting: row => parseProducts(row["products"]),
    },
    "description": {label: "Description", sorting: true},
    "creation_datetime": {
        content: {align: "center"},
        header: {align: "center"},
        label: "Added",
        sorting: true,
    },
    "disable_datetime": {
        content: {align: "center"},
        header: {align: "center"},
        label: "Disabled",
        sorting: true,
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {align: "center"},
    },
};

export const showSuccessMessageAfterMutation = (name, cidr) => {
    showMessageBox(
        name,
        `The CIDR "${cidr}" has been saved successfully.`,
        null,
        "success"
    );
};

export const showErrorMessage = (boxName, cidr, isTypeWhiteList = true) => {
    showMessageBox(
        boxName,
        MESSAGES.NOT_SAVED(MESSAGES.CIDR, cidr),
        isTypeWhiteList ? `${errorMessage.trim()} of the client.` : `${errorMessage.trim()}.`,
        "error",
        true
    );
};
