import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {useQuery} from '@apollo/client';

import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {convertToInt} from '@utils/helpers';
import {getSearchFilters} from '@utils/filters';
import {showModal} from '@utils/modal';
import {GetEventLcppClientNotificationLog} from '@graphql/lcppClientNotificationLog/query';
import useEffectForFilters from '@utils/hooks/useEffectForFilters';

import EventsLcppClientNotificationLogTable from '../components/EventsLcppClientNotificationLogTable';
import EventsLcppClientNotificationLogFilters from '../components/EventsLcppClientNotificationLogFilters';
import {exportExcel} from '../utils/export/eventsLcppClientNotificationLogExcel';
import {renderEventLcppClientNotificationLogModalError} from '../utils/eventsLcppClientNotificationLog';

const DEFAULT_FILTERS_VALUES = {
    eventContent: [],
    client: [],
};

export function EventLcppClientNotificationLogIndex ({
    filters,
    match: {
        params: {
            id,
        },
    },
}) {
    const [isRefetching, setIsRefetching] = useState(false),
        eventId = convertToInt(id),
        queryVariables = {
            event: eventId,
            eventContent: filters.eventContent ? filters.eventContent.map(Number) : DEFAULT_FILTERS_VALUES.eventContent,
            client: filters.client ? filters.client.map(Number) : DEFAULT_FILTERS_VALUES.client,
        };

    const {
        data: {
            lcppClientNotificationsLog,
        } = {},
        error,
        loading: isDataLoading = true,
        refetch,
    } = useQuery(GetEventLcppClientNotificationLog,{
        variables: {
            ...queryVariables,
        },
    });

    useEffectForFilters(lcppClientNotificationsLog, filters.lastClickSubmit, refetch, setIsRefetching);

    if (!_isEmpty(error)) {
        showModal({
            isVisible: true,
            content: renderEventLcppClientNotificationLogModalError(eventId, error.message),
        });
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(lcppClientNotificationsLog, eventId)}
                loading={isDataLoading || isRefetching}
                filters={EventsLcppClientNotificationLogFilters}
                filtersProps={{
                    data: {
                        eventId,
                    },
                }}
                filtersButtonName='EventsLcppClientNotificationLog'
                messagesBoxNames='LcppClientNotificationLog'
            />
            <EventsLcppClientNotificationLogTable
                lcppClientNotificationsLog={lcppClientNotificationsLog}
                loading={isDataLoading || isRefetching}
            />
        </div>
    );
}

EventLcppClientNotificationLogIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

const EventLcppClientNotificationIndexWrapped = FilterQueryWrapper(EventLcppClientNotificationLogIndex, {
    queryForRefresh: 'GetEventLcppClientNotificationLog',
    filterUrls: ['events.lcppClientNotifications'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'EventsLcppClientNotificationLog', DEFAULT_FILTERS_VALUES),
});

export default withRouter(connect(mapStateToProps, null)(
    FilterUrlParamsWrapper(EventLcppClientNotificationIndexWrapped, DEFAULT_FILTERS_VALUES)
));
