import React from 'react';
import PropTypes from 'prop-types';
import {Breadcrumb} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {GetSecuredResources} from '@graphql/changeLog/query';

const ChangelogRoutesCrumb = (props) => {
    const pathParts = props.pathname.split('/');
    const resourceId = pathParts[pathParts.indexOf('resources') + 1];
    const elementsId = pathParts[pathParts.indexOf('elements') + 1];
    const {data: {securedResources} = {}, loading} = useQuery(GetSecuredResources, {
        skip: !resourceId,
    });
    let securedResource, securedResourceName;

    if (securedResources) {
        securedResource = securedResources.find(resource => resource.id === resourceId);
        securedResourceName = securedResource ? securedResource.name : '';
    }

    const paths = [
        {
            key: 0,
            content: (<NavLink exact to={'/'}>Home</NavLink>),
            active: false,
        },
        {
            key: 1,
            content: (<NavLink exact to={`/${pathParts[1]}`}>{pathParts[1]}</NavLink>),
            active: false,
        },
        {
            key: 2,
            content: `${securedResourceName} ${elementsId}`,
            active: true,
        },
    ];

    return (
        !loading && <Breadcrumb as='div' icon='chevron right' sections={paths}/>
    );
};

ChangelogRoutesCrumb.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default ChangelogRoutesCrumb;
