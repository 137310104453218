import validators from "../../../utils/validators";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.country, 'country'));

    if (values.enforcement_date) {
        Object.assign(errors, validators.date(values.enforcement_date, "enforcement_date"));
    }

    return errors;
};

export default validate;
