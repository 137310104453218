import PropTypes from 'prop-types';

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_ip_whitelisting_av_api_access`,
    parseData: avApiAccessWhitelistedIp => [
        avApiAccessWhitelistedIp.ip_version.name,
        avApiAccessWhitelistedIp.cidr,
        avApiAccessWhitelistedIp.description,
        avApiAccessWhitelistedIp.creation_datetime,
        avApiAccessWhitelistedIp.disable_datetime || '',
    ],
    path: 'clients.whitelistedIps.index.path',
    titles: [
        'IP version',
        'CIDR',
        'Description',
        'Added',
        'Disabled',
    ],
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
};
