import PropTypes from 'prop-types';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'mediaconnect_origin_entry_points',
    parseData: mediaconnectOriginEntryPoint => [
        mediaconnectOriginEntryPoint.id,
        mediaconnectOriginEntryPoint.name,
        mediaconnectOriginEntryPoint.description || '',
        mediaconnectOriginEntryPoint.encoding_datacenter.name,
        mediaconnectOriginEntryPoint.mediaconnect_origin_feeds[0]
            ?
            mediaconnectOriginEntryPoint.mediaconnect_origin_feeds[0].url
            : null,
        mediaconnectOriginEntryPoint.mediaconnect_origin_feeds[1]
            ? mediaconnectOriginEntryPoint.mediaconnect_origin_feeds[1].url
            : null,
    ],
    path: 'mediaConnect.path',
    titles: [
        'ID',
        'Name',
        'Description',
        'Encoding datacenter',
        'Primary feed',
        'Secondary feed',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
