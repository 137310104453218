import PropTypes from 'prop-types';

import {convertToInt} from "../../../../../../utils/helpers";

/**
 * Prepare data for "Usage types" tree.
 * Selected values on "Usage" tree display or not branch on "Usage types" tree
 * @param {Object[]} initialUsagesSelected
 * @returns {int[]}
 */
export const getInitialSelectedUsages = (initialUsagesSelected) => {
    let usagesSelected = [];

    initialUsagesSelected.forEach((initialUsage) => {
        const productId = convertToInt(initialUsage.product.id);

        usagesSelected.push(productId);
    });

    return usagesSelected;
};

getInitialSelectedUsages.propTypes = {
    initialUsagesSelected: PropTypes.array.isRequired
};

/**
 * @param {Object[]} usageSelected Usages selected
 * @returns {int[]}
 */
export const getCurrentSelectedUsages = (usageSelected) => (
    usageSelected.map((usage) => (convertToInt(usage.value)))
);

getCurrentSelectedUsages.propTypes = {
    usageSelected: PropTypes.array.isRequired
}
