import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import Link from '@appComponents/Link';
import {replaceMatchIdPlaceholderInsideString} from '@utils/helpers';
import {ORIGIN_STREAM_TYPE_HTTP, ORIGIN_STREAM_TYPE_SRT, ORIGIN_STREAM_TYPE_RTMP} from '@constants/variables';
import CopyOriginStreamUrlButton from '@utils/copyOriginStreamUrlButton/CopyOriginStreamUrlButton';
import {getOriginStreamTypeName, hasProperOriginStreamType} from "@modules/liveControl/utils/helpers";

/**
 * Information about origin stream
 */
export const OriginStream = ({debugMode, eventContentDetails, newWindow}) => {
    let url;

    if (_isEmpty(eventContentDetails)
        || !eventContentDetails.event_id
        || !eventContentDetails.event_content_id
        || (!eventContentDetails.rtmp_origin_stream_name
            && !eventContentDetails.http_origin_entry_point_url
            && !eventContentDetails.srt_origin_entry_point_url)
    ) {
        return <div/>;
    }

    if (ORIGIN_STREAM_TYPE_HTTP === eventContentDetails.origin_stream_type_id) {
        url = eventContentDetails.http_origin_entry_point_url;
    } else if (ORIGIN_STREAM_TYPE_RTMP === eventContentDetails.origin_stream_type_id) {
        url = replaceMatchIdPlaceholderInsideString(
            eventContentDetails.rtmp_origin_stream_name,
            eventContentDetails.match_id
        );
    } else if (ORIGIN_STREAM_TYPE_SRT === eventContentDetails.origin_stream_type_id) {
        url = (eventContentDetails.failover_srt_origin_entry_point_url
            && eventContentDetails.is_srt_origin_entry_point_in_failover)
            ? eventContentDetails.failover_srt_origin_entry_point_url
            : eventContentDetails.srt_origin_entry_point_url;
    }

    const link = (eventContentDetails.rtmp_origin_stream_name || eventContentDetails.srt_origin_entry_point_url)
        ? 'events.streams.play.m3u'
        : (debugMode) ? 'events.streams.play.debug.origin' : 'events.streams.play.origin';

    return (
        <React.Fragment>
            <div>Origin stream ({eventContentDetails.origin_stream_type_name}):&nbsp;</div>
            <div className={'' + eventContentDetails.http_origin_entry_point_url ? 'http' : null}>
                <Link
                    name={link}
                    newWindow={newWindow}
                    params={{
                        id: eventContentDetails.event_id,
                        eventContentId: eventContentDetails.event_content_id,
                    }}
                >
                    {url}
                </Link>
                {hasProperOriginStreamType(eventContentDetails.origin_stream_type_id)
                    &&  <CopyOriginStreamUrlButton
                        eventContentId={eventContentDetails.event_content_id}
                        copyText={'Copy ' + getOriginStreamTypeName(eventContentDetails.origin_stream_type_id) + ' URL'}
                        isEventPage={false}
                    />}
            </div>
        </React.Fragment>
    );
};

OriginStream.propTypes = {
    debugMode: PropTypes.bool.isRequired,
    eventContentDetails: PropTypes.shape({
        event_id: PropTypes.number,
        match_id: PropTypes.number,
        event_content_id: PropTypes.number,
        rtmp_origin_stream_name: PropTypes.string,
        http_origin_entry_point_url: PropTypes.string,
        origin_stream_type_id: PropTypes.number,
        origin_stream_type_name: PropTypes.string,
        srt_origin_entry_point_url: PropTypes.string,
        failover_srt_origin_entry_point_url: PropTypes.string,
        is_srt_origin_entry_point_in_failover: PropTypes.bool,
    }),
    newWindow: PropTypes.bool.isRequired,
};

OriginStream.defaultProps = {
    eventContentDetails: {
        event_id: null,
        event_content_id: null,
        rtmp_origin_stream_name: '',
        http_origin_entry_point_url: '',
        origin_stream_type_id: null,
        origin_stream_type_name: '',
    },
};
