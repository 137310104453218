export const avCompetitorsQuery = `
    query avCompetitors(
        $search: String
    ) {
        avCompetitors(
            filters : {
                search: $search
            }  
        ){
            id
            name
        }
    }
`;

export const avCompetitorQuery = `
    query avCompetitor( $id: ID) {
        avCompetitor(id : $id) {
            id
            name
            creation_datetime
            creation_user {
              id
              name
            }
            update_datetime
            update_user {
              id
              name
            }
        }
    }
`;

export const avCompetitorCreate = `
mutation createAvCompetitor($name: String!){
    createAvCompetitor(name: $name) {
        id
    }
}`;

export const avCompetitorUpdate = `
mutation updateAvCompetitor($id: ID!, $name: String){
    updateAvCompetitor(id: $id, name: $name) {
        id
    }
}`;

export const avCompetitorDelete = `
mutation deleteAvCompetitor($id: ID){
    deleteAvCompetitor(id: $id)
}`;

export const competitorsList = `
query Competitors($sport: ID, $search: String, $tournamentCategory: ID) {
    competitorsForDropdown(filters: {
        search: $search
        sport: $sport
        tournamentCategory: $tournamentCategory
    })
}`;

export const avCompetitorsList = `
query avCompetitors($search: String) {
    avCompetitors(filters : {
                search: $search
    }) {
        id: id
        value: id
        text: name
    }
}`;
