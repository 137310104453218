import ClientAnalyticsSetupLabelModel from "./ClientAnalyticsSetupLabelModel";

export default function ClientAnalyticsSetupLabelModelEdit() {
    let clientAnalyticsSetupLabelModelEdit = ClientAnalyticsSetupLabelModel();

    clientAnalyticsSetupLabelModelEdit.title = () => (
        clientAnalyticsSetupLabelModelEdit.fields.name.props.value
            ? `Label "${clientAnalyticsSetupLabelModelEdit.fields.name.props.value}"`
            : `Label`
    );

    return clientAnalyticsSetupLabelModelEdit;
}
