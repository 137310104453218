import {
    EVENT_SPORTRADAR_TYPE,
    EVENT_NON_SPORTRADAR_TYPE,
    PROPERTY_LICENCE_TYPE_OTHER,
    PROPERTY_LICENCE_TYPE_SPORTRADAR,
} from '@constants/variables';

import {changelogData} from '../changelogData';
import {getNames} from '../utils';
import {BasicData} from '../fragments';

const tournament = `
    tournament {
        id
        name
        parent {
            id
            name
        }
        tournament_category {
            id
            name
            sport {
                id
                name
                has_court
            }
        }
    }
`;

const competitorFragment = (field) => (`
    ${field} {
        id
        name
        is_blacklisted
        country {
            iso_alpha2_code
            name
        }
    }
`);

const avCompetitorFragment = (field) => (`
    ${field} {
        id
        name
        country {
            iso_alpha2_code
            name
        }
    }
`);

const [
    stadium,
    eventType,
    propertyLicense,
    eventStatus,
    rtsCoverage,
    city,
] = getNames([
    'stadium',
    'event_type',
    'property_licence',
    'event_status',
    'rts_coverage',
    'city',
]);

const [
    homeCompetitor,
    awayCompetitor,
    avHomeCompetitor,
    avAwayCompetitor,
] = [
    competitorFragment('home_competitor'),
    competitorFragment('away_competitor'),
    avCompetitorFragment('home_competitor'),
    avCompetitorFragment('away_competitor'),
];

const srEventBody = `
    id
    match {
        id
        has_live_scout
        has_live_odds
        ${rtsCoverage}
        ${tournament}
        ${stadium}
        ${homeCompetitor}
        ${awayCompetitor}
        start_datetime
    }
    synchronise_times
    ${tournament}
    ${homeCompetitor}
    ${awayCompetitor}
    ${stadium}
`;

export const event = `
    query EventQuery($id: ID!) {
        event(id: $id) {
            id
            description
            is_master
            start_datetime
            end_datetime
            notes
            event_preset {
                ${BasicData}
            }
            color
            master_event {
                id 
                description
                is_master
            }
            ${eventType}
            ${eventStatus}
            products {
                id
                short_name
            }
            property_licence {
                id
                name
                sport {
                    id
                    name
                    has_tv_channel
                }
                licensor {
                    id
                    name
                }
            }
            sr_event {
                id
                match {
                    id
                    has_live_scout
                    has_live_odds
                    has_ended
                    ${rtsCoverage}
                    ${tournament}
                    ${homeCompetitor}
                    ${awayCompetitor}
                    match_status {
                        ${BasicData}
                    }
                    league_round_number
                    tournament_round {
                        ${BasicData}
                    }
                    is_cancelled
                    is_postponed
                    is_walkover
                    start_datetime
                    stadium {
                        id
                        name
                        ${city}
                    }
                    started_datetime
                    ended_datetime
                    tv_streaming_encoder {
                        id
                        name
                        stream_url
                    }
                    tv_channels {
                        id
                        name
                    }
                }
                synchronise_times
                ${tournament}
                ${homeCompetitor}
                ${awayCompetitor}
                stadium {
                     id
                     name
                     ${city}
                }
                master_match {
                    id
                    start_datetime
                    end_datetime
                    stadium {
                        id
                        name
                        city {
                            id
                            name
                        }
                    }
                    sport {
                        id
                        name
                    }
                    master_match_tournaments {
                        id
                        tournament {
                            id
                            name
                        }
                    }
                }
                master_event_tournament_products {
                    id
                    master_match_tournament {
                        id
                        tournament {
                          id
                          name
                          tournament_category {
                            id
                            name
                          }
                        }
                    }
                    product {
                        id
                        name
                        short_name
                    }
                }
            }
            av_event {
                id
                content_category {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id 
                            name
                        }
                    }
                }
                venue {
                    id
                    name
                    ${city}
                }
                ${avHomeCompetitor}
                ${avAwayCompetitor}
            }
            has_valid_event_content_for_autobooker_trigger
            ${changelogData}
        }
    }
`;

export const avEventCreateMutation = `
mutation createAvEvent(
    $event: EventInput
    $preset: EventPresetInput
    $home_competitor: ID
    $away_competitor: ID
    $content_category: ID!
    $venue: ID
) {
    createAvEvent(
        event: $event,
        content_category: $content_category,
        home_competitor: $home_competitor,
        away_competitor: $away_competitor,
        venue: $venue,
        preset: $preset
    ) {
        id
        event {
            id
        }
    }
}
`;


export const avEventDeleteMutation = `
mutation deleteEvent($id : ID!) {
    deleteEvent(id : $id)
}
`;

export const srNonExistingEventCreateMutation = `
mutation createSrEvent(
    $event: EventInput
    $preset: EventPresetInput
    $home_competitor: ID
    $away_competitor: ID
    $match: ID
    $tournament: ID
    $stadium: ID
    $synchronise_times: Boolean!
) {
    createSrEvent(
        event: $event,
        match: $match,
        tournament: $tournament,
        stadium: $stadium,
        home_competitor: $home_competitor,
        away_competitor: $away_competitor,
        synchronise_times: $synchronise_times,
        preset: $preset,
    ) {
      id
      event {
        id
      }
    }
}
`;

export const srEventMultipleCreateMutation = `
mutation bulkCreateSrEvents (
    $matches: [ID],
    $property_licence: ID!,
    $description: String,
    $color: String,
    $notes: String,
    $event_status: ID,
    $event_preset: ID,
    $products: [ID]
) {
    bulkCreateSrEvents (
        matches: $matches,
        property_licence: $property_licence,
        description: $description,
        color: $color,
        event_status: $event_status,
        event_preset: $event_preset,
        notes: $notes,
        products: $products
    )
}`;

export const srEventCreateMutation = `
mutation createSrEvent(
    $event: EventInput,
    $match: ID,
    $tournament: ID,
    $stadium: ID,
    $synchronise_times: Boolean,
    $preset: EventPresetInput
) {
    createSrEvent(
        event: $event,
        match: $match,
        tournament: $tournament,
        stadium: $stadium,
        synchronise_times: $synchronise_times,
        preset: $preset
    ) {
      id
      event {
        id
      }
    }
}
`;

export const srEventUpdateMutation = `
mutation updateSrEvent(
    $event: EventInput,
    $match: ID,
    $tournament: ID,
    $away_competitor: ID,
    $home_competitor: ID,
    $stadium: ID,
    $synchronise_times: Boolean
    $id: ID!,
    $preset: EventPresetInput
    $as_additional_contents: Boolean
) {
    updateSrEvent(
        event: $event, 
        match: $match, 
        tournament: $tournament, 
        away_competitor: $away_competitor,
        home_competitor: $home_competitor,
        stadium: $stadium,  
        synchronise_times: $synchronise_times
        id: $id,
        preset: $preset
        as_additional_contents: $as_additional_contents
    ) {
        id
        ${srEventBody}
        event {
          id
          description
          is_master
          start_datetime
          end_datetime
          notes
          color
          event_preset {
                id
          }
          products {
              id
              short_name
          }
          ${eventType}
          ${eventStatus}
          ${propertyLicense}
          master_event {
              id 
              description
              is_master
          }
      }
    }
}
`;

export const srEventDeleteMutation = `
mutation deleteEvent($id : ID!) {
    deleteEvent(id : $id)
}
`;
export const optionsQuery = `
    query optionsQuery($matchId: Int) {
         srEvents(filters: {match: $matchId}) {
            event {
                id
            }
        }
        property_licences: propertyLicencesForDropdown (filters: {
            isArchived: false, 
            propertyLicenceType: ${PROPERTY_LICENCE_TYPE_SPORTRADAR},
            match: $matchId
        }) {
            id
            name
            licensor {
                id
                name
            }
        }
        venues{
            key: id,
            value: id,
            text: name
        }
        event_status :eventStatuses {
            id,
            name,
            key : id,
            value : id,
            text : name
        }
    }
`;

export const nonExistingSportradarOptionsQuery = `
    query optionsQuery {
        property_licences: propertyLicences (filters: {
            isArchived: false, propertyLicenceType: ${PROPERTY_LICENCE_TYPE_SPORTRADAR} 
        })
        event_status :eventStatuses {
            id,
            name,
            key : id,
            value : id,
            text : name
        }
    }
`;

export const avOptionsQuery = `
    query optionsQuery {
        property_licences: propertyLicences (filters: {
            isArchived: false, propertyLicenceType: ${PROPERTY_LICENCE_TYPE_OTHER}
        })
        venues {
            id
            name
            city {
                id
                name
            }
        }
        event_status :eventStatuses {
            id,
            name,
            key : id,
            value : id,
            text : name
        }
    }
`;

export const checkIfMatchIsLinkedToSrEvent = `
    query SrEventsQuery($match: Int) {
        srEvents(filters: {match: $match}) {
            id
        }
    }
`;

export const existingSportradarEventOptions = `
    query options{
        event_status :eventStatuses {
                id,
                name,
                key : id,
                value : id,
                text : name
            }
    }
`;

export const existingSportradarEventByMatch = `
query existingSportradarEventByMatch($id : ID!){
  match(id: $id) {
    id
    start_datetime
    tournament {
      id
      name
      parent{
        id
        name
      }
      tournament_category{
        id
        name
        sport {
          id
          name
          has_tv_channel
        }
      }
    }
    home_competitor {
      id
      name
    }
    away_competitor {
      id
      name
    }
    stadium {
      id
      name
      city {
        id
        name
      }
    }
    has_live_odds
    has_live_scout
    tv_channels {
      id
      name
    }    
  }
}
`;

export const existingSportradarEvent = `
query event($eventId: ID!){
  event(id: $eventId) {
    id
    start_datetime
    end_datetime
    description
    property_licence{
      id
      name
    }
    sr_event{
      match{
        tournament{
          name
          parent{
            tournament_category{
              name
            }
          }
        }
      }
    }
    color
    notes  
  }
}
`;

export const getPropertyLicence = `
query propertyLicence(
    $tournamentCategories: [Int]
){ 
    propertyLicences: propertyLicencesForDropdown ( filters: {
        tournamentCategories: $tournamentCategories,
        propertyLicenceType: 1,
        isArchived: false
    }) {
        id
        name
        licensor {
            id
            name
        }
      }
}`;

export const getMasterEvents = `
query masterEvents($tournamentId: Int){
    events(filters: {
        isMaster: true
        eventType: ${EVENT_SPORTRADAR_TYPE}
        tournament: $tournamentId
    }) {
        key: id
        text: description
        value: id
    }    
}
`;

export const getNonSportradarMasterEvents = `
query masterEvents($contentCategory: Int){
    events(filters: {
        isMaster: true
        eventType: ${EVENT_NON_SPORTRADAR_TYPE}
        contentCategory: $contentCategory
    }) {
        key: id
        text: description
        value: id
    }    
}`;

export const updateSportradarEventStatus = `
mutation updateSrEvent(
     $id: ID
     $event: EventInput
) {
    updateSrEvent(
        id: $id
        event: $event
    ) {
        id
        event {
            ${eventStatus}
        }
    }
}`;

export const updateNonSportradarEventStatus = `
mutation updateAvEvent(
     $id: ID
     $event: EventInput
) {
    updateAvEvent(
        id: $id
        event: $event
    ) {
        id
        event {
            ${eventStatus}
        }
    }
}`;

export const updateExistingSportradarEvent = `
mutation updateSrEvent(
    $event: EventInput,
    $synchronise_times: Boolean
    $id: ID,
    $preset: EventPresetInput
    $as_additional_contents: Boolean
) {
    updateSrEvent(
        event: $event
        synchronise_times: $synchronise_times
        id: $id
        preset: $preset
        as_additional_contents: $as_additional_contents
    ) {
      id
      synchronise_times
      event {
          id
          description
          is_master
          start_datetime
          end_datetime
          notes
          color
          event_preset {
            id
          }
          ${eventType}
          ${eventStatus}
          ${propertyLicense}
          master_event {
              id 
              description
              is_master
          }
          products {
              id
              short_name
          }
      }
    }
}`;

export const eventScheduleQuery = `
  query EventScheduleQuery (
    $search: String
    $from: DatetimeTzInputType
    $to: DatetimeTzInputType
    $propertyLicence: [ID]
    $eventPreset: [ID]
    $eventType: ID
    $sport: [ID]
    $tvChannels: [ID]
    $category: [ID]
    $tournament: [ID]
    $contentCategoryLevel1: [ID]
    $contentCategoryLevel2: [ID]
    $contentCategoryLevel3: [ID]
    $stadium: [ID]
    $venue: [ID]
    $scheduled: Boolean
    $isMaster: Boolean
    $hasBlacklistedCompetitor: Boolean
    $hasMatchId: Boolean
    $eventStatus: [ID]
    $contributionType: [ID]
    $encodingDatacenter: [ID]
    $product: [ID]
    $productStatus: [ID]
    $homeCompetitor: Boolean
    $awayCompetitor: Boolean
    $rtsCoverage: Boolean
    $isTvStreaming: Boolean
    $hasLiveScout: Boolean
    $hasLiveOdds: Boolean
    $isCancelled: Boolean
    $isPostponed: Boolean
    $isWalkover: Boolean
    $isInterrupted: Boolean
    $isSuspended: Boolean
    $isAbandoned: Boolean
    $isRetired: Boolean
    $hasEventReport: Boolean
    $eventContentTypes: [ID]
    $eventContentVariants: [ID]
    $coverage: [ID]
  ) {
    eventSchedule (filters: {
      search: $search
      from: $from
      to: $to
      propertyLicence: $propertyLicence
      eventPreset: $eventPreset
      eventType: $eventType
      sport: $sport
      tvChannels: $tvChannels
      category: $category
      tournament: $tournament
      contentCategoryLevel1: $contentCategoryLevel1
      contentCategoryLevel2: $contentCategoryLevel2
      contentCategoryLevel3: $contentCategoryLevel3
      stadium: $stadium
      venue: $venue
      scheduled: $scheduled
      isMaster: $isMaster
      hasBlacklistedCompetitor: $hasBlacklistedCompetitor
      hasMatchId: $hasMatchId
      eventStatus: $eventStatus
      contributionType: $contributionType
      encodingDatacenter: $encodingDatacenter
      product: $product
      productStatus: $productStatus
      homeCompetitor: $homeCompetitor
      awayCompetitor: $awayCompetitor
      rtsCoverage: $rtsCoverage
      isTvStreaming: $isTvStreaming
      hasLiveScout: $hasLiveScout
      hasLiveOdds: $hasLiveOdds
      isCancelled: $isCancelled
      isPostponed: $isPostponed
      isWalkover: $isWalkover
      isInterrupted: $isInterrupted
      isSuspended: $isSuspended
      isAbandoned: $isAbandoned
      isRetired: $isRetired
      hasEventReport: $hasEventReport
      eventContentType: $eventContentTypes
      eventContentVariant: $eventContentVariants
      coverage: $coverage
    }) {
      events
      matches
      masterMatches 
    }
  }
`;

export const eventScheduleFiltersQuery = `
  query EventScheduleFiltersQuery {
    contributionTypes {
      key: id
      text: name
      value: id
    }
    encodingDatacenters {
      key: id
      text: name
      value: id
    }
    propertyLicences: propertyLicencesForDropdown {
      id
      name
      licensor {
        id
        name
      }
    }
    eventTypes {
      key: id
      text: name
      value: id
    }
    eventContentTypes {
      key: id
      value: id
      text: name
    }
    eventContentVariants {
      key: id
      text: name
      value: id
    }
    eventStatuses {
      key: id
      text: name
      value: id
    }
    productStatuses {
      key: id
      text: name
      value: id
    }
    products {
      key: id
      text: short_name
      value: id
      has_event_booking_support
    }
    venues {
      key: id
      text: name
      value: id
    }
  }
`;

export const getPresetList = `
query PresetsQuery($propertyLicence: [ID], $excludeEvent: ID) {
    eventPresets(filters: {propertyLicence: $propertyLicence, excludeEvent: $excludeEvent}) {
        id
        name
        event {
            id
            master_event {
                id
            }
            description
            color
            event_status {
                id
            }
            products {
                id
                short_name
            }
            notes
        }
    }
}`;
export const eventStatuses = `
  query EventStatusesQuery {
    eventStatuses {
      id
      name
      value: id
    }
  }
`;
