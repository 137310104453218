import PropTypes from 'prop-types';
import {useState} from 'react';
import {connect} from 'react-redux';

import {withApollo} from 'react-apollo';
import * as MESSAGES from '@constants/messages';
import {errorMessage, overlapConfirmationModal} from '@utils/CidrHelpers';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {CreateClientWhitelistedIp} from '@graphql/clientWhitelistedIp/mutation';
import {CreateClientAvApiWhitelistedIp} from '@graphql/clientAvapiWhitelistedIp/mutation';

import {cidrOverlapDetailsRenderer} from '../utils/whitelistingHelper';

const WhitelistedIpFormConfirmation = ({
    client,
    dataToSave,
    MessageBox: {
        addMessage,
    },
    Modal: {
        setModal,
    },
    overlappingClientWhitelistedIps,
    setMutationSuccess,
    stepsMethods: {
        showStep,
    },
    isAvApiAccess,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const data = Object.assign({}, dataToSave, {
        ip_version: convertToInt(dataToSave.ip_version),
        description: dataToSave.description ? dataToSave.description : null,
    });

    const onClickNo = () => showStep('step1'),
        onClickYes = () => {
            setIsLoading(true);

            client.mutate({
                mutation: isAvApiAccess ? CreateClientAvApiWhitelistedIp : CreateClientWhitelistedIp,
                variables: {...data},
            }).then(() => {
                setIsLoading(false);
                setMutationSuccess(Date.now());
                addMessage(
                    'whitelistedIpMessage',
                    MESSAGES.SAVED(MESSAGES.CIDR, data.cidr),
                    null,
                    'success'
                );
                setModal({isVisible: false});
            }).catch(() => {
                setIsLoading(false);
                showStep('step1');
                addMessage(
                    'formInnerErrorMessage',
                    MESSAGES.NOT_SAVED(MESSAGES.CIDR, data.cidr),
                    `${errorMessage.trim()} of the client.`,
                    'error'
                );
            });
        };

    return overlapConfirmationModal(
        `This CIDR "${dataToSave.cidr}" overlaps the following blacklisted CIDRs:`,
        cidrOverlapDetailsRenderer(overlappingClientWhitelistedIps),
        `Are you sure you want to add this new whitelisted CIDR?`,
        onClickYes,
        onClickNo,
        isLoading
    );
};

WhitelistedIpFormConfirmation.propTypes = {
    client: PropTypes.object.isRequired,
    dataToSave: PropTypes.object.isRequired,
    MessageBox: PropTypes.shape({
        addMessage: PropTypes.func.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
    overlappingClientWhitelistedIps: PropTypes.array.isRequired,
    setMutationSuccess: PropTypes.func.isRequired,
    stepsMethods: PropTypes.shape({
        showStep: PropTypes.func.isRequired,
    }),
};

export default withApollo(connect(null, mapModulesToProps(['Modal', 'MessageBox']))(WhitelistedIpFormConfirmation));
