import React from "react";
import {Header} from "semantic-ui-react";
import PropTypes from "prop-types";

const FormHeader = (props) => {
    return  <Header>{props.text}</Header>
};

FormHeader.propTypes = {
    text: PropTypes.string.isRequired,
};

export default FormHeader;
