import {convertToInt} from "../../../utils/helpers";

import {EVENT_TYPE_SPORTRADAR} from "../../app/constants/variables";

/**
 * Check if event is
 * - Sportradar
 * - and no master event
 * @returns {boolean}
 */
export const isSportradarEventAndNoMaster = (event) => (
    EVENT_TYPE_SPORTRADAR === convertToInt(event.event_type.id) && !event.is_master
)
