import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";

import {AKAMAI_LIVE_STREAM_NAME} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE} from "../../app/constants/variables";

const AkamaiLiveStreamNamesIndexButtons = (props) => (
    <div className="content__buttons">
        <Authorization
            privileges={SECURITY_PRIVILEGES_CREATE}
            resources={AKAMAI_LIVE_STREAM_NAME}
        >
            <Link name={'clients.clientCdnConfiguration.akamaiLiveStreamName.add'}
                params={{
                    id: props.clientId,
                    clientCdnConfigurationId: props.clientCdnConfigurationId
                }}
            >
                <ButtonAdd>{'Add new Akamai Live stream name'}</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

AkamaiLiveStreamNamesIndexButtons.propTypes = {
    clientCdnConfigurationId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
};

export default AkamaiLiveStreamNamesIndexButtons;
