import {get as _get} from "lodash";

import SportradarMasterEventModel from "./SportradarMasterEventModel";
import {event as getEventGraphQL} from "../../../graphql/events/events";
import Form from "../../app/components/ReduxFormControls";

export default function SportradarMasterEventEdit() {
    let SportradarMasterEventEdit = SportradarMasterEventModel();

    SportradarMasterEventEdit.editForm = true;
    SportradarMasterEventEdit.title = (props, type, data) => {
        let masterMatchId = _get(data, "formParams.optionsVariables.masterMatchId",  null);

        return `Edit Sportradar master event ${data.formParams.id} (master match ID ${masterMatchId})`
    };

    SportradarMasterEventEdit.query.getEntity =  getEventGraphQL;
    SportradarMasterEventEdit.fields.property_licence.props.disabled = true;
    SportradarMasterEventEdit.fields.status.props.hidden = true;
    SportradarMasterEventEdit.fields.id = {
        component: Form.IdField,
        dataMapKey: "event.sr_event.id",
        props: {
            label: '',
            name: 'id',
            hidden: true
        }
    };

    return SportradarMasterEventEdit;
}
