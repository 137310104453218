import PropTypes from 'prop-types';

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_ip_whitelisting_geo_restrictions`,
    parseData: cdnConfiguration => [
        cdnConfiguration.ip_version.name,
        cdnConfiguration.cidr,
        cdnConfiguration.products.map((product) => (product.short_name)).join(', '),
        cdnConfiguration.description,
        cdnConfiguration.creation_datetime,
        cdnConfiguration.disable_datetime || '',
    ],
    path: 'clients.whitelistedIps.geoRestrictions.index.path',
    titles: [
        'IP version',
        'CIDR',
        'Products',
        'Description',
        'Added',
        'Disabled',
    ],
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired,
};
