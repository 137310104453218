import {get as _get} from 'lodash';

import {withApollo} from 'react-apollo';
import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import * as formUtils from '@utils/forms';
import {showModal} from '@utils/modal';
import {convertToInt, filterDisabledEncodingJobProfiles, isUrlParamValid} from '@utils/helpers';
import {UPLOAD_TARGET_TYPE_AMAZON_S3} from '@constants/variables';

class ThirdPartyClientVodStorageForm extends DefaultForm {
    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        let uploadTargetType = _get(nextProps, 'formValues.upload_target_type', null);

        if (uploadTargetType) {
            this.handleUploadTargetTypeChanged(uploadTargetType);
        }

        if (!this.getIsLoading()) {
            this.setEncodingJobProfileOptions(
                nextProps.GraphQLOptionsData.encodingJobProfiles,
                (null !== nextProps.formParams.id)
                    ? nextProps.GraphQLEntityData.thirdPartyClientVodStorage.encoding_job_profile.id
                    : null
            );
        }
    }

    componentWillMount() {
        super.componentWillMount();

        this.setOnChangeCallback({
            upload_target_type: (data) => {
                this.handleUploadTargetTypeChanged(data.value);
            },
        });
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.formParams.id)) {
            return showModal({
                isVisible: true,
                content: formUtils.renderModalError('VOD storage',
                    getLink(`clients.products.thirdParty.vodStorages.index`, {
                        id: this.props.formParams.clientId,
                    })
                ),
            });
        }
    }

    setEncodingJobProfileOptions = (data, selectedOption) => {
        this.setField('encoding_job_profile', {
            options: filterDisabledEncodingJobProfiles(data, selectedOption)
        });
    };

    handleUploadTargetTypeChanged = (value) => {
        let val = convertToInt(value);

        this.setField('secret_key', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 !== val});
        this.setField('access_key', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 !== val});
        this.setField('region', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 !== val});
        this.setField('bucket', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 !== val});
        this.setField('hostname', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 === val});
        this.setField('username', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 === val});
        this.setField('password', {hidden: UPLOAD_TARGET_TYPE_AMAZON_S3 === val});
    };

    prepareDataForSubmit = (data) => {
        let dataToSave = {};

        const getThirdPartyClientVodStorageData = (data) => ({
            client_product_third_party: this.props.formParams.thirdParty,
            name: data.name,
            upload_target_type: convertToInt(data.upload_target_type),
            path: data.path,
            encoding_job_profile: convertToInt(data.encoding_job_profile),
            upload_metadata_file: !!data.upload_metadata_file,
        });

        const getThirdPartyClientVodStorageAmazonS3Data = (data) => ({
            secret_key: data.secret_key,
            access_key: data.access_key,
            region: data.region,
            bucket: data.bucket,
        });

        const getThirdPartyClientVodStorageFtpData = (data) => ({
            hostname: data.hostname,
            username: data.username,
            password: data.password,
        });

        if (data.id) {
            dataToSave = {
                id: convertToInt(data.id),
                ...getThirdPartyClientVodStorageData(data),
            };

            if (UPLOAD_TARGET_TYPE_AMAZON_S3 === convertToInt(data.upload_target_type)) {
                dataToSave = Object.assign(dataToSave, {
                    third_party_client_vod_storage_amazon_s3: getThirdPartyClientVodStorageAmazonS3Data(data),
                    third_party_client_vod_storage_ftp: null,
                });
            } else {
                dataToSave = Object.assign(dataToSave, {
                    third_party_client_vod_storage_amazon_s3: null,
                    third_party_client_vod_storage_ftp: getThirdPartyClientVodStorageFtpData(data),
                });
            }
        } else {
            dataToSave = {
                third_party_client_vod_storage: getThirdPartyClientVodStorageData(data),
            };

            if (UPLOAD_TARGET_TYPE_AMAZON_S3 === convertToInt(data.upload_target_type)) {
                dataToSave = Object.assign(dataToSave, getThirdPartyClientVodStorageAmazonS3Data(data));
            } else {
                dataToSave = Object.assign(dataToSave, getThirdPartyClientVodStorageFtpData(data));
            }
        }

        return dataToSave;
    };

    renderErrors() {
        this.props.Modal.setModal({
            isVisible: true,
            content: formUtils.renderModalError(
                'VOD storage',
                getLink("clients.products.thirdParty.vodStorages.index", {
                    id: this.props.formParams.clientId || null,
                    thirdPartyClientVodStorageId: this.props.formParams.thirdPartyClientVodStorageId || null,
                }),
                'VOD storage not found.'
            ),
        });
    }
}

export default withApollo(ThirdPartyClientVodStorageForm);
