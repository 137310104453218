import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';
import {Form as SemanticForm} from 'semantic-ui-react';

import Form from '@appComponents/ReduxFormControls';

export const DROPDOWN_DEFAULT_VALUE = '1';

export const ProductField = ({
    data: {product = {}, productStatuses = {}},
    dropdownDefaultValue = DROPDOWN_DEFAULT_VALUE,
    dropdownHidden = true,
    timestamp = null,
    toggleChecked = false,
    disabled = false,
}) => {
    const [isDropdownHidden, setIsDropdownHidden] = React.useState(true);
    const [isProductChecked, setIsProductChecked] = React.useState(false);

    if (_isEmpty(product) || _isEmpty(productStatuses)) {
        return (
            <React.Fragment />
        );
    }

    React.useEffect(() => {
        setIsProductChecked(toggleChecked);
        toggleProductState({checked: toggleChecked});
    }, [toggleChecked]);

    React.useEffect(() => {
        setIsDropdownHidden(dropdownHidden);
    }, [dropdownHidden]);

    const toggleProductState = ({checked}) => {
        setIsDropdownHidden((true !== checked));
    };

    const switchCheckbox = (props, {checked}) => {
        setIsProductChecked(checked);
    };

    return (
        <SemanticForm.Group inline>
            <span className={'inline_checkbox_label --tech-setup-product-label'}>
                <label className='--withPointer' htmlFor={`toggle_${product.id}`}>
                    {product.short_name}
                </label>
            </span>
            <Form.FormField
                checked={isProductChecked}
                component={Form.SemanticInput}
                defaultValue={isProductChecked}
                id={product.id}
                inline
                name={`toggle_${product.id}`}
                onCheckboxChange={toggleProductState}
                onClick={switchCheckbox}
                type={'toggle'}
                disabled={disabled}
            />
            <Form.FormField
                component={Form.SemanticSelect}
                defaultValue={dropdownDefaultValue}
                timestamp={timestamp}
                hidden={isDropdownHidden}
                inline
                name={`status_${product.id}`}
                options={productStatuses}
                disabled={disabled}
            />
        </SemanticForm.Group>
    );
};
ProductField.propTypes = {
    dropdownDefaultValue: PropTypes.string,
    dropdownHidden: PropTypes.bool,
    data: PropTypes.shape({
        product: PropTypes.object,
        productStatuses: PropTypes.array,
    }),
    disabled: PropTypes.bool,
    timestamp: PropTypes.number,
    toggleChecked: PropTypes.bool,
};
