
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SlateTableData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Slate"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"svg"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SlateDetailedData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Slate"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SlateTableData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mute_when_enabled"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SlateData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Slate"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SlateDetailedData"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChangelogData"},"directives":[]}]}}],"loc":{"start":0,"end":257}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment SlateTableData on Slate {\n    id\n    name\n    svg\n}\n\nfragment SlateDetailedData on Slate {\n    ...SlateTableData\n    mute_when_enabled\n}\n\nfragment SlateData on Slate {\n    ...SlateDetailedData\n    ...ChangelogData\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
