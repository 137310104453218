import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Header} from 'semantic-ui-react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {
    get as _get,
    isEqual as _isEqual,
    isNil as _isNil,
    isNumber as _isNumber,
} from 'lodash';
import moment from 'moment';

import {graphql} from 'react-apollo';
import * as ContentGraphQL from '@graphql/clients/mutation';
import {clipConfigOptions} from '@graphql/clipConfiguration/query';
import {CLIENT_PACKAGE_CONTENT} from '@constants/resources';
import Form from '@appComponents/ReduxFormControls';
import {FormWrapper} from '@appComponents/HOCFormWrapper';
import {ButtonCancel} from '@appComponents/ButtonCollection';
import {IconInfoCircle} from '@appComponents/IconCollection';
import MessageBox from '@appComponents/MessageBox';
import {
    convertToInt,
    defaultValuePrice,
    formatPrice,
    parseFloatOrNull,
    parseIntOrNull,
    parseToBoolean,
} from '@utils/helpers';
import {hideModal, showModalConfirmation} from '@utils/modal';
import {showMessageBox} from '@utils/messageBox';
import EventContentTypesField from '@modules/client/forms/ClientPackageContent/Fields/EventContentTypesField';
import EventContentVariantsField from '@modules/client/forms/ClientPackageContent/Fields/EventContentVariantsField';
import {YES_OR_NO_FOR_PACKAGE_FORM} from '@constants/staticDropdowns';
import {getLastMinuteOfToday, getOneHourAgoDateTime, getTodayDate} from '@modules/client/utils/constraintsTables';

import validate from './validatorClientContentPackage';

class ContentPackageForm extends React.Component {
    static propTypes = {
        bookingTypes: PropTypes.array.isRequired,
        eventContentVariants: PropTypes.array.isRequired,
        clientPackageId: PropTypes.number.isRequired,
        CreateClientPackageContent: PropTypes.func,
        DeleteClientPackageIds: PropTypes.func,
        ClipConfigOptions: PropTypes.object,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        entityName: PropTypes.string.isRequired,
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        parentTreeData: PropTypes.object.isRequired,
        reloadTable: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        treeData: PropTypes.array,
        UpdateClientPackageContent: PropTypes.func,
        UpdateBookingsPrice: PropTypes.func,
        header: PropTypes.string,
        isActive: PropTypes.bool,
        treeActiveStatus: PropTypes.object,
        productVariants: PropTypes.array,
        product: PropTypes.object,
        level: PropTypes.number,
        backToIndexUrl: PropTypes.func,
    };

    static defaultProps = {
        isActive: false,
    }

    state = {
        updatePastBookings: false,
        displayUpdatePastBookingsSwitch: false,
        shouldShowDatePicker: false,
        maxTime: '',
        startDatetime: '',
    }

    parseEventContentTypes = (formDataTypes) => {
        if (!formDataTypes) {
            return [];
        }

        return formDataTypes.map(eventContentType => {
            return {
                event_content_type: eventContentType.value,
                match_event_types: (eventContentType.children || []).map(matchEventType => {
                    return matchEventType.value;
                }),
            };
        });
    };

    areFormDataEqual = (dataToSave) => {
        const {
            id,
            booking_type,
            event_price,
            is_hq,
            event_content_type_configs,
            event_content_variants,
            is_active,
            num_bookable_events,
        } = this.props.formData;

        const initialValues = {
            id: id,
            booking_type: booking_type || null,
            event_price: formatPrice(event_price),
            is_hq: is_hq || null,
            event_content_type_configs: event_content_type_configs,
            event_content_variants: event_content_variants ? this.parseEventContentVariants(event_content_variants) : [],
            is_active: is_active,
            num_bookable_events: parseIntOrNull(num_bookable_events),
        };

        const newValues = {
            id: dataToSave.id,
            booking_type: dataToSave.booking_type,
            event_price: formatPrice(dataToSave.event_price),
            is_hq: dataToSave.is_hq,
            event_content_type_configs: dataToSave.event_content_type_configs,
            event_content_variants: dataToSave.event_content_variants,
            is_active: dataToSave.is_active,
            num_bookable_events: parseIntOrNull(dataToSave.num_bookable_events),
        };

        return _isEqual(newValues, initialValues);
    }

    parseEventContentVariants = (variants) => variants.map((variant) => convertToInt(variant.id));

    updatePriceOfPastBookings = async (dataToSave) => {
        const dataToUpdatePastBookingsPrice = {
            id: dataToSave.id,
            start_datetime: dataToSave.start_datetime,
        };

        if (this.areFormDataEqual(dataToSave) && dataToSave.update_past_bookings) {
            this.props.UpdateBookingsPrice({
                variables: {...dataToUpdatePastBookingsPrice},
            });
        } else {
            try {
                await this.props.UpdateClientPackageContent({
                    variables: {...dataToSave},
                });
            } catch (error) {
                throw new Error('Error while updating client package content.');
            }

            if (dataToSave.update_past_bookings) {
                try {
                    await this.props.UpdateBookingsPrice({
                        variables: {...dataToUpdatePastBookingsPrice},
                    });
                } catch (error) {
                    throw new Error('The price for event past bookings was not updated.');
                }
            }
        }
    };

    formSubmit = async (data) => {
        const eventPrice = parseFloatOrNull(data.event_price);
        const numBookableEvents = parseIntOrNull(data.num_bookable_events);
        const shouldUpdatePastBookings = eventPrice || 0 === eventPrice ? data.update_past_bookings : false;
        const startDatetime = shouldUpdatePastBookings ? data.start_datetime : null;

        if (
            !data.event_content_variants.length
            &&!data.event_content_type_configs?.length
            && null === numBookableEvents
            && !data.booking_type
            && !data.event_booking_type
            && _isNil(data.is_hq)
            && null === eventPrice
            && !startDatetime
        ) {
            showMessageBox('contentPackageFormErrorBox', null, 'At least one field should not be empty.', 'error');

            return;
        }

        let dataToSave = Object.assign({}, this.props.dataMap, data),
            dataSaved;

        const entityName = `settings for "${this.props.entityName}"`;

        dataToSave.num_bookable_events = numBookableEvents;
        dataToSave.booking_type = dataToSave.booking_type || null;
        dataToSave.event_booking_type = dataToSave.event_booking_type || null;
        dataToSave.is_hq = _isNil(dataToSave.is_hq) ? null : parseToBoolean(convertToInt(dataToSave.is_hq));
        dataToSave.event_price = eventPrice;
        dataToSave.event_content_type_configs = this.parseEventContentTypes(dataToSave.event_content_type_configs, dataToSave.id);
        dataToSave.start_datetime = startDatetime + ':00';

        if (!dataToSave.id) {
            const defaultData = {
                sport: null,
                tournament: null,
                tournament_category: null,
                property_licence: null,
            };

            dataToSave = {...defaultData, ...this.props.parentTreeData, ...dataToSave, ...{
                client_package: convertToInt(this.props.clientPackageId),
            }};

            dataSaved = await this.props.CreateClientPackageContent({
                variables: {...dataToSave},
            });

            dataToSave.id = _get(dataSaved.data, 'createClientPackageContent.id', null);

            if (shouldUpdatePastBookings && dataToSave.id) {
                const updateData = {...dataToSave, id: dataToSave.id};
                dataSaved = this.props.UpdateBookingsPrice({
                    variables: {...updateData},
                });
            }
        } else {
            if (!shouldUpdatePastBookings) {
                dataSaved = this.props.UpdateClientPackageContent({
                    variables: {...dataToSave},
                });
            } else {
                dataSaved = this.updatePriceOfPastBookings(dataToSave);
            }
        }

        try {
            await dataSaved;
            showMessageBox(
                'contentPackageMessage',
                `The ${entityName} have been saved successfully.`,
                null,
                'success'
            );
            this.props.reloadTable();
        } catch (error) {
            showMessageBox(
                'contentPackageMessage',
                `The ${entityName} have not been saved successfully.`,
                `${error}`,
                'error'
            );
        } finally {
            hideModal();
        }

    };

    isAnyParentActive = (treeActiveStatus) => {
        if (!treeActiveStatus.parent) {
            return false;
        }

        if (treeActiveStatus.is_active) {
            return true;
        }

        return this.isAnyParentActive(treeActiveStatus.parent);
    }

    deleteContentPackage = () => {
        const entityName = ` settings for "${this.props.entityName}"`,
            contentPackageId = this.props.dataForForm.id;

        let handleDeletePackage = null;

        showModalConfirmation({
            header: <Header icon='trash' content={'Remove content settings'}/>,
            text: `The settings for "${this.props.entityName}" are being removed...`,
        });

        handleDeletePackage = this.props.UpdateClientPackageContent({
            variables: {
                id: contentPackageId,
                booking_type: null,
                event_booking_type: null,
                event_content_type_configs: [],
                event_content_variants: null,
                is_hq: null,
                num_bookable_events: null,
                event_price: null,
            },
        });

        handleDeletePackage.then(() => {
            showMessageBox(
                'contentPackageMessage',
                `The ${entityName} have been removed successfully.`,
                null,
                'success'
            );
            hideModal();
            this.props.reloadTable();

            return false;
        }).catch((error) => {
            hideModal();
            showMessageBox('contentPackageMessage', this.props.entityName, error, 'error');
        });
    };

    getIdsForChildren = (data, id, getChildren = false) => {
        let ids = [];

        if (!data) {
            return ids;
        }

        for (let i = 0; i < data.length; i++) {
            const rowData = data[i];

            if (rowData.id && getChildren) {
                ids.push(parseInt(rowData.id));
            }

            if (rowData.children && 0 < rowData.children.length) {
                ids = ids.concat(this.getIdsForChildren(rowData.children, id, (getChildren || rowData.id === id)));
            }
        }

        return ids;
    };

    deleteContentPackageAction = () => {
        showModalConfirmation({
            header: <Header icon='trash' content='Remove content settings'/>,
            onYes: this.deleteContentPackage,
            text: `Are you sure you want to remove the settings for "${this.props.entityName}"?`,
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (this.props.dataForForm.id
            && (this.props.dataForForm.booking_type
                || this.props.dataForForm.event_booking_type
                || this.props.dataForForm.event_content_variants.length
                || this.props.dataForForm.num_bookable_events
                || this.props.dataForForm.event_content_type_configs.length
                || _isNumber(this.props.dataForForm.event_price)
                || !_isNil(this.props.dataForForm.is_hq))
        ) {
            deleteButton =
                <Button onClick={this.deleteContentPackageAction}
                    color='red'
                    disabled={this.props.submitting}
                    icon='trash'
                    content='Remove settings'/>;
        }

        return deleteButton;
    };

    showDatePicker = (toggleShow) => {
        this.setState({shouldShowDatePicker: toggleShow});
    };

    showUpdatePastBookings = (event) => {
        const currentEventPrice = this.props.formData.event_price;
        const newEventPrice = _get(event, 'target.value', null);

        if ((currentEventPrice || 0 === currentEventPrice) && '' !== newEventPrice || newEventPrice) {
            this.setState({displayUpdatePastBookingsSwitch: true});
        } else {
            this.setState({
                displayUpdatePastBookingsSwitch: false,
                shouldShowDatePicker: false,
            });
        }
    };

    updateMaxTimeBasedOnDate(selectedDateTime, options) {
        let maxTime;

        if (moment(selectedDateTime).isSameOrAfter(getTodayDate(), 'hour')) {
            maxTime = options.first;
        } else {
            maxTime = options.second;
        }

        this.setState({maxTime});
    }

    onCancel = () => {
        hideModal();
        this.props.backToIndexUrl();
    };

    render() {
        return (
            <div>
                <Header as='h3'>{this.props.header}</Header>
                <MessageBox name={'contentPackageFormErrorBox'} className='--formInnerError'/>
                <Form.Create
                    onSubmit={this.props.handleSubmit(this.formSubmit)}
                    loading={(this.props.formData !== undefined) ? this.props.formData.loading : false}
                    className={'client-content-package-form --paddingTop-0'}
                >
                    <Form.IdField defaultValue={ this.props.formData.id } />
                    <Form.IdField hidden={true} name={'is_active'} defaultValue={ this.props.isActive } />
                    {this.props.product.has_event_booking_support &&
                        <div className='formRow --align-baseline'>
                            <div className='--label-container'><label>Event booking type</label></div>
                            <div className='input-container'>
                                <Field
                                    component={Form.SemanticSelect}
                                    name='event_booking_type'
                                    placeholder='Same as parent'
                                    allowClear={true}
                                    defaultValue={_get(this.props, 'formData.event_booking_type.id', null)}
                                    options={this.props.bookingTypes}
                                    required
                                />
                            </div>
                        </div>
                    }
                    <div className='formRow --align-baseline'>
                        <div className='--label-container'><label>Booking type</label></div>
                        <div className='input-container'>
                            <Field
                                component={Form.SemanticSelect}
                                name='booking_type'
                                placeholder='Same as parent'
                                allowClear={true}
                                defaultValue={_get(this.props, 'formData.booking_type.id', null)}
                                options={this.props.bookingTypes}
                                required
                            />
                        </div>
                    </div>
                    <div className='formRow --align-center'>
                        <div className='--label-container'><label>Content variants</label></div>
                        <div className='input-container checkboxes-inline'>
                            <EventContentVariantsField
                                productVariants={this.props.productVariants}
                                eventContentVariants={this.props.eventContentVariants}
                                selectedContentVariants={_get(this.props, 'formData.event_content_variants', null)}
                            />
                            &nbsp;<IconInfoCircle className={'--event-price-info'} tooltip={'Leave empty to inherit from parent.'} />
                        </div>
                    </div>
                    {this.props.product.has_hq_support &&
                        <div className='formRow --align-baseline'>
                            <div className='--label-container'><label>HQ</label></div>
                            <div className='input-container'>
                                <Field
                                    component={Form.SemanticSelect}
                                    name='is_hq'
                                    placeholder='Same as parent'
                                    allowClear={true}
                                    defaultValue={_isNil(this.props.formData.is_hq) ? null : this.props.formData.is_hq ? '1' : '0'}
                                    options={YES_OR_NO_FOR_PACKAGE_FORM}
                                />
                            </div>
                        </div>
                    }
                    <div className='formRow --align-center'>
                        <div className='--label-container'><label>Number of bookable events</label></div>
                        <div className='input-container'>
                            <Field
                                component={Form.SemanticInput}
                                defaultValue={this.props.formData.num_bookable_events}
                                name='num_bookable_events'
                                min={0}
                                type='number'
                            />
                        </div>
                    </div>
                    <div className='formRow --align-baseline'>
                        <div className='--label-container'><label>Event price</label></div>
                        <div className='input-container'>
                            <Field
                                component={Form.SemanticInput}
                                defaultValue={defaultValuePrice(this.props.formData.event_price)}
                                name='event_price'
                                min={0}
                                onChange={this.showUpdatePastBookings}
                                icon='euro'
                                type='number'
                                className='client-content-package-form--event-price'
                            />
                            &nbsp;<IconInfoCircle className={'--event-price-info'} tooltip={'Leave empty to inherit from parent.'} />
                        </div>
                    </div>
                    {this.state.displayUpdatePastBookingsSwitch &&
                        <div className='formRow --align-center'>
                            <div className='--label-container'><label>Update price of past bookings?</label></div>
                            <div className='input-container'>
                                <Field
                                    component={Form.SemanticInput}
                                    type='toggle'
                                    name='update_past_bookings'
                                    onChange={this.showDatePicker}
                                    className='client-content-package-form--update-price'
                                    defaultValue={this.state.updatePastBookings}
                                />
                            </div>
                        </div>
                    }
                    {this.state.shouldShowDatePicker &&
                        <div className='formRow --align-baseline'>
                            <div className='--label-container'><label>Update from*</label></div>
                            <div className='input-container --start_datetime'>
                                <Field
                                    className='--minimalWidth --datetimeWithoutSeconds'
                                    component={Form.SemanticInput}
                                    name='start_datetime'
                                    type='date'
                                    onChange={(startDatetime) => {
                                        this.updateMaxTimeBasedOnDate(startDatetime, {
                                            first: getOneHourAgoDateTime(),
                                            second: getLastMinuteOfToday(),
                                        });
                                    }}
                                    configuration={{
                                        minTime: moment().hours(0).minutes(0),
                                        maxTime: this.state.maxTime,
                                        minDate: moment().subtract(1, 'years'),
                                        maxDate: getLastMinuteOfToday(),
                                        timeFormat: 'HH:mm',
                                        showTimeSelect: true,
                                        shouldCloseOnSelect: false,
                                    }}
                                    defaultValue={getTodayDate()}
                                />
                                &nbsp;<IconInfoCircle tooltip={'Please select a date and time in the past but not earlier than 1 year ago.'} />
                            </div>
                        </div>
                    }
                    {this.props.product.has_event_booking_support &&
                        <div className='formRow'>
                            <div className='--label-container --align-baseline'><label>Clips</label></div>
                            <div className='input-container'>
                                <EventContentTypesField
                                    ClipConfigOptions={this.props.ClipConfigOptions}
                                    selectedOptions={_get(this.props, 'formData.event_content_type_configs', null)}
                                    level={this.props.level}
                                />
                            </div>
                        </div>
                    }
                    {this.props.formData.id && <Form.FormRowChangelog
                        resources={CLIENT_PACKAGE_CONTENT}
                        data={ (this.props.formData || {}) }
                    />}
                    <div className='formRow form__footer'>
                        <label/>
                        <Button
                            color='blue'
                            type='submit'
                            loading={ this.props.submitting }
                            disabled={ this.props.submitting }
                            icon='save'
                            content='Save'/>
                        { this.renderDeleteButton() }
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={ this.onCancel }
                        >
                            Cancel
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </div>
        );
    }
}

const ContentPackageFormWithRedux = reduxForm({form: 'ContentPackageForm', validate})(ContentPackageForm);

export default (compose(
    graphql(ContentGraphQL.updateBookingsPrice, {name: 'UpdateBookingsPrice'}),
    graphql(ContentGraphQL.updateClientPackageContent, {name: 'UpdateClientPackageContent'}),
    graphql(ContentGraphQL.createClientPackageContent, {name: 'CreateClientPackageContent'}),
    graphql(ContentGraphQL.deleteBulkClientPackageContents, {name: 'DeleteClientPackageIds'}),
    graphql(clipConfigOptions, {
        skip: (props) => {
            return !props.product.has_event_booking_support;
        },
        options: (props) => ({
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'no-cache',
            variables: {
                preferredClipConfigFilters: {
                    product: [props.product.id],
                    tournaments: [props.formData.tournament?.id],
                },
                fallbackClipConfigsFilters: {
                    product: [props.product.id],
                    sports: [props.formData.sport?.id ?? props.parentTreeData?.sportId],
                    tournamentsEmpty: true,
                },
            },
        }),
        name: 'ClipConfigOptions',
    })
)(FormWrapper({
    id: '',
    is_active: false,
    booking_type: null,
    event_booking_type: null,
    event_content_variants: null,
    is_hq: null,
    event_content_type_configs: [],
    num_bookable_events: null,
    event_price: null,
    update_past_bookings: false,
    level: null,
}, ContentPackageFormWithRedux)));
