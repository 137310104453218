import PropTypes from 'prop-types';
import React from 'react';
import {Button, Grid} from 'semantic-ui-react';
import {
    get as _get,
    includes as _includes,
    isUndefined as _isUndefined,
    remove as _remove,
} from 'lodash';
import {reduxForm} from 'redux-form';

import Form from '@appComponents/ReduxFormControls';
import {FormWrapper} from '@appComponents/HOCFormWrapper';

import validate from './../forms/validatorContent';

import {convertToInt} from '@utils/helpers';

import {
    PROPERTY_LICENCE_TYPE_OTHER,
    PROPERTY_LICENCE_TYPE_RACE,
    PROPERTY_LICENCE_TYPE_SPORTRADAR,
} from '@constants/variables';

class PropertyLicenceContentForm extends React.Component {
    static propTypes = {
        contentCategoriesSecondLevel: PropTypes.array,
        contentCategoriesThirdLevel: PropTypes.array,
        contentCategoriesThirdLevelLoading: PropTypes.bool,
        contentCategoryThirdLevelValue: PropTypes.string,
        contentTiers: PropTypes.array,
        dataForForm: PropTypes.object,
        formData: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        onAdd: PropTypes.func,
        onTournamentCategoryChange: PropTypes.func,
        onTournamentChange: PropTypes.func,
        onContentCategorySecondLevelChange: PropTypes.func,
        onContentCategoryThirdLevelChange: PropTypes.func,
        onDeactivateEditableCell: PropTypes.func,
        onUpdate: PropTypes.func,
        propertyLicence: PropTypes.object,
        propertyLicenceContent: PropTypes.array,
        reset: PropTypes.func,
        staticData: PropTypes.object,
        submitting: PropTypes.bool,
        tournamentCategories: PropTypes.array,
        tournaments: PropTypes.array,
        tournamentsLoading: PropTypes.bool,
        tournamentValue: PropTypes.string,
        columns: PropTypes.object,
        authorization: PropTypes.shape({
            read: PropTypes.bool,
            create: PropTypes.bool,
            update: PropTypes.bool,
            delete: PropTypes.bool,
        }),
    };

    state = {
        contentCategory2: null,
        tournamentCategory: null,
    };

    renderTournamentCategoryColumn = () => {
        if (null === this.props.tournamentCategories) {
            return this.renderTournamentCategoryName();
        } else {
            return this.renderTournamentCategoryDropdown();
        }
    };

    renderTournamentCategoryName = () => {
        return this.props.staticData.tournament_category.name;
    };

    renderTournamentCategoryDropdown = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                onChangeSelect={this.props.onTournamentCategoryChange}
                options={this.props.tournamentCategories}
                name='tournament_category'
                placeholder='Select category'
                defaultValue={this.state.tournamentCategory}
                required
            />
        );
    };

    renderTournamentColumn = () => {
        if (null === this.props.tournaments) {
            return this.renderTournamentName();
        } else {
            return this.renderTournamentDropdown();
        }
    };

    renderTournamentName = () => {
        return this.props.staticData.tournament.name;
    };

    renderTournamentDropdown = () => {
        let contentTournamentsIds = this.props.propertyLicenceContent.map((content) => {
            return content.tournament.id;
        });

        let tournaments = [];

        if (!_isUndefined(this.props.tournaments)) {
            tournaments = _remove([...this.props.tournaments], function (tournament) {
                return !_includes(contentTournamentsIds, tournament.value);
            });
        }

        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                defaultValue={this.props.tournamentValue}
                disabled={_isUndefined(this.props.tournaments)}
                loading={this.props.tournamentsLoading}
                name='tournament'
                onChangeSelect={this.props.onTournamentChange}
                options={tournaments}
                placeholder='Select tournament'
                required
            />
        );
    };

    renderSecondLevelCategoryColumn = () => {
        if (null === this.props.contentCategoriesSecondLevel) {
            return this.renderSecondLevelCategoryName();
        } else {
            return this.renderSecondLevelCategoryDropdown();
        }
    };

    renderSecondLevelCategoryName = () => {
        return _get(this.props, 'staticData.content_category_level_2.name', '');
    };

    renderSecondLevelCategoryDropdown = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                name='content_category_2'
                onChangeSelect={this.props.onContentCategorySecondLevelChange}
                options={this.props.contentCategoriesSecondLevel}
                placeholder='Select 2nd level category'
                defaultValue={this.state.contentCategory2}
                required
            />
        );
    };

    renderThirdLevelCategoryColumn = () => {
        if (null === this.props.contentCategoriesThirdLevel) {
            return this.renderThirdLevelCategoryName();
        } else {
            return this.renderThirdLevelCategoryDropdown();
        }
    };

    renderThirdLevelCategoryName = () => {
        if (!_isUndefined(this.props.staticData)) {
            return this.props.staticData.content_category_level_3.name;
        }

        return '';
    };

    renderThirdLevelCategoryDropdown = () => {
        let contentCategoriesThirdLevelIds = this.props.propertyLicenceContent.map((content) => {
            return content.content_category.id;
        });

        let contentCategoriesThirdLevel = [];

        if (!_isUndefined(this.props.contentCategoriesThirdLevel)) {
            contentCategoriesThirdLevel = _remove([...this.props.contentCategoriesThirdLevel], function (category) {
                return !_includes(contentCategoriesThirdLevelIds, category.value);
            });
        }

        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                disabled={_isUndefined(this.props.contentCategoriesThirdLevel)}
                loading={this.props.contentCategoriesThirdLevelLoading}
                name='content_category_3'
                defaultValue={this.props.contentCategoryThirdLevelValue}
                onChangeSelect={this.props.onContentCategoryThirdLevelChange}
                options={contentCategoriesThirdLevel}
                placeholder='Select 3rd level category'
                required
            />
        );
    };

    renderContentTierDropdown = () => {
        return (
            <Form.FormRow
                component={Form.SemanticSelect}
                defaultValue={this.props.onUpdate
                    ? this.props.dataForForm.content_tier.id
                    : this.props.propertyLicence.content_tier_id}
                name='content_tier'
                options={this.props.contentTiers}
                placeholder='Select tier'
                required
                resetToDefaultValue
            />
        );
    };

    renderButtons = () => {
        if (!_isUndefined(this.props.onAdd)) {
            return this.renderButtonsForAddForm();
        }
        else {
            return this.renderButtonsForEditForm();
        }
    };

    processUpdate = (data) => {
        this.props.onDeactivateEditableCell();
        this.props.onUpdate(data);
    };

    renderButtonsForAddForm = () => {
        return (<Button
            icon='plus'
            type='submit'
        />);
    };

    renderButtonsForEditForm = () => {
        return (<Button.Group>
            <Button
                icon='checkmark'
                key='update-button'
                positive
                type='submit'
            />
            <Button
                icon='ban'
                key='cancel-button'
                onClick={this.props.onDeactivateEditableCell}
                negative
                type='button'
            />
        </Button.Group>);
    };

    onFormSubmit(data) {
        if (!_isUndefined(this.props.onAdd)) {
            this.props.onAdd(data);
            this.props.reset();
            this.setState(() => ({
                tournamentCategory: data.tournament_category,
                contentCategory2: data.content_category_2,
            }));
        } else {
            this.processUpdate(data);
        }
    }

    render() {
        const requiredAuthorization = _isUndefined(this.props.onAdd)
                ? this.props.authorization.update
                : this.props.authorization.create,
            isAuthorized = this.props.authorization.read && requiredAuthorization;

        if (!isAuthorized) {
            return 'Not authorized';
        }

        let isStandardSport = false;
        let isOther = false;

        if (!_isUndefined(this.props.propertyLicence.property_licence_type_id)) {
            switch (convertToInt(this.props.propertyLicence.property_licence_type_id)) {
                case PROPERTY_LICENCE_TYPE_SPORTRADAR:
                case PROPERTY_LICENCE_TYPE_RACE:
                    isStandardSport = true;
                    break;
                case PROPERTY_LICENCE_TYPE_OTHER:
                    isOther = true;
                    break;
            }
        }

        return (
            <Form.Create className={'propertyLicenceContentRow'}
                onSubmit={ this.props.handleSubmit((values) => { return this.onFormSubmit(values); }) }
            >
                <Form.IdField
                    defaultValue={this.props.dataForForm.property_licence_content.id}
                    name='id'
                />

                <Grid columns={4} verticalAlign='middle'>
                    <Grid.Row>
                        {isStandardSport &&
                            <Grid.Column width={this.props.columns.tournament_category.width}>
                                {this.renderTournamentCategoryColumn()}
                            </Grid.Column>
                        }
                        {isStandardSport &&
                            <Grid.Column width={this.props.columns.tournament.width}>
                                {this.renderTournamentColumn()}
                            </Grid.Column>
                        }
                        {isOther &&
                            <Grid.Column width={this.props.columns.content_category_level_2.width}>
                                {this.renderSecondLevelCategoryColumn()}
                            </Grid.Column>
                        }
                        {isOther &&
                            <Grid.Column width={this.props.columns.content_category_level_3.width}>
                                {this.renderThirdLevelCategoryColumn()}
                            </Grid.Column>
                        }
                        <Grid.Column width={this.props.columns.content_tier.width}>
                            {this.renderContentTierDropdown()}
                        </Grid.Column>

                        <Grid.Column width={this.props.columns.actions.width}>
                            {this.renderButtons()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form.Create>
        );
    }
}

const PropertyLicenceContentFormWithRedux = reduxForm({
    form: 'PropertyLicenceContent',
    validate,
})(PropertyLicenceContentForm);

export default FormWrapper({
    property_licence_content: {
        id: '',
    },
    content_category: {
        id: '',
    },
    tournament: {
        id: '',
        tournament_category: {
            id: '',
        },
    },
    content_tier: {
        id: '',
    },
}, PropertyLicenceContentFormWithRedux);
