import {withRouter} from 'react-router';
import {isEmpty as _isEmpty} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {prepareContentCategoriesForDropdown} from '@utils/contentCategories';
import {renderPageTitle} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {prepareTournamentsForDropdown} from '@utils/tournament';
import {adSpliceConfigOptionParser} from '@utils/dropdownOptionParser';

import {prepareFailoversForDropdown} from '../utils/formHelpers';

export class UdpOriginEntryPointForm extends DefaultForm {
    componentDidMount() {
        const link = this.createLinkToIndexPage();

        this.setOptionParsers({
            ad_splice_config: adSpliceConfigOptionParser,
        });
        this.setCreateSuccessCallback(() => {
            this.props.history.push(link);
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(link);
        });
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();
        const adSupportChanged = prevProps.formValues.has_scte35 !== this.props.formValues.has_scte35;

        if (adSupportChanged) {
            this.setField('ad_splice_config', {
                hidden: !this.props.formValues.has_scte35,
                required: true,
            });
        }

        if (undefined !== this.props.GraphQLOptionsData) {
            const {
                GraphQLOptionsData: {
                    contentCategories,
                    tournaments,
                    udpOriginEntryPoints,
                },
            } = this.props;

            if (!this.state.areFailoverOptionsGenerated && !_isEmpty(udpOriginEntryPoints)) {
                const failoversForDropdown = prepareFailoversForDropdown(udpOriginEntryPoints, this.props.formParams.id);

                this.setField('failoverUdpOriginEntryPoint', {
                    options: failoversForDropdown,
                });
                this.setState(() => ({areFailoverOptionsGenerated: true}));
            }

            if (!this.state.areTournamentsOptionsGenerated && !_isEmpty(tournaments)) {
                const tournamentsForDropdown = prepareTournamentsForDropdown(tournaments);

                this.setField('tournaments', {
                    options: sortDropdownOptionsAlphanumerically(tournamentsForDropdown),
                });
                this.setState(() => ({areTournamentsOptionsGenerated: true}));
            }

            if (!this.state.areContentCategoriesOptionsGenerated && !_isEmpty(contentCategories)) {
                const contentCategoriesForDropdown = prepareContentCategoriesForDropdown(contentCategories);

                this.setField('content_categories', {
                    options: sortDropdownOptionsAlphanumerically(contentCategoriesForDropdown)
                });
                this.setState(() => ({areContentCategoriesOptionsGenerated: true}));
            }

            if (undefined !== this.props.GraphQLEntityData && undefined !== this.props.GraphQLEntityData.udpOriginEntryPoint) {
                if (!this.state.areSelectedTournamentsGenerated && !_isEmpty(tournaments)) {
                    const selectedTournaments = (this.props.formParams.id && !_isEmpty(this.props.GraphQLEntityData.udpOriginEntryPoint.tournaments))
                        ? this.props.GraphQLEntityData.udpOriginEntryPoint.tournaments.map((tournament) => tournament.id)
                        : [];

                    this.setField('tournaments', {
                        defaultValue: selectedTournaments,
                    });
                    this.setState(() => ({areSelectedTournamentsGenerated: true}));
                }

                if (!this.state.areSelectedContentCategoriesGenerated && !_isEmpty(contentCategories)) {
                    const selectedContentCategories = (this.props.formParams.id && !_isEmpty(this.props.GraphQLEntityData.udpOriginEntryPoint.content_categories))
                        ? this.props.GraphQLEntityData.udpOriginEntryPoint.content_categories.map((contentCategory) => contentCategory.id)
                        : [];

                    this.setField('content_categories', {
                        defaultValue: selectedContentCategories,
                    });
                    this.setState(() => ({areSelectedContentCategoriesGenerated: true}));
                }
            }
        }

        const udpOriginEntryPointName = (null !== this.props.formParams.id)
            ? undefined !== this.props.GraphQLEntityData.udpOriginEntryPoint ?
                this.props.GraphQLEntityData.udpOriginEntryPoint.name : ' '
            : null;

        this.props.formParams.setPageTitle(
            renderPageTitle({
                id: this.props.formParams.id,
                name: udpOriginEntryPointName,
                entity: this.props.Model.entityLabel,
            })
        );
    }

    createLinkToIndexPage = () => (
        getLink('udpOriginEntryPoint.index')
    );

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.dataMap[this.props.Model.entityDataMapKey],
                {
                    contentCategories: data.content_categories,
                    description: data.description || null,
                    encodingDatacenter: data.encoding_datacenter,
                    failoverUdpOriginEntryPoint: _isEmpty(data.failover_udp_origin_entry_point)
                        ? null
                        : data.failover_udp_origin_entry_point,
                    id: data.id || null,
                    name: data.name,
                    previewUrl: data.preview_url || null,
                    tournaments: data.tournaments,
                    url: data.url,
                    adSpliceConfig: data.has_scte35 ? data.ad_splice_config : null,
                    hasCc: !!data.has_cc,
                    hasScte35: !!data.has_scte35,
                }
            );
    };

    renderErrors() {
        super.renderErrors(
            'The requested UDP origin entry point could not be found.',
            this.props.Model.entityLabel,
            this.createLinkToIndexPage()
        );
    }

    renderCancelButton = () => null;

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});
}

export default withRouter(UdpOriginEntryPointForm);
