import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmpty as _isEmpty} from 'lodash';

import useQuery from '@utils/hooks/useQuery';
import {GetCountriesFilteredWithFullData} from '@graphql/country/query';
import CountryTable from '@modules/country/components/CountryTable';
import {showModal} from '@utils/modal';
import {getSearchFilters} from '@utils/filters';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {renderCountryModalError} from '@modules/country/utils/utils';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import CountryFilters from '@modules/country/components/CountryFilters';
import {exportExcel as countryExcelExporter} from '@modules/country/utils/export/countryExcel';
import navigation from '@constants/navigation';
import mapModulesToProps from '@utils/mapModulesToProps';

const DEFAULT_FILTERS_VALUES = {
    search: null,
    continent: [],
    handleGeoRestrictionsOnSubdivisionLevel: null,
};

const CountriesIndex = ({ filters, Menu }) => {
    const queryVariables = {
        filters: {
            search: filters.search ? filters.search : DEFAULT_FILTERS_VALUES.search,
            continent: filters.continent ? filters.continent.map(Number) : DEFAULT_FILTERS_VALUES.continent,
            handleGeoRestrictionsOnSubdivisionLevel: (filters.handleGeoRestrictionsOnSubdivisionLevel)
                ? filters.handleGeoRestrictionsOnSubdivisionLevel
                : DEFAULT_FILTERS_VALUES.handleGeoRestrictionsOnSubdivisionLevel,
        },
    };

    const {
        data: {
            countries,
        } = {},
        error: countriesFetchingErrorOccurred,
        loading: isCountryDataLoading = true,
    } = useQuery({
        query: GetCountriesFilteredWithFullData,
        options: {
            variables: {
                ...queryVariables,
            },
            fetchPolicy: 'no-cache',
            refetch: filters.lastClickSubmit,
        },
    });

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    if (!_isEmpty(countriesFetchingErrorOccurred)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(countriesFetchingErrorOccurred.message),
        });
    }

    return(
        <div>
            <HeaderRenderer pageTitle='Geolocation services' />

            <p>
                <b>Geolocation based on IP: </b> we use the <a href='https://www.maxmind.com/en/geoip2-city' target='_blank' rel='noreferrer'>MaxMind GeoIP2 City
                Database. </a> It can be tested
                <a href='https://www.maxmind.com/en/geoip-demo' target='_blank' rel='noreferrer'> here.</a><br></br>
                <b>Geolocation based on coordinates: </b> we use Nominatim as
                <a href='http://nominatim.av.srcloud.io/ui' target='_blank' rel='noreferrer'> geocoding</a> and
                <a href='http://nominatim.av.srcloud.io/ui/reverse.html' target='_blank' rel='noreferrer'> reverse-geocoding</a> service.
            </p>

            <HeaderRenderer
                filtersButtonName='Countries'
                messagesBoxNames='Countries'
                exportExcelParams={countryExcelExporter(countries)}
                loading={isCountryDataLoading}
                pageTitle='Countries'
                filters={CountryFilters}
                filtersProps={{loading: isCountryDataLoading}}
            />
            <div className='table-container'>
                <CountryTable
                    data={countries}
                    loading={isCountryDataLoading}
                />
            </div>
        </div>
    );
};

CountriesIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    Menu: PropTypes.object,
};

const CountriesIndexFilterWrapped = FilterQueryWrapper(CountriesIndex,{
    queryForRefresh: 'GetCountriesFilteredWithFullData',
    filterUrls: ['countries.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'Countries', DEFAULT_FILTERS_VALUES),
});

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(FilterUrlParamsWrapper(CountriesIndexFilterWrapped, DEFAULT_FILTERS_VALUES));
