import {convertToInt} from "../../../utils/helpers";
import validators from "../../../utils/validators";

import {UPLOAD_TARGET_TYPE_AMAZON_S3, UPLOAD_TARGET_TYPE_FTP, UPLOAD_TARGET_TYPE_SFTP} from "../../app/constants/variables";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.upload_target_type, 'upload_target_type'));
    Object.assign(errors, validators.required(values.path, 'path'));
    Object.assign(errors, validators.required(values.encoding_job_profile, 'encoding_job_profile'));

    const uploadTargetType = values.upload_target_type ? convertToInt(values.upload_target_type) : null;

    if (values.upload_target_type && UPLOAD_TARGET_TYPE_AMAZON_S3 === uploadTargetType) {
        Object.assign(errors, validators.required(values.secret_key, 'secret_key'));
        Object.assign(errors, validators.required(values.access_key, 'access_key'));
        Object.assign(errors, validators.required(values.region, 'region'));
        Object.assign(errors, validators.required(values.bucket, 'bucket'));
    } else if (values.upload_target_type
        && (
            UPLOAD_TARGET_TYPE_FTP === uploadTargetType
            || UPLOAD_TARGET_TYPE_SFTP === uploadTargetType
        )
    ) {
        Object.assign(errors, validators.required(values.hostname, 'hostname'));
        Object.assign(errors, validators.required(values.username, 'username'));
        Object.assign(errors, validators.required(values.password, 'password'));
    }

    return errors;
};

export default validate;
