import GeoRestrictionsModel from "./GeoRestrictionsModel";

import {GetClientPackageGeoRestrictionForForm} from "../../../../graphql/clientPackageGeoRestriction/query.graphql";
import {
    DeleteClientPackageGeoRestriction,
    UpdateClientPackageGeoRestriction
} from "../../../../graphql/clientPackageGeoRestriction/mutation.graphql";

export default function GeoRestrictionsEditModel() {
    let geoRestrictionsModel = GeoRestrictionsModel();

    geoRestrictionsModel.editForm = true;
    geoRestrictionsModel.deleteId = "clientPackageGeoRestriction.id";
    geoRestrictionsModel.mutation = {
        updateEntity: UpdateClientPackageGeoRestriction,
        deleteEntity: DeleteClientPackageGeoRestriction,
    };
    geoRestrictionsModel.query.getEntity = GetClientPackageGeoRestrictionForForm;

    return geoRestrictionsModel;
}
