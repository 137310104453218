import {MEDIA_CONNECT_ORIGIN_ENTRY_POINT} from '@constants/resources';
import {
    GetOptionsForForm,
    GetMediaConnectOriginEntryPointForForm,
} from '@graphql/mediaConnect/query';
import {
    CreateMediaConnectOriginEntryPoint,
    DeleteMediaConnectOriginEntryPoint,
    UpdateMediaConnectOriginEntryPoint,
} from '@graphql/mediaConnect/mutation';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {
    adFeedSpliceInformationField,
    adSupportSCTEField,
    adSpliceConfigField,
} from '@modules/adSplice/form/fieldsModel';

import {renderFormErrorMessages} from '../utils/modelHelpers';
import validate from './validator';
import {renderHeader} from '../../propertyLicence/utils/technicalSetupModelHelper';

export default function MediaConnectModel() {
    return {
        entityDataMapKey: 'mediaconnectOriginEntryPoint',
        entityLabel: 'MediaConnect origin entry point',
        formName: 'mediaconnectOriginEntryPoint',
        resources: MEDIA_CONNECT_ORIGIN_ENTRY_POINT,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            mediaconnectOriginEntryPoint: {
                id: '',
                name: '',
                description: '',
                encodingDatacenter: {id: ''},
                products: null,
                primary_feed_fields: [{
                    primary_feed: '',
                    primary_feed_number: '',
                }],
                primary_feed_arn : '',
                primary_feed_output_url: '',
                primary_feed_close_captioning: '',
                primary_feed_ad_support: '',
                primary_feed_ad_splice_config: {id: ''},
                primary_feed_feed_splice: '',
                secondary_feed_fields: [{
                    secondary_feed: '',
                    secondary_feed_number: '',
                }],
                secondary_feed_arn : '',
                secondary_feed_output_url: '',
                secondary_feed_close_captioning: '',
                secondary_feed_ad_support: '',
                secondary_feed_ad_splice_config: {id: ''},
                secondary_feed_feed_splice: '',
            },
        },
        messages: {
            boxName: {
                delete: 'mediaConnectMessage',
                error: 'mediaConnectMessageError',
                success: 'mediaConnectMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: renderFormErrorMessages,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'mediaconnectOriginEntryPoint.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'mediaconnectOriginEntryPoint.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            description: {
                dataMapKey: 'mediaconnectOriginEntryPoint.description',
                props: {
                    component: Form.SemanticInput,
                    label: 'Description',
                    name: 'description',
                    type: 'input',
                },
            },
            encodingDatacenter: {
                dataMapKey: 'mediaconnectOriginEntryPoint.encoding_datacenter.id',
                optionsKey: 'encodingDatacenters',
                props: {
                    allowClear: false,
                    component: Form.SemanticSelect,
                    label: 'Encoding datacenter',
                    name: 'encoding_datacenter',
                    placeholder: 'Select encoding datacenter',
                    required: true,
                },
            },
            products: {
                optionsKey: 'products',
                props: {
                    allowClear: true,
                    component: Form.SemanticSelect,
                    name: 'products',
                    label: 'Enable feed switching for',
                    multiple: true,
                    hidden: true,
                },
            },
            header_primary: {
                props: {
                    component: renderHeader,
                    name: 'header_input',
                    defaultValue: {
                        name: 'Primary feed',
                    },
                },
            },
            primary_feed_field: [
                {
                    primary_feed: {
                        dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].url',
                        props: {
                            component: Form.SemanticInput,
                            label: 'URL',
                            name: 'primary_feed',
                            required: true,
                            type: 'input',
                            divClassName: 'primary_feed_url',
                        },
                    },
                    primary_feed_number: {
                        dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].feed_number',
                        props: {
                            component: Form.SemanticInput,
                            name: 'primary_feed_number',
                            hidden: true,
                        },
                    },
                },
            ],
            primary_feed_arn: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].arn',
                props: {
                    component: Form.SemanticInput,
                    label: 'ARN',
                    name: 'primary_feed_arn',
                    type: 'input',
                    divClassName: 'primary_feed',
                },
            },
            primary_feed_output_url: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].output_url',
                props: {
                    component: Form.SemanticInput,
                    label: 'Output URL',
                    name: 'primary_feed_output_url',
                    type: 'input',
                    divClassName: 'primary_feed',
                },
            },
            primary_feed_close_captioning: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].has_cc',
                props: {
                    component: Form.SemanticInput,
                    label: 'Close Captioning',
                    name: 'primary_feed_close_captioning',
                    type: 'toggle',
                },
            },
            primary_feed_ad_support: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].has_scte35',
                props: {
                    ...adSupportSCTEField('primary_feed_ad_support'),
                },
            },
            primary_feed_ad_splice_config: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].ad_splice_config.id',
                optionsKey: 'adSpliceConfigs',
                props: {
                    ...adSpliceConfigField('primary_feed_ad_splice_config'),
                },
            },
            primary_feed_feed_splice: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[0].has_scte104',
                props: {
                    ...adFeedSpliceInformationField('primary_feed_feed_splice'),
                },
            },
            header_secondary: {
                props: {
                    component: renderHeader,
                    name: 'header_input',
                    defaultValue: {
                        name: 'Secondary feed',
                    },
                },
            },
            secondary_feed_field: [
                {
                    secondary_feed: {
                        dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].url',
                        props: {
                            component: Form.SemanticInput,
                            label: 'URL',
                            name: 'secondary_feed',
                            type: 'input',
                            divClassName: 'secondary_feed_url',
                        },
                    },
                    secondary_feed_number: {
                        dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].feed_number',
                        props: {
                            component: Form.SemanticInput,
                            name: 'secondary_feed_number',
                            hidden: true,
                        },
                    },
                },
            ],
            secondary_feed_arn: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].arn',
                props: {
                    component: Form.SemanticInput,
                    label: 'ARN',
                    name: 'secondary_feed_arn',
                    type: 'input',
                    divClassName: 'secondary_feed',
                },
            },
            secondary_feed_output_url: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].output_url',
                props: {
                    component: Form.SemanticInput,
                    label: 'Output URL',
                    name: 'secondary_feed_output_url',
                    type: 'input',
                    divClassName: 'secondary_feed',
                },
            },
            secondary_feed_close_captioning: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].has_cc',
                props: {
                    component: Form.SemanticInput,
                    label: 'Close Captioning',
                    name: 'secondary_feed_close_captioning',
                    type: 'toggle',
                },
            },
            secondary_feed_ad_support: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].has_scte35',
                props: {
                    ...adSupportSCTEField('secondary_feed_ad_support'),
                },
            },
            secondary_feed_ad_splice_config: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].ad_splice_config.id',
                optionsKey: 'adSpliceConfigs',
                props: {
                    ...adSpliceConfigField('secondary_feed_ad_splice_config'),
                },
            },
            secondary_feed_feed_splice: {
                dataMapKey: 'mediaconnectOriginEntryPoint.feeds[1].has_scte104',
                props: {
                    ...adFeedSpliceInformationField('secondary_feed_feed_splice'),
                },
            },
        },
        mutation: {
            createEntity: CreateMediaConnectOriginEntryPoint,
            deleteEntity: DeleteMediaConnectOriginEntryPoint,
            updateEntity: UpdateMediaConnectOriginEntryPoint,
        },
        query: {
            getEntity: GetMediaConnectOriginEntryPointForForm,
            getOptions: GetOptionsForForm,
        },
    };
}
