import PropTypes from "prop-types";

import UserSettingsForm from "../forms/UserSettingsForm";

export const prepareTimezones = (timezones = []) => {
    const timezonesOptions = timezones.map((timezone) => ({
            key: timezone,
            text: timezone,
            value: timezone,
        })),
        automaticOption = {
            key: UserSettingsForm.AUTO_TIMEZONE_TEXT,
            text: UserSettingsForm.AUTO_TIMEZONE_TEXT,
            value: UserSettingsForm.AUTO_TIMEZONE_TEXT,
        };

    timezonesOptions.unshift(automaticOption);

    return timezonesOptions;
};
prepareTimezones.propTypes = {
    timezones: PropTypes.array,
};

export const prepareUserIp = (detectedCountry, detectedIp) => {
    const countryInfo =  detectedCountry || 'this IP is not associated to a country';

    return `${detectedIp} (${countryInfo})`;
};
prepareUserIp.propTypes = {
    detectedCountry: PropTypes.string.isRequired,
    detectedIp: PropTypes.string.isRequired,
};
