import React from 'react';
import {get as _get} from 'lodash';

import {IconInfoCircle} from '@appComponents/IconCollection';
import {PRODUCT} from '@constants/resources';
import {GetProductForForm, GetProductDropdownOptionsForForm} from '@graphql/product/query';
import {UpdateProduct} from '@graphql/product/mutation';
import Form from '@modules/app/components/ReduxFormControls';
import {renderText} from '@modules/product/utils/productHelper';
import validate from '@modules/product/forms/validator';
import * as MESSAGES from '@modules/app/constants/messages';
import * as TOOLTIP_MESSAGES from '@modules/product/constants/tooltipMessages';

export default function ProductModel() {
    return {
        entityDataMapKey: 'product',
        entityLabel: 'product',
        formName: 'product',
        label: 'Product',
        resources: PRODUCT,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            product: {
                id: '',
                cdn: '',
                live_ingest_method: null,
                recording_ingest_method: null,
                akamai_cdn_ingest_method: '',
                has_akamai_rtmp_distr_region_support: false,
                has_scoreboard_overlay_support: false,
                has_slate_support: false,
                has_overlay_support: false,
                has_ad_support: false,
                has_drm_support: false,
                has_cc_support: false,
                has_hq_support: false,
                has_usage_type_support: false,
                has_event_booking_support: false,
                has_geolocation_support: false,
                is_invoiceable: false,
            },
        },
        messages: {
            boxName: {
                error: 'productMessageError',
                success: 'productMessage',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            hidden_name: {
                dataMapKey: 'product.name',
                props: {
                    component: Form.SemanticInput,
                    name: 'name',
                    hidden: true,
                    label: '',
                },
            },
            name: {
                dataMapKey: 'product.name',
                props: {
                    component: (props) => {
                        return renderText(
                            _get(props, 'data.product.name', ''),
                            props.label
                        );
                    },
                    label: 'Name',
                    name: 'name',
                },
            },
            short_name: {
                dataMapKey: 'product.short_name',
                props: {
                    component: (props) => {
                        return renderText(
                            _get(props, 'data.product.short_name', ''),
                            props.label
                        );
                    },
                    label: 'Short name',
                    name: 'short_name',
                },
            },
            external_name: {
                dataMapKey: 'product.external_name',
                props: {
                    component: (props) => {
                        return renderText(
                            _get(props, 'data.product.external_name', ''),
                            props.label
                        );
                    },
                    label: 'External name',
                    name: 'external_name',
                },
            },
            cdn: {
                optionsKey: 'cdns',
                dataMapKey: 'product.cdn.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Default CDN*',
                    name: 'cdn',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.CDN}/>,
                    placeholder: 'Select cnd',
                },
            },
            live_ingest_method: {
                optionsKey: 'ingestMethods',
                dataMapKey: 'product.live_ingest_method.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Default Live ingest method*',
                    name: 'live_ingest_method',
                    placeholder: 'Select ingest method',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.DEFAULT_LIVE_INGEST_METHOD}/>,
                },
            },
            recording_ingest_method: {
                optionsKey: 'ingestMethods',
                dataMapKey: 'product.recording_ingest_method.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Default Recording ingest method*',
                    name: 'recording_ingest_method',
                    placeholder: 'Select ingest method',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.DEFAULT_RECORDING_INGEST_METHOD}/>,
                },
            },
            akamai_cdn_ingest_method: {
                optionsKey: 'akamaiCdnIngestMethods',
                dataMapKey: 'product.akamai_cdn_ingest_method.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Akamai CDN ingest method*',
                    name: 'akamai_cdn_ingest_method',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.AKAMAI_CDN_INGEST_METHOD}/>,
                    placeholder: 'Select cnd',
                },
            },
            has_akamai_rtmp_distr_region_support: {
                dataMapKey: 'product.has_akamai_rtmp_distr_region_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Akamai RTMP distr. regions support',
                    name: 'has_akamai_rtmp_distr_region_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_AKAMAI_RTMP_DISTR_REGION_SUPORT}/>,
                    type: 'toggle',
                },
            },
            has_scoreboard_overlay_support: {
                dataMapKey: 'product.has_scoreboard_overlay_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Scoreboard overlay support',
                    name: 'has_scoreboard_overlay_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_SCOREBOARD_OVERLAY_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_slate_support: {
                dataMapKey: 'product.has_slate_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Slate support',
                    name: 'has_slate_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_SLATE_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_overlay_support: {
                dataMapKey: 'product.has_overlay_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Overlays',
                    name: 'has_overlay_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_OVERLAY_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_ad_support: {
                dataMapKey: 'product.has_ad_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Ad support',
                    name: 'has_ad_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_AD_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_drm_support: {
                dataMapKey: 'product.has_drm_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'DRM support',
                    name: 'has_drm_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_DRM_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_cc_support: {
                dataMapKey: 'product.has_cc_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Close Captioning support',
                    name: 'has_cc_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_CC_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_hq_support: {
                dataMapKey: 'product.has_hq_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'High Quality support',
                    name: 'has_hq_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_HQ_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_usage_type_support: {
                dataMapKey: 'product.has_usage_type_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Usage type support',
                    name: 'has_usage_type_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_USAGE_TYPE_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_geolocation_support: {
                dataMapKey: 'product.has_geolocation_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Geolocation support',
                    name: 'has_geolocation_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_GEOLOCATION_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            has_event_booking_support: {
                dataMapKey: 'product.has_event_booking_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Event booking support',
                    name: 'has_event_booking_support',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.HAS_EVENT_BOOKING_SUPPORT}/>,
                    type: 'toggle',
                },
            },
            is_invoiceable: {
                dataMapKey: 'product.is_invoiceable',
                props: {
                    component: Form.SemanticInput,
                    label: 'Invoiceable',
                    name: 'is_invoiceable',
                    description: <IconInfoCircle tooltip={TOOLTIP_MESSAGES.IS_INVOICEABLE}/>,
                    type: 'toggle',
                },
            },
        },
        mutation: {
            updateEntity: UpdateProduct,
        },
        mutationOptions: {},
        query: {
            getEntity: GetProductForForm,
            getOptions: GetProductDropdownOptionsForForm,
        },
    };
}
