import React from "react";
import {Icon, Message} from "semantic-ui-react";

const MessageBox = () => (
    <Message icon info>
        <Icon name='info circle' />
        <Message.Content>
            {`
                The countries listed here are the countries with subdivision support for which bookings
                are possible for a sub-set of the country subdivisions.
            `}
            <br/>
            {`Before the enforcement date (or if no enforcement date is defined):`}
            <ul className="--noMargin">
                <li>
                    {`
                        Bookings are possible on a given content only if its media-rights allow all of the subdivisons 
                        of the country.
                    `}
                </li>
                <li>
                    {`
                        The AV-API will never require any geolocation coordinates, whatever are the values of the 
                        "Geolocation check" and "Method".
                    `}
                </li>
            </ul>
            {`From the enforcement date:`}
            <ul className="--noMargin">
                <li>
                    {`
                        Bookings are possible on a given content even if its media-rights allow only a sub-set of the 
                        subdivisions of the country.
                    `}
                </li>
                <li>
                    {`
                        The AV-API will require geolocation coordinates to perform a geolocation check only if the option
                        "Geolocation check" is switched on and if the method "Latitude/longitude coordinates" is selected.
                    `}
                </li>
            </ul>
        </Message.Content>
    </Message>
);
MessageBox.displayName = 'MessageBox';

export default MessageBox;
