import {has as _has} from "lodash";

/**
 * We need to get tournament from:
 * - sr_event.match.tournament.id
 * - sr_event.tournament_id
 * in this sequence
 * @param event
 * @returns {null|number}
 */
export const getTournamentIdByEvent = (event) => {
    if (_has(event, 'sr_event.match.tournament.id')) {
        return event.sr_event.match.tournament.id;
    } else if (_has(event, 'sr_event.tournament.id')) {
        return event.sr_event.tournament.id;
    }

    return null;
};
