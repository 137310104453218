import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {Dropdown} from 'semantic-ui-react';
import {has as _has} from 'lodash';

import {EVENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {isSportradarEventAndNoMaster} from '../../../../utils/eventType';

const authorizationEventUpdateObject = {
    [EVENT]: SECURITY_PRIVILEGES_UPDATE,
};

/**
 * Link to match
 */
const ButtonLinkToMatch = ({event, handleOpenLinkToMatchIDModal}) => {
    /**
     * We can link match only with Sportradar Event (no master)
     * We can link only if event is not linked with match
     */
    if (!isSportradarEventAndNoMaster(event) || _has(event, 'sr_event.match.id')) {
        return null;
    }

    return (
        <Authorization authorization={authorizationEventUpdateObject}>
            <Dropdown.Item
                icon='linkify'
                text='Link to a Sportradar match'
                key={'linkToSportradarMatch'}
                onClick={handleOpenLinkToMatchIDModal}
            />
        </Authorization>
    );
};

ButtonLinkToMatch.propTypes = {
    event: PropTypes.object.isRequired,
    handleOpenLinkToMatchIDModal: PropTypes.func.isRequired,
};

export default withRouter(ButtonLinkToMatch);
