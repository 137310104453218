import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {ENCODING_JOB_PROFILE} from '@constants/resources';
import {UpdateEncodingJobProfile} from '@graphql/encodingJobProfile/mutation';
import {GetEncodingJobProfileForForm, GetClientsForFormDropdown} from '@graphql/encodingJobProfile/query';

import validate from './validatorEncodingJobProfile';
import {isDefaultDescription} from '../utils/modelHelpers';

export default function EncodingJobProfileModel() {
    return {
        entityDataMapKey: 'encodingJobProfile',
        entityLabel: 'encoding job profile',
        formName: 'encodingJobProfile',
        label: 'Encoding job profile',
        resources: ENCODING_JOB_PROFILE,
        showChangelog: true,
        title: null,
        validate: validate,
        dataMap: {
            encodingJobProfile: {
                id : '',
                name: '',
                product: {id: ''},
                distribution_type: {id: ''},
                is_default: false,
                clients: null,
                is_disabled: false,
            },
        },
        messages: {
            boxName: {
                error: 'encodingJobProfile',
                success: 'encodingJobProfile',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'encodingJobProfile.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'encodingJobProfile.name',
                props: {
                    component: Form.TextPlaceholder,
                    label: 'Name',
                    name: 'name',
                },
            },
            product: {
                dataMapKey: 'encodingJobProfile.product.name',
                props: {
                    component: Form.TextPlaceholder,
                    label: 'Product',
                    name: 'product',
                },
            },
            distribution_type: {
                dataMapKey: 'encodingJobProfile.distribution_type.name',
                props: {
                    component: Form.TextPlaceholder,
                    label: 'Distribution type',
                    name: 'distribution_type',
                },
            },
            has_drm_support: {
                dataMapKey: 'encodingJobProfile.has_drm_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'DRM?',
                    name: 'has_drm_support',
                    type: 'checkbox',
                },
            },
            has_multi_audio_support: {
                dataMapKey: 'encodingJobProfile.has_multi_audio_support',
                props: {
                    component: Form.SemanticInput,
                    label: 'Multi audio support',
                    name: 'has_multi_audio_support',
                    type: 'checkbox',
                },
            },
            is_default: {
                dataMapKey: 'encodingJobProfile.is_default',
                props: {
                    component: Form.SemanticInput,
                    label: 'Default',
                    name: 'is_default',
                    type: 'checkbox',
                    props: {
                        description: isDefaultDescription(),
                    },
                },
            },
            clients: {
                optionsKey: 'clients',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Clients',
                    name: 'clients',
                    multiple: true,
                    placeholder: 'Leave empty if you want to select all clients',

                },
            },
            is_disabled: {
                dataMapKey: 'encodingJobProfile.is_disabled',
                props: {
                    component: Form.SemanticInput,
                    label: 'Disabled',
                    name: 'is_disabled',
                    type: 'checkbox',
                },
            },
        },
        mutation: {
            updateEntity: UpdateEncodingJobProfile,
        },
        query: {
            getEntity: GetEncodingJobProfileForForm,
            getOptions: GetClientsForFormDropdown,
        },
    };
}
