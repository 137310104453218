import WhitelistedIpModel from '@modules/client/forms/WhitelistedIpModel';
import {CLIENT_WHITELISTED_IP_AV_API_ACCESS} from '@constants/resources';
import {createFieldsByType} from '@modules/client/utils/whitelistingHelper';
import {CreateClientAvApiWhitelistedIp, UpdateClienAvApitWhitelistedIp} from '@graphql/clientAvapiWhitelistedIp/mutation';
import {GetAvApiWhitelistedIpData} from '@graphql/clientAvapiWhitelistedIp/query';
import {GetClientWhitelistedIpDropdownData} from '@graphql/clientWhitelistedIp/query';

import validate from './validatorAvapi';

export default function AvApiAccessModel() {
    const baseModel = WhitelistedIpModel();
    const newModel = {
        ...baseModel,
        resources: CLIENT_WHITELISTED_IP_AV_API_ACCESS,
        validate: validate,
        entityDataMapKey: 'clientAvapiWhitelistedIp',
        dataMap: {
            clientAvapiWhitelistedIp: {
                id: '',
                ip_version: '',
                cidr: '',
                description: '',
            },
        },
        fields: {
            ...createFieldsByType('avapi'),
        },
        mutation: {
            createEntity: CreateClientAvApiWhitelistedIp,
            updateEntity: UpdateClienAvApitWhitelistedIp,
        },
        query: {
            getEntity: GetAvApiWhitelistedIpData,
            getOptions: GetClientWhitelistedIpDropdownData,
        },
    };

    delete newModel.fields.products;

    return newModel;
}
