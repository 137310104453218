import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.description, 'description'));
    Object.assign(errors, validators.url(values.url, 'url'));

    return errors;
};

export default validate;
