import React from 'react';
import {useQuery} from '@apollo/client';
import {isEmpty as _isEmpty} from 'lodash';
import {useParams} from 'react-router';
import {Segment} from 'semantic-ui-react';

import {getCountrySubdivisionsWithPostalCodeCount} from '@graphql/countrySubdivision/query';
import {showModal} from '@utils/modal';
import {CENTERED_HEADLINE_AND_BODY, renderCountryModalError} from '@modules/country/utils/utils';
import Table from '@appComponents/Table';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {exportExcel} from '@modules/country/utils/export/countrySubdivisionsExcel';
import {convertToInt} from '@utils/helpers';
import Link from '@appComponents/Link';

const countrySubdivisionColumns = {
    id: {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    name: {
        label: 'Name',
        sorting: (row) => (row.name ? row.name.toLowerCase() : ''),
    },
    iso_code: {
        label: 'ISO code',
        sorting: (row) => (row.iso_code ? row.iso_code.toUpperCase() : ''),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    postal_codes: {
        label: 'Postal codes',
        sorting: (row) => (row.num_postal_codes ? row.num_postal_codes : 0),
        ...CENTERED_HEADLINE_AND_BODY,
    },
};

const renderSingleSubdivisionTableRow = (column, row, params) => {
    switch (column) {
        case 'id':
            return row.id;
        case 'name':
            return row.name;
        case 'iso_code':
            return row.iso_code;
        case 'postal_codes':
            return (row.num_postal_codes)
                ? <Link name='countries.postalCodes'
                    params={{ id: params.countryId }}
                    query={{ country_subdivision: JSON.stringify([row.id]) }}>{row.num_postal_codes}</Link>
                : 0;
        default:
            return '';
    }
};

const CountrySubdivisions = () => {
    const matchParams = useParams(),
        countryId = convertToInt(matchParams.id),
        queryParams = {
            filters: {
                country: countryId,
            },
            groups: ['num_postal_codes'],
        };

    const {
        data: {
            countrySubdivisions,
        } = {},
        error: countryDataFetchingError,
        loading: isCountryDataLoading = true,
    } = useQuery(getCountrySubdivisionsWithPostalCodeCount,
        {
            variables: {
                ...queryParams,
            },
            fetchPolicy: 'no-cache',
        }
    );

    if (!_isEmpty(countryDataFetchingError)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(countryDataFetchingError.message),
        });
    }

    if (isCountryDataLoading) {
        return (
            <Segment basic loading className='--full-height' />
        );
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(countrySubdivisions, countryId)}
            />
            <Table
                name='Countries'
                columns={countrySubdivisionColumns}
                data={countrySubdivisions}
                loading={isCountryDataLoading}
                noDataText='No subdivisions found'
                rowRenderer={renderSingleSubdivisionTableRow}
                rowRendererProps={{ countryId: countryId }}
                pagination={false}
            />
        </div>
    );
};

export default CountrySubdivisions;
