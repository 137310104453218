import {
    find as _find,
    get as _get,
    forEach as _forEach,
    filter as _filter,
    orderBy as _orderBy,
    union as _union,
} from 'lodash';
import React from 'react';
import {withRouter} from 'react-router';
import {Button, Popup} from 'semantic-ui-react';

import {refetchQueryByName} from '@utils/apollo';
import DefaultForm from '@appComponents/DefaultForm';
import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {isUrlParamValid, convertToInt} from '@utils/helpers';

export class EventsReportForm extends DefaultForm {
    eventInvoiceStatusesCallback(response) {
        const eventInvoiceStatuses = _get(response, 'data.saveEventInvoiceStatuses', null);

        this.setState(() => ({eventInvoiceStatuses: eventInvoiceStatuses}));
        this.setField('invoice_statuses', {eventInvoiceStatuses: eventInvoiceStatuses});
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }

        this.setCreateSuccessCallback((response) => {
            this.eventInvoiceStatusesCallback(response);
            refetchQueryByName('GetEventReportsFilter');
        });

        this.setUpdateSuccessCallback((response) => this.eventInvoiceStatusesCallback(response));
    }

    componentDidUpdate() {
        const {eventContents, eventInvoiceStatuses} = this.props.GraphQLOptionsData;
        const eventIssueTypes = _get(this.props, 'GraphQLEntityData.eventIssueTypes', null);
        const selectedEventIssueTypes = _get(this.props.GraphQLEntityData, 'eventReports[0].event_issue_types', null);

        if (eventContents && eventContents !== this.state.eventContents) {
            let availableProducts = [];

            _forEach(eventContents, (eventContent) => {
                _forEach(eventContent.distributions, (distribution) => {
                    const hasUniqueProduct = _filter(availableProducts, {id: distribution.product.id});

                    if (!hasUniqueProduct.length && distribution.product.is_invoiceable) {
                        availableProducts.push(distribution.product);
                    }
                });
            });

            this.setField('invoice_statuses', {inputsData: availableProducts});
            this.setState(() => ({
                availableProducts: availableProducts,
                eventContents: eventContents,
            }));
        }

        if (eventIssueTypes && eventIssueTypes !== this.state.eventIssueTypes) {

            let issueTypes = [];

            if (selectedEventIssueTypes) {
                selectedEventIssueTypes.forEach((issueType) => {
                    issueTypes = _union(issueTypes, [convertToInt(issueType.id)]);
                });
            }

            const parseOptionsForEventIssueTypes = (eventIssueTypes) => {
                const sortedIssueTypes = _orderBy(eventIssueTypes, ['name'], ['ASC']);

                return sortedIssueTypes.map((issueType) => {
                    return {
                        key: issueType.id,
                        text: issueType.description ?
                            <Popup
                                content={issueType.description}
                                position={'top center'}
                                size={'small'}
                                trigger={<label>{issueType.name}</label>}
                            /> : issueType.name,
                        value: convertToInt(issueType.id),
                    };
                });
            };

            this.setField('event_issue_types', {
                defaultValue: issueTypes,
                options: parseOptionsForEventIssueTypes(eventIssueTypes),
            });

            this.setState(() => ({
                eventIssueTypes: eventIssueTypes,
                selectedEventIssueTypes: selectedEventIssueTypes,
            }));
        }

        if (eventInvoiceStatuses
            && eventInvoiceStatuses !== this.state.eventInvoiceStatuses
            && !this.state.initialStateSet
        ) {
            this.setState(() => ({
                eventInvoiceStatuses: eventInvoiceStatuses,
                initialStateSet: true,
            }));
            this.setField('invoice_statuses', {eventInvoiceStatuses: eventInvoiceStatuses});
        }
    }

    renderDeleteButton() {
        return null;
    }

    renderCancelButton() {
        return null;
    }

    renderSaveButton(props) {
        const defaultProps = {
            color: 'blue',
            content: 'Save',
            disabled: this.props.submitting,
            icon: 'save',
            loading: this.props.submitting,
            type: 'submit',
        };

        return this.checkAuthorization(
            <Button
                {...defaultProps}
                {...props}
            />, this.state.defaultForm_formPrivileges);
    }

    prepareDataForSubmit = (data) => {
        const report = Object.assign({}, this.props.Model.dataMap['eventReports'], data);

        report.event = parseInt(this.props.match.params.id, 10);
        report.origin_stream_status = parseInt(report.origin_stream_status, 10);
        report.invoice_statuses = _get(report, 'invoice_statuses', []);
        report.event_issue_types = _get(report, 'event_issue_types', []);

        report.invoice_statuses.forEach((item) => {
            const invoiceStatus = _find(this.state.eventInvoiceStatuses, {product: {id: item.product}});

            if (invoiceStatus) {
                item.id = invoiceStatus.id;
            }

            item.product = parseInt(item.product, 10);
            item.invoice_status = parseInt(item.invoice_status, 10);
        });

        return report;
    };
}

export default withRouter(EventsReportForm);
