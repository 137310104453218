import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';

import * as CONST from '@constants/variables';

import ContactPersonForm from './ContactPersonForm';
import {ADD_CONTACT_PERSON_TYPE_LICENSOR, ADD_CONTACT_PERSON_TYPE_SPORTRADAR} from '../../app/constants/variables';

class ContactPersonFormAdd extends React.Component{
    static propTypes = {
        parent: PropTypes.object,
        personType: PropTypes.number,
    };

    defaultFormData = {
        contactPerson : {},
    };

    constructor(props) {
        super(props);

        switch (props.parent.name) {
            case 'licensors':
                Object.assign(this.defaultFormData.contactPerson, {
                    licensor_id: props.parent.id,
                    contact_person_type_id: props.personType,
                });
                break;
            case 'licensees':
                Object.assign(this.defaultFormData.contactPerson, {
                    licensee_id: props.parent.id,
                    contact_person_type_id: props.personType,
                });
                break;
            default:
                return null;
        }

        switch (this.props.personType) {
            case CONST.CONTACT_PERSON_TYPE_SPORTRADAR:
                this.personLabel = 'Sportradar';
                document.title = 'Licensor "' + this.props.parent.licencorsName + '" ' + ADD_CONTACT_PERSON_TYPE_SPORTRADAR;
                break;
            case CONST.CONTACT_PERSON_TYPE_LICENSOR:
                this.personLabel = 'licensor';
                document.title = 'Licensor "' + this.props.parent.licencorsName + '" ' + ADD_CONTACT_PERSON_TYPE_LICENSOR;
                break;
            case CONST.CONTACT_PERSON_TYPE_LICENSEE:
                this.personLabel = '';
                break;

        }
    }

    render() {
        return (
            <div>
                <Header content={`Add new ${this.personLabel} contact person`} />
                <ContactPersonForm
                    formData={this.defaultFormData}
                    parent={this.props.parent}
                    personType={this.props.personType}
                />
            </div>
        );
    }
}

export default ContactPersonFormAdd;
