import PropTypes from "prop-types";

import {getLink} from "../../app/components/Link";

import {renderModalError} from "../../../utils/forms";

export const renderTechnicalSetupModalError = (propertyLicenceId) => (
    renderModalError(
        "Technical setup",
        getLink("propertyLicences.edit", {id: propertyLicenceId}),
        null,
        "Back to property licence",
    )
);
renderTechnicalSetupModalError.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
};
renderModalError.displayName = "renderTechnicalSetupModalError";
