import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";

import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import RoleForm from '../forms/RoleForm';
import RoleModel from "../forms/RoleModel";

const RoleEdit = ({
    match: {
        params: {
            id = null
        }
    },
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Role', routes.roles.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(RoleModel, RoleForm, {id: convertToInt(id)})),
        []
    );

    return <Form />;
}

RoleEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }).isRequired
};

export default withRouter(RoleEdit);
