import {isEqual as _isEqual, uniqBy as _uniqBy, uniqWith as _uniqWith, uniq as _uniq} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {showConstraintsModal, skipSearch} from '@modules/client/utils/constraintsTables';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {getBookingStatusForOfferingsDefaultValues} from '@utils/booking';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import {
    EVENT_SPORTRADAR_TYPE,
    EVENT_NON_SPORTRADAR_TYPE,
    COMPETITOR_NOT_DEFINED_BOTH,
    COMPETITOR_NOT_DEFINED,
} from '@constants/variables';
import {GetClientOfferingsTableData, GetDropdownData} from '@graphql/clientOffering/query';

import ClientOfferingsTable from '../components/ClientOfferingsTable';
import ClientOfferingsFilters from '../components/ClientOfferingsFilters';
import {exportExcel as clientOfferingsExportExcel} from '../utils/export/clientOfferingsExcel';

class ClientOfferings extends Component {
    static propTypes = {
        DataClientOfferings: PropTypes.shape({
            clientOfferings: PropTypes.array,
            loading: PropTypes.bool,
        }),
        dataDropDown: PropTypes.shape({
            clientPackages: PropTypes.array,
            contentCategoryLevel1: PropTypes.array,
            contentTiers: PropTypes.array,
            countries: PropTypes.array,
            deviceCategories: PropTypes.array,
            distributionTypes: PropTypes.array,
            eventTypes: PropTypes.array,
            loading: PropTypes.bool.isRequired,
            productsDropdown: PropTypes.array,
            propertyLicences: PropTypes.array,
            sports: PropTypes.array,
            eventContentTypes: PropTypes.array,
            eventContentVariants: PropTypes.array,
        }),
        filters: PropTypes.shape({
            bookingStatus: PropTypes.arrayOf(PropTypes.string),
            clientPackages: PropTypes.number,
            isHq: PropTypes.number,
            contentCategoryLevel1: PropTypes.arrayOf(PropTypes.string),
            contentCategoryLevel2: PropTypes.arrayOf(PropTypes.string),
            contentCategoryLevel3: PropTypes.arrayOf(PropTypes.string),
            contentTiers: PropTypes.arrayOf(PropTypes.string),
            countries: PropTypes.arrayOf(PropTypes.string),
            deviceCategories: PropTypes.arrayOf(PropTypes.string),
            distributionTypes: PropTypes.arrayOf(PropTypes.string),
            eventTypes: PropTypes.number,
            from: PropTypes.string,
            products: PropTypes.arrayOf(PropTypes.string),
            propertyLicences: PropTypes.arrayOf(PropTypes.number),
            sports: PropTypes.arrayOf(PropTypes.string),
            to: PropTypes.string,
            tournamentCategories: PropTypes.arrayOf(PropTypes.string),
            tournaments: PropTypes.arrayOf(PropTypes.string),
            eventContentTypes: PropTypes.arrayOf(PropTypes.string),
            eventContentVariants: PropTypes.arrayOf(PropTypes.string),
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        DataClientOfferings: {
            clientOfferings: [],
        },
        dataDropDown: {
            clientPackages: [],
            contentCategoryLevel1: [],
            contentTiers: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: [],
            loading: false,
            products: [],
            propertyLicences: [],
            sports: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
        filters: {
            bookingStatus: getBookingStatusForOfferingsDefaultValues(),
            clientPackages: [],
            isHq: null,
            contentCategoryLevel1: [],
            contentCategoryLevel2: [],
            contentCategoryLevel3: [],
            contentTiers: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: null,
            from: moment().format('YYYY-MM-DD 00:00'),
            to: moment().add(1, 'day').format('YYYY-MM-DD 00:00'),
            products: [],
            propertyLicences: [],
            sports: [],
            tournamentCategories: [],
            tournaments: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
    };

    cellRefs = [];

    state = {
        clientOfferings: [],
        clientOfferingsOriginal: [],
        contentCategoryLevel1: [],
        contentCategoryLevel1Original: [],
        heightAdjusted: false,
        propertyLicences: [],
        propertyLicencesOriginal: [],
        sports: [],
        sportsOriginal: [],
        eventTypes: [],
        eventTypesOriginal: [],
    };

    componentDidMount() {
        if (skipSearch(this.props)) {
            showConstraintsModal(this.props.filters);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataClientOfferings: {clientOfferings},
            dataDropDown: {
                contentCategoryLevel1,
                propertyLicences,
                eventTypes,
                sports,
            },
        } = nextProps;

        let newState = {};

        if (contentCategoryLevel1 && prevState.contentCategoryLevel1Original !== contentCategoryLevel1) {
            newState.contentCategoryLevel1Original = contentCategoryLevel1;
            newState.contentCategoryLevel1 = sortDropdownOptionsAlphanumerically(contentCategoryLevel1);
        }

        if (propertyLicences && prevState.propertyLicencesOriginal !== propertyLicences) {
            newState.propertyLicencesOriginal = propertyLicences;
            newState.propertyLicences = mapPropertyLicencesForDropdown(propertyLicences);
        }

        if (eventTypes && prevState.eventTypesOriginal !== eventTypes) {
            newState.eventTypesOriginal = eventTypes;
            newState.eventTypes = eventTypes.map((eventType) => ({
                key: eventType.key,
                text: eventType.text,
                value: convertToInt(eventType.value),
            }));
        }

        if (sports && prevState.sportsOriginal !== sports) {
            newState.sportsOriginal = sports;
            newState.sports = sortDropdownOptionsAlphanumerically(sports);
        }

        if (clientOfferings && prevState.clientOfferingsOriginal !== clientOfferings) {
            let resultOfferings = [];

            clientOfferings.forEach((offering, indexOffering) => {
                if (1 === offering.offering_geo_restrictions.length
                    && null !== offering.offering_geo_restrictions[0].country_iso_alpha2_codes
                ) {
                    resultOfferings.push(ClientOfferings.createOfferingForTable(
                        indexOffering,
                        offering,
                        [offering.offering_geo_restrictions[0]]
                    ));
                } else if (
                    0 === offering.offering_geo_restrictions.length
                    || null === offering.offering_geo_restrictions[0].country_iso_alpha2_codes
                ) {
                    resultOfferings.push(ClientOfferings.createOfferingForTable(indexOffering, offering));
                } else {
                    const allCountries = offering.offering_geo_restrictions.map(el =>
                            el.country_iso_alpha2_codes
                        ).reduce((prev, curr) => (prev.concat(curr))).sort(),
                        allBlackoutZones = offering.offering_geo_restrictions
                            .flatMap(el => el.blackout_zones)
                            .map(bz => bz.id);

                    const uniqueCountries = _uniq(allCountries),
                        uniqueBlackoutZones = _uniq(allBlackoutZones),
                        deviceCategoriesCount = offering.offering_geo_restrictions.length;

                    if (uniqueCountries.length === (allCountries.length / deviceCategoriesCount)
                        && uniqueBlackoutZones.length === (allBlackoutZones.length / deviceCategoriesCount)
                    ) {
                        const deviceCategoryName = offering.offering_geo_restrictions.map(
                            (geoRestriction) => geoRestriction.device_category.name
                        ).join(', ');

                        let geoRestriction = Object.assign(
                            {},
                            offering.offering_geo_restrictions[0], {device_category: {name: deviceCategoryName}}
                        );

                        if (null !== offering.offering_geo_restrictions[0].country_iso_alpha2_codes) {
                            resultOfferings.push(ClientOfferings.createOfferingForTable(
                                indexOffering,
                                offering,
                                [geoRestriction]
                            ));
                        } else {
                            resultOfferings.push(ClientOfferings.createOfferingForTable(indexOffering, offering));
                        }
                    } else {
                        let geoRestrictions = [];

                        offering.offering_geo_restrictions.forEach((geoRestriction) => {
                            geoRestrictions.push(geoRestriction);
                        });

                        resultOfferings.push(ClientOfferings.createOfferingForTable(
                            indexOffering,
                            offering,
                            geoRestrictions,
                            true
                        ));
                    }
                }
            });

            newState.clientOfferingsOriginal = clientOfferings;
            newState.clientOfferings = resultOfferings;
        }

        return newState;
    }

    static createOfferingForTable = (index, offering, geoRestrictions = [], hasMultipleGeoRestrictions = false) => (
        Object.assign({}, offering, {
            id: index,
            content_category_level_1_name: offering.content_category_level_1_name,
            content_category_level_2_name: offering.content_category_level_2_name,
            content_category_level_3_name: offering.content_category_level_3_name,
            content_tier_name: ClientOfferings.getContentTierName(offering),
            competitors: ClientOfferings.getCompetitors(offering),
            offering_geo_restrictions: geoRestrictions,
            has_multiple_geo_restrictions: hasMultipleGeoRestrictions,
        })
    );

    static getCompetitors = (data) => {
        let competitors = [],
            defaultCompetitors = COMPETITOR_NOT_DEFINED_BOTH;

        if (data.match_id) {
            competitors = ClientOfferings.getCompetitorsByMatch(data);
        } else if (
            EVENT_SPORTRADAR_TYPE === data.event_type
            && !data.is_master_event
            && (null !== data.sr_event_home_competitor || null !== data.sr_event_away_competitor)
        ) {
            competitors.push(ClientOfferings.getCompetitorsByField(data, 'sr_event_home_competitor'));
            competitors.push(ClientOfferings.getCompetitorsByField(data, 'sr_event_away_competitor'));
        } else if (
            EVENT_NON_SPORTRADAR_TYPE === data.event_type
            && !data.is_master_event
            && (null !== data.av_event_home_competitor || null !== data.av_event_away_competitor)
        ) {
            competitors.push(ClientOfferings.getCompetitorsByField(data, 'av_event_home_competitor'));
            competitors.push(ClientOfferings.getCompetitorsByField(data, 'av_event_away_competitor'));
        } else if (data.is_master_event) {
            defaultCompetitors = '';
        }

        if (1 === _uniqWith(competitors, _isEqual).length && COMPETITOR_NOT_DEFINED === competitors[0]) {
            competitors = [];
        }

        return (0 < competitors.length) ? (competitors.join(' : ')) : defaultCompetitors;
    };

    static getCompetitorsByMatch = (data) => {
        let competitors = [];

        competitors.push(ClientOfferings.getCompetitorsByField(data, 'match_home_competitor'));
        competitors.push(ClientOfferings.getCompetitorsByField(data, 'match_away_competitor'));

        return competitors;
    };

    static getCompetitorsByField = (data, competitorField) => {
        if (null !== competitorField && data[competitorField]) {
            return data[competitorField];
        } else {
            return COMPETITOR_NOT_DEFINED;
        }
    };

    static getContentTierName = (offering) => {
        if (null !== offering.av_event_content_tier_name) {
            return offering.av_event_content_tier_name;
        } else if (null !== offering.sr_event_content_tier_name) {
            return offering.sr_event_content_tier_name;
        } else if (null !== offering.match_content_tier_name) {
            return offering.match_content_tier_name;
        }

        return null;
    };

    componentDidUpdate() {
        if (!this.state.heightAdjusted) {
            let res = this.adjustHeight();

            if (res) {
                this.setState(() => ({heightAdjusted: true}));
            }
        }
    }

    setCellRef = (el) => {
        if (null !== el) {
            this.cellRefs.push(el.firstChild);
        }
    };

    adjustHeight = () => {
        let multiRows = this.cellRefs.filter(el => el.className.includes('--multiRow'));

        if (0 !== multiRows.length) {
            multiRows.forEach((multiRow) => {
                let dataId = multiRow.dataset.id.split('_');

                let rows = multiRows.filter((filterRow) => {
                    let filterRowDataId = filterRow.dataset.id.split('_');

                    return filterRowDataId[0] === dataId[0] && filterRowDataId[1] === dataId[1];
                });

                if (0 < rows.length && 1 < _uniqBy(rows, 'clientHeight').length) {
                    let maxHeight = 0;

                    rows.forEach((row) => {
                        if (row.clientHeight >= maxHeight) {
                            maxHeight = row.clientHeight;
                        }
                    });

                    rows.forEach((row) => {
                        if (row.clientHeight !== maxHeight) {
                            row.style.lineHeight = `${maxHeight}px`;
                        }

                        row.style.height = `${maxHeight}px`;
                    });
                }
            });

            return true;
        }

        return false;
    };

    render() {
        const clientId = convertToInt(this.props.match.params.id),
            defaultProps = ClientOfferings.defaultProps.dataDropDown,
            dropdownProps = this.props.dataDropDown,
            loading = (
                this.props.DataClientOfferings.loading
                || this.props.dataDropDown.loading
                || this.props.loadingRefetch
            ),
            loadingTable = (this.props.DataClientOfferings.loading || this.props.loadingRefetch),
            filtersProps = {
                additionalFilterId: this.props.match.params.id,
                data: {
                    clientPackages: dropdownProps.clientPackages || defaultProps.clientPackages,
                    contentCategoriesLevel1: this.state.contentCategoryLevel1,
                    contentTiers: dropdownProps.contentTiers || defaultProps.contentTiers,
                    countries: dropdownProps.countries || defaultProps.countries,
                    deviceCategories: dropdownProps.deviceCategories || defaultProps.deviceCategories,
                    distributionTypes: dropdownProps.distributionTypes || defaultProps.distributionTypes,
                    eventTypes: this.state.eventTypes,
                    products: dropdownProps.productsDropdown || defaultProps.products,
                    propertyLicences: this.state.propertyLicences,
                    sports: this.state.sports,
                    eventContentTypes: dropdownProps.eventContentTypes || defaultProps.eventContentTypes,
                    eventContentVariants: dropdownProps.eventContentVariants || defaultProps.eventContentVariants,
                },
                loading: loading,
            };

        return (
            <div>
                <HeaderRenderer
                    buttonsProps={{clientID: clientId}}
                    exportExcelParams={clientOfferingsExportExcel(this.state.clientOfferings, clientId)}
                    filters={ClientOfferingsFilters}
                    filtersProps={filtersProps}
                    loading={loading}
                />
                <Segment basic className='--table'>
                    <ClientOfferingsTable
                        adjustHeight={this.adjustHeight}
                        clientOfferings={this.state.clientOfferings}
                        clientId={clientId}
                        loading={loadingTable}
                        refProperty={this.setCellRef}
                    />
                </Segment>
            </div>
        );
    }
}

const ClientOfferingsWithQuery = compose(
    graphql(GetClientOfferingsTableData, {
        skip: (props) => {
            return skipSearch(props);
        },
        options: (props) => {
            let defaultFilters = ClientOfferings.defaultProps.filters;

            let clientPackages = props.filters.clientPackages || defaultFilters.clientPackages,
                contentCategoryLevel1 = props.filters.contentCategoryLevel1 || defaultFilters.contentCategoryLevel1,
                contentCategoryLevel2 = props.filters.contentCategoryLevel2 || defaultFilters.contentCategoryLevel2,
                contentCategoryLevel3 = props.filters.contentCategoryLevel3 || defaultFilters.contentCategoryLevel3,
                contentTiers = props.filters.contentTiers || defaultFilters.contentTiers,
                countries = props.filters.countries || defaultFilters.countries,
                deviceCategories = props.filters.deviceCategories || defaultFilters.deviceCategories,
                distributionTypes = props.filters.distributionTypes || defaultFilters.distributionTypes,
                eventTypes = props.filters.eventTypes || defaultFilters.eventTypes,
                products = props.filters.products || defaultFilters.products,
                sports = props.filters.sports || defaultFilters.sports,
                tournamentCategories = props.filters.tournamentCategories || defaultFilters.tournamentCategories,
                tournaments = props.filters.tournaments || defaultFilters.tournaments,
                eventContentTypes = props.filters.eventContentTypes || defaultFilters.eventContentTypes,
                eventContentVariants = props.filters.eventContentVariants || defaultFilters.eventContentVariants;

            return {
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables: {
                    bookingStatus: props.filters.bookingStatus,
                    client: convertToInt(props.match.params.id),
                    clientPackages: (props.filters.clientPackages)
                        ? props.filters.clientPackages.map(id => convertToInt(id))
                        : clientPackages,
                    isHq: convertBooleanValueForFilters(props.filters.isHq, null),
                    contentCategoryLevel1: (props.filters.contentCategoryLevel1)
                        ? props.filters.contentCategoryLevel1.map(id => convertToInt(id))
                        : contentCategoryLevel1,
                    contentCategoryLevel2: (props.filters.contentCategoryLevel2)
                        ? props.filters.contentCategoryLevel2.map(id => convertToInt(id))
                        : contentCategoryLevel2,
                    contentCategoryLevel3: (props.filters.contentCategoryLevel3)
                        ? props.filters.contentCategoryLevel3.map(id => convertToInt(id))
                        : contentCategoryLevel3,
                    contentTiers: (props.filters.contentTiers)
                        ? props.filters.contentTiers.map(id => convertToInt(id))
                        : contentTiers,
                    countries: props.filters.countries ? props.filters.countries : countries,
                    deviceCategories: (props.filters.deviceCategories)
                        ? props.filters.deviceCategories.map(id => convertToInt(id))
                        : deviceCategories,
                    distributionTypes: (props.filters.distributionTypes)
                        ? props.filters.distributionTypes.map(id => convertToInt(id))
                        : distributionTypes,
                    endDate: props.filters.to ? `${props.filters.to}:00` : defaultFilters.to,
                    eventTypes: (props.filters.eventTypes)
                        ? convertToInt(props.filters.eventTypes)
                        : eventTypes,
                    products: (props.filters.products)
                        ? props.filters.products.map(id => convertToInt(id))
                        : products,
                    propertyLicences: props.filters.propertyLicences || defaultFilters.propertyLicences,
                    sports: (props.filters.sports)
                        ? props.filters.sports.map(id => convertToInt(id))
                        : sports,
                    startDate: props.filters.from ? `${props.filters.from}:00` : `${defaultFilters.from}:00`,
                    tournamentCategories: (props.filters.tournamentCategories)
                        ? props.filters.tournamentCategories.map(id => convertToInt(id))
                        : tournamentCategories,
                    tournaments: (props.filters.tournaments)
                        ? props.filters.tournaments.map(id => convertToInt(id))
                        : tournaments,
                    eventContentTypes: (props.filters.eventContentTypes)
                        ? props.filters.eventContentTypes.map(id => convertToInt(id))
                        : eventContentTypes,
                    eventContentVariants: (props.filters.eventContentVariants)
                        ? props.filters.eventContentVariants.map(id => convertToInt(id))
                        : eventContentVariants,
                },
            };
        },
        name: 'DataClientOfferings',
    }),
    graphql(GetDropdownData, {
        options: (props) => ({
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            variables: {client: [convertToInt(props.match.params.id)]},
        }),
        name: 'dataDropDown',
    })
)(FilterQueryWrapper(ClientOfferings, {
    queryForRefresh: 'DataClientOfferings',
    filterUrls: ['clients.offerings.index'],
}));

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, `ClientOfferings${ownProps.match.params.id}`, ClientOfferings.defaultProps.filters),
});

export default withRouter((connect(mapStateToProps)(
    FilterUrlParamsWrapper(ClientOfferingsWithQuery, ClientOfferings.defaultProps.filters)
)));
