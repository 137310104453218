import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {COMPETITOR_NOT_DEFINED, COMPETITOR_NOT_DEFINED_BOTH} from '@constants/variables';
import {addHasMatchIdToStructure} from '@utils/eventObject';

import {getCompetitorsObjectFromEvent} from './competitorObject';

/**
 * Get "Competitors name" by string / null

 * @param {(null|string)} homeName
 * @param {(null|string)} awayName

 * @returns {string}
 */
export const getCompetitorsNameByTeam = (homeName, awayName) => (
    (homeName || awayName)
        ? `${homeName ? homeName : COMPETITOR_NOT_DEFINED} : ${awayName ? awayName : COMPETITOR_NOT_DEFINED}`
        : COMPETITOR_NOT_DEFINED_BOTH
);

getCompetitorsNameByTeam.propTypes = {
    homeName: PropTypes.string,
    awayName: PropTypes.string,
};

getCompetitorsNameByTeam.defaultProps = {
    homeName: null,
    awayName: null,
};

/**
 * Get competitors from event object
 * @param {Object} event
 * @returns {string}
 */
export const getCompetitorsNameFromEvent = (event) => {
    const competitorObject = getCompetitorsObjectFromEvent(addHasMatchIdToStructure(event));

    return getCompetitorsNameByTeam(competitorObject.home.name, competitorObject.away.name);
};

getCompetitorsNameFromEvent.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            away_competitor: PropTypes.shape({
                name: PropTypes.string,
            }),
            home_competitor: PropTypes.shape({
                name: PropTypes.string,
            }),
            match: PropTypes.shape({
                away_competitor: PropTypes.shape({
                    name: PropTypes.string,
                }),
                home_competitor: PropTypes.shape({
                    name: PropTypes.string,
                }),
            }),
        }),
        av_event: PropTypes.shape({
            away_competitor: PropTypes.shape({
                name: PropTypes.string,
            }),
            home_competitor: PropTypes.shape({
                name: PropTypes.string,
            }),
        }),
        _hasMatchID: PropTypes.bool.isRequired,
    }),
};

getCompetitorsNameFromEvent.defaultProps = {
    event: {
        sr_event: {
            away_competitor: {
                name: null,
            },
            home_competitor: {
                name: null,
            },
            match: {
                away_competitor: {
                    name: null,
                },
                home_competitor: {
                    name: null,
                },
            },
        },
        av_event: {
            away_competitor: {
                name: null,
            },
            home_competitor: {
                name: null,
            },
        },
    },
};

/**
 * Get competitor name from match object
 * @param {Object} match
 * @returns {string}
 */
export const getCompetitorsNameFromMatch = (match) => {
    let competitorHomeName = _get(match, 'home_competitor.name', null),
        competitorAwayName = _get(match, 'away_competitor.name', null);

    if (!competitorHomeName && !competitorAwayName) {
        return COMPETITOR_NOT_DEFINED_BOTH;
    }

    const defaultUndefinedCompetitor = COMPETITOR_NOT_DEFINED,
        competitorHome = `${(competitorHomeName || defaultUndefinedCompetitor)}`,
        competitorAway = `${(competitorAwayName || defaultUndefinedCompetitor)}`;

    return `${competitorHome} : ${competitorAway}`;
};

getCompetitorsNameFromMatch.propTypes = {
    match: PropTypes.object.isRequired,
};
