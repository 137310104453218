import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {HAS_MULTIAUDIOSUPPORT} from '@constants/staticDropdowns';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';

export const EncodingJobProfileFilters = (props) => {
    const { distributionTypes, products } = props.data,
        loading = props.loading;

    return (
        <div className='encoding_job_profiles__filters filter__controls__container'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'search')}
            />

            <Field
                component={Form.ReduxSelect}
                name='products'
                placeholder='Product'
                options={products}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'products')}
            />
            <Field
                component={Form.ReduxSelect}
                name='distributionTypes'
                placeholder='Distribution type'
                options={distributionTypes}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'distributionTypes')}
            />
            <Field
                className='--minimalWidth'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='hasDrmSupport'
                options={[
                    {text: 'DRM', value: 1},
                    {text: 'No DRM', value: 0},
                ]}
                placeholder='DRM?'
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'hasDrmSupport')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                options={HAS_MULTIAUDIOSUPPORT}
                name='hasMultiAudioSupport'
                placeholder='Multi audio?'
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'hasMultiAudioSupport')}
            />
            <Field
                className='--minimalWidth --isDefault'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isDefault'
                options={[
                    {text: 'Default', value: 1},
                    {text: 'Not default', value: 0},
                ]}
                placeholder='Default?'
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'isDefault')}
            />
            <Field
                className='--minimalWidth --status'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isDisabled'
                options={[
                    {text: 'Enabled', value: 0},
                    {text: 'Disabled', value: 1},
                ]}
                placeholder='Status'
                setValue={props.restoreFilterValues(props.filters.encodingJobProfiles, 'isDisabled')}
            />
        </div>
    );
};

EncodingJobProfileFilters.propTypes = {
    data: PropTypes.object.isRequired,
    distributionTypes: PropTypes.array,
    products: PropTypes.array,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
};

EncodingJobProfileFilters.defaultProps = {
    distributionTypes: [],
    filters: {
        encodingJobProfiles: {},
    },
    loading: false,
    products: [],
};

export default FiltersWrapper(EncodingJobProfileFilters, {name: 'encodingJobProfiles'});
