import validators from '../../../utils/validators';

export const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.description, 'description'));
    Object.assign(errors, validators.required(values.message, 'message'));

    return errors;
};
