import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import { ReportWorkingInvoiceableEvents } from '@graphql/reports/mutation';
import {
    REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE,
} from '@modules/events/utils/reportMultipleAsWorkingAndInvoiceableHelper';

const ReportMultipleEventsAsWorkingModel = () => (
    {
        title: () => null,
        entityLabel: REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE,
        label: REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE,
        formName: 'ReportMultipleEventsAsWorkingModel',
        showChangelog: false,
        validate: null,
        messages: {
            boxName: {
                success: 'reportAsWorkingAndInvoiceableSuccessMessage',
                error: 'reportAsWorkingAndInvoiceableErrorMessage',
            },
            text: {
                ...MESSAGES,
                SAVED: () => 'Events marked as working and invoiceable',
                NOT_SAVED: () => 'Events NOT marked.',
                FORM_ERROR: () => 'Events could not be saved, please correct fields below.',
            },
        },
        fields: {
            events: {
                props: {
                    component: Form.SemanticInput,
                    label: 'list of event ids',
                    name: 'events',
                    hidden: true,
                    type: 'select',
                },
            },
        },
        mutation: {
            createEntity: ReportWorkingInvoiceableEvents,
        },
        query: {},
    }
);

export default ReportMultipleEventsAsWorkingModel;
