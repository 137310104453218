import PropTypes from 'prop-types';

import {getProperty} from "../common";

/**
 * Display value for 1 st level category
 */
export const firstLevelCategory = (event) => {
    const eventData = {
        data: event,
        nonSr: ['content_category', 'parent', 'parent']
    };

    return getProperty(eventData, 'name');
};

firstLevelCategory.propTypes = {
    event: PropTypes.object.isRequired
}
