const alertFragment = `
fragment Alert on EventContentAlert {
  id
  message
  event_content_alert_template {
    id
    label
    event_content_alert_type {
        id
        name
    }
  }
  creation_datetime
  creation_user {
    id
    name
  }
}`;

export const templatesData = `
query templatesData {
  eventContentAlertTemplates {
    id
    key: id
    text: label
    value: id
	message
  }
}`;

export const indexQuery = `
${alertFragment}

query EventContentAlerts($eventContentId: Int!){
  eventContentAlerts(filters: {eventContent: $eventContentId}) {
    ...Alert
  }
}`;

export const sendAlert = `
${alertFragment}

mutation sendAlert(
  $eventContentId: Int!
  $template: Int!
  $message: String!
  $recipients: [ID]!
  $additional_recipients: [String]!
) {
  createEventContentAlert(
    event_content: $eventContentId
    event_content_alert_template: $template
    message: $message
    recipients: $recipients
    additional_recipients: $additional_recipients
  ) {
    ...Alert
  }
}`;
