import PropTypes from 'prop-types';

import {convertToInt} from './helpers';

export const getInitialSavedEventContentVariants = (savedEventContentVariants) => {
    const EventContentVariantsMap = new Map();

    savedEventContentVariants.forEach((eventContentVariant) => {
        const productId = convertToInt(eventContentVariant.product.id),
            usageTypeId = convertToInt(eventContentVariant.event_content_variant.id),
            entityEventContentVariantId = convertToInt(eventContentVariant.id);

        if (!EventContentVariantsMap.get(productId)) {
            EventContentVariantsMap.set(productId, new Map());
        }

        EventContentVariantsMap
            .get(productId)
            .set(usageTypeId, entityEventContentVariantId);
    });

    return EventContentVariantsMap;
};

getInitialSavedEventContentVariants.propTypes = {
    savedUsageTypes: PropTypes.object.isRequired,
};

export const getInitialSelectedEventContentVariants = (initialEventContentVariantsSelected) => {
    const selectedMap = new Map();

    if (0 === initialEventContentVariantsSelected.length) {
        return selectedMap;
    }

    initialEventContentVariantsSelected.forEach((EventContentVariantSelected) => {
        const productId = convertToInt(EventContentVariantSelected.product.id),
            eventContentVariantId = convertToInt(EventContentVariantSelected.event_content_variant.id);

        if (!selectedMap.has(productId)) {
            selectedMap.set(productId, []);
        }

        selectedMap
            .get(productId)
            .push(eventContentVariantId);
    });

    return selectedMap;
};

getInitialSelectedEventContentVariants.propTypes = {
    initialUsageTypesSelected: PropTypes.object.isRequired,
};
