import React from "react";

import Link from "../../app/components/Link";
import { Button, Icon } from 'semantic-ui-react';

const EventsTabsLayoutButtons = () => {
    return (
        <div className="indexButtons">
            <Link name="events.index">
                <Button className="button-back">
                    <Icon className="arrow circle left"/> Back to list
                </Button>
            </Link>
        </div>
    )
};

export default EventsTabsLayoutButtons;
