import {isNumber as _isNumber} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Popup} from 'semantic-ui-react';

import {convertToInt} from '@utils/helpers';
import PopupTable from '@appComponents/PopupTable';
import SegmentHeader from '@appComponents/SegmentHeader';
import Table from '@appComponents/Table';

export const EventsConfigurationMatchEventsTable = ({loading, matchEvents, sport}) => {
    const SPORT_ID_TENNIS = 5;

    const isColumnHidden = (column, sport) => {
        const sportId = convertToInt(sport);

        if (SPORT_ID_TENNIS === sportId) {
            return ['players'].includes(column);
        }

        if (SPORT_ID_TENNIS !== sportId) {
            return ['server', 'comment'].includes(column);
        }

        return false;
    };

    const alignCenter = {
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
        columns = {
            id: {
                label: 'ID',
                sorting: (row) => (convertToInt(row.id)),
                ...alignCenter,
            },
            type: {
                label: 'Type',
                sorting: (row) => (getType(row)),
            },
            time: {
                label: 'Time',
                sorting: (row) => (getTime(row)),
                ...alignCenter,
            },
            competitor: {
                label: 'Competitor',
                sorting: (row) => (getHomeAwayValue(row.competitor_qualifier)),
            },
            players: {
                isHidden: isColumnHidden('players', sport),
                label: 'Players',
                sorting: (row) => (getPlayers(row).join('')),
            },
            server: {
                isHidden: isColumnHidden('server', sport),
                label: 'Server',
                sorting: (row) => (getHomeAwayValue(row.server)),
            },
            score: {
                label: 'Score',
                sorting: (row) => (getScore(row)),
                ...alignCenter,
            },
            comment: {
                isHidden: isColumnHidden('comment', sport),
                label: 'Comment',
                sorting: (row) => (getComment(row)),
            },
        };

    const renderId = (row) => {
        const popupRows = [
            {
                header: 'UUID',
                body: row.uuid || '-',
            },
            {
                header: 'Live Data ID',
                body: row.live_data_match_event_id || '-',
            },
            {
                header: 'WSC ID',
                body: row.wsc_event_id || '-',
            },
        ];

        return (
            <Popup
                className='--noPadding'
                flowing
                trigger={<div>{row.id}</div>}
                hoverable
            >
                <PopupTable rows={popupRows}/>
            </Popup>
        );
    };

    const getType = (row) => (
        `${row.match_event_type.name}${row.goal_type ? ` (${row.goal_type.name.toLowerCase()})` : ''}`
    );

    const renderType = (row) => {
        const matchEventType = (
            <div>
                {getType(row)}
            </div>
        );

        return (row.match_event_type.description)
            ? (
                <Popup
                    content={row.match_event_type.description}
                    flowing
                    trigger={matchEventType}
                />
            )
            : matchEventType;
    };

    const getTime = (row) => (moment(row.datetime).format('HH:mm:ss'));

    const renderTime = (row) => {
        const time = (
            <span>
                {getTime(row)}
            </span>
        );

        if (row.match_elapsed_time) {
            const minutes = Math.floor(row.match_elapsed_time / 60).toString().padStart(2, '0'),
                seconds = Math.ceil(row.match_elapsed_time % 60).toString().padStart(2, '0');

            return (
                <Popup
                    content={
                        `Match clock: ${minutes}:${seconds}`
                    }
                    flowing
                    trigger={time}
                />
            );
        }

        return time;
    };

    const getPlayers = (row) => (
        row.match_event_players.map((player) => {
            let playerName = player.player.last_name || '';

            if (player.player.first_name) {
                playerName += (playerName) ? `, ${player.player.first_name}` : player.player.first_name;
            }

            if (playerName && player.player_role) {
                playerName += ` (${player.player_role.name.toLowerCase()})`;
            }

            return playerName;
        })
    );

    const renderPlayers = (row) => (
        getPlayers(row).map((player, index) => (
            <div key={index}>
                {player}
            </div>
        ))
    );

    const getHomeAwayValue = (value) => ((value) ? value.capitalizeFirstLetter() : '-');

    const getHomeAwayScore = (row) => ((_isNumber(row.home_score) ||_isNumber(row.away_score)) ? `${row.home_score}-${row.away_score}` : '');

    const getScore = (row) => (row.score ? row.score : getHomeAwayScore(row));

    const getComment = (row) => (row.comment ? row.comment : '');

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
                return renderId(row);
            case 'type':
                return renderType(row);
            case 'time':
                return renderTime(row);
            case 'competitor':
                return getHomeAwayValue(row.competitor_qualifier);
            case 'players':
                return renderPlayers(row);
            case 'server':
                return getHomeAwayValue(row.server);
            case 'score':
                return getScore(row);
            case 'comment':
                return getComment(row);
            default:
                return null;
        }
    };

    return (
        <>
            <SegmentHeader>Match events</SegmentHeader>
            <Table
                className='mini'
                columns={columns}
                data={matchEvents}
                defaultSort={'id'}
                loading={loading}
                name='EventsConfigurationMatchEventsTable'
                noMessageBox
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </>
    );
};

EventsConfigurationMatchEventsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    matchEvents: PropTypes.arrayOf(PropTypes.shape({
        away_score: PropTypes.number,
        competitor_qualifier: PropTypes.string,
        datetime: PropTypes.string.isRequired,
        goal_type: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        home_score: PropTypes.number,
        id: PropTypes.string.isRequired,
        uuid: PropTypes.string,
        live_data_match_event_id: PropTypes.number,
        match_elapsed_time: PropTypes.number,
        match_event_players: PropTypes.arrayOf(PropTypes.shape({
            player: PropTypes.shape({
                first_name: PropTypes.string,
                last_name: PropTypes.string,
            }),
            player_role: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
        })),
        match_event_type: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        wsc_event_id: PropTypes.string,
    })),
    sport: PropTypes.number.isRequired,
};
