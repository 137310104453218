// eslint-disable-next-line
import {ApolloClient, from, HttpLink, InMemoryCache} from '@apollo/client';
import fetch from 'cross-fetch';
import {onError} from '@apollo/client/link/error';

import {setInfoBoxError} from '@reducers/InfoBoxes';
import {reportError} from '@actions';
import {ENV_ERROR_REPORT} from '@constants/variables';

import avcmpConfig from './avcmpConfig';
import possibleTypes from '../config/graphql/possibleTypes.json';

const httpLink = new HttpLink({
    uri: avcmpConfig.apolloClient.networkInterface.uri,
    fetch,
});

const errorLink = onError(({ graphQLErrors }) => {
    let reportErrorFlag = false;

    if (graphQLErrors) {
        const errorInfo = [];

        graphQLErrors.forEach(({message, locations, path}) => {
            if (500 === errorInfo.code) {
                reportErrorFlag = true;
            }

            return errorInfo.push(`${message}\n${locations}\n${path}`);
        });

        if (!reportErrorFlag) {
            return;
        }

        reportError({
            url: window.location.href,
            error: errorInfo.join('\n\n'),
        });

        if (ENV_ERROR_REPORT.includes(process.env.NODE_ENV)) {
            // eslint-disable-next-line
            console.error(`APOLLO ERROR: ${errorInfo}`);
        }


        setInfoBoxError({
            content: 'Error in GraphQL occured. Log has been sent.',
        });
    }
});


const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({
        possibleTypes,
    }),
    connectToDevTools: ('development' === process.env.NODE_ENV),
});

export default client;
