import {sortBy as _sortBy} from 'lodash';

import {convertToInt, getIdsFromChildren, prepareClientProductUsages} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';

export const getSelectionTypeValue = (selectionType) => (
    'undefined' !== typeof selectionType ? convertToInt(selectionType) : null
);

export const showErrorNotice = () => (
    showMessageBox(
        'formInnerErrorMessage',
        '',
        '"Selection type" (from the "Geo restrictions" tab) and "Blackout Zones" cannot be both empty',
        'error',
        true
    )
);

export const getDataFromAllSteps = (stepsMethods, eventId) => {
    const preparedData =Object.assign(
            {},
            stepsMethods.getForms()['step1'].formValues,
            stepsMethods.getForms()['step2'].formValues,
            stepsMethods.getForms()['step3'].formValues
        ),
        countries = getIdsFromChildren(preparedData.countries),
        subdivisions = getIdsFromChildren(preparedData.subdivisions),
        selectionType = getSelectionTypeValue(preparedData.selection_type);

    return {
        id: convertToInt(preparedData.id),
        event: convertToInt(eventId),
        client_selection_type: convertToInt(preparedData.client_selection_type),
        clients: preparedData.clients.map((client) => convertToInt(client)),
        includes_all_event_contents: preparedData.includes_all_event_contents,
        event_contents: preparedData.includes_all_event_contents ? [] : preparedData.event_contents,
        usages: prepareClientProductUsages(preparedData.usages),
        notes: preparedData.notes || '',
        territory_selection_type: selectionType,
        countries: countries,
        country_subdivisions: subdivisions,
        blackout_zones: preparedData.blackoutZones.map(Number),
    };
};

export const getCountryTreeOptions = (data, callbackForItem) => {
    const continentOptions = [];

    data.continents.forEach((continent) => {
        const countryOptions = [];

        continent.children.forEach((country) => {
            let selected = false;

            if (-1 < data.selectedCountries.indexOf(country.value)) {
                selected = true;
            }

            let item = {
                ...country,
                selected: selected,
            };

            if (callbackForItem) {
                item = callbackForItem({country: country, item: item});
            }

            countryOptions.push(item);
        });

        continentOptions.push({
            ...continent,
            children: _sortBy(countryOptions, 'name'),
        });
    });

    return continentOptions;
};

export const getSubdivisionTreeOptions = (data) => {
    const countryOptions = [];

    data.countries.forEach((country) => {
        let hidden = true;
        const subdivisionOptions = [];

        if (-1 < data.selectedCountries.indexOf(country.value)) {
            hidden = false;

            country.children.forEach((child) => {
                subdivisionOptions.push({
                    ...child,
                    selected: -1 < data.selectedSubdivisions.indexOf(child.value),
                });
            });
        }

        countryOptions.push({
            ...country,
            hidden: hidden,
            children: _sortBy(subdivisionOptions, 'name'),
        });
    });

    return countryOptions;
};

