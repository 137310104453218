import moment from 'moment';

export const formatStartEndDateTime = (startDateTime, endDateTime) => {
    const startFormatted = (null === startDateTime || startDateTime === undefined) ? '' :
        moment(startDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');

    const endFormatted = (null === endDateTime || endDateTime === undefined) ? '' :
        ` - ${moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')}`;

    return startFormatted + endFormatted;
};
