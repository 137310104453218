import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {CLIENT_SELECTION_TYPE_ALL} from '@constants/variables';
import {EVENT_GEO_RESTRICTION} from '@constants/resources';
import {GeoRestrictionsDropdownsOptions} from '@graphql/eventGeoRestriction/query';

import validatorEventGeoRestriction from '../validatorEventGeoRestriction';

export default function EventGeoRestrictionsFormGeneralInformationModel() {
    return {
        editForm: false,
        entityDataMapKey: 'eventGeoRestriction',
        entityLabel: 'geo restriction',
        formName: 'EventGeoRestrictionGeneralInformation',
        label: 'geo restriction',
        resources: EVENT_GEO_RESTRICTION,
        title: null,
        showChangeLog: true,
        validate: validatorEventGeoRestriction,
        dataMap: {
            eventGeoRestriction: {
                client_selection_type: {
                    id: '1',
                },
            },
        },
        messages: {
            boxName: {
                delete: 'event',
                error: 'event',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventGeoRestriction.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            client_selection_type: {
                optionsKey: 'selectionTypes',
                props: {
                    component: Form.SemanticSelect,
                    defaultValue: CLIENT_SELECTION_TYPE_ALL + '',
                    label: 'Clients',
                    name: 'client_selection_type',
                    required: true,
                },
            },
            clients: {
                optionsKey: 'clients',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: ' ',
                    multiple: true,
                    name: 'clients',
                    required: false,
                },
            },
            all_event_contents: {
                props: {
                    component: Form.SemanticInput,
                    defaultValue: true,
                    label: 'Includes all event contents',
                    name: 'includes_all_event_contents',
                    type: 'checkbox',
                },
            },
            event_contents: {
                optionsKey: 'eventContents',
                props: {
                    component: Form.SemanticCheckboxList,
                    hidden: true,
                    label: 'Event contents',
                    name: 'event_contents',
                    required: false,
                },
            },
            usage: {
                props: {
                    component: Form.SemanticCheckboxTree,
                    label: 'Usage',
                    name: 'usages',
                    required: true,
                },
            },
            notes: {
                dataMapKey: 'eventGeoRestriction.notes',
                props: {
                    component: Form.SemanticInput,
                    label: 'Notes',
                    name: 'notes',
                },
            },
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            update: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            delete: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
        },
        query: {
            getOptions: GeoRestrictionsDropdownsOptions,
        },
    };
}
