import PropTypes from "prop-types";
import React from "react";
import {gql, graphql} from "react-apollo";
import {withRouter} from "react-router";
import {compose} from "redux";
import {reduxForm} from "redux-form";
import {Popup, Segment} from "semantic-ui-react";

import Authorization from "../../app/components/Authorization";
import {ButtonSave} from "../../app/components/ButtonCollection";
import {FormWrapper} from "../../app/components/HOCFormWrapper";
import {IconQuestionCircle} from "../../app/components/IconCollection";
import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorClientAnalyticsSetup";
import * as CLIENT_MESSAGES from "../constants/messages";
import * as MESSAGES from "../../app/constants/messages";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

import * as clientAnalyticsSetupGraphQl from "../../../graphql/clients/clientAnalyticsSetup";

export class ClientAnalyticsSetupForm extends React.Component {
    static propTypes = {
        clientId: PropTypes.number.isRequired,
        clientName: PropTypes.string,
        CreateClientAnalyticsSetup: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        history: PropTypes.object,
        loading: PropTypes.bool,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        resetIndexUrl: PropTypes.func,
        submitting: PropTypes.bool,
        UpdateClientAnalyticsSetup: PropTypes.func,
        match: PropTypes.object.isRequired,
    };

    constructor (props) {
        super();

        this.state = {
            clientAnalyticsSetupId: parseInt(props.dataForForm.clientAnalyticsSetup.id, 10),
            clientAnalyticsSetup: props.formData.clientAnalyticsSetup,
        };
    }

    componentWillReceiveProps (nextProps){
        if (
            nextProps.dataForForm.clientAnalyticsSetup.id &&
            this.state.clientAnalyticsSetupId !== parseInt(nextProps.dataForForm.clientAnalyticsSetup.id, 10)
        ) {
            this.setState(() => ({clientAnalyticsSetupId: parseInt(nextProps.dataForForm.clientAnalyticsSetup.id, 10)}));
        }

        if (
            nextProps.formData.clientAnalyticsSetup &&
            this.state.clientAnalyticsSetup !== nextProps.formData.clientAnalyticsSetup
        ) {
            this.setState(() => ({clientAnalyticsSetup: nextProps.formData.clientAnalyticsSetup}));
        }
    }

    formSubmit = (data) => {
        let dataSaved;

        const dataToSave = Object.assign({}, this.props.dataMap.clientAnalyticsSetup, data, {
            client_id: this.props.clientId,
        });

        if (isNaN(this.state.clientAnalyticsSetupId)) {
            dataSaved = this.props.CreateClientAnalyticsSetup({
                variables: {...dataToSave},
            }).then((data) => {
                this.setState(() => ({
                    clientAnalyticsSetupId: parseInt(data.data.createClientAnalyticsSetup.id, 10),
                    clientAnalyticsSetup: data.data.createClientAnalyticsSetup,
                }));
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED('analytics setup for this client'),
                    null,
                    "success"
                );
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.NOT_SAVED('analytics setup for this client'),
                    `${error}`,
                    "error",
                );
            });
        } else {
            dataSaved = this.props.UpdateClientAnalyticsSetup({
                variables: Object.assign({}, {...dataToSave}, {
                    id: this.state.clientAnalyticsSetupId,
                }),
            }).then((data) => {
                this.setState(() => ({
                    clientAnalyticsSetup: data.data.updateClientAnalyticsSetup,
                }));
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED('analytics setup for this client'),
                    null,
                    "success",
                );
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.NOT_SAVED('analytics setup for this client'),
                    `${error}`,
                    "error",
                );
            });
        }

        return dataSaved.then();
    };

    renderFormRows = () => {
        return this.clientAnalyticsSetupFields(this.props).map( (clientAnalyticsSetupField) =>
            <Form.FormRow
                component={Form.SemanticInput}
                defaultValue={clientAnalyticsSetupField.defaultValue}
                description={clientAnalyticsSetupField.description}
                inline
                label={clientAnalyticsSetupField.label}
                name={clientAnalyticsSetupField.name}
                type="checkbox"
                key={clientAnalyticsSetupField.name}
            />
        )
    };

    clientAnalyticsSetupFields = (props) => [
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_overall_figures
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_OVERALL_FIGURES}
                wide="very"/>,
            name: "has_overall_figures",
            label: "Overall figures",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_top_countries
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_TOP_COUNTRIES}
                wide="very"/>,
            name: "has_top_countries",
            label: "Top countries",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_all_countries
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_ALL_COUNTRIES}
                wide="very"/>,
            name: "has_all_countries",
            label: "All countries",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_unikeys_support
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_UNIKEYS_SUPPORT}
                wide="very"/>,
            name: "has_unikeys_support",
            label: "Use unikeys",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_labels_support
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_LABELS_SUPPORT}
                wide="very"/>,
            name: "has_labels_support",
            label: "Use labels",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_session_duration
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_SESSION_DURATION}
                wide="very"/>,
            name: "has_session_duration",
            label: "Session durations",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_top_referrers
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_TOP_REFERRERS}
                wide="very"/>,
            name: "has_top_referrers",
            label: "Top referrers",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_top_streams
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_TOP_STREAMS}
                wide="very"/>,
            name: "has_top_streams",
            label: "Top streams",
        },
        {
            defaultValue:(props.dataForForm.clientAnalyticsSetup)
                ? props.dataForForm.clientAnalyticsSetup.has_stream_details
                : false,
            description: <Popup trigger={<IconQuestionCircle/>}
                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_TOOLTIP_STREAM_DETAILS}
                wide="very"/>,
            name: "has_stream_details",
            label: "Stream details",
        },
    ];

    render() {
        return (
            <Segment basic className="--form">
                <Form.Create
                    loading={this.props.loading}
                    onSubmit={this.props.handleSubmit(this.formSubmit)}
                >
                    <Form.IdField
                        defaultValue={this.state.clientAnalyticsSetupId || null}
                    />

                    <Form.FormRow
                        className="--start_date"
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.clientAnalyticsSetup.report_accessibility_date}
                        description={
                            <Popup
                                trigger={<IconQuestionCircle/>}
                                content={CLIENT_MESSAGES.CLIENT_ANALYTICS_SETUP_REPORT_ACCESSIBILITY_DATE}
                                wide="very"
                            />
                        }
                        label="Report accessibility date"
                        name="report_accessibility_date"
                        type="date"
                    />

                    {this.renderFormRows()}

                    <Form.FormRowChangelog
                        resources={RESOURCES.CLIENT_ANALYTICS_SETUP}
                        data={this.state.clientAnalyticsSetup || {}}
                    />

                    <Authorization
                        privileges={[CONST.SECURITY_PRIVILEGES_CREATE, CONST.SECURITY_PRIVILEGES_UPDATE]}
                        resources={RESOURCES.CLIENT_ANALYTICS_SETUP}
                    >
                        <div className="formRow form__footer">
                            <label/>
                            <ButtonSave
                                loading={this.props.submitting}
                                disabled={this.props.submitting}>
                                Save
                            </ButtonSave>
                        </div>
                    </Authorization>
                </Form.Create>
            </Segment>
        );
    }
}

const ClientAnalyticsSetupFormRedux = reduxForm({
    form: 'ClientAnalyticsSetup',
    validate,
})(withRouter(ClientAnalyticsSetupForm));

export default compose(
    graphql(gql(clientAnalyticsSetupGraphQl.clientAnalyticsSetupCreateMutation), {name: 'CreateClientAnalyticsSetup'}),
    graphql(gql(clientAnalyticsSetupGraphQl.clientAnalyticsSetupUpdateMutation), {name: 'UpdateClientAnalyticsSetup'}),
)(FormWrapper({
    clientAnalyticsSetup: {
        id: "",
        report_accessibility_date: "",
        has_overall_figures: false,
        has_top_countries: false,
        has_all_countries: false,
        has_unikeys_support: false,
        has_labels_support: false,
        has_session_duration: false,
        has_top_referrers: false,
        has_top_streams: false,
        has_stream_details: false,
    },
}, ClientAnalyticsSetupFormRedux));
