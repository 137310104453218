import {GetClientsDropdown} from '@graphql/clients/query';
import EventBookingGeneralInformationCommonModel
    from '@modules/eventBooking/forms/EventBookingGeneralInformationCommonModel';

export default function EventBookingGeneralInformationAddModel() {
    const eventBookingGeneralInformationAddModel = EventBookingGeneralInformationCommonModel();

    /**
     * Default disabled/non-disabled field
     */
    eventBookingGeneralInformationAddModel.fields.client.props.disabled = false;
    eventBookingGeneralInformationAddModel.fields.product.props.disabled = true;
    eventBookingGeneralInformationAddModel.fields.client_package.props.disabled = true;

    eventBookingGeneralInformationAddModel.query.getEntity = null;
    eventBookingGeneralInformationAddModel.query.getOptions = GetClientsDropdown;

    return eventBookingGeneralInformationAddModel;
}
