import {Header} from 'semantic-ui-react';
import {orderBy as _orderBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useQuery} from '@apollo/client';
import {withApollo} from '@apollo/client/react/hoc';

import ClientBookingsBulkChangeForm from '@modules/client/forms/ClientBookingsBulkChangeForm';
import ClientBookingsBulkChangeModel from '@modules/client/forms/ClientBookingsBulkChangeModel';
import {
    getCompetitors,
    getContentTierName,
    getInvoiceStatus,
    renderContentCategoryLevelColumn,
} from '@modules/client/utils/clientBookingTable';
import SelectedBookingsTable from '@modules/client/components/ClientBookingsBulkChange/SelectedBookingsTable';
import {
    showErrorDuringBulkChange,
    showErrorTooManyBookingsSelected,
} from '@modules/client/utils/bookingsBulkChange/errorModals';
import {convertToInt} from '@utils/helpers';
import {createForm} from '@utils/forms';
import {GetClientBookingsForBulkChange} from '@graphql/clientBooking/query';
import responseStatus from '@constants/responseStatuses';

const createBookingForTable = (booking) => ({
    id: booking.id,
    start_datetime: booking.start_datetime,
    content_category_level_1_name: renderContentCategoryLevelColumn(booking, 'sport', 1),
    content_category_level_2_name: renderContentCategoryLevelColumn(booking, 'tournament_category', 2),
    content_category_level_3_name: renderContentCategoryLevelColumn(booking, 'tournament', 3),
    content_tier_name: getContentTierName(booking),
    competitors: getCompetitors(booking),
    event_content_name: booking.event_content_name,
    event_content_type_name: booking.event_content_type_name,
    event_content_variant_id: booking.event_content_variant_id,
    event_content_variant_name: booking.event_content_variant_name,
    event_content_variant_short_name: booking.event_content_variant_short_name,
    product_id: booking.product_id,
    product_short_name: booking.product_short_name,
    distribution_type_name: booking.distribution_type_name,
    client_package_name: booking.client_package_name,
    is_hq: booking.is_hq,
    booking_type_name: booking.booking_type_name,
    price: booking.price,
    auto_update_price: booking.auto_update_price ? 1 : 0,
    booking_invoice_status_name: booking.booking_invoice_status_name,
    event_invoice_status_name: booking.event_invoice_status_name,
    product_is_invoiceable: booking.product_is_invoiceable,
    invoice_status: getInvoiceStatus(booking),
    auto_update_geo_restrictions: booking.auto_update_geo_restrictions ? 1 : 0,
    event_id: booking.event_id,
    event_description: booking.event_description,
    event_color: booking.event_color,
    client_package_id: booking.client_package_id,
    is_cancelled: booking.is_cancelled,
});

const BookingBulkChangeModalContent = ({clientId, bookingsIds}) => {
    const {
        data: {
            clientBookings = [],
        } = {},
        error: queryError,
        loading: loadingClientBookings = true,
    } = useQuery(GetClientBookingsForBulkChange, {
        variables: {
            client: clientId,
            bookings: bookingsIds,
        },
        fetchPolicy: 'network-only',
    });

    const sortedClientBookings = _orderBy(
            clientBookings.map(booking => createBookingForTable(booking)),
            ['start_datetime'],
            ['desc']
        ),
        foundBookingsIds = sortedClientBookings.map(b => b.id),
        productsIds = new Set(sortedClientBookings.map(b => b.product_id));
    let commonProductId = null, isCommonProduct = false;

    const bookingDetails = sortedClientBookings.map(b => ({
        id: b.id,
        product: b.product_id,
        is_invoiceable: b.product_is_invoiceable,
    }));

    if (!loadingClientBookings && bookingsIds.length !== foundBookingsIds.length) {
        const queryErrors = queryError ? queryError.graphQLErrors : [],
            isUriTooLong = 0 < queryErrors.length && responseStatus.HTTP_URI_TOO_LONG === queryErrors[0].code;

        if (isUriTooLong) {
            showErrorTooManyBookingsSelected();
        } else {
            showErrorDuringBulkChange();
        }

        return null;
    }

    if (1 === productsIds.size) {
        commonProductId = convertToInt(productsIds.values().next().value);
        isCommonProduct = true;
    }

    const Form = createForm(
        () => (ClientBookingsBulkChangeModel(isCommonProduct)),
        ClientBookingsBulkChangeForm,
        {
            client: clientId,
            queryForRefetch: 'clientBookingsForTable',
            isCommonProduct,
            bookingDetails,
            optionsVariables: {
                client: [clientId],
                commonProduct: [commonProductId],
                skipClientPackages: !isCommonProduct,
            },
        }
    );

    return (
        <div>
            <Header content='Modify bookings'/>
            <div>
                <div className='ui form'>
                    <SelectedBookingsTable
                        loading={loadingClientBookings}
                        bookings={sortedClientBookings}
                        clientId={clientId}
                    />
                </div>
                <div>
                    <Form formData={{variables: {id: bookingsIds}}}/>
                </div>
            </div>
        </div>
    );
};

BookingBulkChangeModalContent.propTypes = {
    bookingsIds: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired,
};

BookingBulkChangeModalContent.defaultProps = {
    data: {},
};

export default withApollo(BookingBulkChangeModalContent);
