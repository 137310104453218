import {get as _get} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {withRouter} from "react-router";
import {Dimmer, Loader} from "semantic-ui-react";

import {EventRedirect} from "./EventRedirect";

import {convertToInt} from "../../../utils/helpers";
import useQuery from "../../../utils/hooks/useQuery";

import {GetEventRedirectByDistribution} from "../../../graphql/event/query.graphql";

export function EventRedirectByDistribution({
    history,
    match,
}) {
    const {
        data: {
            eventRedirectByDistribution,
        } = {},
        error,
        loading: isDataLoading = true,
    } = useQuery({
        query: GetEventRedirectByDistribution,
        options: {
            variables: {
                id: convertToInt(match.params.id),
            },
        },
    });

    return (isDataLoading && !error)
        ? (
            <Dimmer inverted active>
                <Loader/>
            </Dimmer>
        )
        : (
            <EventRedirect
                errorMessage="distribution"
                eventContentId={_get(eventRedirectByDistribution, 'event_content_id', null)}
                eventId={_get(eventRedirectByDistribution, 'event_id', null)}
                history={history}
            />
        );
}

EventRedirectByDistribution.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(EventRedirectByDistribution);
