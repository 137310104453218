import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {get as _get} from 'lodash';
import {Icon} from 'semantic-ui-react';

import Table from '@appComponents/Table';
import {CHANGELOG_ACTION_TYPE_MODIFICATION} from '@constants/variables';

import {
    renderLinkToChangelogPage,
    renderLinkToElementPage,
} from '../utils/changelogHelper';

export const columns = {
    'datetime': {
        label: 'Date/time',
        sorting: true,
        className: 'dateTime',
        header: {
            isCollapsed: true,
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'resource': {
        label: 'Resource',
        sorting: (row) => row.secured_resource.name,
    },
    'element_id': {
        label: 'Element ID',
        sorting: row => parseInt(row.element_id, 10),
        header: {
            isCollapsed: true,
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'element_name': {
        label: 'Element name',
        sorting: true,
    },
    'changelog_action_type': {
        label: 'Action',
        sorting: (row) => _get(row, 'changelog_action_type.name', null)
    },
    'user': {
        label: 'User',
        sorting: (row) => _get(row, 'user.name', null)
    },
    'ip': {
        label: 'IP',
        sorting: true,
    },
    'details': {
        label: 'Details',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const renderDetailsButton = (row => (
    <div className='actions-list-icon'>
        {parseInt(row.changelog_action_type.id, 10) === CHANGELOG_ACTION_TYPE_MODIFICATION
            && <Link to={`/changelog/${row.id}`}>
                <Icon name='list' size='large'/>
            </Link>
        }
    </div>
));

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'resource':
            return _get(row, 'secured_resource.name', '');
        case 'changelog_action_type':
            return _get(row, 'changelog_action_type.name', '');
        case 'user':
            return _get(row, 'user.name', '');
        case 'element_id':
            return renderLinkToChangelogPage(row);
        case 'element_name':
            return renderLinkToElementPage(row);
        case 'details':
            return renderDetailsButton(row);
        default:
            return null;
    }
};

const ChangeLogTable = (props) => {
    const {changelogRevisions, loading} = props;

    return (
        <div className='changelog__tableContainer'>
            <Table
                noDataText='No results found'
                name='changelogList'
                columns={columns}
                showLimitWarning={true}
                dataLimitWarning={5000}
                data={changelogRevisions}
                loading={loading}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ChangeLogTable.defaultProps = {
    changelogRevisions: [],
};

ChangeLogTable.propTypes = {
    changelogRevisions: PropTypes.array,
    loading: PropTypes.bool,
};

export default ChangeLogTable;
