import {connect} from "react-redux";
import PropTypes from 'prop-types';
import React from 'react';
import {withApollo} from "react-apollo";

import Form from "../../app/components/ReduxFormControls";

import EventMasterBookingGeoRestrictionsForm
    from "../forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsForm";
import EventMasterBookingGeoRestrictionsEditModel
    from "../forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsEditModel";
import ClientMasterBookingGeoRestrictionsEditModel
    from "../../client/forms/ClientMasterBookingGeoRestrictions/ClientMasterBookingGeoRestrictionsEditModel";

import {convertToInt} from "../../../utils/helpers";
import {createForm} from "../../../utils/forms";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {GetProductIdForMasterBooking} from "../../../graphql/masterBooking/query.graphql";

export class EventBookingGeoRestrictionsStep extends React.PureComponent {
    static propTypes = {
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }),
        eventId: PropTypes.number,
        masterBookingId: PropTypes.number.isRequired,
        MessageBox: PropTypes.shape({
            addMessage: PropTypes.func.isRequired,
        }),
        stepsMethods: PropTypes.shape({
            setStep: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {
        eventId: null,
    };

    componentDidMount() {
        this.loadForm();
    }

    setFormForStep(Form) {
        this.props.stepsMethods.setStep({
            id: "step2",
            menuItem: {disabled: false},
            pane: {content: <Form/>}
        });
    }

    loadForm() {
        this.props.client.query({
            query: GetProductIdForMasterBooking,
            variables: {
                id: this.props.masterBookingId
            },
        }).then((result) => {
            const productId = convertToInt(result.data.masterBooking.product.id);

            this.setFormForStep(this.getPreparedForm(productId));
        }).catch((error) => {
            this.props.MessageBox
                .addMessage("formInnerErrorMessage",
                    "Can't get product for master booking.",
                    error.message, "error");
        });
    }

    getPreparedForm(productId) {
        return createForm(
            this.props.eventId ? EventMasterBookingGeoRestrictionsEditModel : ClientMasterBookingGeoRestrictionsEditModel,
            EventMasterBookingGeoRestrictionsForm,
            Object.assign({}, {
                id: this.props.masterBookingId,
                optionsVariables: {
                    event: this.props.eventId,
                    product: productId
                }
            })
        )
    }

    render() {
        return (
            <Form.Create className="eventBookingGeoRestrictionsLoader" loading={true}/>
        )
    }
}

const mapDispatchToProps = mapModulesToProps(['MessageBox']);

export default connect(null, mapDispatchToProps)(withApollo(EventBookingGeoRestrictionsStep));
