import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {AV_COMPETITOR} from "../../app/constants/resources";
import validate from "./validator";

import {GetAvCompetitorForForm} from "../../../graphql/avCompetitor/query.graphql";
import {CreateAvCompetitor, DeleteAvCompetitor, UpdateAvCompetitor} from "../../../graphql/avCompetitor/mutation.graphql";
import {GetCountries} from "../../../graphql/country/query.graphql";

export default function CompetitorModel() {
    return {
        entityDataMapKey: "competitor",
        entityLabel: "competitor",
        formName: "competitor",
        label: "competitor",
        resources: AV_COMPETITOR,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            competitor: {
                id : "",
                name: "",
                country: null,
            }
        },
        messages: {
            boxName: {
                delete: "competitorMessage",
                error: "competitorMessageError",
                success: "competitorMessage",
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "competitor.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "competitor.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            country: {
                optionsKey: "countries",
                dataMapKey: "competitor.country.id",
                props: {
                    component: Form.SemanticSelect,
                    label: "Country",
                    name: "country",
                    allowClear: true,
                    placeholder: "Select country",
                },
            },
        },
        mutation: {
            createEntity: CreateAvCompetitor,
            deleteEntity: DeleteAvCompetitor,
            updateEntity: UpdateAvCompetitor,
        },
        query: {
            getEntity: GetAvCompetitorForForm,
            getOptions: GetCountries,
        },
    }
}
