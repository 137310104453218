import IpBlacklistingModel from './IpBlacklistingModel';

export default function IpBlacklistingModelEdit() {
    const ipBlacklistingModelEdit = IpBlacklistingModel(),
        cidrName = (data) => (data.blacklistedIp ? data.blacklistedIp.cidr : '');

    ipBlacklistingModelEdit.title = (data) => (`CIDR ${cidrName(data)}`);
    ipBlacklistingModelEdit.fields.ip_version.props.disabled = true;
    ipBlacklistingModelEdit.fields.cidr.props.disabled = true;

    return ipBlacklistingModelEdit;
}
