import { Icon } from 'semantic-ui-react';
import React from 'react';
import { withRouter } from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {reportMultipleAsWorkingAndInvoiceableErrorHandler, reportMultipleAsWorkingAndInvoiceableResponseHandler}
    from '@modules/events/utils/reportMultipleAsWorkingAndInvoiceableHelper';
import { refetchQueryByName } from '@utils/apollo';

class ReportMultipleEventsAsWorkingForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        data.events = this.props.formParams.optionsVariables.events;

        return data;
    };

    redirectToEventSchedulingPage = () => (
        this.props.history.push(getLink('events.index'))
    );

    renderSaveButton = () => {
        const yesButtonProps = {
            content: 'Yes',
            icon: <Icon className='check icon' name='check' />,
            color: 'green',
        };

        return super.renderSaveButton(yesButtonProps);
    };

    renderCancelButton = () => {
        const noButtonProps = {
            content: 'No',
            color: 'red',
            icon: <Icon className='times icon' name='times' />,
        };

        return super.renderCancelButton(noButtonProps);
    };

    errorHandler = () => {
        reportMultipleAsWorkingAndInvoiceableErrorHandler(
            'eventsIndex',
            'The selected events could not be reported as working and invoiceable successfully'
        );
        this.redirectToEventSchedulingPage();
    };

    componentDidMount = () => {
        this.setCreateErrorCallback(() => {
            this.errorHandler();
        });

        this.setCreateSuccessCallback((response) => {
            reportMultipleAsWorkingAndInvoiceableResponseHandler(
                response,
                this.errorHandler,
                'eventsIndex',
                'The selected events have been reported as working and invoiceable successfully.'
            );
            refetchQueryByName('EventScheduleQuery');
            this.redirectToEventSchedulingPage();
        });
    };
}

export default withRouter(ReportMultipleEventsAsWorkingForm);
