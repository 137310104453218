import {get as _get} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import {getYesOrNo} from "../../../../utils/helpers";

const getClientProducts = (clientProducts) => (
    clientProducts.map((clientProduct) => {
        let clientProductString = clientProduct.product.short_name;

        clientProductString += (0 < clientProduct.client_packages_count)
            ? ` (${clientProduct.client_packages_count})`
            : '';
        clientProductString += (clientProduct.is_trial) ? ` (trial)` : '';
        clientProductString += (clientProduct.is_disabled) ? ` (disabled)` : '';

        return clientProductString;
    }).join(', ')
);

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'clients',
    parseData: client => [
        client.id,
        _get(client, 'bookmaker.id', ''),
        _get(client, 'crm_id', ''),
        client.name,
        client.brands || '',
        _get(client, 'country.name', ''),
        client.client_types.map((clientType) => (clientType.name)).join(', '),
        getClientProducts(client.client_products),
        getYesOrNo(0 < client.client_whitelisted_ips.length),
        getYesOrNo(client.is_internal),
        moment(client.creation_datetime).format('YYYY-MM-DD'),
        getYesOrNo(client.is_disabled),
    ],
    path: 'clients.index.path',
    titles: [
        "ID",
        "Bookmaker ID",
        "CRM ID",
        "Name",
        "Brands",
        "Country",
        "Type",
        "Products (#packages)",
        "IP whitelisting?",
        "Internal?",
        "Registration date",
        "Disabled?",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
