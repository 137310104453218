import React from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { Popup, TableCell } from 'semantic-ui-react';

import Table from '@appComponents/Table';
import { CLIP_CONFIGURATION } from '@constants/resources';
import { SECURITY_PRIVILEGES_READ } from '@constants/variables';
import { Authorization } from '@appComponents/Authorization';
import { IconEdit } from '@appComponents/IconCollection';
import { getLink } from '@appComponents/Link';
import { columnObjectAlignCenter } from '@utils/tableHelpers';

import {
    getName,
    getProductShortName,
    getSportNames,
    getTournamentNames,
    getTournamentCategoryNames,
    getClipNames,
} from '../utils/propertyDecorator';

const authorizationObject = {
    [CLIP_CONFIGURATION]: SECURITY_PRIVILEGES_READ,
};

const tableColumns = {
    name: {
        label: 'Name',
        sorting: (row) => getName(row),
        className: 'nameClipConfigTable',
    },
    product: {
        label: 'Product',
        sorting: (row) => getProductShortName(row),
    },
    sports: {
        label: 'Sports',
        sorting: (row) => getSportNames(row),
    },
    tournamentCategories: {
        label: 'Categories',
        sorting: (row) => getTournamentCategoryNames(row),
    },
    tournaments: {
        label: 'Tournaments',
        sorting: (row) => getTournamentNames(row),
    },
    clips: {
        label: 'Clips',
        sorting: (row) => getClipNames(row),
    },
    actions: {
        ...columnObjectAlignCenter,
        label: 'Actions',
    },
};

const TournamentsCell = ({row, limit = 10}) => {
    const tournaments = _get(row, 'tournaments', []);

    let content;

    if (limit >= tournaments.length) {
        content = (
            <>
                {getTournamentNames(row)}
            </>
        );
    } else {
        content = (
            <Popup
                trigger={(
                    <TableCell
                        as='div'
                        className='cell'
                        content={getTournamentNames(row, limit)}
                    />
                )}
                position={'top center'}
                className='--veryWidePopup'
                hoverable
                wide='very'
                content={(
                    <>
                        {getTournamentNames(row)}
                    </>
                )}
            />
        );
    }

    return content;
};

const tableRowRenderer = (column, row) => {
    switch (column) {
        case 'name':
            return getName(row);
        case 'product':
            return getProductShortName(row);
        case 'sports':
            return getSportNames(row);
        case 'tournamentCategories':
            return getTournamentCategoryNames(row);
        case 'tournaments':
            return (
                <TournamentsCell row={row} limit={10} />
            );
        case 'clips':
            return getClipNames(row);
        case 'actions':
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            link={getLink('clipConfiguration.edit', {id: row.id})}
                            resource={CLIP_CONFIGURATION}
                        />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};

const ClipConfigurationTable = ({data = [], loading = false}) => {
    return (
        <Table
            name='Clip configuration'
            columns={tableColumns}
            rowRenderer={tableRowRenderer}
            loading={loading}
            data={data}
            defaultSort='name'
            noDataText='No clip configurations found'
            pagination={true}

        />
    );
};

ClipConfigurationTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array,
};

export default ClipConfigurationTable;
