import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from "lodash";

import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {EVENT} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';

import {IconEdit} from "../../../../../../../app/components/IconCollection";
import LinkingEventWithMatchOpenPopup from "../../../../../../views/EventsTabsLayout/LinkingEventWithMatch/LinkingEventWithMatchOpenPopup";
import {convertToInt} from "../../../../../../../../utils/helpers";
import {CopyToClipboardRenderer} from "../../../../../../../app/components/CopyToClipboardRenderer";

const authorizationEventUpdateObject = {
    [EVENT]: SECURITY_PRIVILEGES_UPDATE,
};

/**
 * Match ID
 */
export const MatchId = ({event}) => {
    /**
     * If there is:
     * - sportradar event
     * - match id
     * - event is not master event, we can modify
     */
    if (event.sr_event && !event.is_master && event._hasMatchID) {
        return (
            <React.Fragment>
                <CopyToClipboardRenderer text={event.sr_event.match.id} value={event.sr_event.match.id}/>
                <Authorization authorization={authorizationEventUpdateObject}>
                    <LinkingEventWithMatchOpenPopup eventId={convertToInt(event.id)}>
                        <IconEdit className={'black'} />
                    </LinkingEventWithMatchOpenPopup>
                </Authorization>
            </React.Fragment>
        );
    }

    let label = event.id;

    if (event.sr_event && event.is_master) {
        return `${label} (master match ID ${_get(event, 'sr_event.master_match.id', '')})`;
    }

    return label;
};

MatchId.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string
            }),
            master_match: PropTypes.shape({
                id: PropTypes.string
            })
        }),
        is_master: PropTypes.bool,
        _hasMatchID: PropTypes.bool.isRequired
    }).isRequired
};

MatchId.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null
            },
            master_match: {
                id: null
            }
        },
        is_master: false
    }
};
