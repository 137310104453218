import React from 'react';
import PropTypes from 'prop-types';
import {Divider} from 'semantic-ui-react';

import {CompetitorsAndTypeWrapper} from '@utils/CompetitorsAndTypeWrapper';

import {CompetitorsName} from './CompetitorsName';
import {EventContentType} from './EventContentType';
import {AvatarTournamentCategory} from './AvatarTournamentCategory';

/**
 * Information under Header about:
 * - competitors and type of event content
 * - avatar with tournament / content category
 *
 * Modals list:
 * - Modify encoding start time.
 * - Modify encoding end time.
 * - Start encoding.
 * - Stop encoding.
 * - Reset encoding.
 * - ScoreBoard Overlay.
 * - Broadcast status.
 * - Play output streams.
 *
 * @param event
 * @param bottomDivider
 * @returns {*}
 * @constructor
 */
export const ModalHeaderEventContentInformation = ({event, showBottomDivider = true}) => {
    return (
        <React.Fragment>
            <div className='--marginTop-10 --marginLeft-1-5em --marginBottom-10 small --auto_width --text-left'>
                <CompetitorsAndTypeWrapper>
                    <CompetitorsName event={event} />
                    <EventContentType event={event} />
                </CompetitorsAndTypeWrapper>
                <div className='--marginTop-5'>
                    <AvatarTournamentCategory event={event} />
                </div>
            </div>
            {showBottomDivider && <Divider fitted />}
        </React.Fragment>
    );
};

ModalHeaderEventContentInformation.propTypes = {
    event: PropTypes.object.isRequired,
    showBottomDivider: PropTypes.bool,
};
