import PropTypes from 'prop-types';
import {map as _map, get as _get} from 'lodash';

import {getYesOrNo} from '@utils/helpers';

export const clientPackagesExportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'client_packages',
    parseData: client_package => [
        client_package.id,
        client_package.client_product.client.id,
        _get(client_package.client_product, 'client.bookmaker.id', ''),
        client_package.client_product.client.name,
        client_package.client_product.client.brands,
        getYesOrNo(client_package.client_product.client.is_disabled),
        client_package.client_product.product.short_name,
        getYesOrNo(client_package.client_product.is_disabled),
        client_package.name,
        client_package.description,
        client_package.unique_sportradar_contract_number,
        client_package.av_betting_contract_number,
        client_package.sf_contract_number,
        client_package.client_package_type.name,
        client_package.booking_type.name,
        client_package.content_package_selection_type.name,
        _map(client_package.event_content_variants, (eventContentVariant) => eventContentVariant.name).join(', '),
        client_package.start_date,
        client_package.end_date,
        0 < client_package.client_package_geo_restrictions.length
            ? 'Specific'
            : 'Same as product settings',
        getYesOrNo(client_package.is_premium),
        getYesOrNo(client_package.is_hq),
        getYesOrNo(client_package.is_test),
        getYesOrNo(client_package.disabled),
    ],
    path: 'clientPackages.path',
    titles: [
        'ID',
        'Client ID',
        'Bookmaker ID',
        'Client name',
        'Client brands',
        'Client is disabled',
        'Product',
        'Product is disabled',
        'Package name',
        'Package description',
        'Unique Sportradar contract number',
        'AV betting contract number',
        'SF contract number',
        'Package type',
        'Booking type',
        'Content package selection type',
        'Content variants',
        'Start date',
        'End date',
        'Geo restrictions',
        'Premium',
        'HQ',
        'Test',
        'Package is disabled',
    ],
});

clientPackagesExportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
