import {withRouter} from 'react-router';
import {get as _get, isEmpty as _isEmpty} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {renderPageTitle} from '@utils/helpers';
import {prepareMediaconnectOriginFeeds} from '@modules/mediaConnect/utils/formHelpers';
import {adSpliceConfigOptionParser} from '@utils/dropdownOptionParser';

export class MediaConnectForm extends DefaultForm {
    setProductVisible = (value) => {
        return this.setField('products', {
            hidden: !(value && 0 < value.length),
        });
    };

    componentDidMount() {
        const link = this.createLinkToIndexPage();

        this.setCreateSuccessCallback(() => {
            this.props.history.push(link);
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(link);
        });

        this.setOnChangeCallback({
            'primary_feed_ad_support': () => {
                const visibility = this.props.formValues.primary_feed_ad_support;

                this.setField('primary_feed_ad_splice_config', {hidden: visibility});
            },
            'secondary_feed_arn': () => {
                this.setProductVisible(this.props.formValues.secondary_feed);
            },
            'secondary_feed_output_url': () => {
                this.setProductVisible(this.props.formValues.secondary_feed);
            },
            'secondary_feed_ad_support': () => {
                const visibility = this.props.formValues.secondary_feed_ad_support;

                this.setField('secondary_feed_ad_splice_config', {hidden: visibility});
            },
        });

        this.setField('secondary_feed', {
            onChange: (event, value) => {
                this.setProductVisible(value);
            },
        });

        this.setOptionParsers({
            primary_feed_ad_splice_config: adSpliceConfigOptionParser,
            secondary_feed_ad_splice_config: adSpliceConfigOptionParser,
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        const feeds = _get(this.props, 'GraphQLEntityData.mediaconnectOriginEntryPoint.mediaconnect_origin_feeds', []),
            products = _get(this.props, 'GraphQLEntityData.mediaconnectOriginEntryPoint.products', []);

        if (!this.state.productsLoaded && !_isEmpty(products)) {
            this.setField('products', {
                defaultValue: products.map((product) => product.id),
                hidden: false,
            });
            this.setState(() => ({productsLoaded: true}));
        }

        if (!this.state.feedsLoaded && !_isEmpty(feeds[0])) {
            this.setField('primary_feed', {
                defaultValue: feeds[0].url,
            });

            if (feeds[0].arn) {
                this.setField('primary_feed_arn', {
                    defaultValue: feeds[0].arn,
                });
            }

            if (feeds[0].output_url) {
                this.setField('primary_feed_output_url', {
                    defaultValue: feeds[0].output_url,
                });
            }

            if (feeds[0].has_cc) {
                this.setField('primary_feed_close_captioning', {
                    defaultValue: true,
                });
            }

            if (feeds[0].has_scte35) {
                this.setField('primary_feed_ad_support', {
                    defaultValue: true,
                });
            }

            if (feeds[0].has_scte35) {
                this.setField('primary_feed_ad_splice_config', {
                    defaultValue: feeds[0]?.ad_splice_config?.id || '',
                    hidden: !feeds[0].has_scte35,
                });
            }

            if (feeds[0].has_scte104) {
                this.setField('primary_feed_feed_splice', {
                    defaultValue: true,
                });
            }
            this.setState(() => ({feedsLoaded: true}));
        }

        if (!this.state.feedsLoaded && !_isEmpty(feeds[1])) {
            this.setField('secondary_feed', {
                defaultValue: feeds[1].url,
            });

            if (feeds[1].arn) {
                this.setField('secondary_feed_arn', {
                    defaultValue: feeds[1].arn,
                });
            }

            if (feeds[1].output_url) {
                this.setField('secondary_feed_output_url', {
                    defaultValue: feeds[1].output_url,
                });
            }

            if (feeds[1].has_cc) {
                this.setField('secondary_feed_close_captioning', {
                    defaultValue: true,
                });
            }

            if (feeds[1].has_scte35) {
                this.setField('secondary_feed_ad_support', {
                    defaultValue: true,
                });
            }

            if (feeds[1].has_scte35) {
                this.setField('secondary_feed_ad_splice_config', {
                    defaultValue: feeds[1].ad_splice_config?.id || '',
                    hidden: !feeds[1].has_scte35,
                });
            }

            if (feeds[1].has_scte104) {
                this.setField('secondary_feed_feed_splice', {
                    defaultValue: true,
                });
            }
            this.setState(() => ({feedsLoaded: true}));
        }

        const mediaConnectName = (null !== this.props.formParams.id)
            ? undefined !== this.props.GraphQLEntityData.mediaconnectOriginEntryPoint
                ? this.props.GraphQLEntityData.mediaconnectOriginEntryPoint.name
                : ' '
            : null;

        this.props.formParams.setPageTitle(
            renderPageTitle({
                id: this.props.formParams.id,
                name: mediaConnectName,
                entity: this.props.Model.entityLabel,
            })
        );
    }

    createLinkToIndexPage = () => (
        getLink('mediaConnect.index')
    );

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.dataMap[this.props.Model.entityDataMapKey],
                {
                    id: data.id,
                    name: data.name || null,
                    description: data.description ? data.description : null,
                    encodingDatacenter: data.encoding_datacenter,
                    products: data.secondary_feed ? data.products.map(Number) : null,
                    mediaconnect_origin_feeds:
                        prepareMediaconnectOriginFeeds(data),
                }
            );
    };

    renderErrors() {
        super.renderErrors(
            'The requested MediaConnect origin entry point could not be found',
            this.props.Model.entityLabel,
            this.createLinkToIndexPage()
        );
    }

    renderCancelButton = () => null;

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});
}

export default withRouter(MediaConnectForm);
