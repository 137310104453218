import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {renderModalError} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";

export class ProductionCompanyContactPersonForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {this.setMutationSuccess()});
        this.setDeleteSuccessCallback(() => {this.setMutationSuccess()});
        this.setUpdateSuccessCallback(() => {this.setMutationSuccess()});
    }

    prepareDataForSubmit = (data) => {
        return Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                contact_person_type_id: (data && data.contact_person_type) ? convertToInt(data.contact_person_type) : null,
                production_company_id: convertToInt(this.props.formParams.productionCompanyId),
                client_id: null,
                licensor_id: null,
                licensee_id: null,
            }
        );
    };

    renderErrors(errorData) {
        const errors = errorData.graphQLErrors;

        if (1 === errors.length && "ContactPerson not found." === errors[0].message) {
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError(
                    'Contact person',
                    getLink('productionCompanies.contactPersons.index', {
                        id: this.props.formParams.productionCompanyId
                    })
                ),
            });
        } else {
            super.renderErrors(errorData);
        }
    }

    setMutationSuccess = () => {
        this.props.formParams.setMutationSuccessSubmit(Date.now());
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );
}

export default withRouter(ProductionCompanyContactPersonForm);
