import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import {useParams, withRouter} from 'react-router';
import {connect} from 'react-redux';
import {withApollo} from '@apollo/client/react/hoc';

import {GetEventsEventBookingsData} from '@graphql/eventsEventBookings/query';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import EventsEventBookingsFilters, {DEFAULT_FILTERS_VALUES} from '@modules/events/components/EventsEventBookingsFilters';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import EventsEventBookingsButtons from '@modules/events/components/EventsEventBookingsButtons';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import EventsEventBookingsTable from '@modules/events/components/EventsEventBookingsTable';
import {showErrorModal,showModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import {exportExcel as eventsEventBookingsExportExcel} from '@modules/events/utils/export/eventsEventBookingsExportExcel';
import {renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import mapModulesToProps from '@utils/mapModulesToProps';
import EventsEventBookingContentModal from '@modules/events/components/EventsEventBookingContentModal';
import navigation from "@constants/navigation";

const EventsEventBookings = (props) => {
    const {id} = useParams();
    const [mutationSuccessCallback, setMutationSuccessCallback] = useState(null);
    const { data: {eventEventBookings} = {}, loading: isLoading = true, error: eventsEventErrorOccured} = useQuery(GetEventsEventBookingsData, {
        variables: {
            eventId: convertToInt(id),
            filters: {
                client: props.filters.clients ? props.filters.clients : DEFAULT_FILTERS_VALUES.clients,
                product: props.filters.productsDropdown ? props.filters.productsDropdown : DEFAULT_FILTERS_VALUES.productsDropdown,
                eventContentTypes: props.filters.eventContentTypes ? props.filters.eventContentTypes : DEFAULT_FILTERS_VALUES.eventContentTypes,
                matchEventTypes: props.filters.matchEventTypes ? props.filters.matchEventTypes : DEFAULT_FILTERS_VALUES.matchEventTypes,
                deviceCategory: props.filters.deviceCategories ? props.filters.deviceCategories : DEFAULT_FILTERS_VALUES.deviceCategories,
                country: props.filters.countries ? props.filters.countries : DEFAULT_FILTERS_VALUES.countries,
                bookingType: props.filters.bookingTypes ? props.filters.bookingTypes : DEFAULT_FILTERS_VALUES.bookingTypes,
                isCancelled: convertBooleanValueForFilters(props.filters.isCancelled, null),
            },
        },
        fetchPolicy: "no-cache",
        refetch: [props.filters.lastClickSubmit, mutationSuccessCallback],
    });

    const {Menu} = props;

    useEffect(() => {
        EventsEventBookingContentModal(props, setMutationSuccessCallback);
    }, [props.match]);

    useEffect(() => {
        Menu.storeMenu(navigation.eventAdministration.key);
    }, []);

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Event', routes.events.index.path),
        });
    }

    if (eventsEventErrorOccured) {
        showErrorModal({
            header: 'Failed to fetch event bookings list',
            text: eventsEventErrorOccured,
            errorButtonText: 'Back to dashboard',
            hideButtons: false,
            redirectLink: getLink('dashboard'),
            errorButtonIcon: 'arrow circle left',
            size: 'large',
        });

        return null;
    }

    return (
        <div>
            <HeaderRenderer
                buttons={EventsEventBookingsButtons}
                buttonsProps={{eventId: convertToInt(id)}}
                filters={EventsEventBookingsFilters}
                tableLoading={isLoading}
                exportExcelParams={eventsEventBookingsExportExcel(eventEventBookings, convertToInt(id))}
            />
            <div className='table-container'>
                <EventsEventBookingsTable
                    eventEventBookings={eventEventBookings}
                    loading={isLoading}
                    mutationCallback={setMutationSuccessCallback}
                />
            </div>
        </div>
    );
};

EventsEventBookings.displayName = 'EventsEventBookings';

EventsEventBookings.propTypes = {
    id: PropTypes.string,
    eventEventBookings: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    filters: PropTypes.object,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    Modal: PropTypes.object,
    Menu: PropTypes.object,
};

const EventsEventBookingsWrapped = FilterQueryWrapper(EventsEventBookings, {
    queryForRefresh: 'GetEventsEventBookingsData',
    filterUrls: ['events.eventBookings.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'EventsEventBookings', DEFAULT_FILTERS_VALUES),
});

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal', 'match', 'Menu']);

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(EventsEventBookingsWrapped, DEFAULT_FILTERS_VALUES)
)));
