import {withRouter} from "react-router";
/* eslint import/no-unresolved: 0 */
import {withApollo} from "react-apollo";
import {
    get as _get,
    sortBy as _sortBy
} from "lodash";

import {getLink} from "../../app/components/Link";
import EventForm from "./EventForm";

import RightScope from "./logic/RightScope";

import {refetchQueryByName} from "../../../utils/apollo";

import {EVENT_NON_SPORTRADAR_TYPE} from "../../app/constants/variables";

class EventsNotSportradarMasterForm extends EventForm {
    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (this.state.defaultForm_data
            && this.state.defaultForm_data.event
            && !this.state.contentCategoryId
        ) {
            const entity = _get(this.state, "defaultForm_data.event", {}),
                contentCategory = _get(entity, "av_event.content_category", {});

            this.setState(() => ({
                contentCategoryId: parseInt(contentCategory.id, 10)
            }));

            this.getRightsScopes(entity.property_licence.id).then(() => {
                this.setField("property_licence", {
                    disabled: true,
                    options: [{
                        id: entity.property_licence.id,
                        text: entity.property_licence.name + " - " + entity.property_licence.licensor.name,
                        value: entity.property_licence.id
                    }]
                });

                this.setField("content_category", {
                    disabled: true,
                    options: [{
                        id: parseInt(contentCategory.id, 10),
                        text: `${contentCategory.parent.parent.name} >
                        ${contentCategory.parent.name} >
                        ${contentCategory.name}`,
                        value: contentCategory.id
                    }],
                    defaultValue: contentCategory.id
                });

                this.setField(["start_datetime", "end_datetime"], {disabled: false})
            })
        }
    }

    prepareDataForSubmit = (data) => {
        let event = Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data),
            contentCategoryId = parseInt(event.content_category, 10),
            venue = parseInt(event.venue, 10),
            id = event.id;

        delete event.id;
        delete event.venue;
        delete event.content_category;

        let savedPresetId = _get(this.state, "defaultForm_data.event.event_preset.id", null);

        event.color = event.color.substring(1);
        event.event_status = parseInt(event.event_status, 10);
        event.property_licence = parseInt(event.property_licence, 10);
        event.is_master = true;
        event.event_type = EVENT_NON_SPORTRADAR_TYPE;
        event.description = data.description || null;
        event.notes = data.notes || null;

        if (data.event_preset) {
            event.event_preset = !data.event_preset
                ? parseInt(savedPresetId, 10) : parseInt(data.event_preset, 10);
        }

        let dataToSave =
            {
                event: event,
                content_category: contentCategoryId,
                home_competitor: null,
                away_competitor: null,
                venue: isNaN(venue) ? null : venue,
                preset: {}
            };

        if (id) {
            dataToSave.id = parseInt(id, 10);
        }

        return dataToSave;
    };

    setRightScopes = (data) => {
        return super.setRightScopes(data, "content_category")
    };

    filterStartDatetime = (date) => (
        RightScope.isValidDate(date, this.state.rightsScopes, this.state.contentCategoryId)
    );

    componentWillMount() {
        super.componentWillMount();

        this.setField("start_datetime", {
            configuration: {
                ...this.props.Model.fields.start_datetime.props.configuration,
                filterDate: this.filterStartDatetime,
            }
        });

        this.setValuesParsers({
            color: (value) => ( "#" + value ),
        });

        this.setOptionParsers({
            property_licence: (data) => {
                let newData = [],
                    contentCategoriesOptions = {};

                for (let i = 0; i < data.length; i++) {
                    let propertyLicence = data[i],
                        contents = [];

                    for (let key = 0; key < propertyLicence.contents.length; key++) {
                        let contentCategory = propertyLicence.contents[key];

                        contents.push({
                            id: contentCategory.content_category.id,
                            value: contentCategory.content_category.id,
                            text: propertyLicence.content_category.name + " > " +
                                contentCategory.content_category.parent.name + " > " +
                                contentCategory.content_category.name
                        })
                    }

                    contentCategoriesOptions[propertyLicence.id] = contents;

                    newData.push({
                        key: propertyLicence.id,
                        value: propertyLicence.id,
                        text: propertyLicence.name + " - " + propertyLicence.licensor.name
                    })
                }

                this.setState(() => ({
                    contentCategoriesOptions: contentCategoriesOptions
                }));

                let propertyLicenceKeys = Object.keys(contentCategoriesOptions);

                if (propertyLicenceKeys.length === 1) {
                    this.setContentCategoryOptions(propertyLicenceKeys[0], contentCategoriesOptions)
                }

                return _sortBy(newData, (item) => item.text.toLocaleLowerCase());
            },
            venue: (data) => {
                let newData = [];

                for (let i = 0; i < data.length; i++) {
                    let venue = data[i],
                        cityName = _get(venue, "city.name", null),
                        city = cityName ? ` - ${cityName}` : "";

                    newData.push({
                        key: venue.id,
                        text: venue.name + city + ` (${venue.id})`,
                        value: venue.id
                    });
                }

                return newData;
            }
        });

        this.setOnChangeCallback({
            property_licence: (data) => {
                this.setContentCategoryOptions(data.value);
            },
            content_category: (data) =>{
                this.setState(() => ({
                    contentCategoryId: data.value
                }));

                if (data.value) {
                    this.setField("start_datetime", {disabled: false, selected: null})
                    this.setField("end_datetime", {disabled: false})
                } else {
                    this.setField("start_datetime", {disabled: true, selected: null})
                    this.setField("end_datetime", {disabled: true, selected: null})
                }
            }
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink("events.configuration.index", {id: response.data.createAvEvent.event.id}));
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink("events.index"));
        });

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false})
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false})
        });

        this.setUpdateSuccessCallback(() => {
            this.backToEventPage();
            refetchQueryByName("GetEventContents");
        });
    }
}

export default withRouter(withApollo(EventsNotSportradarMasterForm));
