import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.encoding_datacenter, 'encoding_datacenter'));
    Object.assign(errors, validators.required(values.encoding_job_profile, 'encoding_job_profile'));

    return errors;
};

export default validate;
