import {
    GetClientProductLciForForm,
    GetClientProductLciOptions
} from '@graphql/clientProductLci/query';
import {
    CreateClientProductLci,
    DeleteClientProductLci,
    UpdateClientProductLci
} from '@graphql/clientProductLci/mutation';

import ClientProductModel from './ClientProductModel';
import validate from './validatorClientProductLci';

export default function ClientProductLciModel() {
    let clientProductLciModel = ClientProductModel();

    clientProductLciModel.deleteId = "clientProductsLci.0.id";
    clientProductLciModel.entityDataMapKey = "clientProductsLci.0";
    clientProductLciModel.entityLabel = "product LCI";
    clientProductLciModel.validate = validate;
    clientProductLciModel.query = {
        getEntity: GetClientProductLciForForm,
        getOptions: GetClientProductLciOptions,
    };
    clientProductLciModel.mutation = {
        createEntity: CreateClientProductLci,
        deleteEntity: DeleteClientProductLci,
        updateEntity: UpdateClientProductLci,
    };

    clientProductLciModel.dataMap = {
        clientProductsLci: {
            id: "",
            ...clientProductLciModel.dataMap
        },
    };

    clientProductLciModel.remapBaseDatamapKeys(clientProductLciModel);

    return clientProductLciModel;
}
