import validators from "../../../utils/validators";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(
        values.third_party_client_vod_storages,
        'third_party_client_vod_storages'
    ));

    return errors;
};

export default validate;
