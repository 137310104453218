import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';
import {withApollo} from '@apollo/client/react/hoc';

import {IconWithTooltip} from '@appComponents/IconCollection';
import {hideModal, showErrorModal, showModalConfirmation} from '@utils/modal';
import {showMessageBox} from '@utils/messageBox';

import apolloClient from '../../../apolloClient';

export const getBookingRebookingIconSettings = (modalText, modalTitle) => {
    return {
        className: null,
        iconName: 'check circle outline',
        tooltip: 'Rebook',
        modalText,
        modalTitle,
        action: 'rebooked',
    };
};

export const getBookingCancellationIconSettings = (modalText, modalTitle) => {
    return {
        className: 'flipped --marginLeft-0-3-rem',
        iconName: 'ban',
        tooltip: 'Cancel',
        modalText,
        modalTitle,
        action: 'cancelled',
    };
};

// The component is used for cancelling and rebooking events in booking and event booking
const BookingCancellationIcon = ({
    bookingId,
    isCancelled,
    entityName,
    messageBoxName,
    successCallback = () => {
    },
    mutationChangeCancellationStatus,
}) => {
    const settings = isCancelled ?
        getBookingRebookingIconSettings(
            `Are you sure you want to rebook this ${entityName}?`,
            `Rebook ${entityName}`) :
        getBookingCancellationIconSettings(
            `Are you sure you want to cancel this ${entityName}?`,
            `Cancel ${entityName}`
        );

    const onYesFunction = React.useCallback(() => {
        apolloClient.mutate({
            mutation: mutationChangeCancellationStatus,
            variables: {
                id: bookingId,
                is_cancelled: !isCancelled,
            },
        }).then(() => {
            if (successCallback) {
                successCallback();
            }

            if (messageBoxName) {
                showMessageBox(
                    messageBoxName,
                    `The ${entityName} has been ${settings.action} successfully.`,
                    null,
                    'success'
                );
            }

            hideModal();
        }).catch(() => {
            showErrorModal({
                header: `Cancel/Rebook ${entityName}`,
                text: `There was an error while changing cancellation ${entityName} status.`,
                size: 'large',
            });
        });
    }, [bookingId, isCancelled]);

    return (
        <IconWithTooltip
            key={bookingId}
            iconProps={{
                size: 'large',
                className: settings.className,
            }}
            popupProps={{
                position: 'top right',
            }}
            name={settings.iconName}
            onClick={() => showModalConfirmation({
                header: <Header content={settings.modalTitle}/>,
                text: settings.modalText,
                onYes: onYesFunction,
            })}
            tooltip={settings.tooltip}
        />
    );
};

BookingCancellationIcon.propTypes = {
    bookingId: PropTypes.number.isRequired,
    isCancelled: PropTypes.bool.isRequired,
    entityName: PropTypes.string,
    messageBoxName: PropTypes.string,
    successCallback: PropTypes.func,
    mutationChangeCancellationStatus: PropTypes.object,
};

export default withApollo(BookingCancellationIcon);
