import {get as _get} from "lodash";
import PropTypes from "prop-types";

export const exportExcel = (dataExport, clientId, clientCdnConfigurationId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_cdn_configuration_${clientCdnConfigurationId}_akamai_live_stream_names`,
    parseData: akamaiLiveStreamName => [
        akamaiLiveStreamName.id,
        akamaiLiveStreamName.encoding_datacenter.name,
        akamaiLiveStreamName.stream_name,
        _get(akamaiLiveStreamName, 'stream_protocol.name', ''),
        akamaiLiveStreamName.description || '',
    ],
    path: 'clients.clientCdnConfiguration.akamaiLiveStreamName.index.path',
    titles: [
        "ID",
        "Encoding datacenter",
        "Stream name",
        "Stream protocol",
        "Description",
    ],
});

exportExcel.propTypes = {
    clientCdnConfigurationId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired
};
