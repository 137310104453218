import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import {validateAuthentication} from '@actions';
import {scrollToTop} from '@utils/helpers';

import DefaultForm from '../../app/components/DefaultForm';
import {fillUserResources} from './helpers/editUserResourceFormHelper';
import configuredStore from '../../../store';


export class EditUserResourcesForm extends DefaultForm {
    componentDidMount() {
        this.setUpdateSuccessCallback((response) => {
            const updatedUserId = _get(response, 'data.updateUser.id', -1);
            const loggedInUserId = _get(configuredStore.getState(), 'app.security.user.id', 0);

            if (updatedUserId === loggedInUserId) {
                configuredStore.dispatch(validateAuthentication());
            }

            scrollToTop();
        });

        this.setUpdateErrorCallback(() => {
            // We need to wait some time because div with error appears not immediately
            setTimeout(scrollToTop, 500);
        });
    }

    componentDidUpdate(prevProps) {
        if (
            'undefined' ===  typeof prevProps.GraphQLEntityData.securedResources
            && 'undefined' !== typeof this.props.GraphQLEntityData.securedResources
        ) {
            const resources = {};

            this.props.GraphQLEntityData.user.user_resources.forEach((resource) => {
                resources[`resource_${resource.id}`] = {};
            });

            this.props.GraphQLEntityData.user.roles_resources.forEach((resource) => {
                resources[`resource_${resource.id}`] = {
                    'roleResource': true,
                };
            });

            this.props.GraphQLEntityData.securedResources.forEach((securedResource) => {
                if (resources[`resource_${securedResource.id}`]) {
                    this.setField(`resource_${securedResource.id}`, {
                        'checkboxesHidden': false,
                        'toggleChecked': true,
                        'disabled': resources[`resource_${securedResource.id}`]['roleResource'],
                    });
                } else {
                    this.setField(`resource_${securedResource.id}`, {
                        'checkboxesHidden': true,
                        'toggleChecked': false,
                    });
                }
            });
        }
    }

    prepareDataForSubmit = (data) => {
        const dataToSave = {
            id: data.id,
            user_resources: [],
        };

        fillUserResources(data, dataToSave.user_resources);

        return dataToSave;
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderDeleteButton = () => null;

    renderCancelButton = () => null;
}

export default withRouter(EditUserResourcesForm);
