import {isObject as _isObject} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {
    CDN_AKAMAI,
    CDN_AKAMAI_2,
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_RECORDING,
    DISTRIBUTION_TYPE_VOD,
    ENCODER_TYPE_MANUAL,
    ENCODER_TYPE_SPORTRADAR,
    INGEST_METHOD_DISTRIBUTION_TYPES,
    PRODUCT_LCI,
} from '@constants/variables';

export const prepareDataForCreate = (data) => {
    const encodingTarget = {encoding_target: prepareEncodingTargetToSave(data)},
        encoderToSave = (convertToInt(data.encoder_type) === ENCODER_TYPE_SPORTRADAR)
            ? prepareEncodingTargetSrToSave(data)
            : prepareEncodingTargetManualToSave(data);

    return Object.assign({}, encodingTarget, encoderToSave);
};

export const prepareDataForUpdate = (data) => {
    const encodingTarget = prepareEncodingTargetToSave(data),
        encoderToSave = (convertToInt(data.encoder_type) === ENCODER_TYPE_SPORTRADAR)
            ? {
                encoding_target_sr: prepareEncodingTargetSrToSave(data),
                encoding_target_manual: null,
            }
            : {
                encoding_target_sr: null,
                encoding_target_manual: prepareEncodingTargetManualToSave(data),
            };

    return Object.assign({}, encodingTarget, encoderToSave);
};

export const prepareEncodingTargetToSave = (data) => {
    return {
        id: data.id,
        distribution: convertToInt(data.distribution),
        is_default: data.is_default,
        is_computer_vision:
            (PRODUCT_LCI === convertToInt(data.product.id) && DISTRIBUTION_TYPE_LIVE === data.distributionTypeId)
                ? data.is_computer_vision
                : false,
        has_playback_authentication: (DISTRIBUTION_TYPE_LIVE === data.distributionTypeId)
            ? data.has_playback_authentication
            : (DISTRIBUTION_TYPE_VOD === data.distributionTypeId),
        token_ttl: data.token_ttl && data.has_playback_authentication ? convertToInt(data.token_ttl) : null,
        stream_delay_type: (
            data.distributionTypeId === DISTRIBUTION_TYPE_LIVE
            || data.distributionTypeId === DISTRIBUTION_TYPE_RECORDING
        )
            ? convertToInt(data.stream_delay_type)
            : null,
        delay: (data.distributionTypeId === DISTRIBUTION_TYPE_LIVE
            || data.distributionTypeId === DISTRIBUTION_TYPE_RECORDING)
            ? convertToInt(data.delay)
            : null,
        cdn: (DISTRIBUTION_TYPE_LIVE === data.distributionTypeId)
            ? convertToInt(data.cdn)
            : null,
        ingest_method: INGEST_METHOD_DISTRIBUTION_TYPES.includes(data.distributionTypeId)
            ? convertToInt(data.ingest_method) : null,
        akamai_cdn_ingest_method: (DISTRIBUTION_TYPE_LIVE === data.distributionTypeId
            && [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(data.cdn)))
            ? convertToInt(data.akamai_cdn_ingest_method)
            : null,
        encoder_type: (data.distributionTypeId !== DISTRIBUTION_TYPE_VOD)
            ? convertToInt(data.encoder_type)
            : ENCODER_TYPE_MANUAL,
        device_categories: parseIndexedObjectArray(data.device_category),
    };
};

export const prepareEncodingTargetSrToSave = (data) => {
    return {
        language: convertToInt(data.language) || null,
        encoding_job_profile: convertToInt(data.encoding_job_profile),
        stream_name_prefix: data.stream_name_prefix || null,
        has_scoreboard_overlay_support: (data.product.has_scoreboard_overlay_support)
            ? data.has_scoreboard_overlay_support
            : false,
        has_scoreboard_overlay: (data.has_scoreboard_overlay_support)
            ? data.has_scoreboard_overlay === null ? false : data.has_scoreboard_overlay
            : null,
        scoreboard_overlay_position: (data.has_scoreboard_overlay_support)
            ? convertToInt(data.scoreboard_overlay_position)
            : null,
        has_slate_support: (data.product.has_slate_support)
            ? data.has_slate_support
            : false,
        akamai_rtmp_distribution_regions: (data.product.has_akamai_rtmp_distr_region_support
            && [CDN_AKAMAI, CDN_AKAMAI_2].includes(parseInt(data.cdn, 10)))
            ? parseIndexedObjectArray(data.akamai_rtmp_distr_regions)
            : null,
        slate: (data.has_slate_support && data.slate)
            ? convertToInt(data.slate)
            : null,
        has_overlay_support: (data.product.has_overlay_support)
            ? data.has_overlay_support
            : false,
        has_overlay: (data.start_encoding_with_overlay)
            ? data.start_encoding_with_overlay
            : null,
        overlay: (data.overlay)
            ? convertToInt(data.overlay)
            : null,
        has_ad_support: (data.product.has_ad_support) ? data.has_ad_support : false,
        ad_type: (data.has_ad_support) ? convertToInt(data.ad_type) : null,
        has_drm_support: (data.product.has_drm_support) ? data.has_drm_support : false,
        has_cc: (data.has_cc) ? data.has_cc : false,
        cc_stream_language: (data.cc_stream_language && data.has_cc) ? convertToInt(data.cc_stream_language) : null,
        client_drm_setups: (data.has_drm_support && data.client_drm_setups)
            ? data.client_drm_setups
            : [],
    };
};

export const prepareEncodingTargetManualToSave = (data) => {
    return {
        playback_path: data.playback_path || '',
    };
};

export const parseIndexedObjectArray = (data, getString = false) => {
    const newData = [];

    if (!_isObject(data)) {
        return (getString) ? '' : [];
    }

    Object.keys(data).forEach((key) => {
        if (true === data[key]) {
            newData.push(parseInt(key.substr(1), 10));
        }
    });

    return (getString) ? newData.join() : newData;
};

export const prepareOptions = (data) => {
    return {
        id: data.id,
        key: data.key || data.id,
        text: data.name,
        value: data.value || data.id,
    };
};

export const prepareDistributionOptions = (distributions) => {
    return distributions.map((distribution) => {
        return {
            id: distribution.id,
            key: `distribution_${distribution.id}`,
            value: distribution.id,
            text: `${distribution.product.short_name} > ${distribution.distribution_type.name}`,
        };
    });
};
