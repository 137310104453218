import React from 'react';
import {Header} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import DefaultForm from '@modules/app/components/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {DISTRIBUTION_TYPE_LIVE, DISTRIBUTION_TYPE_RECORDING} from '@constants/variables';
import {hideModal, showModalConfirmation} from '@utils/modal';
import {isDistributionPartOfProduct} from '@utils/product';
import {getEnabledOrAssignedOptions} from '@utils/dropdownOptionParser';

export class ProductForm extends DefaultForm {
    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink('products.index'));
        });
    }

    componentDidUpdate() {
        const { GraphQLOptionsData, GraphQLEntityData } = this.props;
        const isLoading = GraphQLEntityData.loading || GraphQLOptionsData.loading;

        if (!this.state.dataLoaded && !isLoading) {
            const assignedLiveIngestMethod = _get(GraphQLEntityData, 'product.live_ingest_method.id', null);
            const assignedRecordingIngestMethod = _get(GraphQLEntityData, 'product.recording_ingest_method.id', null);
            const ingestMethods = _get(GraphQLOptionsData, 'ingestMethods', []);
            const productId = this.props.formParams.id;
            const productDistributionTypes = GraphQLOptionsData.productDistributionTypes;

            // dataMapKey is removed from Product Model for "id" field.
            // We need to change defaultValue after data are received from BE
            // to trigger validate() method and apply required validators for ingest method logic  (validation depends on
            // distribution types, fetched from BE)
            // With dataMapKey property in the model, defaultValue is the same and validate() is not executed initially.

            this.setField('id', {
                defaultValue:  _get(GraphQLEntityData, 'product.id', null),
            });

            this.setField('live_ingest_method', {
                hidden: !isDistributionPartOfProduct(productId, DISTRIBUTION_TYPE_LIVE, productDistributionTypes),
                defaultValue: assignedLiveIngestMethod,
                options: getEnabledOrAssignedOptions(ingestMethods, assignedLiveIngestMethod),
            });

            this.setField('recording_ingest_method', {
                hidden: !isDistributionPartOfProduct(productId, DISTRIBUTION_TYPE_RECORDING, productDistributionTypes),
                defaultValue: assignedRecordingIngestMethod,
                options: getEnabledOrAssignedOptions(ingestMethods, assignedRecordingIngestMethod),
            });

            this.setState(() => ({ dataLoaded: true }));
        }
    }

    submitForm = (data, updateExistingEncodingTargets = false) => {
        hideModal();

        Object.assign(
            data,
            {update_existing_encoding_targets: updateExistingEncodingTargets, showModal: false}
        );

        data.cdn = convertToInt(data.cdn);
        data.live_ingest_method = convertToInt(data.live_ingest_method);
        data.recording_ingest_method = convertToInt(data.recording_ingest_method);
        data.akamai_cdn_ingest_method = convertToInt(data.akamai_cdn_ingest_method);

        this.setState(() => ({
            defaultForm_loading: true,
        }));

        super.onFormSubmit(data);
    };

    onFormSubmit(data) {
        const onClickYes = () => this.submitForm(data, true);
        const onClickNo = () => this.submitForm(data);

        const {product} = this.props.GraphQLEntityData;
        const liveIngestMethodId = _get(product, 'live_ingest_method.id', null);

        const showUpdateEncodingTargetsModal = (
            data.cdn !== product.cdn.id
            || data.live_ingest_method !== liveIngestMethodId
            || data.akamai_cdn_ingest_method !== product.akamai_cdn_ingest_method.id
            || !!data.has_akamai_rtmp_distr_region_support !== !!product.has_akamai_rtmp_distr_region_support
            || !!data.has_scoreboard_overlay_support !== !!product.has_scoreboard_overlay_support
            || !!data.has_slate_support !== !!product.has_slate_support
            || !!data.has_overlay_support !== !!product.has_overlay_support
            || !!data.has_ad_support !== !!product.has_ad_support
            || !!data.has_drm_support !== !!product.has_drm_support
            || !!data.has_cc_support !== !!product.has_cc_support
        );

        if (showUpdateEncodingTargetsModal) {
            showModalConfirmation({
                header: <Header content='Update existing encoding targets?'/>,
                onNo: onClickNo,
                onYes: onClickYes,
                text: (
                    <div>
                        <p>{'The configuration of the product has been modified.'}</p>
                        <p>
                            {`
                            Do you want to update all of the existing encoding targets defined for a "Live"
                            distribution of this product having a start time in the future in order to reflect
                            these modifications?
                        `}
                        </p>
                    </div>
                ),
            });
        } else {
            onClickNo();
        }
    }

    renderErrors(errorData) {
        super.renderErrors(errorData, null, getLink('products.index'));
    }

    /**
     * undefined is not an object
     */
    prepareDataForSubmit = (data) => {
        return Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);
    };

    renderDeleteButton = () => null;

    renderCancelButton = () => null;
}

export default withRouter(ProductForm);
