import {
    graphql as graphqlMocked,
    withApollo as withApolloMocked
} from '@apollo/client/react/hoc';
import {compose as composeMocked} from "redux"

export {gql} from "@apollo/client";

export function compose(...data) {
    return composeMocked(...data);
}

export function graphql (data, options) {
    return graphqlMocked(data, options);
}

export function withApollo (data) {
    return withApolloMocked(data)
}
