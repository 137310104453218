import React from 'react';
import PropTypes from 'prop-types';
import {Menu} from 'semantic-ui-react';

import {getLink} from '@appComponents/Link';

/**
 * @ignore
 */
const MenuItem = (props) => {
    const onItemClick = (event) => {
        event.preventDefault();
        props.click(props.item, props.name)
    };

    if('undefined' === props.item.hidden) {
        props.item.hidden = false;
    }

    if(true === props.item.hidden) {
        return false;
    }

    return (
        <Menu.Item active={props.active} className={props.className} onClick={(event) => {
            onItemClick(event);
        }} href={getLink(props.item.url, props.item.urlParams)}
        >
            {props.children}
        </Menu.Item>
    )
};

MenuItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    className: PropTypes.string,
    click: PropTypes.func,
    item: PropTypes.object,
    name: PropTypes.string,
};

export default MenuItem
