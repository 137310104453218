import React from 'react';

export const DEFAULT_LIVE_INGEST_METHOD = () => {
    return (
        <div>
            <p>Ingest method used by default when automatically generating encoding targets for Live distributions.<br/>
                Ingest method selected by default when manually adding encoding targets for Live distributions.</p>
        </div>
    );
};
export const DEFAULT_RECORDING_INGEST_METHOD = () => {
    return (
        <div>
            <p>Ingest method used by default when automatically generating encoding targets for Recording distributions.<br/>
                Ingest method selected by default when manually adding encoding targets for Recording distributions.</p>
        </div>
    );
};
export const AKAMAI_CDN_INGEST_METHOD = () => {
    return (
        <div>
            <p>Akamai CDN ingest method used by default when automatically generating encoding targets.<br/>
            Akamai CDN ingest method selected by default when manually adding encoding targets.</p>
        </div>
    );
};
export const CDN = () => {
    return (
        <div>
            <p>CDN used by default when automatically generating encoding targets. <br/>
            CDN selected by default when manually adding encoding targets.</p>
        </div>
    );
};
export const HAS_AD_SUPPORT = 'Defines if the encoding targets have support for ads.';
export const HAS_AKAMAI_RTMP_DISTR_REGION_SUPORT = 'Defines if the encoding targets have support for Akamai RTMP distribution regions.';
export const HAS_DRM_SUPPORT = 'Defines if the encoding targets have support for DRM.';
export const HAS_CC_SUPPORT = 'Defines if the encoding targets have support for Close Captioning.';
export const HAS_HQ_SUPPORT = 'Defines if the product supports the offering of High Quality streams to our clients.';
export const HAS_GEOLOCATION_SUPPORT = () => {
    return (
        <div>
            Defines if:
            <ul>
                <li>Geolocation countries can be configured on client products.</li>
                <li>Booking logic related to geolocation countries is applied.</li>
                <li>Geolocation logic is applied on the Client APIs.</li>
            </ul>
        </div>
    );
};
export const IS_INVOICEABLE = 'Defines if the invoice status is required for event reporting.';
export const HAS_EVENT_BOOKING_SUPPORT = 'Defines if bookings are handled on event level, in addition to bookings on distribution level.';
export const HAS_SCOREBOARD_OVERLAY_SUPPORT = 'Defines if the encoding targets have support for scoreboard overlays.';
export const HAS_OVERLAY_SUPPORT = 'Defines if the encoding targets have support for overlays.';
export const HAS_SLATE_SUPPORT = 'Defines if the encoding targets have support for slates.';
export const HAS_USAGE_TYPE_SUPPORT = 'Defines if usage types need to be configured in the rights scopes of the property licences.';
