import {convertToInt} from "../../../utils/helpers";

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {
        label: "Name",
        sorting: (row) => row.name.toLowerCase()
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    }
};
