import {changelogData} from "../changelogData";

export const rightScopeStreamConstraintBody = `
id
product {
  id
  name
}
device_category {
  id
  name
}
max_bitrate
max_player_size
max_resolution_width
max_resolution_height
`;

export const rightScopeStreamConstraint = `
query RightScopeStreamConstraints($id: ID!, $rightsScopeId: Int) {
    rightsScope(id : $id) {
        id
        usages {
            id
            product {
                id
            }
        }
    }
    products {
        id
        name
    }
    deviceCategories {
        id
        name
    }
    rightsScopeStreamConstraints(filters: {rightsScope: $rightsScopeId}) {
        ${rightScopeStreamConstraintBody}
        ${changelogData}
    }
}`;

export const CreateMutation = `
mutation createRightsScopeStreamConstraint(
    $rightsScopeId:Int!,
    $productId:Int!,
    $deviceCategoryId:Int!,
    $maxBitrate:Int,
    $maxResolutionWidth:Int,
    $maxResolutionHeight:Int,
    $maxPlayerSize:Int
  ) {
  createRightsScopeStreamConstraint(
    rights_scope: $rightsScopeId,
    product: $productId,
    device_category:$deviceCategoryId,
    max_bitrate:$maxBitrate,
    max_resolution_width:$maxResolutionWidth,
    max_resolution_height: $maxResolutionHeight,
    max_player_size: $maxPlayerSize
  ) {
    ${rightScopeStreamConstraintBody}
    ${changelogData}
  }
}`;

export const UpdateMutation = `
mutation updateRightsScopeStreamConstraint(
    $id: ID!,
    $productId:Int!,
    $deviceCategoryId:Int!,
    $maxBitrate:Int,
    $maxResolutionWidth:Int,
    $maxResolutionHeight:Int,
    $maxPlayerSize:Int
  ) {
  updateRightsScopeStreamConstraint(
    id: $id,
    product:$productId,
    device_category:$deviceCategoryId,
    max_bitrate:$maxBitrate,
    max_resolution_width:$maxResolutionWidth,
    max_resolution_height: $maxResolutionHeight,
    max_player_size: $maxPlayerSize
  ) {
    ${rightScopeStreamConstraintBody}
    ${changelogData}
  }
}`;

export const DeleteMutation = `
mutation deleteRightsScopeStreamConstraint($id: ID!) {
    deleteRightsScopeStreamConstraint(id: $id)
}`;
