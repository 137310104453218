import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {Button, Dimmer, Header, Loader, Segment} from 'semantic-ui-react';
import {get as _get} from 'lodash';
import {connect} from 'react-redux';

import {isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {graphql, withApollo} from 'react-apollo';
import {GetChangelogDetailsForView} from '@graphql/changeLog/query';
import {renderModalError} from '@utils/forms';
import {getLink} from '@appComponents/Link';
import responseStatus from '@constants/responseStatuses';
import mapModulesToProps from '@utils/mapModulesToProps';

import ChangeLogDetailsTable from '../components/ChangeLogDetailsTable';
import {renderLinkToChangelogPage, renderLinkToElementPage} from '../utils/changelogHelper';

export class ChangeLogDetails extends React.Component {
    static propTypes = {
        DataChangeLogDetails: PropTypes.object,
        revisionId: PropTypes.string,
    };

    getValue = (field = '') => _get(this.props, 'DataChangeLogDetails.changelogRevision.' + field, '-');

    static getDerivedStateFromProps(nextProps) {
        let errorsArray = _get(nextProps, 'DataChangeLogDetails.error.graphQLErrors', []);

        errorsArray && errorsArray.forEach((error) => {
            if (error.code === responseStatus.HTTP_NOT_FOUND) {
                showModal({
                    isVisible: true,
                    content: renderModalError('Changelog details', getLink('changelog.index')),
                });
            }
        });
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.revisionId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Changelog detail', routes.changelog.index.path),
            });
        }
    }

    closeModal = () => {
        showModal({
            isVisible: false,
            content: null,
        });
    };

    render() {
        const path = _get(this.props, 'DataChangeLogDetails.changelogRevision.secured_resource.path', null);

        return <div>
            <Header>Changelog details</Header>
            <div className="form content changelogDetailsContainer">
                {!this.props.DataChangeLogDetails.loading && <div>
                    <div className="formRow">
                        <label className="--text-left">Revision ID</label>
                        {this.getValue('id')}
                    </div>
                    <div className="formRow">
                        <label className="--text-left">Date/time</label>
                        {this.getValue('datetime')}
                    </div>
                    <div className="formRow">
                        <label className="--text-left">Resource</label>
                        {this.getValue('secured_resource.name')}
                    </div>
                    <div className="formRow">
                        <label className="--text-left">ElementID</label>
                        {renderLinkToChangelogPage(
                            _get(this.props, 'DataChangeLogDetails.changelogRevision'))}
                    </div>
                    <div className="formRow">
                        <label className="--text-left">Element name</label>
                        {this.getValue('element_name')}
                    </div>
                    {path &&
                        <div className="formRow">
                            <label className="--text-left">Link</label>
                            {renderLinkToElementPage(_get(this.props, 'DataChangeLogDetails.changelogRevision'), true)}
                        </div>
                    }
                    <div className="formRow">
                        <label className="--text-left">Action</label>
                        {this.getValue('changelog_action_type.name')}
                    </div>
                    <div className="formRow">
                        <label className="--text-left">User</label>
                        {this.getValue('user.name')}
                    </div>
                    <div className="formRow">
                        <label className="--text-left">IP</label>
                        {this.getValue('ip')}
                    </div>
                    <ChangeLogDetailsTable changelogChanges={
                        _get(this.props, 'DataChangeLogDetails.changelogRevision.changelog_changes', [])}
                    />
                </div>
                }
                {this.props.DataChangeLogDetails.loading && <Dimmer inverted active>
                    <Loader/>
                </Dimmer>
                }
                <Segment className="formRow form__footer">
                    <Button onClick={this.closeModal} icon="close" content="Close"/>
                </Segment>
            </div>
        </div>;
    }
}

const ChangeLogDetailsWithQuery = compose(
    graphql(GetChangelogDetailsForView, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    id: props.revisionId,
                },
            };
        },
        name: 'DataChangeLogDetails',
    })
)(ChangeLogDetails);

export default connect(null, mapModulesToProps(['Modal']))(withApollo(ChangeLogDetailsWithQuery));
