import {UpdateEventBooking, DeleteEventBooking} from '@graphql/eventEventBooking/mutation';
import {GetEventEventBookingForGeoRestrictionsForm} from '@graphql/eventEventBooking/query';

import EventBookingBlackoutZonesModel from './EventBookingBlackoutZonesModel';

export default function EventEventBookingBlackoutZonesEditModel() {
    const eventBookingBlackoutZonesEditModel = EventBookingBlackoutZonesModel();

    eventBookingBlackoutZonesEditModel.deleteId = 'eventEventBooking.id';
    eventBookingBlackoutZonesEditModel.editForm = true;
    eventBookingBlackoutZonesEditModel.mutation = {
        updateEntity: UpdateEventBooking,
        deleteEntity: DeleteEventBooking,
    };
    eventBookingBlackoutZonesEditModel.query.getEntity = GetEventEventBookingForGeoRestrictionsForm;

    return eventBookingBlackoutZonesEditModel;
}
