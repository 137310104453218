import {changelogData} from "../changelogData";

export const clientAnalyticsSetupUpdateMutation = `
mutation UpdateClientAnalyticsSetup (
  $id: ID!
  $client_id: ID,
  $report_accessibility_date: String,
  $has_overall_figures: Boolean!,
  $has_top_countries: Boolean!,
  $has_all_countries: Boolean!,
  $has_unikeys_support: Boolean!,
  $has_labels_support: Boolean!,
  $has_session_duration: Boolean!,
  $has_top_referrers: Boolean!,
  $has_top_streams: Boolean!,
  $has_stream_details: Boolean!
) {
  updateClientAnalyticsSetup (
    id: $id
    client: $client_id,
    report_accessibility_date: $report_accessibility_date
    has_overall_figures: $has_overall_figures
    has_top_countries: $has_top_countries
    has_all_countries: $has_all_countries
    has_unikeys_support: $has_unikeys_support
    has_labels_support: $has_labels_support
    has_session_duration: $has_session_duration
    has_top_referrers: $has_top_referrers
    has_top_streams: $has_top_streams
    has_stream_details: $has_stream_details
  ) {
    id
    report_accessibility_date
    has_overall_figures
    has_top_countries
    has_all_countries
    has_unikeys_support
    has_labels_support
    has_session_duration
    has_top_referrers	
    has_top_streams
    has_stream_details
    ${changelogData}
  }
}
`;

export const clientAnalyticsSetupCreateMutation = `
mutation CreateClientAnalyticsSetup (
    $client_id: ID!,
    $report_accessibility_date: String,
    $has_overall_figures: Boolean!,
    $has_top_countries: Boolean!,
    $has_all_countries: Boolean!,
    $has_unikeys_support: Boolean!,
    $has_labels_support: Boolean!,
    $has_session_duration: Boolean!,
    $has_top_referrers: Boolean!,
    $has_top_streams: Boolean!,
    $has_stream_details: Boolean!
) {
    createClientAnalyticsSetup (
        client: $client_id,
        report_accessibility_date: $report_accessibility_date
        has_overall_figures: $has_overall_figures
        has_top_countries: $has_top_countries
        has_all_countries: $has_all_countries
        has_unikeys_support: $has_unikeys_support
        has_labels_support: $has_labels_support
        has_session_duration: $has_session_duration
        has_top_referrers: $has_top_referrers
        has_top_streams: $has_top_streams
        has_stream_details: $has_stream_details
) {
        id
        client {
          id
        }
        report_accessibility_date
        has_overall_figures
        has_top_countries
        has_all_countries
        has_unikeys_support
        has_labels_support
        has_session_duration
        has_top_referrers
        has_top_streams
        has_stream_details
        ${changelogData}
    }
}
`;

export const clientAnalyticsSetupDataQuery = `
    query ClientAnalyticsSetupDataQuery($clientId: Int!) {
      clientAnalyticsSetups(filters: {client: $clientId}) {
        id
        report_accessibility_date
        has_overall_figures
        has_top_countries
        has_all_countries
        has_unikeys_support
        has_labels_support
        has_session_duration
        has_top_referrers
        has_top_streams
        has_stream_details
        ${changelogData}
      }
    }
`;
