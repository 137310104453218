import {convertToInt} from "../../../utils/helpers";
import validators from '../../../utils/validators';

import {DRM_PROVIDER_BUY_DRM} from "../../app/constants/variables";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.drm_provider, 'drm_provider'));

    if (DRM_PROVIDER_BUY_DRM === convertToInt(values.drm_provider)) {
        Object.assign(errors, validators.required(values.server_key, 'server_key'));
        Object.assign(errors, validators.required(values.user_key, 'user_key'));
    }

    return errors;
};

export default validate;
