import React from 'react';
import {Button as SemanticButton} from 'semantic-ui-react';

import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {CLIENT, CLIENT_PACKAGE} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE_READ, SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {INDEX_BUTTONS_ADD} from '../constants/messages';

const ClientIndexButtons = () => {
    const authorizationCreateReadObject = {
        [CLIENT]: SECURITY_PRIVILEGES_CREATE_READ,
    };

    const authorizationClientPackagesReadObject = {
        [CLIENT_PACKAGE]: SECURITY_PRIVILEGES_READ,
    };

    return (
        <div className='content__buttons'>
            <Authorization authorization={authorizationClientPackagesReadObject}>
                <Link name='clientPackages'>
                    <SemanticButton>Client packages</SemanticButton>
                </Link>
            </Authorization>
            <Authorization authorization={authorizationCreateReadObject}>
                <Link name={'clients.add'}>
                    <ButtonAdd>{INDEX_BUTTONS_ADD}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default ClientIndexButtons;
