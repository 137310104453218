import { get as _get, flatMap as _flatMap, includes as _includes } from 'lodash';

import {routes} from '@constants/routes';
import { CompetitorsName } from '@modules/events/views/common/CompetitorsName';
import { getEventContentTypeName } from '@modules/liveControl/utils/helpers';


export const originPaths = (debug) => {
    return debug ? [routes.events.streams.play.debug.origin.path] : [routes.events.streams.play.origin.path];
};

export const originPathsM3u = () => {
    return [routes.events.streams.play.m3u.path];
};

export const streamPaths = (debug) => {
    return debug ?
        [
            routes.events.streams.play.debug.stream.path,
            routes.events.streams.play.debug.event_stream.path,
        ]
        : [
            routes.events.streams.play.stream.path,
            routes.events.streams.play.event_stream.path,
        ];
};

export const manifestPaths = (debug) => {
    return debug ?
        [
            routes.events.streams.play.debug.manifest.path,
            routes.events.streams.play.debug.manifest_akamai.path,
        ]
        : [
            routes.events.streams.play.manifest.path,
            routes.events.streams.play.manifest_akamai.path,
        ];
};

export const m3uFileContent = (eventContentDetailsForStreamsOutput, streamUrl) => {
    const competitorsNames = CompetitorsName({event: eventContentDetailsForStreamsOutput, renderLink: false});
    const eventContentTypeNames = getEventContentTypeName(eventContentDetailsForStreamsOutput);

    return `#EXTM3U\r\n#EXTINF:-1,${competitorsNames}${eventContentTypeNames} `
        +`(${eventContentDetailsForStreamsOutput.event_id})\r\n${streamUrl}`;
};

export const isWnsPath = (debug, url) => {
    const route = debug ? [routes.wnsStreams.debug] : [routes.wnsStreams.play],
        getAllPaths = (item) => [item.path, _get(item, 'debug.path')];

    return _includes(_flatMap(route, getAllPaths), url);
};
