import {
    findIndex as _findIndex,
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';

import EventBookingGeoRestrictionsForm from '@modules/eventGeoRestriction/form/EventBookingGeoRestrictionsForm';
import {convertToInt} from '@utils/helpers';
import {getGeoRestrictionsForDeviceCategoriesOnMasterBookings} from '@utils/countryHelpers';
import {
    BOOKING_TYPE_AVCMP_MANUAL,
    DEVICE_CATEGORY_DESKTOP,
    DEVICE_CATEGORY_MOBILE,
    DEVICE_CATEGORY_SMART_TV,
} from '@constants/variables';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';

import {getDeviceNameById} from '../../utils/eventsBookingGeoRestriction';

export class EventMasterBookingGeoRestrictionsForm extends EventBookingGeoRestrictionsForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;

    prepareDataForSubmit = (data) => {
        const editMode = this.props.Model.editForm,
            preparedData = editMode
                ? this.props.stepsMethods.getForms()['step1'].formValues
                : this.props.previousStepData;

        return Object.assign({}, {
            id: convertToInt(this.props.formParams.id),
            event: convertToInt(this.props.formParams.optionsVariables.event),
            booking_type: BOOKING_TYPE_AVCMP_MANUAL,
            client: convertToInt(preparedData.client),
            product: convertToInt(preparedData.product),
            client_package: preparedData.client_package ? convertToInt(preparedData.client_package) : null,
            auto_update_geo_restrictions: preparedData.auto_update_geo_restrictions || false,
            master_booking_geo_restrictions: getGeoRestrictionsForDeviceCategoriesOnMasterBookings(data),
        });
    };

    onFormSubmit = (data) => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', '')).toLocaleLowerCase()
            ));
        } else {
            return super.onFormSubmit(data);
        }
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (!this.state.deviceCategoriesGenerated
            && !_isEmpty(_get(nextProps.GraphQLOptionsData, 'product.device_categories', []))) {

            let isChanged = false;

            [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV]
                .forEach((deviceCategoryId) => {
                    if (-1 === _findIndex(
                        nextProps.GraphQLOptionsData.product.device_categories,
                        {'id': deviceCategoryId.toString()}
                    )) {
                        this.setField(`${getDeviceNameById(deviceCategoryId)}_switch`, {
                            hidden: true,
                        });
                    } else if (!isChanged) {
                        isChanged = true;

                        this.setField(`${getDeviceNameById(deviceCategoryId)}_switch`, {
                            divClassName: null,
                        });
                    }
                });
            this.setState(() => ({deviceCategoriesGenerated: true}));
        }
    }

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'masterBooking.master_booking_geo_restrictions', {});
    };
}

export default EventMasterBookingGeoRestrictionsForm;
