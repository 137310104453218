import React from "react";
import PropTypes from "prop-types";

import {IconEdit} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";
import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import {getLink} from "../../app/components/Link";
import Authorization from "../../app/components/Authorization";
import {sortByTextValue} from "@utils/sorters";

const authorizationObject = {
    [RESOURCES.EVENT_CONTENT_TYPE]: CONST.SECURITY_PRIVILEGES_READ
};

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "distribution_types": {
        label: "Distribution types",
        sorting: row => getListRendererSortValue(row, 'distribution_types')
    },
    "products": {
        label: "Products",
        sorting: row => getListRendererSortValue(row, 'products', null, true)
    },
    "event_types": {
        label: "Event types",
        sorting: row => getListRendererSortValue(row, 'event_types')
    },
    "sports": {
        label: "Sports",
        sorting: row => {
            const nonEmpty = Boolean(row.event_types.find(event_type => event_type.id === SPORTRADAR));

            return getListRendererSortValue(row, 'sports', nonEmpty)
        }
    },
    "content_categories": {
        label: "1st level categories",
        sorting: row => {
            const nonEmpty = Boolean(row.event_types.find(event_type => event_type.id === NON_SPORTRADAR));

            return getListRendererSortValue(row, 'content_categories', nonEmpty)
        }
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

const listRenderer = (row, column, nonEmpty = null, hasShortName = false) => {
    let contentToRender = null;

    if (nonEmpty === false) {
        contentToRender = '-';
    } else if (nonEmpty === true && row[column].length === 0) {
        contentToRender = 'All';
    } else if (hasShortName) {
        contentToRender = (!row[column] || !row[column].length)
            ? '-' : sortByTextValue(row[column], ['short_name']).map((col => (col.short_name))).join(', ')
    } else {
        contentToRender = (!row[column] || !row[column].length)
            ? '-' : row[column].map((col => (col.name))).join(', ');
    }

    return contentToRender;
};

const getListRendererSortValue = (row, column, nonEmpty = null, hasShortName = false) => {
    const contentToRender = listRenderer(row, column, nonEmpty, hasShortName);

    return ('-' === contentToRender) ? null : contentToRender;
};

const SPORTRADAR = "1";
const NON_SPORTRADAR = "2";

export const rowRenderer = (column, row) => {
    switch (column) {
        case "id":
        case "name":
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: "eventContentTypes.edit",
                        params: {id: row.id}
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case "distribution_types" :
            return listRenderer(row, column);
        case "products" :
            return listRenderer(row, column,null, true);
        case "event_types" :
            return listRenderer(row, column);
        case "sports" : {
            const nonEmpty = Boolean(row.event_types.find(event_type => event_type.id === SPORTRADAR));

            return listRenderer(row, column, nonEmpty);
        }
        case "content_categories" : {
            const nonEmpty = Boolean(row.event_types.find(event_type => event_type.id === NON_SPORTRADAR));

            return listRenderer(row, column, nonEmpty);
        }
        case "actions" :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className="actions-list-icon">
                        <IconEdit size="large" link={
                            getLink('eventContentTypes.edit', {id: row.id})
                        } resource={RESOURCES.EVENT_CONTENT_TYPE}/>
                    </div>
                </Authorization>
            );
        default:
            return null
    }
};

const EventContentTypesTable = (props) => {
    const {types, loading} = props;

    return (
        <div className="types__table">
            <Table
                columns={columns}
                data={types}
                loading={loading}
                name="typesList"
                noDataText="No event content types found"
                rowRenderer={rowRenderer}
                defaultSort={'name'}
            />
        </div>
    )
};

EventContentTypesTable.propTypes = {
    loading: PropTypes.bool,
    types: PropTypes.array,
};

EventContentTypesTable.defaultProps = {
    loading: false,
    types: [],
};

export default EventContentTypesTable;
