import {
    get as _get,
    isArray as _isArray,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    isInteger as _isInteger,
    has as _has,
} from 'lodash';
import PropTypes from 'prop-types';

import {VIDEO_MANAGEMENT_PLATFORM_LINK} from '@constants/variables';
import {routes} from '@constants/routes';

/**
 * Return variable parse to int with radix 10
 */
export const convertToInt = (variable) => {
    if (null === variable) {
        return null;
    }

    return parseInt(variable, 10);
};

/**
 * Returns nested property from a given chain in object
 * (or fallback arg, if property doesn't exist).
 *
 * Usage:
 * readNested(object, ['sport', 'id'], {});
 * readNested(object, "sport.id", null);
 *
 */
export const readNested = (obj, chain, fallback = null) => {
    let base = Object.assign({}, obj);

    if (null === chain || chain === undefined) {
        return null;
    }

    const properties = Array.isArray(chain) ? chain : chain.split('.');

    for (let i = 0; i < properties.length; i++) {
        if (null !== base[properties[i]] && base[properties[i]] !== undefined) {
            base = base[properties[i]];
        } else {
            return fallback;
        }
    }

    return base;
};

export const getParsedId = (object, propertyName = 'id') => {
    return object && object[propertyName] ? parseInt(object[propertyName], 10) : null;
};

export const getName = (object, defaultValue = null, propertyName = 'name') => {
    return _get(object, propertyName, defaultValue);
};

export const getEncodingLogsInVideoManagementPlatformLink = (eventContentId, datacenterName) =>
    `${VIDEO_MANAGEMENT_PLATFORM_LINK}/event-contents/${eventContentId}?datacenter=${datacenterName}`;

export const urlSearchStringToObject = (queryString, include = null) => {
    if (!queryString) {
        return {};
    }

    const regex = /"[^"]*"|(&)/g,
        queryObject = {},
        queryStringParsed = decodeURIComponent(queryString).substring(1).replace(regex, function (char, group1) {
            return (!group1) ? char : '&&&';
        });

    queryStringParsed.split('&&&').forEach(function (data) {
        const index = data.indexOf('='),
            key = data.substring(0, index);

        if (include && -1 === include.indexOf(key)) {
            return;
        }

        let value = data.substring(index + 1).replace(/^"|"$/g, '');

        if ('' !== value && null !== value) {
            const valueParsedToNumber = Number(value);

            if (isNaN(valueParsedToNumber)) {
                try {
                    value = JSON.parse(value);
                } catch (e) {
                    // value with string type, assigned when value variable is created
                }
            } else {
                value = valueParsedToNumber;
            }
        }

        queryObject[key] = value;
    });

    return queryObject;
};

export const getQueryStringFromObject = (object, excludedParams = []) => (Object.keys(object).filter(function(key) {
    // to avoid display "lastClickSubmit" in URL parameters
    if ('lastClickSubmit' === key) {
        return false;
    }

    return !(
        (_isArray(object[key]) && 0 === object[key].length)
        || null === object[key]
        || 'timestamp' === key
        || -1 < excludedParams.indexOf(key)
    );
}).map((key) => {
    const value = object[key];

    return `${encodeURIComponent(key)}=${(_isInteger(value) ? value : JSON.stringify(value))}`;
}).join('&'));

export const prepareClientProductUsages = (usages) => {
    const preparedUsages = [];

    usages.map((product) => (
        product.children.map((deviceCategory) => (
            deviceCategory.children.map((wayOfTransmission) => {
                preparedUsages.push({
                    'id': wayOfTransmission.id,
                    'product': convertToInt(product.value),
                    'device_category': convertToInt(deviceCategory.value),
                    'way_of_transmission': convertToInt(wayOfTransmission.value),
                });
            })
        ))
    ));

    return preparedUsages;
};

export const prepareClientPackageUsages = (usages) => {
    const preparedUsages = [];

    usages.map((deviceCategory) => {
        deviceCategory.children.map((wayOfTransmission) => {
            preparedUsages.push({
                'id': wayOfTransmission.id,
                'device_category': convertToInt(deviceCategory.value),
                'way_of_transmission': convertToInt(wayOfTransmission.value),
            });
        });
    });

    return preparedUsages;
};

export const getIdsFromChildren = (data) => {
    const response = [];

    if (_isEmpty(data)) {
        return response;
    }

    data.forEach((firstLevel) => (
        firstLevel.children.forEach((secondLevel) => {
            response.push(convertToInt(secondLevel.value));
        })
    ));

    return response;
};

export const renderPageTitle = (data) => (
    data && data.entity
        ? data.id
            ? data.name
                ? `${data.entity.charAt(0).toUpperCase() + data.entity.slice(1)} "${data.name}"`
                : ' '
            : `Add new ${data.entity}`
        : ''
);

export const getYesOrNo = (value) => (value ? 'Yes' : 'No');

export const filterDisabledEncodingJobProfiles = (encodingJobProfiles, encodingJobProfileIdFromForm) => (
    encodingJobProfiles.filter((encodingJobProfile) => (
        !encodingJobProfile.is_disabled || encodingJobProfile.value === encodingJobProfileIdFromForm
    ))
);

export const getProductShortNameWithOnlyLettersAndDigits = (shortName) => (
    shortName.toLowerCase().replace(/[^\w]/g, '')
);

export const getContent = (eventContent) => (
    `${eventContent.event_content_type.name}${eventContent.name ? ` - ${eventContent.name}` : ''}`
);

export const parseToBoolean = (value) => (!!(value));

export const getParamFromSearchUrl = (name, parseType = null) => {
    const urlQueryObject = urlSearchStringToObject(window.location.search);

    if (urlQueryObject && urlQueryObject[name]) {
        const param = urlQueryObject[name];

        if ('int' === parseType) {
            const parsedParam = convertToInt(param);

            return Number.isInteger(parsedParam) ? parsedParam : null;
        } else {
            return param;
        }
    }

    return null;
};

export const setParamsToSearchUrl = (paramsObject, force = false) => {
    const urlQueryObject = urlSearchStringToObject(window.location.search);

    let newParams = {...urlQueryObject, ...paramsObject};

    if (_isEqual(newParams, urlQueryObject)) {
        return;
    }

    if (force) {
        newParams = paramsObject;
    }

    changeUrlParams(newParams);
};

const changeUrlParams = (paramsObject) => {
    const queryString = getQueryStringFromObject(paramsObject);
    let newUrl = queryString
        ? `?${queryString}`
        : '';

    if (routes.clipConfiguration.add.addOnSportLevel.path === location.pathname
        || routes.clipConfiguration.add.addOnTournamentLevel.path === location.pathname) {
        newUrl = '';
    }

    window.history.pushState(null, null, newUrl);
};

/**
 * Scroll page to top.
 * Might be helpful at long pages
 */
export const scrollToTop = () => {
    const header = document.querySelector('.avcmp__header');

    if (header) {
        header.scrollIntoView();
    }
};

export const hasLoadingQuery = (queries) => {
    for (const [, query] of queries) {
        if (!_has(query, 'observableQuery.lastResult.loading') || query.observableQuery.lastResult.loading) {
            return true;
        }
    }

    return false;
};

export const scrollToEventContent = (id, callback = null) => {
    const eventContent = document.querySelector(id);

    if (eventContent) {
        eventContent.scrollIntoView();

        callback && callback();
    }
};

export const parseTvStreamingEncoderStreamUrl = (streamUrl) => {
    let host = null,
        path = null,
        streamName = null;

    if (streamUrl) {
        let rest = [];

        [host, path, ...rest] = streamUrl.substr(streamUrl.indexOf('//') + 2).split('/');

        streamName = rest.join('/');
    }

    return {
        host: host,
        path: (null !== path) ? `/${path}/` : path,
        streamName: streamName,
    };
};

parseTvStreamingEncoderStreamUrl.propTypes = {
    streamUrl: PropTypes.string,
};

export const uppercaseFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1));

export const defaultValuePrice = (price) => {
    if (null === price || '' === price || price.isNaN) {
        return null;
    }

    return formatPrice(price);
};

defaultValuePrice.propTypes = {
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const formattedPriceOrEmptyValue = (price, emptyValue = null) => {
    if (null === price || '' === price || price.isNaN) {
        return emptyValue ?? '';
    }

    return formatPrice(price) + ' €';
};

formattedPriceOrEmptyValue.propTypes = {
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    emptyValue: PropTypes.string,
};

export const formatPrice = (priceString) => {
    const price = parseFloat(priceString);

    if (price.toString().endsWith('.00') || !price.toString().includes('.')) {
        return price.toFixed();
    }

    return price.toFixed(2);
};

export const parseIntOrNull = (sourceNumber) => {
    const parsedNumber = parseInt(sourceNumber);

    return isNaN(parsedNumber) ? null : parsedNumber;
};

export const parseFloatOrNull = (sourceNumber) => {
    const parsedNumber = parseFloat(sourceNumber);

    return isNaN(parsedNumber) ? null : parsedNumber;
};

/** *
 * Helper for url (only digits we can add to url)
 * */
export const digitsOnly = string => /^\d+$/.test(string);
export const digitsConnectedByPlus = string => /^[\d+]+$/.test(string);

export const isUrlParamValid = (param) => {
    if (param && digitsOnly(param) || null === param || param === undefined) {
        return true;
    } else if (param && !digitsOnly(param)) {
        return false;
    }
};

export const replaceMatchIdPlaceholderInsideString = (name, matchId) => {
    if (name.includes('[MATCH_ID]')) {
        return name.replaceAll('[MATCH_ID]', matchId ?? '');
    }

    return name;
};
