import React from 'react';
import PropTypes from 'prop-types';

import Authorization from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {sortByKey} from '@utils/sorters';
import {LinkWithAuthorization} from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name,
    },
    'email': {label: 'Email', sorting: true},
    'roles': {
        label: 'Roles',
        sorting: (row) => renderRoles(row.roles),
    },
    'login_datetime': {
        label: 'Last login time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'login_ip': {label: 'Last login IP', sorting: true},
    'creation_datetime': {
        label: 'Creation time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

export const renderRoles = (roles) => {
    let rolesString = '';

    const sortedRoles = [...roles].sort(sortByKey('name'));

    sortedRoles.forEach((val, key, allRoles) => {
        rolesString += Object.is(allRoles.length - 1, key) ? val.name : val.name + ', ';
    });

    return rolesString;
};

renderRoles.propTypes = {
    roles: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
};

export const rowRenderer = (column, row) => {
    const authorizationObject = {
        [RESOURCES.USER]: CONST.SECURITY_PRIVILEGES_READ,
    };

    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'users.edit', params: {id: row.id}}}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case 'email' :
            return (
                <div><a href={`mailto:${row.email}`}>{row.email}</a></div>
            );
        case 'roles' :
            return (
                <div>{renderRoles(row.roles)}</div>
            );
        case 'actions' :
            return (
                <>
                    <Authorization authorization={authorizationObject}>
                        <div className='actions-list-icon'>
                            <IconEdit
                                link={getLink('users.edit', {
                                    id: row.id,
                                })}
                                size='large'
                                resource={RESOURCES.USER}/>
                        </div>
                    </Authorization>
                </>
            );
        default:
            return null;
    }
};
