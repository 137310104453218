import React from 'react';
import PropTypes from "prop-types";

import {hasPrivileges} from '@appComponents/Authorization';
import {PROPERTY_LICENCE_TECH_SETUP} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

import {MatchId} from "../../../../../components/EventsTabsLayout/Details/MatchDetails/fields/column1/MatchId";
import {TvStreaming} from "../../../../../components/EventsTabsLayout/Details/MatchDetails/fields/column1/TvStreaming";

const authorizationPropertyLicenseObject = {
    [PROPERTY_LICENCE_TECH_SETUP]: SECURITY_PRIVILEGES_READ,
};

export const getFieldsList = (event, isTvStreaming) => {
    const fieldList = [{
        name: 'Match ID',
        value: <MatchId event={event}/>,
    }];

    const isAuthorized = hasPrivileges(authorizationPropertyLicenseObject);

    if (isTvStreaming && isAuthorized) {
        fieldList.push({
            name: 'TV Streaming',
            value: <TvStreaming encoder={event.sr_event.match.tv_streaming_encoder}/>,
        });
    }

    return fieldList;
};

getFieldsList.propTypes = {
    event: PropTypes.object.isRequired,
    isTvStreaming: PropTypes.bool.isRequired,
};
