import * as eventsGraphQl from '../../../graphql/events/events';
import SportradarExistingEventByMatchModel from './SportradarExistingEventByMatchModel'
import {StadiumField} from './fields/StadiumField';
import {TvChannelsEdit} from '@modules/events/forms/fields/TvChannels';

export default function SportradarExistingEventModel() {

    const getField = (props, field) => {
        if (props.data.event) {
            return parentModel.fields[field].props.component(
                {data: {match: props.data.event.sr_event.match}}
            );
        }

        return parentModel.fields[field].props.component();
    };

    // get model
    let sportradarExistingEvent = SportradarExistingEventByMatchModel(true),
        parentModel = SportradarExistingEventByMatchModel();

    //change
    sportradarExistingEvent.entityDataMapKey = "event";
    sportradarExistingEvent.messages.boxName.error =
        sportradarExistingEvent.messages.boxName.success = "eventsConfiguration";

    delete sportradarExistingEvent.formId;

    sportradarExistingEvent.fields.id.dataMapKey = "event.sr_event.id";
    sportradarExistingEvent.title = (data) => {
        let title = "Edit Sportradar event";

        if (data.event
            && data.event.sr_event
            && data.event.sr_event.match) {
            title = `Edit Sportradar event ${data.event.id} (match ID ${data.event.sr_event.match.id})`
        }

        return title
    };

    sportradarExistingEvent.editModel = true;
    sportradarExistingEvent.query.getEntity = eventsGraphQl.event;
    sportradarExistingEvent.query.getOptions = eventsGraphQl.existingSportradarEventOptions;
    sportradarExistingEvent.mutation.updateEntity = eventsGraphQl.updateExistingSportradarEvent;
    sportradarExistingEvent.fields.property_licence.props.disabled = true;
    sportradarExistingEvent.fields.end_datetime.props.disabled = false;
    sportradarExistingEvent.fields.tournament.props.component = (props) => {
        return getField(props, 'tournament')
    };
    sportradarExistingEvent.fields.competitors.props.component = (props) => {
        return getField(props, 'competitors')
    };
    sportradarExistingEvent.fields.stadium.props.component = StadiumField;

    sportradarExistingEvent.fields.start_datetime.dataMapKey = "event.sr_event.match.start_datetime";
    sportradarExistingEvent.fields.status.props.hidden = true;

    // On the EDIT SPORTRADAR EVENT there is different path to get data for tv channels
    sportradarExistingEvent.fields.tvChannels.props.component = TvChannelsEdit;

    delete(sportradarExistingEvent.fields.master_event);

    return sportradarExistingEvent
}
