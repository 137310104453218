import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";

import {THIRD_PARTY_CLIENT_PULL_ORIGIN} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE} from "../../app/constants/variables";

const ThirdPartyPullOriginsIndexButtons = (props) => (
    <div className="content__buttons">
        <Authorization
            privileges={SECURITY_PRIVILEGES_CREATE}
            resources={THIRD_PARTY_CLIENT_PULL_ORIGIN}
        >
            <ButtonAdd onClick={props.prepareDataForModal}>{`Add new pull origin`}</ButtonAdd>
        </Authorization>
    </div>
);

ThirdPartyPullOriginsIndexButtons.propTypes = {
    prepareDataForModal: PropTypes.func.isRequired,
};

export default ThirdPartyPullOriginsIndexButtons;
