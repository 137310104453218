import PropTypes from "prop-types";
import {get as _get} from "lodash";

import Form from "../../app/components/ReduxFormControls";

import {generateFieldsForUserResources} from "../utils/generateFieldsForUserResources";
import {MESSAGE_BOX_USERS_AFTER_EDIT_NAME} from "../utils/variables";

import validate from "../../role/forms/validatorRoleResource";

import * as MESSAGES from "../../app/constants/messages";
import {USER} from "../../app/constants/resources";

import {UpdateUserResources} from "../../../graphql/user/mutation.graphql";
import {GetUserResourcesData} from "../../../graphql/user/query.graphql";

export default function EditUserResourcesModel(data, id) {
    const userName = _get(data, "user.name", "");

    return {
        entityDataMapKey: 'userResources',
        entityLabel: "User",
        formName: "editUserResources",
        resources: USER,
        showChangeLog: false,
        validate: validate,
        title: null,
        dataMap: {
            id: "",
            securedResources: [],
        },
        messages: {
            boxName: {
                error: MESSAGE_BOX_USERS_AFTER_EDIT_NAME,
                success: MESSAGE_BOX_USERS_AFTER_EDIT_NAME,
            },
            text: {
                ...MESSAGES,
                SAVED: () => `The resources of the user "${userName}" have been saved successfully.`,
                FORM_ERROR: () => `The resources of the user "${userName}" have not been saved. Please correct the highlighted fields below.`,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                props: {
                    defaultValue: id,
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            ...generateFieldsForUserResources(data)
        },
        mutation: {
            updateEntity: UpdateUserResources,
        },
        query: {
            getEntity: GetUserResourcesData,
        },
    }
}

EditUserResourcesModel.propTypes = {
    data: PropTypes.shape({
        user_resources: PropTypes.object,
        roles_resources: PropTypes.array,
        securedResource: PropTypes.array,
    }).isRequired,
    id: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
};
