import React from 'react';

import Authorization from '@appComponents/Authorization';
import { ButtonAdd } from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

const authorizationObject = {
    [RESOURCES.ROLE] : CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

const RoleIndexButtons = () => {
    return (
        <div className='content__buttons'>
            <Authorization
                authorization={authorizationObject}
            >
                <Link name='roles.add'>
                    <ButtonAdd>{'Add new role'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default RoleIndexButtons;
