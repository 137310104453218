import { get as _get } from 'lodash';
import PropTypes from 'prop-types';

export const getNullableStringOfNamesFromObject = (object, path) => {
    const tvChannelList = _get(object , path , []);

    return tvChannelList.length
        ? tvChannelList.map(element => element.name).join(', ')
        : null;
};
getNullableStringOfNamesFromObject.propTypes = {
    object: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export const getTvChannelNamesFromEvent = (event) => {
    return getNullableStringOfNamesFromObject(event, 'sr_event.match.tv_channels');
};

getTvChannelNamesFromEvent.propTypes = {
    event: PropTypes.object.isRequired,
};

export const getTvChannelNamesFromChildEvent = (childEvent) => {
    return getNullableStringOfNamesFromObject(childEvent, 'tv_channels');
};

getTvChannelNamesFromChildEvent.propTypes = {
    childEvent: PropTypes.object.isRequired,
};
