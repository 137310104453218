import {changelogData} from '../changelogData';

export const contactPersonIndex = `
query ContactPersons (
  $clientId: ID!
) {
  contactPersons (filters: {
    client: $clientId
  }) {
    id
    name
    contact_person_type {
      id
      name
    }
    position
    address
    email_address
    phone_number_office
    phone_number_mobile
    skype_name
    notes
    ${changelogData}
  }
}
`;

export const contactPersonTypes = `
query ContactPersonTypes (
	$id: [ID]
) {
  contactPersonTypes (filters: {
    id: $id
  }) {
    id
    value : id,
    text : name
  }
}
`;

export const createContactPersonForClient = `
mutation CreateContactPerson (
  $contact_person_type: ID!
  $clientId: ID!
  $name: String!
  $position: String
  $address: String
  $email_address: String
  $phone_number_office: String
  $phone_number_mobile: String
  $skype_name: String
  $notes: String
) {
  createContactPerson (
    contact_person_type: $contact_person_type
    client: $clientId
    name: $name
    position: $position
    address: $address
    email_address: $email_address
    phone_number_office: $phone_number_office
    phone_number_mobile: $phone_number_mobile
    skype_name: $skype_name
    notes: $notes
  ) {
    id
    name
    contact_person_type {
      id
      name
    }
    position
    address
    email_address
    phone_number_office
    phone_number_mobile
    skype_name
    notes
    ${changelogData}
  }
}
`;

export const updateContactPersonForClient = `
mutation UpdateContactPerson (
  $id: ID!
  $contact_person_type: ID!
  $name: String!
  $position: String
  $address: String
  $email_address: String
  $phone_number_office: String
  $phone_number_mobile: String
  $skype_name: String
  $notes: String
) {
  updateContactPerson (
    id: $id
    contact_person_type: $contact_person_type
    name: $name
    position: $position
    address: $address
    email_address: $email_address
    phone_number_office: $phone_number_office
    phone_number_mobile: $phone_number_mobile
    skype_name: $skype_name
    notes: $notes
  ) {
    id
    name
    contact_person_type {
      id
      name
    }
    position
    address
    email_address
    phone_number_office
    phone_number_mobile
    skype_name
    notes
    ${changelogData}
  }
}
`;

export const deleteContactPersonForClient = `
mutation DeleteContactPerson (
  $id: ID!
) {
  deleteContactPerson (id: $id)
}
`;