import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {routes} from '@constants/routes';
import {getQueryStringFromObject, urlSearchStringToObject} from '@utils/helpers';

export function FilterQueryWrapper(Component, params) {
    class FilterQueryWrapper extends React.Component {

        static propTypes = {
            filters: PropTypes.object,
            history: PropTypes.object,
            location: PropTypes.object
        };

        static defaultProps = {
            location: {}
        };

        constructor(props) {
            super(props);

            this.state = {
                loadingRefetch: false
            };

            this.setUrlQueryParams(props);
        }

        setUrlQueryParams = (props) => {
            if (props.location.search) {
                return
            }

            const filterUrlsPaths = _get(params, 'filterUrls', []),
                filterUrlsArray = filterUrlsPaths.map((path) => {
                    const route = _get(routes, `${path}.path`, null);

                    if (!route) {
                        throw Error(`No route found for ${path}`)
                    }

                    return route
                });

            if (-1 === filterUrlsArray.indexOf(_get(props, 'match.path', ''))) {
                return
            }

            const queryString = getQueryStringFromObject(
                props.filters, _get(
                    params,
                    'excluded',
                    ['page','pageLimit', 'order', 'orderBy']
                )
            );

            if (routes.clipConfiguration.index.path !== props.location.pathname) {
                props.history.replace({
                    search: `?${queryString}`,
                });
            }

            if (routes.clipConfiguration.index.path === props.location.pathname && queryString !== 'search=""') {
                props.history.replace({
                    search: `?${queryString}`,
                });
            }
        };

        componentWillReceiveProps(nextProps) {
            if (nextProps.filters.timestamp > this.props.filters.timestamp) {
                /**
                 * To avoid multiple "fetching data" (fix. bad loadingRefetch state,
                 * problem with resolving Promise with the same query name):
                 * - 1st from initial loading from HOC graphql() (timestamp comparision - initial / redux-store)
                 *
                 * - 2nd from timestamp condition - this method componentWillReceiveProps() - this.props[..].refetch()
                 * (timestamp comparision after click on search button
                 * or timestamp from redux-store with initial timestamp)
                 */
                if (this.props[params.queryForRefresh] && this.props[params.queryForRefresh].loading) {
                    this.setUrlQueryParams(nextProps);

                    return;
                }

                if (undefined !== this.props[params.queryForRefresh]) {
                    /**
                     * We need to use own state because of the bug in networkStatus in apolloClient
                     * We can use loadingRefetch props in component which is wrapped by hoc
                     */
                    this.setState(() => ({
                        loadingRefetch: true
                    }));

                    this.props[params.queryForRefresh].refetch()
                        .then(() => {
                            this.setState(() => ({
                                loadingRefetch: false
                            }));
                        });
                }
            }

            this.setUrlQueryParams(nextProps)
        }

        render() {
            return (
                <Component
                    { ...this.props }
                    loadingRefetch={this.state.loadingRefetch}
                />
            )
        }
    }

    return FilterQueryWrapper
}

export function FilterUrlParamsWrapper(Component, defaultFilters = {}, url = null) {
    class FilterUrlParamsWrapper extends React.Component {
        static propTypes = {
            match: PropTypes.object,
            filters: PropTypes.shape({
                lastClickSubmit: PropTypes.oneOfType(
                    [PropTypes.string, PropTypes.number]),
                timestamp: PropTypes.oneOfType(
                    [PropTypes.string, PropTypes.number])
            }),
            location: PropTypes.object,
        };

        static defaultProps = {
            filters: {},
            location: {}
        };

        constructor(props) {
            super(props);

            let urlFilters = null,
                filtersForSession = {},
                filters = {};

            const isPathMatched = !url || _get(routes, `${url}.path`, null) === props.match.path;

            if (isPathMatched) {
                urlFilters = urlSearchStringToObject(props.location.search);
            }

            if (!_isEmpty(urlFilters) && (isPathMatched)) {
                filters = {...defaultFilters, ...urlFilters};
            } else {
                filters = _get(props, 'filters', {});
            }

            if ((_isEmpty(filters) || 1 === Object.keys(filters).length) && _isEmpty(urlFilters)) {
                filtersForSession = {...defaultFilters};
                filters = {...defaultFilters};
            }

            this.state = {...props, ...{filters}, ...{sessionFilters: filtersForSession}}
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (!prevState.filters.timestamp) {
                return {
                    filters: {...prevState.filters,
                        ...{timestamp: nextProps.filters.timestamp || Date.now()}
                    }
                }
            }

            if (nextProps.filters.timestamp !== prevState.filters.timestamp
                && undefined !== nextProps.filters.timestamp
                && nextProps.filters.lastClickSubmit
            ) {
                return {
                    filters: {...nextProps.filters}
                }
            }

            return null
        }

        render() {
            return (
                <Component { ...this.props } sessionFilters={this.state.sessionFilters} filters={this.state.filters}/>
            )
        }
    }

    return FilterUrlParamsWrapper
}
