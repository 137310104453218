import React from "react";
import PropTypes from "prop-types";

/**
 * Wrapper for display:
 * - Competitors name
 * - Event content type
; * with correct margin and style
 *
 * @param children
 * @returns {*}
 * @constructor
 */
export const CompetitorsAndTypeWrapper = ({children}) => {
    return (
        <div className="--flex --flexWrap">
            {children}
        </div>
    )
};

CompetitorsAndTypeWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};
