import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {CLIENT_API_KEY} from "../../app/constants/resources";

import validate from "./validatorClientAvApiKey";

import {ClientApiKeyCreateMutation, ClientApiKeyUpdateMutation} from "../../../graphql/clientAvApiKey/mutation.graphql";
import {ClientApiKeyQuery, EnvironmentDropdownQuery} from "../../../graphql/clientAvApiKey/query.graphql";

export default function ClientAvApiKeyModel() {
    return {
        deleteId: "clientApiKey.id",
        entityDataMapKey: "clientApiKey",
        entityLabel: "API key",
        formName: "AV API key",
        resources: CLIENT_API_KEY,
        showChangeLog: true,
        validate: validate,
        title: () =>  "Generate new API key",
        dataMap: {
            clientApiKey: {
                id: "",
                environment: {
                    id: "",
                },
                description: "",
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('API key'),
                SAVED: () => 'The API key has been generated successfully.',
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "clientApiKey.id",
                props: {
                    label: '',
                    name: "id",
                    hidden: true,
                },
            },
            environment: {
                dataMapKey: "clientApiKey.environment.id",
                optionsKey: "environments",
                props: {
                    className: "--auto_width",
                    component: Form.SemanticSelect,
                    label: "Environment",
                    name: "environment",
                    required: true,
                },
            },
            description: {
                dataMapKey: "clientApiKey.description",
                props: {
                    className: "--description-medium-width",
                    component: Form.SemanticInput,
                    label: "Description",
                    name: "description",
                    type: "input",
                },
            },
        },
        mutation: {
            createEntity: ClientApiKeyCreateMutation,
            updateEntity: ClientApiKeyUpdateMutation,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['ClientApiKeysQuery'],
            },
            update: {
                refetchQueries: ['ClientApiKeysQuery'],
            },
        },
        query: {
            getEntity: ClientApiKeyQuery,
            getOptions: EnvironmentDropdownQuery,
        },
    };
}
