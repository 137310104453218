import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {includes as _includes} from 'lodash';

import {withApollo} from 'react-apollo';
import {showModal} from '@utils/modal';
import {renderModalError} from '@utils/forms';
import {isUrlParamValid} from '@utils/helpers';
import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import Tabs from '@appComponents/Tabs';
import {getUserAuthorizationData} from '@appComponents/Authorization';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';

import mapModulesToProps from "../../../utils/mapModulesToProps";
import {createTabs} from "../utils/productionCompanyTabsLayoutHelper";

import navigation from "../../app/constants/navigation";
import {routes} from "../../app/constants/routes";
import {EXPORT_EXCEL_URL_FRAGMENT} from "../../app/constants/variables";

import {GetProductionCompanyForTabs} from "../../../graphql/productionCompany/query.graphql";

export const ProductionCompanyTabsLayout = ({
    data = {},
    productionCompanyName = '',
    match: {
        path,
        params: {
            id = null,
        },
    },
    Menu,
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Production company', routes.productionCompanies.index.path),
        });
    }

    const rtmpOriginStreamNames = routes.productionCompanies.rtmpOriginStreamNames,
        rtmpOriginStreamNamesExcel = `${rtmpOriginStreamNames.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
        activePath = React.useMemo(() => {
            const contactPersons = routes.productionCompanies.contactPersons;

            if (_includes([
                contactPersons.index.path,
                contactPersons.add.path,
                contactPersons.edit.path,
            ], path)) {
                return contactPersons.index.path;
            } else if (_includes([
                rtmpOriginStreamNames.index.path,
                rtmpOriginStreamNamesExcel,
                rtmpOriginStreamNames.add.path,
                rtmpOriginStreamNames.edit.path,
                rtmpOriginStreamNames.addMultiple.path,
            ], path)) {
                return rtmpOriginStreamNames.index.path;
            }

            return path;
        }, [path]);

    React.useEffect(() => {
        Menu.storeMenu(navigation.eventAdministration.key);
    }, []);

    return (
        !data.error
        && (
            productionCompanyName
            || rtmpOriginStreamNamesExcel !== path
        )
        && isUrlParamValid(id)
        && (
            <div>
                <HeaderRenderer
                    buttons={RenderBackToListButton}
                    buttonsProps={{name: 'productionCompanies.index'}}
                    messagesBoxNames='productionCompany'
                    pageTitle={
                        <TitleFromQuery
                            query={GetProductionCompanyForTabs}
                            variables={{id}}
                            errorBackLink={routes.productionCompanies.index.path}
                            path='productionCompany.name'
                            entity='production company'
                        />
                    }
                />
                <Tabs items={createTabs(id, path, getUserAuthorizationData())} active={activePath}/>
            </div>
        )
    );
};

ProductionCompanyTabsLayout.propTypes = {
    client: PropTypes.object.isRequired,
    data: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
    productionCompanyName: PropTypes.string,
};
ProductionCompanyTabsLayout.displayName = 'ProductionCompanyTabsLayout';

export default connect(null, mapModulesToProps(['Menu']))(withApollo(ProductionCompanyTabsLayout));
