import React from 'react';
import PropTypes from 'prop-types';

import Table from "../../app/components/Table";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";
import {IconEdit} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import Authorization from "../../app/components/Authorization";

const authorizationObject = {
    [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_READ
};

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "country": {
        label: "Country",
        sorting: (row) => (row.country === null) ? "" : row.country.name
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case "id":
        case "name":
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: "licensees.edit",
                        params: {id : row.id}
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case "country" :
            return (
                <div>
                    {(row.country === null || row.country === undefined)
                        ? ""
                        : row.country.name.capitalizeFirstLetter()
                    }
                </div>
            );
        case "actions" :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className="actions-list-icon">
                        <IconEdit size="large" link={
                            getLink('licensees.edit', {id: row.id})
                        } resource={RESOURCES.LICENSEE} />
                    </div>
                </Authorization>
            );
        default:
            return null
    }
};

const LicenseeTable = (props) => {
    const { licensees, loading } = props;

    return (
        <div className="licensees__table">
            <Table
                name="licenseesList"
                columns={columns}
                data={licensees}
                loading={loading}
                rowRenderer={rowRenderer}
                noDataText="No licensees found"
            />
        </div>
    );
};

LicenseeTable.defaultProps = {
    licensees: []
};

LicenseeTable.propTypes = {
    licensees: PropTypes.array.isRequired,
    onLicenseeDelete: PropTypes.func,
    loading: PropTypes.bool
};

export default LicenseeTable;
