import PropTypes from "prop-types";
import {get as _get, has as _has} from "lodash";


export const getTournamentNameFromEvent = (event) => (
    _get(
        event,
        'sr_event.match.tournament.name',
        _get(event, 'sr_event.tournament.name', '-')
    )
);

/**
 * Get tournament name from event object.
 *
 * @param {Object} event Event object type.
 *
 * @return {string}
 */
export const getTournamentCategoryNameFromEvent = (event) => (
    _get(
        event,
        'sr_event.match.tournament.tournament_category.name',
        _get(event, 'sr_event.tournament.tournament_category.name', '-')
    )
);

/**
 * Get tournament name with nested structure
 * @param match
 * @returns {(string|null)}
 */
export const getTournamentNameFromMatch = (match) => {
    let tournamentName;

    if (match.tournament) {
        tournamentName = match.tournament.name || null;
    }

    if (_has(match, 'tournament.tournament_category.name')) {
        tournamentName = `${match.tournament.tournament_category.name} > ${tournamentName}`;
    }

    if (_has(match, 'tournament.tournament_category.sport.name')) {
        tournamentName = `${match.tournament.tournament_category.sport.name} > ${tournamentName}`;
    }

    return tournamentName;
};

getTournamentNameFromMatch.propTypes = {
    match: PropTypes.shape({
        tournament: PropTypes.shape({
            name: PropTypes.string,
            tournament_category: PropTypes.shape({
                name: PropTypes.string,
                sport: PropTypes.shape({
                    name: PropTypes.string
                })
            })
        })
    }).isRequired
};
getTournamentNameFromMatch.defaultProps = {
    match: {
        tournament: {
            name: null,
            tournament_category: {
                name: null,
                sport: {
                    name: null
                }
            }
        }
    }
};

/**
 * Get detailed tournaments list in format
 * sport > tournament_category > tournament
 * prepared for dropdown
 *
 * @param tournaments {(null|array)}
 *
 * @returns {array}
 */
export const prepareTournamentsForDropdown = (tournaments = []) => (tournaments.map((item) => ({
    key: item.id.toString(),
    text: `${item.tournament_category.sport.name} > ${item.tournament_category.name} > ${item.name}`,
    value: item.id.toString(),
})));
