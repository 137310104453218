import {applyPresetFieldsForModel, getEventDefaultFields, presetFieldsForModel} from "./EventModel";
import Form from "../../app/components/ReduxFormControls";

import {validateForNonExistingEvent} from "./validator";
import * as formUtils from "../utils/formHelper";

import * as MESSAGES from "../../app/constants/messages";
import {EVENT} from "../../app/constants/resources";
import {REQUIRE_THREE_LETTERS_PLACEHOLDER} from "../../app/constants/variables";

import * as eventsGraphQl from "../../../graphql/events/events";
import {updateSrEvent} from "../../../graphql/srEvent/mutation.graphql";

export const SPORTRADAR_NON_EXISTING_EVENT_MODEL = "SportradarNonExistingEventModel";

export default function SportradarNonExistingEventModel(isEdit = false) {
    let sportradarNonExistingEvent = {
        formName: "SportradarNonExistingEvent",
        entityDataMapKey: "event",
        id: SPORTRADAR_NON_EXISTING_EVENT_MODEL,
        title: () => {
            return "Create Sportradar event";
        },
        label: "Event",
        entityLabel: "Event",
        resources: EVENT,
        validate: validateForNonExistingEvent,
        dataMap: {
            event: {
                sr_event: {
                    synchronise_times: true
                },
                start_datetime: "",
                end_datetime: "",
                master_event: {
                    id: null
                },
                description: "",
                color: "3a87ad",
                event_status: {
                    id: null
                },
                property_licence: {
                    id: null
                },
                notes: ""
            }

        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'event',
                error: 'event'
            },
            text: MESSAGES,
            delete: MESSAGES.EVENT_DELETE_STATUS_TEXT_OBJECT,
            create: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT,
            update: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT
        },
        mutation: {
            updateEntity: updateSrEvent,
            createEntity: eventsGraphQl.srNonExistingEventCreateMutation,
            deleteEntity: eventsGraphQl.srEventDeleteMutation
        },
        query: {
            getOptions: eventsGraphQl.nonExistingSportradarOptionsQuery
        },
        fields: {
            property_licence: {
                dataMapKey: "event.property_licence.id",
                optionsKey: "property_licences",
                props: {
                    component: formUtils.renderPropertyLicenceField,
                    showLink: false,
                    label: "Property licence",
                    required: true,
                    name: "property_licence",
                    placeholder: "Select property licence",
                    type: "select"
                }
            },
            ...applyPresetFieldsForModel(isEdit),
            tournament: {
                dataMapKey: "event.sr_event.tournament.id",
                props: {
                    component: Form.SemanticSelect,
                    label: "Tournament",
                    required: true,
                    disabled: true,
                    type: "select",
                    name: "tournament_id"
                }
            },
            home_competitor: {
                dataMapKey: "event.sr_event.home_competitor.id",
                props: {
                    component: Form.SemanticSelect,
                    type: "select",
                    placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
                    label: "Home competitor",
                    name: "home_competitor_id",
                    allowClear: true,
                    disabled: true
                }
            },
            away_competitor: {
                dataMapKey: "event.sr_event.away_competitor.id",
                props: {
                    component: Form.SemanticSelect,
                    type: "select",
                    placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
                    label: "Away competitor",
                    name: "away_competitor_id",
                    allowClear: true,
                    disabled: true
                }
            },
            court: {
                dataMapKey: null,
                props: {
                    component: Form.SemanticSelect,
                    type: "select",
                    label: "Court",
                    name: "court",
                    allowClear: true,
                    hidden: true,
                    disabled: true
                }
            },
            stadium: {
                dataMapKey: "event.sr_event.stadium.id",
                props: {
                    component: Form.SemanticSelect,
                    type: "select",
                    placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
                    label: "Venue",
                    name: "stadium",
                    allowClear: true,
                    hidden: true
                }
            }
        }
    };


    sportradarNonExistingEvent.title = () => ("Create Sportradar event");
    sportradarNonExistingEvent.fields = Object.assign(sportradarNonExistingEvent.fields, getEventDefaultFields());
    sportradarNonExistingEvent.fields = {...sportradarNonExistingEvent.fields, ...presetFieldsForModel()};

    delete(sportradarNonExistingEvent.fields.master);

    return sportradarNonExistingEvent
}
