import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dimmer, Header, Loader, Modal as SemanticModal} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {isFunction} from 'lodash';

import {setModal, setModalConfirmation, setModalError} from '@actions';
import {initialState} from '@reducers/modal';
import {MODAL_TYPE_CONFIRMATION, MODAL_TYPE_ERROR} from '@constants/modals';

export const GetModalLoadingContent = () => (
    <div className="content">
        <Dimmer inverted active={true}>
            <Loader/>
        </Dimmer>
    </div>
);

/**
 * @ignore
 */
class Modal extends React.Component {
    static propTypes = {
        Header: PropTypes.object,
        history: PropTypes.object,
        modal: PropTypes.object,
        setModal: PropTypes.func,
        setModalConfirmation: PropTypes.func,
        size: PropTypes.oneOf(['fullscreen', 'large', 'mini', 'small', 'tiny']),
        errorButtonText: PropTypes.string,
        errorButtonIcon: PropTypes.string,
    };

    static defaultProps = {
        setModalConfirmation: () => {},
        size: 'tiny',
        errorButtonText: 'Close',
        errorButtonIcon: 'remove',
    };

    state = {
        documentActiveElement: null,
    };

    constructor(props) {
        super(props);
        this.unlistener = null;

        this.closeModal = this.closeModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this)
        this.enterKey = this.enterKey.bind(this);
        this.yesButtonRef = React.createRef();
    }

    onMount = () => {
        document.addEventListener("keydown", this.enterKey, false);

        if (this.state.documentActiveElement) {
            this.state.documentActiveElement.blur();
        }
    }

    onUnmount = () => {
        document.removeEventListener("keydown", this.enterKey, false);
        this.clearModalProps();

        this.setState(() => ({
            documentActiveElement: null,
        }));
    }

    /**
     * Avoid close popup by "Enter" key
     * Allow confirm "Yes/No" modal by click "Enter"
     * @param event
     */
    enterKey(event) {
        if ("Enter" === event.key) {
            if (this.props.modal.loading) {
                event.preventDefault();

                return;
            }

            if (this.yesButtonRef.current) {
                this.yesButtonRef.current.focus();
            }
        }
    }

    /**
     * Method for control modal by Form (loading status)
     * @param control
     */
    controlModal(control = {}) {
        if (this.Modal) {
            this.Modal.setModal(control);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (null === state.documentActiveElement && document.activeElement && props.modal.isVisible) {
            return {
                documentActiveElement: document.activeElement,
            }
        }

        return null;
    }

    componentDidUpdate() {
        if (this.props.modal.isVisible) {
            this.unlistener = this.props.history.listen(() => {
                this.closeModal()
            })
        } else {
            if (this.unlistener) {
                this.unlistener();
                delete this.unlistener;
            }
        }
    }

    onCloseModal(event) {
        const allowClickEscape = (
            "Escape" === event.key
            && !this.props.modal.loading
        );

        if (allowClickEscape) {
            this.closeModal();
            this.clearModalProps();
        }
    }

    closeModal() {
        if (!this.props.modal.isVisible) {
            return;
        }

        if (isFunction(this.props.modal.onClose)) {
            this.props.modal.onClose();
        }

        this.clearModalProps();
    }

    /**
     * After close, modal's state should be cleared
     * to initial state from reducer
     * to provide the same behaviour in every open modal
     */
    clearModalProps() {
        this.props.setModal(initialState);
    }

    /**
     * Function called by click on Yes Button with changing modal status
     */
    yesFunction = () => {
        this.props.modal.onYes();

        this.props.setModal({
            inProgress: true,
            loading: true
        });
    }

    redirectToLink = () => {
        this.props.history.push(this.props.modal.redirectLink);
    }

    renderButtons() {
        if (!this.props.modal.hideButtons) {
            return (
                <SemanticModal.Actions>
                    <Button
                        ref={this.yesButtonRef}
                        content="Yes"
                        labelPosition="right"
                        onClick={this.yesFunction}
                        color="green"
                        icon="checkmark"
                        positive
                        disabled={this.props.modal.inProgress}
                        loading={this.props.modal.loading}
                    />
                    <Button
                        content="No"
                        labelPosition="right"
                        onClick={this.props.modal.onNo || this.closeModal}
                        color="red"
                        icon="remove"
                        negative
                        disabled={this.props.modal.inProgress}
                    />
                </SemanticModal.Actions>
            )
        }
    }

    renderModalContent() {
        if ('error' === this.props.modal.type) {
            return (
                <div className={"errorModal"}>
                    <Header icon='warning' color='red' content={this.props.modal.header} />
                    {this.props.modal.text && <SemanticModal.Content>{this.props.modal.text}</SemanticModal.Content>}
                    {!this.props.modal.hideButtons && (
                        <SemanticModal.Actions>
                            <Button
                                content={this.getButtonContent()}
                                onClick={this.props.modal.redirectLink ? this.redirectToLink : this.closeModal}
                                icon={this.getButtonIcon()}
                            />
                        </SemanticModal.Actions>
                    )}
                </div>
            );
        }

        if (this.props.modal.content !== undefined && this.props.modal.content !== null) {
            return (
                React.cloneElement(this.props.modal.content, {controlModal: this.controlModal})
            )
        } else {
            return (
                <div>
                    { this.props.modal.header }
                    <SemanticModal.Content>
                        {
                            typeof(this.props.modal.text) === 'object'
                                ? this.props.modal.text
                                : <p>{this.props.modal.text}</p>
                        }
                    </SemanticModal.Content>
                    { this.renderButtons() }
                </div>
            )
        }
    }

    getButtonContent() {
        return this.props.modal.errorButtonText || Modal.defaultProps.errorButtonText;
    }

    getButtonIcon() {
        return this.props.modal.errorButtonIcon || Modal.defaultProps.errorButtonIcon;
    }

    render() {
        if ((undefined === this.props.modal.content || null === this.props.modal.content)
            && MODAL_TYPE_ERROR !== this.props.modal.type
            && (MODAL_TYPE_CONFIRMATION === this.props.modal.type && undefined === this.props.modal.size)
        ) {
            this.props.modal.size = Modal.defaultProps.size;
        }

        return (
            <SemanticModal
                dimmer={this.props.modal.dimmer || true}
                onMount={this.onMount}
                onUnmount={this.onUnmount}
                className={this.props.modal.className}
                onClose={this.onCloseModal}
                open={this.props.modal.isVisible}
                size={this.props.modal.size || Modal.defaultProps.size}
            >
                { this.renderModalContent() }
            </SemanticModal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModal: (params) => {
            dispatch(setModal(params));
        },
        setModalConfirmation: (params) => {
            dispatch(setModalConfirmation(params));
        },
        setModalError: (params) => {
            dispatch(setModalError(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Modal));
