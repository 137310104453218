import React from "react";

const LiveControlMultipleFailoverConfirmation = () => (
    <React.Fragment>
        {`
            This will failover the UDP origin entry point of all event contents displayed in 
            the table below which:
        `}
        <ul>
            <li>Have UDP as origin.</li>
            <li>Have a UDP origin entry point defined with a failover.</li>
            <li>Are being encoded or going to be encoded.</li>
        </ul>
        {`
            Please note that if an event content is being encoded, the failover will be possible only
            if the encoding datacenter of the failover UDP origin entry point is the same as the 
            original UDP origin entry point. If they are different, the encoding of the event content 
            must be stopped first.
        `}
        <br/><br/>
        {`Are you sure you want to apply this failover?`}
    </React.Fragment>
);

export default LiveControlMultipleFailoverConfirmation;
