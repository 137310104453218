import PropTypes from 'prop-types';
import React from 'react';

import Authorization from "../../app/components/Authorization";
import Link from "../../app/components/Link";
import {ButtonAdd} from "../../app/components/ButtonCollection";

import {PRODUCTION_COMPANY, RTMP_ORIGIN_STREAM_NAME} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE_READ, SECURITY_PRIVILEGES_READ} from "../../app/constants/variables";

const RtmpOriginStreamNamesIndexButtons = (id) => {
    const authorizationObject = {
        [PRODUCTION_COMPANY]: SECURITY_PRIVILEGES_READ,
        [RTMP_ORIGIN_STREAM_NAME]: SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="content__buttons">
            <Authorization authorization={authorizationObject}>
                <Link name={'productionCompanies.rtmpOriginStreamNames.add'} params={id}>
                    <ButtonAdd>{'Add new RTMP origin stream name'}</ButtonAdd>
                </Link>
                <Link name={'productionCompanies.rtmpOriginStreamNames.addMultiple'} params={id}>
                    <ButtonAdd>{'Add multiple RTMP origin stream names'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

RtmpOriginStreamNamesIndexButtons.propTypes = {
    id: PropTypes.string.isRequired,
};

export default RtmpOriginStreamNamesIndexButtons;
