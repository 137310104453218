import React from 'react';
import {
    has as _has,
    get as _get,
    sortBy as _sortBy,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {IconInfoCircle} from "@appComponents/IconCollection";
import {getCountryTreeOptions} from '@modules/events/utils/eventGeoRestriction';

class CountriesAndSubdivisionsForm extends DefaultForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            dataReceived: false,
            selectedSubdivisions: [],
        };
    }

    getSubdivisionTreeOptions(data) {
        const countryOptions = [];

        data.countries.forEach((country) => {
            let hidden = true;
            const subdivisionOptions = [];

            if (-1 < data.selectedCountries.indexOf(country.value)) {
                hidden = false;

                country.children.forEach((child) => {
                    subdivisionOptions.push({
                        ...child,
                        selected: -1 < data.selectedSubdivisions.indexOf(child.value),
                    });
                });

                countryOptions.push({
                    ...country,
                    hidden: hidden,
                    children: _sortBy(subdivisionOptions, 'name'),
                });
            }
        });

        return countryOptions;
    }

    renderLabelWithIcon = (labelText, type) => (
        <label>
            <span>{labelText}</span> &nbsp;
            <IconInfoCircle tooltip={`There are some postal codes of the Blackout Zone which belong
                                    to this ${type}. If you want to select this ${type}, they need to
                                    be removed first.`} />
        </label>
    )

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const continents = _get(nextProps, 'GraphQLOptionsData.continents', false),
            countries = _get(nextProps, 'GraphQLOptionsData.countries', false),
            postalCodes = _get(nextProps, 'GraphQLEntityData.postalCodes', []),
            selectedCountries = _get(nextProps, 'GraphQLEntityData.blackoutZone.countries', false),
            selectedSubdivisions = _get(nextProps, 'GraphQLEntityData.blackoutZone.country_subdivisions', false);

        this.setState({
            selectedSubdivisions,
        });

        if (
            continents
            && selectedCountries
            && !this.state.dataReceived
        ) {
            const restrictedPostalCodes = {country: {}, country_subdivision: {}};

            postalCodes.forEach((postalCode) => {
                const key = _get(postalCode, 'country.handle_geo_restrictions_on_subdivision_level', false)
                        ? 'country_subdivision'
                        : 'country',
                    id = _get(postalCode, `${key}.id`, null);

                if (id) {
                    restrictedPostalCodes[key][id] = true;
                }
            });

            this.setState(() => ({
                restrictedPostalCodes,
            }));

            const selectedCountriesOptions = getCountryTreeOptions({
                continents,
                selectedCountries: selectedCountries.map((country) => country.id),
            }, (data) => {
                const dataToReturn = data.item;

                if (!data.country.handle_geo_restrictions_on_subdivision_level
                    && restrictedPostalCodes.country[dataToReturn.id]
                ) {
                    dataToReturn.disabled = true;
                    dataToReturn.label = this.renderLabelWithIcon(dataToReturn.name, 'country');
                }

                return dataToReturn;
            });

            this.setField('countries', {
                options: selectedCountriesOptions,
            });

            const subdivisionsForForm = [];

            countries.forEach((country) => {
                const children = country.children.map((subdivision) => {

                    const newSubdivision = {...subdivision};

                    if (restrictedPostalCodes.country_subdivision[subdivision.id]) {
                        newSubdivision.disabled = true;
                        newSubdivision.label = this.renderLabelWithIcon(subdivision.name, 'subdivision');
                    }

                    return newSubdivision;
                });

                subdivisionsForForm.push({...country, children});
            });

            const subdivisions = this.getSubdivisionTreeOptions({
                countries: subdivisionsForForm,
                selectedCountries: selectedCountries.map((country) => country.id),
                selectedSubdivisions: selectedSubdivisions.map((subdivision) => subdivision.id),
            });

            this.setField('country_subdivisions', {
                options: subdivisions,
                hidden: 0 === subdivisions.length,
            });

            this.setState({
                dataReceived: true,
            });
        }
    }

    prepareDataForSubmit(data) {
        const continents = _get(data, 'countries', []),
            countrySubdivisions = _get(data, 'country_subdivisions', []),
            countriesToSave = continents.flatMap(ct => ct.children.flatMap(c => c.id)),
            subdivisionsToSave = countrySubdivisions.flatMap(cs => {
                if (-1 === countriesToSave.indexOf(cs.value)) {
                    return [];
                }

                return cs.children.flatMap(s => s.id);
            });

        return {
            id: this.props.formParams.id,
            countries: countriesToSave,
            country_subdivisions: subdivisionsToSave,
        };
    }

    componentDidMount() {
        this.setField('countries', {
            onChangeCallback: (event, selectedOptions) => {
                const countryOptions = [],
                    selectedCountries = [],
                    selectedSubdivisions = [];
                let generalHidden = true;

                selectedOptions.forEach((option) => {
                    const country = option.split('.');

                    generalHidden = false;

                    if (country[1]) {
                        selectedCountries.push(country[1].split('-')[1]);
                    }
                });

                const countrySubdivisions = _get(this.props, 'formValues.country_subdivisions', []);

                countrySubdivisions.forEach((country) => {
                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        country.children.forEach((subdivision) => {
                            selectedSubdivisions.push(subdivision.value);
                        });
                    }
                });

                this.props.GraphQLOptionsData.countries.forEach((country) => {
                    let hidden = true;
                    const subdivisions = [];

                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        hidden = false;
                    }

                    country.children.forEach((subdivision) => {
                        let additionalsProps = {};

                        if (_has(this.state.restrictedPostalCodes, `country_subdivision.${subdivision.id}`)) {
                            additionalsProps ={
                                disabled: true,
                                label: this.renderLabelWithIcon(subdivision.name, 'subdivision'),
                            };
                        }

                        subdivisions.push({
                            ...subdivision,
                            selected: -1 < selectedSubdivisions.indexOf(subdivision.value.replace(/-/g, '')),
                            ...additionalsProps,
                        });
                    });

                    if (!hidden) {
                        countryOptions.push({
                            ...country,
                            children: _sortBy(subdivisions, 'name'),
                            hidden: hidden,
                        });
                    }
                });

                this.setField('country_subdivisions', {
                    hidden: generalHidden,
                    options: countryOptions,
                });
            },
        });
    }

    renderSaveButton = (props) => {
        return super.renderSaveButton({
            ...props,
            content: 'Save',
        });
    };

    renderDeleteButton = () => null;
    renderCancelButton = () => null;
}

export default CountriesAndSubdivisionsForm;
