import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from "lodash";

import LinkingEventWithMatchOpenPopup from "../../../../../views/EventsTabsLayout/LinkingEventWithMatch/LinkingEventWithMatchOpenPopup";

import {isSportradarEventAndNoMaster} from "../../../../../utils/eventType";
import {convertToInt} from "../../../../../../../utils/helpers";
import {CopyToClipboardRenderer} from "../../../../../../app/components/CopyToClipboardRenderer";

/**
 * Display:
 * - event ID
 * - event + linking option
 * - information about master match ID
 *
 * for ID field
 */
export const EventId = ({event}) => {
    let eventId = event.id;

    /**
     * Sportradar Event
     * No Master
     * Without match id
     * allow us to link to match ID
     */
    if (isSportradarEventAndNoMaster(event) && !_get(event, 'sr_event.match.id', false)) {
        return (
            <React.Fragment>
                <CopyToClipboardRenderer text={eventId} value={eventId}/>
                &nbsp; - &nbsp;
                <LinkingEventWithMatchOpenPopup eventId={convertToInt(eventId)}>
                    Link to match ID
                </LinkingEventWithMatchOpenPopup>
            </React.Fragment>
        );
    /**
     * Sportradar event
     * Master event
     */
    } else if (event.sr_event && event.is_master) {
        return (
            <React.Fragment>
                <CopyToClipboardRenderer text={eventId} value={eventId}/>
                {`(master match ID ${_get(event, 'sr_event.master_match.id', '')})`}
            </React.Fragment>
        );
    }

    return <CopyToClipboardRenderer text={eventId} value={eventId}/>;
};

EventId.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string
            }),
            master_match: PropTypes.shape({
                id: PropTypes.string
            })
        }),
        is_master: PropTypes.bool
    }).isRequired
};

EventId.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null
            },
            master_match: {
                id: null
            }
        },
        is_master: false
    }
};
