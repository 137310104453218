import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';

import {gql, graphql} from 'react-apollo';
import * as eventContentMultiAudioGraphQl from '@graphql/events/eventMultiAudio';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import MessageBox from '@appComponents/MessageBox';

import EventContentMultiAudioTable from '../components/EventContentMultiAudioTable';

const EventContentMultiAudioModal = (props) => {
    return(
        <div className='eventMultiAudiosContainer editableTable'>
            <MessageBox name='eventAudioMessage'  className='--multiAudioModal'/>
            <EventContentMultiAudioTable
                properties={props}
            />
        </div>
    );
};

const ListQuery = gql(eventContentMultiAudioGraphQl.eventContentMultiAudios);
const CreateMutation = gql(eventContentMultiAudioGraphQl.CreateMutation);
const DeleteMutation = gql(eventContentMultiAudioGraphQl.DeleteMutation);
const UpdateMutation = gql(eventContentMultiAudioGraphQl.UpdateMutation);

EventContentMultiAudioModal.propTypes = {
    Modal: PropTypes.object,
    MessageBox: PropTypes.object,
    DataEventContentMultiAudio: PropTypes.object,
    Form: PropTypes.object,
    encodingTargetId: PropTypes.number.isRequired,
    audioToSave: PropTypes.object,
    audioToUpdate: PropTypes.object,
    CreateMutation: PropTypes.func,
    DeleteMutation: PropTypes.func,
    UpdateMutation: PropTypes.func,
};
EventContentMultiAudioModal.defaultProps = {
    audios: [],
    streamLanguages: [],
    encodingTargetsSr: [],
    loading: false,
};

const EventContentMultiAudioModalWithQuery = compose(
    graphql(ListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    encodingTargetId: convertToInt(props.encodingTargetId),
                },
            };
        },
        name: 'DataEventContentMultiAudio',
    }),

    graphql(CreateMutation, { name: 'CreateMutation' }),
    graphql(DeleteMutation, { name: 'DeleteMutation' }),
    graphql(UpdateMutation, { name: 'UpdateMutation' })
)(EventContentMultiAudioModal);

const mapStateToProps = (state) => {
    let audioToSave = null;
    let audioToUpdate = null;

    if (state.form.form_ema_update !== undefined) {
        audioToUpdate = (state.form.form_ema_update.values);
    }

    if (state.form.form_ema_add !== undefined) {
        audioToSave = (state.form.form_ema_add.values);
    }

    return {
        audioToSave: audioToSave,
        audioToUpdate: audioToUpdate,
    };
};

export default withRouter(
    connect(
        mapStateToProps, mapModulesToProps(['Modal', 'MessageBox', 'Form'])
    )(EventContentMultiAudioModalWithQuery)
);
