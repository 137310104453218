import PropTypes from 'prop-types';

import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import { USER,USER_ACCESS_INFORMATION } from '@constants/resources';
import { hasPrivileges } from '@appComponents/Authorization';
import { SECURITY_PRIVILEGES_CREATE } from '@constants/variables';
import { CreateUserFromExistingIamAccount, CreateUserFromExistingIamAccountWithUserAccessInfo } from '@graphql/user/mutation';
import { GetRolesForCheckboxTree } from '@graphql/role/query';

import validatorAddUserFromExistingIamAccount from './helpers/validatorAddUserFromExistingIamAccount';
import { getUserFieldsCommonProps, getUserAccessInformationCommonProps } from './helpers/userFields';
import {MESSAGE_BOX_USERS_AFTER_ADD_NAME, MESSAGE_BOX_USERS_AFTER_ADD_ERROR_NAME} from '../utils/variables';

export default function AddUserFromExistingIamAccountModel({settingsIamUser = {}}) {
    const userCreatePrivilege = {
        [USER_ACCESS_INFORMATION] : SECURITY_PRIVILEGES_CREATE,
    };

    const userAccessInformationFieldSettings = {};

    if (!hasPrivileges(userCreatePrivilege)) {
        Object.assign(userAccessInformationFieldSettings, {
            hidden: true,
        });
    }

    return {
        entityDataMapKey: 'iam_user',
        entityLabel: 'user',
        formName: 'user_existing_iam_account',
        label: 'Add new user',
        resources: USER,
        validate: validatorAddUserFromExistingIamAccount,
        title: null,
        dataMap: {
            iam_user: {
                email: '',
                has_ava_admin_access: false,
                access_request_reference: '',
                job_description: '',
                manager: '',
                additional_info: '',
                roles: [],
            },
        },
        messages: {
            boxName: {
                error: MESSAGE_BOX_USERS_AFTER_ADD_ERROR_NAME,
                success: MESSAGE_BOX_USERS_AFTER_ADD_NAME,
            },
            text: {
                ...MESSAGES,
                NOT_SAVED: (label, email) => `The user "${email}" could not be saved. The request to the Common-IAM API endpoint failed.`,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('user'),
            },
        },
        fields: {
            name: {
                component: Form.IdField,
                props: {
                    ...getUserFieldsCommonProps('name'),
                    defaultValue: `${settingsIamUser.firstName} ${settingsIamUser.surname}`,
                },
            },
            iam_first_name_text: {
                props: {
                    ...getUserFieldsCommonProps('iam_first_name_text'),
                    defaultValue: settingsIamUser.firstName,
                },
            },
            iam_surname_text: {
                props: {
                    ...getUserFieldsCommonProps('iam_surname_text'),
                    defaultValue: settingsIamUser.surname,
                },
            },
            iam_nickname_text: {
                props: {
                    ...getUserFieldsCommonProps('iam_nickname_text'),
                    defaultValue: settingsIamUser.nickname,
                },
            },
            email: {
                component: Form.IdField,
                props: {
                    ...getUserFieldsCommonProps('email'),
                    defaultValue: settingsIamUser.email,
                },
            },
            iam_email_text: {
                props: {
                    ...getUserFieldsCommonProps('iam_email_text'),
                    defaultValue: settingsIamUser.email,
                },
            },
            roles: {
                optionsKey: 'roles',
                props: {
                    ...getUserFieldsCommonProps('roles'),
                },
            },
            has_ava_admin_access: {
                props: {
                    ...getUserFieldsCommonProps('has_ava_admin_access'),
                    value: settingsIamUser.has_ava_admin_access,
                },
            },
            access_request_reference: {
                props: {
                    ...getUserAccessInformationCommonProps('access_request_reference', userAccessInformationFieldSettings),
                },
            },
            job_description: {
                props: {
                    ...getUserAccessInformationCommonProps('job_description', userAccessInformationFieldSettings),
                },
            },
            manager: {
                props: {
                    ...getUserAccessInformationCommonProps('manager', userAccessInformationFieldSettings),
                },
            },
            additional_information: {
                props: {
                    ...getUserAccessInformationCommonProps('additional_info', userAccessInformationFieldSettings),
                },
            },
        },
        mutation: {
            createEntity: hasPrivileges(userCreatePrivilege) ? CreateUserFromExistingIamAccountWithUserAccessInfo : CreateUserFromExistingIamAccount,
        },
        mutationOptions: {},
        query: {
            getEntity: null,
            getOptions: GetRolesForCheckboxTree,
        },
    };
}

AddUserFromExistingIamAccountModel.propTypes = {
    settingsIamUser: PropTypes.shape({
        firstName: PropTypes.string,
        surname: PropTypes.string,
        nickname: PropTypes.string,
        email: PropTypes.string,
        roles: PropTypes.object,
        has_ava_admin_access: PropTypes.bool,
    }).isRequired,
};
