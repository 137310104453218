import PropTypes from 'prop-types';
import React from 'react';
import {Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';

import {createForm} from '@utils/forms';

import ProductQbGeoRestrictionsForm from '../forms/ProductQbGeoRestrictionsForm';
import ProductQbGeoRestrictionsModel from '../forms/ProductQbGeoRestrictionsModel';

export class ProductQbGeoRestrictionsEdit extends React.PureComponent {
    static propTypes = {
        clientProductId: PropTypes.number.isRequired,
    };

    renderForm = () => {
        const Form = createForm(
            ProductQbGeoRestrictionsModel,
            ProductQbGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };

    render() {
        return (
            <div className='client_products_geo_restrictions'>
                <Segment basic>
                    {this.renderForm()}
                </Segment>
            </div>
        );
    }
}

export default withRouter(ProductQbGeoRestrictionsEdit);
