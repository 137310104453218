import PropTypes from "prop-types";

export const getProductUsages = (rightsScopes) => {
    let products = [];

    rightsScopes.forEach((rightsScope) => {
        rightsScope.usages.forEach((usage) => {
            let productIndex = products.findIndex((product) => (product.id === usage.product.id));

            if (-1 !== productIndex) {
                let deviceCategoryIndex = products[productIndex].device_categories.findIndex((deviceCategory) => (
                    deviceCategory.id === usage.device_category.id
                ));

                if (-1 !== deviceCategoryIndex) {
                    products[productIndex].device_categories[deviceCategoryIndex].ways_of_transmission.push({
                        id: usage.way_of_transmission.id,
                        name: usage.way_of_transmission.name,
                    });
                } else {
                    products[productIndex].device_categories.push({
                        id: usage.device_category.id,
                        name: usage.device_category.name,
                        ways_of_transmission: [{
                            id: usage.way_of_transmission.id,
                            name: usage.way_of_transmission.name,
                        }],
                    });
                }
            } else {
                products.push({
                    id: usage.product.id,
                    short_name: usage.product.short_name,
                    device_categories: [{
                        id: usage.device_category.id,
                        name: usage.device_category.name,
                        ways_of_transmission: [{
                            id: usage.way_of_transmission.id,
                            name: usage.way_of_transmission.name,
                        }],
                    }],
                });
            }
        });
    });

    return products;
};

getProductUsages.PropTypes = {
    rightsScopes: PropTypes.arrayOf(PropTypes.shape({
        usages: PropTypes.arrayOf(PropTypes.shape({
            device_category: {
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            },
            product: {
                id: PropTypes.string.isRequired,
                short_name: PropTypes.string.isRequired,
            },
            way_of_transmission: {
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            },
        })),
    })),
};
