import React from "react";
import {Image} from "semantic-ui-react";

import packageJson from "../../../../package"

const Footer = () => (
    <div className="page-footer">
        <div>© {new Date().getFullYear()} Sportradar</div>
        <Image
            as='a'
            href='https://www.sportradar.com/'
            src={'/images/sportradar-logo.png'}
        />
        <div>
            AV-CMP {packageJson.version}
            <span className="--paddingLeft-0-5rem --paddingRight-0-5rem">-</span>
            <a href={'https://sportradar.slack.com/messages/CBATLMHPB'} rel="noopener noreferrer" target={'_blank'}>Report a bug</a>
        </div>
    </div>
);

export default Footer;
