import validators from "../../../utils/validators";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.cdn_configurations, 'cdn_configurations'));

    return errors;
};

export default validate;
