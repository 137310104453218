import {get as _get} from 'lodash';

import {getParsedId} from '@utils/helpers';
import {getResolution} from '@utils/stream';

import {getEventContentNameWithTypeName} from '@utils/eventContentVariant/eventContentVariant';

const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => getParsedId(row),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'target_id': {
        label: 'Encoding target',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
        sorting: row => getParsedId(row.target),
    },
    'content': {
        label: 'Content',
        sorting: row => getEventContentNameWithTypeName(row.target.distribution.event_content),
    },
    'product': {
        label: 'Product',
        sorting: row => _get(row, 'target.distribution.product.short_name', null),
    },
    'distribution_type': {
        label: 'Distribution',
        subLabel: 'type',
        sorting: row => row.target.distribution.distribution_type.name,
    },
    'stream_delay_type': {
        label: 'Stream delay',
        subLabel: 'type',
        sorting: row => _get(row, 'target.stream_delay_type.name', null),
    },
    'device_category': {
        label: 'Device',
        subLabel: 'category',
        sorting: true,
    },
    'format': {
        label: 'Format',
        sorting: row => row.format.name,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'protocol': {
        label: 'Protocol',
        sorting: true,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'bitrate_video': {
        label: 'Video bitrate',
        sorting: row => row.bitrate_video,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'right',
        },
    },
    'bitrate_audio': {
        label: 'Audio bitrate',
        sorting: row => row.bitrate_audio,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'right',
        },
    },
    'frame_rate': {
        label: 'Frame rate',
        sorting: row => row.frame_rate,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {align: 'right'},
    },
    'resolution': {
        label: 'Resolution',
        sorting: row => getResolution(row),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {align: 'right'},
    },
    'duration': {
        label: 'Duration',
        sorting: row => _get(row, 'duration',''),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {align: 'right'},
    },
    'stream_name': {
        label: 'Stream name',
        sorting: true,
    },
    'akamai_cp_code': {
        label: 'Akamai CP code',
        sorting: row => row.akamai_cp_code,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'has_playback_authentication': {
        label: 'Auth?',
        sorting: true,
        header: {align: 'center'},
        content: {align: 'center'},
    },
    'stream_status': {
        label: 'Status',
        header: {
            align: 'left',
            isCollapsed: true,
        },
        sorting: true,
        content: {
            align: 'left',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

export default columns;
