import React from 'react';
import {withRouter} from "react-router";
import {Segment} from "semantic-ui-react";
import PropTypes from "prop-types";

import PropertyLicenceAttachments from './PropertyLicenceAttachments';
import PropertyLicenceLinks from './PropertyLicenceLinks';
import MessageBox from "../../app/components/MessageBox";

const PropertyLicenceLinksAndAttachments = (props) => {
    const propertyLicenceId = parseInt(props.match.params.id),
        messageBoxName = "propertyLicenceLinksAndAttachmentsMessage";

    return (
        <div>
            <MessageBox name={messageBoxName}/>
            <Segment basic className="--table">
                <PropertyLicenceLinks propertyLicenceId={propertyLicenceId} messageBoxName={messageBoxName}/>
            </Segment>
            <Segment basic className="--table">
                <PropertyLicenceAttachments propertyLicenceId={propertyLicenceId} messageBoxName={messageBoxName}/>
            </Segment>
        </div>
    );
};

PropertyLicenceLinksAndAttachments.propTypes = {
    match: PropTypes.object,
};

export default withRouter(PropertyLicenceLinksAndAttachments);
