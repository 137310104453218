import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from '@apollo/client/react/hoc';

import Table from '@appComponents/Table';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {
    clientBookingRowRenderer,
    getBasicClientBookingsColumnsForBookingsTable,
} from '@modules/client/utils/clientBookingTable';
import {columnObjectAlignCenter} from '@utils/tableHelpers';

export const columns = {
    ...getBasicClientBookingsColumnsForBookingsTable('offering'),
    'is_hq': {
        label: 'HQ',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'price': {
        label: 'Price',
        sorting: row => (null !== row.price)
            ? row.price
            : null,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        },
    },
    'booking_status': {
        label: 'Booking status',
        sorting: row => row.booking_status,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'offering_creation_datetime': {
        label: 'Time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
};

const ClientOfferingsTable = (props) => {
    const {data, clientOfferings, loading} = props;

    return (
        <div className='clientOfferings__table'>
            <Table
                adjustHeight={props.adjustHeight}
                className='mini'
                columns={columns}
                data={clientOfferings}
                defaultSort={'start_datetime'}
                showLimitWarning={true}
                dataLimitWarning={10000}
                loading={loading}
                name='clientOfferingsList'
                noDataText='No offerings found'
                padded={false}
                rowRenderer={clientBookingRowRenderer}
                rowRendererProps={{mappedIsoCodes: data.mappedIsoCodes, type: 'offering', clientId: props.clientId}}
            />
        </div>
    );
};

ClientOfferingsTable.propTypes = {
    adjustHeight: PropTypes.func.isRequired,
    clientOfferings: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    loading: PropTypes.bool.isRequired,
    refProperty: PropTypes.func.isRequired,
};
ClientOfferingsTable.defaultProps = {
    clientOfferings: [],
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
};
ClientOfferingsTable.displayName = 'ClientOfferingsTable';

export default graphql(GetMappedIsoCodes)(ClientOfferingsTable);
