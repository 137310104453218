import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 50}, 'name'));
    Object.assign(errors, validators.required(values.products, 'products'));
    Object.assign(errors, validators.required(values.sports, 'sports'));
    Object.assign(errors, validators.required(values.eventContentTypes, 'eventContentTypes'));

    return errors;
};

export default validate;
