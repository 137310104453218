import DefaultForm from '@appComponents/DefaultForm';
import {refetchQueryByName} from '@utils/apollo';

export default class ClientBookingsBulkChangeForm extends DefaultForm {
    componentWillMount() {
        super.componentWillMount();

        this.setOptionParsers({
            invoice_status: (data) => [
                {key: 'inherited', value: 'inherited', text: 'Inherited from event report'},
                ...data.map(is => ({key: is.id, value: is.id, text: is.name})),
            ],
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName(this.props.formParams.queryForRefetch);
        });
    }

    componentDidMount() {
        this.setOnChangeCallback({
            price_edit_options: (data) => {
                const showPrice = 'modify_price' === data.value;

                this.setField('price', {
                    hidden: !showPrice,
                    defaultValue: !showPrice ? null : '',
                });

                this.setField('separator', {
                    hidden: !showPrice,
                });
            },
            client_package: (data) => {
                if (!data.value) {
                    this.setField('price_edit_options', {
                        defaultValue: 'keep_price',
                    });

                    if (!this.props.Model.fields.inline[1].price.props.hidden || !this.props.Model.fields.inline[2].separator.props.hidden) {
                        this.setField('price', {
                            hidden: true,
                            defaultValue: null,
                        });

                        this.setField('separator', {
                            hidden: true,
                        });
                    }
                }
            },
        });
    }

    componentDidUpdate(prevProps) {
        const { client_package } = this.props.formValues;

        if (client_package !== prevProps.formValues.client_package) {
            this.setField('price_edit_options', {
                props: {
                    options: [
                        {text: 'Keep the existing price', value: 'keep_price'},
                        {text: 'Modify the price', value: 'modify_price'},
                        ...(client_package ? [{text: 'Update based on content settings of selected client package', value: 'update_based_on_client_package'}] : []),
                    ],
                },
            });
        }
    }

    prepareDataForSubmit(data) {
        const dataForSubmit = super.prepareDataForSubmit(data).ClientBookingsBulkChange;

        return {
            ...dataForSubmit,
            client: this.props.formParams.client,
            is_common_product: this.props.formParams.isCommonProduct,
            price: null === dataForSubmit.price ? null : parseFloat(dataForSubmit.price),
            invoice_status: 'inherited' === dataForSubmit.invoice_status ? null : dataForSubmit.invoice_status,
            booking_details: this.props.formParams.bookingDetails,
            keep_existing_price: 'keep_price' === data.price_edit_options,
            update_based_on_client_package: dataForSubmit.client_package ? 'update_based_on_client_package' === data.price_edit_options : false,
        };
    }
}
