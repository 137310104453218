import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

export const exportExcel = (dataExport) => (
    {
        dataExport: dataExport,
        filename: 'countries',
        parseData: country => [
            country.id,
            country.name,
            country.full_name,
            country.iso_alpha2_code,
            country.iso_alpha3_code,
            country.iso_numeric_code,
            _get(country, 'continent.name', ''),
            country.handle_geo_restrictions_on_subdivision_level ? 1 : 0,
            country.country_subdivisions.length ? country.country_subdivisions.length : '',
        ],
        path: 'countries.index.path',
        titles: [
            'ID',
            'Name',
            'Full name',
            'ISO alpha-2 code',
            'ISO alpha-3 code',
            'ISO numeric code',
            'Continent',
            'Subdivision support',
            '#Subdivisions',
        ],
    }
);

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
