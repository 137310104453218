import {BasicData} from '@graphql/fragments';

export const eventContentCreateBulkDistribtionMutation = `
mutation createBulkDistribution(
  $event_content: Int!,
  $distributions: [DistributionInput]
  $generate_targets: Boolean
) {
  createBulkDistribution(
    event_content: $event_content,
    distributions: $distributions,
    generate_targets:$generate_targets
  ) {
    message
    status
    broken_id
    href
    broken_entity
    type
  }
}
`;

export const eventContentUpdateBulkDistribtionMutation = `
mutation updateBulkDistribution(
  $event_content: Int!
  $distributions: [DistributionInput]
  $generate_targets: Boolean
) {
  updateBulkDistribution(
    event_content: $event_content
    distributions: $distributions
    generate_targets: $generate_targets
  ) {
    message
    status
    broken_id
    href
    broken_entity
    type
  }
}
`;

export const eventContentCreateMutation  = `
mutation createEventContent(
  $event: Int!,
  $event_content_type: Int!, 
  $event_content_variant: ID!, 
  $name: String, 
  $start_datetime: DatetimeTzInputType, 
  $end_datetime: DatetimeTzInputType, 
  $synchronise_times: Boolean!, 
  $video_length: Int, 
  $video_duration: Int, 
  $is_247_stream: Boolean!,
  $is_main_content: Boolean!,
  $has_input: Boolean!,
  $event_content_input: EventContentInputInput,
  $start_broadcast_automatically: Boolean!
  $set_as_delivered_automatically: Boolean!
) {
    createEventContent(
      event: $event,
      event_content_type: $event_content_type, 
      event_content_variant: $event_content_variant, 
      name: $name, 
      start_datetime: $start_datetime, 
      end_datetime: $end_datetime, 
      synchronise_times: $synchronise_times, 
      video_length: $video_length, 
      video_duration: $video_duration, 
      is_247_stream: $is_247_stream,
      is_main_content: $is_main_content,
      has_input: $has_input,
      event_content_input: $event_content_input,
      start_broadcast_automatically: $start_broadcast_automatically
      set_as_delivered_automatically: $set_as_delivered_automatically
    ) {
      id
      name
      event_content_type {
        id
        name
      }
      start_datetime
      end_datetime
      video_length
      video_duration
      start_broadcast_automatically
      set_as_delivered_automatically
      is_247_stream
      is_main_content
      has_input
      synchronise_times
      distributions {
        id
      }
      event_content_input {
        contribution_type {
          id
          name
        }
        encoding_datacenter {
          id
          name
        }
        encoding_redundancy_type {
          ${BasicData}
        }
        production_company {
          id
          name
        }
        contact_person {
          id
          name
        }
        origin_stream_type {
          id
          name
        }
        rtmp_origin_stream_name {
          id
          name
        }
        rtmp_origin_entry_point_application {
          id
          name
          rtmp_origin_entry_point {
            id
            name
          }
        }
        udp_origin_entry_point {
          id
          name
        }
        sdi_origin_entry_point {
          id
          name
        }
        http_origin_entry_point_url
        mediaconnect_origin_feeds
        srt_origin_entry_point_url
        failover_srt_origin_entry_point_url
        is_srt_origin_entry_point_in_failover
        srt_has_scte35
        srt_ad_splice_config {
          ${BasicData}
        }
      }
  }
}
`;
export const eventContentUpdateMutation  = `
mutation updateEventContent(
  $event_content_type: Int!,
  $event_content_variant: ID!, 
  $name: String, 
  $start_datetime: DatetimeTzInputType, 
  $end_datetime: DatetimeTzInputType, 
  $synchronise_times: Boolean!, 
  $video_length: Int, 
  $video_duration: Int, 
  $is_247_stream: Boolean!,
  $is_main_content: Boolean!,
  $hide_in_live_control: Boolean!,
  $has_input: Boolean!,
  $event_content_input: EventContentInputInput,
  $id:ID!
  $start_broadcast_automatically: Boolean!
  $set_as_delivered_automatically: Boolean!
) {
    updateEventContent(
      event_content_type: $event_content_type, 
      event_content_variant: $event_content_variant, 
      name: $name, 
      start_datetime: $start_datetime, 
      end_datetime: $end_datetime, 
      synchronise_times: $synchronise_times, 
      video_length: $video_length, 
      video_duration: $video_duration, 
      is_247_stream: $is_247_stream,
      is_main_content: $is_main_content,
      hide_in_live_control: $hide_in_live_control,
      has_input: $has_input,
      event_content_input: $event_content_input,
      start_broadcast_automatically: $start_broadcast_automatically,
      set_as_delivered_automatically: $set_as_delivered_automatically,
      id:$id
    ) {
      id
      name
      event_content_type {
        id
        name
      }
      start_datetime
      end_datetime
      video_length
      start_broadcast_automatically
      set_as_delivered_automatically
      is_247_stream
      is_main_content
      hide_in_live_control
      has_input
      synchronise_times
      event_content_input {
        contribution_type {
          id
          name
        }
        encoding_datacenter {
          id
          name
        }
        encoding_redundancy_type {
          ${BasicData}
        }
        production_company {
          id
          name
        }
        contact_person {
          id
          name
        }
        origin_stream_type {
          id
          name
        }
        rtmp_origin_stream_name {
          id
          name
        }
        rtmp_origin_entry_point_application {
          id
          name
          rtmp_origin_entry_point {
            id
            name
          }
        }
        udp_origin_entry_point {
          id
          name
        }
        sdi_origin_entry_point {
          id
          name
        }
        http_origin_entry_point_url
        mediaconnect_origin_feeds
        srt_origin_entry_point_url
        failover_srt_origin_entry_point_url
        is_srt_origin_entry_point_in_failover
        srt_has_scte35
        srt_ad_splice_config {
          ${BasicData}
        }
      }
      creation_user {
        id
        name
      }
      creation_datetime
      update_user {
        id
        name
      }
      update_datetime
  }
}
`;
export const eventContentDeleteMutation  = `
mutation deleteEventContent($id: ID) {
  deleteEventContent(id: $id)
}
`;
export const optionsOriginEntryPoints = `
query optionsUdpEntryPointsQuery(
  $encodingDatacentersForUpd: [ID],
  $encodingDatacentersForSdi: [ID]
) {
  udpOriginEntryPoints(filters: {encodingDatacenter: $encodingDatacentersForUpd}) {
    key: id
    value: id
    text: name
    url
    content_categories {id}
    tournaments {id name}
  }
  sdiOriginEntryPoints(filters: {encodingDatacenter: $encodingDatacentersForSdi}) {
    key: id
    value: id
    text: name
  }
}
`;
export const optionsContactPersonsAndOriginStreamName = `
query optionsContactPersonsAndOriginStreamNameQuery($productionCompany: ID) {
  contactPersons(filters: {productionCompany: $productionCompany}) {
    key: id
    value: id
    text: name
  }
  rtmpOriginStreamNames(filters:{productionCompany:$productionCompany, isArchived: false}) {
    key: id
    value: id
    text: name
  }
}
`;
export const rightsScopesOfPropertyLicence = `
query RightsScopesOfPropertyLicenceQuery($propertyLicence: ID) {
  rightsScopes(filters: {propertyLicence: $propertyLicence}) {
    id
    usages {
      id
      product {
        id
        name
      }
      way_of_transmission {
        id
        name
      }
    }
    rights_scope_event_content_variants {
      event_content_variant {
        id
        key: id
        value: id
        text: name
      }
      product {
        id
      }
    }
    contents {
      id
      tournament {
        id
      }
      content_category {
        id
      }
    }
    includes_all_content
    start_date
    end_date
  }
  propertyLicenceTechSetups(filters: {propertyLicence: $propertyLicence}) {
    id
    contribution_type {
      id
    }
    encoding_datacenter {
      id
    }
    encoding_redundancy_type {
      id
    }
    origin_stream_type {
      id
    }
    is_tv_streaming
  }
}`;
export const propertyLicenceTechSetupProducts = `
query PropertyLicenceTechSetupProductsQuery($propertyLicenceTechSetup: Int) {
  propertyLicenceTechSetupProducts(filters: {propertyLicenceTechSetup: $propertyLicenceTechSetup}) {
    product {
      id
      name
    }
    product_status {
      id
      name
    }
    property_licence_tech_setup {
      contribution_type {id}
      encoding_datacenter {id}
      origin_stream_type {id}
    }
  }
}
`;
