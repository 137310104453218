import * as MESSAGES from '@constants/messages';
import {BLACKLISTED_IP} from '@constants/resources';
import {createFieldsByType} from '@modules/client/utils/whitelistingHelper';
import {CreateBlacklistedIp, UpdateBlacklistedIp} from '@graphql/blacklistedIp/mutation';
import {GetBlacklistedIpForForm, GetOptionsDataForForm} from '@graphql/blacklistedIp/query';

import validate from './validatorIpBlacklisting';

export default function IpBlacklistingModel() {
    const fields = createFieldsByType('blacklisted');
    delete fields.products;

    return {
        entityDataMapKey: 'blacklistedIp',
        entityLabel: 'CIDR',
        formName: 'blacklistedIp',
        label: 'Blacklisted IP',
        resources: BLACKLISTED_IP,
        showChangeLog: true,
        validate: validate,
        title: () => 'Add new CIDR',
        dataMap: {
            blacklistedIp: {
                id: '',
                ip_version: {id: ''},
                cidr: '',
                description: '',
            },
        },
        messages: {
            boxName: {
                error: 'formInnerErrorMessage',
                success: 'ipBlacklistingIndex',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR(MESSAGES.CIDR),
            },
        },
        fields: {
            ...fields,
        },
        mutation: {
            createEntity: CreateBlacklistedIp,
            updateEntity: UpdateBlacklistedIp,
        },
        query: {
            getEntity: GetBlacklistedIpForForm,
            getOptions: GetOptionsDataForForm,
        },
    };
}
