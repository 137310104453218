import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Segment} from 'semantic-ui-react';

import SegmentHeader from '@appComponents/SegmentHeader';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {GetOverlay} from '@graphql/overlay/query';
import {ButtonBackToList} from '@appComponents/ButtonCollection';
import {showModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';

import OverlayModel from '../forms/OverlayModel';
import OverlayForm from '../forms/OverlayForm';

const OverlayEdit = (props) => {
    const backToListButton = () => <ButtonBackToList
        name='propertyLicences.technicalSetup.overlays.index'
        params={{id: convertToInt(props.match.params.id)}}
        text={'Back to overlays list'}
    />;

    if (!isUrlParamValid(props.match.params.overlayId)) {
        showModal({
            isVisible: true,
            content: renderModalError('Overlay',
                getLink(`propertyLicences.technicalSetup.overlays.index`, {
                    id: props.match.params.id,
                })
            ),
        });

    }
    const renderForm = () => {
        let formVariables = {
            optionsVariables: {
                technicalSetupId: props.technicalSetupId,
            },
        };

        if (props.match.path === routes.propertyLicences.technicalSetup.overlays.edit.path) {
            formVariables = {
                id: convertToInt(props.match.params.overlayId),
                ...formVariables,
            };
        }
        const Form = createForm(OverlayModel, OverlayForm, formVariables);

        return <Form/>;
    };

    const getTitleParams = () => {
        if (props.match.path === routes.propertyLicences.technicalSetup.overlays.edit.path) {
            return {id: convertToInt(props.match.params.overlayId)};
        }

        return {};
    };

    return (
        <div>
            <SegmentHeader buttons={backToListButton()}>
                <TitleFromQuery
                    query={GetOverlay}
                    variables={getTitleParams()}
                    errorBackLink={routes.propertyLicences.technicalSetup.overlays.index.path}
                    path={'overlay.name'}
                    entity={'overlay'}
                />
            </SegmentHeader>
            <Segment basic>
                {renderForm()}
            </Segment>
        </div>
    );
};

OverlayEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            overlayId: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    technicalSetupId: PropTypes.number.isRequired,
};

OverlayEdit.defaultProps = {
    match: {
        params: {
            overlayId: null,
        },
    },
};

export default withRouter(OverlayEdit);
