import React from 'react';
import {Button, Icon} from 'semantic-ui-react';

import Link from '@appComponents/Link';

const MediaConnectEditButtons = () => {
    return (
        <div className='content__buttons'>
            <Link name='mediaConnect.index'>
                <Button className='button-back'>
                    <Icon className='arrow circle left'/> Back to list
                </Button>
            </Link>
        </div>
    );
};

export default MediaConnectEditButtons;
