import React from 'react';
import PropTypes from "prop-types";
import {Segment} from 'semantic-ui-react';

import {Authorization} from '@appComponents/Authorization';
import {SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_READ_UPDATE} from '@constants/variables';
import {ENCODING_TARGET} from '@constants/resources';

import EventsConfigurationThirdPartyTable from "../components/EventsConfigurationThirdPartyTable";
import {LinkButtonSettings} from "../../app/components/ButtonCollection";
import SegmentHeader from "../../app/components/SegmentHeader";
import {parseThirdParty} from "../utils/thirdPartyClients";

const authorizationEncodingTargetReadObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ,
};

const EventsConfigurationThirdParty = (props) => {
    const {eventId, eventContentId, loading, thirdParties} = props;

    return (
        <div>
            <Authorization authorization={authorizationEncodingTargetReadObject}>
                <SegmentHeader buttons={
                    LinkButtonSettings({
                        name: "events.configuration.content.thirdPartyClients",
                        params: {id: eventId, contentId: eventContentId},
                        authorizationObject: {[ENCODING_TARGET]: SECURITY_PRIVILEGES_READ_UPDATE},
                    })
                }>
                    Third party clients (push/pull)
                </SegmentHeader>
                <Segment basic className="--table">
                    <EventsConfigurationThirdPartyTable
                        loading={loading}
                        thirdParties={parseThirdParty(eventContentId, thirdParties)}
                    />
                </Segment>
            </Authorization>
        </div>
    );
};

EventsConfigurationThirdParty.propTypes = {
    thirdParties: PropTypes.object.isRequired,
    eventContentId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default EventsConfigurationThirdParty;
