import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';

/* eslint import/no-unresolved: 0 */
import {Header} from 'semantic-ui-react';
import {find as _find, sortBy as _sortBy} from 'lodash';

import {gql, graphql, withApollo} from 'react-apollo';
import {PROPERTY_LICENCE} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';

import Form from '../../app/components/ReduxFormControls';
import * as MESSAGES from '../../app/constants/messages';
import {refetchQueryByName} from '../../../utils/apollo';
import mapModulesToProps from '../../../utils/mapModulesToProps';
import EditableGridTable from '../../app/components/EditableGridTableComponents/EditableGridTable';
import * as propertyLicenceLinksGraphQl from '../../../graphql/propertyLicences/links';
import validate from '../forms/validatorPropertyLicenceLinks';

class PropertyLicenceLinks extends React.Component {
    static propTypes = {
        propertyLicenceId: PropTypes.number.isRequired,
        messageBoxName: PropTypes.string.isRequired,
        Modal: PropTypes.object.isRequired,
        MessageBox: PropTypes.object.isRequired,
        Form: PropTypes.object.isRequired,
        DataPropertyLicenceLinks: PropTypes.object.isRequired,
        PropertyLicenceLinksCreateMutation: PropTypes.func,
        PropertyLicenceLinksDeleteMutation: PropTypes.func,
        PropertyLicenceLinksUpdateMutation: PropTypes.func,
    };

    constructor() {
        super();

        const updateauthorizationObjects = {
            [PROPERTY_LICENCE]: SECURITY_PRIVILEGES_UPDATE,
        };

        this.state = {
            propertyLicenceLinks: undefined,
            authorizationObjects: {
                create: updateauthorizationObjects,
                update: updateauthorizationObjects,
                delete: updateauthorizationObjects,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        const {DataPropertyLicenceLinks: {propertyLicenceLinks}} = nextProps;

        if (propertyLicenceLinks) {
            this.setState(() => ({propertyLicenceLinks: _sortBy(propertyLicenceLinks, 'description')}));
        }
    }

    handlePropertyLicenceLinksAdd = (data) => {
        this.setState(() => ({loading: true}));

        const dataToSave = Object.assign({}, {
            property_licence_id: this.props.propertyLicenceId,
            description: data.description,
            url: data.url,
        });

        const {
            MessageBox: {addMessage},
            Form: {clearForm},
        } = this.props;

        this.props.PropertyLicenceLinksCreateMutation({
            variables: {...dataToSave},
        }).then(() => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.SAVED('property licence link'),
                null,
                'success'
            );
            refetchQueryByName('PropertyLicenceLinks');
            clearForm('PropertyLicenceLinks');

            this.setState(() => ({loading: false}));
        }).catch((error) => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.NOT_SAVED('property licence link'),
                `${error}`,
                'error'
            );

            this.setState(() => ({loading: false}));
        });
    };

    handlePropertyLicenceLinksDelete = (event, data) => {
        event.stopPropagation();

        let entity = _find(this.state.propertyLicenceLinks, {'id': data['data-id']});

        this.props.Modal.setModalConfirmation({
            header: <Header icon='trash' content={MESSAGES.DELETE_HEADER('property licence link')}/>,
            text: MESSAGES.DELETE_CONFIRMATION(entity.description),
            onYes: () => (this.deletePropertyLicenceLink(data['data-id'])),
        });
    };

    handlePropertyLicenceLinksUpdate = (data) => {
        this.setState(() => ({loading: true}));

        this.props.PropertyLicenceLinksUpdateMutation({
            variables: {...data},
        }).then(() => {
            this.props.MessageBox.addMessage(
                this.props.messageBoxName,
                MESSAGES.SAVED('property licence link'),
                null,
                'success'
            );
            refetchQueryByName('PropertyLicenceLinks');
            this.setState(() => ({loading: false}));
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                this.props.messageBoxName,
                MESSAGES.NOT_SAVED('property licence link'),
                `${error}`,
                'error'
            );

            this.setState(() => ({loading: false}));
        });
    };

    deletePropertyLicenceLink(propertyLicenceLinkId) {
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING('property licence link'),
        });

        return this.props.PropertyLicenceLinksDeleteMutation({
            variables: {
                id: propertyLicenceLinkId,
            },
        }).then(() => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.DELETED('property licence link'),
                null,
                'success'
            );
            refetchQueryByName('PropertyLicenceLinks');
            setModal({isVisible: false});
        }).catch((error) => {
            addMessage(
                this.props.messageBoxName,
                MESSAGES.NOT_DELETED('property licence link'),
                `${error}`,
                'error'
            );
            setModal({isVisible: false});
        });
    }

    render() {
        const loading = this.props.DataPropertyLicenceLinks.loading || this.state.loading,
            columns = {
                description: {
                    component: Form.SemanticInput,
                    componentProps: {
                        name: 'description',
                        required: true,
                        placeholder: 'Description',
                        type: 'text',
                    },
                    label: 'Description',
                    width: 6,
                },
                url: {
                    component: Form.SemanticInput,
                    componentProps: {
                        name: 'url',
                        required: true,
                        placeholder: 'URL',
                        type: 'text',
                    },
                    label: 'URL',
                    width: 8,
                },
                actions: {label: '', width: 2},
            };

        return (
            <EditableGridTable
                loading={loading}
                onCreate={this.handlePropertyLicenceLinksAdd}
                onDelete={this.handlePropertyLicenceLinksDelete}
                onUpdate={this.handlePropertyLicenceLinksUpdate}
                rows={this.state.propertyLicenceLinks}
                columns={columns}
                validator={validate}
                headerText='Links'
                tableClassName='propertyLicenceLinks'
                formName='PropertyLicenceLinks'
                authorizationObjects={this.state.authorizationObjects}
            />
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox', 'Form']);

const PropertyLicenceLinksListQuery = gql(propertyLicenceLinksGraphQl.propertyLicenceLinksList);
const PropertyLicenceLinksCreateMutation = gql(propertyLicenceLinksGraphQl.propertyLicenceLinksCreateMutation);
const PropertyLicenceLinksDeleteMutation = gql(propertyLicenceLinksGraphQl.propertyLicenceLinksDeleteMutation);
const PropertyLicenceLinksUpdateMutation = gql(propertyLicenceLinksGraphQl.propertyLicenceLinksUpdateMutation);

const PropertyLicenceLinksWithApollo = withApollo(PropertyLicenceLinks);
const PropertyLicenceLinksWithStore = connect(null, mapDispatchToProps)(PropertyLicenceLinksWithApollo);

export default compose(
    graphql(PropertyLicenceLinksListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    propertyLicenceId: props.propertyLicenceId,
                },
            };
        },
        name: 'DataPropertyLicenceLinks',
    }),
    graphql(PropertyLicenceLinksCreateMutation, {name: 'PropertyLicenceLinksCreateMutation'}),
    graphql(PropertyLicenceLinksDeleteMutation, {name: 'PropertyLicenceLinksDeleteMutation'}),
    graphql(PropertyLicenceLinksUpdateMutation, {name: 'PropertyLicenceLinksUpdateMutation'})
)(PropertyLicenceLinksWithStore);
