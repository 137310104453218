import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {gql} from '@apollo/client';
import {withApollo} from '@apollo/client/react/hoc';
import {
    debounce as _debounce,
    range as _range,
    isEmpty as _isEmpty, find as _find, orderBy,
} from 'lodash';
import {connect} from 'react-redux';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {EVENT_TYPE_SPORTRADAR, EVENT_TYPE_NON_SPORTRADAR} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically, sortByKey} from '@utils/sorters';
import mapModulesToProps from '@utils/mapModulesToProps';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import {contentCategoriesFilteredByLevelAndParents} from '@graphql/content';
import {GetTournamentsForDropdownMultipleCategories} from '@graphql/tournament/query';
import {tournamentCategoriesFilteredBySports} from '@graphql/tournamentCategories';
import {GetStadiumsByTournamentsForDropdown} from '@graphql/stadium/query';
import {GetSportsWithCourt, GetSportsForDropdown} from '@graphql/sport/query';
import {GetContentCategoriesForDropdown} from '@graphql/contentCategory/query';
import {setTvChannelState} from '@modules/events/utils/eventContentHelper';
import {showMessageBox} from '@utils/messageBox';
import {filteredSelectedTournament, filteredSelectedTournamentCategory} from '@modules/events/utils/eventSchedule';

class LiveControlFilters extends React.Component
{
    static propTypes = {
        client: PropTypes.object,
        data: PropTypes.shape({
            broadcastStatuses: PropTypes.array,
            distributionTypes: PropTypes.array,
            encodingDatacenters: PropTypes.array,
            encodingStatuses: PropTypes.array,
            eventTypes: PropTypes.array,
            products: PropTypes.array,
            propertyLicencesForDropdown: PropTypes.array,
            refetchLiveControl: PropTypes.func,
            setPollingInterval: PropTypes.func,
            tvChannels: PropTypes.array,
            eventContentTypes: PropTypes.array,
            eventContentVariants: PropTypes.array,
        }),
        filters: PropTypes.object,
        form: PropTypes.object,
        loading: PropTypes.bool,
        restoreFilterValues: PropTypes.func.isRequired,
    };

    static defaultProps = {
        data: {
            broadcastStatuses: [],
            distributionTypes: [],
            encodingDatacenters: [],
            encodingStatuses: [],
            eventTypes: [],
            propertyLicencesForDropdown: [],
            products: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
    };

    constructor() {
        super();

        this.state = {
            hasSelectedSport: false,
            hasSportsWithTvChannel: false,
            hasSelectedCategoryLev1: false,
            hasSelectedCategoryLev2: false,
            hasSelectedTournamentCategory: false,
            hasSelectedTournaments: false,
            tournamentCategories: [],
            tournaments: [],
            courts: [],
            sports: [],
            fetchedSports: [],
            sportsLoading: false,
            tvChannels: [],
            contentCategoriesSecondLevel: [],
            contentCategoriesThirdLevel: [],
            contentCategoriesFirstLevel: [],
            tvChannelsLoading: false,
            contentCategoriesFirstLevelLoading: false,
            contentCategoriesSecondLevelLoading: false,
            contentCategoriesThirdLevelLoading: false,
            tournamentCategoriesLoading: false,
            tournamentsLoading: false,
            courtsLoading: false,
            init: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let state = {};

        // Restore filters chain from app.filters
        if (this.props.filters
            && this.props.filters.LiveControl
            && this.props.form
            && this.state.init) {
            const filters = this.props.filters.LiveControl;

            // Sportradar type
            let reduxSports = this.props.restoreFilterValues(filters, 'sport');

            if (!_isEmpty(reduxSports)) {
                state.hasSelectedSport = true;

                if (_isEmpty(this.state.tournamentCategories)) {
                    this.getTournamentCategoriesFromGraphQL(reduxSports);

                    let reduxTournamentCategories = this.props.restoreFilterValues(filters, 'tournamentCategory');

                    if (!_isEmpty(reduxTournamentCategories)) {
                        state.hasSelectedTournamentCategories = true;

                        if (_isEmpty(this.state.tournaments)) {
                            this.getTournamentsFromGraphQL(reduxTournamentCategories);

                            let reduxTournaments = this.props.restoreFilterValues(filters, 'tournament');

                            if (!_isEmpty(reduxTournaments)) {
                                state.selectedTournaments = reduxTournaments;
                            }
                        }
                    }
                }
            }

            // Non Sportradar
            let reduxContentCategoriesLvl1 = this.props.restoreFilterValues(filters, 'contentCategoryLvl1');

            if (!_isEmpty(reduxContentCategoriesLvl1)) {
                state.hasSelectedCategoryLev1 = true;

                if (_isEmpty(this.state.contentCategoriesSecondLevel)) {
                    this.getSecondLevelContentCategoriesFromGraphQL(reduxContentCategoriesLvl1);

                    let reduxContentCategoriesLvl2 = this.props.restoreFilterValues(filters, 'contentCategoryLvl2');

                    if (!_isEmpty(reduxContentCategoriesLvl2)) {
                        state.hasSelectedCategoryLev2 = true;

                        if (_isEmpty(this.state.contentCategoriesThirdLevel)) {
                            this.getThirdLevelContentCategoriesFromGraphQL(reduxContentCategoriesLvl2);

                            let reduxContentCategoriesLvl3 = this.props.restoreFilterValues(filters, 'contentCategoryLvl3');

                            if (!_isEmpty(reduxContentCategoriesLvl3)) {
                                state.selectedThirdLevelCategories = reduxContentCategoriesLvl3;
                            }
                        }
                    }
                }
            }
        }

        if (!_isEmpty(state)) {
            state.init = false;
            this.setState(() => (state));
        }

        filteredSelectedTournamentCategory(this.state.selectedSports, prevState, this.setState.bind(this));
        filteredSelectedTournament(
            prevState,
            this.setState.bind(this),
            this.state.tournaments,
            this.state.selectedTournaments
        );
    }

    onEventTypeChange = (event, value) => {
        const isSportradarEvent = parseInt(value, 10) === EVENT_TYPE_SPORTRADAR;
        const isNonSportradarEvent = parseInt(value, 10) === EVENT_TYPE_NON_SPORTRADAR;
        let state = {
            hasSelectedSport: false,
            hasSelectedTournamentCategory: false,
            hasSelectedCategoryLev1: false,
            hasSelectedCategoryLev2: false,
            isSportradarEvent: isSportradarEvent,
            isNonSportradarEvent: isNonSportradarEvent,
        };

        if (isSportradarEvent) {
            this.props.form.changeValue('contentCategoryLvl1', null);
            this.props.form.changeValue('contentCategoryLvl2', null);
            this.props.form.changeValue('contentCategoryLvl3', null);
            state.selectedFirstLevelCategories = [];
            state.selectedSecondLevelCategories = [];
            state.selectedThirdLevelCategories = [];
            state.sportsLoading = true;
            this.getSportsFromGraphQL();
        } else if (isNonSportradarEvent) {
            this.props.form.changeValue('sport', null);
            this.props.form.changeValue('tournamentCategory', null);
            this.props.form.changeValue('tournament', null);
            this.props.form.changeValue('court', null);
            state.selectedSports = [];
            state.selectedTournaments = [];
            state.selectedTournamentCategories = [];
            state.contentCategoriesFirstLevelLoading = true;
            this.getFirstLevelContentCategoriesFromGraphQL();
        } else {
            this.props.form.changeValue('contentCategoryLvl1', null);
            this.props.form.changeValue('contentCategoryLvl2', null);
            this.props.form.changeValue('contentCategoryLvl3', null);
            this.props.form.changeValue('sport', null);
            this.props.form.changeValue('tournamentCategory', null);
            this.props.form.changeValue('tournament', null);
            this.props.form.changeValue('court', null);
            state = {
                ...state,
                selectedTournaments: [],
                selectedSports: [],
                selectedTournamentCategories: [],
                selectedFirstLevelCategories: [],
                selectedSecondLevelCategories: [],
                selectedThirdLevelCategories: [],
            };
        }

        this.setState(() => (state));
    };

    // load tournament categories level 1 and tv channel
    onSportChange = (event, value) => {
        let state = {
            hasSelectedSport: false,
            hasSportsWithTvChannel: false,
            tournamentCategories: [],
            tournamentCategoriesLoading: false,
            tvChannelsLoading: false,
            selectedSports: [],
        };

        if (0 < value.length) {
            const sportIds = value.map(Number);

            setTvChannelState(this.props.form, value, this.setStateHandler, 'liveControl');

            state = {
                hasSelectedSport: true,
                tournamentCategoriesLoading: true,
                sports: sportIds,
            };

            this.getTournamentCategoriesFromGraphQL(sportIds);
        } else {
            state = {
                ...state,
                selectedTournaments: [],
                selectedTournamentCategories: [],
            };

            this.props.form.changeValue('tournamentCategory', null);
            this.props.form.changeValue('tvChannels', null);
        }

        this.setState(() => (state));
    };

    setStateHandler = (stateObject) => {
        this.setState(() => (stateObject));
    };

    // display only when at least one sport is selected
    onTournamentCategoryChange = (event, value) => {
        let state = {
            hasSelectedTournamentCategory: false,
            tournaments: [],
            tournamentsLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedTournamentCategory: true,
                selectedTournamentCategories: value,
                tournamentsLoading: true,
            };

            const tournamentCategoryIds = value.map((tournamentCategory) => (convertToInt(tournamentCategory)));

            this.getTournamentsFromGraphQL(tournamentCategoryIds);
        } else {
            state = {
                ...state,
                selectedTournaments: [],
            };

            this.props.form.changeValue('tournament', null);
        }

        this.setState(() => (state));
    };

    // load content categories level 2
    onContentCategoryLevelFirstChange = (event, value) => {
        let state = {
            hasSelectedCategoryLev1: false,
            contentCategoriesSecondLevel: [],
            contentCategoriesSecondLevelLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedCategoryLev1: true,
                selectedFirstLevelCategories: value,
                contentCategoriesSecondLevelLoading: true,
            };

            const contentCategoryLevel2Ids = value.map((contentCategoryLevel2) => (
                convertToInt(contentCategoryLevel2)
            ));

            this.getSecondLevelContentCategoriesFromGraphQL(contentCategoryLevel2Ids);
        } else {
            state = {
                ...state,
                selectedSecondLevelCategories: [],
                selectedThirdLevelCategories: [],
            };

            this.props.form.changeValue('contentCategoryLvl2', null);
        }

        this.setState(() => (state));
    };

    onContentCategorySecondLevelChange = (event, value) => {
        let state = {
            hasSelectedCategoryLev2: false,
            contentCategoriesThirdLevel: [],
            contentCategoriesThirdLevelLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedCategoryLev2: true,
                selectedSecondLevelCategories: value,
                contentCategoriesThirdLevelLoading: true,
            };

            const contentCategoryLevel3Ids = value.map((contentCategoryLevel3) => (
                convertToInt(contentCategoryLevel3)
            ));

            this.getThirdLevelContentCategoriesFromGraphQL(contentCategoryLevel3Ids);
        } else {
            state = {
                ...state,
                selectedThirdLevelCategories: [],
            };

            this.props.form.changeValue('contentCategoryLvl3', null);
        }

        this.setState(() => (state));
    };

    onContentCategoryThirdLevelChange = (event, value) => {
        this.setState(() => ({
            selectedThirdLevelCategories: value,
        }));
    };

    onTournamentChange = (event, value) => {
        let state = {
            hasSelectedTournaments: false,
            courtsLoading: false,
            selectedTournaments: value,
        };

        if (0 < value.length) {
            this.props.client.query({
                query: GetSportsWithCourt,
            }).then((result) => {
                const sports = result.data.sport,
                    selectedSports = this.state.sports;

                selectedSports.forEach((sport) => {
                    if (_find(sports, {'id': sport.toString()})) {
                        state = {
                            hasSelectedTournaments: true,
                            selectedTournaments: value,
                            courtsLoading: true,
                        };
                        const tournaments = value.map(Number);
                        this.getCourtsFromGraphQL(tournaments);
                        this.setState(() => (state));
                    }
                });
            }).catch((error) => {
                showMessageBox(
                    'liveControl',
                    'Failed to handle tournaments filter dropdown change',
                    `${error}`,
                    'error'
                );
            });
        } else {
            state = {
                ...state,
                selectedCourts: [],
            };

            this.props.form.changeValue('court', null);
        }

        this.setState(() => (state));
    };

    onCourtChange = (event, value) => {
        this.setState(() => ({
            selectedCourts: value,
        }));
    };

    getSelectedValues(selectedValues, values) {
        let newSelectedTournamentCategories = [];

        for (let selectedCategoryIndex = 0; selectedCategoryIndex < selectedValues.length; ++selectedCategoryIndex) {
            for (let categoryIndex = 0; categoryIndex < values.length; ++categoryIndex) {
                if (selectedValues[selectedCategoryIndex] === values[categoryIndex].value) {
                    newSelectedTournamentCategories.push(values[categoryIndex].value)
                }
            }
        }

        return newSelectedTournamentCategories;
    }

    getTournamentCategoriesFromGraphQL = _debounce((sportIds) => {
        this.props.client.query({
            query: gql(tournamentCategoriesFilteredBySports),
            variables: {
                sports: sportIds,
            },
        }).then((result) => {
            const tournamentCategories = sortDropdownOptionsAlphanumerically(
                    result.data.tournamentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedTournamentCategories = this.state.selectedTournamentCategories || [];

            this.setState(() => {
                return {
                    selectedSports: sportIds,
                    tournamentCategoriesLoading: false,
                    tournamentCategories: tournamentCategories,
                };
            });

            this.props.form.changeValue(
                'tournamentCategory',
                this.getSelectedValues(selectedTournamentCategories, tournamentCategories)
            );
        }).catch((error) => {
            this.setState(() => {
                return {
                    tournamentCategoriesLoading: false,
                    tournamentCategories: [],
                };
            });

            showMessageBox(
                'liveControl',
                'Failed to fetch tournament categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getTournamentsFromGraphQL = _debounce((tournamentCategoriesIds) => {
        this.props.client.query({
            query: GetTournamentsForDropdownMultipleCategories,
            variables: {
                categories: tournamentCategoriesIds,
            },
        }).then((result) => {
            const tournaments = sortDropdownOptionsAlphanumerically(
                result.data.tournaments.mapDataForDropdownWithIntVal()
            );

            this.setState({
                tournamentsLoading: false,
                tournaments: tournaments,
            });

            this.props.form.changeValue(
                'tournament',
                this.getSelectedValues(this.state.selectedTournaments || [], tournaments)
            );
        }).catch((error) => {
            this.setState(() => {
                return {
                    tournamentCategoriesLoading: false,
                    tournamentCategories: [],
                };
            });

            showMessageBox(
                'liveControl',
                'Failed to fetch tournaments filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getCourtsFromGraphQL = _debounce((tournamentIds) => {
        this.props.client.query({
            query: GetStadiumsByTournamentsForDropdown,
            variables: {
                tournaments: tournamentIds,
            },
        }).then((result) => {
            const courts = sortDropdownOptionsAlphanumerically(
                    result.data.stadiums.mapDataForDropdownWithIntVal()
                ),
                selectedCourts = this.state.selectedCourts || [];

            this.setState(() => ({
                courtsLoading: false,
                courts: courts,
            }));

            this.props.form.changeValue('court', this.getSelectedValues(selectedCourts, courts));
        }).catch((error) => {
            this.setState(() => ({
                courtsLoading: false,
                courts: [],
            }));

            showMessageBox(
                'liveControl',
                'Failed to fetch courts filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getSportsFromGraphQL = () => {
        this.props.client.query({
            query: GetSportsForDropdown,
        }).then((result) => {
            const sports = sortDropdownOptionsAlphanumerically(
                result.data.sports.mapDataForDropdownWithIntVal()
            );

            this.setState(() => ({
                sportsLoading: false,
                fetchedSports: sports,
            }));

        }).catch((error) => {
            this.setState(() => ({
                sportsLoading: false,
                fetchedSports: [],
            }));

            showMessageBox(
                'liveControl',
                'Failed to fetch sports filter data',
                `${error}`,
                'error'
            );
        });
    };

    getFirstLevelContentCategoriesFromGraphQL = () => {
        this.props.client.query({
            query: GetContentCategoriesForDropdown,
            variables: {
                level: 1,
            },
        }).then((result) => {
            const firstLevelCategories = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedFirstLevelCategories = this.state.selectedFirstLevelCategories || [];

            this.setState(() => ({
                contentCategoriesFirstLevelLoading: false,
                contentCategoriesFirstLevel: firstLevelCategories,
            }));

            this.props.form.changeValue('contentCategoryLvl1',
                this.getSelectedValues(selectedFirstLevelCategories, firstLevelCategories)
            );

        }).catch((error) => {
            this.setState(() => {
                return {
                    contentCategoriesFirstLevelLoading: false,
                    contentCategoriesFirstLevel: [],
                };
            });

            showMessageBox(
                'liveControl',
                'Failed to fetch first level content categories filter data',
                `${error}`,
                'error'
            );
        });
    };

    getSecondLevelContentCategoriesFromGraphQL = _debounce((firstLevelIds) => {
        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 2,
                parents: firstLevelIds,
            },
        }).then((result) => {
            const secondLevelCategories = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedSecondLevelCategories = this.state.selectedSecondLevelCategories || [];

            this.setState(() => ({
                contentCategoriesSecondLevelLoading: false,
                contentCategoriesSecondLevel: secondLevelCategories,
            }));

            this.props.form.changeValue('contentCategoryLvl2',
                this.getSelectedValues(selectedSecondLevelCategories, secondLevelCategories)
            );

        }).catch((error) => {
            this.setState(() => {
                return {
                    contentCategoriesSecondLevelLoading: false,
                    contentCategoriesSecondLevel: [],
                };
            });

            showMessageBox(
                'liveControl',
                'Failed to fetch 2nd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getThirdLevelContentCategoriesFromGraphQL = _debounce((secondLevelIds) => {
        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 3,
                parents: secondLevelIds,
            },
        }).then((result) => {
            const thirdLevelCategories = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedThirdLevelCategories = this.state.selectedThirdLevelCategories || [];

            this.setState(() => ({
                contentCategoriesThirdLevelLoading: false,
                contentCategoriesThirdLevel: thirdLevelCategories,
            }));

            this.props.form.changeValue('contentCategoryLvl3',
                this.getSelectedValues(selectedThirdLevelCategories, thirdLevelCategories)
            );

        }).catch((error) => {
            this.setState(() => {
                return {
                    contentCategoriesThirdLevelLoading: false,
                    contentCategoriesThirdLevel: [],
                };
            });

            showMessageBox(
                'liveControl',
                'Failed to fetch 3rd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getBufferDropdownOptions(type) {
        let bufferValues = _range(-1, type === '+' ? -75 : -49, -1).map((el) => {
            const element = ((type === '+') ? Math.abs(el) : el).toString();

            return {
                key: type + '_' + element,
                text: (type === '+' ? '+' : '') + element + ' hour' + (Math.abs(el) > 1 ? 's' : ''),
                value: Math.abs(el),
            };
        });

        bufferValues.unshift({
            key: 'now',
            text: 'Now',
            value: 0,
        });

        return bufferValues;
    }

    render() {
        const propertyLicencesForDropdown = mapPropertyLicencesForDropdown(this.props.data.propertyLicencesForDropdown);
        const loading = this.props.loading;
        const {
            broadcastStatuses,
            distributionTypes,
            encodingDatacenters,
            encodingStatuses,
            eventTypes,
            tvChannels,
            products,
            eventContentTypes,
            eventContentVariants,
        } = this.props.data;

        return (
            <div className="livecontrol__filters filter__controls__container">
                <Field
                    component={Form.ReduxInput}
                    disabled={loading}
                    loading={loading}
                    name="search"
                    placeholder="Search..."
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'search')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="propertyLicence"
                    className="--customWidth --propertyLicence"
                    placeholder="Property licence"
                    options={propertyLicencesForDropdown}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'propertyLicence')}
                />
                <Field
                    className="--minimalWidth --eventType"
                    component={Form.ReduxSelect}
                    name="eventType"
                    placeholder="Event type"
                    options={eventTypes.mapDataForDropdownWithIntVal()}
                    search
                    loading={loading}
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'eventType')}
                    onChangeValue={this.onEventTypeChange}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="sport"
                    hidden={!this.state.isSportradarEvent}
                    options={this.state.fetchedSports}
                    disabled={this.state.sportsLoading || loading}
                    placeholder="Sport"
                    loading={this.state.sportsLoading || loading}
                    multiple
                    selection
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'sport')}
                    onChangeValue={this.onSportChange}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="tournamentCategory"
                    hidden={!this.state.hasSelectedSport}
                    options={this.state.tournamentCategories}
                    disabled={this.state.tournamentCategoriesLoading || loading}
                    placeholder="Category"
                    loading={this.state.tournamentCategoriesLoading || loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'tournamentCategory')}
                    selection
                    onChangeValue={this.onTournamentCategoryChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='tournament'
                    hidden={!this.state.hasSelectedTournamentCategory}
                    options={this.state.tournaments}
                    disabled={this.state.tournamentsLoading || this.state.tournamentCategoriesLoading || loading}
                    placeholder='Tournament'
                    loading={this.state.tournamentsLoading || loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'tournament')}
                    selection
                    onChangeValue={this.onTournamentChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='court'
                    hidden={!this.state.hasSelectedTournaments}
                    options={this.state.courts}
                    disabled={this.state.courtsLoading || loading}
                    placeholder='Courts'
                    loading={this.state.courtsLoading || loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'court')}
                    selection
                    onChangeValue={this.onCourtChange}
                    search
                />
                <Field
                    className="--customWidth --contentType"
                    component={Form.ReduxSelect}
                    name="eventContentType"
                    placeholder="Content type"
                    options={eventContentTypes}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'eventContentType')}
                />
                <Field
                    className="--customWidth --contentVariant"
                    component={Form.ReduxSelect}
                    name="eventContentVariant"
                    placeholder="Content variant"
                    options={eventContentVariants}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'eventContentVariant')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='tvChannels'
                    hidden={!this.state.hasSportsWithTvChannel}
                    options={tvChannels.mapDataForDropdownWithIntVal()}
                    disabled={this.state.tvChannelsLoading || loading}
                    placeholder='TV channel'
                    loading={this.state.tvChannelsLoading || loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'tvChannels')}
                    selection
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentCategoryLvl1'
                    hidden={!this.state.isNonSportradarEvent}
                    options={this.state.contentCategoriesFirstLevel}
                    disabled={this.state.contentCategoriesFirstLevelLoading || loading}
                    placeholder='1st level category'
                    loading={this.state.contentCategoriesFirstLevelLoading || loading}
                    multiple
                    selection
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'contentCategoryLvl1')}
                    onChangeValue={this.onContentCategoryLevelFirstChange}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="contentCategoryLvl2"
                    hidden={!this.state.hasSelectedCategoryLev1}
                    options={this.state.contentCategoriesSecondLevel}
                    disabled={this.state.contentCategoriesSecondLevelLoading || loading}
                    placeholder="2nd level category"
                    loading={this.state.contentCategoriesSecondLevelLoading || loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'contentCategoryLvl2')}
                    multiple
                    selection
                    onChangeValue={this.onContentCategorySecondLevelChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name="contentCategoryLvl3"
                    hidden={!this.state.hasSelectedCategoryLev2}
                    options={this.state.contentCategoriesThirdLevel}
                    disabled={
                        this.state.contentCategoriesSecondLevelLoading
                        || this.state.contentCategoriesThirdLevelLoading
                        || loading
                    }
                    placeholder="3rd level category"
                    loading={this.state.contentCategoriesThirdLevelLoading || loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'contentCategoryLvl3')}
                    multiple
                    selection
                    onChangeValue={this.onContentCategoryThirdLevelChange}
                    search
                />
                <Field
                    className="--customWidth --encodingDatacenter"
                    component={Form.ReduxSelect}
                    name="encodingDatacenter"
                    placeholder="Datacenter"
                    options={[].concat(encodingDatacenters.mapDataForDropdownWithIntVal()).sort((a, b) => (
                        a.value > b.value ? 1 : -1
                    ))}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'encodingDatacenter')}
                />
                <Field
                    className="--customWidth --products"
                    component={Form.ReduxSelect}
                    name="product"
                    placeholder="Product"
                    options={products.mapDataForDropdownWithIntVal()}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'product')}
                />
                <Field
                    className="--customWidth --distributionTypes"
                    component={Form.ReduxSelect}
                    name="distributionType"
                    placeholder="Distribution type"
                    options={distributionTypes.mapDataForDropdownWithIntVal()}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'distributionType')}
                />
                <Field
                    className="--customWidth --encodingStatus"
                    component={Form.ReduxSelect}
                    name="encodingStatus"
                    placeholder="Encoding status"
                    options={[{value: null, key: 7, text: 'Not started'}]
                        .concat(encodingStatuses.mapDataForDropdownWithIntVal())
                        .sort(sortByKey('text'))}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'encodingStatus')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="startBroadcastAutomatically"
                    className="--customWidth --startBroadcastAutomatically"
                    placeholder="Broadcast"
                    loading={loading}
                    disabled={loading}
                    options={[
                        {
                            value: 1,
                            key: 1,
                            text: 'Start broadcast automatically',
                        },
                        {
                            value: 0,
                            key: 0,
                            text: 'Start broadcast manually',
                        },
                    ]}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'startBroadcastAutomatically')}
                />
                <Field
                    className='--customWidth --broadcastStatus'
                    component={Form.ReduxSelect}
                    name='broadcastStatus'
                    placeholder='Broadcast status'
                    options={orderBy(broadcastStatuses.mapDataForDropdownWithIntVal(), ['text'], ['asc'])}
                    search
                    loading={loading}
                    multiple
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'broadcastStatus')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="preBuffer"
                    className="preBuffer"
                    placeholder="Select pre buffer"
                    loading={loading}
                    disabled={loading}
                    options={this.getBufferDropdownOptions('-')}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'preBuffer', 2)}
                    allowClear={false}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="postBuffer"
                    className="postBuffer"
                    placeholder="Select post buffer"
                    loading={loading}
                    disabled={loading}
                    options={this.getBufferDropdownOptions('+')}
                    setValue={this.props.restoreFilterValues(this.props.filters.LiveControl, 'postBuffer', 2)}
                    allowClear={false}
                />
            </div>
        );
    }
}

export default FiltersWrapper(
    withApollo(
        connect(null, mapModulesToProps(['MessageBox']))(LiveControlFilters)
    ), {
        name: 'LiveControl',
        initialValues: {
            preBuffer: 2,
            postBuffer: 2,
        },
    }
);
