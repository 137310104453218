import {withRouter} from "react-router";
import {get as _get} from "lodash";

import ProductGeoRestrictionsForm from "./ProductGeoRestrictionsForm";

import {convertToInt} from "../../../utils/helpers";

class ProductLctGeoRestrictionsForm extends ProductGeoRestrictionsForm {

    getEntityClientProductId = () => {
        return convertToInt(_get(this.props.GraphQLEntityData, 'clientProductsLct[0].id', 0));
    };

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'clientProductsLct[0].client_product', {});
    };
}

export default withRouter(ProductLctGeoRestrictionsForm);
