import React from 'react';

export const PROCESS_CSID_INFO = () => (
    <div>
        <p>Defines if the CSID should be processed by our analytics tools.</p>
        <p>If switched off, the CSID will not be included as a tracking parameter
            in the stream URLs provided to the client by the AV-API.</p>
    </div>
);
export const ENFORCE_CSID_INFO = () => (
    <div>
        <p>Defines if the usage of the CSID should be enforced.</p>
        <p>If switched on, the CSID parameter will be required by the AV-API
            in order to provide stream URLs to the client.</p>
    </div>
);
