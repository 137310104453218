import {withRouter} from "react-router";
import {get as _get, isEmpty as _isEmpty} from "lodash";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";
import {renderModalError} from "../../../utils/forms";

import {ORIGIN_HOSTING_PROVIDER_AMAZON} from "../../app/constants/variables";

export class RtmpOriginEntryPointForm extends DefaultForm {
    componentDidMount() {
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('rtmpOriginEntryPoint.index'));
        });
        this.setCreateSuccessCallback((response) => {
            this.setState(() => ({createdRtmpOriginEntryPoint: response.data.createRtmpOriginEntryPoint}));

            this.props.history.push(getLink("rtmpOriginEntryPoint.edit", {
                id: response.data.createRtmpOriginEntryPoint.id
            }));
        });
        this.setOnChangeCallback({
            origin_hosting_provider: (event) => {
                const hasAmazon = (ORIGIN_HOSTING_PROVIDER_AMAZON === convertToInt(event.value));

                this.setField(["amazon_instance", "amazon_region"], {
                    hidden: !hasAmazon,
                    required: hasAmazon,
                    defaultValue: null,
                });

                if (this.props.match.params.id && hasAmazon) {
                    const amazonInstance = _get(
                            this.props,
                            'GraphQLEntityData.rtmpOriginEntryPoint.amazon_instance_id',
                            _get(this.state, 'createdRtmpOriginEntryPoint.amazon_instance_id', null)
                        ),
                        amazonRegion = _get(
                            this.props,
                            'GraphQLEntityData.rtmpOriginEntryPoint.amazon_region',
                            _get(this.state, 'createdRtmpOriginEntryPoint.amazon_region', null)
                        );

                    this.setField(["amazon_instance"], {defaultValue: amazonInstance});
                    this.setField(["amazon_region"], {defaultValue: amazonRegion});
                }
            },
            amazon_instance: (event) => {
                this.setField("amazon_instance", {defaultValue: event.value});
            },
            amazon_region: (event) => {
                this.setField("amazon_region", {defaultValue: event.value});
            },
            is_active: (event) => {
                const isActive = !event.checked;

                this.setField("is_active", {checked: isActive, defaultValue: isActive});
            },
        })
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const rtmpOriginEntryPoint = _get(this.props, 'GraphQLEntityData.rtmpOriginEntryPoint', {});

        if (!this.state.isActiveGenerated && !this.props.formParams.id) {
            this.setField("is_active", {checked: true, defaultValue: true});
            this.setState(() => ({isActiveGenerated: true}));
        } else if (!this.state.dataLoaded && !_isEmpty(rtmpOriginEntryPoint)) {
            const hasAmazon = ORIGIN_HOSTING_PROVIDER_AMAZON === convertToInt(rtmpOriginEntryPoint.origin_hosting_provider.id);

            if (hasAmazon) {
                this.setField("amazon_instance", {hidden: false, required: true});
                this.setField("amazon_region", {hidden: false, required: true});
            }

            this.setState(() => ({dataLoaded: true}));
        }
    }

    prepareDataForSubmit = (data) => {
        const hasAmazon = (data && ORIGIN_HOSTING_PROVIDER_AMAZON === convertToInt(data.origin_hosting_provider));

        return Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                origin_hosting_provider: (data && data.origin_hosting_provider)
                    ? convertToInt(data.origin_hosting_provider)
                    : null,
                rtmp_origin_entry_point_type: (data && data.rtmp_origin_entry_point_type)
                    ? convertToInt(data.rtmp_origin_entry_point_type)
                    : null,
                amazon_instance: (data && hasAmazon && data.amazon_instance) ? data.amazon_instance : null,
                amazon_region: (data && hasAmazon && data.amazon_region) ? data.amazon_region : null,
            }
        );
    };

    renderErrors(errorData) {
        const errors = errorData.graphQLErrors,
            isNotFound = (1 === errors.length) && (404 === errors[0].code);

        if (isNotFound) {
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError('RTMP origin entry point', getLink('rtmpOriginEntryPoint.index')),
            });
        } else {
            super.renderErrors(errorData);
        }
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(RtmpOriginEntryPointForm);
