import {withApollo} from "react-apollo";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

class ClientAnalyticsSetupLabelForm extends DefaultForm {
    componentWillMount() {
        super.componentWillMount();

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (nextProps.formParams.id !== null && nextProps.GraphQLEntityData.clientLabel) {
            const clientLabel = nextProps.GraphQLEntityData.clientLabel;

            this.setField("id", {value: nextProps.formParams.id});
            this.setField("code", {value: clientLabel.code});
            this.setField("name", {value: clientLabel.name});
        }
    }

    prepareDataForSubmit = (data) => {
        let dataToSave = {
            client: this.props.formParams.optionsVariables.id,
            code: data.code,
            name: data.name,
        };

        if (data.id) {
            dataToSave.id = parseInt(data.id, 10);
        }

        return dataToSave;
    };

    renderErrors(errorData) {
        const link = getLink("clients.clientAnalyticsSetup.labels.index", {
            id: this.props.formParams.optionsVariables.id
        });

        super.renderErrors(errorData, this.props.Model.entityLabel.capitalizeFirstLetter(), link);
    }
}

export default withApollo(ClientAnalyticsSetupLabelForm);
