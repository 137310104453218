import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import { useHistory } from 'react-router-dom';

import Table from '@appComponents/Table';
import {columns} from '@modules/events/constants/eventsEventBookingsColumns';
import {rowRenderer} from '@modules/events/utils/eventsEventBookingsTableRowRenderer';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import {getTableRowIsDisabledClassName} from '@modules/booking/utils/bookingsTable';

const EventsEventBookingsTable = ({eventEventBookings = [], loading = false, mutationCallback = null}) => {
    const {data: {mappedIsoCodes} = {}, error} = useQuery(GetMappedIsoCodes);
    const history = useHistory();

    if (error) {
        showErrorModal({
            header: 'Mapped iso codes data could not be retrieved',
            text: error,
            errorButtonText: 'Back to dashboard',
            hideButtons: false,
            redirectLink: getLink('dashboard'),
            errorButtonIcon: 'arrow circle left',
            size: 'large',
        });

        return null;
    }

    return (
        <Table
            className={'mini'}
            data={eventEventBookings}
            loading={loading}
            columns={columns}
            rowRenderer={rowRenderer}
            rowRendererProps={{mappedIsoCodes, history, mutationCallback}}
            rowClassname={getTableRowIsDisabledClassName}
            defaultSort={'product'}
            noDataText={'No event bookings found'}
            pagination={false}
        />
    );
};

EventsEventBookingsTable.propTypes = {
    eventEventBookings: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    mappedIsoCodes: PropTypes.object,
    mutationCallback: PropTypes.func,
};

export default EventsEventBookingsTable;
