import moment from 'moment';
import PropTypes from 'prop-types';

import {
    convertMillisecondsToHoursMinutesAndSeconds,
    convertMillisecondsToMinutesAndSeconds,
    convertMillisecondsToSeconds,
} from '@utils/momentWrapper';

export const getDuration = (duration) => {
    if (null === duration) {
        return null;
    }

    return getConvertedDuration(duration);
};

const getConvertedDuration = (duration) => {
    const durationInMilliseconds = moment.duration(duration, 'milliseconds');

    if (60000 > durationInMilliseconds) {
        return Math.floor(convertMillisecondsToSeconds(durationInMilliseconds)) + 's';
    } else if (60000 <= durationInMilliseconds && 3600000 > durationInMilliseconds) {
        return convertMillisecondsToMinutesAndSeconds(durationInMilliseconds);
    }

    return convertMillisecondsToHoursMinutesAndSeconds(durationInMilliseconds);
};

export const getResolution = (row) => ((row.resolution_width && row.resolution_height)
    ? `${row.resolution_width}x${row.resolution_height}`
    : ''
);

getResolution.propTypes = {
    row: PropTypes.shape({
        resolution_height: PropTypes.number,
        resolution_width: PropTypes.number,
    }),
};
