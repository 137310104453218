import {get as _get} from "lodash";
import React from "react";

import {renderModalError} from "../../../utils/forms";
import {showModal} from "../../../utils/modal";

export const loadModalForm = (Form, setModalVisibility) => {
    showModal({
        content: <Form />,
        header: null,
        isVisible: true,
        size: "tiny",
    });

    setModalVisibility(true);
};

export const renderThirdPartyCdnsModalError = (thirdPartyClientCdnsIndexLink) => (
    renderModalError("Client third party CDNs", thirdPartyClientCdnsIndexLink)
);
renderThirdPartyCdnsModalError.displayName = "renderThirdPartyCdnsModalError";

export const renderModalHeaderTitle = (formData, type, props) => {
    if (null === _get(props, "formParams.id", null)) {
        return "Add new CDN";
    }

    const cdnName = _get(formData, "thirdPartyClientCdn.name", null);

    return cdnName ? `CDN "${cdnName}"` : "CDN";
};
renderModalHeaderTitle.displayName = "renderModalHeaderTitle";
