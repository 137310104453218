export const changelogData = `
    creation_datetime
    creation_user {
        id
        name
    }
    update_datetime
    update_user {
        id
        name
    }
`;


