import moment from "moment";

String.prototype.capitalizeFirstLetter = function () {
    return this.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase())
};

String.prototype.parseToDateString = function () {
    return moment(this).format("YYYY-MM-DD");
};

String.prototype.parseToDateTimeString = function () {
    return moment(this).format("YYYY-MM-DD HH:mm");
};

Array.prototype.mapDataForDropdown = function (key = 'id') {
    return this.map((item) => ({key: item[key], text: item.name, value: item[key]}));
};

Array.prototype.mapDataForDropdownWithIntVal = function (hasShortName = false) {
    return this.map((item) => ({key: item.id, text: hasShortName ? item.short_name : item.name || item.text, value: parseInt(item.id, 10)}));
};

Array.prototype.mapDataForDropdownWithNameVal = function () {
    return this.map((item) => ({key: item.name, text: item.name, value: item.name}));
};

Array.prototype.mapDataForDropdownPropertyLicence = function () {
    return this.map((item) => ({
        key: item.id,
        text: item.licensor.name + ' - ' + item.name,
        value: parseInt(item.id, 10)
    }));
};
