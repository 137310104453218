import _ from 'lodash';

import {ReduxComponent} from './ReduxComponent';

export class TreeBehaviourComponent extends ReduxComponent {
    idsObject = {};

    getTreeData(options, noInteractionsOnParents) {
        if (_.isUndefined(noInteractionsOnParents)) {
            noInteractionsOnParents = false;
        }

        let indeterminate = [],
            parents = [],
            selected = [...options.selected];

        for (let i = 0; i < selected.length; i++) {
            let option = selected[i];

            let intersectionObject = this.checkIntersection(option, selected, options.values, noInteractionsOnParents);

            parents = _.union(parents, intersectionObject.parents);
            indeterminate = _.intersection(_.union(indeterminate, intersectionObject.indeterminate), parents);
            selected = _.union(selected, intersectionObject.selected);
        }

        return {indeterminate: indeterminate, selected: selected};
    }

    /**
     * @ignore
     */
    getChildrenValue(options, parentKey, selected, ids) {
        let data = [],
            newSelected = selected,
            values = {};

        for (let i = 0; i < options.length; i++) {
            let option = options[i],
                name = option.name,
                localKey = `${this.removeDashes(name)}-${this.removeDashes(option.value)}`.replace(/[\s.,]/g,'&&'),
                key = `${parentKey}.${localKey}`;

            if (option.disabled) {
                continue;
            }

            ids[localKey] = {id: option.id};

            data = _.union(data, [key]);

            if (option.selected) {
                newSelected = _.union(newSelected, [key]);
            }

            if (option.children) {
                let children = this.getChildrenValue(option.children, key, newSelected, ids[localKey]);

                newSelected = _.union(newSelected, children.selected);
                values = _.merge(values,{[key] : children.data});
                values = _.merge(values,children.values);
                data = _.union(data, children.data);
            }
        }

        return {data: data, values: values, selected: newSelected};
    }

    /**
     * @ignore
     */
    mapOptions(options, indeterminateSelected) {
        let newData = [],
            selected = [];

        for (let option = 0; option < options.length; option++) {
            let object = options[option],
                name = object.name,
                key = `${this.removeDashes(name)}-${this.removeDashes(object.value)}`.replace(/[\s.,]/g, '&&');

            this.idsObject[key] = {id: option.id};

            let children = this.getChildrenValue(options[option].children, key, selected, this.idsObject[key]);

            newData[key] = children.data;
            selected = _.union(selected, children.selected);

            if (indeterminateSelected) {
                if (object.selected) {
                    selected = _.union(selected, [key]);
                }
            }

            newData = _.merge(newData, children.values);
        }

        return {values: {...newData}, selected: selected};
    }

    checkIntersection = (path, selected, treeValues, noInteractionOnParents) => {
        let parentsTree = _.dropRight(_.split(path, '.')),
            parents = [],
            indeterminate = this.state.indeterminate,
            parentTmp = '';

        if(!treeValues) {
            treeValues = this.state.valuesTree;
        }

        parentsTree.map(parent => {
            parentTmp += parent;
            parents.push(parentTmp);
            parentTmp += '.';
        });

        parents = _.reverse(parents);

        if(treeValues[path]) {
            _.remove(indeterminate, (value) => (
                -1 < treeValues[path].indexOf(value)
            ));
        }

        _.remove(indeterminate, (value) => (
            value === path
        ));

        for (let key = 0; key < parents.length; key++) {
            let parentKey = parents[key],
                intersection = _.intersection(selected, treeValues[parentKey]);

            if (0 === intersection.length) {
                if (!noInteractionOnParents) {
                    _.remove(selected, (value) => (
                        value === parentKey
                    ));
                }

                _.remove(indeterminate, (value) => (
                    value === parentKey
                ));
            }

            if (treeValues[parentKey] && intersection.length === treeValues[parentKey].length) {
                if (!noInteractionOnParents) {
                    selected = _.union(selected, [parentKey]);
                }

                _.remove(indeterminate, (value) => (
                    value === parentKey
                ));

                continue;
            }

            if (treeValues[parentKey] && intersection.length !== treeValues[parentKey].length && 0 !== intersection.length) {
                if (!noInteractionOnParents) {
                    _.remove(selected, (value) => (
                        value === parentKey
                    ));
                }

                indeterminate = _.union(indeterminate, [parentKey]);
            }
        }

        return {indeterminate: indeterminate, selected: selected, parents: parents};
    };

    /**
     * @ignore
     */
    parseValuesToReturn(selected) {
        let data = {};

        for (let key = 0; key < selected.length; key++) {
            data = _.merge(data, this.parseValues(selected[key].split('.')));
        }

        return Object.keys(data).map(key => {

            let tmp = this.createArraysTreeFromObject({[key]: data[key]});

            return tmp[0];
        });
    }

    /**
     * @ignore
     */
    createArraysTreeFromObject(data, ids = this.idsObject) {
        let dataToReturn = [];

        if(!data) {
            return dataToReturn;
        }

        let keys = Object.keys(data);

        for (let objectKey = 0; objectKey < keys.length; objectKey++) {
            let localKey = keys[objectKey],
                keySplitted = localKey.split('-');

            dataToReturn.push( {
                id: _.get(ids, `${localKey}.id`, null),
                name: keySplitted[0],
                value: keySplitted[1],
                children: this.createArraysTreeFromObject(data[localKey].children, ids[localKey]),
            });
        }

        return dataToReturn;
    }

    /**
     * @ignore
     */
    parseValues(values, data) {
        let leaf = values[0];

        if (!data) data = [];

        if (leaf) {
            let children = this.parseValues([..._.drop(values, 1)], data),
                childrenObject = {};

            if(0 !== children.length) {
                childrenObject = {children : children};
            }

            data = {
                [leaf] : {
                    ...childrenObject,
                },
            };
        }

        return data;
    }

    removeDashes(value) {
        return value.replace(/-|\[|\]/g, '');
    }
}
