import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {PROPERTY_LICENCE} from "../../app/constants/resources";

import validate from "./validator";

import {CreatePropertyLicence, DeletePropertyLicence, UpdatePropertyLicence} from "../../../graphql/propertyLicence/mutation.graphql";
import {GetPropertyLicenceForForm, GetOptionsForPropertyLicenceForm} from "../../../graphql/propertyLicence/query.graphql";

export default function PropertyLicenceModel({id}) {
    return {
        entityDataMapKey: "propertyLicence",
        entityLabel: "Property Licence",
        formName: "propertyLicence",
        label: "Property Licence",
        resources: PROPERTY_LICENCE,
        validate: validate,
        title: null,
        showChangeLog: true,
        dataMap: {
            propertyLicence: {
                id: "",
                name: "",
                official_name: "",
                licensor: {id: ""},
                property_licence_type: {id: ""},
                property_licence_status: {id: ""},
                sport: {id: ""},
                content_category: {id: ""},
                content_tier: {id: ""},
                content_package_type: {id: ""},
                content_manager_user: {id: ""},
                expiry_alert_date: "",
                has_accreditation: false,
                is_archived: false,
                notes: ""
            },
            sports: [],
            contentCategories: [],
        },
        messages: {
            boxName: {
                delete: "propertyLicenceIndexMessage",
                error: "propertyLicenceIndexMessage",
                success: "propertyLicenceMessage"
            },
            text: {
                ...MESSAGES,
                SAVED: () => MESSAGES.SAVED('property licence'),
            }
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "propertyLicence.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "propertyLicence.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                },
            },
            official_name: {
                dataMapKey: "propertyLicence.official_name",
                props: {
                    component: Form.SemanticInput,
                    label: "Official name",
                    name: "official_name",
                },
            },
            licensor: {
                dataMapKey: "propertyLicence.licensor.id",
                optionsKey: "licensors",
                props: {
                    component: Form.SemanticSelect,
                    label: "Licensor",
                    name: "licensor",
                    placeholder: "Select licensor",
                    required: true,
                },
            },
            property_licence_type: {
                dataMapKey: "propertyLicence.property_licence_type.id",
                optionsKey: "propertyLicenceTypes",
                props: {
                    component: Form.SemanticSelect,
                    disabled: !!id,
                    label: "Type",
                    name: "property_licence_type",
                    placeholder: "Select Type",
                    required: true,
                },
            },
            content_category_or_sport: {
                optionsKey: "sports",
                props: {
                    allowClear: false,
                    component: Form.SemanticSelect,
                    label: "",
                    name: "content_category_or_sport",
                    placeholder: "",
                    required: true,
                    hidden: true,
                    disabled: true,
                },
            },
            content_tier: {
                dataMapKey: "propertyLicence.content_tier.id",
                optionsKey: "contentTiers",
                props: {
                    allowClear: false,
                    component: Form.SemanticSelect,
                    label: "Tier",
                    name: "content_tier",
                    placeholder: "Select tier",
                    required: true,
                },
            },
            content_package_type: {
                dataMapKey: "propertyLicence.content_package_type.id",
                optionsKey: "contentPackageTypes",
                props: {
                    allowClear: false,
                    component: Form.SemanticSelect,
                    label: "Content package type",
                    name: "content_package_type",
                    placeholder: "Select Package Type",
                    required: true,
                },
            },
            content_manager_user: {
                dataMapKey: "propertyLicence.content_manager_user.id",
                optionsKey: "contentManagers",
                props: {
                    allowClear: false,
                    component: Form.SemanticSelect,
                    label: "Content manager",
                    name: "content_manager_user",
                    placeholder: "Select Content Manager",
                    required: true,
                },
            },
            property_licence_status: {
                dataMapKey: "propertyLicence.property_licence_status.id",
                optionsKey: "propertyLicenceStatuses",
                props: {
                    component: Form.SemanticSelect,
                    label: "Status",
                    name: "property_licence_status",
                    placeholder: "Select a Status",
                    required: true,
                },
            },
            expiry_alert_date: {
                dataMapKey: "propertyLicence.expiry_alert_date",
                props: {
                    component: Form.SemanticInput,
                    label: "Expiry alert date",
                    name: "expiry_alert_date",
                    type: "date",
                },
            },
            has_accreditation: {
                dataMapKey: "propertyLicence.has_accreditation",
                props: {
                    component: Form.SemanticInput,
                    label: "Accreditation",
                    name: "has_accreditation",
                    type: "checkbox",
                },
            },
            notes: {
                dataMapKey: "propertyLicence.notes",
                props: {
                    component: Form.SemanticTextArea,
                    label: "Notes",
                    name: "notes",
                    rows: 3,
                },
            },
        },
        mutation: {
            createEntity: CreatePropertyLicence,
            deleteEntity: DeletePropertyLicence,
            updateEntity: UpdatePropertyLicence,
        },
        query: {
            getEntity: GetPropertyLicenceForForm,
            getOptions: GetOptionsForPropertyLicenceForm
        },
    }
}
