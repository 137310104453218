import {
    get as _get,
} from 'lodash';

import {LCR_CHANNEL} from '@constants/resources';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {
    CreateLcrChannelTournamentCategory,
    UpdateLcrChannelTournamentCategory,
} from '@graphql/lcr/mutation';
import {
    GetLcrChannelCategoryFormOptionsData,
    GetLcrChannelTournamentCategoryData,
} from '@graphql/lcr/query';
import validators from '@utils/validators';

const CategoryModel = () => (
    {
        formName: 'LcrAdministration-contentRestrictions-categories',
        title: (data, type, props) => {
            let title = 'Add new category';
            const name = _get(
                props,
                'GraphQLEntityData.lcrChannelTournamentCategory.tournament_category.name',
                null
            );

            if (props.formParams.id) {
                if (!name) {
                    title = '';
                } else {
                    title = `Category "${name}"`;
                }
            }

            return title;
        },
        entityDataMapKey: 'lcrChannelTournamentCategory.tournament_category',
        label: 'category',
        entityLabel: 'category',
        resources: LCR_CHANNEL,
        editForm: true,
        showChangeLog: false,
        validate: (values) => {
            const errors = {};

            Object.assign(errors, validators.required(values.sport, 'sport'));
            Object.assign(errors, validators.required(values.tournament_category, 'tournament_category'));
            Object.assign(errors, validators.required(values.content_tiers, 'content_tiers'));

            return errors;
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'lcrChannelSport.id',
                props: {
                    component: Form.SemanticInput,
                    label: '',
                    name: 'id',
                },
            },
            sport: {
                dataMapKey: 'lcrChannelTournamentCategory.tournament_category.sport.id',
                props: {
                    className: '--small-width',
                    component: Form.SemanticSelect,
                    required: true,
                    label: 'Sport',
                    name: 'sport',
                },
            },
            tournament_category: {
                props: {
                    component: Form.SemanticSelect,
                    required: true,
                    label: 'Category',
                    name: 'tournament_category',
                },
            },
            content_tiers: {
                optionsKey: 'contentTiers',
                dataMapKey: 'lcrChannelTournamentCategory.content_tiers',
                props: {
                    component: Form.SemanticCheckboxList,
                    required: true,
                    label: 'Tiers',
                    name: 'content_tiers',
                },
            },
        },
        messages: {
            boxName: {
                success: 'lcrChannelContentRestriction-categories',
                error: 'lcrChannelContentRestriction-categories',
            },
            text: {
                ...MESSAGES,
            },
        },
        mutation: {
            createEntity: CreateLcrChannelTournamentCategory,
            updateEntity: UpdateLcrChannelTournamentCategory,
        },
        query: {
            getEntity: GetLcrChannelTournamentCategoryData,
            getOptions: GetLcrChannelCategoryFormOptionsData,
        },
    }
);

export default CategoryModel;
