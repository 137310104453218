import React from 'react';
import PropTypes from 'prop-types';
import {
    ENCODING_STATUS_ENCODING,
    ENCODING_STATUS_ERROR,
    ENCODING_STATUS_PREPARING,
    ENCODING_STATUS_SCHEDULED,
} from '@constants/variables';
import { hideModal, showModalConfirmation } from '@utils/modal';
import ActionButton from '@appComponents/ButtonsCollection/ActionButton';
import { forEach as _forEach, get as _get } from 'lodash';
import ModalHeaderEventContentLiveControl from '../../views/ModalLiveControlHeader';
import { SetOverlayStatusForEventContent } from '@graphql/eventContent/mutation';
import { Authorization } from '@appComponents/Authorization';
import { refetchQueryByName } from '@utils/apollo';
import { useApolloClient } from '@apollo/client';
import { showMessageBox } from '@utils/messageBox';
import { convertToInt } from '@utils/helpers';

const checkRequiredEncodingStatus = (row) => {
    return [
        null, ENCODING_STATUS_PREPARING, ENCODING_STATUS_ENCODING, ENCODING_STATUS_ERROR, ENCODING_STATUS_SCHEDULED
    ].includes(row.encoding_status_id);
};

const getRowEncodingStatusSrPropertyStatus = (row, propertyName) => {
    let result = false;

    _forEach(_get(row, 'distributions_by_product', []), (distribution) => {
        _forEach(_get(distribution, 'encoding_targets', []), (encodingTarget) => {
            if (_get(encodingTarget, propertyName, false)) {
                result = true;

                return false;
            }
        });

        if (result) {
            return false;
        }
    });

    return result;
};

const rowHasActiveOverlay = (row) => {
    return getRowEncodingStatusSrPropertyStatus(row, 'encoding_target_sr.has_overlay');
};

const rowHasOverlaySupport = (row) => {
    return getRowEncodingStatusSrPropertyStatus(row, 'encoding_target_sr.has_overlay_support');
};

const showOverlayErrorMessageBox = () => {
    showMessageBox(
        'liveControl',
        'An error occurred while trying to change the overlay status via the Video Platform Controller API.',
        null,
        'error',
        true
    );
};

const switchOverlayStatus = (apolloInstance, row, status) => {
    apolloInstance.mutate({
        mutation: SetOverlayStatusForEventContent,
        variables: {
            eventContentId: row.event_content_id,
            overlayStatus: status,
        }
    }).then((result) => {
        if (row.event_content_id === convertToInt(result.data.setOverlayStatusForEventContent.id)) {
            refetchQueryByName('LiveControl');
        } else {
            showOverlayErrorMessageBox();
        }
    }).catch(() => {
        showOverlayErrorMessageBox();
    }).finally(() => {
        hideModal();
    });
};

export const showOverlayButton = (row) => {
    return row.event_content_has_input && rowHasOverlaySupport(row) && checkRequiredEncodingStatus(row);
};

showOverlayButton.propTypes = {
    row: PropTypes.object.isRequired,
};

export const OverlayButton = ({row = {}, authorizationObject = {}}) => {
    const apolloClient = useApolloClient();

    if (showOverlayButton(row)) {
        const overlayEnabled = rowHasActiveOverlay(row),
            toolTipAndTitleText = `${overlayEnabled ? 'Disable' : 'Enable'} overlay`,
            modalText = `Are you sure you want to ${overlayEnabled ? 'disable' : 'enable'} the overlay?`;

        return (
            <Authorization authorization={authorizationObject}>
                <ActionButton
                    popup={{content: toolTipAndTitleText}}
                    button={{
                        content: (<span>&nbsp;O&nbsp;</span>),
                        className: 'icon ' + (overlayEnabled ? '--no-text-decoration' : '--strike-through'),
                    }}
                    actionParams={{
                        hideButtons: false,
                        header: (<ModalHeaderEventContentLiveControl event={row} title={toolTipAndTitleText}/>),
                        text: modalText,
                        size: 'tiny',
                        onYes: () => {
                            switchOverlayStatus(apolloClient, row, !overlayEnabled);
                        },
                    }}
                    onClick={showModalConfirmation}
                />;
            </Authorization>
        );
    }

    return null;
};

OverlayButton.propTypes = {
    row: PropTypes.object.isRequired,
    authorizationObject: PropTypes.object.isRequired,
};
