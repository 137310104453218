import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {useRouteMatch} from 'react-router-dom';

import Tabs from '@appComponents/Tabs';
import {getWhitelistedIpActiveTabs, getWhitelistedIpTabsState} from '@modules/client/utils/whitelistingHelper';

const WhitelistedIpTabsLayout = () => {
    const match = useRouteMatch();
    const [tabsState, setTabsState] = useState({});

    useEffect(() => {
        setTabsState(getWhitelistedIpTabsState(match));
    }, [match]);

    return (
        <Tabs active={getWhitelistedIpActiveTabs(match)} items={tabsState} level={2} />
    );
};

WhitelistedIpTabsLayout.propTypes = {
    match: PropTypes.object.isRequired,
};

export default withRouter(WhitelistedIpTabsLayout);
