import validate from "./validatorWhitelistedIp";

import {createFieldsByType} from "../utils/whitelistingHelper";

import * as MESSAGES from "../../app/constants/messages";
import {CLIENT_WHITELISTED_IP} from "../../app/constants/resources";

import {
    CreateClientWhitelistedIp,
    DisableClientWhitelistedIp,
    UpdateClientWhitelistedIp
} from "../../../graphql/clientWhitelistedIp/mutation.graphql";
import {
    GetClientWhitelistedIpDropdownData,
    GetWhitelistedIpData
} from "../../../graphql/clientWhitelistedIp/query.graphql";

export default function WhitelistedIpModel() {
    return {
        entityDataMapKey: "clientWhitelistedIp",
        entityLabel: "whitelisted IPs",
        formName: "whitelistedIps",
        label: "Whitelisted IP",
        resources: CLIENT_WHITELISTED_IP,
        showChangelog: true,
        title: "Add new CIDR",
        validate: validate,
        dataMap: {
            clientWhitelistedIp: {
                id: "",
                ip_version: "",
                cidr: "",
                description: "",
                products: [],
            },
        },
        messages: {
            boxName: {
                delete: "whitelistedIpMessage",
                error: "formInnerErrorMessage",
                success: "whitelistedIpMessage",
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR(MESSAGES.CIDR),
            },
        },
        fields: {
            ...createFieldsByType("whitelisted"),
        },
        mutation: {
            createEntity: CreateClientWhitelistedIp,
            deleteEntity: DisableClientWhitelistedIp,
            updateEntity: UpdateClientWhitelistedIp,
        },
        query: {
            getEntity: GetWhitelistedIpData,
            getOptions: GetClientWhitelistedIpDropdownData,
        },
    }
}
