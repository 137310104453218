/**
* Helpers for common GraphQL queries: *
    entity_name {
        id
        name
    }

  Usage:
    const entityName = getName('entity_name');
    const [ entityName1, entityName2 ] = getNames([ 'entity_name_1', 'entity_name_2' ]);
* */

export const getName = (field) => `
    ${field} {
        id
        name
    }
`;

export const getNames = (args) => args.map(arg => getName(arg));
