import {get as _get} from "lodash";
import PropTypes from "prop-types";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'licensees',
    parseData: licensee => [
        licensee.id,
        licensee.name,
        _get(licensee, 'country.name', ''),
    ],
    path: 'licensees.index.path',
    titles: [
        "ID",
        "Name",
        "Country",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
