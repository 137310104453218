import React from 'react';
import {Header} from 'semantic-ui-react';
import {get as _get, keys as _keys} from 'lodash';

import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {CLIENT_PRODUCT} from '@constants/resources';
import {IconInfoCircle} from '@appComponents/IconCollection';

import {PROCESS_CSID_INFO, ENFORCE_CSID_INFO} from '../constants/tooltipMessages';


export default function ClientProductModel() {
    return {
        changelogDataMapKey: "client_product",
        formName: "clientProductForm",
        resources: CLIENT_PRODUCT,
        showChangeLog: true,
        title: <Header as="h3" dividing>General settings</Header>,
        dataMap: {
            client_product: {
                id: "",
                start_date: "",
                end_date: "",
                process_csid: false,
                enforce_csid: false,
                is_trial: false,
                is_disabled: false,
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "client_product.id",
                props: {
                    label: "",
                    name: "id",
                    hidden: true,
                },
            },
            inline: [
                {
                    start_date: {
                        dataMapKey: "client_product.start_date",
                        props: {
                            className: "--start_date",
                            component: Form.SemanticInput,
                            label: "Start date",
                            name: "start_date",
                            required: true,
                            type: "date",
                        },
                    },
                },
                {
                    end_date: {
                        dataMapKey: "client_product.end_date",
                        props: {
                            className: "--end_date",
                            component: Form.SemanticInput,
                            label: "End date",
                            name: "end_date",
                            required: true,
                            type: "date",
                        },
                    },
                },
            ],
            usages: {
                optionsKey: "usages",
                props: {
                    component: Form.SemanticCheckboxTree,
                    label: "Usage",
                    name: "usages",
                    required: true,
                },
            },
            languages: {
                optionsKey: "languages",
                props: {
                    component: Form.SemanticSelect,
                    label: "Preferred languages",
                    multiple: true,
                    name: "languages",
                },
            },
            process_csid: {
                dataMapKey: 'client_product.process_csid',
                props: {
                    component: Form.SemanticInput,
                    type: 'toggle',
                    name: 'process_csid',
                    label: 'Process CSID',
                    description: <IconInfoCircle tooltip={PROCESS_CSID_INFO()} />,
                },
            },
            enforce_csid: {
                dataMapKey: 'client_product.enforce_csid',
                props: {
                    component: Form.SemanticInput,
                    type: 'toggle',
                    name: 'enforce_csid',
                    label: 'Enforce CSID',
                    description: <IconInfoCircle tooltip={ENFORCE_CSID_INFO()} />,
                },
            },
            is_trial: {
                dataMapKey: "client_product.is_trial",
                props: {
                    component: Form.SemanticInput,
                    label: "Trial",
                    name: "is_trial",
                    type: "checkbox",
                },
            },
            is_disabled: {
                dataMapKey: "client_product.is_disabled",
                props: {
                    component: Form.SemanticInput,
                    label: "Disabled",
                    name: "is_disabled",
                    type: "checkbox",
                },
            },
        },
        remapBaseDatamapKeys: (entity) => {
            const fields = ['id' , 'process_csid' , 'enforce_csid' , 'is_trial' , 'is_disabled'];
            const inlineFields = _get(entity, 'fields.inline' , []);

            fields.forEach((element) => {
                const entityStr = _get(entity, `fields.${element}.dataMapKey`);

                if (entityStr) {
                    entity.fields[element].dataMapKey = `${entity.entityDataMapKey}.${entityStr}`;
                }
            });

            inlineFields.forEach((element , index) => {
                _keys(element).forEach((propertyName) => {
                    const entityStr = _get(entity, `fields.inline[${index}].${propertyName}.dataMapKey`);

                    if (entityStr) {
                        entity.fields.inline[index][propertyName].dataMapKey = `${entity.entityDataMapKey}.${entityStr}`;
                    }
                });
            });
        } ,
    };
}
