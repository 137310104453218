import {
    findIndex as _findIndex,
    get as _get,
    isEmpty as _isEmpty,
    sortBy as _sortBy,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt, getIdsFromChildren, prepareClientProductUsages} from '@utils/helpers';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from '@constants/variables';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {getAdditionalBackButton} from '@modules/events/utils/eventsBookingGeoRestriction';

export class GeoRestrictionsForm extends DefaultForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;

    prepareDataForSubmit = (data) => {
        const countries = getIdsFromChildren(data.countries),
            subdivisions = getIdsFromChildren(data.subdivisions),
            preparedData = this.props.Model.editForm
                ? this.props.stepsMethods.getForms()['step1'].formValues
                : this.props.previousStepData;

        return {
            id: convertToInt(data.id),
            event: convertToInt(this.props.formParams.optionsVariables.event),
            client_selection_type: convertToInt(preparedData.client_selection_type),
            clients: preparedData.clients.map((client) => convertToInt(client)),
            includes_all_event_contents: preparedData.includes_all_event_contents,
            event_contents: preparedData.includes_all_event_contents ? [] : preparedData.event_contents,
            usages: prepareClientProductUsages(preparedData.usages),
            notes: preparedData.notes || '',
            territory_selection_type: convertToInt(data.selection_type),
            countries: countries,
            country_subdivisions: subdivisions,
        };
    };

    onFormSubmit = (data) => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', '')).toLocaleLowerCase()
            ));
        } else {
            return super.onFormSubmit(data);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate();
        super.receivedPropsValidator(prevProps);

        const {
            GraphQLOptionsData: {
                continents,
                countries,
            },
        } = this.props;

        if (this.props.GraphQLOptionsData
            && this.props.GraphQLOptionsData !== prevProps.GraphQLOptionsData) {
            continents && this.setState(() => ({
                continents,
            }));

            countries && this.setState(() => ({
                countries,
            }));
        }

        if (this.props.GraphQLEntityData
            && this.props.GraphQLEntityData !== prevProps.GraphQLEntityData) {
            const entityData = _get(this.props, 'GraphQLEntityData', {}),
                restriction = !_isEmpty(entityData) ? this.getEntityGeoRestrictions(entityData) : {},
                territorySelectionTypeId = convertToInt(_get(
                    restriction,
                    'territory_selection_type.id',
                    TERRITORY_SELECTION_TYPE_WORLDWIDE
                )),
                restrictionCountries = _get(restriction, 'countries', []),
                restrictionSubdivisions = _get(restriction, 'country_subdivisions', []);

            this.setState(() => ({
                restriction,
                territorySelectionTypeId,
                restrictionCountries,
                restrictionSubdivisions,
            }));
        }

        if (!this.state.territorySelectionTypeGenerated
            && this.state.territorySelectionTypeId
            && this.state.territorySelectionTypeId !== prevState.territorySelectionTypeId
            && TERRITORY_SELECTION_TYPE_WORLDWIDE !== this.state.territorySelectionTypeId) {
            this.setField('selection_type', {
                defaultValue: this.state.territorySelectionTypeId.toString(),
            });
            this.setField('countries', {
                hidden: false,
            });

            this.setState(() => ({territorySelectionTypeGenerated: true}));
        }

        if (this.state.continents && this.state.continents !== prevState) {
            if (!this.state.countriesGenerated) {
                this.setField('countries', {
                    options: this.getCountryTreeOptions({
                        continents: this.state.continents,
                        selectedCountries: [],
                    }),
                });

                this.setState(() => ({countriesGenerated: true}));
            }

            if (!this.state.countriesDataGenerated
                && this.state.restrictionCountries
                && 0 < this.state.restrictionCountries.length) {
                this.setField('countries', {
                    options: this.getCountryTreeOptions({
                        continents: this.state.continents,
                        selectedCountries: this.state.restrictionCountries,
                    }),
                });

                this.setState(() => ({countriesDataGenerated: true}));
            }
        }

        if (!this.state.subdivisionsDataGenerated
            && this.state.countries
            && this.state.restrictionCountries
            && this.state.restrictionSubdivisions
            && (0 < this.state.restrictionSubdivisions.length
                || this.checkSubdivisionsAnyCountry(this.state.countries, this.state.restrictionCountries))
        ) {
            this.setField('subdivisions', {
                hidden: false,
                options: this.getSubdivisionTreeOptions({
                    countries: countries,
                    selectedCountries: this.state.restrictionCountries,
                    selectedSubdivisions: this.state.restrictionSubdivisions,
                }),
            });

            this.setState(() => ({subdivisionsDataGenerated: true}));
        }

        if (this.props.formValues.selection_type
            && this.props.formValues.selection_type !== prevProps.formValues.selection_type) {
            this.setState(() => ({
                territorySelectionTypeId: convertToInt(this.props.formValues.selection_type),
            }));

            this.setField('selection_type', {
                onChangeSelect: (event, selectedOptions) => {
                    const worldwideTerritorySelectedType =
                        (TERRITORY_SELECTION_TYPE_WORLDWIDE === convertToInt(selectedOptions.value)
                            || _isEmpty(selectedOptions.value)
                        );
                    let continentsList = [],
                        countriesList = [];

                    if (!worldwideTerritorySelectedType) {
                        const previousCountries = [],
                            previousSubdivisions = [];

                        this.props.formValues.countries.forEach((continent) => {
                            continent.children.forEach((country) => {
                                previousCountries.push(country);
                            });
                        });
                        this.props.formValues.subdivisions.forEach((country) => {
                            country.children.forEach((subdivision) => {
                                previousSubdivisions.push(subdivision);
                            });
                        });

                        continentsList = this.getCountryTreeOptions({
                            continents: this.state.continents,
                            selectedCountries: previousCountries,
                        });
                        countriesList = this.getSubdivisionTreeOptions({
                            countries: this.state.countries,
                            selectedCountries: previousCountries,
                            selectedSubdivisions: previousSubdivisions,
                        });
                    }

                    this.setField('countries', {
                        hidden: worldwideTerritorySelectedType,
                        options: continentsList,
                    });
                    this.setField('subdivisions', {
                        hidden: countriesList.every((country) => {
                            return true === country.hidden;
                        })
                        || worldwideTerritorySelectedType,
                        options: countriesList,
                    });
                },
            });
        }

        if (this.state.countries && this.state.countries !== prevState.countries) {
            this.setCountriesOnChangeCallback(this.state.countries);
        }

        if (!_isEmpty(this.props.stepsMethods) && this.props !== prevProps) {
            this.props.stepsMethods.setForm('step2', this.props);
        }
    }

    setCountriesOnChangeCallback(optionsCountries, countriesFieldName = 'countries', subdivisionsFieldName = 'subdivisions') {
        this.setField(countriesFieldName, {
            onChangeCallback: (event, selectedOptions) => {
                let generalHidden = true;

                const countryOptions = [],
                    selectedCountries = [],
                    selectedSubdivisions = [];

                selectedOptions.forEach((option) => {
                    const country = option.split('.');

                    if (country[1]) {
                        selectedCountries.push(country[1].split('-')[1]);
                    }
                });

                this.props.formValues[subdivisionsFieldName].forEach((country) => {
                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        country.children.forEach((subdivision) => {
                            selectedSubdivisions.push(subdivision.value);
                        });
                    }
                });

                optionsCountries.forEach((country) => {
                    let hidden = true;

                    const subdivisions = [];

                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        hidden = false;
                        generalHidden = false;
                    }

                    country.children.forEach((subdivision) => {
                        subdivisions.push({
                            ...subdivision,
                            selected: -1 < selectedSubdivisions.indexOf(subdivision.value.replace(/-/g, '')),
                        });
                    });

                    if (!hidden) {
                        countryOptions.push({
                            ...country,
                            children: _sortBy(subdivisions, 'name'),
                            hidden: hidden,
                        });
                    }
                });

                this.setField(subdivisionsFieldName, {
                    hidden: generalHidden,
                    options: countryOptions,
                });
            },
        });
    }

    getCountryTreeOptions(data) {
        const selectedOptions = [],
            continentOptions = [];

        if (data.selectedCountries) {
            data.selectedCountries.forEach((country) => {
                selectedOptions.push(country.id);
            });
        }

        data.continents.forEach((continent) => {
            const countryOptions = [];

            continent.children.forEach((country) => {
                let selected = false;

                if (-1 < selectedOptions.indexOf(country.id)) {
                    selected = true;
                }

                countryOptions.push({
                    ...country,
                    selected: selected,
                });
            });

            continentOptions.push({
                ...continent,
                children: _sortBy(countryOptions, 'name'),
            });
        });

        return continentOptions;
    }

    getSubdivisionTreeOptions(data) {
        const selectedCountries = data.selectedCountries.map((option) => {
                return option.id;
            }),
            selectedSubdivisions = data.selectedSubdivisions.map((option) => {
                return option.id;
            }),
            countryOptions = [];

        data.countries.forEach((country) => {
            let hidden = true;

            const subdivisionOptions = [];

            if (-1 < selectedCountries.indexOf(country.id)) {
                hidden = false;

                country.children.forEach((child) => {
                    subdivisionOptions.push({
                        ...child,
                        selected: -1 < selectedSubdivisions.indexOf(child.id),
                    });
                });
            }

            countryOptions.push({
                ...country,
                hidden: hidden,
                children: _sortBy(subdivisionOptions, 'name'),
            });
        });

        return countryOptions;
    }

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'eventGeoRestriction', {});
    };

    checkSubdivisionsAnyCountry = (countries = [], selectedCountries = []) => {
        for (const country of countries) {
            if (-1 !== _findIndex(
                selectedCountries,
                (selectedCountry) => selectedCountry.id === country.id
            )) {
                return true;
            }
        }

        return false;
    };

    renderAdditionalButtons = () => (
        getAdditionalBackButton(this.props.Model.editForm, this.props.stepsMethods.showPreviousStep)
    );
}

export default GeoRestrictionsForm;
