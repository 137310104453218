import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {AKAMAI_LIVE_STREAM_NAME} from "../../app/constants/resources";
import validate from "./validatorAkamaiLiveStreamName";

import {
    CreateAkamaiLiveStreamName,
    DeleteAkamaiLiveStreamName,
    UpdateAkamaiLiveStreamName
} from "../../../graphql/akamaiLiveStreamName/mutation.graphql";
import {
    GetAkamaiLiveStreamNameForForm,
    GetDropdownValuesForAkamaiLiveStreamNameForm
} from "../../../graphql/akamaiLiveStreamName/query.graphql";

export default function AkamaiLiveStreamNameModel() {
    return {
        deleteId: "akamaiLiveStreamName.id",
        entityDataMapKey: "akamaiLiveStreamName",
        entityLabel: "Akamai Live stream name",
        formName: "Akamai Live stream name",
        resources: AKAMAI_LIVE_STREAM_NAME,
        showChangeLog: true,
        validate: validate,
        title: () =>  "Add new Akamai Live stream name",
        dataMap: {
            akamaiLiveStreamName: {
                id: "",
                encoding_datacenter: {
                    id: ""
                },
                stream_name: "",
                stream_protocol: {
                    id: ""
                },
                description: ""
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('Akamai Live stream name')
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "akamaiLiveStreamName.id",
                props: {
                    label: '',
                    name: "id",
                    hidden: true,
                },
            },
            encoding_datacenter: {
                dataMapKey: "akamaiLiveStreamName.encoding_datacenter.id",
                optionsKey: "encodingDatacenters",
                props: {
                    className: "--auto_width",
                    component: Form.SemanticSelect,
                    label: "Encoding datacenter",
                    name: "encoding_datacenter",
                    required: true,
                },
            },
            stream_name: {
                dataMapKey: "akamaiLiveStreamName.stream_name",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    label: "Stream name",
                    name: "stream_name",
                    required: true,
                    type: "input",
                },
            },
            stream_protocol: {
                dataMapKey: "akamaiLiveStreamName.stream_protocol.id",
                optionsKey: "streamProtocols",
                props: {
                    className: "--auto_width",
                    component: Form.SemanticSelect,
                    label: "Stream protocol",
                    name: "stream_protocol",
                    required: true,
                },
            },
            description: {
                dataMapKey: "akamaiLiveStreamName.description",
                props: {
                    className: "--description-medium-width",
                    component: Form.SemanticInput,
                    label: "Description",
                    name: "description",
                    type: "input",
                },
            },
        },
        mutation: {
            createEntity: CreateAkamaiLiveStreamName,
            deleteEntity: DeleteAkamaiLiveStreamName,
            updateEntity: UpdateAkamaiLiveStreamName,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetAkamaiLiveStreamNamesForList'],
            },
            update: {
                refetchQueries: ['GetAkamaiLiveStreamNamesForList'],
            },
            delete: {
                refetchQueries: ['GetAkamaiLiveStreamNamesForList'],
            },
        },
        query: {
            getEntity: GetAkamaiLiveStreamNameForForm,
            getOptions: GetDropdownValuesForAkamaiLiveStreamNameForm,
        },
    };
}
