import validators from '../../../utils/validators';
import {isEmpty as _isEmpty} from "lodash";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 50}, 'name'));

    if (_isEmpty(values.svg)) {
        Object.assign(errors, {file: "No proper file chosen."});
    }

    return errors;
};

export default validate;
