import {get as _get} from "lodash"

import ProductionCompanyContactPersonModel from "./ProductionCompanyContactPersonModel";

export default function ProductionCompanyContactPersonModelEdit() {
    const contactPersonModelEdit = ProductionCompanyContactPersonModel();

    contactPersonModelEdit.title = (data) => {
        const contactPersonName = _get(data, "contactPerson.name", null);

        return contactPersonName ? `Contact person "${contactPersonName}"` : '';
    };

    return contactPersonModelEdit;
}
