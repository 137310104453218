import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";

import {RTMP_ORIGIN_ENTRY_POINT, RTMP_ORIGIN_ENTRY_POINT_APPLICATION} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE_READ, SECURITY_PRIVILEGES_READ} from "../../app/constants/variables";

const ApplicationsIndexButtons = (id) => {
    const applicationAuthorizationObject = {
        [RTMP_ORIGIN_ENTRY_POINT]: SECURITY_PRIVILEGES_READ,
        [RTMP_ORIGIN_ENTRY_POINT_APPLICATION]: SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="content__buttons">
            <Authorization authorization={applicationAuthorizationObject}>
                <Link name={'rtmpOriginEntryPoint.applications.add'} params={id}>
                    <ButtonAdd>{'Add new application'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
}

ApplicationsIndexButtons.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ApplicationsIndexButtons;
