import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {
    includes as _includes
} from "lodash"

import PackagesIndex from "./PackagesIndex";
import {routes} from "../../app/constants/routes";
import ClientPackageLayout from "../layouts/ClientPackageLayout";

class PackagesTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getTabRenderState(props);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.getTabRenderState(nextProps));
    }

    getTabRenderState(props) {
        const clientId = parseInt(props.match.params.id, 10);
        const packagesRoute = routes.clients.packages;

        let viewToRender = <PackagesIndex clientId={clientId}/>,
            renderClientPackageLayout = _includes(
                [
                    packagesRoute.add.path,
                    packagesRoute.edit.path,
                    packagesRoute.geoRestrictions.index.path,
                    packagesRoute.geoRestrictions.add.path,
                    packagesRoute.geoRestrictions.edit.path,
                    packagesRoute.geoRestrictions.editGeoRestrictions.path,
                    packagesRoute.content.index.path,
                    packagesRoute.content.geoRestrictions.path,
                ],
                props.match.path
            );

        if (renderClientPackageLayout) {
            viewToRender = <ClientPackageLayout/>
        }

        return {viewToRender: viewToRender};
    }

    render() {
        return (
            this.state.viewToRender
        )
    }
}

PackagesTab.propTypes = {
    match: PropTypes.object
};

export default withRouter(PackagesTab)
