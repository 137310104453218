import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from "react-router";

import Table from "../../app/components/Table";

import {rowRenderer} from "../utils/RtmpOriginStreamNamesTableRowRenderer";

import {columns} from "../constants/RtmpOriginStreamNamesTableColumns";

const RtmpOriginStreamNamesTable = ({rtmpOriginStreamNames = [], loading, match: {params: {id}}}) => (
    <div className="rtmp_origin_stream_names__table">
        <Table
            columns={columns}
            data={rtmpOriginStreamNames}
            loading={loading}
            name="rtmpOriginStreamNamesList"
            noDataText="No RTMP origin stream names found."
            rowRenderer={rowRenderer}
            rowRendererProps={{id}}
        />
    </div>
);

RtmpOriginStreamNamesTable.propTypes = {
    rtmpOriginStreamNames: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(RtmpOriginStreamNamesTable);
