import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.stream_name, 'stream_name'));
    Object.assign(errors, validators.maxLength({value: values.stream_name, maxLength: 200}, 'stream_name'));
    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));
    Object.assign(errors, validators.required(values.third_party_client_pull_origin, 'third_party_client_pull_origin'));

    return errors;
};

export default validate;
