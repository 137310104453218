import React from "react";
import PropTypes from "prop-types";

import {RenderBackToListButton} from "../../app/components/ButtonCollection";
import SegmentHeader from "../../app/components/SegmentHeader";
import Tabs from "../../app/components/Tabs";

import {createTabs} from "../utils/rightsScopeTabsLayoutHelper";

export const RightsScopeTabsLayout = ({path, propertyLicenceId, rightsScopeId = null}) => (
    <div>
        <SegmentHeader buttons={
            <RenderBackToListButton
                name={'propertyLicences.rightsScopes.index'}
                params={{id: propertyLicenceId}}
                text={' Back to rights scopes'}
            />
        }>
            {rightsScopeId ? 'Edit rights scope' : 'Add rights scope'}
        </SegmentHeader>
        <Tabs items={createTabs(propertyLicenceId, path, rightsScopeId)} active={path} level={2}/>
    </div>
);

RightsScopeTabsLayout.propTypes = {
    path: PropTypes.string.isRequired,
    propertyLicenceId: PropTypes.string.isRequired,
    rightsScopeId: PropTypes.string,
};
RightsScopeTabsLayout.displayName = 'RightsScopeTabsLayout';

export default RightsScopeTabsLayout;
