import {withRouter} from "react-router";
import {get as _get} from "lodash";

import ProductGeoRestrictionsForm from "./ProductGeoRestrictionsForm";

import {convertToInt} from "../../../utils/helpers";

class ProductLcppGeoRestrictionsForm extends ProductGeoRestrictionsForm {
    getEntityClientProductId = () => {
        return convertToInt(_get(this.props.GraphQLEntityData, 'clientProductsLcpp[0].id', 0));
    };

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'clientProductsLcpp[0].client_product', {});
    };
}

export default withRouter(ProductLcppGeoRestrictionsForm);
