import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import mapModulesToProps from '@utils/mapModulesToProps';
import useQuery from '@utils/hooks/useQuery';
import navigation from '@constants/navigation';
import {HAS_ACCREDITATION, IS_ARCHIVED, IS_TV_STREAMING} from '@constants/staticDropdowns';
import {
    PROPERTY_LICENCE_TYPE_SPORTRADAR,
    PROPERTY_LICENCE_TYPE_OTHER,
    PROPERTY_LICENCE_TYPE_RACE,
} from '@constants/variables';
import {
    GetPropertyLicencesForView,
    GetOptionsForPropertyLicenceList,
} from '@graphql/propertyLicence/query';

import {exportExcel} from '../utils/export/propertyLicenceIndexExcel';
import {renderPropertyLicenceModalError} from '../utils/propertyLicenceHelper';
import PropertyLicenceTable from '../components/PropertyLicenceTable';
import PropertyLicenceIndexButtons from '../components/PropertyLicenceIndexButtons';
import PropertyLicenceFilters from '../components/PropertyLicenceFilters';

export const DEFAULT_FILTERS_VALUES = {
    search: null,
    licensors: [],
    licensees: [],
    propertyLicenceType: null,
    propertyLicenceStatus: null,
    sports: [],
    contentTiers: [],
    contentCategories: [],
    contentPackageTypes: [],
    contentManagerUsers: [],
    tournamentCategories: [],
    tournaments: [],
    contentCategoriesLvl2: [],
    contentCategoriesLvl3: [],
    hasAccreditation: null,
    isTvStreaming: null,
    isArchived: 0,
};

const PropertyLicenceIndex = ({areFiltersVisible = true, filters = {}, Menu, Modal}) => {
    const queryVariables = {
        search: filters.search || DEFAULT_FILTERS_VALUES.search,
        licensors: filters.licensors
            ? filters.licensors.map(Number)
            : DEFAULT_FILTERS_VALUES.licensors,
        licensees: filters.licensees
            ? filters.licensees.map(Number)
            : DEFAULT_FILTERS_VALUES.licensees,
        property_licence_type:
            convertToInt(filters.propertyLicenceType || DEFAULT_FILTERS_VALUES.propertyLicenceType),
        content_category: (
            (PROPERTY_LICENCE_TYPE_OTHER === convertToInt(filters.propertyLicenceType))
            && filters.contentCategory
        )
            ? filters.contentCategory.map(Number)
            : DEFAULT_FILTERS_VALUES.contentCategories,
        sport: (([
            PROPERTY_LICENCE_TYPE_SPORTRADAR,
            PROPERTY_LICENCE_TYPE_RACE
        ].includes(convertToInt(filters.propertyLicenceType))) && filters.sport)
            ? filters.sport.map(Number)
            : DEFAULT_FILTERS_VALUES.sports,
        content_tier: filters.contentTier
            ? filters.contentTier.map(Number)
            : DEFAULT_FILTERS_VALUES.contentTiers,
        content_package_type: filters.contentPackageType
            ? filters.contentPackageType.map(Number)
            : DEFAULT_FILTERS_VALUES.contentPackageTypes,
        content_manager_user: filters.contentManagerUser
            ? filters.contentManagerUser.map(Number)
            : DEFAULT_FILTERS_VALUES.contentManagerUsers,
        hasAccreditation:
            convertBooleanValueForFilters(filters.hasAccreditation, DEFAULT_FILTERS_VALUES.hasAccreditation),
        isTvStreaming: convertBooleanValueForFilters(filters.isTvStreaming, DEFAULT_FILTERS_VALUES.isTvStreaming),
        isArchived: (undefined === filters.isArchived) ? false : convertBooleanValueForFilters(filters.isArchived),
        property_licence_status:
            convertToInt(filters.propertyLicenceStatus || DEFAULT_FILTERS_VALUES.propertyLicenceStatus),
        tournamentCategories: filters.tournamentCategories
            ? filters.tournamentCategories.map(Number)
            : DEFAULT_FILTERS_VALUES.tournamentCategories,
        tournaments: filters.tournaments
            ? filters.tournaments.map(Number)
            : DEFAULT_FILTERS_VALUES.tournaments,
        contentCategoriesLvl2: filters.contentCategoriesLvl2
            ? filters.contentCategoriesLvl2.map(Number)
            : DEFAULT_FILTERS_VALUES.contentCategoriesLvl2,
        contentCategoriesLvl3: filters.contentCategoriesLvl3
            ? filters.contentCategoriesLvl3.map(Number)
            : DEFAULT_FILTERS_VALUES.contentCategoriesLvl3,
    };

    const {
        loading: isDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQuery({
        query: GetPropertyLicencesForView,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
    });

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQuery({
        query: GetOptionsForPropertyLicenceList,
    });

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: renderPropertyLicenceModalError()
        })
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={PropertyLicenceIndexButtons}
                exportExcelParams={exportExcel(data.propertyLicences)}
                filters={PropertyLicenceFilters}
                filtersButtonName="PropertyLicence"
                filtersProps={{
                    data: {
                        propertyLicenceTypes: dataForFilters.propertyLicenceTypes || DEFAULT_FILTERS_VALUES.propertyLicenceType,
                        contentTiers: dataForFilters.contentTiers || DEFAULT_FILTERS_VALUES.contentTiers,
                        contentPackageTypes: dataForFilters.contentPackageTypes || DEFAULT_FILTERS_VALUES.contentPackageTypes,
                        contentManagers: dataForFilters.contentManagers || DEFAULT_FILTERS_VALUES.contentManagers,
                        propertyLicenceStatuses: dataForFilters.propertyLicenceStatuses || DEFAULT_FILTERS_VALUES.propertyLicenceStatus,
                        licensors: dataForFilters.licensors || DEFAULT_FILTERS_VALUES.licensors,
                        licensees: dataForFilters.licenseeDropdown || DEFAULT_FILTERS_VALUES.licensors,
                        tvStreaming: IS_TV_STREAMING,
                        archives: IS_ARCHIVED,
                        accreditation: HAS_ACCREDITATION,
                        sports: dataForFilters.sports
                            ? sortDropdownOptionsAlphanumerically(dataForFilters.sports.mapDataForDropdownWithIntVal())
                            : DEFAULT_FILTERS_VALUES.sports,
                        contentCategories: dataForFilters.contentCategories
                            ? sortDropdownOptionsAlphanumerically(dataForFilters.contentCategories.mapDataForDropdownWithIntVal())
                            : DEFAULT_FILTERS_VALUES.licensors,
                        isVisible: areFiltersVisible,
                    },
                    loading: areFiltersLoading,
                }}
                loading={isDataLoading}
                messagesBoxNames="propertyLicenceIndexMessage"
                pageTitle="Property licences"
            />
            <PropertyLicenceTable
                propertyLicences={data.propertyLicences}
                loading={isDataLoading}
            />
        </div>
    );
};

PropertyLicenceIndex.propTypes = {
    areFiltersVisible: PropTypes.bool,
    filters: PropTypes.shape({
        contentCategory: PropTypes.arrayOf(PropTypes.number),
        contentCategoriesLvl2: PropTypes.arrayOf(PropTypes.number),
        contentCategoriesLvl3: PropTypes.arrayOf(PropTypes.number),
        contentManagerUser: PropTypes.arrayOf(PropTypes.string),
        contentPackageType: PropTypes.arrayOf(PropTypes.string),
        contentTier: PropTypes.arrayOf(PropTypes.string),
        hasAccreditation: PropTypes.number,
        isArchived: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        lastClickSubmit: PropTypes.number,
        licensees: PropTypes.arrayOf(PropTypes.string),
        licensors: PropTypes.arrayOf(PropTypes.string),
        propertyLicenceStatus: PropTypes.string,
        propertyLicenceType: PropTypes.string,
        search: PropTypes.string,
        sport: PropTypes.arrayOf(PropTypes.number),
        timestamp: PropTypes.number.isRequired,
        tournamentCategories: PropTypes.arrayOf(PropTypes.number),
        tournaments: PropTypes.arrayOf(PropTypes.number),
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};

const PropertyLicenceIndexWrapped = FilterQueryWrapper(PropertyLicenceIndex, {
    queryForRefresh: "GetPropertyLicencesForView",
    filterUrls: ['propertyLicences.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'PropertyLicence', DEFAULT_FILTERS_VALUES)
});

export default connect(mapStateToProps, mapModulesToProps(['Menu', 'Modal']))(
    FilterUrlParamsWrapper(PropertyLicenceIndexWrapped, DEFAULT_FILTERS_VALUES)
);
