import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from "react-router";

import Authorization from "../../app/components/Authorization"
import {IconEdit} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";
import {getLink} from "../../app/components/Link";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";

import {RTMP_ORIGIN_ENTRY_POINT, RTMP_ORIGIN_ENTRY_POINT_APPLICATION} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_READ} from "../../app/constants/variables";

const ApplicationsTable = ({applications = [], loading, match: {params: {id}}}) => {
    const applicationAuthorizationObject = {
        [RTMP_ORIGIN_ENTRY_POINT]: SECURITY_PRIVILEGES_READ,
        [RTMP_ORIGIN_ENTRY_POINT_APPLICATION]: SECURITY_PRIVILEGES_READ
    };

    const columns = {
        "id": {
            label: "ID",
            sorting: (row) => parseInt(row.id, 10),
            header: {
                align: "center",
                isCollapsed: true
            },
            content: {
                align: "center"
            }
        },
        "name": {
            label: "Name",
            sorting: (row) => (row.name === null) ? "" : row.name.capitalizeFirstLetter()
        },
        "rtmp_origin_entry_point_application_type": {
            label: "Type",
            sorting: (row) => (!row.rtmp_origin_entry_point_application_type.name) ? ""
                : row.rtmp_origin_entry_point_application_type.name.capitalizeFirstLetter()
        },
        "uri": {
            label: "URI",
            sorting: (row) => {
                return renderUri(row);
            }
        },
        "actions": {
            label: "Actions",
            header: {
                align: "center",
                isCollapsed: true
            },
            content: {
                align: "center"
            }
        }
    };

    const renderNameOfFirstLevelDependency = (row, column) => {
        return (
            <div>
                {(row[column] === null || row[column] === undefined)
                    ? ""
                    : row[column].name
                }
            </div>
        );
    };

    const renderUri = (row) => {
        let uri = null;

        if ('' !== row.rtmp_origin_entry_point.host) {
            uri = `rtmp://${row.rtmp_origin_entry_point.host}/${row.rtmp_origin_entry_point_application_type.name}/`;
        }

        return uri
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "name":
                return (
                    <LinkWithAuthorization
                        authorization={applicationAuthorizationObject}
                        link={{
                            name: "rtmpOriginEntryPoint.applications.edit",
                            params: {id: id, applicationId: row.id}
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case "rtmp_origin_entry_point_application_type":
                return renderNameOfFirstLevelDependency(row, column);
            case "uri":
                return <div>{renderUri(row)}</div>;
            case "actions": {
                return (
                    <Authorization authorization={applicationAuthorizationObject}>
                        <div className="actions-list-icon">
                            <IconEdit
                                link={getLink("rtmpOriginEntryPoint.applications.edit", {
                                    id: id,
                                    applicationId: row.id,
                                })}
                                size="large"
                                resource={RTMP_ORIGIN_ENTRY_POINT_APPLICATION}
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="applications__table">
            <Table
                columns={columns}
                data={applications}
                loading={loading}
                name="applicationsList"
                noDataText="No applications found"
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ApplicationsTable.propTypes = {
    applications: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    rtmpOriginEntryPointHost: PropTypes.string.isRequired,
};

export default withRouter(ApplicationsTable);
