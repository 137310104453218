import React from "react";
import PropTypes from "prop-types";

import ClientPackageContentFormEdit from "../forms/ClientPackageContentFormEdit";
import ClientPackageContentFormAdd from "../forms/ClientPackageContentFormAdd";

class ClientPackageEdit extends React.Component {
    static propTypes = {
        clientId: PropTypes.string,
        loading: PropTypes.bool,
        packageId: PropTypes.string,
        setLoading: PropTypes.func
    };

    contentToRender() {
        if (this.props.packageId) {
            return <ClientPackageContentFormEdit
                clientId={this.props.clientId}
                packageId={this.props.packageId}
                setLoading={this.props.setLoading}
                loading={this.props.loading}/>
        }

        return <ClientPackageContentFormAdd clientId={this.props.clientId} loading={false}/>
    }

    render() {
        return (
            this.contentToRender()
        )
    }
}

export default ClientPackageEdit