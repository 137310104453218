import PropTypes from 'prop-types';
import React from 'react';
import {get as _get} from 'lodash';

import Authorization from '@appComponents/Authorization';
import {IconCheckmark, IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {CLIENT_CDN_CONFIGURATION} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';

const columns = {
    'id': {
        label: 'ID',
        sorting: row => parseInt(row.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: true,
    },
    'client_product': {
        label: 'Product',
        sorting: (row) => row.client_product.product.short_name,
    },
    'cdn': {
        label: 'CDN',
        sorting: (row) => row.cdn.name,
    },
    'live_ingest_method': {
        label: 'Live ingest method',
        sorting: (row) => _get(row, 'live_ingest_method.name'),
    },
    'recording_ingest_method': {
        label: 'Recording ingest method',
        sorting: (row) => _get(row, 'recording_ingest_method.name'),
    },
    'akamai_cdn_ingest_method': {
        label: 'Akamai CDN ingest method',
        sorting: (row) => _get(row, 'akamai_cdn_ingest_method.name', null),
    },
    'has_external_storage': {
        label: 'External storage?',
        sorting: true,
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'live_encoding_job_profile': {
        label: 'Live encoding job profile',
        sorting: (row) => _get(row, 'live_encoding_job_profile.name', null),
    },
    'recording_encoding_job_profile': {
        label: 'Recording encoding job profile',
        sorting: (row) => _get(row, 'recording_encoding_job_profile.name', null),
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};

const ClientCdnConfigurationTable = (props) => {
    const {clientCdnConfigurations, clientId, loading} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'name':
                return (
                    <LinkWithAuthorization
                        resources={CLIENT_CDN_CONFIGURATION}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'clients.clientCdnConfiguration.edit',
                            params: {
                                id: clientId,
                                clientCdnConfigurationId: parseInt(row.id, 10),
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'client_product':
                return row[column].product.short_name;
            case 'cdn':
            case 'akamai_cdn_ingest_method':
            case 'live_ingest_method':
            case 'recording_ingest_method':
            case 'live_encoding_job_profile':
            case 'recording_encoding_job_profile':
                return row[column] !== null ? row[column]?.name : null;
            case 'has_external_storage':
                return row[column] ? <IconCheckmark/> : null;
            case 'actions':
                return (
                    <Authorization
                        resources={CLIENT_CDN_CONFIGURATION}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                    >
                        <div className="actions-list-icon">
                            <IconEdit
                                link={getLink('clients.clientCdnConfiguration.edit', {
                                    id: clientId,
                                    clientCdnConfigurationId: parseInt(row.id, 10),
                                })}
                                size="large"
                            />
                        </div>
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className="client_cdn_configurations__table">
            <Table
                name="clientCdnConfigurationList"
                noDataText="No CDN configurations found."
                columns={columns}
                data={clientCdnConfigurations}
                loading={loading}
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

ClientCdnConfigurationTable.defaultProps = {
    clientCdnConfigurations: [],
};

ClientCdnConfigurationTable.propTypes = {
    clientCdnConfigurations: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ClientCdnConfigurationTable;
