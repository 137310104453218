import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";
import {sortByTextValue} from "../../../utils/sorters";
import {orderBy} from "lodash";

class EventContentTypesFilters extends React.Component {

    renderDropdown = (name, options, classname, label, hasShortName = false) => {
        const { filters, loading} = this.props;

        return (
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name={name}
                options={options.mapDataForDropdownWithIntVal(hasShortName)}
                placeholder={label}
                search
                setValue={this.props.restoreFilterValues(filters.eventContentTypes, name)}
                selection
            />
        );
    };

    render() {
        const {data: {eventTypes, sports, firstLevelCategories, distributionTypes, products}, loading, filters} = this.props;

        const sortedSports = sortByTextValue(sports, 'name');
        const sortedFirstLevelCategories = sortByTextValue(firstLevelCategories, 'name');
        const sortedProducts = orderBy(products, ['short_name'], ['asc']);

        return (
            <div className="eventContentTypes__filters filter__controls__container">
                <Field
                    component={Form.ReduxInput}
                    name="search"
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(filters.eventContentTypes, 'search')}
                    placeholder="Search..."
                />

                {this.renderDropdown("distributionType", distributionTypes, "distributionType", "Distribution type")}
                {this.renderDropdown("products", sortedProducts, "products", "Product", true)}
                {this.renderDropdown("eventType", eventTypes, "eventType", "Event type")}
                {this.renderDropdown("sport", sortedSports, "sport", "Sport")}
                {this.renderDropdown("firstLevelCategory", sortedFirstLevelCategories, "firstLevelCategory", "1st level category")}
            </div>
        );
    }
}

EventContentTypesFilters.propTypes = {
    data: PropTypes.shape({
        eventTypes: PropTypes.array,
        products: PropTypes.array,
        sports: PropTypes.array,
        firstLevelCategories: PropTypes.array,
        distributionTypes: PropTypes.array,
    }),
    filters: PropTypes.object,
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired
};

EventContentTypesFilters.defaultProps = {
    data: {
        eventTypes: [],
        products: [],
        sports: [],
        firstLevelCategories: [],
        distributionTypes: [],
    },
    filters: {},
    loading: false
};

export default  FiltersWrapper(EventContentTypesFilters, {name: "eventContentTypes"});

