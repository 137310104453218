import React from "react";

import Form from "../ReduxFormControls";

import {convertToInt} from "../../../../utils/helpers";

const RenderDeviceCategoriesCheckboxes = (deviceCategories, defaultValues) => {
    return deviceCategories.map((deviceCategory) => {
        return (
            <div className="checkboxContainer" key={`container_device_category_${deviceCategory.id}`}>
                <Form.FormField
                    id={`deviceCategory`+deviceCategory.id}
                    component={Form.SemanticInput}
                    defaultValue={(defaultValues.indexOf(convertToInt(deviceCategory.id)) > -1)}
                    description={deviceCategory.text}
                    key={`device_category_${deviceCategory.id}`}
                    name={`device_category._${deviceCategory.id}`}
                    type="checkbox"
                />
            </div>
        )
    });
};

export default RenderDeviceCategoriesCheckboxes;
