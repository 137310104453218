import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from '@constants/variables';
import {CLIENT_PACKAGE_CONTENT} from '@constants/resources';
import {GeoRestrictionsDropdownOptionsForCountries} from '@graphql/eventGeoRestriction/query';
import {
    CreateClientPackageContentGlobeIcon,
    UpdateClientPackageContentGlobeIcon,
    DeleteClientPackageMutationGlobeIcon,
} from '@graphql/clientPackage/mutation';

import validatorClientPackageGeoRestriction from './validatorClientPackageGeoRestriction';

export default function PackageContentTierGeoRestrictionsModel() {
    return {
        entityDataMapKey: 'clientPackageContent',
        entityLabel: 'Client package content',
        formName: 'ClientPackageContent',
        resources: CLIENT_PACKAGE_CONTENT,
        showChangeLog: false,
        title: null,
        validate: validatorClientPackageGeoRestriction,
        dataMap: {
            eventGeoRestriction: {
                selection_type: {
                    id: TERRITORY_SELECTION_TYPE_WORLDWIDE.toString(),
                },
            },
        },
        messages: {
            boxName: {
                delete: 'clientPackageContent',
                error: 'clientPackageContent',
                success: 'clientPackageContent',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'clientPackageContent.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            selection_type: {
                optionsKey: 'territorySelectionTypes',
                dataMapKey: 'clientPackageContent.territory_selection_type.id',
                props: {
                    component: Form.SemanticSelect,
                    defaultValue: TERRITORY_SELECTION_TYPE_WORLDWIDE + '',
                    label: 'Selection type',
                    name: 'selection_type',
                    required: true,
                },
            },
            countries: {
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Countries',
                    name: 'countries',
                },
            },
            subdivisions: {
                optionsKey: 'subdivisions',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Subdivisions',
                    name: 'subdivisions',
                },
            },
        },
        mutation: {
            createEntity: CreateClientPackageContentGlobeIcon,
            updateEntity: UpdateClientPackageContentGlobeIcon,
            deleteEntity: DeleteClientPackageMutationGlobeIcon,
        },
        query: {
            getOptions: GeoRestrictionsDropdownOptionsForCountries,
        },
    };
}
