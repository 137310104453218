import React from 'react';
import PropTypes from 'prop-types';

import ContactPersonFormAdd from '../components/ContactPersonFormAdd';
import ContactPersonFormEdit from '../components/ContactPersonFormEdit';

class ContactPersonEdit extends React.Component{

    constructor(props) {
        super(props);

        this.hasId = props.id !== undefined && !(isNaN(props.id));
    }

    render() {
        const FormToRender = this.hasId
            ? ContactPersonFormEdit
            : ContactPersonFormAdd
        ;

        return (
            <FormToRender
                parent={this.props.parent}
                personType={this.props.personType}
                id={this.props.id}
                licencorsName={this.props.parent.licencorsName}
            />
        );
    }
}


ContactPersonEdit.propTypes = {
    parent: PropTypes.object,
    id: PropTypes.number,
    personType: PropTypes.number,
    licencorsName: PropTypes.string.isRequired,
};

export default ContactPersonEdit;
