import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {compose} from "redux";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import {FilterQueryWrapper, FilterUrlParamsWrapper} from "../../app/components/HOCFiltersQueryWrapper";
import RtmpOriginEntryPointIndexButtons from "../components/RtmpOriginEntryPointIndexButtons";
import RtmpOriginEntryPointTable from "../components/RtmpOriginEntryPointTable";
import RtmpOriginEntryPointFilters from "../components/RtmpOriginEntryPointFilters";

import {exportExcel} from "../utils/export/rtmpOriginEntryPointIndexExcel";
import {getSearchFilters} from "../../../utils/filters";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import navigation from "../../app/constants/navigation";

import {
    GetRtmpOriginEntryPointsForTable,
    GetRtmpOriginEntryPointForFormDropdowns,
} from "../../../graphql/rtmpOriginEntryPoint/query.graphql";

export const DEFAULT_FILTERS_VALUES = {
    search: "",
    rtmpOriginEntryPointTypes: [],
    originHostingProviders: [],
    isActive: null
};

const RtmpOriginEntryPointIndex = ({
    DataRtmpOriginEntry = {
        rtmpOriginEntryPoints: DEFAULT_FILTERS_VALUES.rtmpOriginEntryPointTypes,
        loading: false
    },
    DataFilters = {
        rtmpOriginEntryPointTypes: DEFAULT_FILTERS_VALUES.rtmpOriginEntryPointTypes,
        originHostingProviders: DEFAULT_FILTERS_VALUES.originHostingProviders,
        loading: false
    },
    Menu
}) => {
    React.useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={RtmpOriginEntryPointIndexButtons}
                exportExcelParams={exportExcel(DataRtmpOriginEntry.rtmpOriginEntryPoints)}
                filters={RtmpOriginEntryPointFilters}
                filtersButtonName="rtmpOriginEntryPoints"
                filtersProps={{
                    data: {
                        ...DataFilters,
                        isActive: [{text: "Active", value: 1}, {text: "Not active", value: 0}]
                    }
                }}
                loading={DataRtmpOriginEntry.loading}
                messagesBoxNames="rtmpOriginEntryPointMessage"
                pageTitle="RTMP origin entry points"
            />
            <RtmpOriginEntryPointTable
                loading={DataRtmpOriginEntry.loading}
                rtmpOriginEntryPoints={DataRtmpOriginEntry.rtmpOriginEntryPoints}
            />
        </div>
    );
};

RtmpOriginEntryPointIndex.propTypes = {
    DataRtmpOriginEntry: PropTypes.shape({
        rtmpOriginEntryPoints: PropTypes.array,
        loading: PropTypes.bool,
    }),
    DataFilters: PropTypes.shape({
        rtmpOriginEntryPointTypes: PropTypes.array,
        originHostingProviders: PropTypes.array,
        loading: PropTypes.bool,
    }),
    Menu: PropTypes.object,
};

const RtmpOriginEntryPointWithQuery = compose(
    graphql(GetRtmpOriginEntryPointsForTable, {
        options: ({filters: {
            search = DEFAULT_FILTERS_VALUES.search,
            rtmpOriginEntryPointTypes = DEFAULT_FILTERS_VALUES.rtmpOriginEntryPointTypes,
            originHostingProviders = DEFAULT_FILTERS_VALUES.originHostingProviders,
            isActive = DEFAULT_FILTERS_VALUES.isActive
        }}) => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
            variables: {
                search: search,
                rtmpOriginEntryPointType: rtmpOriginEntryPointTypes.map(type => convertToInt(type)),
                originHostingProvider: originHostingProviders.map(provider => convertToInt(provider)),
                isActive: null === isActive ? null : !!isActive
            }
        }),
        name: "DataRtmpOriginEntry"
    }),
    graphql(GetRtmpOriginEntryPointForFormDropdowns, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "cache-and-network",
            }
        },
        name: "DataFilters"
    })
)(FilterQueryWrapper(RtmpOriginEntryPointIndex, {
    queryForRefresh: "DataRtmpOriginEntry",
    filterUrls: ["rtmpOriginEntryPoint.index"]
}));

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'rtmpOriginEntryPoints', DEFAULT_FILTERS_VALUES)
});

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(
    FilterUrlParamsWrapper(RtmpOriginEntryPointWithQuery, DEFAULT_FILTERS_VALUES)
);
