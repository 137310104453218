import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconCheckmark, IconEdit} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import Table from "../../app/components/Table";

import {convertToInt} from "../../../utils/helpers";

import {CLIENT_PRODUCT_GEOLOCATION_COUNTRY} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_UPDATE} from "../../app/constants/variables";

export const columns = {
    "country": {
        label: "Country",
        sorting: true,
    },
    "enforcement_date": {
        content: {
            align: "center",
        },
        header: {
            align: "center",
        },
        label: "Enforcement date",
        sorting: true,
    },
    "has_geolocation_check": {
        content: {
            align: "center",
        },
        header: {
            align: "center",
        },
        label: "Geolocation check",
        sorting: (row) => (row.has_geolocation_check),
    },
    geolocation_check_method: {
        content: {
            align: "left",
        },
        header: {
            align: "left",
        },
        label: "Method",
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
};

export const rowRenderer = (column, row, props) => {
    const {clientId, productUrlKey} = props;

    switch (column) {
        case "country":
            return row.country ? row.country.name : "";
        case "enforcement_date":
            return row[column];
        case "has_geolocation_check":
            return row[column] ? <IconCheckmark/> : null;
        case "geolocation_check_method":
            return row.geolocation_check_method ? row.geolocation_check_method.name : "";
        case "actions":
            return (
                <Authorization
                    resources={CLIENT_PRODUCT_GEOLOCATION_COUNTRY}
                    privileges={SECURITY_PRIVILEGES_UPDATE}
                >
                    <div className="actions-list-icon">
                        <IconEdit
                            link={getLink(`clients.products.${productUrlKey}.geolocation.edit`, {
                                id: clientId,
                                geolocationId: convertToInt(row.id),
                            })}
                            size="large"
                        />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};

const ProductGeolocationsTable = (props) => {
    const {clientId, productGeolocations, loading, productUrlKey} = props;

    return (
        <div className="client_product_geolocation_countries__table">
            <Table
                name="clientProductGeolocationCountriesList"
                noDataText="No countries found."
                columns={columns}
                data={productGeolocations}
                loading={loading}
                rowRenderer={rowRenderer}
                rowRendererProps={{clientId, productUrlKey}}
                pagination={false}
                defaultSort={(row) => row.country.name}
            />
        </div>
    );
};

rowRenderer.propTypes = {
    clientId: PropTypes.number.isRequired,
    productUrlKey: PropTypes.string.isRequired
};
rowRenderer.displayName = 'rowRenderer';

ProductGeolocationsTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    productGeolocations: PropTypes.array,
    productUrlKey: PropTypes.string.isRequired
};
ProductGeolocationsTable.defaultProps = {
    productGeolocations: [],
};
ProductGeolocationsTable.displayName = 'ProductGeolocationsTable';

export default ProductGeolocationsTable;
