import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {getFormMessageForWizard} from '@utils/forms';
import {
    populateClientPackages,
    onCloseModal,
    editValidation,
    setMutationSuccess,
} from '@modules/eventBooking/forms/helpers/helpers';
import {getEventBookingDataFromAllSteps} from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';

export class EventBookingGeneralInformationEditForm extends DefaultForm {
    state = {
        ...this.state,
        clientPackagesLoaded: false,
    };

    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
        this.setDeleteSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
    }

    prepareDataForSubmit = () => {
        this.props.stepsMethods.setForm('step1', {formValues: this.props.formValues});

        return getEventBookingDataFromAllSteps(this.props.stepsMethods, this.props.formParams.id);
    };

    onFormSubmit = (data) => {
        if (editValidation(this.props.stepsMethods)) {
            this.setState(() => ({
                defaultForm_loading: true,
            }));
            super.onFormSubmit(data);
        }
    }

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    componentDidUpdate() {
        this.setClientPackages();
        this.props.stepsMethods.setForm('step1', {formValues: this.props.formValues});
    }

    setClientPackages = () => {
        if (false === this.state.clientPackagesLoaded) {
            const productId = _get(this.props, 'GraphQLEntityData.eventEventBooking.product.id', null);
            const clientId = _get(this.props, 'GraphQLEntityData.eventEventBooking.client.id', null);
            const clientPackage = _get(this.props, 'GraphQLEntityData.eventEventBooking.client_package.id', null);

            if (!_isEmpty(productId) && !_isEmpty(clientId)) {
                this.setState({
                    clientPackagesLoaded: true,
                }, () => {
                    populateClientPackages(this.setField, this.runApolloRequest, clientId, productId);
                    this.setField('client_package', {
                        defaultValue: clientPackage,
                    });
                });
            }
        }
    };

    renderErrors(errorData, childLabel = null, childLink = null, childModalProps = {}) {
        const label = childLabel || 'EventBooking',
            link = childLink || getLink('events.eventBookings.index', {
                id: this.props.formParams.optionsVariables.event || null,
            }),
            modalProps = childModalProps || {size: 'tiny'};

        super.renderErrors(errorData, label, link, modalProps);
    }

    onCancel = () => {
        onCloseModal(
            this.props.formParams.optionsVariables.event,
            this.props.formParams.optionsVariables.client,
            this.props.Modal.setModal,
            this.props
        );
    };

    renderCancelButton = (props) => super.renderCancelButton({
        ...props,
        type: 'button',
        onClick: this.onCancel,
    });
}

EventBookingGeneralInformationEditForm.propTypes = {
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default withRouter(EventBookingGeneralInformationEditForm);
