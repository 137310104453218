import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    includes as _includes,
} from 'lodash';

import {isUrlParamValid} from '@utils/helpers';
import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';

import {RenderBackToListButton} from "../../app/components/ButtonCollection";
import HeaderRenderer from "../../app/components/HeaderRenderer";
import Tabs from "../../app/components/Tabs";
import {TitleFromQuery} from "../../app/components/HeaderWithQuery";

import {createTabs} from "../utils/licenseeTabsLayoutHelper";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import navigation from "../../app/constants/navigation";
import {routes} from "../../app/constants/routes";

import {GetLicenseeBasicData} from "../../../graphql/licensee/query.graphql";

export const LicenseeTabsLayout = ({
    match: {
        path,
        params: {
            id = null,
        }
    },
    Menu,
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Licensee', routes.licensees.index.path),
        });
    }

    const activePath = React.useMemo(() => (
        (_includes([
            routes.licensees.contactPerson.index.path,
            routes.licensees.contactPerson.add.path,
            routes.licensees.contactPerson.edit.path
        ], path))
            ? routes.licensees.contactPerson.index.path
            : path
    ), [path]);

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                buttonsProps={{name: 'licensees.index'}}
                messagesBoxNames={["licensee", "contactPerson"]}
                pageTitle={
                    <TitleFromQuery
                        query={GetLicenseeBasicData}
                        variables={{id}}
                        errorBackLink={routes.licensees.index.path}
                        path={"licensee.name"}
                        entity={"licensee"}
                    />
                }
            />
            <Tabs items={createTabs(id, path)} active={activePath}/>
        </div>
    );
};

LicenseeTabsLayout.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};
LicenseeTabsLayout.displayName = 'LicenseeTabsLayout';

export default connect(null, mapModulesToProps(['Menu']))(LicenseeTabsLayout)
