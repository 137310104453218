import React from "react";
import {get as _get} from "lodash";

import Link from "../../app/components/Link";
import {Authorization} from "../../app/components/Authorization";
import {IconCheckmark} from "../../app/components/IconCollection";

import {convertToInt} from "../../../utils/helpers";

import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";

const authorizationObject = {
    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ
};

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true,
            className: "--noPadding",
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "licensor": {
        label: "Licensor",
        sorting: true,
        header: {
            className: "column--min-width-140",
        },
    },
    "licensees": {
        label: "Licensee(s)",
        sorting: row => renderLicensees(row)
    },
    "property_licence_type": {
        label: "Type",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left"
        }
    },
    "sport": {
        label: "Sport / 1st level category",
        sorting: row => `${_get(row, 'sport.name', '')}${_get(row, 'content_category.name', '')}`
    },
    "contents": {
        label: "Content",
        sorting: row => row.contentSortValue,
        header: {
            className: "column--min-width-250",
        },
    },
    "content_tier": {
        label: "Tier",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left"
        }
    },
    "content_package_type": {
        label: "Content package type",
        sorting: true,
        header: {
            align: "left",
        },
        content: {
            align: "left"
        }
    },
    "content_manager_user": {
        label: "Content manager",
        sorting: true,
    },
    "property_licence_status": {
        label: "Status",
        sorting: true,
    },
    "has_accreditation": {
        label: "Accreditation?",
        sorting: true,
        header: {
            align: "center",
        },
        content: {
            align: "center"
        }
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

export const renderNameOfFirstLevelDependency = (row, column) => {
    return (
        <div>
            {(null === row[column] || "undefined" === typeof row[column])
                ? ""
                : row[column].name
            }
        </div>
    );
};

export const renderLicensees = (row) => {
    return row.licensees.map(licensee => (
        licensee.name
    )).join(', ');
};

export const renderSportOrTopCategory = (row) => {
    const sportName = _get(row, 'sport.name', ""),
        contentCategoryName = _get(row, 'content_category.name', "");

    if (!sportName && !contentCategoryName) {
        return "";
    }

    return (
        <div>
            {sportName}
            {contentCategoryName}
        </div>
    );
};

export const renderCheckbox = (row, column) => (
    !row[column] ? "" : <IconCheckmark/>
);

export const linkToPropertyLicenseEdit = (rowColumn, rowId) => (
    <Authorization authorization={authorizationObject}>
        <Link name="propertyLicences.edit" params={{id: rowId}}>
            {rowColumn}
        </Link>
    </Authorization>
);
