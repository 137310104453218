import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.licensor, 'licensor'));
    Object.assign(errors, validators.required(values.property_licence_type, 'property_licence_type'));
    Object.assign(errors, validators.required(values.content_category_or_sport, 'content_category_or_sport'));
    Object.assign(errors, validators.required(values.content_tier, 'content_tier'));
    Object.assign(errors, validators.required(values.content_package_type, 'content_package_type'));
    Object.assign(errors, validators.required(values.content_manager_user, 'content_manager_user'));
    Object.assign(errors, validators.required(values.property_licence_status, 'property_licence_status'));

    if (values.expiry_alert_date) {
        Object.assign(errors, validators.date(values.expiry_alert_date, 'expiry_alert_date'));
    }

    return errors;
};

export default validate;
