import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import Form from '@appComponents/ReduxFormControls';
import {BookingsDatesFields} from '@modules/booking/components/BookingsDatesFields';

export const BookingsFiltersFields = (props) => {
    const {
        additionalFilterId,
        clientFilters,
        dateConfigProps,
        loading,
        restoreFilterValues,
        state,
        data: {
            propertyLicences,
            eventTypes,
            contentTiers,
        },
        methods: {
            onEventTypeChange,
            onSportChange,
            onTournamentCategoryChange,
            onTournamentChange,
            onContentCategoryLevel1Change,
            onContentCategoryLevel2Change,
            onContentCategoryLevel3Change,
        },
    } = props;

    return (
        <>
            <Form.IdField
                defaultValue={additionalFilterId}
                name='client'
            />
            <BookingsDatesFields {...{loading, clientFilters, dateConfigProps, restoreFilterValues}}/>
            <Field
                className='long'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='propertyLicences'
                options={propertyLicences}
                placeholder='Property licence'
                search
                setValue={restoreFilterValues(clientFilters, 'propertyLicences')}
            />
            <Field
                className='--minimalWidth --eventType'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='eventTypes'
                placeholder='Event type'
                onChangeValue={onEventTypeChange}
                options={eventTypes}
                selection
                setValue={restoreFilterValues(clientFilters, 'eventTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={state.sportsLoading || loading}
                hidden={!state.hasSport}
                loading={state.sportsLoading || loading}
                multiple
                name='sports'
                placeholder='Sport'
                onChangeValue={onSportChange}
                options={state.sports}
                search
                setValue={restoreFilterValues(clientFilters, 'sports')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={state.tournamentCategoriesLoading || loading}
                hidden={!state.hasSelectedSport}
                loading={state.tournamentCategoriesLoading || loading}
                multiple
                name='tournamentCategories'
                placeholder='Category'
                onChangeValue={onTournamentCategoryChange}
                options={state.tournamentCategories}
                search
                setValue={restoreFilterValues(clientFilters, 'tournamentCategories')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={
                    state.tournamentCategoriesLoading ||
                    state.tournamentsLoading ||
                    loading
                }
                hidden={!state.hasSelectedTournamentCategory}
                loading={
                    state.tournamentCategoriesLoading ||
                    state.tournamentsLoading ||
                    loading
                }
                multiple
                name='tournaments'
                placeholder='Tournament'
                onChangeValue={onTournamentChange}
                options={state.tournaments}
                search
                setValue={restoreFilterValues(clientFilters, 'tournaments')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={state.contentCategoriesLevel1Loading || loading}
                hidden={!state.hasContentCategoryLevel1}
                loading={state.contentCategoriesLevel1Loading || loading}
                multiple
                name='contentCategoryLevel1'
                placeholder='1st level category'
                onChangeValue={onContentCategoryLevel1Change}
                options={state.contentCategoriesLevel1}
                search
                setValue={restoreFilterValues(clientFilters, 'contentCategoryLevel1')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={state.contentCategoriesLevel2Loading || loading}
                hidden={!state.hasSelectedContentCategoryLevel1}
                loading={state.contentCategoriesLevel2Loading || loading}
                multiple
                name='contentCategoryLevel2'
                placeholder='2nd level category'
                onChangeValue={onContentCategoryLevel2Change}
                options={state.contentCategoriesLevel2}
                search
                setValue={restoreFilterValues(clientFilters, 'contentCategoryLevel2')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={
                    state.contentCategoriesLevel2Loading ||
                    state.contentCategoriesLevel3Loading ||
                    loading
                }
                hidden={!state.hasSelectedContentCategoryLevel2}
                loading={
                    state.contentCategoriesLevel2Loading ||
                    state.contentCategoriesLevel3Loading ||
                    loading
                }
                multiple
                name='contentCategoryLevel3'
                placeholder='3rd level category'
                onChangeValue={onContentCategoryLevel3Change}
                options={state.contentCategoriesLevel3}
                search
                setValue={restoreFilterValues(clientFilters, 'contentCategoryLevel3')}
            />
            <Field
                className='--customWidth --contentTiers'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='contentTiers'
                placeholder='Tier'
                options={contentTiers}
                search
                setValue={restoreFilterValues(clientFilters, 'contentTiers')}
            />
        </>
    );
};

BookingsFiltersFields.propTypes = {
    additionalFilterId: PropTypes.string,
    clientFilters: PropTypes.object,
    dateConfigProps: PropTypes.object,
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func,
    state: PropTypes.object,
    data: PropTypes.shape({
        propertyLicences: PropTypes.array,
        eventTypes: PropTypes.array,
        contentTiers: PropTypes.array,
    }),
    methods:
        PropTypes.shape({
            onEventTypeChange: PropTypes.func,
            onSportChange: PropTypes.func,
            onTournamentCategoryChange: PropTypes.func,
            onTournamentChange: PropTypes.func,
            onContentCategoryLevel1Change: PropTypes.func,
            onContentCategoryLevel2Change: PropTypes.func,
            onContentCategoryLevel3Change: PropTypes.func,
        }),
};
