import {isUndefined as _isUndefined} from "lodash";
import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {graphql, gql} from "react-apollo";
import {withRouter} from "react-router";
import {compose} from "redux";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Button, Header} from "semantic-ui-react";

import Authorization from "../../app/components/Authorization";
import {ButtonCancel} from "../../app/components/ButtonCollection";
import {FormWrapper} from "../../app/components/HOCFormWrapper";
import Form from "../../app/components/ReduxFormControls";
import MessageBox from "../../app/components/MessageBox";

import {refetchQueryByName} from "../../../utils/apollo";

import * as thirdPartyGraphQl from "../../../graphql/clients/thirdPartyPullOrigin";
import {GetThirdPartyPullOriginDropdowDataForForm} from "../../../graphql/thirdPartyClientPullOrigin/query.graphql";

import validate from "./validatorThirdPartyPullOrigins";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";
import * as MESSAGES from "../../app/constants/messages";

import {filterDisabledEncodingJobProfiles} from "../../../utils/helpers";

export class ThirdPartyPullOriginsForm extends React.Component {
    static propTypes = {
        clientProductThirdPartyId: PropTypes.number.isRequired,
        CreateThirdPartyPullOrigin: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        DataThirdPartyPullOriginsDropdowns: PropTypes.object,
        DeleteThirdPartyPullOrigin: PropTypes.func,
        formData: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object,
        initialize: PropTypes.func,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        resetIndexUrl: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        UpdateThirdPartyPullOrigin: PropTypes.func,
    };

    constructor() {
        super();

        this.state = {
            encodingDatacenters: [],
            encodingJobProfiles: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataThirdPartyPullOriginsDropdowns: {encodingDatacenters, encodingJobProfiles},
        } = nextProps;

        let nextState = {};

        if (encodingDatacenters) {
            nextState.encodingDatacenters = encodingDatacenters;
        }

        if (encodingJobProfiles) {
            nextState.encodingJobProfiles = encodingJobProfiles;
        }

        this.setState(() => (nextState));

        if (false === nextProps.DataThirdPartyPullOriginsDropdowns.loading) {
            this.props.Modal.setModal({
                loading: false
            });
        }
    }

    formSubmit = (data) => {
        let dataSaved;

        const dataToSave = Object.assign({}, this.props.dataMap.thirdPartyPullOrigin, data, {
            client_product_third_party: parseInt(this.props.clientProductThirdPartyId, 10),
            encoding_datacenter: parseInt(data.encoding_datacenter, 10),
            encoding_job_profile: parseInt(data.encoding_job_profile, 10),
        });

        if (0 === dataToSave.id.length) {
            dataSaved = this.props.CreateThirdPartyPullOrigin({
                variables: {...dataToSave},
            }).then(() => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED(`pull origin "${data.name}"`),
                    null,
                    "success",
                );
                refetchQueryByName("GetThirdPartyClientPullOrigins");
                this.props.Modal.setModal({isVisible: false});
                this.props.resetIndexUrl();
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "clientProductPullOriginMessage",
                    MESSAGES.NOT_SAVED(`pull origin "${data.name}"`),
                    `${error}`,
                    "error",
                );
            });
        } else {
            dataSaved = this.props.UpdateThirdPartyPullOrigin({
                variables: {...dataToSave},
            }).then(() => {
                this.props.MessageBox.addMessage(
                    "clientMessage",
                    MESSAGES.SAVED(`pull origin "${data.name}"`),
                    null,
                    "success",
                );
                refetchQueryByName("GetThirdPartyClientPullOrigins");
                this.props.Modal.setModal({isVisible: false});
                this.props.resetIndexUrl();
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "clientProductPullOriginMessage",
                    MESSAGES.NOT_SAVED(`pull origin "${data.name}"`),
                    `${error}`,
                    "error",
                );
            });
        }

        return dataSaved.then();
    };

    deleteThirdPartyPullOrigin = (id) => {
        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING(`pull origin "${this.props.dataForForm.thirdPartyPullOrigin.name}"`)
        });

        return this.props.DeleteThirdPartyPullOrigin({
            variables: {
                id: id,
            }
        }).then(() => {
            this.props.MessageBox.addMessage(
                "clientMessage",
                MESSAGES.DELETED(`pull origin "${this.props.dataForForm.thirdPartyPullOrigin.name}"`),
                null,
                "success",
            );
            refetchQueryByName("GetThirdPartyClientPullOrigins");
            this.props.Modal.setModal({isVisible: false});
            this.props.resetIndexUrl();
        }).catch((error) => {
            this.props.Modal.setModal({isVisible: false});
            this.props.MessageBox.addMessage(
                "clientProductPullOriginMessage",
                MESSAGES.NOT_DELETED(`pull origin "${this.props.dataForForm.thirdPartyPullOrigin.name}"`),
                `${error}`,
                "error",
            );
        });
    };

    handleApplicationDelete = () => {
        const data = this.props.dataForForm.thirdPartyPullOrigin;

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={MESSAGES.DELETE_HEADER('pull origin')}/>,
            onYes: () => (this.deleteThirdPartyPullOrigin(parseInt(data.id, 10))),
            text: MESSAGES.DELETE_CONFIRMATION(`pull origin "${this.props.dataForForm.thirdPartyPullOrigin.name}"`)
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (!_isUndefined(this.props.dataForForm.thirdPartyPullOrigin.id) &&
            ("" !== this.props.dataForForm.thirdPartyPullOrigin.id)
        ) {
            deleteButton =
                <Button
                    onClick={this.handleApplicationDelete}
                    color="red"
                    content="Delete"
                    disabled={this.props.submitting}
                    icon="trash"
                />
        }

        return deleteButton;
    };

    closeModal = (e) => {
        e.preventDefault();

        this.props.Modal.setModal({
            content: null,
            isVisible : false
        });
        this.props.resetIndexUrl();
    };

    render() {
        return (
            <div>
                <Header as="h3" dividing>
                    {(this.props.dataForForm.thirdPartyPullOrigin.id)
                        ? `Pull origin "${this.props.dataForForm.thirdPartyPullOrigin.name}" `
                        : 'Add new pull origin'
                    }
                </Header>
                <MessageBox name="clientProductPullOriginMessage"/>
                <Form.Create
                    loading={this.props.formData.loading}
                    onSubmit={this.props.handleSubmit(this.formSubmit)}
                >
                    <Form.IdField
                        defaultValue={this.props.dataForForm.thirdPartyPullOrigin.id}
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.thirdPartyPullOrigin.name}
                        label="Name"
                        name="name"
                        required
                        autoFocus
                    />
                    <Form.FormRow
                        component={Form.SemanticSelect}
                        defaultValue={this.props.dataForForm.thirdPartyPullOrigin.encoding_datacenter.id}
                        options={this.state.encodingDatacenters}
                        loading={this.props.DataThirdPartyPullOriginsDropdowns.loading}
                        disabled={this.props.DataThirdPartyPullOriginsDropdowns.loading}
                        label="Encoding datacenter"
                        name="encoding_datacenter"
                        required
                    />
                    <Form.FormRow
                        component={Form.SemanticSelect}
                        defaultValue={this.props.dataForForm.thirdPartyPullOrigin.encoding_job_profile.id}
                        options={filterDisabledEncodingJobProfiles(
                            this.state.encodingJobProfiles,
                            this.props.dataForForm.thirdPartyPullOrigin.encoding_job_profile.id
                        )}
                        loading={this.props.DataThirdPartyPullOriginsDropdowns.loading}
                        disabled={this.props.DataThirdPartyPullOriginsDropdowns.loading}
                        label="Encoding job profile"
                        name="encoding_job_profile"
                        required
                    />
                    <Form.FormRowChangelog
                        resources={RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN}
                        data={ (this.props.formData || {}).thirdPartyPullOrigin }
                    />
                    <div className="formRow form__footer">
                        <label/>
                        <Button
                            color="blue"
                            content="Save"
                            disabled={ this.props.submitting }
                            icon="save"
                            loading={ this.props.submitting }
                            type="submit"
                        />
                        <Authorization
                            privileges={CONST.SECURITY_PRIVILEGES_DELETE}
                            resources={RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN}
                        >
                            {this.renderDeleteButton()}
                        </Authorization>
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={ this.closeModal }
                        >
                            Cancel
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            encoding_job_profile: ownProps.dataForForm.thirdPartyPullOrigin.encoding_job_profile.id,
            encoding_datacenter: ownProps.dataForForm.thirdPartyPullOrigin.encoding_datacenter.id,
        }
    };
};

const ThirdPartyPullOriginsFormRedux = reduxForm({
    form: 'ThirdPartyPullOrigin',
    validate,
})(withRouter(ThirdPartyPullOriginsForm));

export default compose(
    graphql(gql(thirdPartyGraphQl.createThirdPartyPullOrigin), {name: 'CreateThirdPartyPullOrigin'}),
    graphql(gql(thirdPartyGraphQl.deleteThirdPartyPullOrigin), {name: 'DeleteThirdPartyPullOrigin'}),
    graphql(gql(thirdPartyGraphQl.updateThirdPartyPullOrigin), {name: 'UpdateThirdPartyPullOrigin'}),
    graphql(GetThirdPartyPullOriginDropdowDataForForm, {
        options: () => {
            return {
                fetchPolicy: "cache-and-network",
                notifyOnNetworkStatusChange: true,
                variables: {
                    products: [CONST.PRODUCT_THIRD_PARTY],
                },
            };
        },
        name: "DataThirdPartyPullOriginsDropdowns",
    }),
)(FormWrapper({
    thirdPartyPullOrigin: {
        encoding_datacenter: {id: ""},
        encoding_job_profile: {id: ""},
        id: "",
        name: "",
    },
}, (connect(mapStateToProps)(ThirdPartyPullOriginsFormRedux))));
