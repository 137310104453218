import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {VENUE} from "../../app/constants/resources";
import {REQUIRE_THREE_LETTERS_PLACEHOLDER} from "../../app/constants/variables";
import validate from "./validator";

import {GetVenuesForForm} from "../../../graphql/venue/query.graphql";
import {CreateVenue, DeleteVenue, UpdateVenue} from "../../../graphql/venue/mutation.graphql";

export default function VenueModel() {
    return {
        entityDataMapKey: "venue",
        entityLabel: "venue",
        formName: "venue",
        label: "venue",
        resources: VENUE,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            venue: {
                id : "",
                name: "",
                city: null,
                capacity: "",
            }
        },
        messages: {
            boxName: {
                delete: "venueMessage",
                error: "venueMessageError",
                success: "venueMessage",
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "venue.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "venue.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            city: {
                optionsKey: "cities",
                dataMapKey: "venue.city.id",
                props: {
                    component: Form.SemanticSelect,
                    label: "City",
                    name: "city",
                    allowClear: true,
                    placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
                },
            },
            capacity: {
                dataMapKey: "venue.capacity",
                props: {
                    component: Form.SemanticInput,
                    label: "Capacity",
                    name: "capacity",
                    type: "number",
                },
            },
        },
        mutation: {
            createEntity: CreateVenue,
            deleteEntity: DeleteVenue,
            updateEntity: UpdateVenue,
        },
        query: {
            getEntity: GetVenuesForForm,
        },
    }
}
