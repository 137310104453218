import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {GetEventContentType} from '@graphql/eventContentType/query';
import {routes} from '@constants/routes';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';

import EventContentTypeForm from '../forms/EventContentTypeForm';
import EventContentTypeModel from '../forms/EventContentTypeModel';

const EventContentTypeEdit = ({
    match: {
        params: {
            id = null,
        },
    },
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Event content type', routes.eventContentTypes.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(EventContentTypeModel, EventContentTypeForm, {id: convertToInt(id)})),
        []
    );

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                buttonsProps={{name: 'eventContentTypes.index'}}
                messagesBoxNames='eventContentTypeMessageError'
                pageTitle={<TitleFromQuery
                    query={GetEventContentType}
                    variables={{id}}
                    errorBackLink={routes.eventContentTypes.index.path}
                    path={'eventContentType.name'}
                    entity={'event content type'}
                />
                }
            />
            <Form/>
        </div>
    );
};

EventContentTypeEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    eventContentTypeName: PropTypes.string.isRequired,
};

export default withRouter(EventContentTypeEdit);
