import configuredStore from "../../../store";
import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {
    get as _get,
    isArray as _isArray,
    isEmpty as _isEmpty,
    isFunction as _isFunction,
    isNil as _isNil,
    isUndefined as _isUndefined,
} from "lodash";

/**
 * Method for checking authorization, from given parameters
 *
 * @param {Array<String> | String} resources Array of resources names or resource name
 * @param {Bitmask | Integer} privileges Bitmask ( fe. 0b1010 ) or representation in Integer
 * @param {Object} userPrivileges Object representing privileges that needs to be check
 * @param {Boolean} combinedResources Flag if only one resource must have privileges or both
 * <pre>
 *  [
 *      {
 *        name: "licensor",
 *        privileges: 15
 *      },
 *      {
 *        name: "licensee",
 *        privileges: 1
 *      },
 *      {
 *        name: "property-licence",
 *        privileges: 7
 *      }
 *  ]
 * </pre>
 *
 * @returns {boolean}
 */
export const isAuthorized = (resources, privileges, userPrivileges, combinedResources = false) => {
    if (
        (_isNil(resources) || (_isArray(resources) && _isEmpty(resources)))
        || (_isNil(privileges) || (_isArray(privileges) && _isEmpty(privileges)))
        || (_isNil(userPrivileges))
    ) return false;

    if (!_isArray(resources)) {
        resources = [resources]
    }

    if (!_isArray(privileges)) {
        privileges = [privileges]
    }

    for (let index = 0; index < resources.length; index++) {
        const resource = resources[index];

        for (let privilegesIndex = 0; privilegesIndex < privileges.length; privilegesIndex++) {
            const privilege = privileges[privilegesIndex];

            if (!combinedResources && userPrivileges && userPrivileges[resource] && privilege &&
                userPrivileges[resource] & privilege
            ) {
                return true;
            } else if (combinedResources && (
                _isUndefined(userPrivileges) || _isUndefined(userPrivileges[resource]) || _isUndefined(privilege) ||
                !(userPrivileges[resource] & privilege))
            ) {
                return false;
            }
        }
    }

    return combinedResources;
};

/**
 * @param authorizationObject
 *
 * @returns {boolean}
 */
export const hasPrivileges = (authorizationObject) => matchUserPrivileges(
    _get(configuredStore.getState(), 'app.security.user.privileges', {}),
    authorizationObject
);

/**
 * @param userPrivileges
 * @param authorizationObject
 *
 * @returns {boolean}
 */
export const matchUserPrivileges = (userPrivileges, authorizationObject) => {
    if (!Object.keys(authorizationObject).length || !Object.keys(userPrivileges).length) {
        return false;
    }

    return Object.keys(authorizationObject).every(
        privilegeType => matchUserPrivilegeToRequiredPrivilege(
            convertPrivilegesToInt(userPrivileges[privilegeType]),
            convertPrivilegesToInt(authorizationObject[privilegeType])
        )
    );
};

const convertPrivilegesToInt = (privileges) => _isArray(privileges)
    ? privileges.reduce((acc, val) => acc + val)
    : privileges;

const matchUserPrivilegeToRequiredPrivilege = (userPrivilege, requiredPrivilege) => (
    (userPrivilege & requiredPrivilege) === requiredPrivilege
);

/**
 * Authorization component
 *
 * Component used for authorization
 *
 */
export class Authorization extends React.Component {
    /**
     * @property {String | Array<String>} resources Array of resources names or resource name
     * @property {Bitmask | Integer | Array<Bitmask | Integer>} privileges Bitmask ( fe. 0b1010 ) or representation in Integer,
     * In case of Array is passed OR condition will be used to check privileges
     * @property {Function} authorizationCheck User function for checking authorization. Should returns boolean,
     * it will omit given resources and privileges
     * @property {Object<Component>} notAuthorized Component that will be render if user is not authorized
     * @property {Boolean} combinedResources Flag if only one resource must have privileges or both
     *
     */
    static propTypes = {
        authorization: PropTypes.object,
        authorizationCheck: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        combinedResources: PropTypes.bool,
        notAuthorized: PropTypes.object,
        privileges: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
        resources: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
        userPrivileges: PropTypes.object,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        /**
         * @ignore
         */
        this.state = {
            combinedResources: _isUndefined(props.combinedResources) ? false : props.combinedResources,
            privileges: props.privileges,
            resources: props.resources,
            userPrivileges: props.userPrivileges
        }
    }

    /**
     * @ignore
     */
    authorizationCheck() {
        if (_isFunction(this.props.authorizationCheck)) {
            return this.props.authorizationCheck(this.state.userPrivileges)
        } else {
            if (this.props.authorization) {
                return hasPrivileges(this.props.authorization);
            }

            return isAuthorized(
                this.state.resources,
                this.state.privileges,
                this.state.userPrivileges,
                this.state.combinedResources
            )
        }
    }

    /**
     * @ignore
     */
    render() {
        if (this.authorizationCheck()) {
            return this.props.children
        } else {
            if (this.props.notAuthorized) {
                return this.props.notAuthorized
            } else {
                return null
            }
        }
    }
}

export const AuthorizationAny = ({authorizationObjects, children}) => (
    0 === authorizationObjects.length || authorizationObjects.some(auth => hasPrivileges(auth))
        ? children
        : null
);

AuthorizationAny.propTypes = {
    authorizationObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    children: PropTypes.node,
};

export const getUserAuthorizationData = () => (
    _get(configuredStore.getState(), 'app.security.user.privileges', [])
)

/**
 * @ignore
 */
const mapStateToProps = (state) => ({
    userPrivileges: state.app.security.user.privileges
});

export default connect(mapStateToProps)(Authorization)
