import React from 'react';
import PropTypes from "prop-types";

import {Master} from "../../../../../components/EventsTabsLayout/Details/EventDetails/column4/Master";
import {GetDateTimeFieldValue} from "../../../../../components/EventsTabsLayout/Details/EventDetails/common/GetDateTimeFieldValue";

export const getFieldsList = (event) => {
    let fieldsColumn = [
        {
            name: 'Status',
            value: (event.event_status || {}).name,
            id: (event.event_status || {}).id
        },
        {
            name: 'Start time',
            value: <GetDateTimeFieldValue event={event} label={'start'} matchDateKey={'start_datetime'} />
        },
        {
            name: 'End time',
            value: <GetDateTimeFieldValue event={event} label={'end'} matchDateKey={'end_datetime'} />
        },
    ];

    /**
     * Display only for NOT A MASTER EVENT
     */
    if (!event.is_master) {
        fieldsColumn.push(
            {
                name: 'Master',
                value: <Master event={event} />
            }
        );
    }

    return fieldsColumn;
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        event_status: PropTypes.shape({
            name: PropTypes.string
        }),
        is_master: PropTypes.bool
    }).isRequired
};

getFieldsList.defaultProps = {
    event: {
        event_status: {
            name: null
        },
        is_master: false
    }
}
