import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";

import {isAuthorized, hasPrivileges} from "./Authorization";
import Link from "./Link";

export const LinkWithAuthorization = (props) => {
    const {link} = props;

    let hasAuthorization;

    if (props.authorization) {
        hasAuthorization = hasPrivileges(props.authorization);
    } else {
        hasAuthorization = isAuthorized(
            props.resources,
            props.privileges,
            props.userPrivileges,
            props.combinedResources
        );
    }

    return (
        hasAuthorization
            ? (<Link
                {...props}
                hash={link.hash}
                name={link.name}
                query={link.query}
                options={link.options}
                params={link.params}
            >
                {props.children}
            </Link>)
            : (<span
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            >
                {props.children}
            </span>)
    );
};

LinkWithAuthorization.propTypes = {
    authorization: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number
    ]),
    combinedResources: PropTypes.bool,
    link: PropTypes.shape({
        hash: PropTypes.string,
        name : PropTypes.string,
        query: PropTypes.object,
        options: PropTypes.object,
        params : PropTypes.object,
    }),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    privileges: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
    ]),
    resources: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
    ]),
    userPrivileges: PropTypes.object,
};

LinkWithAuthorization.defaultProps = {
    combinedResources: false,
    link: {
        hash: null,
        name: null
    },
    privileges: [],
    resources: [],
    userPrivileges: {},
};

/**
 * @ignore
 */
const mapStateToProps = (state) => {
    return {
        userPrivileges: state.app.security.user.privileges
    }
};

export default connect(mapStateToProps)(LinkWithAuthorization);
