import EventEventBookingGeoRestrictionsEditModel
    from '@modules/eventBooking/forms/EventEventBookingGeoRestrictionsEditModel';
import {GetEventEventBookingForGeoRestrictionsForm} from '@graphql/eventEventBooking/query';
import {UpdateEventBooking, DeleteEventBooking} from '@graphql/eventEventBooking/mutation';

export default function ClientEventBookingGeoRestrictionsEditModel() {
    let ClientEventBookingGeoRestrictionsEditModel = EventEventBookingGeoRestrictionsEditModel();

    ClientEventBookingGeoRestrictionsEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    ClientEventBookingGeoRestrictionsEditModel.editForm = true;
    ClientEventBookingGeoRestrictionsEditModel.mutationOptions = {
        delete: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
        update: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
    };

    ClientEventBookingGeoRestrictionsEditModel.mutation = {
        updateEntity: UpdateEventBooking,
        deleteEntity: DeleteEventBooking,
    };

    ClientEventBookingGeoRestrictionsEditModel.query.getEntity = GetEventEventBookingForGeoRestrictionsForm;

    return ClientEventBookingGeoRestrictionsEditModel;
}
