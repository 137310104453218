import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
} from 'lodash';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {useQuery} from '@apollo/client';

import {
    GetLcrChannelContent,
} from '@graphql/lcr/query';
import {showModal} from '@utils/modal';
import {createForm, renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import {getLink} from '@appComponents/Link';
import {isUrlParamValid} from '@utils/helpers';

import CategoryModel from '../forms/CategoryModel';
import CategoryForm from '../forms/CategoryForm';
import SportModel from '../forms/SportModel';
import SportForm from '../forms/SportForm';
import ContentRestriction from './ContentRestriction';
import TournamentModel from '../forms/TournamentModel';
import TournamentForm from '../forms/TournamentForm';

export const ContentRestrictions = ({lcrId, modal}) => {
    const { id, sportId, categoryId, tournamentId } = useParams(),
        history = useHistory(),
        match = useRouteMatch();

    useEffect(() => {
        let Form = null;

        if (routes.lcrChannel.edit.contentRestrictions.sports.edit.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.sports.add.path === match.path
            && isUrlParamValid(lcrId) && isUrlParamValid(sportId)) {
            Form = createForm(SportModel, SportForm,
                {
                    id: sportId,
                    optionsVariables: {
                        lcrId: id,
                    },
                });
        }

        if (routes.lcrChannel.edit.contentRestrictions.categories.edit.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.categories.add.path === match.path
            && isUrlParamValid(categoryId)) {
            Form = createForm(CategoryModel, CategoryForm,
                {
                    id: categoryId,
                    optionsVariables: {
                        lcrId: id,
                    },
                });
        }

        if (routes.lcrChannel.edit.contentRestrictions.tournaments.add.path === match.path
            && isUrlParamValid(tournamentId)) {
            Form = createForm(TournamentModel, TournamentForm,
                {
                    id: tournamentId,
                    optionsVariables: {
                        lcrId: id,
                    },
                });
        }

        if (isUrlParamValid(id) && Form) {
            const link = getLink(`lcrChannel.edit.contentRestrictions.index`, {
                id: id,
            });

            if (!isUrlParamValid(sportId)) {
                return showModal({
                    isVisible: true,
                    content: renderModalError('Sport', link),
                });
            } else if (!isUrlParamValid(categoryId)) {
                return showModal({
                    isVisible: true,
                    content: renderModalError('Category', link),
                });
            } else if(!isUrlParamValid(tournamentId)) {
                return showModal({
                    isVisible: true,
                    content: renderModalError('Tournament', link),
                });
            }

            showModal({
                isVisible: true,
                size: 'small',
                content: <Form />,
            });
        }
    }, [match.path]);

    useEffect(() => {
        if (!modal.isVisible) {
            history.push(
                getLink('lcrChannel.edit.contentRestrictions.index', {id})
            );
        }
    }, [modal.isVisible]);

    const {data: contentData, loading} = useQuery(GetLcrChannelContent, {
        variables: {
            id: lcrId,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'no-cache',
    });

    const territorySelectionOptions = _get(contentData, 'selectionTypes', []);

    return (
        <>
            <ContentRestriction
                loading={loading}
                type={'sports'}
                territorySelection={_get(contentData, 'lcrChannel.sport_selection_type.id', null)}
                territorySelectionOptions={territorySelectionOptions}
            />
            <ContentRestriction
                loading={loading}
                type={'categories'}
                territorySelection={_get(contentData, 'lcrChannel.tournament_category_selection_type.id', null)}
                territorySelectionOptions={territorySelectionOptions}
            />
            <ContentRestriction
                loading={loading}
                type={'tournaments'}
                territorySelection={_get(contentData, 'lcrChannel.tournament_selection_type.id', null)}
                territorySelectionOptions={territorySelectionOptions}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

ContentRestrictions.propTypes = {
    lcrId: PropTypes.string,
    modal: PropTypes.object,
};

ContentRestrictions.displayName = 'ContentRestrictions';

export default connect(mapStateToProps)(ContentRestrictions);
