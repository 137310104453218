import React from 'react';
import {withRouter} from "react-router";

import ProductThirdPartyGeoRestrictionsForm from "../forms/ProductThirdPartyGeoRestrictionsForm";
import ProductThirdPartyGeoRestrictionsModel from "../forms/ProductThirdPartyGeoRestrictionsModel";

import {createForm} from "../../../utils/forms";

import {ProductLctGeoRestrictionsEdit} from "./ProductLctGeoRestrictionsEdit";

export class ProductThirdPartyGeoRestrictionsEdit extends ProductLctGeoRestrictionsEdit {
    renderForm = () => {
        const Form = createForm(
            ProductThirdPartyGeoRestrictionsModel,
            ProductThirdPartyGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };
}

export default withRouter(ProductThirdPartyGeoRestrictionsEdit)
