import {get as _get} from 'lodash';

import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';
import {transformProductsForCoverageDropdown} from '@modules/events/utils/formHelper';
import DefaultForm from '@appComponents/DefaultForm';

export class SetCoverageForm extends DefaultForm {

    prepareDataForSubmit(data) {
        return {
            id: 'UPDATE', //Dummy variable for update
            events: this.props.formParams.optionsVariables.eventsIds,
            products: data.coverage,
        };
    }

    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName(this.props.formParams.queryForRefetch);
        });

        const products = _get(this.props, 'formParams.optionsVariables.products', []);

        this.setField('coverage', {
            options: transformProductsForCoverageDropdown(products),
            defaultValue: products.map(product => convertToInt(product.id)),
        });
    }

    renderSaveButton(props = {}) {
        return super.renderSaveButton({...props, ...{content: 'Apply'}});
    }
}

export default SetCoverageForm;
