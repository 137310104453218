import React from "react";
import PropTypes from "prop-types";
import {Dropdown} from "semantic-ui-react";
import {get as _get, uniq as _uniq} from "lodash";
import classnames from "classnames";

export class InputMultiple extends React.PureComponent {

    static propTypes = {
        addItemShortcuts: PropTypes.arrayOf(PropTypes.string),
        className: PropTypes.string,
        defaultValueMultiple: PropTypes.arrayOf(PropTypes.string),
        disabled: PropTypes.bool,
        error: PropTypes.bool.isRequired,
        input: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChangeMultipleValue: PropTypes.func,
        renderLabelParams: PropTypes.shape({
            className: PropTypes.string,
            condition: PropTypes.func
        })
    }

    static defaultProps = {
        addItemShortcuts: [],
        onChangeMultipleValue: () => null
    }

    constructor() {
        super();

        this.state = {
            searchQuery: '',
            options: [],
            value: []
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setDefaultValue(nextProps.defaultValueMultiple);
    }

    onBlurHandler = (event, data) => {
        const value = this.addToArrayWithoutRepetitions(this.state.value, data.searchQuery);

        this.setInputState(value);

        if (this.props.onChangeMultipleValue) {
            this.props.onChangeMultipleValue(event, {...data, value});
        }
    }

    onChangeHandler = (event, data) => {
        this.setInputState(data.value);

        if (this.props.onChangeMultipleValue) {
            this.props.onChangeMultipleValue(event, data);
        }
    }

    onSearchChangeHandler = (event, data) => {
        this.setState(() => ({searchQuery: data.searchQuery.replace(/\s/g, "")}));

        this.shortcutsUpdateInputState(data);
    }

    createRenderLabel = (params) => {
        if (!this.props.meta.touched || !params) return;

        return (label) => ({
            className: params.condition(label.text) ? params.className : '',
            content: label.text
        });
    }

    addToArrayWithoutRepetitions = (arr, el) => {
        return arr.indexOf(el) === -1 && el !== '' ? arr.concat([el]) : arr;
    }

    createInputState = (inputs) => {
        const inputsPrepared = _uniq(inputs.map(el => el.toLowerCase()));

        return {
            options: inputsPrepared.map(el => ({key: el, text: el, value: el})),
            value: inputsPrepared
        };
    }

    setDefaultValue = (value) => {
        const defaultValue = this.props.meta.pristine && this.state.value.length === 0 && _get(value, 'length') && value;

        if (defaultValue) {
            this.setInputState(defaultValue);
        }
    }

    setInputState = (notifications) => {
        const {options, value} = this.createInputState(notifications);

        this.setState(() => ({
            searchQuery: '',
            options,
            value
        }));

        this.props.input.onBlur();
        this.props.input.onChange(value);
    }

    shortcutsUpdateInputState = (data) => {
        let i = 0;

        while (i < this.props.addItemShortcuts.length) {
            if (data.searchQuery.endsWith(this.props.addItemShortcuts[i++])) {
                this.setInputState(this.addToArrayWithoutRepetitions(this.state.value, data.searchQuery.slice(0, -1)));
            }
        }
    }

    render() {
        return (
            <Dropdown
                basic fluid multiple selection allowAdditions search
                className={classnames(
                    'inputMultiple',
                    this.props.className,
                )}
                disabled={this.props.disabled}
                name={this.props.name}
                error={this.props.error}
                renderLabel={this.createRenderLabel(this.props.renderLabelParams)}
                options={this.state.options}
                onBlur={this.onBlurHandler}
                onChange={this.onChangeHandler}
                onSearchChange={this.onSearchChangeHandler}
                searchQuery={this.state.searchQuery}
                value={this.state.value}
            />
        );
    }
}
