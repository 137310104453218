import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {routes} from '@constants/routes';
import {Renderer} from '@appComponents/TabsComponents/Renderer';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetUserNameForResources} from '@graphql/user/query';
import UserEditButtons from '@modules/user/components/UserEditButtons';
import {
    MESSAGE_BOX_USERS_IAM_STATUS_NAME,
    MESSAGE_BOX_USERS_IAM_STATUS_ERROR_NAME,
    MESSAGE_BOX_USERS_AFTER_GRANT_ERROR_NAME,
    MESSAGE_BOX_USERS_AFTER_GRANT_NAME,
    MESSAGE_BOX_USERS_AFTER_ADD_ERROR_NAME,
    MESSAGE_BOX_USERS_AFTER_EDIT_ERROR_NAME,
    MESSAGE_BOX_USERS_AFTER_EDIT_NAME,
    MESSAGE_BOX_USERS_EDIT_WARNING_NAME,
} from '@modules/user/utils/variables';
import {
    createTabs,
} from '@modules/user/utils/userTabsLayoutHelper';

export const UserTabsLayout = ({
    match: {
        path,
        params: {
            id = null,
        },
    },
}) => {

    const createPageTitle = () => {
        if (path === routes.users.add_user_from_existing_iam_account.path) {
            return 'Add new user';
        }

        return (
            <TitleFromQuery
                query={GetUserNameForResources}
                variables={{id}}
                errorBackLink={routes.users.index.path}
                path={'user.name'}
                entity={'user'}
            />
        );
    };

    return (
        <Renderer
            active={path}
            buttons={UserEditButtons}
            buttonsProps={{id: id}}
            items={createTabs(id, path)}
            messagesBoxNames={[
                MESSAGE_BOX_USERS_IAM_STATUS_NAME,
                MESSAGE_BOX_USERS_IAM_STATUS_ERROR_NAME,
                MESSAGE_BOX_USERS_AFTER_GRANT_ERROR_NAME,
                MESSAGE_BOX_USERS_AFTER_GRANT_NAME,
                MESSAGE_BOX_USERS_AFTER_ADD_ERROR_NAME,
                MESSAGE_BOX_USERS_AFTER_EDIT_ERROR_NAME,
                MESSAGE_BOX_USERS_AFTER_EDIT_NAME,
                MESSAGE_BOX_USERS_EDIT_WARNING_NAME,
            ]}
            pageTitle={createPageTitle()}
        />
    );
};

UserTabsLayout.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
};

UserTabsLayout.displayName = 'UserTabsLayout';

export default connect(null, mapModulesToProps(['Menu']))(UserTabsLayout);
