import {get as _get} from 'lodash';

export const columns = {
    'country': {
        label: 'Country',
        sorting: row => row.country.name,
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    'country_subdivision': {
        label: 'Subdivision',
        sorting: row => _get(row, 'country_subdivision.name', ''),
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    'code': {
        label: 'Postal code',
        sorting: row => row.code,
        header: {
            align: 'center',
        },
        content: {align: 'center'},
    },
};
