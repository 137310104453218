import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const EnvironmentLabel = ({isExpanded = false, environmentName}) => (
    <div className={classnames(
        'sidebar__environment-label',
        isExpanded
            ? 'sidebar__environment-label--expanded'
            : 'sidebar__environment-label--collapsed'
    )}>
        <div className='ui orange label'>
            {
                isExpanded
                    ? environmentName
                    : environmentName.charAt(0).toUpperCase()
            }
        </div>
    </div>
);

EnvironmentLabel.propTypes = {
    isExpanded: PropTypes.bool,
    environmentName: PropTypes.string.isRequired
};

export default EnvironmentLabel;
