import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";

import Table from "../../app/components/Table";

import {rowRenderer} from "../utils/MatchSelectionPeriodsTableRowRenderer";

import {columns} from "../constants/MatchSelectionPeriodsTableColumns";

const MatchSelectionPeriodsTable = ({matchSelectionPeriods = [], loading, match: {params: {id}}}) => (
    <div className="match_selection_periods__table">
        <Table
            name="matchSelectionPeriodsList"
            noDataText="No match selection periods found"
            columns={columns}
            data={matchSelectionPeriods}
            loading={loading}
            rowRenderer={rowRenderer}
            pagination={false}
            rowRendererProps={{propertyLicenceId: id}}
        />
    </div>
);

MatchSelectionPeriodsTable.propTypes = {
    matchSelectionPeriods: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(MatchSelectionPeriodsTable);
