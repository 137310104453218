import React from "react";
import PropTypes from "prop-types";

import {ReduxComponent} from "./ReduxComponent";
import {Form} from '../ReduxFormControls';

/**
 * Render dropdown for countries
 */
class CountriesSelection extends ReduxComponent {
    /**
     * @property {Boolean} allowClear Define if field can be cleared or not
     * @property {Object} data Data for dropdown
     * @property {Array} defaultValue Array of numbers
     * @property {Array<Object>} options Array of Options<br/>
     * <pre>
     * [
     *  {
     *      key: 1
     *      value: "Value 1"
     *  },
     *  {
     *      key: 2
     *      value: "Value 2"
     *  }
     *  ...
     * ]
     * </pre>
     */
    static propTypes = {
        allowClear: PropTypes.bool,
        data: PropTypes.object,
        defaultValue: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
            PropTypes.number
        ]),
        input: PropTypes.object,
        meta: PropTypes.object,
        options: PropTypes.array,
        type: PropTypes.string,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        let loadingData = false;

        if (props.data !== undefined && props.data.loading && !props.meta.visited) {
            loadingData = true;
        }

        this.state = Object.assign({}, props, {loading: loadingData, options: this.getOptionsForSelect(props)})
    }

    /**
     * @ignore
     */
    getOptionsForSelect(props) {
        let optionsForSelect = [];

        if (props.options !== undefined) {
            optionsForSelect = props.options.map(this.mapCountriesToFormSelect)
        }

        return optionsForSelect;
    }

    /**
     * @ignore
     */
    mapCountriesToFormSelect(item) {
        return {
            key: item.key,
            value: item.key,
            text: item.text.capitalizeFirstLetter()
        }
    }

    /**
     * @ignore
     */
    componentWillReceiveProps(props) {
        let loadingData = false;

        if (props.data !== undefined && props.data.loading) {
            loadingData = true;
        }

        this.setState(() => (
            Object.assign(
                {}, props, {options: this.getOptionsForSelect(props), loading: loadingData, value: props.defaultValue}
            )
        ))
    }

    /**
     * @ignore
     */
    render() {
        return React.createElement(Form.SemanticSelect, this.state)
    }
}

export default CountriesSelection;
