import React from "react";
import PropTypes from "prop-types";

import Table from "../../app/components/Table";
import Link from "../../app/components/Link";

import {isAtLeastOneRecipientDefined} from "../utils/helpers";


const columns = {
    "name": {
        label: "Client",
        sorting: row => row.name
    },
    "notification_email_recipients": {
        label: "Recipients",
        sorting: row => row.notification_email_recipients ? row.notification_email_recipients.join(', ') : null
    }
};

const EventContentAlertClientsTable = (props) => {
    const {clients} = props,
        rowRenderer = (column, row) => {
            switch (column) {
                case "name":
                    return <Link newWindow={true} name={'clients.edit'} params={{id: row.id}}>{row.name}</Link>
                case "notification_email_recipients":
                    return row.notification_email_recipients ? row.notification_email_recipients.join(', ') : null;
                default:
                    return null
            }
        };

    return (
        <div className="eventContentAlertRecipients__table">
            {0 < clients.length
                ? <React.Fragment>
                    <Table
                        name="eventContentAlertRecipients"
                        columns={columns}
                        multiple={true}
                        selectable={true}
                        pagination={false}
                        data={clients}
                        size="small"
                        rowRenderer={rowRenderer}
                        selected={props.selected || []}
                        defaultSort={'name'}
                        getSelectedElements={props.getSelectedClients}
                    />
                    {
                        !isAtLeastOneRecipientDefined(clients)
                        && <div className="ui warning message visible">
                            {`The alert cannot be sent because no recipients have been defined for any of the listed
                                clients. Recipients can be defined in the "General information" section
                                of the client configuration page.`}
                        </div>
                    }
                </React.Fragment>
                : <div className="ui warning message visible">
                    No alerts can be sent because no clients have booked or offered this content
                </div>
            }
        </div>
    )
};

EventContentAlertClientsTable.propTypes = {
    clients: PropTypes.array,
    getSelectedClients: PropTypes.func,
    input: PropTypes.object,
    selected: PropTypes.array
};

EventContentAlertClientsTable.defaultProps = {
    clients: [],
    getSelectedClients: () => null,
    input: {},
    selected: []
};

export default EventContentAlertClientsTable;
