import GeoRestrictionsGeneralInformationModel from "./GeoRestrictionsGeneralInformationModel";

import {GetClientPackageGeoRestrictionForForm} from "../../../../graphql/clientPackageGeoRestriction/query.graphql"
import {
    DeleteClientPackageGeoRestriction,
    UpdateClientPackageGeoRestriction
} from "../../../../graphql/clientPackageGeoRestriction/mutation.graphql";

export default function GeoRestrictionsGeneralInformationEditModel() {
    let geoRestrictionsGeneralInformationEditModel = GeoRestrictionsGeneralInformationModel();

    geoRestrictionsGeneralInformationEditModel.editForm = true;
    geoRestrictionsGeneralInformationEditModel.deleteId = "clientPackageGeoRestriction.id";
    geoRestrictionsGeneralInformationEditModel.mutation = {
        updateEntity: UpdateClientPackageGeoRestriction,
        deleteEntity: DeleteClientPackageGeoRestriction,
    };
    geoRestrictionsGeneralInformationEditModel.query.getEntity = GetClientPackageGeoRestrictionForForm;

    return geoRestrictionsGeneralInformationEditModel;
}
