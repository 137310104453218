import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {renderPageTitle} from "../../../utils/helpers";
import {getLink} from "../../app/components/Link";

export class EncodingJobProfileForm extends DefaultForm {
    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(this.createLinkToIndexPage());
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const clients = _get(this.props, 'GraphQLEntityData.encodingJobProfile.clients', []);

        if (!this.state.dataLoaded && !_isEmpty(clients)) {
            this.setField("clients", {
                defaultValue: clients.map((client) => client.id),
            });

            this.setState(() => ({dataLoaded: true}));
        }

        const encodingJobProfileName = (null !== this.props.formParams.id)
            ? (undefined !== this.props.GraphQLEntityData.encodingJobProfile)
                ? this.props.GraphQLEntityData.encodingJobProfile.name
                : null
            : null;

        this.props.formParams.setPageTitle(
            renderPageTitle({
                id: this.props.formParams.id,
                name: encodingJobProfileName,
                entity: this.props.Model.entityLabel
            })
        )
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                name: _get(this.props, 'GraphQLEntityData.encodingJobProfile.name', null),
                clients: (data && data.clients) ? data.clients.map(Number) : [],
            }
        )
    );

    createLinkToIndexPage = () => (
        getLink('encodingJobProfile.index')
    );

    renderErrors(errorData) {
        super.renderErrors(errorData, this.props.Model.label, this.createLinkToIndexPage());
    }

    renderSaveButton = () => super.renderSaveButton({content: "Save"});

    renderDeleteButton = () => null;

    renderCancelButton = () => null;
}

export default withRouter(EncodingJobProfileForm);
