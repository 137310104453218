import React from 'react';

import Form from '@appComponents/ReduxFormControls';
import {IconInfoCircle} from '@appComponents/IconCollection';
import {BOOKING} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {EventBookingsDropdownsOptions} from '@graphql/eventBooking/query';

import validate from './../validatorEventBookings';
import {renderBookingUserInformation} from '../helpers/booking';

export default function EventBookingGeoRestrictionsGeneralInformationModel() {
    const informationTooltip = (content) => <IconInfoCircle
        tooltip={content}
    />;

    return {
        entityDataMapKey: 'booking',
        entityLabel: 'booking',
        formName: 'BookingGeneralInformation',
        label: 'booking',
        editForm: false,
        resources: BOOKING,
        showChangeLog: true,
        title: null,
        validate: validate,
        messages: {
            boxName: {
                delete: 'event',
                success: 'event',
                error: 'formInnerErrorMessage',
            },
            text: MESSAGES,
        },
        dataMap: {
            booking: {
                id: '',
                client: {
                    id: '',
                },
                distribution: {
                    id: '',
                },
                auto_update_geo_restrictions: true,
                auto_update_price: true,
                price: null,
                booking_type: {
                    id: 3,
                },
                client_package: {
                    id: '',
                },
                is_hq: false,
                invoice_status: {
                    id: null,
                },
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'booking.id',
                props: {
                    name: 'id',
                },
            },
            booking_type: {
                component: Form.IdField,
                dataMapKey: 'booking.booking_type.id',
                props: {
                    defaultValue: 3,
                    name: 'booking_type',
                    hidden: true,
                },
            },
            client: {
                dataMapKey: 'booking.client.id',
                optionsKey: 'clients',
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    label: 'Client',
                    disabled: true,
                    name: 'client',
                },
            },
            distribution: {
                dataMapKey: 'booking.distribution.id',
                optionsKey: 'distributions',
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    label: 'Distribution',
                    disabled: true,
                    name: 'distribution',
                    className: '--long-width',
                },
            },
            client_package: {
                dataMapKey: 'booking.client_package.id',
                optionsKey: 'client_packages',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Client package',
                    name: 'client_package',
                    disabled: true,
                    allowClear: true,
                },
            },
            is_hq: {
                dataMapKey: 'booking.is_hq',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'High Quality',
                    name: 'is_hq',
                    type: 'toggle',
                },
            },
            price: {
                dataMapKey: 'booking.price',
                props: {
                    component: Form.SemanticInput,
                    label: 'Price',
                    name: 'price',
                    labelDescription: {content: '€'},
                    min: 0,
                },
            },
            auto_update_price: {
                dataMapKey: 'booking.auto_update_price',
                props: {
                    component: Form.SemanticInput,
                    type: 'checkbox',
                    defaultValue: true,
                    label: 'Auto-update price',
                    name: 'auto_update_price',
                    description: informationTooltip('Select this box if you want the price to be automatically updated when ' +
                        'changes in client packages affect the price of this booking'),
                },
            },
            invoice_status: {
                dataMapKey: 'booking.invoice_status.id',
                optionsKey: 'invoiceStatuses',
                props: {
                    component: Form.SemanticSelect,
                    name: 'invoice_status',
                    label: 'Invoice status',
                    placeholder: 'Inherited from event report',
                    allowClear: true,
                    hidden: true,
                    disabled: true,
                },
            },
            auto_update_geo_restrictions: {
                dataMapKey: 'booking.auto_update_geo_restrictions',
                props: {
                    component: Form.SemanticInput,
                    label: 'Auto-update geo restrictions',
                    name: 'auto_update_geo_restrictions',
                    type: 'checkbox',
                    props: {
                        description: informationTooltip('Select this box if you want the geo restrictions to be automatically updated when changes in \n' +
                            'media-rights, client packages or event settings affect the geo restrictions of this booking.'),
                    },
                },
            },
            booked_by: {
                dataMapKey: 'booking.booked_by',
                props: {
                    component: renderBookingUserInformation,
                    label: 'Booked by',
                    name: 'booked_by',
                    type: 'text',
                },
            },
            cancelled_by: {
                dataMapKey: 'booking.cancelled_by',
                props: {
                    component: renderBookingUserInformation,
                    label: 'Cancelled by',
                    name: 'cancelled_by',
                    type: 'text',
                },
            },
        },
        mutation: {},
        create: {
            refetchQueries: ['GetEventBookingsForTable'],
        },
        update: {
            options: {
                refetchQueries: ['GetEventBookingsForTable'],
            },
        },
        delete: {
            refetchQueries: ['GetEventBookingsForTable'],
        },
        query: {
            getOptions: EventBookingsDropdownsOptions,
        },
    };
}
