import React from 'react';
import PropTypes from 'prop-types';

import UsersTableActionColumn from '../../views/UserTable/UsersTableActionColumn';

const contentAlignCenterSettings = {
    header: {
        align: 'center',
        isCollapsed: true,
    },
    content: {
        align: 'center',
        singleLine: true,
    },
};

const contentAlignLeftWithSortingSettings = {
    header: {
        align: 'left',
        isCollapsed: true,
    },
    content: {
        align: 'left',
        singleLine: true,
    },
    sorting: true,
};

export const columns = {
    'firstName': {
        label: 'First name',
        ...contentAlignLeftWithSortingSettings,
    },
    'surname':  {
        label: 'Surname',
        ...contentAlignLeftWithSortingSettings,
    },
    'nickname':  {
        label: 'Nickname',
        ...contentAlignLeftWithSortingSettings,
    },
    'email': {
        label: 'Email',
        ...contentAlignLeftWithSortingSettings,
    },
    'actions': {
        label: 'Actions',
        sorting: false,
        className: '--width-50px',
        ...contentAlignCenterSettings,
    },
};

export const getColumnClassName = (tableRowData) => {
    if (tableRowData.avcmpID) {
        return '--is-archived';
    } else if (tableRowData.blocked) {
        return '--is-disabled';
    }

    return null;
};

getColumnClassName.propTypes = {
    tableRowData: PropTypes.object.isRequired,
};

export const getRowRenderer = (columnName, tableRowData) => {
    switch (columnName) {
        case 'id':
        case 'first_name':
        case 'surname':
        case 'email':
            return tableRowData[columnName];
        case 'actions':
            return <UsersTableActionColumn iamUserData={tableRowData} />;
        default:
            return null;
    }
};

getRowRenderer.propTypes = {
    columnName: PropTypes.string.isRequired,
    tableRowData: PropTypes.object.isRequired,
};
