import PropTypes from 'prop-types';

import {generateBlackoutZonesRow, getBlackoutZonesFromGeoRestrictions} from '@utils/blackoutZones';
import {getBookingUserInformationForExcelExport} from '@modules/events/forms/helpers/booking';
import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions,
} from '@utils/countryHelpers';
import {getYesOrNo} from '@utils/helpers';
import {renderContentCategoryLevelColumn} from '@modules/client/utils/clientBookingTable';
import {formatStartEndDateTime} from '@modules/client/utils/date';

export const exportExcel = (dataExport, clientId) => ({
    dataExport,
    filename: `client_${clientId}_event_bookings`,
    parseData: clientEventBooking => [
        clientEventBooking.event_booking_id,
        clientEventBooking.event_id,
        clientEventBooking.match_id ? clientEventBooking.match_id : '',
        formatStartEndDateTime(clientEventBooking.start_datetime, clientEventBooking.end_datetime),
        renderContentCategoryLevelColumn(clientEventBooking, 'sport', 1),
        renderContentCategoryLevelColumn(clientEventBooking, 'tournament_category', 2),
        renderContentCategoryLevelColumn(clientEventBooking, 'tournament', 3),
        clientEventBooking.content_tier_name,
        clientEventBooking.competitors,
        clientEventBooking.product_short_name,
        clientEventBooking.clips,
        clientEventBooking.event_booking_geo_restrictions
            ? getListOfDeviceCategoriesForGeoRestrictions(clientEventBooking.event_booking_geo_restrictions).join(', ') : '',
        clientEventBooking.event_booking_geo_restrictions
            ? getListOfCodesForDeviceCategories(clientEventBooking.event_booking_geo_restrictions) : '',
        clientEventBooking.event_booking_geo_restrictions
            ? getListOfCountryCodesForDeviceCategories(clientEventBooking.event_booking_geo_restrictions).length.toString() : '',
        clientEventBooking.event_booking_geo_restrictions
            ? generateBlackoutZonesRow(
                getBlackoutZonesFromGeoRestrictions(clientEventBooking.event_booking_geo_restrictions),
                true
            ) : '',
        clientEventBooking.client_package_name,
        clientEventBooking.booking_type_name,
        clientEventBooking.event_booking_creation_datetime,
        getBookingUserInformationForExcelExport(clientEventBooking.booked_by),
        getYesOrNo(clientEventBooking.is_cancelled),
        getBookingUserInformationForExcelExport(clientEventBooking.cancelled_by),
    ],
    path: 'clients.eventBookings.index.path',
    titles: [
        'ID',
        'Event ID',
        'Match ID',
        'Date/Time',
        'Sport / 1st level category',
        'Category / 2nd level category',
        'Tournament / 3rd level category',
        'Tier',
        'Competitors',
        'Product',
        'Clips',
        'Device categories',
        'Countries',
        '#Countries',
        'Blackout Zones',
        'Package',
        'Type',
        'Time',
        'Booked by',
        'Cancelled?',
        'Cancelled by',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired,
};
