import React from "react"

import Form from "../../../app/components/ReduxFormControls";

import validate from "./../validatorMasterBookings";

import {MASTER_BOOKING} from "../../../app/constants/resources";
import * as MESSAGES from "../../../app/constants/messages";

import {CreateMasterBooking} from "../../../../graphql/bookings/mutation.graphql";
import {MasterBookingsDropdownOptionsForCountries} from "../../../../graphql/bookings/query.graphql";

export default function EventMasterBookingGeoRestrictionsModel() {
    const GeoRestrictionsError = (params) => {
        return params.meta.touched && !params.meta.valid
            ? <div className="error errorContent">At least one device category must be switched on.</div>
            : null;
    };

    return {
        entityDataMapKey: "masterBooking",
        entityLabel: "master booking",
        formName: "MasterBookingGeoRestriction",
        label: "master booking",
        editForm: false,
        resources: MASTER_BOOKING,
        showChangeLog: false,
        title: null,
        validate: validate,
        messages: {
            boxName: {
                delete: 'event',
                error: 'event',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "masterBooking.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                }
            },
            geo_restrictions_error_content: {
                dataMapKey: "separator.geo_restrictions_error_content",
                props: {
                    component: (props) => GeoRestrictionsError(props),
                    name: "geo_restrictions_error_content",
                    hidden: true
                }
            },
            desktop_switch: {
                props: {
                    divClassName: "geoRestrictionsSwitch",
                    component: Form.SemanticInput,
                    label: "Desktop",
                    name: "desktop_switch",
                    type: "toggle",
                    checked: false,
                    defaultValue: false
                },
            },
            desktop_countries: {
                optionsKey: "continents",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Desktop countries",
                    name: "desktop_countries",
                    required: true,
                }
            },
            desktop_subdivisions: {
                optionsKey: "countries",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Desktop subdivisions",
                    name: "desktop_subdivisions",
                    required: true,
                }
            },
            mobile_switch: {
                dataMapKey: "mobile.switch",
                props: {
                    divClassName: "geoRestrictionsSwitch",
                    component: Form.SemanticInput,
                    label: "Mobile",
                    name: "mobile_switch",
                    hidden: false,
                    type: "toggle",
                    checked: false,
                    defaultValue: false
                },
            },
            mobile_countries: {
                dataMapKey: "mobile.countries",
                optionsKey: "continents",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Mobile countries",
                    name: "mobile_countries",
                    required: true,
                }
            },
            mobile_subdivisions: {
                dataMapKey: "mobile.subdivisions",
                optionsKey: "countries",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Mobile subdivisions",
                    name: "mobile_subdivisions",
                    required: true,
                }
            },
            smart_tv_switch: {
                dataMapKey: "smart_tv.switch",
                props: {
                    divClassName: "geoRestrictionsSwitch",
                    component: Form.SemanticInput,
                    label: "Smart TV",
                    name: "smart_tv_switch",
                    hidden: false,
                    type: "toggle",
                    checked: false,
                    defaultValue: false
                },
            },
            smart_tv_countries: {
                dataMapKey: "smart_tv.countries",
                optionsKey: "continents",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Smart TV countries",
                    name: "smart_tv_countries",
                    required: true,
                }
            },
            smart_tv_subdivisions: {
                dataMapKey: "smart_tv.subdivisions",
                optionsKey: "countries",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Smart TV subdivisions",
                    name: "smart_tv_subdivisions",
                    required: true,
                }
            }
        },
        mutation: {
            createEntity: CreateMasterBooking
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventMasterBookingsForView'],
            },
            update: {
                refetchQueries: ['GetEventMasterBookingsForView'],
            },
            delete: {
                refetchQueries: ['GetEventMasterBookingsForView'],
            }
        },
        query: {
            getOptions: MasterBookingsDropdownOptionsForCountries
        }
    }
}
