import PropTypes from 'prop-types';
import {map as _map} from 'lodash';

import {getYesOrNo} from '@utils/helpers';

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_packages`,
    parseData: clientPackage => [
        clientPackage.id,
        clientPackage.client_product.product.short_name,
        clientPackage.name,
        clientPackage.client_package_type.name,
        clientPackage.booking_type.name,
        clientPackage.content_package_selection_type.name,
        _map(clientPackage.event_content_variants, (eventContentVariant) => eventContentVariant.name).join(', '),
        clientPackage.start_date,
        clientPackage.end_date,
        0 < clientPackage.client_package_geo_restrictions.length
            ? 'Specific'
            : 'Same as product settings',
        getYesOrNo(clientPackage.is_premium),
        getYesOrNo(clientPackage.is_hq),
        getYesOrNo(clientPackage.is_test),
        getYesOrNo(clientPackage.is_disabled),
    ],
    path: 'clients.packages.index.path',
    titles: [
        'ID',
        'Product',
        'Name',
        'Type',
        'Booking',
        'Content selection',
        'Content variants',
        'Start date',
        'End date',
        'Geo restrictions',
        'Premium?',
        'High Quality?',
        'Test?',
        'Disabled?',
    ],
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired
};
