import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';
import { Dimmer, Loader} from 'semantic-ui-react';

import {ModalHeaderEventContentInformation} from '@modules/events/views/common/ModalHeaderEventContentInformation';

/**
 * Information about event content details in "Play output streams" modal
 */
export const HeaderEventContent = ({eventContentDetails, showBottomDivider = true}) => {
    if (_isEmpty(eventContentDetails)) {
        return (
            <Dimmer active inverted>
                <Loader inverted />
            </Dimmer>
        );
    }

    return (
        <ModalHeaderEventContentInformation event={eventContentDetails} showBottomDivider={showBottomDivider} />
    );
};

HeaderEventContent.propTypes = {
    eventContentDetails: PropTypes.object,
    showBottomDivider: PropTypes.bool,
};

HeaderEventContent.defaultProps = {
    eventContentDetails: {},
    showBottomDivider: true,
};
