import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 50}, 'name'));
    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));
    Object.assign(errors, validators.required(values.encoding_datacenter, 'encoding_datacenter'));
    Object.assign(errors, validators.required(values.input_id, 'input_id'));
    Object.assign(errors, validators.maxLength({value: values.input_id, maxLength: 50}, 'input_id'));
    Object.assign(errors, validators.urlOptional(values.preview_url, 'preview_url', undefined, '.+'));
    Object.assign(errors, validators.maxLength({value: values.preview_url, maxLength: 500}, 'preview_url'));

    return errors;
};

export default validate;
