import PropTypes from 'prop-types';
import {useEffect} from 'react';

import {getLink} from '@appComponents/Link';
import {showErrorModal} from '@utils/modal';

export function EventRedirect({
    errorMessage,
    eventContentId = null,
    eventId = null,
    history,
}) {
    useEffect(() => {
        const hash = (eventContentId) ? `#content-${eventContentId}` : null;

        (eventId)
            ? history.push(getLink("events.configuration.index", {id: eventId}, {}, hash))
            : showErrorModal({
                header: 'Page not found',
                redirectLink: getLink('dashboard'),
                text: `The requested ${errorMessage} could not be found.`,
                errorButtonIcon: 'home',
                errorButtonText: 'Go to home page',
            });
    }, []);

    return null;
}

EventRedirect.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    eventContentId: PropTypes.number,
    eventId: PropTypes.number,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default EventRedirect;
