import {isEmpty as _isEmpty, has as _has} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Segment} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {IconEdit, IconCheckmark, IconWithTooltip, IconInfoCircle} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import Table from '@appComponents/Table';
import {getName, getParsedId} from '@utils/helpers';
import {
    columnObjectAlignCenter,
    columnObjectAlignLeft,
    renderProductColumnForComputerVision,
} from '@utils/tableHelpers';
import {NO_ASSOC_CDN_CONFIGS, NO_ASSOC_VOD_STORAGES} from '@constants/messages';
import {AUDIO, ENCODING_TARGET, SUBTITLE} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import IconsAudioConfiguration from '@modules/events/views/EventsConfigurationTargetsTable/IconsAudioConfiguration';
import IconsSubtitleConfiguration
    from '@modules/events/views/EventsConfigurationTargetsTable/IconsSubtitleConfiguration';

import {
    getRowClassname,
    hasNoClientCdnConfigurationsForSpott,
    hasEncodingJobProfileDRMSupportEnabled,
    hasEncodingTargetDRMSupportEnabled,
    showInfoSpottIcon,
    showInfoDrmIcon,
} from '../utils/eventsConfigurationTargetsTableHelper';

const authorizationEncodingTargetReadObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_READ,
};

const authorizationAudioReadObject = {
    [AUDIO]: SECURITY_PRIVILEGES_READ,
};

const authorizationSubtitleReadObject = {
    [SUBTITLE]: SECURITY_PRIVILEGES_READ,
};

const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => getParsedId(row),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'product': {
        label: 'Product',
        sorting: (row) => row.product.name,
    },
    'distribution_type': {
        label: 'Distribution type',
        sorting: true,
    },
    'stream_delay_type': {
        label: 'Stream delay type',
        sorting: true,
    },
    'is_default': {
        label: 'Default?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'device_categories': {
        label: 'Device categories',
        sorting: row => renderListOfNameOfFirstLevelDependency(row, 'device_categories'),
    },
    'cdn': {
        label: 'CDN',
        sorting: (row) => setCdnColumn('cdn', row),
    },
    'ingest_method': {
        label: 'Ingest method',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'encoder_type': {
        label: 'Encoders',
        sorting: true,
    },
    'encoding_job_profile': {
        label: 'Encoding job profile',
        sorting: true,
    },
    'stream_name_prefix': {
        label: 'Stream name prefix',
        sorting: true,
    },
    'has_playback_authentication': {
        label: 'Auth?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_scoreboard_overlay_support': {
        label: 'SB overlay?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_slate_support': {
        label: 'Slate?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_overlay_support': {
        label: 'Overlay?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_ad_support': {
        label: 'Ads?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_drm_support': {
        label: 'DRM?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_cc': {
        label: 'CC?',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'actions': {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },
};

const renderCheckbox = (row, column) => {
    return (
        (null === row[column] || row[column] === undefined || false === row[column]) ? '' : <IconCheckmark/>
    );
};

const renderListOfNameOfFirstLevelDependency = (row, column) => {
    return row[column].map((item => (item.name))).join(', ');
};

const setCdnColumn = (column, row) => {
    const cdnName = getName(row[column]),
        akamaiCdnIngestMethodName = _has(row, 'akamai_cdn_ingest_method.name')
            ? ` - ${row.akamai_cdn_ingest_method.name}`
            : '';

    return cdnName
        ? (cdnName + akamaiCdnIngestMethodName)
        : '';
};

const getDrmInfoText = (row) => {
    if (hasEncodingTargetDRMSupportEnabled(row) && !hasEncodingJobProfileDRMSupportEnabled(row)) {
        return 'This encoding target has DRM enabled but its encoding job profile does not support DRM.';
    } else if (!hasEncodingTargetDRMSupportEnabled(row) && hasEncodingJobProfileDRMSupportEnabled(row)) {
        return 'This encoding target has DRM disabled but its encoding job profile supports DRM.';
    }

    return '';
};

const getIconWarningText = (row) => {
    const textTooltip = [];

    if (showInfoSpottIcon(row)) {
        textTooltip.push(
            <>
                {(hasNoClientCdnConfigurationsForSpott(row) ? NO_ASSOC_CDN_CONFIGS : NO_ASSOC_VOD_STORAGES)}
                <br />
            </>
        );
    }

    if (showInfoDrmIcon(row)) {
        textTooltip.push(<React.Fragment>{getDrmInfoText(row)}</React.Fragment>);
    }

    return textTooltip;
};

const EventsConfigurationTargetsTable = (props) => {
    const {content, eventId, eventContentId, targets, loading} = props;

    const rowRenderer = (column, row) => {
        const cdnColumn = setCdnColumn(column, row),
            iconWarningText = getIconWarningText(row);

        switch (column) {
            case 'product':
                return renderProductColumnForComputerVision(row.product.name, row.is_computer_vision);
            case 'cdn':
                return <div>{cdnColumn}</div>;
            case 'ingest_method':
                return row[column] ? row[column].name : null;
            case 'stream_delay_type':
                return <div>{row[column] ? `${row[column].name}` : ''}{row.delay ? ` (${row.delay}s)` : ''}</div>;
            case 'device_categories':
                return renderListOfNameOfFirstLevelDependency(row, column);
            case 'has_playback_authentication':
                return (
                    <>
                        {renderCheckbox(row, column)}
                        {null !== row.token_ttl && <IconInfoCircle tooltip={`Token TTL: ${row.token_ttl}s`} />}
                    </>
                );
            case 'is_default':
            case 'has_slate_support':
            case 'has_overlay_support':
            case 'has_ad_support':
            case 'has_scoreboard_overlay_support':
            case 'has_drm_support':
            case 'has_cc':
                return renderCheckbox(row, column);
            case 'actions' :
                return (
                    <div className='actions-list-icon'>
                        <Authorization
                            authorization={authorizationEncodingTargetReadObject}
                        >
                            <IconEdit size='large' link={
                                getLink(
                                    'events.configuration.content.target.edit',
                                    {id: eventId, contentId: eventContentId, targetId: row.id}
                                )
                            }
                            resource={ENCODING_TARGET}
                            />
                        </Authorization>
                        <Authorization authorization={authorizationAudioReadObject}>
                            <IconsAudioConfiguration
                                content={content}
                                tableRow={row}
                            />
                        </Authorization>
                        <Authorization authorization={authorizationSubtitleReadObject}>
                            <IconsSubtitleConfiguration
                                content={content}
                                tableRow={row}
                            />
                            {
                                (!_isEmpty(iconWarningText))
                                    ? <IconWithTooltip
                                        name='info circle'
                                        iconProps={{size: 'large'}}
                                        popupProps={{
                                            wide: 'very',
                                            position: 'top right',
                                        }}
                                        tooltip={iconWarningText}
                                    />
                                    : null
                            }
                        </Authorization>
                    </div>
                );
            default:
                return null;
        }
    };

    const sortTargets = (row) => {
        return row.distribution_type ? `${row.product.id} - ${row.distribution_type.id}` : null;
    };

    return (
        <Segment basic className='event_configuration_target__table --noPadding'>
            <Table
                name='eventConfigurationTargetList'
                columns={columns}
                data={targets}
                noDataText='No encoding targets defined.'
                noMessageBox
                loading={loading}
                defaultSort={sortTargets}
                rowRenderer={rowRenderer}
                pagination={false}
                className='mini'
                rowClassname={getRowClassname}
            />
        </Segment>
    );
};

EventsConfigurationTargetsTable.defaultProps = {
    targets: [],
};

EventsConfigurationTargetsTable.propTypes = {
    content: PropTypes.object.isRequired,
    eventContentId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    onDelete: PropTypes.func,
    targets: PropTypes.array,
};

export default EventsConfigurationTargetsTable;
