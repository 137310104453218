import Form from "../../app/components/ReduxFormControls";

import {prepareMessageText} from "../utils/contentCategoryHelpers";
import {getSuffixWithNumber} from "../../../utils/ordinalNumberSuffix";

import * as MESSAGES from "../../app/constants/messages";
import {CONTENT_CATEGORY} from "../../app/constants/resources";

import validate from "./validator";

import {CreateContentCategory, UpdateContentCategory} from "../../../graphql/contentCategory/mutation.graphql";
import {GetContentCategoryForForm} from "../../../graphql/contentCategory/query.graphql";

export default function ContentCategoryModel() {
    return {
        entityDataMapKey: "contentCategory",
        entityLabel: "content category",
        formName: "content category",
        label: "content category",
        resources: CONTENT_CATEGORY,
        showChangelog: true,
        title: (data, type, props) => (`Add new ${getSuffixWithNumber(props.formParams.level)} level category`),
        validate: validate,
        dataMap: {
            contentCategory: {
                id : "",
                name: "",
            },
        },
        messages: {
            boxName: {
                delete: "contentCategoriesMessage",
                error: "contentCategoriesMessage",
                success: "contentCategoriesMessage",
            },
            text: MESSAGES,
            create: {
                success: (data) => prepareMessageText(data),
                error: (data) => prepareMessageText(data, true),
            },
            update: {
                success: (data) => prepareMessageText(data),
                error: (data) => prepareMessageText(data, true),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "contentCategory.id",
                props: {
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            name: {
                dataMapKey: "contentCategory.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                    autoFocus: true,
                },
            },
        },
        mutation: {
            createEntity: CreateContentCategory,
            updateEntity: UpdateContentCategory,
        },
        query: {
            getEntity: GetContentCategoryForForm,
        },
    }
}
