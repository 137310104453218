import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';

import ProductionCompanyForm from '../forms/ProductionCompanyForm';
import ProductionCompanyModel from '../forms/ProductionCompanyModel';

const ProductionCompanyEdit = ({match: {params: {id = null}}}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Production company', routes.productionCompanies.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(ProductionCompanyModel, ProductionCompanyForm, {id: convertToInt(id)})),
        [id]
    );

    return <Form/>;
};

ProductionCompanyEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

export default withRouter(ProductionCompanyEdit);
