import PropTypes from "prop-types";

import {getContentTierName, getProperty} from "../common";

export const tournament = (event) => {
    const eventData = {
        data: event,
        srWithMatchID: ['match', 'tournament'],
        srWithoutMatchID: ['tournament'],
    };

    return getProperty(eventData) + getContentTierName(event);
};

tournament.propTypes = {
    event: PropTypes.object.isRequired,
};
