import {Label, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

import { EVENT_CONTENT_VARIANT_STANDARD_FEED} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

export const EventContentVariantPopup = ({eventContentVariant}) => {

    if (convertToInt(eventContentVariant.id) !== EVENT_CONTENT_VARIANT_STANDARD_FEED) {
        return (
            <Popup
                trigger={<Label content={eventContentVariant.short_name}
                    color='blue' size='mini' className='--marginLeft-0-5-em contentVariantLabel'/>}
                content={eventContentVariant.name}
                size='small'
                hoverable
            />
        );
    }

    return null;
};

EventContentVariantPopup.propTypes = {
    eventContentVariant: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        short_name: PropTypes.string,
    }),
};
