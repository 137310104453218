import GeoRestrictionsFormEditModel from "../../geoRestriction/forms/GeoRestrictionsFormEditModel";

import {RIGHT_SCOPE} from "../../app/constants/resources";
import validatorRightsScopeGeoRestriction from "./validatorRightsScopeGeoRestriction";
import * as MESSAGES from "../../app/constants/messages";

import {GetRightsScopeForGeoRestrictionsForm} from "../../../graphql/rightsScope/query.graphql";
import {UpdateGeoRestrictionsRightsScope} from "../../../graphql/rightsScope/mutation.graphql";

export default function RightsScopeGeoRestrictionModel() {
    let rightsScopeGeoRestrictionsModel = GeoRestrictionsFormEditModel();

    rightsScopeGeoRestrictionsModel.entityDataMapKey ="rightsScope";
    rightsScopeGeoRestrictionsModel.entityLabel = "geo restrictions";
    rightsScopeGeoRestrictionsModel.formName = "rights scope";
    rightsScopeGeoRestrictionsModel.label ="geo restrictions";
    rightsScopeGeoRestrictionsModel.resources = RIGHT_SCOPE;
    rightsScopeGeoRestrictionsModel.validate = validatorRightsScopeGeoRestriction;
    rightsScopeGeoRestrictionsModel.dataMap = {
        rightsScope: {
            selection_type: {
                id: "1",
            }
        }
    };
    rightsScopeGeoRestrictionsModel.messages = {
        boxName: {
            delete: 'propertyLicenceMessage',
            error: 'propertyLicenceMessage',
            success: 'propertyLicenceMessage',
        },
        text: {
            ...MESSAGES,
            SAVED: () => 'The geo restrictions have been saved successfully.',
            FORM_ERROR: () => 'The geo restrictions have not been saved. Please correct the highlighted fields below.',
        },
    };
    rightsScopeGeoRestrictionsModel.fields.id.dataMapKey = "rightsScope.id";
    rightsScopeGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsRightsScope,
    };
    rightsScopeGeoRestrictionsModel.query.getEntity = GetRightsScopeForGeoRestrictionsForm;

    delete rightsScopeGeoRestrictionsModel.deleteId;
    delete rightsScopeGeoRestrictionsModel.mutationOptions;

    return rightsScopeGeoRestrictionsModel;
}
