import {connect} from 'react-redux';
import {withRouter} from 'react-router';


import {getLink} from '@appComponents/Link';
import {convertToInt, defaultValuePrice} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {PRODUCT_LCO} from '@constants/variables';

import ClientProductForm from './ClientProductForm';
import {deleteSuccessCallback, setClientProductsEntity} from '../utils/clientProducts';

class ClientProductLcoForm extends ClientProductForm {
    componentDidMount() {
        super.componentDidMount();

        const entityName = 'client',
            params = {
                clientData: this.props.clientData,
                entityName,
                setEntity: this.props.Entity.setEntity,
            };

        this.setCreateSuccessCallback((response) => {
            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientProducts: this.props.clientData.clientProducts.concat(
                        response.data.createClientProductLco.client_product
                    ),
                }),
            });

            this.props.history.push(getLink('clients.products.lco.editGeoRestrictions', {
                id: this.props.match.params.id,
            }));
        });

        this.setUpdateSuccessCallback((response) => {
            const clientProductLcoIndex = this.props.clientData.clientProducts.findIndex(clientProduct =>
                    PRODUCT_LCO === convertToInt(clientProduct.product.id)
                ),
                clientProducts = {};

            clientProducts[clientProductLcoIndex] = Object.assign({},
                response.data.updateClientProductLco.client_product
            );
            params.clientProducts = clientProducts;

            setClientProductsEntity(params);
        });

        this.setDeleteSuccessCallback(() => {
            params.formParamsId = this.props.formParams.id;
            params.historyPush = this.props.history.push;

            deleteSuccessCallback(params);
        });

        this.setOnChangeCallback({
            has_additional_cdn_bucket: (data) => {
                this.handleHasAdditionalCdnBucketChanged(!data.value);
            },
        });
    }

    componentDidUpdate() {
        const {
            formParams: {isAddForm},
            GraphQLEntityData: {
                clientProductsLco,
                loading: entityLoading,
            } = {
                entityLoading: false,
            },
            GraphQLOptionsData: {
                loading: optionsLoading,
            },
        } = this.props;

        let nextProps;

        if (!optionsLoading && !entityLoading && !this.state.dataReceived) {
            if (!isAddForm) {
                nextProps = Object.assign({}, this.props, {
                    clientProduct: clientProductsLco[0].client_product,
                });

                this.setField('id', {defaultValue: convertToInt(clientProductsLco[0].id)});
                this.setField('cdn_price', {defaultValue: defaultValuePrice(clientProductsLco[0].cdn_price)});
                this.handleHasAdditionalCdnBucketChanged(clientProductsLco[0].has_additional_cdn_bucket);
            }

            super.componentDidUpdate(nextProps);
        }
    }

    prepareDataForSubmit(data) {
        data = Object.assign({}, data, {product: PRODUCT_LCO});

        const dataToSubmit = super.prepareDataForSubmit(data);

        return Object.assign({}, dataToSubmit, {
            cdn_price: parseFloat(data.cdn_price),
            has_additional_cdn_bucket: !!data.has_additional_cdn_bucket,
            additional_cdn_bucket_size_gb: (!data.has_additional_cdn_bucket)
                ? null
                : convertToInt(data.additional_cdn_bucket_size_gb),
        });
    }

    handleHasAdditionalCdnBucketChanged = (value) => {
        this.setField('additional_cdn_bucket_size_gb', {hidden: !value});
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity']),
    mapStateToProps = (state) => ({clientData: state.app.entities.client || null});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientProductLcoForm));
