import {filter as _filter, groupBy as _groupBy} from 'lodash';
import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';

export const isDistributionPartOfProduct = (product, distribution, productDistributionTypes) => {
    const productDistributions = getDistributionsPartOfProduct(product, distribution, productDistributionTypes);

    return 0 < productDistributions.length;
};

export const getDistributionsPartOfProduct = (product, distribution, productDistributionTypes) => {
    const grouped = _groupBy(productDistributionTypes, ((item) => (
        convertToInt(item.product.id)
    )));

    return _filter(grouped[product], (o) => {
        return convertToInt(o.distribution_type.id) === convertToInt(distribution);
    });
};

isDistributionPartOfProduct.propTypes = {
    product: PropTypes.number.isRequired,
    distribution: PropTypes.number.isRequired,
    productDistributionTypes: PropTypes.arrayOf(PropTypes.object),
};
