import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    if (values.num_bookable_events) {
        Object.assign(errors, validators.digitsOnly(values.num_bookable_events, 'num_bookable_events'));
    }

    if (values.event_price) {
        Object.assign(errors, validators.floatAndNumbersWithFixedDecimal({value: values.event_price}, 'event_price'));
    }

    if (values.start_datetime) {
        Object.assign(errors, validators.dateTime(values.start_datetime, 'start_datetime'));
        Object.assign(errors, validators.futureDateTime(values.start_datetime, 'start_datetime'));
    } else {
        Object.assign(errors, validators.required(values.start_datetime, 'start_datetime'));
    }

    return errors;
};

export default validate;
