import PropTypes from "prop-types";
import {get as _get} from "lodash";

import {getBookingUserInformationForExcelExport} from "../../forms/helpers/booking";
import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions
} from "../../../../utils/countryHelpers";

export const exportExcel = (dataExport, eventId) => ({
    dataExport: dataExport,
    filename: `event_${eventId}_master_bookings`,
    parseData: eventMasterBooking => [
        eventMasterBooking.id,
        eventMasterBooking.client.name,
        eventMasterBooking.product.short_name,
        getListOfDeviceCategoriesForGeoRestrictions(eventMasterBooking.master_booking_geo_restrictions).join(', '),
        getListOfCodesForDeviceCategories(eventMasterBooking.master_booking_geo_restrictions),
        getListOfCountryCodesForDeviceCategories(eventMasterBooking.master_booking_geo_restrictions).length.toString(),
        _get(eventMasterBooking, 'client_package.name', ''),
        eventMasterBooking.booking_type.name,
        eventMasterBooking.creation_datetime,
        getBookingUserInformationForExcelExport(eventMasterBooking.booked_by),
    ],
    path: 'events.masterBookings.index.path',
    titles: [
        "ID",
        "Client",
        "Product",
        "Device categories",
        "Countries",
        "#Countries",
        "Package",
        "Type",
        "Time",
        "Booked by",
    ]
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    eventId: PropTypes.number.isRequired
}
