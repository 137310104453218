import React from "react"
import {Input} from "semantic-ui-react";
import {
    isFunction as _isFunction,
    isEqual as _isEqual
} from "lodash"
import {connect} from "react-redux";

import {ReduxComponent} from "./ReduxComponent";
import Datepicker from "./Datepicker";

class ReduxInput extends ReduxComponent {
    constructor(props) {
        super(props);

        let value = this.parseValue(props.value),
            defaultValue = this.parseValue(props.defaultValue);

        if (!props.value && props.defaultValue) {
            value = defaultValue;
        }

        if (undefined !== props.setValue) {
            props.input.onChange(props.setValue);
            value = props.setValue;
        }

        this.state = {
            value: value,
            defaultValue: defaultValue,
            visited: false
        }
    }

    isInputHidden() {
        return this.props.input.name === "id";
    }

    onChange = (event, data) => {
        if (data.value === "" && this.props.resetWhenEmpty) {
            this.props.input.onChange(this.props.defaultValue);
        } else {
            this.props.input.onChange(data.value);
        }

        if (_isFunction(this.props.onChange)) {
            this.props.onChange(data)
        }
    };

    componentWillReceiveProps(nextProps) {
        let value = nextProps.input.value;

        if (!_isEqual(nextProps.setValue, this.state.setValue)) {
            this.setState(() => ({
                setValue: nextProps.setValue,
            }), () => {this.props.input.onChange(nextProps.setValue)});

            return
        }

        if (!_isEqual(nextProps.defaultValue, this.props.defaultValue) && !this.state.visited) {
            this.setState(() => ({
                defaultValue: nextProps.defaultValue,
                visited: true
            }));

            this.props.input.onChange(nextProps.defaultValue);

            return
        }

        if (!nextProps.storedValue) {
            if (nextProps.resetToDefaultValue) {
                value = nextProps.defaultValue;

                this.props.input.onChange(nextProps.defaultValue)
            }
        } else {
            value = nextProps.storedValue
        }

        this.setState(() => ({
            value: value
        }))
    }

    renderContent() {
        switch (this.props.type) {
            case "date":
                return (
                    <Datepicker
                        timestamp={this.props.timestamp}
                        {...this.props.input}
                        configuration={this.props.configuration}
                        defaultValue={this.state.value || this.props.defaultValue}
                        disabled={this.props.disabled ? this.props.disabled : this.props.meta.submitting}
                        error={this.isError(this.props)}
                        selected={this.props.selected}
                        onChangeDate={this.props.onChangeDate}
                        isReduxComponent={true}
                        placeholderText={this.props.placeholder}
                        restorePreviousValue={this.props.restorePreviousValue || false}
                        meta={this.props.meta}
                    />
                );
            default:
                return (
                    <Input
                        value={this.state.value}
                        onChange={this.onChange}
                        placeholder={this.props.placeholder}
                        icon={this.props.icon}
                        className={this.isInputHidden() || this.props.hidden ? 'hidden' : '--' + this.props.input.name}
                        disabled={this.props.meta.submitting || this.props.disabled}
                        error={this.isError(this.props)}
                        type={this.props.type || "text"}
                        size={this.props.size || "small"}
                        autoComplete="off"
                    />
                );
        }
    }

    render() {
        let descriptionClassName = "";

        if (this.props.description) {
            descriptionClassName = "--description"
        }

        return (
            <div className={`input-container ${descriptionClassName} ${this.props.className || ""}`}>
                {this.renderContent()}<div className="input_description">{this.props.description}</div>
                <div className="error info">{this.isError(this.props) ? this.props.meta.error : ''}</div>
            </div>
        )
    }
}

let mapStateToProps = (state, props) => {
    let value = (
        state.form[props.meta.form]
        && state.form[props.meta.form].values
        && state.form[props.meta.form].values[props.input.name]
    )
        ? state.form[props.meta.form].values[props.input.name]
        : null;

    return {
        storedValue : value
    }
};

export default connect(mapStateToProps)(ReduxInput)
