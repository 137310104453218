import EventMasterBookingGeoRestrictionsGeneralInformationModel
    from "./EventMasterBookingGeoRestrictionsGeneralInformationModel";

import * as MESSAGES from "../../../app/constants/messages";

import {GetEventMasterBookingForForm} from "../../../../graphql/bookings/query.graphql"
import {
    UpdateMasterBooking,
    DeleteMasterBooking
} from "../../../../graphql/bookings/mutation.graphql";

export default function EventMasterBookingGeoRestrictionsGeneralInformationEditModel() {
    let EventMasterBookingGeoRestrictionsGeneralInformationEditModel = EventMasterBookingGeoRestrictionsGeneralInformationModel();

    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.deleteId = "masterBooking.id";
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.editForm = true;
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.messages.text = {
        ...MESSAGES,
        DELETE_CONFIRMATION: () => "Are you sure you want to delete this master booking?",
    };
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.fields.client.props.disabled = true;
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.fields.product.props.disabled = true;
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.fields.client_package.props.disabled = false;
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.mutation = {
        updateEntity: UpdateMasterBooking,
        deleteEntity: DeleteMasterBooking,
    };
    EventMasterBookingGeoRestrictionsGeneralInformationEditModel.query.getEntity = GetEventMasterBookingForForm;

    return EventMasterBookingGeoRestrictionsGeneralInformationEditModel;
}
