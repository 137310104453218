import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {SDI_ORIGIN_ENTRY_POINTS} from "../../app/constants/resources";

import validate from "./validator";

import {GetSdiOriginEntryPointForForm} from "../../../graphql/sdiOriginEntryPoint/query.graphql";
import {GetEncodingDatacenters} from "../../../graphql/encodingDatacenter/query.graphql";
import {
    CreateSdiOriginEntryPoint,
    DeleteSdiOriginEntryPoint,
    UpdateSdiOriginEntryPoint
} from "../../../graphql/sdiOriginEntryPoint/mutation.graphql";

export default function SdiOriginEntryPointModel() {
    return {
        entityDataMapKey: "sdiOriginEntryPoint",
        entityLabel: "SDI origin entry point",
        formName: "SdiOriginEntryPoint",
        label: "SDI origin entry point",
        resources: SDI_ORIGIN_ENTRY_POINTS,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            sdiOriginEntryPoint: {
                id : "",
                name: "",
                description: "",
                encoding_datacenter: {id: ""},
                input_id: "",
                preview_url: "",
            },
        },
        messages: {
            boxName: {
                delete: "sdiOriginEntryPoint",
                error: "sdiOriginEntryPointMessageError",
                success: "sdiOriginEntryPoint",
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('SDI origin entry point')
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "sdiOriginEntryPoint.id",
                props: {
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            name: {
                dataMapKey: "sdiOriginEntryPoint.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            description: {
                dataMapKey: "sdiOriginEntryPoint.description",
                props: {
                    component: Form.SemanticInput,
                    label: "Description",
                    name: "description",
                    type: "input",
                },
            },
            encoding_datacenter: {
                dataMapKey: "sdiOriginEntryPoint.encoding_datacenter.id",
                optionsKey: "encodingDatacenters",
                props: {
                    component: Form.SemanticSelect,
                    label: "Encoding datacenter",
                    name: "encoding_datacenter",
                    placeholder: "Select encoding datacenter",
                    required: true,
                },
            },
            input_id: {
                dataMapKey: "sdiOriginEntryPoint.input_id",
                props: {
                    component: Form.SemanticInput,
                    label: "Input ID",
                    name: "input_id",
                    required: true,
                    type: "input",
                },
            },
            preview_url: {
                dataMapKey: "sdiOriginEntryPoint.preview_url",
                props: {
                    component: Form.SemanticInput,
                    label: "Preview URL",
                    name: "preview_url",
                    type: "input",
                },
            },
        },
        mutation: {
            createEntity: CreateSdiOriginEntryPoint,
            deleteEntity: DeleteSdiOriginEntryPoint,
            updateEntity: UpdateSdiOriginEntryPoint,
        },
        query: {
            getEntity: GetSdiOriginEntryPointForForm,
            getOptions: GetEncodingDatacenters,
        },
    }
}
