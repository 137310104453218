import React from 'react';
import PropTypes from 'prop-types';

import {Authorization} from '@appComponents/Authorization';
import {CLIENT_WHITELISTED_IP_AV_API_ACCESS} from '@constants/resources';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

const ClientAvapiWhitelistingIpIndexButtons = ({client}) => (
    <Authorization authorization={{[CLIENT_WHITELISTED_IP_AV_API_ACCESS]: SECURITY_PRIVILEGES_CREATE}}>
        <Link name='clients.whitelistedIps.add' params={{id: client}}>
            <div className='content__buttons'>
                <ButtonAdd>Add new CIDR</ButtonAdd>
            </div>
        </Link>
    </Authorization>
);
ClientAvapiWhitelistingIpIndexButtons.propTypes = {
    client: PropTypes.number,
};
export default ClientAvapiWhitelistingIpIndexButtons;
