import React from 'react';
import {has as _has, isUndefined as _isUndefined} from 'lodash';
import PropTypes from 'prop-types';
/* eslint import/no-unresolved: 0 */
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {gql, graphql, withApollo} from 'react-apollo';
import {renderModalError} from '@utils/forms';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {routes} from '@constants/routes';
import {PULL} from '@constants/variables';
import {sortByTextValue} from '@utils/sorters';

import {exportExcel} from '../utils/export/productThirdPartyStreamNamesIndexExcel';
import ProductThirdPartyStreamNamesPullForm from '../forms/ProductThirdPartyStreamNamesPullForm';
import ProductThirdPartyStreamNamesPullTable from '../components/ProductThirdPartyStreamNamesPullTable';
import ProductThirdPartyStreamNamesIndexButtons from '../components/ProductThirdPartyStreamNamesIndexButtons';
import * as thirdPartyStreamNamesPullGraphQl from '../../../graphql/clients/thirdPartyStreamNamesPull';


export class ProductThirdPartyStreamNamesPullIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        DataProductThirdPartyStreamNamesPull: PropTypes.object,
        DeleteProductThirdPartyStreamNamesPull: PropTypes.func,
        loading: PropTypes.bool,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        pullOrigins: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingModal: false,
            modalOpenedOnPageLoad: false,
            productThirdPartyStreamNamesPull: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataProductThirdPartyStreamNamesPull: {thirdPartyClientStreamNamesPull},
        } = nextProps;

        let nextState = {};

        if (
            thirdPartyClientStreamNamesPull
            && thirdPartyClientStreamNamesPull !== this.state.productThirdPartyStreamNamesPull
        ) {
            nextState.productThirdPartyStreamNamesPull = sortByTextValue(thirdPartyClientStreamNamesPull, 'stream_name');
        }

        this.setState(nextState, () => {
            if (this.state.modalOpenedOnPageLoad) {
                return false;
            }

            const streamNamesPullRoutes = routes.clients.products.thirdParty.streamNamesPull;

            if (
                nextProps.match.path === streamNamesPullRoutes.add.path
                && !nextProps.DataProductThirdPartyStreamNamesPull.loading
            ) {
                this.openModal({});
            } else if (
                nextProps.match.path === streamNamesPullRoutes.edit.path
                && !nextProps.DataProductThirdPartyStreamNamesPull.loading
            ) {
                const streamNamesPull = thirdPartyClientStreamNamesPull.filter(
                    item => parseInt(item.id, 10) === parseInt(nextProps.match.params.thirdPartyClientPullStreamNameId, 10)
                ).pop();

                if (!_isUndefined(streamNamesPull)) {
                    this.openModal(streamNamesPull);
                } else {
                    nextProps.Modal.setModal({
                        isVisible : true,
                        content: renderModalError(
                            'Stream name',
                            getLink(
                                'clients.products.thirdParty.streamNamesPull.index',
                                {id: this.props.match.params.id}
                            )
                        ),
                    });
                }
            }
        });
    }

    resetIndexUrl = () => {
        window.history.pushState({}, '', getLink('clients.products.thirdParty.streamNamesPull.index', {
            id: this.props.match.params.id,
        }));
    };

    queryDataForModal = (id) => {
        let modalLink = getLink('clients.products.thirdParty.streamNamesPull.edit', {
            id: this.props.match.params.id,
            thirdPartyClientPullStreamNameId: id,
        });

        this.setState(() => ({
            loadingModal: true,
        }));

        this.props.client.query({
            query: ProductThirdPartyStreamNamePullQuery,
            variables: {
                id: id,
            },
        }).then((result) => {
            this.setState(() => ({
                loadingModal: false,
            }));

            this.openModal(result.data.thirdPartyClientStreamNamePull, modalLink);
        });
    };

    prepareDataForModal = (id) => {
        this.setState(() => ({
            modalOpenedOnPageLoad: true,
        }));

        let modalLink = getLink('clients.products.thirdParty.streamNamesPull.add', {
            id: this.props.match.params.id,
        });

        if (!isNaN(id)) {
            this.queryDataForModal(id);
        } else if (_has(id, 'id')) {
            this.queryDataForModal(id.id);
        } else {
            this.openModal({}, modalLink);
        }
    };

    openModal = (data, modalLink) => {
        const {
            Modal: {setModal},
        } = this.props;

        window.history.pushState({}, '', modalLink);

        setModal({
            content: <ProductThirdPartyStreamNamesPullForm
                formData={{
                    pullOrigins: this.props.pullOrigins.map((pullOrigin) => ({
                        key: pullOrigin.id,
                        text: pullOrigin.name,
                        value: pullOrigin.id,
                    })),
                    thirdPartyStreamName: data,
                }}
                resetIndexUrl={this.resetIndexUrl}
            />,
            isVisible: true,
            onClose: this.resetIndexUrl,
            size: 'small',
        });
    };

    render() {
        return (
            <Segment basic loading={this.state.loadingModal} className='--marginTopNegative-15 --paddingTop-0'>
                <HeaderRenderer
                    buttons={ProductThirdPartyStreamNamesIndexButtons}
                    buttonsProps={{
                        prepareDataForModal: this.prepareDataForModal,
                        type: PULL,
                    }}
                    exportExcelParams={exportExcel(
                        this.state.productThirdPartyStreamNamesPull,
                        this.props.match.params.id,
                        PULL
                    )}
                    loading={this.props.DataProductThirdPartyStreamNamesPull.loading}
                />
                <ProductThirdPartyStreamNamesPullTable
                    editClick={this.prepareDataForModal}
                    loading={this.props.DataProductThirdPartyStreamNamesPull.loading}
                    productThirdPartyStreamNamesPull={this.state.productThirdPartyStreamNamesPull}
                />
            </Segment>
        );
    }
}

const ProductThirdPartyStreamNamesPullQuery = gql(thirdPartyStreamNamesPullGraphQl.thirdPartyStreamNamesPullIndex);
const ProductThirdPartyStreamNamePullQuery = gql(thirdPartyStreamNamesPullGraphQl.thirdPartyStreamNamePull);

const ProductThirdPartyStreamNamesPullWithGraphQL = compose(
    graphql(ProductThirdPartyStreamNamesPullQuery, {
        options: (props) => {
            return {
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: {
                    thirdPartyClientPullOrigin: props.pullOrigins.map((pullOrigin) => convertToInt(pullOrigin.id)),
                },
            };
        },
        name: 'DataProductThirdPartyStreamNamesPull',
    })
)(ProductThirdPartyStreamNamesPullIndex);

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal']),
    mapStateToProps = (state) => {
        return {
            pullOrigins: state.app.entities.thirdPartyClientPullOrigins.pullOrigins,
        };
    };

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withApollo(ProductThirdPartyStreamNamesPullWithGraphQL))
);
