import React from 'react';
import {get as _get} from 'lodash';
import {Popup} from 'semantic-ui-react';

import PropTypes from 'prop-types';

import {IconEdit} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';

import {formatPopupContent, formatTimeDiff} from '../../utils/eventsConfigurationEncodingInformation';
import ContentConfigurationLink from './ContentConfigurationLink';

function EndDatetime(props) {
    let timeToShow = 'Manual';
    let shouldShowEditIcon = true;
    let popupContent = null;

    const encodingEndDatetime = _get(props.content, 'event_content_input.encoding_end_datetime', null);
    const encodingEndMargin = _get(props.content, 'event_content_input.encoding_end_margin', null);
    const eventContentEndTime = _get(props.content, 'end_datetime', null);

    if (encodingEndDatetime) {
        let encodingEndTime = encodingEndDatetime;
        shouldShowEditIcon = new Date(encodingEndDatetime) > new Date();

        if (encodingEndMargin) {
            encodingEndTime += ` (+${encodingEndMargin} min)`;

            popupContent = formatPopupContent('getEndDatetime', {
                encodingEndMargin: encodingEndMargin,
                eventContentEndTime: eventContentEndTime
            });
            timeToShow = encodingEndTime;
        } else {
            timeToShow = encodingEndDatetime;
        }
    } else if (encodingEndMargin) {
        timeToShow = eventContentEndTime
            ? `${formatTimeDiff(eventContentEndTime, encodingEndMargin, 'add')} (+${encodingEndMargin} min)`
            : `+${encodingEndMargin} min`;
        popupContent = formatPopupContent('getEndDatetime', {
            encodingEndMargin: encodingEndMargin,
            eventContentEndTime: eventContentEndTime,
        });
    }

    const contentData = (
        <div>
            {timeToShow}
            &nbsp;&nbsp;
            {
                shouldShowEditIcon &&
                <ContentConfigurationLink
                    contentId={convertToInt(props.content.id)}
                    eventId={convertToInt(props.eventData.id)}
                    linkName="changeEncodingEndTime"
                    icon={<IconEdit className={'black'}/>}
                    text=""

                />
            }
        </div>
    );

    return (popupContent)
        ? (
            <Popup
                flowing
                hoverable
                trigger={contentData}
            >
                {popupContent}
            </Popup>
        )
        : contentData;
}

EndDatetime.propTypes = {
    content: PropTypes.object,
    eventData: PropTypes.object,
};

export default EndDatetime;
