
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RtmpOriginStreamNameBasicData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RtmpOriginStreamName"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RtmpOriginStreamNameListData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RtmpOriginStreamName"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RtmpOriginStreamNameBasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"preview_url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_archived"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"creation_datetime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"creation_user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"update_datetime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"update_user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}}],"loc":{"start":0,"end":389}};
    doc.loc.source = {"body":"#import \"../user/fragment.graphql\"\n\nfragment RtmpOriginStreamNameBasicData on RtmpOriginStreamName {\n    id\n    name\n}\n\nfragment RtmpOriginStreamNameListData on RtmpOriginStreamName {\n    ...RtmpOriginStreamNameBasicData\n    preview_url\n    is_archived\n    creation_datetime\n    creation_user {\n        ...BasicData\n    }\n    update_datetime\n    update_user {\n        ...BasicData\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../user/fragment.graphql").definitions));


      module.exports = doc;
    
