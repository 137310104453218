import React from'react';
import PropTypes from "prop-types";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classnames from 'classnames';
import 'moment/locale/en-gb';
import {
    get as _get,
    isString as _isString
} from "lodash"
import 'react-datepicker/dist/react-datepicker.css';

class Datepicker extends React.Component {
    static propTypes = {
        error: PropTypes.bool,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        defaultValue: PropTypes.string,
        configuration: PropTypes.object,
        isReduxComponent: PropTypes.bool,
        name: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onChangeDate: PropTypes.func,
        selected: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        timestamp: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        placeholderText: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.string,
        restorePreviousValue: PropTypes.bool,
        meta: PropTypes.shape({
            submitting: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        configuration: {},
        isReduxComponent: false,
        restorePreviousValue: false,
    };

    constructor (props) {
        super(props);

        let timeFormat = _get(props, "configuration.timeFormat", "HH:mm:ss");
        const format = props.configuration.dateFormat || (props.configuration.showTimeSelect
            ? `YYYY-MM-DD ${timeFormat}`
            : "YYYY-MM-DD"
        );

        this.state = {
            disabled: null,
            prefilled: false,
            date: null,
            value: null,
            wrongFormatError: false,
            format: this.reformatDateFormat(format),
            momentFormat: format
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.meta.submitting) {
            return;
        }

        if (nextProps.selected !== undefined
            && this.state.timestamp !== nextProps.timestamp
            && (nextProps.selected !== this.state.selected)) {
            this.setState(() => ({
                timestamp: nextProps.timestamp
            }), () => {
                this.handleChange(nextProps.selected);
            });
        }

        if (
            (!this.state.prefilled && nextProps.defaultValue)
            || (nextProps.disabled !== this.state.disabled)
            || (nextProps.timestamp !== this.state.timestamp)
        ) {
            if (nextProps.defaultValue) {
                let date = moment(nextProps.defaultValue).format(this.state.momentFormat),
                    value = (nextProps.isReduxComponent) ? nextProps.defaultValue : date;

                if (!nextProps.isReduxComponent
                    && nextProps.value !== nextProps.defaultValue
                    && undefined !== this.state.selected
                    && !nextProps.restorePreviousValue
                ) {
                    value = date = (nextProps.value) ? moment(nextProps.value).format(this.state.momentFormat) : null;
                }

                this.setState(() => ({
                    disabled: nextProps.disabled,
                    date: moment(nextProps.defaultValue),
                    selected: date,
                    value: new Date(value),
                    prefilled: !nextProps.isReduxComponent,
                    timestamp: nextProps.timestamp,
                }));
                this.props.onChange(value);
            }  else if (
                !nextProps.defaultValue
                && (nextProps.disabled !== this.state.disabled || nextProps.timestamp !== this.state.timestamp)
                && nextProps.configuration.updateOnExternalChange
            ) {
                this.setState(() => ({
                    disabled: nextProps.disabled,
                    date: null,
                    selected: null,
                    value: null,
                    prefilled: !nextProps.isReduxComponent,
                    timestamp: nextProps.timestamp,
                }));
                this.props.onChange(null);
            } else {
                this.setState(() => ({
                    prefilled: true
                }));
            }
        }

        if (nextProps.isReduxComponent && !nextProps.value) {
            this.setState(() => ({
                date: null,
                selected: null,
                value: null
            }));
        }
    }

    handleChange = (date) => {
        let dateObject = date,
            formattedDate = date;

        if (_isString(date)) {
            dateObject = moment(date);
            formattedDate = date
        } else if(date !== null) {
            formattedDate = date ? moment(date).format(this.state.momentFormat) : null
        }

        if (this.props.configuration.onChange) {
            this.props.configuration.onChange(moment(date), this.props.name);
        }

        this.props.onChange(formattedDate);
        this.props.onBlur();

        if (this.props.onChangeDate) {
            this.props.onChangeDate(formattedDate);
        }

        this.setState(() => ({
            date: dateObject,
            value: formattedDate ? new Date(formattedDate) : null,
            selected: new Date(formattedDate)
        }));
    };

    handleChangeRaw = (event) => {
        const date = event.target.value;

        if (!moment(date, this.state.format, true).isValid()) {
            return
        }

        this.handleChange(event.target.value);
    };

    reformatDateFormat = (format) => {
        return format.replace("DD", "dd")
            .replace("YYYY", "yyyy")
            .replace("YY", "yy")
            .replace("D", "d");
    }

    parseDatepickerConfiguration = () => {
        const data = this.props.configuration,
            dataFields = ['minDate', 'maxDate', 'minTime', 'maxTime'];

        dataFields.forEach((field) => {
            const fieldValue = _get(data, field, null);

            if (fieldValue) {
                data[field] = new Date(fieldValue);
            }
        });

        return data;
    }

    render() {
        return (
            <div className={classnames('datepicker-wrapper ui input small',
                { 'error': this.props.error, 'loading': this.props.loading })}>
                <DatePicker
                    { ...this.parseDatepickerConfiguration() }
                    error={this.props.error}
                    name={this.props.name}
                    selected={this.state.value}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onChangeRaw={this.handleChangeRaw}
                    disabled={this.props.disabled}
                    dateFormat={this.state.format}
                    placeholderText={this.props.placeholderText}
                    className={this.props.className}
                />
                {this.props.loading && <i aria-hidden="true" className="spinner icon"/>}
            </div>
        );
    }
}

export default Datepicker;
