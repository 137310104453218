import {find as _find, get as _get, has as _has, isUndefined as _isUndefined} from 'lodash';
import {withApollo} from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt, filterDisabledEncodingJobProfiles, isUrlParamValid} from '@utils/helpers';
import {CDN_AKAMAI, CDN_AKAMAI_2, DISTRIBUTION_TYPE_LIVE, DISTRIBUTION_TYPE_RECORDING} from '@constants/variables';
import {
    GetClientCdnConfigurationEncodingJobProfilesDropdownData,
} from '@graphql/clientCdnConfiguration/query';
import {showMessageBox} from '@utils/messageBox';
import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';

import {filteredIngestMethodsToDropdownOptions} from '../utils/clientCdnHelpers';

class ClientCdnConfigurationForm extends DefaultForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            initialEncodingJobProfilesSelected: false,
        };
    }

    _isMounted = false;

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {
            GraphQLOptionsData: {
                akamaiCdnIngestMethods,
                cdns,
                clientProducts,
            },
        } = nextProps;

        if (this.props.formValues.client_product
            && convertToInt(this.props.formValues.client_product) !== convertToInt(nextProps.formValues.client_product)
        ) {
            this.setField("live_encoding_job_profile", {
                defaultValue: null,
            });
            this.setField("recording_encoding_job_profile", {
                defaultValue: null,
            });
        }

        if (this.props.formValues.live_encoding_job_profile !== nextProps.formValues.live_encoding_job_profile) {
            this.setField("live_encoding_job_profile", {
                defaultValue: nextProps.formValues.live_encoding_job_profile,
            });
        }

        if (this.props.formValues.recording_encoding_job_profile !== nextProps.formValues.recording_encoding_job_profile) {
            this.setField("recording_encoding_job_profile", {
                defaultValue: nextProps.formValues.recording_encoding_job_profile,
            });
        }

        if (!_isUndefined(cdns)) {
            this.setField("cdn", {
                options: cdns,
                allowClear: false,
            });
        }

        if (!_isUndefined(clientProducts)) {
            this.setField("client_product", {
                options: clientProducts.map((clientProduct) => ({
                    key: clientProduct.id,
                    text: clientProduct.product.name,
                    value: convertToInt(clientProduct.id),
                })),
                allowClear: false,
            });
        }

        if (!_isUndefined(akamaiCdnIngestMethods)) {
            this.setField("akamai_cdn_ingest_method", {
                options: akamaiCdnIngestMethods,
                allowClear: false,
            });
        }

        if (
            null !== nextProps.formParams.id &&
            nextProps.GraphQLEntityData &&
            !nextProps.GraphQLEntityData.loading &&
            !nextProps.GraphQLOptionsData.loading &&
            !this.state.dataLoaded &&
            nextProps.GraphQLEntityData.clientCdnConfiguration
        ) {
            const clientCdnConfiguration = nextProps.GraphQLEntityData.clientCdnConfiguration;

            this.setField('client_product', {
                defaultValue: convertToInt(clientCdnConfiguration.client_product.id),
            });

            this.setField('cdn', {
                defaultValue: clientCdnConfiguration.cdn.id,
            });

            this.handleCdnChanged(convertToInt(clientCdnConfiguration.cdn.id));

            this.handleHasExternalStorageChanged(clientCdnConfiguration.has_external_storage);

            if (_has(clientCdnConfiguration.live_encoding_job_profile, 'id')) {
                this.setField("live_encoding_job_profile", {
                    defaultValue: clientCdnConfiguration.live_encoding_job_profile.id,
                });
            }

            if (_has(clientCdnConfiguration.recording_encoding_job_profile, 'id')) {
                this.setField("recording_encoding_job_profile", {
                    defaultValue: clientCdnConfiguration.recording_encoding_job_profile.id,
                });
            }

            this.setField("encoding_datacenters", {
                defaultValue: clientCdnConfiguration.encoding_datacenters.map((encodingDatacenter) => (
                    encodingDatacenter.id
                )),
            });

            this.setState(() => ({dataLoaded: true}));
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        this.setInitialEncodingJobProfiles();

        let {
            GraphQLOptionsData: {
                ingestMethods,
            },
        } = this.props;

        if (this.props.GraphQLEntityData?.clientCdnConfiguration !== prevProps.GraphQLEntityData?.clientCdnConfiguration) {
            if (!_isUndefined(ingestMethods) && this.props.GraphQLEntityData.clientCdnConfiguration) {
                if (ingestMethods) {
                    this.setField(['live_ingest_method', 'recording_ingest_method'], {
                        options: filteredIngestMethodsToDropdownOptions(ingestMethods),
                    });
                }

                if (this.props.GraphQLEntityData.clientCdnConfiguration.live_ingest_method?.id) {
                    this.setField('live_ingest_method', {
                        options: filteredIngestMethodsToDropdownOptions(ingestMethods,
                            this.props.GraphQLEntityData.clientCdnConfiguration.live_ingest_method?.id),
                    });
                }

                if (this.props.GraphQLEntityData.clientCdnConfiguration.recording_ingest_method?.id) {
                    this.setField('recording_ingest_method', {
                        options: filteredIngestMethodsToDropdownOptions(ingestMethods,
                            this.props.GraphQLEntityData.clientCdnConfiguration.recording_ingest_method?.id),
                    });
                }
            }
        } else {
            if (this.props.match.path.includes('add') && this.props.GraphQLOptionsData?.ingestMethods !== prevProps.GraphQLOptionsData?.ingestMethods) {
                this.setField(['live_ingest_method', 'recording_ingest_method'], {
                    options: ingestMethods.filter((notDisabledIngestMethod)=>!notDisabledIngestMethod.is_disabled),
                });
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (!isUrlParamValid(this.props.match.params.clientCdnConfigurationId)) this.errorCdnModal();
    }

    errorCdnModal = () => showModal({
        isVisible: true,
        content: renderModalError('Cdn Configurations', getLink(`clients.clientCdnConfiguration.index`, {
            id: this.props.match.params.id,
        })),
    });

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillMount() {
        super.componentWillMount();

        this.setOnChangeCallback({
            client_product: (data) => {
                this.handleClientProductChanged(data.value);
            },
            cdn: (data) => {
                this.handleCdnChanged(data.value);
            },
            has_external_storage: (data) => {
                this.handleHasExternalStorageChanged(!data.value);
            },
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink("clients.clientCdnConfiguration.edit", {
                id: this.props.match.params.id,
                clientCdnConfigurationId: response.data.createClientCdnConfiguration.id
            }));
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink("clients.clientCdnConfiguration.index", {id: this.props.match.params.id}));
        });

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });

        this.setFallbackRoute(getLink("clients.clientCdnConfiguration.index", {id: this.props.match.params.id}))
    }

    /**
     * Ger product ID from "Product" field.
     * It is ID from "product_client" table (not "product" table)
     * @returns {number} Product ID from "product" table
     */
    getProductId = (clientProductId) => {
        const clientProduct = _find(
            this.props.GraphQLOptionsData.clientProducts,
            clientProductData => convertToInt(clientProductData.id) === convertToInt(clientProductId)
        );

        return (!_isUndefined(clientProduct))
            ? convertToInt(clientProduct.product.id)
            : this.errorCdnModal();
    }

    setInitialEncodingJobProfiles = () => {
        if (!this.state.initialEncodingJobProfilesSelected
            && !_get(this.props, 'GraphQLEntityData.loading', true)
            && !_get(this.props, 'GraphQLOptionsData.loading', true)
        ) {
            const clientCdnConfiguration = this.props.GraphQLEntityData.clientCdnConfiguration;

            if (_isUndefined(_get(this.props.GraphQLEntityData, 'clientCdnConfiguration'))) {
                return;
            }

            this.setEncodingJobFieldsStatusLoading();
            this.setState(() => ({
                productId: this.getProductId(clientCdnConfiguration.client_product.id),
                initialEncodingJobProfilesSelected: true,
            }),
            () => {
                this.setEncodingJobProfiles();
            });
        }
    }

    setEncodingJobFieldsStatusLoading = () => {
        this.setField(
            ['live_encoding_job_profile', 'recording_encoding_job_profile'],
            {
                allowClear: false,
                disabled: true,
                loading: true,
            }
        );
    }

    prepareDataForSubmit = (data) => {
        const clientCdnConfiguration = Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {'cdn': convertToInt(data.cdn)}
        );

        if (false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(clientCdnConfiguration.cdn))) {
            clientCdnConfiguration.akamai_cdn_ingest_method = null;
            clientCdnConfiguration.akamai_cp_code = null;
            clientCdnConfiguration.encoder_ingest_password = null;
            clientCdnConfiguration.akamai_live_cp_hostname = null;
            clientCdnConfiguration.akamai_vod_cp_hostname = null;
        } else {
            clientCdnConfiguration.akamai_cdn_ingest_method =
                convertToInt(clientCdnConfiguration.akamai_cdn_ingest_method);
        }

        if (!clientCdnConfiguration.has_external_storage) {
            clientCdnConfiguration.vod_netstorage_hostname = null;
            clientCdnConfiguration.vod_netstorage_username = null;
            clientCdnConfiguration.vod_netstorage_password = null;
            clientCdnConfiguration.vod_netstorage_upload_path = null;
            clientCdnConfiguration.vod_netstorage_playback_path = null;
        }

        clientCdnConfiguration.live_encoding_job_profile = clientCdnConfiguration.live_encoding_job_profile
            ? convertToInt(clientCdnConfiguration.live_encoding_job_profile)
            : null;

        clientCdnConfiguration.recording_encoding_job_profile = clientCdnConfiguration.recording_encoding_job_profile
            ? convertToInt(clientCdnConfiguration.recording_encoding_job_profile)
            : null;

        clientCdnConfiguration.encoding_datacenters = clientCdnConfiguration.encoding_datacenters.map(
            (encodingDatacenter) => (convertToInt(encodingDatacenter))
        );

        clientCdnConfiguration.live_ingest_method = clientCdnConfiguration.live_ingest_method
            ? convertToInt(clientCdnConfiguration.live_ingest_method)
            : null;

        clientCdnConfiguration.recording_ingest_method = clientCdnConfiguration.recording_ingest_method
            ? convertToInt(clientCdnConfiguration.recording_ingest_method)
            : null;

        return clientCdnConfiguration;
    };

    handleClientProductChanged = (clientProductId) => {
        this.setEncodingJobFieldsStatusLoading();
        this.setState(() => ({
            productId: this.getProductId(clientProductId),
        }), () => {
            this.setEncodingJobProfiles();
        });
    };

    handleCdnChanged = (value) => {
        this.setField("akamai_cdn_ingest_method", {
            hidden: false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
            required: [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
        });
        this.setField("akamai_cp_code", {
            hidden: false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
            required: [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
        });
        this.setField("encoder_ingest_password", {
            hidden: false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
            required: [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
        });
        this.setField("akamai_live_cp_hostname", {
            hidden: false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
            required: [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
        });
        this.setField("akamai_vod_cp_hostname", {
            hidden: false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
            required: [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(value)),
        });
    };

    handleHasExternalStorageChanged = (value) => {
        this.setField("has_external_storage", {
            checked: value,
            defaultValue: value,
        });

        this.setField("vod_netstorage_hostname", {
            hidden: !value,
            required: value,
        });
        this.setField("vod_netstorage_username", {
            hidden: !value,
            required: value,
        });
        this.setField("vod_netstorage_password", {
            hidden: !value,
            required: value,
        });
        this.setField("vod_netstorage_upload_path", {
            hidden: !value,
            required: value,
        });
        this.setField("vod_netstorage_playback_path", {
            hidden: !value,
            required: value,
        });
    };
    handleError = (error = null) => {
        this.setField(
            ['live_encoding_job_profile', 'recording_encoding_job_profile'],
            {
                allowClear: false,
                disabled: true,
                loading: false,
            }
        );

        showMessageBox(
            'formInnerErrorMessage',
            "Can't get encoding job profiles.",
            error && error.message,
            'error',
            true
        );
    }

    setEncodingJobProfiles = () => {
        if (!this.state.productId) {
            return;
        }

        this.props.client.query({
            fetchPolicy: 'cache-first',
            query: GetClientCdnConfigurationEncodingJobProfilesDropdownData,
            variables: {
                product: [this.state.productId],
                distributionTypeLive: [DISTRIBUTION_TYPE_LIVE],
                distributionTypeRecording: [DISTRIBUTION_TYPE_RECORDING],
            },
        }).then((response) => {
            if (this._isMounted) {
                const fieldObject = {
                    allowClear: true,
                    disabled: false,
                    loading: false,
                };

                this.setField('live_encoding_job_profile', {
                    ...fieldObject,
                    options: filterDisabledEncodingJobProfiles(
                        response.data.liveEncodingJobProfiles,
                        _get(
                            this.props,
                            'GraphQLEntityData.clientCdnConfiguration.live_encoding_job_profile.id',
                            null
                        )
                    ),
                });
                this.setField('recording_encoding_job_profile', {
                    ...fieldObject,
                    options: filterDisabledEncodingJobProfiles(
                        response.data.recordingEncodingJobProfiles,
                        _get(
                            this.props,
                            'GraphQLEntityData.clientCdnConfiguration.recording_encoding_job_profile.id',
                            null
                        )
                    ),
                });
            }
        }).catch((response) => {
            this.handleError(response);
        });
    };

    renderCancelButton() {
        return null;
    }
}

export default withRouter(withApollo(ClientCdnConfigurationForm));
