import React from 'react';
import {Popup} from 'semantic-ui-react';
import {get as _get, uniq as _uniq} from 'lodash';

import {
    IconInfoCircle,
    IconInfoCircleWithoutTooltip,
    IconWithTooltip,
} from '@appComponents/IconCollection';
import {convertMillisecondsToSeconds, getTimeDifferenceBetweenDatesByGivenUnitOfTime} from '@utils/momentWrapper';
import {getCompetitorsNameFromMatch} from '@utils/competitorName';
import Link, {getLink} from '@appComponents/Link';
import PopupTable from '@appComponents/PopupTable';
import {renderModalError} from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup'

const CONTENT_START_TIME = 'Content start time';
const CONTENT_CREATION_TIME = 'Content creation time';
const CONTENT_ID = 'Content ID';
const VIDEO_DURATION = 'Video duration';
const RESPONSE_TIME = 'Response time';
const START_DATETIME_KEY = 'event_content.start_datetime';
const CREATION_DATETIME_KEY = 'event_content.creation_datetime';
const EVENT_ID_KEY = 'event_content.event.id';
const EVENT_CONTENT_ID_KEY = 'event_content.id';
const CSS_NO_PADDING = '--noPadding';

export const renderLcppClientNotificationLogModalError = (clientId, message) => (
    renderModalError(
        'Client LCPP Notification History',
        getLink('clients.products.lcpp.edit', {id: convertToInt(clientId)}),
        message
    )
);
renderLcppClientNotificationLogModalError.displayName = 'renderLcppClientNotificationLogModalError';

export const getSendingTimeColumnHeaderLabel = () => (
    <div>Sending time <IconInfoCircle tooltip={'Time when the notification was sent to the client'}/></div>
);

export const getDelayColumnHeaderLabel = () => (
    <div>Delay <IconInfoCircle tooltip={'Sending time minus event content creation time'}/></div>
);

export const lcppClientNotificationLogColumns = {
    'content_start_time': {
        label: CONTENT_START_TIME,
        sorting: row => _get(row, START_DATETIME_KEY, null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'content_creation_time': {
        label: CONTENT_CREATION_TIME,
        sorting: row => _get(row, CREATION_DATETIME_KEY, null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'event_id': {
        label: 'Event ID',
        sorting: row => _get(row, EVENT_ID_KEY, null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'match_id': {
        label: 'Match ID',
        sorting: row => _get(row, 'event_content.event.sr_event.match.id', null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'content_id': {
        label: CONTENT_ID,
        sorting: row => _get(row, EVENT_CONTENT_ID_KEY, null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'sport': {
        label: 'Sport',
        header: {className: CSS_NO_PADDING},
        sorting: row => _get(
            row,
            'event_content.event.sr_event.match.tournament.tournament_category.sport.name',
            null
        ),
    },
    'category': {
        label: 'Category',
        header: {className: CSS_NO_PADDING},
        sorting: row => _get(row, 'event_content.event.sr_event.match.tournament.tournament_category.name', null),
    },
    'tournament': {
        label: 'Tournament',
        header: {className: CSS_NO_PADDING},
        sorting: row => _get(row, 'event_content.event.sr_event.match.tournament.name', null),
    },
    'competitors': {
        label: 'Competitors',
        header: {className: CSS_NO_PADDING},
        sorting: row => getCompetitorsNameFromMatch(_get(row, 'event_content.event.sr_event.match', null)),
    },
    'content': {
        label: 'Content',
        header: {className: CSS_NO_PADDING},
        sorting: row => getEventContentName(row),
    },
    'video_duration': {
        label: VIDEO_DURATION,
        sorting: row => _get(row, 'event_content.video_duration', null),
        header: {align: 'center', className: CSS_NO_PADDING},
        content: {align: 'right'},
    },
    'sending_time': {
        label: getSendingTimeColumnHeaderLabel,
        sorting: row => _get(row, 'request_datetime', null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'delay': {
        label: getDelayColumnHeaderLabel,
        sorting: row => getDelay(row),
        header: {align: 'center', className: CSS_NO_PADDING},
        content: {align: 'right'},
    },
    'response_code': {
        label: 'Response',
        sorting: row => _get(row, 'response_http_code', null),
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
    'response_time': {
        label: RESPONSE_TIME,
        sorting: row => getResponseTime(row),
        header: {align: 'center', className: CSS_NO_PADDING},
        content: {align: 'right'},
    },
    'details': {
        label: 'Details',
        header: {
            align: 'center',
            isCollapsed: true,
            className: CSS_NO_PADDING,
        },
        content: {align: 'center'},
    },
};

export const renderActionsPopup = (row) => {
    const rows = [
        {
            header: 'Sent to message queue at:',
            body: row.sent_queue_datetime || '-',
        },
        {
            header: 'Consumed from message queue at:',
            body: row.consumed_queue_datetime || '-',
        },
        {
            header: 'Response body:',
            body: row.response_body || '-',
        },
        {
            header: 'Error message:',
            body: row.error_message || '-',
        },
    ];

    return (
        <Popup
            className={CSS_NO_PADDING}
            flowing
            key={`popup_lcpp_${row.id}`}
            hoverable
            position='top right'
            trigger={
                <div key={`popup_trigger_${row.id}`}>
                    <IconInfoCircleWithoutTooltip />
                </div>
            }
        >
            <PopupTable rows={rows}/>
        </Popup>
    );
};

export const getEventContentName = (row, isExcelExport = false) => {
    const contentName = _get(row, 'event_content.name', null),
        contentTypeName = _get(row, 'event_content.event_content_type.name', null),
        eventId = _get(row, EVENT_ID_KEY, null),
        eventContentId = _get(row, EVENT_CONTENT_ID_KEY, null),
        contentNameForColumn = contentName ? `${contentTypeName} – ${contentName}` : contentTypeName;

    if (isExcelExport || (!eventId || !eventContentId)) {
        return contentNameForColumn;
    }

    return (
        <>
            <Link
                hash={`#content-${eventContentId}`}
                name='events.configuration.index'
                newWindow={true}
                params={{id: eventId}}
            >
                {contentNameForColumn}
            </Link>
            <EventContentVariantPopup eventContentVariant={row.event_content.event_content_variant}/>
        </>
    );
};

export const getDelay = (row) => {
    const requestDatetime = _get(row, 'request_datetime', null),
        eventContentCreationDatetime = _get(row, CREATION_DATETIME_KEY, null);

    if (!requestDatetime || !eventContentCreationDatetime) {
        return '-';
    }

    return getTimeDifferenceBetweenDatesByGivenUnitOfTime(requestDatetime, eventContentCreationDatetime, 's');
};

export const getResponseTime = (row) => {
    const requestDatetime = _get(row, 'response_datetime', null),
        eventContentCreationDatetime = _get(row, 'request_datetime', null);

    if (!requestDatetime || !eventContentCreationDatetime) {
        return '-';
    }

    return getTimeDifferenceBetweenDatesByGivenUnitOfTime(requestDatetime, eventContentCreationDatetime, 's');
};

export const getCompetitorsLink = (row) => {
    const eventId = row.event_content.event.id,
        competitorsNames = getCompetitorsNameFromMatch(row.event_content.event.sr_event.match);

    return (
        <Link
            name='events.configuration.index'
            newWindow={true}
            params={{id: eventId}}
        >
            {competitorsNames}
        </Link>
    );
};

export const getVideoDuration = (row) => {
    const videoDuration = _get(row, 'event_content.video_duration', null);

    if (!videoDuration) {
        return '-';
    }

    return videoDuration
        ? `${convertMillisecondsToSeconds(videoDuration).toString().match(/\d+/)[0]} s`
        : '-';
};

export const getClientName = (row) => (
    <Link
        name='clients.edit'
        newWindow={true}
        params={{id: _get(row, 'client.id', null)}}
    >
        {_get(row, 'client.name', null)}
    </Link>
);

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'content_start_time': {
            return row.event_content.start_datetime;
        }
        case 'content_creation_time': {
            return row.event_content.creation_datetime;
        }
        case 'event_id': {
            return row.event_content.event.id;
        }
        case 'match_id': {
            return row.event_content.event.sr_event.match.id;
        }
        case 'content_id': {
            return row.event_content.id;
        }
        case 'sport': {
            return row.event_content.event.sr_event.match.tournament.tournament_category.sport.name;
        }
        case 'category': {
            return row.event_content.event.sr_event.match.tournament.tournament_category.name;
        }
        case 'tournament': {
            return row.event_content.event.sr_event.match.tournament.name;
        }
        case 'competitors': {
            return getCompetitorsLink(row);
        }
        case 'content': {
            return getEventContentName(row);
        }
        case 'video_duration': {
            return getVideoDuration(row);
        }
        case 'client': {
            return getClientName(row);
        }
        case 'sending_time': {
            return (
                row.request_datetime
                || <IconWithTooltip
                    name='exclamation circle'
                    tooltip='Not sent'
                    iconProps={{color: 'red'}}
                />
            );
        }
        case 'delay': {
            return getDelay(row);
        }
        case 'response_code': {
            return (
                row.response_http_code
                || <IconWithTooltip
                    name='exclamation circle'
                    tooltip='No response'
                    iconProps={{color: 'red'}}
                />
            );
        }
        case 'response_time': {
            return getResponseTime(row);
        }
        case 'details': {
            return (
                <div className='actions-list-icon'>
                    {renderActionsPopup(row)}
                </div>
            );
        }
        default: {
            return null;
        }
    }
};

export const prepareSportsDropdownData = (
    isDataLoading,
    propertyLicences,
    sports
) => {
    const sportsWithOurMediaRights = [];

    if (!isDataLoading) {
        let propertyLicencesWithSport = [];

        propertyLicences.forEach(propertyLicence => {
            if (propertyLicence.sport) {
                propertyLicencesWithSport.push(propertyLicence.sport.id);
            }
        });
        propertyLicencesWithSport = _uniq(propertyLicencesWithSport);

        for (const sport of sports) {
            for (const propertyLicenceWithSport of propertyLicencesWithSport) {
                if (propertyLicenceWithSport === convertToInt(sport.id)) {
                    sportsWithOurMediaRights.push(sport);
                }
            }
        }
    }

    return sportsWithOurMediaRights;
};

export const showErrorNotificationMessage = () => {
    showMessageBox(
        'LcppClientNotificationLog',
        'Failed to fetch filter data',
        'There was some error while fetching data for filters',
        'error',
        true
    );
};

export const prepareExcelExportData = (row, isEventPage = false) => {
    const errorMessage = _get(row, 'error_message', '') || '';

    return isEventPage
        ? [
            _get(row, START_DATETIME_KEY, ''),
            _get(row, CREATION_DATETIME_KEY, ''),
            _get(row, EVENT_CONTENT_ID_KEY, ''),
            getEventContentName(row, true),
            _get(row, 'event_content.event_content_variant.name', ''),
            getVideoDuration(row),
            _get(row, 'client.name', ''),
            _get(row, 'sent_queue_datetime', ''),
            _get(row, 'consumed_queue_datetime', ''),
            _get(row, 'request_datetime', 'Not sent'),
            getDelay(row),
            getResponseTime(row),
            _get(row, 'response_body', ''),
            errorMessage,
        ]
        : [
            _get(row, START_DATETIME_KEY, ''),
            _get(row, CREATION_DATETIME_KEY, ''),
            _get(row, EVENT_ID_KEY, ''),
            _get(row, 'event_content.event.sr_event.match.id', ''),
            _get(row, EVENT_CONTENT_ID_KEY, ''),
            _get(row, 'event_content.event.sr_event.match.tournament.tournament_category.sport.name', ''),
            _get(row, 'event_content.event.sr_event.match.tournament.tournament_category.name', ''),
            _get(row, 'event_content.event.sr_event.match.tournament.name', ''),
            getCompetitorsNameFromMatch(row.event_content.event.sr_event.match),
            getEventContentName(row, true),
            _get(row, 'event_content.event_content_variant.name', ''),
            getVideoDuration(row),
            _get(row, 'sent_queue_datetime', ''),
            _get(row, 'consumed_queue_datetime', ''),
            _get(row, 'request_datetime', 'Not sent'),
            getDelay(row),
            getResponseTime(row),
            _get(row, 'response_body', ''),
            errorMessage,
        ];
};

export const prepareTitlesForExcelExport = (isEventPage = false) => (
    isEventPage
        ? [
            CONTENT_START_TIME,
            CONTENT_CREATION_TIME,
            CONTENT_ID,
            'Content',
            'Content variant',
            VIDEO_DURATION,
            'Client',
            'Sent to message queue at',
            'Consumed from message queue at',
            'Sending time',
            'Delay',
            RESPONSE_TIME,
            'Response body',
            'Error message',
        ]
        : [
            CONTENT_START_TIME,
            CONTENT_CREATION_TIME,
            'Event ID',
            'Match ID',
            CONTENT_ID,
            'Sport',
            'Category',
            'Tournament',
            'Competitors',
            'Content',
            'Content variant',
            VIDEO_DURATION,
            'Sent to message queue at',
            'Consumed from message queue at',
            'Sending time',
            'Delay',
            RESPONSE_TIME,
            'Response body',
            'Error message',
        ]
);
