import PropTypes from "prop-types";

import {getYesOrNo} from "../../../../utils/helpers";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'rtmp_origin_entry_points',
    parseData: rtmpOriginEntryPoint => [
        rtmpOriginEntryPoint.id,
        rtmpOriginEntryPoint.name,
        rtmpOriginEntryPoint.description || '',
        rtmpOriginEntryPoint.host,
        rtmpOriginEntryPoint.public_host || '',
        rtmpOriginEntryPoint.location,
        rtmpOriginEntryPoint.rtmp_origin_entry_point_type.name,
        rtmpOriginEntryPoint.origin_hosting_provider.name,
        getYesOrNo(rtmpOriginEntryPoint.is_active),
        rtmpOriginEntryPoint.start_time || '',
        rtmpOriginEntryPoint.stop_time || '',
    ],
    path: 'rtmpOriginEntryPoint.index.path',
    titles: [
        "ID",
        "Name",
        "Description",
        "Host",
        "Public host",
        "Location",
        "Type",
        "Hosting provider",
        "Active?",
        "Start time",
        "End time",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
