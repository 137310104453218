import {changelogData} from '../changelogData';

export const eventContentSubtitleBody = `
    id,
    track_number,
    stream_language {
        id
    }
    ${changelogData}
`;

export const eventContentSubtitles = `
query getEventContentSubtitles($encodingTargetId: ID!) {
    streamLanguages{
        id,
        name
        language{
            name
            code
        }
    }
    subtitles(filters: {encodingTarget: $encodingTargetId}){
        ${eventContentSubtitleBody}
    }
}`;

export const CreateMutation = `
mutation CreateEventContentSubtitle(
    $encodingTargetId: ID!,
    $trackNumber: Int!,
    $streamLanguage: ID!
  ) {
    createSubtitle(
        encoding_target: $encodingTargetId,
        track_number: $trackNumber,
        stream_language:$streamLanguage,
    ) {
    ${eventContentSubtitleBody}
  }
}`;

export const UpdateMutation = `
mutation UpdateEventContentSubtitle(
    $id: ID!,
    $encodingTargetId: ID!,
    $trackNumber: Int!,
    $streamLanguage: ID!
  ) {
    updateSubtitle(
        id: $id,
        encoding_target: $encodingTargetId,
        track_number: $trackNumber,
        stream_language:$streamLanguage,
    ) {
    ${eventContentSubtitleBody}
  }
}`;

export const DeleteMutation = `
mutation DeleteEventContentSubtitle($id: ID!) {
    deleteSubtitle(id: $id)
}`;
