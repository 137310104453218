import React from "react";
import PropTypes from "prop-types";

import Table from "../../app/components/Table";
import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';
import Authorization from "../../app/components/Authorization";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import {getLink} from "../../app/components/Link";
import {IconEdit} from "../../app/components/IconCollection";

const authorizationObject = {
    [RESOURCES.VENUE]: CONST.SECURITY_PRIVILEGES_READ
};

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {
        label: "Name",
        sorting: (row) => row.name.toLowerCase()
    },
    "city": {
        label: "City",
        sorting: (row) => row.city ? row.city.name.toLowerCase() : ""
    },
    "capacity": {
        label: "Capacity",
        sorting: (row) => row.capacity ? parseInt(row.capacity, 10) : ""
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

const renderLink = (id, content) => {
    return <LinkWithAuthorization
        authorization={authorizationObject}
        link={{
            name: "venues.edit",
            params: {id: id}
        }}
    >
        {content}
    </LinkWithAuthorization>
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case "id" :
            return renderLink(row.id, row.id);
        case "name" :
            return renderLink(row.id, row.name);
        case "city" :
            return row.city ? row.city.name : "";
        case "capacity" :
            return row.capacity ? row.capacity : "";
        case "actions" :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className="actions-list-icon">
                        <IconEdit size="large" link={getLink("venues.edit", {id: row.id})}
                            resource={RESOURCES.VENUE}
                        />
                    </div>
                </Authorization>
            );
    }

    return null
};

const VenuesTable = (props) => {
    return (
        <div className="venues__table">
            <Table
                name="venuesList"
                columns={columns}
                data={props.data}
                loading={props.loading}
                noDataText="No venues found"
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

VenuesTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};

export default VenuesTable;
