import PropTypes from "prop-types";
import React from "react";
import {get as _get} from "lodash";
import {Header} from "semantic-ui-react";

import Form from "../../app/components/ReduxFormControls";

import {convertToInt} from "../../../utils/helpers";

import {ORIGIN_STREAM_TYPE_RTMP} from "../../app/constants/variables";

export const renderHeader = (props) => (
    <Header as="h3" dividing className="technicalSetupFormHeader">
        {props.defaultValue.name}
        <Header.Subheader className="subHeaderRight">
            {props.defaultValue.description}
        </Header.Subheader>
    </Header>
);

renderHeader.propTypes = {
    defaultValue: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};

renderHeader.displayName = 'renderHeader';

export const renderTvStreamingCheckbox = (props) => {
    const originStreamType = _get(props, "formValues.origin_stream_type", null),
        isRtmp = (null !== originStreamType) && (ORIGIN_STREAM_TYPE_RTMP === convertToInt(originStreamType)),
        isChecked = _get(props, "formValues.is_tv_streaming", props.defaultValue);

    return isRtmp
        ? (<Form.SemanticInput {...props} defaultValue={isChecked}/>)
        : null;
};
