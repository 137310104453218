import React from "react";
import PropTypes from "prop-types";

import LinkWithAuthorization from "../../../app/components/LinkWithAuthorization";

import {getCompetitorsNameByTeam} from "../../../../utils/competitorName";

import * as RESOURCES from "../../../app/constants/resources";
import {SECURITY_PRIVILEGES_READ} from "../../../app/constants/variables";

/**
 * Get competitors for:
 * - master event (from description)
 * - no-master (from home/away team name)
 *
 * Usages:
 * - Live Control
 * - Modal Header Information about event etc.
 * @param event
 * @returns {*}
 */
const getCompetitors = (event) => (
    (event.is_master_event) ? event.event_description : getCompetitorsNameByTeam(event.home_team_name, event.away_team_name)
);

/**
 * Information about
 * - event - from description (for master event)
 * - competitors (for no-master)
 * @param event
 * @param renderLink
 */
export const CompetitorsName = ({event, renderLink = true}) => {
    const competitors = getCompetitors(event);

    return renderLink
        ? (
            <LinkWithAuthorization
                resources={RESOURCES.EVENT}
                privileges={SECURITY_PRIVILEGES_READ}
                link={{
                    hash: `#content-${event.event_content_id}`,
                    name: "events.configuration.index",
                    params: {id: event.event_id}
                }}
                newWindow={true}
            >
                <div key={`contentToRender${competitors}`}>{`${competitors} `}</div>
            </LinkWithAuthorization>
        )
        : `${competitors} `;
};

CompetitorsName.propTypes = {
    event: PropTypes.shape({
        event_id: PropTypes.number.isRequired,
        event_content_id: PropTypes.number.isRequired,
    }).isRequired,
    renderLink: PropTypes.bool,
};

CompetitorsName.defaultProps = {
    renderLink: true,
};
