import React from 'react';
import {get as _get} from 'lodash';

import Form from '@appComponents/ReduxFormControls';
import {IconInfoCircle} from '@appComponents/IconCollection';
import * as CONST from '@constants/variables';
import * as MESSAGES from '@constants/messages';
import * as RESOURCES from '@constants/resources';
import {YES_OR_NO} from '@constants/staticDropdowns';
import {BulkChangeClientBookings} from '@graphql/clientBooking/mutation';
import {GetClientBookingsBulkChangeOptions} from '@graphql/clientBooking/query';

import {
    CLIENT_BOOKING_BULK_PACKAGE_MSG,
} from '@modules/client/constants/messages';
import validateBulkChange from '../../client/forms/validatorClientBooking';
import ClientPackageDropdown from '../components/ClientBookingsBulkChange/ClientPackageDropdown';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PriceInfoIcon = (props) => (
    <div className={classNames({'hidden': props.hidden})}>
        <IconInfoCircle tooltip={'Leave empty if you want to remove the price of the bookings.'}/>
    </div>
);

PriceInfoIcon.propTypes = {
    hidden: PropTypes.bool,
};


const responsePath = 'data.bulkChangeClientBookings';

const getMessageStatus = (response) => (_get(response, responsePath, false) ? 'success' : 'error');

const getMessageHeader = (response) => {
    const success = _get(response, responsePath, false);

    if (!success) {
        return 'Some bookings could not be modified.';
    }

    return 'The bookings have been modified successfully.';
};



const ClientBookingsBulkChangeModel = (isCommonProduct) => ({
    title: null,
    formName: 'clientBookingsBulkChange',
    entityDataMapKey: 'ClientBookingsBulkChange',
    validate: validateBulkChange,
    authorization: {
        [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
        [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
    },
    dataMap: {
        ClientBookingsBulkChange: {
            client_package: null,
            price: null,
            auto_update_price: null,
            invoice_status: null,
            auto_update_geo_restrictions: null,
        },
    },
    fields: {
        client_package: {
            optionsKey: 'clientPackages',
            props: {
                component: ClientPackageDropdown,
                label: 'Client package',
                allowClear: true,
                name: 'client_package',
                type: 'select',
                isCommonProduct: isCommonProduct,
                description: <IconInfoCircle tooltip={CLIENT_BOOKING_BULK_PACKAGE_MSG}/>,
            },
        },
        inline: [
            {
                price_edit_options: {
                    props: {
                        label: 'Price',
                        name: 'price_edit_options',
                        component: Form.SemanticSelect,
                        type: 'select',
                        required: true,
                        options: [
                            {text: 'Keep the existing price', value: 'keep_price'},
                            {text: 'Modify the price', value: 'modify_price'},
                        ],
                    },
                    defaultValue: 'keep_price',
                },
            },
            {
                price: {
                    props: {
                        component: Form.SemanticInput,
                        name: 'price',
                        labelDescription: {content: '€'},
                        min: 0,
                        hidden: true,
                        defaultValue: '',
                    },
                },
            },
            {
                separator: {
                    props: {
                        name: 'separator',
                        component: PriceInfoIcon,
                        hidden: true,
                    },
                },
            },
        ],
        auto_update_price: {
            props: {
                component: Form.SemanticSelect,
                label: 'Auto-update price',
                allowClear: true,
                name: 'auto_update_price',
                type: 'select',
                options: YES_OR_NO,
            },
        },
        invoice_status: {
            optionsKey: 'invoiceStatuses',
            props: {
                component: Form.SemanticSelect,
                label: 'Invoice status',
                allowClear: true,
                name: 'invoice_status',
                type: 'select',
            },
        },
        auto_update_geo_restrictions: {
            optionsKey: 'autoUpdateGeoRestrictions',
            props: {
                component: Form.SemanticSelect,
                label: 'Auto-update geo restrictions',
                allowClear: true,
                name: 'auto_update_geo_restrictions',
                type: 'select',
                options: YES_OR_NO,
            },
        },
    },
    messages: {
        boxName: {
            delete: 'clientMessage',
            success: 'clientMessage',
            error: 'clientMessage',
        },
        text: {
            ...MESSAGES,
            SAVED: () => 'The bookings have been modified successfully.',
            FORM_ERROR: () => 'The bookings have not been saved. Please correct the highlighted fields below.',
        },
        create: {
            getStatus: getMessageStatus,
            getHeader: getMessageHeader,
        },
    },
    query: {
        getOptions: GetClientBookingsBulkChangeOptions,
    },
    mutation: {
        createEntity: BulkChangeClientBookings,
    },
});

export default ClientBookingsBulkChangeModel;
