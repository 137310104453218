import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';

import {Authorization} from '@appComponents/Authorization';
import {Link,getLink} from '@appComponents/Link';
import {
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP, ORIGIN_STREAM_TYPE_SRT,
    SECURITY_PRIVILEGES_READ,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {STREAM, EVENT_CONTENT} from '@constants/resources';
import {IconWithTooltip} from '@appComponents/IconCollection';
import SegmentHeader from '@appComponents/SegmentHeader';
import ListingInfo from '@appComponents/ListingInfo';
import CopyOriginStreamUrlButton from '@utils/copyOriginStreamUrlButton/CopyOriginStreamUrlButton';

import {parseDetails} from '../utils/parseEventContentInputs';
import {getOriginStreamTypeName} from "@modules/liveControl/utils/helpers";

const authorizationStreamReadObject = {
    [STREAM]: SECURITY_PRIVILEGES_READ,
};

export default class EventsConfigurationInput extends React.Component {
    static propTypes = {
        content: PropTypes.object,
        data: PropTypes.object,
        eventData: PropTypes.object.isRequired,
        eventId: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        isTvStreaming: PropTypes.bool,
        matchTvStreamingEncoder: PropTypes.object,
    };

    static defaultProps = {
        data: {},
    };

    editHandler = () => {
        const {
            content: {
                content: {
                    id,
                },
            },
            eventId,
        } = this.props;

        this.props.history.replace(
            getLink(
                'events.configuration.content.input',
                {
                    id: eventId,
                    contentId: id,
                }
            )
        );
    };

    renderButtons = () => {
        const {
            content: {
                content: {
                    id,
                },
            },
            data,
        } = this.props;

        return (
            <>
                <IconWithTooltip
                    name='setting'
                    tooltip='Configure the input'
                    onClick={this.editHandler}
                    resource={EVENT_CONTENT}
                    popupProps={{position: 'top right'}}
                />
                {this.renderOriginStreamButtons(data, id)}
            </>
        );
    };

    renderOriginStreamButtons = (data, eventContentId) => {
        const originStreamType = convertToInt(data.origin_stream_type.id),
            linkName = ORIGIN_STREAM_TYPE_HTTP === originStreamType ? 'events.streams.play.debug.origin' : 'events.streams.play.m3u';

        if (
            ORIGIN_STREAM_TYPE_HTTP === originStreamType && data.http_origin_entry_point_url
            || ORIGIN_STREAM_TYPE_RTMP === originStreamType && data.rtmp_origin_stream_name?.id
            || ORIGIN_STREAM_TYPE_SRT === originStreamType && data.srt_origin_entry_point_url) {
            return (
                <Authorization authorization={authorizationStreamReadObject}>
                    <Link
                        newWindow={true}
                        name={linkName}
                        params={{id: this.props.eventId, eventContentId: eventContentId}}
                    >
                        <IconWithTooltip
                            name='play circle outline'
                            tooltip='Play origin stream'
                            popupProps={{position: 'top right'}}
                        />
                    </Link>
                    <CopyOriginStreamUrlButton
                        eventContentId={eventContentId}
                        className='--withPointer'
                        position='top right'
                        copyText={'Copy ' + getOriginStreamTypeName(originStreamType) + ' URL'}
                        size='small'
                    />
                </Authorization>
            );
        }
    }

    render() {
        const parsedData = parseDetails({
            ...{
                isMainContent: this.props.content.content.is_main_content,
                isTvStreaming: this.props.isTvStreaming,
                matchTvStreamingEncoder: this.props.matchTvStreamingEncoder,
                matchId: this.props.eventData?.sr_event?.match?.id,
            },
            ...this.props.data});

        return (
            <div className='eventsConfigurationInput'>
                <SegmentHeader buttons={this.renderButtons()}> Input </SegmentHeader>
                {parsedData && <div>
                    <Grid doubling stackable columns={parsedData.length}>
                        {parsedData.map((group, i) => (
                            <Grid.Column key={i}>
                                <ListingInfo data={group}/>
                            </Grid.Column>
                        ))}
                    </Grid>
                </div>}
            </div>
        );
    }
}
