import React from "react";
import {Button, Icon} from "semantic-ui-react";

import Link from "../../app/components/Link"

const UdpOriginEntryPointEditButtons = () => {
    return (
        <div className="udpOriginEntryPoints__editButtons content__buttons">
            <Link name="udpOriginEntryPoint.index">
                <Button className="button-back">
                    <Icon className="arrow circle left"/> Back to list
                </Button>
            </Link>
        </div>
    )
};

export default UdpOriginEntryPointEditButtons;
