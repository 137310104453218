import React from "react";
import PropTypes from "prop-types";
import {Step, Segment, Icon} from "semantic-ui-react";

/**
 * @ignore
 */
const Steps = (props) => {
    let steps = props.steps,
        editMode = props.editMode,
        currentStep = props.currentStep;

    return (
        <Segment basic>
            <Step.Group size={props.size} widths={steps.length}>
                {steps.map((step, i) => (
                    <Step
                        key={i}
                        id={i}
                        active={currentStep === i}
                        completed={(!editMode && currentStep > i)}
                        disabled={(!editMode && currentStep < i)}
                        onClick={editMode ? props.onClick : null}
                        data-path={editMode ? step.path : null}
                    >
                        {step.icon && <Icon name={step.icon}/>}
                        <Step.Content>
                            <Step.Title>{step.title}</Step.Title>
                            {step.description && <Step.Description>{step.description}</Step.Description>}
                        </Step.Content>
                    </Step>
                ))}
            </Step.Group>
        </Segment>
    )
};

Steps.propTypes = {
    currentStep: PropTypes.number.isRequired,
    editMode: PropTypes.bool,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
            icon: PropTypes.string,
            path: PropTypes.string
        })
    ).isRequired,
    size: PropTypes.string,
    onClick: PropTypes.func
};

Steps.defaultProps = {
    size: 'mini',
};

export default Steps