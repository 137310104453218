import moment from "moment";

import validators from "../../../utils/validators";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.property_licence, 'property_licence'));
    Object.assign(errors, validators.dateTime(values.start_datetime, 'start_datetime'));
    Object.assign(errors, validators.dateTime(values.end_datetime, 'end_datetime'));

    if (values.end_datetime && moment(values.start_datetime).isAfter(values.end_datetime)) {
        Object.assign(errors, {end_datetime: "End date must be later than the Start date"});
    }

    Object.assign(errors, validators.required(values.event_status, 'event_status'));
    Object.assign(errors, validators.required(values.tournament_products, 'tournament_products'));
    Object.assign(errors, validators.required(values.description, 'description'));
    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));
    Object.assign(errors, validators.maxLength({value: values.notes, maxLength: 200}, 'notes'));

    return errors;
};

export default validate
