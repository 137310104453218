import React from 'react';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {BLACKLISTED_IP} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

export const IpBlacklistingIndexButtons = () => (
    <Authorization authorization={{[BLACKLISTED_IP]: SECURITY_PRIVILEGES_CREATE}}>
        <Link name={'ipBlacklisting.add'}>
            <div className='content__buttons'>
                <ButtonAdd>Add new CIDR</ButtonAdd>
            </div>
        </Link>
    </Authorization>
);
