import * as actionTypes from '../actions/actionTypes';
import {get as _get} from "lodash"

const initialState = {};

/**
 * Add timestamp to object
 * The Timestamp is used to compare timestamp (prevTimestamp > currentTimestamp)
 * and refetch data after click on "Search" button
 */
const getDataWithTimestamp = (data) => (
    Object.assign({}, data, {timestamp: Date.now()})
)

/**
 * @param state
 * @param action
 * @returns {*} 
 */
const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        /**
         * Initial timestamp, when mount component with filters
         */
        case actionTypes.SET_INITIAL_FILTER: {
            let {name} = action.data,
                actualStateTimestamp = _get(state, `${[name]}.params.timestamp`, null);

            if (null === actualStateTimestamp) {
                let {data} = action.data,
                    dataWithTimestamp = getDataWithTimestamp(data);

                return Object.assign(
                    {},
                    state,
                    {
                        [name]: {
                            params: {...dataWithTimestamp},
                        }
                    }
                );
            }

            return state;
        }

        case actionTypes.SET_FILTER : {
            let {name, data, forceRefresh} = action.data;

            for (const key in data) {
                if (data[key] === null) {
                    delete data[key]
                }
            }

            if (true === forceRefresh) {
                data = getDataWithTimestamp(data);
            }

            return Object.assign(
                {}, state, { [name]: {
                    params: {...data},
                    areFiltersVisible: _get(state, `${[name]}.areFiltersVisible`, true)
                }}
            );
        }

        case actionTypes.RESET_FILTER: {
            let resource = Object.assign({}, state);

            delete resource[action.data.params];

            return resource
        }

        case actionTypes.FILTERS_VISIBILITY: {
            let filtersVisible = _get(state, `${action.data.key}.areFiltersVisible`, true);
            let data = Object.assign({}, state, { [action.data.key]: {
                ...state[action.data.key],
                areFiltersVisible: !filtersVisible
            }});

            return {...data}
        }

        case "persist/REHYDRATE": {
            const savedData = _get(action, "payload.app.filters", false) || initialState;

            return {
                ...state,
                ...savedData
            }
        }

        default:
            return state
    }
};

export default filterReducer
