import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {graphql} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import {setModal} from '@actions/index';
import {GetContactPersonForForm} from '@graphql/contactPerson/query';
import {showErrorModal} from '@utils/modal';
import {CONTACT_PERSON_TYPE_LICENSOR} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

import ContactPersonForm from './ContactPersonForm';

class ContactPersonFormEdit extends React.Component {

    constructor() {
        super();

        this.orEmpty = function (entity) {
            return entity || '';
        };
    }

    componentDidUpdate() {
        const contactPersonTypeId = this.props.data?.contactPerson?.contact_person_type?.id,
            licencorsName = this.state.licencorsName,
            contactPersonName = this.props.data?.contactPerson?.name;

        if (this.props.parent.name === 'licensors' && licencorsName) {
            document.title = (CONTACT_PERSON_TYPE_LICENSOR === convertToInt(contactPersonTypeId))
                ? ('Licensor "' + licencorsName +'" - Licensor contact person "' + contactPersonName + '" - AV-CMP')
                : ('Licensor "' + licencorsName +'" - Sportradar contact person "' + contactPersonName + '" - AV-CMP');
        }
    }

    componentWillUnmount() {
        if (this.props.parent.name === 'licensors') {
            document.title = 'Licensor "' + this.props.parent.licencorsName + '" - Contact persons - AV-CMP';
        }
    }

    static getDerivedStateFromProps (nextProps) {
        if (nextProps.data.error !== undefined) {
            showErrorModal({
                isVisible: true,
                header: 'Contact person not found',
                redirectLink: getLink(`${nextProps.parent.name}.contactPerson.index`, {id: nextProps.parent.id}),
                errorButtonText: 'Back to list',
            });
        }

        if (nextProps.parent.licencorsName) {
            return {licencorsName: nextProps.parent.licencorsName};
        }
    }

    render() {
        const entity = this.props.data.contactPerson;

        return (
            <div>
                <Header content={`Contact person ${this.orEmpty(entity && entity.name)}`}/>
                <ContactPersonForm
                    formData={this.props.data}
                    parent={this.props.parent}
                    personType={this.props.personType}
                />
            </div>
        );
    }
}

ContactPersonFormEdit.propTypes = {
    data: PropTypes.object,
    Modal: PropTypes.object,
    parent: PropTypes.object,
    personType: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
    return {
        Modal: {
            setModal: (params) => {
                dispatch(setModal(params));
            },
        },
    };
};

export default graphql(GetContactPersonForForm, {
    options: (props) => {
        return {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            variables: {
                id: props.id,
            },
        };
    },
})(connect(null, mapDispatchToProps)(ContactPersonFormEdit));
