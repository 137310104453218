export const PRODUCTION = 'Production';
export const STAGING = 'Staging';
export const DEVELOPMENT = 'Dev';
export const LOCAL = 'Local';

export const ENVIRONMENT_TO_HOSTNAME_MATCHERS = [
    {environmentName: PRODUCTION, environmentIndicators: ['avcmp.sportradar.com'], matchAllHosts: false},
    {environmentName: STAGING, environmentIndicators: ['staging', 'stg'], matchAllHosts: false},
    {environmentName: DEVELOPMENT, environmentIndicators: ['development', 'dev'], matchAllHosts: false},
    {environmentName: LOCAL, environmentIndicators: [], matchAllHosts: true}
];
