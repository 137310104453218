import {
    get as _get,
    isBoolean as _isBoolean,
    isEmpty as _isEmpty,
    find as _find,
} from 'lodash';

import {withApollo} from 'react-apollo';
import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {THIRD_PARTY_CLIENT_TYPE_PULL, THIRD_PARTY_CLIENT_TYPE_PUSH} from '@constants/variables';
import {
    GetPropertyLicenceTechnicalSetupForThirdPartyClients,
} from '@graphql/thirdPartyClients/query';
import {GetThirdPartyClientsForForm} from '@graphql/eventContentsThirdParty/query';

import {
    checkIfAnyStreamExists,
    checkIfAnyTechSetupThirdPartyClientExists,
    getStreams,
    getTechSetupThirdPartyClientTypes,
    parseClientsStreams,
    parseThirdPartyClientTypes,
} from '../../utils/thirdPartyClients';

export class ThirdPartyClientsForm extends DefaultForm {
    componentDidMount() {
        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });

        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: GetThirdPartyClientsForForm,
            variables: {
                id: convertToInt(this.props.formParams.eventId),
            },
        }).then((response) => {
            this.parseThirdPartyClientsPushPull(response);
        });
    }

    componentDidUpdate(prevProps) {
        const clientProductsThirdParty = _get(this.props, 'GraphQLOptionsData.clientProductsThirdParty', null);
        let clientStreams = {};

        if (clientProductsThirdParty && !this.state.clientStreams
            && clientProductsThirdParty !== prevProps.GraphQLOptionsData.clientProductsThirdParty) {
            clientStreams = parseClientsStreams(clientProductsThirdParty, this.props.formParams.eventContent);

            this.setField('pushClients', {
                inputsData: clientStreams.pushClients,
            });
            this.setField('pullClients', {
                inputsData: clientStreams.pullClients,
            });
            this.setState(() => ({clientStreams: clientStreams}));
        }

        if (
            !this.state.isSaveButtonChecked
            && (
                !_isEmpty(this.props.Model.fields.pushClients.props.inputsData)
                || !_isEmpty(this.props.Model.fields.pullClients.props.inputsData)
            )
        ) {
            let streamExistence = checkIfAnyStreamExists(this.props.Model.fields.pushClients.props.inputsData);

            if (!streamExistence) {
                streamExistence = checkIfAnyStreamExists(this.props.Model.fields.pullClients.props.inputsData);
            }

            this.setState(() => ({isSaveButtonChecked: true, isSaveButtonDisabled: !streamExistence}));
        }
    }

    prepareDataForSubmit = (data) => {
        let dataToSave = {
            eventContentId: parseInt(this.props.formParams.eventContentId, 10),
            push: [],
            pull: [],
        };

        dataToSave.push = getStreams(data.pushStreams, 'push');
        dataToSave.pull = getStreams(data.pullStreams, 'pull');

        return dataToSave;
    };

    parseThirdPartyClientsPushPull = (data) => {
        const thirdParty = _find(
                _get(data, 'data.eventContentsPushPull', null),
                {'event_content_id': this.props.formParams.eventContentId}
            ),
            types = {
                pull: parseThirdPartyClientTypes(thirdParty, 'pull'),
                push: parseThirdPartyClientTypes(thirdParty, 'push'),
            };

        if (_isEmpty(types.push) && _isEmpty(types.pull)) {
            this.setState(() => ({
                formLoading: true,
            }));

            this.getTechSetupForThirdParty(this.props.formParams.propertyLicenceId).then((techSetup) => {
                const thirdPartyClientTypes = getTechSetupThirdPartyClientTypes(techSetup);

                this.setPushPullFields(thirdPartyClientTypes);
                this.setState(() => ({
                    formLoading: false,
                }));

                if (!_isEmpty(thirdPartyClientTypes.push) || !_isEmpty(thirdPartyClientTypes.pull)) {
                    let isTechSetupCreated = checkIfAnyTechSetupThirdPartyClientExists(
                        thirdPartyClientTypes.push,
                        this.props.Model.fields.pushClients.props.inputsData
                    );

                    if (!isTechSetupCreated) {
                        isTechSetupCreated = checkIfAnyTechSetupThirdPartyClientExists(
                            thirdPartyClientTypes.pull,
                            this.props.Model.fields.pullClients.props.inputsData
                        );
                    }

                    this.setField('preSectionMessageBox', {hidden: !isTechSetupCreated});
                }
            });
        } else {
            this.setPushPullFields(types);
        }
    };

    getTechSetupForThirdParty = () => {
        return this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: GetPropertyLicenceTechnicalSetupForThirdPartyClients,
            variables: {
                id: convertToInt(this.props.formParams.propertyLicenceId),
                types: [THIRD_PARTY_CLIENT_TYPE_PUSH, THIRD_PARTY_CLIENT_TYPE_PULL],
            },
        });
    };

    setPushPullFields = (types) => {
        this.setField('pushClients', {
            initialValues: types.push,
        });
        this.setField('pullClients', {
            initialValues: types.pull,
        });
    };

    renderSaveButton = () => {
        return super.renderSaveButton({
            content: 'Save',
            disabled: _isBoolean(this.state.isSaveButtonDisabled) ? this.state.isSaveButtonDisabled : true,
        });
    };
}

export default withApollo(ThirdPartyClientsForm);
