import React from 'react';
import {
    isEqual as _isEqual,
} from 'lodash';

let state = {},
    listener = {};

const reduce = (prevState, data) => (
    {...prevState, [data.name]: data}
);

export function setInfoBox(data) {
    if (!data.name) {
        data.name = Date.now() + Object.keys(state).length;
    }

    const prevValue = listener.getValue(state);

    state = reduce(state, data);

    if (!_isEqual(prevValue,state)) {
        listener.setValue(state);
    }
}

export function removeInfoBox(id) {
    if (state[id]) {
        delete state[id];

        listener.setValue({...state});
    }
}


export function setInfoBoxError(data) {
    setInfoBox({...data, type: 'error'});
}

export function setInfoBoxSuccess(data) {
    setInfoBox({...data, type: 'success'});

}
export function useState(getValue) {
    const [value, setValue] = React.useState(getValue(state));

    React.useEffect(() => {
        listener = {getValue, setValue};
    }, [getValue]);

    return value;
}
