import {get as _get} from "lodash";
import PropTypes from "prop-types";

import {getYesOrNo} from "../../../../utils/helpers";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'production_companies',
    parseData: productionCompany => [
        productionCompany.id,
        productionCompany.name,
        _get(productionCompany, 'country.name', ''),
        _get(productionCompany, 'client.name', ''),
        getYesOrNo(productionCompany.is_archived),
    ],
    path: 'productionCompanies.index.path',
    titles: [
        "ID",
        "Name",
        "Country",
        "Client",
        "Archived?",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
