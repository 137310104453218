import {
    GetOptionsForForm,
    GetClipConfigurationSportLevelForForm,
} from '@graphql/clipConfiguration/query';

import ClipConfigurationModel from './ClipConfigurationModel';

export default function ClipConfigurationSportLevelModel() {
    const clipConfigurationSportLevelModel = ClipConfigurationModel();

    clipConfigurationSportLevelModel.entityLabel = 'clip configuration';
    clipConfigurationSportLevelModel.fields.sports.props.multiple = true;
    clipConfigurationSportLevelModel.fields.tournaments.props.hidden = true;
    clipConfigurationSportLevelModel.fields.tournaments.props.required = false;
    clipConfigurationSportLevelModel.fields.categories.props.hidden = true;
    clipConfigurationSportLevelModel.fields.categories.props.required = false;

    clipConfigurationSportLevelModel.query.getEntity = GetClipConfigurationSportLevelForForm;
    clipConfigurationSportLevelModel.query.getOptions = GetOptionsForForm;

    return clipConfigurationSportLevelModel;
}
