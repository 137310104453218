import React from 'react';
import PropTypes from 'prop-types';

import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {IconCheckmark, IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import {getTerritoriesWithTooltip} from '@utils/countryHelpers';
import {Authorization} from '@appComponents/Authorization';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';

import PropertyLicenceContentsRow from '../components/PropertyLicenceContentsRow';

const authorizationObject = {
    [RESOURCES.RIGHT_SCOPE]: CONST.SECURITY_PRIVILEGES_READ,
};

export const rowRenderer = (column, row, props) => {
    const {mappedIsoCodes = {}, propertyLicenceId} = props;

    switch (column) {
        case 'id':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: 'propertyLicences.rightsScopes.editGeneralInformation',
                        params: {id: propertyLicenceId, rightsScopeId: row.id},
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        case 'licensee':
            return (
                <div>{
                    (row.licensee)
                        ? row.licensee.name
                        : ''
                }</div>
            );
        case 'contents': {
            if (false === row.includes_all_content) {
                return PropertyLicenceContentsRow(row);
            }

            return (<div>All</div>);
        }
        case 'territories':
            return getTerritoriesWithTooltip(row, mappedIsoCodes);
        case 'exclusivity':
            return row.rights_scope_exclusivity ? row.rights_scope_exclusivity.name : '';
        case 'sub_licencing':
            return row.rights_scope_sub_licensing ? row.rights_scope_sub_licensing.name : '';
        case 'stream_constraints':
            if (row.rights_scope_stream_constraints && 0 < row.rights_scope_stream_constraints.length) {
                return <IconCheckmark/>;
            } else {
                return '';
            }
        case 'products':
            return row.usages
                .map((usage) => usage.product.short_name)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(', ');
        case 'actions': {
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            link={getLink('propertyLicences.rightsScopes.editGeneralInformation', {
                                id: propertyLicenceId,
                                rightsScopeId: row.id,
                            })}
                            size='large'
                            resource={RESOURCES.RIGHT_SCOPE}
                        />
                    </div>
                </Authorization>
            );
        }
        default: {
            return null;
        }
    }
};

rowRenderer.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
    mappedIsoCodes: PropTypes.shape({
        countries: PropTypes.object,
        subdivisions: PropTypes.object,
    }),
};

rowRenderer.displayName = 'rowRenderer';
