import {get as _get} from 'lodash';

import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import {
    getActions,
    getClientPackageName,
    getClientName,
} from '@modules/events/utils/eventsEventBookingsTableHelpers';
import {getProductShortName, getClipNames} from '@modules/clipConfiguration/utils/propertyDecorator';

export const rowRenderer = (column, row, props) => {
    const eventBookingGeoRestrictions = _get(row, 'event_booking_geo_restrictions', []);
    const deviceCategoriesData = parseDeviceCategories(props.mappedIsoCodes, eventBookingGeoRestrictions);
    const { history } = props;

    switch (column) {
        case 'id':
            return row.id;
        case 'client':
            return getClientName(row);
        case 'product':
            return getProductShortName(row);
        case 'clips':
            return getClipNames(row);
        case 'deviceCategories':
            return renderDeviceCategories(deviceCategoriesData);
        case 'countries':
            return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesData, props.mappedIsoCodes);
        case '#countries':
            return renderNumberOfCountries(deviceCategoriesData);
        case 'blackoutZones':
            return renderBlackoutZonesTableRowCell(deviceCategoriesData);
        case 'package':
            return _get(row, 'client_package', '') ? getClientPackageName(row) : '-';
        case 'type':
            return _get(row, 'booking_type.name', '');
        case 'time':
            return _get(row, 'creation_datetime', '');
        case 'actions':
            return getActions(row, history, props.mutationCallback);
        default:
            return null;
    }
};
