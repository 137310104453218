import {get as _get} from 'lodash';
import {withRouter} from 'react-router';

import {setBlackoutZones} from '@utils/event/blackoutZones';
import {editValidation, onCloseModal, setMutationSuccess} from '@modules/eventBooking/forms/helpers/helpers';
import {getEventBookingDataFromAllSteps} from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';
import {EventBookingBlackoutZonesForm} from '@modules/eventBooking/forms/EventBookingBlackoutZonesForm';

export class EventBookingBlackoutZonesEditForm extends EventBookingBlackoutZonesForm {
    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
        this.setDeleteSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
    }

    componentDidUpdate() {
        const {
                GraphQLEntityData,
                GraphQLOptionsData,
            } = this.props,
            isLoading = (GraphQLEntityData && GraphQLEntityData.loading) || GraphQLOptionsData.loading;

        if (this.state.switchesStatus !== this.props.switches) {
            this.setField('desktop_blackoutZones', {
                hidden: !this.props.switches.desktop_switch,
            });
            this.setField('mobile_blackoutZones', {
                hidden: !this.props.switches.mobile_switch,
            });
            this.setField('smart_tv_blackoutZones', {
                hidden: !this.props.switches.smart_tv_switch,
            });

            this.setState(() => ({
                switchesStatus: this.props.switches,
            }));
        }

        if ((!isLoading && !this.state.dataLoaded)) {
            const eventBookingGeoRestrictions = _get(GraphQLEntityData, 'eventEventBooking.event_booking_geo_restrictions', []),
                previousStepData = _get(this.props, 'previousStepData', {}),
                blackoutZonesOptions = _get(GraphQLOptionsData, 'blackoutZones', []);

            setBlackoutZones(this.setField, previousStepData, blackoutZonesOptions, eventBookingGeoRestrictions);

            this.setState(() => ({dataLoaded: true}));
        }
    }

    prepareDataForSubmit = () => {
        return getEventBookingDataFromAllSteps(this.props.stepsMethods, this.props.formParams.id);
    };

    onFormSubmit = (data) => {
        if (editValidation(this.props.stepsMethods)) {
            this.setState(() => ({
                defaultForm_loading: true,
            }));
            super.onFormSubmit(data);
        }
    }
}

export default withRouter(EventBookingBlackoutZonesEditForm);
