import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';

const EventStreamsFilters = (props) => {

    return (
        <div className='eventStreams__filters filter__controls__container'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'search')}
                disabled={props.loading}
            />
            <Field
                component={Form.ReduxSelect}
                name='contents'
                options={props.data.contents}
                disabled={props.loading}
                placeholder='Content'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'contents')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='eventContentTypes'
                className='short'
                options={props.data.eventContentTypes}
                disabled={props.loading}
                placeholder='Content type'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'eventContentTypes')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='eventContentVariants'
                className='short'
                options={props.data.eventContentVariants}
                disabled={props.loading}
                placeholder='Content variant'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'eventContentVariants')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='products'
                className='short'
                options={props.data.products}
                disabled={props.loading}
                placeholder='Product'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'products')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='distributionTypes'
                options={props.data.distributionTypes}
                disabled={props.loading}
                placeholder='Distribution type'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'distributionTypes')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='streamDelayTypes'
                options={props.data.streamDelayTypes}
                disabled={props.loading}
                placeholder='Stream delay type'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'streamDelayTypes')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='deviceCategories'
                options={props.data.deviceCategories}
                disabled={props.loading}
                placeholder='Device category'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'deviceCategories')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='formats'
                className='short'
                options={props.data.streamFormats}
                disabled={props.loading}
                placeholder='Format'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'formats')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='protocols'
                className='short'
                options={props.data.streamProtocols}
                disabled={props.loading}
                placeholder='Protocol'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'protocols')}
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='streamStatuses'
                options={props.data.streamStatuses}
                disabled={props.loading}
                placeholder='Status'
                loading={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventsStreams, 'streamStatuses')}
                multiple
                selection
            />
        </div>
    );
};

EventStreamsFilters.propTypes = {
    data: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func,
};

EventStreamsFilters.defaultProps = {
    loading: false,
};

export default FiltersWrapper(EventStreamsFilters, {name: 'EventsStreams'});
