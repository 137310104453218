import PropTypes from "prop-types";
import React from "react";

import Table from "../../app/components/Table";

import {cidrColumns, prepareStringForCidrSorting} from "../../../utils/CidrHelpers";
import {rowRenderer} from "../utils/whitelistingHelper";

const WhitelistedIpTable = (props) => {
    const {whitelistedIps = [], loading} = props;

    return (
        <Table
            columns={cidrColumns}
            data={whitelistedIps}
            defaultSort={prepareStringForCidrSorting}
            loading={loading}
            name="whitelistingIpsList"
            noDataText="No CIDRs found"
            pagination={false}
            rowRenderer={rowRenderer}
            rowRendererProps={{...props}}
        />
    );
};

WhitelistedIpTable.propTypes = {
    whitelistedIps: PropTypes.array,
    disableClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

WhitelistedIpTable.displayName = "WhitelistingTable";

export default WhitelistedIpTable;
