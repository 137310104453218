import {get as _get} from 'lodash';

import {withApollo} from 'react-apollo';
import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {
    STREAM_PROTOCOL_RTMP,
    STREAM_PROTOCOL_HLS,
    STREAM_PROTOCOL_DASH, LIVE_INGEST_METHOD_AKAMAI_STREAM_PACKAGING, LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

class AkamaiLiveStreamNameForm extends DefaultForm {
    state = {
        ...this.state,
        entityDataLoaded: false,
        optionsLoaded: false,
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {GraphQLOptionsData: {streamProtocols, loading = true}} = nextProps;

        if (streamProtocols && !loading && !this.state.optionsLoaded) {
            this.setField('stream_protocol', {
                hidden: !this.showStreamProtocolInputField(nextProps.formParams.liveIngestMethodId),
                options: streamProtocols.filter((streamProtocol) => (
                    this.filterStreamProtocolOptions(streamProtocol, nextProps.formParams.liveIngestMethodId)
                )),
            });

            this.setState(() => ({
                optionsLoaded: true,
            }));
        }

        if (this.props.formParams.id) {
            const {GraphQLEntityData: {loading: loadingEntityData}} = nextProps;

            if (!loadingEntityData && !this.state.entityDataLoaded) {
                this.setField('stream_protocol', {
                    defaultValue: !this.showStreamProtocolInputField(nextProps.formParams.liveIngestMethodId)
                        ? null
                        : (
                            nextProps.formValues.stream_protocol
                            || _get(nextProps, 'GraphQLEntityData.akamaiLiveStreamName.stream_protocol.id', '')
                        ),
                });

                this.setState(() => ({
                    entityDataLoaded: true,
                }));
            }
        }
    }

    filterStreamProtocolOptions = (streamProtocol, liveIngestMethodId) => {
        if (LIVE_INGEST_METHOD_AKAMAI_STREAM_PACKAGING === liveIngestMethodId) {
            return STREAM_PROTOCOL_RTMP === convertToInt(streamProtocol.id);
        } else if (LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH === liveIngestMethodId) {
            return [STREAM_PROTOCOL_HLS, STREAM_PROTOCOL_DASH].includes(convertToInt(streamProtocol.id));
        }

        return false;
    };

    showStreamProtocolInputField = (liveIngestMethodId) => (
        [LIVE_INGEST_METHOD_AKAMAI_STREAM_PACKAGING, LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH].includes(liveIngestMethodId)
    );

    prepareDataForSubmit = (data) => {
        return Object.assign({}, data, {
            client_cdn_configuration_id: convertToInt(this.props.formParams.clientCdnConfigurationId),
            encoding_datacenter_id: convertToInt(data.encoding_datacenter),
            stream_protocol: data.stream_protocol ? convertToInt(data.stream_protocol) : null,
            description: data.description || null,
        });
    };

    renderErrors(errorData) {
        const link = getLink('clients.clientCdnConfiguration.akamaiLiveStreamName.index', {
            id: this.props.formParams.clientId || null,
            clientCdnConfigurationId: this.props.formParams.clientCdnConfigurationId || null,
        });

        super.renderErrors(errorData, 'Akamai Live stream name', link);
    }
}

export default withApollo(AkamaiLiveStreamNameForm);
