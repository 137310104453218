import PropTypes from "prop-types";
import React from "react";
import {Dropdown, Icon, Popup} from "semantic-ui-react";

import {LIVE_CONTROL, EVENT_CONTENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {IconQuestionCircleOutline} from "../../app/components/IconCollection";
import LiveControlAutoRefresh from "../components/LiveControlAutoRefresh";

const authorizationLiveControlUpdateObject = {
    [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
    [EVENT_CONTENT]: SECURITY_PRIVILEGES_UPDATE,
};

const LiveControlLegend = (props) => {
    const content = (
        <div className={`liveControlLegend`}>
            <div className={`legendBlock`}>
                The Live Control page shows all of the non-hidden event contents verifying one of the following conditions:
                <ul>
                    <li>Event content belongs to an event with the status &quot;Planned&quot;,
                        has a start time within the selected time-frame and has at least one distribution
                        &quot;Live&quot; or &quot;Recording&quot;.</li>
                    <li>Event content belongs to an event with the status &quot;Planned&quot; and without report,
                        has a start time in the last 72 hours and has at least one non-SpOTT distribution
                        &quot;Live&quot; with broadcast status &quot;Delivered&quot;.</li>
                    <li>Event content has the encoding status &quot;Preparing&quot;, &quot;Encoding&quot;,
                        &quot;Stopping&quot; or &quot;Error&quot;.</li>
                    <li>Event content has at least one distribution &quot;Live&quot;
                        with broadcast status &quot;On Air Ready&quot; or &quot;On Air Broadcast&quot;.</li>
                </ul>
            </div>
            <div className={`legendBlock`}>
                <div className={`legendTitle`}>Legend</div>
            </div>
            <div className={`legendBlock`}>
                <div className={`legendColor --red`}/>
                Event contents with encoding status &quot;Preparing&quot; or &quot;Error&quot;.
            </div>
            <div className={`legendBlock`}>
                <div className={`legendColor --orange`}/>
                Event contents with a possibly wrong broadcast status:
                <ul className={`--indented`}>
                    <li>When the broadcast status is not &quot;On Air Broadcast&quot; whereas the encoding status
                        is &quot;Encoding&quot; and the event content is running.</li>
                    <li>When the broadcast status is &quot;On Air Ready&quot; or &quot;On Air Broadcast&quot;
                        whereas the encoding status is not &quot;Encoding&quot;.</li>
                </ul>
            </div>
            <div className={`legendBlock`}>
                <div className={`legendColor --yellow`}/>
                Event contents with encoding status &quot;Encoding&quot; and with broadcast status &quot;On Air
                Ready&quot; or &quot;Delivered&quot;.
            </div>
            <div className={`legendBlock`}>
                <div className={`legendColor --green`}/>
                Event contents with broadcast status &quot;On Air Broadcast&quot;.
            </div>
            <div className={`legendBlock`}>
                <div className={`legendColor --purple`}/>
                Event contents with missing event report.
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div className="liveControl__indexLegend content__buttons">
                <Authorization authorization={authorizationLiveControlUpdateObject}>
                    <Dropdown button direction="left" trigger={<Icon name='cog'/>}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                disabled={props.loading}
                                onClick={props.handleMultipleUdpOriginEntryPointFailover}
                                text="UDP origin entry point failover"
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Authorization>
                <Popup
                    content={content}
                    trigger={<IconQuestionCircleOutline size="large"/>}
                    on='click'
                />
            </div>
            <LiveControlAutoRefresh
                autoRefreshInterval={props.autoRefreshInterval}
                setPollingInterval={props.setPollingInterval}
                refetchLiveControl={props.refetchLiveControl}
            />
        </React.Fragment>
    );
};

LiveControlLegend.propTypes = {
    handleMultipleUdpOriginEntryPointFailover: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    autoRefreshInterval: PropTypes.number.isRequired,
    setPollingInterval: PropTypes.func.isRequired,
    refetchLiveControl: PropTypes.func.isRequired
};

export default LiveControlLegend;
