import React from "react";
import {get as _get} from "lodash";

export const renderBookingUserInformation = (params = {}) => {
    const editModeBookingId = _get(params, 'formValues.id', null);

    if (!editModeBookingId) {
        return null;
    }

    const ssoUserId = _get(params, 'defaultValue.id', '');

    if (!ssoUserId) {
        return null;
    }

    const username = _get(params, 'defaultValue.username', ''),
        name = _get(params, 'defaultValue.name', ''),
        bookmakerName = _get(params, 'defaultValue.bookmaker.name', ''),
        bookmakerId = _get(params, 'defaultValue.bookmaker.id', '');

    return (
        <div>
            <label>{params.label}</label>
            {getBookingUserInformationText(username, name, ssoUserId, bookmakerName, bookmakerId)}
        </div>
    )
};

export const getBookingUserInformationForExcelExport = (bookingInformation) => (
    (bookingInformation && bookingInformation.id)
        ? getBookingUserInformationText(
            _get(bookingInformation, 'username', ''),
            _get(bookingInformation, 'name', ''),
            bookingInformation.id,
            _get(bookingInformation, 'bookmaker.name', ''),
            _get(bookingInformation, 'bookmaker.id', ''),
        )
        : ''
);

const getBookingUserInformationText = (username, name, ssoUserId, bookmakerName, bookmakerId) => (
    `${username} - ${name} (${ssoUserId}) - ${bookmakerName} (${bookmakerId})`
);
