import Form from '@appComponents/ReduxFormControls';
import {GetBlackoutZone} from '@graphql/blackoutZone/query';
import {CreateBlackoutZone, UpdateBlackoutZone, DeleteBlackoutZone} from '@graphql/blackoutZone/mutation';
import * as MESSAGES from '@constants/messages';
import {BLACKOUT_ZONE} from '@constants/resources';

import validationGeneralInformation from './validationGenerealInformation';

const GeneralInformationModel = () => (
    {
        formName: 'BlackoutZone-generalInformation',
        showChangelog: true,
        title: null,
        entityDataMapKey: 'blackoutZone',
        label: 'Blackout zone',
        entityLabel: 'Blackout zone',
        resources: BLACKOUT_ZONE,
        validate: validationGeneralInformation,
        dataMap: {
            blackoutZone: {
                id: null,
                name: null,
                description: null,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'blackoutZone.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'blackoutZone.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            description: {
                dataMapKey: 'blackoutZone.description',
                props: {
                    className: 'form__input--input-width-medium',
                    component: Form.SemanticInput,
                    label: 'Description',
                    name: 'description',
                    type: 'input',
                },
            },
        },
        messages: {
            boxName: {
                delete: 'BlackoutZoneIndex',
                error: 'BlackoutZoneEdit',
                success: 'BlackoutZoneEdit',
            },
            text: {
                ...MESSAGES,
            },
        },
        mutation: {
            createEntity: CreateBlackoutZone,
            updateEntity: UpdateBlackoutZone,
            deleteEntity: DeleteBlackoutZone,
        },
        query: {
            getEntity: GetBlackoutZone,
            getOptions: null,
        },
    }
);

export default GeneralInformationModel;
