import React from "react";
import PropTypes from "prop-types";

import ContactPersonIndex from "../views/ContactPersonIndex";
import LicenseeEdit from "../views/LicenseeEdit";

import {routes} from "../../app/constants/routes";
import {CONTACT_PERSON, LICENSEE} from "../../app/constants/resources";

export const createTabs = (id, path) => {
    return (routes.licensees.add.path === path)
        ? {
            [routes.licensees.add.path]: {
                key: routes.licensees.add.path,
                url: "licensees.add",
                text: "General information",
                render: <LicenseeEdit/>,
                resource: LICENSEE
            }
        }
        : {
            [routes.licensees.edit.path]: {
                key: routes.licensees.edit.path,
                url: "licensees.edit",
                urlParams: {id: id},
                text: "General information",
                render: <LicenseeEdit/>,
                resource: LICENSEE
            },
            [routes.licensees.contactPerson.index.path]: {
                key: routes.licensees.contactPerson.index.path,
                url: "licensees.contactPerson.index",
                urlParams: {id: id},
                text: "Contact persons",
                render: <ContactPersonIndex/>,
                resource: CONTACT_PERSON
            }
        };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createLicenseeTabs';
