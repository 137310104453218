import { isEmpty as _isEmpty, isNumber as _isNumber } from 'lodash';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from '@apollo/client';

import { createForm, renderModalError } from '@utils/forms';
import { GetEventsForReportMultipleAsWorkingAndInvoiceable } from '@graphql/events/query';
import { getLink } from '@appComponents/Link';
import { showModal, showErrorModal } from '@utils/modal';
import ReportMultipleEventsAsWorkingModel from '@modules/notifications/forms/ReportMultipleEventsAsWorkingModel';
import ReportMultipleEventsAsWorkingForm from '@modules/notifications/forms/ReportMultipleEventsAsWorkingForm';

import {EventsReportedAsWorkingAndInvoiceableTable} from './EventsMultiple/EventsReportedAsWorkingAndInvoiceableTable';

const ReportMultipleAsWorkingAndInvoiceableModalContent = ({eventIds = []}) => {
    const EVENTS_LIMIT = 100;

    const errorHandler = (message = null)  => {
        showModal({
            isVisible: true,
            content: renderModalError(
                'Report as working and invoiceable',
                getLink('events.index'),
                message,
                'Back to Event Scheduling'
            ),
            size: 'tiny',
        });
    };

    if (!eventIds.length) {
        errorHandler('No events selected');

        return null;
    }

    if (eventIds.length > EVENTS_LIMIT) {
        errorHandler(`You can report maximum of ${EVENTS_LIMIT} events at once, ${eventIds.length} selected.`);

        return null;
    }

    eventIds.forEach((eid) => {
        if (!_isNumber(eid)) {
            errorHandler('Wrong event id provided, all event ids must be numeric!');

            return null;
        }
    });

    const {
        data: {
            eventListForMultipleView,
        } = {},
        error,
        loading: isLoading,
    } = useQuery(GetEventsForReportMultipleAsWorkingAndInvoiceable, {
        fetchPolicy: 'no-cache',
        variables: {events: eventIds},
    });

    if (!_isEmpty(error)) {
        showModal({
            isVisible: true,
            content: renderModalError(
                'Report as working and invoiceable',
                getLink('events.index'),
                error.message,
                'Back to Event Scheduling'
            ),
        });

        return null;
    }

    if (!isLoading) {
        eventListForMultipleView.events.forEach((event) => {
            if (_isEmpty(event.event_contents)) {
                showErrorModal({
                    header: 'Invalid selection to report as working and invoiceable',
                    text: 'Reporting as working and invoiceable is possible only for a selection of events which have at least one distribution.' +
                        ' Please correct your selection.',
                });
            }
        });
    }

    const ReportMultipleForm = createForm(
        ReportMultipleEventsAsWorkingModel,
        ReportMultipleEventsAsWorkingForm,
        {
            optionsVariables: {
                events: eventIds,
            },
        }
    );

    return (
        <>
            <Header content='Report as working and invoiceable' />
            <EventsReportedAsWorkingAndInvoiceableTable
                data={eventListForMultipleView}
                isLoading={isLoading}
            />
            <p className='--marginTop-10 --marginLeft-1-5em --marginBottom-0'>
                Are you sure that these events were delivered without any issues and should be reported
                as working and invoiceable for all products?
            </p>
            <ReportMultipleForm />
        </>
    );
};

ReportMultipleAsWorkingAndInvoiceableModalContent.propTypes = {
    eventIds: PropTypes.array.isRequired,
};

export default ReportMultipleAsWorkingAndInvoiceableModalContent;
