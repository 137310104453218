export const countryList = `countries{
    id,
    name,
    key : id,
    value : id,
    text : name
}`;

export const countryListQuery = `
query countries{
    ${countryList}
}`;
