import PropTypes from "prop-types";
import React from "react";

import Table from "../../app/components/Table";

import {thirdPartyColumns, thirdPartyRowRenderer} from "../utils/thirdPartyCdnTable";

const ThirdPartyCdnsTable = (props) => {
    const {thirdPartyClientCdns = [], loading} = props;

    return (
        <Table
            columns={thirdPartyColumns}
            data={thirdPartyClientCdns}
            loading={loading}
            name="thirdPartyClientCdnsList"
            noDataText="No CDNs found"
            pagination={false}
            rowRenderer={thirdPartyRowRenderer}
            rowRendererProps={{...props}}
        />
    );
};

ThirdPartyCdnsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    thirdPartyClientCdns: PropTypes.array,
};

ThirdPartyCdnsTable.displayName = "ThirdPartyCdnsTable";

export default ThirdPartyCdnsTable;
