import { isEmpty as _isEmpty } from 'lodash';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withApollo} from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import {useQuery} from '@apollo/client';

import { FilterQueryWrapper, FilterUrlParamsWrapper } from '@appComponents/HOCFiltersQueryWrapper';
import { GetClipConfigs } from '@graphql/clipConfiguration/query';
import { getSearchFilters } from '@utils/filters';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import { showErrorModal } from '@utils/modal';
import { getLink } from '@appComponents/Link';
import mapModulesToProps from '@utils/mapModulesToProps';
import ClipConfigurationContentModal from '@modules/clipConfiguration/components/ClipConfigurationContentModal';
import navigation from '@constants/navigation';

import ClipConfigurationFilters, { DEFAULT_FILTER_VALUES, FILTER_KEY } from '../components/ClipConfigurationFilters';
import ClipConfigurationTable from '../components/ClipConfigurationTable';
import ClipConfigurationIndexButtons from '../components/ClipConfigurationIndexButtons.jsx';
import { exportExcel } from '../utils/excel/clipConfigurationIndexExcel';

const ClipConfigurationIndex = (props) => {
    const queryVariables = {
            filters: {
                search: props.filters.search ? props.filters.search : DEFAULT_FILTER_VALUES.search,
                product: props.filters.products ? props.filters.products : DEFAULT_FILTER_VALUES.products,
                sports: props.filters.sports ? props.filters.sports : DEFAULT_FILTER_VALUES.sports,
                categories: props.filters.categories ? props.filters.categories : DEFAULT_FILTER_VALUES.categories,
                tournaments: props.filters.tournaments ? props.filters.tournaments : DEFAULT_FILTER_VALUES.tournaments,
                eventContentTypes: props.filters.eventContentTypes ? props.filters.eventContentTypes : DEFAULT_FILTER_VALUES.eventContentTypes,
                matchEventTypes: props.filters.matchEventTypes ? props.filters.matchEventTypes : DEFAULT_FILTER_VALUES.matchEventTypes,
            },
        },
        filters= props.filters;

    const {
        data: {
            clipConfigs,
        } = {},
        loading: isLoading = true,
        error: clipConfigsFetchingErrorOccured,
    } = useQuery(GetClipConfigs, {
        variables: {
            ...queryVariables,
        },
        refetch: filters.lastClickSubmit,
        fetchPolicy: 'no-cache',
    });

    const {Menu} = props;

    useEffect(() => {
        Menu.storeMenu(navigation.eventAdministration.key);
    }, []);

    useEffect(() => {
        ClipConfigurationContentModal(props);
    }, [props.match]);

    if (!_isEmpty(clipConfigsFetchingErrorOccured)) {
        showErrorModal({
            header: 'Clip configurations list could not be retrieved',
            text: clipConfigsFetchingErrorOccured,
            errorButtonText: 'Back to dashboard',
            hideButtons: false,
            redirectLink: getLink('dashboard'),
            errorButtonIcon: 'arrow circle left',
        });
    }

    return (
        <div>
            <HeaderRenderer
                buttons={ClipConfigurationIndexButtons}
                exportExcelParams={exportExcel(clipConfigs)}
                pageTitle='Clip configuration'
                filtersButtonName='clipConfiguration'
                messagesBoxNames='clipConfigurationMessage'
                loading={isLoading}
                filters={ClipConfigurationFilters}
                filtersProps={{
                    loading: isLoading,
                    data: {clipConfigs: clipConfigs || []},
                }}
            />
            <div className='table-container'>
                <ClipConfigurationTable
                    data={clipConfigs}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

ClipConfigurationIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    Modal: PropTypes.object,
    Menu: PropTypes.object,
};

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, FILTER_KEY, DEFAULT_FILTER_VALUES),
});

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal', 'match', 'Menu']);

const ClipConfigurationIndexWrapped = FilterQueryWrapper(ClipConfigurationIndex, {
    queryForRefresh: 'GetClipConfigs',
    filterUrls: ['clipConfiguration.index'],
});

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(ClipConfigurationIndexWrapped, DEFAULT_FILTER_VALUES)
)));
