const urls = {
    authValidate: '/auth/validate',
    authLogin: '/auth/login?redirectTo=',
    authLogout: '/auth/logout',
    sportsImages: {
        small: 'https://img.sportradar.com/ls/sports/small/',
    },
    services: {
        getIpV4: process.env.REACT_APP_SERVICE_GET_IP_V4,
        getIpV6: process.env.REACT_APP_SERVICE_GET_IP_V6,
    },
};

export default urls;
