import {connect} from "react-redux";
import {withRouter} from "react-router";

import ClientProductForm from "./ClientProductForm";
import {getLink} from "../../app/components/Link";

import {deleteSuccessCallback, setClientProductsEntity} from "../utils/clientProducts";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {PRODUCT_LCPP} from "../../app/constants/variables";

class ClientProductLcppForm extends ClientProductForm {
    componentDidMount() {
        super.componentDidMount();

        const entityName = "client",
            params = {
                clientData: this.props.clientData,
                entityName,
                setEntity: this.props.Entity.setEntity,
            };

        this.setCreateSuccessCallback((response) => {
            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientProducts: this.props.clientData.clientProducts.concat(
                        response.data.createClientProductLcpp.client_product
                    ),
                }),
            });

            this.props.history.push(getLink("clients.products.lcpp.editGeoRestrictions", {
                id: this.props.match.params.id,
            }));
        });

        this.setUpdateSuccessCallback((response) => {
            const clientProductLcppIndex = this.props.clientData.clientProducts.findIndex(clientProduct =>
                    PRODUCT_LCPP === convertToInt(clientProduct.product.id)
                ),
                clientProducts = {};

            clientProducts[clientProductLcppIndex] = Object.assign({},
                response.data.updateClientProductLcpp.client_product
            );
            params.clientProducts = clientProducts;

            setClientProductsEntity(params);
        });

        this.setDeleteSuccessCallback(() => {
            params.formParamsId = this.props.formParams.id;
            params.historyPush = this.props.history.push;

            deleteSuccessCallback(params);
        });
    }

    componentDidUpdate() {
        const {
            formParams: {isAddForm},
            GraphQLEntityData: {
                clientProductsLcpp,
                loading: entityLoading,
            } = {
                entityLoading: false,
            },
            GraphQLOptionsData: {
                loading: optionsLoading,
            },
        } = this.props;

        let nextProps;

        if (!optionsLoading && !entityLoading && !this.state.dataReceived) {
            if (!isAddForm) {
                nextProps = Object.assign({}, this.props, {
                    clientProduct: clientProductsLcpp[0].client_product,
                });

                this.setField("id", {defaultValue: convertToInt(clientProductsLcpp[0].id)});
            }

            super.componentDidUpdate(nextProps);
        }
    }

    prepareDataForSubmit(data) {
        data = Object.assign({}, data, {product: PRODUCT_LCPP});

        const dataToSubmit = super.prepareDataForSubmit(data);

        return Object.assign({}, dataToSubmit, {
            clip_lifetime: data.clip_lifetime ? convertToInt(data.clip_lifetime) : null,
            notification_api_url: data.notification_api_url || null,
            api_key: data.api_key || null,
        });
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity']),
    mapStateToProps = (state) => ({clientData: state.app.entities.client || null});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientProductLcppForm));
