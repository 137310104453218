import PropTypes from "prop-types";
import React from "react";
import {Header, Button, Segment} from "semantic-ui-react";
import {
    has as _has,
    isArray as _isArray,
    isEqual as _isEqual,
    isFunction as _isFunction,
    isObject as _isObject,
    get as _get
} from "lodash";

import * as formUtils from "../../../utils/forms";
import Authorization, {hasPrivileges} from "./Authorization";
import Form from "./ReduxFormControls";
import MessageBox from "./MessageBox";
import SemanticCheckboxList from "./ReduxFormControlsComponents/SemanticCheckboxList";
import SemanticSelect from "./ReduxFormControlsComponents/SemanticSelect";
import {convertToInt, readNested} from "../../../utils/helpers";

import * as VARIABLES from "../../app/constants/variables";
import {FORM_ERROR, FORM_OPTIONS_QUERY_ERROR} from "../constants/messages";
import {
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_UPDATE,
    SECURITY_PRIVILEGES_DELETE
} from "../constants/variables";

/**
 * Class with default form behaviour
 */
export class DefaultForm extends React.Component {
    static propTypes = {
        formData: PropTypes.object,
        formValues: PropTypes.object,
        client: PropTypes.object,
        controlModal: PropTypes.func,
        data: PropTypes.object,
        GraphQLEntityData: PropTypes.object,
        GraphQLOptionsData: PropTypes.object,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        Model: PropTypes.object,
        DeleteEntity: PropTypes.func,
        CreateEntity: PropTypes.func,
        UpdateEntity: PropTypes.func,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        submitFailed: PropTypes.bool,
        valid: PropTypes.bool,
        onSaveCallback: PropTypes.func,
        onNotCreatedCallback:  PropTypes.func,
        onNotUpdatedCallback:  PropTypes.func,
        user: PropTypes.object
    };

    static defaultProps = {
        controlModal: () => {}
    };

    setFormMessage(message, boxName = "formInnerErrorMessage") {
        this.props.MessageBox
            .addMessage(boxName,
                null, message, "error", true);
    }

    parseModelFields(modelFields) {
        let fields = {},
            fieldsArray = Object.keys(modelFields);

        fieldsArray.forEach((index) => {
            const field = modelFields[index];

            if(_isArray(field)) {
                let parsedFieldsFromArrayToObject = {};

                field.forEach((field) => {
                    parsedFieldsFromArrayToObject = {...parsedFieldsFromArrayToObject, ...field}
                });

                fields = { ...fields, ...this.parseModelFields(parsedFieldsFromArrayToObject)}
            } else {
                fields = { ...fields, [index]: field}
            }
        });

        return fields;
    }

    /**
     * @ignore
     */
    constructor(props) {
        super(props);
        this.apolloRequests = {
            // Any running query run by .runApolloRequest()
            runningCounter: 0,
            // Running queries for "loading" state in DefaultForm/DefaultWizard
            runningLoadingFormCounter: 0
        };

        this.defaultFormInnerErrorMessageBoxRef = React.createRef();
        this.state = {
            defaultForm_formPrivileges: (props.formData) ?
                VARIABLES.SECURITY_PRIVILEGES_UPDATE : VARIABLES.SECURITY_PRIVILEGES_CREATE,
            defaultForm_data: props.formData || {},
            defaultForm_dataAfterMutation: {},
            defaultForm_disableSubmit: false,
            defaultForm_id: props.Model.formId,
            defaultForm_fields: this.parseModelFields(props.Model.fields),
            defaultForm_fieldsRender: props.Model.fields,
            defaultForm_formTitle: this.getFormTitle("init"),
            defaultForm_fallbackRoute: '/',
            defaultForm_optionParsers: {},
            defaultForm_valueParsers: {},
            defaultForm_fieldCallbacks: {},
            defaultForm_errorShowed: false,
            defaultForm_formValidationMessageBoxName: _get(props, "Model.formValidationMessageBoxName", "formInnerErrorMessage"),
            defaultForm_loading: false,
            defaultForm_callback: {
                created: null,
                updated: null,
                deleted: null,
                notCreated: null,
                notUpdated: null,
                notDeleted: null
            },
            apolloRequests: {
                /**
                 * Any running query run by .runApolloRequest()
                 */
                isRunning: false,
                /**
                 * Flag for "loading" state in DefaultForm/DefaultWizard
                 * calculate on query run by .runApolloRequest()
                 * without loadingFormFlag = false
                 */
                isRunningLoadingForm: false
            }
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.renderSaveButton = this.renderSaveButton.bind(this);
        this.renderCancelButton = this.renderCancelButton.bind(this);
        this.renderDeleteButton = this.renderDeleteButton.bind(this);
        this.prepareDataForSubmit = this.prepareDataForSubmit.bind(this);
        this.setOptionParsers = this.setOptionParsers.bind(this);
    }

    /**
     * Increment pending queries after call runApolloRequest
     * and change state for pending query
     */
    apolloRequestIncrement = () => {
        /**
         * We can change state on pending queries (TRUE)
         * if we decrement counter to 1 (actual is 0)
         */
        if (0 === this.apolloRequests.runningCounter) {
            this.setState((prevState) => ({
                apolloRequests: {
                    ...prevState.apolloRequests,
                    isRunning: true
                }
            }));
        }

        this.apolloRequests.runningCounter++;
    }

    /**
     * Decrement pending queries after then/catch, when call runApolloRequest
     * and change state for pending query
     */
    apolloRequestDecrement = () => {
        /**
         * We can change state on pending queries (FALSE)
         * if we decrement counter to 0 (previous was 1)
         */
        if (1 === this.apolloRequests.runningCounter) {
            this.setState((prevState) => ({
                apolloRequests: {
                    ...prevState.apolloRequests,
                    isRunning: false
                }
            }));
        }

        this.apolloRequests.runningCounter--;
    }

    /**
     * Increment pending queries after call runApolloRequest and change state for loading form flag
     */
    apolloRequestIncrementLoadingForm = () => {
        if (0 === this.apolloRequests.runningLoadingFormCounter) {
            this.setState((prevState) => ({
                apolloRequests: {
                    ...prevState.apolloRequests,
                    isRunningLoadingForm: true
                }
            }));
        }

        this.apolloRequests.runningLoadingFormCounter++;
    }

    /**
     * Decrement pending queries after then/catch, when call runApolloRequest and change state for loading form flag
     */
    apolloRequestDecrementLoadingForm = () => {
        if (1 === this.apolloRequests.runningLoadingFormCounter) {
            this.setState((prevState) => ({
                apolloRequests: {
                    ...prevState.apolloRequests,
                    isRunningLoadingForm: false
                }
            }));
        }

        this.apolloRequests.runningLoadingFormCounter--;
    }

    /**
     * Override default this.props.client for run:
     * - this.props.client.query
     * - this.props.client.mutate
     * with increment/decrement pending queries state
     */
    runApolloRequest = (method, params = {}, options = { loadingFormFlag: true }) => {
        const requestTypes = ['query', 'mutate'];

        if (requestTypes.includes(method)) {
            this.apolloRequestIncrement();

            /**
             * We can exclude query from calculate loading flag in the form
             */
            if (options.loadingFormFlag) {
                this.apolloRequestIncrementLoadingForm();
            }

            return this.props.client[method](params)
                .then((response) => {
                    this.apolloRequestDecrement();

                    if (options.loadingFormFlag) {
                        this.apolloRequestDecrementLoadingForm();
                    }

                    return response;
                }).catch((error) => {
                    this.apolloRequestDecrement();

                    throw error;
                }).finally(() => {
                    return () => {}
                });
        }

        throw new Error('Bad method type');
    }

    /**
     * @ignore
     *
     */
    componentWillMount() {
        this.setDefaultFormTitle();
    }

    componentDidMount() {
        this.setDefaultFormTitle();
    }

    setDefaultFormTitle = () => {
        let title = this.props.Model.title || this.setAddFormTitle(this.props.Model.entityLabel);

        if (this.state.defaultForm_data && _has(this.state.defaultForm_data, 'variables.id')) {
            title = this.getFormTitle("edit")
        } else if (_isFunction(this.props.Model.title)) {
            title = this.props.Model.title(_get(this.props, "formData", {}), "edit", this.props)
        } else if (this.props.Model.title === null) {
            title = null
        }

        this.setState(() => ({
            defaultForm_formTitle: title
        }));
    }

    /**
     * @ignore
     */
    getFormTitle = (type) => {
        let formData = _get(this.state, "defaultForm_data", {});

        if (!_get(this.props, "formParams.dataRequest", false)) {
            formData = _get(this.props, "formData", {});
        }

        if (this.props.Model.title) {
            if (_isFunction(this.props.Model.title)) {
                return this.props.Model.title(formData, type, this.props)
            } else {
                return this.props.Model.title
            }
        }

        if (formData[this.props.Model.entityDataMapKey]) {
            return this.getEditFormTitle(
                this.props.Model.entityLabel,
                formData[this.props.Model.entityDataMapKey]
            );
        }

        return "";
    };

    receivedPropsValidator(prevProps)  {
        if (this.props.GraphQLEntityData !== prevProps.GraphQLEntityData) {
            const entityData = _get(this.props.GraphQLEntityData, this.props.Model.entityDataMapKey, {});

            if (entityData) {
                this.setState(() => ({
                    defaultForm_formTitle: this.getFormTitle("edit"),
                    defaultForm_data: this.props.GraphQLEntityData,
                    defaultForm_formPrivileges: VARIABLES.SECURITY_PRIVILEGES_UPDATE
                }));
            }

            if (this.props.GraphQLEntityData.error !== undefined) {
                this.renderErrors(this.props.GraphQLEntityData.error);
            }
        }

        if (!this.props.valid && this.props.submitFailed && !this.state.defaultForm_errorShowed && this.props.Model.showValidationError !== false) {
            const label = _get(this.props, "Model.label", _get(this.props, "Model.formName", "")).toLocaleLowerCase(),
                errorMessage = this.props.Model.messages.text.FORM_ERROR
                    ? this.props.Model.messages.text.FORM_ERROR(label)
                    : FORM_ERROR(label);

            this.setFormMessage(errorMessage, this.state.defaultForm_formValidationMessageBoxName)
            this.scrollToError();

            this.setState(() => ({
                defaultForm_errorShowed: true
            }))
        } else if (this.props.submitting !== prevProps.submitting) {
            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
        }

        if (this.props.GraphQLOptionsData && !_isEqual(this.props.GraphQLOptionsData, prevProps.GraphQLOptionsData)) {
            const fields = Object.assign({}, this.state.defaultForm_fields);

            if (this.props.GraphQLOptionsData.error) {
                this.setFormMessage(FORM_OPTIONS_QUERY_ERROR, this.state.defaultForm_formValidationMessageBoxName);
            }

            for (const key in fields) {
                let option = fields[key];

                if (option.optionsKey && (!option.props.options || option.props.options.length === 0)) {
                    option.props.options =
                        this.parseOptions(option.props.name, this.props.GraphQLOptionsData[option.optionsKey])
                }
            }

            this.setState(() => ({
                defaultForm_fields: fields
            }))
        }
    }

    componentDidUpdate() {
        this.modalLoadingControl();
    }

    /**
     * @ignore
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.GraphQLEntityData) {
            const entityData = _get(nextProps.GraphQLEntityData, nextProps.Model.entityDataMapKey, {});

            if (entityData) {
                this.setState(() => ({
                    defaultForm_formTitle: this.getFormTitle("edit"),
                    defaultForm_data: nextProps.GraphQLEntityData,
                    defaultForm_formPrivileges: VARIABLES.SECURITY_PRIVILEGES_UPDATE
                }));
            }

            if (nextProps.GraphQLEntityData.error !== undefined) {
                this.renderErrors(nextProps.GraphQLEntityData.error);
            }
        }

        if (!nextProps.valid && nextProps.submitFailed && !this.state.defaultForm_errorShowed && nextProps.Model.showValidationError !== false) {
            const label = _get(nextProps, "Model.label", _get(nextProps, "Model.formName", "")).toLocaleLowerCase(),
                errorMessage = nextProps.Model.messages.text.FORM_ERROR
                    ? nextProps.Model.messages.text.FORM_ERROR(label)
                    : FORM_ERROR(label);

            this.setFormMessage(errorMessage, this.state.defaultForm_formValidationMessageBoxName)
            this.scrollToError();

            this.setState(() => ({
                defaultForm_errorShowed: true
            }))
        } else if (nextProps.submitting) {
            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
        }

        if (nextProps.GraphQLOptionsData && !_isEqual(this.props.GraphQLOptionsData, nextProps.GraphQLOptionsData)) {
            const fields = Object.assign({}, this.state.defaultForm_fields);

            if (nextProps.GraphQLOptionsData.error) {
                this.setFormMessage(FORM_OPTIONS_QUERY_ERROR, this.state.defaultForm_formValidationMessageBoxName);
            }

            for (const key in fields) {
                let option = fields[key];

                if (option.optionsKey && (!option.props.options || option.props.options.length === 0)) {
                    option.props.options =
                        this.parseOptions(option.props.name, nextProps.GraphQLOptionsData[option.optionsKey])
                }
            }

            this.setState(() => ({
                defaultForm_fields: fields
            }))
        }
    }

    /**
     *
     * @param data - Data from form submit
     * @returns object - Data from form under key that is set in entityDataMapKey in model,
     */
    prepareDataForSubmit(data) {
        const dataToSave = Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);

        return { [this.props.Model.entityDataMapKey] : dataToSave }
    }

    /**
     * @ignore
     */
    setAddFormTitle = (label) => `Add new ${label}`;

    /**
     * @ignore
     */
    getEditFormTitle = (label, data) => {
        if (this.props.Model.title === null) {
            return null
        }

        return `${label.capitalizeFirstLetter()} ${data && data.name || ''}`;
    };

    /**
     * Method used for set callback when create entity with success
     * @param func
     */
    setCreateSuccessCallback = (func) => this.setState((state) => ({ defaultForm_callback: Object.assign({}, state.defaultForm_callback, { created: func }) }));

    /**
     * Method used for set callback when create entity throws error
     * @param func
     */
    setCreateErrorCallback = (func) => this.setState((state) => ({ defaultForm_callback: Object.assign({}, state.defaultForm_callback, { notCreated: func }) }));

    /**
     * Method used for set callback when update entity with success
     * @param func
     */
    setUpdateSuccessCallback = (func) => this.setState((state) => ({ defaultForm_callback: Object.assign({}, state.defaultForm_callback, { updated: func }) }));

    /**
     * Method used for set callback when update entity throws error
     * @param func
     */

    setUpdateErrorCallback = (func) => this.setState((state) => ({ defaultForm_callback: Object.assign({}, state.defaultForm_callback, { notUpdated: func }) }));

    /**
     * Method used for set callback when delete entity with success
     * @param func
     */
    setDeleteSuccessCallback = (func) => this.setState((state) => ({ defaultForm_callback: Object.assign({}, state.defaultForm_callback, { deleted: func }) }));

    /**
     * Method used for set callback when delete entity throws error
     * @param func
     */
    setDeleteErrorCallback = (func) => this.setState((state) => ({ defaultForm_callback: Object.assign({}, state.defaultForm_callback, { notDeleted: func }) }));

    /**
     * Method for setting fallback route in case of error
     */
    setFallbackRoute = (path) => this.setState(() => ({ defaultForm_fallbackRoute: path }));

    /**
     * Method for set parsers for options in dropdown fields.
     * @param {Object} object Object with fields and functions for each field. [data] param passed to user method
     * have all data for field.  See example below
     *
     * @example {
      *     [field_name]: (data) => {
      *
      *         //parse data, change values etc.
      *
      *         return [ {key: [String], id: [String], text: [String]}, ... ]
      *     }
      * }
     */
    setOptionParsers = (object) => this.setState(() => ({
        defaultForm_optionParsers: {...object}
    }));

    /**
     * @ignore
     */
    parseOptions = (key, data) => {
        if (key && data && this.state.defaultForm_optionParsers[key]) {
            return this.state.defaultForm_optionParsers[key](data)
        }

        if (!data) {
            return []
        }

        return data
    };

    /**
     * Method for setting callback on onChange action
     *
     * @param {Object} callbacksObject Object with fields and functions for each field. [data] param passed to user method
     * have all data for changed field. See example below
     * @example {
      *     [field_name]: (data) => {
      *
      *         // some action after field changed
      *         // call this.setField for dynamic forms ;)
      *     }
      * }
     */
    setOnChangeCallback = (callbacksObject) => {
        this.setState(() => (Object.assign(this.state.defaultForm_fieldCallbacks, callbacksObject)))
    };

    /**
     * @ignore
     */
    onChangeCallback = (event, data) => {
        if (data && (data.type === "toggle" || data.type === "checkbox") && data.input) {
            data = data.input
        }

        if (data && (typeof data !== "object") && event.currentTarget) {
            data = Object.assign({}, {
                value: data,
                name: event.currentTarget.name
            });
        }

        if (data && this.state.defaultForm_fieldCallbacks[data.name]) {
            this.state.defaultForm_fieldCallbacks[data.name](data)
        }
    };

    /**
     * Method used to parse values that are set for form field ( value prop )
     *
     * @param {Object} parsersObject Object with fields and functions for each field that will parse the value.
     * Param passed to user method will be value of the field. See example.
     *
     * @example {
     *      color: (value) => {
     *          // value from database without #
     *
     *          // value passed to field will have be leaded by # ( needed for color input type )
     *          return "#" + value
     *      }
     * }
     */
    setValuesParsers = (parsersObject) => {
        this.setState(() => ({defaultForm_valueParsers: parsersObject}))
    };

    /**
     * Method that can be called to set field props. Used with onChangeCallback gives dynamic forms ;)
     *
     * @param {String | Array} id Name or array with names of the field/s
     * @param {Object} fieldProps Props that will be passed to the field.
     * @param {function} callback that will be executed after setState.
     *
     * @example
     * this.setField("content_category",
     *  {
     *    disabled: false,
     *    value: null,
     *    options: this.state.contentCategoriesOptions[data.value]
     *   },
     *   callbackFunction
     * )
     *
     */
    setField = (id, fieldProps, callback = null) => {
        const fields = this.state.defaultForm_fields;

        let ids = [];

        if (_isArray(id)) {
            ids = id
        } else {
            ids.push(id)
        }

        for(const key in fields) {
            const field = this.state.defaultForm_fields[key];

            for (let i = 0; i < ids.length; i++) {
                if (field.props.name === ids[i]) {
                    const defaultProps = field.props;

                    field.props = {...defaultProps, ...fieldProps}

                    //pass timestamp for datepicker to refresh date that come in prop
                    if (field.props.type == "date" && field.props.selected) {
                        field.props.timestamp = Date.now()
                    }

                    if ((undefined !== fieldProps.defaultValue)
                        && (Form.SemanticSelect == field.props.component)) {
                        field.props.timestamp = Date.now()
                    }
                }

            }
        }

        callback && callback(fields);

        this.setState(() => ({defaultForm_fields: fields}));
    };

    setFieldFocus = (id) => {
        let ref = this.state.refs[id];

        if (ref.current) {
            ref.current.focus();
        }
    };

    resetFieldToDefault = (id) => {
        this.setField(id, {timestamp: Date.now()});
    };

    /**
     * @ignore
     */
    onFormSubmit(data = {}) {
        const dataToSave = this.prepareDataForSubmit(data);

        this.setState(() => ({
            defaultForm_errorShowed: false
        }));

        const callbacks = {
            created: (data) => {
                this.state.defaultForm_callback.created && this.state.defaultForm_callback.created(data);
                this.props.onSaveCallback && this.props.onSaveCallback(data);
            },
            updated: (data) => {
                this.state.defaultForm_callback.updated && this.state.defaultForm_callback.updated(data);
                this.props.onSaveCallback && this.props.onSaveCallback(data);
            },
            notCreated: this.state.defaultForm_callback.notCreated ? (data) => {
                this.state.defaultForm_callback.notCreated(data);
                this.props.onNotCreatedCallback && this.props.onNotCreatedCallback(data);
                this.scrollToError();
            } : null,
            notUpdated: this.state.defaultForm_callback.notUpdated ? (data) => {
                this.state.defaultForm_callback.notUpdated(data);
                this.props.onNotUpdatedCallback && this.props.onNotUpdatedCallback(data);
                this.scrollToError();
            } : null
        };

        return formUtils.onSubmit({
            dataToSave,
            actions: {
                create: {
                    mutation: _isFunction(this.props.Model.mutation.createEntity)
                        ? this.props.Model.mutation.createEntity(dataToSave)
                        : this.props.CreateEntity,
                    options: _get(this.props, "Model.mutationOptions.create", {})
                },
                update: {
                    mutation: this.props.UpdateEntity,
                    options: _get(this.props, "Model.mutationOptions.update", {})
                }
            },
            message: {
                modal: this.props.Modal,
                box: this.props.MessageBox,
                boxName: this.props.Model.messages.boxName,
                text: this.props.Model.messages.text,
                entityName: dataToSave.name || "",
                entityLabel: this.props.Model.entityLabel,
                description: this.props.Model.messages.description
            },
            callback: {
                created: callbacks.created,
                updated: callbacks.updated,
                notCreated: callbacks.notCreated,
                notUpdated: callbacks.notUpdated
            },
            apolloClient: this.props.client,
            props: this.props
        });
    }

    /**
     * @ignore
     */
    deleteEntity = () => {
        const callbacks = {
            deleted: this.state.defaultForm_callback.deleted ? (data) => {
                this.state.defaultForm_callback.deleted(data);
            } : null,
            notDeleted: this.state.defaultForm_callback.notDeleted ? (data) => {
                this.state.defaultForm_callback.notDeleted(data);
            } : null
        };

        let entityData = this.getData(),
            deleteId = entityData.variables.id;

        if (this.props.Model.deleteId) {
            if (_isFunction(this.props.Model.deleteId)) {
                deleteId = this.props.Model.deleteId(entityData);
            } else {
                deleteId = readNested(entityData, this.props.Model.deleteId)
            }
        }

        return formUtils.onDelete({
            id: parseInt(deleteId, 10),
            action: {
                delete: {
                    mutation: this.props.DeleteEntity,
                    options: _get(this.props, "Model.mutationOptions.delete", {})
                }
            },
            modal: this.props.Modal,
            props: this.props,
            message: {
                box: this.props.MessageBox,
                boxName: this.props.Model.messages.boxName,
                text: this.props.Model.messages.text,
                entityName: this.getEntityName() || this.state.entityName,
                entityLabel: this.props.Model.entityLabel,
                description: this.props.Model.messages.description
            },
            callback: {
                deleted: callbacks.deleted,
                notDeleted: callbacks.notDeleted
            }
        });
    };

    getMessagesText = (key = null) => {
        if (!key) {
            return "";
        }

        const text = _get(this.props, `Model.messages.${key}`);

        if (typeof(text) === "function") {
            return text(this.props)
        }

        return text
    };

    /**
     * @ignore
     */
    deleteButtonClick = (e) => {
        e.preventDefault();

        let headerText = this.props.Model.messages.text.DELETE_HEADER_ARGUMENT_FIRST(
            this.props.Model.entityLabel,
            this.state.entityName);

        if (_has(this.props, "Model.messages.delete.header", false)) {
            headerText = this.getMessagesText("delete.header");
        }

        let confirmationText = this.props.Model.messages.text.DELETE_CONFIRMATION(
            this.props.Model.entityLabel,
            this.getEntityName() || this.state.entityName);

        if (_has(this.props, "Model.messages.delete.confirmation", false)) {
            confirmationText = this.getMessagesText("delete.confirmation");
        }

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={headerText}/>,
            text: confirmationText,
            size: 'tiny',
            onYes: this.deleteEntity
        });
    };

    /**
     * @ignore
     */
    closeModal = () => {
        this.props.Modal.setModal({
            isVisible: false,
            content: null,
            header: null,
        })
    };

    getHeaderForErrorModal() {
        return _get(this.props, "Model.entityLabel", "").capitalizeFirstLetter();
    }

    /**
     * @ignore
     */
    renderErrors(errorData, label = null, link = null, modalProps = {}) {
        let content;

        if ("string" === typeof(errorData)) {
            content = errorData
        } else {
            const errors = errorData.graphQLErrors;

            content = (
                1 === errors.length
                && (
                    404 === errors[0].code
                    || `${this.getHeaderForErrorModal()} not found.` === errors[0].message
                )
            )
                ? null
                : errors.map((e, i) => <p key={i}>{`${e.message}`}</p>)
        }

        const errorModalLabel = label || this.getHeaderForErrorModal(),
            errorModalLink = link || this.state.defaultForm_fallbackRoute;

        this.props.Modal.setModal({
            content: formUtils.renderModalError(
                errorModalLabel,
                errorModalLink,
                content
            ),
            isVisible: true,
            ...modalProps
        });
    }

    /**
     * @ignore
     */
    getFieldCallback = (field) => {
        const callbackForField = this.state.defaultForm_fieldCallbacks[field.props.name],
            props = {};

        if (!callbackForField) {
            return props
        }

        if ((Form.SemanticSelect == field.props.component) && (undefined === field.props.value)) {
            props.value = field.props.value
        }

        if (((Form.SemanticInput == field.props.component)
            && ("checkbox" === field.props.type || "toggle" === field.props.type))
            || (Form.SemanticCheckboxList == field.props.component))
        {
            props.onCheckboxChange = this.onChangeCallback
        } else if ((Form.SemanticInput == field.props.component) && (!field.props.type || ("input" === field.props.type))) {
            props.onChange = this.onChangeCallback
        } else if ((Form.SemanticInput == field.props.component) && ("multiple" === field.props.type)) {
            props.onChangeMultipleValue = this.onChangeCallback
        } else if ((Form.SemanticSelect == field.props.component) || ("select" === field.props.type)) {
            props.onChangeSelect = this.onChangeCallback
        } else if ((Form.SemanticInput == field.props.component) && ("date" === field.props.type)) {
            props.onChangeDate = this.onChangeCallback
        } else {
            props.onChangeCallback = this.onChangeCallback;
        }

        return props
    };

    /**
     * @ignore
     */
    checkAuthorization = (element, privileges) => {
        if (this.props.Model.authorization) {
            return (<Authorization
                authorization={this.props.Model.authorization}
            >
                {element}
            </Authorization>);
        }

        if (this.props.Model.resources) {
            return (<Authorization
                resources={this.props.Model.resources}
                privileges={privileges}
            >
                {element}
            </Authorization>);
        } else {
            return element
        }
    };

    /**
     * @ignore
     */
    getData() {
        let entityData = {};

        if (this.props.formData && this.props.formData[this.props.Model.entityDataMapKey]) {
            entityData = this.props.formData;
        } else {
            entityData = this.props.GraphQLEntityData || null;
        }

        return entityData
    }

    /**
     * @ignore
     */
    getEntityName = () => {
        if (this.props.formData && this.props.formData[this.props.Model.entityDataMapKey]) {
            return this.props.formData[this.props.Model.entityDataMapKey].name || "";
        }

        return _get(this.props, `GraphQLEntityData.${this.props.Model.entityDataMapKey}.name`, "");
    };

    /**
     * Method for rendering additional buttons before save button
     * Dummy method that can be overwritted
     *
     * @param component
     * @returns {*}
     */
    renderAdditionalButtons(component = null) {
        return component;
    }

    /**
     * Method for rendering additional buttons after save button
     * Dummy method that can be overwritten
     *
     * @param component
     * @returns {*}
     */
    renderAdditionalButtonsAfterSave(component = null) {
        return component;
    }

    renderArchiveButton = () => {
        return null;
    }

    /**
     * Method for rendering save button.
     * If button properties need to be changed method can be overwritten and new props can be passed in param.
     *
     * @param {Object} props Object with props for button component
     * @name renderSaveButton
     * @example
     *
     * renderSaveButton = () => {
     *      // default button behaviour with changed props
     *      return super.renderSaveButton({
     *          color: "red"
     *      })
     *
     *      //new button ( needs all logic no default behaviour )
     *      return <Button .../>
     * }
     */
    renderSaveButton(props = {}) {
        let content = "Create";

        if (this.state.defaultForm_data && _has(this.state.defaultForm_data, 'variables.id')) {
            content = "Save"
        }

        const defaultProps = {
            color: "blue",
            content: content,
            disabled: this.props.submitting || this.state.defaultForm_disableSubmit,
            icon: "save",
            loading: this.props.submitting,
            type: "submit",
            hidden: false
        };

        if (!props.hidden) {
            return <Button
                { ...defaultProps}
                { ...props }
            />;
        }
    }

    /**
     * Method for rendering cancel button. See renderSaveButton for more information.
     *
     * @param {Object} props
     */
    renderCancelButton(props = {}) {
        const defaultProps = {
            disabled: this.props.submitting,
            onClick: this.closeModal,
            content: "Cancel",
            icon: "cancel"
        };

        return <Button
            {...defaultProps}
            {...props}
        />
    }

    /**
     * Method for rendering delete button. See renderSaveButton for more information.
     * @param {Object} props
     *
     */
    renderDeleteButton(props = {}) {
        const entityData = this.getData();

        const defaultProps = {
            onClick: this.deleteButtonClick,
            color: "red",
            content: "Delete",
            disabled: this.props.submitting,
            icon: "trash"
        };

        if ((( entityData || {}).variables || {}).id !== undefined) {
            return this.checkAuthorization(
                <Button
                    {...defaultProps}
                    {...props}
                />,
                VARIABLES.SECURITY_PRIVILEGES_DELETE)
        }

        return null
    }

    scrollToError = () => {
        if (this.defaultFormInnerErrorMessageBoxRef.current) {
            this.defaultFormInnerErrorMessageBoxRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    };

    prepareFieldProps = (field, entityData) => {
        const hasAutoFocus = (this.props.Model.autoFocusOnField && this.props.Model.autoFocusOnField === field.props.name)
            ? true
            : false;

        let value = field.defaultValue,
            optionsObject = {options: []},
            getOptionsFromProps = true,
            displayName = null;

        if (entityData) {
            value = entityData
                ? readNested(entityData, field.dataMapKey)
                : readNested(this.props.Model.dataMap, field.dataMapKey);
            displayName = _get(field, 'props.component.displayName', null);

            if ((SemanticSelect.displayName === displayName)
                && (!field.props.options || 0 ===  field.props.options.length)
                && (null !== value && _isObject(value))
            ) {
                optionsObject.options = [{
                    id: value.id,
                    key: value.id,
                    value: value.id,
                    text: value.name
                }];
                value = value.id;
                getOptionsFromProps = false;
            } else if ((SemanticCheckboxList.displayName === displayName) && field.dataMapKey) {
                // Check checkbox on the checkboxList field, based on dataMapKey
                // if options contains "id" key (number or string)
                const optionsValuesChecked = readNested(entityData, field.dataMapKey),
                    firstListOptionType = (typeof _get(field, 'props.options.0.id', null));

                if (_isArray(optionsValuesChecked) && optionsValuesChecked.length) {
                    if (['string', 'number'].includes(firstListOptionType)) {
                        value = optionsValuesChecked.map((selectedValue) => (
                            convertToInt(selectedValue.id)
                        ))
                    }
                }
            }

            if (value === null && field.defaultValue !== undefined) {
                value = field.defaultValue
            }
        }

        if (this.state.defaultForm_valueParsers[field.props.name]) {
            value = this.state.defaultForm_valueParsers[field.props.name](value)
        }

        optionsObject = (field.props.options && getOptionsFromProps) ? {options : field.props.options} : optionsObject;

        const fieldSpecificProps = this.getFieldCallback(field);
        let fieldDefaultValue = {defaultValue: value};

        if (field.props.type === VARIABLES.FIELD_TYPE_MULTIPLE && _isArray(value)) {
            fieldDefaultValue = {defaultValueMultiple: value};
        }

        return {
            fieldDefaultValue,
            fieldSpecificProps,
            options: {
                ...optionsObject,
                hasAutoFocus,
            }
        }
    };

    /**
     * Set modal "loading" flag, to allow/prevent close by ESC
     * by shared controlModal(params = {}) method (if exist)
     */
    modalLoadingControl = () => {
        const isRequestLoading = this.getIsRequestLoading();

        this.props.controlModal && this.props.controlModal({
            loading: isRequestLoading
        });
    }

    /**
     * Check if exist any pending query in DefaultForm defined by model
     * or received from props
     * @returns {boolean}
     */
    getIsLoading = () => {
        return (undefined !== this.props.data && this.props.data.loading)
            || (this.state.defaultForm_loading)
            || (undefined !== this.props.GraphQLEntityData && this.props.GraphQLEntityData.loading)
            || (undefined !== this.props.GraphQLOptionsData && this.props.GraphQLOptionsData.loading);
    }

    /**
     * Check if exist any pending query in DefaultForm/DefaultWizard defined by model
     * OR by runApolloRequest helper method
     * for block closing by ESCAPE
     * @returns {boolean}
     */
    getIsRequestLoading = () => (
        this.getIsLoading() || this.state.apolloRequests.isRunning
    )

    /**
     * Check if exist any pending query in DefaultForm/DefaultWizard defined by model
     * OR by runApolloRequest (without exclude query to loading flag)
     * for display loading status
     * @returns {boolean}
     */
    getIsFormLoading = () => (
        this.getIsLoading() || this.state.apolloRequests.isRunningLoadingForm
    )

    customAuthorization = () => {
        return null;
    }

    /**
     * @ignore
     */
    render() {
        const isFormLoading = this.getIsFormLoading(),
            userResourcePrivilages = this.props.user.privileges[this.props.Model.resources];

        let isDeleteAuthorized = (userResourcePrivilages & SECURITY_PRIVILEGES_DELETE) > 0;
        let entityData = this.getData(),
            isAuthorized = (userResourcePrivilages & SECURITY_PRIVILEGES_CREATE) > 0,
            isArchiveButtonAuthorized = (userResourcePrivilages & SECURITY_PRIVILEGES_UPDATE) > 0;

        if (_has(this.state.defaultForm_data, 'variables.id')) {
            isAuthorized = (userResourcePrivilages & SECURITY_PRIVILEGES_UPDATE) > 0
        }

        if (!this.props.Model.resources) {
            isAuthorized = true;
            isDeleteAuthorized = true;
        }

        if (this.props.Model.authorization) {
            isAuthorized = hasPrivileges(this.props.Model.authorization);
            isDeleteAuthorized = isAuthorized;
        }

        const customAuthorization = this.customAuthorization();

        if (null !== customAuthorization) {
            isAuthorized = this.customAuthorization(userResourcePrivilages);
        }

        return (
            <div ref={this.defaultFormInnerErrorMessageBoxRef}>
                {this.state.defaultForm_formTitle !== null ? <Header>{this.state.defaultForm_formTitle}</Header> : null}
                <MessageBox name={this.state.defaultForm_formValidationMessageBoxName} className="--formInnerError"/>
                <Form.Create
                    onSubmit={isAuthorized && this.props.handleSubmit(this.onFormSubmit) }
                    loading={isFormLoading}
                    className={(this.props.Model.formName || "") + (!isAuthorized ? " notAuthorized" : "")}
                >
                    {Object.values(this.state.defaultForm_fieldsRender).map((defaultForm_field, i) => {
                        let field = defaultForm_field;
                        let Field = field.component || Form.FormRow;
                        let fieldProps = {};

                        if(_isArray(defaultForm_field)) {
                            Field = Form.FormMultipleRow;
                            let multipleData = [];

                            defaultForm_field.forEach((field, index) => {
                                const fieldData = Object.values(field)[0];

                                multipleData.push({
                                    ...this.prepareFieldProps(fieldData, entityData),
                                    props: fieldData.props,
                                    data: entityData,
                                    key: `${i}-${index}`,
                                    isAuthorized: isAuthorized,
                                })
                            });

                            return (
                                <Field
                                    key={i}
                                    multipleData = {multipleData}
                                />
                            );

                        } else {
                            let authorizationProps = {};

                            if (!isAuthorized) {
                                authorizationProps = {
                                    readOnly: true,
                                    disabled: true,
                                };
                            }

                            if (Form.FileUpload === field.props.component) {
                                return (
                                    <Field
                                        key={`render_file_${field.props.name}`}
                                        {...field.props}
                                        {...authorizationProps}
                                    />
                                );
                            }

                            fieldProps = this.prepareFieldProps(field, entityData);

                            return (
                                <Field
                                    data={entityData}
                                    formValues={this.props.formValues}
                                    key={i}
                                    {...fieldProps.fieldDefaultValue}
                                    {...field.props}
                                    {...fieldProps.options}
                                    {...fieldProps.fieldSpecificProps}
                                    {...authorizationProps}
                                />
                            );
                        }
                    })}
                    {(this.props.Model.showChangeLog !== false
                        && entityData
                        && _get(entityData, `${this.props.Model.changelogDataMapKey ? `${this.props.Model.entityDataMapKey}.${this.props.Model.changelogDataMapKey}` : this.props.Model.entityDataMapKey}`, {}))
                    && <Form.FormRowChangelog
                        resources={this.props.Model.resources}
                        data={
                            _get(entityData,
                                `${this.props.Model.changelogDataMapKey 
                                    ? `${this.props.Model.entityDataMapKey}.${this.props.Model.changelogDataMapKey}` 
                                    : this.props.Model.entityDataMapKey}`, {})}
                    />}
                    <Segment className="formRow form__footer" loading={this.state.defaultFormButtons_loading}>
                        {(this.props.Model.renderLabels === undefined || this.props.Model.renderLabels) ? <label/> : null}
                        {this.renderAdditionalButtons()}
                        {isAuthorized && this.renderSaveButton()}
                        {isArchiveButtonAuthorized && this.renderArchiveButton()}
                        {this.renderAdditionalButtonsAfterSave()}
                        {isDeleteAuthorized && this.renderDeleteButton()}
                        {this.renderCancelButton()}
                    </Segment>
                </Form.Create>
            </div>
        );
    }
}

export default DefaultForm
