import {validate as clientProductValidate} from './validatorClientProduct';
import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, clientProductValidate(values));

    Object.assign(errors, validators.required(
        values.lcr_client_types,
        'lcr_client_types',
        'Choose at least one type'
    ));

    return errors;
};

export default validate;
