import PropTypes from "prop-types";
import React from "react";
import {Header} from "semantic-ui-react";

import Table from "../../app/components/Table";

const columns = {
    "message": {
        label: "Message",
        sorting: true
    },
    "creation_datetime": {
        label: "Time",
        sorting: true,
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
    "creation_user": {
        label: "User",
        sorting: (row) => (null === row.creation_user) ? "" : row.creation_user.name
    }
};

const MasterEventAlertsTable = (props) => {
    const {alerts} = props,
        rowRenderer = (column, row) => {
            switch (column) {
                case "message":
                    return <div className="messageText">{row.message}</div>;
                case "creation_datetime" :
                    return <span style={{whiteSpace: "nowrap"}}>{row.creation_datetime}</span>;
                case "creation_user" :
                    return row.creation_user ? <span style={{whiteSpace: "nowrap"}}>{row.creation_user.name}</span> : "";
                default:
                    return row[column];
            }
        };

    if (0 < alerts.length || props.loading) {
        return (
            <div className="eventContentAlerts__table">
                <Header content="Previous notifications sent"/>
                <Table
                    name="masterEventAlerts"
                    columns={columns}
                    data={alerts}
                    rowRenderer={rowRenderer}
                    pagination={false}
                    size="small"
                    loading={props.loading}
                />
            </div>
        )
    }

    return null;
};

MasterEventAlertsTable.propTypes = {
    alerts: PropTypes.array,
    loading: PropTypes.bool
};

MasterEventAlertsTable.defaultProps = {
    alerts: [],
    loading: false
};

export default MasterEventAlertsTable;
