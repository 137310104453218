
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VenueDataForTable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Venue"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"capacity"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"city"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VenueDataForForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Venue"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VenueDataForTable"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChangelogData"},"directives":[]}]}}],"loc":{"start":0,"end":226}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment VenueDataForTable on Venue {\n    ...BasicData\n    capacity\n    city {\n        ...BasicData\n    }\n}\n\nfragment VenueDataForForm on Venue {\n    ...VenueDataForTable\n    ...ChangelogData\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
