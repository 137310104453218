import PropTypes from "prop-types";
import {get as _get} from 'lodash';

import {convertToInt} from "./helpers";

/**
 * Add key _hasMatchID to object structure with information about event
 * and existing match_id in event
 *
 * @param {Object} event
 *
 * @returns {Object}
 */
export const addHasMatchIdToStructure = (event) => {
    return Object.assign({}, event, {
        _hasMatchID: Number.isInteger(convertToInt(_get(event, 'sr_event.match.id', {})))
    });
};

addHasMatchIdToStructure.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string
            })
        })
    }).isRequired
};

addHasMatchIdToStructure.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null
            }
        }
    }
}
