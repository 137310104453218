import {setErrorModal} from '@utils/modal';

export const showErrorNoBookingsSelected = (isCancel = false) => {
    setErrorModal({
        header: isCancel ? 'Cancel bookings' : 'Invalid selection to modify bookings',
        text: 'There are no bookings selected. Please select at least one booking.',
        isVisible: true,
        size: 'large',
    });
};

export const showErrorDuringBulkChange = () => {
    setErrorModal({
        header: 'Unexpected error',
        text: 'Error while fetching bookings for bulk change.',
        isVisible: true,
        size: 'large',
    });
};

export const showErrorTooManyBookingsSelected = () => {
    setErrorModal({
        header: 'URI too large',
        text: 'Too many bookings selected.',
        isVisible: true,
        size: 'large',
    });
};

export const showErrorDuringBulkCancel = () => {
    setErrorModal({
        header: 'Unexpected error',
        text: 'Error while fetching bookings for bulk cancel.',
        isVisible: true,
        size: 'large',
    });
};

export const showErrorSomeBookingIdsAreInvalid = () => {
    setErrorModal({
        header: 'Cancel bookings',
        text: 'The selection of bookings to be cancelled is not valid. At least one of them is not part of the table in the background.',
        isVisible: true,
        size: 'large',
    });
};
