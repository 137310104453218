import {GetEventGeoRestrictionForForm} from '@graphql/eventGeoRestriction/query';
import {UpdateEventGeoRestriction, DeleteEventGeoRestriction} from '@graphql/eventGeoRestriction/mutation';

import EventGeoRestrictionsFormBlackoutZonesModel from './EventGeoRestrictionsFormBlackoutZonesModel';

export default function EventGeoRestrictionsFormBlackoutZonesEditModel() {
    const eventGeoRestrictionsFormBlackoutZonesModel = EventGeoRestrictionsFormBlackoutZonesModel();

    eventGeoRestrictionsFormBlackoutZonesModel.editForm = true;
    eventGeoRestrictionsFormBlackoutZonesModel.deleteId = 'eventGeoRestriction.id';
    eventGeoRestrictionsFormBlackoutZonesModel.mutation = {
        updateEntity: UpdateEventGeoRestriction,
        deleteEntity: DeleteEventGeoRestriction,
    };
    eventGeoRestrictionsFormBlackoutZonesModel.query.getEntity = GetEventGeoRestrictionForForm;

    return eventGeoRestrictionsFormBlackoutZonesModel;
}
