import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../app/components/Table';
import {columns, rowRenderer} from '../utils/roleHelper';

const RoleTable = ({roles = [], loading = true}) => {
    return (
        <div className='roles__table'>
            <Table
                name='roleList'
                columns={columns}
                data={roles}
                loading={loading}
                noDataText='No roles found'
                rowRenderer={rowRenderer}
                defaultSort={'name'}
                pagination={false}
            />
        </div>
    );
};

RoleTable.propTypes = {
    roles: PropTypes.array,
    loading: PropTypes.bool,
};

export default RoleTable;
