import PropTypes from "prop-types";
import React from "react";

import {IconDownload} from "../../app/components/IconCollection";
import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {SLATE as RESOURCE_SLATE} from "../../app/constants/resources";

import validate from './validatorSlate';

import {
    UpdateSlate,
    CreateSlate,
    DeleteSlate
} from "../../../graphql/slate/mutation.graphql";
import {GetSlateForForm} from "../../../graphql/slate/query.graphql";

export default function SlatesBaseModel() {
    const renderDownloadSvg = ({formValues: {
        id = null,
        svg = null,
    }}) => {
        return (
            <div className="formRow">
                <label>Download the SVG file</label>
                <a
                    href={`data:application/octet-stream;base64,${window.btoa(svg)}`}
                    download={`slate_${id}.svg`}
                >
                    <IconDownload size="large"/>
                </a>
            </div>
        );
    };

    renderDownloadSvg.propTypes = {
        formValues: PropTypes.shape({
            id: PropTypes.string,
            svg: PropTypes.string,
        })
    };

    return {
        entityDataMapKey: "slate",
        entityLabel: "slate",
        formName: "Slate",
        label: "Slate",
        resources: RESOURCE_SLATE,
        showChangeLog: true,
        validate: validate,
        title: () => {
            return null;
        },
        dataMap: {
            slate: {
                id: null,
                tech_setup_id: null,
                name: null,
                svg: null,
                mute_when_enabled: null
            }
        },
        messages: {
            boxName: {
                delete: 'propertyLicenceMessage',
                error: 'propertyLicenceMessage',
                success: 'propertyLicenceMessage',
            },
            text: MESSAGES
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "slate.id",
                props: {
                    label: '',
                    name: "id",
                    hidden: true,
                }
            },
            tech_setup_id: {
                dataMapKey: null,
                props: {
                    component: () => (null),
                    name: "tech_setup_id",
                }
            },
            slate_name: {
                dataMapKey: "slate.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                }
            },
            slate_file: {
                props: {
                    component: Form.FileUpload,
                    extensions: ['image/svg+xml'],
                    label: "SVG",
                    name: "file",
                }
            },
            slate_preview: {
                dataMapKey: "slate.svg",
                props: {
                    className: '--topLabel',
                    component: Form.FilePreview,
                    containerClassName: 'svgPreview',
                    label: "Preview",
                    name: "svg",
                    type: "svg",
                },
            },
            slate_download: {
                props: {
                    component: renderDownloadSvg,
                    hidden: true,
                    name: "download_svg",
                },
            },
            mute_when_enabled: {
                dataMapKey: "slate.mute_when_enabled",
                props: {
                    component: Form.SemanticInput,
                    label: "Mute when enabled",
                    name: "mute_when_enabled",
                    type: "checkbox"
                },
            },
        },
        mutation: {
            updateEntity: UpdateSlate,
            createEntity: CreateSlate,
            deleteEntity: DeleteSlate,
        },
        query: {
            getEntity: GetSlateForForm,
        },
    }
}
