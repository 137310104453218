import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';
import {withApollo} from '@apollo/client/react/hoc';
import {get as _get} from 'lodash';

import {IconBan, IconCheckCircle, IconWithTooltip} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {hideModal, showModalConfirmation} from '@utils/modal';
import {GetAvapiOverlappingWhitelistedIps} from '@graphql/clientAvapiWhitelistedIp/query';

import apolloClient from '../apolloClient';

const getAvapiWhitelistingEnableSettings = (modalText) => {
    return {
        className: null,
        iconName: 'check circle outline',
        tooltip: 'Enable this CIDR.',
        modalText,
    };
};

const getAvapiWhitelistingDisableSettings = (modalText) => {
    return {
        className: 'flipped',
        iconName: 'ban',
        tooltip: 'Disable this CIDR.',
        modalText,
    };
};

// The component is used for disabling and enabling CIDRs in the client AV-API whitelisting
const AvapiWhitelistingDisableCidrIcon = ({
    clientId,
    cidrData,
    isDisabled,
    messageBoxName,
    successCallback = () => {},
    mutationChangeDisableStatus,
}) => {
    const settings = isDisabled ?
        getAvapiWhitelistingEnableSettings('Are you sure you want to enable this CIDR?')
        : getAvapiWhitelistingDisableSettings('Are you sure you want to disable this CIDR?');
    const message = `CIDR "${cidrData.cidr}"`;

    const isCidrOverlapping = async () => {
        const data = await apolloClient.query({
            query: GetAvapiOverlappingWhitelistedIps,
            variables: {
                cidr: cidrData.cidr,
            },
        });

        const overlappingAvapiIps = _get(data, 'data.overlappingAvapiWhitelistedIps', []);

        return !!(overlappingAvapiIps.length);
    };

    const onYesFunction = useCallback(async () => {
        showModalConfirmation({text: isDisabled ? `Enabling the ${message}...` : `Disabling the ${message}...`});

        if (isDisabled && await isCidrOverlapping()) {
            showMessageBox(
                messageBoxName,
                `The ${message} cannot be enabled because it is overlapping another CIDR of the client.`,
                null,
                'error'
            );
            hideModal();

            return;
        }

        try {
            await apolloClient.mutate({
                mutation: mutationChangeDisableStatus,
                variables: {
                    id: convertToInt(cidrData.id),
                    is_disabled: !isDisabled,
                    disable_datetime: isDisabled ? null : new Date().toISOString(),
                },
            });

            if (successCallback) {
                successCallback();
            }

            showMessageBox(
                messageBoxName,
                `${message} was ${isDisabled ? 'enabled' : 'disabled'} successfully.`,
                null,
                'success'
            );
            hideModal();
        } catch (error) {
            showMessageBox(
                messageBoxName,
                `The ${message} could not be ${isDisabled ? 'enabled' : 'disabled'}. ${error}`,
                null,
                'error'
            );
            hideModal();
        }
    }, [clientId, isDisabled, cidrData, messageBoxName, successCallback, mutationChangeDisableStatus]);

    return (
        <IconWithTooltip
            key={clientId}
            iconProps={{
                size: 'large',
                className: settings.className,
            }}
            popupProps={{
                position: 'top right',
            }}
            name={settings.iconName}
            onClick={() => showModalConfirmation({
                header: (
                    <Header>
                        {isDisabled ? <IconCheckCircle/> : <IconBan flipped='vertically'/>}
                        <Header.Content>{isDisabled ? 'Enable CIDR' : 'Disable CIDR'}</Header.Content>
                    </Header>
                ),
                text: settings.modalText,
                onYes: onYesFunction,
            })}
            tooltip={settings.tooltip}
        />
    );
};

AvapiWhitelistingDisableCidrIcon.propTypes = {
    clientId: PropTypes.number.isRequired,
    cidrData: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    messageBoxName: PropTypes.string,
    successCallback: PropTypes.func,
    mutationChangeDisableStatus: PropTypes.object,
};

export default withApollo(AvapiWhitelistingDisableCidrIcon);
