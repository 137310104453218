import React from "react";
import PropTypes from "prop-types";

import HeaderRenderer from "../HeaderRenderer";
import Tabs from "../Tabs";

export const Renderer = (props) => {
    return (
        <div>
            <HeaderRenderer
                buttons={props.buttons}
                buttonsProps={props.buttonsProps}
                messagesBoxNames={props.messagesBoxNames}
                pageTitle={props.pageTitle}
            />
            <Tabs
                active={props.active}
                items={props.items}
            />
        </div>
    )
};

Renderer.propTypes = {
    active: PropTypes.string.isRequired,
    buttons: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    buttonsProps: PropTypes.object,
    items: PropTypes.object.isRequired,
    messagesBoxNames: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    pageTitle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
Renderer.defaultProps = {
    messagesBoxNames: null,
    pageTitle: null,
    buttonsProps: {},
};
Renderer.displayName = 'TabsLayoutRenderer';
