import {get as _get, isEmpty as _isEmpty} from "lodash";
import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";
import {renderArchivingButton} from "../../../utils/archiving";
import {convertToInt} from "../../../utils/helpers";
import {getPreparedBasicDataForEntity} from "../utils/propertyLicenceHelper";
import {PROPERTY_LICENCE_TYPE_OTHER} from "../../app/constants/variables";
import {ToggleArchiveStatePropertyLicence} from "../../../graphql/propertyLicence/mutation.graphql";
import {GetContentCategoriesForDropdown} from "../../../graphql/contentCategory/query.graphql";
import {GetSportsForDropdown} from "../../../graphql/sport/query.graphql";

export class PropertyLicenceForm extends DefaultForm {
    LABEL_FOR_CONTENT_CATEGORY_FIELD = "1st level category";
    LABEL_FOR_SPORT_FIELD = "Sport";
    PLACEHOLDER_FOR_CONTENT_CATEGORY_FIELD = "Select category";
    PLACEHOLDER_FOR_SPORT_FIELD = "Select sport";

    constructor(props) {
        super(props);

        this.contentCategories = [];
        this.sports = [];
    }

    componentDidMount() {
        this.setFallbackRoute(getLink('propertyLicences.index'));
        this.setOnChangeCallback({
            property_licence_type: (data) => (this.handlePropertyLicenceTypeChange(convertToInt(data.value)))
        });
        this.setCreateSuccessCallback((response) => {
            this.props.Entity.setEntity(getPreparedBasicDataForEntity(response.data.createPropertyLicence));
            this.props.history.push(getLink("propertyLicences.content.index", {
                id: convertToInt(response.data.createPropertyLicence.id),
            }));
        });
        this.setUpdateSuccessCallback((response) => {
            this.props.Entity.setEntity(getPreparedBasicDataForEntity(response.data.updatePropertyLicence));
        });
        this.setDeleteSuccessCallback(() => {
            this.props.Entity.removeEntity("propertyLicence");
            this.props.history.push(getLink("propertyLicences.index"));
        });
        this.setDeleteErrorCallback(() => {
            this.props.history.push(getLink("propertyLicences.index"));
        });
    }

    componentDidUpdate() {
        const propertyLicenceData = _get(this.props.GraphQLEntityData, "propertyLicence", null);

        if (!this.state.isContentCategoryOrSportFieldGenerated && (null !== propertyLicenceData)) {
            const data = this.checkOtherType(convertToInt(propertyLicenceData.property_licence_type.id))
                ? {id: propertyLicenceData.content_category.id, name: propertyLicenceData.content_category.name}
                : {id: propertyLicenceData.sport.id, name: propertyLicenceData.sport.name};

            this.setField("content_category_or_sport", {
                disabled: true,
                label: this.getContentCategoryOrSportLabel(convertToInt(propertyLicenceData.property_licence_type.id)),
                hidden: false,
                defaultValue: data.id,
                value: data.id,
                options: [{text: data.name, value: data.id}]
            });

            this.setState(() => ({isContentCategoryOrSportFieldGenerated: true}));
        }
    }

    handlePropertyLicenceTypeChange = (propertyLicenceTypeId) => {
        if (this.checkOtherType(propertyLicenceTypeId) !== this.checkOtherType(
            convertToInt(_get(this.props, "formValues.property_licence_type", 0))
        )) {
            this.setField("content_category_or_sport", {
                defaultValue: null,
            });
        }

        if (this.checkOtherType(propertyLicenceTypeId)) {
            if (_isEmpty(this.contentCategories)) {
                this.prepareDataField(PROPERTY_LICENCE_TYPE_OTHER);
                this.props.client.query({
                    query: GetContentCategoriesForDropdown,
                    fetchPolicy: "network-only",
                    variables: {level: 1}
                }).then((response) => {
                    this.contentCategories = response.data.contentCategories;

                    this.setDataField(this.contentCategories, PROPERTY_LICENCE_TYPE_OTHER);
                });
            } else {
                this.setDataField(this.contentCategories, PROPERTY_LICENCE_TYPE_OTHER);
            }
        } else {
            if (_isEmpty(this.sports)) {
                this.prepareDataField();
                this.props.client.query({
                    query: GetSportsForDropdown,
                    fetchPolicy: "network-only",
                    variables: {}
                }).then((response) => {
                    this.sports = response.data.sports;

                    this.setDataField(this.sports);
                });
            } else {
                this.setDataField(this.sports);
            }
        }
    };

    prepareDataField = (fieldType = null) => {
        this.setField("content_category_or_sport", {
            disabled: true,
            label: this.getContentCategoryOrSportLabel(fieldType),
            loading: true,
            hidden: false,
            placeholder: this.getContentCategoryOrSportPlaceholder(fieldType),
        });
    };

    setDataField = (data, fieldType = null) => {
        this.setField("content_category_or_sport", {
            disabled: false,
            label: this.getContentCategoryOrSportLabel(fieldType),
            loading: false,
            options: data,
            placeholder: this.getContentCategoryOrSportPlaceholder(fieldType),
        });
    };

    checkOtherType = (fieldType) => (PROPERTY_LICENCE_TYPE_OTHER === fieldType);

    getContentCategoryOrSportLabel = (fieldType) => (
        this.checkOtherType(fieldType)
            ? this.LABEL_FOR_CONTENT_CATEGORY_FIELD
            : this.LABEL_FOR_SPORT_FIELD
    );

    getContentCategoryOrSportPlaceholder = (fieldType) => (
        this.checkOtherType(fieldType)
            ? this.PLACEHOLDER_FOR_CONTENT_CATEGORY_FIELD
            : this.PLACEHOLDER_FOR_SPORT_FIELD
    );

    prepareDataForSubmit = (data) => {
        const isOtherType = this.checkOtherType(convertToInt(data.property_licence_type || null));

        return Object.assign({}, data, {
            licensor: data.licensor,
            official_name: data.official_name || null,
            sport: !isOtherType
                ? data.content_category_or_sport
                : null,
            content_category: isOtherType
                ? data.content_category_or_sport
                : null,
            expiry_alert_date: data.expiry_alert_date || null,
            has_accreditation: data.has_accreditation || null,
            notes: data.notes || null,
        });
    };

    renderArchiveButton = () => (
        renderArchivingButton(
            {
                ...this.props,
                callbackLink: getLink("propertyLicences.index"),
                messageBoxName: "propertyLicenceIndexMessage"
            },
            ToggleArchiveStatePropertyLicence,
            {
                id: convertToInt(this.props.match.params.id),
                name: _get(this.props, "GraphQLEntityData.propertyLicence.name", null)
            }
        )
    );

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(PropertyLicenceForm);
