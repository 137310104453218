import {
    find as _find,
    findIndex as _findIndex,
    get as _get,
    isEmpty as _isEmpty,
    isFunction as _isFunction
} from "lodash";

import {convertToInt} from "../../../utils/helpers";

import {
    CLIENT_SELECTION_TYPE_ALL,
    CLIENT_SELECTION_TYPE_BLACKLIST,
    CLIENT_SELECTION_TYPE_WHITELIST,
    DISTRIBUTION_TYPE_LIVE,
    PRODUCT_THIRD_PARTY
} from "../../app/constants/variables";

/**
 * Prepares Third Party Clients streams data for form's inputs
 *
 * @param {Object[]} data
 * @param {Object} eventContent
 *
 * @returns {Object}
 */
export const parseClientsStreams = (data, eventContent) => {
    const clientsObject = {pushClients: [], pullClients: []},
        thirdPartyLiveDistribution = eventContent.distributions.find((distribution) => (
            PRODUCT_THIRD_PARTY === convertToInt(distribution.product.id)
            && DISTRIBUTION_TYPE_LIVE === convertToInt(distribution.distribution_type.id)
        ));

    if (data && thirdPartyLiveDistribution) {
        const clientIds = thirdPartyLiveDistribution.clients.map((client) => (client.id)),
            clientSelectionType = convertToInt(thirdPartyLiveDistribution.client_selection_type.id);

        data.filter((clientThirdParty) => (
            CLIENT_SELECTION_TYPE_ALL === clientSelectionType
            || (
                CLIENT_SELECTION_TYPE_WHITELIST === clientSelectionType
                && clientIds.includes(clientThirdParty.client_product.client.id)
            ) || (
                CLIENT_SELECTION_TYPE_BLACKLIST === clientSelectionType
                && !clientIds.includes(clientThirdParty.client_product.client.id)
            )
        )).forEach(function(clientThirdParty) {
            let cdns = [],
                origins = [];

            if (clientThirdParty.third_party_client_cdns) {
                clientThirdParty.third_party_client_cdns.forEach((stream) => {
                    cdns.push(stream)
                });
            }

            if (clientThirdParty.third_party_client_pull_origins) {
                clientThirdParty.third_party_client_pull_origins.forEach((stream) => {
                    origins.push(stream)
                });
            }

            if (0 < cdns.length) {
                clientsObject.pushClients.push({
                    client: {
                        id: clientThirdParty.client_product.client.id,
                        key: `push${clientThirdParty.client_product.client.id}`,
                        name: clientThirdParty.client_product.client.name
                    },
                    options: cdns
                })
            }

            if (0 < origins.length) {
                clientsObject.pullClients.push({
                    client: {
                        id: clientThirdParty.client_product.client.id,
                        key: `pull${clientThirdParty.client_product.client.id}`,
                        name: clientThirdParty.client_product.client.name
                    },
                    options: origins
                })
            }
        });
    }

    return clientsObject;
};

/**
 * Gets streams data from Third Party Clients form's inputs for submitting data
 *
 * @param {Object} data
 *
 * @returns {Object[]}
 */
export const getStreams = (data) => {
    const streams = [];

    if (!_isEmpty(data)) {
        Object.keys(data).forEach((key) => {
            const encoding_job_profile = convertToInt(key.split("_")[1]),
                stream_name = convertToInt(data[key]);

            if (0 < encoding_job_profile && 0 < stream_name) {
                streams.push({
                    encoding_job_profile: encoding_job_profile,
                    stream_name: stream_name
                })
            }
        });
    }

    return streams;
};

/**
 * Get Third Party Clients for data from Tech Setup and sets to parseTechSetupThirdPartyClientTypes
 *
 * @param {Object} techSetup
 *
 * @returns {Object}
 */
export const getTechSetupThirdPartyClientTypes = (techSetup) => {
    const clientProducts = _get(techSetup, "data.clientProductsThirdParty", null),
        clientProductThirdParties = _get(
            techSetup,
            "data.propertyLicenceTechSetups[0].property_licence_tech_setup_sr.client_product_third_parties",
            null
        );

    return parseTechSetupThirdPartyClientTypes(clientProducts, clientProductThirdParties);
};

/**
 * Prepares Third Party Clients per client and type (cdn & pull origin)
 *
 * @param {Object} data
 * @param {string} type
 *
 * @returns {Object}
 */
export const parseThirdPartyClientTypes = (data, type) => {
    const types = {},
        typeName = type === 'push' ? 'cdn' : 'pull_origin';

    if(data && data[type]) {
        data[type].forEach((stream) => {
            const clientId = _get(stream, `third_party_client_${typeName}.client.id`, null);

            if (0 < clientId && !types[clientId]) {
                types[clientId] = {switchId: type + clientId, streams: {}}
            }

            if (types[clientId]) {
                types[clientId]["streams"][stream[`third_party_client_${typeName}`].id] = stream.id;
            }
        });
    }

    return types;
};

/**
 * Prepares Third Party Clients before setting into EventsConfigurationThirdPartyTable
 *
 * @param {number} eventContentId
 * @param {Object} thirdParties
 *
 * @returns {Object[]}
 */
export const parseThirdParty = (eventContentId, thirdParties) => {
    if (_isEmpty(thirdParties)) {
        return [];
    }

    let parsed = [];

    if(thirdParties[eventContentId]) {
        thirdParties[eventContentId].forEach((targets) => {
            if(!_isEmpty(targets)) {
                if (!_isEmpty(targets.pull)) {
                    targets.pull.forEach((pull) => {
                        parsed.push(parsePull(pull))
                    });
                }

                if (!_isEmpty(targets.push)) {
                    targets.push.forEach((push) => {
                        parsed.push(parsePush(push))
                    });
                }
            }
        });
    }

    return parsed;
};

/**
 * Prepares column for Third Party Clients table on which one is sorting based
 *
 * @param {Object} row
 *
 * @returns {(null|string)}
 */
export const sortThirdParties = (row) => {
    if (_isEmpty(row)) {
        return null;
    }

    return row.client ? `${row.type || ""}-${row.client}` : null;
};

/**
 * Checks if setted push/pull data includes any stream
 *
 * @param {Array} data
 *
 * @returns {boolean}
 */
export const checkIfAnyStreamExists = (data = []) => {
    for (const client in data) {
        for (const option in data[client].options) {
            if (
                !_isFunction(data[client].options[option])
                && (0 < data[client].options[option].streams.length)
            ) {
                return true;
            }
        }
    }

    return false;
};

/**
 * Checks if setted push/pull data includes any tech setup client
 *
 * @param {Object} techSetupData
 * @param {Array} inputsData
 *
 * @returns {boolean}
 */
export const checkIfAnyTechSetupThirdPartyClientExists = (techSetupData = {}, inputsData = []) => {
    for (const clientId in techSetupData) {
        const client = _find(inputsData, {'client': {'id': clientId}});

        if (!_isEmpty(client) && checkIfAnyStreamExists([client])) {
            return true;
        }
    }

    return false;
};

/**
 * Prepares Third Party Clients per type (push & pull) and client for data from Tech Setup
 *
 * @param {Object[]} clientProducts
 * @param {Object[]} clientProductThirdParties
 *
 * @returns {Object}
 */
const parseTechSetupThirdPartyClientTypes = (clientProducts, clientProductThirdParties) => {
    const types = {push: {}, pull: {}};

    if (!_isEmpty(clientProducts)) {
        clientProducts.forEach((clientProduct) => {
            const clientId = _get(clientProduct, "client_product.client.value", null),
                productId = _get(clientProduct, "id", null);

            if (-1 !== _findIndex(clientProductThirdParties, (o) => o.id === productId)) {
                types.push[clientId] = {switchId: `push${clientId}`, streams: {}};
                types.pull[clientId] = {switchId: `pull${clientId}`, streams: {}};
            }
        });
    }

    return types;
};

/**
 * Prepares single pull before setting into EventsConfigurationThirdPartyTable
 *
 * @param {Object} data
 *
 * @returns {Object}
 */
const parsePull = (data) => {
    return {
        id: `pull_${data.id}_${data.encoding_target.id}`,
        type: 'Pull',
        cdnOrOrigin: data.third_party_client_pull_origin.name,
        client: data.third_party_client_pull_origin.client.name,
        stream_name: data.description ? `${data.stream_name} (${data.description})` : data.stream_name,
        target: convertToInt(data.encoding_target.id),
    };
};

/**
 * Prepares single push before setting into EventsConfigurationThirdPartyTable
 *
 * @param {Object} data
 *
 * @returns {Object}
 */
const parsePush = (data) => {
    return {
        id: `push_${data.id}_${data.encoding_target.id}`,
        type: 'Push',
        cdnOrOrigin: data.third_party_client_cdn.name,
        client: data.third_party_client_cdn.client.name,
        stream_name: data.description ? `${data.stream_name} (${data.description})` : data.stream_name,
        target: convertToInt(data.encoding_target.id),
    };
};
