import EventMasterBookingGeoRestrictionsGeneralInformationEditModel
    from "../../../events/forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsGeneralInformationEditModel";

import {GetEventMasterBookingForForm} from "../../../../graphql/bookings/query.graphql";

export default function ClientMasterBookingGeoRestrictionsGeneralInformationEditModel() {
    let ClientMasterBookingGeoRestrictionsGeneralInformationEditModel = EventMasterBookingGeoRestrictionsGeneralInformationEditModel();

    ClientMasterBookingGeoRestrictionsGeneralInformationEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    ClientMasterBookingGeoRestrictionsGeneralInformationEditModel.mutationOptions = {
        update: {
            refetchQueries: ['GetClientMasterBookings'],
        },
        delete: {
            refetchQueries: ['GetClientMasterBookings'],
        },
    };
    ClientMasterBookingGeoRestrictionsGeneralInformationEditModel.query.getEntity = GetEventMasterBookingForForm;

    return ClientMasterBookingGeoRestrictionsGeneralInformationEditModel;
}
