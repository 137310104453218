import React from 'react';
import PropTypes from 'prop-types';
import {Form as SemanticForm} from 'semantic-ui-react';
import classnames from 'classnames';

import {ReduxComponent} from './ReduxComponent';
import {convertToInt} from '@utils/helpers';

/**
 * Render list of checkboxes from given array
 */
export class SemanticCheckboxList extends ReduxComponent {
    /**
     * @property {Array} defaultValue Array of numbers
     * @property {Boolean} hidden Param for hidding list
     * @property {String} name Name for checkboxes fields
     * @property {Array<Object>} options Array of Options<br/>
     * <pre>
     * [
     *  {
     *      key: 1
     *      value: "Value 1"
     *  },
     *  {
     *      key: 2
     *      value: "Value 2"
     *  }
     *  ...
     * ]
     * </pre>
     */
    static propTypes = {
        className: PropTypes.string,
        defaultValue: PropTypes.arrayOf(PropTypes.number),
        hidden: PropTypes.bool,
        input: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        name: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        timestamp: PropTypes.number,
    };

    static defaultProps = {
        className: null,
        defaultValue: [],
        hidden: false,
        name: null,
        options: [],
        timestamp: null,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        this.renderContent = this.renderContent.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);

        let selected = [];

        if (!props.meta.visited) {
            selected = props.defaultValue;

            this.props.input.onChange(selected);
        }

        /**
         * @ignore
         */
        this.state = {
            selected : selected,
            options: props.options,
            timestamp: props.timestamp,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.timestamp !== prevState.timestamp) {
            nextProps.input.onChange(nextProps.defaultValue);

            return {
                selected: nextProps.defaultValue,
                timestamp: nextProps.timestamp,
            };
        }

        if (!nextProps.meta.visited) {
            const selected = nextProps.defaultValue;

            nextProps.input.onChange(selected);

            return {
                selected: selected,
            };
        }

        return null;
    }

    /**
     * @ignore
     */
    toggleCheckbox(event){
        const value = parseInt(event.currentTarget.dataset.value, 10),
            inputData = this.state.selected.slice(0),
            inputIndex = inputData.indexOf(value);

        if (-1 === inputIndex) {
            inputData.push(value);
        } else {
            inputData.splice(inputIndex, 1);
        }

        this.setState(() => ({
            selected: inputData,
        }));

        this.props.input.onFocus();
        this.props.input.onChange(inputData);
        this.props.input.onBlur();

        if(this.props.onCheckboxChange) {
            this.props.onCheckboxChange(null, {name: this.props.input.name, data: inputData});
        }
    }

    /**
     * @ignore
     */
    renderContent() {
        const contents = [];

        for (let i = 0; i < this.props.options.length; i++)  {
            const content = this.props.options[i];

            contents.push(
                <div key={content.key + i}>
                    <SemanticForm.Checkbox
                        className={this.props.className}
                        checked={
                            this.state.selected
                                ? -1 !== this.state.selected.indexOf(convertToInt(content.value))
                                : false
                        }
                        data-value={convertToInt(content.value)}
                        disabled={this.props.meta.submitting || content.disabled || this.props.disabled}
                        label={content.text || content.key}
                        name={`${this.props.name}[${content.key}]`}
                        onChange={this.toggleCheckbox}
                    />
                    {content.popup}
                </div>
            );
        }

        return contents;
    }

    /**
     * @return
     * Store:<br/>
     * Array of numbers - [1, 4, 25]<br/><br/>
     * Html:
     * <pre>
     * < div class="{null | hidden}">
     *   Label
     *   < div className="input-container">
     *      < div class="field">
     *          < div data-value="1"
     *          class="ui checkbox">
     *              < input type="checkbox"
     *                class="hidden"
     *                name="[Johnpaul Thompson MD > Mr. Sylvester Watsica]"
     *                readonly=""
     *                tabindex="0"
     *                value="on">
     *              < label>Johnpaul Thompson MD &gt; Mr. Sylvester Watsica</ label>
     *           </ div>
     *      </ div>
     *      < div class="field">
     *          < div data-value="2"
     *          class="ui checkbox">
     *              < input type="checkbox"
     *                class="hidden"
     *                name="[Mr. Sylvester Watsica]"
     *                readonly=""
     *                tabindex="0"
     *                value="on">
     *              < label>Mr. Sylvester Watsica</ label>
     *           </ div>
     *      </ div>
     *      < div class="error">{Error information}</div>
     *   </ div>
     * </ div>
     * </pre>
     */
    render() {
        return (
            <div className={classnames({
                'hidden': this.props.hidden,
            })}>
                {this.renderLabel(this.props)}
                <div className={classnames('input-container', {'--inline': this.props.inline})}>
                    <div className='formFields'>{this.renderContent()}</div>
                    <div className='error'>{this.isError(this.props) ? this.props.meta.error : ''}</div>
                </div>
            </div>
        );
    }
}

SemanticCheckboxList.displayName = 'SemanticCheckboxList';

export default SemanticCheckboxList;
