import {orderBy as _orderBy} from "lodash";
import PropTypes from "prop-types";
import React from "react";

/**
 * Display value for tournament for Sportradar Master Event
 */
export const TournamentsMasterSrEvent = ({event}) => {
    let tournaments = event.sr_event.master_event_tournament_products,
        tournamentsDetails = {};

    tournaments.forEach((tournament) => {
        let masterMatchTournament = tournament.master_match_tournament,
            tournamentId = masterMatchTournament.tournament.id;

        if (!tournamentsDetails[tournamentId]) {
            tournamentsDetails[tournamentId] = {
                text: `${masterMatchTournament.tournament.tournament_category.name}
                    > ${masterMatchTournament.tournament.name}`
            };

            tournamentsDetails[tournamentId]['products'] = tournament.product.short_name
        } else {
            tournamentsDetails[tournamentId]['products'] =
                `${tournamentsDetails[tournamentId]['products']}, ${tournament.product.short_name}`;
        }
    });

    tournamentsDetails = _orderBy(tournamentsDetails, 'text');

    return(
        <React.Fragment>
            {Object.keys(tournamentsDetails).map((key) => (
                <p key={`tournamentLabel${key}`}>
                    {tournamentsDetails[key].text}: {tournamentsDetails[key].products}
                </p>
            ))}
        </React.Fragment>
    )
};

TournamentsMasterSrEvent.propTypes = {
    event: PropTypes.shape({
        property_licence: PropTypes.shape({
            contents: PropTypes.array,
        }),
        sr_event: PropTypes.shape({
            master_event_tournament_products: PropTypes.array
        })
    }).isRequired
};

TournamentsMasterSrEvent.defaultProps = {
    event: {
        property_licence: {
            contents: [],
        },
        sr_event: {
            master_event_tournament_products: []
        }
    }
};
