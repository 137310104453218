import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import Authorization from '@appComponents/Authorization';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';
import {IconEdit} from '@appComponents/IconCollection';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

const authorizationEventPresetObject = {
    [RESOURCES.EVENT_PRESET]: CONST.SECURITY_PRIVILEGES_READ,
};

const authorizationEventContentObject = {
    [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_READ,
};

const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: true,
    },
    'event_id': {
        label: 'Reference event ID',
        sorting: (row) => parseInt(row.event.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};

const EventPresetsTable = (props) => {
    const {eventPresets, loading, propertyLicenceId} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'name':
                return (
                    <LinkWithAuthorization
                        authorization={authorizationEventPresetObject}
                        link={{
                            name: 'propertyLicences.eventPresets.edit',
                            params: {
                                id: propertyLicenceId,
                                eventPresetId: row.id,
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'event_id':
                return (
                    <LinkWithAuthorization
                        newWindow={true}
                        authorization={authorizationEventContentObject}
                        link={{
                            name: 'events.configuration.index',
                            params: {id: row.event.id},
                        }}
                    >
                        {row.event.id}
                    </LinkWithAuthorization>
                );
            case 'actions': {
                return (
                    <Authorization
                        authorization={authorizationEventPresetObject}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit
                                link={getLink('propertyLicences.eventPresets.edit', {
                                    id: propertyLicenceId,
                                    eventPresetId: parseInt(row.id, 10),
                                })}
                                size='large'
                                resource={RESOURCES.EVENT_PRESET}
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className='event_presets__table'>
            <Table
                name='eventPresetsList'
                columns={columns}
                data={eventPresets}
                loading={loading}
                noDataText='No event presets found'
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

EventPresetsTable.propTypes = {
    propertyLicenceId: PropTypes.number,
    eventPresets: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

EventPresetsTable.defaultProps = {
    eventPresets: [],
};

export default EventPresetsTable;
