import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import {Button, Icon} from "semantic-ui-react";

import TriggerAutobookerButton from "../../autobooker/components/TriggerAutobookerButton";

const PropertyLicenceTabsButtons = ({id}) => (
    <div className="indexButtons">
        {
            id && (
                <TriggerAutobookerButton
                    id={id}
                    entity="property licence"
                    messageBoxName="propertyLicenceMessage"
                    popupProps={{position: "bottom right"}}
                />
            )
        }
        <Link to="/property-licences">
            <Button className="button-back">
                <Icon className="arrow circle left"/> Back to list
            </Button>
        </Link>
    </div>
);

PropertyLicenceTabsButtons.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PropertyLicenceTabsButtons;
