import {get as _get} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {renderContentCategoryLevelColumn} from '@modules/client/utils/clientBookingTable';
import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';

export const eventFields = {
    'event_id': {
        label: 'Event ID',
        sorting: (row) => convertToInt(row.event_id),
        ...columnObjectAlignCenter,
    },
    'match_id': {
        label: 'Match ID',
        sorting: (row) => row.match_id ? convertToInt(row.match_id) : null,
        ...columnObjectAlignCenter,
    },
};

export const bookingsFields = {
    'start_datetime': {
        label: 'Date/Time',
        sorting: row => _get(row, 'start_datetime', null),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'content_category_level_1_name': {
        label: 'Sport /',
        subLabel: '1st level category',
        sorting: (row) => renderContentCategoryLevelColumn(row, 'sport', 1),
        ...columnObjectAlignLeft,
    },
    'content_category_level_2_name': {
        label: 'Category /',
        subLabel: '2nd level category',
        sorting: (row) => renderContentCategoryLevelColumn(row, 'tournament_category', 2),
        ...columnObjectAlignLeft,
    },
    'content_category_level_3_name': {
        label: 'Tournament /',
        subLabel: '3rd level category',
        sorting: (row) => renderContentCategoryLevelColumn(row, 'tournament', 3),
        ...columnObjectAlignLeft,
    },
    'content_tier_name': {
        label: 'Tier',
        sorting: true,
        ...columnObjectAlignLeft,
    },
};

export const productShortName = {
    'product_short_name': {
        label: 'Product',
        sorting: true,
        ...columnObjectAlignLeft,
    },
};

export const clientPackageName = {
    'client_package_name': {
        label: 'Package',
        sorting: true,
        ...columnObjectAlignLeft,
    },
};

export const geoFields = {
    'geo_restrictions_device': {
        label: 'Device categories',
        sorting: false,
        ...columnObjectAlignLeft,
        className: 'noPadding',
    },
    'geo_restrictions_country': {
        label: 'Countries',
        sorting: false,
        ...columnObjectAlignLeft,
        className: 'noPadding',
    },
    'geo_restrictions_country_count': {
        label: '#Countries',
        sorting: false,
        ...columnObjectAlignLeft,
        className: 'noPadding',
    },
};
