import {isEmpty as _isEmpty} from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit} from "../../app/components/IconCollection";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import {getLink} from "../../app/components/Link";
import Table from "../../app/components/Table";
import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';

const authorizationObject = {
    [RESOURCES.SDI_ORIGIN_ENTRY_POINTS]: CONST.SECURITY_PRIVILEGES_READ,
};

const columns = {
    "id": {
        label: "ID",
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {
        label: "Name",
        sorting: (row) => row.name.capitalizeFirstLetter()
    },
    "encoding_datacenter": {
        label: "Encoding datacenter",
        sorting: (row) => (row.country === null) ? "" : row.encoding_datacenter.name.capitalizeFirstLetter()
    },
    "input_id": {
        label: "Input ID",
        sorting: row => parseInt(row.input_id, 10)
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

const renderNameOfFirstLevelDependency = (row, column) => {
    return (
        <div>
            {(row[column] === null || row[column] === undefined)
                ? ""
                : row[column].name
            }
        </div>
    );
};

const renderLinkToEditAndDescription = (row) => {
    return (
        <div>
            <LinkWithAuthorization
                authorization={authorizationObject}
                link={{
                    name: "sdiOriginEntryPoints.edit",
                    params: {id: row.id}
                }}
            >
                {row.name}
            </LinkWithAuthorization>
            {!_isEmpty(row.description) ?
                <p className="muted">{row.description}</p> : null
            }
        </div>
    )
};

const SdiOriginEntryPointsTable = (props) => {
    const {sdiOriginEntryPoints, loading} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
                return (
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: "sdiOriginEntryPoints.edit",
                            params: {id: row.id}
                        }}
                    >
                        {row.id}
                    </LinkWithAuthorization>
                );
            case "name":
                return renderLinkToEditAndDescription(row);
            case "encoding_datacenter" :
                return renderNameOfFirstLevelDependency(row, column);
            case "actions" :
                return (
                    <Authorization authorization={authorizationObject}>
                        <div className="actions-list-icon">
                            <IconEdit size="large" link={getLink("sdiOriginEntryPoints.edit", {id: row.id})}
                                resource={RESOURCES.SDI_ORIGIN_ENTRY_POINTS}
                            />
                        </div>
                    </Authorization>
                );
            default:
                return null
        }
    };

    return (
        <div className="sdiOriginEntryPoints__table">
            <Table
                name="sdiOriginEntryPointsList"
                columns={columns}
                data={sdiOriginEntryPoints}
                loading={loading}
                rowRenderer={rowRenderer}
                pagination={false}
                noDataText={'No SDI origin entry points found'}
            />
        </div>
    )
};

SdiOriginEntryPointsTable.defaultProps = {
    sdiOriginEntryPoints: []
};

SdiOriginEntryPointsTable.propTypes = {
    sdiOriginEntryPoints: PropTypes.array.isRequired,
    loading: PropTypes.bool
};

export default SdiOriginEntryPointsTable;
