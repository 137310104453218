import {
    get as _get,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {refetchQueryByName} from '@utils/apollo';
import {SECURITY_PRIVILEGES_UPDATE} from "@constants/variables";

class SportForm extends DefaultForm {
    renderDeleteButton = () => null

    prepareDataForSubmit(data) {
        return {...data,
            lcr_channel: this.props.formParams.optionsVariables.lcrId,
            id: this.props.formParams.id,
        };
    }

    componentDidMount() {
        if (this.props.formParams.id) {
            this.setField('sport', {
                disabled: true,
            });
        }

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelSportContent');
        });

        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelSportContent');
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelSportContent');
        });

        this.setValuesParsers({
            'content_tiers': (data) => (
                data ? data.map((item) => {
                    return item.id ? item.id : item;
                }) : []
            ),
        });
    }

    componentDidUpdate() {
        if (!this.state.dataLoaded) {

            let sportsForForm = _get(this.props, 'GraphQLOptionsData.sports', []),
                sportsIdRestricted = [];

            const sportsInLcr = _get(this.props, 'GraphQLOptionsData.lcrChannelSports', []);

            if (!this.props.formParams.id) {
                sportsIdRestricted = sportsInLcr.map((item) => (item.sport.id));
            }

            if (sportsForForm.length) {
                sportsForForm = sportsForForm.reduce((array, sport) => {
                    if (!sportsIdRestricted.includes(sport.id)) {
                        array.push(sport);
                    }

                    return array;
                }, []);

                this.setField('sport', {
                    options: sportsForForm,
                });

                this.setState(() => ({
                    dataLoaded: true,
                }));
            }
        }
    }

    customAuthorization = (userResourcePrivilages) => (
        (userResourcePrivilages & SECURITY_PRIVILEGES_UPDATE) > 0
    )
}

export default SportForm;
