import React from 'react';
import PropTypes from 'prop-types';
import {Popup} from "semantic-ui-react";

import {Icon} from "../../../../../../../app/components/IconCollection";
import {RedLabel} from "../../../../../../../../utils/decorators";

export const TvStreaming = ({encoder}) => {
    if (null === encoder) {
        return <RedLabel label="No encoder assigned"/>;
    }

    return (
        <Popup
            content={encoder.stream_url}
            on={['hover']}
            hoverable
            position="right center"
            wide="very"
            trigger={
                <div>
                    {encoder.name}
                    <Icon name='info circle' className="--marginLeft-0-5-em"/>
                </div>
            }
        />
    );
};

TvStreaming.propTypes = {
    encoder: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        stream_url: PropTypes.string,
    })
};

TvStreaming.defaultProps = {
    encoder: null
};
