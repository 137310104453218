import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.type, 'type'));
    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 100}, 'name'));
    Object.assign(errors, validators.url(values.url, 'url'));
    Object.assign(errors, validators.maxLength({value: values.url, maxLength: 2000}, 'url'));

    return errors;
};

export default validate;
