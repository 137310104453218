import AvApiAccessModel from '@modules/client/forms/AvApiAccessModel';

export default function ClientAvapiModelEdit() {
    const whitelistedIpModelEdit = AvApiAccessModel(),
        cidrName = (data) => (data.clientWhitelistedIp ? `"${data.clientWhitelistedIp.cidr}"` : "");

    whitelistedIpModelEdit.title = (data) => (`CIDR ${cidrName(data)}`);
    whitelistedIpModelEdit.fields.ip_version.props.disabled = true;
    whitelistedIpModelEdit.fields.cidr.props.disabled = true;

    return whitelistedIpModelEdit;
}
