import PropTypes from "prop-types";
import React from "react";
import {Table} from "semantic-ui-react";

const PopupTable = (props) => {
    let {header, rows} = props;
    
    return (
        <Table className="popupTable" basic="very" collpasing="true" compact="very" size="small">
            {
                (null !== header)
                    ? (
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2}><b>{header}</b></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    )
                    : null
            }
            <Table.Body>
                {rows.filter((row) => (!row.hidden)).map((row, index) => (
                    <Table.Row key={index}>
                        <Table.Cell><b>{row.header}</b></Table.Cell>
                        <Table.Cell>{row.body}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

PopupTable.defaultProps = {
    header: null,
    rows: [],
};

PopupTable.propTypes = {
    header: PropTypes.string,
    rows: PropTypes.array,
};

export default PopupTable;
