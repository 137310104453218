import React from 'react';
import PropTypes from 'prop-types';
import {Breadcrumb} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';

/**
 * @ignore
 */
const CrumbRoutes = (props) => {
    const paths = props.pathname.split('/').map((p, i, arr) => {
        if (0 === i) return {
            key: i,
            content: (<NavLink exact to={'/'}>Home</NavLink>),
            active: (i === arr.length - 1),
        };

        if (i === arr.length - 1) return {
            key: i,
            content: p,
            active: (i === arr.length - 1),
        };

        return {
            key: i,
            content: (<NavLink exact to={`${arr.slice(0, i + 1).join('/')}`}>{p}</NavLink>),
            active: (i === arr.length - 1),
        };
    });

    return <Breadcrumb as='div' icon='chevron right' sections={paths}/>;
};

CrumbRoutes.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default CrumbRoutes;
