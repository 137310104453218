import {get as _get, has as _has} from 'lodash';

import {PRODUCT_LCPP} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {lcppClientNotificationLogColumns} from '@modules/client/utils/lcppClientNotificationLog';
import {getLink} from '@appComponents/Link';
import {renderModalError} from '@utils/forms';
import {getEventContentText} from '@utils/eventContentVariant/eventContentVariant';

export const renderEventLcppClientNotificationLogModalError = (eventId, message) => (
    renderModalError(
        'LCPP Client notification history',
        getLink('events.configuration.index', {id: convertToInt(eventId)}),
        message,
        'Go to event page'
    )
);
renderEventLcppClientNotificationLogModalError.displayName = 'renderEventLcppClientNotificationLogModalError';

export const hasEventContentWithLcppDistribution = (eventContentsDistributionsProducts) => (
    !!eventContentsDistributionsProducts.find(eventContent => (
        _has(eventContent, 'distributions') && eventContent.distributions.find(distribution => {
            const productId = _get(distribution, 'product.id', null);

            return productId ? PRODUCT_LCPP === convertToInt(productId) : false;
        })
    ))
);

export const prepareEventContentNamesForDropdown = (eventContents = []) => (eventContents.map((item) => {

    return {
        key: item.id.toString(),
        text: getEventContentText(item, true),
        value: item.id.toString(),
    };
}));

export const getEventLcppClientNotificationLogColumns = () => {
    delete lcppClientNotificationLogColumns['event_id'];
    delete lcppClientNotificationLogColumns['match_id'];
    delete lcppClientNotificationLogColumns['sport'];
    delete lcppClientNotificationLogColumns['category'];
    delete lcppClientNotificationLogColumns['tournament'];
    delete lcppClientNotificationLogColumns['competitors'];

    const eventLcppClientNotificationLogColumns = {};

    for (const column in lcppClientNotificationLogColumns) {
        eventLcppClientNotificationLogColumns[column] = lcppClientNotificationLogColumns[column];

        if ('video_duration' === column) {
            eventLcppClientNotificationLogColumns['client'] = {
                label: 'Client',
                sorting: row => _get(row, 'client.name', null),
            };
        }
    }

    return eventLcppClientNotificationLogColumns;
};

export const getEventContentWithLcppProduct = (eventContents) => (
    eventContents.filter(eventContent => (
        eventContent.distributions.every(distribution => ([PRODUCT_LCPP].includes(Number(distribution.product.id))))
    ))
);
