import {get as _get} from 'lodash';

import {getLink} from '@appComponents/Link';
import EventBookingGeoRestrictionsGeneralInformationForm
    from '@modules/events/forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsGeneralInformationForm';

export class ClientBookingGeoRestrictionsGeneralInformationForm extends EventBookingGeoRestrictionsGeneralInformationForm {
    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const booking = _get(nextProps, 'GraphQLEntityData.booking', null);

        if (!this.state.setFields && booking) {
            const distribution = _get(booking, 'distribution', null),
                eventContent = _get(booking, 'distribution.event_content', null);

            this.setField('client', {
                options: [{
                    id: booking.client.id,
                    key: `client${booking.client.id}`,
                    text: booking.client.name,
                    value: booking.client.id,
                }],
            });

            this.setField('distribution', {
                options: [{
                    id: booking.distribution.id,
                    key: `distribution${booking.distribution.id}`,
                    text: `${eventContent.event_content_type.name} ${eventContent.name ? ' - ' + eventContent.name : ''} > 
                        ${distribution.product.short_name} > ${distribution.distribution_type.name}`,
                    value: booking.distribution.id,
                }],
            });

            this.getClientPackages(nextProps.formParams.optionsVariables.client, distribution.product.id);

            this.setState(() => ({
                setFields: true,
            }));
        }
    }

    renderErrors(errorData) {
        const link = getLink('clients.bookings.index', {
            id: this.props.formParams.optionsVariables.client || null,
        });

        super.renderErrors(errorData, 'Booking', link, {size: 'tiny'});
    }
}

export default ClientBookingGeoRestrictionsGeneralInformationForm;
