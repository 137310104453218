import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {Button, Grid} from 'semantic-ui-react';

import {FormWrapper} from '@appComponents/HOCFormWrapper';
import Form from '@appComponents/ReduxFormControls';
import {convertToInt} from '@utils/helpers';

import validate from '../forms/validatorRightScopeStreamConstraints';

class RightScopeStreamConstraintsForm extends React.Component {
    static propTypes = {
        currentDeviceCategoryValueAdd: PropTypes.number.isRequired,
        currentProductValueAdd: PropTypes.number.isRequired,
        dataForForm: PropTypes.shape({
            deviceCategories: PropTypes.arrayOf(PropTypes.object),
            products: PropTypes.arrayOf(PropTypes.object),
            rightsScopeStreamConstraints: PropTypes.object,
            usedProductsAndCategories: PropTypes.arrayOf(PropTypes.object),
        }),
        handleSubmit: PropTypes.func.isRequired,
        onAdd: PropTypes.func,
        onDeactivateEditableCell: PropTypes.func,
        onUpdate: PropTypes.func,
        submitting: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        onAdd: null,
        onDeactivateEditableCell: null,
        onUpdate: null,
    };

    state = {
        deviceCategories: [],
        deviceCategoryDropdownValues: [],
        inputRefreshTimestamp: Date.now(),
        products: [],
        productDropdownValues: [],
    };



    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {};

        if (nextProps.dataForForm.deviceCategories && 0 === prevState.deviceCategories.length) {
            nextState.deviceCategories = nextProps.dataForForm.deviceCategories;

            if (nextProps.onAdd) {
                nextState.deviceCategoryDropdownValues = nextProps.dataForForm.deviceCategories.filter((item) => (
                    nextProps.dataForForm.usedProductsAndCategories.filter(
                        used => used.device_category.id === item.id
                    ).length !== nextProps.dataForForm.products.length
                ));
            } else {
                nextState.deviceCategoryDropdownValues = nextProps.dataForForm.deviceCategories.filter((item) => (
                    0 === nextProps.dataForForm.usedProductsAndCategories.filter(
                        used => used.device_category.id === item.id && used.product.id === nextProps.dataForForm.rightsScopeStreamConstraints.product.id
                    ).length
                    || nextProps.dataForForm.rightsScopeStreamConstraints.device_category.id === item.id
                ));
            }
        }

        if (nextProps.dataForForm.products && 0 === prevState.products.length) {
            nextState.products = nextProps.dataForForm.products;

            if (nextProps.onAdd) {
                nextState.productDropdownValues = nextProps.dataForForm.products.filter((item) => (
                    nextProps.dataForForm.usedProductsAndCategories.filter(
                        used => used.product.id === item.id
                    ).length !== nextProps.dataForForm.deviceCategories.length
                ));
            } else {
                nextState.productDropdownValues = nextProps.dataForForm.products.filter((item) => (
                    0 === nextProps.dataForForm.usedProductsAndCategories.filter(
                        used => used.product.id === item.id && used.device_category.id === nextProps.dataForForm.rightsScopeStreamConstraints.device_category.id
                    ).length
                    || nextProps.dataForForm.rightsScopeStreamConstraints.product.id === item.id
                ));
            }
        }

        return nextState;
    }

    onFormSubmit(data) {
        if (data.id) {
            this.props.onUpdate();
            this.props.onDeactivateEditableCell();
        } else {
            this.props.onAdd().then(() => {
                this.setState(() => ({
                    inputRefreshTimestamp: Date.now(),
                }));
            });
        }
    }

    onChangeProduct = (event, product) => {
        if (!product) {
            return;
        }

        let deviceCategoryDropdownValues = this.props.dataForForm.deviceCategories.filter((item) => (
            0 === this.props.dataForForm.usedProductsAndCategories.filter(
                used => used.device_category.id === item.id && convertToInt(used.product.id) === product
            ).length
            || (
                convertToInt(this.props.dataForForm.rightsScopeStreamConstraints.product.id) === product
                && this.props.dataForForm.rightsScopeStreamConstraints.device_category.id === item.id
                && null === this.props.onAdd
            )
        ));

        this.setState(() => ({
            deviceCategoryDropdownValues: deviceCategoryDropdownValues,
        }));
    };

    onChangeDeviceCategory = (event, deviceCategory) => {
        if (!deviceCategory) {
            return;
        }

        let productDropdownValues = this.props.dataForForm.products.filter((item) => (
            0 === this.props.dataForForm.usedProductsAndCategories.filter(
                used => used.product.id === item.id && convertToInt(used.device_category.id) === deviceCategory
            ).length
            || (
                convertToInt(this.props.dataForForm.rightsScopeStreamConstraints.device_category.id) === deviceCategory
                && this.props.dataForForm.rightsScopeStreamConstraints.product.id === item.id
                && null === this.props.onAdd
            )
        ));

        this.setState(() => ({
            productDropdownValues: productDropdownValues,
        }));
    };

    renderProductColumn = () => (
        <Form.FormRow
            allowClear={false}
            component={Form.SemanticSelect}
            defaultValue={this.props.onUpdate
                ? convertToInt(this.props.dataForForm.rightsScopeStreamConstraints.product.id)
                : this.props.currentProductValueAdd
            }
            name='product'
            onChange={this.onChangeProduct}
            options={this.state.productDropdownValues.map((product) => ({
                text: product.name, value: convertToInt(product.id), key: 'p_' + product.id,
            }))}
            placeholder='Select product'
            required
        />
    );

    renderDeviceCategoryColumn = () => (
        <Form.FormRow
            allowClear={false}
            component={Form.SemanticSelect}
            defaultValue={this.props.onUpdate
                ? convertToInt(this.props.dataForForm.rightsScopeStreamConstraints.device_category.id)
                : this.props.currentDeviceCategoryValueAdd
            }
            name='device_category'
            onChange={this.onChangeDeviceCategory}
            options={this.state.deviceCategoryDropdownValues.map((category) => ({
                text: category.name, value: convertToInt(category.id), key: 'dc_' + category.id,
            }))}
            placeholder='Select device category'
            required
        />
    );

    renderMaxBitrateColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticInput}
                defaultValue={this.props.onUpdate
                    ? this.props.dataForForm.rightsScopeStreamConstraints.max_bitrate
                    : ''}
                name='max_bitrate'
                placeholder='Max Bitrate'
                required
                min={0}
                type='number'
                labelDescription={{content: 'Kbps'}}
            />
        );
    };

    renderMaxResolutionColumn = () => {
        return (
            <div className='editableTable__resolution'>
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.onUpdate
                        ? this.props.dataForForm.rightsScopeStreamConstraints.max_resolution_width
                        : ''}
                    name='max_resolution_width'
                    required
                    size='small'
                    min={0}
                    type='number'
                    labelDescription={{content: 'px'}}
                />
                <span>x</span>
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.onUpdate
                        ? this.props.dataForForm.rightsScopeStreamConstraints.max_resolution_height
                        : ''}
                    name='max_resolution_height'
                    required
                    size='small'
                    min={0}
                    type='number'
                    labelDescription={{content: 'px'}}
                />
            </div>
        );
    };

    renderMaxPlayerSizeColumn = () => {
        return (
            <Form.FormRow
                component={Form.SemanticInput}
                defaultValue={this.props.onUpdate
                    ? this.props.dataForForm.rightsScopeStreamConstraints.max_player_size
                    : ''}
                name='max_player_size'
                required
                type='number'
                min={0}
                max={100}
                labelDescription={{content: '%'}}
            />
        );
    };

    renderButtons = () => {
        if (this.props.onAdd) {
            return this.renderButtonsForAddForm();
        } else {
            return this.renderButtonsForEditForm();
        }
    };

    renderButtonsForAddForm = () => {
        return (
            <Button
                disabled={this.props.submitting}
                size='small'
                icon='plus'
                loading={this.props.submitting}

            />
        );
    };

    renderButtonsForEditForm = () => {
        return (
            <Button.Group>
                <Button
                    data-id={this.props.dataForForm.rightsScopeStreamConstraints.id}
                    data-product={this.props.dataForForm.rightsScopeStreamConstraints.product.id}
                    data-device={this.props.dataForForm.rightsScopeStreamConstraints.device_category.id}
                    disabled={this.props.submitting}
                    icon='checkmark'
                    key='update-button'
                    loading={this.props.submitting}
                    positive
                />
                <Button
                    icon='ban'
                    key='cancel-button'
                    negative
                    onClick={this.props.onDeactivateEditableCell}
                    type='button'
                />
            </Button.Group>
        );
    };

    render() {
        return (
            <Form.Create onSubmit={this.props.handleSubmit((values) => {
                return this.onFormSubmit(values);
            })}
            >
                <Form.IdField
                    defaultValue={this.props.dataForForm.rightsScopeStreamConstraints.id}
                    name='id'
                />
                <Grid verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            {this.renderProductColumn()}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.renderDeviceCategoryColumn()}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.renderMaxBitrateColumn()}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {this.renderMaxResolutionColumn()}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.renderMaxPlayerSizeColumn()}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <div className='actions-list-icon'>
                                {this.renderButtons()}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form.Create>

        );
    }
}

const selectorAdd = formValueSelector('form_rssc_add');

const RightScopeStreamConstraintsWithRedux = reduxForm({
    form: 'RightScopeStreamConstraints',
    validate,
})(RightScopeStreamConstraintsForm);

const RightScopeStreamConstraintsWithForm = connect(state => {
    let currentDeviceCategoryValueAdd = convertToInt(selectorAdd(state, 'device_category')),
        currentProductValueAdd = convertToInt(selectorAdd(state, 'product'));

    return {
        currentDeviceCategoryValueAdd,
        currentProductValueAdd,
    };
})(RightScopeStreamConstraintsWithRedux);


export default FormWrapper({
    deviceCategories: [],
    products: [],
    rightsScopeStreamConstraints: {
        id: '',
        product: {
            id: '',
            name: '',
        },
        device_category: {
            id: '',
            name: '',
        },
        max_bitrate: '',
        max_player_size: '',
        max_resolution_width: '',
        max_resolution_height: '',
    },
    usedProductsAndCategories: [],
}, RightScopeStreamConstraintsWithForm);
