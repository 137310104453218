import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {PROPERTY_LICENCE} from "../../app/constants/resources";

import {renderFormSavedMessages} from "../utils/technicalSetupProductsModelHelper";

import validate from "./validator";

import {UpdatePropertyLicencePricing} from "../../../graphql/propertyLicence/mutation.graphql";
import {GetPropertyLicencePricingForForm} from "../../../graphql/propertyLicence/query.graphql";

export default function PropertyLicencePricingModel() {
    return {
        entityDataMapKey: "propertyLicence",
        entityLabel: "Property Licence Pricing",
        formName: "propertyLicencePricing",
        label: "Property Licence Pricing",
        resources: PROPERTY_LICENCE,
        validate: validate,
        title: null,
        renderLabels: false,
        showChangeLog: false,
        dataMap: {
            propertyLicence: {
                id: "",
                pricing: ""
            }
        },
        messages: {
            boxName: {
                error: "propertyLicenceMessageError",
                success: "propertyLicenceMessage"
            },
            text: {
                ...MESSAGES,
                SAVED: renderFormSavedMessages
            }
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "propertyLicence.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            address: {
                dataMapKey: "propertyLicence.pricing",
                props: {
                    component: Form.SemanticTextArea,
                    label: "",
                    name: "pricing",
                },
            },
        },
        mutation: {
            updateEntity: UpdatePropertyLicencePricing,
        },
        mutationOptions: {},
        query: {
            getEntity: GetPropertyLicencePricingForForm
        },
    }
}
