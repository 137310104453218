import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {getBookingStatusForOfferings,
    getBookingStatusForOfferingsDefaultValues,
} from '@utils/booking';
import {HAS_HQ} from '@constants/staticDropdowns';

export const EventsOfferingsComponent = (props) => {
    const {
            clients,
            countries,
            deviceCategories,
            distributionTypes,
            eventContents,
            products,
            eventContentTypes,
            eventContentVariants,
        } = props.data,
        filters = props.filters.EventsOfferings,
        loading = props.loading;

    return (
        <div className="eventOfferings__filters filter__controls__container">
            <Field
                className="long"
                component={Form.ReduxSelect}
                name="clients"
                placeholder="Client"
                options={clients}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'clients')}
            />
            <Field
                className="medium"
                component={Form.ReduxSelect}
                name="eventContents"
                placeholder="Content"
                options={eventContents}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'eventContents')}
            />
            <Field
                className="--customWidth --contentType"
                component={Form.ReduxSelect}
                name="eventContentTypes"
                placeholder="Content type"
                options={eventContentTypes}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'eventContentTypes')}
            />
            <Field
                className="--customWidth --contentVariant"
                component={Form.ReduxSelect}
                name="eventContentVariants"
                placeholder="Content variant"
                options={eventContentVariants}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'eventContentVariants')}
            />
            <Field
                className="--customWidth --products"
                component={Form.ReduxSelect}
                name="products"
                placeholder="Product"
                options={products}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'products')}
            />
            <Field
                className="--customWidth --distributionTypes"
                component={Form.ReduxSelect}
                name="distributionTypes"
                placeholder="Distribution type"
                options={distributionTypes}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'distributionTypes')}
            />
            <Field
                className="--customWidth --deviceCategories"
                component={Form.ReduxSelect}
                name="deviceCategories"
                placeholder="Device category"
                options={deviceCategories}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'deviceCategories')}
            />
            <Field
                component={Form.ReduxSelect}
                name="countries"
                placeholder="Country"
                options={countries}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(filters, 'countries')}
            />
            <Field
                className='--customWidth --isHq'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                selection
                name='isHq'
                placeholder='HQ?'
                options={HAS_HQ}
                setValue={props.restoreFilterValues(filters, 'isHq')}
            />
            <Field
                component={Form.ReduxSelect}
                name="bookingStatus"
                disabled={loading}
                loading={loading}
                multiple
                options={getBookingStatusForOfferings()}
                placeholder="Booking status"
                setValue={props.restoreFilterValues(filters, 'bookingStatus', getBookingStatusForOfferingsDefaultValues())}
                selection
            />
        </div>
    );
};

EventsOfferingsComponent.propTypes = {
    data: PropTypes.shape({
        clients: PropTypes.array.isRequired,
        countries: PropTypes.array.isRequired,
        deviceCategories: PropTypes.array.isRequired,
        distributionTypes: PropTypes.array.isRequired,
        eventContents: PropTypes.array.isRequired,
        products: PropTypes.array.isRequired,
        eventContentTypes: PropTypes.array.isRequired,
        eventContentVariants: PropTypes.array.isRequired,
    }),
    filters: PropTypes.shape({
        EventsOfferings: PropTypes.object,
    }),
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func,
};

EventsOfferingsComponent.defaultProps = {
    filters: {
        EventsOfferings: {},
    },
};

export default FiltersWrapper(EventsOfferingsComponent, {
    name: 'EventsOfferings',
    initialValues: {
        bookingStatus: getBookingStatusForOfferingsDefaultValues(),
    },
});
