import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Dropdown, Header, Popup} from "semantic-ui-react";

import {Authorization} from '@appComponents/Authorization';
import {SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE} from '@constants/variables';
import {BOOKING, EVENT_BOOKING, OFFERING} from '@constants/resources';

import ActionButton from "../../app/components/ButtonsCollection/ActionButton";
import {IconWithTooltip} from "../../app/components/IconCollection";
import {triggerAutobooker} from "../utlis/triggerAutobooker";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import {convertToInt} from "../../../utils/helpers";

const authorizationTriggerAutobookerObject = {
    [BOOKING]: SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
    [OFFERING]: SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
    [EVENT_BOOKING]: SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
};

const TriggerAutobookerButton = ({id, entity, messageBoxName, MessageBox, Modal, popupProps = {}, uiType = null, disabled = false}) => {
    const icon = "bolt",
        text = `Trigger the Autobooker / Auto-offerer for this ${entity}`,
        disabledText = "Not possible for the contents that have a start time before 1 hour ago",
        content = disabled ? <span><p>{text}</p>{disabledText}</span> : text,
        tooltipProps = {content: content, wide: "very", ...popupProps},
        entityId = (typeof id === 'string') ? convertToInt(id) : id;

    const showModal = () => {
        Modal.setModal({
            isVisible: true,
            hideButtons: false,
            content: null,
            header: <Header content="Trigger the Autobooker / Auto-offerer"/>,
            text: `Are you sure you want to trigger the Autobooker / Auto-offerer for this ${entity}?`,
            onYes: () => triggerAutobooker(entityId, entity, messageBoxName, MessageBox, Modal)
        });
    }

    const renderDropdown = () => {
        const dropdown = (
            <Dropdown.Item
                className="trigger-autobooker-button__dropdown-item--pointer-events-all"
                disabled={disabled}
                icon={icon}
                key="autobooker"
                onClick={!disabled && showModal}
                text={text}
            />
        );

        return disabled
            ? (
                <Popup
                    content="Not available because there are no contents with a start time greater than 1 hour ago"
                    flowing
                    trigger={dropdown}
                />
            )
            : dropdown;
    };

    const renderButton = () => {
        switch (uiType) {
            case "dropdown":
                return renderDropdown();
            case "icon":
                return <IconWithTooltip
                    name={icon}
                    onClick={showModal}
                    disabled={disabled}
                    popupProps={tooltipProps}
                    iconProps={{disabled: disabled}}
                />
            default:
                return <ActionButton
                    popup={tooltipProps}
                    button={{icon: icon, disabled: disabled}}
                    onClick={showModal}
                />
        }
    };

    return (
        <Authorization authorization={authorizationTriggerAutobookerObject}>
            {renderButton()}
        </Authorization>
    );
};

TriggerAutobookerButton.propTypes = {
    disabled: PropTypes.bool,
    entity: PropTypes.oneOf(["client", "event", "content", "property licence"]).isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    MessageBox: PropTypes.object.isRequired,
    messageBoxName: PropTypes.string.isRequired,
    Modal: PropTypes.object.isRequired,
    popupProps: PropTypes.object,
    uiType: PropTypes.oneOf(["dropdown", "icon"]),
};

export default connect(null, mapModulesToProps(["MessageBox", "Modal"]))(TriggerAutobookerButton);
