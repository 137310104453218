import PropTypes from "prop-types";
import React from "react";
import {gql, graphql} from "react-apollo";

import ClientForm from "./ClientForm";

import {countryListQuery} from "../../../graphql/country";

const ClientFormAdd = (props) => (
    <div>
        <ClientForm formData={props.data}/>
    </div>
);

ClientFormAdd.propTypes = {
    data: PropTypes.object
};

const dropdownDataQuery = gql`${countryListQuery}`;

export default graphql(dropdownDataQuery)(ClientFormAdd)
