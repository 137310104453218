import React from 'react';
import PropTypes from 'prop-types';
import {Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';

import {routes} from '@constants/routes';
import {isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {renderModalError} from '@utils/forms';
import {getLink} from '@appComponents/Link';

import RightsScopeIndex from './RightsScopeIndex';
import {RightsScopeTabsLayout} from '../layouts/RightsScopeTabsLayout';

const RightsScopes = ({match: {params: {id, rightsScopeId = null}, path}}) => {
    if(!isUrlParamValid(rightsScopeId)) {
        showModal({
            isVisible: true,
            content: renderModalError('Rights scope',
                getLink(`propertyLicences.rightsScopes.index`, {
                    id: id,
                })
            ),
        });
    }

    let viewToRender =
        <RightsScopeTabsLayout
            path={path}
            propertyLicenceId={id}
            rightsScopeId={rightsScopeId}
        />;

    if (routes.propertyLicences.rightsScopes.index.path === path) {
        viewToRender = <RightsScopeIndex propertyLicenceId={id}/>;
    }

    return (
        <Segment basic className="--table">
            {viewToRender}
        </Segment>
    );
};

RightsScopes.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            rightsScopeId: PropTypes.string,
        }),
        path: PropTypes.string.isRequired
    })
};

export default withRouter(RightsScopes);
