import React from 'react';
import PropTypes from 'prop-types';
import {Popup} from "semantic-ui-react";

export default class NavigationPopup extends React.Component {
    static propTypes = {
        offset: PropTypes.number.isRequired,
        submenu: PropTypes.element.isRequired,
        trigger: PropTypes.element.isRequired
    };

    constructor(props) {
        super(props);

        this.state = { popup: false };
    }

    closePopup = () => {
        document.getElementsByClassName(this.props.trigger.props['data-key'])[0].classList.remove('--hover');
        this.setState(() => ({ popup: false }));
    };

    openPopup = () => {
        document.getElementsByClassName(this.props.trigger.props['data-key'])[0].classList.add('--hover');
        this.setState(() => ({ popup: true }));
    };

    render() {
        return (
            <Popup
                className={'navigation__popup --submenu'}
                content={this.props.submenu}
                style={{
                    left: this.props.offset,
                    right: 'auto'
                }}
                position="bottom left"
                trigger={this.props.trigger}
                hoverable
                onClick={this.closePopup}
                onOpen={this.openPopup}
                onClose={this.closePopup}
                open={this.state.popup}
            />
        );
    }
}
