import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";

const RtmpOriginEntryPointFilters = ({
    data: {
        rtmpOriginEntryPointTypes = [],
        originHostingProviders = [],
        isActive = [],
    },
    loading,
    filters,
    restoreFilterValues,
}) => {
    return (
        <div className="RtmpOriginEntryPoints__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                name="search"
                placeholder="Search..."
                defaultValue={null}
                setValue={restoreFilterValues(filters.rtmpOriginEntryPoints, 'search')}
                disabled={loading}
            />
            <Field
                component={Form.ReduxSelect}
                name="rtmpOriginEntryPointTypes"
                options={rtmpOriginEntryPointTypes}
                disabled={loading}
                placeholder="Type"
                loading={loading}
                setValue={restoreFilterValues(filters.rtmpOriginEntryPoints, 'rtmpOriginEntryPointTypes')}
                search
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name="originHostingProviders"
                options={originHostingProviders}
                disabled={loading}
                placeholder="Hosting provider"
                loading={loading}
                setValue={restoreFilterValues(filters.rtmpOriginEntryPoints, 'originHostingProviders')}
                search
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name="isActive"
                className="isActiveDropdownFilter"
                options={isActive}
                disabled={loading}
                setValue={restoreFilterValues(filters.rtmpOriginEntryPoints, 'isActive')}
                placeholder="Status"
                loading={loading}
                selection
            />
        </div>
    )
};

RtmpOriginEntryPointFilters.propTypes = {
    data: PropTypes.shape({
        rtmpOriginEntryPointTypes: PropTypes.array,
        originHostingProviders: PropTypes.array,
        isActive: PropTypes.array,
    }),
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(RtmpOriginEntryPointFilters, {name: "rtmpOriginEntryPoints"});
