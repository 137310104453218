import {generateIdNameFieldsByDataMapKey} from '@utils/fieldsGenerator';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {MATCH_SELECTION_PERIOD} from '@constants/resources';
import {
    CreateMatchSelectionPeriod,
    DeleteMatchSelectionPeriod,
    UpdateMatchSelectionPeriod,
} from '@graphql/matchSelectionPeriod/mutation';
import {GetMatchSelectionPeriodForForm} from '@graphql/matchSelectionPeriod/query';
import {GetPropertyLicenceContents} from '@graphql/propertyLicenceContent/query';

import validate from './validatorMatchSelectionPeriod';

export default function MatchSelectionPeriodModel() {
    return {
        entityDataMapKey: 'matchSelectionPeriod',
        entityLabel: 'match selection period',
        formName: 'matchSelectionPeriod',
        label: 'match selection period',
        resources: MATCH_SELECTION_PERIOD,
        showChangelog: true,
        title: null,
        validate: validate,
        dataMap: {
            matchSelectionPeriod: {
                id: '',
                name: '',
                includes_all_content: true,
                start_date: '',
                end_date: '',
                num_matches_agreed: '',
                num_matches_commitment: '',
                num_matches_budget: '',
                notes: '',
                contents: [],
            },
        },
        messages: {
            boxName: {
                delete: 'propertyLicenceMessage',
                error: 'propertyLicenceMessage',
                success: 'propertyLicenceMessage',
            },
            text: MESSAGES,
        },
        fields: {
            ...generateIdNameFieldsByDataMapKey('matchSelectionPeriod'),
            includes_all_content: {
                dataMapKey: 'matchSelectionPeriod.includes_all_content',
                props: {
                    component: Form.SemanticInput,
                    defaultValue: true,
                    label: 'Includes all content',
                    name: 'includes_all_content',
                    type: 'checkbox',
                },
            },
            contents: {
                props: {
                    component: Form.SemanticCheckboxList,
                    hidden: true,
                    label: 'Content',
                    name: 'contents',
                    required: true,
                },
            },
            start_date: {
                dataMapKey: 'matchSelectionPeriod.start_date',
                props: {
                    component: Form.SemanticInput,
                    label: 'Start date',
                    name: 'start_date',
                    required: true,
                    type: 'date',
                },
            },
            end_date: {
                dataMapKey: 'matchSelectionPeriod.end_date',
                props: {
                    component: Form.SemanticInput,
                    label: 'End date',
                    name: 'end_date',
                    required: true,
                    type: 'date',
                },
            },
            num_matches_agreed: {
                dataMapKey: 'matchSelectionPeriod.num_matches_agreed',
                props: {
                    component: Form.SemanticInput,
                    label: 'Matches agreed',
                    name: 'num_matches_agreed',
                    required: true,
                    type: 'number',
                },
            },
            num_matches_commitment: {
                dataMapKey: 'matchSelectionPeriod.num_matches_commitment',
                props: {
                    component: Form.SemanticInput,
                    label: 'Matches commitment',
                    name: 'num_matches_commitment',
                    required: true,
                    type: 'number',
                },
            },
            num_matches_budget: {
                dataMapKey: 'matchSelectionPeriod.num_matches_budget',
                props: {
                    component: Form.SemanticInput,
                    label: 'Matches budget',
                    name: 'num_matches_budget',
                    required: true,
                    type: 'number',
                },
            },
            notes: {
                dataMapKey: 'matchSelectionPeriod.notes',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Notes',
                    name: 'notes',
                    rows: 2,
                },
            },
        },
        mutation: {
            createEntity: CreateMatchSelectionPeriod,
            deleteEntity: DeleteMatchSelectionPeriod,
            updateEntity: UpdateMatchSelectionPeriod,
        },
        query: {
            getEntity: GetMatchSelectionPeriodForForm,
            getOptions: GetPropertyLicenceContents,
        },
    };
}
