import React from 'react';

const NotFound = () => (
    <div>
        <h1 className="not-found-page__header">Page not found</h1>
        <div>
            <p className="not-found-page__description">Sorry, the page you requested could not be found.</p>
        </div>
    </div>
);

export default NotFound;
