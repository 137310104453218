import {get as _get} from "lodash"

import ThirdPartyClientVodStorageModel from "./ThirdPartyClientVodStorageModel";

export default function ThirdPartyClientVodStorageModelEdit() {
    const thirdPartyClientVodStorageEdit = ThirdPartyClientVodStorageModel();

    thirdPartyClientVodStorageEdit.title = (data) => {
        const name = _get(data, "thirdPartyClientVodStorage.name", null);

        return `VOD storage ${name ? `"${name}"` : ''}`;
    };

    return thirdPartyClientVodStorageEdit;
}
