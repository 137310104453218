import {sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';

import {graphql} from 'react-apollo';
import {createForm, renderModalError} from '@utils/forms';
import mapModulesToProps from '@utils/mapModulesToProps';
import {routes} from '@constants/routes';
import {GetAkamaiLiveStreamNamesForList} from '@graphql/akamaiLiveStreamName/query';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {getLink} from '@appComponents/Link';
import {showModal} from '@utils/modal';
import {isUrlParamValid} from '@utils/helpers';

import AkamaiLiveStreamNameForm from '../forms/AkamaiLiveStreamNameForm';
import AkamaiLiveStreamNameModel from '../forms/AkamaiLiveStreamNameModel';
import AkamaiLiveStreamNameModelEdit from '../forms/AkamaiLiveStreamNameModelEdit';
import {exportExcel} from '../utils/export/akamaiLiveStreamNamesIndexExcel';
import AkamaiLiveStreamNamesIndexButtons from '../components/AkamaiLiveStreamNamesIndexButtons';
import AkamaiLiveStreamNamesTable from '../components/AkamaiLiveStreamNamesTable';

export class AkamaiLiveStreamNamesIndex extends React.PureComponent {
    static propTypes = {
        akamaiCdnIngestMethodId: PropTypes.number,
        liveIngestMethodId: PropTypes.number,
        clientId: PropTypes.number.isRequired,
        clientCdnConfigurationId: PropTypes.number.isRequired,
        DataAkamaiLiveStreamNames: PropTypes.shape({
            akamaiLiveStreamNames: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool,
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                akamaiLiveStreamNameId: PropTypes.string,
                clientCdnConfigurationId: PropTypes.string,
                id: PropTypes.string,
            }),
            path: PropTypes.string.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        akamaiCdnIngestMethodId: null,
        liveIngestMethodId: null,
        DataAkamaiLiveStreamNames: {
            akamaiLiveStreamNames: [],
            loading: false,
        },
        match: {
            params: {
                akamaiLiveStreamNameId: null,
                clientCdnConfigurationId: null,
                id: null,
            },
        },
    };

    state = {
        streamsTableLoaded: false,
        akamaiLiveStreamNamesSorted: false,
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
        sortedAkamaiLiveStreamNames: [],
    };

    constructor(props) {
        super(props);

        this.loadModal(props.match.path);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.clients.clientCdnConfiguration.akamaiLiveStreamName.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        return {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            DataAkamaiLiveStreamNames: {akamaiLiveStreamNames: prevAkamaiLiveStreamNames},
        } = prevProps;

        const {
            DataAkamaiLiveStreamNames: {akamaiLiveStreamNames},
        } = this.props;

        if (!this.state.streamsTableLoaded || JSON.stringify(prevAkamaiLiveStreamNames) !== JSON.stringify(akamaiLiveStreamNames)) {
            this.setState(() => ({
                akamaiLiveStreamNamesSorted: true,
                sortedAkamaiLiveStreamNames: _sortBy(akamaiLiveStreamNames, [
                    'encoding_datacenter.name', 'stream_name',
                ]),
            }));

            this.setState(() => ({
                streamsTableLoaded: true,
            }));
        }

        if (this.state.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (this.state.modalWasClosed) {
            this.props.history.push(
                getLink('clients.clientCdnConfiguration.akamaiLiveStreamName.index', {
                    id: this.props.match.params.id,
                    clientCdnConfigurationId: this.props.match.params.clientCdnConfigurationId,
                })
            );
        }
    }

    loadModal(path, params = {}) {
        let modalForm = null;

        if (!isUrlParamValid(this.props.match.params.clientCdnConfigurationId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Cdn Configuration', getLink(`clients.clientCdnConfiguration.index`, {
                    id: this.props.match.params.id,
                })),
            });
        }

        if (!isUrlParamValid(this.props.match.params.akamaiLiveStreamNameId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Akamai live stream name', getLink(`clients.clientCdnConfiguration.akamaiLiveStreamName.index`, {
                    id: this.props.match.params.id,
                    clientCdnConfigurationId: this.props.match.params.clientCdnConfigurationId,
                })),
            });
        }

        if (path === routes.clients.clientCdnConfiguration.akamaiLiveStreamName.add.path) {
            modalForm = this.loadModalForm(createForm(
                AkamaiLiveStreamNameModel,
                AkamaiLiveStreamNameForm,
                {
                    liveIngestMethodId: this.props.liveIngestMethodId,
                    akamaiCdnIngestMethodId: this.props.akamaiCdnIngestMethodId,
                    clientCdnConfigurationId: this.props.match.params.clientCdnConfigurationId
                }
            ), params);
        } else if (path === routes.clients.clientCdnConfiguration.akamaiLiveStreamName.edit.path) {
            modalForm = this.loadModalForm(createForm(
                AkamaiLiveStreamNameModelEdit,
                AkamaiLiveStreamNameForm,
                {
                    liveIngestMethodId: this.props.liveIngestMethodId,
                    akamaiCdnIngestMethodId: this.props.akamaiCdnIngestMethodId,
                    clientCdnConfigurationId: this.props.match.params.clientCdnConfigurationId,
                    clientId: this.props.match.params.id,
                    id: this.props.match.params.akamaiLiveStreamNameId || params.akamaiLiveStreamNameId
                }
            ), params);
        }

        return modalForm;
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });
    };

    render() {
        return (
            <div>
                <HeaderRenderer
                    exportExcelParams={exportExcel(
                        this.state.sortedAkamaiLiveStreamNames,
                        this.props.clientId,
                        this.props.clientCdnConfigurationId
                    )}
                    buttons={AkamaiLiveStreamNamesIndexButtons}
                    buttonsProps={{
                        clientId: this.props.clientId,
                        clientCdnConfigurationId: this.props.clientCdnConfigurationId,
                    }}
                    loading={this.props.DataAkamaiLiveStreamNames.loading || !this.state.akamaiLiveStreamNamesSorted}
                />
                <AkamaiLiveStreamNamesTable
                    clientId={this.props.clientId}
                    clientCdnConfigurationId={this.props.clientCdnConfigurationId}
                    akamaiLiveStreamNames={this.state.sortedAkamaiLiveStreamNames}
                    loading={this.props.DataAkamaiLiveStreamNames.loading || !this.state.akamaiLiveStreamNamesSorted}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

const AkamaiLiveStreamNamesWithGraphQL = compose(
    graphql(GetAkamaiLiveStreamNamesForList, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    client_cdn_configuration_id: parseInt(props.match.params.clientCdnConfigurationId, 10),
                },
            };
        },
        name: 'DataAkamaiLiveStreamNames',
    })
)(AkamaiLiveStreamNamesIndex);

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(AkamaiLiveStreamNamesWithGraphQL));
