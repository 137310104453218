import React from "react";

import Form from "@appComponents/ReduxFormControls";
import validate from "./validator";
import * as MESSAGES from "../../app/constants/messages";
import {EVENT_CONTENT_TYPE} from "../../app/constants/resources";
import {
    EVENT_TYPE_SPORTRADAR,
    EVENT_TYPE_NON_SPORTRADAR,
    MATCH_EVENT_SELECTION_TOOLTIP
} from "../../app/constants/variables";

import {GetEventContentTypeForForm, GetDataForFormDropdowns} from "../../../graphql/eventContentType/query.graphql";
import {
    CreateEventContentType,
    DeleteEventContentType,
    UpdateEventContentType
} from "../../../graphql/eventContentType/mutation.graphql";
import {IconWithTooltip} from "@appComponents/IconCollection";

export default function EventContentTypeModel() {
    return {
        entityDataMapKey: "eventContentType",
        entityLabel: "event content type",
        formName: "event content type",
        label: "event content type",
        resources: EVENT_CONTENT_TYPE,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            eventContentType: {
                id : "",
                name: "",
                external_name: "",
                event_types: null,
                sports: null,
                content_categories: null,
                distribution_types: null,
                products: null,
                has_match_event_type_selection: null,
            }
        },
        messages: {
            boxName: {
                delete: "eventContentTypeMessage",
                error: "eventContentTypeMessageError",
                success: "eventContentTypeMessage",
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "eventContentType.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "eventContentType.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            external_name: {
                dataMapKey: "eventContentType.external_name",
                props: {
                    component: Form.SemanticInput,
                    label: "External name",
                    name: "external_name",
                    required: true,
                    type: "input",
                },
            },
            distribution_types: {
                props: {
                    name: "distribution_types",
                    label: "Distribution types",
                    component: Form.SemanticCheckboxList,
                    inline: true,
                    required: true,
                }
            },
            products: {
                props: {
                    component: Form.SemanticSelect,
                    label: "Products",
                    name: "products",
                    multiple: true,
                    required: true,
                },
            },
            event_types: {
                props: {
                    name: "event_types",
                    label: "Event types",
                    component: Form.SemanticCheckboxList,
                    inline: true,
                    required: true,
                    props: {
                        options: [
                            {key: `event_type_${EVENT_TYPE_SPORTRADAR}`, value: EVENT_TYPE_SPORTRADAR, text: 'Sportradar'},
                            {key: `event_type_${EVENT_TYPE_NON_SPORTRADAR}`, value: EVENT_TYPE_NON_SPORTRADAR, text: 'Non-Sportradar'}
                        ]
                    }
                }
            },
            sports: {
                props: {
                    component: Form.SemanticSelect,
                    label: "Sports",
                    name: "sports",
                    multiple: true,
                    hidden: true,
                    placeholder: "Leave empty if you want to select all sports",
                },
            },
            content_categories: {
                props: {
                    component: Form.SemanticSelect,
                    label: "1st level categories",
                    name: "content_categories",
                    multiple: true,
                    hidden: true,
                    placeholder: "Leave empty if you want to select all 1st level categories",
                },
            },
            has_match_event_type_selection: {
                props: {
                    component: Form.SemanticInput,
                    label: "Match event type selection",
                    name: "has_match_event_type_selection",
                    type: "toggle",
                    description: <IconWithTooltip
                        name="info circle"
                        tooltip={MATCH_EVENT_SELECTION_TOOLTIP} />,
                },
            }
        },
        mutation: {
            createEntity: CreateEventContentType,
            deleteEntity: DeleteEventContentType,
            updateEntity: UpdateEventContentType,
        },
        query: {
            getEntity: GetEventContentTypeForForm,
            getOptions: GetDataForFormDropdowns,
        },
    }
}
