import {validate as clientProductValidate} from './validatorClientProduct';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, clientProductValidate(values));

    return errors;
};

export default validate;
