import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";
import Form from "../../app/components/ReduxFormControls";

import {scrollToTop} from "../../../utils/helpers";

import {toggleHandler, fillRoleResources} from "./helpers/roleResourceModelHelper";

export class RoleResourceForm extends DefaultForm {
    componentDidMount() {
        this.setUpdateSuccessCallback((response) => {
            this.props.history.push(getLink("roles.resources.index", {
                id: response.data.updateRole.id
            }));
            scrollToTop();
        });

        this.setUpdateErrorCallback(() => {
            // We need to wait some time because div with error appears not immediately
            setTimeout(scrollToTop, 500);
        });
    }

    prepareDataForSubmit = (data) => {
        const roleId = this.props.formParams.entityId || null;
        const dataToSave = {
            id: roleId,
            resources: [],
        };
        data = super.prepareDataForSubmit(data);

        fillRoleResources(data.roleResources, dataToSave.resources)

        return dataToSave;
    };

    toggleChangeHandler = (data) => {
        toggleHandler(data, this.setField)
    };

    getFieldCallback = (field) => {
        const props = {};

        if ((Form.SemanticInput == field.props.component) && ("toggle" === field.props.type)) {
            props.onCheckboxChange = this.toggleChangeHandler;
        }

        return props;
    };

    renderSaveButton = () => super.renderSaveButton({content: "Save"});

    renderDeleteButton = () => null;

    renderCancelButton = () => null;
}

export default withRouter(RoleResourceForm)
