import {get as _get} from 'lodash';

import {getLink} from '@appComponents/Link';
import {GetEventRedirectByEncodingTarget, GetEventRedirectByBooking, GetEventRedirectByStream} from '@graphql/event/query';

export const getIdFromUrl = (url) => {
    const match = url.match(/\/(\d+)(?=\/?$)/);

    return match ? match[1] : '';
};

const redirectData = {
    '/encoding-targets/:id': {
        query: GetEventRedirectByEncodingTarget,
        entityName: 'encoding target',
        redirect: (history, {eventRedirectByEncodingTarget} = {}) => {
            const eventId = _get(eventRedirectByEncodingTarget, 'event_id', null);
            const eventContentId = _get(eventRedirectByEncodingTarget, 'event_content_id', null);
            const path = getLink('events.configuration.index', {id: eventId}, {}, `#content-${eventContentId}`);

            if(eventId && eventContentId) {
                history.push(path);

                return true;
            }

            return false;
        },
    },
    '/bookings/:id': {
        query: GetEventRedirectByBooking,
        entityName: 'booking',
        redirect: (history, {eventRedirectByBooking} = {}) => {
            const eventId = _get(eventRedirectByBooking, 'event_id', null),
                bookingUrlToRedirect = history.location.pathname,
                bookingId = getIdFromUrl(bookingUrlToRedirect),
                path = getLink('events.bookings.edit', {id: eventId, bookingId}, {});

            if (eventId && bookingId) {
                history.push(path);

                return true;
            }

            return false;
        },
    },
    '/streams/:id': {
        query: GetEventRedirectByStream,
        entityName: 'stream',
        redirect: (history, {eventRedirectByStream} = {}) => {
            const eventId = _get(eventRedirectByStream, 'event_id', null);
            const streamRedirectUrl = history.location.pathname;
            const streamId = getIdFromUrl(streamRedirectUrl);
            const path = getLink('events.streams.edit', {id: eventId, streamId}, {});

            if (eventId && streamId) {
                history.push(path);

                return true;
            }

            return false;
        },
    },
};

export default redirectData;
