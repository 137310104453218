import {
    GetClientProductQbForForm,
    GetClientProductQbOptions,
} from '@graphql/clientProductQb/query';
import {
    CreateClientProductQb,
    DeleteClientProductQb,
    UpdateClientProductQb,
} from '@graphql/clientProductQb/mutation';
import Form from '@appComponents/ReduxFormControls';

import ClientProductModel from './ClientProductModel';
import validate from './validatorClientProductLco';
import {renderHeader} from './clientProductFormUtils';

export default function ClientProductQbModel() {
    let clientProductQbModel = ClientProductModel();

    clientProductQbModel.deleteId = 'clientProductsQb.0.id';
    clientProductQbModel.entityDataMapKey = 'clientProductsQb.0';
    clientProductQbModel.entityLabel = 'product QB';
    clientProductQbModel.validate = validate;
    clientProductQbModel.query = {
        getEntity: GetClientProductQbForForm,
        getOptions: GetClientProductQbOptions,
    };
    clientProductQbModel.mutation = {
        createEntity: CreateClientProductQb,
        deleteEntity: DeleteClientProductQb,
        updateEntity: UpdateClientProductQb,
    };

    clientProductQbModel.dataMap = {
        clientProductsQb: {
            id: '',
            ...clientProductQbModel.dataMap,
        },
    };

    clientProductQbModel.remapBaseDatamapKeys(clientProductQbModel);

    clientProductQbModel.fields = Object.assign({}, clientProductQbModel.fields, {
        header: {
            props: {
                component: renderHeader,
                label: 'Header',
                name: 'header',
                defaultValue: 'QB specific settings',
            },
        },
        cdn_price: {
            dataMapKey: 'clientProductsQb.0.cdn_price',
            props: {
                component: Form.SemanticInput,
                label: 'CDN price',
                labelDescription: {content: '€'},
                name: 'cdn_price',
                required: true,
            },
        },
        has_additional_cdn_bucket: {
            dataMapKey: 'clientProductsQb.0.has_additional_cdn_bucket',
            props: {
                component: Form.SemanticInput,
                label: 'Add additional CDN bucket',
                name: 'has_additional_cdn_bucket',
                type: 'checkbox',
            },
        },
        additional_cdn_bucket_size_gb: {
            dataMapKey: 'clientProductsQb.0.additional_cdn_bucket_size_gb',
            props: {
                component: Form.SemanticInput,
                hidden: true,
                label: 'Additional CDN bucket size',
                labelDescription: {content: 'Gb'},
                min: 1,
                name: 'additional_cdn_bucket_size_gb',
                required: true,
                type: 'number',
            },
        },
    });

    return clientProductQbModel;
}
