import {get as _get} from 'lodash';

import {LCR_CHANNEL} from '@constants/resources';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {UpdateLcrChannelTournament} from '@graphql/lcr/mutation';
import {GetTournamentById} from '@graphql/tournament/query';
import {GetLcrChannelTournamentFormOptionsData} from '@graphql/lcr/query';
import validators from '@utils/validators';

const TournamentModel = () => (
    {
        formName: 'LcrAdministration-contentRestrictions-sports',
        title: (data, type, props) => {
            let title = 'Add new tournament';

            if (props.formParams.id) {
                title = '';
            }

            const tournament = _get(props, 'GraphQLEntityData.tournament', null);

            if (tournament) {
                title = `${tournament.tournament_category.sport.name} > ${tournament.tournament_category.name} > ${tournament.name}`;
            }

            return title;
        },
        entityDataMapKey: 'tournament',
        label: 'tournament',
        entityLabel: 'tournament',
        resources: LCR_CHANNEL,
        showChangeLog: false,
        validate: (values) => {
            const errors = {};

            Object.assign(errors, validators.required(values.sport, 'sport'));
            Object.assign(errors, validators.required(values.tournament_category, 'tournament_category'));
            Object.assign(errors, validators.required(values.tournament, 'tournament'));

            return errors;
        },
        fields: {
            sport: {
                props: {
                    className: '--small-width',
                    component: Form.SemanticSelect,
                    required: true,
                    label: 'Sport',
                    name: 'sport',
                },
            },
            tournament_category: {
                props: {
                    component: Form.SemanticSelect,
                    required: true,
                    label: 'Category',
                    name: 'tournament_category',
                },
            },
            tournament: {
                dataMapKey: 'tournament.id',
                optionsKey: 'tournaments',
                props: {
                    component: Form.SemanticSelect,
                    required: true,
                    label: 'Tournament',
                    name: 'tournament',
                },
            },
        },
        messages: {
            boxName: {
                success: 'lcrChannelContentRestriction-tournaments',
                error: 'lcrChannelContentRestriction-tournaments',
            },
            text: {
                ...MESSAGES,
            },
        },
        mutation: {
            createEntity: UpdateLcrChannelTournament,
            updateEntity: UpdateLcrChannelTournament,
        },
        query: {
            getOptions: GetLcrChannelTournamentFormOptionsData,
            getEntity: GetTournamentById,
        },
    }
);

export default TournamentModel;
