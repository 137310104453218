import React, {useState} from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {CopyTextIcon} from '@utils/CopyTextIcon';
import mapModulesToProps from '@utils/mapModulesToProps';
import {getClientIpV6orV4} from '@modules/user/forms/helpers/userSettingsLocation';
import {convertToInt, scrollToTop} from '@utils/helpers';

import {
    GetOriginStreamUrl,
} from '../../graphql/eventContent/query.graphql';

const CopyOriginStreamUrlButton = ({client, MessageBox, eventContentId, className, position, copyText, size, isEventPage = true}) => {
    const [originStreamUrl, setOriginStreamUrl] = useState('');

    const getCopyUrl = async () => {
        const clientIp = await getClientIpV6orV4();

        return client.query({
            fetchPolicy: 'cache-first',
            query: GetOriginStreamUrl,
            variables: { eventContent: convertToInt(eventContentId), clientIp: clientIp},
        })
            .then((response) => {
                setOriginStreamUrl(response.data.originStreamUrl);

                return response;
            })
            .catch((error) => {
                MessageBox.addMessage(
                    isEventPage ? 'event' : 'liveControl',
                    'There was an error when trying to copy the origin stream url.',
                    `${error}`,
                    'error',
                    true
                );
                scrollToTop();
            });
    };


    return (
        <span onClick={getCopyUrl}>
            <CopyTextIcon
                value={originStreamUrl}
                triggerCopy={originStreamUrl}
                className={className}
                position={position}
                defaultCopyText={copyText}
                size={size}
            />
        </span>
    );
};

CopyOriginStreamUrlButton.propTypes = {
    client: PropTypes.object,
    MessageBox: PropTypes.object,
    eventContentId: PropTypes.string,
    className: PropTypes.string,
    position: PropTypes.string,
    copyText: PropTypes.string,
    size: PropTypes.string,
    isEventPage: PropTypes.bool,
};

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal']);

const mapStateToProps = (state) => ({
    modal: state.modal,
});

export default withApollo(connect(mapStateToProps ,mapDispatchToProps)(CopyOriginStreamUrlButton));
