import React from "react";
import PropTypes from "prop-types";

import {CopyTextIcon} from "../../../utils/CopyTextIcon";

export const CopyToClipboardRenderer = (props) => {
    const {text, value} = props;

    return (
        <React.Fragment>
            {text}
            <CopyTextIcon value={value} />
        </React.Fragment>
    )
};

CopyToClipboardRenderer.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};
