import {saveMessage, clearMessage} from '@actions';

import configuredStore from '../store';

export const showMessageBox = (name, header, text, type, stayVisible, addClosedTimestamp) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(saveMessage({name, header, text, type, stayVisible, addClosedTimestamp}));
        }
    );
};

export const removeMessageBox = (name) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(clearMessage({name}));
        }
    );
};

