import React from 'react';
import {
    cloneDeep as _cloneDeep,
    filter as _filter,
    find as _find,
    findKey as _findKey,
    get as _get,
    has as _has,
    isEmpty as _isEmpty,
} from 'lodash';
import {gql} from '@apollo/client';
import {withApollo} from '@apollo/client/react/hoc';
import {connect} from 'react-redux';
import {untouch} from 'redux-form';
import moment from 'moment-timezone';

import DefaultWizard from '@appComponents/DefaultWizard';
import Form from '@appComponents/ReduxFormControls';
import {convertToInt, parseTvStreamingEncoderStreamUrl} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {refetchQueryByName} from '@utils/apollo';
import {getProductsFromEventAndRightScopes} from '@utils/rightsScopeProducts';
import responseStatus from '@constants/responseStatuses';
import * as VARIABLES from '@constants/variables';
import {
    getMediaconnectOriginEntryPoints,
    getMediaconnecOriginEntryPoint,
} from '@graphql/mediaConnect/query';
import {
    optionsContactPersonsAndOriginStreamName,
    optionsOriginEntryPoints,
    propertyLicenceTechSetupProducts,
    rightsScopesOfPropertyLicence,
} from '@graphql/events/eventContent';
import {getLink} from '@appComponents/Link';
import {setErrorModal} from '@utils/modal';
import {
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_RECORDING,
    DISTRIBUTION_TYPE_VOD,
    ENCODING_END_MARGIN,
    ENCODING_START_MARGIN,
    EVENT_TYPE_SPORTRADAR,
    ORIGIN_STREAM_TYPE_SRT,
} from '@constants/variables';
import {CreateBulkDistribution, UpdateBulkDistribution} from '@graphql/distribution/mutation';
import {validatorTypes} from '@utils/validators';
import {getDistributionsPartOfProduct, isDistributionPartOfProduct} from '@utils/product';
import {sortDropdownOptionsAlphabetically} from '@utils/sorters';

import {
    getEventContentVariantDropdownOptions,
    getRightScopesByEventContentVariant, getInitialEventContentVariant,
    renderModalErrorObject,
    setCanDeleteState,
    filterActiveRtmp,
} from '../utils/eventContentHelper';
import {CANT_DELETE_CONTENT} from '../constants/messages';

const HAS_VOD_ONLY = 1;
const HAS_VOD = 2;
const HAS_NO_VOD = 3;
const DISTRIBUTION_TYPE_IDS = [
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_RECORDING,
    DISTRIBUTION_TYPE_VOD,
];

class EventsContentForm extends DefaultWizard {
    showOriginStreamTypeRtmpFields = () => {
        this.setField('rtmp_origin_entry_point_application', {hidden: false});
        this.setField('rtmp_origin_stream_name', {hidden: false});
        this.setField('udp_origin_entry_point', {hidden: true});
        this.setField('sdi_origin_entry_point', {hidden: true});
        this.setField('http_origin_entry_point_url', {hidden: true});
        this.setField('mediaconnect_origin_entry_point', {hidden: true});
        this.setField('mediaconnect_origin_feeds', {hidden: true});
        this.setField('srt_origin_entry_point_url', {hidden: true});
        this.setField('failover_srt_origin_entry_point_url', {hidden: true});
        this.setField('srt_has_scte35', {hidden: true});
        this.setField('srt_ad_splice_config', {hidden: true});
    };

    showOriginStreamTypeUdpFields = () => {
        this.setField('rtmp_origin_entry_point_application', {hidden: true});
        this.setField('rtmp_origin_stream_name', {hidden: true});
        this.setField('udp_origin_entry_point', {hidden: false});
        this.setField('sdi_origin_entry_point', {hidden: true});
        this.setField('http_origin_entry_point_url', {hidden: true});
        this.setField('mediaconnect_origin_entry_point', {hidden: true});
        this.setField('mediaconnect_origin_feeds', {hidden: true});
        this.setField('srt_origin_entry_point_url', {hidden: true});
        this.setField('failover_srt_origin_entry_point_url', {hidden: true});
        this.setField('srt_has_scte35', {hidden: true});
        this.setField('srt_ad_splice_config', {hidden: true});
    };

    showOriginStreamTypeSdiFields = () => {
        this.setField('rtmp_origin_entry_point_application', {hidden: true});
        this.setField('rtmp_origin_stream_name', {hidden: true});
        this.setField('udp_origin_entry_point', {hidden: true});
        this.setField('sdi_origin_entry_point', {hidden: false});
        this.setField('http_origin_entry_point_url', {hidden: true});
        this.setField('mediaconnect_origin_entry_point', {hidden: true});
        this.setField('mediaconnect_origin_feeds', {hidden: true});
        this.setField('srt_origin_entry_point_url', {hidden: true});
        this.setField('failover_srt_origin_entry_point_url', {hidden: true});
        this.setField('srt_has_scte35', {hidden: true});
        this.setField('srt_ad_splice_config', {hidden: true});
    };

    showOriginStreamTypeHttpFields = () => {
        this.setField('rtmp_origin_entry_point_application', {hidden: true});
        this.setField('rtmp_origin_stream_name', {hidden: true});
        this.setField('udp_origin_entry_point', {hidden: true});
        this.setField('sdi_origin_entry_point', {hidden: true});
        this.setField('http_origin_entry_point_url', {hidden: false});
        this.setField('mediaconnect_origin_entry_point', {hidden: true});
        this.setField('mediaconnect_origin_feeds', {hidden: true});
        this.setField('srt_origin_entry_point_url', {hidden: true});
        this.setField('failover_srt_origin_entry_point_url', {hidden: true});
        this.setField('srt_has_scte35', {hidden: true});
        this.setField('srt_ad_splice_config', {hidden: true});
    };

    showHasInputFields = () => {
        this.setField('contribution_type', {hidden: false});
        this.setField('encoding_datacenter', {hidden: false});
        this.setField('encoding_redundancy_type', {hidden: false});
        this.setField('production_company', {hidden: false});
        this.setField('contact_person', {hidden: false});
        this.setField('origin_stream_type', {hidden: false});
    };

    showOriginStreamTypeMediaConnectFields = () => {
        this.setField('rtmp_origin_entry_point_application', {hidden: true});
        this.setField('rtmp_origin_stream_name', {hidden: true});
        this.setField('udp_origin_entry_point', {hidden: true});
        this.setField('sdi_origin_entry_point', {hidden: true});
        this.setField('http_origin_entry_point_url', {hidden: true});
        this.setField('mediaconnect_origin_entry_point', {hidden: false});
        this.setField('srt_origin_entry_point_url', {hidden: true});
        this.setField('failover_srt_origin_entry_point_url', {hidden: true});
        this.setField('srt_has_scte35', {hidden: true});
        this.setField('srt_ad_splice_config', {hidden: true});
    };

    showOriginStreamTypeSrtFields = () => {
        this.setField('rtmp_origin_entry_point_application', {hidden: true});
        this.setField('rtmp_origin_stream_name', {hidden: true});
        this.setField('udp_origin_entry_point', {hidden: true});
        this.setField('sdi_origin_entry_point', {hidden: true});
        this.setField('http_origin_entry_point_url', {hidden: true});
        this.setField('mediaconnect_origin_entry_point', {hidden: true});
        this.setField('mediaconnect_origin_feeds', {hidden: true});
        this.setField('srt_origin_entry_point_url', {hidden: false});
        this.setField('failover_srt_origin_entry_point_url', {hidden: false});
        this.setField('srt_has_scte35', {hidden: false});
    };

    hideHasInputFields = () => {
        this.setField([
            'contribution_type',
            'encoding_datacenter',
            'encoding_redundancy_type',
            'production_company',
            'contact_person',
            'origin_stream_type',
            'rtmp_origin_entry_point_application',
            'rtmp_origin_stream_name',
            'udp_origin_entry_point',
            'sdi_origin_entry_point',
            'http_origin_entry_point_url',
            'mediaconnect_origin_entry_point',
            'mediaconnect_origin_feeds',
            'srt_origin_entry_point_url',
            'failover_srt_origin_entry_point_url',
            'srt_has_scte35',
            'srt_ad_splice_config',
        ], {hidden: true});
    };

    showFieldsBasedOnOriginStreamType = (originStreamType) => {
        switch (convertToInt(originStreamType)) {
            case VARIABLES.ORIGIN_STREAM_TYPE_RTMP:
                this.showOriginStreamTypeRtmpFields();
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_UDP:
                this.showOriginStreamTypeUdpFields();
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_SDI:
                this.showOriginStreamTypeSdiFields();
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_HTTP:
                this.showOriginStreamTypeHttpFields();
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_MEDIACONNECT:
                this.showOriginStreamTypeMediaConnectFields();
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_SRT:
                this.showOriginStreamTypeSrtFields();
                break;
        }
    };

    showAvailableProducts = (availableProducts) => {
        availableProducts.map((availableProduct) => {
            this.setField(`product_${availableProduct}`, {
                hidden: false,
            });
            this.setField(`product_${availableProduct}_divider`, {hidden: false});
        });
    };

    showAdSpliceConfigField = (hasInput, hasSrtScte35, originStreamType) => {
        if (hasInput && hasSrtScte35 && VARIABLES.ORIGIN_STREAM_TYPE_SRT === convertToInt(originStreamType)) {
            this.setField('srt_ad_splice_config', {hidden: false, required: true});
        } else {
            this.setField('srt_ad_splice_config', {hidden: true, required: false});
        }
    };

    setMediaConnectOfEncodingDataCenterInput = (encodingDatacenter, mediaConnectOriginEntryPointId) => {
        this.setField('mediaconnect_origin_entry_point', {disabled: true, loading: true});
        this.runApolloRequest('query', {
            query: getMediaconnectOriginEntryPoints,
            variables: {
                encodingDatacenter: [encodingDatacenter],
            },
            fetchPolicy: 'network-only',
        }).then((response) => {
            if (_isEmpty(response.data.mediaconnectOriginEntryPoints)) {
                this.setField('mediaconnect_origin_entry_point', {
                    disabled: false,
                    loading: false,
                    options: null,
                    defaultValue: null,
                    value: null,
                });
                this.setField('mediaconnect_origin_feeds', {
                    hidden: true,
                    defaultValue: null,
                    value: null,
                });
            } else {
                const isMediaConnectContainsOptions = (mediaconnect) => mediaconnect.id === this.props.formValues.mediaconnect_origin_entry_point,
                    isMediaConnectIdContainsInResponse = response.data.mediaconnectOriginEntryPoints.some(isMediaConnectContainsOptions);

                if (isMediaConnectIdContainsInResponse) {
                    this.setField('mediaconnect_origin_entry_point', {
                        disabled: false,
                        loading: false,
                        options: response.data.mediaconnectOriginEntryPoints,
                    });
                    this.setMediaConnectOriginFeeds(mediaConnectOriginEntryPointId);
                } else {
                    this.setField('mediaconnect_origin_entry_point', {
                        disabled: false,
                        loading: false,
                        value: null,
                        defaultValue: null,
                        options: response.data.mediaconnectOriginEntryPoints,
                    });
                    this.setField('mediaconnect_origin_feeds', {
                        hidden: true,
                        value: null,
                    });
                }
            }
        });
    }

    /** Helping methods for querying additional data from graphql */
    queryUdpAndSdiOriginEntryPointsOfEncodingDatacenter = (encodingDatacenter) => {
        this.setState(() => ({queryUdpAndSdiOriginEntryPointsOfEncodingDatacenterLoading: true}));

        return this.runApolloRequest('query', {
            query: gql`${optionsOriginEntryPoints}`,
            variables: {
                encodingDatacentersForUpd: [convertToInt(encodingDatacenter)],
                encodingDatacentersForSdi: [encodingDatacenter],
            },
        });
    };

    queryContactPersonsAndOriginStreamNameOfProductionCompany = (productionCompany) => {
        this.setState(() => ({queryContactPersonsAndOriginStreamNameOfProductionCompanyLoading: true}));

        return this.runApolloRequest('query', {
            fetchPolicy: "network-only",
            query: gql`${optionsContactPersonsAndOriginStreamName}`,
            variables: {
                productionCompany: convertToInt(productionCompany),
            },
        });
    };

    queryRightScopesOfPropertyLicence = (propertyLicence) => {
        this.setState(() => ({queryRightScopesOfPropertyLicenceLoaded: false}));

        return this.runApolloRequest('query', {
            fetchPolicy: "network-only",
            query: gql`${rightsScopesOfPropertyLicence}`,
            variables: {
                propertyLicence: convertToInt(propertyLicence),
            },
        });
    };

    queryPropertyLicenceTechSetupProducts = (propertyLicenceTechSetup) => {
        this.setState(() => ({queryPropertyLicenceTechSetupProductsLoading: true}));

        return this.runApolloRequest('query', {
            query: gql`${propertyLicenceTechSetupProducts}`,
            variables: {
                propertyLicenceTechSetup: convertToInt(propertyLicenceTechSetup),
            },
        });
    };

    queryMediaConnectOriginEntryPointsFeed = (mediaConnectOriginEntryPointId) => {

        return this.runApolloRequest('query', {
            query: getMediaconnecOriginEntryPoint,
            variables: {
                id: convertToInt(mediaConnectOriginEntryPointId),
            },
        });
    }

    mutationContentDistributionCreate = (data) => (
        this.runApolloRequest('mutate', {
            mutation: CreateBulkDistribution,
            variables: data,
        })
    );

    mutationContentDistributionUpdate = (data) => (
        this.runApolloRequest('mutate', {
            mutation: UpdateBulkDistribution,
            variables: data,
        })
    );

    /** Helping methods for parsing data */
    parseUdpOriginEntryPoints = (udpOriginEntryPoints, tournaments, contentCategories) => {
        udpOriginEntryPoints = udpOriginEntryPoints.filter((udpOriginEntryPoint) => (
            (0 === udpOriginEntryPoint.tournaments.length && 0 === udpOriginEntryPoint.content_categories.length)
            || tournaments && udpOriginEntryPoint.tournaments.find((tournament) => (tournament.id === tournaments))
            || contentCategories && udpOriginEntryPoint.content_categories.find((contentCategory) => (
                contentCategory.id === contentCategories
            ))
        ));

        return udpOriginEntryPoints.map((item) => ({
            key: item.key,
            text: item.text + " - " + item.url,
            value: item.value
        }));
    };

    selectProductsBasedOnPropertyLicenceTechSetup = (propertyLicenceTechSetups) => {
        this
            .queryPropertyLicenceTechSetupProducts(propertyLicenceTechSetups[0].id)
            .then((result) => {
                this.setInputAndDistributionFields(
                    result.data.propertyLicenceTechSetupProducts,
                    this.state.currentSelectedEventContentType
                );
                this.setState(() => ({
                    propertyLicenceTechSetupProducts: result.data.propertyLicenceTechSetupProducts,
                }));
            })
            .finally(() => {
                this.setState(() => ({
                    queryPropertyLicenceTechSetupProductsLoading: false,
                }));
            });
    };

    setInputAndDistributionFields = (propertyLicenceTechSetupProducts, currentSelectedEventContentType) => {
        const selectedContentType = _find(this.state.eventContentTypes, {
            key: currentSelectedEventContentType,
        });

        this.props.GraphQLOptionsData.productDistributionTypes.forEach((distributionType) => {
            if (this.state.availableProducts.includes(distributionType.product.id)) {
                DISTRIBUTION_TYPE_IDS.forEach((distribution) => {
                    const shouldBeShown = this.shouldDistributionBeShown(
                        distributionType.product.id,
                        distribution,
                        selectedContentType
                    );

                    this.toggleDistributionGroup(
                        distributionType.product.id,
                        distribution,
                        this.props.formParams.id ? shouldBeShown : false
                    );
                });
            }
        });

        if (0 < this.state.propertyLicenceTechSetups.length && !this.props.formParams.id) {
            let setup = this.state.propertyLicenceTechSetups[0],
                encodingDatacenterId = _get(setup, "encoding_datacenter.id", null),
                encodingRedundancyTypeId = _get(setup, "encoding_redundancy_type.id", null);

            this.setField("contribution_type", {defaultValue: _get(setup, "contribution_type.id", null)});
            this.setField("encoding_datacenter", {defaultValue: encodingDatacenterId});
            this.setField("encoding_redundancy_type", {defaultValue: encodingRedundancyTypeId});
            this.setField("origin_stream_type", {defaultValue: _get(setup, "origin_stream_type.id", null)});

            this.showFieldsBasedOnOriginStreamType(_get(setup, "origin_stream_type.id", null));

            if (encodingDatacenterId) {
                this.loadUdpAndSdi(
                    encodingDatacenterId,
                    this.getTournamentOfEvent(),
                    this.getContentCategoryOfEvent()
                );
            }
        }
    };

    isTvStreaming = () => (
        this.props.formValues.is_main_content
        && VARIABLES.ORIGIN_STREAM_TYPE_RTMP === convertToInt(_get(this.state.propertyLicenceTechSetups, '[0].origin_stream_type.id', null))
        && _get(this.state.propertyLicenceTechSetups, '[0].is_tv_streaming', false)
        && _get(this.props.formParams, 'event.sr_event.match.tv_streaming_encoder.id', false)
    );

    shouldDistributionBeShown = (product, distribution, selectedContentType, productStatus = null) => {
        const distributionsPartOfProducts = getDistributionsPartOfProduct(
            product, distribution, this.props.GraphQLOptionsData.productDistributionTypes);

        if (0 < distributionsPartOfProducts.length) {
            if (
                0 < this.isDistributionPartOfEventContentType(selectedContentType, distribution).length
                && this.hasDistributionMediaRights(product, distribution)
            ) {
                this.setDistributionGroupValues(product, distribution, productStatus);

                if (!_get(distributionsPartOfProducts, '0.is_default', false)) {
                    this.setField(`${product}_${distribution}_distribution_usage`, {defaultValue: false});
                }

                this.toggleDistributionGroup(product, distribution, true);

                this.setState(() => ({
                    [`product_${product}_distribution_type_${distribution}_always_hidden`]: false,
                }));

                return true;
            }
        }

        this.setState(() => ({
            [`product_${product}_distribution_type_${distribution}_always_hidden`]: true,
        }));

        this.toggleDistributionGroup(product, distribution, false);

        return false;
    };

    hasDistributionMediaRights = (product, distribution) => {
        const distributionInt = convertToInt(distribution);

        return this.state.filteredRightsScopes.filter((rightsScope) => (
            rightsScope.usages.find((usage) => {
                const wayOfTransmissionIdInt = convertToInt(usage.way_of_transmission.id);

                return convertToInt(product) === convertToInt(usage.product.id)
                    && (
                        (
                            VARIABLES.DISTRIBUTION_TYPE_LIVE === distributionInt
                            && (
                                VARIABLES.WAY_OF_TRANSMISSION_LIVE === wayOfTransmissionIdInt
                                || VARIABLES.WAY_OF_TRANSMISSION_DELAYED === wayOfTransmissionIdInt
                            )
                        )
                        || VARIABLES.DISTRIBUTION_TYPE_RECORDING === distributionInt
                        || (
                            VARIABLES.DISTRIBUTION_TYPE_VOD === distributionInt
                            && VARIABLES.WAY_OF_TRANSMISSION_ON_DEMAND === wayOfTransmissionIdInt
                        )
                    )
            }) !== undefined
        )).length > 0;
    };

    checkIfDistributionShouldBeShown = (product, distribution) => {
        const selectedContentType = _find(this.state.eventContentTypes, {
                key: this.state.currentSelectedEventContentType,
            }),
            isDistributionPartOfProducts = isDistributionPartOfProduct(
                product, distribution, this.props.GraphQLOptionsData.productDistributionTypes);

        return isDistributionPartOfProducts
            && this.isDistributionPartOfEventContentType(selectedContentType, distribution).length > 0
            && this.hasDistributionMediaRights(product, distribution);
    };

    isDistributionPartOfEventContentType = (eventContentType, distribution) => {
        return _filter(_get(eventContentType, "distribution_types", []), (o) => (
            convertToInt(o.id) === convertToInt(distribution)
        ));
    };

    setDistributionGroupValues = (product, distribution, productStatus) => {
        this.setField(`${product}_${distribution}_distribution_usage`, { defaultValue: true});

        if (productStatus) {
            this.setField(`${product}_${distribution}_product_status`, { defaultValue: productStatus});
        }

        this.setField(`${product}_${distribution}_start_datetime`, {
            defaultValue: this.props.formParams.event.start_datetime,
            disabled: true,
        });
        this.setField(`${product}_${distribution}_end_datetime`, {
            defaultValue: this.props.formParams.event.end_datetime,
            disabled: true,
        });
    };

    toggleDistributionGroup = (product, distribution, toggle) => {
        this.setField(`${product}_${distribution}_distribution_usage`, {hidden: !toggle});

        if (this.props.formValues[`${product}_${distribution}_distribution_usage`]) {
            this.setField(`${product}_${distribution}_product_status`, {hidden: !toggle});
            this.setField(`${product}_${distribution}_synchronise_times`, {hidden: !toggle});
            this.setField(`${product}_${distribution}_start_datetime`, {hidden: !toggle});
            this.setField(`${product}_${distribution}_end_datetime`, {hidden: !toggle});
            this.setField(`${product}_${distribution}_name`, {hidden: !toggle});
            this.setField(`${product}_${distribution}_client_selection_type`, {hidden: !toggle});
            this.setField(`${product}_${distribution}_clients`, {
                hidden: (
                    !toggle
                    || VARIABLES.CLIENT_SELECTION_TYPE_ALL === convertToInt(
                        this.props.formValues[`${product}_${distribution}_client_selection_type`]
                    )
                ),
            });

            if (VARIABLES.DISTRIBUTION_TYPE_RECORDING !== distribution) {
                this.setField(`${product}_${distribution}_broadcast_status`, {hidden: !toggle});
            }
        }
    };

    changeSynchroniseTimes = (data, product, distribution) => {
        this.setField(`${product}_${distribution}_start_datetime`, {disabled: !data.value});
        this.setField(`${product}_${distribution}_end_datetime`, {disabled: !data.value});

        if (data.value) {
            this.setField(`${product}_${distribution}_start_datetime`, {
                defaultValue: this.state[`product_${product}_distribution_type_${distribution}_start_datetime`],
            });
            this.setField(`${product}_${distribution}_end_datetime`, {
                defaultValue: this.state[`product_${product}_distribution_type_${distribution}_end_datetime`],
            });
        } else {
            this.setState(() => ({
                [`product_${product}_distribution_type_${distribution}_start_datetime`]:
                    this.props.formValues[`${product}_${distribution}_start_datetime`],
                [`product_${product}_distribution_type_${distribution}_end_datetime`]:
                    this.props.formValues[`${product}_${distribution}_end_datetime`],
            }));

            this.setField(`${product}_${distribution}_start_datetime`, {
                defaultValue: this.props.formValues.start_datetime,
            });
            this.setField(`${product}_${distribution}_end_datetime`, {
                defaultValue: this.props.formValues.end_datetime,
            });
        }
    };

    toggleClientsInputField = (data, product, distribution) => {
        this.setField(`${product}_${distribution}_clients`, {
            hidden: VARIABLES.CLIENT_SELECTION_TYPE_ALL === convertToInt(data.value),
        });
    };

    selectProductsBasedOnEventContentDistributionData = (distributions) => {
        this.props.formParams.products.map((product) => {
            DISTRIBUTION_TYPE_IDS.forEach((distributionId) => {
                const distributionSet = distributions.find((d) => (
                    convertToInt(d.distribution_type.id) === convertToInt(distributionId)
                    && convertToInt(d.product.id) === convertToInt(product.id)
                ));

                if (!distributionSet) {
                    this.setField(`${product.id}_${distributionId}_start_datetime`, {
                        defaultValue: this.props.formValues.start_datetime,
                        disabled: true,
                    });
                    this.setField(`${product.id}_${distributionId}_end_datetime`, {
                        defaultValue: this.props.formValues.end_datetime,
                        disabled: true,
                    });
                } else if (distributionSet) {
                    DISTRIBUTION_TYPE_IDS.filter((distributionForFilter) => (
                        !distributions.find((d) => (
                            convertToInt(d.distribution_type.id) === convertToInt(distributionForFilter)
                            && convertToInt(d.product.id) === convertToInt(product.id)
                        ))
                    )).forEach((distribution) => {
                        this.setField(`${product.id}_${distribution}_distribution_usage`, {
                            defaultValue: false,
                            hidden: false,
                        });
                    });
                }

                this.setState(() => ({
                    [`product_${product.id}_distribution_type_${distributionId}_start_datetime`]:
                    this.props.formValues.start_datetime,
                    [`product_${product.id}_distribution_type_${distributionId}_end_datetime`]:
                    this.props.formValues.end_datetime,
                    [`product_${product.id}_distribution_type_${distributionId}_always_hidden`]:
                        !this.checkIfDistributionShouldBeShown(product.id, distributionId),
                }));

            });
        });

        distributions.map((distribution) => {
            const product = distribution.product.id,
                distributionType = distribution.distribution_type.id;

            this.setField(`product_${product}`, {checked: true, defaultValue: true});
            this.setField(`${product}_${distributionType}_distribution_usage`, {
                defaultValue: true,
                hidden: false,
            });
            this.setField(`${product}_${distributionType}_product_status`, {
                defaultValue: distribution.product_status.id,
                hidden: false,
            });
            this.setField(`${product}_${distributionType}_name`, {
                defaultValue: distribution.name,
                hidden: false,
            });
            this.setField(`${product}_${distributionType}_client_selection_type`, {
                defaultValue: distribution.client_selection_type.id,
                hidden: false,
            });
            this.setField(`${product}_${distributionType}_clients`, {
                defaultValue: distribution.clients.map((client) => client.id),
                hidden: VARIABLES.CLIENT_SELECTION_TYPE_ALL === convertToInt(distribution.client_selection_type.id),
            });
            this.setField(`${product}_${distributionType}_synchronise_times`, {
                defaultValue: distribution.synchronise_times,
                hidden: false,
            });
            this.setField(`${product}_${distributionType}_start_datetime`, {
                defaultValue: distribution.start_datetime || this.props.formValues.start_datetime,
                disabled: distribution.synchronise_times,
                hidden: false,
                timestamp: Date.now(),
            });
            this.setField(`${product}_${distributionType}_end_datetime`, {
                defaultValue: distribution.end_datetime || this.props.formValues.end_datetime,
                disabled: distribution.synchronise_times,
                hidden: false,
                timestamp: Date.now(),
            });

            if (VARIABLES.DISTRIBUTION_TYPE_RECORDING !== convertToInt(distributionType)) {
                this.setField(`${product}_${distributionType}_broadcast_status`, {
                    defaultValue: distribution.broadcast_status ? distribution.broadcast_status.id.toString() : null,
                    hidden: false,
                });
            }
        });
    };

    additionalChangeTabsCallback = (currentStep) => {
        if (1 === currentStep) {
            if (this.props.formParams.id) {
                if (this.isTvStreaming()) {
                    this.showHideTvStreamingWarningForRtmpOriginEntryPointApplication(
                        this.props.formValues.rtmp_origin_entry_point_application
                    );
                    this.showHideTvStreamingWarningForRtmpOriginStreamName(
                        this.props.formValues.rtmp_origin_stream_name,
                        this.state.contactPersonsAndOriginStreamNameOfProductionCompany.rtmpOriginStreamNames
                    );
                } else {
                    this.setField('rtmp_origin_entry_point_application', {customWarning: null});
                    this.setField('rtmp_origin_stream_name', {customWarning: null});
                }
            }
        } else if (2 === currentStep) {
            this.changeDistributionDatetimesForAddMode();
        }
    };

    stepChangedCallback = (currentStep) => {
        const EVENT_CONTENT = "event content";

        if (currentStep === 1) {
            if(VARIABLES.ORIGIN_STREAM_TYPE_MEDIACONNECT === convertToInt(this.props.formValues.origin_stream_type)){
                this.setMediaConnectOfEncodingDataCenterInput(
                    this.props.formValues.encoding_datacenter,
                    this.props.formValues.mediaconnect_origin_entry_point
                );
            }

            if (!this.props.formParams.id) {
                this.showHasInputFields();

                if (this.isTvStreaming()) {
                    const productionCompany = `${VARIABLES.TV_STREAMING_PRODUCTION_COMPANY}`;

                    this.setField("production_company", {defaultValue: productionCompany});
                    this.onChangeCallbackProductionCompany(productionCompany);
                }

                this.setField("has_input", {checked: true, defaultValue: true});
            }

            this.props.dispatch(untouch(EVENT_CONTENT, 'contribution_type'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'encoding_datacenter'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'encoding_redundancy_type'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'production_company'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'contact_person'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'origin_stream_type'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'rtmp_origin_entry_point_application'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'rtmp_origin_stream_name'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'udp_origin_entry_point'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'sdi_origin_entry_point'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'http_origin_entry_point_url'));
            this.props.dispatch(untouch(EVENT_CONTENT, 'srt_ad_splice_config'));
        } else if (currentStep === 2) {
            this.props.formParams.products.forEach((product) => {
                this.props.dispatch(untouch(EVENT_CONTENT, `product_${product.id}`));

                DISTRIBUTION_TYPE_IDS.forEach((distribution) => {
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_distribution_usage`));
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_product_status`));
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_start_datetime`));
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_end_datetime`));
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_name`));
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_client_selection_type`));
                    this.props.dispatch(untouch(EVENT_CONTENT, `${product.id}_${distribution}_clients`));
                })
            });

            if (!this.props.formParams.id) {
                this.changeDistributionDatetimesForAddMode();

                if (this.state.propertyLicenceTechSetupProducts) {
                    this.toggleDistributionsForPropertyLicenceTechSetup();
                }
            }
        }
    };

    toggleDistributionsForPropertyLicenceTechSetup = () => {
        this.state.propertyLicenceTechSetupProducts.map((item) => {
            if (this.state.availableProducts.includes(item.product.id)) {
                let distributions = Object.keys(this.props.formValues).filter(key =>
                    key.startsWith(`${item.product.id}`)
                );

                if (distributions.length > 0) {
                    this.setField(
                        `product_${item.product.id}`,
                        {checked: true, defaultValue: true},
                        this.toggleGenerateTargets
                    );

                    DISTRIBUTION_TYPE_IDS.forEach((distribution) => {
                        this.setField(`${item.product.id}_${distribution}_product_status`, {
                            defaultValue: item.product_status.id,
                        });

                        this.toggleDistributionGroup(item.product.id, distribution, true);
                    });
                }
            }
        });
    };

    changeDistributionDatetimesForAddMode = () => {
        let newState = {};

        this.props.formParams.products.forEach((product) => {
            DISTRIBUTION_TYPE_IDS.forEach((distribution) => {
                if (this.props.formValues[`${product.id}_${distribution}_synchronise_times`]) {
                    this.setField(`${product.id}_${distribution}_start_datetime`, {
                        defaultValue: this.props.formValues.start_datetime,
                        timestamp: Date.now(),
                    });
                    this.setField(`${product.id}_${distribution}_end_datetime`, {
                        defaultValue: this.props.formValues.end_datetime,
                        timestamp: Date.now(),
                    });

                    newState = Object.assign(newState, {
                        [`product_${product.id}_distribution_type_${distribution}_start_datetime`]:
                        this.props.formValues.start_datetime,
                        [`product_${product.id}_distribution_type_${distribution}_end_datetime`]:
                        this.props.formValues.end_datetime,
                    });
                }
            });
        });

        this.setState(() => (newState));
    };

    loadUdpAndSdi = (encoding_datacenter, tournaments, content_categories) => {
        this.setField("udp_origin_entry_point", { disabled: true, loading: true });
        this.setField("sdi_origin_entry_point", { disabled: true, loading: true });

        this
            .queryUdpAndSdiOriginEntryPointsOfEncodingDatacenter(encoding_datacenter)
            .then((data) => {
                this.setField("udp_origin_entry_point", {
                    disabled: false,
                    loading: false,
                    options: this.parseUdpOriginEntryPoints(
                        data.data.udpOriginEntryPoints,
                        tournaments,
                        content_categories
                    )
                });
                this.setField("sdi_origin_entry_point", {
                    disabled: false,
                    loading: false,
                    options: data.data.sdiOriginEntryPoints
                });
            })
            .finally(() => {
                this.setState(() => ({queryUdpAndSdiOriginEntryPointsOfEncodingDatacenterLoading: false}));
            });

    };

    getTournamentOfEvent = () => {
        const event = this.props.formParams.event;

        if (_get(event, "sr_event.match.tournament", null)) {
            return event.sr_event.match.tournament.id
        } else {
            return _get(event, "sr_event.tournament.id", null)
        }
    };

    getContentCategoryOfEvent = () => {
        const event = this.props.formParams.event;

        return event.av_event ? event.av_event.content_category.id : null;
    };

    isSrNonMasterEvent = () => {
        return this.props.formParams.event.sr_event && !this.props.formParams.event.is_master
    };

    getTvStreamingEncoderStreamUrl = () => (
        _get(this.props.formParams, 'event.sr_event.match.tv_streaming_encoder.stream_url', false)
    );

    showHideTvStreamingWarningForRtmpOriginEntryPointApplication = (value) => {
        if (
            this.props.formParams.id
            && value
            && this.isTvStreaming()
        ) {
            const streamUrl = this.getTvStreamingEncoderStreamUrl(),
                parsedUrl = parseTvStreamingEncoderStreamUrl(streamUrl);

            const {
                GraphQLOptionsData: {rtmpOriginEntryPointApplications},
            } = this.props;

            const rtmpOriginEntryPointApplication = rtmpOriginEntryPointApplications.find((application) => (
                parsedUrl
                && value === application.id
                && parsedUrl.host === application.rtmp_origin_entry_point.host
                && parsedUrl.path === application.rtmp_origin_entry_point_application_type.path
            ));

            this.setField('rtmp_origin_entry_point_application', {
                customWarning: (rtmpOriginEntryPointApplication)
                    ? null
                    : VARIABLES.TV_STREAMING_RTMP_ORIGIN_ENTRY_POINT_APPLICATION_WARNING_EDIT_MODE(streamUrl),
            });
        } else {
            this.setField('rtmp_origin_entry_point_application', {customWarning: null});
        }
    };

    showHideTvStreamingWarningForRtmpOriginStreamName = (value, rtmpOriginStreamNames) => {
        if (
            this.props.formParams.id
            && value
            && this.isTvStreaming()
        ) {
            const streamUrl = this.getTvStreamingEncoderStreamUrl(),
                parsedUrl = parseTvStreamingEncoderStreamUrl(streamUrl);

            const rtmpOriginStreamName = rtmpOriginStreamNames.find((streamName) => (
                parsedUrl
                && streamName.key === value
                && streamName.text === parsedUrl.streamName
            ));

            this.setField('rtmp_origin_stream_name', {
                customWarning: (rtmpOriginStreamName)
                    ? null
                    : VARIABLES.TV_STREAMING_RTMP_ORIGIN_STREAM_NAME_WARNING_EDIT_MODE(streamUrl),
            });
        } else {
            this.setField('rtmp_origin_stream_name', {customWarning: null});
        }
    };

    onChangeCallbackProductionCompany = (value) => {
        if (value) {
            this.setField("contact_person", {defaultValue: null, disabled: true, loading: true});
            this.setField("rtmp_origin_stream_name", {
                defaultValue: null,
                disabled: true,
                loading: true,
            });

            this.queryContactPersonsAndOriginStreamNameOfProductionCompany(value)
                .then((data) => {
                    const contactPersonFieldData = {
                        disabled: false,
                        loading: false,
                        options: data.data.contactPersons,
                    };

                    if (
                        this.isTvStreaming()
                        && !this.props.formParams.id
                        && VARIABLES.TV_STREAMING_PRODUCTION_COMPANY === convertToInt(value)
                    ) {
                        contactPersonFieldData.defaultValue = `${VARIABLES.TV_STREAMING_CONTACT_PERSON}`;

                        const {
                            formParams,
                            GraphQLOptionsData: {rtmpOriginEntryPointApplications},
                        } = this.props;

                        const streamUrl = _get(formParams, 'event.sr_event.match.tv_streaming_encoder.stream_url', false),
                            parsedUrl = parseTvStreamingEncoderStreamUrl(streamUrl);

                        const rtmpOriginEntryPointApplication = rtmpOriginEntryPointApplications.find((application) => (
                            parsedUrl
                            && parsedUrl.host === application.rtmp_origin_entry_point.host
                            && parsedUrl.path === application.rtmp_origin_entry_point_application_type.path
                        ));

                        const rtmpOriginStreamName = data.data.rtmpOriginStreamNames.find((streamName) => (
                            parsedUrl && streamName.text === parsedUrl.streamName
                        ));

                        if (rtmpOriginEntryPointApplication) {
                            this.setField('rtmp_origin_entry_point_application', {
                                defaultValue: rtmpOriginEntryPointApplication.value,
                            });
                        } else {
                            this.setField('rtmp_origin_entry_point_application', {
                                customWarning:
                                    VARIABLES.TV_STREAMING_RTMP_ORIGIN_ENTRY_POINT_APPLICATION_WARNING_ADD_MODE(streamUrl),
                            });
                        }

                        if (rtmpOriginStreamName) {
                            this.setField('rtmp_origin_stream_name', {
                                defaultValue: rtmpOriginStreamName.value,
                            });
                        } else {
                            this.setField('rtmp_origin_stream_name', {
                                customWarning: VARIABLES.TV_STREAMING_RTMP_ORIGIN_STREAM_NAME_WARNING_ADD_MODE(streamUrl),
                            });
                        }
                    }

                    this.setField("contact_person", contactPersonFieldData);
                    this.setField("rtmp_origin_stream_name", {
                        disabled: false,
                        loading: false,
                        options: data.data.rtmpOriginStreamNames,
                    });
                    this.setState(() => ({
                        contactPersonsAndOriginStreamNameOfProductionCompany: data.data,
                    }));
                })
                .finally(() => {
                    this.setState(() => ({
                        queryContactPersonsAndOriginStreamNameOfProductionCompanyLoading: false,
                    }));
                });
        }
    };

    onChangeCallbackMediaConnectOriginEntryPoint = (value) => {
        if (value) {
            this.queryMediaConnectOriginEntryPointsFeed(value)
                .then((data) => {
                    if (data.data.mediaconnectOriginEntryPoint.mediaconnect_origin_feeds.length === 2) {
                        this.setField("mediaconnect_origin_feeds", {hidden: false, defaultValue: '2,1'});
                    } else {
                        this.setField("mediaconnect_origin_feeds", {hidden: true, defaultValue: '1'});
                    }
                });
        } else {
            this.setField('mediaconnect_origin_feeds', {
                hidden: true,
                value: null,
                defaultValue: null,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (nextProps.submitting) {
            return;
        }

        const {
            formParams,
            formValues,
            GraphQLOptionsData: {
                eventContentTypes,
                loading,
            },
        } = nextProps;

        if (
            this.state.defaultForm_data
            && this.state.defaultForm_data.event_content
            && !loading
        ) {
            this.prepareFormFieldsForEditing(
                nextProps.GraphQLEntityData.event_content,
                eventContentTypes,
                formParams,
                formValues
            );
        } else {
            this.prepareForm(formParams, formValues, loading);
        }

        this.setState(() => ({
            eventContentTypes: eventContentTypes,
        }));
    }

    prepareFormFieldsForEditing(eventContent, eventContentTypes, formParams, formValues) {
        this.setState(() => ({
            currentSelectedEventContentType: eventContent.event_content_type.id,
        }));

        /* General Info Form */
        this.setGeneralTabForm(eventContent, eventContentTypes, formValues);

        /* Input Form */
        this.setInputTabForm(formValues);

        /* Distribution Form */
        this.setDistributionTabForm(formParams);
    }

    setDistributionTabForm(formParams) {
        this.setAvailableProducts(formParams, true);
        this.setField("event_content_type", {disabled: true});
    }

    changeAvailableProducts(formParams, isProcessingDistributions) {
        const rightScopesOfPropertyLicence = this.state.RightScopesOfPropertyLicence;

        if (rightScopesOfPropertyLicence) {
            let availableProducts = [];

            const filteredRightsScopes = rightScopesOfPropertyLicence.data.rightsScopes.map(
                scope => getRightScopesByEventContentVariant(scope, this.state.selectedEventContentVariant)
            );
            this.setState(() => ({
                rightsScopes: rightScopesOfPropertyLicence.data.rightsScopes,
                filteredRightsScopes: filteredRightsScopes,
                propertyLicenceTechSetups: rightScopesOfPropertyLicence.data.propertyLicenceTechSetups,
            }), () => {
                availableProducts = getProductsFromEventAndRightScopes(formParams.event, rightScopesOfPropertyLicence.data.rightsScopes);
                this.showAvailableProducts(availableProducts);

                if (isProcessingDistributions) {
                    this.selectProductsBasedOnEventContentDistributionData(
                        this.state.defaultForm_data.event_content.distributions
                    );
                } else {
                    if (0 < rightScopesOfPropertyLicence.data.propertyLicenceTechSetups.length) {
                        this.selectProductsBasedOnPropertyLicenceTechSetup(
                            this.state.propertyLicenceTechSetups
                        );
                    }
                }

                this.setState(() => ({
                    availableProducts: availableProducts,
                    processingDistributions: false,
                }));
            });
        }
    }

    setAvailableProducts(formParams, isProcessingDistributions) {
        if (this.state.availableProducts !== undefined || this.state.queryRightScopesOfPropertyLicenceLoaded) {
            return;
        }

        if (isProcessingDistributions) {
            this.setState(() => ({processingDistributions: true}));
        }

        this
            .queryRightScopesOfPropertyLicence(formParams.event.property_licence.id)
            .then((result) => {
                //@TODO use method to get only valid right scopes
                // https://jira.sportradar.ag/browse/AVCMP-3822
                const validRightScopes = [];
                const resultPropertyLicenceRightScopes = _cloneDeep(result);

                // Filter out expired right scopes
                result.data.rightsScopes.map((rightScope) => {
                    if (moment(formParams.event.start_datetime)
                        .isBetween(rightScope.start_date, rightScope.end_date, null, '[]')
                    ) {
                        validRightScopes.push(rightScope);
                    }
                });

                resultPropertyLicenceRightScopes.data.rightsScopes = validRightScopes;

                const contentVariantOptions = getEventContentVariantDropdownOptions(
                        formParams.event,
                        resultPropertyLicenceRightScopes.data.rightsScopes
                    ),
                    selectedContentVariant = getInitialEventContentVariant(formParams, contentVariantOptions);

                this.setField('event_content_variant', {
                    options: contentVariantOptions,
                    defaultValue: selectedContentVariant,
                });

                this.setState(() => ({
                    RightScopesOfPropertyLicence: resultPropertyLicenceRightScopes,
                    selectedEventContentVariant: selectedContentVariant,
                    queryRightScopesOfPropertyLicenceLoaded: true,
                }), () => {
                    this.changeAvailableProducts(formParams, isProcessingDistributions);
                });
            });
    }

    setMediaConnectOriginFeeds = (mediaconnectOriginEntryPointId) => {
        if (mediaconnectOriginEntryPointId) {
            this.queryMediaConnectOriginEntryPointsFeed(mediaconnectOriginEntryPointId)
                .then((data) => {
                    if (data.data.mediaconnectOriginEntryPoint.mediaconnect_origin_feeds.length === 2) {
                        this.setField('mediaconnect_origin_feeds', {hidden: false});
                    }
                });
        }
    }

    setInputTabForm(formValues) {
        const originStreamType = convertToInt(formValues.origin_stream_type);

        if (formValues.has_input) {
            this.setField(
                [
                    "contribution_type",
                    "encoding_datacenter",
                    "encoding_redundancy_type",
                    "production_company",
                    "contact_person",
                    "origin_stream_type"
                ],
                {hidden: false}
            );

            this.showFieldsBasedOnOriginStreamType(originStreamType);
            this.setField("has_input", {checked: true});

            if (VARIABLES.ORIGIN_STREAM_TYPE_MEDIACONNECT === originStreamType) {
                this.setMediaConnectOfEncodingDataCenterInput(
                    formValues.encoding_datacenter,
                    formValues.mediaconnect_origin_entry_point
                );
            }
        }

        this.showAdSpliceConfigField(formValues.has_input, formValues.srt_has_scte35, originStreamType);

        if (
            !this.state.udpAndSdiOriginEntryPointsOfEncodingDatacenter
            && (
                VARIABLES.ORIGIN_STREAM_TYPE_UDP === originStreamType
                || VARIABLES.ORIGIN_STREAM_TYPE_SDI === originStreamType
            )
            && (
                this.state.queryUdpAndSdiOriginEntryPointsOfEncodingDatacenterLoading === undefined
                || !this.state.queryUdpAndSdiOriginEntryPointsOfEncodingDatacenterLoading
            )
        ) {
            this.setField(["udp_origin_entry_point", "sdi_origin_entry_point"], {disabled: true, loading: true});

            this
                .queryUdpAndSdiOriginEntryPointsOfEncodingDatacenter(
                    formValues.encoding_datacenter,
                )
                .then((data) => {
                    this.setField("udp_origin_entry_point", {
                        defaultValue: formValues.udp_origin_entry_point,
                        disabled: false,
                        loading: false,
                        options: this.parseUdpOriginEntryPoints(
                            data.data.udpOriginEntryPoints,
                            this.getTournamentOfEvent(),
                            this.getContentCategoryOfEvent()
                        ),
                    });

                    this.setField("sdi_origin_entry_point", {
                        defaultValue: formValues.sdi_origin_entry_point,
                        disabled: false,
                        loading: false,
                        options: data.data.sdiOriginEntryPoints
                    });

                    this.setState(() => ({
                        udpAndSdiOriginEntryPointsOfEncodingDatacenter: data.data,
                    }));
                })
                .finally(() => {
                    this.setState(() => ({queryUdpAndSdiOriginEntryPointsOfEncodingDatacenterLoading: false}));
                });
        }

        if (
            !this.state.contactPersonsAndOriginStreamNameOfProductionCompany
            && !this.state.queryContactPersonsAndOriginStreamNameOfProductionCompanyLoading
            && formValues.production_company
        ) {
            this.setField(["contact_person", "rtmp_origin_stream_name"], {disabled: true, loading: true});
            this
                .queryContactPersonsAndOriginStreamNameOfProductionCompany(formValues.production_company)
                .then((data) => {
                    this.setField("contact_person", {
                        defaultValue: formValues.contact_person || null,
                        disabled: false,
                        loading: false,
                        options: data.data.contactPersons,
                    });

                    this.setField("rtmp_origin_stream_name", {
                        defaultValue: formValues.rtmp_origin_stream_name,
                        disabled: false,
                        loading: false,
                        options: data.data.rtmpOriginStreamNames,
                    });

                    this.setState(() => ({
                        contactPersonsAndOriginStreamNameOfProductionCompany: data.data,
                    }));
                })
                .finally(() => {
                    this.setState(() => ({
                        queryContactPersonsAndOriginStreamNameOfProductionCompanyLoading: false,
                    }));
                });
        }

        this.showHideTvStreamingWarningForRtmpOriginEntryPointApplication(
            formValues.rtmp_origin_entry_point_application
        );

        if (this.state.contactPersonsAndOriginStreamNameOfProductionCompany) {
            this.showHideTvStreamingWarningForRtmpOriginStreamName(
                formValues.rtmp_origin_stream_name,
                this.state.contactPersonsAndOriginStreamNameOfProductionCompany.rtmpOriginStreamNames
            );
        }
    }

    setGeneralTabForm(eventContent, eventContentTypes, formValues) {
        this.showFieldsByEventContentType(
            this.selectedEventContentDistribution(eventContent.event_content_type.id, eventContentTypes)
        );

        const hasLiveDistribution = _find(eventContent.event_content_type.distribution_types,
            {id: VARIABLES.DISTRIBUTION_TYPE_LIVE + ""});

        if (hasLiveDistribution && this.isSrNonMasterEvent()) {
            this.setField('start_broadcast_automatically', {
                hidden: false,
                disabled: false,
            });
            this.setField('set_as_delivered_automatically', {
                hidden: false,
                disabled: false,
            });
        }

        this.setField("is_247_stream", {hidden: !hasLiveDistribution, disabled: !hasLiveDistribution});

        if (!this.state.event_content_start_datetime
            && !this.state.event_content_end_datetime
        ) {
            const startDateTime = formValues.start_datetime || eventContent.start_datetime;
            const endDateTime = formValues.end_datetime || eventContent.end_datetime;

            this.setState(() => ({
                event_content_start_datetime: startDateTime,
                event_content_end_datetime: endDateTime,
            }));

            this.setField("start_datetime", {
                defaultValue: startDateTime,
                disabled: formValues.synchronise_times,
                timestamp: Date.now(),
            });
            this.setField("end_datetime", {
                defaultValue: endDateTime,
                disabled: formValues.synchronise_times,
                timestamp: Date.now(),
            });
        }
    }

    prepareForm(formParams, formValues, loading) {
        if (formParams.id || loading) {
            return;
        }

        if (
            formParams
            && !this.state.event_content_start_datetime
            && !this.state.event_content_end_datetime
        ) {
            this.setState(() => ({
                event_content_start_datetime: formParams.event.start_datetime,
                event_content_end_datetime: formParams.event.end_datetime,
            }));

            this.setField("start_datetime", {
                defaultValue: formParams.event.start_datetime,
                disabled: formValues.synchronise_times,
            });
            this.setField("end_datetime", {
                defaultValue: formParams.event.end_datetime,
                disabled: formValues.synchronise_times,
            });
        }

        if (!formValues.production_company) {
            this.setField(["contact_person", "rtmp_origin_stream_name"], {disabled: true, loading: false});
        }

        if (!formValues.encoding_datacenter) {
            this.setField(["udp_origin_entry_point", "sdi_origin_entry_point"], {disabled: true, loading: false});
        }

        this.setAvailableProducts(formParams, false);
        this.showAdSpliceConfigField(formValues.has_input, formValues.srt_has_scte35, formValues.origin_stream_type);
    }

    prepareDataForSubmit = (data) => {
        this.setState(() => ({submitData: data}));

        return this.prepareData(data);
    };

    getGenerateTargetsCheckboxValue = () => (
        !this.state.defaultForm_fields.generateTargets.props.hidden
            ? this.state.submitData.generateTargets
            : false
    );

    prepareData = (data) => {
        let event_content = Object.assign(
                {},
                this.props.Model.dataMap[this.props.Model.entityDataMapKey],
                data
            ),
            id = event_content.id,
            dataToSave = Object.assign(
                {},
                this.prepareGeneralInformationData(data),
                this.prepareInputData(data)
            );

        if (id) {
            dataToSave = Object.assign(
                dataToSave,
                {id: convertToInt(id)}
            );
        } else {
            dataToSave.event = convertToInt(this.props.formParams.event.id);
        }

        return dataToSave;
    };

    prepareGeneralInformationData = (data) => {
        let startBroadcastAutomatically = data.start_broadcast_automatically || false;
        let setAsDeliveredAutomatically = data.set_as_delivered_automatically || false;

        if (this.state.defaultForm_fields.start_broadcast_automatically.props.disabled) {
            startBroadcastAutomatically = false;
        }

        if (this.state.defaultForm_fields.set_as_delivered_automatically.props.disabled) {
            setAsDeliveredAutomatically = false;
        }

        const dataToSave = {
            event_content_type: convertToInt(data.event_content_type),
            event_content_variant: convertToInt(data.event_content_variant),
            is_247_stream: data.is_247_stream || false,
            is_main_content: data.is_main_content || false,
            name: data.name || null,
            start_broadcast_automatically: startBroadcastAutomatically,
            set_as_delivered_automatically: setAsDeliveredAutomatically,
        };

        if (this.props.formParams.id) {
            dataToSave.hide_in_live_control = data.hide_in_live_control || false;
        }

        const endDatetime = data.end_datetime ? data.end_datetime + ":00" : null;

        switch (this.selectedEventContentDistribution(data.event_content_type, this.state.eventContentTypes)) {
            case HAS_NO_VOD:
                dataToSave.video_length = null;
                dataToSave.video_duration = null;
                dataToSave.synchronise_times = data.synchronise_times;
                dataToSave.start_datetime = data.start_datetime + ":00";
                dataToSave.end_datetime = endDatetime;
                break;
            default:
                dataToSave.video_length = data.video_length ? convertToInt(data.video_length) : null;
                dataToSave.video_duration = data.video_duration ? convertToInt(data.video_duration) : null;
                dataToSave.synchronise_times = data.synchronise_times;
                dataToSave.start_datetime = data.start_datetime ? data.start_datetime + ":00" : null;
                dataToSave.end_datetime = endDatetime;
        }

        return dataToSave;
    };

    prepareInputData = (data) => {
        if (!data.has_input) {
            return {
                has_input: false,
                event_content_input: {},
            };
        }

        let dataToSave = {
                has_input: data.has_input,
                event_content_input: {
                    contribution_type: convertToInt(data.contribution_type),
                    encoding_datacenter: convertToInt(data.encoding_datacenter),
                    encoding_redundancy_type: convertToInt(data.encoding_redundancy_type),
                    production_company: convertToInt(data.production_company),
                    contact_person: convertToInt(data.contact_person),
                    origin_stream_type: convertToInt(data.origin_stream_type),
                },
            }, additionalData = {};

        switch (convertToInt(data.origin_stream_type)) {
            case VARIABLES.ORIGIN_STREAM_TYPE_RTMP:
                additionalData = {
                    event_content_input: {
                        rtmp_origin_entry_point_application: convertToInt(data.rtmp_origin_entry_point_application),
                        rtmp_origin_stream_name: convertToInt(data.rtmp_origin_stream_name) || null,
                        udp_origin_entry_point: null,
                        sdi_origin_entry_point: null,
                        http_origin_entry_point_url: null,
                        mediaconnect_origin_entry_point: null,
                        mediaconnect_origin_feeds: null,
                        srt_origin_entry_point_url: null,
                        failover_srt_origin_entry_point_url: null,
                        is_srt_origin_entry_point_in_failover: null,
                        srt_has_scte35: null,
                        srt_ad_splice_config: null,
                    },
                };
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_UDP:
                additionalData = {
                    event_content_input: {
                        rtmp_origin_entry_point_application: null,
                        rtmp_origin_stream_name: null,
                        udp_origin_entry_point: convertToInt(data.udp_origin_entry_point),
                        sdi_origin_entry_point: null,
                        http_origin_entry_point_url: null,
                        mediaconnect_origin_entry_point: null,
                        mediaconnect_origin_feeds: null,
                        srt_origin_entry_point_url: null,
                        failover_srt_origin_entry_point_url: null,
                        is_srt_origin_entry_point_in_failover: null,
                        srt_has_scte35: null,
                        srt_ad_splice_config: null,
                    },
                };
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_SDI:
                additionalData = {
                    event_content_input: {
                        rtmp_origin_entry_point_application: null,
                        rtmp_origin_stream_name: null,
                        udp_origin_entry_point: null,
                        sdi_origin_entry_point: convertToInt(data.sdi_origin_entry_point),
                        http_origin_entry_point_url: null,
                        mediaconnect_origin_entry_point: null,
                        mediaconnect_origin_feeds: null,
                        srt_origin_entry_point_url: null,
                        failover_srt_origin_entry_point_url: null,
                        is_srt_origin_entry_point_in_failover: null,
                        srt_has_scte35: null,
                        srt_ad_splice_config: null,
                    },
                };
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_HTTP:
                additionalData = {
                    event_content_input: {
                        rtmp_origin_entry_point_application: null,
                        rtmp_origin_stream_name: null,
                        udp_origin_entry_point: null,
                        sdi_origin_entry_point: null,
                        http_origin_entry_point_url: data.http_origin_entry_point_url || null,
                        mediaconnect_origin_entry_point: null,
                        mediaconnect_origin_feeds: null,
                        srt_origin_entry_point_url: null,
                        failover_srt_origin_entry_point_url: null,
                        is_srt_origin_entry_point_in_failover: null,
                        srt_has_scte35: null,
                        srt_ad_splice_config: null,
                    },
                };
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_MEDIACONNECT:
                additionalData = {
                    event_content_input: {
                        rtmp_origin_entry_point_application: null,
                        rtmp_origin_stream_name: null,
                        udp_origin_entry_point: null,
                        sdi_origin_entry_point: null,
                        http_origin_entry_point_url: null,
                        mediaconnect_origin_entry_point: convertToInt(data.mediaconnect_origin_entry_point),
                        mediaconnect_origin_feeds: data.mediaconnect_origin_feeds || null,
                        srt_origin_entry_point_url: null,
                        failover_srt_origin_entry_point_url: null,
                        is_srt_origin_entry_point_in_failover: null,
                        srt_has_scte35: null,
                        srt_ad_splice_config: null,
                    },
                };
                break;
            case VARIABLES.ORIGIN_STREAM_TYPE_SRT:
                additionalData = {
                    event_content_input: {
                        rtmp_origin_entry_point_application: null,
                        rtmp_origin_stream_name: null,
                        udp_origin_entry_point: null,
                        sdi_origin_entry_point: null,
                        http_origin_entry_point_url: null,
                        mediaconnect_origin_entry_point: null,
                        mediaconnect_origin_feeds: null,
                        srt_origin_entry_point_url: data.srt_origin_entry_point_url || null,
                        failover_srt_origin_entry_point_url: data.failover_srt_origin_entry_point_url || null,
                        is_srt_origin_entry_point_in_failover: data.failover_srt_origin_entry_point_url
                            ? data.is_srt_origin_entry_point_in_failover : null,
                        srt_has_scte35: data.srt_has_scte35 || null,
                        srt_ad_splice_config: !data.srt_has_scte35 ? null : convertToInt(data.srt_ad_splice_config),
                    },
                };
                break;
        }

        dataToSave.event_content_input = Object.assign(
            dataToSave.event_content_input,
            additionalData.event_content_input
        );

        if (dataToSave.has_input && !data.eventContentInputId) {
            const event = this.props.formParams.event,
                sportsWithMarginNull = [
                    VARIABLES.SPORT_TENNIS,
                    VARIABLES.SPORT_BADMINTON,
                ];

            dataToSave.event_content_input.encoding_start_margin = ENCODING_START_MARGIN;

            let sportId = null;

            if (event.sr_event) {
                if (event.sr_event.match && event.sr_event.match.tournament) {
                    sportId = convertToInt(event.sr_event.match.tournament.tournament_category.sport.id);
                } else if (event.sr_event.master_match && event.sr_event.master_match.sport) {
                    sportId = convertToInt(event.sr_event.master_match.sport.id);
                } else {
                    sportId = convertToInt(event.sr_event.tournament.tournament_category.sport.id);
                }
            }

            if (sportsWithMarginNull.includes(sportId)) {
                dataToSave.event_content_input.encoding_start_margin = null;
            }

            const eventTypeId = this.props.formParams.event.event_type.id;
            const isMaster = this.props.formParams.event.is_master;

            dataToSave.event_content_input.encoding_end_margin = null !== data.end_datetime || (EVENT_TYPE_SPORTRADAR === convertToInt(eventTypeId) && !isMaster)
                ? ENCODING_END_MARGIN
                : null;
        }

        return dataToSave;
    };

    prepareDistributionData = (data) => {
        let distributionsNestedMap = {};

        this.props.formParams.products.forEach((product) => {
            distributionsNestedMap[`${product.id}_1`] = {
                product: convertToInt(product.id),
                distribution_type: VARIABLES.DISTRIBUTION_TYPE_LIVE,
            };
            distributionsNestedMap[`${product.id}_2`] = {
                product: convertToInt(product.id),
                distribution_type: VARIABLES.DISTRIBUTION_TYPE_RECORDING,
            };
            distributionsNestedMap[`${product.id}_3`] = {
                product: convertToInt(product.id),
                distribution_type: VARIABLES.DISTRIBUTION_TYPE_VOD,
            };
        });

        const distributions = [];

        for (const key of Object.keys(data)) {
            Object.keys(distributionsNestedMap).forEach((nestedKey) => {
                if (key.startsWith(nestedKey)) {
                    distributionsNestedMap[nestedKey][key.replace(`${nestedKey}_`, '')] = data[key];
                }
            });
        }

        Object.keys(distributionsNestedMap).forEach((nestedKey) => {
            // Product id build in this way "productID_distributionTypeId", ex. "10_1", "1_2", etc...
            const productId = nestedKey.split('_').shift();

            if (data[`product_${productId}`]) {
                const preparedDistribution = this.prepareDistriubtionForSubmit(distributionsNestedMap[nestedKey], data);

                if (null !== preparedDistribution) {
                    distributions.push(preparedDistribution);
                }
            }
        });

        return {
            distributions: distributions.filter((e) => (e)),
        };
    };

    prepareDistriubtionForSubmit = (distribution, data) => {
        const distriubtionMap = {
            product: '',
            distribution_type: '',
            product_status: '',
            broadcast_status: null,
            synchronise_times: false,
            start_datetime: null,
            end_datetime: null,
            name: null,
        };

        let isDistributionHidden = this.state[
            `product_${distribution.product}_distribution_type_${distribution.distribution_type}_always_hidden`
        ];

        if (!distribution.distribution_usage || isDistributionHidden) {
            return null;
        }

        delete distribution.distribution_usage;

        const preparedDistribution = Object.assign(distriubtionMap, distribution);

        preparedDistribution.product_status = convertToInt(preparedDistribution.product_status);

        if (preparedDistribution.synchronise_times) {
            preparedDistribution.start_datetime = data.start_datetime
                ? data.start_datetime + ":00"
                : null;
            preparedDistribution.end_datetime = data.end_datetime
                ? data.end_datetime + ":00"
                : null;
        }

        preparedDistribution.clients = (VARIABLES.CLIENT_SELECTION_TYPE_ALL !== convertToInt(distribution.client_selection_type))
            ? distribution.clients
            : [];

        preparedDistribution.broadcast_status = VARIABLES.DISTRIBUTION_TYPE_RECORDING !== distribution.distribution_type
            ? convertToInt(distribution.broadcast_status)
            : null;

        return preparedDistribution;
    };

    selectedEventContentDistribution = (selectedValue, eventContentTypes) => {
        if (!eventContentTypes) {
            return false;
        }

        const selectedEventContent = _find(eventContentTypes, {key: selectedValue});

        let hasVod = selectedEventContent.distribution_types.some((distribution_type) => (
            VARIABLES.DISTRIBUTION_TYPE_VOD === convertToInt(distribution_type.id)
        ));

        if (hasVod && selectedEventContent.distribution_types.length === 1) {
            return HAS_VOD_ONLY;
        } else if (hasVod) {
            return HAS_VOD;
        }

        return HAS_NO_VOD;
    };

    hasAlreadyMainContent = (eventContents) => (eventContents.some((item) => (item.is_main_content === true)));

    toggleGenerateTargets = (fields) => {
        const hasNewDistributionSelected = this.hasNewDistributionSelected(
                fields,
                _get(this.props, 'GraphQLEntityData.event_content.distributions', []),
                this.props.formParams.products
            ),
            hideGenerateTargets = !(hasNewDistributionSelected && !!this.state.propertyLicenceTechSetups.length),
            hideGenerateTargetsWarning = !(hasNewDistributionSelected && !this.state.propertyLicenceTechSetups.length);

        this.setField("generateTargets", {
            hidden: hideGenerateTargets,
        });
        this.setField("generateTargetsWarning", {
            hidden: hideGenerateTargetsWarning,
        });
    };

    hasNewDistributionSelected = (fields, existingDistributions, products) => (
        0 < products.filter((product) => (
            0 < DISTRIBUTION_TYPE_IDS.filter((distribution) => {
                const productStateKey = _findKey(fields, (field) => (
                        `product_${product.id}` === _get(field, 'props.name', '')
                    )),
                    distributionTypeStateKey = _findKey(fields, (field) => (
                        `${product.id}_${distribution}_distribution_usage` === _get(field, 'props.name', '')
                    ));

                return (
                    _get(fields[productStateKey], 'props.defaultValue', false)
                    && _get(fields[distributionTypeStateKey], 'props.defaultValue', false)
                    && !existingDistributions.some((existingDistribution) => (
                        existingDistribution.product.id === product.id
                        && convertToInt(existingDistribution.distribution_type.id) === distribution
                    ))
                );
            }).length
        )).length
    );

    onChangeProductSwitch = (product, toggle) => {
        if (this.checkIfDistributionShouldBeShown(product, VARIABLES.DISTRIBUTION_TYPE_LIVE)) {
            this.toggleDistributionGroup(product, VARIABLES.DISTRIBUTION_TYPE_LIVE, toggle);
        }

        if (this.checkIfDistributionShouldBeShown(product, VARIABLES.DISTRIBUTION_TYPE_RECORDING)) {
            this.toggleDistributionGroup(product, VARIABLES.DISTRIBUTION_TYPE_RECORDING, toggle);
        }

        if (this.checkIfDistributionShouldBeShown(product, VARIABLES.DISTRIBUTION_TYPE_VOD)) {
            this.toggleDistributionGroup(product, VARIABLES.DISTRIBUTION_TYPE_VOD, toggle);
        }

        this.setFieldOnDistributionChange(`product_${product}`, toggle);
    };

    onChangeDistributionUsageCheckbox = (product, distributionType, toggle) => {
        this.setField(`${product}_${distributionType}_synchronise_times`, {hidden: !!toggle});
        this.setField(`${product}_${distributionType}_start_datetime`, {hidden: !!toggle});
        this.setField(`${product}_${distributionType}_end_datetime`, {hidden: !!toggle});
        this.setField(`${product}_${distributionType}_product_status`, {hidden: !!toggle});
        this.setField(`${product}_${distributionType}_name`, {hidden: !!toggle});
        this.setField(`${product}_${distributionType}_client_selection_type`, {hidden: !!toggle});
        this.setField(`${product}_${distributionType}_clients`, {
            hidden: (
                !!toggle
                || VARIABLES.CLIENT_SELECTION_TYPE_ALL === convertToInt(
                    this.props.formValues[`${product}_${distributionType}_client_selection_type`]
                )
            ),
        });

        if (VARIABLES.DISTRIBUTION_TYPE_RECORDING !== distributionType) {
            this.setField(`${product}_${distributionType}_broadcast_status`, {hidden: !!toggle});
        }

        this.setFieldOnDistributionChange(`${product}_${distributionType}_distribution_usage`, !toggle);
    };

    setFieldOnDistributionChange = (id, toggle) => {
        this.setField(
            id,
            {checked: toggle, defaultValue: toggle},
            this.toggleGenerateTargets
        );
    };

    onChangeEventContentType = (value) => {
        if (!value) {
            return;
        }

        let contentTypes = _find(this.state.eventContentTypes, {value: value}),
            hasLiveDistribution = _find(contentTypes.distribution_types, {id: VARIABLES.DISTRIBUTION_TYPE_LIVE + ""}),
            isStartBroadcastAutomaticallyDisabled = false,
            isSetAsDeliveredAutomaticallyDisabled = false;

        if (!this.isSrNonMasterEvent() || !hasLiveDistribution) {
            isStartBroadcastAutomaticallyDisabled = true;
            isSetAsDeliveredAutomaticallyDisabled = true;
        }

        this.setField('start_broadcast_automatically', {
            hidden: isStartBroadcastAutomaticallyDisabled,
            disabled: isStartBroadcastAutomaticallyDisabled,
        });

        this.setField('set_as_delivered_automatically', {
            hidden: isSetAsDeliveredAutomaticallyDisabled,
            disabled: isSetAsDeliveredAutomaticallyDisabled,
        });

        this.setField("is_247_stream", {
            checked: false,
            defaultValue: false,
            disabled: !hasLiveDistribution,
            hidden: !hasLiveDistribution,
        });

        this.showFieldsByEventContentType(
            this.selectedEventContentDistribution(value, this.state.eventContentTypes)
        );

        this.setInputAndDistributionFields(
            this.state.propertyLicenceTechSetupProducts,
            value
        );

        this.setState(() => ({
            currentSelectedEventContentType: value,
        }));
    };

    showFieldsByEventContentType = (type) => {
        switch (type) {
            case HAS_NO_VOD:
                this.setField(['video_length', 'video_duration'], {hidden: true});
                break;
            default:
                this.setField(['video_length', 'video_duration'], {hidden: false});
        }
    };

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        const {GraphQLOptionsData: {loading: prevLoading}} = prevProps,
            {
                formParams: {products},
                formValues: {srt_ad_splice_config},
                GraphQLOptionsData: {
                    adSpliceConfigs,
                    clientsForProducts,
                    rtmpOriginEntryPointApplications,
                    loading,
                },
            } = this.props;

        if (!loading && loading !== prevLoading) {
            const hasValidProduct = (clientForProducts, product, distributionType) => (
                clientForProducts.products.some((clientProduct) => (
                    clientProduct.product_id === product.id
                    && (
                        (VARIABLES.DISTRIBUTION_TYPE_LIVE === distributionType && clientProduct.valid_for_live)
                        || (VARIABLES.DISTRIBUTION_TYPE_RECORDING === distributionType)
                        || (VARIABLES.DISTRIBUTION_TYPE_VOD === distributionType && clientProduct.valid_for_vod)
                    )
                ))
            );

            products.forEach((product) => {
                DISTRIBUTION_TYPE_IDS.forEach((distributionType) => {
                    this.setField(`${product.id}_${distributionType}_clients`, {
                        options: clientsForProducts.filter((clientForProducts) => (
                            hasValidProduct(clientForProducts, product, distributionType)
                        )),
                    });
                });
            });

            if (adSpliceConfigs) {
                this.setField('srt_ad_splice_config', {
                    options: sortDropdownOptionsAlphabetically(adSpliceConfigs.map((adSpliceConfig) => ({
                        key: convertToInt(adSpliceConfig.id),
                        text: adSpliceConfig.name,
                        value: convertToInt(adSpliceConfig.id),
                    }))),
                    defaultValue: convertToInt(srt_ad_splice_config) || null,
                });
            } else {
                this.setField('srt_ad_splice_config', {options: []});
            }
        }

        if (rtmpOriginEntryPointApplications && rtmpOriginEntryPointApplications !== prevProps.GraphQLOptionsData.rtmpOriginEntryPointApplications) {
            this.setField('rtmp_origin_entry_point_application', {
                defaultValue: this.props.formValues.rtmp_origin_entry_point_application,
                options: filterActiveRtmp(rtmpOriginEntryPointApplications, this.props.formValues.rtmp_origin_entry_point_application),
            });
        }

        if (!this.getIsFormLoading() && !this.props.formParams.id && !this.props.formValues.event_content_type) {
            let eventContentTypes = this.eventContentTypeParser(this.props.GraphQLOptionsData.eventContentTypes),
                hasFullmatch = this.hasFullmatch(eventContentTypes),
                fullmatchEventContentExists = this.fullmatchEventContentExists(this.props.formParams.eventContents);

            if (hasFullmatch && !fullmatchEventContentExists) {
                this.setField("event_content_type", {
                    defaultValue: VARIABLES.EVENT_CONTENT_TYPE_FULLMATCH.toString(),
                });

                this.setFieldFocus("name");

                this.onChangeEventContentType(VARIABLES.EVENT_CONTENT_TYPE_FULLMATCH.toString());
            }
        }

        if (this.props.formValues.failover_srt_origin_entry_point_url !== prevProps.formValues.failover_srt_origin_entry_point_url
            || this.props.formValues.has_input !== prevProps.formValues.has_input
            || this.props.formValues.origin_stream_type !== prevProps.formValues.origin_stream_type) {
            const isFailoverSrtUrlValid = !validatorTypes.url.validate(
                this.props.formValues.failover_srt_origin_entry_point_url,
                'srt'
            );

            if (this.props.formValues.has_input && convertToInt(this.props.formValues.origin_stream_type) === ORIGIN_STREAM_TYPE_SRT
                && this.props.formValues.failover_srt_origin_entry_point_url && isFailoverSrtUrlValid) {
                const valueToIsSrtFailover = this.props.formValues.is_srt_origin_entry_point_in_failover ?? false;
                this.setField('is_srt_origin_entry_point_in_failover', {hidden: false, defaultValue: valueToIsSrtFailover});
            } else {
                this.setField('is_srt_origin_entry_point_in_failover', {hidden: true});
            }
        }
    }

    hasFullmatch = (eventContentTypes) => (
        eventContentTypes.map((eventContentType) => (
            convertToInt(eventContentType.value)
        )).includes(VARIABLES.EVENT_CONTENT_TYPE_FULLMATCH)
    );

    fullmatchEventContentExists = (eventContents) => (
        0 < eventContents.filter((eventContent) => (
            VARIABLES.EVENT_CONTENT_TYPE_FULLMATCH === convertToInt(eventContent.event_content_type.id)
        )).length
    );

    /**
     * We need to pass ACTUAL value of fields:
     * 1. Logic react on 2 fields: encoding_datacenter and origin_stream_type
     *   - if we change encoding_datacenter,
     *     we have to pass "data.value" as 1st param / this.props.formValues.origin_stream_type as 2nd param
     *   - if we change origin_stream_type,
     *     we have to pass this.props.formValues.encoding_datacenter as 1st param / data.value as 2nd param
     * !!! On change has PREVIOUS VALUE in the this.props.formValues !!!
     * !!! Current value for CHANGED field is in the data.value !!!
     * @param encodingDatacenterIdParam
     * @param originStreamTypeIdParam
     */
    onChangeCallbackOriginStreamType = (encodingDatacenterIdParam, originStreamTypeIdParam, mediaConnectOriginEntryPointId) => {
        const encodingDatacenterId = convertToInt(encodingDatacenterIdParam),
            originStreamTypeId = convertToInt(originStreamTypeIdParam);

        if (VARIABLES.ORIGIN_STREAM_TYPE_MEDIACONNECT === originStreamTypeId) {
            this.setMediaConnectOfEncodingDataCenterInput(encodingDatacenterId, mediaConnectOriginEntryPointId);
        } else if (VARIABLES.ORIGIN_STREAM_TYPE_UDP === originStreamTypeId
            || VARIABLES.ORIGIN_STREAM_TYPE_SDI === originStreamTypeId
        ) {
            this.queryUdpAndSdiOriginEntryPointsOfEncodingDatacenter(encodingDatacenterId)
                .then((data) => {
                    this.setField("udp_origin_entry_point", {
                        disabled: false,
                        loading: false,
                        options: this.parseUdpOriginEntryPoints(
                            data.data.udpOriginEntryPoints,
                            this.getTournamentOfEvent(),
                            this.getContentCategoryOfEvent()
                        ),
                    });
                    this.setField("sdi_origin_entry_point", {
                        disabled: false,
                        loading: false,
                        options: data.data.sdiOriginEntryPoints
                    });
                })
                .finally(() => {
                    this.setState(() => ({
                        queryUdpAndSdiOriginEntryPointsOfEncodingDatacenterLoading: false,
                    }));
                });
        }
    }

    componentWillMount() {
        super.componentWillMount();

        let state = {
            event_content_start_datetime: null,
            event_content_end_datetime: null,
            propertyLicenceTechSetupProducts: [],
        };

        this.props.formParams.products.forEach((product) => {
            state[`product_${product.id}_distribution_type_1_always_hidden`] = true;
            state[`product_${product.id}_distribution_type_1_start_datetime`] = null;
            state[`product_${product.id}_distribution_type_1_end_datetime`] = null;
            state[`product_${product.id}_distribution_type_2_always_hidden`] = true;
            state[`product_${product.id}_distribution_type_2_start_datetime`] = null;
            state[`product_${product.id}_distribution_type_2_end_datetime`] = null;
            state[`product_${product.id}_distribution_type_3_always_hidden`] = true;
            state[`product_${product.id}_distribution_type_3_start_datetime`] = null;
            state[`product_${product.id}_distribution_type_3_end_datetime`] = null;
        });

        this.setState(() => (state));

        if (!this.props.formParams.id && !this.hasAlreadyMainContent(this.props.formParams.eventContents)) {
            this.setField("is_main_content", {checked: true, defaultValue: true});
        }

        let onChangeCallbacks = {
            event_content_type: (data) => {
                this.onChangeEventContentType(data.value);
            },
            event_content_variant: (data) => {
                this.setState(() => ({
                    processingDistributions: true,
                    selectedEventContentVariant: data.value,
                }),() => {
                    let isProcessingDistributions = false;

                    if (this.state.defaultForm_data && this.state.defaultForm_data.event_content) {
                        isProcessingDistributions = true;
                    }
                    this.changeAvailableProducts(this.props.formParams, isProcessingDistributions);
                });
            },
            synchronise_times: (data) => {
                if (!data.value) {
                    this.setState(() => ({
                        event_content_start_datetime: this.props.formValues.start_datetime,
                        event_content_end_datetime: this.props.formValues.end_datetime,
                    }));

                    this.setField("start_datetime", {
                        defaultValue: this.props.formParams.event.start_datetime,
                        disabled: !data.value,
                    });
                    this.setField("end_datetime", {
                        defaultValue: this.props.formParams.event.end_datetime,
                        disabled: !data.value,
                    });
                } else {
                    this.setField("start_datetime", {
                        defaultValue: this.state.event_content_start_datetime,
                        disabled: !data.value,
                    });
                    this.setField("end_datetime", {
                        defaultValue: this.state.event_content_end_datetime,
                        disabled: !data.value,
                    });
                }
            },
            is_247_stream: (data) => {
                this.setField("is_247_stream", {checked: !data.checked, defaultValue: !data.checked});
            },
            is_main_content: (data) => {
                this.setField("is_main_content", {checked: !data.checked, defaultValue: !data.checked});
            },
            hide_in_live_control: (data) => {
                this.setField("hide_in_live_control", {
                    checked: !data.checked,
                    defaultValue: !data.checked,
                });
            },
            // Input Tab
            has_input: (data) => {
                if (!data.value) {
                    this.showHasInputFields();
                    this.showFieldsBasedOnOriginStreamType(this.props.formValues.origin_stream_type);

                    this.setField("has_input", {checked: true, defaultValue: true});
                } else {
                    this.hideHasInputFields();

                    this.setField("has_input", {checked: false, defaultValue: false});
                }
            },
            encoding_datacenter: (data) => {
                if (!data.value) {
                    return;
                }

                this.setField("udp_origin_entry_point", {
                    defaultValue: null,
                    disabled: true,
                    loading: true,
                });
                this.setField("sdi_origin_entry_point", {
                    defaultValue: null,
                    disabled: true,
                    loading: true,
                });

                this.onChangeCallbackOriginStreamType(data.value, this.props.formValues.origin_stream_type, this.props.formValues.mediaconnect_origin_entry_point);
            },
            production_company: (data) => {
                this.onChangeCallbackProductionCompany(data.value);
            },
            origin_stream_type: (data) => {
                this.showFieldsBasedOnOriginStreamType(data.value);
                this.onChangeCallbackOriginStreamType(this.props.formValues.encoding_datacenter, data.value, this.props.formValues.mediaconnect_origin_entry_point);
            },
            rtmp_origin_stream_name: (data) => {
                if (_has(this.state, 'contactPersonsAndOriginStreamNameOfProductionCompany.rtmpOriginStreamNames')) {
                    this.showHideTvStreamingWarningForRtmpOriginStreamName(
                        data.value,
                        this.state.contactPersonsAndOriginStreamNameOfProductionCompany.rtmpOriginStreamNames
                    );
                }
            },
            mediaconnect_origin_entry_point: (data) => {
                this.onChangeCallbackMediaConnectOriginEntryPoint(data.value);
            },

        };

        this.props.formParams.products.forEach((product) => {
            onChangeCallbacks[`product_${product.id}`] = (data) => {
                this.onChangeProductSwitch(product.id, !data.value);
            };

            DISTRIBUTION_TYPE_IDS.forEach((distributionTypeId) => {
                onChangeCallbacks[`${product.id}_${distributionTypeId}_distribution_usage`] = (data) => {
                    this.onChangeDistributionUsageCheckbox(product.id, distributionTypeId, data.value);
                };
                onChangeCallbacks[`${product.id}_${distributionTypeId}_synchronise_times`] = (data) => {
                    this.changeSynchroniseTimes(data, product.id, distributionTypeId);
                };
                onChangeCallbacks[`${product.id}_${distributionTypeId}_client_selection_type`] = (data) => {
                    this.toggleClientsInputField(data, product.id, distributionTypeId);
                };
            });
        });

        this.setOnChangeCallback(onChangeCallbacks);

        this.setOptionParsers({
            event_content_type: (data) => {
                return this.eventContentTypeParser(data);
            }
        });

        this.setCreateSuccessCallback((response) => {
            let distributions = this.prepareDistributionData(this.state.submitData);

            if (0 < distributions.distributions.length) {
                this.setState(() => ({processingDistributions: true}));

                let dataToSave = Object.assign(
                    {},
                    distributions,
                    {
                        event_content: convertToInt(response.data.createEventContent.id),
                        generate_targets: this.getGenerateTargetsCheckboxValue(),
                    },
                );

                this
                    .mutationContentDistributionCreate(dataToSave)
                    .then((mutationResponse) => {
                        this.props.onSuccessCallback();
                        this.showAutomaticTargetGenerationMessage(mutationResponse.data.createBulkDistribution);
                    })
                    .finally(() => {
                        this.processingDistributionsFinished();
                    });
            } else {
                this.props.onSuccessCallback();
                this.props.Modal.setModal({isVisible: false});
                refetchQueryByName("EventQuery");
            }
        });

        this.setUpdateSuccessCallback((response) => {
            this.setState(() => ({processingDistributions: true}));

            const dataToSave = Object.assign(
                {},
                this.prepareDistributionData(this.state.submitData),
                {
                    event_content: convertToInt(response.data.updateEventContent.id),
                    generate_targets: this.getGenerateTargetsCheckboxValue(),
                }
            );

            this
                .mutationContentDistributionUpdate(dataToSave)
                .then((mutationResponse) => {
                    this.props.onSuccessCallback();
                    this.showAutomaticTargetGenerationMessage(mutationResponse.data.updateBulkDistribution);
                })
                .finally(() => {
                    this.processingDistributionsFinished();
                    refetchQueryByName("GetEncodingTargetsForDistributions");
                });
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName("EventQuery");
            refetchQueryByName("GetEventContents");
        });
    }

    setStateHandler = (stateObject) => {
        this.setState(() => (stateObject));
    };

    processingDistributionsFinished = () => {
        this.setState(() => ({processingDistributions: false}));
        this.props.Modal.setModal({isVisible: false});
        refetchQueryByName('GetEventContents', true);
    };

    showAutomaticTargetGenerationMessage = (data) => {
        const automaticTargetGenerationResponse = data.find((item) => (
            VARIABLES.TYPE_AUTOMATIC_TARGET_GENERATION === item.type
        ));

        if (automaticTargetGenerationResponse) {
            const message = (
                    <React.Fragment>
                        {automaticTargetGenerationResponse.message.split('\\n').map((line, index) => (
                            <div key={`automaticTargetGenerationResponse_line_${index}`}>{line}</div>
                        ))}
                    </React.Fragment>
                ),
                success = responseStatus.HTTP_OK === automaticTargetGenerationResponse.status;

            this.props.MessageBox.addMessage(
                'automaticTargetGeneration',
                message,
                null,
                success ? 'success' : 'warning',
                !success
            );
        }
    };

    componentDidMount() {
        if (this.props.formParams.id) {
            this.setField('hide_in_live_control', {hidden: false});
        }

        const contentId = _get(
            this.props,
            'formParams.eventContent.id',
            null
        );

        'undefined' == typeof this.state.canDelete
        && contentId
        && setCanDeleteState(contentId, this.setStateHandler, false);
    }

    eventContentTypeParser = (data) => {
        let event = this.props.formParams.event,
            filteredData = [];

        if (!event) {
            return data;
        }

        if (event.sr_event) {
            let searchId;

            if (event.sr_event.match && event.sr_event.match.tournament) {
                searchId = event.sr_event.match.tournament.tournament_category.sport.id;
            } else if (event.sr_event.tournament) {
                searchId = event.sr_event.tournament.tournament_category.sport.id;
            }

            filteredData = _filter(data, (item) => {
                if (_filter(item.event_types, (event_type) => {
                    return VARIABLES.EVENT_SPORTRADAR_TYPE === convertToInt(event_type.id)
                }).length === 0) {
                    return false;
                }

                if (0 === item.sports.length) {
                    return true;
                }

                return _find(item.sports, (sport) => {
                    return sport.id === searchId;
                });
            });
        } else if (event.av_event) {
            let searchId = event.av_event.content_category.parent.parent.id;

            filteredData = _filter(data, (item) => {
                if (_filter(item.event_types, (event_type) => {
                    return VARIABLES.EVENT_NON_SPORTRADAR_TYPE === convertToInt(event_type.id)
                }).length === 0) {
                    return false;
                }

                if (0 === item.content_categories.length) {
                    return true;
                }

                return _find(item.content_categories, (contentCategory) => {
                    return contentCategory.id === searchId;
                });
            });
        } else {
            filteredData = data;
        }

        return filteredData;
    };

    /**
     * @ignore
     */
    getFieldCallback = (field) => {
        const callbackForField = this.state.defaultForm_fieldCallbacks[field.props.name],
            props = {};

        if (!callbackForField) {
            return props;
        }

        if ((Form.SemanticInput == field.props.component)
            && ("checkbox" === field.props.type || "toggle" === field.props.type)) {
            props.onCheckboxChange = this.onChangeCallback;
        }

        if ((Form.SemanticInput == field.props.component) && (!field.props.type || "input" === field.props.type)) {
            props.onChange = this.onChangeCallback;
        }

        if ((Form.SemanticSelect == field.props.component) || ("select" === field.props.type)) {
            props.onChangeSelect = this.onChangeCallback;
        }

        if ((Form.SemanticInput == field.props.component) && ("date" === field.props.type)) {
            props.onChangeDate = this.onChangeCallback;
        }

        /** EventsConfigurationDistributionForm specific code */
        if (this.props.formParams.products.map((product) => (`product_${product.id}`)).includes(field.props.name)) {
            props.onCheckboxChange = this.onChangeCallback;
        }

        return props;
    };

    redirectToEventContentEditPage = () => {
        window.location.href = getLink('events.configuration.content.edit', {
            id: _get(this.props, 'formParams.event.id', null),
            contentId: _get(this.props, 'formParams.id', null),
        });
    };

    renderDeleteButton = () => {
        return this.state.canDelete
            ? super.renderDeleteButton()
            : super.renderDeleteButton({
                onClick: () => setErrorModal(
                    renderModalErrorObject(CANT_DELETE_CONTENT, this.redirectToEventContentEditPage)
                ),
            });
    };
}

export default withApollo(connect(null, mapModulesToProps(["MessageBox"]))(EventsContentForm));
