const filterProperties = `
            key: id
            value: id
            text: name
        `;

const bookingTypes = `bookingTypes {
    ${filterProperties}
}`;

const clientPackages = `clientPackages {
    ${filterProperties}
}`;

const contentCategories = `contentCategories {
    ${filterProperties}
}`;

const contentTiers = `contentTiers {
    ${filterProperties}
}`;

const deviceCategories = `deviceCategories {
    ${filterProperties}
}`;

const distributionTypes = `distributionTypes {
    ${filterProperties}
}`;

const eventTypes = `eventTypes {
    ${filterProperties}
}`;

const invoiceStatuses = `invoiceStatuses {
    ${filterProperties}
}`;

const products = `products {
    key: id
    value: id
    text: short_name
}`;

const sports = `sports {
    ${filterProperties}
}`;

const tournamentCategories = `tournamentCategories {
    ${filterProperties}
}`;

const tournaments = `tournaments {
    ${filterProperties}
}`;

const eventContentTypes = `eventContentTypes {
    ${filterProperties}
}`;

const eventContentVariants = `eventContentVariants {
    ${filterProperties}
}`;

const matchEventTypes = `matchEventTypes {
    ${filterProperties}
}`;

export const filters = {
    bookingTypes,
    clientPackages,
    contentCategories,
    contentTiers,
    deviceCategories,
    distributionTypes,
    eventTypes,
    invoiceStatuses,
    products,
    sports,
    tournamentCategories,
    tournaments,
    eventContentTypes,
    eventContentVariants,
};

export const filtersEventBooking = {
    bookingTypes,
    clientPackages,
    contentCategories,
    contentTiers,
    deviceCategories,
    distributionTypes,
    eventTypes,
    products,
    sports,
    tournamentCategories,
    tournaments,
    eventContentTypes,
    matchEventTypes,
};
