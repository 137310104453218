import {
    isFunction as _isFunction,
    map as _map,
    some as _some,
    isNil as _isNil,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import {IconEdit, IconCheckmark} from '@appComponents/IconCollection';
import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';
import Authorization from '@appComponents/Authorization';
import {getParsedId} from '@utils/helpers';
import {CLIENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

import {CLIENT_TABLE_NO_DATA} from '../constants/messages';

const authorizationObject = {
    [CLIENT]: SECURITY_PRIVILEGES_READ,
};

const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => getParsedId(row),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'bookmaker': {
        label: 'Bookmaker ID',
        sorting: (row) => getParsedId(row.bookmaker),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'crm_id': {
        label: 'CRM ID',
        sorting: (row) => getParsedId(row, 'crm_id'),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: true,
    },
    'brands': {
        label: 'Brands',
        sorting: true,
    },
    'country': {
        label: 'Country',
        sorting: true,
    },
    'client_types': {
        label: 'Type',
        sorting: (row) => _map(row.client_types, 'name').join(', '),
    },
    'client_products': {
        label: 'Products (#packages)',
        sorting: row => row.clientProductsSortValue,
    },
    'client_whitelisted_ips': {
        label: 'IP whitelisting',
        sorting: (row) => (!(
            null === row.client_whitelisted_ips
            || row.client_whitelisted_ips === undefined
            || false === _some(row.client_whitelisted_ips, {'is_disabled': false})
        )),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'is_internal': {
        label: 'Internal',
        sorting: (row) => (row.is_internal),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'creation_datetime': {
        label: 'Registration date',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const renderNameOfFirstLevelDependency = (row, column) => {
    return (
        <div>
            {(null === row[column] || row[column] === undefined)
                ? ''
                : row[column].name.capitalizeFirstLetter()
            }
        </div>
    );
};

const renderCheckbox = (row, column) => {
    return (
        (null === row[column] || row[column] === undefined || false === _some(row[column], {'is_disabled': false}))
            ? ''
            : <IconCheckmark/>
    );
};

const createListElementsArray = (row, column) => {
    let listElements = [];

    if (row[column] !== undefined && null !== row[column] && _isFunction(row[column][Symbol.iterator])) {
        row[column].map((dataSourceElement) => {
            listElements.push(renderCommaSeparatedListElement(dataSourceElement));
        });
    }

    return listElements;
};

const renderCommaSeparatedList = (row, column) => {
    const listElements = createListElementsArray(row, column);

    return listElements.map((element, i) => (
        <span key={i}>
            <span className={(element.className) ? element.className : ''}>{element.text}</span>
            {(i < listElements.length - 1) ? ', ' : ''}
        </span>
    ));
};

const renderCommaSeparatedListElement = (element) => {
    let text = '';

    if (element.name !== undefined) {
        text = element.name;
    } else if (element.product !== undefined) {
        text = element.product.short_name;
    }

    text += (0 < element.client_packages_count) ? ` (${element.client_packages_count})` : '';
    text += (element.is_trial !== undefined && true === element.is_trial) ? ' (trial)' : '';

    return (element.is_disabled !== undefined && true === element.is_disabled)
        ? {text: text, className: 'error'}
        : {text: text};
};

const ClientTable = (props) => {
    const {loading} = props,
        clients = props.clients.map(el => {
            const listOfProducts = createListElementsArray(el, 'client_products');

            return {
                ...el,
                clientProductsSortValue: listOfProducts.map(el => el.text).join(','),
            };
        });

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'name':
                return (
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'clients.edit',
                            params: {id: row.id},
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'bookmaker':
                return (!_isNil(row.bookmaker) ? row.bookmaker.id : null);
            case 'country':
                return renderNameOfFirstLevelDependency(row, column);
            case 'client_types':
            case 'client_products':
                return renderCommaSeparatedList(row, column);
            case 'client_whitelisted_ips':
                return renderCheckbox(row, column);
            case 'is_internal':
                return row.is_internal && <IconCheckmark/>;
            case 'actions' :
                return (
                    <Authorization authorization={authorizationObject}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit
                                size='large'
                                link={getLink('clients.edit', {
                                    id: row.id,
                                })}
                                resource={CLIENT}
                            />
                        </div>
                    </Authorization>
                );
            case 'creation_datetime':
                return row[column] ? moment(row[column]).format('YYYY-MM-DD') : '';
            default:
                return null;
        }
    };

    return (
        <div className='clients__table'>
            <Table
                columns={columns}
                data={clients}
                loading={loading}
                name='clientsList'
                noDataText={CLIENT_TABLE_NO_DATA}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ClientTable.defaultProps = {
    clients: [],
};

ClientTable.propTypes = {
    clients: PropTypes.array,
    loading: PropTypes.bool,
    onClientDelete: PropTypes.func,
};

export default ClientTable;
