import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GlobalError from "../views/GlobalError";
import RedirectView from "../views/RedirectView";
import AuthenticationView from "../views/AuthenticationView";
import ApplicationView from "../views/ApplicationView";

/**
 * @ignore
 */
export const AvcmpRouter = (props) => {
    if (props.globalError) {
        return <GlobalError error={props.globalError}/>
    } else if (!props.isLoggedIn) {
        return <AuthenticationView />
    } else if (props.isRedirectLoaderVisible && !props.redirectError) {
        return <RedirectView isRedirectLoaderVisible={props.isRedirectLoaderVisible}/>
    }

    return <ApplicationView/>
};

AvcmpRouter.propTypes = {
    isRedirectLoaderVisible: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    globalError: PropTypes.string,
    redirectError: PropTypes.bool.isRequired
};

AvcmpRouter.defaultProps = {
    isRedirectLoaderVisible: false,
    isLoggedIn: false,
    redirectError: false
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn : state.app.security.loggedIn,
        globalError: state.app.security.errorMessage,
        isRedirectLoaderVisible: state.app.menu.isRedirectLoaderVisible,
        redirectError: state.app.menu.redirectError
    }
};

export const AvcmpRouterWithRedux = connect(mapStateToProps)(AvcmpRouter);
