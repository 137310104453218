import PropTypes from 'prop-types';
import React from 'react';

import Table from '@appComponents/Table';
import {getTableRowIsDisabledClassName} from '@modules/booking/utils/bookingsTable';

import {columns, rowRenderer} from '../../utils/bookingsBulkChange/tableHelper';

const SelectedBookingsTable = ({bookings = [], clientId, loading}) => (
    <Table
        loading={loading}
        name='selectedBookingsTable'
        columns={columns}
        data={bookings}
        rowRenderer={rowRenderer}
        rowRendererProps={{
            clientId: clientId,
        }}
        limit={100}
        size='small'
        className={'mini'}
        rowClassname={getTableRowIsDisabledClassName}
    />
);

SelectedBookingsTable.propTypes = {
    bookings: PropTypes.arrayOf(PropTypes.object),
    clientId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default SelectedBookingsTable;
