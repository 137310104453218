import {DeleteBooking, UpdateBooking} from '@graphql/booking/mutation';
import {GetEventBookingForGeoRestrictionsForm} from '@graphql/eventBooking/query';

import EventBookingGeoRestrictionsBlackoutZonesModel from './EventBookingGeoRestrictionsBlackoutZonesModel';

export default function EventBookingGeoRestrictionsBlackoutZonesEditModel() {
    const EventBookingGeoRestrictionsEditModel = EventBookingGeoRestrictionsBlackoutZonesModel();

    EventBookingGeoRestrictionsEditModel.deleteId = 'booking.id';
    EventBookingGeoRestrictionsEditModel.editForm = true;
    EventBookingGeoRestrictionsEditModel.mutation = {
        updateEntity: UpdateBooking,
        deleteEntity: DeleteBooking,
    };
    EventBookingGeoRestrictionsEditModel.query.getEntity = GetEventBookingForGeoRestrictionsForm;

    return EventBookingGeoRestrictionsEditModel;
}
