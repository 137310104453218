import PropTypes from 'prop-types';

import {prepareExcelExportData, prepareTitlesForExcelExport} from '../lcppClientNotificationLog';

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_lcpp_notification_history`,
    parseData: row => prepareExcelExportData(row),
    path: 'clients.products.lcpp.notificationHistory.path',
    titles: prepareTitlesForExcelExport(),
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired,
};
