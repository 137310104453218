import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Dimmer, Loader, Segment} from "semantic-ui-react";

import urls from '../constants/urls';

export function Authenticate(props) {
    React.useEffect(() => {
        if (props.shouldRedirect) {
            const fromUrl = urls.authLogin + encodeURIComponent(
                window.location.pathname + window.location.search + window.location.hash
            );

            window.location.replace(fromUrl);
        }
    }, [props.shouldRedirect]);

    return (
        <Segment className="applicationLoader">
            <Dimmer active>
                <Loader size="massive">
                    Loading
                </Loader>
            </Dimmer>
        </Segment>
    );
}

Authenticate.propTypes = {
    shouldRedirect: PropTypes.bool,
};
Authenticate.defaultProps = {
    shouldRedirect: false
};

const mapStateToProps = (state) => {
    return {
        shouldRedirect: state.app.security.shouldRedirect
    }
};

export default connect(mapStateToProps)(Authenticate);
