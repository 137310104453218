import {Button} from 'semantic-ui-react';
import React from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    isEmpty as _isEmpty,
    sortBy as _sortBy,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from '@constants/variables';
import {createForm} from '@utils/forms';

import EventGeoRestrictionsFormBlackoutZonesModel from '../EventGeoRestrictions/EventGeoRestrictionsFormBlackoutZonesModel';
import EventGeoRestrictionsFormBlackoutZonesForm from '../EventGeoRestrictions/EventGeoRestrictionsFormBlackoutZonesForm';
import {getDataFromAllSteps, showErrorNotice} from '../../utils/eventGeoRestriction';

export class EventGeoRestrictionsForm extends DefaultForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;

    prepareDataForSubmit = () => {
        const data = getDataFromAllSteps(
                this.props.stepsMethods,
                this.props.formParams.optionsVariables.event
            ),
            isFormDataValid = null !== data.territory_selection_type || 0 < data.blackout_zones.length;

        return isFormDataValid ? data : showErrorNotice();
    };

    onFormSubmit = (data) => {
        if (!this.props.Model.editForm) {
            this.setState(() => ({
                defaultForm_errorShowed: false,
            }));

            const Form = createForm(
                EventGeoRestrictionsFormBlackoutZonesModel,
                EventGeoRestrictionsFormBlackoutZonesForm,
                {
                    id: data.id,
                    optionsVariables: {
                        event: this.props.formParams.optionsVariables.event,
                        isArchived: false,
                    },
                });

            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
            this.props.stepsMethods.setStep({
                id: 'step3',
                menuItem: {disabled: false},
                pane: {content: <Form previousStepData={data}/>},
            });
            this.props.stepsMethods.showStep('step3');
        } else {
            return super.onFormSubmit(data);
        }
    };

    setCountriesAndSubdivisionsOnChange(selectedOptions, continents, countries) {
        const selectedValue = selectedOptions.value ? selectedOptions.value : null,
            isWorldwideTerritorySelectedType =
                TERRITORY_SELECTION_TYPE_WORLDWIDE === convertToInt(selectedOptions.value),
            doesSelectedType = (null !== selectedValue && !isWorldwideTerritorySelectedType);

        let continentsList = [],
            countriesList = [];

        if (doesSelectedType) {
            const previousCountries = [],
                previousSubdivisions = [];

            this.props.formValues.countries.forEach((continent) => {
                continent.children.forEach((country) => {
                    previousCountries.push(country);
                });
            });
            this.props.formValues.subdivisions.forEach((country) => {
                country.children.forEach((subdivision) => {
                    previousSubdivisions.push(subdivision);
                });
            });

            continentsList = this.getCountryTreeOptions({
                continents: continents,
                selectedCountries: previousCountries,
            });
            countriesList = this.getSubdivisionTreeOptions({
                countries: countries,
                selectedCountries: previousCountries,
                selectedSubdivisions: previousSubdivisions,
            });
        }

        this.setField('countries', {
            hidden: !doesSelectedType,
            options: continentsList,
        });
        this.setField('subdivisions', {
            hidden: countriesList.every((country) => {
                return true === country.hidden;
            }) || !doesSelectedType,
            options: countriesList,
        });
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {
            GraphQLOptionsData: {
                continents,
                countries,
            },
        } = nextProps;

        const entityData = _get(nextProps, 'GraphQLEntityData', {}),
            restriction = !_isEmpty(entityData) ? this.getEntityGeoRestrictions(entityData) : {},
            territorySelectionTypeId = _get(
                restriction,
                'territory_selection_type.id',
                null
            ),
            restrictionCountries = _get(restriction, 'countries', []),
            restrictionSubdivisions = _get(restriction, 'country_subdivisions', []);

        if (!this.state.territorySelectionTypeGenerated && null !== territorySelectionTypeId) {
            this.setField('selection_type', {
                defaultValue: territorySelectionTypeId,
            });

            if (TERRITORY_SELECTION_TYPE_WORLDWIDE !== convertToInt(territorySelectionTypeId)) {
                this.setField('countries', {
                    hidden: false,
                });
            }

            this.setState(() => ({territorySelectionTypeGenerated: true}));
        }

        if (!this.state.countriesGenerated && continents) {
            this.setField('countries', {
                options: this.getCountryTreeOptions({
                    continents: continents,
                    selectedCountries: [],
                }),
            });

            this.setState(() => ({countriesGenerated: true}));
        }

        if (!this.state.countriesDataGenerated && continents && 0 < restrictionCountries.length) {
            this.setField('countries', {
                options: this.getCountryTreeOptions({
                    continents: continents,
                    selectedCountries: restrictionCountries,
                }),
            });

            this.setState(() => ({countriesDataGenerated: true}));
        }

        if (!this.state.subdivisionsDataGenerated
            && countries
            && (0 < restrictionSubdivisions.length || this.checkSubdivisionsAnyCountry(countries, restrictionCountries))
        ) {
            this.setField('subdivisions', {
                hidden: false,
                options: this.getSubdivisionTreeOptions({
                    countries: countries,
                    selectedCountries: restrictionCountries,
                    selectedSubdivisions: restrictionSubdivisions,
                }),
            });

            this.setState(() => ({subdivisionsDataGenerated: true}));
        }

        this.setField('selection_type', {
            onChangeSelect: (event, selectedOptions) => {
                this.setCountriesAndSubdivisionsOnChange(selectedOptions, continents, countries);
            },
        });

        this.setCountriesOnChangeCallback(countries);

        if (!_isEmpty(this.props.stepsMethods)) {
            this.props.stepsMethods.setForm('step2', nextProps);
        }
    }

    setCountriesOnChangeCallback(optionsCountries, countriesFieldName = 'countries', subdivisionsFieldName = 'subdivisions') {
        this.setField(countriesFieldName, {
            onChangeCallback: (event, selectedOptions) => {
                const countryOptions = [],
                    selectedCountries = [],
                    selectedSubdivisions = [];
                let generalHidden = true;

                selectedOptions.forEach((option) => {
                    const country = option.split('.');

                    if (country[1]) {
                        selectedCountries.push(country[1].split('-')[1]);
                    }
                });

                this.props.formValues[subdivisionsFieldName].forEach((country) => {
                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        country.children.forEach((subdivision) => {
                            selectedSubdivisions.push(subdivision.value);
                        });
                    }
                });

                optionsCountries.forEach((country) => {
                    let hidden = true;
                    const subdivisions = [];

                    if (-1 < selectedCountries.indexOf(country.value.replace(/-/g, ''))) {
                        hidden = false;
                        generalHidden = false;
                    }

                    country.children.forEach((subdivision) => {
                        subdivisions.push({
                            ...subdivision,
                            selected: -1 < selectedSubdivisions.indexOf(subdivision.value.replace(/-/g, '')),
                        });
                    });

                    if (!hidden) {
                        countryOptions.push({
                            ...country,
                            children: _sortBy(subdivisions, 'name'),
                            hidden: hidden,
                        });
                    }
                });

                this.setField(subdivisionsFieldName, {
                    hidden: generalHidden,
                    options: countryOptions,
                });
            },
        });
    }

    getCountryTreeOptions(data) {
        const selectedOptions = [],
            continentOptions = [];

        if (data.selectedCountries) {
            data.selectedCountries.forEach((country) => {
                selectedOptions.push(country.id);
            });
        }

        data.continents.forEach((continent) => {
            const countryOptions = [];

            continent.children.forEach((country) => {
                let selected = false;

                if (-1 < selectedOptions.indexOf(country.id)) {
                    selected = true;
                }

                countryOptions.push({
                    ...country,
                    selected: selected,
                });
            });

            continentOptions.push({
                ...continent,
                children: _sortBy(countryOptions, 'name'),
            });
        });

        return continentOptions;
    }

    getSubdivisionTreeOptions(data) {
        const selectedCountries = data.selectedCountries.map((option) => {
                return option.id;
            }),
            selectedSubdivisions = data.selectedSubdivisions.map((option) => {
                return option.id;
            }),
            countryOptions = [];

        data.countries.forEach((country) => {
            let hidden = true;
            const subdivisionOptions = [];

            if (-1 < selectedCountries.indexOf(country.id)) {
                hidden = false;

                country.children.forEach((child) => {
                    subdivisionOptions.push({
                        ...child,
                        selected: -1 < selectedSubdivisions.indexOf(child.id),
                    });
                });
            }

            countryOptions.push({
                ...country,
                hidden: hidden,
                children: _sortBy(subdivisionOptions, 'name'),
            });
        });

        return countryOptions;
    }

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'eventGeoRestriction', {});
    };

    checkSubdivisionsAnyCountry = (countries = [], selectedCountries = []) => {
        for (let key = 0; key < countries.length; key++) {
            if (-1 !== _findIndex(
                selectedCountries,
                (selectedCountry) => selectedCountry.id === countries[key].id
            )) {
                return true;
            }
        }

        return false;
    };

    renderAdditionalButtons = () => {
        return !this.props.Model.editForm && <Button
            color={'grey'}
            content={'Back'}
            icon={'angle double left'}
            labelPosition={'left'}
            onClick={this.props.stepsMethods.showPreviousStep}
        />;
    }

    renderSaveButton = (props) => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                ...props,
                content: 'Next',
                icon: 'angle double right',
                labelPosition: 'right',
            });
        }

        return super.renderSaveButton();
    };
}

export default EventGeoRestrictionsForm;
