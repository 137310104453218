import * as VARIABLES from "../../app/constants/variables";

export const distributions = [
    VARIABLES.DISTRIBUTION_TYPE_LIVE,
    VARIABLES.DISTRIBUTION_TYPE_RECORDING,
    VARIABLES.DISTRIBUTION_TYPE_VOD
];

export const distributionLabels = {
    [VARIABLES.DISTRIBUTION_TYPE_LIVE]: "Live",
    [VARIABLES.DISTRIBUTION_TYPE_RECORDING]: "Recording",
    [VARIABLES.DISTRIBUTION_TYPE_VOD]: "Vod",
};
