import {get as _get} from 'lodash';

import {
    eventPreset,
    eventPresetUpdateMutation,
    eventPresetDeleteMutation,
} from '@graphql/propertyLicences/eventPresets';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {EVENT_PRESET as RESOURCE_EVENT_PRESET} from '@constants/resources';

import validate from './validatorEventPreset';

export default function EventPresetModel() {
    return {
        entityDataMapKey: 'eventPreset',
        entityLabel: 'event preset',
        formName: 'event preset',
        resources: RESOURCE_EVENT_PRESET,
        showChangeLog: true,
        validate: validate,
        title: (data) => {
            let name = _get(data, 'eventPreset.name');
            name = name ? `"${name}"` : '';

            return `Event preset ${name}`;
        },
        dataMap: {
            eventPreset: {
                id: '',
                name: '',
            },
        },
        messages: {
            boxName: {
                delete: 'eventPresetMessage',
                error: 'eventPresetMessage',
                success: 'eventPresetMessage',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventPreset.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            event_preset_name: {
                dataMapKey: 'eventPreset.name',
                props: {
                    component: Form.SemanticInput,
                    name: 'name',
                    label: 'Name',
                    type: 'input',
                    required: true,
                    autoFocus: true,
                },
            },
        },
        mutation: {
            deleteEntity: eventPresetDeleteMutation,
            updateEntity: eventPresetUpdateMutation,
        },
        mutationOptions: {
            delete: {
                refetchQueries: ['EventPresetsQuery'],
            },
        },
        query: {
            getEntity: eventPreset,
        },
    };
}
