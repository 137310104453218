import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {PROPERTY_LICENCE_TECH_SETUP} from "../../app/constants/resources";

import {renderProductsWithStatus} from "../utils/technicalSetupProductsModelHelper";

import {CreatePropertyLicenceTechSetupProduct} from "../../../graphql/propertyLicence/mutation.graphql";
import {GetPropertyLicencesTechSetupProductsData} from "../../../graphql/propertyLicence/query.graphql";

export default function TechnicalSetupProductsModel(data, propertyLicenceTechSetupId) {
    return {
        entityDataMapKey: "propertyLicenceTechSetup",
        entityLabel: "Property Licence Technical Setup Products",
        formName: "propertyLicenceTechnicalSetupProducts",
        label: "Property Licence Technical Setup Products",
        resources: PROPERTY_LICENCE_TECH_SETUP,
        showChangelog: false,
        validate: null,
        title: null,
        dataMap: {
            propertyLicenceTechSetup: {
                id: "",
                productsWithStatus: [],
            }
        },
        messages: {
            boxName: {
                error: "propertyLicenceMessage",
                success: "propertyLicenceMessage",
            },
            text: {
                ...MESSAGES,
                SAVED: () => 'The products of the technical setup of the property licence have been saved successfully.',
                FORM_ERROR: () => 'The products of the technical setup of the property licence have not been saved. Please correct the highlighted fields below.',
            }
        },
        fields: {
            id: {
                component: Form.IdField,
                props: {
                    defaultValue: propertyLicenceTechSetupId,
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            description: {
                props: {
                    className: "--product-description",
                    component: Form.TextPlaceholder,
                    isLabelVisible: false,
                    label: "",
                    name: "description",
                    defaultValue: "Define which products should be selected by default when creating a new event content.",
                }
            },
            title_row: {
                props: {
                    className: "--product-headers",
                    component: Form.TextPlaceholder,
                    label: "Product",
                    name: "title_row",
                    defaultValue: "Product status",
                }
            },
            ...renderProductsWithStatus(data)
        },
        mutation: {
            createEntity: CreatePropertyLicenceTechSetupProduct
        },
        query: {
            getEntity: GetPropertyLicencesTechSetupProductsData
        },
    }
}
