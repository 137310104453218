import moment from 'moment';
import {
    isEmpty as _isEmpty,
    isFunction as _isFunction,
    isUndefined as _isUndefined,
} from 'lodash';

import * as MESSAGES from '@constants/messages';

const ALLOWED_IPV4_RANGE = 28;
const ALLOWED_IPV6_RANGE = 124;
const INT_UNSIGNED_MIN_VALUE = 0;
const INT_UNSIGNED_MAX_VALUE = 4294967295;

const getUrlRegex = (protocol) => {
    return `^((${protocol}):\\/\\/)`
        + /(((([a-z0-9-@]*\.)*)([a-z0-9@]+))(:[0-9]{1,5})?)?((\/(([a-zA-Z0-9\-_]*\.)*([a-zA-Z0-9\-_]+)|\.\.|\.)?)+)?(([?&][a-zA-Z0-9_!\\#/]{0,}=?[[\]{}a-zA-Z0-9_!\-:\\#/]{0,})+)?$/.source;
};

const containsDot = (value) => {
    return /\./.test(value);
}

export const validatorTypes = {
    floatAndNumbers: {
        validate: (value) => (!`${value}` || _isUndefined(value) || !/^[\d.]+$/.test(value)),
        message : MESSAGES.DIGITS_AND_FLOATS_ONLY,
    },
    floatAndNumbersWithFixedDecimal: {
        validate: (options) => (
            !`${options.value}`
            || _isUndefined(options.value)
            || !RegExp(`^([0-9]*)?([.][0-9]{${options.decimal || 2}})?$`).test(options.value)
        ),
        message: (options) => MESSAGES.DIGITS_AND_FLOAT_WITH_FIXED_DECIMAL_ONLY(options.decimal || 2),
    },
    digitsOnly: {
        validate: (value) => {
            return (_isUndefined(value) || !/^\d+$/.test(value));
        },
        message: MESSAGES.DIGITS_ONLY,
    },
    email: {
        validate: (value) => value && 0 < value.length && !/^[A-Z0-9._%+-]{1,200}@[A-Z0-9.-]{1,200}\.[A-Z]{2,24}$/i.test(value),
        message: MESSAGES.INVALID_EMAIL_ADDRESS,
    },
    multipleEmail: {
        validate: (emails) => {
            if (emails) {
                for (const email of emails) {
                    if (validatorTypes.email.validate(email)) {
                        return validatorTypes.email.message;
                    }
                }
            }

            return false;
        },
        message: MESSAGES.INVALID_EMAIL_ADDRESS,
    },
    phoneNumber: {
        validate: (value) => value && !/^\+?[\d\s]{1,75}$/.test(value),
        message: MESSAGES.INVALID_PHONE_NUMBER,
    },
    range: {
        validate: (options) => options.value && (parseInt(options.value, 10) < options.min || parseInt(options.value, 10) > options.max),
        message: (options) => MESSAGES.NUMBER_NOT_IN_RANGE(options.min, options.max),
    },
    rangeFloat: {
        validate: (options) => options.value && (options.value < options.min || options.value > options.max),
        message: (options) => MESSAGES.NUMBER_NOT_IN_RANGE(options.min, options.max),
    },
    dbInt: {
        validate: (value) => validatorTypes.range.validate({value, min: INT_UNSIGNED_MIN_VALUE, max: INT_UNSIGNED_MAX_VALUE}),
        message: () => MESSAGES.NUMBER_NOT_IN_RANGE(INT_UNSIGNED_MIN_VALUE, INT_UNSIGNED_MAX_VALUE),
    },
    required: {
        validate: (value) => {
            if ('[object Array]' === Object.prototype.toString.call(value)) {
                return 0 === value.length;
            }

            if ('[object Object]' === Object.prototype.toString.call(value)) {
                return 0 === Object.keys(value).length;
            }

            if ('number' === typeof value) {
                return false;
            }

            if ('string' === typeof value) {
                return 0 === value.trim().length;
            }

            return !value;
        },
        message: MESSAGES.FIELD_REQUIRED,
    },
    requiredAtLeastOneSubdivisionPerCountry: {
        validate: (values) => {
            let subdivisionsError = false;
            const errorCheckings = [];

            values.countries.forEach((continent) => {
                continent.children.forEach((country) => {
                    if (-1 < values.required.indexOf(country.value) && values.subdivisions) {
                        subdivisionsError = true;
                        values.subdivisions.forEach((subdivisionsCountry) => {
                            if (subdivisionsCountry.value === country.value) {
                                subdivisionsError = false;
                            }
                        });

                        errorCheckings.push(subdivisionsError);
                    }
                });
            });

            return !errorCheckings.every((value) => {
                return false === value;
            });
        },
        message: MESSAGES.INVALID_SUBDIVISION,
    },
    requiredAtLeastOneStreamDropdownPerClientToggle: {
        validate: (values) => {
            let validateResult = true;

            if (!_isEmpty(values.valueField) && !_isEmpty(values.values)) {
                const streamType = values.valueField.replace(/[0-9]/g, ''),
                    clientId = values.valueField.replace(/[a-z]/g, ''),
                    streams = values.values[`${streamType}Streams`];

                if (undefined !== streams) {
                    Object.keys(streams).forEach((streamValue) => {
                        if (clientId === streamValue.split('_')[2]) {
                            validateResult = false;
                        }
                    });
                }
            }

            return validateResult;
        },
        message: MESSAGES.INVALID_CLIENT_TOGGLE_DROPDOWNS,
    },
    requiredAtLeastOneSwitch: {
        validate: (values) => {
            const checked = values.filter(value => true === value);

            return !checked.length;
        },
        message: MESSAGES.ONE_SWITCH_ON,
    },
    date: {
        validate: (value) => {
            return !((/^\d{4}-\d{2}-\d{2}$/).test(value)
                && moment(value, 'YYYY-MM-DD', true).isValid());
        },
        message: MESSAGES.INVALID_DATE,
    },
    dateTime: {
        validate: (value) => !(moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
            || moment(value, 'YYYY-MM-DD HH:mm', true).isValid()) || _isEmpty(value),
        message: MESSAGES.INVALID_DATETIME,
    },
    time: {
        validate:(value) => value && !/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(value),
        message: MESSAGES.INVALID_TIME,
    },
    futureDateTime: {
        validate: (value) =>
            value && moment(value).format('YYYY-MM-DD HH:mm') > moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm'),
        message: 'You can not select time in the future.',
    },
    /**
     * Regex taken from here: https://code.tutsplus.com/tutorials/8-regular-expressions-you-should-know--net-6149
     * We could also take the same regex as in Symfony Validator (UrlValidator.php) if needed.
     * UPDATE:
     * Source regex was: /^(https?:\/\/)([\w\-.]+)\.([a-z.]{2,6})([\w\-?&=./]*)*\/?$/,
     * - added validation of IPv4 format as an alternative ( ((\d{1,3}\.){3})\d{1,3} ),
     * - added optional port number validation ( (:\d{1,5})? ),
     * - extracted protocol for separate validation.
     */
    url: {
        validate: (value, protocol = 'https?') => (
            !value || !containsDot(value) || /\s/g.test(value) || !RegExp(getUrlRegex(protocol)).test(value)
        ),
        message: MESSAGES.INVALID_URL,
    },
    urlOptional: {
        validate: (value, protocol = 'https?') => (
            value && (!containsDot(value) || /\s/g.test(value) || !RegExp(getUrlRegex(protocol)).test(value))
        ),
        message: MESSAGES.INVALID_URL,
    },
    cidrIPv4: {
        validate: (value) => !value || !/^(?=\d+\.\d+\.\d+\.\d+($|\/))(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.?){4}(\/([0-9]|[1-2][0-9]|3[0-2]))+$/.test(value),
        message: MESSAGES.INVALID_CIDR,
    },
    cidrIPv4Range: {
        validate: (value) => !value || !/\/(\d+)/.test(value) || (null !== value.match(/\/(\d+)/) && ALLOWED_IPV4_RANGE > value.match(/\/(\d+)/)[1]),
        message: MESSAGES.INVALID_CIDR_RANGE,
    },
    /**
     * Regex for IPv6 CIDR taken from here: https://www.regextester.com/93988
     */
    cidrIPv6: {
        validate: (value) => !value || !/^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))+$/.test(value),
        message: MESSAGES.INVALID_CIDR,
    },
    cidrIPv6Range: {
        validate: (value) => !value || !/\/(\d+)/.test(value) || (null !== value.match(/\/(\d+)/) && ALLOWED_IPV6_RANGE > value.match(/\/(\d+)/)[1]),
        message: MESSAGES.INVALID_CIDR_RANGE,
    },
    maxLength: {
        validate: (options) => options.value && options.value.length > options.maxLength,
        message: (options) => MESSAGES.TOO_LONG_STRING_MAX_LENGTH(options.maxLength),
    },
    minLength: {
        validate: (options) => options.value && options.value.length < options.minLength,
        message: MESSAGES.TOO_SHORT_STRING,
    },
    CheckboxCheckedRange: {
        validate: (options) => options.value && (options.value.length < options.min || options.value.length > options.max),
        message: (options) => MESSAGES.NUMBER_OF_CHECKBOX_NOT_IN_RANGE(options.min, options.max),
    },
    startDateBeforeEndDate: {
        validate: (options) => new Date(options.startDate) > new Date(options.endDate),
        message: MESSAGES.START_DATE_BEFORE_END_DATE,
    },
    alphanumeric: {
        validate: (value) => !value || _isUndefined(value) || !/^[a-zA-Z0-9]*$/.test(value),
        message: MESSAGES.ALPHANUMERIC_ONLY,
    },
    alphanumericWithCommasAndWhitespaces: {
        validate: (value) => !value || _isUndefined(value) || !/^[a-zA-Z0-9,\s]*$/.test(value),
        message: MESSAGES.ALPHANUMERIC_WITH_COMMAS_AND_WHITESPACES,
    },
    alphanumericAtAlphanumeric: {
        validate: (value) => !value || _isUndefined(value) || !/^[\w]+@[\w]+$/.test(value),
        message: MESSAGES.ALPHANUMERIC_AT_ALPHANUMERIC,
    },
    alphanumericWithOptionalUnderscore: {
        validate: (value) => !value || _isUndefined(value) || !/^[\w]*$/.test(value),
        message: MESSAGES.ALPHANUMERIC_WITH_UNDERSCORE,
    },
    alphanumericWithOptionalUnderscoreAndForwardSlash: {
        validate: (value) => !value || _isUndefined(value) || !/^[\w/]*$/.test(value),
        message: MESSAGES.ALPHANUMERIC_WITH_UNDERSCORE_AND_FORWARD_SLASH,
    },
    numeric: {
        validate: (value) => (value ? !/^\d+$/.test(value) : false),
        message: MESSAGES.DIGITS_ONLY,
    },
};

export const validatorFactory = (validatorType) => {
    const { validate, message } = validatorTypes[validatorType];

    return (value, fieldName, errorMessage = message, ...args) => {
        const errors = {};

        if (value && value.errorMessage) {
            errorMessage = value.errorMessage;
        }

        if (validate(value, ...args)) {
            let errorText = '';

            if (_isFunction(message)) {
                errorText = errorMessage(value, ...args);
            } else {
                errorText = errorMessage;
            }

            errors[fieldName] = errorText;
        }

        return errors;
    };
};

const validators = {};

Object.keys(validatorTypes).forEach((validatorType) => {
    validators[validatorType] = validatorFactory(validatorType);
});

export default validators;
