import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';

const AdSpliceFilters = ({
    data: {
        adSplices = [],
    },
    filters,
    loading,
    restoreFilterValues,
}) => {
    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={restoreFilterValues(filters.adSplice, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='adSplices'
                placeholder='Ad splice'
                options={adSplices}
                multiple
                setValue={restoreFilterValues(filters.adSplice, 'adSplices')}
            />
        </div>
    );
};

AdSpliceFilters.propTypes = {
    data: PropTypes.shape({
        adSplices: PropTypes.array,
    }),
    loading: PropTypes.bool,
    filters: PropTypes.object.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(AdSpliceFilters, {name: 'adSplice'});
