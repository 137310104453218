import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorApplication";

import * as MESSAGES from "../../app/constants/messages";
import {RTMP_ORIGIN_ENTRY_POINT_APPLICATION} from "../../app/constants/resources";

import {
    GetRtmpOriginEntryPointApplicationForForm,
    GetRtmpOriginEntryPointApplicationForFormDropdowns
} from "../../../graphql/rtmpOriginEntryPointApplication/query.graphql";
import {
    CreateRtmpOriginEntryPointApplication,
    DeleteRtmpOriginEntryPointApplication,
    UpdateRtmpOriginEntryPointApplication
} from "../../../graphql/rtmpOriginEntryPointApplication/mutation.graphql";

export default function ApplicationModel() {
    return {
        entityDataMapKey: "rtmpOriginEntryPointApplication",
        entityLabel: "RTMP origin entry point application",
        formName: "RTMP origin entry point application",
        label: "RTMP origin entry point application",
        resources: RTMP_ORIGIN_ENTRY_POINT_APPLICATION,
        showChangelog: true,
        validate: validate,
        title: () =>  "Add new RTMP origin entry point application",
        autoFocusOnField: 'name',
        dataMap: {
            rtmpOriginEntryPointApplication: {
                id : "",
                name: "",
                rtmp_origin_entry_point_application_type: {id: ""},
            },
        },
        messages: {
            boxName: {
                delete: "rtmpOriginEntryPoint",
                error: "rtmpOriginEntryPoint",
                success: "rtmpOriginEntryPoint",
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('RTMP origin entry point application')
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "rtmpOriginEntryPointApplication.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                },
            },
            name: {
                dataMapKey: "rtmpOriginEntryPointApplication.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            rtmp_origin_entry_point_application_type: {
                dataMapKey: "rtmpOriginEntryPointApplication.rtmp_origin_entry_point_application_type.id",
                optionsKey: "rtmpOriginEntryPointApplicationTypes",
                props: {
                    component: Form.SemanticSelect,
                    label: "Type",
                    name: "rtmp_origin_entry_point_application_type",
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: CreateRtmpOriginEntryPointApplication,
            deleteEntity: DeleteRtmpOriginEntryPointApplication,
            updateEntity: UpdateRtmpOriginEntryPointApplication,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetRtmpOriginEntryPointApplicationsForTable'],
            },
            update: {
                refetchQueries: ['GetRtmpOriginEntryPointApplicationsForTable'],
            },
            delete: {
                refetchQueries: ['GetRtmpOriginEntryPointApplicationsForTable'],
            },
        },
        query: {
            getEntity: GetRtmpOriginEntryPointApplicationForForm,
            getOptions: GetRtmpOriginEntryPointApplicationForFormDropdowns,
        },
    }
}
