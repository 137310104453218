import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import Table from "../../app/components/Table";

import {THIRD_PARTY_CLIENT_VOD_STORAGE} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_UPDATE} from "../../app/constants/variables";

const columns = {
    "id": {
        label: "ID",
        sorting: true,
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
    "name": {label: "Name", sorting: true},
    "upload_target_type": {
        label: "Type",
        sorting: (row) => (row.upload_target_type.name.capitalizeFirstLetter()),
    },
    "path": {label: "Path", sorting: true},
    "encoding_job_profile": {
        label: "Encoding job profile",
        sorting: (row) => (row.encoding_job_profile.name.capitalizeFirstLetter()),
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
};

const ThirdPartyVodStoragesTable = (props) => {
    const {clientId, loading, thirdPartyClientVodStorages} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "name":
            case "path":
                return row[column];
            case "upload_target_type":
            case "encoding_job_profile":
                return row[column].name;
            case "actions": {
                return (
                    <Authorization
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        resources={THIRD_PARTY_CLIENT_VOD_STORAGE}
                    >
                        <div className="actions-list-icon">
                            <IconEdit
                                link={getLink('clients.products.thirdParty.vodStorages.edit', {
                                    id: clientId,
                                    thirdPartyClientVodStorageId: row.id,
                                })}
                                size="large"
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="third_party_vod_storages__table">
            <Table
                name="thirdPartyClientVodStoragesList"
                noDataText="No VOD storages found"
                columns={columns}
                data={thirdPartyClientVodStorages}
                loading={loading}
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

ThirdPartyVodStoragesTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    thirdPartyClientVodStorages: PropTypes.array,
};

ThirdPartyVodStoragesTable.defaultProps = {
    thirdPartyClientVodStorages: [],
};

export default ThirdPartyVodStoragesTable;
