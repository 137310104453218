import {sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint import/no-unresolved: 0 */
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Header} from 'semantic-ui-react';

import {gql, graphql, withApollo} from 'react-apollo';
import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import MessageBox from '@appComponents/MessageBox';
import {refetchQueryByName} from '@utils/apollo';
import * as eventStreamConstraintsGraphQl from '@graphql/events/eventStreamConstraints';

import EventStreamConstraintsTable from '../components/EventStreamConstraintsTable';

export class EventStreamConstraints extends React.Component {
    static propTypes = {
        Modal: PropTypes.object,
        MessageBox: PropTypes.object,
        DataEventStreamConstraints: PropTypes.object,
        Form: PropTypes.object,
        eventId: PropTypes.number.isRequired,
        streamConstraintToSave: PropTypes.object,
        streamConstraintToUpdate: PropTypes.object,
        CreateMutation: PropTypes.func,
        DeleteMutation: PropTypes.func,
        UpdateMutation: PropTypes.func,
        match: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {
            eventStreamConstraints: [],
            deviceCategories: [],
            products: [],
            loading: false,
        };
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const {DataEventStreamConstraints: {eventStreamConstraints, products, deviceCategories}} = nextProps;

        if (eventStreamConstraints) {
            return {
                eventStreamConstraints: _sortBy(eventStreamConstraints, 'product.name'),
                products,
                deviceCategories,
            };
        }

        return null;
    }

    handleStreamConstraintsAdd = () => {
        this.setState(() => ({loading: true}));

        const dataToSave = Object.assign({}, {
            eventId: parseInt(this.props.eventId, 10),
            productId: parseInt(this.props.streamConstraintToSave.product, 10),
            deviceCategoryId: parseInt(this.props.streamConstraintToSave.device_category, 10),
            maxBitrate: parseInt(this.props.streamConstraintToSave.max_bitrate, 10),
            maxResolutionWidth: parseInt(this.props.streamConstraintToSave.max_resolution_width, 10),
            maxResolutionHeight: parseInt(this.props.streamConstraintToSave.max_resolution_height, 10),
            maxPlayerSize: parseInt(this.props.streamConstraintToSave.max_player_size, 10),
        });
        const {
            MessageBox: {addMessage},
            Form: {clearForm},
        } = this.props;

        return this.props.CreateMutation({
            variables: {...dataToSave},
        }).then(() => {
            addMessage(
                'eventStreamConstraintsMessage',
                'The stream constraint has been saved successfully.',
                null, 'success'
            );
            refetchQueryByName('EventStreamConstraints');
            clearForm('form_esc_add');

            this.setState(() => ({loading: false}));
        }).catch(this.showErrorStreamConstraintNotSaved);
    };

    handleStreamConstraintsDelete = (id) => {
        this.props.Modal.setModalConfirmation({
            header: <Header icon='trash' content='Delete stream constraint'/>,
            text: 'Are you sure you want to delete this stream constraint?',
            onYes: () => (this.deleteStreamConstraint(id)),
        });
    };

    handleStreamConstraintsUpdate = (event, data) => {
        this.setState(() => ({loading: true}));

        const streamConstraintToSave = this.props.streamConstraintToUpdate;

        const dataToUpdate = Object.assign({}, {
            id: parseInt(streamConstraintToSave.id),
            productId: parseInt(streamConstraintToSave.product || data['data-product'], 10),
            deviceCategoryId: parseInt(streamConstraintToSave.device_category || data['data-device'], 10),
            maxBitrate: parseInt(streamConstraintToSave.max_bitrate, 10),
            maxResolutionWidth: parseInt(streamConstraintToSave.max_resolution_width, 10),
            maxResolutionHeight: parseInt(streamConstraintToSave.max_resolution_height, 10),
            maxPlayerSize: parseInt(streamConstraintToSave.max_player_size, 10),
        });
        const {
            MessageBox: {addMessage},
        } = this.props;

        this.props.UpdateMutation({
            variables: {...dataToUpdate},
        }).then(() => {
            addMessage(
                'eventStreamConstraintsMessage',
                'The stream constraint has been saved successfully.',
                null,
                'success'
            );
            refetchQueryByName('EventStreamConstraints');
            this.setState(() => ({loading: false}));
        }).catch(
            this.showErrorStreamConstraintNotSaved
        );
    };

    showErrorStreamConstraintNotSaved = (error) => {
        this.props.MessageBox.addMessage(
            'eventStreamConstraintsMessage',
            'The stream constraint could not be saved.',
            `${error}`,
            'error'
        );

        this.setState(() => ({loading: false}));
    };

    deleteStreamConstraint(streamConstraintId) {
        const dataToDelete = Object.assign({}, {
            id: streamConstraintId,
        });
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: 'The stream constraint is being deleted...',
        });

        return this.props.DeleteMutation({
            variables: {...dataToDelete},
        }).then(() => {
            setModal({isVisible: false});

            addMessage('eventStreamConstraintsMessage',
                'The stream constraint has been deleted successfully.',
                null,
                'success'
            );
            refetchQueryByName('EventStreamConstraints');
        }).catch((error) => {
            setModal({isVisible: false});

            addMessage(
                'eventStreamConstraintsMessage',
                'The stream constraint could not be deleted.',
                `${error}`,
                'error'
            );
        });
    }

    render() {
        const loading = this.props.DataEventStreamConstraints.loading || this.state.loading;

        return (
            <div className='eventStreamConstraintsContainer editableTable'>
                <MessageBox name='eventStreamConstraintsMessage'/>
                <EventStreamConstraintsTable
                    loading={loading}
                    onAdd={this.handleStreamConstraintsAdd}
                    onDelete={this.handleStreamConstraintsDelete}
                    onUpdate={this.handleStreamConstraintsUpdate}
                    products={this.state.products}
                    deviceCategories={this.state.deviceCategories}
                    eventStreamConstraints={this.state.eventStreamConstraints}
                />
            </div>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox', 'Form']);

export const mapStateToProps = (state) => {
    let streamConstraintToSave = null;
    let streamConstraintToUpdate = null;

    if (state.form.form_esc_update !== undefined) {
        streamConstraintToUpdate = (state.form.form_esc_update.values);
    }

    if (state.form.form_esc_add !== undefined) {
        streamConstraintToSave = (state.form.form_esc_add.values);
    }

    return {
        streamConstraintToSave: streamConstraintToSave,
        streamConstraintToUpdate: streamConstraintToUpdate,
    };
};

const ListQuery = gql(eventStreamConstraintsGraphQl.eventStreamConstraints);
const CreateMutation = gql(eventStreamConstraintsGraphQl.CreateMutation);
const DeleteMutation = gql(eventStreamConstraintsGraphQl.DeleteMutation);
const UpdateMutation = gql(eventStreamConstraintsGraphQl.UpdateMutation);

const EventStreamConstraintsWithApollo = withApollo(EventStreamConstraints);
const EventStreamConstraintsWithStore = connect(mapStateToProps, mapDispatchToProps)(EventStreamConstraintsWithApollo);

export default compose(
    graphql(ListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    eventId: convertToInt(props.eventId),
                },
            };
        },
        name: 'DataEventStreamConstraints',
    }),

    graphql(CreateMutation, {name: 'CreateMutation'}),
    graphql(DeleteMutation, {name: 'DeleteMutation'}),
    graphql(UpdateMutation, {name: 'UpdateMutation'})
)(withRouter(EventStreamConstraintsWithStore));
