import moment from "moment";

/**
 * Check if date is between range - start_date / end_date
 * @param date
 * @param datesObject
 * @returns {boolean}
 */
export const checkDateRange = (date, datesObject) => {
    date = transformMomentDatetimeToDate(date);

    return (date.isSameOrAfter(datesObject.start_date) && date.isSameOrBefore(datesObject.end_date))
};

export const transformMomentDatetimeToDate = (date) => {
    const momentObject = !moment.isMoment(date) ? moment(date) : date;

    momentObject.set({
        "hour": 0,
        "minute": 0,
        "second": 0
    });

    return momentObject;
};
