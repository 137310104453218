import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {
    get as _get,
    isEqual as _isEqual,
    isNumber as _isNumber,
    orderBy as _orderBy,
    isNil as _isNil,
} from 'lodash';
import {Button, Header, Segment} from 'semantic-ui-react';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router';

import {getLink} from '@appComponents/Link';
import {graphql} from 'react-apollo';
import SemanticTreeTable from '@appComponents/ReduxFormControlsComponents/SemanticTreeTable';
import {Checkbox} from '@appComponents/TreeTableComponents/Checkbox';
import {IconSettings, IconSettingsAdd, IconGlobe, IconGlobeAdd} from '@appComponents/IconCollection';
import MessageBox from '@appComponents/MessageBox';
import Authorization from '@appComponents/Authorization';
import {
    saveClientPackageContentTree as saveClientPackageContentTreeMutation,
} from '@graphql/clients/mutation';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {clientPackageContentTree as contentPackageTreeQuery} from '@graphql/clients/query';
import {
    EMPTY_VALUE_DASH,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import {CLIENT_PACKAGE_CONTENT} from '@constants/resources';
import {formattedPriceOrEmptyValue, convertToInt} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {hideModal, showModal, showModalConfirmation, showErrorModal} from '@utils/modal';
import {getTerritoriesWithTooltip, getSortingTerritories} from '@utils/countryHelpers';
import {routes} from '@constants/routes';

import ContentPackageForm from '../forms/ContentPackageForm';
import PackageContentTierGeoRestrictionsLayout from '../forms/PackageContentTierGeoRestrictionsLayout';
import client from '../../../apolloClient';

class ContentIndex extends React.Component {
    static propTypes = {
        ContentTreeData: PropTypes.object,
        booking: PropTypes.object,
        saveClientPackageContentTree: PropTypes.func,
        submitting: PropTypes.bool,
        clientName: PropTypes.string,
        productVariants: PropTypes.array,
        clientId: PropTypes.number,
        clientProduct: PropTypes.object,
        clientPackageName: PropTypes.string,
        packageId: PropTypes.number,
        match: PropTypes.shape({
            path: PropTypes.string.isRequired,
            params: PropTypes.object,
        }),
        clientPackageId: PropTypes.number,
        client_package: PropTypes.number,
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }),
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: props.ContentTreeData.loading,
            data: [],
            selected: false,
            dataForTable: [],
            clientName: props.clientName,
            clientPackageName: props.clientPackageName,
        };
    }

    composeTableColumns = () => {
        const hasEventBookingSupport  = this.props.clientProduct?.product?.has_event_booking_support;
        const hasHqSupport = this.props.clientProduct?.product?.has_hq_support;

        return {
            content: {
                label: 'Content',
            },
            ...(hasEventBookingSupport
                ? {event_booking_type: {label: 'Event booking type', align: 'center'}}
                : {}
            ),
            booking: {
                label: 'Booking type',
            },
            event_content_variants: {
                label: 'Content variants',
            },
            ...(hasHqSupport ?
                {
                    has_hq: {
                        label: 'HQ',
                        align: 'center',
                    },
                } : {}
            ),
            num_bookable_events: {
                label: '#Events',
            },
            event_price: {
                label: 'Event price',
                align: 'right',
            },
            ...(hasEventBookingSupport ?
                {
                    event_content_type_configs: {
                        label: 'Clips',
                        align: 'left',
                    },
                } : {}
            ),
            territories: {
                label: 'Territories',
                align: 'left',
                sorting: (row) => getSortingTerritories(row),
            },
            active: {
                label: 'Active?',
            },
            actions: {
                label: 'Actions',
            },
        };
    };

    generateLinkForModals = (data, path) => {
        if (!data && !data.parents) {
            return this.openErrorModal();
        }

        const urlParams = [];

        data.parents.sportId && urlParams.push('sport_id=' + convertToInt(data.parents.sportId));
        data.parents.content_tier && urlParams.push('content_tier_id=' + convertToInt(data.parents.content_tier));
        data.parents.tournament_category && urlParams.push('tournament_category_id=' + convertToInt(data.parents.tournament_category));
        data.parents.property_licence && urlParams.push('property_licence_id=' + convertToInt(data.parents.property_licence));
        data.parents.tournament && urlParams.push('tournament_id=' + convertToInt(data.parents.tournament));

        const paramArray = urlParams.filter(param => param);
        let params = {};

        if ('clients.packages.content.index' === path) {
            params = {
                id: this.props.clientId,
                packagesId: this.props.clientPackageId,
            };
        } else if ('clients.packages.content.geoRestrictions' === path) {
            params = {
                id: this.props.clientId,
                packageId: this.props.clientPackageId,
            };
        }

        this.props.history.push({
            pathname: getLink(path, params),
            search: '?' + paramArray.join('&'),
        });
    };

    openEditModal = (data) => {
        this.generateLinkForModals(data, 'clients.packages.content.index');

        showModal({
            size: 'small',
            isVisible: true,
            className: 'client-package-content-modal',
            content: <ContentPackageForm
                header={`Client '${this.state.clientName}' - ${this.state.clientPackageName} - Content\n${data.parentNames.join(' > ')} > ${data.name}`}
                entityName={`${data.parentNames.join(' > ')} > ${data.name}`}
                bookingTypes={this.props.ContentTreeData.bookingTypes}
                eventContentVariants={this.props.ContentTreeData.eventContentVariants}
                productVariants={this.props.clientProduct.product.product_event_content_variants}
                product={this.props.clientProduct.product}
                formData={data.clientPackageContentData}
                isActive={data.is_active}
                parentTreeData={data.parents}
                treeActiveStatus={data.treeActiveStatus}
                treeData={this.state.dataForTable}
                clientId={convertToInt(this.props.clientId)}
                clientPackageId={convertToInt(this.props.clientPackageId)}
                reloadTable={this.reloadTable}
                level={data.parentNames.length + 1}
                backToIndexUrl={this.backToIndexUrl}
            />,
        });
    };

    openErrorModal = () => {
        return showErrorModal({
            header: 'Page not found',
            text: 'Please check the url and try again',
            errorButtonText: 'Back to packages content table',
            hideButtons: false,
            redirectLink: getLink('clients.packages.content.index',
                {
                    id: this.props.clientId,
                    packagesId: this.props.clientPackageId,
                }),
            errorButtonIcon: 'arrow circle left',
        });
    };

    openEditGlobeModal = (data) => {
        this.generateLinkForModals(data, 'clients.packages.content.geoRestrictions');

        showModal({
            size: 'small',
            isVisible: true,
            className: 'client-package-content-geo-modal',
            content: <PackageContentTierGeoRestrictionsLayout
                header={`Client '${this.state.clientName}' - ${this.state.clientPackageName} - Content\n${data.parentNames.join(' > ')} > ${data.name}`}
                entityName={`${data.parentNames.join(' > ')} > ${data.name}`}
                bookingTypes={this.props.ContentTreeData.bookingTypes}
                eventContentVariants={this.props.ContentTreeData.eventContentVariants}
                productVariants={this.props.clientProduct?.product?.product_event_content_variants}
                product={this.props.clientProduct?.product}
                formData={data.clientPackageContentData}
                isActive={data.is_active}
                parentTreeData={data.parents}
                treeActiveStatus={data.treeActiveStatus}
                treeData={this.state.dataForTable}
                clientId={convertToInt(this.props.clientId)}
                clientPackageId={convertToInt(this.props.clientPackageId)}
                reloadTable={this.reloadTable}
                level={data.parentNames.length + 1}
                sport={convertToInt(data.parents.sportId)}
                id={data.id}
                clientPackageName={this.props.clientPackageName}
                backToIndexUrl={this.backToIndexUrl}
            />,
        });
        this.setState({openEditGlobeModal: true});
    };

    reloadTable = () => {
        hideModal();
        this.setState(() => ({
            loading: true,
        }));
        this.setSelected(false);
        this.props.ContentTreeData.refetch().then(() => {
            this.setState(() => ({
                loading: false,
            }));
        });
        this.backToIndexUrl();
    };

    backToIndexUrl = () => {
        this.props.history.push(getLink(
            'clients.packages.content.index',
            {
                id: this.props.clientId,
                packagesId: this.props.clientPackageId,
            }
        ));
    };

    componentDidMount() {
        this.getMappedIsoCodes();
    }

    componentDidUpdate(prevProps, prevState) {
        let data = prevState.dataForTable;

        if (this.props.ContentTreeData.clientPackageContentTree
            && !_isEqual(this.props.ContentTreeData.clientPackageContentTree, this.state.data)
            && (!this.props.ContentTreeData.loading
                && this.props.ContentTreeData !== prevProps.ContentTreeData
                && this.props.ContentTreeData.clientPackageContentTree !== prevProps.ContentTreeData.clientPackageContentTree)
        ) {
            this.setState(() => ({
                loading: false,
            }));

            this.booking = this.props.ContentTreeData.clientPackage.booking_type;
            this.eventBooking = this.props.ContentTreeData.clientPackage.event_booking_type;
            this.clientPackage = this.props.ContentTreeData.clientPackage;

            data = this.parseDataForTable(this.props.ContentTreeData.clientPackageContentTree, 1);

            this.setState(() => ({
                data: this.props.ContentTreeData.clientPackageContentTree,
                dataForTable: data,
                clientName: this.props.clientName,
                clientPackage: this.props.ContentTreeData.clientPackage,
                clientPackageId: this.props.clientPackageId,
            }), () => {
                const queryStringToObject = url =>
                    [...new URLSearchParams(url.split('?')[1])].reduce(
                        (a, [k, v]) => ((a[k] = convertToInt(v)), a),
                        {}
                    );
                const params = this.props.location.search && queryStringToObject(this.props.location.search);
                let dataForForm;

                if (params && this.state.dataForTable && this.state.dataForTable.length
                    && this.state.dataForTable !== prevState.dataForTable) {
                    dataForForm = this.state.dataForTable.find((item)=>item.value === params.sport_id.toString());

                    if (params.content_tier_id && dataForForm && dataForForm.children) {
                        dataForForm = dataForForm.children.find((item) => item.parents.content_tier === params.content_tier_id.toString());

                        if (params.tournament_category_id) {
                            dataForForm = dataForForm.children.find((item) => item.parents.tournament_category === params.tournament_category_id.toString());

                            if (params.property_licence_id) {
                                dataForForm = dataForForm.children.find((item) => item.parents.property_licence === params.property_licence_id.toString());

                                if (params.tournament_id) {
                                    dataForForm = dataForForm.children.find((item) => item.parents.tournament === params.tournament_id.toString());
                                }
                            }
                        } else if (params.property_licence_id && dataForForm && dataForForm.children) {
                            dataForForm = dataForForm.children.map((item) => {
                                dataForForm = item.children.find((itemLevelBelow)=> itemLevelBelow.parents.property_licence === params.property_licence_id.toString());

                                if (params.tournament_id) {
                                    dataForForm = dataForForm?.children.find((item) => item.parents.tournament === params.tournament_id.toString());
                                }

                                return dataForForm;
                            });
                            dataForForm = dataForForm[0];
                        }
                    }

                    if (routes.clients.packages.content.index.path === this.props.match.path) {
                        this.openEditModal(dataForForm);
                    } else if (routes.clients.packages.content.geoRestrictions.path === this.props.match.path) {
                        this.openEditGlobeModal(dataForForm);
                    }
                }
            });
        }
    }

    getContentPackageTreeLevelObject(level, data, parentId) {
        const treeObject = {
            keys: [],
            treeId: null,
        };

        switch (level) {
            case 1:
                treeObject.keys = ['sport', 'content_category'];
                break;
            case 2:
                treeObject.keys = ['content_tier'];
                break;
            case 3:
                treeObject.keys = ['tournament_category', 'content_category'];
                break;
            case 4:
                treeObject.keys = ['property_licence'];
                break;
            case 5:
                treeObject.keys = ['tournament', 'content_category'];
                break;
        }

        if (data) {
            treeObject.keys.forEach(
                (value) => {
                    if (data[value]) {
                        const contentCategory = data[value],
                            id = `${this.removeDashes(contentCategory.name)}-${this.removeDashes(contentCategory.id)}`
                                .replace(/[\s.,]/g, '&&');

                        treeObject.treeId = (parentId ? `${parentId}.${id}` : id);
                    }
                }
            );
        }

        return treeObject;
    }

    removeDashes(value) {
        return value.replace(/-|\[|\]/g, '');
    }

    parseDataForTable(data, level, parent, parentNames) {
        let parentNamesArray = [];

        if (parentNames) {
            parentNamesArray = [...parentNames];
        }

        if (parent) {
            parentNamesArray.push(parent.name);
        }

        const rowsData = data.map((object) => {
            const rowData =
                this.createRowData(object, parent, this.getContentPackageTreeLevelObject(level).keys, level);

            if (object.children && 0 < object.children.length) {

                rowData.children = this.parseDataForTable(object.children, level + 1, rowData, parentNamesArray);
            }

            rowData.parentNames = parentNamesArray;

            return rowData;
        });

        return _orderBy(rowsData, (row) => (row.name.toLowerCase()), ['asc']);
    }

    createRowData(data, parentData, keys, level) {
        let row = {};

        const clientPackageContentData = data.client_package_content;

        for (let value of keys) {
            if (clientPackageContentData[value]) {
                const source = clientPackageContentData[value],
                    parents = {
                        event_booking_type: null,
                        booking: null,
                        sport: null,
                        content_category: null,
                        content_tier: null,
                        tournament_category: null,
                        property_licence: null,
                        tournament: null,
                        event_content_type_configs: null,
                        event_content_variants: null,
                        is_hq: null,
                        territory_selection_type: null,
                        countries: null,
                        country_subdivisions: null,
                    },
                    values = {
                        event_booking_type: clientPackageContentData.event_booking_type,
                        booking: clientPackageContentData.booking_type,
                        events: '',
                        eventPrice: '',
                        event_content_type_configs: clientPackageContentData.event_content_type_configs,
                        event_content_variants: clientPackageContentData.event_content_variants,
                        is_hq: clientPackageContentData.is_hq,
                        territory_selection_type: null,
                        countries: [],
                        country_subdivisions: [],
                    };

                if (!clientPackageContentData.booking_type) {
                    if (1 < level) {
                        values.booking = parentData.parents.booking;
                    }

                    if (!values.booking) {
                        values.booking = this.booking;
                    }
                }

                parents.booking = values.booking;

                if (!clientPackageContentData.event_booking_type) {
                    if (1 < level) {
                        values.event_booking_type = parentData.parents.event_booking_type;
                    }

                    if (!values.event_booking_type) {
                        values.event_booking_type = this.eventBooking;
                    }
                }

                parents.event_booking_type = values.event_booking_type;

                if (!clientPackageContentData.is_hq) {
                    if (1 < level) {
                        values.is_hq = parentData.parents.is_hq;
                    }

                    if (!values.is_hq) {
                        values.is_hq = this.clientPackage.is_hq;
                    }
                }

                parents.is_hq = values.is_hq;

                if (!values.event_content_type_configs || !values.event_content_type_configs.length) {
                    if (1 < level) {
                        values.event_content_type_configs = parentData.parents.event_content_type_configs;
                    }
                }

                parents.event_content_type_configs = values.event_content_type_configs;

                if (!values.event_content_variants || !values.event_content_variants.length) {
                    if (1 < level) {
                        values.event_content_variants = parentData.parents.event_content_variants;

                        if (!values.event_content_variants || !values.event_content_variants.length) {
                            values.event_content_variants = this.clientPackage.event_content_variants;
                        }
                    }
                }

                parents.event_content_variants = values.event_content_variants;

                switch (level) {
                    case 2:
                        parents.sport = clientPackageContentData.sport && clientPackageContentData.sport.id;
                        parents.content_category = clientPackageContentData.content_category && clientPackageContentData.content_category.id;
                        parents.content_tier = clientPackageContentData.content_tier && clientPackageContentData.content_tier.id;

                        break;
                    case 3:
                        parents.content_category = clientPackageContentData.content_category && clientPackageContentData.content_category.id;
                        parents.content_tier = parentData.parents.content_tier;
                        parents.tournament_category = clientPackageContentData.tournament_category && clientPackageContentData.tournament_category.id;

                        break;
                    case 4:
                        parents.content_category = parentData.parents.content_category;
                        parents.content_tier = parentData.parents.content_tier;
                        parents.tournament_category = parentData.parents.tournament_category;
                        parents.property_licence = clientPackageContentData.property_licence && clientPackageContentData.property_licence.id;

                        break;
                    case 5:
                        parents.content_category = clientPackageContentData.content_category && clientPackageContentData.content_category.id;
                        parents.content_tier = parentData.parents.content_tier;
                        parents.tournament = clientPackageContentData.tournament && clientPackageContentData.tournament.id;
                        parents.property_licence = parentData.parents.property_licence;

                        break;
                }

                if (clientPackageContentData.sport?.id) {
                    parents.sportId = clientPackageContentData.sport.id;
                } else {
                    parents.sportId = parentData?.parents?.sportId ?? parentData?.sportId ?? null;
                }

                if (1 !== level) {
                    values.events = (null !== clientPackageContentData.num_bookable_events
                        ? clientPackageContentData.num_bookable_events : parentData.num_bookable_events);

                    values.eventPrice = (_isNumber(clientPackageContentData.event_price))
                        ? clientPackageContentData.event_price
                        : parentData.event_price;

                    values.is_hq = (null !== clientPackageContentData.is_hq
                        ? clientPackageContentData.is_hq : parentData.is_hq);

                    if (!clientPackageContentData.territory_selection_type) {
                        values.territory_selection_type = parentData.territory_selection_type;
                        values.countries = parentData.countries;
                        values.country_subdivisions = parentData.country_subdivisions;
                    } else {
                        values.territory_selection_type = clientPackageContentData.territory_selection_type;
                        values.countries = clientPackageContentData.countries;
                        values.country_subdivisions = clientPackageContentData.country_subdivisions;
                    }

                    parents.territory_selection_type = values.territory_selection_type;
                    parents.countries = values.countries;
                    parents.country_subdivisions = values.country_subdivisions;
                }

                row = {
                    id: clientPackageContentData.id,
                    is_active: clientPackageContentData.is_active || false,
                    key: clientPackageContentData.id,
                    value: source.id,
                    name: source.name,
                    content: source.name,
                    selected: clientPackageContentData.is_active,
                    event_booking_type: values.event_booking_type,
                    booking: values.booking,
                    booking_type: (values.booking ? values.booking.id : null),
                    event_content_type_configs: this.formatEventContentTypes(values.event_content_type_configs),
                    event_content_variants: this.formatEventContentVariants(values.event_content_variants),
                    num_bookable_events: values.events,
                    event_price: values.eventPrice,
                    parents: parents,
                    clientPackageContentData: clientPackageContentData,
                    clips: values.clips,
                    is_hq: values.is_hq,
                    territory_selection_type: values.territory_selection_type,
                    countries: values.countries,
                    country_subdivisions: values.country_subdivisions,
                };

                row.treeActiveStatus = {
                    is_active: row.is_active,
                    parent: _get(parentData, 'treeActiveStatus', null),
                };
            }
        }

        return row;
    }

    rowRender = (column, data, treeState, treeActions, level) => {
        let columnToRender = null;

        const emptyValue = 0 === level ? '' : EMPTY_VALUE_DASH;
        const eventPrice = data.clientPackageContentData.event_price || data.event_price;

        data.is_active = -1 < treeState.selected.indexOf(data.treeId);

        switch (column) {
            case 'event_price': {
                columnToRender = formattedPriceOrEmptyValue(eventPrice, emptyValue);

                if (_isNumber(data.clientPackageContentData.event_price)) {
                    columnToRender = <strong>{columnToRender}</strong>;
                }

                break;
            }
            case 'num_bookable_events':
                columnToRender = data.clientPackageContentData.num_bookable_events ?? emptyValue;

                if (_isNumber(data.clientPackageContentData.num_bookable_events) && data.id) {
                    columnToRender = <strong>{columnToRender}</strong>;
                }
                break;
            case 'booking':
                columnToRender = emptyValue;

                if (0 !== level && data.booking) {
                    columnToRender = data.booking.name;
                }

                if (data.clientPackageContentData.booking_type && data.id) {
                    columnToRender = <strong>{columnToRender}</strong>;
                }

                break;
            case 'territories':
                if(0 !== level) {
                    const hasGeoRestrictions = null !== data.clientPackageContentData.territory_selection_type;

                    let territoriesConfiguration = {
                        id: data.id,
                        countries: _get(data, 'countries', []),
                        country_subdivisions: _get(data, 'country_subdivisions', []),
                        territory_selection_type: _get(data, 'territory_selection_type', null),
                    };

                    columnToRender = getTerritoriesWithTooltip(territoriesConfiguration, this.state.mappedIsoCodesData);

                    return hasGeoRestrictions ? <strong>{columnToRender}</strong> : columnToRender;
                }
                break;
            case 'event_booking_type':
                columnToRender = emptyValue;

                if (0 !== level && data.event_booking_type) {
                    columnToRender = data.event_booking_type.name;
                }

                if (data.clientPackageContentData.event_booking_type && data.id) {
                    columnToRender = <strong>{columnToRender}</strong>;
                }

                break;
            case 'event_content_type_configs':
                columnToRender = emptyValue;

                if (0 !== level && data.event_content_type_configs) {
                    columnToRender = data.event_content_type_configs;
                }

                if (data.clientPackageContentData.event_content_type_configs?.length && data.id) {
                    columnToRender = <strong>{columnToRender}</strong>;
                }

                break;
            case 'event_content_variants':
                columnToRender = emptyValue;

                if (0 !== level && data.event_content_variants) {
                    columnToRender = data.event_content_variants;
                }

                if (data.clientPackageContentData.event_content_variants?.length && data.id) {
                    columnToRender = <strong>{columnToRender}</strong>;
                }

                break;
            case 'has_hq':
                columnToRender = emptyValue;

                if (0 !== level && !_isNil(data.is_hq)) {
                    if (data.is_hq) {
                        columnToRender = 'Yes';
                    } else {
                        columnToRender = 'No';
                    }
                }

                if (!_isNil(data.clientPackageContentData.is_hq) && data.id) {
                    if (data.clientPackageContentData.is_hq) {
                        columnToRender = <strong>Yes</strong>;
                    } else {
                        columnToRender = <strong>No</strong>;
                    }
                }

                break;
            case 'active':
                columnToRender = (0 !== level)
                    && <Checkbox
                        data={data} onClick={treeActions.checkChildren}
                        selected={-1 < treeState.selected.indexOf(data.treeId)}
                        indeterminate={-1 < treeState.indeterminate.indexOf(data.treeId)}/>;
                break;
            case 'actions': {
                const iconProps = {
                    onClick: this.openEditModal,
                    onClickElement: data,
                    className: 'large',
                };

                const iconGlobProps = {
                    onClick: this.openEditGlobeModal,
                    onClickElement: data,
                    className: 'large',
                };

                if ((1 <= level)) {
                    columnToRender =
                        <Authorization resources={CLIENT_PACKAGE_CONTENT} privileges={SECURITY_PRIVILEGES_UPDATE}>
                            {
                                (_get(data, 'clientPackageContentData.booking_type.id', null)
                                    || _get(data, 'clientPackageContentData.event_booking_type.id', null)
                                    || data.clientPackageContentData.num_bookable_events
                                    || data.clientPackageContentData.event_content_type_configs?.length
                                    || data.clientPackageContentData.event_content_variants?.length
                                    || _isNumber(data.clientPackageContentData.event_price)
                                    || !_isNil(data.clientPackageContentData.is_hq)
                                )
                                    ? <IconSettings {...iconProps}/>
                                    : <IconSettingsAdd {...iconProps}/>
                            }
                            {
                                (data.clientPackageContentData.territory_selection_type?.id && data.clientPackageContentData?.countries)
                                    ? <IconGlobe {...iconGlobProps} size='large' />
                                    : <IconGlobeAdd {...iconGlobProps} size='large' />
                            }
                        </Authorization>;
                }
            }
        }

        if (null === columnToRender && data[column]) {
            return data[column];
        }

        return columnToRender;
    };

    getTreeDataForSave(data, level, parentId) {
        if (!level) {
            level = 1;
        }

        return data.map((object) => {
            const rowToSave = {
                    children: null,
                    client_package_content: null,
                },
                clientPackageContent = object.client_package_content,
                treeObject = this.getContentPackageTreeLevelObject(level, object.client_package_content, parentId);

            const isActive = (-1 < this.state.selected.indexOf(treeObject.treeId)),
                isActiveParent = (-1 < this.state.selected.indexOf(parentId));

            if (0 < object.children.length) {
                rowToSave.children = this.getTreeDataForSave(object.children, level + 1, treeObject.treeId);
            }

            if (clientPackageContent.id || isActive !== isActiveParent) {
                rowToSave.client_package_content = {
                    id: clientPackageContent.id,
                    sport: clientPackageContent.sport && parseInt(clientPackageContent.sport.id, 10),
                    content_tier: clientPackageContent.content_tier && parseInt(clientPackageContent.content_tier.id, 10),
                    tournament_category: clientPackageContent.tournament_category
                        && parseInt(clientPackageContent.tournament_category.id, 10),
                    property_licence: clientPackageContent.property_licence
                        && parseInt(clientPackageContent.property_licence.id, 10),
                    tournament: clientPackageContent.tournament && parseInt(clientPackageContent.tournament.id, 10),
                    content_category: clientPackageContent.content_category
                        && parseInt(clientPackageContent.content_category.id, 10),
                    is_active: isActive,
                    territory_selection_type: clientPackageContent.territory_selection_type && parseInt(clientPackageContent.territory_selection_type.id, 10),
                    countries: clientPackageContent.countries && clientPackageContent.countries.map(({id})=>id),
                    country_subdivisions: clientPackageContent.country_subdivisions && clientPackageContent.country_subdivisions.map(({id})=>id),
                };
            }

            return rowToSave;
        });
    }

    openSaveTreeModal = () => {
        showModalConfirmation({
            header: <Header content={'Apply changes on activation status'}/>,
            text: 'Are you sure you want to apply the changes on the activation status?',
            onYes: this.saveContentPackageTree,
        });
    };

    getMappedIsoCodes = () => {
        client.query({
            fetchPolicy: 'cache-first',
            query: GetMappedIsoCodes,
        }).then((response) => {
            this.setState(() => ({
                mappedIsoCodesData: response.data.mappedIsoCodes,
            }));
        });
    }


    saveContentPackageTree = () => {
        const dataToSave = this.getTreeDataForSave(this.state.data);

        hideModal();
        this.setState(() => ({
            loading: true,
        }));

        if (this.props.clientPackageId) {
            const saveTree = this.props.saveClientPackageContentTree({
                variables: {
                    contentId: dataToSave,
                    clientPackageId: this.props.clientPackageId,
                    client_package_id: this.props.clientPackageId,
                    client_package_content_tree: dataToSave,
                },
            }).then(() => {
                showMessageBox('contentPackageMessage',
                    'The activation statuses have been saved successfully.', '', 'success');

            }).catch((response) => {
                showMessageBox('contentPackageMessage',
                    'The activation statuses have not been saved successfully.', response.message, 'error');
            });
            saveTree.then(() => {
                this.reloadTable();
            });
        }
    };

    setSelected = (selected) => {
        this.setState(() => ({
            selected: selected,
        }));
    };

    renderContent = () => {
        if (this.state.data && this.state.data.length || this.state.loading) {
            return (
                <>
                    <MessageBox name="_warningBox"/>
                    <div className="contentPackageContainer">
                        <MessageBox name="contentPackageMessage"/>
                        <Segment basic compact loading={this.props.submitting || this.state.loading} className="--table --clear contentPackage">
                            <SemanticTreeTable
                                columns={this.composeTableColumns()}
                                setSelected={this.setSelected}
                                data={this.state.dataForTable}
                                rowRenderer={this.rowRender}
                                loading={this.state.loading}
                                className="widthOfRow"
                            />
                            <Authorization resources={CLIENT_PACKAGE_CONTENT} privileges={SECURITY_PRIVILEGES_UPDATE}>
                                <Button
                                    onClick={this.openSaveTreeModal}
                                    color="blue"
                                    type="submit"
                                    loading={this.props.submitting}
                                    disabled={this.props.submitting || false === this.state.selected || this.state.loading}
                                    content="Apply changes on activation status"
                                />
                            </Authorization>
                        </Segment>
                    </div>
                </>
            );
        } else {
            return <div>There are no package contents for this client</div>;
        }
    };

    formatEventContentTypes(eventContentTypes) {
        if (!eventContentTypes || !eventContentTypes.length) {
            return 'All';
        }

        return _orderBy(eventContentTypes, row => row.event_content_type.name, 'asc')
            .map(ect => {
                let match_event_types = '';

                if (ect.match_event_types.length) {
                    match_event_types =
                        ` (${_orderBy(ect.match_event_types, row => row.name, 'asc')
                            .map(met => met.name).join(', ')})`;
                }

                return ect.event_content_type.name + match_event_types;
            })
            .join(', ');
    }

    formatEventContentVariants(variantsArray) {
        return (variantsArray ?? []).map(ecv => ecv.name).join(', ');
    }

    render() {
        return this.renderContent();
    }
}

const mapStateToProps = (state, props) => {
    const clientName = state.app.entities.client?.name;
    const clientPackageName = state.app.entities.clientPackage.name ?? null;
    const clientPackageId = isNaN(props.clientPackageId)
        ? convertToInt(props.match.params.packageId)
        : props.clientPackageId;

    return {
        booking: props.clientPackage,
        clientName: clientName,
        clientPackageName: clientPackageName,
        clientPackageId: clientPackageId,
    };
};

const contentIndexWithData = compose(
    graphql(saveClientPackageContentTreeMutation, {
        name: 'saveClientPackageContentTree',
    }),
    graphql(contentPackageTreeQuery, {
        options: (props) => ({
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'no-cache',
            variables: {
                clientPackageId: props.clientPackageId,
            },
        }),
        name: 'ContentTreeData',
    }
    ))(reduxForm({form: 'ContentPackageTree'})(ContentIndex));

export default withRouter(connect(mapStateToProps)(contentIndexWithData));
