import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {isUrlParamValid, getParsedId} from '@utils/helpers';
import Table from '@appComponents/Table';
import SegmentHeader from '@appComponents/SegmentHeader';
import {getLink} from '@appComponents/Link';
import {saveMessage, setModal} from '@actions/index';
import {IconEdit} from '@appComponents/IconCollection';
import Authorization from '@appComponents/Authorization';
import * as Buttons from '@appComponents/ButtonCollection';
import {loadModalForm, renderModalError} from '@utils/forms';
import {
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_READ,
    ADD_CONTACT_PERSON_TYPE_LICENSOR,
    ADD_CONTACT_PERSON_TYPE_SPORTRADAR,
    CONTACT_PERSON_TYPE_LICENSOR,
} from '@constants/variables';
import {CONTACT_PERSON} from '@constants/resources';
import {showModal} from '@utils/modal';

import ContactPersonEdit from '../../contactPerson/views/ContactPersonEdit';

class ContactPersonList extends React.Component {
    authorizationObject = {
        'readContactPerson': {
            [CONTACT_PERSON]: SECURITY_PRIVILEGES_READ,
        },
        'createContactPerson': {
            [CONTACT_PERSON]: SECURITY_PRIVILEGES_CREATE,
        },
    };

    constructor(props) {
        super(props);

        this.columns = {
            'id': {
                label: 'ID',
                sorting: row => getParsedId(row),
                header: {
                    align: 'center',
                    isCollapsed: true,
                },
                content: {
                    align: 'center',
                },
            },
            'name': {label: 'Name', sorting: true},
            'position': {label: 'Position', sorting: true},
            'email_address': {label: 'E-mail', sorting: true},
            'phone_number_office': {label: 'Phone number (office)', sorting: true},
            'phone_number_mobile': {label: 'Phone number (mobile)', sorting: true},
            'skype_name': {label: 'Skype name', sorting: true},
            'actions': {
                label: 'Actions',
                header: {
                    align: 'center',
                },
                content: {
                    align: 'center',
                },
            },
        };

        this.renderRowCells = this.renderRowCells.bind(this);

        if (props.openPopup) {
            this.openModal(props.openPopup.id, props.openPopup.type, props.openPopup.person);
        }
    }

    componentDidUpdate (prevProps) {
        const {openPopup} = this.props;

        if (openPopup !== prevProps.openPopup
            && openPopup
            && openPopup.id
            && openPopup.type
            && openPopup.person
            && this.props.parent) {
            this.openModal(openPopup.id, openPopup.type, openPopup.person, this.props.parent);
        }

        if (!isUrlParamValid(this.props.match.params.id)) {
            const modalName = (this.props.parent.name);

            showModal({
                isVisible : true,
                content: renderModalError(modalName.capitalizeFirstLetter(), getLink(`${modalName}.index`)),
            });
        }
    }

    openModal = (e, actionType, personType = null) => {
        const id = e && e.currentTarget ? e.currentTarget.dataset.id : e;
        const person = personType ? personType : this.props.personType;
        let url;

        if (!isUrlParamValid(this.props.match.params.contactPersonId)) {
            return showModal({
                isVisible : true,
                content: renderModalError('Contact person', getLink(`${this.props.parent.name}.contactPerson.index`, {
                    id: this.props.parent.id,
                })),
            });
        }

        if (id) {
            url = getLink(`${this.props.parent.name}.contactPerson.edit`, {
                id: this.props.parent.id,
                contactPersonId: id,
            }, {
                contact_person_type_id: person,
            });
        } else {
            url = getLink(`${this.props.parent.name}.contactPerson.add`, {
                id: this.props.parent.id,
            }, {
                contact_person_type_id: person,
            });
        }

        loadModalForm({
            url: url,
            form: <ContactPersonEdit
                parent={this.props.parent}
                id={parseInt(id, 10)}
                personType={this.props.personType}
            />,
            setModal: this.props.Modal.setModal,
        });
    };

    openAddModal = (e) => {
        this.openModal(e, 'add');

        if (this.props.parent.name === 'licensors') {
            document.title = (this.props.personType === CONTACT_PERSON_TYPE_LICENSOR)
                ? ('Licensor "'+ this.props.parent.licencorsName + '" ' + ADD_CONTACT_PERSON_TYPE_LICENSOR)
                : ('Licensor "'+ this.props.parent.licencorsName + '" ' +  ADD_CONTACT_PERSON_TYPE_SPORTRADAR);
        }
    };

    openEditModal = (e) => {
        this.openModal(e, 'edit');
    };

    renderRowCells(column, row) {
        let content = null;
        let placeholder = null;

        switch (column) {
            case 'id':
            case 'name': {
                placeholder = <span>{row[column]}</span>;
                content =
                    <div className="link-to-modal" data-id={row.id} onClick={this.openEditModal}>{row[column]}</div>;
                break;
            }
            case 'actions': {
                content = (
                    <div className="actions-list-icon">
                        <span data-id={row.id} onClick={this.openEditModal}>
                            <IconEdit resource={CONTACT_PERSON} size="large"/>
                        </span>
                    </div>
                );
                break;
            }
            default:
                return null;
        }

        return (
            <Authorization
                authorization={this.authorizationObject.readContactPerson}
                notAuthorized={placeholder}
            >
                {content}
            </Authorization>
        );
    }

    render() {
        return (
            <div>
                <SegmentHeader
                    buttons={(
                        <Authorization authorization={this.authorizationObject.createContactPerson}>
                            <Buttons.ButtonAdd onClick={this.openAddModal}>{this.props.addButtonText}</Buttons.ButtonAdd>
                        </Authorization>
                    )}
                    dividing={!!this.props.title}
                >
                    {this.props.title}
                </SegmentHeader>
                <Table
                    name="contactPersonList"
                    data={this.props.data}
                    columns={this.columns}
                    loading={this.props.loading}
                    rowRenderer={this.renderRowCells}
                    noDataText="No contact persons found."
                    pagination={false}
                    defaultSort={'name'}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        Modal: {
            setModal: (params) => {
                dispatch(setModal(params));
            },
        },
        MessageBox: {
            addMessage: (name, header, text, type) => {
                dispatch(saveMessage({name, header, text, type}));
            },
        },
    };
};

ContactPersonList.propTypes = {
    Modal: PropTypes.object,
    MessageBox: PropTypes.object,
    data: PropTypes.array,
    loading: PropTypes.bool,
    refetchList: PropTypes.func.isRequired,
    parent: PropTypes.object,
    title: PropTypes.string,
    addButtonText: PropTypes.string,
    personType: PropTypes.number,
    openPopup: PropTypes.object,
    match: PropTypes.object.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(ContactPersonList));
