import {get as _get} from 'lodash';

import {convertToInt} from '@utils/helpers';
import validators from '@utils/validators';
import * as messages from '@constants/messages';
import {
    CDN_AKAMAI, CDN_AKAMAI_2,
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_VOD,
    ENCODER_TYPE_MANUAL,
    ENCODER_TYPE_SPORTRADAR,
    INGEST_METHOD_DISTRIBUTION_TYPES,
} from '@constants/variables';

const countValueInObjectArray = (objects = null, value = null) => {
    let valuesCount = 0;

    if (!objects) {
        return valuesCount;
    }

    Object.keys(objects).forEach((key) => {
        const objectValue = objects[key];

        if (objectValue === value) {
            valuesCount++;
        }
    });

    return valuesCount;
};

const validateEncodingJobProfileDrm = (values, props, errors) => {
    if (ENCODER_TYPE_MANUAL === convertToInt(values.encoder_type)) {
        return;
    }

    const encodingJobProfilesWithDrmSetup = _get(
        props,
        'GraphQLOptionsData.encodingJobProfilesWithDrm',
        []
    ).map((encodingJobProfile) => (
        encodingJobProfile.id
    ));

    let messageError = null;

    if (true === values.has_drm_support && !encodingJobProfilesWithDrmSetup.includes(values.encoding_job_profile)) {
        messageError = 'DRM support can be enabled only for encoding job profiles with DRM support.';
    } else if (false === values.has_drm_support && encodingJobProfilesWithDrmSetup.includes(values.encoding_job_profile)) {
        messageError = 'DRM support must be enabled for encoding job profiles with DRM support.';
    }

    if (messageError) {
        Object.assign(errors, validators.required(null, 'encoding_job_profile', messageError));
    }
};

const validatorEventContentTarget = (values, props) => {
    const errors = {};

    /**Device category validation*/
    if (!countValueInObjectArray(values.device_category, true)) {
        Object.assign(errors, {device_categories: messages.FIELD_REQUIRED});
    }

    if (values.distribution_type !== DISTRIBUTION_TYPE_VOD) {
        if (parseInt(values.encoder_type, 10) === ENCODER_TYPE_SPORTRADAR) {
            if (0 !== values.delay) {
                Object.assign(errors, validators.digitsOnly(values.delay, 'delay'));
            }

            if (values.has_scoreboard_overlay_support) {
                Object.assign(errors, validators.required(values.scoreboard_overlay_position, 'scoreboard_overlay_position'));
            }

            Object.assign(errors, validators.required(values.stream_delay_type, 'stream_delay_type'));

            if (values.stream_name_prefix) {
                Object.assign(errors, validators.alphanumericWithOptionalUnderscoreAndForwardSlash(values.stream_name_prefix, 'stream_name_prefix'));
            }

            Object.assign(errors, validators.required(values.encoder_type, 'encoder_type'));
            Object.assign(errors, validators.required(values.encoding_job_profile, 'encoding_job_profile'));

            if (values.has_overlay_support) {
                Object.assign(errors, validators.required(values.overlay, 'overlay'));
            }

            if (values.has_ad_support) {
                Object.assign(errors, validators.required(values.ad_type, 'ad_type'));
            }
        }

        if (values.token_ttl) {
            Object.assign(errors, validators.digitsOnly(values.token_ttl, 'token_ttl'));
        }
    }

    if (values.distribution_type === DISTRIBUTION_TYPE_LIVE) {
        Object.assign(errors, validators.required(values.cdn, 'cdn'));

        if ([CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(values.cdn))) {
            Object.assign(errors, validators.required(values.akamai_cdn_ingest_method, 'akamai_cdn_ingest_method'));
        }
    }

    if (INGEST_METHOD_DISTRIBUTION_TYPES.includes(values.distribution_type)) {
        Object.assign(errors, validators.required(values.ingest_method, 'ingest_method'));
    }

    Object.assign(errors, validators.required(values.distribution, 'distribution'));

    validateEncodingJobProfileDrm(values, props, errors);

    return errors;
};

export default validatorEventContentTarget;
