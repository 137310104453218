import PropTypes from "prop-types";
import React from 'react';

import TechnicalSetupForm from '../forms/TechnicalSetupForm';
import TechnicalSetupModel from "../forms/TechnicalSetupModel";

import {createForm} from "../../../utils/forms";

import {THIRD_PARTY_CLIENT_TYPE_PULL, THIRD_PARTY_CLIENT_TYPE_PUSH} from "../../app/constants/variables";

const TechnicalSetupEdit = ({propertyLicenceId, technicalSetupId = null}) => {
    const Form = React.useMemo(
        () => (createForm(
            TechnicalSetupModel,
            TechnicalSetupForm,
            {
                id: technicalSetupId ? propertyLicenceId : null,
                propertyLicenceId,
                technicalSetupId: technicalSetupId,
                optionsVariables: {
                    types: [THIRD_PARTY_CLIENT_TYPE_PUSH, THIRD_PARTY_CLIENT_TYPE_PULL]
                },
            }
        )),
        [technicalSetupId]
    );

    return <Form/>;
};

TechnicalSetupEdit.propTypes = {
    propertyLicenceId: PropTypes.number.isRequired,
    technicalSetupId: PropTypes.number,
};

export default TechnicalSetupEdit;
