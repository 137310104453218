import {isEmpty as _isEmpty, sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {useQuery} from '@apollo/client';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import BlackoutZoneFilters from '@modules/blackoutZone/components/BlackoutZoneFilters';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {GetBlackoutZoneForTable} from '@graphql/blackoutZone/query';
import {GetMappedIsoCodes} from '@graphql/country/query';
import Table from '@appComponents/Table';
import {
    blackoutZoneColumns,
    prepareStringForExcelSorting,
    renderBlackoutZonesModalError,
    rowRenderer,
} from '@modules/blackoutZone/utils/blackoutZone';
import {showModal} from '@utils/modal';
import BlackoutZoneIndexButtons from '@modules/blackoutZone/components/BlackoutZoneIndexButtons';

import {exportExcel} from '../utils/export/blackoutZoneIndexExcel';
import mapModulesToProps from "@utils/mapModulesToProps";
import navigation from "@constants/navigation";

const DEFAULT_FILTERS_VALUES = {
    search: '',
    countries: [],
    isArchived: 0,
};

export function BlackoutZoneIndex(props) {
    const filters = props.filters,
        queryVariables = {
            search: filters.search ? filters.search : DEFAULT_FILTERS_VALUES.search,
            countries: filters.countries ? filters.countries.map(Number) : DEFAULT_FILTERS_VALUES.countries,
            isArchived: (undefined === typeof filters.isArchived)
                ? false
                : convertBooleanValueForFilters(filters.isArchived),
            timestamp: filters.timestamp
        };

    props.Menu.storeMenu(navigation.mediaRights.key);

    const {
        data: {
            blackoutZones,
        } = {},
        error: blackoutZonesFetchingErrorOccurred,
        loading: isBlackoutZonesDataLoading = true,
    } = useQuery(GetBlackoutZoneForTable,
        {
            variables: {
                ...queryVariables,
            },
            fetchPolicy: 'no-cache'
        }
    );

    const {
        data: {
            mappedIsoCodes,
        } = {},
        error: mappedIsoCodesFetchingErrorOccurred,
        loading: isMappedIsoCodesDataLoading = true,
    } = useQuery(GetMappedIsoCodes);

    const isLoading = isBlackoutZonesDataLoading || isMappedIsoCodesDataLoading;

    if (!_isEmpty(blackoutZonesFetchingErrorOccurred) || !_isEmpty(mappedIsoCodesFetchingErrorOccurred)) {
        showModal({
            isVisible: true,
            content: renderBlackoutZonesModalError(
                blackoutZonesFetchingErrorOccurred
                    ? blackoutZonesFetchingErrorOccurred.message
                    : mappedIsoCodesFetchingErrorOccurred.message
            ),
        });
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(_sortBy(blackoutZones, [prepareStringForExcelSorting]))}
                filters={BlackoutZoneFilters}
                filtersProps={{loading: isLoading}}
                buttons={BlackoutZoneIndexButtons}
                filtersButtonName='BlackoutZone'
                messagesBoxNames='BlackoutZoneIndex'
                pageTitle='Blackout Zones'
            />
            <div className='table-container'>
                <Table
                    columns={blackoutZoneColumns}
                    data={blackoutZones}
                    defaultSort={'name'}
                    loading={isLoading}
                    name='BlackoutZoneList'
                    noDataText='No blackout zones found'
                    rowRenderer={rowRenderer}
                    rowRendererProps={{mappedIsoCodes}}
                />
            </div>
        </div>
    );
}

BlackoutZoneIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    match: PropTypes.object,
    Menu: PropTypes.object
};

const BlackoutZoneIndexWrapped = FilterQueryWrapper(BlackoutZoneIndex, {
    queryForRefresh: 'GetBlackoutZoneForTable',
    filterUrls: ['blackoutZones.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'BlackoutZone', DEFAULT_FILTERS_VALUES),
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Menu']))(
    FilterUrlParamsWrapper(BlackoutZoneIndexWrapped, DEFAULT_FILTERS_VALUES)
));
