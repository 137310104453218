import PropTypes from "prop-types";

import {convertToInt} from "../../../../utils/helpers";

import {EVENT_TYPE_NON_SPORTRADAR, EVENT_TYPE_SPORTRADAR} from "../../../app/constants/variables";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'event_content_types',
    parseData: eventContentType => [
        eventContentType.id,
        eventContentType.name,
        eventContentType.distribution_types.map(distributionType => distributionType.name).join(', '),
        eventContentType.products.map(product => product.short_name).join(', '),
        eventContentType.event_types.map(eventType => eventType.name).join(', '),
        eventContentType.event_types.find(eventType => EVENT_TYPE_SPORTRADAR === convertToInt(eventType.id))
            ? (0 === eventContentType.sports.length)
                ? 'All'
                : eventContentType.sports.map(sport => sport.name).join(', ')
            : '-',
        eventContentType.event_types.find(eventType => EVENT_TYPE_NON_SPORTRADAR === convertToInt(eventType.id))
            ? (0 === eventContentType.content_categories.length)
                ? 'All'
                : eventContentType.content_categories.map(contentCategory => contentCategory.name).join(', ')
            : '-'
    ],
    path: 'eventContentTypes.index.path',
    titles: [
        "ID",
        "Name",
        "Distribution types",
        "Products",
        "Event types",
        "Sports",
        "1st level categories",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
