import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {createForm, renderModalError} from '@utils/forms';
import RtmpOriginStreamNameAddMultipleModel
    from '@modules/productionCompany/forms/RtmpOriginStreamNameAddMultipleModel';
import useQuery from '@utils/hooks/useQuery';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {IS_ARCHIVED} from '@constants/staticDropdowns';
import {GetRtmpOriginStreamNamesForTable} from '@graphql/rtmpOriginStreamName/query';

import HeaderRenderer from '../../app/components/HeaderRenderer';
import mapModulesToProps from '../../../utils/mapModulesToProps';
import RtmpOriginStreamNameForm from '../forms/RtmpOriginStreamNameForm';
import RtmpOriginStreamNameModel from '../forms/RtmpOriginStreamNameModel';
import RtmpOriginStreamNameModelEdit from '../forms/RtmpOriginStreamNameModelEdit';
import RtmpOriginStreamNamesFilter from '../components/RtmpOriginStreamNamesFilter';
import RtmpOriginStreamNamesIndexButtons from '../components/RtmpOriginStreamNamesIndexButtons';
import RtmpOriginStreamNamesTable from '../components/RtmpOriginStreamNamesTable';
import {exportExcel} from '../utils/export/rtmpOriginStreamNamesIndexExcel';
import {renderProductionCompanyModalError} from '../utils/productionCompanyTabsLayoutHelper';


const DEFAULT_FILTERS_VALUES = {
    search: null,
    isArchived: 0,
};

const RtmpOriginStreamNameIndex = ({
    filters = {},
    history: {
        push,
    },
    isVisible,
    match: {
        params: {
            id,
            rtmpOriginStreamNameId = null,
        },
        path,
    },
    Modal,
}) => {
    const [modalVisibility, setModalVisibility] = useState(isVisible);
    const [mutationSuccessSubmit, setMutationSuccessSubmit] = useState(Date.now);

    const refetchDeps = [filters.lastClickSubmit, mutationSuccessSubmit],
        queryVariables = {
            search: filters.search || DEFAULT_FILTERS_VALUES.search,
            is_archived: (undefined === filters.isArchived) ? false : convertBooleanValueForFilters(filters.isArchived),
            production_company: convertToInt(id),
        };

    const {
        loading: areDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQuery({
        query: GetRtmpOriginStreamNamesForTable,
        options: {
            variables: queryVariables,
            refetch: React.useMemo(() => (refetchDeps), refetchDeps),
        },
    });

    if (!isUrlParamValid(rtmpOriginStreamNameId)) {
        showModal({
            isVisible: true,
            content: renderModalError('Rtmp Origin Stream Name', getLink('productionCompanies.rtmpOriginStreamNames.index', {
                id: id,
            })),
        });
    }

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: renderProductionCompanyModalError(),
        });
    }

    useEffect(() => {
        if (path !== routes.productionCompanies.rtmpOriginStreamNames.index.path) {
            const formData = path === routes.productionCompanies.rtmpOriginStreamNames.edit.path
                ? {id, rtmpOriginStreamNameId}
                : null;

            loadModal(path, formData);
        }
    }, [path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            push(getLink('productionCompanies.rtmpOriginStreamNames.index', {id: id}));
        }
    }, [isVisible]);

    const loadModal = (activePath, params = {}) => {
        const defaultParams = {
            productionCompanyId: id,
            setMutationSuccessSubmit,
        };

        if (routes.productionCompanies.rtmpOriginStreamNames.add.path === activePath) {
            return loadModalForm(createForm(
                RtmpOriginStreamNameModel,
                RtmpOriginStreamNameForm,
                defaultParams
            ), params);
        } else if (routes.productionCompanies.rtmpOriginStreamNames.edit.path === activePath) {
            return loadModalForm(createForm(
                RtmpOriginStreamNameModelEdit,
                RtmpOriginStreamNameForm,
                {id: rtmpOriginStreamNameId, ...defaultParams}
            ), params);
        } else if (routes.productionCompanies.rtmpOriginStreamNames.addMultiple.path === activePath) {
            return loadModalForm(createForm(
                RtmpOriginStreamNameAddMultipleModel,
                RtmpOriginStreamNameForm,
                defaultParams
            ), params, 'large');
        }
    };

    const loadModalForm = (Form, data, size = '') => {
        Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
            size,
        });

        setModalVisibility(true);
    };

    return (
        <div>
            <HeaderRenderer
                buttons={RtmpOriginStreamNamesIndexButtons}
                buttonsProps={{id}}
                exportExcelParams={exportExcel(data.rtmpOriginStreamNames, id)}
                filters={RtmpOriginStreamNamesFilter}
                filtersProps={{data: {isArchived: IS_ARCHIVED}}}
                loading={areDataLoading}
            />
            <RtmpOriginStreamNamesTable
                rtmpOriginStreamNames={data.rtmpOriginStreamNames}
                loading={areDataLoading}
            />
        </div>
    );
};

RtmpOriginStreamNameIndex.propTypes = {
    filters: PropTypes.shape({
        search: PropTypes.string,
        isArchived: PropTypes.number,
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    isVisible: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            rtmpOriginStreamNameId: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
};

const RtmpOriginStreamNameIndexWrapped = FilterQueryWrapper(RtmpOriginStreamNameIndex, {
    queryForRefresh: 'GetRtmpOriginStreamNamesForTable',
    filterUrls: ['productionCompanies.rtmpOriginStreamNames.index'],
});

const mapStateToProps = (state) => ({
    isVisible: state.modal.isVisible,
    filters: getSearchFilters(state, 'rtmpOriginStreamNames', DEFAULT_FILTERS_VALUES),
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(
    FilterUrlParamsWrapper(RtmpOriginStreamNameIndexWrapped, DEFAULT_FILTERS_VALUES)));
