import React from 'react';
import PropTypes from 'prop-types';
import {isArray as _isArray} from 'lodash';
import {Header} from "semantic-ui-react";

import MessageBox from "./MessageBox";

/**
 * @ignore
 */
const ContentHeader = (props) => {
    const renderMessagesBox = (messagesBoxNames) => {
        if (!messagesBoxNames) return false;

        if (_isArray(messagesBoxNames)) {
            return messagesBoxNames.map((messagesBoxName) => {
                return <MessageBox key={`messageBox_${messagesBoxName}`} name={messagesBoxName}/>
            })
        } else {
            return <MessageBox name={messagesBoxNames}/>
        }
    };

    return (
        <div className={`content__viewHeader viewHeader ${!props.title && '--margin-0 --noBorder'}`}>
            {renderMessagesBox(props.messagesBoxNames)}
            <Header as='h1' className="viewHeader__header">{props.title}</Header>
            {props.children}
            <div className="clearfix"/>
        </div>
    );
};

ContentHeader.propTypes = {
    children: PropTypes.array,
    messagesBoxNames: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    title: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
ContentHeader.defaultProps = {
    messagesBoxName: null,
};

export default ContentHeader;
