import PropTypes from "prop-types";

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_labels`,
    parseData: clientAnalyticsSetupLabel => [
        clientAnalyticsSetupLabel.id,
        clientAnalyticsSetupLabel.code,
        clientAnalyticsSetupLabel.name,
    ],
    path: 'clients.clientAnalyticsSetup.labels.index.path',
    titles: [
        "ID",
        "Code",
        "Name",
    ],
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired
};
