import React from 'react';
import {Header, Modal as SemanticModal} from 'semantic-ui-react';

import {ButtonAlert} from '@appComponents/ButtonCollection';

export const ApplicationError = () => {
    const probability = [1, 9, 18, 18, 18, 18, 18],
        devs = ['Adrian', 'Artem', 'Ivan', 'Wojciech', 'Łukasz','Bartunio', 'Mario'],
        images = [
            'https://ca.slack-edge.com/T0A8LC9N1-UBHHKB961-496076f56925-512',
            'https://ca.slack-edge.com/T0A8LC9N1-U5DFA9J20-b851989712b1-512',
            'https://ca.slack-edge.com/T0A8LC9N1-U0104AK8SHX-f1a34f31dcce-512',
            'https://ca.slack-edge.com/T0A8LC9N1-U01NT8CGKA9-18aacb93cf09-512',
            'https://ca.slack-edge.com/T0A8LC9N1-UKPSL1WKD-08f49b46f934-512',
            'https://ca.slack-edge.com/T0A8LC9N1-U1WSS644T-4eeeb3c16289-512',
            'https://ca.slack-edge.com/T0A8LC9N1-U40GZQF6E-5d8fe050db6e-512',
        ],
        desc = [
            'Ultra rare developer, lonely samurai.\nCurrently walking somewhere around the world.',
            'PHP/Symfony evangelist.\nLove green color, some says that his blood is green.',
            'Calm and steady proffesionalist.\nJust look at that picture.',
            'New, fresh breath of ideas and knowledge in AVCMP team. \nJust like Hitman, issue will be solved fast and professional.',
            'One and only, unique, our father, last hope, AVCMP team leader.',
            'The oldest guy in the team, but he looks quite young, and... crazy.',
            'Manchester United fan, angel inside.',
        ],
        sumOfWeights = probability.reduce((a, b) => a+b),
        rand = Math.floor(Math.random() * sumOfWeights);

    let selectedDev = null,
        probabilitySum = 0;

    for(let i = 0; i < probability.length; i++) {
        probabilitySum += probability[i];

        if (rand <= probabilitySum) {
            selectedDev = {
                name: devs[i],
                desc: desc[i],
                img: images[i],
            };

            break;
        }
    }

    return (
        <div>
            <Header icon='warning' color='red' content={'Error occured'}/>
            <SemanticModal.Content>
                <p><b>Our developers were notified about this issue.</b><br/>
                    Maybe this developer will work on fix? :)
                </p>
                <p><img alt={selectedDev.name} src={selectedDev.img} style={{width: '80px', borderRadius: '50%' ,marginRight: '10px', float: 'left'}}/>
                    <b>{selectedDev.name}</b><br/>{selectedDev.desc}</p>
                <br/><br/><p><b>Can you Catch &apos;Em All?</b> :)</p>
                <ButtonAlert onClick={() => {window.location.assign('/');}}>Back to the main page</ButtonAlert>
            </SemanticModal.Content>
        </div>
    );
};
