import {changelogData} from "../changelogData";

export const eventStreamConstraintBody = `
id
product{
    id
    name
}
device_category {
    id
    name
}
max_bitrate
max_player_size
max_resolution_width
max_resolution_height
${changelogData}
`;

export const eventStreamConstraints = `
query EventStreamConstraints($eventId: Int!) {
    products {
        id
        name 
    }
    deviceCategories {
        id
        name 
    }
    eventStreamConstraints(filters: {event: $eventId}) {
        ${eventStreamConstraintBody}
    }
}`;

export const CreateMutation = `
mutation createEventStreamConstraint(
    $eventId:Int!, 
    $productId:Int!, 
    $deviceCategoryId:Int!,
    $maxBitrate:Int, 
    $maxResolutionWidth:Int,
    $maxResolutionHeight:Int,
    $maxPlayerSize:Int
  ) {
  createEventStreamConstraint(
    event: $eventId,
    product: $productId, 
    device_category:$deviceCategoryId,
    max_bitrate:$maxBitrate,
    max_resolution_width:$maxResolutionWidth,
    max_resolution_height: $maxResolutionHeight,
    max_player_size: $maxPlayerSize
  ) {
    ${eventStreamConstraintBody}
  }
}`;

export const UpdateMutation = `
mutation updateEventStreamConstraint(
    $id: ID!,
    $productId:Int!,
    $deviceCategoryId:Int!,
    $maxBitrate:Int, 
    $maxResolutionWidth:Int,
    $maxResolutionHeight:Int,
    $maxPlayerSize:Int
  ) {
  updateEventStreamConstraint(
    id: $id,
    product:$productId,
    device_category:$deviceCategoryId,
    max_bitrate:$maxBitrate,
    max_resolution_width:$maxResolutionWidth,
    max_resolution_height: $maxResolutionHeight,
    max_player_size: $maxPlayerSize
  ) {
    ${eventStreamConstraintBody}
  }
}`;

export const DeleteMutation = `
mutation deleteEventStreamConstraint($id: ID!) {
    deleteEventStreamConstraint(id: $id) 
}`;
