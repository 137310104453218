import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';
import Link from '@appComponents/Link';

const FormChangelogEntry = ({date, user, label, changelogLink: hasChangelogLink = false,  resources, elementId}) => {
    const userName = user
        ? (<Link name='users.edit' newWindow={true} params={{id: user.id}}>{user.name}</Link>)
        : (<i>[unknown user]</i>);

    return (
        date ?
            (
                <div className='formRow'>
                    <div className='changelogData__row'>
                        <label>{label}</label>
                        <span className='changelogData__info'>{date} by {userName}</span>
                        {hasChangelogLink &&
                            <Popup
                                content='See the changelog'
                                position='right center'
                                trigger={
                                    <span className='changelogData__info'>
                                        <Link name='changelog.changelogElement' newWindow={true} params={{
                                            resourceId: resources,
                                            elementId: convertToInt(elementId),
                                        }}>
                                            <Icon name='file alternate outline'/>
                                        </Link>
                                    </span>
                                }
                            />
                        }
                    </div>
                </div>
            )
            : null
    );
};

FormChangelogEntry.propTypes = {
    date: PropTypes.string,
    user: PropTypes.object,
    label: PropTypes.string,
    changelogLink: PropTypes.bool,
    creationDate: PropTypes.string,
    elementId: PropTypes.string,
    resources: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
};

export default FormChangelogEntry;
