import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import {IconEdit} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";

import {PRODUCTION_COMPANY, RTMP_ORIGIN_STREAM_NAME} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_READ} from "../../app/constants/variables";

const rtmpOriginStreamNameAuthorizationObject = {
    [PRODUCTION_COMPANY]: SECURITY_PRIVILEGES_READ,
    [RTMP_ORIGIN_STREAM_NAME]: SECURITY_PRIVILEGES_READ
};

export const rowRenderer = (column, row, props) => {
    const {id} = props;

    switch (column) {
        case "id":
        case "name":
            return (
                <LinkWithAuthorization
                    authorization={rtmpOriginStreamNameAuthorizationObject}
                    link={{
                        name: "productionCompanies.rtmpOriginStreamNames.edit",
                        params: {id: id, rtmpOriginStreamNameId: row.id}
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case "actions": {
            return (
                <Authorization authorization={rtmpOriginStreamNameAuthorizationObject}>
                    <div className="actions-list-icon">
                        <IconEdit
                            link={getLink("productionCompanies.rtmpOriginStreamNames.edit", {
                                id: id,
                                rtmpOriginStreamNameId: row.id,
                            })}
                            size="large"
                            resource={RTMP_ORIGIN_STREAM_NAME}
                        />
                    </div>
                </Authorization>
            );
        }
        default: {
            return null;
        }
    }
};

rowRenderer.propTypes = {
    id: PropTypes.string.isRequired,
};

rowRenderer.displayName = 'rowRenderer';
