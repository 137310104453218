import {sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';

import {SAVED} from '@constants/messages';

import {ProductField} from '../forms/TechnicalSetup/Products/ProductField';

export function renderFormSavedMessages() {
    return SAVED('pricing of the property licence');
}

export function renderProductsWithStatus({products, productStatuses}) {
    if (undefined === products || undefined === productStatuses) {
        return {};
    }

    const productsForModel = {};

    _sortBy(products, 'id').forEach((product) => {
        Object.assign(productsForModel, {
            [`product_${product.short_name.toLowerCase()}`]: {
                dataMapKey: `product_${product.short_name.toLowerCase()}`,
                props: {
                    component: ProductField,
                    data: {
                        product: product,
                        productStatuses: productStatuses,
                    },
                    id: product.id,
                    name: `product_${product.short_name.toLowerCase()}`,
                    disabled: product.disabled  || false,
                },
            },
        });
    });

    return productsForModel;
}
renderProductsWithStatus.propTypes = {
    products: PropTypes.array.isRequired,
    productStatuses: PropTypes.array.isRequired,
};
