import EventIncidentModel from "./EventsIncidentModel";

export default function EventIncidentEditModel() {
    let eventIncidentEditModel = EventIncidentModel();

    eventIncidentEditModel.fields.reason.props.disabled = false;
    eventIncidentEditModel.fields.causer.props.disabled = false;

    eventIncidentEditModel.title = () => {
        return "Edit incident";
    };

    return eventIncidentEditModel;
}