import client from "../../../apolloClient";

import {
    TriggerClientAutobooking, TriggerEventAutobooking, TriggerEventContentAutobooking, TriggerPropertyLicenceAutobooking
} from "../../../graphql/autobooker/mutation.graphql";

const mapEntityToMutation = (entity) => {
    switch (entity) {
        case "client":
            return TriggerClientAutobooking
        case "event":
            return TriggerEventAutobooking
        case "content":
            return TriggerEventContentAutobooking
        case "property licence":
            return TriggerPropertyLicenceAutobooking
        default:
            throw `Attempt to trigger autobooker for undefined type: ${entity}`
    }
}

export const triggerAutobooker = (id, entity, messageBoxName, MessageBox, Modal) => {
    client.mutate({
        mutation: mapEntityToMutation(entity),
        variables: {
            id: id
        },
    }).then(() => {
        MessageBox.addMessage(
            messageBoxName,
            `The Autobooker / Auto-offerer have been triggered successfully for this ${entity}.`,
            "It may take up to 30 seconds before they finish their execution. Be patient!",
            "success",
        );
    }).catch((error) => {
        MessageBox.addMessage(
            messageBoxName,
            "The Autobooker / Auto-offerer could not be triggered.",
            `${error}`,
            "error"
        );
    }).finally(() => Modal.setModal({isVisible: false}));
};
