import React from 'react';
import PropTypes from 'prop-types';

import {LCR_CHANNEL} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import {Authorization} from '@appComponents/Authorization';
import Link from '@appComponents/Link';

export const ContentRestrictionButtons = ({type, id}) => {
    let title = '';

    switch (type) {
        case 'sports':
            title = 'sport';
            break;
        case 'categories':
            title = 'category';
            break;
        case 'tournaments':
            title = 'tournament';
            break;
    }

    return (
        <div className='content__buttons'>
            <Authorization authorization={{[LCR_CHANNEL]: SECURITY_PRIVILEGES_UPDATE}}>
                <Link name={`lcrChannel.edit.contentRestrictions.${type}.add`} params={{id}}>
                    <ButtonAdd>
                        Add {title}
                    </ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

ContentRestrictionButtons.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
};
