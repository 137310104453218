import PropTypes from "prop-types"

import NotificationForm from "./NotificationForm";

export default class MasterEventNotificationForm extends NotificationForm {
    static propTypes = {
        ...super.PropTypes,
        eventDescription: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        eventDescription: null
    };

    prepareDataForSubmit = (formData) => {
        let data = super.prepareDataForSubmit(formData);

        data.event = this.props.formParams.optionsVariables.eventId;
        data.events = this.props.formParams.optionsVariables.eventsIds;

        return data;
    };

    componentDidMount() {
        super.componentDidMount();

        this.setField('description', {
            defaultValue: this.props.eventDescription
        })
    }
}
