import React from 'react';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {BLACKOUT_ZONE} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

const BlackoutZoneIndexButtons = () => (
    <div className='content__buttons'>
        <Authorization
            authorization={{
                [BLACKOUT_ZONE]: SECURITY_PRIVILEGES_CREATE
            }}
        >
            <Link name={'blackoutZones.add'}>
                <ButtonAdd>Add new Blackout Zone</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

export default BlackoutZoneIndexButtons;
