import PropTypes from "prop-types";
import React from "react";

import Link from "../../app/components/Link"
import {Button, Icon} from "semantic-ui-react";

const ClientCdnConfigurationButtons = (props) => {
    const {clientId} = props;

    return (
        <div className="clientCdnConfiguration__indexButtons content__buttons">
            <Link name="clients.clientCdnConfiguration.index" params={{id: clientId}}>
                <Button className="button-back">
                    <Icon className="arrow circle left"/> Back to CDN configuration list
                </Button>
            </Link>
        </div>
    )
};

ClientCdnConfigurationButtons.propTypes = {
    clientId: PropTypes.number.isRequired,
};

export default ClientCdnConfigurationButtons;
