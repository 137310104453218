import PropTypes from 'prop-types';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'ad_splice_configs',
    parseData: adSpliceConfigs => [
        adSpliceConfigs.id,
        adSpliceConfigs.name,
        adSpliceConfigs.ad_splice.name,
        adSpliceConfigs.duration ? adSpliceConfigs.duration + 's' : '',
        (adSpliceConfigs.ad_start_offset || 0 === adSpliceConfigs.ad_start_offset) ? adSpliceConfigs.ad_start_offset + 'ms' : '',
        (adSpliceConfigs.ad_duration_offset || 0 === adSpliceConfigs.ad_duration_offset) ? adSpliceConfigs.ad_duration_offset + 'ms' : '',
    ],
    path: 'adSplice.path',
    titles: [
        'ID',
        'Name',
        'Ad splice',
        'Duration',
        'Ad start offset',
        'Ad duration offset',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
