import { filters, filtersEventBooking } from "../bookings"
import {clientPackagesForDropdown} from "../clientPackages/index";
import {propertyLicencesForDropdown} from "../propertyLicences/index";

export const contentCategoriesList = `
  contentCategoryLevel1: contentCategories(filters: {level: 1}){
    id
    name
    key: id
    value: id
    text: name
  }
`;

export const deviceCategoryList = `
 deviceCategories {
    id
    name
    value: id
    text: name
  }
`;

export const countryList = `
 countries {
    id: iso_alpha2_code
    name
  }
`;

export const dropDownListClientBookingsQuery = `query dropDownListsClientBookings($client: [ID]) {
    ${filters.bookingTypes}
    ${clientPackagesForDropdown}
    ${filters.contentTiers}
    ${filters.deviceCategories}
    ${filters.distributionTypes}
    ${filters.eventTypes}
    ${filters.invoiceStatuses}
    ${filters.products}
    ${filters.eventContentTypes}
    ${filters.eventContentVariants}
    ${propertyLicencesForDropdown}
    ${filters.sports}
    ${contentCategoriesList}
    ${deviceCategoryList}
    ${countryList}
    }`;

export const dropDownListClientEventBookingsQuery = `query dropDownListsClientEventBookings($client: Int) {
    ${filtersEventBooking.bookingTypes}
    ${clientPackagesForDropdown}
    ${filtersEventBooking.contentTiers}
    ${filtersEventBooking.deviceCategories}
    ${filtersEventBooking.distributionTypes}
    ${filtersEventBooking.eventTypes}
    ${filtersEventBooking.products}
    ${filtersEventBooking.eventContentTypes}
    ${filtersEventBooking.matchEventTypes}
    ${propertyLicencesForDropdown}
    ${filtersEventBooking.sports}
    ${contentCategoriesList}
    ${deviceCategoryList}
    ${countryList}
    }`;
