import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {graphql} from '@apollo/client/react/hoc';
import {Header, Segment} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link, {getLink} from '@appComponents/Link';
import StepsContent from '@appComponents/StepsContent';
import {routes} from '@constants/routes';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetEventGeoRestrictionsForTable} from '@graphql/eventGeoRestriction/query';
import {EVENT_GEO_RESTRICTION} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE_READ} from '@constants/variables';
import {showModal} from '@utils/modal';

import EventGeoRestrictionTable from '../components/EventGeoRestrictionTable';
import EventGeoRestrictionsGeneralInformationForm from '../forms/EventGeoRestrictions/EventGeoRestrictionsGeneralInformationForm';
import EventGeoRestrictionsForm from '../forms/EventGeoRestrictions/EventGeoRestrictionsForm';
import EventGeoRestrictionsFormEditModel from '../forms/EventGeoRestrictions/EventGeoRestrictionsFormEditModel';
import EventGeoRestrictionsFormGeneralInformationModel from '../forms/EventGeoRestrictions/EventGeoRestrictionsFormGeneralInformationModel';
import EventGeoRestrictionsFormGeneralInformationEditModel from '../forms/EventGeoRestrictions/EventGeoRestrictionsFormGeneralInformationEditModel';
import EventGeoRestrictionsFormBlackoutZonesForm from '../forms/EventGeoRestrictions/EventGeoRestrictionsFormBlackoutZonesForm';
import EventGeoRestrictionsFormBlackoutZonesEditModel from '../forms/EventGeoRestrictions/EventGeoRestrictionsFormBlackoutZonesEditModel';

const authorizationObject = {
    [EVENT_GEO_RESTRICTION] : SECURITY_PRIVILEGES_CREATE_READ,
};

export class EventsGeoRestrictionsIndex extends React.PureComponent {
    static propTypes = {
        DataEventGeoRestrictions: PropTypes.shape({
            eventGeoRestrictions: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool,
        }),
        event: PropTypes.object.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                restrictionId: PropTypes.string,
                id: PropTypes.string,
            }),
            path: PropTypes.string.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        rightsScopes: PropTypes.array,
        timezone: PropTypes.string.isRequired,
    };

    static defaultProps = {
        DataEventGeoRestrictions: {
            eventGeoRestrictions: [],
            loading: false,
        },
        match: {
            params: {
                restrictionId: null,
                id: null,
            },
        },
        rightsScopes: [],
    };

    state = {
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.events.geoRestrictions.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        return {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        };
    }

    componentDidMount() {
        this.loadModal(this.props.match.path);

        if (!isUrlParamValid(this.props.match.params.id)) {
            showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        } else if (!isUrlParamValid(this.props.match.params.restrictionId)) {
            showModal({
                isVisible: true,
                content: renderModalError(
                    'Event geo restrictions',
                    getLink('events.geoRestrictions.index', {id: this.props.match.params.id})),
            });
        }
    }

    componentDidUpdate() {
        if (this.state.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (this.state.modalWasClosed) {
            this.props.history.push(
                getLink('events.geoRestrictions.index', {
                    id: this.props.match.params.id,
                })
            );
        }
    }

    loadModal(path, params = {}) {
        const eventGeoRestrictionsPath = routes.events.geoRestrictions,
            form = EventGeoRestrictionsGeneralInformationForm;
        let formParams = {};

        if (path === eventGeoRestrictionsPath.add.path) {
            formParams = Object.assign({}, {
                event: this.props.event,
                optionsVariables: {
                    event: convertToInt(this.props.match.params.id),
                },
                rightsScopes: this.props.rightsScopes,
                timezone: this.props.timezone,
            });

            return this.loadAddModalForm(
                createForm(EventGeoRestrictionsFormGeneralInformationModel, form, formParams)
            );
        } else if (path === eventGeoRestrictionsPath.editGeneralInformation.path
            || path === eventGeoRestrictionsPath.editGeoRestrictions.path
            || path === eventGeoRestrictionsPath.blackoutZones.path) {

            formParams = Object.assign({}, {
                event: this.props.event,
                id: convertToInt(this.props.match.params.restrictionId) || convertToInt(params.restrictionId),
                optionsVariables: {
                    event: convertToInt(this.props.match.params.id),
                    isArchived: null,
                },
                rightsScopes: this.props.rightsScopes,
                timezone: this.props.timezone,
            });

            return this.loadEditModalForm(
                {
                    tabs: [
                        {
                            key: 'step1',
                            menuItem: {content: 'General information', disabled: false },
                            pane: {
                                content: createForm(
                                    EventGeoRestrictionsFormGeneralInformationEditModel,
                                    form,
                                    formParams
                                ),
                            },
                            url: getLink('events.geoRestrictions.editGeneralInformation', {
                                id: convertToInt(this.props.match.params.id),
                                restrictionId: convertToInt(this.props.match.params.restrictionId) || convertToInt(params.restrictionId),
                            }),
                        },
                        {
                            key: 'step2',
                            menuItem: {content: 'Geo restrictions', disabled: false },
                            pane: {
                                content: createForm(
                                    EventGeoRestrictionsFormEditModel,
                                    EventGeoRestrictionsForm,
                                    formParams
                                ),
                            },
                            url: getLink('events.geoRestrictions.editGeoRestrictions', {
                                id: convertToInt(this.props.match.params.id),
                                restrictionId: convertToInt(this.props.match.params.restrictionId)
                                    || convertToInt(params.restrictionId),
                            }),
                        },
                        {
                            key: 'step3',
                            menuItem: {content: 'Blackout Zones', disabled: false },
                            pane: {
                                content: createForm(
                                    EventGeoRestrictionsFormBlackoutZonesEditModel,
                                    EventGeoRestrictionsFormBlackoutZonesForm,
                                    formParams
                                ),
                            },
                            url: getLink('events.geoRestrictions.blackoutZones', {
                                id: convertToInt(this.props.match.params.id),
                                restrictionId: convertToInt(this.props.match.params.restrictionId)
                                    || convertToInt(params.restrictionId),
                            }),
                        },
                    ],
                },
                formParams
            );
        }
    }

    loadAddModalForm = (Form) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={'Add new geo restriction'}
                isAddMode={true}
                tabs={[
                    {key: 'step1', menuItem: {content: 'General information', disabled: false }, pane: { content: Form}},
                    {key: 'step2', menuItem: {content: 'Geo restrictions', disabled: true }, pane: {content: null}},
                    {key: 'step3', menuItem: {content: 'Blackout Zones', disabled: true }, pane: {content: null}},
                ]}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    loadEditModalForm = (Forms) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={'Edit geo restriction'}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    render() {
        return (
            <Segment basic>
                <Header>
                    <span className='__tableNote'>
                        {
                            `The geo restrictions defined here will overwrite the geo restrictions defined
                             on property licence level.`
                        }
                    </span>
                    <div className='indexButtons'>
                        <Authorization
                            authorization={authorizationObject}
                        >
                            <Link name={'events.geoRestrictions.add'}
                                params={{
                                    id: this.props.match.params.id,
                                }}
                            >
                                <ButtonAdd>{'Add new geo restriction'}</ButtonAdd>
                            </Link>
                        </Authorization>
                    </div>
                </Header>
                <EventGeoRestrictionTable
                    eventId={convertToInt(this.props.match.params.id)}
                    eventGeoRestrictions={this.props.DataEventGeoRestrictions.eventGeoRestrictions}
                    loading={this.props.DataEventGeoRestrictions.loading}
                />
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        timezone: state.timezone.timezone,
    };
};

const EventGeoResctrictionsWithGraphQL = compose(
    graphql(GetEventGeoRestrictionsForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                variables: {
                    event: props.eventArray,
                },
            };
        },
        name: 'DataEventGeoRestrictions',
    })
)(EventsGeoRestrictionsIndex);

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(EventGeoResctrictionsWithGraphQL));
