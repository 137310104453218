import {
    GetClientProductLcrForForm,
    GetClientProductLcrOptions
} from '@graphql/clientProductLcr/query';
import {
    CreateClientProductLcr,
    DeleteClientProductLcr,
    UpdateClientProductLcr
} from '@graphql/clientProductLcr/mutation';
import Form from '@appComponents/ReduxFormControls';

import ClientProductModel from './ClientProductModel';
import {renderHeader} from './clientProductFormUtils';
import validate from './validatorClientProductLcr';

export default function ClientProductLcrModel() {
    let clientProductLcrModel = ClientProductModel();

    clientProductLcrModel.deleteId = "clientProductsLcr.0.id";
    clientProductLcrModel.entityDataMapKey = "clientProductsLcr.0";
    clientProductLcrModel.entityLabel = "product LCR";
    clientProductLcrModel.validate = validate;
    clientProductLcrModel.query = {
        getEntity: GetClientProductLcrForForm,
        getOptions: GetClientProductLcrOptions,
    };
    clientProductLcrModel.mutation = {
        createEntity: CreateClientProductLcr,
        deleteEntity: DeleteClientProductLcr,
        updateEntity: UpdateClientProductLcr,
    };

    clientProductLcrModel.dataMap = {
        clientProductsLcr: {
            id: "",
            ...clientProductLcrModel.dataMap
        },
    };

    clientProductLcrModel.remapBaseDatamapKeys(clientProductLcrModel);

    clientProductLcrModel.fields = Object.assign({}, clientProductLcrModel.fields, {
        header: {
            props: {
                component: renderHeader,
                label: "Header",
                name: "header",
                defaultValue: "LCR specific settings",
            }
        },
        lcr_client_types: {
            optionsKey: "lcrClientTypes",
            props: {
                className: "inline",
                component: Form.SemanticCheckboxList,
                label: "Types",
                name: "lcr_client_types",
                required: true,
            },
        },
    });

    return clientProductLcrModel;
}
