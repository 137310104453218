import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';

export class LicensorForm extends DefaultForm {
    componentDidMount() {
        document.title = 'Add new licensor - AV-CMP';
        this.setCreateSuccessCallback(() => {
            this.props.history.push(getLink('licensors.index'));
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('licensors.index'));
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink('licensors.index'));
        });
    }

    /**
     * undefined is not an object (evaluating 'data.createLicensor.id')
     */
    prepareDataForSubmit = (data) => {
        return Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);
    };

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            this.props.Model.label,
            getLink('licensors.index'),
            {size: 'tiny'}
        );
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderCancelButton = () => null;
}

export default withRouter(LicensorForm);
