import PropTypes from 'prop-types';

export const wnsStreamsExportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'world_number_service_streams',
    parseData: wnsStreams => [
        wnsStreams.id,
        wnsStreams.name,
        wnsStreams.stream_name,
    ],
    path: 'wnsStreams.index.path',
    titles: [
        'Id',
        'Name',
        'Stream name',
    ],
});

wnsStreamsExportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
