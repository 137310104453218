import Form from '@appComponents/ReduxFormControls';
import {EVENT_BOOKING} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {GetBlackoutZonesForDropdown} from '@graphql/blackoutZone/query';
import {createEventBooking} from '@graphql/eventEventBooking/mutation';
import validate from '@modules/events/forms/validatorEventsEventBookings';

export default function EventBookingBlackoutZonesModel() {
    return {
        entityDataMapKey: 'eventEventBooking',
        entityLabel: 'event booking',
        formName: 'EventBookingGeoRestrictionsBlackoutZones',
        label: 'Event Booking',
        editForm: false,
        resources: EVENT_BOOKING,
        showChangeLog: false,
        title: null,
        validate: validate,
        messages: {
            boxName: {
                delete: 'event',
                error: 'formInnerErrorMessage',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventEventBooking.id',
                props: {
                    hidden: true,
                    name: 'id',
                },
            },
            desktop_blackoutZones: {
                optionsKey: 'blackoutZones',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Desktop',
                    name: 'desktop_blackoutZones',
                    allowClear: true,
                    multiple: true,
                    hidden: true,
                },
            },
            mobile_blackoutZones: {
                optionsKey: 'blackoutZones',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Mobile',
                    name: 'mobile_blackoutZones',
                    allowClear: true,
                    multiple: true,
                    hidden: true,
                },
            },
            smart_tv_blackoutZones: {
                optionsKey: 'blackoutZones',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Smart TV',
                    name: 'smart_tv_blackoutZones',
                    allowClear: true,
                    multiple: true,
                    hidden: true,
                },
            },
        },
        mutation: {
            createEntity: createEventBooking,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            update: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            delete: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
        },
        query: {
            getOptions: GetBlackoutZonesForDropdown,
        },
    };
}
