import PropTypes from 'prop-types';

import routes from '@constants/routes';
import ClipConfigurationAddModalContent from '@modules/clipConfiguration/components/ClipConfigurationAddModalContent';
import ClipConfigurationEditModalContent from '@modules/clipConfiguration/components/ClipConfigurationEditModalContent';
import ClipConfigurationSportLevelModel from '@modules/clipConfiguration/forms/ClipConfigurationSportLevelModel';
import ClipConfigurationTournamentLevelModel
    from '@modules/clipConfiguration/forms/ClipConfigurationTournamentLevelModel';
import ClipConfigurationSportLevelForm from '@modules/clipConfiguration/forms/ClipConfigurationSportLevelForm';
import ClipConfigurationTournamentLevelForm from '@modules/clipConfiguration/forms/ClipConfigurationTournamentLevelForm';

const ClipConfigurationContentModal = (props) => {
    switch (props.match.path) {
        case routes.clipConfiguration.add.addOnSportLevel.path:
            ClipConfigurationAddModalContent(props, routes.clipConfiguration.add.addOnSportLevel.title, ClipConfigurationSportLevelModel, ClipConfigurationSportLevelForm);
            break;
        case routes.clipConfiguration.add.addOnTournamentLevel.path:
            ClipConfigurationAddModalContent(props, routes.clipConfiguration.add.addOnTournamentLevel.title, ClipConfigurationTournamentLevelModel, ClipConfigurationTournamentLevelForm);
            break;
        case routes.clipConfiguration.edit.path:
            ClipConfigurationEditModalContent(props);
            break;
    }
};

ClipConfigurationContentModal.propTypes = {
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default ClipConfigurationContentModal;
