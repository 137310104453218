import React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import {
    get as _get,
    isNumber as _isNumber,
    isUndefined as _isUndefined
} from "lodash"

import {toggleFiltersVisibility} from "../../../actions/index";
import * as STYLES from "../../app/constants/styles";

const INACTIVE_BUTTON_CLASS = `small filter__button ${STYLES.MODIFIER_INACTIVE}`;
const ACTIVE_BUTTON_CLASS = "primary small filter__button";

/**
 * @ignore
 */
export class FilterButton extends React.Component{
    static propTypes = {
        filters: PropTypes.object,
        defaultProps: PropTypes.object,
        toggleFiltersVisibility: PropTypes.func,
        isFiltersWarningVisible: PropTypes.bool,
        areFiltersVisible: PropTypes.bool,
        name: PropTypes.string,
    };

    getFilters(filters) {
        if (!filters[this.props.name]) {
            return {}
        }

        let filtersObject = {...filters[this.props.name].params};

        if (filtersObject) {
            /**
             * Remove default props, which support refetch
             * (timestamp and lastClickSubmit are not parameters from filters)
             */
            delete filtersObject.timestamp;
            delete filtersObject.lastClickSubmit;
        } else {
            return {}
        }

        return filtersObject
    }

    getFiltersWithState = (filters) => {
        let filtersObject = this.getFilters(filters);

        this.setState(() => ({
            isFiltersWarningVisible: (this.state.areFiltersVisible === false && this.hasParams(filtersObject))
        }));

        return filtersObject;
    };

    constructor(props) {
        super(props);
        const filtersData = this.getFilters(props.filters);

        this.state = {
            filters: filtersData.filters,
            areFiltersVisible: _get(props, `filters[${props.name}].areFiltersVisible`, true),
            isFiltersWarningVisible: _get(props, `filters[${props.name}].areFiltersVisible`, true) === false
                && this.hasParams(filtersData),
            isWarningTooltipVisible: false

        };
    }

    /**
     * @ignore
     */
    toggleFilters = () => {
        this.props.toggleFiltersVisibility({key: this.props.name});
    };

    hasParams = (filtersObject) => {
        const filtersObjectKeys = Object.keys(filtersObject);
        const notEmptyValue = filtersObjectKeys.find(el => filtersObject[el] !== null && (filtersObject[el].length > 0 || _isNumber(filtersObject[el])));

        return filtersObjectKeys.length > 0 && !_isUndefined(notEmptyValue)
    };

    getFiltersData = (props) => {
        const filters = this.getFiltersWithState(props.filters);
        const filtersWarningVisible = _get(props, `filters[${props.name}].areFiltersVisible`, true) === false
            && this.hasParams(filters);

        return {
            filters: filters,
            filtersWarningVisible: filtersWarningVisible
        }
    };

    componentWillReceiveProps(nextProps) {
        const filtersData = this.getFiltersData(nextProps);

        this.setState(() => ({
            filters: filtersData.filters,
            areFiltersVisible: _get(nextProps, `filters[${nextProps.name}].areFiltersVisible`, true),
            isFiltersWarningVisible: filtersData.filtersWarningVisible,
            isWarningTooltipVisible: filtersData.filtersWarningVisible
        }))
    }

    onOpenTooltip = () => {
        if (!this.state.isFiltersWarningVisible) {
            return
        }

        this.setState(() => ({
            isWarningTooltipVisible: true
        }))
    };

    onCloseTooltip = () => {
        this.setState(() => ({
            isWarningTooltipVisible: false
        }))
    };

    /**
     * @ignore
     */
    render() {
        let warningIconClassVisible = ( this.state.isFiltersWarningVisible ? "" : "--is-hidden");

        return (
            <Popup
                trigger={
                    <Button
                        className={_get(this.props ,`filters[${this.props.name}].areFiltersVisible`, true)
                            ? ACTIVE_BUTTON_CLASS : INACTIVE_BUTTON_CLASS}
                        onClick={this.toggleFilters}
                    >
                        <Icon name="filter"/>
                        <Icon name="warning" circular size="small"
                            className={`filter__warning ${warningIconClassVisible}`}/>
                    </Button>
                }
                content="Some filters have been applied but filters are currently hidden. Click here to show them."
                open={this.state.isWarningTooltipVisible}
                onOpen={this.onOpenTooltip}
                onClose={this.onCloseTooltip}
                basic
            />

        )
    }
}

/**
 * @ignore
 */
const mapStateToProps = (state) => {
    return {
        filters: state.app.filters
    }
};

/**
 * @ignore
 */
const mapDispatchToProps = (dispatch) => {
    return {
        toggleFiltersVisibility: (key) => {dispatch(toggleFiltersVisibility(key))}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
