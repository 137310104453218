import { createStore, applyMiddleware, compose } from 'redux';
import { persistCombineReducers, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { createFilter } from 'redux-persist-transform-filter';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';

import appReducer from './reducers/index';
import messageReducer from './reducers/message';
import modalReducer from './reducers/modal';
import timezoneReducer from './reducers/timezone';

const appFilters = createFilter(
    'app', null, ['menu', 'filters', 'scroll', 'table', 'streamPlayback']
);

const messagesFilters = createFilter(
    'messages', ['closedDatetimes']
);

const config = {
    key: 'root',
    storage,
    whitelist: [
        'app',
        'messages',
    ],
    transforms: [
        appFilters,
        messagesFilters,
    ]
}

const middlewares = [
    thunkMiddleware // lets dispatch() functions
];

let appliedMiddleware;

if (process.env.NODE_ENV === 'development') {
    appliedMiddleware = composeWithDevTools(
        applyMiddleware( 
            ...middlewares
        )
    );
}
else {
    appliedMiddleware = applyMiddleware(
        ...middlewares
    );
}

const store = createStore(
    persistCombineReducers(config,{
        app: appReducer,
        form: formReducer,
        messages : messageReducer,
        modal : modalReducer,
        timezone: timezoneReducer,
    }),
    {},
    compose (
        appliedMiddleware,
    )
);

persistStore(store, {});

export default store;
