import React from 'react';
import {useParams} from 'react-router';

import {createForm} from '@utils/forms';
import CountryGeneralInformationModel from '@modules/country/forms/CountryGeneralInformationModel';
import CountryGeneralInformationForm from '@modules/country/forms/CountryGeneralInformationForm';

const CountryGeneralInformation = () => {
    const matchParams = useParams(),
        countryId = {id: matchParams.id};

    const Form = createForm(
        CountryGeneralInformationModel,
        CountryGeneralInformationForm,
        countryId
    );

    return (
        <Form />
    );
};

export default CountryGeneralInformation;
