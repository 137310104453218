import {get as _get} from 'lodash';
import {withRouter} from 'react-router-dom';

import {getDeviceNameById} from '@modules/events/utils/eventsBookingGeoRestriction';
import {
    DEVICE_CATEGORY_DESKTOP,
    DEVICE_CATEGORY_MOBILE,
    DEVICE_CATEGORY_SMART_TV,
} from '@constants/variables';
import {GetEventEventBookingForGeoRestrictionsForm} from '@graphql/eventEventBooking/query';
import {convertToInt} from '@utils/helpers';
import {getEventBookingDataFromAllSteps} from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';
import {EventEventBookingGeoRestrictionsForm} from '@modules/eventBooking/forms/EventEventBookingGeoRestrictionForm';
import {editValidation, onCloseModal, setMutationSuccess} from "@modules/eventBooking/forms/helpers/helpers";

export class EventEventBookingGeoRestrictionsEditForm extends EventEventBookingGeoRestrictionsForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;
    DEVICE_CATEGORIES = [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV];

    prepareDataForSubmit = () => {
        return getEventBookingDataFromAllSteps(this.props.stepsMethods, convertToInt(this.props.formParams.optionsVariables.id));
    };

    onFormSubmit = (data) => {
        if (editValidation(this.props.stepsMethods)) {
            this.setState(() => ({
                defaultForm_loading: true,
            }));
            super.onFormSubmit(data);
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.setUpdateSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
        this.setDeleteSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });

        this.queryGetSelectedGeoRestrictions();
        this.setField('desktop_switch', {
            hidden: true,
        });
        this.setField('mobile_switch', {
            hidden: true,
        });
        this.setField('smart_tv_switch', {
            hidden: true,
        });
    }

    queryGetSelectedGeoRestrictions = () => {
        this.setState(() => ({queryGetSelectedGeoRestrictionsLoading: true}));

        return this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: GetEventEventBookingForGeoRestrictionsForm,
            variables: {
                id: this.props.formParams.optionsVariables.id,
            },
        }).then((response) => {
            this.setState(() => ({
                selectedGeoRestrictions: response.data,
            }));

        }).catch((response) => {
            this.props.MessageBox.addMessage(
                'formInnerErrorMessage',
                'Can\'t get selected event booking geo restrictions',
                response.message,
                'error'
            );
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const selectedGeoRestrictionsQueryData = _get(this.state, 'selectedGeoRestrictions'),
            selectedEventEventBookingForGeoRestrictions = _get(selectedGeoRestrictionsQueryData, 'eventEventBooking'),
            selectedGeoRestrictions = _get(selectedEventEventBookingForGeoRestrictions, 'event_booking_geo_restrictions'),
            selectedProduct = _get(this.props.GraphQLEntityData, 'eventEventBooking.product.device_categories', []);

        if (selectedGeoRestrictionsQueryData
            || (this.props.formParams.optionsVariables.client && this.props.GraphQLOptionsData.continents)) {
            if ((this.props?.GraphQLOptionsData?.continents && this.props.GraphQLOptionsData.continents !== prevProps.GraphQLOptionsData.continents)
                || (this.state.selectedGeoRestrictions && this.state.selectedGeoRestrictions !== prevState.selectedGeoRestrictions)
                && this.props.GraphQLOptionsData.continents
            ) {
                this.addSelectedGeoRestrictions(selectedGeoRestrictions);
            }
        }

        if (selectedProduct && this.props.GraphQLOptionsData !== prevProps.GraphQLOptionsData) {
            selectedProduct.map(deviceCategory => deviceCategory.id).forEach((deviceCategoryId) => {
                const deviceName = getDeviceNameById(parseInt(deviceCategoryId));

                this.setField(`${deviceName}_switch`, {
                    hidden: false,
                });
            });
        }
    }

    renderSaveButton = (props) => {
        return super.renderSaveButton({
            ...props,
            content: 'Save',
        });
    };
}

export default withRouter(EventEventBookingGeoRestrictionsEditForm);
