import React from 'react';
import PropTypes from 'prop-types';

import Link from "../../../../../../app/components/Link";

export const Master = ({event}) => (
    (event.is_master || !event.master_event || !event.master_event.id || !event.master_event.description)
        ? '-'
        : (
            <Link name="events.configuration.index" params={{id: event.master_event.id}}>
                {event.master_event.description}
            </Link>
        )
);

Master.propTypes = {
    event: PropTypes.shape({
        is_master: PropTypes.bool.isRequired,
        master_event: PropTypes.shape({
            id: PropTypes.string,
            description: PropTypes.string
        })
    }).isRequired
};

Master.defaultProps  = {
    event: {
        master_event: {
            id: null,
            description: null
        }
    }
}
