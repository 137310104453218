import {changelogData} from '../changelogData';

export const eventContentMultiAudioBody = `
    id,
    track_number,
    channels,
    stream_language {
      id
    }
${changelogData}
`;

export const eventContentMultiAudios = `
query EventContentMultiAudios($encodingTargetId: ID!) {
  streamLanguages{
    id,
    name,
    language{      
      code
    }
  }
  encodingTargetsSr(filters: {encodingTarget: $encodingTargetId}) {
    encoding_job_profile{
      has_multi_audio_support
    }
  }
    audios(filters: {encodingTarget: $encodingTargetId}){
        ${eventContentMultiAudioBody}
    }
}`;

export const CreateMutation = `
mutation createEventContentMultiAudio(
    $encodingTargetId: ID!, 
    $trackNumber: Int!, 
    $channels: CommaSeparatedStringToArray!, 
    $streamLanguage: ID!
  ) {
  createAudio(
    encoding_target: $encodingTargetId,
    track_number: $trackNumber, 
    channels:$channels,
    stream_language:$streamLanguage,
  ) {
    ${eventContentMultiAudioBody}
  }
}`;

export const UpdateMutation = `
mutation updateEventContentMultiAudio(
    $id: ID!,
    $encodingTargetId: ID!, 
    $trackNumber: Int!, 
    $channels: CommaSeparatedStringToArray!, 
    $streamLanguage: ID!
  ) {
  updateAudio(
    id: $id,
    encoding_target: $encodingTargetId,
    track_number: $trackNumber, 
    channels:$channels,
    stream_language:$streamLanguage,
  ) {
    ${eventContentMultiAudioBody}
  }
}`;

export const DeleteMutation = `
mutation deleteEventContentMultiAudio($id: ID!) {
    deleteAudio(id: $id)
}`;
