export const contentCategoriesFragment = `
id
name 
parent {
  id
  name
  parent {
    id
    name
  }
}`;

export const contentCategoriesFilteredByLevelAndParentsQuery = `
contentCategories(filters: {level: $level, parent: $parents}) {
  ${contentCategoriesFragment}
}`;

export const contentCategoriesFilteredByLevelAndParents = `
query contentCategories($level: Int, $parents: [Int]) {
  ${contentCategoriesFilteredByLevelAndParentsQuery}
}`;
