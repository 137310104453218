import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";

import {CLIENT_PACKAGE} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE} from "../../app/constants/variables";

const PackagesIndexButtons = (props) => (
    <div className="content__buttons">
        <Authorization
            privileges={SECURITY_PRIVILEGES_CREATE}
            resources={CLIENT_PACKAGE}
        >
            <Link name={'clients.packages.add'} params={{id: props.clientId}}>
                <ButtonAdd>{'Add new package'}</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

PackagesIndexButtons.propTypes = {
    clientId: PropTypes.number.isRequired,
};

export default PackagesIndexButtons;
