import React from "react";
import PropTypes from "prop-types";

import ApplicationIndex from "../views/ApplicationIndex";
import RtmpOriginEntryPointEdit from "../views/RtmpOriginEntryPointEdit";

import {routes} from "../../app/constants/routes";
import {RTMP_ORIGIN_ENTRY_POINT, RTMP_ORIGIN_ENTRY_POINT_APPLICATION} from "../../app/constants/resources";

import {renderModalError} from "../../../utils/forms";

export const createTabs = (id, path, userPrivileges, rtmpOriginEntryPointHost) => {
    return (routes.rtmpOriginEntryPoint.add.path === path)
        ? {
            [routes.rtmpOriginEntryPoint.add.path]: {
                key: routes.rtmpOriginEntryPoint.add.path,
                url: "rtmpOriginEntryPoint.add",
                text: "General information",
                render: <RtmpOriginEntryPointEdit/>,
                resource: RTMP_ORIGIN_ENTRY_POINT
            }
        }
        : {
            [routes.rtmpOriginEntryPoint.edit.path]: {
                key: routes.rtmpOriginEntryPoint.edit.path,
                url: "rtmpOriginEntryPoint.edit",
                urlParams: {id: id},
                text: "General information",
                render: <RtmpOriginEntryPointEdit/>,
                resource: RTMP_ORIGIN_ENTRY_POINT
            },
            [routes.rtmpOriginEntryPoint.applications.index.path]: {
                key: routes.rtmpOriginEntryPoint.applications.index.path,
                url: "rtmpOriginEntryPoint.applications.index",
                urlParams: {id: id},
                text: "Applications",
                render: <ApplicationIndex rtmpOriginEntryPointHost={rtmpOriginEntryPointHost}/>,
                resource: RTMP_ORIGIN_ENTRY_POINT_APPLICATION
            }
        };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createRtmpOriginEntryPointTabs';

export const renderRtmpOriginEntryPointModalError = () => {
    return renderModalError('RTMP origin entry point', routes.rtmpOriginEntryPoint.index.path)
};
renderModalError.displayName = 'renderRtmpOriginEntryPointModalError';
