import React from 'react';
import {get as _get, isEmpty as _isEmpty} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt, getIdsFromChildren} from '@utils/helpers';
import {createForm, getFormMessageForWizard} from '@utils/forms';
import {CLIENT_SELECTION_TYPE_ALL} from '@constants/variables';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';

import {EventGeoRestrictionsForm} from './EventGeoRestrictionsForm';
import EventGeoRestrictionsFormModel from './EventGeoRestrictionsFormModel';
import {getProductUsages} from '../../utils/rightsScopeUsagesHelper';
import {
    getDataFromAllSteps,
    getSelectionTypeValue,
    showErrorNotice
} from '../../utils/eventGeoRestriction';

import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';

export class EventGeoRestrictionsGeneralInformationForm extends DefaultForm {
    prepareDataForSubmit = () => {
        const data = getDataFromAllSteps(
                this.props.stepsMethods,
                this.props.formParams.optionsVariables.event
            ),
            isFormDataValid = null !== data.territory_selection_type || 0 < data.blackout_zones.length;

        return isFormDataValid ? data : showErrorNotice();
    };

    onFormSubmit = (data) => {
        if (!this.props.Model.editForm) {
            this.setState(() => ({
                defaultForm_errorShowed: false,
            }));

            const Form = createForm(
                EventGeoRestrictionsFormModel,
                EventGeoRestrictionsForm,
                {
                    id: data.id,
                    optionsVariables: {
                        event: this.props.formParams.optionsVariables.event,
                    },
                });

            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
            this.props.stepsMethods.setStep({
                id: 'step2',
                menuItem: {disabled: false},
                pane: {content: <Form previousStepData={data}/>},
            });
            this.props.stepsMethods.showStep('step2');
        } else {
            if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
                this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                    _get(this.props, 'Model.label', _get(this.props, 'Model.formName', ''))
                        .toLocaleLowerCase()
                ));
            } else {
                const step2Data = this.props.stepsMethods.getForms()['step2'].formValues;
                const step3Data = this.props.stepsMethods.getForms()['step3'].formValues;

                return super.onFormSubmit(Object.assign({}, data, {
                    territory_selection_type: getSelectionTypeValue(step2Data.selection_type),
                    countries: getIdsFromChildren(step2Data.countries),
                    country_subdivisions: getIdsFromChildren(step2Data.subdivisions),
                    blackout_zones: step3Data.blackoutZones.map(Number),
                }));
            }
        }
    };

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {
            GraphQLOptionsData: {
                eventContents,
                products,
                waysOfTransmission,
            },
        } = nextProps;
        const restriction = _get(nextProps, 'GraphQLEntityData.eventGeoRestriction', {}),
            clientSelectionTypeId = convertToInt(_get(restriction, 'client_selection_type.id', 1)),
            restrictionClients = _get(restriction, 'clients', []);

        if (!this.state.clientSelectionTypeGenerated && CLIENT_SELECTION_TYPE_ALL !== clientSelectionTypeId) {
            this.setField('client_selection_type', {
                defaultValue: clientSelectionTypeId.toString(),
            });
            this.setField('clients', {
                hidden: false,
            });

            this.setState(() => ({clientSelectionTypeGenerated: true}));
        }

        if (!this.state.clientsGenerated && 0 < restrictionClients.length) {
            this.setField('clients', {
                defaultValue: restriction.clients.map((client) => {
                    return client.id;
                }),
            });

            this.setState(() => ({clientsGenerated: true}));
        }

        if (!this.state.allEventContentsGenerated && false === restriction.includes_all_event_contents) {
            this.setField('includes_all_event_contents', {
                defaultValue: false,
            });

            this.setField('event_contents', {
                hidden: false,
                required: true,
            });

            this.setState(() => ({allEventContentsGenerated: true}));
        }

        if (!this.state.eventContentsOptionsGenerated && eventContents) {
            this.setField('event_contents', {
                defaultValue: _get(restriction, 'event_contents', [])
                    .map((eventContent) => convertToInt(eventContent.id)),
                options: eventContents.map((eventContent) => ({
                    popup: (
                        <>
                            <EventContentVariantPopupWithName eventContent={eventContent}/>
                            <br/>
                        </>
                    ),
                    value: convertToInt(eventContent.id),
                })),
                className: '--auto_width',
            });

            this.setState(() => ({eventContentsOptionsGenerated: true}));
        }

        if (!this.state.usageOptionsTreeGenerated && products && waysOfTransmission) {
            this.setField('usages', {
                options: this.getUsageTreeOptions({products: products}),
            });

            this.setState(() => ({usageOptionsTreeGenerated: true}));
        }

        if (!this.state.entityUsageOptionsTreeGenerated && products && waysOfTransmission && !_isEmpty(restriction.usages)) {
            this.setField('usages', {
                options: this.getUsageTreeOptions({
                    products: products,
                    selected: restriction.usages,
                }),
            });

            this.setState(() => ({entityUsageOptionsTreeGenerated: true}));
        }

        this.props.stepsMethods.setForm('step1', nextProps);

        if (_isEmpty(this.props.formParams.rightsScopes)) {
            super.setFormMessage(
                'There\'s not overlapping Media Rights for current Event!',
                'formInnerErrorMessage'
            );
        }
    }

    componentDidMount() {
        this.setOnChangeCallback({
            includes_all_event_contents: (data) => {
                const hideContents = !data.value;

                this.setField('event_contents', {
                    hidden: hideContents,
                    required: !hideContents,
                    defaultValue: [],
                });
            },
            client_selection_type: (data) => {
                this.setField('clients', {
                    defaultValue: [],
                    hidden: CLIENT_SELECTION_TYPE_ALL === convertToInt(data.value),
                });
            },
        });
    }

    getUsageTreeOptions(data) {
        const {
            formParams: {
                rightsScopes,
            },
        } = this.props;

        const selectedOptions = {};

        if (data.selected) {
            data.selected.forEach((option) => {
                if (option) {
                    selectedOptions[
                        `product-${option.product.id}`
                        + `.device_category-${option.device_category.id}`
                        + `.way_of_transmissions-${option.way_of_transmission.id}`
                    ] = option.id;
                }
            });
        }

        let productUsages = [];

        if (!_isEmpty(rightsScopes)) {
            productUsages = getProductUsages(rightsScopes);
        }

        return productUsages.map((product) => ({
            label: product.short_name,
            name: 'product',
            value: product.id,
            children: product.device_categories.map((deviceCategory) => ({
                label: deviceCategory.name,
                name: 'device_category',
                value: deviceCategory.id,
                children: deviceCategory.ways_of_transmission.map((wayOfTransmission) => {
                    const id = _get(selectedOptions,
                        `product-${product.id}.device_category-${deviceCategory.id}.way_of_transmissions-${wayOfTransmission.id}`,
                        null
                    );

                    return {
                        id: id,
                        label: wayOfTransmission.name,
                        name: 'ways_of_transmissions',
                        value: wayOfTransmission.id,
                        selected: (null !== id),
                    };
                }),
            })),
        }));
    }

    renderErrors(errorData) {
        const link = getLink('events.geoRestrictions.index', {
            id: this.props.formParams.optionsVariables.event || null,
        });

        super.renderErrors(errorData, 'Event geo restrictions', link, {size: 'tiny'});
    }

    renderSaveButton = (props) => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                ...props,
                content: 'Next',
                icon: 'angle double right',
                labelPosition: 'right',
            });
        }

        return super.renderSaveButton();
    };
}

export default EventGeoRestrictionsGeneralInformationForm;
