import {connect} from "react-redux";
import {withRouter} from "react-router";

import ClientProductForm from "./ClientProductForm";
import {getLink} from "../../app/components/Link";

import {deleteSuccessCallback, setClientProductsEntity} from "../utils/clientProducts";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {PRODUCT_LCI} from "../../app/constants/variables";

export class ClientProductLciForm extends ClientProductForm {
    componentDidMount() {
        const entityName = "client",
            params = {
                clientData: this.props.clientData,
                entityName,
                setEntity: this.props.Entity.setEntity,
            };

        this.setCreateSuccessCallback((response) => {
            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientProducts: this.props.clientData.clientProducts.concat(
                        response.data.createClientProductLci.client_product
                    ),
                }),
            });

            this.props.history.push(getLink("clients.products.lci.editGeoRestrictions", {
                id: this.props.match.params.id,
            }));
        });

        this.setUpdateSuccessCallback((response) => {
            const clientProductLciIndex = this.props.clientData.clientProducts.findIndex(clientProduct =>
                    PRODUCT_LCI === convertToInt(clientProduct.product.id)
                ),
                clientProducts = {};

            clientProducts[clientProductLciIndex] = Object.assign({},
                response.data.updateClientProductLci.client_product
            );
            params.clientProducts = clientProducts;

            setClientProductsEntity(params);
        });

        this.setDeleteSuccessCallback(() => {
            params.formParamsId = this.props.formParams.id;
            params.historyPush = this.props.history.push;

            deleteSuccessCallback(params);
        });
    }

    componentDidUpdate() {
        const {
            formParams: {isAddForm},
            GraphQLEntityData: {
                clientProductsLci,
                loading: entityLoading,
            } = {
                entityLoading: false,
            },
            GraphQLOptionsData: {
                loading: optionsLoading,
            },
        } = this.props;

        let nextProps;

        if (!optionsLoading && !entityLoading && !this.state.dataReceived) {
            if (!isAddForm) {
                nextProps = Object.assign({}, this.props, {
                    clientProduct: clientProductsLci[0].client_product,
                });

                this.setField("id", {defaultValue: convertToInt(clientProductsLci[0].id)});
            }

            super.componentDidUpdate(nextProps);
        }
    }

    prepareDataForSubmit(data) {
        data = Object.assign({}, data, {product: PRODUCT_LCI});

        return super.prepareDataForSubmit(data);
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity']),
    mapStateToProps = (state) => ({clientData: state.app.entities.client || null});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientProductLciForm));
