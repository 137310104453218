import validators from '@utils/validators';
import {IP_VERSION_IP_V4} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

export const commonValidatorWhitelistedIp = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.ip_version, 'ip_version'));

    if (convertToInt(values.ip_version, 10) === IP_VERSION_IP_V4) {
        Object.assign(errors, validators.cidrIPv4Range(values.cidr, 'cidr'));
        Object.assign(errors, validators.cidrIPv4(values.cidr, 'cidr'));
    } else {
        Object.assign(errors, validators.cidrIPv6Range(values.cidr, 'cidr'));
        Object.assign(errors, validators.cidrIPv6(values.cidr, 'cidr'));
    }

    return errors;
};
