import React from 'react';
import {Header, Icon} from 'semantic-ui-react';

import ShowCurrentDateTime from './ShowCurrentDateTime.jsx';

/**
 * @ignore
 */
const Time = () => (
    <Header as="h5" className="page-header__widget page-header__widget--time">
        <Icon.Group>
            <Icon name='wait' className="page-header__timeIcon" />
        </Icon.Group>
        <ShowCurrentDateTime />
    </Header>
);

export default Time;
