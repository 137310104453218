import {get as _get, isEmpty as _isEmpty} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV} from '@constants/variables';
import {
    getAdditionalBackButton,
    getDeviceNameById,
    getEventBookingDataFromAllSteps,
} from '@modules/events/utils/eventsBookingGeoRestriction';

export class EventBookingGeoRestrictionsBlackoutZonesForm extends DefaultForm {
    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        const {
                GraphQLEntityData,
                GraphQLOptionsData,
            } = this.props,
            isLoading = (GraphQLEntityData && GraphQLEntityData.loading) || GraphQLOptionsData.loading;

        if (this.props.switches && this.props.switches !== prevProps.switches) {
            this.setField('desktop_blackoutZones', {
                hidden: !this.props.switches.desktop_switch,
            });
            this.setField('mobile_blackoutZones', {
                hidden: !this.props.switches.mobile_switch,
            });
            this.setField('smart_tv_blackoutZones', {
                hidden: !this.props.switches.smart_tv_switch,
            });

            this.setState(() => ({
                switchesStatus: this.props.switches,
            }));
        }

        if ((!isLoading && !this.state.dataLoaded)) {
            let assignedBlackoutZonesIds = {};
            const blackoutZonesPrefilled = _get(this.props, 'previousStepData.blackoutZonesPrefilled', []),
                eventBookingGeoRestrictions = _get(GraphQLEntityData, 'booking.booking_geo_restrictions', []),
                blackoutZonesOptions = _get(GraphQLOptionsData, 'blackoutZones', []),
                preparedBlackoutZonesOptions = {};

            if (0 < blackoutZonesPrefilled.length && 0 === eventBookingGeoRestrictions.length) {
                blackoutZonesPrefilled.forEach((blackoutZonePrefilled) => {
                    assignedBlackoutZonesIds = Object.assign(
                        assignedBlackoutZonesIds,
                        {[blackoutZonePrefilled.device_category]: blackoutZonePrefilled.blackout_zones}
                    );
                });
            } else {
                eventBookingGeoRestrictions.forEach((eventBookingGeoRestriction) => {
                    assignedBlackoutZonesIds = Object.assign(
                        assignedBlackoutZonesIds,
                        {[eventBookingGeoRestriction.device_category.id]: eventBookingGeoRestriction.
                            blackout_zones.map((blackoutZone) => {
                                return blackoutZone.id;
                            })}
                    );
                });

                for (const [key, blackoutZonesIds] of Object.entries(assignedBlackoutZonesIds)) {
                    preparedBlackoutZonesOptions[key] = blackoutZonesOptions.filter(blackoutZone => {
                        if (blackoutZone.is_archived && blackoutZonesIds.includes(blackoutZone.id)) {
                            return true;
                        } else {
                            return !blackoutZone.is_archived;
                        }
                    });
                }
            }

            [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV].forEach((deviceCategoryId) => {
                const deviceName = getDeviceNameById(deviceCategoryId);

                if (!this.props.formParams.id) {
                    this.setField(`${deviceName}_blackoutZones`, {
                        hidden: !this.props.previousStepData[`${deviceName}_switch`],
                    });
                }

                this.setField(`${deviceName}_blackoutZones`, {
                    defaultValue: assignedBlackoutZonesIds[deviceCategoryId]
                        ? assignedBlackoutZonesIds[deviceCategoryId].map(String)
                        : [],
                    options: preparedBlackoutZonesOptions[deviceCategoryId]
                        ? preparedBlackoutZonesOptions[deviceCategoryId]
                        : blackoutZonesOptions.filter(blackoutZone => !blackoutZone.is_archived),
                });
            });

            this.setState(() => ({dataLoaded: true}));
        }
    }

    prepareDataForSubmit = () => {
        return getEventBookingDataFromAllSteps(this.props.stepsMethods, this.props.formParams.id);
    };

    onFormSubmit = (data) => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', '')).toLocaleLowerCase()
            ));
        } else {
            return super.onFormSubmit(data);
        }
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (!_isEmpty(this.props.stepsMethods)) {
            this.props.stepsMethods.setForm('step3', nextProps);
        }
    }

    renderAdditionalButtons = () => (
        getAdditionalBackButton(this.props.Model.editForm, this.props.stepsMethods.showPreviousStep)
    );
}

export default EventBookingGeoRestrictionsBlackoutZonesForm;
