
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClientWhitelistedIpData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClientWhitelistedIp"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ip_version"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"cidr"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_disabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"disable_datetime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"products"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"short_name"},"arguments":[],"directives":[]}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChangelogData"},"directives":[]}]}}],"loc":{"start":0,"end":277}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment ClientWhitelistedIpData on ClientWhitelistedIp {\n    id\n    ip_version {\n        ...BasicData\n    }\n    cidr\n    description\n    is_disabled\n    disable_datetime\n    products {\n        id\n        short_name\n    }\n    ...ChangelogData\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
