import {get as _get} from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint import/no-unresolved: 0 */
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Header, Segment} from 'semantic-ui-react';

import {gql, graphql, withApollo} from 'react-apollo';
import {showMessageBox} from '@utils/messageBox';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {restoreFilterValues} from '@appComponents/HOCFiltersWrapper';
import {refetchQueryByName} from '@utils/apollo';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {
    ENCODING_STATUS_ENCODING,
    ENCODING_STATUS_ERROR,
    ENCODING_STATUS_PREPARING,
    ENCODING_STATUS_SCHEDULED,
    ORIGIN_STREAM_TYPE_UDP,
    ORIGIN_STREAM_TYPE_SRT,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {liveControlFilterQuery, liveControlIndexQuery} from '@graphql/liveControl/index';
import {setUdpOriginEntryPointFailover} from '@graphql/liveControl/actions';

import LiveControlLegend from './LiveControlLegend';
import LiveControlMultipleFailoverConfirmation from './LiveControlMultipleFailoverConfirmation';
import ModalHeaderEventContentLiveControl from './ModalLiveControlHeader';
import LiveControlTable from '../components/LiveControlTable';
import LiveControlFilters from '../components/LiveControlFilters';
import {failoverContentsMessageBox} from '../utils/helpers';

export class LiveControlIndex extends React.Component {
    static propTypes = {
        autoRefreshInterval: PropTypes.number,
        client: PropTypes.shape({
            mutate: PropTypes.func.isRequired,
        }),
        LiveControl: PropTypes.object,
        LiveControlFilter: PropTypes.shape({
            broadcastStatuses: PropTypes.array,
            distributionTypes: PropTypes.array,
            encodingDatacenters: PropTypes.array,
            encodingStatuses: PropTypes.array,
            eventTypes: PropTypes.array,
            products: PropTypes.array,
            propertyLicencesForDropdown: PropTypes.array,
            loading: PropTypes.bool,
            search: PropTypes.string,
            tvChannels: PropTypes.array,
            eventContentTypes: PropTypes.array,
            eventContentVariants: PropTypes.array,
        }),
        Menu: PropTypes.object,
        Modal: PropTypes.shape({
            setModalConfirmation: PropTypes.func.isRequired,
            setModal: PropTypes.func.isRequired,
        }),
        filters: PropTypes.object,
        Filters: PropTypes.object,
        loading: PropTypes.bool,
        loadingRefetch: PropTypes.bool.isRequired,
        liveControlAutoFailoverClosedTimestamp: PropTypes.string,
        timezone: PropTypes.string.isRequired,
        sessionFilters: PropTypes.object,
    };

    static defaultProps = {
        liveControlAutoFailoverClosedTimestamp: null,
        filters: {
            preBuffer: 2,
            postBuffer: 2,
        },
    };

    state = {
        liveControlList: [],
        pollingInterval: 20,
    };

    queryIntervalTimeoutHandler = null;

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.liveControl.key);

        this.props.Filters.setFilter({
            name: 'LiveControlAutorefresh',
            data: {
                autorefresh_interval: this.props.autoRefreshInterval,
            },
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const liveControlList = nextProps.LiveControl.liveControlList;

        if (liveControlList && prevState.liveControlList !== liveControlList) {
            return {
                liveControlList: liveControlList,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        if (this.props.LiveControl && this.props.LiveControl !== prevProps.LiveControl
            && !this.props.LiveControl.loading && this.props.LiveControl.liveControlList
            && this.props.LiveControl.liveControlList !== prevProps.LiveControl.liveControlList)
        {
            if (this.props.filters.lastClickSubmit && this.props.filters.lastClickSubmit !== this.state.formSubmitTimestamp) {
                this.setState(() => ({
                    formSubmitTimestamp: this.props.filters.lastClickSubmit,
                }));
            }
            this.setQueryTimeout(this.props.autoRefreshInterval);

            let autoFailoverContents = [],
                lastFailoverDatetime = '';

            this.props.LiveControl.liveControlList.forEach((listItem) => {
                if (moment.tz(listItem.origin_entry_point_auto_failover_datetime, this.props.timezone)
                    .isAfter(moment.tz(this.props.timezone).subtract(5, 'minutes'))) {
                    if ([ORIGIN_STREAM_TYPE_SRT, ORIGIN_STREAM_TYPE_UDP].includes(convertToInt(listItem.origin_stream_type_id))) {
                        autoFailoverContents.push(listItem);
                    }

                    if (lastFailoverDatetime < listItem.origin_entry_point_auto_failover_datetime) {
                        lastFailoverDatetime = listItem.origin_entry_point_auto_failover_datetime;
                    }
                }
            });

            if (!this.props.liveControlAutoFailoverClosedTimestamp
                || moment.tz(lastFailoverDatetime, this.props.timezone)
                    .isAfter(moment.tz(this.props.liveControlAutoFailoverClosedTimestamp, this.props.timezone))
            ) {
                failoverContentsMessageBox(autoFailoverContents);
            }
        }
    }

    setPollingInterval = (intervalValue) => {
        const interval = convertToInt(intervalValue);

        this.setState(() => ({
            pollingInterval: interval,
        }));

        clearTimeout(this.queryIntervalTimeoutHandler);

        this.props.Filters.setFilter({name: 'LiveControlAutorefresh', data: {autorefresh_interval: interval}});

        if (0 < interval && interval !== this.state.pollingInterval) {
            this.props.LiveControl.refetch();
        }
    };

    setQueryTimeout = (interval) => {
        var _self = this;

        //Polling interval is not working in current apollo client so we use timeout instead
        clearTimeout(this.queryIntervalTimeoutHandler);

        if (0 < interval) {
            this.queryIntervalTimeoutHandler = setTimeout(() => {
                this.props.LiveControl.refetch().finally(() => {
                    _self.setQueryTimeout(interval);
                });
            }, interval * 1000);
        }
    };

    componentWillUnmount() {
        clearTimeout(this.queryIntervalTimeoutHandler);
    }

    handleUdpOriginEntryPointFailover = (row) => {
        this.props.Modal.setModalConfirmation({
            header: <ModalHeaderEventContentLiveControl event={row} title='UDP origin entry point failover'/>,
            text: `Are you sure you want to failover to the UDP origin entry point "${row.failover_udp_entry_point_name}" now?`,
            onYes: () => {
                let eventContentUdpOriginEntryPointFailover = [{
                    event_content: row.event_content_id,
                    udp_origin_entry_point_failover: row.failover_udp_entry_point_id,
                    udp_origin_entry_point_failover_encoding_datacenter: convertToInt(row.failover_udp_entry_point_encoding_datacenter_id),
                }];

                return this.udpateUdpOriginEntryPoint(eventContentUdpOriginEntryPointFailover);
            },
        });
    };

    handleMultipleUdpOriginEntryPointFailover = () => {
        this.props.Modal.setModalConfirmation({
            header: <Header content='UDP origin entry point failover'/>,
            text: <LiveControlMultipleFailoverConfirmation/>,
            onYes: () => {
                let eventContentUdpOriginEntryPointFailover = this.filterEventContentsForMultipleFailover();

                if (0 === eventContentUdpOriginEntryPointFailover.length) {
                    showMessageBox(
                        'liveControl',
                        'None of the event contents fulfilled the conditions for a UDP origin entry point failover!',
                        null,
                        'error'
                    );
                    this.props.Modal.setModal({isVisible: false});

                    return;
                }

                return this.udpateUdpOriginEntryPoint(eventContentUdpOriginEntryPointFailover, true);
            },
        });
    };

    udpateUdpOriginEntryPoint = (eventContentUdpOriginEntryPointFailover, multiple = false) => {
        this.props.client.mutate({
            mutation: gql(setUdpOriginEntryPointFailover),
            variables: {
                eventContentUdpOriginEntryPointFailover: eventContentUdpOriginEntryPointFailover,
            },
        }).then((data) => {
            this.props.Modal.setModal({isVisible: false});

            let result = data.data.setUdpOriginEntryPointFailover,
                failedIds = result.filter((item) => (
                    400 === item.status
                )).map((item) => (item.id));

            if (0 < failedIds.length) {
                showMessageBox(
                    'liveControl',
                    (!multiple ? result[0].message : `
                        The UDP origin entry point failover failed for the following 
                        event contents: ${failedIds.join(', ')}.
                    `),
                    null,
                    'error'
                );
            } else if (multiple) {
                showMessageBox(
                    'liveControl',
                    'The UDP origin entry point failover was applied successfully.',
                    null,
                    'success'
                );
            }

            refetchQueryByName('LiveControl');
        }).catch((error) => {
            this.props.Modal.setModal({isVisible: false});
            showMessageBox(
                'liveControl',
                'There was an error while changing to UDP origin entry point failover.',
                `${error}`,
                'error'
            );
        });
    };

    filterEventContentsForMultipleFailover = () => (
        this.state.liveControlList.filter((eventContent) => (
            eventContent.event_content_has_input
            && ORIGIN_STREAM_TYPE_UDP === eventContent.origin_stream_type_id
            && null !== eventContent.udp_entry_point_id
            && null !== eventContent.failover_udp_entry_point_id
            && (
                null === eventContent.encoding_status_id
                || (
                    [
                        ENCODING_STATUS_SCHEDULED,
                        ENCODING_STATUS_PREPARING,
                        ENCODING_STATUS_ENCODING,
                        ENCODING_STATUS_ERROR,
                    ].includes(eventContent.encoding_status_id)
                    && eventContent.udp_entry_point_encoding_datacenter_id === eventContent.failover_udp_entry_point_encoding_datacenter_id
                )
            )
        )).map((eventContent) => ({
            event_content: convertToInt(eventContent.id),
            udp_origin_entry_point_failover: eventContent.failover_udp_entry_point_id,
            udp_origin_entry_point_failover_encoding_datacenter: convertToInt(
                eventContent.failover_udp_entry_point_encoding_datacenter_id
            ),
        }))
    );

    render() {
        const loading = (
            this.props.LiveControl.loading
            || this.props.loadingRefetch
        );

        return (<div>
            <HeaderRenderer
                pageTitle='Live Control'
                messagesBoxNames={['liveControl', 'liveControlAutoFailoverForUdp', 'liveControlAutoFailoverForSrt']}
                buttons={LiveControlLegend}
                buttonsProps={{
                    handleMultipleUdpOriginEntryPointFailover: this.handleMultipleUdpOriginEntryPointFailover,
                    loading: loading,
                    autoRefreshInterval: this.props.autoRefreshInterval,
                    refetchLiveControl: this.props.LiveControl.refetch,
                    setPollingInterval: this.setPollingInterval,
                }}
                filters={LiveControlFilters}
                filtersProps={
                    {
                        loading: this.props.LiveControlFilter.loading,
                        data: {
                            filters: this.props.filters,
                            restoreFilterValues: restoreFilterValues,
                            encodingStatuses: this.props.LiveControlFilter.encodingStatuses || [],
                            propertyLicencesForDropdown: this.props.LiveControlFilter.propertyLicencesForDropdown || [],
                            eventTypes: this.props.LiveControlFilter.eventTypes || [],
                            tvChannels: this.props.LiveControlFilter.tvChannels || [],
                            products: this.props.LiveControlFilter.products || [],
                            distributionTypes: this.props.LiveControlFilter.distributionTypes || [],
                            encodingDatacenters: this.props.LiveControlFilter.encodingDatacenters || [],
                            eventContentTypes: this.props.LiveControlFilter.eventContentTypes || [],
                            eventContentVariants: this.props.LiveControlFilter.eventContentVariants || [],
                            broadcastStatuses: this.props.LiveControlFilter.broadcastStatuses || [],
                        },
                    }
                }
            />
            <Segment basic className='--table'>
                <LiveControlTable
                    handleUdpOriginEntryPointFailover={this.handleUdpOriginEntryPointFailover}
                    liveControlList={this.state.liveControlList}
                    loading={loading}
                />
            </Segment>
        </div>);
    }
}

const mapDispatchToProps = mapModulesToProps(['Filters', 'Menu', 'Modal']);

const mapStateToProps = (state) => {
    return {
        filters: getSearchFilters(state, 'LiveControl'),
        autoRefreshInterval: _get(state, 'app.filters.LiveControlAutorefresh.params.autorefresh_interval', 20),
        liveControlAutoFailoverClosedTimestamp: _get(state.messages.closedDatetimes, 'liveControlAutoFailover'),
        timezone: state.timezone.timezone,
    };
};

const LiveControlWithQuery = compose(
    graphql(gql(liveControlIndexQuery), {
        options: (props) => {
            let eventContentTypes = props.filters.eventContentType,
                eventContentVariants = props.filters.eventContentVariants;

            if (props.filters.eventContentVariant) {
                eventContentVariants = props.filters.eventContentVariant.map(id => convertToInt(id));
            }

            if (props.filters.eventContentType) {
                eventContentTypes = props.filters.eventContentType.map(id => convertToInt(id));
            }

            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    search: props.filters.search || null,
                    broadcastStatus: props.filters.broadcastStatus || null,
                    encodingStatus: props.filters.encodingStatus || null,
                    sport: props.filters.sport || null,
                    tvChannels: props.filters.tvChannels || null,
                    tournamentCategory: props.filters.tournamentCategory || null,
                    tournament: props.filters.tournament || null,
                    court: props.filters.court || null,
                    propertyLicence: props.filters.propertyLicence || null,
                    eventType: props.filters.eventType || null,
                    contentCategoryLevel1: props.filters.contentCategoryLvl1 || null,
                    contentCategoryLevel2: props.filters.contentCategoryLvl2 || null,
                    contentCategoryLevel3: props.filters.contentCategoryLvl3 || null,
                    product: props.filters.product || null,
                    preBuffer: _get(props.filters, 'preBuffer', 2),
                    postBuffer: _get(props.filters, 'postBuffer', 2),
                    distributionType: props.filters.distributionType || null,
                    encodingDatacenter: props.filters.encodingDatacenter || null,
                    startBroadcastAutomatically: (null !== props.filters.startBroadcastAutomatically
                    && props.filters.startBroadcastAutomatically !== undefined
                        ? !!props.filters.startBroadcastAutomatically : null),
                    eventContentType: eventContentTypes || null,
                    eventContentVariant: eventContentVariants || null,
                },
            };
        },
        name: 'LiveControl',
    }),
    graphql(gql(liveControlFilterQuery), {
        options: () => {
            return {
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
            };
        },
        name: 'LiveControlFilter',
    })
)(FilterQueryWrapper(LiveControlIndex, {
    excluded: ['autorefresh_interval'],
    queryForRefresh: 'LiveControl',
    filterUrls: ['liveControl'],
}));

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(LiveControlWithQuery, LiveControlIndex.defaultProps.filters)));
