import {
    get as _get,
} from 'lodash';
import PropTypes from 'prop-types';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {
    SECURITY_PRIVILEGES_UPDATE,
    TERRITORY_SELECTION_TYPE_WORLDWIDE
} from '@constants/variables';

class TerritorySelectionForm extends DefaultForm {
    fieldKey = 'territory_selection_type';

    componentDidMount() {
        this.setField(this.fieldKey, {
            onChange: (value) => {
                if (TERRITORY_SELECTION_TYPE_WORLDWIDE === convertToInt(this.props.territorySelection)) {
                    return;
                }

                this.props.formParams.setSelectedType(value);
            },
        });

        this.setUpdateSuccessCallback(() => {
            this.props.formParams.setSelectedType(this.props.formValues[this.fieldKey]);
        });
    }

    prepareDataForSubmit(data) {
        return {...data, id: this.props.formParams.id};
    }

    componentDidUpdate() {
        if (!this.state.formSet && 0 < _get(this.props, 'territorySelectionOptions', []).length) {
            this.setField('territory_selection_type', {
                options: this.props.territorySelectionOptions,
                defaultValue: this.props.territorySelection,
            });

            this.setState(() => ({
                formSet: true,
            }));
        }

    }

    renderSaveButton(props = {}) {
        return super.renderSaveButton({...props, 'content': 'Save'});
    }

    renderCancelButton = () => null;
    renderDeleteButton = () => null;

    customAuthorization = (userResourcePrivilages) => (
        (userResourcePrivilages & SECURITY_PRIVILEGES_UPDATE) > 0
    )
}

TerritorySelectionForm.propTypes = {
    formParams: PropTypes.object,
    territorySelectionOptions: PropTypes.array,
    territorySelection: PropTypes.string,
};

export default TerritorySelectionForm;
