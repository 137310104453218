import {BasicData} from "../fragments";

export const akamaiRtmpDistrRegionsFilter = `
    akamaiRtmpDistrRegions {
        id
        value: id
        text : name
    }
`;

export const contentFilter = `
    eventContents(filters: {event: $eventId}) {
        id
        name
        event_content_type {
            name
        }
        event_content_variant {
            ${BasicData}
            short_name
        }
    }
`;

export const deviceCategoriesFilter = `
    deviceCategories {
        id
        value: id
        text : name
    }
`;

export const deviceCategoriesOfProductFilter = `
    deviceCategories(filters: {product: $productId}) {
        id
        value: id
        text : name
    }
`;

export const distributionTypesFilter = `
    distributionTypes {
        id
        value: id
        text : name
    }
`;

export const productsFilter = `
    products {
        id
        value: id
        text : short_name    
    }
`;

export const streamDelayTypesFilter = `
    streamDelayTypes {
        id
        value: id
        text : name
    }
`;

export const streamFormatsFilter = `
    streamFormats {
        id
        value: id
        text : name
    }
`;

export const streamProtocolsFilter = `
    streamProtocols {
        id
        value: id
        text : name
    }
`;

export const streamStatusesFilter = `
    streamStatuses {
        id
        value: id
        text: name
    }
`;

export const eventContentTypesFilter = `
    eventContentTypes {
        id
        value: id
        text: name
    }
`;

export const eventContentVariantsFilter = `
    eventContentVariants {
        id
        value: id
        text: name
    }
`;
