import React from 'react';
import {Label, Popup} from 'semantic-ui-react';

export const columnObjectAlignCenter = {
    header: {
        align: 'center',
    },
    content: {
        align: 'center',
    },
};

export const columnObjectAlignLeft = {
    header: {
        align: 'left',
    },
    content: {
        align: 'left',
    },
};

export const renderNameOfRowColumn = (row, column) => (
    (!row || undefined === row[column] || null === row[column])
        ? ''
        : row[column].name || ''
);

export const renderProductColumnForComputerVision = (productName, isComputerVision) => (
    <>
        {productName}
        {isComputerVision
            ? (
                <Popup
                    content='Computer Vision'
                    position='top center'
                    trigger={<Label size='mini' className='td__label'>CV</Label>}
                />
            )
            : null
        }
    </>
);

export const getDisabledClassNameForCell = (tableRowData) => {
    return tableRowData.disabled || tableRowData.is_disabled ? '--is-disabled' : null;
};
