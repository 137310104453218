import PropTypes from 'prop-types';

import {convertToInt} from './helpers';
import {isEmpty as _isEmpty} from "lodash";

/**
 * Generate tree for "usage types"
 *
 * @param {Object[]} products Main object with products configuration
 * @param {int[]} productsSelected Selected "usage"/"products" to display or not product in "usage types" tree
 * @param {Map<int, int[]>} usageTypesSelectedMap Map with structure for "usage types"
 * @param {Map<int, Map<int, int>>} treeSavedUsageTypesMap Saved rights_scope_usage_type from database (edit mode) to populate
 *
 * @returns {Object[]} Tree structure
 */
export const generateUsageTypesTree = (products, productsSelected, usageTypesSelectedMap, treeSavedUsageTypesMap) => {
    return generateTreeData(products, productsSelected, usageTypesSelectedMap, treeSavedUsageTypesMap,'usage_type', true);
};

export const generateContentVariantsTree = (products, productsSelected, contentVariantsSelectedMap, treeSavedContentVariantsMap) => {
    return generateTreeData(products, productsSelected, contentVariantsSelectedMap, treeSavedContentVariantsMap, 'product_event_content_variants');
};

const generateTreeData = (products, productsSelected, selectedMap, treeSavedMap, type, filterByUsageTypeSupport = false) => {
    const treeData = [];

    products.forEach((product) => {
        /**
         * products which have support for usage types
         * !!! and which have at least one usage selected !!!
         */
        const productId = convertToInt(product.id),
            /**
             * Products for event usage types
             * Usage types (products level structure) for rights scopes
             */
            isProductLevelSelected = isProductSelected(productId, productsSelected);

        if (filterByUsageTypeSupport ? (product.has_usage_type_support && isProductLevelSelected) : isProductLevelSelected && !_isEmpty(product.product_event_content_variants)) {
            treeData.push({
                value: product.id,
                label: product.short_name,
                name: 'product',
                children: generateSecondLevelTree(product, selectedMap, treeSavedMap, {type}),
            });
        }
    });

    return treeData;
};

generateTreeData.propTypes = {
    products: PropTypes.object.isRequired,
    usagesSelected: PropTypes.array.isRequired,
    selectedMap:  PropTypes.instanceOf(Map).isRequired,
    treeSavedMap: PropTypes.instanceOf(Map).isRequired,
};

const getSavedItemsId = (productId, itemId, treeSavedMap) => {

    const productMap = treeSavedMap.get(productId);

    if (undefined === productMap) {
        return null;
    }

    const savedItem = productMap.get(itemId);

    if (undefined !== savedItem) {
        return savedItem;
    }

    return null;
};

getSavedItemsId.propTypes = {
    productId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    treeSavedMap: PropTypes.instanceOf(Map).isRequired,
};

const generateSecondLevelTree = (product, selectedMap, treeSavedMap, type) => {
    const secondLevel = [],
        productId = convertToInt(product.id);

    const items = 'usage_type' === type.type ? product.usage_types : product.product_event_content_variants;
    const getId = 'usage_type' === type.type
        ? usageType => convertToInt(usageType.id) : contentVariant => convertToInt(contentVariant.event_content_variant.id);
    const getName = 'usage_type' === type.type ? 'usage_type' : 'product_event_content_variants';

    items.forEach((item) => {
        const itemId = getId(item);
        secondLevel.push({
            id: getSavedItemsId(productId, itemId, treeSavedMap),
            value: item.id ? item.id : item.event_content_variant.id,
            label: item.name ? item.name : item.event_content_variant.name,
            name: getName,
            selected: isProductItemsSelected2ndLevel(productId, itemId, selectedMap),
        });
    });

    return secondLevel;
};

generateSecondLevelTree.propTypes = {
    product: PropTypes.object.isRequired,
    treeSavedMap: PropTypes.instanceOf(Map).isRequired,
};

/**
 * Is there selected "usage type" for product
 *
 * @param {int} productId
 * @param {int[]} usagesSelected
 *
 * @returns {boolean}
 */
const isProductSelected = (productId, usagesSelected) => (
    usagesSelected.includes(productId)
);

isProductSelected.propTypes = {
    productId: PropTypes.number.isRequired,
    usagesSelected: PropTypes.array.isRequired,
};

const isProductItemsSelected2ndLevel = (productId, itemsId, selectedMap) => {
    if (!selectedMap.has(productId)) {
        return false;
    }

    return selectedMap.get(productId).includes(itemsId);
};

isProductItemsSelected2ndLevel.propTypes = {
    productId: PropTypes.number.isRequired,
    itemsId: PropTypes.number.isRequired,
    selectedMap: PropTypes.instanceOf(Map).isRequired,
};
