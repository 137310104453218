import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import {routes} from '@constants/routes';

import OverlayIndex from '../views/OverlayIndex';
import OverlayEdit from '../views/OverlayEdit';

const OverlayLayout = (props) => {
    const {match, technicalSetupId} = props;
    let overlayLayoutRoutes = routes.propertyLicences.technicalSetup.overlays;

    return (match.path === overlayLayoutRoutes.add.path || match.path === overlayLayoutRoutes.edit.path)
        ? <OverlayEdit technicalSetupId={technicalSetupId}/>
        : <OverlayIndex technicalSetupId={technicalSetupId}/>;
};

OverlayLayout.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }),
    technicalSetupId: PropTypes.number.isRequired,
};

export default withRouter(OverlayLayout);
