import React from 'react';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    Icon as SemanticIcon,
    Popup,
    IconGroup as SemanticIconGroup,
} from 'semantic-ui-react';

import {SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';

import {getUserAuthorizationData} from './Authorization';
import {FormChangelog} from './FormChangelog';

export const Icon = (props) => {
    const {link, onClick, onClickElement, ...iconProps} = props;

    let handleClick = null;

    if ('function' === typeof (onClick)) {
        handleClick = (event) => {
            event.stopPropagation();
            onClick(onClickElement);
        };
    }

    let icon = <SemanticIcon onClick={handleClick} {...iconProps} />;

    if (props.children && 0 < props.children.length) {
        icon = <SemanticIconGroup onClick={handleClick} {...iconProps} />;
    }

    return link
        ? <Link to={link}>{icon}</Link>
        : icon;
};
Icon.propTypes = {
    name: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
    onClickElement: PropTypes.any,
    children: PropTypes.node,
};
Icon.displayName = 'Icon';

export const IconDelete = (props) => (<Icon {...props} name='trash' />);
IconDelete.displayName = 'IconDelete';

export const IconEdit = (props) => {
    let IconName = 'eye';
    const userAuthorizationData = getUserAuthorizationData(),
        authorizationResourceBitsComparison = (userAuthorizationData[props.resource] & SECURITY_PRIVILEGES_UPDATE);

    if (!props.resource || (0 < authorizationResourceBitsComparison)) {
        IconName = props.editIconName || 'edit';
    }

    return <Icon {...props} name={IconName} />;
};
IconEdit.displayName = 'IconEdit';
IconEdit.propTypes = {
    resource: PropTypes.number,
    editIconName: PropTypes.string,
};

export const IconSettings = (props) => <Icon {...props} name='setting' />;
IconSettings.displayName = 'IconSettings';

export const IconSettingsAdd = (props) => (
    <Icon {...props}>
        <Icon name='setting'/>
        <Icon corner='top right' name='plus' className='--greyShadow'/>
    </Icon>
);
IconSettingsAdd.displayName = 'IconSettingsAdd';

export const IconListPlay = (props) => (<Icon {...props}>
    <Icon name='list'/>
    <Icon corner='top right' name='play' className='--noShadow --large'/>
</Icon>);
IconListPlay.displayName = 'IconListPlay';

export const IconSearch = (props) => <Icon {...props} name='search' />;
IconSearch.displayName = 'IconSearch';

export const IconCheckmark = (props) => <Icon {...props} name='checkmark' />;
IconCheckmark.displayName = 'IconCheckmark';

export const IconAngleRight = (props) => <Icon {...props} name='angle right' />;
IconAngleRight.displayName = 'IconAngleRight';

export const IconUsers = (props) => <Icon {...props} name='users' />;
IconUsers.displayName = 'IconUsers';

export const IconVideoCamera = (props) => <Icon {...props} name='video camera' />;
IconVideoCamera.displayName = 'IconVideoCamera';

export const IconVideoFile = (props) => <Icon {...props} name='video file' />;
IconVideoFile.displayName = 'IconVideoFile';

export const IconQuestionCircle = (props) => <Icon {...props} name='question circle' />;
IconQuestionCircle.displayName = 'IconQuestionCircle';

export const IconQuestionCircleOutline = (props) => <Icon {...props} name='question circle outline' />;
IconQuestionCircleOutline.displayName = 'IconQuestionCircleOutline';

export const IconBookmarkOutline = (props) => <Icon {...props} name='bookmark outline' />;
IconBookmarkOutline.displayName = 'IconBookmarkOutline';

export const IconLinkify = (props) => <Icon {...props} name='linkify' />;
IconLinkify.displayName = 'IconLinkify';

export const IconAdd = (props) => {
    let IconName = '';
    const userAuthorizationData = getUserAuthorizationData(),
        authorizationResourceBitsComparison = (userAuthorizationData[props.resource] & SECURITY_PRIVILEGES_CREATE);

    if (!props.resource || (0 < authorizationResourceBitsComparison)) {
        IconName = props.editIconName || 'add';
    }

    return <Icon {...props} name={IconName}/>;
};
IconAdd.displayName = 'IconAdd';
IconAdd.propTypes = {
    resource: PropTypes.number,
    editIconName: PropTypes.string,
};

export const IconDownload = (props) => <Icon {...props} name='download' />;
IconDownload.displayName = 'IconDownload';

export const IconCheckCircle = (props) => <Icon {...props} name='check circle' />;
IconCheckCircle.displayName = 'IconCheckCircle';

export const IconBan = (props) => <Icon {...props} name='ban' />;
IconBan.displayName = 'IconBan';

export const IconExclamationCircle = (props) => <Icon {...props} name='exclamation circle' />;
IconExclamationCircle.displayName = 'IconExclamationCircle';

export const IconX = (props) => <Icon {...props} name='delete' />;
IconX.displayName = 'IconX';

export const IconGlobe = (props) => <Icon {...props} name='globe' className={'--marginLeft-0-3-rem'}/>;
IconGlobe.displayName = 'IconGlobe';

export const IconGlobeAdd = (props) => <Icon {...props} name='globe' className={'--marginLeft-0-3-rem'}>
    <Icon name='globe'/>
    <Icon corner='top right' name='plus' className='--greyShadow'/>
</Icon>;
IconGlobeAdd.displayName = 'IconGlobeAdd';

export const IconShield = (props) => <Icon {...props} name='shield' />;
IconShield.displayName = 'IconShield';

export const IconCodeBranch = (props) => <Icon {...props} name='code branch' />;
IconCodeBranch.displayName = 'IconCodeBranch';

export const IconWithTooltip = (props) => {
    const {iconProps, name, onClick, onClickElement, popupProps, tooltip, resource} = props,
        handleClick = (event) => {
            event.stopPropagation();

            if ('function' === typeof (onClick)) {
                onClick(event, onClickElement);
            }
        };

    let IconName = name;
    const userAuthorizationData = getUserAuthorizationData(),
        authorizationResourceBitsComparison = (userAuthorizationData[resource] & SECURITY_PRIVILEGES_UPDATE);

    if (resource && (0 >= authorizationResourceBitsComparison)) {
        IconName = 'eye';
    }

    return  <Popup
        trigger={<SemanticIcon name={IconName} onClick={handleClick} {...iconProps}/>}
        content={tooltip}
        {...popupProps}
    />;
};

IconWithTooltip.propTypes = {
    iconProps: PropTypes.object,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onClickElement: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    popupProps: PropTypes.object,
    resource: PropTypes.number,
    tooltip: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
};
IconWithTooltip.defaultProps = {
    iconProps: {},
    onClick: () => false,
    popupProps: {},
    tooltip: {},
};

/**
 * Icon with "?" and message in popup
 * props.title - to display message
 */
export const IconHint = (props) => {
    return (
        <IconWithTooltip
            name='question circle'
            popupProps={{wide: 'very'}}
            tooltip={props.tooltip}
        />
    );
};

IconHint.propTypes = {
    tooltip: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]).isRequired,
};

export const IconInfoCircleChangelog = (props) => {
    let tooltip = <FormChangelog
        resources={props.resources}
        data={props.data}
    />;

    if (!_get(props, 'data.creation_datetime', null)
        && !_get(props, 'data.update_datetime', null)
    ) {
        tooltip = <div>No details available</div>;
    }

    return <IconInfoCircle
        {...props}
        iconProps = {{
            className: 'icon--default-cursor',
            ...props.iconProps,
        }}
        tooltip={tooltip}
    />;
};

IconInfoCircleChangelog.propTypes = {
    resources: PropTypes.number,
    tooltipProps: PropTypes.object,
    data: PropTypes.object,
    iconProps: PropTypes.object,
};

export const IconInfoCircle = (props) => {
    const tooltipProps = _get(props, 'tooltipProps', {}),
        iconProps = _get(props, 'iconProps', {});

    return <IconWithTooltip
        name='info circle'
        popupProps={{
            wide: 'very',
            header: props.header,
            hoverable: props.hoverable,
            ...tooltipProps,
        }}
        tooltip={props.tooltip}
        iconProps={{
            className: props.className,
            ...iconProps,
        }}
    />;
};

IconInfoCircle.propTypes = {
    tooltip: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]).isRequired,
    className: PropTypes.string,
    header: PropTypes.string,
    hoverable: PropTypes.bool,
    tooltipProps: PropTypes.object,
    iconProps: PropTypes.object,
};

IconInfoCircle.defaultProps = {
    className: '',
    header: '',
    hoverable: false,
};

export const IconInfoCircleWithoutTooltip = (props) => <Icon {...props} name='info circle' />;
IconInfoCircleWithoutTooltip.displayName = 'IconInfoCircleWithoutTooltip';
