import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import {IconWithTooltip} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import mapModulesToProps from '@utils/mapModulesToProps';

import {canDisplayModal, displayModal} from '../../utils/usersTable/action';

const UsersTableActionIcon = ({
    Modal,
    username,
    email,
    history,
}) => {
    const gotoCreateUser = React.useCallback(() => {
        history.push(getLink('users.add_user_from_existing_iam_account', {email: email}));
    }, [email]);

    const handleClick = React.useCallback(() => {
        if (canDisplayModal(email)) {
            displayModal(username, email, gotoCreateUser, Modal);

            return;
        }

        gotoCreateUser();
    }, [username, email]);

    return (
        <IconWithTooltip
            name='plus'
            onClick={handleClick}
            iconProps={{
                'className': '--withPointer',
            }}
            popupProps={{
                position: 'top right',
            }}
            tooltip={'Add new user from this Common-IAM user'}
        />
    );
};

UsersTableActionIcon.propTypes = {
    Modal: PropTypes.shape({
        setModalConfirmation: PropTypes.func.isRequired,
    }).isRequired,
    iamUserId: PropTypes.string.isRequired,
    username: PropTypes.string,
    email: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapDispatchToProps = mapModulesToProps(['Modal']);

export default withRouter(connect(null, mapDispatchToProps)(UsersTableActionIcon));
