import PropTypes from "prop-types";
import {get as _get} from "lodash";

/**
 * Get stadium name
 * @param match
 * @returns {string}
 */
export const getStadiumNameFromMatch = (match) => {
    let stadium = '-';

    if (match.stadium) {
        stadium = match.stadium.name || '-';
    }

    return stadium;
};

getStadiumNameFromMatch.propTypes = {
    match: {
        stadium: PropTypes.shape({
            name: PropTypes.string
        })
    }
};

getStadiumNameFromMatch.defaultProps = {
    match: {
        stadium: {
            name: '-'
        }
    }
};

export const getStadiumOrVenueNameFromEvent = (event) => (
    _get(
        event,
        'sr_event.match.stadium.name',
        _get(event, 'sr_event.stadium.name')
    ) || _get(event, 'av_event.venue.name', "")
);

getStadiumOrVenueNameFromEvent.propTypes = {
    event: PropTypes.object
};
