import PropTypes from "prop-types";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'sdi_origin_entry_points',
    parseData: sdiOriginEntryPoint => [
        sdiOriginEntryPoint.id,
        sdiOriginEntryPoint.name,
        sdiOriginEntryPoint.description || '',
        sdiOriginEntryPoint.encoding_datacenter.name,
        sdiOriginEntryPoint.input_id,
    ],
    path: 'sdiOriginEntryPoints.index.path',
    titles: [
        "ID",
        "Name",
        "Description",
        "Encoding datacenter",
        "Input ID",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
