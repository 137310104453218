import React from "react";
import PropTypes from "prop-types";

import Table from "../../app/components/Table";

import {rowRenderer} from "../utils/ContentCategoryTableRowRenderer";

import {columns} from "../constants/ContentCategoryTableColumns";

export class ContentCategoryTable extends React.Component {
    renderContent() {
        if (this.props.loading === false
            && (this.props.data === undefined || this.props.data === null || this.props.data.length === 0)
        ) {
            return "No categories found."
        }

        let rowClass = (this.props.level !== 3) ? "clickable" : "";

        return (
            <Table
                selected={this.props.selected}
                className={`content-categories no-padding ${rowClass}`}
                name={`contentCategoriesLevel${this.props.level}List`}
                columns={columns}
                data={this.props.data}
                pagination={false}
                loading={this.props.loading}
                rowRenderer={rowRenderer}
                rowRendererProps={this.props.rowRendererProps}
                rowOnClick={this.props.rowOnClick}
                rowClassname={tableRowData => this.props.selected[0] === tableRowData.id ? "--is-selected" : ""}
            />)
    }

    render() {
        return (
            <div>
                {this.renderContent()}
            </div>
        )
    }
}

ContentCategoryTable.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    rowRendererProps: PropTypes.object,
    rowOnClick: PropTypes.func,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    selected: PropTypes.array
};

export default ContentCategoryTable;
