import {EVENT_CONTENT_VARIANT_STANDARD_FEED} from '@constants/variables';

import {convertToInt} from '@utils/helpers';


export const getEventContentText = (eventContent, renderWithContentId = false) => {
    let eventContentId = '';

    if (renderWithContentId) {
        eventContentId = `(${eventContent.id })`;
    }

    if (EVENT_CONTENT_VARIANT_STANDARD_FEED !== convertToInt(eventContent.event_content_variant.id)) {
        return eventContent.name
            ? `${eventContent.event_content_type.name} - ${eventContent.name} (${eventContent.event_content_variant.short_name}) ${eventContentId}`
            : `${eventContent.event_content_type.name} (${eventContent.event_content_variant.short_name}) ${eventContentId}`;
    }

    return eventContent.name
        ? `${eventContent.event_content_type.name} - ${eventContent.name} ${eventContentId}`
        : `${eventContent.event_content_type.name} ${eventContentId}`;
};

export const getEventContentVariantSteppedText = (eventContent, distribution) => {
    const eventContentName = eventContent.name
        ? `${eventContent.event_content_type.name} - ${eventContent.name}`
        : `${eventContent.event_content_type.name}`;

    const productDistribution = `> ${distribution.product.short_name} > ${distribution.distribution_type.name}`;

    if (EVENT_CONTENT_VARIANT_STANDARD_FEED !== convertToInt(eventContent.event_content_variant.id)){
        return `${eventContentName} (${eventContent.event_content_variant.short_name}) ${productDistribution}`;
    }

    return `${eventContentName} ${productDistribution}`;

};

export const getEventContentNameWithTypeName = (eventContent) => {
    return eventContent.event_content_type.name + (eventContent.name ? ` - ${eventContent.name}` : '');
};
