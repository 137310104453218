import {GetEventGeoRestrictionForForm} from '@graphql/eventGeoRestriction/query';
import {
    UpdateEventGeoRestriction,
    DeleteEventGeoRestriction,
} from '@graphql/eventGeoRestriction/mutation';

import GeoRestrictionsFormModel from './GeoRestrictionsFormModel';

export default function GeoRestrictionsFormEditModel() {
    const geoRestrictionsFormModel = GeoRestrictionsFormModel();

    geoRestrictionsFormModel.editForm = true;
    geoRestrictionsFormModel.deleteId = 'eventGeoRestriction.id';
    geoRestrictionsFormModel.mutation = {
        updateEntity: UpdateEventGeoRestriction,
        deleteEntity: DeleteEventGeoRestriction,
    };
    geoRestrictionsFormModel.query.getEntity = GetEventGeoRestrictionForForm;

    return geoRestrictionsFormModel;
}
