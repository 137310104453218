import React from "react";
import PropTypes from "prop-types";

import {ButtonBackToList} from "../../app/components/ButtonCollection";
import {routes} from "../../app/constants/routes";

import {renderModalError} from "../../../utils/forms";

export const RenderBackToListButton = React.memo(() => {
    return <ButtonBackToList name={'products.index'} />;
});
RenderBackToListButton.displayName = 'RenderBackToListButton';

export const renderHeaderTitle = (productName = '') => {
    if (productName) {
        return `Product "${productName}"`;
    }

    return 'Product';
};
renderHeaderTitle.propTypes = {
    productName: PropTypes.string,
};
renderHeaderTitle.displayName = 'renderHeaderTitle';

export const renderProductModalError = () => {
    return renderModalError('Product', routes.products.index.path)
};
renderModalError.displayName = 'renderProductModalError';
