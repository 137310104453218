import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import {prepareStringForCidrSorting} from '@utils/CidrHelpers';

import {columns, rowRenderer} from '../utils/clientAvapiWhitelistingTable';

const ClientAvapiWhitelistingIpTable = ({clientId, loading, clientAvapiWhitelistedIps = [], setMutationSuccessCallback}) => {
    return (
        <Table
            columns={columns}
            data={clientAvapiWhitelistedIps}
            defaultSort={prepareStringForCidrSorting}
            loading={loading}
            name='clientAvapiWhitelistingIpList'
            noDataText='No CIDRs found'
            pagination={false}
            rowRenderer={rowRenderer}
            rowRendererProps={{clientId: clientId, setMutationSuccessCallback: setMutationSuccessCallback}}
        />
    );
};

ClientAvapiWhitelistingIpTable.displayName = 'ClientAvapiWhitelistingIpTable';

ClientAvapiWhitelistingIpTable.propTypes = {
    clientId : PropTypes.number.isRequired,
    loading: PropTypes.bool,
    clientAvapiWhitelistedIps: PropTypes.array,
    setMutationSuccessCallback: PropTypes.func,
};

export default ClientAvapiWhitelistingIpTable;
