import React from 'react';
import PropTypes from "prop-types";
import {Dropdown} from "semantic-ui-react";
import {has as _has} from "lodash";

import {isSportradarEventAndNoMaster} from "../../../../utils/eventType";

import {SCOUT_MANAGEMENT_PLATFORM_MATCH_DETAILS_LINK} from "../../../../../app/constants/variables";

/**
 * Open the match in Scout Management Platform (SMP)
 */
class MatchInScoutManagementPlatform extends React.Component {
    /**
     * Open platform in new tab
     */
    openScoutManagementPlatform = () => {
        const openURL = `${SCOUT_MANAGEMENT_PLATFORM_MATCH_DETAILS_LINK}${this.props.event.sr_event.match.id}`;

        window.open(openURL, '_blank');
    };

    render() {
        /**
         * This option is available only if the event is a Sportradar event, not master
         * and
         * with sr_event.match_id is not NULL
         */
        if (!isSportradarEventAndNoMaster(this.props.event) || !_has(this.props.event, "sr_event.match.id")) {
            return null;
        }

        return (
            <Dropdown.Item
                icon="external alternate"
                key={`openScoutManagementPlatform`}
                onClick={this.openScoutManagementPlatform}
                text="Open the match in Scout Management Platform (SMP)"
            />
        )
    }
}

MatchInScoutManagementPlatform.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string,
            })
        })
    })
};

export default MatchInScoutManagementPlatform;
