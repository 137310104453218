import React from 'react';
import {get as _get} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {IconWithTooltip} from '@appComponents/IconCollection';
import {CLIENT, CLIENT_PACKAGE, EVENT_BOOKING} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {getLink} from '@appComponents/Link';
import {LinkWithAuthorization} from '@appComponents/LinkWithAuthorization';
import EventBookingCancellationIcon from '@modules/booking/views/BookingCancellationIcon';
import {ToggleIsCancelledEventBooking} from '@graphql/eventBooking/mutation';
import Authorization from '@appComponents/Authorization';

export const getClientName = (row) => {
    return (
        <>
            <LinkWithAuthorization
                authorization={{
                    [CLIENT]: SECURITY_PRIVILEGES_READ,
                }}
                link={{
                    name: 'clients.edit',
                    params: {
                        id: convertToInt(_get(row, 'client.id', null)),
                    },
                }}
            >
                {_get(row, 'client.name', '')}
            </LinkWithAuthorization>
        </>
    );
};

export const getClientPackageName = (row) => {
    return (
        <>
            <LinkWithAuthorization
                authorization={{
                    [CLIENT_PACKAGE]: SECURITY_PRIVILEGES_READ,
                }}
                link={{
                    name: 'clients.packages.edit',
                    params: {
                        id: convertToInt(_get(row, 'client.id', null)),
                        packagesId: convertToInt(_get(row, 'client_package.id', null)),
                    },
                }}
            >
                {_get(row, 'client_package.name', '-')}
            </LinkWithAuthorization>
        </>
    );
};

export const getActions = (row, history, setMutationSuccessCallback) => {
    const eventId = convertToInt(_get(row, 'event.id', null));
    const eventBookingId = convertToInt(_get(row, 'id', null));
    const productId = convertToInt(_get(row, 'product.id', null));

    return (
        <Authorization
            resources={EVENT_BOOKING}
            privileges={SECURITY_PRIVILEGES_UPDATE}
        >
            <div className='actions-list-icon'>
                <IconWithTooltip
                    iconProps={{
                        size: 'large',
                        className: '--withPointer',
                        corner: 'top right',
                    }}
                    name='edit'
                    onClick={() => {
                        history.push(getLink('events.eventBookings.edit', {
                            id: eventId,
                            eventBookingId: eventBookingId
                        }));
                    }}
                    tooltip={'General information'}
                    popupProps={{position: 'top right'}}
                />
                <IconWithTooltip
                    iconProps={{
                        size: 'large',
                        className: '--withPointer',
                        corner: 'top right',
                    }}
                    name='video file'
                    onClick={() => {
                        history.push(getLink('events.eventBookings.clips', {
                            id: eventId,
                            eventBookingId: eventBookingId,
                            productId: productId
                        }));
                    }}
                    tooltip={'Clips'}
                    popupProps={{position: 'top right'}}
                />
                <IconWithTooltip
                    iconProps={{
                        size: 'large',
                        className: '--withPointer',
                        corner: 'top right',
                    }}
                    name='globe'
                    onClick={() => {
                        history.push(getLink('events.eventBookings.editGeoRestrictions', {
                            id: eventId,
                            eventBookingId: eventBookingId,
                            productId: productId
                        }));
                    }}
                    tooltip={'Geo restrictions'}
                    popupProps={{position: 'top right'}}
                />
                <IconWithTooltip
                    iconProps={{
                        size: 'large',
                        className: '--withPointer',
                        corner: 'top right',
                    }}
                    name='shield'
                    onClick={() => {
                        history.push(getLink('events.eventBookings.blackoutZones', {
                            id: eventId,
                            eventBookingId: eventBookingId,
                            productId: productId
                        }));
                    }}
                    tooltip={'Blackout Zones'}
                    popupProps={{position: 'top right'}}
                />
                <EventBookingCancellationIcon
                    bookingId={eventBookingId}
                    isCancelled={row.is_cancelled}
                    entityName={'event booking'}
                    messageBoxName='eventMessage'
                    successCallback={() => {
                        setMutationSuccessCallback(Date.now());
                    }}
                    mutationChangeCancellationStatus={ToggleIsCancelledEventBooking}
                />
            </div>
        </Authorization>
    );
};
