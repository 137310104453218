import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(
        values.rtmp_origin_entry_point_application_type, 'rtmp_origin_entry_point_application_type')
    );

    return errors;
};

export default validate;
