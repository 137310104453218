import PropTypes from "prop-types";

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_third_party_vod_storages`,
    parseData: thirdPartyVodStorage => [
        thirdPartyVodStorage.id,
        thirdPartyVodStorage.name,
        thirdPartyVodStorage.upload_target_type.name,
        thirdPartyVodStorage.path,
        thirdPartyVodStorage.encoding_job_profile.name,
    ],
    path: 'clients.products.thirdParty.vodStorages.index.path',
    titles: [
        "ID",
        "Name",
        "Type",
        "Path",
        "Encoding job profile",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired
};
