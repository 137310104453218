import {compose} from 'redux';
import {connect} from 'react-redux';
import {Header} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import {graphql} from 'react-apollo';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {isUrlParamValid, convertToInt} from '@utils/helpers';
import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link, {getLink} from '@appComponents/Link';
import StepsContent from '@appComponents/StepsContent';
import mapModulesToProps from '@utils/mapModulesToProps';
import * as CONSTANT from '@constants/variables';
import * as RESOURCES from '@constants/resources';

import GeoRestrictionsTable from '../components/GeoRestrictionsTable';
import GeoRestrictionsForm
    from '../../client/forms/ClientPackageGeoRestrictions/GeoRestrictionsForm';
import GeoRestrictionsGeneralInformationForm
    from '../../client/forms/ClientPackageGeoRestrictions/GeoRestrictionsGeneralInformationForm';
import GeoRestrictionsEditModel
    from '../../client/forms/ClientPackageGeoRestrictions/GeoRestrictionsEditModel';
import GeoRestrictionsGeneralInformationEditModel
    from '../../client/forms/ClientPackageGeoRestrictions/GeoRestrictionsGeneralInformationEditModel';
import GeoRestrictionsGeneralInformationModel
    from '../../client/forms/ClientPackageGeoRestrictions/GeoRestrictionsGeneralInformationModel';
import {GetClientPackageGeoRestrictionsForTable} from '../../../graphql/clientPackageGeoRestriction/query.graphql';

export class GeoRestrictionsIndex extends React.PureComponent {
    static propTypes = {
        clientId: PropTypes.number.isRequired,
        clientPackage: PropTypes.shape({
            client_product: PropTypes.shape({
                id: PropTypes.string.isRequired
            }),
        }),
        clientPackageId: PropTypes.number.isRequired,
        DataGeoRestrictions: PropTypes.shape({
            clientPackageGeoRestrictions: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool,
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                geoRestrictionId: PropTypes.string,
                id: PropTypes.string,
                packagesId: PropTypes.string,
            }),
            path: PropTypes.string.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        DataGeoRestrictions: {
            clientPackageGeoRestrictions: [],
            loading: false,
        },
        match: {
            params: {
                geoRestrictionId: null,
                restrictionId: null,
                id: null,
            }
        }
    };

    state = {
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.clients.packages.geoRestrictions.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        return {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        }
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        }

        this.loadModal(this.props.match.path);
    }

    componentDidUpdate() {
        if (this.state.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (this.state.modalWasClosed) {
            this.props.history.push(
                getLink("clients.packages.geoRestrictions.index", {
                    id: this.props.clientId,
                    packagesId: this.props.clientPackageId
                })
            );
        }
    }

    loadModal(path, params = {}) {
        const clientPackageGeoRestrictionsPath = routes.clients.packages.geoRestrictions;
        let form = GeoRestrictionsGeneralInformationForm,
            formParams = {};

        if (path === clientPackageGeoRestrictionsPath.add.path) {
            formParams = Object.assign({}, {
                optionsVariables: {
                    client_package: convertToInt(this.props.match.params.packagesId),
                    client_product: convertToInt(this.props.clientPackage.client_product.id),
                }
            });

            return this.loadAddModalForm(
                createForm(GeoRestrictionsGeneralInformationModel, form, formParams, formParams)
            );
        } else if (path === clientPackageGeoRestrictionsPath.edit.path
            || path === clientPackageGeoRestrictionsPath.editGeoRestrictions.path) {

            formParams = Object.assign({}, {
                id: convertToInt(this.props.match.params.geoRestrictionId) || convertToInt(params.geoRestrictionId),
                optionsVariables: {
                    client: this.props.clientId,
                    client_package: convertToInt(this.props.match.params.packagesId),
                    client_product: convertToInt(this.props.clientPackage.client_product.id),
                }
            });

            return this.loadEditModalForm(
                {
                    tabs: [
                        {
                            key: "step1",
                            menuItem: {content: "General information", disabled: false },
                            pane: {
                                content: createForm(
                                    GeoRestrictionsGeneralInformationEditModel,
                                    form,
                                    formParams
                                )
                            },
                            url: getLink('clients.packages.geoRestrictions.edit', {
                                id: this.props.clientId,
                                packagesId: this.props.clientPackageId,
                                geoRestrictionId: convertToInt(this.props.match.params.geoRestrictionId) || convertToInt(params.geoRestrictionId)
                            })
                        },
                        {
                            key: "step2",
                            menuItem: {content: "Geo restrictions", disabled: false },
                            pane: {
                                content: createForm(
                                    GeoRestrictionsEditModel,
                                    GeoRestrictionsForm,
                                    formParams
                                )
                            },
                            url: getLink('clients.packages.geoRestrictions.editGeoRestrictions', {
                                id: this.props.clientId,
                                packagesId: this.props.clientPackageId,
                                geoRestrictionId: convertToInt(this.props.match.params.geoRestrictionId) || convertToInt(params.geoRestrictionId)
                            })
                        }
                    ],
                },
                formParams
            );
        }
    }

    loadAddModalForm = (Form) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={"Add new geo restriction"}
                isAddMode={true}
                tabs={[
                    {key: "step1", menuItem: {content: "General information", disabled: false }, pane: { content: Form}},
                    {key: "step2", menuItem: {content: "Geo restrictions", disabled: true }, pane: {content: null}}
                ]}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    loadEditModalForm = (Forms) => {
        if (!isUrlParamValid(this.props.match.params.geoRestrictionId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Client package geo restrictions',
                    getLink('clients.packages.geoRestrictions.index',
                        {
                            id: this.props.clientId,
                            packagesId: this.props.clientPackageId,
                        })
                ),
            });
        }

        this.props.Modal.setModal({
            content: <StepsContent
                title={"Edit geo restriction"}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    render() {
        return (
            <div>
                <Header>
                    <span className="__tableNote">
                        The geo restrictions defined here will overwrite the geo restrictions defined on product level.
                    </span>
                    <div className="indexButtons">
                        <Authorization
                            privileges={CONSTANT.SECURITY_PRIVILEGES_CREATE}
                            resources={RESOURCES.CLIENT_PACKAGE_GEO_RESTRICTION}
                        >
                            <Link name={'clients.packages.geoRestrictions.add'}
                                params={{
                                    id: this.props.clientId,
                                    packagesId: this.props.clientPackageId,
                                }}
                            >
                                <ButtonAdd>{'Add new geo restriction'}</ButtonAdd>
                            </Link>
                        </Authorization>
                    </div>
                </Header>
                <GeoRestrictionsTable
                    clientId={this.props.clientId}
                    clientPackageId={this.props.clientPackageId}
                    geoRestrictions={this.props.DataGeoRestrictions.clientPackageGeoRestrictions}
                    loading={this.props.DataGeoRestrictions.loading}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientPackage: state.app.entities.clientPackage,
        modal: state.modal,
    };
};

const ClientPackageGeoRestrictionsWithGraphQL = compose(
    graphql(GetClientPackageGeoRestrictionsForTable, {
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    client_package: props.clientPackageId
                },
            }
        },
        name: "DataGeoRestrictions"
    }),
)(GeoRestrictionsIndex);

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(ClientPackageGeoRestrictionsWithGraphQL));
