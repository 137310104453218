import PropTypes from "prop-types";
import React from "react";
import {Header} from "semantic-ui-react";

import {EVENT_CONTENT_BOOKED_BY_CLIENT, EVENT_CONTENT_OFFERED_BY_CLIENT} from "../../app/constants/variables";

import Form from "../../app/components/ReduxFormControls";
import EventContentAlertClientsTable from "../../eventContentAlert/components/EventContentAlertClientsTable";

import * as MESSAGES from "../../app/constants/messages";

import {notificationRecipientsOptions} from "../../../graphql/notifications/query.graphql";

import {validatorTypes} from "../../../utils/validators";

export default function NotificationModel() {
    const renderHeader = (props) => (<Header>{props.text}</Header>);
    renderHeader.propTypes = {
        text: PropTypes.string
    };
    renderHeader.defaultProps = {
        text: null
    };

    const renderRecipients = (props) => (<EventContentAlertClientsTable {...props} clients={props.options}/>);
    renderRecipients.propTypes = {
        options: PropTypes.array
    };

    return {
        title: () => null,
        messages: {
            boxName: {
                success: "formInnerMessage",
                error: "formInnerErrorMessage"
            },
            text: {
                ...MESSAGES,
                SAVED: () => "The notification has been sent to the clients successfully.",
                NOT_SAVED: () => "The alert was not sent to the clients.",
                FORM_ERROR: () => "The notification could not be sent. Please correct the highlighted fields below."
            }
        },
        fields: {
            notification_header: {
                props: {
                    component: renderHeader,
                    name: "notification_header",
                    text: "New notification"
                }
            },
            description: {
                props: {
                    label: "Description",
                    component: Form.SemanticInput,
                    name: "description",
                    required: true,
                    type: 'input'
                }
            },
            message: {
                props: {
                    component: Form.SemanticTextArea,
                    defaultValue: "Please be informed of the below live video streaming update in our booking tool.",
                    rows: 3,
                    name: "message",
                    label: "Message",
                    required: true
                }
            },
            recipients_header: {
                props: {
                    component: renderHeader,
                    name: "recipients_header",
                    text: "Recipients"
                }
            },
            recipient_type: {
                props: {
                    name: "recipient_type",
                    label: "Type",
                    component: Form.SemanticCheckboxList,
                    props: {
                        options: [
                            {
                                key: "type1",
                                value: EVENT_CONTENT_BOOKED_BY_CLIENT,
                                text: "At least one event is booked by the client"
                            },
                            {
                                key: "type2",
                                value: EVENT_CONTENT_OFFERED_BY_CLIENT,
                                text: "At least one event is offered to the client"
                            },
                        ]
                    }
                }
            },
            recipients: {
                optionsKey: "clients",
                props: {
                    name: "recipients",
                    component: renderRecipients
                }
            },
            additional_recipients: {
                props: {
                    validate: validatorTypes.multipleEmail.validate,
                    name: "additional_recipients",
                    component: Form.SemanticInput,
                    type: "multiple",
                    label: "Additional recipients",
                    addItemShortcuts: [',', ';'],
                    className: "additionalRecipients",
                    renderLabelParams: {
                        className: 'invalid',
                        condition: validatorTypes.email.validate
                    },
                }
            }
        },
        mutation: {},
        query: {
            getOptions: notificationRecipientsOptions
        },
    }
}
