import React from "react";
import {Button} from "semantic-ui-react";

import DefaultForm from "../../app/components/DefaultForm";

import {convertToInt} from "../../../utils/helpers";

import {
    CLIENT_SELECTION_TYPE_ALL,
    CLIENT_SELECTION_TYPE_BLACKLIST,
    CLIENT_SELECTION_TYPE_WHITELIST
} from "../../app/constants/variables";

export default class EventContentThirdPartyClientVodStorageForm extends DefaultForm {
    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (nextProps.GraphQLOptionsData.thirdPartyClientVodStorages) {
            this.setField("third_party_client_vod_storages", {
                options: this.prepareThirdPartyClientVodStorageOptions(
                    nextProps.GraphQLOptionsData.thirdPartyClientVodStorages,
                    nextProps.formParams
                ),
            });
        }
    }

    renderSaveButton(props) {
        const defaultProps = {
            color: "blue",
            content: "Save",
            disabled: this.props.submitting,
            icon: "save",
            loading: this.props.submitting,
            type: "submit",
        };

        return this.checkAuthorization(
            <Button
                {...defaultProps}
                {...props}
            />, this.state.defaultForm_formPrivileges);
    }

    prepareThirdPartyClientVodStorageOptions = (thirdPartyClientVodStorages, formParams) => {
        let {thirdPartyRecordingDistribution, encodingTargets} = formParams,
            filtered = [];

        const clientSelectionType = convertToInt(thirdPartyRecordingDistribution.client_selection_type.id),
            clientIds = thirdPartyRecordingDistribution.clients.map((client) => (client.id));

        if (thirdPartyRecordingDistribution) {
            filtered = thirdPartyClientVodStorages.filter((thirdPartyClientVodStorage) => (
                (
                    CLIENT_SELECTION_TYPE_ALL === clientSelectionType
                    || (
                        CLIENT_SELECTION_TYPE_WHITELIST === clientSelectionType
                        && clientIds.includes(thirdPartyClientVodStorage.client_product_third_party.client_product.client.id)
                    ) || (
                        CLIENT_SELECTION_TYPE_BLACKLIST === clientSelectionType
                        && !clientIds.includes(thirdPartyClientVodStorage.client_product_third_party.client_product.client.id)
                    )
                )
                && (
                    0 === encodingTargets.filter((encodingTarget) => (
                        encodingTarget.third_party_client_vod_storages.map((thirdPartyClientVodStorage) => (
                            thirdPartyClientVodStorage.id
                        )).includes(thirdPartyClientVodStorage.id)
                    )).length
                )
            ));
        }

        return filtered.map((thirdPartyClientVodStorage) => {
            let clientName = thirdPartyClientVodStorage.client_product_third_party.client_product.client.name,
                uploadTargetTypeName = thirdPartyClientVodStorage.upload_target_type.name,
                name = thirdPartyClientVodStorage.name;

            return {
                id: thirdPartyClientVodStorage.id,
                key: `thirdPartyClientVodStorage_${thirdPartyClientVodStorage.id}`,
                value: thirdPartyClientVodStorage.id,
                text: `${clientName} - ${uploadTargetTypeName} - ${name}`,
            };
        });
    };

    prepareDataForSubmit = (data) => {
        return Object.assign({}, {
            eventContent: this.props.formParams.optionsVariables.eventContentId,
            thirdPartyClientVodStorages: data.third_party_client_vod_storages.map((thirdPartyClientVodStorage) =>
                convertToInt(thirdPartyClientVodStorage)
            ),
        });
    };
}
