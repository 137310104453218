import React from "react";
import {withApollo} from "react-apollo";
import {withRouter} from "react-router";
import {Button} from "semantic-ui-react";

import DefaultForm from "../../app/components/DefaultForm";

import {convertToInt} from "../../../utils/helpers";

import {
    CLIENT_SELECTION_TYPE_ALL,
    CLIENT_SELECTION_TYPE_BLACKLIST,
    CLIENT_SELECTION_TYPE_WHITELIST,
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_RECORDING,
    DISTRIBUTION_TYPE_VOD
} from "../../app/constants/variables";

class EventContentCdnConfigurationForm extends DefaultForm {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        super.componentWillMount();
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (nextProps.GraphQLOptionsData.clientCdnConfigurations) {
            this.setField("cdn_configurations", {
                options: this.prepareCdnConfigurationOptions(
                    nextProps.GraphQLOptionsData.clientCdnConfigurations,
                    nextProps.formParams
                ),
            })
        }
    }

    renderSaveButton(props) {
        const defaultProps = {
            color: "blue",
            content: "Save",
            disabled: this.props.submitting,
            icon: "save",
            loading: this.props.submitting,
            type: "submit",
        };

        return this.checkAuthorization(
            <Button
                {...defaultProps}
                {...props}
            />, this.state.defaultForm_formPrivileges);
    }

    prepareCdnConfigurationOptions = (clientCdnConfigurations, formParams) => {
        let {distributions, encodingTargets} = formParams;

        let filtered = clientCdnConfigurations.filter((clientCdnConfiguration) => (
            encodingTargets.filter((encodingTarget) => (
                encodingTarget.client_cdn_configurations.map((clientCdnConfiguration) => (
                    clientCdnConfiguration.id
                )).includes(clientCdnConfiguration.id)
            )).length === 0
            && distributions.filter((distribution) => (
                parseInt(distribution.product.id, 10) === parseInt(clientCdnConfiguration.client_product.product.id, 10)
                && (
                    parseInt(distribution.distribution_type.id, 10) === DISTRIBUTION_TYPE_VOD
                    || (
                        parseInt(distribution.distribution_type.id, 10) === DISTRIBUTION_TYPE_LIVE
                        && clientCdnConfiguration.live_encoding_job_profile !== null
                    )
                    || (
                        parseInt(distribution.distribution_type.id, 10) === DISTRIBUTION_TYPE_RECORDING
                        && clientCdnConfiguration.recording_encoding_job_profile !== null
                    )
                )
            && (
                CLIENT_SELECTION_TYPE_ALL === convertToInt(distribution.client_selection_type.id)
                || (
                    CLIENT_SELECTION_TYPE_WHITELIST === convertToInt(distribution.client_selection_type.id)
                    && distribution.clients.map((client) => (client.id)).includes(clientCdnConfiguration.client_product.client.id)
                )
                || (
                    CLIENT_SELECTION_TYPE_BLACKLIST === convertToInt(distribution.client_selection_type.id)
                    && !distribution.clients.map((client) => (client.id)).includes(clientCdnConfiguration.client_product.client.id)
                )
            )
            )).length > 0
        ));

        return filtered.map((clientCdnConfiguration) => {
            let clientName = clientCdnConfiguration.client_product.client.name,
                productName = clientCdnConfiguration.client_product.product.short_name,
                name = clientCdnConfiguration.name;

            return {
                id: clientCdnConfiguration.id,
                key: `clientCdnConfiguration_${clientCdnConfiguration.id}`,
                value: clientCdnConfiguration.id,
                text: `${clientName} - ${productName} - ${name}`,
            }
        });
    };

    prepareDataForSubmit = (data) => {
        return Object.assign({}, {
            eventContent: this.props.formParams.optionsVariables.eventContentId,
            clientCdnConfigurations: data.cdn_configurations.map(cdnConfiguration => parseInt(cdnConfiguration, 10)),
        });
    };
}

export default withRouter(withApollo(EventContentCdnConfigurationForm));
