import React from "react";
import PropTypes from "prop-types"
import {withRouter} from "react-router";

import RightsScopeForm from "../forms/RightsScopeForm";
import RightsScopeModel from "../forms/RightsScopeModel";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";

const RightsScopeEdit = ({match: {params: {id, rightsScopeId = null}}}) => {
    const Form = React.useMemo(
        () => (createForm(
            RightsScopeModel,
            RightsScopeForm,
            {
                id: convertToInt(rightsScopeId),
                optionsVariables: {
                    id: convertToInt(id),
                },
            }
        )),
        [rightsScopeId]
    );

    return <Form/>;
};

RightsScopeEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            rightsScopeId: PropTypes.string
        }),
    })
};

export default withRouter(RightsScopeEdit);
