import React from 'react';
import PropTypes from 'prop-types';

import FormChangelogEntry from './FormChangelogEntry';

export const FormChangelog = ({data, resources, isForForm = false}) => {
    const formStyleClassName = isForForm ? 'changelog-data--formStyling' : '';

    return data ?
        (<div className={`changelogData ${formStyleClassName}`}>
            <FormChangelogEntry
                label="Created on"
                date={data.creation_datetime}
                user={data.creation_user}
            />
            <FormChangelogEntry
                label='Last update on'
                date={data.update_datetime}
                user={data.update_user}
                elementId={data.id}
                resources={resources}
                changelogLink={true}
            />
            <FormChangelogEntry
                label='Cancelled on'
                date={data.cancellation_datetime}
                user={data.cancellation_user}
            />
        </div>)
        : null;
};

FormChangelog.propTypes = {
    data: PropTypes.object,
    resources: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    isForForm: PropTypes.bool,
};

export default FormChangelog;
