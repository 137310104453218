import EventMasterBookingGeoRestrictionsEditModel
    from "../../../events/forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsEditModel";

export default function ClientMasterBookingGeoRestrictionsEditModel() {
    let ClientMasterBookingGeoRestrictionsEditModel = EventMasterBookingGeoRestrictionsEditModel();

    ClientMasterBookingGeoRestrictionsEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };

    ClientMasterBookingGeoRestrictionsEditModel.mutationOptions = {
        update: {
            refetchQueries: ['GetClientMasterBookings'],
        },
        delete: {
            refetchQueries: ['GetClientMasterBookings'],
        },
    };

    return ClientMasterBookingGeoRestrictionsEditModel;
}
