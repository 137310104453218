import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import React, {useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {isEmpty as _isEmpty} from 'lodash';

import Form from '@appComponents/ReduxFormControls';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {showErrorNotificationMessage} from '@modules/client/utils/lcppClientNotificationLog';
import {GetContentNamesWithDistributionsForDropdownData} from '@graphql/eventContent/query';
import {GetEventLcppClientsDataByEventContent} from '@graphql/lcppClientNotificationLog/query';

import {
    getEventContentWithLcppProduct,
    prepareEventContentNamesForDropdown,
} from '../utils/eventsLcppClientNotificationLog';

export const EventsLcppClientNotificationLogFilters = ({
    data: {
        eventId,
    },
    restoreFilterValues,
    filters,
}) => {
    let eventContentsOnlyWithLcpp = [],
        eventContentIds = [];
    const clients = [],
        [eventContentsData, setEventContentsData] = useState([]);

    const {
        error: errorEventContentsDropdownData,
        loading: isDataForEventContentsLoading = true,
    } = useQuery(GetContentNamesWithDistributionsForDropdownData, {
        variables: {
            eventId: eventId,
        },
        skip: !_isEmpty(eventContentsData),
        onCompleted: ({eventContents}) => {
            setEventContentsData(eventContents);
        },
    });

    if (!isDataForEventContentsLoading && eventContentsData) {
        eventContentsOnlyWithLcpp = getEventContentWithLcppProduct(eventContentsData);

        eventContentIds = eventContentsOnlyWithLcpp.map((eventContent) => {
            return Number(eventContent.id);
        });
    }

    const {
        data: {
            lcppClientNotificationsLog,
        } = {},
        error: errorDataForClients,
        loading: isDataForClientsLoading = true,
    } = useQuery(GetEventLcppClientsDataByEventContent, {
        variables: {
            eventContent: eventContentIds,
        },
        skip: _isEmpty(eventContentIds),
    });

    if (lcppClientNotificationsLog) {
        lcppClientNotificationsLog.forEach((lcppClientNotification) => {
            if (!clients.includes(lcppClientNotification.client)) {
                clients.push(lcppClientNotification.client);
            }
        });
    }

    const errorOccurredWhileFetchingData = errorEventContentsDropdownData || errorDataForClients;

    useMemo(() => {
        errorOccurredWhileFetchingData && showErrorNotificationMessage();
    }, [errorOccurredWhileFetchingData]);

    return (
        <div className='content__filters'>
            <Field
                className='long'
                component={Form.ReduxSelect}
                disabled={isDataForEventContentsLoading}
                loading={isDataForEventContentsLoading}
                multiple
                name='eventContent'
                options={prepareEventContentNamesForDropdown(eventContentsOnlyWithLcpp)}
                placeholder='Content'
                search
                setValue={restoreFilterValues(filters.EventsLcppClientNotificationLog, 'eventContent')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={isDataForClientsLoading}
                loading={isDataForEventContentsLoading || isDataForClientsLoading}
                multiple
                name='client'
                placeholder='Client'
                options={clients}
                search
                setValue={restoreFilterValues(filters.EventsLcppClientNotificationLog, 'client')}
            />
        </div>
    );
};

EventsLcppClientNotificationLogFilters.propTypes = {
    data: PropTypes.shape({
        eventId: PropTypes.number.isRequired,
    }),
    filters: PropTypes.shape({
        EventsLcppClientNotificationLog: PropTypes.object.isRequired,
    }),
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(EventsLcppClientNotificationLogFilters, {name: 'EventsLcppClientNotificationLog'});
