import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import { showMessageBox } from '@utils/messageBox';

export const REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE = 'Report as working and invoiceable';

export const reportMultipleAsWorkingAndInvoiceableErrorHandler = (name, text) => {
    showMessageBox(
        name,
        REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE,
        text,
        'error',
        true
    );
};

reportMultipleAsWorkingAndInvoiceableErrorHandler.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export const reportMultipleAsWorkingAndInvoiceableResponseHandler = (response, errorHandler, messageBoxName, message) => {
    const errors = _get(response , 'errors' , []),
        result = _get(response, 'data.reportWorkingInvoiceableEvents', false);

    if (errors.length || !result) {
        errorHandler();

        return;
    }

    showMessageBox(
        messageBoxName,
        REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE,
        message,
        'success',
        true
    );
};

reportMultipleAsWorkingAndInvoiceableResponseHandler.propTypes = {
    response: PropTypes.object.isRequired,
    errorHandler: PropTypes.func.isRequired,
    messageBoxName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};
