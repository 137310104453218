import React from 'react';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Dimmer, Loader} from 'semantic-ui-react';

import {GetClientByBookmaker} from '@graphql/client/query';
import useQuery from '@utils/hooks/useQuery';
import {convertToInt} from '@utils/helpers';

import {ClientRedirect} from './ClientRedirect';

export function ClientRedirectByBookmaker({
    history,
    match,
}) {
    const {
        data: {
            clientByBookmaker,
        } = {},
        error,
        loading: isDataLoading = true,
    } = useQuery({
        query: GetClientByBookmaker,
        options: {
            variables: {
                bookmakerId: convertToInt(match.params.id),
            },
        },
    });

    return (isDataLoading && !error)
        ? (
            <Dimmer inverted active>
                <Loader/>
            </Dimmer>
        )
        : (
            <ClientRedirect
                history={history}
                clientId={_get(clientByBookmaker, 'id', null)}
            />
        );
}

ClientRedirectByBookmaker.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(ClientRedirectByBookmaker);
