import React from 'react';
import PropTypes from 'prop-types';

import Authorization from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {MATCH_SELECTION_PERIOD} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';

import PropertyLicenceContentsRow from '../components/PropertyLicenceContentsRow';

export const rowRenderer = (column, row, props) => {
    const {propertyLicenceId} = props;

    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    authorization={{[MATCH_SELECTION_PERIOD]: SECURITY_PRIVILEGES_UPDATE}}
                    link={{
                        name: 'propertyLicences.matchSelectionPeriods.edit',
                        params: {id: propertyLicenceId, matchSelectionPeriodId: row.id},
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case 'contents': {
            if (false === row.includes_all_content) {
                return PropertyLicenceContentsRow(row);
            }

            return (<div>All</div>);
        }
        case 'actions': {
            return (

                <Authorization authorization={{[MATCH_SELECTION_PERIOD]: SECURITY_PRIVILEGES_READ}} >
                    <div className='actions-list-icon'>
                        <IconEdit
                            link={getLink('propertyLicences.matchSelectionPeriods.edit', {
                                id: propertyLicenceId,
                                matchSelectionPeriodId: row.id,
                            })}
                            size='large'
                            resource={MATCH_SELECTION_PERIOD}
                        />
                    </div>
                </Authorization>
            );
        }
        default: {
            return null;
        }
    }
};

rowRenderer.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
};

rowRenderer.displayName = 'rowRenderer';
