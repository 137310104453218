import {sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {gql, graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {eventPresets as GraphQLPresetList} from '@graphql/propertyLicences/eventPresets';
import {showModal} from '@utils/modal';

import {exportExcel as propertyLicenceEventPresetsExportExcel} from '../utils/export/propertyLicenceEventPresetsExcel';
import EventPresetForm from '../forms/EventPresetForm';
import EventPresetModel from '../forms/EventPresetModel';
import EventPresetsTable from '../components/EventPresetsTable';

export class PropertyLicenceEventPresets extends React.Component {
    static propTypes = {
        DataEventPresets: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
        Modal: PropTypes.object,
        modal: PropTypes.object,
        propertyLicenceName: PropTypes.string,
    };

    static defaultProps = {
        DataEventPresets: {},
        propertyLicenceName: null,
    };

    constructor() {
        super();

        this.state = {
            eventPresets: [],
        };
    }

    componentWillMount() {
        this.loadModal(this.props.match.path);
    }

    componentWillReceiveProps(nextProps) {
        const urlChanged = this.props.match.path !== nextProps.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.propertyLicences.eventPresets.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex;

        shouldOpenModal && this.loadModal(nextProps.match.path, nextProps.match.params);

        const modalChanged = nextProps.modal.isVisible !== this.props.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(
            getLink('propertyLicences.eventPresets.index', {id: this.props.match.params.id})
        );

        if (nextProps.DataEventPresets.eventPresets) {
            this.setState(() => ({
                eventPresets: _sortBy(nextProps.DataEventPresets.eventPresets, 'name'),
            }));
        }
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.eventPresetId)) {
            showModal({
                isVisible: true,
                content: renderModalError('Event preset',
                    getLink(`propertyLicences.eventPresets.index`, {
                        id: this.props.match.params.id,
                    })
                ),
            });
        }
    }

    loadModal(path, params = {}) {
        if (path === routes.propertyLicences.eventPresets.edit.path) {
            return this.loadModalForm(createForm(
                EventPresetModel,
                EventPresetForm,
                {
                    id: this.props.match.params.eventPresetId || params.eventPresetId,
                    dataRequest: true,
                    optionsVariables: {
                        propertyLicenceId: parseInt(this.props.match.params.id, 10),
                    },
                }
            ), params.data);
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });
    };

    render() {
        return (this.props.propertyLicenceName)
            ? (
                <Segment basic className='--table --marginBottom-0'>
                    <HeaderRenderer
                        exportExcelParams={propertyLicenceEventPresetsExportExcel(
                            this.state.eventPresets,
                            this.props.match.params.id
                        )}
                        loading={this.props.DataEventPresets.loading}
                        messagesBoxNames='eventPresetMessage'
                    />
                    <div className='clearfix'/>
                    <EventPresetsTable
                        propertyLicenceId={parseInt(this.props.match.params.id, 10)}
                        loading={this.props.DataEventPresets.loading}
                        eventPresets={this.state.eventPresets}
                    />
                </Segment>
            )
            : null;
    }
}

const EventPresetsWithGraphQL = compose(
    graphql(gql(GraphQLPresetList), {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    propertyLicence: [convertToInt(props.match.params.id)],
                },
            };
        },
        name: 'DataEventPresets',
    })
)(PropertyLicenceEventPresets);

const mapStateToProps = (state) => {
    return ({
        modal: state.modal,
        propertyLicenceName: (state.app.entities.propertyLicence)
            ? state.app.entities.propertyLicence.name
            : null,
    });
};

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(EventPresetsWithGraphQL));
