import {get as _get} from 'lodash';

import {convertToInt, parseToBoolean} from '@utils/helpers';
import {
    DISTRIBUTION_TYPE_RECORDING,
    ENCODER_TYPE_SPORTRADAR,
    PRODUCT_SPOTT,
    PRODUCT_THIRD_PARTY
} from '@constants/variables';

export const hasNoClientCdnConfigurationsForSpott = (row) => (
    (PRODUCT_SPOTT === convertToInt(row.product.id)) && !row.has_client_cdn_configurations
);

export const hasNoThirdPartyClientVodStoragesForThirdPartyRecording = (row) => (
    (PRODUCT_THIRD_PARTY === convertToInt(row.product.id))
    && (DISTRIBUTION_TYPE_RECORDING === convertToInt(row.distribution_type.id))
    && !row.has_third_party_client_vod_storages
);

export const showInfoSpottIcon = (row) => (
    hasNoClientCdnConfigurationsForSpott(row) || hasNoThirdPartyClientVodStoragesForThirdPartyRecording(row)
);

export const showInfoDrmIcon = (row) => (
    hasEncodingTargetDRMSupportEnabled(row) !== hasEncodingJobProfileDRMSupportEnabled(row)
);

export const hasEncodingTargetDRMSupportEnabled = (row) => (
    parseToBoolean(_get(row, 'has_drm_support', false))
);

export const hasEncodingJobProfileDRMSupportEnabled = (row) => (
    parseToBoolean(_get(row, 'encoding_job_profile.has_drm_support', false))
);

export const getRowClassname = (row) => (
    (showInfoSpottIcon(row) || showInfoDrmIcon(row))
        ? '--row-orange-background-color'
        : ''
);

export const isContentHasInputAndEncoderSportradar = (content, tableRow) => (
    content.has_input && ENCODER_TYPE_SPORTRADAR === convertToInt(tableRow.encoder_type.id)
);

