import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {CLIENT_PRODUCT_GEOLOCATION_COUNTRY, GEOLOCATION_CHECK_METHOD_COORDINATES} from "@constants/resources";
import validate from "./validatorClientProductGeolocationCountry";

import {
    CreateClientProductGeolocationCountry,
    UpdateClientProductGeolocationCountry,
    DeleteClientProductGeolocationCountry
} from "../../../graphql/clientProductGeolocationCountry/mutation.graphql";
import {GetProductGeolocationsForForm} from "@graphql/clientProductGeolocationCountry/query";
import {GetOptionsForProductGeolocationsForm} from "@graphql/country/query";

export default function ProductGeolocationsModel() {
    return {
        deleteId: "clientProductGeolocationCountry.id",
        entityDataMapKey: "clientProductGeolocationCountry",
        entityLabel: "country",
        formName: "country",
        resources: CLIENT_PRODUCT_GEOLOCATION_COUNTRY,
        showChangeLog: true,
        validate: validate,
        title: () =>  "Add new country",
        dataMap: {
            clientProductGeolocationCountry: {
                id: "",
                country: "",
                enforcement_date: "",
                has_geolocation_check: "",
                geolocation_check_method: "",
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                DELETE_CONFIRMATION: () => "Are you sure you want to delete this country?",
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "clientProductGeolocationCountry.id",
                props: {
                    label: '',
                    name: "id",
                    hidden: true,
                },
            },
            country: {
                dataMapKey: "clientProductGeolocationCountry.country.id",
                optionsKey: "clientProductGeolocationDropdownCountries",
                props: {
                    component: Form.SemanticSelect,
                    label: "Country",
                    name: "country",
                    required: true,
                },
            },
            enforcement_date: {
                dataMapKey: "clientProductGeolocationCountry.enforcement_date",
                props: {
                    className: "--enforcement_date",
                    component: Form.SemanticInput,
                    label: "Enforcement date",
                    name: "enforcement_date",
                    type: "date",
                },
            },
            has_geolocation_check: {
                dataMapKey: "clientProductGeolocationCountry.has_geolocation_check",
                props: {
                    checked: true,
                    component: Form.SemanticInput,
                    description: null,
                    defaultValue: true,
                    label: 'Geolocation check',
                    name: 'has_geolocation_check',
                    type: 'toggle',
                },
            },
            geolocation_check_method: {
                dataMapKey: "clientProductGeolocationCountry.geolocation_check_method.id",
                optionsKey: "geolocationCheckMethods",
                props: {
                    component: Form.SemanticSelect,
                    label: "Method",
                    name: "geolocation_check_method",
                    defaultValue: GEOLOCATION_CHECK_METHOD_COORDINATES.toString(),
                },
            },
        },
        mutation: {
            createEntity: CreateClientProductGeolocationCountry,
            deleteEntity: DeleteClientProductGeolocationCountry,
            updateEntity: UpdateClientProductGeolocationCountry,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetProductGeolocationsForTable'],
            },
            update: {
                refetchQueries: ['GetProductGeolocationsForTable'],
            },
            delete: {
                refetchQueries: ['GetProductGeolocationsForTable'],
            },
        },
        query: {
            getEntity: GetProductGeolocationsForForm,
            getOptions: GetOptionsForProductGeolocationsForm,
        },
    };
}
