import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Menu, Segment} from 'semantic-ui-react';

import {getLink} from '@appComponents/Link';
import MenuItem from '@appComponents/TabsComponents/MenuItem';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {getUserAuthorizationData} from './Authorization';

/**
 * @ignore
 */
class Tabs extends React.Component {
    static propTypes = {
        active: PropTypes.string,
        hasContentRendered: PropTypes.bool,
        history: PropTypes.object,
        items: PropTypes.object,
        level: PropTypes.number,
        loading: PropTypes.bool,
    };

    authorizationData = [];

    constructor(props) {
        super(props);

        const contentToRender = this.findContentToRender(props);
        this.authorizationData = getUserAuthorizationData();

        this.state = {
            active: props.active,
            contentToRender: contentToRender,
            hasContentRendered: !_isEmpty(contentToRender),
            style: this.setStyle(props)
        };
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentWillReceiveProps(nextProps)
    {
        if (
            nextProps.active !== this.props.active ||
            !this.state.hasContentRendered ||
            nextProps.items !== this.props.items
        ) {
            const contentToRender = this.findContentToRender(nextProps);

            this.setState(() => {
                return {
                    active: nextProps.active,
                    contentToRender: contentToRender,
                    hasContentRendered: !_isEmpty(contentToRender)
                }
            });
        }
    }

    setStyle(props) {
        switch (props.level) {
            case 2:
                return {
                    secondary: true
                };
            case 1:
            default:
                return {
                    secondary: true,
                    pointing: true
                };
        }
    }

    findContentToRender = (props) => {
        let contentToRender = null;

        for (let key in props.items) {
            if (props.items[key].key === props.active) {
                contentToRender = props.items[key].render
            }
        }

        return contentToRender;
    }

    setContentToRender(content) {
        this.setState(function () {
            return {
                contentToRender: content
            }
        })
    }

    onItemClick(item, key) {
        if (item.url !== undefined) {
            let link = getLink(item.url, item.urlParams);

            this.props.history.push(link);
        }

        this.setState(function () {
            return {
                active: key
            }
        });

        this.setContentToRender(item.render)
    }

    hasReadPrivilege = (itemResource) => {
        if (!itemResource) {
            return true;
        }

        const hasNotReadPrivilege = (0 === (this.authorizationData[itemResource] & SECURITY_PRIVILEGES_READ));

        return !hasNotReadPrivilege;
    };

    render() {
        const items = this.props.items;

        return (
            <div>
                <Menu {...this.state.style} size='small'>
                    {
                        items && Object.keys(items).map((key) => {
                            const item = items[key];
                            let className = '';

                            if (!this.hasReadPrivilege(item.resource)) {
                                return null;
                            }

                            if (items[key].isDisabled === true ) {
                                className = 'disabled';
                            }

                            return (
                                <MenuItem
                                    active={key === this.state.active}
                                    click={this.onItemClick}
                                    item={items[key]}
                                    key={key}
                                    name={key}
                                    className={className}
                                >
                                    {items[key].text}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
                <Segment basic className='--tabs'>
                    {!this.props.loading && this.state.contentToRender}
                </Segment>
            </div>

        )
    }
}

export default withRouter(Tabs)
