import {forEach as _forEach} from "lodash";

const targetFragment = `fragment EncodingTargetBody on EncodingTarget {
  id
  encoding_target_sr {
    id
    has_scoreboard_overlay_support
    has_scoreboard_overlay
    has_slate_support
    slate {
        id
    }
  }
}`;

/**
 *
 * @param {array} targetIds
 * @param {string} propertyToUpdate one of: has_scoreboard_overlay
 *
 * @returns {string}
 */
export const updateLiveControlTargets = (targetIds, propertyToUpdate) => {
    let aliasedMutations = '';

    _forEach(targetIds, (id) => {
        aliasedMutations += `
         target_${id}: updateEncodingTarget(
            id: ${id}
            encoding_target_sr: {
              has_${propertyToUpdate}: $val
            }
         ) { 
            ...EncodingTargetBody
         }
        `;
    });

    return `${targetFragment}
    mutation toggle_${propertyToUpdate}($val: NullableBoolean) {
        ${aliasedMutations}
    }`;
};

export const updateLiveControlTargetsSlates = (targetIds, propertyToUpdate) => {
    let aliasedMutations = '';

    _forEach(targetIds, (id) => {
        aliasedMutations += `
         target_${id}: updateEncodingTarget(
            id: ${id}
            encoding_target_sr: {
              ${propertyToUpdate}: $val
            }
         ) { 
            ...EncodingTargetBody
         }`;
    });

    return `${targetFragment}
    mutation toggle_${propertyToUpdate}($val: Int) {
        ${aliasedMutations}
    }`;
};

const eventContentFragment = `fragment EncodingDetails on EventContent {
  id
  event_content_input {
    id
    encoding_first_start_datetime
    encoding_start_datetime
    encoding_start_margin
    encoding_end_datetime
    encoding_end_margin
    encoding_status {
     id
     name
    }
    encoder
  }
}`;

export const startEncoding = `
${eventContentFragment}

mutation startEncoding($id: ID!) {
  startEncoding(
    id: $id
  ) {
    ...EncodingDetails
  }
}`;

export const stopEncoding = `
${eventContentFragment}

mutation stopEncoding($id: ID!) {
  stopEncoding(
    id: $id
  ) {
    ...EncodingDetails
  }
}`;

export const resetEncoding = `
${eventContentFragment}

mutation resetEncoding($id: ID!, $datetime: DatetimeTzInputType, $nullInt: Int, $nullString: String, $endMargin: Int) {
  updateEventContent(
    id: $id, 
    event_content_input: {
      encoding_start_datetime: $datetime, 
      encoding_start_margin: $nullInt, 
      encoding_end_datetime: $datetime, 
      encoding_end_margin: $endMargin, 
      encoding_status: $nullInt,
      encoder: $nullString,
      encoding_message: $nullString
    }
  ) {
    ...EncodingDetails
  }
}`;


const distributionFragment = `fragment BroadcastStatusDetails on Distribution {
  id
  broadcast_status {
    id
    name
  }
}`;

export const setBroadcastStatus = (distributionIds) => {
    let aliasedMutations = '';

    _forEach(distributionIds, (id) => {
        aliasedMutations += `
          distribution_${id}: updateDistribution(
            id: ${id}
            broadcast_status: $broadcastStatus
          ) {
            ...BroadcastStatusDetails
          } 
        `;
    });

    return `${distributionFragment}
    mutation setBroadcastStatus($broadcastStatus: Int) {
        ${aliasedMutations}
    }`;
};

export const setScoreboardOverlayViaVideoPlatformController = `
mutation toggleScoreboardOverlayViaVideoPlatformController (
  $eventContentId: ID!
  $encodingDatacenterName: String!
  $scoreboardOverlayStatus: Boolean
  $scoreboardOverlayPosition: ID!
) {
  toggleScoreboardOverlay (
    event_content_id: $eventContentId
    encoding_datacenter_name: $encodingDatacenterName
    scoreboard_overlay_status: $scoreboardOverlayStatus
    scoreboard_overlay_position: $scoreboardOverlayPosition
  )
}
`;

export const setSlateViaVideoPlatformController = `
mutation toggleSlateViaVideoPlatformController (
  $eventContentId: Int!
  $encodingDatacenterName: String!
  $slateStatus: Boolean
) {
  toggleSlate (
    event_content_id: $eventContentId
    encoding_datacenter_name: $encodingDatacenterName
    slate_status: $slateStatus
  )
}
`;

export const setSlateIdViaVideoPlatformController = `
mutation setSlateViaVideoPlatformController (
  $eventContentId: Int!
  $encodingDatacenterName: String!
  $slateId: ID
) {
  setSlate (
    event_content_id: $eventContentId
    encoding_datacenter_name: $encodingDatacenterName
    slate_id: $slateId
  )
}`;

export const setUdpOriginEntryPointFailover = `
mutation setUdpOriginEntryPointFailover (
  $eventContentUdpOriginEntryPointFailover: [EventContentUdpOriginEntryPointFailoverInput]
) {
  setUdpOriginEntryPointFailover (
    event_content_udp_origin_entry_point_failover: $eventContentUdpOriginEntryPointFailover
  ) {
    status
    message
    id
    event_content {
      id
      event_content_input {
        udp_origin_entry_point {
          id
          name
          encoding_datacenter {
            id
            name
          }
          failover_udp_origin_entry_point {
            id
            name
            encoding_datacenter {
              id
              name
            }
          }
        }
        encoding_datacenter {
          id
          name
        }
      }
    }
  }
}
`;
