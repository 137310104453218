import {connect} from 'react-redux';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {graphql} from '@apollo/client/react/hoc';

import {Authorization} from '@appComponents/Authorization';
import ContactPersonList from '@modules/contactPerson/components/ContactPersonList';
import validateContactPersonType from '@modules/contactPerson/utils/validateContactPersonType';
import {GetContactPersonForForm, GetLicenseeContactPersonsForList} from '@graphql/contactPerson/query';
import * as VARIABLES from '@constants/variables';
import {CONTACT_PERSON, LICENSEE} from '@constants/resources';
import {routes} from '@constants/routes';
import * as formUtils from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';

class LicenseeContactPersonIndex extends React.Component {
    authorizationObject = {
        'contactPerson': {
            [LICENSEE]: VARIABLES.SECURITY_PRIVILEGES_READ,
            [CONTACT_PERSON]: VARIABLES.SECURITY_PRIVILEGES_READ,
        },
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.DataContactPersonsList.error !== undefined) {
            this.props.Modal.setModal({
                isVisible: true,
                content: formUtils.renderModalError('Licensee', routes.licensees.index.path),
            });
        }
    }

    getPopupState() {
        const personTypeFromURL = convertToInt(formUtils.getUrlParam('contact_person_type_id', this.props.location.search)),
            personType = convertToInt(_get(this.props.DataContactPerson, 'contactPerson.contact_person_type.id', null)),
            contactPersonId = this.props.match.params.contactPersonId,
            addPath = routes.licensees.contactPerson.add.path,
            editPath = routes.licensees.contactPerson.edit.path,
            shouldOpenPopup = [addPath, editPath].includes(this.props.match.path);

        validateContactPersonType([VARIABLES.CONTACT_PERSON_TYPE_LICENSEE],
            personTypeFromURL,
            personType,
            addPath === this.props.match.path,
            'licensees',
            this.props.match.params.id
        );

        if (contactPersonId && personType !== personTypeFromURL) {
            return {
                isLicensee: false,
                config: null,
            };
        }

        return {
            isLicensee: shouldOpenPopup && VARIABLES.CONTACT_PERSON_TYPE_LICENSEE === personTypeFromURL,
            config: {
                id: contactPersonId,
                type: contactPersonId ? 'edit' : 'add',
                person: personTypeFromURL,
            },
        };
    }

    render() {
        const popupState = this.getPopupState(),
            parent = {
                name: 'licensees',
                id: this.props.match.params.id,
            };

        return (
            <Segment basic className='content__table'>
                <Authorization authorization={this.authorizationObject.contactPerson}>
                    <ContactPersonList
                        addButtonText='Add new licensee contact person'
                        title='Licensee contact persons'
                        data={this.props.DataContactPersonsList.contactPersons}
                        loading={this.props.DataContactPersonsList.loading}
                        refetchList={this.props.DataContactPersonsList.refetch}
                        parent={parent}
                        personType={VARIABLES.CONTACT_PERSON_TYPE_LICENSEE}
                        openPopup={popupState.isLicensee ? popupState.config : null}
                    />
                </Authorization>
            </Segment>
        );
    }
}

LicenseeContactPersonIndex.propTypes = {
    match: PropTypes.object,
    DataContactPersonsList: PropTypes.object,
    DataContactPerson: PropTypes.object,
    loading: PropTypes.bool,
    location: PropTypes.object,
    Modal: PropTypes.object,
};

export default withRouter(compose(
    graphql(GetLicenseeContactPersonsForList, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    licensee_id: (props.match.params.id) ? convertToInt(props.match.params.id) : null,
                },
            };
        },
        name: 'DataContactPersonsList',
    }),
    graphql(GetContactPersonForForm, {
        skip: (props) => !props.match.params.contactPersonId,
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    id: (props.match.params.contactPersonId) ? convertToInt(props.match.params.contactPersonId) : null,
                },
            };
        },
        name: 'DataContactPerson',
    })
)(connect(null, mapModulesToProps(['Modal']))(LicenseeContactPersonIndex)));
