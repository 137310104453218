import React from 'react';
import {withRouter} from "react-router";

import ProductSpottGeoRestrictionsForm from "../forms/ProductSpottGeoRestrictionsForm";
import ProductSpottGeoRestrictionsModel from "../forms/ProductSpottGeoRestrictionsModel";

import {createForm} from "../../../utils/forms";

import {ProductLctGeoRestrictionsEdit} from "./ProductLctGeoRestrictionsEdit";

export class ProductSpottGeoRestrictionsEdit extends ProductLctGeoRestrictionsEdit {
    renderForm = () => {
        const Form = createForm(
            ProductSpottGeoRestrictionsModel,
            ProductSpottGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };
}

export default withRouter(ProductSpottGeoRestrictionsEdit)
