import {sortBy as _sortBy} from "lodash";

import {convertToInt} from "./helpers";

export const mapPropertyLicencesForDropdown = (propertyLicences) => _sortBy(
    propertyLicences.map((propertyLicence) => ({
        key: propertyLicence.id,
        text: `${propertyLicence.name} - ${propertyLicence.licensor.name}`,
        value: convertToInt(propertyLicence.id)
    })),
    (el) => el.text.toLowerCase()
);
