import React from 'react';
import PropTypes from "prop-types";

import StepsContent from "../../app/components/StepsContent";

import EventContentAlertDetails from "./EventContentAlertDetails";

class EventContentAlertSteps extends React.PureComponent{
    tabs = [];

    constructor() {
        super();

        this.tabs = [
            {
                id: "step1",
                key: "step1",
                menuItem: {content: "", hidden: true},
                pane: {
                    content: <EventContentAlertDetails {...this.props} />
                },
            }
        ];
    }

    render() {
        this.tabs[0].pane.content = <EventContentAlertDetails {...this.props} />;

        return <StepsContent tabs={this.tabs} hideHeader={true} hideMenu={true} className={"alertStepsModal"}/>
    }
}

EventContentAlertSteps.propTypes = {
    eventContentId: PropTypes.number.isRequired,
};

EventContentAlertSteps.displayName = "EventContentAlertSteps";

export default EventContentAlertSteps;
