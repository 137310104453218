import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.encoding_datacenter, 'encoding_datacenter'));
    Object.assign(errors, validators.required(values.primary_feed, 'primary_feed'));

    if (values.primary_feed_ad_support) {
        Object.assign(errors, validators.required(values.primary_feed_ad_splice_config, 'primary_feed_ad_splice_config'));
    }

    if (values.secondary_feed_arn
        || values.secondary_feed_feed_splice
        || values.secondary_feed_ad_support
        || values.secondary_feed_output_url
        || values.secondary_feed_close_captioning) {
        Object.assign(errors, validators.required(values.secondary_feed, 'secondary_feed'));
    }

    if (values.secondary_feed_ad_support) {
        Object.assign(errors, validators.required(values.secondary_feed_ad_splice_config, 'secondary_feed_ad_splice_config'));
    }

    return errors;
};

export default validate;
