
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AkamaiLiveStreamNameBasicData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AkamaiLiveStreamName"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"stream_name"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AkamaiLiveStreamNameListData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AkamaiLiveStreamName"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AkamaiLiveStreamNameBasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"encoding_datacenter"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"stream_protocol"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AkamaiLiveStreamNameData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AkamaiLiveStreamName"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AkamaiLiveStreamNameListData"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChangelogData"},"directives":[]}]}}],"loc":{"start":0,"end":464}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment AkamaiLiveStreamNameBasicData on AkamaiLiveStreamName {\n    id\n    stream_name\n}\n\nfragment AkamaiLiveStreamNameListData on AkamaiLiveStreamName {\n    ...AkamaiLiveStreamNameBasicData\n    description\n    encoding_datacenter {\n        ...BasicData\n    }\n    stream_protocol {\n        ...BasicData\n    }\n}\n\nfragment AkamaiLiveStreamNameData on AkamaiLiveStreamName {\n    ...AkamaiLiveStreamNameListData\n    ...ChangelogData\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
