import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';

import {SPORTRADAR_DOMAIN} from '@constants/variables';

export const isEmailCorrectDomain = (email) => (
    email.toLowerCase().includes(`@${SPORTRADAR_DOMAIN}`)
);

isEmailCorrectDomain.PropTypes = {
    email: PropTypes.string.isRequired,
};

export const canDisplayModal = (email) => (
    !isEmailCorrectDomain(email)
);

canDisplayModal.PropTypes = {
    email: PropTypes.string.isRequired,
};

export const displayModal = (username, email, gotoCreateUser, Modal) => {
    Modal.setModalConfirmation({
        header: <Header content={'Add new user from this Common-IAM user'}/>,
        // eslint-disable-next-line react/no-danger
        text: <div dangerouslySetInnerHTML={getTextModal(username, email)}/>,
        onYes: gotoCreateUser,
        size: 'small',
    });
};

displayModal.PropTypes = {
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    gotoCreateUser: PropTypes.func.isRequired,
    Modal: PropTypes.object.isRequired,
};

export const getTextModal = (username, email) => {
    let message = '<ul>';

    if (!isEmailCorrectDomain(email)) {
        message = message.concat(`<li>The email address is not a Sportradar email address (&lt;username&gt;@${SPORTRADAR_DOMAIN}).</li>`);
    }

    message = message.concat('</ul>Are you sure you want to create a new user from that Common-IAM account anyway?');

    return {__html: message};
};

getTextModal.PropTypes = {
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};
