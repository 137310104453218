import PropTypes from 'prop-types';
import moment from 'moment/moment';

import {generateBlackoutZonesRow, getBlackoutZonesFromGeoRestrictions} from '@utils/blackoutZones';
import {getBookingUserInformationForExcelExport} from '@modules/events/forms/helpers/booking';
import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions,
} from '@utils/countryHelpers';
import {formattedPriceOrEmptyValue, getYesOrNo} from '@utils/helpers';
import {renderContentCategoryLevelColumn} from '@modules/client/utils/clientBookingTable';
import {EMPTY_VALUE_DASH} from '@constants/variables';

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_bookings`,
    parseData: clientBooking => [
        clientBooking.booking_id.toString(),
        clientBooking.event_id.toString(),
        clientBooking.match_id ? clientBooking.match_id.toString() : '',
        clientBooking.event_content_id,
        clientBooking.distribution_id,
        `${moment(clientBooking.start_datetime).format('YYYY-MM-DD')}`,
        `${moment(clientBooking.start_datetime).format('HH:mm')}`,
        `${clientBooking.end_datetime ? moment(clientBooking.end_datetime).format('YYYY-MM-DD') : ''}`,
        `${clientBooking.end_datetime ? moment(clientBooking.end_datetime).format('HH:mm') : ''}`,
        renderContentCategoryLevelColumn(clientBooking, 'sport', 1),
        renderContentCategoryLevelColumn(clientBooking, 'tournament_category', 2),
        renderContentCategoryLevelColumn(clientBooking, 'tournament', 3),
        clientBooking.content_tier_name,
        clientBooking.competitors,
        clientBooking.event_description || '',
        `${clientBooking.event_content_type_name}${clientBooking.event_content_name ? ` - ${clientBooking.event_content_name}` : ''}`,
        clientBooking.event_content_variant_name,
        clientBooking.product_short_name,
        clientBooking.distribution_type_name,
        getListOfDeviceCategoriesForGeoRestrictions(clientBooking.booking_geo_restrictions).join(', '),
        getListOfCodesForDeviceCategories(clientBooking.booking_geo_restrictions),
        getListOfCountryCodesForDeviceCategories(clientBooking.booking_geo_restrictions).length.toString(),
        generateBlackoutZonesRow(
            getBlackoutZonesFromGeoRestrictions(clientBooking.booking_geo_restrictions),
            true
        ),
        clientBooking.client_package_name,
        getYesOrNo(clientBooking.is_hq),
        clientBooking.booking_type_name,
        formattedPriceOrEmptyValue(clientBooking.price, EMPTY_VALUE_DASH),
        clientBooking.booking_invoice_status_name
            ? clientBooking.booking_invoice_status_name
            : clientBooking.event_invoice_status_name,
        getYesOrNo(clientBooking.booking_invoice_status_name),
        clientBooking.booking_creation_datetime,
        getBookingUserInformationForExcelExport(clientBooking.booked_by),
        getYesOrNo(clientBooking.is_cancelled),
        getBookingUserInformationForExcelExport(clientBooking.cancelled_by),
    ],
    path: 'clients.bookings.index.path',
    titles: [
        'ID',
        'Event ID',
        'Match ID',
        'Content ID',
        'Distribution ID',
        'Date start',
        'Time start',
        'Date end',
        'Time end',
        'Sport / 1st level category',
        'Category / 2nd level category',
        'Tournament / 3rd level category',
        'Tier',
        'Competitors',
        'Description',
        'Content',
        'Content variant',
        'Product',
        'Distribution type',
        'Device categories',
        'Countries',
        '#Countries',
        'Blackout Zones',
        'Package',
        'HQ',
        'Type',
        'Price',
        'Invoice status',
        'Invoice status set on booking level?',
        'Time',
        'Booked by',
        'Cancelled?',
        'Cancelled by',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired,
};
