import {get as _get} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import Table from '@appComponents/Table';
import { getCompetitorsNameFromEvent } from '@utils/competitorName';
import { getStadiumOrVenueNameFromEvent } from '@utils/stadium';
import { getTvChannelNamesFromEvent } from '@utils/tvChannels';
import {IconCheckmark} from '@appComponents/IconCollection';
import {renderEventStatusName} from '@utils/status';

import {
    getSportName,
    getTournamentCategoryName,
    getTournamentName,
} from '../../utils/eventSchedule';
import {
    CompetitorNameAndLabels,
    MatchId,
} from '../../views/common/EventScheduleTableColumns';

export const EventsReportedAsWorkingAndInvoiceableTable = ({data = [], isLoading = true}) => {
    const tableColumns = {
        'start_datetime': {
            label: 'Date / Time',
            header: {
                align: 'center',
            },
            sorting: true,
            content: {
                align: 'center',
                singleLine: true,
            },
        },
        'id': {
            label: 'Event ID',
            header: {
                align: 'center',
            },
            sorting: true,
            content: {
                align: 'center',
            },
        },
        'match_id': {
            label: 'Match ID',
            header: {
                align: 'center',
            },
            sorting: (row) => _get(row, 'sr_event.match.id', 0),
            content: {
                align: 'center',
            },
        },
        'sport_name': {
            label: 'Sport / 1st level category',
            sorting: (row) => getSportName(row),
        },
        'category_name': {
            label: 'Category / 2nd level category',
            content: {
                align: 'left',
            },
            sorting: (row) => getTournamentCategoryName(row),
        },
        'tournament_name': {
            label: 'Tournament / 3rd level category',
            content: {
                align: 'left',
            },
            sorting: (row) => getTournamentName(row),
        },
        'venue_or_court_or_tvchannel': {
            label: 'Venue / Court / TV Channel',
            content: {
                align: 'left',
            },
            sorting: (row) => `${getStadiumOrVenueNameFromEvent(row)} ${getTvChannelNamesFromEvent(row)}`,
        },
        'competitors': {
            label: 'Competitors / Description',
            sorting: (row) => getCompetitorsNameFromEvent(row),
        },
        'status': {
            label: 'Status',
            sorting: (row) => _get(row, 'event_status.name', ''),
        },
        'has_event_report': {
            label: 'Report',
            sorting: (row) => (row.has_event_report ? 1 : 0),
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
        'notes': {
            label: 'Notes',
            sorting: false,
        },
    };

    const tableRowRenderer = (column, row) =>
    {
        switch (column) {
            case 'start_datetime':
                return moment(row[column]).format('YYYY-MM-DD HH:mm');
            case 'id':
                return row.id;
            case 'match_id':
                return (<MatchId event={row} />);
            case 'sport_name':
                return getSportName(row);
            case 'category_name':
                return getTournamentCategoryName(row);
            case 'tournament_name':
                return getTournamentName(row);
            case 'venue_or_court_or_tvchannel': {
                const tvChannels = getTvChannelNamesFromEvent(row);

                return (
                    <>
                        {getStadiumOrVenueNameFromEvent(row)}
                        {tvChannels &&
                            <p>{tvChannels}</p>
                        }
                    </>
                );
            }
            case 'competitors':
                return (<CompetitorNameAndLabels event={row} />);
            case 'status':
                return renderEventStatusName(row.event_status.id, row.event_status.name);
            case 'has_event_report':
                return row[column] ? <IconCheckmark/> : null;
            case 'notes':
                return _get(row, 'notes', '');
            default:
                return '';
        }
    };

    return (
        <div className='ui form'>
            <Table
                name='eventsPresetApply'
                columns={tableColumns}
                loading={isLoading}
                data={_get(data, 'events', [])}
                rowRenderer={tableRowRenderer}
                pagination={false}
                size='small'
                defaultSort={'start_datetime'}
            />
        </div>
    );
};

EventsReportedAsWorkingAndInvoiceableTable.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};
