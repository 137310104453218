import NonSportradarEventModel from "./NonSportradarEventModel";

export default function NonSportradarEventEditModal() {
    let nonSportradarEventEdit = NonSportradarEventModel(true);

    nonSportradarEventEdit.fields.property_licence_id.props.disabled = true;
    nonSportradarEventEdit.fields.event_status.props.hidden = true;

    return nonSportradarEventEdit
}
