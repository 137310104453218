import PropTypes from 'prop-types';
import React from 'react';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";

const ProductionCompanyFilters = ({
    data: {
        countries = [],
        clients = [],
        isArchived = [],
    },
    loading,
    filters,
    restoreFilterValues,
}) => {
    let isArchivedValue = restoreFilterValues(filters.productionCompanies, 'isArchived', 0);

    if ('' !== isArchivedValue) {
        isArchivedValue = isArchivedValue ? 1 : 0;
    }

    return (
        <div className="productionCompanies__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                name="search"
                disabled={loading}
                setValue={restoreFilterValues(filters.productionCompanies, 'search')}
                placeholder="Search..."
            />
            <Field
                className="productionCompanies__filters__country"
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name="countries"
                options={countries}
                placeholder="Country"
                search
                setValue={restoreFilterValues(filters.productionCompanies, 'countries')}
                selection
            />
            <Field
                className="productionCompanies__filters__country"
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name="clients"
                options={clients}
                placeholder="Client"
                search
                setValue={restoreFilterValues(filters.productionCompanies, 'clients')}
                selection
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name="isArchived"
                options={isArchived}
                setValue={isArchivedValue}
                placeholder="Archive status"
                selection
            />
        </div>
    );
}

ProductionCompanyFilters.propTypes = {
    data: PropTypes.shape({
        countries: PropTypes.array,
        clients: PropTypes.array,
        isArchived: PropTypes.array,
    }),
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(ProductionCompanyFilters, {name: "productionCompanies", initialValues: {isArchived: 0}});
