import React from 'react';

import Form from '@appComponents/ReduxFormControls';
import {RTMP_ORIGIN_STREAM_NAME} from '@constants/resources';
import {AddMultipleRtmpOriginStreamNames, GetRtmpOriginStreamNameForForm} from '@graphql/rtmpOriginStreamName/mutation';
import {IconInfoCircle} from '@appComponents/IconCollection';
import * as MESSAGES from '@constants/messages';

import validate from './validatorRtmpOriginStreamNameAddMultiple';

export default function RtmpOriginStreamNameAddMultipleModel() {
    const renderInfo = () => (<div className='infoRow'>
        Enter a list of RTMP origin stream names separated by commas or lines breaks.&nbsp;
        <IconInfoCircle className='--align-self-baseline'
            tooltip='Placeholder [MATCH_ID] will be replaced by the Sportradar match ID.'/>
    </div>);

    return {
        entityDataMapKey: 'rtmpOriginStreamName',
        entityLabel: 'RTMP origin stream names',
        formName: 'rtmp_origin_stream_name_add_multiple',
        label: 'RTMP origin stream name add multiple',
        resources: RTMP_ORIGIN_STREAM_NAME,
        showChangelog: true,
        title: () => 'Add multiple RTMP origin stream names',
        validate: validate,
        dataMap: {
            rtmpOriginStreamName: {
                rtmp_origin_stream_names: '',
            },
        },
        messages: {
            boxName: {
                error: 'productionCompany',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('RTMP origin stream names', 'have'),
            },
        },
        fields: {
            info: {
                props: {
                    component: renderInfo,
                    name: null,
                },
            },
            rtmp_origin_stream_names: {
                dataMapKey: 'rtmpOriginStreamName.name',
                props: {
                    className: '--width-125-percent',
                    component: Form.SemanticTextArea,
                    name: 'rtmp_origin_stream_names',
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: AddMultipleRtmpOriginStreamNames,
        },
        query: {
            getEntity: GetRtmpOriginStreamNameForForm,
        },
    };
}
