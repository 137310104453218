import React from "react";
import PropTypes from "prop-types";
import {isEmpty as _isEmpty} from "lodash";

import {IconEdit} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";
import Authorization from "../../app/components/Authorization";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';
import UdpOriginEntryPointContentsRow from "./UdpOriginEntryPointContentsRow";
import {getLink} from "../../app/components/Link";

const authorizationObject = {
    [RESOURCES.UDP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ
};

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "encoding_datacenter": {
        label: "Encoding datacenter",
        sorting: (row) => _isEmpty(row.encoding_datacenter) ? "" : row.encoding_datacenter.name
    },
    "url": {label: "URL", sorting: true},
    "failover_udp_origin_entry_point": {
        label: "Failover",
        sorting: (row) => _isEmpty(row.failover_udp_origin_entry_point) ? "" : row.failover_udp_origin_entry_point.name
    },
    "content": {
        label: "Content",
        sorting: false
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case "id":
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: "udpOriginEntryPoint.edit",
                        params: {id : row.id}
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        case "name":
            return (
                <div>
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: "udpOriginEntryPoint.edit",
                            params: {id : row.id}
                        }}
                    >
                        {row.name}
                    </LinkWithAuthorization>
                    {_isEmpty(row.description) ? null : <p className="muted">{row.description}</p>}
                </div>
            );
        case "encoding_datacenter" :
            return (
                <div>
                    {_isEmpty(row.encoding_datacenter) ? "" : row.encoding_datacenter.name}
                </div>
            );
        case "failover_udp_origin_entry_point" :
            return (
                <div>
                    {_isEmpty(row.failover_udp_origin_entry_point) ? "" : row.failover_udp_origin_entry_point.name}
                </div>
            );
        case "content" :
            return UdpOriginEntryPointContentsRow(row);
        case "actions" :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className="actions-list-icon">
                        <IconEdit size="large" link={
                            getLink('udpOriginEntryPoint.edit', {
                                id: row.id
                            })
                        } resource={RESOURCES.UDP_ORIGIN_ENTRY_POINT}/>
                    </div>
                </Authorization>
            );
        default:
            return null
    }
};

const UdpOriginEntryPointTable = (props) => {
    const {udpOriginEntryPoints, loading} = props;

    return (
        <div className="udpOriginEntryPoints__table">
            <Table
                name="udpOriginEntryPointsList"
                columns={columns}
                data={udpOriginEntryPoints}
                loading={loading}
                rowRenderer={rowRenderer}
                noDataText="No UDP origin entry points found."
            />
        </div>
    )
};

UdpOriginEntryPointTable.defaultProps = {
    udpOriginEntryPoints: []
};

UdpOriginEntryPointTable.propTypes = {
    udpOriginEntryPoints: PropTypes.array.isRequired,
    loading: PropTypes.bool
};

export default UdpOriginEntryPointTable;
