import PropTypes from 'prop-types';
import React from 'react';

import Table from '@appComponents/Table';

import {lcppClientNotificationLogColumns, rowRenderer} from '../utils/lcppClientNotificationLog';

const LcppClientNotificationLogTable = ({lcppClientNotificationsLog = [], loading}) => (
    <Table
        columns={lcppClientNotificationLogColumns}
        data={lcppClientNotificationsLog}
        loading={loading}
        name='NotificationHistoryList'
        noDataText='No notification history found'
        rowRenderer={rowRenderer}
    />
);

LcppClientNotificationLogTable.propTypes = {
    lcppClientNotificationsLog: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

LcppClientNotificationLogTable.displayName = 'LcppClientNotificationLogTable';

export default LcppClientNotificationLogTable;
