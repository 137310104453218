import {difference as _difference} from "lodash";

import {convertToInt} from "../../../../utils/helpers";

import {
    SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_DELETE,
    SECURITY_PRIVILEGES_READ,
    SECURITY_PRIVILEGES_UPDATE
} from "../../../app/constants/variables";

export const fillUserResources = (userResources, resourcesToFill) => {
    Object.entries(userResources).forEach(([key, value]) => {
        const resourceId = key.match(/toggle_(\d+)/);

        if (null !== resourceId && null !== resourceId[1]) {
            let privileges = 0;

            const privilege = userResources[`privilege_${resourceId[1]}`],
                hiddenPrivileges = userResources[`privilege_hidden_${resourceId[1]}`];

            if (privilege.length !== hiddenPrivileges.length) {
                if (value) {
                    privileges = _difference(privilege, hiddenPrivileges).reduce(function (a, b) {
                        return a + b;
                    }, 0);
                }
            }

            if (privileges) {
                resourcesToFill.push({
                    resource: convertToInt(resourceId[1]),
                    privileges: privileges
                });
            }
        }
    });
};

export const prepareCheckBoxOptions = (resourceId, defaultPrivilegesValue) => {
    return [
        {
            key: `${resourceId}_create`,
            value: SECURITY_PRIVILEGES_CREATE,
            text: 'Create',
            disabled: !!(defaultPrivilegesValue.find(privilege => privilege === SECURITY_PRIVILEGES_CREATE)),
        },
        {
            key: `${resourceId}_read`,
            value: SECURITY_PRIVILEGES_READ,
            text: 'Read',
            disabled: !!(defaultPrivilegesValue.find(privilege => privilege === SECURITY_PRIVILEGES_READ)),
        },
        {
            key: `${resourceId}_update`,
            value: SECURITY_PRIVILEGES_UPDATE,
            text: 'Update',
            disabled: !!(defaultPrivilegesValue.find(privilege => privilege === SECURITY_PRIVILEGES_UPDATE)),
        },
        {
            key: `${resourceId}_delete`,
            value: SECURITY_PRIVILEGES_DELETE,
            text: 'Delete',
            disabled: !!(defaultPrivilegesValue.find(privilege => privilege === SECURITY_PRIVILEGES_DELETE)),
        },
    ];
};
