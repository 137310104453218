import * as actionTypes from '../actions/actionTypes';

const DEFAULT_PAGE_LIMIT = 15;
const SORT_ASC = 'ascending';
const SORT_DESC = 'descending';

const initialState = {
    data: [],
    sets: [],
    dataSet: [],
    index: 0,
    sortColumn: null,
    sortDirection: null
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const paginator = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAGINATOR_PAGINATE: {
            let sets = []
            let data = action.data ? action.data : state.data
            let chunks = data.length / DEFAULT_PAGE_LIMIT

            for (let i = 0, j = 0; i < chunks; i++, j += DEFAULT_PAGE_LIMIT) {
                sets[i] = data.slice(j, j + DEFAULT_PAGE_LIMIT )
            }

            return {
                ...state,
                data: data,
                sets: sets,
                dataSet: sets[state.index]
            };
        }

        case actionTypes.PAGINATOR_CHANGE_PAGE: {
            if (action.index === state.index) {
                return state
            }

            return {
                ...state,
                index: action.index,
                dataSet: state.sets[action.index]
            };
        }

        case actionTypes.PAGINATOR_SORT: {
            let direction = state.sortDirection == SORT_DESC
                ? SORT_ASC
                : SORT_DESC
            let column = action.column

            let chunks = [].concat.apply([], state.sets)
            let mapped = chunks.map((el, i) => (
                {index: i, value: el[column]}
            ))

            mapped.sort((a, b) => {
                let aValue
                let bValue

                if (!isNaN(parseFloat(a.value, 10))) {
                    aValue = parseFloat(a.value, 10)
                    bValue = parseFloat(b.value, 10)

                    return (direction == SORT_DESC)
                        ? aValue - bValue
                        : bValue - aValue;
                }

                aValue = a.value.name.toLowerCase();
                bValue = b.value.name.toLowerCase();

                return (direction == SORT_DESC)
                    ? +(aValue > bValue) || +(aValue === bValue) - 1
                    : +(bValue > aValue) || +(bValue === aValue) - 1;
            });

            // container for the resulting order
            const sortedData = mapped.map((el) => chunks[el.index]);

            return {
                ...state,
                data: sortedData,
                sortColumn: column,
                sortDirection: direction
            };
        }

        default:
            return state
    }
};

export default paginator;
