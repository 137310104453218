import React from 'react';
import PropTypes from "prop-types";
import {Table as SemanticTable}  from 'semantic-ui-react'

/**
 * @ignore
 */
class TableRow extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.array,
        data: PropTypes.object,
        id: PropTypes.number,
        onClick: PropTypes.func,
        positive: PropTypes.bool,
    };

    /**
     * @ignore
     */
    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props)
        }
    };

    /**
     * @ignore
     */
    render() {
        return (
            <SemanticTable.Row
                className={this.props.className}
                positive={this.props.positive}
                id={this.props.id}
                onClick={this.onClick}
            >
                {this.props.children}
            </SemanticTable.Row>
        )
    }
}

export default TableRow;
