import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.ad_splice, 'ad_splice'));

    return errors;
};

export default validate;
