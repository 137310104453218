import PropTypes from 'prop-types';

import {convertToInt} from "../../../../../utils/helpers";

/**
 * Prepare structure for save data into "rights_scope_usage"
 * @param {Object[]} productUsages
 * @returns {Object[]} Array objects with structure to save data into "rights_scope_usage"
 */
export const formatToSaveUsages = (productUsages = []) => {
    let usagesToSave = [];

    productUsages.forEach((product) => (
        product.children.forEach((deviceCategory) => (
            deviceCategory.children.forEach((wayOfTransmission) => {
                usagesToSave.push({
                    "id": wayOfTransmission.id,
                    "product": convertToInt(product.value),
                    "device_category": convertToInt(deviceCategory.value),
                    "way_of_transmission": convertToInt(wayOfTransmission.value),
                });
            })
        ))
    ));

    return usagesToSave;
};

formatToSaveUsages.propTypes = {
    productUsages: PropTypes.array.isRequired
}
