import React from "react";
import PropTypes from "prop-types";
import {gql, graphql} from "react-apollo";
import {Dimmer, Header, Loader} from "semantic-ui-react";

import {indexQuery} from "../../../graphql/liveControl/alerts";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";

import EventContentAlertsTable from "../components/EventContentAlertsTable";

import EventContentAlertForm from "../forms/EventContentAlertForm";
import EventContentAlertModel from "../forms/EventContentAlertModel";

import {DISTRIBUTION_TYPE_LIVE} from "../../app/constants/variables";

class EventContentAlertDetails extends React.Component{
    Form = () => <Dimmer active inverted>
        <Loader active inline/>
    </Dimmer>;

    componentDidMount() {
        this.Form = createForm(
            EventContentAlertModel,
            EventContentAlertForm,
            {
                optionsVariables: {
                    eventContentId: convertToInt(this.props.eventContentId),
                    distributionType: DISTRIBUTION_TYPE_LIVE
                }
            }
        );
    }

    render() {
        let Form = this.Form;

        return (
            <div className="ClientsAlertModal">
                <Header content="Send alert to clients"/>
                <div className="--noBorder --noMargin --noPadding">
                    <EventContentAlertsTable
                        alerts={this.props.EventContentAlertDetails.eventContentAlerts}
                        loading={this.props.EventContentAlertDetails.loading}
                    />
                    <div className="eventContentNewAlertForm">
                        <Form
                            stepsMethods={this.props.stepsMethods}
                            eventContentStartDatetime={this.props.eventContentStartDatetime}
                            eventContentId={this.props.eventContentId}
                            recipients={this.props.EventContentAlertDetails.eventContentAlertClients}
                            messageBoxName={this.props.messageBoxName}
                            preFillData={this.props.preFillData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

EventContentAlertDetails.propTypes = {
    eventContentId: PropTypes.number.isRequired,
    EventContentAlertDetails: PropTypes.object,
    eventContentStartDatetime: PropTypes.string,
    messageBoxName: PropTypes.string.isRequired,
    preFillData: PropTypes.shape({
        template: PropTypes.number,
        type: PropTypes.number,
    }),
    stepsMethods: PropTypes.object.isRequired
};

export default graphql(gql(indexQuery), {
    options: (props) => {
        return {
            fetchPolicy: "network-only",
            variables: {
                eventContentId: props.eventContentId
            },
        }
    },
    name: "EventContentAlertDetails"
})(EventContentAlertDetails);
