import {get as _get, has as _has} from 'lodash';
import PropTypes from 'prop-types';

import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions,
} from '@utils/countryHelpers';
import {formattedPriceOrEmptyValue, getContent, getYesOrNo} from '@utils/helpers';
import {generateBlackoutZonesRow,getBlackoutZonesFromGeoRestrictions} from '@utils/blackoutZones';

import {getBookingUserInformationForExcelExport} from '../../forms/helpers/booking';

export const exportExcel = (dataExport, eventId) => ({
    dataExport: dataExport,
    filename: `event_${eventId}_bookings`,
    parseData: booking => [
        booking.id,
        booking.client.name,
        getContent(booking.distribution.event_content),
        booking.distribution.event_content.event_content_variant.name,
        booking.distribution.product.short_name,
        booking.distribution.distribution_type.name,
        getListOfDeviceCategoriesForGeoRestrictions(booking.booking_geo_restrictions).join(', '),
        getListOfCodesForDeviceCategories(booking.booking_geo_restrictions),
        getListOfCountryCodesForDeviceCategories(booking.booking_geo_restrictions).length.toString(),
        generateBlackoutZonesRow(
            getBlackoutZonesFromGeoRestrictions(booking.booking_geo_restrictions),
            true
        ),
        _get(booking, 'client_package.name', ''),
        getYesOrNo(booking.is_hq),
        _get(booking, 'booking_type.name', ''),
        formattedPriceOrEmptyValue(booking.price, ''),
        _has(booking, 'booking_invoice_status.name')
            ? _get(booking, 'booking_invoice_status.name', '')
            : _get(booking, 'event_invoice_status.name', ''),
        _has(booking, 'booking_invoice_status.name')
            ? 'Yes'
            : 'No',
        booking.creation_datetime,
        getBookingUserInformationForExcelExport(booking.booked_by),
        getYesOrNo(booking.is_cancelled),
        getBookingUserInformationForExcelExport(booking.cancelled_by),
    ],
    path: 'events.bookings.index.path',
    titles: [
        'ID',
        'Client',
        'Content',
        'Content variant',
        'Product',
        'Distribution type',
        'Device categories',
        'Countries',
        '#Countries',
        'Blackout Zones',
        'Package',
        'HQ',
        'Type',
        'Price',
        'Invoice status',
        'Invoice status set on booking level?',
        'Time',
        'Booked by',
        'Cancelled?',
        'Cancelled by',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    eventId: PropTypes.number.isRequired,
};
