import {withRouter} from 'react-router';
import {
    get as _get,
    isEmpty as _isEmpty,
    isArray as _isArray,
    uniqWith as _uniqWith,
    isEqual as _isEqual,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {
    GetTournamentCategoriesFilteredBySport,
    GetTournamentsExcludingClipConfigTournamentsFilteredByTournamentCategories,
    GetSports,
} from '@graphql/clipConfiguration/query';
import {convertToInt} from '@utils/helpers';
import {
    formatToSaveClipsOptions,
    setClipsTree,
    runQuery,
    createLinkToIndexPage,
} from '@modules/clipConfiguration/utils/formHelpers';
import {refetchQueryByName} from '@utils/apollo';

export class ClipConfigurationTournamentLevelForm extends DefaultForm {
    setSports = (isEditForm = false) => {
        if (!this.state.SportsDataLoading) {
            this.setState(() => ({SportsDataLoading: true}));

            runQuery(this.runApolloRequest, GetSports).then((response) => {
                const options = response.data.sports;

                this.setField('sports', {
                    options: options,
                    disabled: 0 === options.length || isEditForm,
                });
                this.setState(() => ({
                    SportsData: options,
                    SportsDataLoading: false,
                }));
            });
        }
    }

    checkIfSelectedTournamentAreAvailable = (options, selectedTournaments) => {
        let selectedAvailableTournaments = [];

        selectedTournaments.forEach((value) => {
            if (options.some(v => (v.id === value))) {
                selectedAvailableTournaments.push(value);
            }
        });

        return selectedAvailableTournaments;
    }

    setTournaments = (tournamentCategories, product, includeSelectedTournaments) => {
        if (0 < tournamentCategories.length && !this.state.TournamentDataLoading) {
            this.setState(() => ({TournamentDataLoading: true}));
            runQuery(this.runApolloRequest,
                GetTournamentsExcludingClipConfigTournamentsFilteredByTournamentCategories,
                {
                    productId: parseInt(product),
                    includeTournaments: includeSelectedTournaments.map((tournament) => (
                        convertToInt(tournament.id)
                    )),
                    tournamentsCategories: tournamentCategories,
                }
            ).then((response) => {
                this.setField('tournaments', {
                    options: response.data.tournamentWithoutClipConfigSports,
                    disabled: 0 === response.data.tournamentWithoutClipConfigSports.length,
                    defaultValue: this.checkIfSelectedTournamentAreAvailable(
                        response.data.tournamentWithoutClipConfigSports,
                        this.props.formValues.tournaments
                    ),
                });
                this.setState(() => ({
                    TournamentData: response.data.tournamentWithoutClipConfigSports,
                    TournamentDataLoading: false,
                }));
            });
        } else {
            this.setField('tournaments', {
                disabled: true,
                defaultValue: [],
            });
        }
    }

    setTournamentCategories = (sport) => {
        if (sport && !this.state.TournamentCategoriesDataLoading) {
            this.setState(() => ({TournamentCategoriesDataLoading: true}));

            runQuery(this.runApolloRequest,
                GetTournamentCategoriesFilteredBySport,
                {
                    sport: _isArray(sport) ? sport.map((tournament) => (
                        convertToInt(tournament.id)
                    )) : [convertToInt(sport)],
                }).then((response) => {
                this.setField('categories', {
                    options: response.data.tournamentCategories,
                    disabled: 0 === response.data.tournamentCategories.length,
                });
                this.setState(() => ({
                    TournamentCategoriesData: response.data.tournamentCategories,
                    TournamentCategoriesDataLoading: false,
                }));
            });
        }
    }

    getSelectedTournamentCategories = (selectedTournamentCategories) => {
        if (0 < selectedTournamentCategories.length && !this.state.selectedTournamentCategoriesData) {
            const tournamentCategories = selectedTournamentCategories.map((tournamentCategorie) => (
                tournamentCategorie.id.toString()
            ));
            this.setField('categories', {
                defaultValue: _uniqWith(tournamentCategories, _isEqual),
            });
            this.setState(() => ({
                selectedTournamentCategoriesData: selectedTournamentCategories,
            }));
        }
    }

    componentDidMount() {
        const link = createLinkToIndexPage();

        this.setField('sports', {
            label: 'Sport',
        });

        this.setOnChangeCallback({
            products: (event) => {
                setClipsTree(this, event.value, []);
            },
            sports: (event) => {
                this.setTournamentCategories(event.value);
            },
            categories: (event) => {
                const clipConfigSelectedTournaments =
                    _get(this.props, 'GraphQLEntityData.clipConfig.tournaments', []);

                this.setTournaments(
                    event.value.map((value) => (
                        parseInt(value)
                    )),
                    this.props.formValues.products,
                    [...this.props.formValues.tournaments, ...clipConfigSelectedTournaments]
                );
            },
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });
        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });

        this.setState(() => ({
            defaultForm_formTitle: 'Add new clip configuration on tournament level',
        }));

        if (!this.props.formParams.id) {
            this.setSports();
        }
    }

    componentDidUpdate(prevProps) {
        const clipConfig = _get(this.props, 'GraphQLEntityData.clipConfig', null);
        const selectedTournamentCategoriesAndSports = _get(this.props, 'GraphQLEntityData.selectedTournamentCategoriesAndSports', null);

        if (clipConfig && !this.state.dataLoaded) {
            const selectedSports = selectedTournamentCategoriesAndSports.sports.map((sport) => (
                sport.id
            ));
            this.setSports( true);
            setClipsTree(this, clipConfig.product.id, clipConfig.event_content_type_configs);
            this.setField('sports', {
                defaultValue: selectedSports.toString(),
            });

            this.setField('products', {
                defaultValue: clipConfig.product.id,
                disabled: true,
            });

            this.setField('tournaments', {
                defaultValue: clipConfig.tournaments.map((tournament) => (
                    tournament.id
                )),
            });

            this.setState(() => ({dataLoaded: true}));
        }

        if (selectedTournamentCategoriesAndSports && _isEmpty(this.state.selectedTournamentCategoriesData)) {
            this.getSelectedTournamentCategories(selectedTournamentCategoriesAndSports.tournamentCategories);
        }

        if (clipConfig && _isEmpty(this.state.TournamentCategoriesData)) {
            this.setTournamentCategories(selectedTournamentCategoriesAndSports.sports);
        }

        if (clipConfig && !_isEmpty(this.state.selectedTournamentCategoriesData) && _isEmpty(this.state.TournamentData) && !this.state.TournamentDataLoading) {
            this.setTournaments(
                this.state.selectedTournamentCategoriesData.map((tournamentCategorie) => (
                    convertToInt(tournamentCategorie.id)
                )),
                clipConfig.product.id,
                clipConfig.tournaments
            );
        }

        if (this.props.formParams.id && this.props.formValues.name
            && prevProps.formValues.name === this.props.formValues.name) {
            this.props.Model.title = `Clip configuration "${this.props.formValues.name}"`;
        }
    }

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.fields[this.props.Model.entityDataMapKey],
                {
                    id: this.props.formParams.id,
                    name: data.name,
                    product: convertToInt(data.products),
                    sports: null,
                    tournaments: data.tournaments,
                    clipConfigEventContentType: formatToSaveClipsOptions(data.eventContentTypes),
                }
            );
    }

    renderErrors() {
        super.renderErrors(
            'The requested clip configuration could not be found.',
            this.props.Model.entityLabel,
            createLinkToIndexPage()
        );
    }
    onCancel = () => {
        this.props.history.push(createLinkToIndexPage());
    };

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});

    renderCancelButton = (props) => super.renderCancelButton({...props, type: 'button', onClick: this.onCancel});
}

export default withRouter(ClipConfigurationTournamentLevelForm);
