import {isBoolean as _isBoolean} from "lodash";
import {useEffect, useState} from "react";
import {PropTypes} from "prop-types";

import client from "../../apolloClient";

const DEFAULT_OPTIONS = {
    variables: {},
    fetchPolicy: "network-only",
    skip: false,
};

function useQuery({
    query,
    options = {},
}) {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    async function fetchData() {
        await client.query({
            query: query,
            variables: options.variables || DEFAULT_OPTIONS.variables,
            fetchPolicy: options.fetchPolicy || DEFAULT_OPTIONS.fetchPolicy,
        }).then((response) => {
            setData(response);
        }).catch((e) => {
            setError(e.message);
        });
    }

    useEffect(() => {
        const skip = _isBoolean(options.skip) ? options.skip : DEFAULT_OPTIONS.skip;

        if (!skip) {
            setData({loading: true});
            fetchData();
        }
    }, [options.refetch]);

    return {...data, error};
}
useQuery.propTypes = {
    query: PropTypes.object.isRequired,
    options: PropTypes.shape({
        fetchPolicy: PropTypes.string,
        refetch: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]),
        skip: PropTypes.bool,
        variables: PropTypes.object,
    }),
};

export default useQuery;
