import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';

import ProductForm from '../forms/ProductForm';
import ProductModel from '../forms/ProductModel';

export function ProductEdit({
    match: {
        params: {
            id,
        },
    },
}) {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Product', routes.products.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(ProductModel, ProductForm, {id: convertToInt(id)})),
        []
    );

    return <Form/>;
}

ProductEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

export default withRouter(ProductEdit);
