import {isEmpty as _isEmpty, sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {getLink} from '@appComponents/Link';
import {prepareStringForCidrSorting} from '@utils/CidrHelpers';
import {loadModalForm, openDisableModal as openDisableModalHelper} from '@utils/disableCidrHelpers';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid, digitsOnly} from '@utils/helpers';
import {showModal} from '@utils/modal';
import useQuery from '@utils/hooks/useQuery';
import {routes} from '@constants/routes';
import {GetClientWhitelistedIps} from '@graphql/clientWhitelistedIp/query';

import {exportExcel} from '../utils/export/whitelistedIpsIndexExcel';
import {ClientWhitelistHeaderMessage, renderWhitelistedIpModalError} from '../utils/whitelistingHelper';
import WhitelistedIpModelEdit from '../forms/WhitelistedIpModelEdit';
import WhitelistedIpForm from '../forms/WhitelistedIpForm';
import WhitelistedIpTable from '../components/WhitelistedIpTable';
import WhitelistedIpEdit from './WhitelistedIpEdit';
import WhitelistedIpIndexButtons from '../components/WhitelistedIpIndexButtons';

export function WhitelistedIpIndex({
    history,
    match: {
        path,
        params: {
            id,
            whitelistedIpId = null,
        },
    },
    isVisible,
}) {
    const [mutationSuccessCallback, setMutationSuccessCallback] = useState(null),
        whitelistedIpIndexLink = getLink('clients.whitelistedIps.geoRestrictions.index', {id: convertToInt(id)}),
        {
            loading = true,
            error = {},
            data = {},
        } = useQuery({
            query: GetClientWhitelistedIps,
            options: {
                variables: {
                    notifyOnNetworkStatusChange: true,
                    client: id,
                },
                refetch: mutationSuccessCallback,
            },
        });

    const WhitelistedIpMessage = `
        Geo-restrictions will not be applied by the AV-API for the IP addresses defined
        here, which means that the end-users behind these IP addresses will be able to
        stream all content booked by the client, no matter the countries/subdivisions
        booked for that content.
        <br/>
        IP addresses which should be whitelisted are typically IP addresses from the
        offices of the client, from where the client needs to test its integration of our
        streams.
        <br/>
        Please use this feature wisely, whitelist IP addresses as little as possible, and do not use large IP
        ranges.
        <br/>
    `;

    if (!_isEmpty(error)) {
        showModal({
            isVisible: true,
            content: renderWhitelistedIpModalError(whitelistedIpIndexLink, error.message),
        });
    }

    if (!isUrlParamValid(whitelistedIpId)) {
        showModal({
            isVisible: true,
            content: renderModalError('Whitelisted IP', getLink('clients.whitelistedIps.geoRestrictions.index', {
                id: id,
            })),
        });
    }

    const {
            add,
            edit,
        } = routes.clients.whitelistedIps.geoRestrictions,
        {whitelistedIps} = data,
        [modalVisibility, setModalVisibility] = useState(isVisible);

    useEffect(() => {
        if (add.path === path && digitsOnly(id)) {
            showModal({
                isVisible: true,
                size: 'small',
                content: <WhitelistedIpEdit
                    clientId={id}
                    setMutationSuccessCallback={setMutationSuccessCallback}
                />,
            });
            setModalVisibility(true);
        } else if (edit.path === path && digitsOnly(id) && digitsOnly(whitelistedIpId)) {
            loadModalForm(
                createForm(
                    WhitelistedIpModelEdit,
                    WhitelistedIpForm,
                    {
                        id: whitelistedIpId,
                        optionsVariables: {
                            client: convertToInt(id),
                        },
                        setMutationSuccessCallback,
                    }
                ),
                {id, whitelistedIpId},
                setModalVisibility
            );
        }
    }, [path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            history.push(whitelistedIpIndexLink);
        }
    }, [isVisible]);

    const openDisableModal = (value) => {
        const cidrData = {
            id: value.id,
            cidr: value.cidr,
            products: value.products,
        };

        openDisableModalHelper(
            cidrData,
            whitelistedIpIndexLink,
            history,
            setMutationSuccessCallback
        );
    };

    return (
        <div>
            <ClientWhitelistHeaderMessage message={WhitelistedIpMessage}/>
            <HeaderRenderer
                buttons={WhitelistedIpIndexButtons}
                buttonsProps={{client: convertToInt(id)}}
                exportExcelParams={exportExcel(
                    _sortBy(whitelistedIps, [prepareStringForCidrSorting]),
                    id
                )}
                loading={loading}
                messagesBoxNames={['whitelistedIpMessage', 'formInnerErrorMessage']}
            />
            <WhitelistedIpTable
                clientId={id}
                disableClick={openDisableModal}
                loading={loading}
                whitelistedIps={whitelistedIps}
            />
        </div>
    );
}

WhitelistedIpIndex.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            whitelistedIpId: PropTypes.string,
        }),
    }),
    isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isVisible: state.modal.isVisible,
});

export default withRouter(connect(mapStateToProps)(WhitelistedIpIndex));
