import Form from "@appComponents/ReduxFormControls";

import * as MESSAGES from "@constants/messages";
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from "@constants/variables";
import {EVENT_GEO_RESTRICTION} from "@constants/resources";
import validatorEventGeoRestriction from "../validatorGeoRestriction";

import {CreateEventGeoRestriction} from "@graphql/eventGeoRestriction/mutation";
import {GeoRestrictionsDropdownOptionsForCountries} from "@graphql/eventGeoRestriction/query"

export default function GeoRestrictionsFormModel() {
    return {
        entityDataMapKey: "eventGeoRestriction",
        entityLabel: "geo restriction",
        formName: "EventGeoRestriction",
        label: "geo restriction",
        resources: EVENT_GEO_RESTRICTION,
        showChangeLog: false,
        title: null,
        validate: validatorEventGeoRestriction,
        dataMap: {
            eventGeoRestriction: {
                selection_type: {
                    id: "1",
                }
            }
        },
        messages: {
            boxName: {
                delete: 'event',
                error: 'event',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "eventGeoRestriction.id",
                props: {
                    hidden: true,
                    label: '',
                    name: "id",
                }
            },
            selection_type: {
                optionsKey: "territorySelectionTypes",
                props: {
                    component: Form.SemanticSelect,
                    defaultValue: TERRITORY_SELECTION_TYPE_WORLDWIDE + "",
                    label: "Selection type",
                    name: "selection_type",
                    required: true,
                }
            },
            countries: {
                optionsKey: "continents",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Countries",
                    name: "countries",
                    required: true,
                }
            },
            subdivisions: {
                optionsKey: "countries",
                props: {
                    className: "geoRestrictions",
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: "Subdivisions",
                    name: "subdivisions",
                    required: true,
                }
            }
        },
        mutation: {
            createEntity: CreateEventGeoRestriction
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            update: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            delete: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
        },
        query: {
            getOptions: GeoRestrictionsDropdownOptionsForCountries
        }
    }
}
