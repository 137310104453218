import PropTypes from "prop-types";
import React from "react";
import {Segment} from "semantic-ui-react";
import {withRouter} from "react-router";

import ProductLciGeoRestrictionsForm from "../forms/ProductLciGeoRestrictionsForm";
import ProductLciGeoRestrictionsModel from "../forms/ProductLciGeoRestrictionsModel";

import {createForm} from "../../../utils/forms";

export class ProductLciGeoRestrictionsEdit extends React.PureComponent {
    static propTypes = {
        clientProductId: PropTypes.number.isRequired,
    };

    renderForm = () => {
        const Form = createForm(
            ProductLciGeoRestrictionsModel,
            ProductLciGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };

    render() {
        return (
            <div className="client_products_geo_restrictions">
                <Segment basic>
                    {this.renderForm()}
                </Segment>
            </div>
        );
    }
}

export default withRouter(ProductLciGeoRestrictionsEdit)
