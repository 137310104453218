import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

/**
 * Get value from object structure with properties:
 * - srWithMatchID,
 * - srWithoutMatchID,
 * - nonSr
 *
 * for different type of event
 */
export const getProperty = ({ data, srWithMatchID = [], srWithoutMatchID = [], nonSr = [] }, propertyName = 'name') => {
    if (data.sr_event) {
        if (data._hasMatchID) {
            return _get(data.sr_event, [...srWithMatchID, propertyName], null);
        }

        return _get(data.sr_event, [...srWithoutMatchID, propertyName], null);
    }

    return _get(data.av_event, [...nonSr, propertyName], null);
};

getProperty.propTypes = {
    data: PropTypes.shape({
        sr_event: PropTypes.object,
        av_event: PropTypes.object,
    }),
    _hasMatchID: PropTypes.bool.isRequired,
};

getProperty.defaultProps = {
    data: {
        sr_event: null,
        av_event: null,
    },
};

export const getContentTierName = () => {
    return '';

    /** Logic for getting tier name was removed, contents can't be reached from propertyLicence entity anymore.*/
};

getContentTierName.propTypes = {
    data: PropTypes.shape({
        av_event: PropTypes.object,
        property_licence: PropTypes.shape({
            contents: PropTypes.array,
        }),
        sr_event: PropTypes.object,
    }),
    _hasMatchID: PropTypes.bool.isRequired,
};

getContentTierName.defaultProps = {
    data: {
        av_event: null,
        property_licence: {
            contents: [],
        },
        sr_event: null,
    },
};
