import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
/* eslint import/no-unresolved: 0 */
import {withRouter} from 'react-router';
import {Segment} from 'semantic-ui-react';
import {
    get as _get, sortBy as _sortBy,
} from 'lodash';

import {gql, graphql} from 'react-apollo';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {getSearchFilters} from '@utils/filters';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {eventStreamsFiltersQuery} from '@graphql/streams/streams';
import mapModulesToProps from '@utils/mapModulesToProps';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {EventStreamsQuery} from '@graphql/streams/query';
import {showModal} from '@utils/modal';

import EventStreamsTable from '../components/EventStreamsTable';
import EventStreamsIndexButtons from '../components/EventStreamsIndexButtons';
import EventStreamsFilters from '../components/EventStreamsFilters';
import EventStreamForm from '../forms/EventStreamForm';
import EventStreamModel from '../forms/EventStreamModel';
import EventStreamModelEdit from '../forms/EventStreamModelEdit';
import {exportExcel as eventStreamsIndexExportExcel} from '../utils/export/eventStreamsIndexExcel';
import {getEventContentText} from '@utils/eventContentVariant/eventContentVariant';

export class EventStreamsIndex extends React.Component {
    static propTypes = {
        DataEventStreams: PropTypes.object,
        DataEventStreamsFilters: PropTypes.object,
        filters: PropTypes.shape({
            contents: PropTypes.arrayOf(PropTypes.number),
            deviceCategories: PropTypes.arrayOf(PropTypes.number),
            distributionTypes: PropTypes.arrayOf(PropTypes.number),
            formats: PropTypes.arrayOf(PropTypes.number),
            products: PropTypes.arrayOf(PropTypes.number),
            protocols: PropTypes.arrayOf(PropTypes.number),
            search: PropTypes.string,
            streamDelayTypes: PropTypes.arrayOf(PropTypes.number),
            streamStatuses: PropTypes.arrayOf(PropTypes.number),
            eventContentTypes: PropTypes.arrayOf(PropTypes.number),
            eventContentVariants: PropTypes.arrayOf(PropTypes.number),
        }),
        history: PropTypes.object,
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.object,
        Modal: PropTypes.object,
        modal: PropTypes.object,
    };

    static defaultProps = {
        DataEventStreams: {},
        DataEventStreamsFilters: {},
        filters: {
            contents: [],
            deviceCategories: [],
            distributionTypes: [],
            formats: [],
            products: [],
            protocols: [],
            search: null,
            streamDelayTypes: [],
            streamStatuses: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
    };

    constructor() {
        super();

        this.state = {
            originalStreams: [],
            streams: [],
        };
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }

        this.loadModal(this.props.match.path);
    }

    componentDidUpdate(prevProps) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.events.streams.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex;

        shouldOpenModal && this.loadModal(this.props.match.path, this.props.match.params);

        const modalChanged = prevProps.modal.isVisible !== this.props.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(
            getLink('events.streams.index', {id: this.props.match.params.id})
        );

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.DataEventStreams.streams && nextProps.DataEventStreams.streams !== prevState.originalStreams) {
            let sortedStreams = _sortBy(nextProps.DataEventStreams.streams, [
                (stream) => (convertToInt(stream.target.distribution.event_content.id)),
                (stream) => (convertToInt(stream.target.distribution.product.id)),
                (stream) => (convertToInt(stream.target.distribution.distribution_type.id)),
                (stream) => (stream.target.stream_delay_type ? convertToInt(stream.target.stream_delay_type.id) : null),
                (stream) => (convertToInt(stream.device_category.id)),
                (stream) => (convertToInt(stream.protocol.id)),
                (stream) => (convertToInt(stream.bitrate_video)),
            ]);

            return {
                originalStreams: nextProps.DataEventStreams.streams,
                streams: sortedStreams,
            };
        }

        return null;
    }

    loadModal(path, params = {}) {
        if (!isUrlParamValid(this.props.match.params.streamId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Stream',
                    getLink('events.streams.index',
                        {id: this.props.match.params.id})),
            });
        }

        switch (path) {
            case routes.events.streams.add.path:
                return this.loadModalForm(createForm(
                    EventStreamModel,
                    EventStreamForm,
                    {
                        optionsVariables: {
                            eventId: convertToInt(this.props.match.params.id),
                        },
                    }
                ));

            case routes.events.streams.edit.path:
                return this.loadModalForm(createForm(
                    EventStreamModelEdit,
                    EventStreamForm,
                    {
                        id: this.props.match.params.streamId || params.streamId,
                        dataRequest: true,
                        optionsVariables: {
                            eventId: convertToInt(this.props.match.params.id),
                        },
                    }
                ), params.data);
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });
    };

    prepareContentNamesForDropdown(event_contents) {
        if (event_contents) {
            return event_contents.map((event_content) => ({
                id: event_content.id,
                value: event_content.id,
                text: getEventContentText(event_content),
            }));
        }

        return null;
    }

    render() {
        const loading = (
            this.props.DataEventStreams.loading
            || this.props.DataEventStreamsFilters.loading
            || this.props.loadingRefetch
        );

        return (
            <div>
                <HeaderRenderer
                    buttons={EventStreamsIndexButtons}
                    buttonsProps={{eventId: convertToInt(this.props.match.params.id)}}
                    exportExcelParams={eventStreamsIndexExportExcel(
                        this.state.streams,
                        convertToInt(this.props.match.params.id)
                    )}
                    filters={EventStreamsFilters}
                    filtersProps={{
                        data: {
                            contents: this.props.DataEventStreamsFilters.eventContents && this.prepareContentNamesForDropdown(this.props.DataEventStreamsFilters.eventContents).mapDataForDropdownWithIntVal(),
                            deviceCategories: _get(this.props, 'DataEventStreamsFilters.deviceCategories', []).mapDataForDropdownWithIntVal(),
                            distributionTypes: _get(this.props, 'DataEventStreamsFilters.distributionTypes', []).mapDataForDropdownWithIntVal(),
                            products: _get(this.props, 'DataEventStreamsFilters.products', []).mapDataForDropdownWithIntVal(),
                            streamDelayTypes: _get(this.props, 'DataEventStreamsFilters.streamDelayTypes', []).mapDataForDropdownWithIntVal(),
                            streamFormats: _get(this.props, 'DataEventStreamsFilters.streamFormats', []).mapDataForDropdownWithIntVal(),
                            streamProtocols: _get(this.props, 'DataEventStreamsFilters.streamProtocols', []).mapDataForDropdownWithIntVal(),
                            streamStatuses: _get(this.props, 'DataEventStreamsFilters.streamStatuses', []).mapDataForDropdownWithIntVal(),
                            eventContentTypes: _get(this.props, 'DataEventStreamsFilters.eventContentTypes', []).mapDataForDropdownWithIntVal(),
                            eventContentVariants: _get(this.props, 'DataEventStreamsFilters.eventContentVariants', []).mapDataForDropdownWithIntVal(),
                        },
                        loading: loading,
                    }}
                    loading={loading}
                    messagesBoxNames='eventStreamsMessage'
                />
                <Segment basic className='--table'>
                    <EventStreamsTable
                        eventId={convertToInt(this.props.match.params.id)}
                        loading={loading}
                        streams={this.state.streams}
                    />
                </Segment>
            </div>
        );
    }
}

const withQuery = compose(
    graphql(EventStreamsQuery, {
        options: (props) => {
            const defaultFiltersValues = EventStreamsIndex.defaultProps.filters;
            const { filters } = props;

            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    contents: (filters.contents)
                        ? filters.contents
                        : defaultFiltersValues.contents,
                    deviceCategories: (filters.deviceCategories)
                        ? filters.deviceCategories
                        : defaultFiltersValues.deviceCategories,
                    distributionTypes: (filters.distributionTypes)
                        ? filters.distributionTypes
                        : defaultFiltersValues.distributionTypes,
                    eventId: convertToInt(props.match.params.id),
                    eventContentTypes: (filters.eventContentTypes)
                        ? filters.eventContentTypes
                        : defaultFiltersValues.eventContentTypes,
                    eventContentVariants: (filters.eventContentVariants)
                        ? filters.eventContentVariants
                        : defaultFiltersValues.eventContentVariants,
                    formats: (filters.formats)
                        ? filters.formats
                        : defaultFiltersValues.formats,
                    products: (filters.products)
                        ? filters.products
                        : defaultFiltersValues.products,
                    protocols: (filters.protocols)
                        ? filters.protocols
                        : defaultFiltersValues.protocols,
                    search: filters.search || '',
                    streamDelayTypes: (filters.streamDelayTypes)
                        ? filters.streamDelayTypes
                        : defaultFiltersValues.streamDelayTypes,
                    streamStatuses: (filters.streamStatuses)
                        ? filters.streamStatuses
                        : defaultFiltersValues.streamStatuses,
                },
            };
        },
        name: 'DataEventStreams',
    }),
    graphql(gql(eventStreamsFiltersQuery), {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                variables: {
                    eventId: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataEventStreamsFilters',
    })
)(FilterQueryWrapper(EventStreamsIndex, {
    queryForRefresh: 'DataEventStreams',
    filterUrls: ['events.streams.index'],
}));

const mapStateToProps = (state) => {
    return ({
        filters: getSearchFilters(state, 'EventsStreams', EventStreamsIndex.defaultProps.filters),
        modal: state.modal,
    });
};

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(
    FilterUrlParamsWrapper(withQuery, EventStreamsIndex.defaultProps.filters)
));
