import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {ButtonBackToList} from '@appComponents/ButtonCollection';
import Tabs from '@appComponents/Tabs';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import GeneralInformationModel from '@modules/blackoutZone/forms/GeneralInformationModel';
import GeneralInformationForm from '@modules/blackoutZone/forms/GeneralInformationForm';
import BlackoutZonePostalCodes from '@modules/blackoutZone/views/BlackoutZonePostalCodes';
import {GetBlackoutZone} from '@graphql/blackoutZone/query';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import CountriesAndSubdivisionsModel from '@modules/blackoutZone/forms/CountriesAndSubdivisionsModel';
import CountriesAndSubdivisionsForm from '@modules/blackoutZone/forms/CountriesAndSubdivisionsForm';
import navigation from '@constants/navigation';
import mapModulesToProps from '@utils/mapModulesToProps';
import {showModal} from '@utils/modal';

export const BlackoutZoneEdit = (props) => {
    props.Menu.storeMenu(navigation.mediaRights.key);

    const backToListButton = () => <ButtonBackToList name='blackoutZones.index' />,
        id = props.match.params.id,
        formParams = {};

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Blackout zones', routes.blackoutZones.index.path),
        });
    }

    if (id) {
        formParams['id'] = convertToInt(id);
    }

    const Form = createForm(GeneralInformationModel, GeneralInformationForm, formParams),
        tabs = {
            [routes.blackoutZones.edit.generalInformation.path]: {
                key: routes.blackoutZones.edit.generalInformation.path,
                url: 'blackoutZones.edit.generalInformation',
                urlParams: {id},
                text: 'General settings',
                render: <Form />,
            },
        };

    let activeTabDefault = props.match.path;

    if (routes.blackoutZones.add.path === props.match.path) {
        activeTabDefault = routes.blackoutZones.edit.generalInformation.path;
        tabs[routes.blackoutZones.edit.generalInformation.path]['urlParams'] = {id: null};
    } else {
        const CountriesAndSubdivisionForm = createForm(
            CountriesAndSubdivisionsModel, CountriesAndSubdivisionsForm, {id}
        );

        tabs[routes.blackoutZones.edit.countriesAndSubdivisions.path] = {
            key: routes.blackoutZones.edit.countriesAndSubdivisions.path,
            url: 'blackoutZones.edit.countriesAndSubdivisions',
            urlParams: {id},
            text: 'Countries and Subdivisions',
            render: <CountriesAndSubdivisionForm/>
        };

        tabs[routes.blackoutZones.edit.postalCodes.index.path] = {
            key: routes.blackoutZones.edit.postalCodes.index.path,
            url: 'blackoutZones.edit.postalCodes.index',
            urlParams: {id},
            text: 'Postal codes',
            render: <BlackoutZonePostalCodes/>,
        };
    }

    const [activeTab, setActiveTab] = useState(activeTabDefault);

    useEffect(() => {
        if (routes.blackoutZones.add.path === props.match.path) {
            return;
        }

        if (routes.blackoutZones.edit.postalCodes.add.path === props.match.path) {
            setActiveTab(routes.blackoutZones.edit.postalCodes.index.path);

            return;
        }

        setActiveTab(props.match.path);
    }, [props.match.path]);

    return (
        <div>
            <HeaderRenderer
                buttons={backToListButton}
                messagesBoxNames='BlackoutZoneEdit'
                pageTitle={<TitleFromQuery
                    query={GetBlackoutZone}
                    variables={{id}}
                    errorBackLink={routes.blackoutZones.index.path}
                    path={'blackoutZone.name'}
                    entity={'Blackout Zone'}
                />}
            />
            <Tabs active={activeTab} items={tabs}/>
        </div>
    );
};

BlackoutZoneEdit.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    Menu: PropTypes.object
};

export default withRouter(connect(null, mapModulesToProps(['Menu']))(BlackoutZoneEdit));
