import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {includes as _includes} from "lodash"

import CdnConfigurationsIndex from "./CdnConfigurationsIndex";
import {routes} from "../../app/constants/routes";
import ClientCdnConfigurationLayout from "../layouts/ClientCdnConfigurationLayout";

import {EXPORT_EXCEL_URL_FRAGMENT} from "../../app/constants/variables";

const CdnConfigurationsTab = (props) => {
    const {match} = props;

    const getTabRenderState = () => {
        const cdnConfigurationRoute = routes.clients.clientCdnConfiguration,
            renderClientCdnConfigurationsLayout = _includes(
                [
                    cdnConfigurationRoute.add.path,
                    cdnConfigurationRoute.edit.path,
                    cdnConfigurationRoute.akamaiLiveStreamName.index.path,
                    `${cdnConfigurationRoute.akamaiLiveStreamName.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
                    cdnConfigurationRoute.akamaiLiveStreamName.add.path,
                    cdnConfigurationRoute.akamaiLiveStreamName.edit.path
                ],
                match.path
            );

        return (renderClientCdnConfigurationsLayout)
            ? <ClientCdnConfigurationLayout/>
            : <CdnConfigurationsIndex clientId={parseInt(match.params.id, 10)}/>;
    };

    return getTabRenderState();
};

CdnConfigurationsTab.propTypes = {
    match: PropTypes.object.isRequired,
};

export default withRouter(CdnConfigurationsTab)
