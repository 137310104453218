import EventStreamModel from './EventStreamModel';

export default function EventStreamModelEdit() {
    let eventStreamModelEdit = EventStreamModel();
    eventStreamModelEdit.title = () => {
        return "Edit stream"
    };

    return eventStreamModelEdit;
}
