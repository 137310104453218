import validators from '@utils/validators';

const validationGeneralInformation = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));

    return errors;
};

export default validationGeneralInformation;
