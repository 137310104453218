import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';

import {gql, graphql} from 'react-apollo';
import * as eventContentSubtitleGraphQl from '@graphql/events/eventSubtitle';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import MessageBox from '@appComponents/MessageBox';

import EventContentSubtitleTable from '../components/EventContentSubtitleTable';

const EventContentSubtitleModal = (props) => {
    return(
        <div className='eventMultiSubtitlesContainer editableTable'>
            <MessageBox name='eventSubtitleMessage'  className='--multiAudioModal'/>
            <EventContentSubtitleTable
                properties={props}
            />
        </div>
    );
};

const ListQuery = gql(eventContentSubtitleGraphQl.eventContentSubtitles);
const CreateMutation = gql(eventContentSubtitleGraphQl.CreateMutation);
const DeleteMutation = gql(eventContentSubtitleGraphQl.DeleteMutation);
const UpdateMutation = gql(eventContentSubtitleGraphQl.UpdateMutation);

EventContentSubtitleModal.propTypes = {
    Modal: PropTypes.object,
    MessageBox: PropTypes.object,
    DataEventContentSubtitle: PropTypes.object,
    Form: PropTypes.object,
    encodingTargetId: PropTypes.number.isRequired,
    subtitleToSave: PropTypes.object,
    subtitleToUpdate: PropTypes.object,
    CreateMutation: PropTypes.func,
    DeleteMutation: PropTypes.func,
    UpdateMutation: PropTypes.func,
};
EventContentSubtitleModal.defaultProps = {
    subtitles: [],
    streamLanguages: [],
    encodingTargetsSr: [],
    loading: false,
};

const EventContentSubtitleModalWithQuery = compose(
    graphql(ListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    encodingTargetId: convertToInt(props.encodingTargetId),
                },
            };
        },
        name: 'DataEventContentSubtitle',
    }),

    graphql(CreateMutation, { name: 'CreateMutation' }),
    graphql(DeleteMutation, { name: 'DeleteMutation' }),
    graphql(UpdateMutation, { name: 'UpdateMutation' })
)(EventContentSubtitleModal);

const mapStateToProps = (state) => {
    let subtitleToSave = null;
    let subtitleToUpdate = null;

    if (state.form.form_es_update !== undefined) {
        subtitleToUpdate = (state.form.form_es_update.values);
    }

    if (state.form.form_es_add !== undefined) {
        subtitleToSave = (state.form.form_es_add.values);
    }

    return {
        subtitleToSave: subtitleToSave,
        subtitleToUpdate: subtitleToUpdate,
    };
};

export default withRouter(
    connect(
        mapStateToProps, mapModulesToProps(['Modal', 'MessageBox', 'Form'])
    )(EventContentSubtitleModalWithQuery)
);
