import PropTypes from 'prop-types';
import React from 'react';

import AvApiAccessModel from '@modules/client/forms/AvApiAccessModel';
import {cidrFormTabs} from '@utils/CidrHelpers';
import {createForm} from '@utils/forms';
import {convertToInt} from '@utils/helpers';

import StepsContent from '../../app/components/StepsContent';
import WhitelistedIpForm from '../forms/WhitelistedIpForm';
import WhitelistedIpModel from '../forms/WhitelistedIpModel';

export function WhitelistedIpEdit({clientId, setMutationSuccessCallback, isAvApiAccess = false}) {
    const content = createForm(
            isAvApiAccess ? AvApiAccessModel : WhitelistedIpModel,
            WhitelistedIpForm,
            {
                optionsVariables: {
                    client: convertToInt(clientId),
                },
                setMutationSuccessCallback,
                isAvApiAccess,
            }
        ),
        tabs = cidrFormTabs(content);

    return <StepsContent
        tabs={tabs}
        hideHeader={true}
        hideMenu={true}
        title={'Add new CIDR'}
    />;
}
WhitelistedIpEdit.propTypes = {
    clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setMutationSuccessCallback: PropTypes.func.isRequired,
    isAvApiAccess: PropTypes.bool,
};
WhitelistedIpEdit.displayName = 'WhitelistedIpEdit';

export default WhitelistedIpEdit;
