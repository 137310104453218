import React from "react";
import {
    findIndex as _findIndex,
    get as _get,
    has as _has,
    isEmpty as _isEmpty
} from "lodash";
import {Header, Tab} from "semantic-ui-react";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {touch} from "redux-form";
import {connect} from "react-redux";

export class StepsContent extends React.Component {
    static propTypes = {
        activeTab: PropTypes.string,
        forms: PropTypes.object.isRequired,
        isAddMode: PropTypes.bool,
        className: PropTypes.string,
        hideHeader: PropTypes.bool,
        hideMenu: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }),
        tabs: PropTypes.arrayOf(PropTypes.object),
        title: PropTypes.string,
        touch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        activeTab: null,
        className: "",
        hideHeader: false,
        hideMenu: false,
        isAddMode: false,
        tabs: [],
        title: "",
    };

    constructor(props) {
        super(props);

        this.tabs = props.tabs;
        this.stepComponentMethods = {
            enableStep: this.enableStep,
            setStep: this.setStep,
            showStep: this.showStep,
            showPreviousStep: this.showPreviousStep,
            validateForms: this.validateForms,
            getForms: this.getForms,
            setForm: this.setForm
        };

        let panes = [];

        this.tabs.forEach((Tab) => {
            if (!_isEmpty(Tab)) {
                let Component = _get(Tab, "pane.content", null),
                    keyPrefix = (Tab.key || `step${Tab.key}`),
                    pane = null;

                if (Component) {
                    if ("object" !== typeof(Component)) {
                        pane = <Component
                            stepsMethods={this.stepComponentMethods}
                        />
                    } else {
                        pane = React.cloneElement(Component, {stepsMethods: this.stepComponentMethods})
                    }
                }

                if (_has(Tab, "menuItem")) {
                    Tab.menuItem.key = `${keyPrefix}menuItem`;

                    panes.push({
                        menuItem: Tab.menuItem,
                        id: keyPrefix,
                        pane: {key: `${keyPrefix}pane`, content: pane},
                        url: Tab.url
                    });
                }
            }
        });

        const active = _findIndex(panes, {url: props.location.pathname});
        let stateObject = {
            data: {},
            forms: {},
            panes: panes
        };

        if (!this.props.activeTab) {
            stateObject.activeIndex = (0 <= active ? active : 0);
        }

        this.state = {
            ...stateObject
        };
    }

    /**
     * setStep
     *
     * Setting content for step by id, or any other props.
     *
     * example:
     * {
     *     id: "step2"
     *     menuItem: {content: "Label for step", disabled: false}
     *     pane: {content: <Component>}
     * }
     *
     * @param step
     */
    setStep = (step) => {
        if (!step.id) {
            throw new Error("Id key in step not set!");
        }

        let panes = this.state.panes.concat(),
            currentStepIndex = _findIndex(this.state.panes, {id: step.id});

        if (0 <= currentStepIndex) {
            const stepsMethods = step.pane.props
                ? Object.assign({}, {stepsMethods: {...this.stepComponentMethods}, ...step.pane.props})
                : Object.assign({}, {stepsMethods: {...this.stepComponentMethods}});
            let tab = panes[currentStepIndex],
                Component = _get(step, 'pane.content', tab.pane.content);

            tab.menuItem.content = _get(step, 'menuItem.content', tab.menuItem.content);
            tab.menuItem.disabled = _get(step, 'menuItem.disabled', tab.menuItem.disabled);
            tab.pane.content = React.cloneElement(Component, stepsMethods);
        } else {
            panes = panes.concat(step)
        }

        this.setState(() => ({
            panes: panes
        }));
    };

    /**
     * enableStep
     *
     * Shorthand for switching tab status
     *
     * @param id
     */
    enableStep = (id) => {
        this.setStep({id: id, menuItem: {disabled: false}});
    };

    showPreviousStep = (event) => {
        let newActiveIndex = this.state.activeIndex - 1;

        event.preventDefault();
        event.stopPropagation();

        if (newActiveIndex >= 0) {
            this.setState(() => ({
                activeIndex: newActiveIndex
            }))
        }
    };

    showStep = (id) => {
        let stepIndex = _findIndex(this.state.panes, {id: id});

        if (stepIndex > -1) {
            this.setState(() => ({
                activeIndex: stepIndex
            }))
        }
    };

    getForms = () => {
        return this.state.forms;
    };

    setForm = (formName, formData = {}) => {
        const forms = this.state.forms;

        if (formName) {
            forms[formName] = formData;

            this.setState(() => ({
                forms: forms
            }));
        }
    };

    validateForms = () => {
        const forms = this.props.forms;
        let validationResult = true;

        Object.keys(forms).forEach(formName => {
            let formErrors = forms[formName].syncErrors;

            if (undefined !== formErrors) {
                validationResult = false;

                Object.keys(formErrors).forEach(fieldName => {
                    this.props.touch(formName, fieldName);
                })
            }
        });

        return validationResult;
    };

    onTabChange = (event, data) => {
        if (this.props.isAddMode) {
            return
        }

        if (this.tabs[data.activeIndex].url) {
            window.history.pushState({}, '', this.tabs[data.activeIndex].url);
        }

        this.setState(() => ({
            activeIndex: data.activeIndex
        }));
    };

    render() {
        return <div className={`stepsContent ${this.props.className}`}>
            {!this.props.hideHeader && <Header>{this.props.title}</Header>}
            <Tab
                activeIndex={this.state.activeIndex}
                menu={{className: (this.props.hideMenu && "hidden"), secondary: true, pointing: true}}
                onTabChange={this.onTabChange}
                panes={this.state.panes}
                renderActiveOnly={false}
            />
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        touch: (formName, fieldName) => {
            dispatch(touch(formName, fieldName))
        }
    }
};

const mapStateToProps = (state) => {
    return {
        forms: state.form
    }
};

export const StepsContentWithRedux = connect(mapStateToProps, mapDispatchToProps)(StepsContent);
const StepsContentWithRouter = withRouter((StepsContentWithRedux));
export default StepsContentWithRouter
