import PropTypes from 'prop-types';

import {
    renderLcrContentsAsText,
    renderLcrCountriesForExcelExport,
} from '@modules/lcr/utils/lcrChannels';
import {getYesOrNo} from '@utils/helpers';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'lcr_channels',
    parseData: lcrChannel => [
        lcrChannel.id,
        lcrChannel.name,
        lcrChannel.description,
        getYesOrNo(lcrChannel.has_lmt_support),
        renderLcrCountriesForExcelExport(lcrChannel),
        renderLcrContentsAsText(lcrChannel),
    ],
    path: 'lcrChannel.index.path',
    titles: [
        'Id',
        'Name',
        'Description',
        'LMT',
        'Territories',
        'Content',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
