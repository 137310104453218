import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';

export class LicenseeForm extends DefaultForm {
    setLinkToIndexPage = () => (
        this.props.history.push(getLink('licensees.index'))
    );

    componentDidMount() {
        this.setDeleteSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setCreateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setUpdateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {country_id: (data && data.country_id) ? convertToInt(data.country_id) : null}
        )
    );

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            this.props.Model.label,
            getLink('licensees.index'),
            {size: 'tiny'}
        );
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderCancelButton = () => null;
}

export default withRouter(LicenseeForm);
