import PropTypes from 'prop-types';
import React from 'react';
import {orderBy as _orderBy} from 'lodash';
import {Field} from 'redux-form';

import Form from '@appComponents/ReduxFormControls';



const parseEventContentVariantDefaultValues = (variants) => {
    if (!variants) {
        return [];
    }

    return variants.map(
        (variant) => Number(variant.id)
    );
};

const EventContentVariantsField = (props) => {
    const optionsForContentVariants = _orderBy(props.eventContentVariants
        .filter(variant =>
            props.productVariants
                .find(productVariant => productVariant.event_content_variant.id === variant.id)
        ), (row) => (row.id), ['asc']);

    return (
        <Field
            component={Form.SemanticCheckboxList}
            required
            defaultValue={parseEventContentVariantDefaultValues(props.selectedContentVariants)}
            options={optionsForContentVariants}
            allowClear={true}
            name='event_content_variants'
            type='checkbox'
        />
    );
};

EventContentVariantsField.propTypes = {
    productVariants: PropTypes.array,
    eventContentVariants: PropTypes.array,
    selectedContentVariants: PropTypes.array,
};

export default EventContentVariantsField;
