import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Dimmer, Loader} from 'semantic-ui-react';

import {createForm, renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import useQuery from '@utils/hooks/useQuery';

import EditUserResourcesForm from '../forms/EditUserResourcesForm';
import EditUserResourcesModel from '../forms/EditUserResourcesModel';
import {GetUserResourcesData} from '../../../graphql/user/query.graphql';

const EditUserResources = ({
    match: {
        params: {
            id,
        },
    },
}) => {
    const {
        loading: areDataLoading = true,
        data = {},
    } = useQuery({
        query: GetUserResourcesData,
        options: {
            variables: {id: id}
        }
    });

    if (areDataLoading) {
        return (
            <Dimmer inverted active>
                <Loader/>
            </Dimmer>
        )
    }

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('User', routes.users.index.path),
        });
    }

    const renderForm = () => {
        const model = EditUserResourcesModel(data, id),
            Form = createForm(
                () => model,
                EditUserResourcesForm,
                {entityId: convertToInt(id), id: convertToInt(id)}
            )

        return <Form/>;
    }

    return renderForm();
}

EditUserResources.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(EditUserResources);
