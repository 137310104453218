import {withRouter} from "react-router";
import {
    debounce as _debounce,
    get as _get,
    isEmpty as _isEmpty
} from "lodash";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";

import {GetCitiesForDropdown} from "../../../graphql/city/query.graphql";

export class VenueForm extends DefaultForm {
    setLinkToIndexPage = () => (
        this.props.history.push(getLink('venues.index'))
    );

    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setDeleteSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setUpdateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setField("city", {
            onSearchChangeSelect: _debounce(this.onCityChange, 1000)
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        if (!this.state.cityLoaded && _get(this.props.GraphQLEntityData, "venue.city", null)) {
            const currentCity = this.props.GraphQLEntityData.venue.city;

            this.setField("city", {
                options: [{
                    key: currentCity.id,
                    value: currentCity.id,
                    text: currentCity.name
                }]
            });
            this.setState(() => ({cityLoaded: true}));
        }
    }

    prepareDataForSubmit = (data) => {
        let dataToSave = Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);

        if (!_isEmpty(data)) {
            dataToSave.city = convertToInt(data.city) || null;
            dataToSave.capacity = convertToInt(data.capacity) || null;
        }

        return dataToSave;
    };

    renderErrors(errorData) {
        const link = getLink('venues.index');

        super.renderErrors(errorData, 'Venue', link);
    }

    onCityChange = (event, value) => {
        const searchString = value.searchQuery.trim();

        if (3 <= searchString.length) {
            this.getCitiesFromGraphQL(searchString);
        }
    };

    getCitiesFromGraphQL = (searchString) => {
        this.setField("city", {loading: true});

        this.props.client.query({
            query: GetCitiesForDropdown,
            variables: {
                search: searchString
            }
        }).then((result) => {
            this.setField("city", {options: result.data.cities, loading: false});
        }).catch(() => {
            this.setFormMessage("Failed to fetch cities filter data", "venueMessageError");
            this.setField("city", {loading: false});
        });
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(VenueForm);
