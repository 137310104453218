import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {LCR_CHANNEL} from '@constants/resources';
import {CreateLcrChannel, DeleteLcrChannel, UpdateLcrChannel} from '@graphql/lcr/mutation';
import {GetLcrChannelById} from '@graphql/lcr/query';

import validationGeneralInformation from '../utils/forms/validationGeneralInformation';

const LcrChannelGeneralInformationModel = () => ({
    formName: 'lcrChannel',
    showChangelog: true,
    title: null,
    entityDataMapKey: 'lcrChannel',
    label: 'LCR Channel',
    entityLabel: 'LCR Channel',
    resources: LCR_CHANNEL,
    validate: validationGeneralInformation,
    dataMap: {
        lcrChannel: {
            id: null,
            name: null,
            description: null,
            has_lmt_support: true,
            is_disabled: false,
        },
    },
    fields: {
        id: {
            component: Form.IdField,
            dataMapKey: 'lcrChannel.id',
            props: {
                hidden: true,
                label: '',
                name: 'id',
            },
        },
        name: {
            dataMapKey: 'lcrChannel.name',
            props: {
                className: 'form__input--input-width-medium',
                component: Form.SemanticInput,
                label: 'Name',
                name: 'name',
                required: true,
                type: 'input',
            },
        },
        description: {
            dataMapKey: 'lcrChannel.description',
            props: {
                component: Form.SemanticInput,
                label: 'Description',
                name: 'description',
                type: 'input',
            },
        },
        has_lmt_support: {
            dataMapKey: 'lcrChannel.has_lmt_support',
            props: {
                component: Form.SemanticInput,
                label: 'LMT',
                name: 'has_lmt_support',
                type: 'toggle',
            },
        },
        is_disabled: {
            dataMapKey: 'lcrChannel.is_disabled',
            props: {
                component: Form.SemanticInput,
                label: 'Disabled',
                name: 'is_disabled',
                type: 'checkbox',
            },
        },
    },
    messages: {
        boxName: {
            delete: 'LcrChannelIndex',
            error: 'LcrChannelEdit',
            success: 'LcrChannelEdit',
        },
        text: {
            ...MESSAGES,
            FORM_ERROR: () => MESSAGES.FORM_ERROR('LCR channel')
        },
    },
    mutation: {
        createEntity: CreateLcrChannel,
        updateEntity: UpdateLcrChannel,
        deleteEntity: DeleteLcrChannel,
    },
    query: {
        getEntity: GetLcrChannelById,
        getOptions: null,
    },
});

export default LcrChannelGeneralInformationModel;
