import Form from "../../app/components/ReduxFormControls";

import ContactPersonModel from "../../contactPerson/forms/ContactPersonModel";

import validate from "./validatorContactPerson";

import {GetContactPersonTypesForDropdown} from "../../../graphql/contactPersonType/query.graphql";

export default function ProductionCompanyContactPersonModel() {
    const contactPersonModel = ContactPersonModel(),
        fields = {};

    Object.keys(contactPersonModel.fields).forEach((field) => {
        fields[field] = contactPersonModel.fields[field];

        if ("name" === field) {
            fields.contact_person_type = {
                dataMapKey: "contactPerson.contact_person_type.id",
                optionsKey: "contactPersonTypes",
                props: {
                    component: Form.SemanticSelect,
                    label: "Type",
                    name: "contact_person_type",
                    placeholder: "Select type",
                    required: true,
                },
            }
        }
    });

    contactPersonModel.validate = validate;
    contactPersonModel.dataMap.contactPerson = {
        ...contactPersonModel.dataMap.contactPerson,
        contact_person_type: {id: ""},
    };
    contactPersonModel.messages.boxName = {
        delete: "productionCompany",
        error: "productionCompany",
        success: "productionCompany",
    };
    contactPersonModel.fields = fields;
    contactPersonModel.query.getOptions = GetContactPersonTypesForDropdown;

    contactPersonModel.mutationOptions = {
        create: {
            refetchQueries: ["GetProductionCompanyContactPersonsForList"]
        },
        update: {
            refetchQueries: ["GetProductionCompanyContactPersonsForList"]
        },
        delete: {
            refetchQueries: ["GetProductionCompanyContactPersonsForList"]
        },
    };

    return contactPersonModel;
}
