import React from 'react';
import PropTypes from 'prop-types';

import { isChangelogElementPath } from '@modules/changelog/utils/changelogHelper';

import RoutesCrumb from './HeaderComponents/RoutesCrumb';
import ChangelogRoutesCrumb from './HeaderComponents/ChangelogRoutesCrumb';

const PathValidator = ({ pathname }) => {
    if (isChangelogElementPath(pathname)) {
        return <ChangelogRoutesCrumb pathname={pathname} />;
    } else {
        return <RoutesCrumb pathname={pathname} />;
    }
};

PathValidator.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default PathValidator;
