import PropTypes from 'prop-types';

import {
    getName,
    getProductShortName,
    getSportNames,
    getTournamentNames,
    getTournamentCategoryNames,
    getClipNames,
} from '../propertyDecorator';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'clip_configurations',
    parseData: clipConfiguration => [
        getName(clipConfiguration),
        getProductShortName(clipConfiguration),
        getSportNames(clipConfiguration),
        getTournamentCategoryNames(clipConfiguration),
        getTournamentNames(clipConfiguration),
        getClipNames(clipConfiguration),
    ],
    path: 'clipConfiguration.index.path',
    titles: [
        'Name',
        'Product',
        'Sports',
        'Categories',
        'Tournaments',
        'Clips',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
