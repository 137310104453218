import React from "react";
import PropTypes from "prop-types";
import Table from "../../app/components/Table";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";

import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';
import Authorization from "../../app/components/Authorization";
import {getLink} from "../../app/components/Link";
import {IconEdit} from "../../app/components/IconCollection";
import {getParsedId} from "../../../utils/helpers";

const CompetitorsTable = (props) => {
    const columns = {
        "id": {
            label: "ID",
            sorting: (row) => getParsedId(row),
            header: {
                align: "center",
                isCollapsed: true
            },
            content: {
                align: "center"
            }
        },
        "name": {
            label: "Name",
            sorting: (row) => row.name
        },
        "country": {
            label: "Country",
            sorting: (row) => (
                (row.country)
                    ? row.country.name
                    : ''
            )
        },
        "actions": {
            label: "Actions",
            header: {
                align: "center"
            },
            content: {
                align: "center"
            }
        }
    };

    const renderLink = (id, content) => {
        return <LinkWithAuthorization
            resources={RESOURCES.AV_COMPETITOR}
            privileges={[CONST.SECURITY_PRIVILEGES_UPDATE, CONST.SECURITY_PRIVILEGES_READ]}
            link={{
                name: "competitors.edit",
                params: {id: id}
            }}
        >
            {content}
        </LinkWithAuthorization>
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id" :
                return renderLink(row.id, row.id);
            case "name" :
                return renderLink(row.id, row.name);
            case "actions" :
                return (
                    <Authorization resources={RESOURCES.AV_COMPETITOR}
                        privileges={[CONST.SECURITY_PRIVILEGES_UPDATE, CONST.SECURITY_PRIVILEGES_READ]}
                    >
                        <div className="actions-list-icon">
                            <IconEdit size="large" link={getLink("competitors.edit", {id: row.id})}
                                resource={RESOURCES.AV_COMPETITOR}/>
                        </div>
                    </Authorization>
                );
        }

        return null
    };

    return (

        <div className="competitors__table">
            <Table
                name="competitorsList"
                columns={columns}
                limit={50}
                data={props.data}
                loading={props.loading}
                noDataText="No competitors found"
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

CompetitorsTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};

CompetitorsTable.defaultProps = {
    data: [],
    loading: false
};

export default CompetitorsTable
