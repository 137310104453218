import PropTypes from "prop-types";
import React from "react";
import {graphql} from "react-apollo";

import ClientPackageContentForm from "../forms/ClientPackageContentForm";

import {convertToInt} from "../../../utils/helpers";

import {ClientPackageDropdownDataQuery} from "../../../graphql/clientPackage/query.graphql";

const ClientPackageContentFormAdd = (props) => (
    <ClientPackageContentForm
        loading={(!props.loading) ? props.data.loading : false}
        formData={props.data}
    />
);

ClientPackageContentFormAdd.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
};

export default graphql(ClientPackageDropdownDataQuery, {
    options: (props) => ({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
        variables: {clientId: convertToInt(props.clientId)},
    }),
})(ClientPackageContentFormAdd);
