import {convertToInt} from "./helpers";
import PropTypes from "prop-types";

/**
 * Map(
 *  product_id => Map(
 *          usage_type_id => rights_scope_usage_type.id / event_usage_type.id
 *       )
 * )
 * @param {Object[]} savedUsageTypes
 * @returns {Map<int, Map<int, int>>}
 */
export const getInitialSavedUsageTypes = (savedUsageTypes) => {
    let usageTypesMap = new Map();

    savedUsageTypes.forEach((usageTypes) => {
        const productId = convertToInt(usageTypes.product.id),
            usageTypeId = convertToInt(usageTypes.usage_type.id),
            entityUsageTypeId = convertToInt(usageTypes.id);

        if (!usageTypesMap.get(productId)) {
            usageTypesMap.set(productId, new Map());
        }

        usageTypesMap
            .get(productId)
            .set(usageTypeId, entityUsageTypeId);
    });

    return usageTypesMap;
};

getInitialSavedUsageTypes.propTypes = {
    savedUsageTypes: PropTypes.object.isRequired
};

/**
 * Generate Map object for "usages type" tree (first settings render)
 * (key - product.id, value - array with rights_scope_usage_type.id)
 *
 * for rendering structure for "usage types" tree
 * @param {Object[]} initialUsageTypesSelected Usages type selected
 * @returns {Map<int, int[]>}
 */
export const getInitialSelectedUsageTypes = (initialUsageTypesSelected) => {
    let selectedMap = new Map();

    if (0 === initialUsageTypesSelected.length) {
        return selectedMap;
    }

    initialUsageTypesSelected.forEach((usageTypeSelected) => {
        const productId = convertToInt(usageTypeSelected.product.id),
            usageTypeId = convertToInt(usageTypeSelected.usage_type.id);

        if (!selectedMap.has(productId)) {
            selectedMap.set(productId, []);
        }

        selectedMap
            .get(productId)
            .push(usageTypeId);
    });

    return selectedMap;
};

getInitialSelectedUsageTypes.propTypes = {
    initialUsageTypesSelected: PropTypes.object.isRequired
}
