import React from "react";
import PropTypes from "prop-types";
import {Image, Popup} from "semantic-ui-react";
import {get as _get, isEmpty as _isEmpty} from "lodash";

import {convertToInt} from "../../../../../../../../utils/helpers";
import {getProperty} from "../common";
import {getCompetitorsObjectFromEvent} from "../../../../../../../../utils/competitorObject";

import {COMPETITOR_FLAG_LINK, SPORT_TENNIS} from "../../../../../../../app/constants/variables";

/**
 * Get URL for country flag
 */
export const generateCountryCodeFlagUrl = (countryCode) => (
    `${COMPETITOR_FLAG_LINK}${countryCode.toLowerCase()}.png`
);

generateCountryCodeFlagUrl.propTypes = {
    countryCode: PropTypes.string.isRequired
};

/**
 * Get competitors country flag from event
 * with logic for:
 * - sr event (sport tennis)
 * - av event
 * @param event
 * @returns {{away: null, home: null}}
 */
export const getCompetitorCountryFlagsFromEvent = (event) => {
    const competitorObject = getCompetitorsObjectFromEvent(event),
        eventData = {
            data: event,
            srWithMatchID: ['match', 'tournament', 'tournament_category', 'sport'],
            srWithoutMatchID: ['tournament', 'tournament_category', 'sport']
        },
        sportId = getProperty(eventData, 'id'),
        isSportRadarEventSportTennis = (!_isEmpty(event.sr_event) && SPORT_TENNIS === convertToInt(sportId));

    let homeImage = null,
        awayImage = null;

    /**
     * Display country flag only for:
     * - Sportradar Event with sport tennis and competitors
     * - Non-Sportradar event with competitors
     */
    if (isSportRadarEventSportTennis || !_isEmpty(event.av_event)) {
        const homeCountryCode = _get(competitorObject.home, 'country.iso_alpha2_code', null),
            awayCountryCode = _get(competitorObject.away, 'country.iso_alpha2_code', null),
            homeCountryName = _get(competitorObject.home, 'country.name', null),
            awayCountryName = _get(competitorObject.away, 'country.name', null);

        /**
         * If country is defined in the event competitors, prepare image
         */
        if (homeCountryCode) {
            homeImage = (
                <Popup
                    content={homeCountryName}
                    trigger={<Image
                        className={'sportImage --display-inline --marginRight-0-5-em --verdicalAlign-TextTop --marginTop-1'}
                        key="imageHomeCompetitor"
                        src={generateCountryCodeFlagUrl(homeCountryCode)}
                    />}
                />
            );
        }

        if (awayCountryCode) {
            awayImage = (
                <Popup
                    content={awayCountryName}
                    trigger={<Image
                        className={'sportImage --display-inline --marginLeft-0-5-em --verdicalAlign-TextTop --marginTop-1'}
                        key="imageAwayCompetitor"
                        src={generateCountryCodeFlagUrl(awayCountryCode)}
                    />}
                />
            );
        }
    }

    return {
        home: homeImage,
        away: awayImage
    };
};

getCompetitorCountryFlagsFromEvent.defaultProps = {
    event: PropTypes.object.isRequired
};
