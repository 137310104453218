import NotificationModel from "./NotificationModel";
import {validate} from "./validatorMasterEventNotification";

import {CreateEventGroupNotification} from "../../../graphql/notifications/mutation.graphql"
import {EVENT_GROUP_NOTIFICATION} from "../../app/constants/resources";

export default function EventGroupNotificationModel() {
    const eventGroupNotificationModel = NotificationModel();

    eventGroupNotificationModel.messages.boxName.success = "eventsIndex";
    eventGroupNotificationModel.messages.boxName.error = "eventsIndex";

    eventGroupNotificationModel.resource = EVENT_GROUP_NOTIFICATION;
    eventGroupNotificationModel.entityLabel = "EventGroupNotification";
    eventGroupNotificationModel.formName = "eventGroupNotification";
    eventGroupNotificationModel.validate = validate;
    eventGroupNotificationModel.mutation.createEntity = CreateEventGroupNotification;

    return eventGroupNotificationModel;
}
