import {isEmpty as _isEmpty} from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit, IconCheckmark} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';

const ROUTE_EDIT = (id) => `/rtmp-origin-entry-points/${id}`;

const rtmpAuthorizationObject = {
    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ
};

const columns = {
    "id": {
        label: "ID",
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {
        label: "Name",
        sorting: true
    },
    "host" : {
        label: "Host",
        sorting: true
    },
    "location" : {
        label: "Location",
        sorting: true
    },
    "rtmp_origin_entry_point_type" : {
        label: "Type",
        sorting: (row) => row.rtmp_origin_entry_point_type.name
    },
    "origin_hosting_provider" : {
        label: "Hosting provider",
        sorting: (row) => row.origin_hosting_provider.name
    },
    "is_active": {
        label: "Active",
        sorting: true,
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
    "start_time": {
        label: "Start time",
        sorting: true,
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
    "stop_time": {
        label: "End time",
        sorting: true,
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};
const renderNameOfFirstLevelDependency = (row, column) => {
    return (
        <div>
            {(row[column] === null || row[column] === undefined)
                ? ""
                : row[column].name.capitalizeFirstLetter()
            }
        </div>
    );
};

const renderLinkToEditAndDescription = (row) => {
    return (
        <div>
            <LinkWithAuthorization
                authorization={rtmpAuthorizationObject}
                link={{
                    name: "rtmpOriginEntryPoint.edit",
                    params: {id: row.id}
                }}
            >
                {row.name}
            </LinkWithAuthorization>
            { !_isEmpty(row.description) ? 
                <p className="muted">{row.description}</p> : null
            }
        </div>
    )
}

const renderHostAndPublicHost = (row) => {
    return (
        <div>
            {row.host}
            { !_isEmpty(row.public_host) ? 
                <p>{ `Public: ${ row.public_host }` }</p> : null
            }
        </div>
    )
}

const renderCheckbox = (row, column) => {
    return (
        row[column] ? <IconCheckmark/> : ""
    );
};

const RtmpOriginEntryPointTable = (props) => {
    const { rtmpOriginEntryPoints, loading } = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
                return (
                    <LinkWithAuthorization
                        authorization={rtmpAuthorizationObject}
                        link={{
                            name: "rtmpOriginEntryPoint.edit",
                            params: {id: row.id}
                        }}
                    >
                        {row.id}
                    </LinkWithAuthorization>
                );
            case "name":
                return renderLinkToEditAndDescription(row);
            case "rtmp_origin_entry_point_type":
            case "origin_hosting_provider":
                return renderNameOfFirstLevelDependency(row, column);
            case "host": 
                return renderHostAndPublicHost(row);
            case "is_active":
                return renderCheckbox(row, column);
            case "actions" :
                return (
                    <Authorization authorization={rtmpAuthorizationObject}>
                        <div className="actions-list-icon">
                            <IconEdit size="large" link={ROUTE_EDIT(row.id)}
                                resource={RESOURCES.RTMP_ORIGIN_ENTRY_POINT}/>
                        </div>
                    </Authorization>
                );
            default:
                return null
        }
    };

    return (
        <div className="RtmpOriginEntryPoint__table">
            <Table
                name="RtmpOriginEntryPointList"
                columns={columns}
                data={rtmpOriginEntryPoints}
                loading={loading}
                rowRenderer={rowRenderer}
                pagination={false}
                noDataText="No RTMP origin entry points found."
            />
        </div>
    )
};

RtmpOriginEntryPointTable.defaultProps = {
    rtmpOriginEntryPoint: []
};

RtmpOriginEntryPointTable.propTypes = {
    rtmpOriginEntryPoints: PropTypes.array,
    rtmpOriginEntryPoint: PropTypes.array.isRequired,
    onLicensorDelete: PropTypes.func,
    loading: PropTypes.bool
};

export default RtmpOriginEntryPointTable;
