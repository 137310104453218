import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import HeaderRenderer from "../../app/components/HeaderRenderer";

import UserSettingsForm from "../forms/UserSettingsForm";
import UserSettingsModel from "../forms/UserSettingsModel";

import {createForm} from "../../../utils/forms";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import navigation from "../../app/constants/navigation";

const DUMMY_USER_ID = '0';

export const UserSettings = ({
    Menu,
}) => {
    React.useEffect(() => {
        Menu.storeMenu(navigation.settings.key);
    }, []);

    const Form = React.useMemo(
        () => (createForm(UserSettingsModel, UserSettingsForm, {id: DUMMY_USER_ID})),
        []
    );

    return (
        <React.Fragment>
            <HeaderRenderer
                messagesBoxNames="userSettings"
                pageTitle="User settings"
            />
            <Form />
        </React.Fragment>
    );
};
UserSettings.propTypes = {
    Menu: PropTypes.object.isRequired,
};

const mapDispatchToProps = mapModulesToProps(['Menu']);

export default connect(null, mapDispatchToProps)(UserSettings)
