import PropTypes from 'prop-types';

import {getProperty} from "../common";

/**
 * Display value for Category (for sr event)
 */
export const category = (event) => {
    const eventData = {
        data: event,
        srWithMatchID: ['match', 'tournament', 'tournament_category'],
        srWithoutMatchID: ['tournament', 'tournament_category']
    };

    return getProperty(eventData);
};

category.propTypes = {
    event: PropTypes.object.isRequired
}
