import React from 'react';
import PropTypes from "prop-types"
import {Button, Icon} from "semantic-ui-react";
import Link from "../../app/components/Link";

const ClientPackageBackButton = (props) => {
    return (
        <div className="indexButtons">
            <Link name="clients.packages.index" params={{id: props.clientId}}>
                <Button className="button-back">
                    <Icon className="arrow circle left"/> Back to packages list
                </Button>
            </Link>
        </div>
    )
};

ClientPackageBackButton.propTypes = {
    clientId: PropTypes.number.isRequired
};

export default ClientPackageBackButton;
