import {debounce as _debounce, map as _map} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';

import {gql, withApollo} from 'react-apollo';
import {FiltersWrapper, getSelectedValues} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetTournamentsByTournamentCategories} from '@graphql/tournament/query';
import {tournamentCategoriesFilteredBySports} from '@graphql/tournamentCategories';

class ClientMasterBookingsFiltersComponent extends React.Component{
    static propTypes = {
        additionalFilterId: PropTypes.string,
        client: PropTypes.object.isRequired,
        data: PropTypes.shape({
            bookingTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
            clientPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
            countries: PropTypes.arrayOf(PropTypes.object).isRequired,
            deviceCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
            products: PropTypes.arrayOf(PropTypes.object).isRequired,
            propertyLicences: PropTypes.arrayOf(PropTypes.object).isRequired,
            sports: PropTypes.arrayOf(PropTypes.object).isRequired,
        }),
        filters: PropTypes.shape({
            ClientMasterBookings: PropTypes.object,
        }),
        form: PropTypes.shape({
            changeValue: PropTypes.func.isRequired,
        }),
        loading: PropTypes.bool.isRequired,
        MessageBox: PropTypes.shape({
            addMessage: PropTypes.func.isRequired,
        }),
        restoreFilterValues: PropTypes.func.isRequired
    };

    static defaultProps = {
        additionalFilterId: null,
        filters: {
            ClientMasterBookings: {},
        },
    };

    initDate = moment().format("YYYY-MM-DD 00:00");

    state = {
        fromMaxDate: null,
        fromMaxDateDefault: null,
        fromMaxTime: moment().hours(23).minutes(59),
        hasSelectedSport: false,
        hasSelectedTournamentCategory: false,
        selectedTournamentCategories: [],
        selectedTournaments: [],
        toMinDate: null,
        toMinDateDefault: null,
        toMinTime: moment().hours(0).minutes(0),
        tournamentCategories: [],
        tournaments: [],
    };

    componentDidMount() {
        let fromMaxDate = this.props.restoreFilterValues(
                this.props.filters[`ClientMasterBookings${this.props.additionalFilterId}`], 'to'
            ),
            toMinDate = this.props.restoreFilterValues(
                this.props.filters[`ClientMasterBookings${this.props.additionalFilterId}`], 'from', moment()
            ),
            nextState = {};

        fromMaxDate = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
        toMinDate = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);

        if (fromMaxDate && !moment(this.state.fromMaxDateDefault).isSame(fromMaxDate)) {
            nextState.fromMaxDateDefault = fromMaxDate;
            nextState.fromMaxDate = fromMaxDate;
        }

        if (toMinDate && !moment(this.state.toMinDateDefault).isSame(toMinDate)) {
            nextState.toMinDateDefault = toMinDate;
            nextState.toMinDate = toMinDate;
        }

        if (
            nextState.fromMaxDateDefault &&
            nextState.toMinDateDefault &&
            nextState.fromMaxDateDefault.format('YYYY-MM-DD') === nextState.toMinDateDefault.format('YYYY-MM-DD')
        ) {
            nextState.fromMaxTime = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
            nextState.toMinTime = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);
        }

        this.setState(() => (nextState));
    }

    getTournamentCategoriesFromGraphQL = _debounce((sportIds) => {
        this.props.client.query({
            query: gql(tournamentCategoriesFilteredBySports),
            variables: {
                sports: sportIds,
            },
        }).then((result) => {
            const tournamentCategories = sortDropdownOptionsAlphanumerically(
                result.data.tournamentCategories.mapDataForDropdownWithIntVal()
            );

            this.setState(() => ({
                tournamentCategories: tournamentCategories,
                tournamentCategoriesLoading: false,
            }));

            this.props.form.changeValue(
                "tournamentCategories",
                getSelectedValues(this.state.selectedTournamentCategories, tournamentCategories)
            );
        }).catch((error) => {
            this.setState(() => ({
                tournamentCategories: [],
                tournamentCategoriesLoading: false,
            }));

            this.props.MessageBox.addMessage(
                "clientMessage",
                "Failed to fetch tournament categories filter data",
                `${error}`,
                "error",
            );
        });
    }, 1000);

    getTournamentsFromGraphQL = _debounce((tournamentCategoryIds) => {
        this.props.client.query({
            query: GetTournamentsByTournamentCategories,
            variables: {
                tournamentCategories: tournamentCategoryIds,
            },
        }).then((result) => {
            const tournaments = sortDropdownOptionsAlphanumerically(
                result.data.tournaments.mapDataForDropdownWithIntVal()
            );

            this.setState(() => ({
                tournaments: tournaments,
                tournamentsLoading: false,
            }));

            this.props.form.changeValue(
                "tournaments",
                getSelectedValues(this.state.selectedTournaments, tournaments)
            );
        }).catch((error) => {
            this.setState(() => ({
                tournaments: [],
                tournamentsLoading: false,
            }));

            this.props.MessageBox.addMessage(
                "clientMessage",
                "Failed to fetch tournaments filter data",
                `${error}`,
                "error",
            );
        });
    }, 1000);

    onSportChange = (event, value) => {
        let state = {
            hasSelectedSport: false,
            tournamentCategories: [],
            tournamentCategoriesLoading: false,
        };

        if (value.length > 0) {
            state = {
                hasSelectedSport: true,
                tournamentCategoriesLoading: true,
            };

            let sportIds = _map(value, (sport) => (convertToInt(sport)));

            this.getTournamentCategoriesFromGraphQL(sportIds);
        } else {
            this.props.form.changeValue("tournamentCategory", null);
        }

        this.setState(() => (state));
    };

    onTournamentCategoryChange = (event, value) => {
        let state = {
            hasSelectedTournamentCategory: false,
            tournaments: [],
            tournamentsLoading: false,
        };

        if (value.length > 0) {
            state = {
                hasSelectedTournamentCategory: true,
                selectedTournamentCategories: value,
                tournamentsLoading: true,
            };

            let tournamentCategoryIds = _map(value, (tournamentCategory) => (convertToInt(tournamentCategory)));

            this.getTournamentsFromGraphQL(tournamentCategoryIds);
        } else {
            this.props.form.changeValue("tournament", null);
        }

        this.setState(() => (state));
    };

    onTournamentChange = (event, value) => {
        this.setState(() => ({
            selectedTournaments: value,
        }));
    };

    onDateChange = (date, name) => {
        let nextState = {
            fromMaxTime: null,
            toMinTime: null,
        };

        if ("from" === name) {
            nextState.toMinDate = date;

            if (date.format('YYYY-MM-DD') === this.state.fromMaxDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = this.state.fromMaxDate;
                nextState.toMinTime = date;
            }
        } else if ("to" === name) {
            nextState.fromMaxDate = date;

            if (date.format('YYYY-MM-DD') === this.state.toMinDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = date;
                nextState.toMinTime = this.state.toMinDate;
            }
        }

        this.setState(() => (nextState));
    };

    render() {
        const {
            additionalFilterId,
            data: {
                bookingTypes,
                clientPackages,
                countries,
                deviceCategories,
                products,
                propertyLicences,
                sports,
            },
            loading,
        } = this.props;
        const clientMasterBookingsFilters = this.props.filters[`ClientMasterBookings${additionalFilterId}`];

        return (
            <div className="clientMasterBookings__filters filter__controls__container">
                <Field
                    className="--minimalWidth --datetimeWithoutSeconds"
                    component={Form.ReduxInput}
                    configuration={{
                        onChange: this.onDateChange,
                        calendarClassName: "filterDatepicker",
                        dateFormat: "YYYY-MM-DD HH:mm",
                        maxDate: this.state.fromMaxDate || this.state.fromMaxDateDefault,
                        maxTime: this.state.fromMaxTime || moment().hours(23).minutes(59),
                        minTime: moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: "HH:mm",
                        timeIntervals: 5,
                    }}
                    defaultValue={this.initDate}
                    disabled={loading}
                    name="from"
                    placeholder="From..."
                    resetWhenEmpty={true}
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'from', this.initDate)}
                    type="date"
                />
                <Field
                    className="--minimalWidth --datetimeWithoutSeconds"
                    component={Form.ReduxInput}
                    configuration={{
                        onChange: this.onDateChange,
                        calendarClassName: "filterDatepicker",
                        dateFormat: "YYYY-MM-DD HH:mm",
                        minDate: this.state.toMinDate || this.state.toMinDateDefault,
                        maxTime: moment().hours(23).minutes(59),
                        minTime: this.state.toMinTime || moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: "HH:mm",
                        timeIntervals: 5,
                    }}
                    disabled={loading}
                    name="to"
                    placeholder="To..."
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'to')}
                    type="date"
                />
                <Field
                    className="long"
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="propertyLicence"
                    options={propertyLicences}
                    placeholder="Property licence"
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'propertyLicence')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="sport"
                    placeholder="Sport"
                    onChangeValue={this.onSportChange}
                    options={sports}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'sport')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.tournamentCategoriesLoading || loading}
                    hidden={!this.state.hasSelectedSport}
                    loading={this.state.tournamentCategoriesLoading || loading}
                    multiple
                    name="tournamentCategory"
                    placeholder="Category"
                    onChangeValue={this.onTournamentCategoryChange}
                    options={this.state.tournamentCategories}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'tournamentCategory')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.tournamentCategoriesLoading || this.state.tournamentsLoading || loading}
                    hidden={!this.state.hasSelectedTournamentCategory}
                    loading={this.state.tournamentCategoriesLoading || this.state.tournamentsLoading || loading}
                    multiple
                    name="tournament"
                    placeholder="Tournament"
                    onChangeValue={this.onTournamentChange}
                    options={this.state.tournaments}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'tournament')}
                />
                <Field
                    className="--customWidth --products"
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="product"
                    placeholder="Product"
                    options={products}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'product')}
                />
                <Field
                    className="--customWidth --deviceCategories"
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="deviceCategory"
                    placeholder="Device category"
                    options={deviceCategories}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'deviceCategory')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="country"
                    placeholder="Country"
                    options={countries}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'country')}
                />
                <Field
                    className="medium"
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="clientPackage"
                    placeholder="Package"
                    options={clientPackages}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'clientPackage')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name="bookingType"
                    placeholder="Type"
                    options={bookingTypes}
                    search
                    setValue={this.props.restoreFilterValues(clientMasterBookingsFilters, 'bookingType')}
                />
            </div>
        );
    }
}

const ClientMasterBookingsFilters = FiltersWrapper(
    withApollo(connect(null, mapModulesToProps(['MessageBox']))(ClientMasterBookingsFiltersComponent)
    ), {
        name: "ClientMasterBookings",
        initialValues: {
            from: moment().format("YYYY-MM-DD 00:00"),
        },
    });

export default ClientMasterBookingsFilters;
