import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import {convertToInt, isUrlParamValid} from '@utils/helpers';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import routes from '@constants/routes';
import Tabs from '@appComponents/Tabs';
import {GetCountryBasicData} from '@graphql/country/query';
import CountryGeneralInformation from '@modules/country/views/CountryGeneralInformation';
import CountrySubdivisions from '@modules/country/views/CountrySubdivisions';
import CountryPostalCodes from '@modules/country/views/CountryPostalCodes';
import {ButtonBackToList} from '@appComponents/ButtonCollection';
import {showModal} from '@utils/modal';
import {renderModalError} from '@utils/forms';

const countryDetailsTabsBuilder = (id) => {
    return {
        [routes.countries.generalInformation.path]: {
            key: routes.countries.generalInformation.path,
            render: <CountryGeneralInformation/>,
            text: 'General information',
            url: 'countries.generalInformation',
            urlParams: {id: id},
        },
        [routes.countries.subdivisions.path]: {
            key: routes.countries.subdivisions.path,
            render: <CountrySubdivisions/>,
            text: 'Subdivisions',
            url: 'countries.subdivisions',
            urlParams: {id: id},
        },
        [routes.countries.postalCodes.path]: {
            key: routes.countries.postalCodes.path,
            render: <CountryPostalCodes/>,
            text: 'Postal Codes',
            url: 'countries.postalCodes',
            urlParams: {id: id},
        },
    };
};

const CountryTabsLayout = ({
    match: {params: {id = null}, path},
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Countries', routes.countries.index.path),
        });
    }
    const {
        data: {
            country: countryBasicData = null,
        } = {},
        loading: isCountryBasicDataLoading = true,
    } = useQuery(GetCountryBasicData, {
        variables: { id: convertToInt(id) },
        fetchPolicy: 'cache-first',
    });

    const headerText = () => (
            <React.Fragment>
                Country &quot;{(countryBasicData ? countryBasicData.name : '')}&quot;
            </React.Fragment>
        ),
        backToListButton = () => <ButtonBackToList name='countries.index' />;

    return (
        <div>
            <HeaderRenderer
                pageTitle={headerText()}
                loading={isCountryBasicDataLoading}
                buttons={backToListButton}
            />
            <Tabs
                active={path}
                items={countryDetailsTabsBuilder(id)}
            />
        </div>
    );
};

CountryTabsLayout.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
};

export default CountryTabsLayout;
