import React from 'react';
import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';
import {createForm} from '@utils/forms';

import ClientCdnConfigurationModel from '../forms/ClientCdnConfigurationModel';
import ClientCdnConfigurationForm from '../forms/ClientCdnConfigurationForm';

const ClientCdnConfigurationEdit = ({clientId, clientCdnConfigurationId}) => {
    const Form = React.useMemo(() => (
        createForm(
            ClientCdnConfigurationModel,
            ClientCdnConfigurationForm, {
                dataRequest: true,
                id: convertToInt(clientCdnConfigurationId),
                optionsVariables: {
                    client: convertToInt(clientId),
                },
            }
        )), [clientCdnConfigurationId]);

    return <Form/>;
};

ClientCdnConfigurationEdit.propTypes = {
    clientId: PropTypes.string.isRequired,
    clientCdnConfigurationId: PropTypes.string.isRequired,
};

export default ClientCdnConfigurationEdit;
