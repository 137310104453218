import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";
import {refetchQueryByName} from "../../../utils/apollo";
import {renderModalError} from "../../../utils/forms";

import {CONTACT_PERSON_TYPE_ACCREDITATION} from "../../app/constants/variables";
import {NOT_SAVED} from "../../app/constants/messages";

import {UpdatePropertyLicenceAccreditation} from "../../../graphql/propertyLicence/mutation.graphql";

export class AccreditationForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            this.props.client.mutate({
                mutation: UpdatePropertyLicenceAccreditation,
                variables: {
                    id: convertToInt(this.props.match.params.id),
                    accreditation_contact_person: convertToInt(response.data.createContactPerson.id),
                },
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    "propertyLicenceMessage",
                    NOT_SAVED("property licence"),
                    error.message,
                    "error"
                );
            }).then(() => {
                refetchQueryByName('GetPropertyLicenceForForm');
            });
        });
    }

    prepareDataForSubmit = (data) => {
        return Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                contact_person_type_id: CONTACT_PERSON_TYPE_ACCREDITATION,
                production_company_id: null,
                client_id: null,
                licensor_id: null,
                licensee_id: null,
            }
        );
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderDeleteButton = () => null;

    renderCancelButton = () => null;

    render() {
        if (true === this.props.formParams.hasAccreditation) {
            return super.render();
        }

        this.props.Modal.setModal({
            isVisible: true,
            content: renderModalError(
                this.props.Model.label,
                getLink('propertyLicences.edit', {id: this.props.match.params.id}),
                null,
                'Back to property licence'
            ),
        });

        return null;
    }
}

export default withRouter(AccreditationForm);
