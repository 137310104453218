import React from 'react';
import PropTypes from 'prop-types';

import routes from '@constants/routes';
import {getLink} from '@appComponents/Link';
import StepsContent from '@appComponents/StepsContent';
import {convertToInt} from '@utils/helpers';
import {createForm} from '@utils/forms';
import EventBookingGeneralInformationEditForm from '@modules/eventBooking/forms/EventBookingGeneralInformationEditForm';
import EventBookingClipsEditModel from '@modules/eventBooking/forms/EventBookingClipsEditModel';
import EventBookingClipsEditForm from '@modules/eventBooking/forms/EventBookingClipsEditForm';
import EventBookingGeneralInformationAddModel from '@modules/eventBooking/forms/EventBookingGeneralInformationAddModel';
import EventBookingGeneralInformationAddForm from '@modules/eventBooking/forms/EventBookingGeneralInformationAddForm';
import EventBookingBlackoutZonesEditModel from '@modules/eventBooking/forms/EventBookingBlackoutZonesEditModel';
import EventBookingGeneralInformationEditModel from '@modules/eventBooking/forms/EventBookingGeneralInformationEditModel';
import EventBookingBlackoutZonesEditForm from '@modules/eventBooking/forms/EventBookingBlackoutZonesEditForm';
import {getEventBookingFormParams} from '@modules/eventBooking/forms/helpers/helpers';
import EventEventBookingGeoRestrictionsEditModel
    from '@modules/eventBooking/forms/EventEventBookingGeoRestrictionsEditModel';
import EventEventBookingGeoRestrictionsEditForm from "@modules/eventBooking/forms/EventEventBookingGeoRestrictionEditForm";

const loadModal = (props, params = {}, setMutationSuccessCallback) => {
    const eventBookingsPath = routes.events.eventBookings;
    const path = props.match.path;
    let formParams = {};

    if (path === eventBookingsPath.add.path) {
        formParams = Object.assign({}, {
            setMutationSuccessCallback,
            optionsVariables: {
                event: convertToInt(props.match.params.id),
                eventId: convertToInt(props.match.params.id),
            },
        });

        return loadAddModalForm(
            createForm(
                EventBookingGeneralInformationAddModel,
                EventBookingGeneralInformationAddForm,
                formParams
            ),
            props
        );
    } else if (path === eventBookingsPath.edit.path
        || path === eventBookingsPath.clips.path
        || path === eventBookingsPath.editGeoRestrictions.path
        || path === eventBookingsPath.blackoutZones.path
    ) {
        const [formParamsObject, linkParams] = getEventBookingFormParams(
            props.match.params.id,
            props.match.params.eventBookingId,
            params,
            true,
            setMutationSuccessCallback
        );

        return loadEditModalForm(
            {
                tabs: [
                    {
                        key: 'step1',
                        menuItem: {content: 'General information', disabled: false},
                        pane: {
                            content: createForm(
                                EventBookingGeneralInformationEditModel,
                                EventBookingGeneralInformationEditForm,
                                formParamsObject
                            ),
                        },
                        url: getLink('events.eventBookings.edit', linkParams),
                    },
                    {
                        key: 'step2',
                        menuItem: {content: 'Clips', disabled: false},
                        pane: {
                            content: createForm(
                                EventBookingClipsEditModel,
                                EventBookingClipsEditForm,
                                formParamsObject
                            ),
                        },
                        url: getLink('events.eventBookings.clips', linkParams),
                    },
                    {
                        key: 'step3',
                        menuItem: {content: 'Geo restrictions', disabled: false},
                        pane: {
                            content: createForm(
                                EventEventBookingGeoRestrictionsEditModel,
                                EventEventBookingGeoRestrictionsEditForm,
                                Object.assign({}, {
                                    id: props.match.params.eventBookingId,
                                    optionsVariables: {
                                        id: props.match.params.eventBookingId,
                                        event: convertToInt(props.match.params.id),
                                    },
                                    setMutationSuccessCallback,
                                })
                            ),
                        },
                        url: getLink('events.eventBookings.editGeoRestrictions', linkParams),
                    },
                    {
                        key: 'step4',
                        menuItem: {content: 'Blackout Zones', disabled: false},
                        pane: {
                            content: createForm(
                                EventBookingBlackoutZonesEditModel,
                                EventBookingBlackoutZonesEditForm,
                                formParamsObject
                            ),
                        },
                        url: getLink('events.eventBookings.blackoutZones', linkParams),
                    },
                ],
            },
            props
        );

    }
};

loadModal.propTypes = {
    Modal: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default loadModal;

export const loadAddModalForm = (Form, props) => {
    props.Modal.setModal({
        content: <StepsContent
            title={'Add new event booking'}
            isAddMode={true}
            tabs={[
                {key: 'step1', menuItem: {content: 'General information', disabled: false}, pane: {content: Form}},
                {key: 'step2', menuItem: {content: 'Clips', disabled: true}, pane: {content: null}},
                {key: 'step3', menuItem: {content: 'Geo restrictions', disabled: true}, pane: {content: null}},
                {key: 'step4', menuItem: {content: 'Blackout Zones', disabled: true}, pane: {content: null}},
            ]}
        />,
        isVisible: true,
        size: 'fullscreen',
    });
};

export const loadEditModalForm = (Forms, props) => {
    props.Modal.setModal({
        header: null,
        content: <StepsContent
            title={'Edit event booking'}
            activeStep={Forms.active}
            tabs={Forms.tabs}
        />,
        isVisible: true,
        size: 'fullscreen',
    });
};
