import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import {hasPrivileges} from '@appComponents/Authorization';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {USER_ACCESS_INFORMATION} from '@constants/resources';
import {sortByKey} from '@utils/sorters';
import {getYesOrNo} from '@utils/helpers';

import {renderRoles} from '../userHelper';

export const exportExcel = (userData, usersAccessInformation = []) => {
    const dataExport = _isEmpty(usersAccessInformation) ?
        userData :
        userData.map(user => ({...user, ...usersAccessInformation.find(info => info.id === user.id) }));

    const sortedUsers = [].concat(dataExport).sort(sortByKey('name'));

    const addRestrictedUserAccessInformation = (...userFields) => {
        const userAccessPrivileges = {
            [USER_ACCESS_INFORMATION] : SECURITY_PRIVILEGES_READ,
        };

        const hasPrivilege = hasPrivileges(userAccessPrivileges);

        if (hasPrivilege) {
            return [...userFields];
        }

        return [];
    };

    return  {
        dataExport: sortedUsers,
        filename: 'users',
        parseData: user => [
            user.id,
            user.name,
            user.email,
            user.roles ? renderRoles(user.roles) : '',
            ...addRestrictedUserAccessInformation(
                user.access_request_reference,
                user.job_description,
                user.manager,
                user.additional_info
            ),
            user.login_datetime,
            user.login_ip,
            user.creation_datetime,
            getYesOrNo(user.is_disabled),
        ],
        path: 'users.index.path',
        titles: [
            'ID',
            'Name',
            'Email',
            'Roles',
            ...addRestrictedUserAccessInformation(
                'Access request reference',
                'Job description',
                'Manager',
                'Additional info'
            ),
            'Last login time',
            'Last login IP',
            'Creation time',
            'Disabled?',
        ],
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
