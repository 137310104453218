import {withRouter} from 'react-router';

import {convertToInt, isUrlParamValid} from '@utils/helpers';
import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {refetchQueryByName} from '@utils/apollo';
import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';

export class ApplicationForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetRtmpOriginEntryPointApplicationsForTable');
        });

        if (!isUrlParamValid(this.props.formParams.id)) {
            showModal({
                isVisible: true,
                content: renderModalError('RTMP origin entry point application',
                    getLink(`rtmpOriginEntryPoint.applications.index`, {
                        id: this.props.formParams.rtmpOriginEntryPointId,
                    })
                ),
            });
        }
    }

    prepareDataForSubmit = (data) => {
        return Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                rtmp_origin_entry_point: convertToInt(this.props.formParams.rtmpOriginEntryPointId),
                rtmp_origin_entry_point_application_type: (data && data.rtmp_origin_entry_point_application_type)
                    ? convertToInt(data.rtmp_origin_entry_point_application_type)
                    : null,
            }
        );
    };

    renderErrors(errorData) {
        const errors = errorData.graphQLErrors,
            isNotFound = (1 === errors.length) && (404 === errors[0].code);

        if (isNotFound) {
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError(
                    'RTMP origin entry point application',
                    getLink('rtmpOriginEntryPoint.applications.index', {
                        id: this.props.formParams.rtmpOriginEntryPointId
                    })
                ),
            });
        } else {
            super.renderErrors(errorData);
        }
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );
}

export default withRouter(ApplicationForm);
