
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PropertyLicenceContentData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PropertyLicenceContent"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tournament"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TournamentData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"content_category"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentCategoriesWithParent"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"content_tier"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PropertyLicenceContentDataForRightsScope"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PropertyLicenceContent"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tournament"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"content_category"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}}],"loc":{"start":0,"end":506}};
    doc.loc.source = {"body":"#import \"../contentCategory/fragment.graphql\"\n#import \"../tournament/fragment.graphql\"\n\nfragment PropertyLicenceContentData on PropertyLicenceContent {\n    id\n    tournament {\n        ...TournamentData\n    }\n    content_category {\n        ...ContentCategoriesWithParent\n    }\n    content_tier {\n        ...BasicData\n    }\n}\n\nfragment PropertyLicenceContentDataForRightsScope on PropertyLicenceContent {\n    id\n    tournament {\n        ...BasicData\n    }\n    content_category {\n        ...BasicData\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../contentCategory/fragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../tournament/fragment.graphql").definitions));


      module.exports = doc;
    
