import {get as _get} from 'lodash';
import {DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV} from '@constants/variables';
import {getDeviceNameById} from '@modules/events/utils/eventsBookingGeoRestriction';


/**
 * Logic shared between:
 * - booking
 * - event booking
 * to fill Blackout Zones
 *
 * @param setField
 * @param previousStepData
 * @param blackoutZonesOptions
 * @param eventBookingGeoRestrictions
 */
export const setBlackoutZones = (
    setField,
    previousStepData = {},
    blackoutZonesOptions = [],
    eventBookingGeoRestrictions = [],
) => {
    let assignedBlackoutZonesIds = {};
    const preparedBlackoutZonesOptions = {};
    const blackoutZonesPrefilled = _get(previousStepData, 'blackoutZonesPrefilled', []);

    if (0 < blackoutZonesPrefilled.length && 0 === eventBookingGeoRestrictions.length) {
        blackoutZonesPrefilled.forEach((blackoutZonePrefilled) => {
            assignedBlackoutZonesIds = Object.assign(
                assignedBlackoutZonesIds,
                {[blackoutZonePrefilled.device_category]: blackoutZonePrefilled.blackout_zones}
            );
        });
    } else {
        eventBookingGeoRestrictions.forEach((eventBookingGeoRestriction) => {
            assignedBlackoutZonesIds = Object.assign(
                assignedBlackoutZonesIds,
                {
                    [eventBookingGeoRestriction.device_category.id]: eventBookingGeoRestriction.blackout_zones.map((blackoutZone) => {
                        return blackoutZone.id;
                    })
                }
            );
        });

        for (const [key, blackoutZonesIds] of Object.entries(assignedBlackoutZonesIds)) {
            preparedBlackoutZonesOptions[key] = blackoutZonesOptions.filter(blackoutZone => {
                if (blackoutZone.is_archived && blackoutZonesIds.includes(blackoutZone.id)) {
                    return true;
                } else {
                    return !blackoutZone.is_archived;
                }
            });
        }
    }

    [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV].forEach((deviceCategoryId) => {
        const deviceName = getDeviceNameById(deviceCategoryId);

        setField(`${deviceName}_blackoutZones`, {
            hidden: assignedBlackoutZonesIds[deviceCategoryId]
                ? false
                : previousStepData && !previousStepData[`${deviceName}_switch`],
            defaultValue: assignedBlackoutZonesIds[deviceCategoryId]
                ? assignedBlackoutZonesIds[deviceCategoryId].map(String)
                : [],
            options: preparedBlackoutZonesOptions[deviceCategoryId]
                ? preparedBlackoutZonesOptions[deviceCategoryId]
                : blackoutZonesOptions.filter(blackoutZone => !blackoutZone.is_archived),
        });
    });
}
