import {CDN_AKAMAI, CDN_AKAMAI_2} from "../../app/constants/variables";
import {convertToInt} from "../../../utils/helpers";
import validators from "../../../utils/validators";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.client_product, 'client_product'));
    Object.assign(errors, validators.required(values.cdn, 'cdn'));

    if ([CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(values.cdn))) {
        Object.assign(errors, validators.required(values.akamai_cdn_ingest_method, 'akamai_cdn_ingest_method'));
        Object.assign(errors, validators.required(values.akamai_cp_code, 'akamai_cp_code'));
        Object.assign(errors, validators.required(values.encoder_ingest_password, 'encoder_ingest_password'));
        Object.assign(errors, validators.required(values.akamai_live_cp_hostname, 'akamai_live_cp_hostname'));
        Object.assign(errors, validators.required(values.akamai_vod_cp_hostname, 'akamai_vod_cp_hostname'));
    }

    if (values.has_external_storage) {
        Object.assign(errors, validators.required(values.vod_netstorage_hostname, 'vod_netstorage_hostname'));
        Object.assign(errors, validators.required(values.vod_netstorage_username, 'vod_netstorage_username'));
        Object.assign(errors, validators.required(values.vod_netstorage_password, 'vod_netstorage_password'));
        Object.assign(errors, validators.required(values.vod_netstorage_upload_path, 'vod_netstorage_upload_path'));
        Object.assign(errors, validators.required(values.vod_netstorage_playback_path, 'vod_netstorage_playback_path'));
    }

    if (!values.live_encoding_job_profile && !values.recording_encoding_job_profile) {
        Object.assign(errors, validators.required(values.live_encoding_job_profile, 'live_encoding_job_profile', 'At least one encoding job profile must be set'));
        Object.assign(errors, validators.required(values.recording_encoding_job_profile, 'recording_encoding_job_profile', 'At least one encoding job profile must be set'));
    }

    if (!values.live_ingest_method && !values.recording_ingest_method) {
        Object.assign(errors, validators.required(values.live_ingest_method, 'live_ingest_method', 'At least one ingest method must be selected'));
        Object.assign(errors, validators.required(values.recording_ingest_method, 'recording_ingest_method', 'At least one ingest method must be selected'));
    }

    return errors;
};

export default validate;
