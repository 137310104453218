import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {withApollo} from '@apollo/client/react/hoc';

import Form from '@appComponents/ReduxFormControls';
import ClientBookingGeoRestrictionsEditModel
    from '@modules/client/forms/ClientBookingGeoRestrictions/ClientBookingGeoRestrictionsEditModel';
import {convertToInt} from '@utils/helpers';
import {createForm} from '@utils/forms';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetDistributionIdForEventBooking} from '@graphql/eventBooking/query';

import EventBookingGeoRestrictionsForm
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsForm';
import EventBookingGeoRestrictionsEditModel
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsEditModel';

export class EventBookingGeoRestrictionsStep extends React.PureComponent {
    static propTypes = {
        bookingId: PropTypes.number.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }),
        eventId: PropTypes.number,
        MessageBox: PropTypes.shape({
            addMessage: PropTypes.func.isRequired,
        }),
        stepsMethods: PropTypes.shape({
            setStep: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {
        eventId: null,
    };

    componentDidMount() {
        this.loadForm();
    }

    setFormForStep(Form) {
        this.props.stepsMethods.setStep({
            id: 'step2',
            menuItem: {disabled: false},
            pane: {content: <Form/>},
        });
    }

    loadForm() {
        this.props.client.query({
            query: GetDistributionIdForEventBooking,
            variables: {
                id: this.props.bookingId,
            },
        }).then((result) => {
            const distributionId = convertToInt(result.data.booking.distribution.id);

            this.setFormForStep(this.getPreparedForm(distributionId));
        }).catch((error) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    "Can't get distribution for booking.",
                    error.message, 'error');
        });
    }

    getPreparedForm(distributionId) {
        return createForm(
            this.props.eventId ? EventBookingGeoRestrictionsEditModel : ClientBookingGeoRestrictionsEditModel,
            EventBookingGeoRestrictionsForm,
            Object.assign({}, {
                id: this.props.bookingId,
                optionsVariables: {
                    event: this.props.eventId,
                    distribution: distributionId,
                },
            })
        );
    }

    render() {
        return (
            <Form.Create className='eventBookingGeoRestrictionsLoader' loading={true}/>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['MessageBox']);

export default connect(null, mapDispatchToProps)(withApollo(EventBookingGeoRestrictionsStep));
