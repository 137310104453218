import React from "react";
import {Icon, Message} from "semantic-ui-react";

export const ClientDrmSetupNoDrmMessage = () => {
    return (
        <Message icon info>
            <Icon className="--fontSize-1rem" name='info circle' />
            <Message.Content>
                {`
                        No DRM setup has been defined for this client. 
                        If you want to define one, start by selecting a DRM provider.
                    `}
            </Message.Content>
        </Message>
    )
};

ClientDrmSetupNoDrmMessage.displayName = 'NoDrmMessage';
