import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';

const Logo = ({isProduction, children}) => (
    <NavLink
        className={classnames(
            'sidebar__logo',
            isProduction ? '' : 'sidebar__logo--dev'
        )}
        to={'/'}
    >
        {children}
    </NavLink>
);

Logo.propTypes = {
    isProduction: PropTypes.bool.isRequired,
    children: PropTypes.node
};

export default Logo;
