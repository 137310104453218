import PropTypes from "prop-types";
import React from "react";

import ButtonAdd from "../../app/components/ButtonsCollection/ButtonAdd";

import {CLIENT_ANALYTICS_SETUP_LABELS_INDEX_BUTTONS_ADD} from "../constants/messages";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const ClientAnalyticsSetupLabelsIndexButtons = (props) => {
    return (
        <div className="clientAnalyticsSetupLabels__indexButtons content__buttons">
            <ButtonAdd
                auth={{
                    resources: RESOURCES.CLIENT_LABEL,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                }}
                label={CLIENT_ANALYTICS_SETUP_LABELS_INDEX_BUTTONS_ADD}
                link={{
                    name: `clients.clientAnalyticsSetup.labels.add`,
                    params: {id: props.id},
                }}
            />
        </div>
    )
};

ClientAnalyticsSetupLabelsIndexButtons.propTypes = {
    id: PropTypes.number.isRequired,
};

export default ClientAnalyticsSetupLabelsIndexButtons;
