import {changelogData} from "../changelogData";

const thirdPartyPullOriginIndexResponseFragment = `
  id
  name
  encoding_datacenter {
    id
    name
  }
  encoding_job_profile {
    id
    name
  }
`;

const thirdPartyPullOriginMutationVariablesDefinition = `
  $client_product_third_party: Int
  $name: String!
  $encoding_datacenter: Int
  $encoding_job_profile: Int
`;

const thirdPartyPullOriginMutationVariablesFragment = `
  client_product_third_party:$client_product_third_party
  name: $name
  encoding_datacenter: $encoding_datacenter
  encoding_job_profile: $encoding_job_profile
`;

export const thirdPartyPullOriginDropdowns = `
query thirdPartyPullOriginsDropdows
  {
    encodingDatacenters {
        id
        value : id,
        text : name
    }
    encodingJobProfiles {
        id
        value : id,
        text : name
    }
  }
`;

export const createThirdPartyPullOrigin = `
mutation createThirdPartyClientPullOrigin (
  ${thirdPartyPullOriginMutationVariablesDefinition}
) {
  createThirdPartyClientPullOrigin (
    ${thirdPartyPullOriginMutationVariablesFragment}
  ) {
    ${thirdPartyPullOriginIndexResponseFragment}
    ${changelogData}
  }
}
`;

export const updateThirdPartyPullOrigin = `
mutation updateThirdPartyClientPullOrigin (
  $id: ID!
  ${thirdPartyPullOriginMutationVariablesDefinition}
) {
  updateThirdPartyClientPullOrigin (
    id: $id
    ${thirdPartyPullOriginMutationVariablesFragment}
  ) {
    ${thirdPartyPullOriginIndexResponseFragment}
    ${changelogData}
  }
}
`;

export const deleteThirdPartyPullOrigin = `
mutation deleteThirdPartyClientPullOrigin (
  $id: ID!
) {
  deleteThirdPartyClientPullOrigin (
    id: $id
  )
}
`;
