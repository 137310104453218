import React from "react";
import PropTypes from "prop-types";
import {Field} from "redux-form";

import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";
import Form from "../../app/components/ReduxFormControls";

const CompetitorsFilters = (props) => {
    return (
        <div className="competitors__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                name="search"
                placeholder="Search..."
                disabled={props.loading}
                setValue={props.restoreFilterValues(props.filters.AvCompetitors, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={props.loading}
                loading={props.loading}
                multiple
                name="countries"
                options={props.data.countries}
                placeholder="Country"
                setValue={props.restoreFilterValues(props.filters.AvCompetitors, 'countries')}
                search
            />
        </div>
    )
};

CompetitorsFilters.propTypes = {
    filters: PropTypes.shape({
        AvCompetitors: PropTypes.object,
    }),
    data: PropTypes.shape({
        countries: PropTypes.array
    }),
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
};

CompetitorsFilters.defaultProps = {
    filters: {
        AvCompetitors: {}
    },
    data: {
        countries: []
    },
    loading: false
};

export default FiltersWrapper(CompetitorsFilters, {name: "AvCompetitors"});
