import {GetClientProductThirdPartyForGeoRestrictionsForm} from "../../../graphql/clientProductThirdParty/query.graphql";
import {UpdateGeoRestrictionsClientProductThirdParty} from "../../../graphql/clientProductThirdParty/mutation.graphql";

import ProductLctGeoRestrictionsModel from "./ProductLctGeoRestrictionsModel";

export default function ProductThirdPartyGeoRestrictionsModel() {
    let productThirdPartyGeoRestrictionsModel = ProductLctGeoRestrictionsModel();

    productThirdPartyGeoRestrictionsModel.entityDataMapKey = "clientProductThirdParty";
    productThirdPartyGeoRestrictionsModel.formName = "product Third party";
    productThirdPartyGeoRestrictionsModel.fields.id.dataMapKey = "clientProductThirdParty.id";
    productThirdPartyGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductThirdParty,
    };
    productThirdPartyGeoRestrictionsModel.query.getEntity = GetClientProductThirdPartyForGeoRestrictionsForm;

    return productThirdPartyGeoRestrictionsModel;
}
