import PropTypes from 'prop-types';

import {formatBlackoutZonesAsString} from '../utils.jsx';

export const exportExcel = (dataExport, countryId) => (
    {
        dataExport: dataExport,
        filename: `country_${countryId}_postal_codes`,
        parseData: dataRow => [
            dataRow.id,
            (dataRow.subdivision_name) ? dataRow.subdivision_name : '',
            dataRow.code ? dataRow.code : '',
            formatBlackoutZonesAsString(dataRow.blackout_zones),
        ],
        path: 'countries.index.path',
        titles: [
            'ID',
            'Subdivision',
            'Postal code',
            'Blackout zones',
        ],
    }
);

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
