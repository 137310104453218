import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {IconWithTooltip} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import {isAuthorized} from '@appComponents/Authorization';
import {SUBTITLE} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

const IconSubtitle = ({iconName, history, linkParams, userPrivileges}) => {
    const hasReadAccess = isAuthorized(SUBTITLE, SECURITY_PRIVILEGES_READ, userPrivileges, false);

    if  (!hasReadAccess) {
        return null;
    }

    return (
        <IconWithTooltip
            iconProps={{
                size: 'large',
                'className': '--withPointer ' + iconName,
            }}
            name=''
            onClick={() => {
                history.push(getLink('events.configuration.content.target.subtitles', linkParams));
            }}
            tooltip={'Subtitle configuration'}
            popupProps={{position: 'top right'}}
        />
    );
};

IconSubtitle.propTypes = {
    iconName: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    linkParams: PropTypes.object.isRequired,
    userPrivileges: PropTypes.object.isRequired,
};
IconSubtitle.displayName = 'IconSubtitle';

const mapStateToProps = (state) => ({
    userPrivileges: state.app.security.user.privileges,
});

export default withRouter(connect(mapStateToProps, null)(IconSubtitle));
