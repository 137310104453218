import React from 'react';
import {withRouter} from "react-router";

import ProductLcppGeoRestrictionsForm from "../forms/ProductLcppGeoRestrictionsForm";
import ProductLcppGeoRestrictionsModel from "../forms/ProductLcppGeoRestrictionsModel";

import {createForm} from "../../../utils/forms";

import {ProductLctGeoRestrictionsEdit} from "./ProductLctGeoRestrictionsEdit";

export class ProductLcppGeoRestrictionsEdit extends ProductLctGeoRestrictionsEdit {
    renderForm = () => {
        const Form = createForm(
            ProductLcppGeoRestrictionsModel,
            ProductLcppGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };
}

export default withRouter(ProductLcppGeoRestrictionsEdit)
