import React from 'react';

import {createForm} from '@utils/forms';
import {routes} from '@constants/routes';

import EventsContentForm from '../forms/EventsContentForm';
import EventsContentModel from '../forms/EventsContentModel';

const wizardForm = (data) => {
    return createForm(
        EventsContentModel,
        EventsContentForm,
        {
            ...data,
            optionsFetchPolicy: 'no-cache',
        },
    );
};

const wizardTabs = (WizardForm, active, func) => {
    let initialStep = 0;

    if (routes.events.configuration.content.input.path === active) {
        initialStep = 1;
    }

    if (routes.events.configuration.content.distribution.path === active) {
        initialStep = 2;
    }

    return <WizardForm initalStep={initialStep} onSuccessCallback={func}/>;
};

export function loadModalEventsContentWizard(data, active, Modal, func) {
    Modal.setModal({
        isVisible: true,
        content: wizardTabs(wizardForm(data), active, func),
        header: null,
        size: 'large eventContent',
    });
}
