import EventBookingGeoRestrictionsGeneralInformationEditModel
    from "../../../events/forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsGeneralInformationEditModel";

import {GetInvoiceStatuses} from "../../../../graphql/invoiceStatus/query.graphql";

export default function ClientBookingGeoRestrictionsGeneralInformationEditModel() {
    let ClientBookingGeoRestrictionsGeneralInformationEditModel = EventBookingGeoRestrictionsGeneralInformationEditModel();

    ClientBookingGeoRestrictionsGeneralInformationEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    ClientBookingGeoRestrictionsGeneralInformationEditModel.mutationOptions = {
        delete: {
            refetchQueries: ['clientBookings'],
        },
        update: {
            refetchQueries: ['clientBookings'],
        },
    };
    ClientBookingGeoRestrictionsGeneralInformationEditModel.query.getOptions = GetInvoiceStatuses;

    return ClientBookingGeoRestrictionsGeneralInformationEditModel;
}
