import {
    FragmentStreamDelayTypeData,
    FragmentDeviceCategoryBasicData,
    FragmentEncodingJobProfilesDropdown,
} from "../fragments";
import {FragmentProductBasicData} from "../products";

export const getEncodingTargetsOfDistributions = `
    query GetEncodingTargetsOfDistributions($distributionIds: [Int]) {
        encodingTargets(filters: {distribution: $distributionIds}) {
            id
        }
    }
`;

export const getEncodingTargetOptions = `
    ${FragmentStreamDelayTypeData}
    ${FragmentProductBasicData}
    ${FragmentDeviceCategoryBasicData}
    ${FragmentEncodingJobProfilesDropdown}
    query GetEncodingTargetOptions($eventId: Int!, $distributionId: Int!, $productId: [Int], $distributionTypeId: [Int]) {
        encodingTargetOptions(event: $eventId, distribution: $distributionId) {
            id
            stream_name_prefix
            product_stream_delay_types{
                id
                stream_delay_type{
                    ...StreamDelayTypeData
                }
                is_default
            }
            product_device_categories{
                device_category{
                    ...DeviceCategoryBasicData
                }
                is_default
            }
            product{
                ...ProductBasicData
                cdn {
                    id
                },
                live_ingest_method {
                    id
                }
                recording_ingest_method {
                    id
                }
                akamai_cdn_ingest_method {
                    id
                }
                has_scoreboard_overlay_support
                has_slate_support
                has_overlay_support
                has_ad_support
                has_drm_support
                has_cc_support
                has_akamai_rtmp_distr_region_support
            }
        }
        encodingJobProfiles(filters: {
            product: $productId,
            distributionType: $distributionTypeId
        }) {
            ...EncodingJobProfilesDropdown
            is_default
            is_disabled
        }
    }
`;

export const DeleteEncodingTargetMutation = `
    mutation DeleteEncodingTarget($id: ID!) {
        deleteEncodingTarget(id: $id)
    }
`;
