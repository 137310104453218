import React from "react";
import PropTypes from "prop-types";

import Table from "../../app/components/Table";
import {Header} from "semantic-ui-react";

const columns = {
    "type": {
        label: "Type",
        sorting: (row) => row.event_content_alert_template.event_content_alert_type.name
    },
    "template": {
        label: "Template",
        sorting: (row) => row.event_content_alert_template.label
    },
    "message": {
        label: "Message",
        sorting: true
    },
    "creation_datetime": {
        label: "Time",
        sorting: true,
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    },
    "creation_user": {
        label: "User",
        sorting: (row) => (row.creation_user === null) ? "" : row.creation_user.name
    }
};

const EventContentAlertsTable = (props) => {
    const {alerts} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "type":
                return row.event_content_alert_template.event_content_alert_type.name;
            case "template":
                return row.event_content_alert_template.label;
            case "message":
                return <div className="messageText">{row.message}</div>;
            case "creation_datetime" :
                return <span style={{whiteSpace: "nowrap"}}>{row.creation_datetime}</span>;
            case "creation_user" :
                return row.creation_user ? <span style={{whiteSpace: "nowrap"}}>{row.creation_user.name}</span> : "";
            default:
                return row[column];
        }
    };

    if (0 < alerts.length) {
        return (
            <div className="eventContentAlerts__table">
                <Header content="Previous alerts sent"/>
                <Table
                    name="eventContentAlerts"
                    columns={columns}
                    data={alerts}
                    rowRenderer={rowRenderer}
                    pagination={false}
                    size="small"
                    loading={props.loading}
                />
            </div>
        )
    }

    return null;
};

EventContentAlertsTable.propTypes = {
    alerts: PropTypes.array,
    loading: PropTypes.bool
};

EventContentAlertsTable.defaultProps = {
    alerts: [],
    loading: false
};

export default EventContentAlertsTable;
