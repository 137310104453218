import PropTypes from 'prop-types';

import {renderCountriesForExcelExport, renderPostalCodes} from '@modules/blackoutZone/utils/blackoutZone';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'blackout_zones',
    parseData: blackoutZone => [
        blackoutZone.id,
        blackoutZone.name,
        blackoutZone.description,
        renderPostalCodes(blackoutZone),
        renderCountriesForExcelExport(blackoutZone),
    ],
    path: 'blackoutZones.index.path',
    titles: [
        'Id',
        'Name',
        'Description',
        '#Postal codes',
        'Countries',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
