import React from 'react';
import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useParams, withRouter} from 'react-router';
import {connect} from 'react-redux';

import useQuery from '@utils/hooks/useQuery';
import {showModal} from '@utils/modal';
import {
    CENTERED_HEADLINE_AND_BODY,
    formatBlackoutZonesAsString,
    renderCountryModalError,
} from '@modules/country/utils/utils';
import Table from '@appComponents/Table';
import {convertToInt} from '@utils/helpers';
import CountryPostalCodesFilters from '@modules/country/components/CountryPostalCodesFilters';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import {exportExcel} from '@modules/country/utils/export/countryPostalCodesExcel';

import {BlackoutZonesForCountrySubdivisionFragment} from './BlackoutZonesForCountrySubdivisionFragment';
import {GetDataForCountryPostalCodesViewQuery} from '../graphql/query.graphql';

const DEFAULT_FILTER_NAME = 'PostalCodesWithBlackoutZones';

const countryPostalCodeColumns = {
    id: {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    subdivision: {
        label: 'Subdivision',
        sorting: (row) => (row.subdivision_name
            ? row.subdivision_name.toLowerCase()
            : ''),
    },
    postal_code: {
        label: 'Postal code',
        sorting: (row) => (row.code ? row.code.toLowerCase() : ''),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    blackout_zones: {
        label: 'Blackout Zones',
        sorting: (row) => formatBlackoutZonesAsString(row.blackout_zones),
    },
};

const renderCountryPostalCodeTableRow = (column, row) => {
    switch (column) {
        case 'id':
            return row.id;
        case 'subdivision':
            return (row.subdivision_name) ? row.subdivision_name : '-';
        case 'postal_code':
            return (row.code) ? row.code : '';
        case 'blackout_zones':
            return BlackoutZonesForCountrySubdivisionFragment(row);
        default:
            return '';
    }
};

export function CountryPostalCodes({filters}) {
    const matchParams = useParams(),
        selectedCountryId = convertToInt(matchParams.id),
        DATA_WARNING_LIMIT = 5000;

    const filtersQuery = {
        country: [selectedCountryId],
        ...(!_isEmpty(filters.country_subdivision)) && { countrySubdivision: [...filters.country_subdivision]},
        ...(filters.search) && {search: filters.search},
    };

    const {
        data: {
            postalCodesWithBlackoutZones = [],
        } = {},
        error: queryFetchingError,
        loading: isQueryLoading = true,
    } = useQuery({
        query: GetDataForCountryPostalCodesViewQuery,
        options: {
            variables: {
                filters: filtersQuery,
            },
            refetch: filters.lastClickSubmit,
        },
    });

    if (!_isEmpty(queryFetchingError)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(queryFetchingError.message),
        });
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(postalCodesWithBlackoutZones, selectedCountryId)}
                messagesBoxNames='Country postal codes'
                filters={CountryPostalCodesFilters}
                filtersProps={{ additionalFilterId: selectedCountryId }}
                filtersButtonName={DEFAULT_FILTER_NAME + selectedCountryId}
                loading={isQueryLoading}
            />
            <div className='table-container'>
                <Table
                    name='Countries'
                    columns={countryPostalCodeColumns}
                    data={postalCodesWithBlackoutZones}
                    loading={isQueryLoading}
                    noDataText='No postal codes found'
                    rowRenderer={renderCountryPostalCodeTableRow}
                    pagination={true}
                    showLimitWarning={true}
                    dataLimitWarning={DATA_WARNING_LIMIT}
                    defaultSort={'postal_code'}
                />
            </div>
        </div>
    );
}

CountryPostalCodes.propTypes = {
    filters: PropTypes.object.isRequired,
};

const CountryPostalCodesFilterWrapped = FilterQueryWrapper(CountryPostalCodes, {
    queryForRefresh: 'GetDataForCountryPostalCodesViewQuery',
    filterUrls: ['countries.postalCodes'],
});

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, DEFAULT_FILTER_NAME + ownProps.match.params.id, {}),
});

export default withRouter(connect(mapStateToProps)(
    FilterUrlParamsWrapper(CountryPostalCodesFilterWrapped, {})
));
