import React from 'react';
import {Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { getTvChannelNamesFromChildEvent } from '@utils/tvChannels';

/**
 * Display
 * - AVATAR
 * AND
 * - Tournament
 *
 * for SPORTRADAR EVENT
 * @param event
 * @param sportImage
 * @returns {*}
 */
export const SportradarEvent = ({ event, sportImage }) => {
    const tvChannels = getTvChannelNamesFromChildEvent(event);

    return (
        <div className='--flex' key={`second-line-${event.id}`}>
            <Popup trigger={sportImage} content={event.sport_name}/>
            <span>
                {event.tournament_category_name ?? null}
                {event.tournament_category_name ? ' > ' : null}
                {event.tournament_name ?? null}
                {event.sport_has_court && event.stadium_name ? ` - ${event.stadium_name}` : ''}
                {tvChannels &&
                    <> - {tvChannels}</>}
            </span>
        </div>
    );
};

SportradarEvent.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        stadium_name: PropTypes.string,
        sport_name: PropTypes.string,
        sport_has_court: PropTypes.bool,
        tournament_category_name: PropTypes.string,
        tournament_name: PropTypes.string,
        tv_channels: PropTypes.array,
    }).isRequired,
    sportImage: PropTypes.element,
};

SportradarEvent.defaultProps = {
    event: {
        sport_name: null,
        tournament_category_name: null,
        tournament_name: null,
    },
    sportImage: React.createElement('div'),
};
