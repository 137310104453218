import {
    find as _find,
    get as _get,
} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {CDN_AKAMAI, CDN_AKAMAI_2} from '@constants/variables';
import validators from '@utils/validators';

const validate = (values, props) => {
    const errors = {};
    const STREAM_PROTOCOL_RTMP = '1';
    const encodingTarget = _find(props.GraphQLOptionsData.encodingTargets,
        (el) => convertToInt(el.id) === convertToInt(_get(values, 'encoding_target', null))
    );

    Object.assign(errors, validators.required(values.encoding_target, 'encoding_target'));
    Object.assign(errors, validators.required(values.device_category, 'device_category'));
    Object.assign(errors, validators.required(values.stream_name, 'stream_name'));
    Object.assign(errors, validators.required(values.bitrate_video, 'bitrate_video'));
    Object.assign(errors, validators.digitsOnly(values.bitrate_video, 'bitrate_video'));
    Object.assign(errors, validators.required(values.bitrate_audio, 'bitrate_audio'));
    Object.assign(errors, validators.digitsOnly(values.bitrate_audio, 'bitrate_audio'));

    if (values.frame_rate) {
        Object.assign(errors, validators.digitsOnly(values.frame_rate, 'frame_rate'));
    }

    if (values.resolution_width) {
        Object.assign(errors, validators.digitsOnly(values.resolution_width, 'resolution_width'));
    }

    if (values.resolution_height) {
        Object.assign(errors, validators.digitsOnly(values.resolution_height, 'resolution_height'));
    }

    if (values.duration) {
        Object.assign(errors, validators.digitsOnly(values.duration, 'duration'));
        Object.assign(errors, validators.range({value: values.duration, min: 0, max: 4294967295}, 'duration'));
    }

    Object.assign(errors, validators.required(values.stream_format, 'stream_format'));
    Object.assign(errors, validators.required(values.stream_protocol, 'stream_protocol'));

    if (values.stream_protocol === STREAM_PROTOCOL_RTMP
        && [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(_get(encodingTarget, 'cdn.id', null)))
    ) {
        Object.assign(errors, validators.required(values.akamai_rtmp_distr_region, 'akamai_rtmp_distr_region'));
    }

    return errors;
};

export default validate;
