import PropTypes from 'prop-types';
import React from 'react';
import {Label} from 'semantic-ui-react';

import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {IconAdd, IconEdit} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {renderEventStatusName} from '@utils/status';
import {Authorization} from '@appComponents/Authorization';
import {getLink} from '@appComponents/Link';
import { getTvChannelNamesFromChildEvent } from '@utils/tvChannels';
import {COMPETITOR_NOT_DEFINED, COMPETITOR_NOT_DEFINED_BOTH} from '@constants/variables';

const authorizationAddEventObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE,
};

const authorizationEventObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
};

const authorizationBookingObject = {
    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_READ,
};

const EventChildEventsTable = (props) => {
    const {eventChilds, loading} = props,
        columns = {
            'id': {
                label: 'ID',
                header: {
                    align: 'center',
                },
                sorting: (row) => convertToInt(row.event_id),
                content: {
                    align: 'center',
                },
            },
            'match_id': {
                label: 'Match ID',
                header: {
                    align: 'center',
                },
                sorting: true,
                content: {
                    align: 'center',
                },
            },
            'start_datetime': {
                label: 'Date/time',
                header: {
                    align: 'center',
                },
                sorting: true,
                content: {
                    align: 'center',
                },
            },
            'category_name': {
                label: 'Category',
                sorting: true,
                content: {
                    align: 'left',
                },
            },
            'tournament_name': {
                label: 'Tournament',
                sorting: true,
            },
            'venue': {
                label: props.labelForVenue,
                sorting: true,
            },
            'competitors': {
                label: 'Competitors',
                sorting: (row) => {
                    return ((row.home_competitor || COMPETITOR_NOT_DEFINED) +
                        (row.away_competitor || COMPETITOR_NOT_DEFINED)).toLowerCase();
                },
            },
            'event_status': {
                label: 'Status',
                sorting: true,
            },
            'products': {
                label: 'Products',
                sorting: true,
            },
            'bookings_count': {
                label: 'Bookings',
                header: {
                    align: 'center',
                },
                sorting: true,
                content: {
                    align: 'center',
                },
            },
            'actions': {
                label: 'Actions',
                header: {
                    align: 'center',
                },
                content: {
                    align: 'center',
                },
            },
        };

    const getEventLink = (eventId, content = eventId) => (
        <LinkWithAuthorization
            authorization={authorizationEventObject}
            link={{
                name: 'events.configuration.index',
                params: {id: eventId},
            }}
        >
            {content}
        </LinkWithAuthorization>
    );

    let rowRenderer = (column, row) => {
        let bookingCount = row.bookings_count;

        if (!bookingCount && row.event_id) {
            bookingCount = 0;
        }

        switch (column) {
            case 'id':
                return getEventLink(row.event_id);
            case 'start_datetime':
                return row.start_datetime.substring(0, row.start_datetime.length - 3);
            case 'competitors': {
                let text = `${row.home_competitor || COMPETITOR_NOT_DEFINED} : ${row.away_competitor || COMPETITOR_NOT_DEFINED}`;

                if (!row.home_competitor && !row.away_competitor) {
                    text = COMPETITOR_NOT_DEFINED_BOTH;
                }

                text = row.event_id
                    ? getEventLink(row.event_id, text)
                    : text;

                return (<div>
                    <div>
                        {text}
                    </div>
                    <div>
                        {(null === row.description || undefined === row.description)
                            ? ''
                            : <Label style={{backgroundColor: `#${row.color}`}} size='mini'>{row.description}</Label>
                        }
                    </div>
                </div>);
            }
            case 'event_status':
                return renderEventStatusName(row.event_status.id, row.event_status.name);
            case 'bookings_count': {
                return <LinkWithAuthorization
                    authorization={authorizationBookingObject}
                    link={{
                        name: 'events.bookings.index',
                        params: {id: row.event_id},
                    }}
                >
                    {bookingCount}
                </LinkWithAuthorization>;
            }
            case 'venue': {
                const tvChannels = getTvChannelNamesFromChildEvent(row);

                return (
                    <>
                        {row.venue}
                        {tvChannels &&
                            <p>{tvChannels}</p>
                        }
                    </>
                );
            }
            case 'actions':
                return (
                    row.event_id
                        ? <Authorization authorization={authorizationEventObject}>
                            <div className='actions-list-icon'>
                                <IconEdit
                                    size='large'
                                    editIconName='cog'
                                    link={getLink('events.configuration.index', {
                                        id: row.event_id,
                                    })}
                                    resource={RESOURCES.EVENT}
                                />
                            </div>
                        </Authorization>
                        : <LinkWithAuthorization
                            authorization={authorizationAddEventObject}
                            link={{
                                name: 'events.add.sportradarExisting',
                                params: {matchId: row.match_id},
                            }}
                        >
                            <div className='actions-list-icon'>
                                <IconAdd
                                    size='large'
                                    resource={RESOURCES.EVENT}
                                />
                            </div>
                        </LinkWithAuthorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className='eventChildEventsList__table'>
            <Table
                className='mini'
                columns={columns}
                data={eventChilds}
                loading={loading}
                getSelectedElements={props.getSelectedElements}
                name='eventChildEventsList'
                noDataText='No child events found'
                padded={false}
                pagination={false}
                rowRenderer={rowRenderer}
                selectable={true}
                multiple={true}
            />
        </div>
    );
};

EventChildEventsTable.propTypes = {
    labelForVenue: PropTypes.string,
    loading: PropTypes.bool,
    eventChilds: PropTypes.array,
    getSelectedElements: PropTypes.func.isRequired,
};

EventChildEventsTable.defaultProps = {
    labelForVenue: 'Venue',
    eventChilds: [],
    loading: false,
};

export default EventChildEventsTable;
