import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Segment} from 'semantic-ui-react';
import {get as _get} from 'lodash';

/* eslint import/no-unresolved: 0 */
import {compose, graphql} from 'react-apollo';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import navigation from '@constants/navigation';
import {convertToInt} from '@utils/helpers';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetCity} from '@graphql/city/query';
import {GetVenuesForTable} from '@graphql/venue/query';

import VenuesTable from '../components/VenueTable';
import VenueIndexButtons from '../components/VenueIndexButtons';
import VenuesFilters from '../components/VenueFilters';
import {exportExcel} from '../utils/export/venuesIndexExcel';

export class VenuesIndex extends React.Component {
    static propTypes = {
        DataVenues: PropTypes.shape({
            loading: PropTypes.bool,
            venues: PropTypes.array,
        }),
        DataCitiesForFilters: PropTypes.object,
        loadingRefetch: PropTypes.bool.isRequired,
        Menu: PropTypes.object,
        filters: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {
            venues: [],
        };
    }

    componentDidUpdate(prevProps) {
        let stateToSave = {};

        if (this.props.DataVenues && this.props.DataVenues.venues
            && this.props.DataVenues.venues !== prevProps.DataVenues.venues) {
            stateToSave.venues = this.props.DataVenues.venues;

            this.setState(() => ({
                ...stateToSave,
            }));
        }
    }

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.eventAdministration.key);
    }

    render() {
        const loading = (
            this.props.DataVenues.loading
            || _get(this.props.DataCitiesForFilters, 'loading', false)
            || this.props.loadingRefetch
        );
        const filterCity = _get(this.props, 'DataCitiesForFilters.city', null);

        return (
            <div>
                <HeaderRenderer
                    buttons={VenueIndexButtons}
                    exportExcelParams={exportExcel(this.state.venues)}
                    filters={VenuesFilters}
                    filtersButtonName="Venues"
                    filtersProps={{
                        loading: loading,
                        data: filterCity ? {cities: [filterCity]} : {cities: []}
                    }}
                    loading={loading}
                    messagesBoxNames="venueMessage"
                    pageTitle="Venues"
                />
                <Segment basic className="--table">
                    <VenuesTable
                        data={this.state.venues}
                        loading={loading}
                    />
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'Venues'),
});

const VenuesWithQuery = compose(
    graphql(GetVenuesForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    search: props.filters.search || null,
                    city: (props.filters.city) ? convertToInt(props.filters.city) : null,
                },
            };
        },
        name: 'DataVenues',
    }),
    graphql(GetCity, {
        skip: props => !props.filters.city,
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                variables: {
                    id: props.filters.city,
                },
            };
        },
        name: 'DataCitiesForFilters',
    })
)(FilterQueryWrapper(VenuesIndex, {
    queryForRefresh: 'DataVenues',
    filterUrls: ['venues.index'],
}));

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(
    FilterUrlParamsWrapper(VenuesWithQuery)
);
