import { get as _get, isEmpty as _isEmpty } from 'lodash';

import { sortStringArrayWithoutCaseSensitivity } from '@utils/sorters';

export const getName = (row) => {
    return _get(row, 'name', '');
};

export const getTournamentCategoryNames = (row) => {
    let tournamentCategories = [];
    const tournaments = _get(row, 'tournaments', []);

    tournaments.forEach((singleTournament) => {
        const tournamentCategoryName = _get(singleTournament, 'tournament_category.name', null);

        if (!_isEmpty(tournamentCategoryName) && !tournamentCategories.includes(tournamentCategoryName)) {
            tournamentCategories.push(tournamentCategoryName);
        }
    });

    sortStringArrayWithoutCaseSensitivity(tournamentCategories);

    return tournamentCategories.length
        ? tournamentCategories.join(', ')
        : '-';
};

export const getClipNames = (row) => {
    const eventContentTypes = _get(row, 'event_content_type_configs', []);
    let result = [];

    eventContentTypes.map((eventContentType) => {
        let singleName = _get(eventContentType, 'event_content_type.name', '');
        const matchEventTypes = _get(eventContentType, 'match_event_types', []).map((matchEventType) => {
            return _get(matchEventType, 'name', '');
        });
        sortStringArrayWithoutCaseSensitivity(matchEventTypes);

        if (matchEventTypes.length) {
            singleName = singleName + ' (' + matchEventTypes.join(', ') + ')';
        }

        result.push(singleName);
    });

    sortStringArrayWithoutCaseSensitivity(result);

    return result.length
        ? result.join(', ')
        : '-';
};

export const getProductShortName = (row) => {
    return _get(row, 'product.short_name', '');
};

export const getSportNames = (row) => {
    const sports = _get(row, 'sports', []);
    let sportsText = [];

    if (sports.length) {
        sportsText = sports.map(element => element.name);
    } else {
        let sportName = null;

        _get(row, 'tournaments', []).forEach((tournament) => {
            sportName = _get(tournament, 'tournament_category.sport.name', null);

            if (!_isEmpty(sportName) && !sportsText.includes(sportName)) {
                sportsText.push(sportName);
            }
        });
    }

    sortStringArrayWithoutCaseSensitivity(sportsText);

    return sportsText.length
        ? sportsText.join(', ')
        : '-';
};

export const getTournamentNames = (row, limit) => {
    const tournaments = _get(row, 'tournaments', []);
    let result = [];

    tournaments.map((tournament) => {
        result.push(_get(tournament, 'name', ''));
    });

    sortStringArrayWithoutCaseSensitivity(result);

    if (Number.isInteger(limit) && limit < result.length) {
        result = result.slice(0, limit);
        result.push('...');
    }


    if (11 === result.length) {
        return result.join(', ').replace(', ...', '...');
    }

    return result.length
        ? result.join(', ')
        : '-';
};
