import PropTypes from 'prop-types';
import React from 'react';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";

const ClientFilters = (props) => {
    const loading = props.loading,
        clientsFilters = props.filters.Clients,
        {
            clientTypes,
            countries,
            products
        } = props.data;

    return (
        <div className="client__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                name="search"
                placeholder="Search..."
                disabled={loading}
                setValue={props.restoreFilterValues(clientsFilters, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name="countries"
                options={countries}
                placeholder="Country"
                setValue={props.restoreFilterValues(clientsFilters, 'countries')}
                search
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name="clientTypes"
                options={clientTypes}
                placeholder="Type"
                setValue={props.restoreFilterValues(clientsFilters, 'clientTypes')}
                search
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name="products"
                options={products}
                placeholder="Product"
                setValue={props.restoreFilterValues(clientsFilters, 'products')}
                search
            />
            <Field
                className="--minimalWidth --isInternal"
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name="isInternal"
                options={[
                    {text: 'Internal', value: 1},
                    {text: 'Not internal', value: 0},
                ]}
                placeholder="Internal?"
                setValue={props.restoreFilterValues(clientsFilters, 'isInternal')}
            />
            <Field
                className="--minimalWidth --status"
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name="isDisabled"
                options={[
                    {text: 'Enabled', value: 0},
                    {text: 'Disabled', value: 1}
                ]}
                placeholder="Status"
                setValue={props.restoreFilterValues(clientsFilters, 'isDisabled')}
            />
        </div>
    );
};

ClientFilters.propTypes = {
    clientTypes: PropTypes.array,
    countries: PropTypes.array,
    loading: PropTypes.bool,
    products: PropTypes.array,
    data: PropTypes.object,
    filters: PropTypes.shape({
        Clients: PropTypes.object
    }),
    restoreFilterValues: PropTypes.func.isRequired,
};

ClientFilters.defaultProps = {
    data: {
        clientTypes: [],
        countries: [],
        products: []
    }
};

export default FiltersWrapper(ClientFilters, {name: "Clients"});
