import {has as _has} from "lodash";

import Form from "../../app/components/ReduxFormControls";
import {convertToInt} from "../../../utils/helpers";

import * as MESSAGES from "../../app/constants/messages";
import {THIRD_PARTY_CLIENT_VOD_STORAGE} from "../../app/constants/resources";
import {UPLOAD_TARGET_TYPE_AMAZON_S3} from "../../app/constants/variables";

import validate from "./validatorThirdPartyClientVodStorage";

import {
    CreateThirdPartyClientVodStorageAmazonS3,
    CreateThirdPartyClientVodStorageFtp,
    DeleteThirdPartyClientVodStorage,
    UpdateThirdPartyClientVodStorage,
} from "../../../graphql/thirdPartyClientVodStorage/mutation.graphql";
import {
    GetThirdPartyVodStorageDropdownData,
    GetThirdPartyVodStorageForForm
} from "../../../graphql/thirdPartyClientVodStorage/query.graphql";

export default function ThirdPartyClientVodStorageModel() {
    return {
        deleteId: "thirdPartyClientVodStorage.id",
        entityDataMapKey: "thirdPartyClientVodStorage",
        entityLabel: "VOD storage",
        formName: "VOD storage",
        resources: THIRD_PARTY_CLIENT_VOD_STORAGE,
        showChangeLog: true,
        validate: validate,
        title: () =>  "Add new VOD storage",
        dataMap: {
            thirdPartyClientVodStorage: {
                id: "",
                name: "",
                upload_target_type: {
                    id: "",
                },
                path: "",
                encoding_job_profile: {
                    id: ""
                },
                upload_metadata_file: "",
                third_party_client_vod_storage_ftp: {
                    hostname: "",
                    username: "",
                    password: "",
                },
                third_party_client_vod_storage_amazon_s3: {
                    secret_key: "",
                    access_key: "",
                    region: "",
                    bucket: "",
                },
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('VOD storage'),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "thirdPartyClientVodStorage.id",
                props: {
                    label: '',
                    name: "id",
                    hidden: true,
                },
            },
            name: {
                dataMapKey: "thirdPartyClientVodStorage.name",
                props: {
                    autoFocus: true,
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            upload_target_type: {
                dataMapKey: "thirdPartyClientVodStorage.upload_target_type.id",
                optionsKey: "uploadTargetTypes",
                props: {
                    className: "--auto_width",
                    component: Form.SemanticSelect,
                    label: "Type",
                    name: "upload_target_type",
                    required: true,
                },
            },
            path: {
                dataMapKey: "thirdPartyClientVodStorage.path",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    label: "Path",
                    name: "path",
                    required: true,
                    type: "input",
                },
            },
            encoding_job_profile: {
                dataMapKey: "thirdPartyClientVodStorage.encoding_job_profile.id",
                optionsKey: "encodingJobProfiles",
                props: {
                    className: "--auto_width",
                    component: Form.SemanticSelect,
                    label: "Encoding job profile",
                    name: "encoding_job_profile",
                    required: true,
                },
            },
            upload_metadata_file: {
                dataMapKey: "thirdPartyClientVodStorage.upload_metadata_file",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    label: "Upload metadata file",
                    name: "upload_metadata_file",
                    type: "checkbox",
                },
            },
            hostname: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_ftp.hostname",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Hostname",
                    name: "hostname",
                    required: true,
                    type: "input",
                },
            },
            username: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_ftp.username",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Username",
                    name: "username",
                    required: true,
                    type: "input",
                },
            },
            password: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_ftp.password",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Password",
                    name: "password",
                    required: true,
                    type: "input",
                },
            },
            secret_key: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_amazon_s3.secret_key",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Secret key",
                    name: "secret_key",
                    required: true,
                    type: "input",
                },
            },
            access_key: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_amazon_s3.access_key",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Access key",
                    name: "access_key",
                    required: true,
                    type: "input",
                },
            },
            region: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_amazon_s3.region",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Region",
                    name: "region",
                    required: true,
                    type: "input",
                },
            },
            bucket: {
                dataMapKey: "thirdPartyClientVodStorage.third_party_client_vod_storage_amazon_s3.bucket",
                props: {
                    className: "--medium-width",
                    component: Form.SemanticInput,
                    hidden: true,
                    label: "Bucket",
                    name: "bucket",
                    required: true,
                    type: "input",
                },
            },
        },
        mutation: {
            createEntity: (data) => {
                const uploadTargetType = _has(data, 'third_party_client_vod_storage.upload_target_type')
                    ? convertToInt(data.third_party_client_vod_storage.upload_target_type)
                    : convertToInt(data.upload_target_type);

                return (UPLOAD_TARGET_TYPE_AMAZON_S3 === uploadTargetType)
                    ? CreateThirdPartyClientVodStorageAmazonS3
                    : CreateThirdPartyClientVodStorageFtp;
            },
            deleteEntity: DeleteThirdPartyClientVodStorage,
            updateEntity: UpdateThirdPartyClientVodStorage,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetThirdPartyVodStoragesForTable'],
            },
            update: {
                refetchQueries: ['GetThirdPartyVodStoragesForTable'],
            },
            delete: {
                refetchQueries: ['GetThirdPartyVodStoragesForTable'],
            },
        },
        query: {
            getEntity: GetThirdPartyVodStorageForForm,
            getOptions: GetThirdPartyVodStorageDropdownData,
        },
    };
}
