import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";

import PropertyLicencePricingForm from "../forms/PropertyLicencePricingForm";
import PropertyLicencePricingModel from "../forms/PropertyLicencePricingModel";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";

const PropertyLicencePricing = ({match: {params: {id: propertyLicenceId}}}) => {
    const renderForm = () => {
        const Form = createForm(
            PropertyLicencePricingModel,
            PropertyLicencePricingForm, {
                id: convertToInt(propertyLicenceId),
            }
        );

        return <Form/>
    };

    return (
        React.useMemo(() => renderForm(), [propertyLicenceId])
    )
};

PropertyLicencePricing.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        })
    })
};

export default withRouter(PropertyLicencePricing);
