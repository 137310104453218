import React from 'react';

import {IconInfoCircle} from '@appComponents/IconCollection';
import {EVENT_BOOKING} from '@constants/resources';
import {BOOKING_TYPE_AVCMP_MANUAL} from '@constants/variables';
import * as MESSAGES from '@constants/messages';
import Form from '@appComponents/ReduxFormControls';
import validate from '@modules/events/forms/validatorEventsEventBookings';
import {renderEventBookingUserInformation} from '@modules/events/forms/helpers/eventBooking';

export default function EventBookingGeneralInformationCommonModel() {
    const informationTooltip = (content) => <IconInfoCircle
        tooltip={content}
    />;

    return {
        entityDataMapKey: 'eventEventBooking',
        entityLabel: 'event booking',
        formName: 'EventBookingGeneralInformation',
        label: 'Event Booking',
        editForm: false,
        resources: EVENT_BOOKING,
        showChangeLog: false,
        title: null,
        validate: validate,
        messages: {
            boxName: {
                delete: 'event',
                success: 'event',
                error: 'formInnerErrorMessage',
            },
            text: MESSAGES,
        },
        dataMap: {
            eventBooking: {
                id: '',
                client: {
                    id: '',
                },
                product: {
                    id: '',
                },
                client_package: {
                    id: '',
                },
                auto_update_geo_restrictions: true,
                booking_type: {
                    id: BOOKING_TYPE_AVCMP_MANUAL,
                },
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventEventBooking.id',
                props: {
                    name: 'id',
                },
            },
            booking_type: {
                component: Form.IdField,
                dataMapKey: 'eventEventBooking.booking_type.id',
                props: {
                    defaultValue: BOOKING_TYPE_AVCMP_MANUAL,
                    name: 'booking_type',
                    hidden: true,
                },
            },
            client: {
                dataMapKey: 'eventEventBooking.client.id',
                optionsKey: 'clients',
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    label: 'Client',
                    name: 'client',
                },
            },
            product: {
                dataMapKey: 'eventEventBooking.product.id',
                optionsKey: 'products',
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    label: 'Product',
                    name: 'product',
                    className: '--long-width',
                },
            },
            client_package: {
                dataMapKey: 'eventEventBooking.client_package.id',
                optionsKey: 'client_packages',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Client package',
                    name: 'client_package',
                    allowClear: true,
                },
            },
            auto_update_geo_restrictions: {
                dataMapKey: 'eventEventBooking.auto_update_geo_restrictions',
                props: {
                    component: Form.SemanticInput,
                    label: 'Auto-update geo restrictions',
                    name: 'auto_update_geo_restrictions',
                    type: 'checkbox',
                    props: {
                        description: informationTooltip('Select this box if you want the geo restrictions to be automatically updated when changes in \n' +
                            'media-rights, client packages or event settings affect the geo restrictions of this event booking.'),
                    },
                },
            },
            booked_by: {
                dataMapKey: 'eventEventBooking.booked_by',
                props: {
                    component: renderEventBookingUserInformation,
                    label: 'Booked by',
                    name: 'booked_by',
                    type: 'text',
                },
            },
            cancelled_by: {
                dataMapKey: 'eventEventBooking.cancelled_by',
                props: {
                    component: renderEventBookingUserInformation,
                    label: 'Cancelled by',
                    name: 'cancelled_by',
                    type: 'text',
                },
            },
            is_cancelled: {
                component: Form.IdField,
                dataMapKey: 'eventEventBooking.is_cancelled',
                props: {
                    component: Form.SemanticInput,
                    type: 'checkbox',
                    name: 'is_cancelled',
                    hidden: true,
                },
            },
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            update: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            delete: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
        },
        create: {},
        update: {
            options: {},
        },
        delete: {},
        query: {},
    };
}
