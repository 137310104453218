import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {queryGetClipConfigForEventBooking} from '@graphql/eventEventBooking/query';
import {convertToInt} from '@utils/helpers';
import {getEventBookingDataFromAllSteps, generateTree}
    from '@modules/events/utils/EventEventBooking/eventBookingDataFromAllSteps';
import {
    editValidation,
    onCloseModal,
    prepareClipsTree,
    setMutationSuccess,
} from '@modules/eventBooking/forms/helpers/helpers';

export class EventBookingClipsEditForm extends DefaultForm {
    prepareDataForSubmit = () => {
        return getEventBookingDataFromAllSteps(this.props.stepsMethods, convertToInt(this.props.formParams.id));
    };

    onFormSubmit = (data) => {
        if (editValidation(this.props.stepsMethods)) {
            this.setState(() => ({
                defaultForm_loading: true,
            }));
            super.onFormSubmit(data);
        }
    }

    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
        this.setDeleteSuccessCallback(() => {
            if (this.props.formParams.optionsVariables.event) {
                setMutationSuccess(this.props);
            }

            onCloseModal(
                this.props.formParams.optionsVariables.event,
                this.props.formParams.optionsVariables.client,
                this.props.Modal.setModal,
                this.props
            );
        });
    }

    componentDidUpdate() {
        const productId = _get(this.props.GraphQLEntityData.eventEventBooking, 'product.id');
        const eventId = _get(this.props.GraphQLEntityData.eventEventBooking, 'event.id');
        const selectedObject = _get(this.props.GraphQLEntityData, 'eventEventBooking.event_content_type_configs');
        let objectToSelect = [];

        if (selectedObject) {
            objectToSelect = selectedObject.map((selectedClip) => {
                const selectedEvent = selectedClip.event_content_type.id;
                const selectedMatch = selectedClip.match_event_types.map(({id}) => id);

                return {
                    firstLevelTree: selectedEvent,
                    secondLevelTree: selectedMatch,
                };
            });
            prepareClipsTree(
                eventId,
                productId,
                this.state,
                (state) => this.setState(state),
                this.setField,
                this.runApolloRequest,
                queryGetClipConfigForEventBooking,
                this.props.MessageBox
            );

            if (this.state.clipConfigsForEventBooking) {
                if (!this.state.generatedClipConfigTreeEventBooking) {
                    const tree = generateTree(
                        this.state.clipConfigsForEventBooking,
                        objectToSelect);

                    if (!_isEmpty(tree)) {
                        this.setField('clips', {
                            required: true,
                            options: tree,
                        });
                    }

                    this.setState(() => ({generatedClipConfigTreeEventBooking: true}));
                }
            }
        }

        this.props.stepsMethods.setForm('step2', {formValues: this.props.formValues});
    }

    onCancel = () => {
        onCloseModal(
            this.props.formParams.optionsVariables.event,
            this.props.formParams.optionsVariables.client,
            this.props.Modal.setModal,
            this.props
        );
    };

    renderCancelButton = (props) => super.renderCancelButton({
        ...props,
        type: 'button',
        onClick: this.onCancel,
    });
}

export default withRouter(EventBookingClipsEditForm);
