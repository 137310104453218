import {reset} from 'redux-form';
import {isArray as _isArray} from 'lodash';
import {
    clearMessage,
    removeEntity,
    resetFilter,
    saveMenu,
    saveMessage,
    setFilter,
    setModal,
    setModalConfirmation,
    setModalError,
    setRedirectLoaderVisibility,
    storeEntity,
    storeMenu,
    toggleSidebar
} from "../actions";

export default function mapModulesToProps(config) {
    if (!_isArray(config)) {
        config = [config]
    }

    return (dispatch) => {
        return config.reduce((result, module) => {
            switch (module) {
                case 'Modal':
                    result.Modal = {
                        setModal: (params) => {
                            dispatch(setModal(params))
                        },
                        setModalConfirmation: (params) => {
                            dispatch(setModalConfirmation(params))
                        },
                        setModalError: (params) => {
                            dispatch(setModalError(params))
                        }
                    };
                    break;
                case 'MessageBox':
                    result.MessageBox = {
                        addMessage: (name, header, text, type, stayVisible, addClosedTimestamp) => {
                            dispatch(saveMessage({name, header, text, type, stayVisible, addClosedTimestamp}));
                        },
                        removeMessage: (name) => {
                            dispatch(clearMessage({name: name}));
                        },
                    };
                    break;
                case 'Filters':
                    result.Filters = {
                        setFilter: (data) => {
                            dispatch(setFilter(data))
                        },
                        resetFilter: (name) => {
                            dispatch(resetFilter(name))
                        }
                    };
                    break;
                case 'Entity':
                    result.Entity = {
                        setEntity: (entity) => {
                            dispatch(storeEntity(entity))
                        },
                        removeEntity: (name) => {
                            dispatch(removeEntity(name))
                        },
                    };
                    break;
                case 'Form':
                    result.Form = {
                        clearForm: (formName) => {
                            dispatch(reset(formName));
                        }
                    };
                    break;
                case 'Menu':
                    result.Menu = {
                        storeMenu: (key) => {
                            dispatch(storeMenu(key));
                        },
                        saveMenu: (key) => {
                            dispatch(saveMenu(key))
                        },
                        toggleSidebar: (key) => {
                            dispatch(toggleSidebar(key))
                        },
                        setRedirectLoaderVisibility: (isRedirectLoaderVisible) => {
                            dispatch(setRedirectLoaderVisibility(isRedirectLoaderVisible))
                        }
                    };
                    break;
            }

            return result;
        }, {});
    };
}
