import {connect} from 'react-redux';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {graphql} from '@apollo/client/react/hoc';

import {Authorization} from '@appComponents/Authorization';
import ContactPersonList from '@modules/contactPerson/components/ContactPersonList';
import validateContactPersonType from '@modules/contactPerson/utils/validateContactPersonType';
import {GetContactPersonForForm, GetLicensorContactPersonsForList} from '@graphql/contactPerson/query';
import {GetLicensorForTabs} from '@graphql/licensor/query';
import * as VARIABLES from '@constants/variables';
import {CONTACT_PERSON, LICENSOR} from '@constants/resources';
import {routes} from '@constants/routes';
import * as formUtils from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';

class ContactPersonIndex extends React.Component {
    authorizationObject = {
        'contactPerson': {
            [LICENSOR]: VARIABLES.SECURITY_PRIVILEGES_READ,
            [CONTACT_PERSON]: VARIABLES.SECURITY_PRIVILEGES_READ,
        },
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.DataContactPersonsList.error !== undefined) {
            this.props.Modal.setModal({
                isVisible: true,
                content: formUtils.renderModalError('Licensor', routes.licensors.index.path),
            });
        }

        if (this.props.name) {
            this.setState(() => ({
                modalName: this.props.name
            }));
        }
    }

    getPopupState() {
        const personTypeFromURL = convertToInt(formUtils.getUrlParam('contact_person_type_id', this.props.location.search)),
            personType = convertToInt(_get(this.props.DataContactPerson, 'contactPerson.contact_person_type.id', null)),
            contactPersonId = this.props.match.params.contactPersonId,
            addPath = routes.licensors.contactPerson.add.path,
            editPath = routes.licensors.contactPerson.edit.path,
            shouldOpenPopup = [addPath, editPath].includes(this.props.match.path);

        validateContactPersonType([VARIABLES.CONTACT_PERSON_TYPE_LICENSOR, VARIABLES.CONTACT_PERSON_TYPE_SPORTRADAR],
            personTypeFromURL,
            personType,
            addPath === this.props.match.path,
            'licensors',
            this.props.match.params.id
        );

        if (contactPersonId && personType !== personTypeFromURL) {
            return {
                isLicensor: false,
                isSportradar: false,
                config: null,
            };
        }

        return {
            isLicensor: shouldOpenPopup && VARIABLES.CONTACT_PERSON_TYPE_LICENSOR === personTypeFromURL,
            isSportradar: shouldOpenPopup && VARIABLES.CONTACT_PERSON_TYPE_SPORTRADAR === personTypeFromURL,
            config: {
                id: contactPersonId,
                type: contactPersonId ? 'edit' : 'add',
                person: personTypeFromURL,
            },
        };
    }

    render() {
        const popupState = this.getPopupState(),
            parent = {
                name: 'licensors',
                id: this.props.match.params.id,
                licencorsName: _get(this.state,'modalName', null) ?? _get(this.props.GetLicensorName.licensor, 'name', null),
            };

        return (
            <div>
                <Segment basic className='content__table'>
                    <Authorization authorization={this.authorizationObject.contactPerson}>
                        <ContactPersonList
                            addButtonText='Add new licensor contact person'
                            title='Licensor contact persons'
                            data={this.props.DataContactPersonsList.licensorContactPerson}
                            loading={this.props.DataContactPersonsList.loading}
                            refetchList={this.props.DataContactPersonsList.refetch}
                            parent={parent}
                            personType={VARIABLES.CONTACT_PERSON_TYPE_LICENSOR}
                            openPopup={popupState.isLicensor ? popupState.config : null}
                        />
                    </Authorization>
                </Segment>
                <Segment basic className='content__table'>
                    <Authorization authorization={this.authorizationObject.contactPerson}>
                        <ContactPersonList
                            addButtonText='Add new Sportradar contact person'
                            title='Sportradar contact persons'
                            data={this.props.DataContactPersonsList.sportradarContactPerson}
                            loading={this.props.DataContactPersonsList.loading}
                            refetchList={this.props.DataContactPersonsList.refetch}
                            parent={parent}
                            personType={VARIABLES.CONTACT_PERSON_TYPE_SPORTRADAR}
                            openPopup={popupState.isSportradar ? popupState.config : null}
                        />
                    </Authorization>
                </Segment>
            </div>
        );
    }
}

ContactPersonIndex.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    DataContactPersonsList: PropTypes.object,
    DataContactPerson: PropTypes.object,
    location: PropTypes.object,
    Modal: PropTypes.object,
    name: PropTypes.string,
    GetLicensorName: PropTypes.object,
};

export default withRouter(compose(
    graphql(GetLicensorContactPersonsForList, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    licensor_id: (props.match.params.id) ? convertToInt(props.match.params.id) : null,
                    licensor_contact_person_type: VARIABLES.CONTACT_PERSON_TYPE_LICENSOR,
                    sportradar_contact_person_type: VARIABLES.CONTACT_PERSON_TYPE_SPORTRADAR,
                },
            };
        },
        name: 'DataContactPersonsList',
    }),
    graphql(GetContactPersonForForm, {
        skip: (props) => !props.match.params.contactPersonId,
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    id: (props.match.params.contactPersonId) ? convertToInt(props.match.params.contactPersonId) : null,
                },
            };
        },
        name: 'DataContactPerson',
    }),
    graphql(GetLicensorForTabs, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    id: (props.match.params.id) ? convertToInt(props.match.params.id) : null,
                },
            };
        },
        name: 'GetLicensorName',
    }),
)(connect(null, mapModulesToProps(['Modal']))(ContactPersonIndex)));
