import NotificationModel from "./NotificationModel";
import {validate} from "./validatorMasterEventNotification";

import {CreateMasterEventNotification} from "../../../graphql/notifications/mutation.graphql"
import {MASTER_EVENT_NOTIFICATION} from "../../app/constants/resources";

export default function MasterEventNotificationModel() {
    const masterEventNotificationModel = NotificationModel();

    masterEventNotificationModel.messages.boxName.success = "childEventsIndex";
    masterEventNotificationModel.messages.boxName.error = "childEventsIndex";

    masterEventNotificationModel.resource = MASTER_EVENT_NOTIFICATION;
    masterEventNotificationModel.entityLabel = "MasterEventNotification";
    masterEventNotificationModel.formName = "masterEventNotification";
    masterEventNotificationModel.validate = validate;
    masterEventNotificationModel.mutation.createEntity = CreateMasterEventNotification;
    masterEventNotificationModel.fields.recipient_type.props.props.options[0].text
        = "At least one child event is booked by the client";
    masterEventNotificationModel.fields.recipient_type.props.props.options[1].text
        = "At least one child event is offered to the client";


    return masterEventNotificationModel;
}
