import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';

import {convertToInt} from '@utils/helpers';
import validators from '@utils/validators';
import {
    CLIENT_SELECTION_TYPE_ALL,
    TERRITORY_SELECTION_TYPE_WHITELIST,
    TERRITORY_SELECTION_TYPE_BLACKLIST,
} from '@constants/variables';
import {getSelectionTypeValue} from '@modules/events/utils/eventGeoRestriction';

const validate = (values, props) => {
    const errors = {};

    if ('EventGeoRestrictionGeneralInformation' === props.Model.formName) {
        Object.assign(errors, validators.required(values.client_selection_type, 'client_selection_type'));
        Object.assign(errors, validators.required(values.usages, 'usages'));

        if (!values.includes_all_event_contents) {
            Object.assign(errors, validators.required(values.event_contents, 'event_contents'));
        }

        if (CLIENT_SELECTION_TYPE_ALL !== convertToInt(values.client_selection_type)) {
            Object.assign(errors, validators.required(values.clients, 'clients'));
        }
    } else if ('EventGeoRestriction' === props.Model.formName) {
        const requiredSubdivisionCountries = _get(props.GraphQLOptionsData, 'countries', []),
            selectionType = getSelectionTypeValue(values.selection_type);

        if (TERRITORY_SELECTION_TYPE_WHITELIST === selectionType
            || TERRITORY_SELECTION_TYPE_BLACKLIST === selectionType) {
            Object.assign(errors, validators.required(values.countries, 'countries'));
        }

        if (!_isEmpty(requiredSubdivisionCountries) && _get(values, 'countries', []).length) {
            Object.assign(errors, validators.requiredAtLeastOneSubdivisionPerCountry({
                countries: values.countries,
                subdivisions: values.subdivisions,
                required: requiredSubdivisionCountries.map((country) => {
                    return country.id;
                }),
            }, 'subdivisions'));
        }
    }

    return errors;
};

export default validate;
