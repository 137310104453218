import {
    get as _get
} from "lodash";
import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const menu = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MENU_STORE: {
            return Object.assign({}, state, {active: action.key, opened: action.key})
        }
        case actionTypes.MENU_SAVE: {
            return Object.assign({}, state, {opened: action.key})
        }
        case actionTypes.MENU_TOGGLE: {
            return Object.assign({}, state, {expanded: action.key})
        }
        case actionTypes.SET_REDIRECT_LOADER_VISIBILITY: {
            return Object.assign({}, state, {
                isRedirectLoaderVisible: action.params.isRedirectLoaderVisible,
                redirectError: _get(action, 'params.redirectError', state.redirectError)
            })
        }
        
        case "persist/REHYDRATE": {
            const savedData = _get(action, "payload.app.menu", false) || initialState;

            return {
                ...state,
                ...savedData,
                isRedirectLoaderVisible: false,
                redirectError: false
            }
        }

        default:
            return state
    }
};

export default menu
