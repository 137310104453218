import {withRouter} from "react-router";
import {get as _get} from "lodash";

import {getLink} from "../../app/components/Link";

import GeoRestrictionsForm from "../../geoRestriction/forms/GeoRestrictionsForm";

import {convertToInt} from "../../../utils/helpers";

class RightsScopeGeoRestrictionForm extends GeoRestrictionsForm {
    prepareDataForSubmit = (data) => {
        let countries = [],
            subdivisions = [];

        data.countries.map((continent) => (
            continent.children.map((country) => {
                countries.push(convertToInt(country.id));
            })
        ));

        data.subdivisions.map((country) => (
            country.children.map((subdivision) => {
                subdivisions.push(convertToInt(subdivision.id));
            })
        ));

        return Object.assign({}, {
            id: convertToInt(data.id),
            territory_selection_type: convertToInt(data.selection_type),
            countries: countries,
            country_subdivisions: subdivisions,
        });
    };

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'rightsScope', {});
    };

    renderErrors(errorData) {
        const link = getLink("propertyLicences.rightsScopes.index", {
            id: this.props.match.params.id || null,
        });

        super.renderErrors(errorData, 'Rights scope', link);
    }

    renderAdditionalButtons = () => {
        return null;
    };

    renderCancelButton() {
        return null;
    }

    renderDeleteButton = () => {
        return null;
    };
}

export default withRouter(RightsScopeGeoRestrictionForm);
