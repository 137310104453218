import PropTypes from 'prop-types';
import {useEffect} from 'react';

import {getLink} from '@appComponents/Link';
import {showErrorModal} from '@utils/modal';

export function ClientRedirect({
    clientId = null,
    history,
}) {
    useEffect(() => {
        clientId
            ? history.push(getLink('clients.edit', {id: clientId}))
            : showErrorModal({
                errorButtonIcon: 'home',
                errorButtonText: 'Go to home page',
                header: 'Page not found',
                redirectLink: getLink('dashboard'),
                text: 'The requested bookmaker could not be found.',
            });
    },[]);

    return null;
}

ClientRedirect.propTypes = {
    clientId: PropTypes.number,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};
