import {GetClientProductSpottForGeoRestrictionsForm} from "../../../graphql/clientProductSpott/query.graphql";
import {UpdateGeoRestrictionsClientProductSpott} from "../../../graphql/clientProductSpott/mutation.graphql";

import ProductLctGeoRestrictionsModel from "./ProductLctGeoRestrictionsModel";

export default function ProductSpottGeoRestrictionsModel() {
    let productSpottGeoRestrictionsModel = ProductLctGeoRestrictionsModel();

    productSpottGeoRestrictionsModel.entityDataMapKey = "clientProductSpott";
    productSpottGeoRestrictionsModel.formName = "product SpOTT";
    productSpottGeoRestrictionsModel.fields.id.dataMapKey = "clientProductSpott.id";
    productSpottGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductSpott,
    };
    productSpottGeoRestrictionsModel.query.getEntity = GetClientProductSpottForGeoRestrictionsForm;

    return productSpottGeoRestrictionsModel;
}
