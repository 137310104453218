import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorClientCdnConfiguration";
import * as MESSAGES from "../../app/constants/messages";
import {CLIENT_CDN_CONFIGURATION as RESOURCE_CLIENT_CDN_CONFIGURATION} from "../../app/constants/resources";

import {
    CreateClientCdnConfiguration,
    DeleteClientCdnConfiguration,
    UpdateClientCdnConfiguration
} from "../../../graphql/clientCdnConfiguration/mutation.graphql";
import {
    GetClientCdnConfigurationFormDropdownData,
    GetClientCdnConfigurationsForForm
} from "../../../graphql/clientCdnConfiguration/query.graphql";

export default function ClientCdnConfigurationModel() {
    return {
        deleteId: 'clientCdnConfiguration.id',
        entityDataMapKey: 'clientCdnConfiguration',
        entityLabel: 'CDN configuration',
        formName: 'ClientCdnConfiguration',
        resources: RESOURCE_CLIENT_CDN_CONFIGURATION,
        showChangeLog: true,
        validate: validate,
        title: () => {
            return null;
        },
        mutation: {
            createEntity: CreateClientCdnConfiguration,
            deleteEntity: DeleteClientCdnConfiguration,
            updateEntity: UpdateClientCdnConfiguration,
        },
        query: {
            getEntity: GetClientCdnConfigurationsForForm,
            getOptions: GetClientCdnConfigurationFormDropdownData,
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                success: 'clientMessage',
                error: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => MESSAGES.FORM_ERROR('CDN configuration')
            },
        },
        dataMap: {
            clientCdnConfiguration: {
                id: '',
                name: '',
                client_product: '',
                cdn: '',
                live_ingest_method: '',
                recording_ingest_method: '',
                akamai_cdn_ingest_method: '',
                akamai_cp_code: '',
                encoder_ingest_password: '',
                akamai_live_cp_hostname: '',
                akamai_vod_cp_hostname: '',
                has_external_storage: '',
                vod_netstorage_hostname: '',
                vod_netstorage_username: '',
                vod_netstorage_password: '',
                vod_netstorage_upload_path: '',
                vod_netstorage_playback_path: '',
                live_encoding_job_profile: '',
                recording_encoding_job_profile: '',
                encoding_datacenters: '',
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'clientCdnConfiguration.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            name: {
                dataMapKey: 'clientCdnConfiguration.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    required: true,
                    name: 'name',
                },
            },
            client_product: {
                dataMapKey: 'clientCdnConfiguration.client_product.id',
                optionsKey: 'client_product',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Product',
                    required: true,
                    name: 'client_product',
                },
            },
            cdn: {
                dataMapKey: 'clientCdnConfiguration.cdn.id',
                optionsKey: 'cdn',
                props: {
                    component: Form.SemanticSelect,
                    label: 'CDN',
                    required: true,
                    name: 'cdn',
                },
            },
            live_ingest_method: {
                dataMapKey: 'clientCdnConfiguration.live_ingest_method.id',
                optionsKey: 'ingestMethods',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Live ingest method',
                    required: true,
                    name: 'live_ingest_method',
                    allowClear: true,
                },
            },
            recording_ingest_method: {
                dataMapKey: 'clientCdnConfiguration.recording_ingest_method.id',
                optionsKey: 'ingestMethods',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Recording ingest method',
                    required: true,
                    name: 'recording_ingest_method',
                    allowClear: true,
                },
            },
            akamai_cdn_ingest_method: {
                dataMapKey: 'clientCdnConfiguration.akamai_cdn_ingest_method.id',
                optionsKey: 'akamai_cdn_ingest_method',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Akamai CDN ingest method',
                    required: true,
                    name: 'akamai_cdn_ingest_method',
                    hidden: true,
                },
            },
            akamai_cp_code: {
                dataMapKey: 'clientCdnConfiguration.akamai_cp_code',
                props: {
                    component: Form.SemanticInput,
                    label: 'Akamai CP code',
                    name: 'akamai_cp_code',
                    hidden: true,
                },
            },
            encoder_ingest_password: {
                dataMapKey: 'clientCdnConfiguration.encoder_ingest_password',
                props: {
                    component: Form.SemanticInput,
                    label: 'Encoder ingest password',
                    name: 'encoder_ingest_password',
                    hidden: true,
                },
            },
            akamai_live_cp_hostname: {
                dataMapKey: 'clientCdnConfiguration.akamai_live_cp_hostname',
                props: {
                    component: Form.SemanticInput,
                    label: 'Akamai Live CP hostname',
                    name: 'akamai_live_cp_hostname',
                    hidden: true,
                },
            },
            akamai_vod_cp_hostname: {
                dataMapKey: 'clientCdnConfiguration.akamai_vod_cp_hostname',
                props: {
                    component: Form.SemanticInput,
                    label: 'Akamai VOD CP hostname',
                    name: 'akamai_vod_cp_hostname',
                    hidden: true,
                },
            },
            has_external_storage: {
                dataMapKey: 'clientCdnConfiguration.has_external_storage',
                props: {
                    component: Form.SemanticInput,
                    label: 'External storage',
                    name: 'has_external_storage',
                    type: 'toggle',
                    checked: true,
                    defaultValue: true,
                },
            },
            vod_netstorage_hostname: {
                dataMapKey: 'clientCdnConfiguration.vod_netstorage_hostname',
                props: {
                    component: Form.SemanticInput,
                    label: 'VOD NetStorage hostname',
                    required: true,
                    name: 'vod_netstorage_hostname',
                },
            },
            vod_netstorage_username: {
                dataMapKey: 'clientCdnConfiguration.vod_netstorage_username',
                props: {
                    component: Form.SemanticInput,
                    label: 'VOD NetStorage username',
                    required: true,
                    name: 'vod_netstorage_username',
                },
            },
            vod_netstorage_password: {
                dataMapKey: 'clientCdnConfiguration.vod_netstorage_password',
                props: {
                    component: Form.SemanticInput,
                    label: 'VOD NetStorage password',
                    required: true,
                    name: 'vod_netstorage_password',
                },
            },
            vod_netstorage_upload_path: {
                dataMapKey: 'clientCdnConfiguration.vod_netstorage_upload_path',
                props: {
                    component: Form.SemanticInput,
                    label: 'VOD NetStorage upload path',
                    required: true,
                    name: 'vod_netstorage_upload_path',
                },
            },
            vod_netstorage_playback_path: {
                dataMapKey: 'clientCdnConfiguration.vod_netstorage_playback_path',
                props: {
                    component: Form.SemanticInput,
                    label: 'VOD NetStorage playback path',
                    required: true,
                    name: 'vod_netstorage_playback_path',
                },
            },
            encoding_datacenters: {
                dataMapKey: 'clientCdnConfiguration.encoding_datacenters',
                optionsKey: 'encodingDatacenters',
                props: {
                    allowClear: true,
                    component: Form.SemanticSelect,
                    label: 'Datacenters with Aspera upload',
                    multiple: true,
                    name: 'encoding_datacenters',
                },
            },
            live_encoding_job_profile: {
                dataMapKey: 'clientCdnConfiguration.live_encoding_job_profile.id',
                optionsKey: 'live_encoding_job_profile',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Live encoding job profile',
                    name: 'live_encoding_job_profile',
                    disabled: true,
                    required: true,
                    allowClear: true,
                },
            },
            recording_encoding_job_profile: {
                dataMapKey: 'clientCdnConfiguration.recording_encoding_job_profile.id',
                optionsKey: 'recording_encoding_job_profile',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Recording encoding job profile',
                    name: 'recording_encoding_job_profile',
                    disabled: true,
                    required: true,
                    allowClear: true,
                },
            },
        },
    };
}
