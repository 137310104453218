import {isEmpty as _isEmpty, orderBy as _orderBy} from 'lodash';

import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {ClientPackageList} from '@graphql/eventBooking/query';

export const onCloseModal = (eventId, clientId, setModal, props) => {
    eventId
        ? props.history.push(getLink('events.eventBookings.index', {id: eventId}))
        : props.history.push(getLink('clients.eventBookings.index', {id: clientId}));
    setModal({isVisible: false});
};

export const getEventBookingFormParams = (id, eventBookingId, params, isEvent = false, setMutationSuccessCallback = null) => {
    const optionVariableKey = isEvent ? 'event' : 'client',
        formParamsObject = Object.assign({}, {
            id: convertToInt(eventBookingId) || convertToInt(params.eventBookingId),
            optionsVariables: {
                [optionVariableKey]: convertToInt(id),
                isArchived: null,
            },
            setMutationSuccessCallback,
        }),
        linkParams = {
            id: convertToInt(id),
            eventBookingId: convertToInt(eventBookingId) || convertToInt(params.eventBookingId),
        };

    return [formParamsObject, linkParams];
};

export const GetClipConfigForEventBooking = (event, product, setState, runApolloRequest, query, MessageBox) => {
    setState(() => ({queryGetClipIdByChosenProductLoading: true}));

    return runApolloRequest('query', {
        fetchPolicy: 'network-only',
        query: query,
        variables: {
            event: event,
            product: product,
        },
    }).catch((response) => {
        MessageBox.addMessage(
            'formInnerErrorMessage',
            'Can\'t get selected clip id for clips',
            response.message,
            'error'
        );
    });
};

export const prepareClipsTree = (event, product, state, setState, setField, runApolloRequest, query, MessageBox) => {
    if (!state.queryGetClipIdByChosenProductLoading) {
        GetClipConfigForEventBooking(event, product, (state) => setState(state), runApolloRequest, query, MessageBox)
            .then((result) => {
                if (_isEmpty(result.data.clipConfigsForEventBooking)) {
                    setField('clips', {
                        options: null,
                        showNoDataText: true,
                        noDataText: 'There is no clip configuration for the sport of this event.',
                        required: true,
                    });
                } else {
                    setState(() => ({
                        clipConfigsForEventBooking: _orderBy(result.data.clipConfigsForEventBooking, 'event_content_type.name', ['asc']),
                    }));
                }
            });
    }
};

export const populateClientPackages = (setField, runApolloRequest, clientId, productId) => {
    setField('client_package', {
        defaultValue: null,
        disabled: true,
        loading: true,
    });

    runApolloRequest('query', {
        fetchPolicy: 'network-only',
        query: ClientPackageList,
        variables: {
            client: [convertToInt(clientId)],
            product: [convertToInt(productId)],
        },
    }).then((response) => {
        if (!_isEmpty(response.data.clientPackages)) {
            setField('client_package', {
                options: response.data.clientPackages,
                loading: false,
                disabled: false,
            });
        } else {
            setField('client_package', {
                disabled: false,
                loading: false,
            });
        }
    }).catch((response) => {
        showMessageBox('formInnerErrorMessage', 'Can\'t get client packages.', response.message, 'error');
    });
};

export const editValidation = (data) => {
    const eventBookingData = Object.assign(
        {},
        data.getForms()['step1'].formValues,
        data.getForms()['step2'].formValues,
        data.getForms()['step3'].formValues,
        data.getForms()['step4'].formValues
    );

    if (_isEmpty(eventBookingData.clips) ||
        (eventBookingData.desktop_switch && _isEmpty(eventBookingData.desktop_countries)) ||
        (eventBookingData.mobile_switch && _isEmpty(eventBookingData.mobile_countries)) ||
        (eventBookingData.smart_tv_switch && _isEmpty(eventBookingData.smart_tv_countries)) ||
        (!eventBookingData.desktop_switch && !eventBookingData.mobile_switch && !eventBookingData.smart_tv_switch)) {
        showMessageBox(
            'formInnerErrorMessage',
            '',
            'Can\'t go to next step. Please correct the highlighted fields below.',
            'error',
            true
        );

        return false;
    }

    return true;
};

export const setMutationSuccess = (props) => {
    props.formParams.setMutationSuccessCallback(Date.now());
};
