import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {IconWithTooltip} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import {isAuthorized} from '@appComponents/Authorization';
import {AUDIO} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

const IconAudio = ({iconName, history, linkParams, userPrivileges}) => {
    const hasReadAccess = isAuthorized(AUDIO, SECURITY_PRIVILEGES_READ, userPrivileges, false);

    if  (!hasReadAccess) {
        return null;
    }

    return (
        <IconWithTooltip
            iconProps={{
                size: 'large',
                'className': '--withPointer',
                corner: 'top right',
            }}
            name={iconName}
            onClick={() => {
                history.push(getLink('events.configuration.content.target.audio', linkParams));
            }}
            tooltip={'Audio configuration'}
            popupProps={{position: 'top right'}}
        />
    );
};

IconAudio.propTypes = {
    iconName: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    linkParams: PropTypes.object.isRequired,
    userPrivileges: PropTypes.object.isRequired,
};
IconAudio.displayName = 'IconAudio';

const mapStateToProps = (state) => ({
    userPrivileges: state.app.security.user.privileges,
});

export default withRouter(connect(mapStateToProps, null)(IconAudio));
