import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {TERRITORY_SELECTION_TYPE_WHITELIST} from '@constants/variables';

class ClientProductForm extends DefaultForm {

    componentDidUpdate(prevProps) {
        let {
            formParams: {isAddForm},
            GraphQLOptionsData: {
                languages,
                product: {
                    product_device_categories,
                    product_ways_of_transmission,
                },
            },
        } = this.props;

        let selected = [];

        if (isAddForm) {
            product_device_categories.map((deviceCategory) => {
                if (deviceCategory.is_default) {
                    product_ways_of_transmission.map((wayOfTransmission) => {
                        if (wayOfTransmission.is_default) {
                            selected.push({
                                id: '',
                                device_category: {
                                    id: convertToInt(deviceCategory.device_category.id),
                                },
                                way_of_transmission: {
                                    id: convertToInt(wayOfTransmission.way_of_transmission.id),
                                },
                            });
                        }
                    });
                }
            });
        } else {
            selected = prevProps.clientProduct.usages;

            this.setField("languages", {
                defaultValue: prevProps.clientProduct.languages.map(language => language.id),
                options: languages,
            });
            super.receivedPropsValidator(prevProps);
        }

        this.createUsageTreeOptions({
            deviceCategories: product_device_categories,
            selected: selected,
            waysOfTransmission: product_ways_of_transmission,
        });

        this.setState(() => ({dataReceived: true}));

        super.componentDidUpdate();
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => (null);

    prepareDataForSubmit(data) {
        let usages = [];

        data.usages.forEach(deviceCategory => {
            deviceCategory.children.forEach(wayOfTransmission => {
                usages.push({
                    "id": wayOfTransmission.id,
                    "device_category": convertToInt(deviceCategory.value),
                    "way_of_transmission": convertToInt(wayOfTransmission.value),
                });
            });
        });

        let clientProduct = {
            client_product: {
                client: convertToInt(this.props.match.params.id),
                end_date: data.end_date,
                is_disabled: !!data.is_disabled,
                process_csid: !!data.process_csid,
                enforce_csid: !!data.enforce_csid,
                is_trial: !!data.is_trial,
                languages: data.languages.map(language => convertToInt(language)),
                product: data.product,
                start_date: data.start_date,
                territory_selection_type: TERRITORY_SELECTION_TYPE_WHITELIST,
                usages: usages,
            },
        };

        if (!this.props.formParams.isAddForm) {
            clientProduct = Object.assign({id: data.id}, clientProduct);

            delete clientProduct.client_product.territory_selection_type;
        }

        return clientProduct;
    }

    createUsageTreeOptions(data) {
        let selectedOptions = {};

        if (data.selected) {
            data.selected.forEach((option) => {
                let key = `device_category-${option.device_category.id}`
                    + `.way_of_transmissions-${option.way_of_transmission.id}`;

                selectedOptions[key] = option.id;
            });
        }

        let treeData = data.deviceCategories.map((deviceCategory) => ({
            children: data.waysOfTransmission.map((wayOfTransmission) => {
                let id = _get(selectedOptions, `device_category-${deviceCategory.device_category.id}.way_of_transmissions-${wayOfTransmission.way_of_transmission.id}`,null);

                return {
                    id: id,
                    key: `${deviceCategory.id}.${id}`,
                    label: wayOfTransmission.way_of_transmission.name,
                    name: "way_of_transmissions",
                    selected: null !== id,
                    value: wayOfTransmission.way_of_transmission.id,
                };
            }),
            key: deviceCategory.id,
            label: deviceCategory.device_category.name,
            name: "device_category",
            value: deviceCategory.device_category.id,
        }));

        this.setField("usages", {options: treeData});
    }

    getEntityName = () => {
        return null;
    };
}

export default ClientProductForm;
