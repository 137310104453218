import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';

class EventPresetForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        const dataToSave = super.prepareDataForSubmit(data);

        return dataToSave.eventPreset;
    }
}

export default withRouter(EventPresetForm);
