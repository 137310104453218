import PropTypes from 'prop-types';
import {get as _get, isEmpty as _isEmpty, isPlainObject as _isPlainObject} from 'lodash';

/**
 * Get competitors object for home/away depending on type of event and competitor/team settings
 * @param {Object} event
 * @returns {Object}
 */
export const getCompetitorsObjectFromEvent = (event) => {
    let home = {},
        away = {};

    if (!_isEmpty(event.sr_event)) {
        if (event._hasMatchID) {
            const match = event.sr_event.match;

            home = (match.home_competitor);
            away = (match.away_competitor);
        } else {
            home =  _get(event, 'sr_event.home_competitor', {});
            away = _get(event, 'sr_event.away_competitor', {});
        }
    } else {
        home = _get(event, 'av_event.home_competitor', _get(event, 'av_event.home_av_competitor', {}));
        away = _get(event, 'av_event.away_competitor', _get(event, 'av_event.away_av_competitor', {}));
    }

    return {
        home: _isPlainObject(home)
            ? home
            : {},
        away: _isPlainObject(away)
            ? away
            : {},
    };
};

getCompetitorsObjectFromEvent.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            away_competitor: PropTypes.object,
            home_competitor: PropTypes.object,
            match: PropTypes.shape({
                away_competitor: PropTypes.object,
                home_competitor: PropTypes.object,
            }),
        }),
        av_event: PropTypes.shape({
            away_av_competitor: PropTypes.object,
            away_competitor: PropTypes.object,
            home_av_competitor: PropTypes.object,
            home_competitor: PropTypes.object,
        }),
        _hasMatchID: PropTypes.bool.isRequired,
    }),
};

getCompetitorsObjectFromEvent.defaultProps = {
    event: {
        sr_event: {
            away_competitor: {},
            home_competitor: {},
            match: {
                away_competitor: {},
                home_competitor: {},
            },
        },
        av_event: {
            away_av_competitor: {},
            away_competitor: {},
            home_av_competitor: {},
            home_competitor: {},
        },
    },
};
