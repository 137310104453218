import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

import {createForm} from '@utils/forms';
import {convertToInt} from '@utils/helpers';

import LicensorForm from '../forms/LicensorForm';
import LicensorModel from '../forms/LicensorModel';

class LicensorEdit extends React.Component {
    renderForm = () => {
        document.title = 'Add new licensor - AV-CMP';
        const Form = createForm(
            LicensorModel,
            LicensorForm, {
                id: convertToInt(this.props.match.params.id),
            }
        );

        return <Form/>;
    };

    render() {
        return(
            this.renderForm()
        );
    }
}

LicensorEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

export default withRouter(LicensorEdit);
