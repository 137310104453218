import PropTypes from 'prop-types';
import React from 'react';
import {gql, graphql} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";

import ClientAnalyticsSetupForm from "../forms/ClientAnalyticsSetupForm";
import {getLink} from "../../app/components/Link";

import * as clientAnalyticsSetupGraphQl from "../../../graphql/clients/clientAnalyticsSetup";

export class ClientAnalyticsSetupsIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientId: PropTypes.number.isRequired,
        DataClientAnalyticsSetups: PropTypes.object.isRequired,
        DeleteWhitelistedIp: PropTypes.func,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {clientAnalyticsSetups: undefined};
    }

    componentWillReceiveProps(nextProps) {
        const {DataClientAnalyticsSetups: {clientAnalyticsSetups}} = nextProps;

        if (clientAnalyticsSetups) {
            this.setState(() => ({
                clientAnalyticsSetups: clientAnalyticsSetups[0],
            }));
        }
    }

    resetIndexUrl = () => {
        window.history.pushState({}, '', getLink('clients.clientAnalyticsSetups.index', {id: this.props.clientId}));
    };

    render() {
        return (
            <ClientAnalyticsSetupForm
                clientId={this.props.clientId}
                clientName={this.props.client ? this.props.client.name : ""}
                formData={{clientAnalyticsSetup: this.state.clientAnalyticsSetups}}
                loading={this.props.DataClientAnalyticsSetups.loading}
                resetIndexUrl={this.resetIndexUrl}
            />
        );
    }
}

const ClientAnalyticsSetupsQuery = gql(clientAnalyticsSetupGraphQl.clientAnalyticsSetupDataQuery);

const ClientAnalyticsSetupsWithGraphQL = compose(
    graphql(ClientAnalyticsSetupsQuery, {
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    clientId: props.clientId,
                }
            };
        },
        name: "DataClientAnalyticsSetups",
    })
)(ClientAnalyticsSetupsIndex);

const mapStateToProps = (state) => {
    return {
        client: state.app.entities.client,
    };
};

export default withRouter(connect(mapStateToProps)(ClientAnalyticsSetupsWithGraphQL));
