import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';

import {matchColumns, matchRowRenderer} from '../../utils/EventMultipleCreation/tableHelper';

export const MatchesSimpleTable = ({matches = []}) => {

    const columns = matchColumns(matches);

    return <Table
        name='masterEventAlerts'
        columns={columns}
        data={matches}
        rowRenderer={matchRowRenderer}
        pagination={false}
        size='small'
        className={'mini'}
    />
};

MatchesSimpleTable.propTypes = {
    matches: PropTypes.arrayOf(PropTypes.object),
};
