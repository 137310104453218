import {commonValidatorWhitelistedIp} from '@modules/client/forms/commonValidatorWhitelistedIp';
import validators from '@utils/validators';

const validate = (values) => {
    const errors = commonValidatorWhitelistedIp(values);

    Object.assign(errors, validators.required(values.description, 'description'));
    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));
    Object.assign(errors, validators.required(values.products, 'products'));

    return errors;
};

export default validate;
