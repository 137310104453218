import {Header} from 'semantic-ui-react';
import React from 'react';
import {get as _get} from 'lodash';
import moment from 'moment/moment';

import {showModal, hideModal} from '@utils/modal';
import {loadModalForm} from '@utils/forms';
import {refetchQueryByName} from '@utils/apollo';
import {
    ENCODER_TYPE_SPORTRADAR,
    ENCODING_STATUS_ENDED,
    INGEST_METHOD_MSL_PUSH,
    PRODUCT_SPOTT,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import {HeaderEventContent} from '@modules/liveControl/views/StreamsPlayback/HeaderEventContent';
import ModifyEncodingStartDatetimeForm from '@modules/liveControl/forms/ModifyEncodingStartDatetimeForm';
import {showMessageBox} from '@utils/messageBox';

import ModifyEncodingEndDatetimeForm from '../../liveControl/forms/ModifyEncodingEndDatetimeForm';

export const getStartDatetimeDataForEncodingModal = (content, eventData) => {
    return {
        ...getDataForEncodingModal(content, eventData),
        start_datetime: content.start_datetime,
        encoding_start_datetime: content.event_content_input.encoding_start_datetime,
        encoding_start_margin: content.event_content_input.encoding_start_margin,
    };
};

export const getEndDatetimeDataForEncodingModal = (content, eventData) => {
    return {
        ...getDataForEncodingModal(content, eventData),
        end_datetime: content.end_datetime,
        event_content_is_main_content: content.is_main_content,
        encoding_end_datetime: content.event_content_input.encoding_end_datetime,
        encoding_end_margin: content.event_content_input.encoding_end_margin,
        match_id: _get(content, 'sr_event.match.id', null),
        event_content_end_datetime: content.end_datetime,
    };
};

const getDataForEncodingModal = (content, eventData) => {
    const commonData = {
        id: content.id,
        event_id: convertToInt(eventData.id),
        event_type_id: convertToInt(eventData.event_type.id),
        is_master_event: eventData.is_master,
        event_description: eventData.description,
        event_content_start_datetime: content.start_datetime,
        event_content_id: convertToInt(content.id),
        event_content_name: content.name,
        event_content_type_name: content.event_content_type.name,
        event_content_variant_id: content.event_content_variant.id,
        event_content_variant_name: content.event_content_variant.name,
        event_content_variant_short_name: content.event_content_variant.short_name,
        home_team_name: _get(eventData, 'sr_event.home_competitor.name', null)
            || _get(eventData, 'av_event.home_competitor', null),
        away_team_name: _get(eventData, 'sr_event.away_competitor.name', null)
            || _get(eventData, 'av_event.away_competitor', null),
        sport_id: _get(eventData, 'sr_event.tournament.tournament_category.sport.id', null)
            ||  _get(eventData, 'sr_event.match.tournament.tournament_category.sport.id', null),
        sport_name: _get(eventData, 'sr_event.match.tournament.tournament_category.sport.name', null)
            || _get(eventData, 'sr_event.tournament.tournament_category.sport.name', null),
        stadium_name: _get(eventData, 'sr_event.stadium.name', null)
            || _get(eventData, 'sr_event.match.stadium.name', null),
        sport_has_court: _get(eventData, 'sr_event.tournament.tournament_category.sport.has_court', null)
            || _get(eventData, 'sr_event.match.tournament.tournament_category.sport.has_court', false),
    };

    if (ENCODER_TYPE_SPORTRADAR === convertToInt(eventData.event_type.id)) {
        return {
            ...commonData,
            tournament_name: _get(eventData, 'sr_event.tournament.name')
                || _get(eventData, 'sr_event.match.tournament.name', null),
            tournament_category_name: _get(eventData, 'sr_event.tournament.tournament_category.name')
                || _get(eventData, 'sr_event.match.tournament.tournament_category.name', null),
            tv_channels: _get(eventData, 'sr_event.match.tv_channels.name', []),
        };
    }

    return {
        ...commonData,
        content_category_level_1_name: _get(eventData, 'av_event.content_category.parent.parent.name', null),
        content_category_level_2_name: _get(eventData, 'av_event.content_category.parent.name', null),
        content_category_level_3_name: _get(eventData, 'av_event.content_category.name', null),
    };
};

export const shouldShowLinkToReleaseAkamaiStreamId = (distributions, encodingTargets, eventContentInput) => {
    if (!distributions || !encodingTargets) {
        return false;
    }

    const filteredEncodingTargets = encodingTargets.filter((encodingTarget) => {
        const productId = _get(encodingTarget, 'distribution.product.id', null),
            encoderType = _get(encodingTarget, 'encoder_type.id', null),
            ingestMethod = _get(encodingTarget, 'ingest_method.id', []);

        return PRODUCT_SPOTT === convertToInt(productId)
            && ENCODER_TYPE_SPORTRADAR === convertToInt(encoderType)
            && INGEST_METHOD_MSL_PUSH === convertToInt(ingestMethod);
    });

    if (0 === filteredEncodingTargets.length) {
        return false;
    }

    const encodingStatusId = _get(eventContentInput, 'encoding_status.id', null),
        isEncodingStatusEnded = ENCODING_STATUS_ENDED === convertToInt(encodingStatusId),
        isEncodingHasBeenStartedBefore = (
            null === eventContentInput.encoding_status
            && null !== eventContentInput.encoding_first_start_datetime
        );

    return isEncodingStatusEnded || isEncodingHasBeenStartedBefore;
};


export const headerContent = (title, eventContentDetails, showBottomDivider = true) => {
    return (
        <>
            <Header content={title} />
            <HeaderEventContent eventContentDetails={eventContentDetails} showBottomDivider={showBottomDivider} />
        </>
    );
};

export const pushToEventConfigurationIndex = (eventId) => window.history.pushState(
    {}, '', getLink('events.configuration.index', {id: eventId}));

export const showStartEncodingModal = ({content, eventData, timezone}) => {
    loadModalForm({
        url: getLink(
            'events.configuration.content.changeEncodingStartTime',
            {
                id: eventData.id,
                contentId: content.id,
            }
        ),
        form: <ModifyEncodingStartDatetimeForm
            formData={getStartDatetimeDataForEncodingModal(content, eventData)}
            id={content.id}
            timezone={timezone}
            successCallback={() => {
                pushToEventConfigurationIndex(eventData.id);
                refetchQueryByName('GetEventContents');
            }}
        />,
        setModal: showModal,
        size: 'small',
        onClose: {pushToEventConfigurationIndex},
    });
};

export const showEndEncodingModal = ({content, eventData, timezone}) => {
    loadModalForm({
        url: getLink(
            'events.configuration.content.changeEncodingEndTime',
            {
                id: eventData.id,
                contentId: content.id,
            }
        ),
        form: <ModifyEncodingEndDatetimeForm
            formData={getEndDatetimeDataForEncodingModal(content, eventData)}
            id={content.id}
            timezone={timezone}
            successCallback={() => {
                pushToEventConfigurationIndex(eventData.id);
                refetchQueryByName('GetEventContents');
            }}
        />,
        setModal: showModal,
        size: 'small',
        onClose: {pushToEventConfigurationIndex},
    });
};

export const submit = ({promise, variables, successCallback}) => {
    return promise({
        variables: {...variables},
        fetchPolicy: 'no-cache',
    }).then(() => {
        if (successCallback) {
            successCallback();
        }

        hideModal();
    }).catch((error) => {
        showMessageBox('formInnerErrorMessage', null, error, 'error');
    });
};

export const formatPopupContent = (type, data) => {
    switch (type) {
        case 'getStartDatetime':
            return `${data?.encodingStartMargin} minutes before the event content start time`;
        case 'getEndDatetime':
            return `${data?.encodingEndMargin} minutes after the ${data?.eventContentEndTime ? 'event content end time' : 'end of the match'}`;
        default:
            return `formatPopupContent: ${type}`;
    }
};

export const formatTimeDiff = (baseTime, marginInMinutes, type) => {
    const formattedTime = moment(baseTime);

    switch (type) {
        case 'add':
            formattedTime.add(marginInMinutes, 'minutes');
            break;
        case 'subtract':
            formattedTime.subtract(marginInMinutes, 'minute');
            break;
    }

    return formattedTime.format('YYYY-MM-DD HH:mm:ss');
};
