import * as MESSAGES from "../../app/constants/messages";
import {THIRD_PARTY_CLIENT_CDN} from "../../app/constants/resources";

import validate from "./validatorThirdPartyCdns";

import Form from "../../app/components/ReduxFormControls";

import {renderModalHeaderTitle} from "../utils/thirdPartyCdn";

import {
    CreateThirdPartyClientCdn,
    UpdateThirdPartyClientCdn,
    DeleteThirdPartyClientCdn
} from "../../../graphql/thirdPartyClientCdn/mutation.graphql";
import {GetThirdPartyClientCdn, GetThirdPartyCdnDropdowDataForForm} from "../../../graphql/thirdPartyClientCdn/query.graphql";

export default function ThirdPartyCdnsModel() {
    return {
        entityDataMapKey: "thirdPartyClientCdn",
        entityLabel: "CDN",
        formName: "thirdPartyCdn",
        label: "Third party CDN",
        resources: THIRD_PARTY_CLIENT_CDN,
        showChangelog: true,
        title: renderModalHeaderTitle,
        autoFocusOnField: "name",
        validate: validate,
        dataMap: {
            thirdPartyClientCdn: {
                application_name: "",
                backup_gateway: "",
                encoding_job_profile: {id: ""},
                id: "",
                name: "",
                password: "",
                playback_hostname: "",
                primary_gateway: "",
                third_party_client_cdn_type: {id: ""},
                username: "",
            },
        },
        messages: {
            boxName: {
                delete: "clientMessage",
                error: "formInnerErrorMessage",
                success: "clientMessage",
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "thirdPartyClientCdn.id",
                props: {
                    hidden: true,
                    label: "",
                    name: "id",
                },
            },
            name: {
                dataMapKey: "thirdPartyClientCdn.name",
                props: {
                    component: Form.SemanticInput,
                    label: "Name",
                    name: "name",
                    required: true,
                    type: "input",
                },
            },
            third_party_client_cdn_type: {
                optionsKey: "thirdPartyClientCdnTypes",
                dataMapKey: "thirdPartyClientCdn.third_party_client_cdn_type.id",
                props: {
                    component: Form.SemanticSelect,
                    label: "Type",
                    name: "third_party_client_cdn_type",
                    placeholder: "Select type",
                    required: true,
                },
            },
            primary_gateway: {
                dataMapKey: "thirdPartyClientCdn.primary_gateway",
                props: {
                    component: Form.SemanticInput,
                    label: "Primary gateway",
                    name: "primary_gateway",
                    required: true,
                    type: "input",
                },
            },
            backup_gateway: {
                dataMapKey: "thirdPartyClientCdn.backup_gateway",
                props: {
                    component: Form.SemanticInput,
                    label: "Backup gateway",
                    name: "backup_gateway",
                    type: "input",
                },
            },
            application_name: {
                dataMapKey: "thirdPartyClientCdn.application_name",
                props: {
                    component: Form.SemanticInput,
                    label: "Application name",
                    name: "application_name",
                    type: "input",
                },
            },
            username: {
                dataMapKey: "thirdPartyClientCdn.username",
                props: {
                    component: Form.SemanticInput,
                    label: "Username",
                    name: "username",
                    type: "input",
                    shouldIgnoreLastPass: true,
                },
            },
            password: {
                dataMapKey: "thirdPartyClientCdn.password",
                props: {
                    component: Form.SemanticInput,
                    label: "Password",
                    name: "password",
                    type: "input",
                    shouldIgnoreLastPass: true,
                },
            },
            playback_hostname: {
                dataMapKey: "thirdPartyClientCdn.playback_hostname",
                props: {
                    component: Form.SemanticInput,
                    label: "Playback hostname",
                    name: "playback_hostname",
                    type: "input",
                },
            },
            encoding_job_profile: {
                optionsKey: "encodingJobProfiles",
                dataMapKey: "thirdPartyClientCdn.encoding_job_profile.id",
                props: {
                    component: Form.SemanticSelect,
                    label: "Encoding job profile",
                    name: "encoding_job_profile",
                    placeholder: "Select type",
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: CreateThirdPartyClientCdn,
            deleteEntity: DeleteThirdPartyClientCdn,
            updateEntity: UpdateThirdPartyClientCdn,
        },
        query: {
            getEntity: GetThirdPartyClientCdn,
            getOptions: GetThirdPartyCdnDropdowDataForForm,
        },
    }
}
