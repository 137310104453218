import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {isUndefined} from 'lodash';
import {List, Menu, Popup} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';

import mapModulesToProps from '@utils/mapModulesToProps';
import {getUserAuthorizationData} from '@appComponents/Authorization';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

import NavigationPopup from './NavigationPopup';
import {Icon} from './IconCollection';

export class Navigation extends React.Component {
    static propTypes = {
        activeSection: PropTypes.string,
        isExpanded: PropTypes.bool,
        menu: PropTypes.object.isRequired,
        Menu: PropTypes.object.isRequired,
        openedSection: PropTypes.string,
        sidebarWidth: PropTypes.object.isRequired,
        toggleSidebar: PropTypes.func.isRequired,
    };

    authorizationData = [];

    constructor(props) {
        super(props);

        this.authorizationData = getUserAuthorizationData();
        this.state = {
            firstLoad: true,
        };
    }

    componentDidMount() {
        this.saveOpenedSection(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.saveOpenedSection(nextProps);
    }

    saveOpenedSection = (props) => {
        if (this.state.firstLoad) {
            if (props.activeSection) {
                this.props.Menu.saveMenu(props.activeSection);
            }

            this.setState(() => ({
                firstLoad: false,
            }));
        }
    };

    handleOnClick = (event) => {
        const clickedKey = event.currentTarget.dataset.name;

        if (this.props.isExpanded) {
            if (clickedKey === this.props.openedSection) {
                this.props.Menu.saveMenu(null);
            } else {
                this.props.Menu.saveMenu(clickedKey);
            }
        }
    };

    hasReadPrivilege = (itemResource) => {
        if (!itemResource) {
            return true;
        }

        return (0 !== (this.authorizationData[itemResource] & SECURITY_PRIVILEGES_READ));
    }

    renderSubmenu(item, customClass = null) {
        if (!item || !item.submenu) {
            return null;
        }

        const Component = this.props.isExpanded ? List : Menu,
            props = {};

        if (!this.props.isExpanded) {
            props.vertical = true;
        }

        return (
            <Component className={classnames('navigation__submenu', customClass)} {...props}>
                <div className='navigation__subtitle'>{item.title}</div>
                {this.renderMenu(item.submenu, true)}
            </Component>
        );
    }

    renderIcon(item) {
        if ('string' !== typeof item.icon) {
            return item.icon;
        }

        return <Icon className='navigation__icon' name={item.icon}/>;
    }

    renderLabel(item) {
        return <span className='navigation__label'>{item.title}</span>;
    }

    renderArrow() {
        return <Icon className='navigation__state' name='dropdown'/>;
    }

    handlerWrap(item) {
        if (isUndefined(item.submenu)) {
            if (!this.hasReadPrivilege(item.resource)) {
                return null;
            }

            return (
                <NavLink
                    className='navigation__link navigation__title'
                    to={item.path}
                >
                    {this.renderIcon(item)}
                    {this.renderLabel(item)}
                </NavLink>
            );
        }

        if (item.resources) {
            let itemsWithReadPrivileges = [];

            item.resources.forEach(itemResource => {
                if (this.hasReadPrivilege(itemResource)) {
                    itemsWithReadPrivileges.push(itemResource);
                }
            });

            if (0 === itemsWithReadPrivileges.length) {
                return null;
            }
        }

        return (
            <span className={classnames('navigation__title', item.key)} data-key={item.key}>
                {this.renderIcon(item)}
                {this.renderLabel(item)}
                {this.renderArrow()}
            </span>
        );
    }

    tooltipWrap(item) {
        if (this.props.isExpanded) {
            return this.handlerWrap(item);
        }

        if (!this.props.menu[item.key].submenu) {
            return (
                <Popup
                    className={'navigation__popup'}
                    content={item.title}
                    inverted
                    style={{
                        left: this.props.sidebarWidth.collapsed,
                        right: 'auto',
                    }}
                    position='bottom left'
                    trigger={this.handlerWrap(item)}
                />
            );
        }

        return (
            <NavigationPopup
                offset={this.props.sidebarWidth.collapsed}
                trigger={this.handlerWrap(item)}
                submenu={this.renderSubmenu(this.props.menu[item.key])}
            />
        );
    }

    renderMenu(menu, submenu = false) {
        if (isUndefined(menu)) {
            return null;
        }

        return Object.keys(menu).map((key) => {
            const externalAttr = menu[key].external ? {target: '_blank'} : {};

            if (!submenu) {
                return (
                    <li key={key} className={classnames(
                        'navigation__section',
                        {'--is-active': key === this.props.activeSection},
                        {'--is-opened':
                            key === this.props.openedSection ||
                            key === this.props.activeSection,
                        }
                    )}>
                        <div onClick={this.handleOnClick} data-name={key} className={classnames(
                            'navigation__header',
                            {'--is-active': key === this.props.activeSection},
                            {'--is-opened': key === this.props.openedSection}
                        )}>
                            {this.tooltipWrap(menu[key])}
                        </div>
                        {!isUndefined(menu[key].submenu) && this.renderSubmenu(menu[key])}
                    </li>
                );
            }

            if (menu[key].isDisabled) {
                return null;
            }

            const Component = this.props.isExpanded ? List : Menu;

            return (
                <Component.Item data-name={key} key={key} className='navigation__item'>
                    {this.renderNavLink(menu[key], externalAttr)}
                </Component.Item>
            );
        });
    }

    renderNavLink(item, externalAttr) {
        const className = 'navigation__sublink item';

        if (!this.hasReadPrivilege(item.resource)) {
            return null;
        }

        if (0 === item.path.indexOf('http://') || 0 === item.path.indexOf('https://')) {
            return (
                <a className={className} href={item.path} {...externalAttr}>{item.title}</a>
            );
        }

        return (
            <NavLink className={className} to={item.path} {...externalAttr}>{item.title}</NavLink>
        );
    }

    render() {
        return (
            <ul className='navigation'>
                {this.renderMenu(this.props.menu)}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeSection: state.app.menu.active,
        openedSection: state.app.menu.opened,
    };
};

const NavigationWithRedux = connect(mapStateToProps, mapModulesToProps(['Menu']), null, {pure: false})(Navigation);

export default NavigationWithRedux;
