import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorEventContentThirdPartyClientVodStorages";

import * as CUSTOM_MESSAGES from "../constants/eventContentThirdPartyClientVodStorageMessages";
import * as MESSAGES from "../../app/constants/messages";
import {THIRD_PARTY_CLIENT_VOD_STORAGE} from "../../app/constants/resources";

import {AddVodStorageMutation} from "../../../graphql/thirdPartyClientVodStorage/mutation.graphql";
import {
    GetThirdPartyVodStoragesForEventContentAddFormData
} from "../../../graphql/thirdPartyClientVodStorage/query.graphql";

export default function EventContentThirdPartyClientVodStorageModel() {
    return {
        entityDataMapKey: "third_party_client_vod_storage",
        entityLabel: "third_party_client_vod_storage",
        formName: "EventContentThirdPartyClientVodStorage",
        resources: THIRD_PARTY_CLIENT_VOD_STORAGE,
        showChangelog: false,
        validate: validate,
        showValidationError: false,
        title: () => "Add new third party client VOD storages",
        dataMap: {
            third_party_client_vod_storage: {
                third_party_client_vod_storages: [],
            },
        },
        messages: {
            boxName: {
                delete: 'eventsConfiguration',
                error: 'eventsConfiguration',
                success: 'eventsConfiguration',
            },
            text: Object.assign({}, MESSAGES, CUSTOM_MESSAGES),
        },
        fields: {
            cdn_configuration: {
                dataMapKey: "third_party_client_vod_storage.third_party_client_vod_storages",
                optionsKey: "thirdPartyClientVodStorages",
                props: {
                    component: Form.SemanticSelect,
                    label: "",
                    multiple: true,
                    name: "third_party_client_vod_storages",
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: AddVodStorageMutation,
        },
        mutationOptions: {
            create: {
                refetchQueries: [
                    'GetEncodingTargetsForDistributions',
                    'GetEncodingTargetThirdPartyVodStoragesData',
                    'GetEventContentEncodingConfigurationChanged',
                ],
            },
        },
        query: {
            getOptions: GetThirdPartyVodStoragesForEventContentAddFormData,
        },
    };
}
