import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {isEmpty as _isEmpty} from "lodash";

import HeaderRenderer from '@appComponents/HeaderRenderer';
import mapModulesToProps from '@utils/mapModulesToProps';
import ProductionCompanyFilters from "../components/ProductionCompanyFilters";
import ProductionCompanyIndexButtons from "../components/ProductionCompanyIndexButtons";
import ProductionCompanyTable from "../components/ProductionCompanyTable";
import useQuery from '@utils/hooks/useQuery';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {exportExcel} from "../utils/export/productionCompanyIndexExcel";
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {renderProductionCompanyModalError} from "../utils/productionCompanyTabsLayoutHelper";

import navigation from '@constants/navigation';
import {IS_ARCHIVED} from '@constants/staticDropdowns';

import {GetProductionCompaniesForTable, GetProductionCompanyFilters} from '@graphql/productionCompany/query';


export const DEFAULT_FILTERS_VALUES = {
    search: null,
    countries: [],
    clients: [],
    isArchived: 0
};

export const ProductionCompanyIndex = ({areFiltersVisible = true, filters = {}, Menu, Modal}) => {
    const queryVariables = {
        search: filters.search || DEFAULT_FILTERS_VALUES.search,
        country: filters.countries ? filters.countries.map(Number) : DEFAULT_FILTERS_VALUES.countries,
        client: filters.clients ? filters.clients.map(Number) : DEFAULT_FILTERS_VALUES.clients,
        isArchived: (undefined === filters.isArchived) ? false : convertBooleanValueForFilters(filters.isArchived),
    };

    const {
        loading: areDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQuery({
        query: GetProductionCompaniesForTable,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
    });

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQuery({
        query: GetProductionCompanyFilters,
    });

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: renderProductionCompanyModalError()
        })
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.eventAdministration.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={ProductionCompanyIndexButtons}
                exportExcelParams={exportExcel(data.productionCompanies)}
                filters={ProductionCompanyFilters}
                filtersButtonName="productionCompanies"
                filtersProps={{
                    data: {
                        countries: dataForFilters.countries || DEFAULT_FILTERS_VALUES.countries,
                        clients: dataForFilters.clients || DEFAULT_FILTERS_VALUES.clients,
                        isArchived: IS_ARCHIVED,
                        isVisible: areFiltersVisible,
                    },
                    loading: areFiltersLoading,
                }}
                loading={areDataLoading}
                messagesBoxNames="productionCompanyMessage"
                pageTitle="Production companies"
            />
            <ProductionCompanyTable
                productionCompanies={data.productionCompanies}
                loading={areDataLoading}
            />
        </div>
    );
};

ProductionCompanyIndex.propTypes = {
    areFiltersVisible: PropTypes.bool,
    filters: PropTypes.shape({
        search: PropTypes.string,
        countries: PropTypes.array,
        clients: PropTypes.array,
        isArchived: PropTypes.number,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};

const ProductionCompanyIndexWrapped = FilterQueryWrapper(ProductionCompanyIndex, {
    queryForRefresh: "GetProductionCompaniesForTable",
    filterUrls: ["productionCompanies.index"]
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'productionCompanies', DEFAULT_FILTERS_VALUES)
});

export default connect(mapStateToProps, mapModulesToProps(['Menu', 'Modal']))(
    FilterUrlParamsWrapper(ProductionCompanyIndexWrapped, DEFAULT_FILTERS_VALUES)
);
