import moment from 'moment';

export const getTimeDifferenceBetweenDatesByGivenUnitOfTime = (dateA, dateB, unitOfTime = 'ms') => (
    `${moment(dateA).diff(dateB, unitOfTime)} ${unitOfTime}`
);

export const convertMillisecondsToSeconds = (milliseconds) => (moment.duration(milliseconds).asSeconds());

export const convertMillisecondsToMinutesAndSeconds = (milliseconds) => {
    const minutes = Math.floor(milliseconds.asMinutes());
    const seconds = Math.floor(milliseconds.asSeconds()) - minutes * 60;

    return `${minutes}m ${seconds}s`;
};

export const convertMillisecondsToHoursMinutesAndSeconds = (milliseconds) => {
    const hours = Math.floor(milliseconds.asHours());
    const minutes = Math.floor(milliseconds.asMinutes()) - (hours * 60);
    const seconds = Math.floor(milliseconds.asSeconds()) - (hours * 3600 + minutes * 60);

    return `${hours}h ${minutes}m ${seconds}s`;
};
