import React from 'react';
import PropTypes from "prop-types";
import {Dimmer, Loader} from "semantic-ui-react";

const RedirectView = ({isRedirectLoaderVisible}) => (
    <Dimmer active={isRedirectLoaderVisible}>
        <Loader size="massive">
            Loading
        </Loader>
    </Dimmer>
);

RedirectView.propTypes = {
    isRedirectLoaderVisible: PropTypes.bool
};

RedirectView.defaultProps = {
    isRedirectLoaderVisible: false
};

export default RedirectView;
