import * as actionTypes from '../actions/actionTypes';
import {get as _get} from "lodash";

const initialState = {
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const scrollReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SCROLL : {
            const data = {...state, ...{[action.params.pathname]: action.params.scrollOffset}};

            return data
        }

        case "persist/REHYDRATE": {
            const savedData = _get(action, "payload.app.scroll", false) || initialState;

            return {
                ...state,
                ...savedData
            }
        }

        default:
            return state
    }
};

export default scrollReducer;
