import PropTypes from 'prop-types';

import {COMPETITOR_NOT_DEFINED, COMPETITOR_NOT_DEFINED_BOTH} from '@constants/variables';
import {getTvChannelNamesFromChildEvent} from '@utils/tvChannels';

const getBookingCount = (event) => {
    let bookingCount = event.bookings_count;

    if (!bookingCount && event.event_id) {
        bookingCount = 0;
    }

    return bookingCount;
};

export const exportExcel = (dataExport, eventId, labelCourtVenue) => {
    return {
        dataExport: dataExport,
        filename: `event_${eventId}_child_events`,
        parseData: (childEvent) => {
            const tvChannels = getTvChannelNamesFromChildEvent(childEvent);
            let competitors;
            competitors = !childEvent.home_competitor && !childEvent.away_competitor
                ? COMPETITOR_NOT_DEFINED_BOTH
                : `${childEvent.home_competitor || COMPETITOR_NOT_DEFINED} : ${childEvent.away_competitor || COMPETITOR_NOT_DEFINED}`;

            return [
                childEvent.event_id,
                childEvent.match_id,
                childEvent.start_datetime,
                childEvent.category_name,
                childEvent.tournament_name,
                (childEvent.venue + (tvChannels ? '\n' + tvChannels : '')),
                competitors,
                childEvent.event_status.name,
                childEvent.products,
                getBookingCount(childEvent),
            ];
        },
        path: 'events.childEvents.index.path',
        titles: [
            'ID',
            'Match ID',
            'Date/time',
            'Category',
            'Tournament',
            labelCourtVenue,
            'Competitors',
            'Status',
            'Products',
            'Bookings',
        ],
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    eventId: PropTypes.number.isRequired,
    labelCourtVenue: PropTypes.string.isRequired,
};
