import {hasPrivileges} from "../../../app/components/Authorization";
import {ROLE} from "../../../app/constants/resources";
import Form from "../../../app/components/ReduxFormControls";

import {
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_READ,
    SECURITY_PRIVILEGES_UPDATE,
    SECURITY_PRIVILEGES_DELETE
} from "../../../app/constants/variables";
import {convertToInt} from "../../../../utils/helpers";

export const toggleHandler = (data, setField) => {
    const id = data.id || null;

    if (!id || null === id.match(/\d+/) || null === id.match(/\d+/)[0]) {
        return;
    }

    setField(`privilege_${id.match(/\d+/)[0]}`, {
        hidden: !data.checked,
        required: data.checked,
    });

    setField(`toggle_${id.match(/\d+/)[0]}`, {
        checked: data.checked,
    });
};

export const generatePrivileges = (roleResources, resourceId) => {
    const privilege = [];

    roleResources.forEach((roleResource) => {
        if (roleResource.id === resourceId) {
            if (SECURITY_PRIVILEGES_CREATE & roleResource.privileges) {
                privilege.push(SECURITY_PRIVILEGES_CREATE);
            }

            if (SECURITY_PRIVILEGES_READ & roleResource.privileges) {
                privilege.push(SECURITY_PRIVILEGES_READ);
            }

            if (SECURITY_PRIVILEGES_UPDATE & roleResource.privileges) {
                privilege.push(SECURITY_PRIVILEGES_UPDATE);
            }

            if (SECURITY_PRIVILEGES_DELETE & roleResource.privileges) {
                privilege.push(SECURITY_PRIVILEGES_DELETE);
            }
        }
    });

    return privilege;
};

const generateToggle = (resourceId, resourceName, switches, isAuthorized) => {
    return {
        dataMapKey: `role.resources.${resourceId - 1}.privileges`,
        props: {
            component: Form.SemanticInput,
            divClassName: '--auto_width --paddingRight-3rem --toggle-label-padding-top-0',
            defaultValue: switches[resourceId],
            label: resourceName,
            disabled: !isAuthorized,
            name: `toggle_${resourceId}`,
            type: "toggle",
        },
    }
};

const generateCheckboxList = (resourceId, privilege, isAuthorized) => {
    return {
        props: {
            component: Form.SemanticCheckboxList,
            className: "--checkbox-padding-top-0",
            name: `privilege_${resourceId}`,
            defaultValue: privilege,
            inline: true,
            disabled: !isAuthorized,
            hidden: 0 === privilege.length,
            required: true,
            props: {
                options: [
                    {key: `${resourceId}_create`, value: SECURITY_PRIVILEGES_CREATE, text: 'Create'},
                    {key: `${resourceId}_read`, value: SECURITY_PRIVILEGES_READ, text: 'Read'},
                    {key: `${resourceId}_update`, value: SECURITY_PRIVILEGES_UPDATE, text: 'Update'},
                    {key: `${resourceId}_delete`, value: SECURITY_PRIVILEGES_DELETE, text: 'Delete'},
                ]
            }
        }
    };
};

export const generateSwitches = (roleResources) => {
    return roleResources.reduce((switches, roleResource) => {
        return Object.assign(switches, {[roleResource.id]: 0 !== roleResource.privileges});
    }, {});
};

export const generateFields = (roleResources, securedResources) => {
    const switches = generateSwitches(roleResources),
        isAuthorized = hasPrivileges({[ROLE]: SECURITY_PRIVILEGES_UPDATE});

    return securedResources.reduce((fields, resource) => {
        const privilege = generatePrivileges(roleResources, resource.id);

        return Object.assign(fields, {
            ['resource_' + resource.id]: [
                {
                    ['toggle_' + resource.id]: generateToggle(resource.id, resource.name, switches, isAuthorized)
                },
                {
                    ['privilege_' + resource.id]: generateCheckboxList(resource.id, privilege, isAuthorized)
                }
            ]
        });
    }, {});
};

export const generateDataMapFields = (roleResources) => {
    return roleResources.reduce((dataMapFields, resource) => {
        return Object.assign(dataMapFields,{
            ["toggle_" + resource.id]: 0 !== resource.privileges
        });
    }, {});
};

export const fillRoleResources = (roleResources, resources) => {
    Object.entries(roleResources).forEach(([key, value]) => {
        const resourceId = key.match(/toggle_(\d+)/);

        if (null !== resourceId && null !== resourceId[1]) {
            let privileges = 0;

            if (value) {
                privileges = roleResources[`privilege_${resourceId[1]}`].reduce(function(a, b){
                    return a + b;
                }, 0);
            }

            if (privileges) {
                resources.push({
                    resource: convertToInt(resourceId[1]),
                    privileges: privileges
                });
            }
        }
    });
};
