import React from 'react';
import PropTypes from 'prop-types';
import {Dimmer, Divider, Grid, Loader} from 'semantic-ui-react';

import EventStreamConstraintsForm from "../forms/EventStreamConstraintsForm";

import {IconDelete, IconInfoCircleChangelog} from "../../app/components/IconCollection";

import {EVENT_STREAM_CONSTRAINT} from "../../app/constants/resources";

const columns = {
    product: {label: "Product", width: 2},
    device_category: {label: "Device category", width: 2},
    max_bitrate: {label: "Max bitrate", width: 2},
    max_resolution: {label: "Max resolution", width: 3},
    max_player_size: {label: "Max player size", width: 2},
    actions: {label: "", width: 5}
};

class EventStreamConstraintsTable extends React.Component {
    static propTypes = {
        onAdd: PropTypes.func,
        onDelete: PropTypes.func,
        onUpdate: PropTypes.func,
        loading: PropTypes.bool,
        eventStreamConstraints: PropTypes.array,
        products : PropTypes.array,
        deviceCategories  : PropTypes.array,
    };

    static defaultProps = {
        eventStreamConstraints: [],
        products: [],
        deviceCategories: [],
    };

    constructor() {
        super();

        this.state = {
            activeEditableFields: null
        };
    }

    activateEditableCell = (event) => {
        this.setState({
            activeEditableFields: parseInt(event.currentTarget.dataset.id, 10)
        });
    };

    deactivateEditableCell = () => {
        this.setState({
            activeEditableFields: null
        });
    };

    renderHeader = () => {
        const cells = Object.keys(columns).map((keys) => {
            return (
                <Grid.Column key={keys} width={columns[keys].width}>
                    {columns[keys].label}
                </Grid.Column>
            );
        });

        return (
            <Grid className="editableTable__header">
                <Grid.Row>
                    {cells}
                </Grid.Row>
            </Grid>
        );
    };

    renderButtonsForEditableRowInViewMode = (props, eventStreamConstraint) => {
        return (
            <div className="actions-list-icon">
                <IconDelete icon="trash"
                    onClick={props.onDelete}
                    onClickElement={eventStreamConstraint.id}
                />
                <IconInfoCircleChangelog
                    tooltipProps={{hoverable: true, position: "top right"}}
                    resources={EVENT_STREAM_CONSTRAINT}
                    data={eventStreamConstraint}
                />
            </div>
        );
    };

    renderEditableGrids = () => {
        return this.props.eventStreamConstraints.map((data, index) => {
            return (
                [
                    <Divider key={`divider_${index}`}/>,
                    (this.state.activeEditableFields === parseInt(data.id, 10))
                        ? this.renderEditForm(data)
                        : this.renderEditableGrid(data)
                ]
            );
        });
    };

    renderEditableGrid = (data) => {
        return (
            <Grid key={data.id} verticalAlign="middle">
                <Grid.Row  data-id={data.id} onClick={this.activateEditableCell} className="editableTable__trigger">
                    <Grid.Column width={2} >
                        {data.product.name}
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {data.device_category.name}
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {data.max_bitrate ? `${data.max_bitrate}  Kbps` : ''}
                    </Grid.Column>
                    <Grid.Column width={3} >
                        {data.max_resolution_width || data.max_resolution_height
                            ? <span>{data.max_resolution_width} x {data.max_resolution_height + " px" || '–'}</span>
                            : ''
                        }
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {data.max_player_size ? `${data.max_player_size}%` : ''}
                    </Grid.Column>
                    <Grid.Column width={5} >
                        {this.renderButtonsForEditableRowInViewMode(this.props, data)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    renderAddForm = () => {
        if ((parseInt(this.props.products.length, 10) * parseInt(this.props.deviceCategories.length, 10)) ===
            this.props.eventStreamConstraints.length) {
            return null;
        }

        return (
            <EventStreamConstraintsForm
                form="form_esc_add"
                formData={{
                    products: this.props.products,
                    deviceCategories: this.props.deviceCategories,
                    usedProductsAndCategories: this.props.eventStreamConstraints
                }}
                onAdd={this.props.onAdd}
                loading={this.props.loading}
            />
        );
    };

    renderEditForm = (data) => {
        return (
            <EventStreamConstraintsForm
                form="form_esc_update"
                loading={this.props.loading}
                formData={{
                    products: this.props.products,
                    deviceCategories: this.props.deviceCategories,
                    usedProductsAndCategories: this.props.eventStreamConstraints,
                    eventStreamConstraints: {
                        id: data.id,
                        product: data.product,
                        device_category: data.device_category,
                        max_bitrate: data.max_bitrate ? data.max_bitrate.toString() : null,
                        max_resolution_width: data.max_resolution_width ? data.max_resolution_width.toString() : null,
                        max_resolution_height: data.max_resolution_height ? data.max_resolution_height.toString() : null,
                        max_player_size: data.max_player_size ? data.max_player_size.toString() : null
                    }
                }}
                initialValues={{
                    product: parseInt(data.product.id, 10),
                    device_category: parseInt(data.device_category.id, 10),
                }}
                onUpdate={this.props.onUpdate}
                onDeactivateEditableCell={this.deactivateEditableCell}
            />
        );
    };

    render() {
        return (
            <div>
                <Dimmer inverted active={this.props.loading}>
                    <Loader/>
                </Dimmer>
                {this.renderHeader()}
                {this.renderAddForm()}
                {this.renderEditableGrids()}
            </div>
        );
    }
}

export default EventStreamConstraintsTable;
