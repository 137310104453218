import React from 'react';
import PropTypes from 'prop-types';

/* eslint import/no-unresolved: 0 */
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {sortBy as _sortBy} from 'lodash';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import {convertToInt} from '@utils/helpers';
import {SLATE} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import Link from '@appComponents/Link';

import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';
import {GetSlatesForTableView} from '../../../graphql/slate/query.graphql';
import SlatesTable from '../components/SlatesTable';
import SegmentHeader from '../../app/components/SegmentHeader';

const SlateIndex = (props) => {
    const {DataSlates, match} = props;

    const renderButtons = () => {
        return (
            <div className='slate__indexButtons content__buttons'>
                <Authorization authorization={{[SLATE]: SECURITY_PRIVILEGES_CREATE}}>
                    <Link name={'propertyLicences.technicalSetup.slates.add'} params={{id: convertToInt(match.params.id)}}>
                        <ButtonAdd>Add new slate</ButtonAdd>
                    </Link>
                </Authorization>
            </div>
        );
    };

    return (
        <div className='slate'>
            <SegmentHeader
                buttons={renderButtons()}
                dividing={false}
            >
            </SegmentHeader>
            <Segment basic className='--table'>
                <SlatesTable
                    loading={DataSlates.loading}
                    propertyLicenceId={convertToInt(match.params.id)}
                    slates={_sortBy(DataSlates.slates, 'name')}
                />
            </Segment>
        </div>
    );
};

const SlatesWithGraphQL = compose(
    graphql(GetSlatesForTableView, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    property_licence_tech_setup_id: props.technicalSetupId,
                },
            };
        },
        name: 'DataSlates',
    })
)(SlateIndex);

SlateIndex.propTypes = {
    DataSlates: PropTypes.shape({
        slates: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    technicalSetupId: PropTypes.number.isRequired,
};

SlateIndex.defaultProps = {
    DataSlates: {
        slates: [],
        loading: false,
    },
};

export default withRouter(SlatesWithGraphQL);
