import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Button, Icon, Segment} from 'semantic-ui-react';

import Link, {getLink} from '@appComponents/Link';
import SegmentHeader from '@appComponents/SegmentHeader';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import {GetSlate} from '@graphql/slate/query';
import {showModal} from '@utils/modal';

import SlatesForm from '../forms/SlatesForm';
import SlatesEditModel from '../forms/SlatesEditModel';
import SlatesCreateModel from '../forms/SlatesCreateModel';

export class SlateEdit extends React.PureComponent {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
                slateId: PropTypes.string,
            }),
            path: PropTypes.string.isRequired,
        }),
        technicalSetupId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        match: {
            params: {
                slateId: null,
            },
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            form: this.renderForm(),
        };
    }

    renderButtons = () => {
        return (
            <div className='slate__indexButtons content__buttons'>
                <Link
                    name={'propertyLicences.technicalSetup.slates.index'}
                    params={{id: convertToInt(this.props.match.params.id)}}
                >
                    <Button className='button-back'>
                        <Icon className='arrow circle left'/> Back to slate list
                    </Button>
                </Link>
            </div>
        );
    };

    renderForm = () => {
        if (!isUrlParamValid(this.props.match.params.slateId)) {
            showModal({
                isVisible: true,
                content: renderModalError('Country',
                    getLink(`propertyLicences.technicalSetup.slates.index`, {
                        id: this.props.match.params.id,
                    })
                ),
            });
        }

        const formOptions = {
                dataRequest: true,
                id: convertToInt(this.props.match.params.slateId),
                optionsVariables: {
                    technicalSetupId: this.props.technicalSetupId,
                },
            },
            Model = (this.props.match.path === routes.propertyLicences.technicalSetup.slates.add.path)
                ? SlatesCreateModel
                : SlatesEditModel;

        const Form = createForm(Model, SlatesForm, formOptions);

        return <Form />;
    }

    render() {
        const backLink = getLink('propertyLicences.technicalSetup.slates.index', {
            id: this.props.match.params.id,
        });

        return (
            <div className='slate'>
                <SegmentHeader
                    buttons={this.renderButtons()}
                >
                    <TitleFromQuery
                        query={GetSlate}
                        variables={{id: this.props.match.params.slateId}}
                        errorBackLink={backLink}
                        path={'slate.name'}
                        entity={'slate'}
                    />
                </SegmentHeader>
                <Segment basic>
                    {this.state.form}
                </Segment>
            </div>
        );
    }
}

export default withRouter(SlateEdit);
