import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';
/* eslint import/no-unresolved: 0 */
import moment from 'moment';
import {Header} from 'semantic-ui-react';
import {reduxForm} from 'redux-form';

import {compose, graphql, gql} from 'react-apollo';
import {hideModal} from '@utils/modal';
import {convertToInt} from '@utils/helpers';
import {
    ENCODING_END_MARGIN,
    EVENT_TYPE_SPORTRADAR,
    TRIGGER_DATE_OPTION,
    TRIGGER_MANUAL_OPTION,
    TRIGGER_MARGIN_OPTION,
} from '@constants/variables';
import Form from '@appComponents/ReduxFormControls';
import {FormWrapper} from '@appComponents/HOCFormWrapper';
import {ButtonCancel, ButtonSave} from '@appComponents/ButtonCollection';
import MessageBox from '@appComponents/MessageBox';
import {showMessageBox} from '@utils/messageBox';
import {submit} from '@modules/events/utils/eventsConfigurationEncodingInformation';
import {ModalHeaderEventContentInformation} from '@modules/events/views/common/ModalHeaderEventContentInformation';
import {updateEventContent as GraphQLupdateEventContent} from '@graphql/liveControl/index';

import {validateEndForm} from './validator';

class ModifyEncodingEndDatetimeForm extends React.Component {
    static propTypes = {
        change: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        valid: PropTypes.bool,
        submitFailed: PropTypes.bool,
        UpdateEventContent: PropTypes.func,
        successCallback: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let selectedOption = TRIGGER_DATE_OPTION;

        const data = props.formData;

        if (null === data.encoding_end_margin && null === data.encoding_end_datetime) {
            selectedOption = TRIGGER_MANUAL_OPTION;
        } else if (null !== data.encoding_end_margin) {
            selectedOption = TRIGGER_MARGIN_OPTION;
        }

        this.state = {
            selected: selectedOption,
        };
    }

    componentDidUpdate() {
        if (!this.props.valid && this.props.submitFailed && !this.state.defaultForm_errorShowed) {
            showMessageBox('formInnerErrorMessage',
                null, 'The encoding end time has not been saved. Please correct the highlighted fields below.', 'error');
            this.setState(() => ({
                defaultForm_errorShowed: true,
            }));
        }
    }

    componentDidMount() {
        this.props.change('trigger_type', this.state.selected);
    }

    handleChange = ({input}) => {
        input.onChange(input.value);

        this.setState(() => ({
            selected: input.value,
        }));
    };

    renderDatepicker = () => (
        <div className='modifyEncodingDatetime-byDate--datepicker'>
            <Form.FormField
                className='--minimalWidth --datetimeWithoutSeconds'
                name='encoding_end_datetime'
                component={Form.SemanticInput}
                configuration={{
                    showTimeSelect: true,
                    timeFormat: 'HH:mm',
                    timeIntervals: 5,
                    minDate: moment(),
                }}
                defaultValue={_get(this.props.dataForForm, 'encoding_end_datetime', null)}
                type='date'
            />
        </div>
    );

    onFormSubmit = (data) => {
        let dataToSave = Object.assign({}, this.props.dataMap, data);

        let eventContentUpdateVariables = {
            id: dataToSave.event_content_id,
            eventContentInput: {
                encoding_end_datetime: null,
                encoding_end_margin: null,
            },
        };

        if (this.state.selected === TRIGGER_MARGIN_OPTION) {
            eventContentUpdateVariables.eventContentInput.encoding_end_margin = parseInt(data.encoding_end_margin, 10);
        } else if (this.state.selected === TRIGGER_DATE_OPTION) {
            eventContentUpdateVariables.eventContentInput.encoding_end_datetime = data.encoding_end_datetime + ':00';
        }

        return submit({
            promise: this.props.UpdateEventContent,
            variables: eventContentUpdateVariables,
            eventId: this.props.formData.event_id,
            successCallback: this.props.successCallback,
        });
    };

    renderSecondOption = () => (
        <div className='modifyEncodingDatetime-byMargin'>
            <label>
                <Form.FormField
                    component={Form.RenderRadio}
                    disabled={this.props.submitting}
                    handleChange={this.handleChange}
                    name='trigger_type'
                    selected={TRIGGER_MARGIN_OPTION === this.state.selected}
                    type='radio'
                    value={TRIGGER_MARGIN_OPTION}
                />
                <Form.FormField
                    className='modifyEncodingDatetime-minutes'
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.encoding_end_margin || ENCODING_END_MARGIN}
                    min={0}
                    name='encoding_end_margin'
                    onlyDigitsAllowed
                    type='number'
                />
                <span>
                    {
                        (null !== this.props.formData.event_content_end_datetime)
                            ? 'minutes after the event content end time'
                            : 'minutes after the end of the match'
                    }
                </span>
            </label>
        </div>
    );

    render() {
        let {formData} = this.props;

        return (
            <div>
                <Header>Modify encoding end time</Header>
                <ModalHeaderEventContentInformation event={this.props.formData} />
                <MessageBox name='formInnerErrorMessage' className='--formInnerError'/>
                <Form.Create
                    onSubmit={this.props.handleSubmit(this.onFormSubmit)}
                >
                    <Form.FormField
                        name='event_content_id'
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.event_content_id}
                        hidden={true}
                    />
                    <Form.FormField
                        name='event_content_end_datetime'
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.event_content_end_datetime}
                        hidden={true}
                    />
                    <div className='modifyEncodingDatetime-triggerManually'>
                        <Form.FormField
                            name='trigger_type'
                            component={Form.RenderRadio}
                            handleChange={this.handleChange}
                            selected={this.state.selected === TRIGGER_MANUAL_OPTION}
                            type='radio'
                            disabled={this.props.submitting}
                            label='Trigger manually (no end time)'
                            value={TRIGGER_MANUAL_OPTION}
                        />
                    </div>
                    {
                        (
                            null !== formData.event_content_end_datetime
                            || (EVENT_TYPE_SPORTRADAR === convertToInt(formData.event_type_id) && !formData.is_master_event)
                        )
                            ? this.renderSecondOption()
                            :null
                    }
                    <div className='modifyEncodingDatetime-byDate'>
                        <label className='--flex'>
                            <Form.FormField
                                name='trigger_type'
                                component={Form.RenderRadio}
                                handleChange={this.handleChange}
                                selected={this.state.selected === TRIGGER_DATE_OPTION}
                                disabled={this.props.submitting}
                                type='radio'
                                value={TRIGGER_DATE_OPTION}
                            />
                            <span>Fixed time: </span>
                            {this.renderDatepicker()}
                        </label>
                    </div>
                    <div className='formRow form__footer'>
                        <label/>
                        <ButtonSave
                            loading={this.props.submitting}
                            disabled={this.props.submitting}>
                            Save
                        </ButtonSave>
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={() => hideModal()}
                        >
                            Cancel
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </div>
        );
    }
}


export default compose(
    graphql(gql(GraphQLupdateEventContent), {name: 'UpdateEventContent'})
)(FormWrapper({
    event_content_id: '',
    encoding_end_margin: '',
    encoding_end_datetime: '',
    event_content_end_datetime: '',
    trigger_type: '',
}, reduxForm({form: 'ModifyEncodingDatetime', validate: validateEndForm})(ModifyEncodingEndDatetimeForm)));
