import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {Button} from 'semantic-ui-react';
import React from 'react';

import DefaultForm from '@appComponents/DefaultForm';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';

import {getDataFromAllSteps, showErrorNotice} from '../../utils/eventGeoRestriction';

export class EventGeoRestrictionsFormBlackoutZonesForm extends DefaultForm {
    componentDidUpdate() {
        super.componentDidUpdate();

        const {
                GraphQLEntityData,
                GraphQLOptionsData,
            } = this.props,
            assignedBlackoutZonesData = _get(GraphQLEntityData, 'eventGeoRestriction.blackout_zones', []),
            blackoutZones = _get(GraphQLOptionsData, 'blackoutZones', []),
            isLoading = (GraphQLEntityData && GraphQLEntityData.loading) || GraphQLOptionsData.loading;

        if (!isLoading && !this.state.dataLoaded) {
            const assignedBlackoutZonesIds = assignedBlackoutZonesData.map(blackoutZone => (blackoutZone.id)),
                blackoutZonesOptions = blackoutZones.filter(blackoutZone => {
                    if (blackoutZone.is_archived && assignedBlackoutZonesIds.includes(blackoutZone.id)) {
                        return true;
                    } else {
                        return !blackoutZone.is_archived;
                    }
                });

            this.setField('blackoutZones', {
                defaultValue: assignedBlackoutZonesData.map(blackoutZone => (blackoutZone.id)),
                options: blackoutZonesOptions,
            });
            this.setState(() => ({dataLoaded: true}));
        }
    }

    prepareDataForSubmit = () => {
        const data = getDataFromAllSteps(
                this.props.stepsMethods,
                this.props.formParams.optionsVariables.event
            ),
            isFormDataValid = null !== data.territory_selection_type || 0 < data.blackout_zones.length;

        return isFormDataValid ? data : showErrorNotice();
    };

    onFormSubmit = (data) => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', '')).toLocaleLowerCase()
            ));
        } else {
            return super.onFormSubmit(data);
        }
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (!_isEmpty(this.props.stepsMethods)) {
            this.props.stepsMethods.setForm('step3', nextProps);
        }
    }

    renderAdditionalButtons = () => {
        return !this.props.Model.editForm && <Button
            color={'grey'}
            content={'Back'}
            icon={'angle double left'}
            labelPosition={'left'}
            onClick={this.props.stepsMethods.showPreviousStep}
        />;
    }
}

export default EventGeoRestrictionsFormBlackoutZonesForm;
