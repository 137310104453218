import React from 'react';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Segment} from 'semantic-ui-react';
import {includes as _includes} from 'lodash';
import {connect} from 'react-redux';
import {withApollo} from 'react-apollo';
import {saveAs as fsSaveAs} from 'file-saver';

import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {showDarkLoadingModal, hideModal} from '@utils/modal';
import {GetEventContentDetailsForStreamsOutput} from '@graphql/eventContent/query';
import {
    GetStreamPlayback,
    GetStreamPlaybackManifest,
    GetStreamPlaybackOrigin,
    GetWnsStream,
} from '@graphql/streamPlayback/query';

import AvPlayerIndex from './AvPlayerIndex';
import {
    originPaths,
    originPathsM3u,
    streamPaths,
    manifestPaths,
    m3uFileContent,
    isWnsPath,
} from '../utils/streamPlaybackIndex';
import {getClientIpV4, getClientIpV6orV4} from '../../user/forms/helpers/userSettingsLocation';
import { FORMAT_M3U, ORIGIN_STREAM_TYPE_RTMP, ORIGIN_STREAM_TYPE_SRT } from '@constants/variables';

export class StreamPlaybackIndex extends React.Component {
    static propTypes = {
        EventContentDetails: PropTypes.shape({
            eventContentDetailsForStreamsOutput: PropTypes.object,
        }),
        match: PropTypes.object.isRequired,
        Menu: PropTypes.object,
        client: PropTypes.object.isRequired,
        StreamPlayback: PropTypes.object,
        worldNumberServiceStream: PropTypes.object,
        location: PropTypes.object,
    };

    static defaultProps = {
        EventContentDetails: {
            eventContentDetailsForStreamsOutput: {},
        },
    };

    state = {
        content: '',
        loading: true,
        reloading: false,
        ipHandlerFired: false,
    };

    showLoadingStreamModal = () => {
        showDarkLoadingModal('Opening the video');
    }

    componentDidMount() {
        this.showLoadingStreamModal();
        this.prepareStreamPlayback();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.showLoadingStreamModal();
            this.setState(
                () => ({reloading: true}),
                () => {this.prepareStreamPlayback();}
            );
        }
    }

    async prepareStreamPlayback() {
        this.setState(() => ({
            loading: true,
        }));

        let query, variables = {debug: -1 !== this.props.match.path.indexOf('/debug')};
        const clientId = new URLSearchParams(this.props.location.search).get('clientId');

        switch (true) {
            case _includes(streamPaths(variables.debug), this.props.match.path):
                try {
                    variables.clientIp = await getClientIpV4();
                } catch (e) {
                    variables.clientIp = '';
                }

                query = GetStreamPlayback;
                variables = {
                    ...variables,
                    client: clientId ? convertToInt(clientId) : null,
                    stream: convertToInt(this.props.match.params.streamId),
                };
                break;
            case _includes(originPaths(variables.debug), this.props.match.path):
                try {
                    variables.clientIp = await getClientIpV4();
                } catch (e) {
                    variables.clientIp = '';
                }

                query = GetStreamPlaybackOrigin;
                variables.eventContent = convertToInt(this.props.match.params.eventContentId);
                variables.requestFile = null;
                break;
            case _includes(originPathsM3u(), this.props.match.path):
                try {
                    variables.clientIp = await getClientIpV4();
                } catch (e) {
                    variables.clientIp = '';
                }

                query = GetStreamPlaybackOrigin;
                variables.eventContent = convertToInt(this.props.match.params.eventContentId);
                variables.requestFile = FORMAT_M3U;
                break;
            case _includes(manifestPaths(variables.debug), this.props.match.path):
            {
                try {
                    variables.clientIp = await getClientIpV6orV4();
                } catch (e) {
                    variables.clientIp = '';
                }

                query = GetStreamPlaybackManifest;
                variables = {
                    ...variables,
                    client: clientId ? convertToInt(clientId) : null,
                    encodingTarget: convertToInt(this.props.match.params.encodingTargetId),
                    streamProtocol: convertToInt(this.props.match.params.streamProtocolId),
                    akamaiRtmpDistrRegion: this.props.match.params.akamaiRtmpDistrRegionId
                        ? convertToInt(this.props.match.params.akamaiRtmpDistrRegionId)
                        : null,
                };

                break;
            }
            case isWnsPath(variables.debug, this.props.match.path):
                try {
                    variables.clientIp = await getClientIpV6orV4();
                } catch (e) {
                    variables.clientIp = '';
                }

                query = GetWnsStream;
                variables.id = convertToInt(this.props.match.params.id);
                break;
        }

        if (query) {
            this.setState(() => ({ipHandlerFired: true}));

            if (FORMAT_M3U === _get(variables, 'requestFile', null)) {
                this.getStreamM3u(query, variables);
            } else if (variables.id) {
                this.getWnsPlayback(query, variables);
            } else {
                this.getStreamPlayback(query, variables);
            }
        }
    }

    fetchEventContentDetails = () => (
        this.props.client.query({
            query: GetEventContentDetailsForStreamsOutput,
            variables: {eventContentId: this.props.match.params.eventContentId},
        })
    );

    fetchStreamPlayback = (query, variables) => (
        this.props.client.query({
            query: query,
            variables: variables,
            fetchPolicy: 'network-only',
        })
    );

    errorHandler = (message) => {
        this.props.Menu.setRedirectLoaderVisibility({isRedirectLoaderVisible: false, redirectError: true});
        this.setState(() => ({
            content: message,
            loading: false,
            reloading: false,
        }));

        hideModal();
    };

    // content-type for m3u is - according to wikipedia article - application/vnd.apple.mpegurl
    // alternatives: application/mpegurl, application/x-mpegurl
    // also, currently m3u request is always for origin stream
    async getStreamM3u(query, variables){
        const defaultErrorMessage = 'The origin stream is not playable as M3U.';

        try {
            const eventContentDetailsPromise = await this.fetchEventContentDetails();
            const streamPlaybackPromise = await this.fetchStreamPlayback(query, variables);

            const m3uFileContentType = 'application/vnd.apple.mpegurl',
                m3uFileName = 'playlist.m3u',
                M3U_ALLOWED_STREAM_TYPES = [ORIGIN_STREAM_TYPE_RTMP, ORIGIN_STREAM_TYPE_SRT],
                eventContentDetails = _get(eventContentDetailsPromise, 'data.eventContentDetailsForStreamsOutput', null),
                originStreamSupportsM3u = !_isEmpty(eventContentDetails)
                    && M3U_ALLOWED_STREAM_TYPES.includes(_get(eventContentDetails, 'origin_stream_type_id', 0));

            if (originStreamSupportsM3u) {
                const streamUrl = _get(streamPlaybackPromise, 'data.StreamPlayback.url', null);

                if (!_isEmpty(streamUrl)) {
                    const data = m3uFileContent(eventContentDetails, streamUrl),
                        dataBlob = new Blob([data], {type: m3uFileContentType});

                    fsSaveAs(dataBlob, m3uFileName);
                } else {
                    this.errorHandler(defaultErrorMessage);
                }
            } else {
                this.errorHandler(defaultErrorMessage);
            }
        } catch (error) {
            this.errorHandler(_get(error,'message', defaultErrorMessage));
        }

        hideModal();
    };

    async getStreamPlayback(query, variables) {
        try {
            const eventContentDetails = await this.fetchEventContentDetails();
            const streamPlayback = await this.fetchStreamPlayback(query, variables);

            if (streamPlayback.data.StreamPlayback.isOriginStream) {
                if (_isEmpty(_get(eventContentDetails,'data.eventContentDetailsForStreamsOutput.http_origin_entry_point_url', null))) {
                    this.errorHandler('The origin stream is not playable because it is not a HTTP stream or because no '
                        + 'HTTP origin entry point URL is defined.');
                    hideModal();

                    return;
                }
            }

            if (variables.debug) {
                window.location.replace(streamPlayback.data.StreamPlayback.url);
            } else {
                this.props.Menu.setRedirectLoaderVisibility({isRedirectLoaderVisible: false, redirectError: true});
                this.setState(() => ({
                    content: (
                        <AvPlayerIndex
                            avPlayerParams={streamPlayback.data.StreamPlayback.avPlayerParams}
                            eventContentDetails={eventContentDetails.data.eventContentDetailsForStreamsOutput}
                            streamInfo={{
                                product: streamPlayback.data.StreamPlayback.productShortName,
                                distributionType: streamPlayback.data.StreamPlayback.distributionTypeName,
                                streamDelayType: streamPlayback.data.StreamPlayback.streamDelayTypeName,
                                protocol: streamPlayback.data.StreamPlayback.streamProtocolName,
                                bitrate: streamPlayback.data.StreamPlayback.bitrate,
                                isOriginStream: streamPlayback.data.StreamPlayback.isOriginStream,
                                isManifest: streamPlayback.data.StreamPlayback.isManifest,
                                client: variables.client,
                            }}
                            url={streamPlayback.data.StreamPlayback.url}
                        />
                    ),
                    loading: false,
                    reloading: false,
                }));

                hideModal();
            }
        } catch (error) {
            this.errorHandler(error.message);
        }
    }

    async getWnsPlayback(query, variables) {
        try {
            const streamPlayback = await this.fetchStreamPlayback(query, variables);

            if (variables.debug) {
                window.location.replace(streamPlayback.data.worldNumberServiceStream.url);
            } else {
                this.props.Menu.setRedirectLoaderVisibility({isRedirectLoaderVisible: false, redirectError: true});
                this.setState(() => ({
                    content: (
                        <AvPlayerIndex
                            avPlayerParams={streamPlayback.data.worldNumberServiceStream.avPlayerParams}
                            wnsDetails={streamPlayback.data.worldNumberServiceStream.wnsStreamName}
                            url={streamPlayback.data.worldNumberServiceStream.url}
                            id={streamPlayback.data.worldNumberServiceStream.id}
                        />
                    ),
                    loading: false,
                    reloading: false,
                }));
            }
        } catch (error) {
            this.errorHandler(error.message);
        }

        hideModal();
    }

    render() {
        return !this.state.loading && <Segment
            basic
            className={(this.state.reloading) ? 'avPlayerReload' : 'error'}
            textAlign='center'
            size='large'
            loading={this.state.loading}
            content={this.state.content}
        />;
    }
}

export default withRouter(withApollo(connect(null, mapModulesToProps(['Menu']))(StreamPlaybackIndex)));
