import React from 'react';
import PropTypes from "prop-types";
import {get as _get} from "lodash";

import {RtsScoutData} from "../../../../../components/EventsTabsLayout/Details/MatchDetails/fields/column2/RtsScoutData";

import {ValuesDashRedLabel} from "../../../../../../../utils/decorators";

export const getFieldsList = (event) => {
    return [
        {
            name: 'Scout',
            value: <ValuesDashRedLabel options={{
                value: _get(event, 'sr_event.match.has_live_scout', null),
                valueColorRed: 'No scout',
                trueValue: 'Yes',
                falseValue: 'No scout'
            }} />
        },
        {
            name: 'RTS scout data',
            value: <RtsScoutData event={event} />
        },
        {
            name: 'Live Odds',
            value: <ValuesDashRedLabel options={{
                value: _get(event, 'sr_event.match.has_live_odds', null),
                valueColorRed: 'No Live Odds',
                trueValue: 'Yes',
                falseValue: 'No Live Odds'
            }}/>
        }
    ];
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                has_live_scout: PropTypes.bool,
                has_live_odds: PropTypes.bool
            })
        })
    }).isRequired
};

getFieldsList.defaultProps = {
    event: {
        sr_event: {
            match: {
                has_live_scout: null,
                has_live_odds: null
            }
        }
    }
}
