import PropTypes from 'prop-types';
import React from 'react';
import {Icon, Message} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import Link from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Form from '@appComponents/ReduxFormControls';
import {CidrFormInfoMessage} from '@utils/CidrHelpers';
import {DisableCidrIcon} from '@utils/DisableCidrIcon';
import {convertToInt} from '@utils/helpers';
import {renderModalError} from '@utils/forms';
import {CLIENT_WHITELISTED_IP} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {routes} from '@constants/routes';
import AvApiAccessIndex from '@modules/client/views/ClientAvapiIndex';
import WhitelistedIpIndex from '@modules/client/views/WhitelistedIpIndex';

export const cidrOverlapDetailsRenderer = (data) => {
    return data.map((element) => {
        const {cidr, description, id} = element;
        const linkText = `${cidr} (${description})`;

        return (
            <li key={id}>
                {
                    <Link
                        newWindow={true}
                        name={'ipBlacklisting.edit'}
                        params={{id: id}}
                    >
                        {linkText}
                    </Link>
                }
            </li>
        );
    });
};
cidrOverlapDetailsRenderer.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        cidr: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }),
};

export const ClientWhitelistHeaderMessage = ({message}) => {
    if (!message) {
        return '';
    }

    const messageParts = message.split('<br/>');

    return (
        <Message icon info>
            <Icon name='info circle'/>
            <Message.Content>
                {messageParts.map((part, index) => (
                    <React.Fragment key={`${part}-${index}`}>
                        {part}
                        {index < messageParts.length - 1 && <br/>}
                    </React.Fragment>
                ))}
            </Message.Content>
        </Message>
    );
};

ClientWhitelistHeaderMessage.propTypes = {
    message: PropTypes.string,
};

export const parseProducts = (products) => (
    [].concat(products)
        .sort((a, b) => (convertToInt(a.id) > convertToInt(b.id) ? 1 : -1))
        .map(product => product.short_name)
        .join(', ')
);

export const rowRenderer = (column, row, props) => {
    const {clientId, disableClick} = props;

    switch (column) {
        case 'ip_version':
            return row[column].name;
        case 'products':
            return parseProducts(row[column]);
        case 'actions': {
            return (
                <div className="actions-list-icon">
                    <LinkWithAuthorization
                        authorization={{[CLIENT_WHITELISTED_IP]: SECURITY_PRIVILEGES_READ}}
                        link={{
                            name: 'clients.whitelistedIps.geoRestrictions.edit',
                            params: {id: clientId, whitelistedIpId: row.id},
                        }}
                    >
                        <IconEdit resource={CLIENT_WHITELISTED_IP} size="large"/>
                    </LinkWithAuthorization>
                    <Authorization authorization={{[CLIENT_WHITELISTED_IP]: SECURITY_PRIVILEGES_UPDATE}}>
                        {!row.is_disabled ? <DisableCidrIcon onClick={disableClick} onClickElement={row}/> : null}
                    </Authorization>
                </div>
            );
        }
        default: {
            return null;
        }
    }
};

export const renderWhitelistedIpModalError = (whitelistedIpIndexLink, message) => (
    renderModalError('Client whitelisted IP', whitelistedIpIndexLink, message)
);
renderWhitelistedIpModalError.displayName = 'renderWhitelistedIpModalError';

export const createFieldsByType = (type) => {
    const dataMapKeys = {
        'whitelisted': 'clientWhitelistedIp',
        'blacklisted': 'blacklistedIp',
        'avapi': 'clientAvapiWhitelistedIp',
    };
    const mainDataMapKey = dataMapKeys[type];

    return {
        info_message: {
            props: {
                component: CidrFormInfoMessage,
                label: '',
                name: 'info_message',
            }
        },
        id: {
            component: Form.IdField,
            dataMapKey: `${mainDataMapKey}.id`,
            props: {
                hidden: true,
                label: '',
                name: 'id',
            },
        },
        ip_version: {
            dataMapKey: `${mainDataMapKey}.ip_version.id`,
            optionsKey: 'ipVersions',
            props: {
                className: 'form__input--select-width-auto',
                component: Form.SemanticSelect,
                label: 'IP version',
                name: 'ip_version',
                required: true,
            },
        },
        cidr: {
            dataMapKey: `${mainDataMapKey}.cidr`,
            props: {
                className: 'form__input--input-width-medium',
                component: Form.SemanticInput,
                label: 'CIDR',
                name: 'cidr',
                required: true,
                type: 'input',
            },
        },
        products: {
            dataMapKey: `${mainDataMapKey}.products.id`,
            optionsKey: 'clientProducts',
            props: {
                className: 'form__input--select-width-medium',
                component: Form.SemanticSelect,
                label: 'Products',
                name: 'products',
                optionsSubKey: 'product',
                placeholder: 'Select products',
                multiple: true,
                required: true,
            },
        },
        description: {
            dataMapKey: `${mainDataMapKey}.description`,
            props: {
                className: 'form__input--input-width-medium',
                component: Form.SemanticInput,
                label: 'Description',
                name: 'description',
                type: 'input',
                required: 'clientAvapiWhitelistedIp' !== dataMapKeys[type],
            },
        },
    };
};

export const getWhitelistedIpTabsState = (match) => {
    return {
        [routes.clients.whitelistedIps.index.path]: {
            key: routes.clients.whitelistedIps.index.path,
            render: <AvApiAccessIndex/>,
            text: 'AV-API access',
            url: 'clients.whitelistedIps.index',
            urlParams: {id: match.params.id},
        },
        [routes.clients.whitelistedIps.geoRestrictions.index.path]: {
            key: routes.clients.whitelistedIps.geoRestrictions.index.path,
            render: <WhitelistedIpIndex/>,
            text: 'Geo restrictions',
            url: 'clients.whitelistedIps.geoRestrictions.index',
            urlParams: {id: match.params.id},
        },
    };
};

export const getWhitelistedIpActiveTabs = (match) => {
    let activePath = match.path;
    const whitelistedIps = routes.clients.whitelistedIps;
    const geoRestrictionRoutes = whitelistedIps.geoRestrictions;

    if (match.path === whitelistedIps.add.path
        || match.path === whitelistedIps.edit.path
        || match.path === whitelistedIps.index.path) {
        activePath = whitelistedIps.index.path;
    }

    if (match.path === geoRestrictionRoutes.add.path
        || match.path === geoRestrictionRoutes.edit.path
        || match.path === geoRestrictionRoutes.index.path) {
        activePath = geoRestrictionRoutes.index.path;
    }

    return activePath;
};
