import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {GetSports, GetSportsExcludingClipConfigSports, ClipConfigsToSports} from '@graphql/clipConfiguration/query';
import {convertToInt} from '@utils/helpers';
import {
    formatToSaveClipsOptions,
    setClipsTree,
    runQuery,
    createLinkToIndexPage,
} from '@modules/clipConfiguration/utils/formHelpers';
import {refetchQueryByName} from '@utils/apollo';

export class ClipConfigurationSportLevelForm extends DefaultForm {

    setSports = (product, includeSelectedSports = []) => {
        if (product && !this.state.SportsDataLoading) {
            this.setState(() => ({SportsDataLoading: true}));

            runQuery(this.runApolloRequest, GetSportsExcludingClipConfigSports, {
                productId: product,
                includeSports: includeSelectedSports.map((sport) => (
                    convertToInt(sport)
                )),
            }).then((response) => {
                const options = response.data.sportsWithoutClipConfigSports;

                this.setField('sports', {
                    options: options,
                    disabled: 0 === options.length,
                });
                this.setState(() => ({
                    SportsData: options,
                    SportsDataLoading: false,
                }));
            });
        }
    }
    clipConfigToSports = () => {
        runQuery(this.runApolloRequest, ClipConfigsToSports).then((response) => {
            const options = response.data.clipConfigs;

            this.setState(() => ({
                allSports: options,
            }));
        });
    }

    setOptionsToSportsOnEditForm = () => {
        if (!this.state.SportsDataLoading) {
            this.setState(() => ({SportsDataLoading: true}));

            runQuery(this.runApolloRequest, GetSports).then((response) => {
                const formId = this.props.formParams.id,
                    selectedProductId = this.props.formValues.products,
                    options = response.data.sports,
                    allSports = this.state.allSports;
                let sportsNotAssociatedToAnyClips;

                if (options.length !== 0 && formId) {
                    let idsOfSportsAssociatedToClips = allSports.filter(clipConfig => clipConfig.id !== formId && clipConfig.product.id === selectedProductId)
                        .flatMap(clipConfigWithoutCurrentFormId => clipConfigWithoutCurrentFormId.sports.map(sport =>sport.id));
                    sportsNotAssociatedToAnyClips  = options.filter(obj => !idsOfSportsAssociatedToClips.includes(obj.id));
                }

                this.setField('sports', {
                    options: sportsNotAssociatedToAnyClips,
                    disabled: 0 === sportsNotAssociatedToAnyClips.length,
                });
            });
        }
    }

    componentDidMount() {
        const link = createLinkToIndexPage();

        if (this.props.formParams.id) {
            this.clipConfigToSports();
        }

        this.setOnChangeCallback({
            products: (event) => {
                this.setSports(event.value);
                setClipsTree(this, event.value, []);
            },
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });

        this.setState(() => ({
            defaultForm_formTitle: 'Add new clip configuration on sport level',
        }));

        if (this.props.formParams.id) {
            this.setField('sports', {
                disabled: false,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const clipConfig = _get(this.props, 'GraphQLEntityData.clipConfig', null);

        if (this.state.allSports && this.props.formValues.products) {
            this.setOptionsToSportsOnEditForm();
        }

        if (clipConfig && !this.state.dataLoaded) {
            const selectedSports = clipConfig.sports.map((sport) => (
                sport.id
            ));

            setClipsTree(this, clipConfig.product.id, clipConfig.event_content_type_configs);
            this.setField('sports', {
                defaultValue: selectedSports,
            });

            this.setField('products', {
                defaultValue: clipConfig.product.id,
                disabled: true,
            });

            this.setState(() => ({dataLoaded: true}));
        }

        if (this.props.formParams.id && this.props.formValues.name
            && prevProps.formValues.name === this.props.formValues.name) {
            this.props.Model.title = `Clip configuration "${this.props.formValues.name}"`;
        }
    }

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.fields[this.props.Model.entityDataMapKey],
                {
                    id: this.props.formParams.id,
                    name: data.name,
                    product: convertToInt(data.products),
                    sports: data.sports,
                    tournaments: null,
                    clipConfigEventContentType: formatToSaveClipsOptions(data.eventContentTypes),
                }
            );
    }

    renderErrors() {
        super.renderErrors(
            'The requested clip configuration could not be found.',
            this.props.Model.entityLabel,
            createLinkToIndexPage()
        );
    }
    onCancel = () => {
        this.props.history.push(createLinkToIndexPage());
    };

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});

    renderCancelButton = (props) => super.renderCancelButton({...props, type: 'button', onClick: this.onCancel});
}

export default withRouter(ClipConfigurationSportLevelForm);
