import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {GetSdiOriginEntryPoint} from '@graphql/sdiOriginEntryPoint/query';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import SdiOriginEntryPointForm from '../forms/SdiOriginEntryPointForm';
import SdiOriginEntryPointModel from '../forms/SdiOriginEntryPointModel';

const SdiOriginEntryPointEdit = ({
    match: {
        params: {
            id = null,
        },
    },
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Sdi Origin Entry Point', routes.sdiOriginEntryPoints.index.path),
        });
    }

    const Form = React.useMemo(
        () => (createForm(SdiOriginEntryPointModel, SdiOriginEntryPointForm, {id: convertToInt(id)})),
        [id]
    );

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                buttonsProps={{name: 'sdiOriginEntryPoints.index'}}
                messagesBoxNames='sdiOriginEntryPointMessageError'
                pageTitle={<TitleFromQuery
                    query={GetSdiOriginEntryPoint}
                    variables={{id}}
                    errorBackLink={routes.sdiOriginEntryPoints.index.path}
                    path={'sdiOriginEntryPoint.name'}
                    entity={'SDI origin entry point'}
                />}
            />
            <Form/>
        </div>
    );
};

SdiOriginEntryPointEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    sdiOriginEntryPointName: PropTypes.string.isRequired,
};

export default withRouter(SdiOriginEntryPointEdit);
