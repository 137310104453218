import {
    GetOptionsForForm,
    GetClipConfigurationTournamentLevelForForm,
} from '@graphql/clipConfiguration/query';

import ClipConfigurationModel from './ClipConfigurationModel';
import validate from './TournamentLevelValidator';

export default function ClipConfigurationTournamentLevelModel() {
    const clipConfigurationTournamentLevelModel = ClipConfigurationModel();

    clipConfigurationTournamentLevelModel.validate = validate;

    clipConfigurationTournamentLevelModel.entityLabel = 'clip configuration';
    clipConfigurationTournamentLevelModel.fields.sports.props.multiple = false;
    clipConfigurationTournamentLevelModel.fields.tournaments.props.hidden = false;
    clipConfigurationTournamentLevelModel.fields.tournaments.props.required = true;
    clipConfigurationTournamentLevelModel.fields.categories.props.hidden = false;
    clipConfigurationTournamentLevelModel.fields.categories.props.required = true;

    clipConfigurationTournamentLevelModel.query.getEntity = GetClipConfigurationTournamentLevelForForm;
    clipConfigurationTournamentLevelModel.query.getOptions = GetOptionsForForm;

    return clipConfigurationTournamentLevelModel;
}
