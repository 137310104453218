import React from 'react';
import PropTypes from 'prop-types';

import {ENCODING_TARGET} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';
import {IconDelete} from '@appComponents/IconCollection';
import Table from '@appComponents/Table';
import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';
import {convertToInt} from '@utils/helpers';

const authorizationEncodingTargetUpdateObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_UPDATE,
};

const EventsConfigurationCdnConfigurationsTable = (props) => {
    const {cdnConfigurations, loading} = props;

    const columns = {
        'id': {
            label: 'ID',
            sorting: (row) => convertToInt(row.id.value),
            isHidden: true,
        },
        'clientName': {
            label: 'Client',
            sorting: (row) => row.clientName.value,
        },
        'productName': {
            label: 'Product',
            sorting: (row) => row.productName.value,
        },
        'cdnConfigurationName': {
            label: 'Name',
            sorting: (row) => row.cdnConfigurationName.value,
        },
        'cdnName': {
            label: 'CDN',
            sorting: (row) => row.cdnName.value,
        },
        'liveIngestMethod': {
            label: 'Live ingest method',
            sorting: true,
            ...columnObjectAlignLeft,
        },
        'recordingIngestMethod': {
            label: 'Recording ingest method',
            sorting: true,
            ...columnObjectAlignLeft,
        },
        'akamaiCdnIngestMethodName': {
            label: 'Akamai CDN ingest method',
            sorting: (row) => row.akamaiCdnIngestMethodName.value,
        },
        'distributionTypeName': {
            label: 'Distribution type',
        },
        'encodingTargetId': {
            label: 'Encoding target',
            ...columnObjectAlignCenter,
        },
        'actions': {
            label: 'Actions',
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'clientName':
            case 'productName':
            case 'cdnConfigurationName':
            case 'cdnName':
            case 'liveIngestMethod':
            case 'recordingIngestMethod':
            case 'akamaiCdnIngestMethodName':
            case 'distributionTypeName':
            case 'encodingTargetId':
                return row[column].value;
            case 'actions' :
                return (
                    <Authorization authorization={authorizationEncodingTargetUpdateObject}
                    >
                        <div className='actions-list-icon'>
                            <IconDelete size='large' onClick={props.onDelete} onClickElement={row} />
                        </div>
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className='event_configuration_cdn_configuration__table'>
            <Table
                name='eventConfigurationCdnConfigurationList'
                columns={columns}
                data={cdnConfigurations}
                loading={loading}
                noMessageBox
                noDataText='No client CDN configurations defined.'
                rowRenderer={rowRenderer}
                pagination={false}
                className='mini'
                structured={true}
            />
        </div>
    );
};

EventsConfigurationCdnConfigurationsTable.defaultProps = {
    cdnConfigurations: [],
};

EventsConfigurationCdnConfigurationsTable.propTypes = {
    eventContentId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    onDelete: PropTypes.func,
    cdnConfigurations: PropTypes.array,
};

export default EventsConfigurationCdnConfigurationsTable;
