import React from 'react';

import Form from '@appComponents/ReduxFormControls';
import {EVENT_BOOKING} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {EventEventBookingsDropdownOptionsForCountries} from '@graphql/eventEventBooking/query';
import validate from '@modules/events/forms/validatorEventsEventBookings';

export default function EventEventBookingGeoRestrictionsModel() {
    const GeoRestrictionsError = (params) => {
        return params.meta.touched && !params.meta.valid
            ? <div className='error errorContent'>At least one device category must be switched on.</div>
            : null;
    };

    return {
        entityDataMapKey: 'eventEventBooking',
        entityLabel: 'event booking',
        formName: 'EventBookingGeoRestriction',
        label: 'Event Booking',
        editForm: false,
        resources: EVENT_BOOKING,
        showChangeLog: false,
        title: null,
        validate: validate,
        messages: {
            boxName: {
                delete: 'event',
                error: 'formInnerErrorMessage',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventEventBooking.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            geo_restrictions_error_content: {
                dataMapKey: 'separator.geo_restrictions_error_content',
                props: {
                    component: (props) => GeoRestrictionsError(props),
                    name: 'geo_restrictions_error_content',
                    hidden: true,
                },
            },
            desktop_switch: {
                props: {
                    component: Form.SemanticInput,
                    label: 'Desktop',
                    name: 'desktop_switch',
                    type: 'toggle',
                    checked: false,
                    defaultValue: false,
                    hidden: true,
                },
            },
            desktop_countries: {
                optionsKey: 'continents',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Desktop countries',
                    name: 'desktop_countries',
                    required: true,
                },
            },
            desktop_subdivisions: {
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Desktop subdivisions',
                    name: 'desktop_subdivisions',
                    required: true,
                },
            },
            mobile_switch: {
                dataMapKey: 'mobile.switch',
                props: {
                    divClassName: 'geoRestrictionsSwitch',
                    component: Form.SemanticInput,
                    label: 'Mobile',
                    name: 'mobile_switch',
                    hidden: true,
                    type: 'toggle',
                    checked: false,
                    defaultValue: false,
                },
            },
            mobile_countries: {
                dataMapKey: 'mobile.countries',
                optionsKey: 'continents',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Mobile countries',
                    name: 'mobile_countries',
                    required: true,
                },
            },
            mobile_subdivisions: {
                dataMapKey: 'mobile.subdivisions',
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Mobile subdivisions',
                    name: 'mobile_subdivisions',
                    required: true,
                },
            },
            smart_tv_switch: {
                dataMapKey: 'smart_tv.switch',
                props: {
                    divClassName: 'geoRestrictionsSwitch',
                    component: Form.SemanticInput,
                    label: 'Smart TV',
                    name: 'smart_tv_switch',
                    hidden: true,
                    type: 'toggle',
                    checked: false,
                    defaultValue: false,
                },
            },
            smart_tv_countries: {
                dataMapKey: 'smart_tv.countries',
                optionsKey: 'continents',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Smart TV countries',
                    name: 'smart_tv_countries',
                    required: true,
                },
            },
            smart_tv_subdivisions: {
                dataMapKey: 'smart_tv.subdivisions',
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Smart TV subdivisions',
                    name: 'smart_tv_subdivisions',
                    required: true,
                },
            },
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            update: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
            delete: {
                refetchQueries: ['GetEventsEventBookingsData'],
            },
        },
        query: {
            getOptions: EventEventBookingsDropdownOptionsForCountries,
        },
    };
}
