import React from 'react';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {LCR_CHANNEL} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

const LcrChannelIndexButtons = () => (
    <div className='content__buttons'>
        <Authorization
            authorization={{
                [LCR_CHANNEL]: SECURITY_PRIVILEGES_CREATE,
            }}
        >
            <Link name={'lcrChannel.add'}>
                <ButtonAdd>Add new LCR Channel</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

export default LcrChannelIndexButtons;
