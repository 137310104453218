import PropTypes from 'prop-types';

import {displayFormModal} from '@modules/clipConfiguration/utils/formHelpers';

const ClipConfigurationAddModalContent = (props, title, model, form) => {
    displayFormModal(props, title, model, form, {});
};

ClipConfigurationAddModalContent.propTypes = {
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default ClipConfigurationAddModalContent;
