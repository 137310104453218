import React from "react";
import PropTypes from "prop-types";

import {ButtonBackToList} from "../../app/components/ButtonCollection";
import {routes} from "../../app/constants/routes";
import {renderModalError} from "../../../utils/forms";

import RoleEdit from "../views/RoleEdit";
import RoleResourceIndex from "../views/RoleResourceIndex";

export const createTabs = (id, path) => {
    return (routes.roles.add.path === path)
        ? {
            [routes.roles.add.path]: {
                key: routes.roles.add.path,
                url: "roles.add",
                text: "General information",
                render: <RoleEdit/>
            }
        }
        : {
            [routes.roles.edit.path]: {
                key: routes.roles.edit.path,
                url: "roles.edit",
                urlParams: {id: id},
                text: "General information",
                render: <RoleEdit/>
            },
            [routes.roles.resources.index.path]: {
                key: routes.roles.resources.index.path,
                url: "roles.resources.index",
                urlParams: {id: id},
                text: "Resources",
                render: <RoleResourceIndex/>
            }
        };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createRoleTabs';

export const RenderBackToListButton = React.memo(() => {
    return <ButtonBackToList name={'roles.index'} />;
});
RenderBackToListButton.displayName = 'RenderBackToListButton';

export const renderHeaderTitle = ({path, roleName = ''}) => {
    if (routes.roles.add.path === path) {
        return 'Add new role';
    }
    else if (roleName) {
        return `Role "${roleName}"`;
    }

    return 'Role';
};
renderHeaderTitle.propTypes = {
    roleName: PropTypes.string,
    path: PropTypes.string.isRequired,
};
renderHeaderTitle.displayName = 'renderHeaderTitle';

export const renderRoleModalError = () => {
    return renderModalError('Role', routes.roles.index.path)
};
renderModalError.displayName = 'renderRoleModalError';
