import React from "react";
import {Icon} from "semantic-ui-react";

/**
 * @param {boolean} isHiddenInLiveControl
 * @returns {JSX|null}
 */
export const hideInLiveControlRenderer = (isHiddenInLiveControl) => (
    isHiddenInLiveControl
        ? (
            <span className="eventContent__headerInfo">
                <Icon className="eye slash outline"/> Hidden in Live Control
            </span>
        )
        : null
);
