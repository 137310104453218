import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import {TABLE_DATA_LIMIT} from '@constants/variables';

import {columns, getColumnClassName, getRowRenderer} from '../utils/usersTable/columns';

const UsersTable = ({
    data = [],
    loading,
    search,
}) => {
    if (3 > search.length) {
        return null;
    }

    return (
        <>
            <Table
                className={'rows_color'}
                columns={columns}
                defaultSort={'email'}
                data={data}
                limit={TABLE_DATA_LIMIT}
                loading={loading}
                name='userAddTable'
                noDataText={'No Common-IAM users found.'}
                pagination={false}
                rowRenderer={getRowRenderer}
                rowClassname={getColumnClassName}
            />
            <br />
        </>
    );
};

UsersTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
};

export default UsersTable;
