import {convertToInt} from "../../../utils/helpers";

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "contents": {label: "Content"},
    "start_date": {
        label: "From",
        sorting: (row) => row.start_date,
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "end_date": {
        label: "To",
        sorting: (row) => row.end_date,
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "num_matches_agreed": {
        label: "Matches agreed",
        sorting: (row) => convertToInt(row.num_matches_agreed),
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "num_matches_commitment": {
        label: "Match commitment",
        sorting: (row) => convertToInt(row.num_matches_commitment),
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "num_matches_budget": {
        label: "Match budget",
        sorting: (row) => convertToInt(row.num_matches_budget),
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};
