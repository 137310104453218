import {get as _get} from "lodash";
import PropTypes from "prop-types";
import React from 'react';
import {connect} from "react-redux";
import {Segment} from "semantic-ui-react";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import MatchSelectionPeriodIndexButtons from "../components/MatchSelectionPeriodIndexButtons";
import MatchSelectionPeriodsTable from "../components/MatchSelectionPeriodsTable";

import {convertToInt} from "../../../utils/helpers";
import {exportExcel as matchSelectionPeriodsIndexExportExcel} from "../utils/export/matchSelectionPeriodsIndexExcel";
import useQuery from "../../../utils/hooks/useQuery";

import {GetMatchSelectionPeriodsForTable} from "../../../graphql/matchSelectionPeriod/query.graphql";

const MatchSelectionPeriodIndex = ({propertyLicenceId, propertyLicenceTypeId = null}) => {
    const {
        loading: areDataLoading = true,
        data = {},
    } = useQuery({
        query: GetMatchSelectionPeriodsForTable,
        options: {
            variables: {
                id: convertToInt(propertyLicenceId)
            },
        },
    });

    return (areDataLoading || !propertyLicenceTypeId)
        ? (<Segment basic loading={true}/>)
        : (
            <div>
                <HeaderRenderer
                    buttons={MatchSelectionPeriodIndexButtons}
                    buttonsProps={{propertyLicenceId}}
                    exportExcelParams={matchSelectionPeriodsIndexExportExcel(
                        data.matchSelectionPeriods,
                        convertToInt(propertyLicenceId),
                        convertToInt(propertyLicenceTypeId),
                    )}
                    loading={areDataLoading}
                />
                <MatchSelectionPeriodsTable
                    loading={areDataLoading}
                    matchSelectionPeriods={data.matchSelectionPeriods}
                />
            </div>
        );
};

MatchSelectionPeriodIndex.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
    propertyLicenceTypeId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    propertyLicenceTypeId: _get(state, 'app.entities.propertyLicence.property_licence_type_id', null),
});

export default connect(mapStateToProps)(MatchSelectionPeriodIndex);
