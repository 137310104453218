import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Popup} from 'semantic-ui-react';

import UserAddActionIcon from './UsersTableActionIcon';

const UsersTableActionColumn = ({
    iamUserData: {
        id,
        email,
        avcmpID,
        blocked,
    },
}) => {
    const messages = {
        associated: 'This Common-IAM user is already associated to a user in AV-CMP.',
        blocked: 'This Common-IAM user is disabled.',
    };

    const infoMessages = [];

    if (avcmpID) {
        infoMessages.push(messages.associated);
    }

    if (true === blocked) {
        infoMessages.push(messages.blocked);
    }

    const infoMessage = infoMessages.join('\n');

    return (
        <>
            {0 === infoMessages.length ? <UserAddActionIcon
                iamUserId={id}
                email={email}
            /> : <Popup
                trigger={<Icon color='blue' name='info circle'/>}
                position='top right'
                hoverable
                content={infoMessage}
            />}
        </>
    );
};

UsersTableActionColumn.propTypes = {
    iamUserData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        avcmpID: PropTypes.number,
        blocked: PropTypes.bool,
    }).isRequired,
};

export default UsersTableActionColumn;
