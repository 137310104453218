import {get as _get} from "lodash"

import ApplicationModel from "./ApplicationModel";

export default function ApplicationModelEdit() {
    const applicationModelEdit = ApplicationModel();

    applicationModelEdit.title = (data) => {
        const applicationName = _get(data, "rtmpOriginEntryPointApplication.name", null);

        return applicationName ? `RTMP origin entry point application "${applicationName}"` : '';
    };

    return applicationModelEdit;
}
