import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.description, 'description'));

    // filename is required only on ADD
    if (values["id"] === undefined) {
        Object.assign(errors, validators.required(values.filename, 'filename'));
    }

    return errors;
};

export default validate;
