import React from 'react';
import {withRouter} from 'react-router';
import {
    debounce as _debounce,
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';
import moment from 'moment';

import {gql, withApollo} from 'react-apollo';
import {getCompetitorsNameFromMatch} from '@utils/competitorName';
import {getStadiumNameFromMatch} from '@utils/stadium';
import {getTournamentNameFromMatch} from '@utils/tournament';
import {convertToInt} from '@utils/helpers';
import DefaultForm from '@appComponents/DefaultForm';
import {IconHint, IconLinkify} from '@appComponents/IconCollection';
import {checkIfMatchIsLinkedToSrEvent} from '@graphql/events/events';
import {rightsScopesDates as GraphQLRightsScopes} from '@graphql/propertyLicences/rightsScopes';
import { getTvChannelNamesFromChildEvent } from '@utils/tvChannels';

import {LinkedMatchDetailsQuery} from '../../../graphql/matches/query.graphql';
import RightScope from './logic/RightScope';
import {getTournamentIdByEvent} from '../utils/tournament';
import {MATCH_ID_MIN_LENGTH} from '../constants/linkEventToMatch';

class LinkEventToMatchIDForm extends DefaultForm {
    componentWillMount() {
        super.componentWillMount();

        this.MESSAGE_LOOKING_FOR_MATCH = "Looking for the match...";
        this.MESSAGE_MATCH_NOT_FOUND = "Match not found.";
        this.MESSAGE_MATCH_IS_LINKED = "This match is already linked to another event.";
        this.MESSAGE_WRONG_MATCH_TOURNAMENT = "The tournament of this match is not the same as the one of the event.";
        this.MESSAGE_ERROR = "An error occurred when trying to retrieve the requested match.";
        this.MESSAGE_RIGHT_SCOPE = "This match does not have media-rights for the property licence of the event.";

        this.initializeForm();

        this.setOnChangeCallback({
            match_id: _debounce(this.getMatchDetails, 1300),
            synchronise_times: this.setInfoForSynchroniseStartTime,
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false})
        });
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();

        if (prevProps.formValues.match_id != this.props.formValues.match_id) {
            this.resetMatchDetails('');
        }
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if(!nextProps.formValues.match_id) {
            this.resetMatchDetails('');
            this.setState(() => ({
                isLinkingDisabled: true,
            }));
        }
    }

    initializeForm = () => {
        this.resetMatchDetails('');
        this.setInfoForSynchroniseStartTime();
    };

    setInfoForSynchroniseStartTime = () => {
        const tooltip = `Current event start time is ${moment(this.props.formParams.eventStartDatetime).format("YYYY-MM-DD HH:mm")}`;

        this.setField("synchronise_times", {
            description: (<IconHint tooltip={tooltip} />)
        });
    };

    getMatchDetails = (data) => {
        let pattern = /^\d+$/,
            matchId = data.value,
            doesMatchIdExist = (Boolean(matchId) && Boolean(this.props.formValues.match_id)),
            matchIdValid = (pattern.test(matchId) && MATCH_ID_MIN_LENGTH <= matchId.length);

        if (!doesMatchIdExist || !matchIdValid) {
            this.setState(() => ({
                isLinkingDisabled: true
            }));

            return;
        }

        this.resetMatchDetails(this.MESSAGE_LOOKING_FOR_MATCH);

        // Get match details
        this.runApolloRequest('query', {
            query: LinkedMatchDetailsQuery,
            variables: {
                id: convertToInt(matchId)
            }
        }, {
            loadingFormFlag: false
        }).then((data) => {
            /**
             * Match not found
             */
            if (!data.data || !data.data.match) {
                this.resetMatchDetails(this.MESSAGE_MATCH_NOT_FOUND);
            } else {
                // check if match is linked to another event
                this.runApolloRequest('query', {
                    fetchPolicy: "network-only",
                    query: gql`${checkIfMatchIsLinkedToSrEvent}`,
                    variables: {
                        match: convertToInt(matchId)
                    }
                }, {
                    loadingFormFlag: false
                }).then((response) => {
                    let isLinkedMatchWithEvent = false;

                    /**
                     * Match is linked with another event
                     */
                    if (!_isEmpty(response.data.srEvents)) {
                        isLinkedMatchWithEvent = true;
                        /**
                         * Match is linked with another event
                         * we don't need to check right scopes because of previous error message
                         */
                        this.createMatchDetails(data.data.match, isLinkedMatchWithEvent);

                        return;
                    }

                    /**
                     * Match not linked with event,
                     * we need to check media right: right scope/tournament
                     * and match date range (compared with right scope)
                     */
                    this.runApolloRequest('query', {
                        fetchPolicy: "network-only",
                        query: gql(GraphQLRightsScopes),
                        variables: {
                            propertyLicenceId: convertToInt(this.props.formData.event.property_licence.id)
                        }
                    },
                    {
                        loadingFormFlag: false
                    }).then((response) => {
                        /**
                         * Add information about right scopes to check tournament and date range
                         */
                        let rightScopes = RightScope.perapreRightScopes(response.data.rightsScopes);

                        this.createMatchDetails(data.data.match, isLinkedMatchWithEvent, rightScopes);
                    });
                }).catch(() => {
                    this.resetMatchDetails(this.MESSAGE_ERROR);
                });
            }
        }).catch(() => {
            this.resetMatchDetails(this.MESSAGE_MATCH_NOT_FOUND);
        });
    };

    createMatchDetails = (match, isLinkedMatchWithEvent, rightScopes = []) => {
        let startDatetime = (match.start_datetime ? moment(match.start_datetime).format("YYYY-MM-DD HH:mm") : undefined),
            startDatetimeRightScope = (match.start_datetime ? moment(match.start_datetime).format("YYYY-MM-DD") : undefined),
            competitor = getCompetitorsNameFromMatch(match),
            stadium = getStadiumNameFromMatch(match),
            tournamentName = getTournamentNameFromMatch(match),
            tournamentIdByEvent = getTournamentIdByEvent(this.props.formData.event),
            newMatchTournamentId = _get(match, 'tournament.id', null),
            hasError = false,
            tvChannels = getTvChannelNamesFromChildEvent(match),
            message;

        const matchFound = {isMatchFound: true};

        if (isLinkedMatchWithEvent) {
            message = this.MESSAGE_MATCH_IS_LINKED;
            hasError = true;

        /**
         * There is tournamentId and bad media rights
         */
        } else if (
            startDatetimeRightScope
            && newMatchTournamentId
            && !RightScope.isValidDate(moment(startDatetimeRightScope), rightScopes, newMatchTournamentId)
        ) {
            message = this.MESSAGE_RIGHT_SCOPE;
            hasError = true;

        /**
         * No tournament value in the event (match/event) or new linking match
         * OR
         * bad tournament in the relationship between an existing event (match/event) and new match
         */
        } else if (
            !tournamentIdByEvent
            || !newMatchTournamentId
            || convertToInt(newMatchTournamentId) !== convertToInt(tournamentIdByEvent)
        ) {
            message = this.MESSAGE_WRONG_MATCH_TOURNAMENT;
            hasError = true;
        }

        /**
         * Display fields (if there is no error)
         * or
         * hide fields (if there is error)
         */
        this.setField('synchronise_times', {hidden: hasError});
        this.setState(() => ({
            isLinkingDisabled: hasError,
        }));

        this.setField('start_time', {
            values: {
                ...matchFound,
                startMatchTime: startDatetime,
                startEventTime: this.props.formParams.eventStartDatetime
            }
        });

        this.setField('competitor', {
            values: {...matchFound, description: competitor}
        });

        this.setField('tournament', {
            values: {...matchFound, description: tournamentName}
        });

        this.setField('court_venue', {
            values: {
                ...matchFound,
                text: stadium,
                sportId: _get(match, 'tournament.tournament_category.sport.id', null)
            }
        });

        this.setField('tv_channels', {
            values: {
                ...matchFound,
                text: tvChannels,
            },
        });

        this.setField('message', {
            values: {...matchFound, message: message}
        });

        this.setState(() => ({
            matchStartDateTime: startDatetime,
        }));
    };

    /**
     * Hide all information about match details
     * and hide checkbox
     * @param message
     */
    resetMatchDetails = (message) => {
        const matchNotFound = {isMatchFound: false};

        this.setField(['start_time', 'competitor', 'tournament', 'court_venue', 'tv_channels'], {
            values: {...matchNotFound}
        });

        this.setField('synchronise_times', {hidden: true});

        this.setField('message', {
            values: {
                ...matchNotFound,
                message: message,
                loading: message === this.MESSAGE_LOOKING_FOR_MATCH,
            }
        });
        this.setState(() => ({
            isLinkingDisabled: true,
        }));
    };

    renderSaveButton = () => {
        return super.renderSaveButton({
            content: "Link",
            disabled: this.state.isLinkingDisabled,
            icon: <IconLinkify/>,
        })
    };

    /**
     * It's necessary because when ID is passed to the form Delete button is created by default
     * @returns {null}
     */
    renderDeleteButton = () => {
        return null;
    };

    prepareDataForSubmit = (data) => {
        let dataToSave = {
            away_competitor: null,
            home_competitor: null,
            id: data.id,
            match: convertToInt(data.match_id),
            stadium: null,
            synchronise_times: data.synchronise_times,
            tournament: null,
            preset: null,
            as_additional_contents: null,
        };

        if (data.synchronise_times) {
            dataToSave = Object.assign(dataToSave, {
                event: {
                    start_datetime: moment(this.state.matchStartDateTime).format("YYYY-MM-DD HH:mm").toString(),
                }
            });
        } else {
            dataToSave = Object.assign(dataToSave, {
                event: null
            });
        }

        return dataToSave;
    };
}

export default withRouter(withApollo(LinkEventToMatchIDForm));
