import {get as _get, isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";

import {useQuery} from "@apollo/client";
import {isUrlParamValid} from '@utils/helpers';

import HeaderRenderer from "../../app/components/HeaderRenderer";
import PropertyLicenceTabsButtons from "../components/PropertyLicenceTabsButtons";
import Tabs from "../../app/components/Tabs";

import {routes} from "../../app/constants/routes";

import mapModulesToProps from "../../../utils/mapModulesToProps";
import {getPreparedBasicDataForEntity, renderPropertyLicenceModalError} from "../utils/propertyLicenceHelper";
import {createTabs, prepareActivePath} from "../utils/propertyLicenceTabsLayoutHelper";

import navigation from "../../app/constants/navigation";

import {GetPropertyLicenceBasicData} from "../../../graphql/propertyLicence/query.graphql";
import {TitleFromQuery} from "../../app/components/HeaderWithQuery";

export const PropertyLicenceTabsLayout = ({
    Entity,
    Menu,
    Modal,
    match: {params: {id = null}, path},
}) => {
    const propertyLicenceRoute = routes.propertyLicences,
        activePath = React.useMemo(() => (prepareActivePath(path)), [path]);

    const {error = {}, data: basicData = {}} = useQuery(GetPropertyLicenceBasicData, {
        variables: {id: id},
        skip: (propertyLicenceRoute.add.path === activePath),
    });

    if (!_isEmpty(error) || !isUrlParamValid(id)) {
        Modal.setModal({
            isVisible: true,
            content: renderPropertyLicenceModalError(),
        });
    }

    React.useEffect(() => {
        if (!_isEmpty(basicData)) {
            Entity.setEntity(getPreparedBasicDataForEntity(basicData.propertyLicence));
        }
    }, [basicData]);

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={PropertyLicenceTabsButtons}
                buttonsProps={{id}}
                messagesBoxNames="propertyLicenceMessage"
                pageTitle={<TitleFromQuery
                    query={GetPropertyLicenceBasicData}
                    variables={{id}}
                    errorBackLink={routes.propertyLicences.index.path}
                    path={'propertyLicence.name'}
                    entity={'property licence'}
                />}
            />
            <Tabs
                items={createTabs(id, path, {
                    hasAccreditation: _get(
                        basicData,
                        'propertyLicence.has_accreditation',
                        null
                    ),
                })
                }
                active={activePath}
            />
        </div>
    );
};

PropertyLicenceTabsLayout.propTypes = {
    accreditationContactPersonId: PropTypes.number,
    hasAccreditation: PropTypes.bool,
    propertyLicenceName: PropTypes.string,
    Entity: PropTypes.object.isRequired,
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
};
PropertyLicenceTabsLayout.displayName = "PropertyLicenceTabsLayout";

export default connect(null, mapModulesToProps(["Entity", "Menu", "Modal"]))(PropertyLicenceTabsLayout);
