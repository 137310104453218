import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));
    Object.assign(errors, validators.url(values.url, 'url', undefined, 'udp'));
    Object.assign(errors, validators.urlOptional(values.preview_url, 'preview_url', undefined, '.+'));
    Object.assign(errors, validators.required(values.encoding_datacenter, 'encoding_datacenter'));

    if (values.has_scte35) {
        Object.assign(errors, validators.required(values.ad_splice_config, 'ad_splice_config'));
    }

    return errors;
};

export default validate;
