import React from "react";

import {ButtonAdd} from "../../app/components/ButtonCollection";

import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";
import Authorization from "../../app/components/Authorization";
import Link from "../../app/components/Link";

const VenueIndexButtons = () => {
    const authorizationObject = {
        [RESOURCES.VENUE]: CONST.SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="content__buttons">
            <Authorization authorization={authorizationObject}>
                <Link name="venues.add">
                    <ButtonAdd>{"Add new venue"}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

export default VenueIndexButtons;
