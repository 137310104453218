import React from 'react';
import {withRouter} from 'react-router';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';
import {
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_MEDIACONNECT,
    ORIGIN_STREAM_TYPE_SDI,
    ORIGIN_STREAM_TYPE_UDP,
    ORIGIN_STREAM_TYPE_SRT,
} from '@constants/variables';
import {isContentHasInputAndEncoderSportradar} from '@modules/events/utils/eventsConfigurationTargetsTableHelper';
import IconAudio from '@modules/events/views/EventsConfigurationTargetsTable/IconAudio';

const originStreamTypes = [
    ORIGIN_STREAM_TYPE_UDP,
    ORIGIN_STREAM_TYPE_SDI,
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_MEDIACONNECT,
    ORIGIN_STREAM_TYPE_SRT,
];

const IconsAudioConfiguration = ({match, content, tableRow}) => {
    const originStreamType = convertToInt(_get(content, 'event_content_input.origin_stream_type.id', null));

    if (!isContentHasInputAndEncoderSportradar(content, tableRow) || !originStreamTypes.includes(originStreamType)) {
        return null;
    }

    const linkParams = {
        id: match.params.id,
        contentId: content.id,
        targetId: tableRow.id,
    };
    let iconName = 'volume up';

    if (0 === tableRow.audios.length) {
        iconName = 'volume off';
    } else if (1 === tableRow.audios.length) {
        iconName = 'volume down';
    }

    return <IconAudio iconName={iconName} linkParams={linkParams}/>;
};

IconsAudioConfiguration.propTypes = {
    match: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    tableRow: PropTypes.object.isRequired,
};
IconsAudioConfiguration.displayName = 'IconsAudioConfiguration';

export default withRouter(IconsAudioConfiguration);
