import {get as _get, has as _has} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Popup} from 'semantic-ui-react';

import {IconInfoCircleChangelog, IconWithTooltip} from '@appComponents/IconCollection';
import {getLink, Link} from '@appComponents/Link';
import SegmentHeader from '@appComponents/SegmentHeader';
import Table from '@appComponents/Table';
import {convertToInt, getParsedId} from '@utils/helpers';
import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';
import {DISTRIBUTION} from '@constants/resources';
import {
    CLIENT_SELECTION_TYPE_BLACKLIST,
    CLIENT_SELECTION_TYPE_WHITELIST,
    SECURITY_PRIVILEGES_READ,
} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {showBroadcastStatusChangelogModal} from '../utils/eventContentDistributionBroadcastStatusChangelog';

const authorizationDistributionReadObject = {
    [DISTRIBUTION]: SECURITY_PRIVILEGES_READ,
};

const columns = {
    'id': {
        label: 'ID',
        sorting: row  => getParsedId(row),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'product': {
        label: 'Product',
        sorting: row => _get(row, 'product.short_name', null),
        ...columnObjectAlignLeft,
    },
    'distribution_type': {
        label: 'Distribution type',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'product_status': {
        label: 'Status',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'start_datetime': {
        label: 'Start time',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'end_datetime': {
        label: 'End time',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'name': {
        label: 'Name',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'clients': {
        label: 'Clients',
        sorting: (row) => renderClients(row),
        ...columnObjectAlignLeft,
    },
    'broadcast_status': {
        label: 'Broadcast status',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'bookings': {
        label: '#Bookings',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'actions': {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },

};

const renderTime = (column, row) => {
    let time = row[column] ? moment(row[column]).format('HH:mm') : '-';
    const columnName = column.replace('_datetime', '');

    if (!row.synchronise_times) {
        time = (
            <Popup
                content={`${columnName.capitalizeFirstLetter()} time is not synchronised with the ${columnName} time of the content.` }
                trigger={<i>{time}</i>}
            />
        );
    }

    return time;
};

const renderClients = (row) => {
    if (!_has(row, 'client_selection_type.id')) {
        return '';
    }

    const clientSelectionTypeId = convertToInt(row.client_selection_type.id);
    let clientsText = 'All';

    if (CLIENT_SELECTION_TYPE_BLACKLIST === clientSelectionTypeId) {
        clientsText = `All except ${row.clients.map((client) => (client.name)).join(', ')}`;
    } else if (CLIENT_SELECTION_TYPE_WHITELIST === clientSelectionTypeId) {
        clientsText = row.clients.map((client) => (client.name)).join(', ');
    }

    return clientsText;
};

const rowRenderer = (column, row, props) => {
    switch (column) {
        case 'product':
            return row[column].short_name;
        case 'broadcast_status':
            return row[column] ? row[column].name : '-';
        case 'start_datetime':
        case 'end_datetime':
            return renderTime(column, row);
        case 'bookings':
            return <Link name='events.bookings.index' params={{id: props.eventId}}>{row['active_bookings_count'].toString()}</Link>;
        case 'clients':
            return renderClients(row);
        case 'actions':
            return (
                <div className='actions-list-icon'>
                    <IconInfoCircleChangelog
                        tooltipProps={{position: 'top right', hoverable: true}}
                        data={row}
                        resources={DISTRIBUTION}
                    />
                    <IconWithTooltip
                        name='file alternate outline'
                        tooltip='Broadcast status changelog'
                        popupProps={{position: 'top right'}}
                        onClick={showBroadcastStatusChangelogModal}
                        onClickElement={{
                            eventId: props.eventId,
                            contentId: props.contentId,
                            distributionId: row.id,
                        }}
                    />
                </div>
            );
        default:
            return null;
    }
};

const sortDistributions = (row) => {
    return (row.product && row.distribution_type) ? `${row.product.id}-${row.distribution_type.id}` : null;
};

export default class EventsConfigurationDistributions extends React.Component {
    static propTypes = {
        content: PropTypes.object,
        data: PropTypes.array,
        eventId: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        content: {},
        data: [],
        loading: false,
    };

    editHandler = () => {
        const {
            content: {
                content: {
                    id,
                },
            },
            eventId,
        } = this.props;

        this.props.history.replace(
            getLink(
                'events.configuration.content.distribution',
                {
                    id: eventId,
                    contentId: id,
                }
            )
        );
    };

    renderButton = () => {
        return <IconWithTooltip
            name='setting'
            onClick={this.editHandler}
            tooltip='Configure the distributions'
            resource={DISTRIBUTION}
            popupProps={{position: 'top right'}}
        />;
    };

    renderDistributions = (data) => {
        if (0 === data.length) {
            return null;
        }

        return (
            <Table
                name='distributionsList'
                data={this.props.data}
                columns={columns}
                loading={this.props.loading}
                rowRenderer={rowRenderer}
                rowRendererProps={{contentId: this.props.content.content.id, eventId: this.props.eventId}}
                noDataText='No distributions defined.'
                pagination={false}
                defaultSort={sortDistributions}
                className='mini'
            />
        );
    };

    render() {
        return (
            <div>
                <Authorization authorization={authorizationDistributionReadObject}>
                    <SegmentHeader buttons={this.renderButton()}> Distributions </SegmentHeader>
                    {this.renderDistributions(this.props.data)}
                </Authorization>
            </div>
        );
    }
}
