import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {graphql} from 'react-apollo';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import {getLink} from '@appComponents/Link';
import {convertToInt, isUrlParamValid} from '@utils/helpers';

import ProductGeolocationIndex from '../views/ProductGeolocations/ProductGeolocationIndex';
import ProductGeolocationsModel from '../forms/ProductGeolocationsModel';
import ProductGeolocationsModelEdit from '../forms/ProductGeolocationsModelEdit';
import ProductGeolocationsForm from '../forms/ProductGeolocationsForm';
import mapModulesToProps from '../../../utils/mapModulesToProps';
import {GetProductGeolocationsForTable} from '../../../graphql/clientProductGeolocationCountry/query.graphql';

function ProductGeolocations(props) {
    const {
        clientProductId,
        DataProductGeolocations: {
            clientProductGeolocationCountries,
            loading,
        },
        history: {
            push,
        },
        match: {
            params: {
                id,
                geolocationId,
            },
            path,
        },
        Modal,
        modal: {
            isVisible,
        },
        productShortName,
    } = props;
    const [modalVisibility, setModalVisibility] = useState(isVisible);

    useEffect(() => {
        loadModal(path);
    }, []);

    useEffect(() => {
        if (path !== routes.clients.products[productShortName].geolocation.index.path) {
            loadModal(path, {id, geolocationId});
        }
    }, [path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            push(getLink(`clients.products.${productShortName}.geolocation.index`, {id: id}));
        }
    }, [isVisible]);

    const loadModal = (path, params = {}) => {
        const formParams = {
            clientId: id,
            clientProductId: clientProductId,
            productShortName: productShortName,
            optionsVariables: {
                clientProductId: clientProductId,
            },
        };

        if (path === routes.clients.products[productShortName].geolocation.add.path) {
            return loadModalForm(createForm(
                ProductGeolocationsModel,
                ProductGeolocationsForm,
                formParams
            ), params);
        } else if (path === routes.clients.products[productShortName].geolocation.edit.path) {
            return loadModalForm(createForm(
                ProductGeolocationsModelEdit,
                ProductGeolocationsForm,
                {
                    id: geolocationId || params.geolocationId,
                    ...formParams
                }
            ), params);
        }
    };

    const loadModalForm = (Form, data) => {
        if (!isUrlParamValid(data.geolocationId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Country',
                    getLink(`clients.products.geolocation.index`, {
                        id: data.id,
                    })
                ),
            });
        }

        Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });

        setModalVisibility(true);
    };

    return (
        <ProductGeolocationIndex
            clientId={convertToInt(id)}
            loading={loading}
            productGeolocationCountries={clientProductGeolocationCountries}
            productShortName={productShortName}
        />
    );
}

ProductGeolocations.propTypes = {
    clientProductId: PropTypes.number.isRequired,
    DataProductGeolocations: PropTypes.shape({
        clientProductGeolocationCountries: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            geolocationId: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
    modal: PropTypes.shape({
        isVisible: PropTypes.bool.isRequired,
    }),
    productShortName: PropTypes.string.isRequired,
};
ProductGeolocations.defaultProps = {
    DataProductGeolocations: {
        clientProductGeolocationCountries: [],
        loading: false,
    },
    match: {
        params: {
            geolocationId: null
        }
    }
};

const ProductGeolocationsWithQuery = compose(
    graphql(GetProductGeolocationsForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "network-only",
                variables: {
                    clientProduct: convertToInt(props.clientProductId),
                }
            }
        },
        name: "DataProductGeolocations"
    })
)(ProductGeolocations);

const mapStateToProps = (state) => ({
    modal: state.modal
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(ProductGeolocationsWithQuery));
