import React from 'react';
import {get as _get} from 'lodash';

import {getLink} from '@appComponents/Link';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {loadModalForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {USER} from '@constants/resources';

import EventContentDistributionBroadcastStatusChangelog
    from '../components/EventContentDistributionBroadcastStatusChangelog';

export const BROADCAST_STATUS_NAME_KEY = 'broadcast_status.name';

export const columns = {
    'datetime': {
        label: 'Date/time',
        sorting: row => _get(row, 'datetime', ''),
        header: {
            align: 'center',
        },
        content: {align: 'center'},
    },
    'broadcastStatus': {
        label: 'Broadcast status',
        sorting: row => _get(row, BROADCAST_STATUS_NAME_KEY, ''),
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    'user': {
        label: 'User',
        sorting: row => _get(row, 'user.name', ''),
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    'ip': {
        label: 'IP',
        sorting: row => _get(row, 'ip', ''),
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'datetime':
            return _get(row, 'datetime', '');
        case 'broadcastStatus':
            return _get(row, BROADCAST_STATUS_NAME_KEY, '-');
        case 'user':
            if (!row.user) {
                return '';
            }

            return (
                <LinkWithAuthorization
                    authorization={{
                        [USER]: SECURITY_PRIVILEGES_READ,
                    }}
                    link={{
                        name: 'users.edit',
                        params: {id: row.user.id},
                    }}
                    newWindow={true}
                >
                    {row.user.name}
                </LinkWithAuthorization>
            );
        case 'ip':
            return _get(row, 'ip', '');
        default:
            return null;
    }
};

export const showBroadcastStatusChangelogModal = (e, {eventId, contentId, distributionId}) => {
    const indexUrl = getLink('events.configuration.index', {id: eventId}),
        broadcastStatusChangelogUrl = getLink(
            'events.configuration.content.broadcastStatusChangelog',
            {
                id: eventId,
                contentId: contentId,
                distributionId: distributionId,
            }
        );

    if (!isUrlParamValid(distributionId)) {
        return showModal({
            isVisible: true,
            content: renderModalError('Distribution',
                getLink('events.configuration.index', {
                    id: eventId,
                })
            ),
        });
    }

    loadModalForm({
        url: broadcastStatusChangelogUrl,
        form: <EventContentDistributionBroadcastStatusChangelog
            contentId={convertToInt(contentId)}
            distributionId={convertToInt(distributionId)}
            eventId={convertToInt(eventId)}
        />,
        setModal: showModal,
        size: 'small',
        onClose: () => window.history.pushState({}, '', indexUrl),
    });
};
