import React from 'react';
import {Modal, Header} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import moment from 'moment';
/* eslint import/no-unresolved: 0 */
import {withApollo} from 'react-apollo';
import {
    get as _get,
    isNil as _isNil,
} from 'lodash';

import Link, {getLink} from '@appComponents/Link';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';

import EventForm from './EventForm';
import {SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID} from './SportradarExistingEventByMatchModel';
import RightScope from './logic/RightScope';
import {prefillCoverage} from "@modules/events/utils/formHelper";

class SportradarExistingForm extends EventForm {
    componentWillReceiveProps(nextProps) {
        const matchEvents = _get(nextProps, "GraphQLOptionsData.srEvents", []);

        if (matchEvents.length > 0 && this.state.defaultForm_id === SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
            this.props.Modal.setModal({
                content: <div>
                    <Header color="red" content="Match error" icon="warning"/>
                    <Modal.Content>
                        <p>Match already linked to event!</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Link
                            newWindow={true}
                            name={'events.configuration.index'}
                            params={{id: matchEvents[0].event.id}}
                        >
                            Open event
                        </Link>
                    </Modal.Actions>
                </div>,
            });

            return
        }

        super.componentWillReceiveProps(nextProps);

        let matchObject = _get(this.state, "defaultForm_data.match");

        if (this.state.defaultForm_id !== SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
            const propertyLicence = _get(this.state, "defaultForm_data.event.property_licence", {});
            matchObject = _get(this.state, "defaultForm_data.event.sr_event.match", {});

            if (_get(this.state, "defaultForm_data.event", false) && !this.state.dataReceived) {
                this.setField("property_licence", {
                    defaultValue: propertyLicence.id,
                    options: [{
                        key: propertyLicence.id,
                        text: propertyLicence.name + " - " + propertyLicence.licensor.name,
                        value: propertyLicence.id
                    }]
                });

                let synchroniseTimes = _get(this.state, "defaultForm_data.event.sr_event.synchronise_times", true);

                if (!synchroniseTimes) {
                    this.setField("synchronise_times", {
                        defaultValue: false
                    });
                    this.setField("start_datetime", {
                        disabled: false,
                        selected: moment(_get(this.state, "defaultForm_data.event.start_datetime", null)).format("YYYY-MM-DD HH:mm").toString()
                    })
                }

                this.setState(() => ({
                    dataReceived: true
                }));

                this.getRightsScopes(propertyLicence.id);
                this.getPresetsForPropertyLicence(propertyLicence.id, _get(this.state, "defaultForm_data.event.id", null));
            }
        }

        /**
         * tournamentId - for datepicker and right scope - available date range on datepicker
         */
        const tournamentId = _get(matchObject, "tournament.id", false);

        this.setState(() => ({
            tournamentId: tournamentId
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.formValues.property_licence
            && this.props.formValues.property_licence !== prevProps.formValues.property_licence) {
            this.getProductsForCoverage(this.props.formValues.property_licence);
        }

        if ((this.state.productsFromUsages && this.props.formValues.start_datetime
            && (this.state.productsFromUsages !== prevState.productsFromUsages
                || this.props.formValues.start_datetime !== prevProps.formValues.start_datetime))) {
            const selectedCoverage = this.props.GraphQLEntityData.event?.products
                .map((product) => convertToInt(product.id)),
                selectedTournament = this.props.GraphQLEntityData.event?.sr_event.tournament?.id
                    ?? this.props.GraphQLEntityData.match?.tournament.id
                    ?? this.props.GraphQLEntityData.event?.sr_event.match.tournament?.id;

            this.showCoverage(this.props.formValues.start_datetime, this.state.productsFromUsages, selectedTournament);
            this.setField('coverage', {
                defaultValue: selectedCoverage,
            });
        }

        if (prevProps.formValues.event_preset !== this.props.formValues.event_preset) {
            prefillCoverage(
                this.props.Model.fields.coverage.props.hidden,
                this.state.presetProducts,
                this.props.Model,
                this.setField,
                this.state,
                (newState) => this.setState(newState)
            );
        }
    }

    renderDeleteButton = () => {
        if (this.state.defaultForm_id === SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
            return null
        }

        return super.renderDeleteButton();
    };

    filterStartDatetime = (date) => (
        RightScope.isValidDate(date, this.state.rightsScopes, this.state.tournamentId)
    );

    componentWillMount() {
        super.componentWillMount();

        this.setField("synchronise_times", {
            defaultValue: true
        });

        this.setOnChangeCallback({
            "property_licence": (data) => {
                if (this.props.Model.editModel){
                    return
                }

                if (data.value &&
                    !_isNil(this.state.selectedSynchroniseTimes)
                    && !this.state.selectedSynchroniseTimes) {
                    this.setField("start_datetime", {
                        disabled: false
                    })
                }

                if (data.value) {
                    this.setField("start_datetime", {
                        disabled: true,
                        loading: true
                    });

                    this.getRightsScopes(data.value).then(() => {
                        this.setField("start_datetime", {
                            loading: false
                        });

                        if (!_isNil(this.state.selectedSynchroniseTimes)
                            && !this.state.selectedSynchroniseTimes) {
                            this.setField("start_datetime", {
                                disabled: false
                            })
                        }
                    });
                }

                this.setField("end_datetime", {
                    disabled: false
                });

                this.getPresetsForPropertyLicence(data.value, _get(this.state, "defaultForm_data.event.id", null));
            },
            "synchronise_times": (data) => {
                this.setState(() => ({
                    selectedSynchroniseTimes: !data.value
                }));

                let disableDateInput = true,
                    propertyLicenceId = this.props.formValues.property_licence;

                if (data.checked) {
                    if (propertyLicenceId) {
                        disableDateInput = false
                    } else if (this.state.defaultForm_id !== SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
                        disableDateInput = false
                    }
                }

                let props = {
                        disabled: disableDateInput
                    },
                    matchObject = _get(this.state, "defaultForm_data.match", {});

                if (this.state.defaultForm_id !== SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
                    matchObject = _get(this.state, "defaultForm_data.event.sr_event.match", {})
                }

                if (!data.checked) {
                    let date = _get(matchObject, "start_datetime", null);

                    props.timestamp = Date.now();
                    props.selected = date ? moment(date) : date;
                }

                this.setField("start_datetime", props)
            }
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink("events.configuration.index", {id: response.data.createSrEvent.event.id}));
        });

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({
                isVisible: false
            })
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink("events.index"));
        });

        this.setUpdateSuccessCallback(() => {
            this.backToEventPage();
            refetchQueryByName("GetEventContents");
            refetchQueryByName("GetEventPresetsByEvent");
            refetchQueryByName("encodingTargetsQuery");
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({
                isVisible: false
            })
        });
    }

    renderSaveButton = () => {
        let props = {};

        if (this.state.defaultForm_id === SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
            props.content = "Create"
        }

        return super.renderSaveButton(props)
    };

    prepareDataForSubmit = (data) => {
        const coverageToSave = (data.coverage || []).filter(product => {
            return this.state.productsToCoverageFilteredByContentAndDate.find(prod => convertToInt(prod.value) === product);
        });

        let savedPresetId = _get(this.state, "defaultForm_data.event.event_preset.id", null);
        let dataToSave = {
            event: {
                property_licence: parseInt(data.property_licence, 10),
                start_datetime: data.start_datetime,
                end_datetime: data.end_datetime,
                description: data.description || null,
                color: data.color.substring(1),
                notes: data.notes || null,
                products: coverageToSave || null,
            },
            synchronise_times: data.synchronise_times,
        };

        if (data.event_status) {
            dataToSave.event.event_status = parseInt(data.event_status, 10);
        }

        if (data.event_preset) {
            dataToSave.event.event_preset = !data.event_preset
                ? parseInt(savedPresetId, 10) : parseInt(data.event_preset, 10)
        }

        if (data.id) {
            dataToSave.id = parseInt(data.id, 10);
        } else {
            dataToSave.event = Object.assign(dataToSave.event, {
                is_master: false,
                event_type: 1,
            });

            dataToSave = Object.assign(dataToSave, {
                match: parseInt(data.match_id, 10),
                home_competitor: null,
                away_competitor: null,
                stadium: null,
                tournament: null
            })
        }

        if (undefined !== data.as_additional_contents) {
            dataToSave.as_additional_contents = !!data.as_additional_contents;
        }

        return this.addPresetDataForSubmit(dataToSave, data);
    }
}

export default withRouter(withApollo(SportradarExistingForm))
