import {
    GetClientProductThirdPartyForForm,
    GetClientProductThirdPartyOptions
} from '@graphql/clientProductThirdParty/query';
import {
    CreateClientProductThirdParty,
    DeleteClientProductThirdParty,
    UpdateClientProductThirdParty
} from '@graphql/clientProductThirdParty/mutation';
import Form from '@appComponents/ReduxFormControls';

import ClientProductModel from './ClientProductModel';
import {renderHeader} from './clientProductFormUtils';
import validate from './validatorClientProductThirdParty';

export default function ClientProductThirdPartyModel() {
    let clientProductThirdPartyModel = ClientProductModel();

    clientProductThirdPartyModel.deleteId = "clientProductsThirdParty.0.id";
    clientProductThirdPartyModel.entityDataMapKey = "clientProductsThirdParty.0";
    clientProductThirdPartyModel.entityLabel = "product Third party";
    clientProductThirdPartyModel.validate = validate;
    clientProductThirdPartyModel.query = {
        getEntity: GetClientProductThirdPartyForForm,
        getOptions: GetClientProductThirdPartyOptions,
    };
    clientProductThirdPartyModel.mutation = {
        createEntity: CreateClientProductThirdParty,
        deleteEntity: DeleteClientProductThirdParty,
        updateEntity: UpdateClientProductThirdParty,
    };

    clientProductThirdPartyModel.dataMap = {
        clientProductsThirdParty: {
            id: "",
            ...clientProductThirdPartyModel.dataMap
        },
    };

    clientProductThirdPartyModel.remapBaseDatamapKeys(clientProductThirdPartyModel);

    clientProductThirdPartyModel.fields = Object.assign({}, clientProductThirdPartyModel.fields, {
        header: {
            props: {
                component: renderHeader,
                label: "Header",
                name: "header",
                defaultValue: "Third party specific settings",
            }
        },
        third_party_client_types: {
            optionsKey: "thirdPartyClientTypes",
            props: {
                className: "inline",
                component: Form.SemanticCheckboxList,
                label: "Types",
                name: "third_party_client_types",
                required: true,
            },
        },
    });

    return clientProductThirdPartyModel;
}
