import PropTypes from "prop-types";

export const exportExcel = (dataExport) => (
    {
        dataExport: dataExport,
        filename: 'roles',
        parseData: role => [
            role.id,
            role.name,
            role.resources_count,
            role.users_count,
        ],
        path: 'roles.index.path',
        titles: [
            "ID",
            "Name",
            "#Resources",
            "#Users",
        ]
    }
);

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
