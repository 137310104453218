import React from 'react';
import {withRouter} from "react-router";

import ProductLcoGeoRestrictionsForm from "../forms/ProductLcoGeoRestrictionsForm";
import ProductLcoGeoRestrictionsModel from "../forms/ProductLcoGeoRestrictionsModel";

import {createForm} from "../../../utils/forms";

import {ProductLctGeoRestrictionsEdit} from "./ProductLctGeoRestrictionsEdit";

export class ProductLcoGeoRestrictionsEdit extends ProductLctGeoRestrictionsEdit {
    renderForm = () => {
        const Form = createForm(
            ProductLcoGeoRestrictionsModel,
            ProductLcoGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };
}

export default withRouter(ProductLcoGeoRestrictionsEdit)
