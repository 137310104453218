import React from "react";
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {withRouter} from "react-router";
import {Header, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {get as _get} from "lodash";

import {getLink} from "../../app/components/Link";
import Tabs from "../../app/components/Tabs";
import {TitleFromQuery} from "../../app/components/HeaderWithQuery";

import ClientCdnConfigurationButtons from "../components/ClientCdnConfigurationButtons";

import ClientCdnConfigurationEdit from "../views/ClientCdnConfigurationEdit";
import AkamaiLiveStreamNamesIndex from "../views/AkamaiLiveStreamNamesIndex";

import mapModulesToProps from "../../../utils/mapModulesToProps";
import {convertToInt, parseToBoolean} from '../../../utils/helpers';

import {routes} from "../../app/constants/routes";
import {CDN_AKAMAI, CDN_AKAMAI_2, EXPORT_EXCEL_URL_FRAGMENT} from "../../app/constants/variables";

import {GetCdnConfigurationForLayout} from "../../../graphql/clientCdnConfiguration/query.graphql";

const ClientCdnConfigurationLayoutTabs = ({loading, match}) => {
    const {data = {}} = useQuery(GetCdnConfigurationForLayout, {
        variables: {
            id: match.params.clientCdnConfigurationId,
        },
        skip: parseToBoolean(!_get(match, 'params.clientCdnConfigurationId', false)),
    });

    const getClientTabsState = () => {
        let tabs = {};

        if (match.path === routes.clients.clientCdnConfiguration.add.path) {
            tabs = {
                [routes.clients.clientCdnConfiguration.add.path]: {
                    key: routes.clients.clientCdnConfiguration.add.path,
                    url: 'clients.clientCdnConfiguration.add',
                    text: 'General settings',
                    render: <ClientCdnConfigurationEdit
                        clientId={match.params.id}
                    />,
                },
            };
        } else {
            tabs = {
                [routes.clients.clientCdnConfiguration.edit.path]: {
                    key: routes.clients.clientCdnConfiguration.edit.path,
                    url: 'clients.clientCdnConfiguration.edit',
                    urlParams: {id: match.params.id, clientCdnConfigurationId: match.params.clientCdnConfigurationId},
                    text: 'General settings',
                    render: <ClientCdnConfigurationEdit
                        clientId={match.params.id}
                        clientCdnConfigurationId={match.params.clientCdnConfigurationId}
                    />,
                },
            };

            if ([CDN_AKAMAI, CDN_AKAMAI_2].includes(
                convertToInt(_get(data, 'clientCdnConfiguration.cdn.id', '')))
            ) {
                tabs = Object.assign(tabs, {
                    [routes.clients.clientCdnConfiguration.akamaiLiveStreamName.index.path]: {
                        key: routes.clients.clientCdnConfiguration.akamaiLiveStreamName.index.path,
                        url: 'clients.clientCdnConfiguration.akamaiLiveStreamName.index',
                        urlParams: {
                            id: match.params.id,
                            clientCdnConfigurationId: match.params.clientCdnConfigurationId
                        },
                        text: 'Akamai Live stream names',
                        render: '' === _get(data, 'clientCdnConfiguration.name', null)
                            ? null
                            : <AkamaiLiveStreamNamesIndex
                                akamaiCdnIngestMethodId={convertToInt(_get(data, 'clientCdnConfiguration.akamai_cdn_ingest_method.id', null))}
                                liveIngestMethodId={convertToInt(_get(data, 'clientCdnConfiguration.live_ingest_method.id', null))}
                                clientId={convertToInt(match.params.id)}
                                clientCdnConfigurationId={convertToInt(match.params.clientCdnConfigurationId)}
                            />,
                    },
                });
            }
        }

        return tabs;
    };

    const getActiveTab = () => {
        const akamaiLiveStreamNameRoutes = routes.clients.clientCdnConfiguration.akamaiLiveStreamName;
        let active = match.path;

        if (active === akamaiLiveStreamNameRoutes.add.path
            || active === akamaiLiveStreamNameRoutes.edit.path
            || active === `${akamaiLiveStreamNameRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = akamaiLiveStreamNameRoutes.index.path;
        }

        return active;
    };

    return (
        <React.Fragment>
            <Tabs
                loading={loading}
                items={getClientTabsState()}
                active={getActiveTab()}
                level={2}
            />
        </React.Fragment>
    );
};
ClientCdnConfigurationLayoutTabs.propTypes = {
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            clientCdnConfigurationId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string.isRequired,
    }),
};
ClientCdnConfigurationLayoutTabs.displayName = 'ClientCdnConfigurationLayoutTabs';

class ClientCdnConfigurationLayout extends React.Component {
    static propTypes = {
        akamaiCdnIngestMethodId: PropTypes.number,
        liveIngestMethodId: PropTypes.number,
        cdnId: PropTypes.number,
        client: PropTypes.object,
        clientCdnConfigurationName: PropTypes.string,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
    };

    static defaultProps = {
        akamaiCdnIngestMethodId: null,
        liveIngestMethodId: null,
        cdnId: null,
    };

    state = {loading: false};

    render() {
        return (
            <div>
                <Header as="h3" className="--paddingBottom-14" dividing>
                    <TitleFromQuery
                        query={GetCdnConfigurationForLayout}
                        variables={{id: this.props.match.params.clientCdnConfigurationId}}
                        errorBackLink={getLink('clients.clientCdnConfiguration.index', {id: this.props.match.params.id})}
                        path={'clientCdnConfiguration.name'}
                        entity={'CDN configuration'}
                    />
                    <ClientCdnConfigurationButtons clientId={convertToInt(this.props.match.params.id)} />
                </Header>
                <Segment basic className="--tabs --clear" loading={this.state.loading}>
                    <ClientCdnConfigurationLayoutTabs
                        loading={this.state.loading}
                        match={this.props.match}
                    />
                </Segment>
            </div>
        );
    }
}

export default withRouter(connect(null, mapModulesToProps(['Modal']))(ClientCdnConfigurationLayout));
