import React from 'react';
import {sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import {Button, Dimmer, Divider, Grid, Header, Loader, Segment} from 'semantic-ui-react';

import {hideModal, showModal} from '@utils/modal';
import EventContentSubtitleForm from '@modules/events/forms/EventContentSubtitleForm';
import {refetchQueryByName} from '@utils/apollo';
import {IconDelete, IconInfoCircleChangelog} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import {Authorization, hasPrivileges} from '@appComponents/Authorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {showMessageBox} from '@utils/messageBox';
import EventContentSubtitleModal from '@modules/events/components/EventContentSubtitleModal';

const columns = {
    tracks: {label: 'Track', width: 5},
    streamLanguage: {label: 'Language', width: 7},
    actions: {label: '', width: 4},
};

class EventContentSubtitleTable extends React.Component {
    static propTypes = {
        properties: PropTypes.array,
        subtitles: PropTypes.array,
        tracks: PropTypes.array,
        streamLanguages: PropTypes.array,
        encodingTargetId: PropTypes.number,
    };

    static defaultProps = {
        subtitles: [],
        tracks: [],
        streamLanguages: [],
    };

    constructor() {
        super();

        this.state = {
            activeEditableFields: null,
            subtitles: [],
            streamLanguages: [],
            loading: false,
            authorization: {
                update: hasPrivileges({[RESOURCES.SUBTITLE]: CONST.SECURITY_PRIVILEGES_UPDATE}),
            },
        };
    }

    addMessageSuccessSave = () => {
        showMessageBox(
            'eventSubtitleMessage',
            null,
            'The subtitle has been saved successfully.',
            'success'
        );
    }

    activateEditableCell = (event) => {
        this.setState({
            activeEditableFields: convertToInt(event.currentTarget.dataset.id),
        });
    };

    deactivateEditableCell = () => {
        this.setState({
            activeEditableFields: null,
        });
    };

    componentWillReceiveProps(nextProps) {
        const {DataEventContentSubtitle: {subtitles, streamLanguages}} = nextProps.properties;

        this.setState(() => ({
            subtitles: subtitles ? _sortBy(subtitles, 'creation_datetime') : [],
            streamLanguages,
            encodingTargetId: nextProps.properties.encodingTargetId,
        }));
    }

    renderHeader = () => {
        const cells = Object.keys(columns).map((keys) => {
            return (
                <Grid.Column key={keys} width={columns[keys].width}>
                    {columns[keys].label}
                </Grid.Column>
            );
        });

        return (
            <Grid className='editableTable__header --audio_header'>
                <Grid.Row>
                    {cells}
                </Grid.Row>
            </Grid>
        );
    };

    handleSubtitleAdd = () => {
        this.setState(() => ({loading: true}));

        const dataToSave = Object.assign({}, {
            encodingTargetId: convertToInt(this.state.encodingTargetId),
            trackNumber: convertToInt(this.props.properties.subtitleToSave.trackNumber),
            streamLanguage:
                convertToInt(this.props.properties.subtitleToSave.streamLanguage) || '',
        });

        const {
            Form: { clearForm },
        } = this.props.properties;

        return this.props.properties.CreateMutation({
            variables: {...dataToSave},
        }).then(() => {
            this.addMessageSuccessSave();
            refetchQueryByName('getEventContentSubtitles');
            refetchQueryByName('GetEncodingTargetsForDistributions');
            refetchQueryByName('GetEventContentEncodingConfigurationChanged');

            clearForm('form_es_add');

            this.setState(() => ({loading: false}));
        }).catch(this.showErrorSubtitleNotSaved);
    };

    reRenderModal = () => {
        showModal({
            isVisible: true,
            content: <EventContentSubtitleModal encodingTargetId={this.props.properties.encodingTargetId}  key={2}/>,
            size: 'small',
        });
    }

    handleSubtitleDelete = (id) => {
        this.props.properties.Modal.setModalConfirmation({
            header: <Header icon='trash' content='Delete subtitle'/>,
            text: 'Are you sure you want to remove this subtitle?',
            onYes: () => (this.deleteSubtitle(id)),
            onNo: () => (this.reRenderModal()),
        });
    };

    handleSubtitleUpdate = () => {
        this.setState(() => ({loading: true}));

        const subtitleToSave = this.props.properties.subtitleToUpdate;
        const dataToUpdate = Object.assign({}, {
            id: convertToInt(subtitleToSave.id),
            trackNumber: convertToInt(subtitleToSave.trackNumber),
            streamLanguage: convertToInt(subtitleToSave.streamLanguage) || '',
            encodingTargetId: convertToInt(this.props.properties.encodingTargetId),
        });

        return this.props.properties.UpdateMutation({
            variables: {...dataToUpdate},
        }).then(() => {
            this.addMessageSuccessSave();
            refetchQueryByName('getEventContentSubtitles');
            refetchQueryByName('GetEncodingTargetsForDistributions');
            refetchQueryByName('GetEventContentEncodingConfigurationChanged');

            this.setState(() => ({loading: false}));
        }).catch(
            this.showErrorSubtitleNotSaved
        );
    };

    showErrorSubtitleNotSaved = (error) => {
        this.props.properties.MessageBox.addMessage(
            'eventSubtitleMessage',
            'The subtitle could not be saved.',
            `${error}`,
            'error'
        );

        this.setState(() => ({loading: false}));
    };

    deleteSubtitle(subtitleId) {
        const dataToDelete = Object.assign({}, {
            id: subtitleId,
        });
        const {
            MessageBox: { addMessage },
        } = this.props.properties;

        this.props.properties.Modal.setModalConfirmation({
            text: 'The subtitle is being deleted...',
        });

        return this.props.properties.DeleteMutation({
            variables: {...dataToDelete},
        }).then(() => {
            addMessage('eventSubtitleMessage',
                null,
                'The subtitle has been deleted successfully.',
                'success'
            );

            refetchQueryByName('GetEncodingTargetsForDistributions');
            refetchQueryByName('GetEventContentEncodingConfigurationChanged');
            this.reRenderModal();

        }).catch((error) => {
            addMessage(
                'eventSubtitleMessage',
                'The subtitle could not be deleted.',
                `${error}`,
                'error'
            );
        });
    }

    renderButtonsForEditableRowInViewMode = (props, subtitleId) => {
        return (
            <div className='actions-list-icon'>
                <Authorization authorization={{
                    [RESOURCES.SUBTITLE]: CONST.SECURITY_PRIVILEGES_DELETE,
                }}>
                    <IconDelete icon='trash'
                        onClick={() => {this.handleSubtitleDelete(subtitleId.id);}}
                        onClickElement={subtitleId.id}
                    />
                </Authorization>
                <IconInfoCircleChangelog
                    tooltipProps={{hoverable: true, position: 'top right'}}
                    resources={RESOURCES.SUBTITLE}
                    data={subtitleId}
                />
            </div>
        );
    };

    getStreamLanguageById = (object) => {
        let language;
        let id = object.id ? object.id : '';

        this.state.streamLanguages.forEach((streamLanguage) => {
            if (id === streamLanguage.id) {
                const languageCodeString = streamLanguage.language.code ? `(${streamLanguage.language.code})` : '';

                language = `${streamLanguage.name} ${languageCodeString}`;
            }
        });

        return language;
    }

    renderEditableGrids = () => {
        return this.state.subtitles.map((data, index) => {
            return (
                [
                    <Divider key={`divider_${index}`}/>,
                    (this.state.activeEditableFields === convertToInt(data.id))
                        ? this.renderEditForm(data)
                        : this.renderEditableGrid(data),
                ]
            );
        });
    };

    renderEditableGrid = (data) => {
        const gridClass = this.state.authorization.update ? 'editableTable__trigger --audio_modal_row' : '--audio_modal_row',
            onClickCallback = this.state.authorization.update ? this.activateEditableCell : null;

        return (
            <Grid key={data.id} verticalAlign='middle'>
                <Grid.Row  data-id={data.id} onClick={onClickCallback} className={gridClass}>
                    <Grid.Column width={columns.tracks.width} >
                        {data.track_number}
                    </Grid.Column>
                    <Grid.Column width={columns.streamLanguage.width} >
                        {this.getStreamLanguageById(data.stream_language)}
                    </Grid.Column>
                    <Grid.Column width={columns.actions.width} >
                        {this.renderButtonsForEditableRowInViewMode(this.props, data)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    renderAddForm = () => {
        return (
            <EventContentSubtitleForm
                form='form_es_add'
                formData={{
                    streamLanguages: this.state.streamLanguages,
                    subtitles: this.state.subtitles,
                }}
                onAdd={this.handleSubtitleAdd}
                loading={this.state.loading}
            />
        );
    };

    renderEditForm = (data) => {
        return (
            <EventContentSubtitleForm
                form='form_es_update'
                loading={this.state.loading}
                formData={{
                    streamLanguages: this.state.streamLanguages,
                    subtitles: {
                        id: data.id,
                        trackNumber: data.track_number,
                        streamLanguages: data.stream_language,
                    },
                }}
                onUpdate={this.handleSubtitleUpdate}
                onDeactivateEditableCell={this.deactivateEditableCell}
            />
        );
    };

    render() {
        return (
            <div>
                <Dimmer inverted active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <Header content='Subtitle configuration' />
                {this.renderHeader()}
                {this.renderAddForm()}
                {this.renderEditableGrids()}
                <Segment className='--multiAudioModalFooter'>
                    <Button onClick={hideModal} icon='close' content='Close'/>
                </Segment>
            </div>
        );
    }
}

export default EventContentSubtitleTable;
