import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.email(values.email_address, 'email_address'));
    Object.assign(errors, validators.phoneNumber(values.phone_number_office, 'phone_number_office'));
    Object.assign(errors, validators.phoneNumber(values.phone_number_mobile, 'phone_number_mobile'));

    return errors;
};

export default validate;
