import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import {Popup} from "semantic-ui-react";

const LiveControlEncodingTimePopup = ({
    content,
    row: {
        encoding_end_datetime = null,
        encoding_end_margin = null,
        encoding_first_start_datetime = null,
        encoding_start_datetime = null,
        encoding_start_margin = null,
        event_content_end_datetime = null,
        event_content_start_datetime,
    },
    type
}) => {
    const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss",
        START = "start";

    let popupContent = '',
        additionalPopupContent = '';

    if (START === type) {
        let xMinutesBeforeString = (value) => ` (${value} minutes before the expected event content start time)`;

        additionalPopupContent = encoding_start_margin
            ? xMinutesBeforeString(encoding_start_margin)
            : '';

        popupContent = encoding_start_datetime
            ? `${encoding_start_datetime}${additionalPopupContent.replace(' expected', '')}`
            : encoding_start_margin
                ? `${
                    moment(event_content_start_datetime)
                        .subtract(encoding_start_margin, 'minutes')
                        .format(DATE_FORMAT)
                }${additionalPopupContent}`
                : '';

        if (null !== encoding_first_start_datetime && encoding_first_start_datetime !== encoding_start_datetime) {
            popupContent = [
                `First started at ${encoding_first_start_datetime}.`,
                popupContent
            ].map((el, i) => (<div key={i}>{el}</div>));
        }
    } else {
        let xMinutesAfterString = (value, hasEventEndTime) => (
            `${value} minutes after the ${hasEventEndTime ? 'event content end time' : 'end of the match'}`
        );

        additionalPopupContent = encoding_end_margin
            ? xMinutesAfterString(encoding_end_margin, event_content_end_datetime)
            : '';

        popupContent = encoding_end_datetime
            ? `${encoding_end_datetime}${additionalPopupContent ? ` (${additionalPopupContent})` : ''}`
            : event_content_end_datetime
                ? `${
                    moment(event_content_end_datetime).add(encoding_end_margin, 'minutes').format(DATE_FORMAT)
                }${additionalPopupContent ? ` (${additionalPopupContent})` : ''}`
                : additionalPopupContent
    }

    return (
        <Popup
            flowing
            hoverable
            trigger={content}
        >
            {popupContent}
        </Popup>
    );
};

LiveControlEncodingTimePopup.propTypes = {
    content: PropTypes.object.isRequired,
    row: PropTypes.shape({
        encoding_end_datetime: PropTypes.string,
        encoding_end_margin: PropTypes.number,
        encoding_first_start_datetime: PropTypes.string,
        encoding_start_datetime: PropTypes.string,
        encoding_start_margin: PropTypes.number,
        event_content_end_datetime: PropTypes.string,
        event_content_start_datetime: PropTypes.string.isRequired,
    }),
    type: PropTypes.string.isRequired,
};

export default LiveControlEncodingTimePopup;
