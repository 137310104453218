import PropTypes from 'prop-types';
import React from 'react';

import { ButtonAdd } from '@appComponents/ButtonCollection';
import Authorization from '@modules/app/components/Authorization';
import Link from '@modules/app/components/Link';
import {EVENT_BOOKING} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

const authorizationObject={
    [EVENT_BOOKING]: SECURITY_PRIVILEGES_CREATE,
};

const EventsEventBookingsButtons = ({eventId}) => {
    return (
        <div className='content__buttons'>
            <Authorization authorization={authorizationObject}>
                <Link name='events.eventBookings.add' params={{id: eventId}}>
                    <ButtonAdd>{'Add new event booking'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

EventsEventBookingsButtons.propTypes = {
    eventId: PropTypes.number,
};

export default EventsEventBookingsButtons;
