import React from 'react';
import {TableCell} from 'semantic-ui-react';
import {uniq as _uniq} from 'lodash';

import {sortByTextValue} from '@utils/sorters';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {BLACKOUT_ZONE} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';

export const generateBlackoutZonesRow = (blackoutZones, isExcelExport = false) => {
    const linkedBlackoutZones = [];

    sortByTextValue(blackoutZones, 'name').forEach((blackoutZone) => {
        const link = isExcelExport ? blackoutZone.name : (
            <LinkWithAuthorization
                authorization={{
                    [BLACKOUT_ZONE]: SECURITY_PRIVILEGES_READ,
                }}
                link={{
                    name: 'blackoutZones.edit.generalInformation',
                    params: {
                        id: blackoutZone.id,
                    },
                }}
                key={blackoutZone.id}
            >
                {blackoutZone.name}
            </LinkWithAuthorization>
        );

        // Here we can't use linkedBlackoutZones.join(', ') because the link is not string (jsx object with a link)
        linkedBlackoutZones.push(link);

        if (!isExcelExport) {
            linkedBlackoutZones.push(', ');
        }
    });

    if (0 === linkedBlackoutZones.length) {
        return isExcelExport ? '' : '-';
    }

    if (!isExcelExport && 0 < linkedBlackoutZones.length) {
        linkedBlackoutZones.pop();
    }

    return isExcelExport ?
        _uniq(linkedBlackoutZones).join(', ')
        : (
            <React.Fragment>
                {linkedBlackoutZones}
            </React.Fragment>
        );
};

export const getBlackoutZonesFromGeoRestrictions = (geoRestrictions) => {
    const blackoutZones = [];

    geoRestrictions.forEach((geoRestriction) => {
        if (!geoRestriction.blackout_zones) {
            return;
        }

        geoRestriction.blackout_zones.forEach((blackoutZone) => blackoutZones.push(blackoutZone));
    });

    return blackoutZones;
};

export const renderBlackoutZonesTableRowCell = (deviceCategories, shouldOpenNewTab = false) => {
    const generatedRows = [];
    let linksToRender = null,
        tableCellContent = null;

    deviceCategories.forEach((deviceCategory) => {
        const blackoutZoneLinks = [];

        sortByTextValue(deviceCategory.blackoutZones, 'name').forEach((blackoutZone) => {
            linksToRender = (
                <LinkWithAuthorization
                    newWindow={shouldOpenNewTab}
                    authorization={{
                        [BLACKOUT_ZONE]: SECURITY_PRIVILEGES_READ,
                    }}
                    link={{
                        name: 'blackoutZones.edit.generalInformation',
                        params: {
                            id: blackoutZone.id,
                        },
                    }}
                    key={blackoutZone.id}
                >
                    {blackoutZone.name}
                </LinkWithAuthorization>
            );

            // Here we can't use linkedBlackoutZones.join(', ') because the link is not string (jsx object with a link)
            blackoutZoneLinks.push(linksToRender);
            blackoutZoneLinks.push(', ');
        });

        if (0 < blackoutZoneLinks.length) {
            blackoutZoneLinks.pop();
        } else {
            blackoutZoneLinks.push('-');
        }

        tableCellContent = (
            <TableCell
                as='div'
                className='cell'
                key={`type_blackout_zones_${deviceCategory.id}`}
                content={blackoutZoneLinks}
            />
        );

        generatedRows.push(tableCellContent);
    });


    return (
        <React.Fragment>
            {generatedRows}
        </React.Fragment>
    );
};
