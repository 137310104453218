
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OverlayTableData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Overlay"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"overlay_type"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OverlayFormData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Overlay"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OverlayTableData"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChangelogData"},"directives":[]}]}}],"loc":{"start":0,"end":233}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment OverlayTableData on Overlay {\n    ...BasicData\n    overlay_type {\n        id\n        name\n    }\n    url\n}\n\nfragment OverlayFormData on Overlay {\n    ...OverlayTableData\n    ...ChangelogData\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
