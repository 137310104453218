import SlatesBaseModel from "./SlatesBaseModel";

export default function SlatesCreateModel() {
    const SlateBaseModel = new SlatesBaseModel();

    // In create mode field "Mute when enabled" should be checked by default
    SlateBaseModel.fields.mute_when_enabled.defaultValue = true;

    return SlateBaseModel;
}
