import PropTypes from "prop-types";

import {getYesOrNo} from "../../../../utils/helpers";

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'encoding_job_profiles',
    parseData: encodingJobProfile => [
        encodingJobProfile.id,
        encodingJobProfile.name,
        encodingJobProfile.product.short_name,
        encodingJobProfile.distribution_type.name,
        getYesOrNo(encodingJobProfile.is_default),
        (0 < encodingJobProfile.clients.length)
            ? encodingJobProfile.clients.map(client => client.name).join(', ')
            : 'All',
        getYesOrNo(encodingJobProfile.is_disabled),
    ],
    path: 'encodingJobProfile.index.path',
    titles: [
        "ID",
        "Name",
        "Product",
        "Distribution type",
        "Default?",
        "Clients",
        "Disabled?",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
