import React from 'react';
import PropTypes from 'prop-types';
import {Image} from "semantic-ui-react";

import {getHeaderTitleParameters} from "../../utils/EventsTabsLayout/eventsTabsLayoutHeader";

import {COMPETITOR_FLAG_LINK} from "../../../app/constants/variables";

/**
 * Header title on the top of main event page
 *
 * @param event Event main object
 */
const EventsTabsLayoutHeader = ({event}) => {
    const {title, competitorsIds} = getHeaderTitleParameters(event);

    if (null === competitorsIds) {
        return title;
    }

    return (
        <div className="--flex --alignCenter">
            {competitorsIds.homeCompetitorId &&
            <React.Fragment>
                <Image src={`${COMPETITOR_FLAG_LINK}${competitorsIds.homeCompetitorId}.png`}/>
                &nbsp;
            </React.Fragment>
            }
            {title}
            {competitorsIds.awayCompetitorId &&
            <React.Fragment>
                &nbsp;
                <Image src={`${COMPETITOR_FLAG_LINK}${competitorsIds.awayCompetitorId}.png`}/>
            </React.Fragment>
            }
        </div>
    )
};

EventsTabsLayoutHeader.propTypes = {
    event: PropTypes.object.isRequired
};

export default EventsTabsLayoutHeader;
