import {get as _get} from "lodash";

import {getSortingTerritories} from "../../../utils/countryHelpers";
import {convertToInt} from "../../../utils/helpers";

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "licensee": {
        label: "Licensee",
        sorting: row => _get(row, 'licensee.name', null)
    },
    "contents": {
        label: "Content"
    },
    "products": {
        label: "Products"
    },
    "start_date": {
        label: "From",
        sorting: true,
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "end_date": {
        label: "To",
        sorting: true,
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "territories": {
        label: "Territories",
        sorting: (row) => getSortingTerritories(row),
    },
    "exclusivity": {
        label: "Exclusivity",
        sorting: row => _get(row, 'rights_scope_exclusivity.name', null),
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "sub_licencing": {
        label: "Sub-licencing",
        sorting: row => _get(row, 'rights_scope_sub_licensing.name', null),
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "stream_constraints": {
        label: "Stream constraints",
        sorting: (row) => !!row.rights_scope_stream_constraints.length,
        content: {
            align: "center"
        },
        header: {
            align: "center"
        }
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};
