import React from "react";
import {
    get as _get,
    isEmpty as _isEmpty,
    isNumber as _isNumber
} from "lodash";

/**
 * @ignore
 */
export class ReduxComponent extends React.Component {
    parseValue(value, multiple) {
        if (!_isNumber(value) && _isEmpty(value)) {
            return ( multiple ? [] : "" )
        }

        return value
    }

    isEmpty = (val) => {
        return (!_isNumber(val) && _isEmpty(val))
    };

    /**
     * isValidateLive - allow display error message live instead of submitting or remove focus from field
     * @ignore
     */
    isError = (props) => {
        if (props.meta.touched || Boolean(props.isValidateLive)) {
            return !props.meta.valid && !props.loading
        }

        return !!props.errorText
    };

    /**
     * @ignore
     */
    renderErrorMessage = (props) => {
        let errorText = _get(props, "errorText",
            this.isError(props) ? props.meta.error : null);

        return <div className="error info">{errorText}</div>
    };

    /**
     * Get "labelFor" generated by element name
     * @param props
     * @returns {string}
     */
    getLabelFor = (props) => (
        undefined !== props.input ? props.input.name : ''
    );

    /**
     * Return ID for component
     * @param props
     * @returns {string}
     */
    getElementComponentId = (props) => (
        undefined !== props.id ? props.id : this.getLabelFor(props)
    );

    /**
     * In case the element types is "checkbox/toggle" and:
     * - description is not defined as object (component with icon hint) or there are options (label with multi-checkbox),
     * label is not clickable (clickable is desc)
     * - without description or description as component with icon as hint, label is clickable
     * Other element types - label is not clickable
     * @param props
     * @returns {boolean}
     */
    isLabelClickable = (props) => {
        let labelWithPointerTypeElement = ['checkbox', 'toggle'];

        if (!labelWithPointerTypeElement.includes(props.type)
            || (undefined !== props.options && props.options.length)
            || (undefined !== props.description && 'object' !== typeof (props.description))
        ) {
            return false;
        }

        return true;
    };

    /**
     * In case the element types checkbox/toggle and:
     * - description is not defined as object (component with icon hint), desc is clickable (label is not clickable)
     * - desc as component with icon as hint (desc icon not clickable, label clickable)
     * Other types - desc is not clickable
     * @param props
     * @returns {boolean}
     */
    isDescriptionClickable = (props) => {
        let labelWithPointerTypeElement = ['checkbox', 'toggle'];

        if (labelWithPointerTypeElement.includes(props.type)
            && undefined !== props.description
            && 'object' !== typeof (props.description)
        ) {
            return true;
        }

        return false;
    };

    /**
     * className for <label className="...">  element
     * @param props
     * @returns {{className: string}}
     */
    getLabelTagClassName = (props) => {
        let cursorPointer = (this.isLabelClickable(props) && !this.isDescriptionClickable(props)) ? '--withPointer' : '',
            errorClass = (props.required && this.isError(props)) ? 'error' : '',
            labelFor = this.getLabelFor(props);

        return {
            className: `label-${labelFor} ${errorClass} ${cursorPointer}`
        }
    };

    /**
     * If description is clickable, label should not be clickable and we don't need tag for=""
     * (clickable description is superior to the label)
     * @param props
     */
    getLabelTagFor = (props) => (
        (this.isDescriptionClickable(props)) ? {} : {htmlFor: this.getElementComponentId(props)}
    );

    /**
     * @ignore
     */
    renderLabel = (props) => {
        if (!props.label) {
            return '';
        }

        let spanRequired = '';

        if (props.required) {
            spanRequired = <span className="form-input-required">*</span>;
        }

        return <label {...this.getLabelTagFor(props)} {...this.getLabelTagClassName(props)} >{props.label}{spanRequired}</label>;
    };

    render() {
        return null
    }
}
