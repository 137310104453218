import EventMasterBookingGeoRestrictionsModel from "./EventMasterBookingGeoRestrictionsModel";

import * as MESSAGES from "../../../app/constants/messages";

import {GetEventMasterBookingForGeoRestrictionsForm} from "../../../../graphql/bookings/query.graphql";
import {UpdateMasterBooking, DeleteMasterBooking} from "../../../../graphql/bookings/mutation.graphql";

export default function EventMasterBookingGeoRestrictionsEditModel() {
    let EventMasterBookingGeoRestrictionsEditModel = EventMasterBookingGeoRestrictionsModel();

    EventMasterBookingGeoRestrictionsEditModel.deleteId = "masterBooking.id";
    EventMasterBookingGeoRestrictionsEditModel.editForm = true;
    EventMasterBookingGeoRestrictionsEditModel.messages.text = {
        ...MESSAGES,
        DELETE_CONFIRMATION: () => "Are you sure you want to delete this master booking?",
    };
    EventMasterBookingGeoRestrictionsEditModel.mutation = {
        updateEntity: UpdateMasterBooking,
        deleteEntity: DeleteMasterBooking,
    };
    EventMasterBookingGeoRestrictionsEditModel.query.getEntity = GetEventMasterBookingForGeoRestrictionsForm;

    return EventMasterBookingGeoRestrictionsEditModel;
}
