import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link"

import {SECURITY_PRIVILEGES_CREATE_READ} from "../../app/constants/variables";
import {LICENSEE} from "../../app/constants/resources";

const LicenseeIndexButtons = () => {
    const authorizationObject = {
        [LICENSEE]: SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="content__buttons">
            <Authorization authorization={authorizationObject}>
                <Link name="licensees.add">
                    <ButtonAdd>{"Add new licensee"}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

export default LicenseeIndexButtons;
