import {get as _get} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {getNullableStringOfNamesFromObject} from '@utils/tvChannels';

const TvChannels = (props) => {
    const tvChannelList = _get(props, props.path, []);
    const label = (1 < tvChannelList.length) ? 'TV Channels' : 'TV Channel';
    let sportHasTvChannel = false;

    if (props.data.match) {
        sportHasTvChannel = props.data.match?.tournament?.tournament_category?.sport?.has_tv_channel;
    } else if (props.data.event) {
        sportHasTvChannel = props.data.event?.property_licence?.sport?.has_tv_channel;
    }

    return sportHasTvChannel ? (
        <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label>{label}:</label>
            <span>{tvChannelList.length
                ? getNullableStringOfNamesFromObject(props, props.path)
                : '-'}</span>
        </div>
    ) : null;
};

TvChannels.propTypes = {
    data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export const TvChannelsCreate = (props) => (
    <TvChannels {...props} path={'data.match.tv_channels'}/>
);

export const TvChannelsEdit = (props) => (
    <TvChannels {...props} path={'data.event.sr_event.match.tv_channels'}/>
);
