import React from 'react';
import {Header, Icon, Dropdown} from 'semantic-ui-react';
import PropTypes from "prop-types";

import urls from '../../constants/urls';
import {withRouter} from "react-router";
import {getLink} from "../Link";

/**
 * @ignore
 */
function logout() {
    window.location.href = urls.authLogout;
}

const User = (props) => {
    if (!props.name) {
        return null;
    }

    const handleRedirection = (url) => {
        props.history.push(getLink(url));
    };

    const handleOnClick = () => {
        handleRedirection("users.settings")
    };

    return (
        <Header as="h4" className="page-header__widget page-header__widget--user">
            <Dropdown trigger={(
                <div>
                    <Icon.Group>
                        <Icon name="user circle" className="page-header__userIcon"/>
                    </Icon.Group>
                    {props.name}
                </div>
            )}>
                <Dropdown.Menu>
                    <Dropdown.Item text="User settings" icon="setting" onClick={handleOnClick}/>
                    <Dropdown.Item text="Log out" icon="sign out" onClick={logout}/>
                </Dropdown.Menu>
            </Dropdown>
        </Header>
    );
};

User.propTypes = {
    history: PropTypes.object,
    name: PropTypes.string,
};

export default withRouter(User);
