import PropTypes from "prop-types";

import {addHasMatchIdToStructure} from "../../../../../../utils/eventObject";
import {getFieldsList as getFieldsListColumn1} from "./fieldsList/column1";
import {getFieldsList as getFieldsListColumn2} from "./fieldsList/column2";
import {getFieldsList as getFieldsListColumn3} from "./fieldsList/column3";
import {getFieldsList as getFieldsListColumn4} from "./fieldsList/column4";
import {getFieldsList as getFieldsListColumn5} from "./fieldsList/column5";

export const eventFieldsList = (event) => {
    if (!event) {
        return [];
    }

    const eventData = addHasMatchIdToStructure(event),
        columns = [
            getFieldsListColumn1(eventData),
            getFieldsListColumn2(eventData),
            getFieldsListColumn3(eventData),
            getFieldsListColumn4(eventData),
        ];

    // Do not display last column if notes is empty to avoid unnecessary margin in "Event details" section
    if (null === event.notes) {
        return columns;
    }

    columns.push(getFieldsListColumn5(eventData));

    return columns;
};

eventFieldsList.propTypes = {
    event: PropTypes.object.isRequired
}
