import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.start_date, 'start_date'));
    Object.assign(errors, validators.required(values.end_date, 'end_date'));
    Object.assign(errors, validators.date(values.start_date, 'start_date'));
    Object.assign(errors, validators.date(values.end_date, 'end_date'));
    Object.assign(errors, validators.required(values.num_matches_agreed, 'num_matches_agreed'));
    Object.assign(errors, validators.required(values.num_matches_commitment, 'num_matches_commitment'));
    Object.assign(errors, validators.required(values.num_matches_budget, 'num_matches_budget'));
    Object.assign(errors, validators.digitsOnly(values.num_matches_agreed, 'num_matches_agreed'));
    Object.assign(errors, validators.digitsOnly(values.num_matches_commitment, 'num_matches_commitment'));
    Object.assign(errors, validators.digitsOnly(values.num_matches_budget, 'num_matches_budget'));

    if (false === values.includes_all_content) {
        Object.assign(errors, validators.required(values.contents, 'contents'));
    }

    if (new Date(values.start_date) > new Date(values.end_date)) {
        Object.assign(errors, {end_date: "End date must be later than the Start date"});
    }

    return errors;
};

export default validate;
