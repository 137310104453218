import React from 'react';
import {withRouter} from 'react-router';
import {
    find as _find,
    get as _get,
    has as _has,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    isUndefined as _isUndefined,
} from 'lodash';

import {gql, withApollo} from 'react-apollo';
import DefaultForm from '@appComponents/DefaultForm';
import RenderDeviceCategoriesField from '@appComponents/FormComponents/RenderDeviceCategoriesField';
import {convertToInt, filterDisabledEncodingJobProfiles} from '@utils/helpers';
import {
    CDN_AKAMAI,
    CDN_AKAMAI_2,
    CLIENT_SELECTION_TYPE_BLACKLIST,
    CLIENT_SELECTION_TYPE_WHITELIST,
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_RECORDING,
    DISTRIBUTION_TYPE_VOD,
    ENCODER_TYPE_MANUAL,
    ENCODER_TYPE_SPORTRADAR,
    INGEST_METHOD_DISTRIBUTION_TYPES,
    ORIGIN_STREAM_TYPE_MEDIACONNECT,
    ORIGIN_STREAM_TYPE_SRT,
    PRODUCT_LCI,
    ORIGIN_STREAM_TYPE_UDP,
    PRODUCT_LCT,
} from '@constants/variables';
import {getEncodingTargetOptions, getEncodingTargetsOfDistributions} from '@graphql/events/encodingTarget';
import {GetSlatesForDropdown} from '@graphql/slate/query';
import {GetEventContentEncodingTargetOverlaysForDropdown} from '@graphql/overlay/query'
import {IconHint} from '@appComponents/IconCollection';
import {getEnabledOrAssignedOptions} from '@utils/dropdownOptionParser';

import {
    prepareDataForCreate,
    prepareDataForUpdate,
    prepareDistributionOptions,
    prepareOptions,
} from './logic/EventContentTargetForm';
import {DEFAULT_TARGET_FIELD_HINT} from '../constants/messages';

class EventContentTargetForm extends DefaultForm {
    cdnId = null;
    distribution = {};
    distributionTypeId = null;
    encodingTarget = {};
    encoderTypeId = null;
    hasInput = false;
    mediaconnectOriginFeedsHasCc = false;
    udpOriginEntryPointHasCc = false;
    originStreamTypeId = null;
    propertyLicenceTechSetup = {};
    product = {};
    streamLanguages = [];

    constructor(props) {
        super(props);

        this.state = Object.assign(this.state, {
            edit: false,
            isEncodingJobProfilesLoaded: false,
        });
    }

    handleWithEditForm = () => {
        this.setField('id', {
            defaultValue: this.props.formParams.id,
        });

        if (this.encodingTarget.distribution) {
            this.distribution = this.encodingTarget.distribution;
            this.distributionTypeId = convertToInt(this.distribution.distribution_type.id);
            this.setField('distribution', {
                defaultValue: _get(this.encodingTarget, 'distribution.id', null),
            });

            this.setField('distribution_type', {
                defaultValue: this.distributionTypeId,
            });
        }

        this.setField('is_default', {
            defaultValue: this.encodingTarget.is_default,
        });
        this.setField('is_computer_vision', {defaultValue: this.encodingTarget.is_computer_vision});
        this.setField('has_playback_authentication', {
            checked: this.encodingTarget.has_playback_authentication,
            defaultValue: this.encodingTarget.has_playback_authentication,
        });

        this.presetFieldsDependsOnDistributionType();
        this.setFieldsDependsOnDistribution();
    };

    presetFieldsDependsOnDistributionType() {
        /**
         * Display/mandatory only if the distribution type is "Live" or "Recording". Save NULL otherwise.
         */
        this.setField('stream_delay_type', {
            hidden: !(
                this.distributionTypeId === DISTRIBUTION_TYPE_LIVE
                || this.distributionTypeId === DISTRIBUTION_TYPE_RECORDING
            ),
            loading: (
                this.props.formParams.isAddForm
                && (
                    this.distributionTypeId === DISTRIBUTION_TYPE_LIVE
                    || this.distributionTypeId === DISTRIBUTION_TYPE_RECORDING
                )
            ),
            options: [],
        });

        const isDefaultFieldProps = {
            hidden: false,
            disabled: false,
        };

        if (this.distributionTypeId === DISTRIBUTION_TYPE_VOD) {
            isDefaultFieldProps.hidden = true;
            isDefaultFieldProps.disabled = true;
        }

        this.setField('is_default', isDefaultFieldProps);

        this.setField('device_categories', {
            message: 'Device categories list is loading...',
        });
    }

    setFieldsDependsOnDistribution() {
        this.setState(() => ({
            defaultForm_loading: true,
            isEncodingJobProfilesLoaded: false,
        }));

        const distributionId = convertToInt(this.distribution.id);
        const productId = convertToInt(this.distribution.product.id);

        /**
         * Get options based on distribution
         */
        this.runApolloRequest('query', {
            query: gql(getEncodingTargetOptions),
            fetchPolicy: 'network-only',
            variables: {
                eventId: this.props.formParams.optionsVariables.eventId,
                distributionId: distributionId,
                productId: [productId],
                distributionTypeId: [this.distributionTypeId],
            },
        }).then((response) => {
            this.setState(() => ({
                isEncodingJobProfilesLoaded: true,
            }));

            this.product = Object.assign({}, response.data.encodingTargetOptions.product);

            if (this.distributionTypeId === DISTRIBUTION_TYPE_VOD) {
                this.encoderTypeId = ENCODER_TYPE_MANUAL;
            }

            if (this.props.formParams.isAddForm) {
                this.encoderTypeId = convertToInt(_get(this.propertyLicenceTechSetup, 'encoder_type.id', null));

                if (this.distributionTypeId === DISTRIBUTION_TYPE_VOD) {
                    this.encoderTypeId = ENCODER_TYPE_MANUAL;
                }

                this.encoderTypeDependencies();
                this.setCdnsField();
                this.setIngestMethod();
                this.setAkamaiCdnIngestMethodField();

                /**
                 * Display/mandatory only if the distribution type is "Live" or "Recording". Save NULL otherwise.
                 * List only the stream delay types of the selected product (relations in "product_stream_delay_type").
                 * List only the stream delay types which have media-rights for the property licence of the event:
                 * i.e. at least one right scope which satisfies the following conditions:
                 *
                 * Same as for "Device categories" input plus:
                 *     For "Live" and "Premium" stream delay types, the way of transmission "Live" is needed.
                 *     For "Delayed" stream delay type, the way of transmission "Delayed" is needed.
                 *
                 * Select by default the stream delay type with "is_default = 1" in the table "product_stream_delay_type".
                 */
                if (
                    this.distributionTypeId === DISTRIBUTION_TYPE_LIVE
                    || this.distributionTypeId === DISTRIBUTION_TYPE_RECORDING
                ) {
                    const productDefultStreamDelayType = _find(response.data.encodingTargetOptions.product_stream_delay_types, (item) => {
                        return item.is_default;
                    });

                    this.setField('stream_delay_type', {
                        loading: false,
                        options: response.data.encodingTargetOptions.product_stream_delay_types.map(
                            (data) => {
                                return prepareOptions(data.stream_delay_type);
                            }
                        ),
                        defaultValue: productDefultStreamDelayType?.stream_delay_type?.id
                    });
                }

                this.setTokenTtl(productId, this.distributionTypeId, this.props.formValues.has_playback_authentication);
            } else {
                this.setCdnsField();
                this.setIngestMethod();
                this.setAkamaiCdnIngestMethodField();
                this.encoderTypeDependencies();

                const streamDelayTypeObject = _get(this.encodingTarget, 'stream_delay_type', null);
                let options = [];

                if (streamDelayTypeObject) {
                    options = [{
                        id: streamDelayTypeObject.id,
                        key: streamDelayTypeObject.id,
                        text: streamDelayTypeObject.name,
                        value: streamDelayTypeObject.id,
                    }];
                }

                this.setField('stream_delay_type', {
                    options,
                    defaultValue: _get(streamDelayTypeObject, 'id', null),
                });
            }

            /**
             * Display only if "Encoders" is "Sportradar"
             *  and if the selected product has support for scoreboard overlay
             *  (product.has_scoreboard_overlay_support).
             */
            this.setScoreboardOverlaySupportField();

            /**
             * Display only if "Encoders" is "Sportradar"
             *  and if the selected product has support for slate
             *  (product.has_slate_support).
             */
            this.setSlateSupportField();

            this.setOverlaySupportField();

            /**
             * Display only if "Encoders" is "Sportradar"
             * and if the selected product has support for ad
             * (product.has_ad_support).
             */
            this.setAdSupportField();

            /**
             * Display only if "Encoders" is "Sportradar"
             * and if the selected product has support for DRM
             * (product.has_drm_support).
             */
            this.setDrmSupportField();

            this.setClientDrmSetupOptions();

            /**
             * Display only if "Encoders" is "Sportradar"
             * and if the selected product has support for DRM
             * (product.has_cc_support).
             */
            this.setCloseCaptionFields();

            /**
             * Display only if "Encoders" is "Sportradar"
             *  and if the selected product has support for Akamai RTMP distribution regions
             *  (product.has_akamai_rtmp_distr_region_support).
             */
            this.setAkamaiRtmpDistributionRegionsField();

            /**
             * Display only the device categories of the selected product
             * (relations in "product_device_category").
             *
             * In add mode, pre-select the ones with "product_device_category.is_default" = 1.
             *
             * Display only the device categories which have media-rights for the property licence of the event:
             * i.e. at least one right scope which satisfies the following conditions:
             * Includes the content (tournament or 3rd level content category) of the event.
             * Has at least one usage for the given product / device category / distribution type.
             * For "Live" distribution type, the way of transmission "Live" or "Delayed" is needed.
             * For "Recording" distribution type, any way of transmission is needed (but at least one).
             * For "VOD" distribution type, the way of transmission "On demand" is needed.
             * Date of the event is included in the date range of the right scope.
             */
            const productDefaultDeviceCategory = [];

            if (this.props.formParams.isAddForm) {
                response.data.encodingTargetOptions.product_device_categories.forEach((item) => {
                    if (item.is_default) productDefaultDeviceCategory.push(convertToInt(item.device_category.id));
                });
            } else {
                this.encodingTarget.device_categories.forEach((item) => {
                    productDefaultDeviceCategory.push(convertToInt(item.id));
                });
            }

            if (!response.data.encodingTargetOptions.product_device_categories.length) {
                this.setFormMessage(
                    'There\'s not overlapping Media Rights for current Event Content!',
                    'formInnerErrorMessage'
                );
            }

            this.setField('device_categories', {
                component: RenderDeviceCategoriesField,
                deviceCategories: response.data.encodingTargetOptions.product_device_categories.map(
                    (data) => {
                        return prepareOptions(data.device_category);
                    }
                ),
                defaultValue: productDefaultDeviceCategory,
            });

            /**
             * List only the encoding job profiles for the selected product and distribution type
             *  (filter on "encoding_job_profile.product_id" and "encoding_job_profile.distribution_type_id").
             *
             * In add mode, pre-select the default encoding job profile for the selected product
             *  and distribution type (encoding_job_profile.is_default).
             */
            const encodingJobProfile = this.props.formParams.isAddForm
                ? _find(response.data.encodingJobProfiles, (item) => {
                    return !item.is_disabled && item.is_default;
                })
                : {value: _get(this.encodingTarget, 'encoding_target_sr.encoding_job_profile.id')};

            this.setField('encoding_job_profile', {
                loading: false,
                options: filterDisabledEncodingJobProfiles(
                    response.data.encodingJobProfiles,
                    _get(encodingJobProfile, 'value', null)
                ),
                defaultValue: _get(encodingJobProfile, 'value', null),
            });

            this.setCdnsField();

            const streamNamePrefix = (this.props.formParams.isAddForm)
                ? response.data.encodingTargetOptions.stream_name_prefix
                : _get(this.encodingTarget, 'encoding_target_sr.stream_name_prefix', null);

            /**
             * In add mode, pre-fill with <sportName>_<tournamentCategoryName>_<tournamentName> for Sportradar events
             * and <1stLevelContentCategoryName>_<2ndLevelContentCategoryName>_<3rdLevelContentCategoryName> for non-Sportradar events.
             * Only letters, numbers and underscores authorized, only lower-case. Remove other characters (like in BCMS).
             */
            this.setField('stream_name_prefix', {
                loading: response.data.encodingTargetOptions.stream_name_prefix && false,
                defaultValue: (!this.encoderTypeId || ENCODER_TYPE_SPORTRADAR === this.encoderTypeId)
                    ? streamNamePrefix
                    : null,
            });

            let encoderTypeOptions;

            if (this.distributionTypeId === DISTRIBUTION_TYPE_VOD) {
                encoderTypeOptions = [{
                    key: '1',
                    value: ENCODER_TYPE_MANUAL + '',
                    text: 'Manual',
                }];
            } else {
                encoderTypeOptions = this.props.GraphQLOptionsData.encoderTypes;
            }

            /**
             * Display/mandatory only if the distribution type is not "VOD".
             * Save NULL otherwise.
             *
             * In add mode, pre-select the encoder type from the property licence tech setup
             * (property_licence_tech_setup.encoder_type_id via event.property_licence_id).
             * Depending on the selection, show/hide inputs below.
             *
             * If "Sportradar" is selected, save a row in "encoding_target_sr"
             * and delete potential existing row in "encoding_target_manual".
             *
             * If "Manual" is selected, save a row in "encoding_target_manual"
             * and delete potential existing row in "encoding_target_sr".
             */
            this.setField('encoder_type', {
                defaultValue: this.encoderTypeId && this.encoderTypeId.toString(),
                options: encoderTypeOptions
            });

            this.setState(() => ({
                defaultForm_loading: false
            }));

            this.setField('encoding_job_profile', {
                loading: false,
            });
        });

        /**
         * Display/mandatory only if the distribution type is "Live" or "Recording". Save NULL otherwise.
         * In add mode, pre-fill with 0.
         */
        const showDelayInput = (this.distributionTypeId === DISTRIBUTION_TYPE_LIVE
            || this.distributionTypeId === DISTRIBUTION_TYPE_RECORDING);

        this.setField('delay', {
            hidden: !showDelayInput,
            required: showDelayInput,
            defaultValue: (this.props.formParams.isAddForm) ? '0' : this.encodingTarget.delay,
        });

        this.setField('is_computer_vision', {
            hidden: !(PRODUCT_LCI === productId && DISTRIBUTION_TYPE_LIVE === this.distributionTypeId),
        });
        this.setField('has_playback_authentication', {
            checked: (this.props.formParams.isAddForm) ? true : this.encodingTarget.has_playback_authentication,
            defaultValue: (this.props.formParams.isAddForm) ? true : this.encodingTarget.has_playback_authentication,
            hidden: DISTRIBUTION_TYPE_LIVE !== this.distributionTypeId,
        });

        this.setTokenTtl(productId, this.distributionTypeId, this.encodingTarget.has_playback_authentication);
    }

    setClientDrmSetupOptions = () => {
        const clientSelectionType = convertToInt(this.distribution.client_selection_type.id),
            clientIds = this.distribution.clients.map((client) => (client.id));

        let clientDrmSetups = this.props.GraphQLOptionsData.clientDrmSetups;

        if (CLIENT_SELECTION_TYPE_WHITELIST === clientSelectionType) {
            clientDrmSetups = clientDrmSetups.filter((clientDrmSetup) => (
                clientIds.includes(clientDrmSetup.client.id)
            ));
        } else if (CLIENT_SELECTION_TYPE_BLACKLIST === clientSelectionType) {
            clientDrmSetups = clientDrmSetups.filter((clientDrmSetup) => (
                !clientIds.includes(clientDrmSetup.client.id)
            ));
        }

        this.setField('client_drm_setups', {
            options: clientDrmSetups.map((clientDrmSetup) => ({
                key: convertToInt(clientDrmSetup.id),
                text: clientDrmSetup.client.name,
                value: convertToInt(clientDrmSetup.id),
            })).sort((a, b) => (
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            )),
        });
    };

    componentWillMount() {
        super.componentWillMount();

        this.setValuesParsers({
            'akamai_rtmp_distr_regions': (data = []) => {
                const values = [];

                data && data.forEach((option) => {
                    values.push(convertToInt(option.id));
                });

                return values;
            },
            'client_drm_setups': (data = []) => {
                const values = [];

                data && data.forEach((option) => {
                    values.push(convertToInt(option.id));
                });

                return values;
            },
        });

        this.setOnChangeCallback({
            distribution: (params) => {
                this.distribution = _find(this.props.GraphQLOptionsData.distributions, {id: params.value});
                this.distributionTypeId = convertToInt(this.distribution.distribution_type.id);

                const distributionId = convertToInt(params.value);

                this.setField('distribution_type', {
                    defaultValue: this.distributionTypeId,
                });

                this.presetFieldsDependsOnDistributionType();
                this.setFieldsDependsOnDistribution();

                this.setField('stream_delay_type', {
                    defaultValue: null,
                    timestamp: Date.now(),
                });

                this.setField('encoder_type', {
                    disabled: false,
                });

                if (this.props.formParams.isAddForm) {
                    /**
                     * Selected by default in add mode if there is no other encoding target for the same distribution.
                     * Otherwise, not selected by default in add mode.
                     * If selected, save 0 for all other encoding targets of the distribution for the given event content.
                     */
                    this.setField('is_default', {
                        description: 'Checking...',
                    });

                    this.runApolloRequest('query', {
                        query: gql(getEncodingTargetsOfDistributions),
                        fetchPolicy: 'network-only',
                        variables: {
                            distributionIds: [distributionId]
                        }
                    }).then((response) => {
                        this.setField('is_default', {
                            defaultValue: (_isEmpty(response.data.encodingTargets)),
                            description: <IconHint tooltip={DEFAULT_TARGET_FIELD_HINT}/>,
                        });
                    });
                }
            },
            has_playback_authentication: (params) => {
                this.setField('has_playback_authentication', {
                    checked: !params.value,
                    defaultValue: !params.value,
                });

                this.setTokenTtl(this.distribution.product.id, this.distributionTypeId, !params.value);
            },
            has_scoreboard_overlay_support: (params) => {
                const hasScoreboardOverlaySupport = !params.value;

                this.setField('has_scoreboard_overlay_support', {
                    checked: hasScoreboardOverlaySupport,
                    defaultValue: hasScoreboardOverlaySupport,
                });

                /**
                 * Fields depend on has_scoreboard_overlay_support field
                 */
                this.setHasScorebordOverlayField(hasScoreboardOverlaySupport);
                this.setScoreboardOverlayPosition(hasScoreboardOverlaySupport);

            },

            has_slate_support: (params) => {
                const hasSlateSupport = !params.value;

                this.setField('has_slate_support', {
                    checked: hasSlateSupport,
                    defaultValue: hasSlateSupport,
                });

                this.setField('slate', {
                    disabled: !hasSlateSupport,
                    hidden: !hasSlateSupport,
                });

                this.addSlatesForField(this.propertyLicenceTechSetup.id)
            },
            has_overlay_support: (params) => {
                const hasOverlaySupport = !params.value;

                this.setField("has_overlay_support", {
                    checked: hasOverlaySupport,
                    defaultValue: hasOverlaySupport
                });

                /**
                 * Fields depend on has_overlay_support field
                 */
                this.addOverlaysForField(this.propertyLicenceTechSetup.id);
                this.setStartEncodingWithOverlay(hasOverlaySupport);
                this.setOverlay(hasOverlaySupport);
            },
            has_ad_support: (params) => {
                const hasAdSupport = !params.value;

                this.setField('has_ad_support', {
                    checked: hasAdSupport,
                    defaultValue: hasAdSupport,
                });

                this.setField('ad_type', {
                    disabled: !hasAdSupport,
                    hidden: !hasAdSupport,
                });

                this.addAdTypesForField();
            },
            has_drm_support: (params) => {
                const hasDrmSupport = !params.value;

                this.setField('has_drm_support', {
                    checked: hasDrmSupport,
                    defaultValue: hasDrmSupport,
                });

                this.setField('client_drm_setups', {
                    disabled: !hasDrmSupport,
                    hidden: !hasDrmSupport,
                });

                this.addClientDrmSetupsForField();
            },
            has_cc: (params) => {
                const hasCc = !params.value;

                this.setField('has_cc', {
                    checked: hasCc,
                    defaultValue: hasCc,
                });

                this.setField('cc_stream_language', {
                    disabled: !hasCc,
                    hidden: !hasCc,
                });
            },
        });
    }

    addSlatesForField = (propertyLicenceTechSetupId = null) => {
        if (!propertyLicenceTechSetupId || this.state.slatesListReceived) {
            return;
        }

        this.setField('slate', {
            loading: true,
            disabled: true,
            hidden: false,
        });

        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: GetSlatesForDropdown,
            variables: {
                propertyLicenceTechSetup: convertToInt(propertyLicenceTechSetupId),
            },
        }).then((results) => {
            this.setState(() => ({
                slatesListReceived: true,
            }));

            this.setField('slate', {
                options: results.data.slates,
                loading: false,
                disabled: false,
                defaultValue: this.props.formParams.isAddForm
                    ? _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.slate.id', null)
                    : _get(this.encodingTarget, 'encoding_target_sr.slate.id', null),
            });
        });
    };

    addOverlaysForField = (propertyLicenceTechSetupId = null) => {
        if (!propertyLicenceTechSetupId || this.state.overlaysListReceived) {
            return;
        }

        this.setField('overlay', {
            loading: true,
            disabled: true,
            hidden: false,
        });

        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: GetEventContentEncodingTargetOverlaysForDropdown,
            variables: {
                propertyLicenceTechSetup: convertToInt(propertyLicenceTechSetupId),
            },
        }).then((results) => {
            this.setState(() => ({
                overlaysListReceived: true,
            }));

            this.setField('overlay', {
                options: results.data.overlays,
                loading: false,
                disabled: false,
                defaultValue: this.props.formParams.isAddForm
                    ? _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.overlay.id', null)
                    : _get(this.encodingTarget, 'encoding_target_sr.overlay.id', null),
            });
        });
    };

    addAdTypesForField = () => {
        this.setField('ad_type', {
            defaultValue: this.props.formParams.isAddForm
                ? _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.ad_type.id', null)
                : _get(this.encodingTarget, 'encoding_target_sr.ad_type.id', null),
            disabled: false,
            loading: false,
        });
    };

    addClientDrmSetupsForField = () => {
        const clientDrmSetups = this.props.formParams.isAddForm
            ? _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.client_drm_setups', [])
            : _get(this.encodingTarget, 'encoding_target_sr.client_drm_setups', []);

        this.setField('client_drm_setups', {
            defaultValue: clientDrmSetups.map((clientDrmSetup) => (convertToInt(clientDrmSetup.id))),
            disabled: false,
            loading: false,
        });
    };

    /**
     * Display only if "Encoders" is "Manual" or if distribution type is "VOD".
     *
     * In add mode, set the value based on the property licence tech setup
     * (property_licence_tech_setup.playback_path via event.property_licence_id).
     */
    setPlaybackPathField() {
        this.setField('playback_path', {
            hidden: (this.encoderTypeId === ENCODER_TYPE_SPORTRADAR && this.distributionTypeId !== DISTRIBUTION_TYPE_VOD),
            defaultValue: (this.props.formParams.isAddForm)
                ? (_has(this.propertyLicenceTechSetup, 'property_licence_tech_setup_manual.playback_path'))
                    ? this.propertyLicenceTechSetup.property_licence_tech_setup_manual.playback_path
                    : null
                : _get(this.encodingTarget, 'encoding_target_manual.playback_path', null),
        });
    }

    /**
     * Display only if "Encoders" is "Sportradar".
     * No selection should show "- Default -" and save NULL.
     */
    setLanguagesField() {
        this.setField('language', {
            hidden: (this.encoderTypeId === ENCODER_TYPE_MANUAL),
            defaultValue: this.props.formParams.isAddForm
                ? null
                : _get(this.encodingTarget, 'encoding_target_sr.language.id', null),
        });
    }

    /**
     * Display/mandatory only if "Encoders" is "Sportradar".
     */
    setEncodingJobProfilesField() {
        const isHidden = this.encoderTypeId === ENCODER_TYPE_MANUAL;

        this.setField('encoding_job_profile', {
            loading: !this.state.isEncodingJobProfilesLoaded && !isHidden,
            hidden: isHidden,
            required: !isHidden,
        });
    }

    /**
     * Display/mandatory only if the distribution type is "Live".
     * Save NULL if it is not "Live".
     */
    setCdnsField() {
        const isHidden = DISTRIBUTION_TYPE_LIVE !== this.distributionTypeId;

        this.setField('cdn', {
            loading: (this.props.formValues.cdn === undefined) && !isHidden,
            hidden: isHidden,
            required: !isHidden,
            defaultValue: !this.props.formParams.isAddForm
                ? this.encodingTarget && _get(this.encodingTarget, 'cdn.id', null)
                : this.product.cdn.id,
        });
    }

    setTokenTtl(productId, distributionTypeId, hasPlaybackAuthentication = false) {
        const isHidden = PRODUCT_LCT !== convertToInt(productId)
            || DISTRIBUTION_TYPE_LIVE !== convertToInt(distributionTypeId)
            || !hasPlaybackAuthentication,
            defaultValue = !isHidden && _get(this.encodingTarget, 'token_ttl', null);

        this.setField('token_ttl', {
            hidden: isHidden,
            defaultValue: defaultValue,
        });
    }

    setIngestMethod() {
        if (!INGEST_METHOD_DISTRIBUTION_TYPES.includes(this.distributionTypeId)) {
            this.setField('ingest_method', {
                hidden: true,
            });

            return;
        }

        const ingestMethods = _get(this.props, 'GraphQLOptionsData.ingestMethods', []);
        let selectedIngestMethod;

        if (this.props.formParams.isAddForm) {
            const getEnabledIngestMethod = (ingestMethodsOptions, ingestMethodId) => {
                if (!ingestMethodId) {
                    return;
                }

                const notDisabledIngestMethod = _find(ingestMethodsOptions, (ingestMethodOption) => (
                    ingestMethodOption.id === ingestMethodId && false === ingestMethodOption.is_disabled
                ));

                return notDisabledIngestMethod ? ingestMethodId : null;
            };
            selectedIngestMethod = this.distributionTypeId === DISTRIBUTION_TYPE_LIVE
                ? getEnabledIngestMethod(ingestMethods, _get(this.product, 'live_ingest_method.id', null))
                : getEnabledIngestMethod(ingestMethods, _get(this.product, 'recording_ingest_method.id', null));
        } else {
            selectedIngestMethod = _get(this.encodingTarget, 'ingest_method.id', null);
        }

        this.setField('ingest_method', {
            hidden: false,
            options: getEnabledOrAssignedOptions(ingestMethods, selectedIngestMethod),
            defaultValue: selectedIngestMethod,
        });
    }

    /**
     * Display/mandatory only if the distribution type is "Live"
     * and if "CDN" is "Akamai".
     * Save NULL otherwise.
     *
     * In add mode, pre-select the Akamai CDN ingest method of the selected product
     * (product.akamai_cdn_ingest_method_id).
     */
    setAkamaiCdnIngestMethodField(toggleOnly = false) {
        const isAkamai = [CDN_AKAMAI, CDN_AKAMAI_2].includes(this.cdnId);

        this.setField('akamai_cdn_ingest_method', {
            hidden: DISTRIBUTION_TYPE_LIVE !== this.distributionTypeId || !isAkamai,
            required: (this.distributionTypeId === DISTRIBUTION_TYPE_LIVE && isAkamai),
        });

        if (!toggleOnly) {
            this.setField('akamai_cdn_ingest_method', {
                defaultValue: this.props.formParams.isAddForm
                    ? this.product.akamai_cdn_ingest_method && this.product.akamai_cdn_ingest_method.id
                    : this.encodingTarget && _get(this.encodingTarget, 'akamai_cdn_ingest_method.id', null)
            });
        }

        this.setAkamaiRtmpDistributionRegionsField(toggleOnly);
    }

    /**
     * Display/mandatory only if "Encoders" is "Sportradar".
     */
    setStreamNamePrefixField() {
        const isHidden = this.encoderTypeId === ENCODER_TYPE_MANUAL;

        this.setField('stream_name_prefix', {
            loading: (this.props.formValues.stream_name_prefix === undefined) && !isHidden,
            hidden: isHidden,
        });
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     *  and if the selected product has support for scoreboard overlay
     *  (product.has_scoreboard_overlay_support).
     * Save 0 if it has no support.
     *
     *  In add mode, set the status based on the property licence tech setup
     *  (property_licence_tech_setup.has_scoreboard_overlay_support via event.property_licence_id).
     */
    setScoreboardOverlaySupportField() {
        let defaultValue =
            _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.has_scoreboard_overlay_support', false);

        if (!this.props.formParams.isAddForm) {
            defaultValue = _get(this.encodingTarget, 'encoding_target_sr.has_scoreboard_overlay_support', false);
        }

        const isHidden = (this.encoderTypeId === ENCODER_TYPE_MANUAL || !this.product.has_scoreboard_overlay_support);
        const isChecked = !isHidden && defaultValue;

        this.setField('has_scoreboard_overlay_support', {
            hidden: isHidden,
            checked: isChecked,
            defaultValue: isChecked,
        });

        this.setHasScorebordOverlayField(isChecked);
        this.setScoreboardOverlayPosition(isChecked);
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     *  and if "Scoreboard overlay support" is switched on.
     *  Save NULL if it is switched off.
     *
     *  In add mode, set the status based on the property licence tech setup
     *  (property_licence_tech_setup.start_encoding_with_scoreboard_overlay via event.property_licence_id).
     */
    setHasScorebordOverlayField(hasScoreboardOverlaySupport = false) {
        this.setField('has_scoreboard_overlay', {
            hidden: (this.encoderTypeId === ENCODER_TYPE_MANUAL || !hasScoreboardOverlaySupport),
            defaultValue: (this.encoderTypeId === ENCODER_TYPE_SPORTRADAR && hasScoreboardOverlaySupport)
                ? this.props.formParams.isAddForm
                    ? _get(
                        this.propertyLicenceTechSetup,
                        'property_licence_tech_setup_sr.start_encoding_with_scoreboard_overlay',
                        false
                    )
                    : _get(this.encodingTarget, 'encoding_target_sr.has_scoreboard_overlay', false)
                : false
        });
    }

    /**
     * Display/mandatory only if "Encoders" is "Sportradar"
     *  and if "Scoreboard overlay support" is switched on.
     *  Save NULL if it is switched off.
     *
     *  In add mode, set the value based on the property licence tech setup
     *  (property_licence_tech_setup.scoreboard_overlay_position_id via event.property_licence_id).
     */
    setScoreboardOverlayPosition(hasScoreboardOverlaySupport = false) {
        this.setField('scoreboard_overlay_position', {
            hidden: (this.encoderTypeId === ENCODER_TYPE_MANUAL || !hasScoreboardOverlaySupport),
            required: (this.encoderTypeId === ENCODER_TYPE_SPORTRADAR && hasScoreboardOverlaySupport),
            defaultValue: (this.encoderTypeId === ENCODER_TYPE_SPORTRADAR && hasScoreboardOverlaySupport)
                ? this.props.formParams.isAddForm
                    ? _get(this.propertyLicenceTechSetup, `property_licence_tech_setup_sr.scoreboard_overlay_position.id`, null)
                    : _get(this.encodingTarget, `encoding_target_sr.scoreboard_overlay_position.id`, null)
                : null
        });
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     *  and if the selected product has support for slate (product.has_slate_support).
     * Save 0 if it has no support.
     *
     *  In add mode, set the status based on the property licence tech setup
     *  (property_licence_tech_setup.has_slate_support via event.property_licence_id).
     */
    setSlateSupportField() {
        let defaultValue =
            _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.has_slate_support', false);

        if (!this.props.formParams.isAddForm) {
            defaultValue = _get(this.encodingTarget, 'encoding_target_sr.has_slate_support', false);
        }

        const isHidden = (this.encoderTypeId === ENCODER_TYPE_MANUAL
            || !this.product.has_slate_support);
        const isChecked = !isHidden
            && defaultValue;

        if (defaultValue) {
            this.addSlatesForField(_get(this.propertyLicenceTechSetup, 'id', null));
        }

        this.setField('has_slate_support', {
            hidden: isHidden,
            checked: isChecked,
            defaultValue: isChecked,
        });

        this.setField('slate', {
            hidden: !isChecked
        });
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     *  and if the selected product has support for scoreboard overlay
     *  (product.has_overlay_support).
     * Save 0 if it has no support.
     *
     *  In add mode, set the status based on the property licence tech setup
     *  (property_licence_tech_setup.has_overlay_support via event.property_licence_id).
     */
    setOverlaySupportField() {
        let hasOverlaySupport =
            _get(this.propertyLicenceTechSetup, "property_licence_tech_setup_sr.has_overlay_support", false);

        if (!this.props.formParams.isAddForm) {
            hasOverlaySupport = _get(this.encodingTarget, "encoding_target_sr.has_overlay_support", false);
        }

        const isHidden = (this.encoderTypeId === ENCODER_TYPE_MANUAL || !this.product.has_overlay_support);
        const defaultValue = !isHidden && hasOverlaySupport;

        if (hasOverlaySupport) {
            this.addOverlaysForField(_get(this.propertyLicenceTechSetup, 'id', null));
        }

        this.setField("has_overlay_support", {
            hidden: isHidden,
            checked: defaultValue,
            defaultValue: defaultValue,
        });

        this.setStartEncodingWithOverlay();
        this.setOverlay(defaultValue);
    }

    setStartEncodingWithOverlay(hasOverlaySupport) {
        if (_isUndefined(hasOverlaySupport)) {
            this.setField("start_encoding_with_overlay", {
                defaultValue: this.props.formParams.isAddForm
                    ? _get(this.propertyLicenceTechSetup, "property_licence_tech_setup_sr.start_encoding_with_overlay", false)
                    : _get(this.encodingTarget, "encoding_target_sr.has_overlay", false),
            });
        } else {
            this.setField("start_encoding_with_overlay", {
                defaultValue: hasOverlaySupport,
            });
        }
    }

    /**
     * Display/mandatory only if "Encoders" is "Sportradar"
     *  and if "Scoreboard overlay support" is switched on.
     *  Save NULL if it is switched off.
     *
     *  In add mode, set the value based on the property licence tech setup
     *  (property_licence_tech_setup.overlay_id via event.property_licence_id).
     */
    setOverlay(hasOverlaySupport = false) {
        const isVisible = this.encoderTypeId === ENCODER_TYPE_SPORTRADAR && hasOverlaySupport;

        if (!isVisible) {
            this.setField("overlay", {
                hidden: true,
                defaultValue: false,
                required: false,
            });

            return;
        }

        this.setField("overlay", {
            hidden: false,
            required: true,
            defaultValue: this.props.formParams.isAddForm
                ? _get(this.propertyLicenceTechSetup, "property_licence_tech_setup_sr.overlay.id", null)
                : _get(this.encodingTarget, "encoding_target_sr.overlay_id", null),
        });
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     * and if the selected product has support for ad (product.has_ad_support).
     * Save 0 if it has no support.
     *
     * In add mode, set the status based on the property licence tech setup
     * (property_licence_tech_setup.has_ad_support via event.property_licence_id).
     */
    setAdSupportField() {
        let defaultValue =
            _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.has_ad_support', false);

        if (!this.props.formParams.isAddForm) {
            defaultValue = _get(this.encodingTarget, 'encoding_target_sr.has_ad_support', false);
        }

        const isHidden = (ENCODER_TYPE_MANUAL === this.encoderTypeId || !this.product.has_ad_support),
            isChecked = !isHidden && defaultValue;

        if (defaultValue) {
            this.addAdTypesForField();
        }

        this.setField('has_ad_support', {
            checked: isChecked,
            defaultValue: isChecked,
            hidden: isHidden,
        });

        this.setField('ad_type', {hidden: !isChecked});
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     * and if the selected product has support for DRM (product.has_drm_support).
     * Save 0 if it has no support.
     *
     * In add mode, set the status based on the property licence tech setup
     * (property_licence_tech_setup.has_drm_support via event.property_licence_id).
     */
    setDrmSupportField() {
        let defaultValue =
            _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.has_drm_support', false);

        if (!this.props.formParams.isAddForm) {
            defaultValue = _get(this.encodingTarget, 'encoding_target_sr.has_drm_support', false);
        }

        const isHidden = (ENCODER_TYPE_MANUAL === this.encoderTypeId || !this.product.has_drm_support),
            isChecked = !isHidden && defaultValue;

        if (defaultValue) {
            this.addClientDrmSetupsForField();
        }

        this.setField('has_drm_support', {
            checked: isChecked,
            defaultValue: isChecked,
            hidden: isHidden,
        });

        this.setField('client_drm_setups', {hidden: !isChecked});
    }

    /**
     * Display only if "Encoders" is "Sportradar",
     * if the selected product has support for CC (product.has_cc_support),
     * if the event content has an input (event_content.has_input = 1),
     * if the origin stream type is MediaConnect (event_content_input.origin_stream_type_id = 8)
     * and if the MediaConnect origin entry point has at least one MediaConnect origin feed with CC (mediaconnect_origin_feed.has_cc = 1).
     *
     * In add mode, set the status based on the property licence tech setup (property_licence_tech_setup.has_cc via event.property_licence_id) if there is one.
     */
    setCloseCaptionFields() {
        const defaultValue = this.props.formParams.isAddForm
            ? _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.has_cc', false)
            : _get(this.encodingTarget, 'encoding_target_sr.has_cc', false);

        const streamLanguage = this.props.formParams.isAddForm
            ? _get(this.propertyLicenceTechSetup, 'property_licence_tech_setup_sr.cc_stream_language.id', [])
            : _get(this.encodingTarget, 'encoding_target_sr.cc_stream_language.id', []);

        const isVisible = (
                ENCODER_TYPE_MANUAL !== this.encoderTypeId &&
                this.product.has_cc_support &&
                this.hasInput &&
                ((ORIGIN_STREAM_TYPE_MEDIACONNECT === this.originStreamTypeId && this.mediaconnectOriginFeedsHasCc)
                    || ORIGIN_STREAM_TYPE_SRT === this.originStreamTypeId
                    || (ORIGIN_STREAM_TYPE_UDP === this.originStreamTypeId && this.udpOriginEntryPointHasCc))
            ),
            isChecked = defaultValue;

        this.setField('has_cc', {
            checked: isChecked,
            defaultValue: isChecked,
            hidden: !isVisible,
        });

        this.setField('cc_stream_language', {hidden: (!isVisible || !isChecked), defaultValue: streamLanguage});
    }

    /**
     * Display only if "Encoders" is "Sportradar"
     *  and if the selected product has support for Akamai RTMP distribution regions
     *  (product.has_akamai_rtmp_distr_region_support).
     * Remove potential exisitng entries otherwise.
     *
     *  In add mode, pre-select the regions based on the property licence tech setup
     *  (property_licence_tech_setup_sr_to_akamai_rtmp_distr_region via event.property_licence_id).
     */
    setAkamaiRtmpDistributionRegionsField(toggleOnly = false) {
        const isHidden = !(
            this.encoderTypeId === ENCODER_TYPE_SPORTRADAR
            && this.distributionTypeId === DISTRIBUTION_TYPE_LIVE
            && [CDN_AKAMAI, CDN_AKAMAI_2].includes(this.cdnId)
            && this.product.has_akamai_rtmp_distr_region_support
        );

        let akamaiRtmpDistributionRegions = _get(this.encodingTarget, 'encoding_target_sr.akamai_rtmp_distribution_regions', []);

        if (this.props.formParams.isAddForm) {
            akamaiRtmpDistributionRegions = _get(this.propertyLicenceTechSetup,
                'property_licence_tech_setup_sr.akamai_rtmp_distribution_regions', []);
        }

        const defaultValue = akamaiRtmpDistributionRegions.map((item) => (convertToInt(item.id)));

        this.setField('akamai_rtmp_distr_regions', {
            hidden: isHidden,
            disabled: isHidden,
        });

        if (!toggleOnly) {
            this.setField('akamai_rtmp_distr_regions', {
                defaultValue: isHidden ? null : defaultValue,
            });
        }
    }

    encoderTypeDependencies() {
        this.setPlaybackPathField();
        this.setLanguagesField();
        this.setEncodingJobProfilesField();
        this.setStreamNamePrefixField();
        this.setScoreboardOverlaySupportField();
        this.setSlateSupportField();
        this.setOverlaySupportField();
        this.setAdSupportField();
        this.setDrmSupportField();
        this.setCloseCaptionFields();
        this.setAkamaiRtmpDistributionRegionsField();
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (nextProps.GraphQLOptionsData.distributions) {
            this.setField('distribution', {
                options: prepareDistributionOptions(nextProps.GraphQLOptionsData.distributions)
            });
        }

        if (nextProps.GraphQLOptionsData.propertyLicenceTechSetups) {
            this.propertyLicenceTechSetup = Object.assign({}, nextProps.GraphQLOptionsData.propertyLicenceTechSetups[0]);
        }

        if (_has(nextProps, 'GraphQLEntityData.encodingTarget')
            && !nextProps.GraphQLOptionsData.loading
            && !_isEqual(nextProps.GraphQLEntityData.encodingTarget, this.encodingTarget)
            && !this.state.edit
        ) {
            this.encodingTarget = nextProps.GraphQLEntityData.encodingTarget;

            this.setState({edit: true});
            this.handleWithEditForm();
        }

        if (
            _has(nextProps.GraphQLOptionsData, 'eventContent.event_content_input.origin_stream_type.id')
            && this.originStreamTypeId !== convertToInt(_get(nextProps.GraphQLOptionsData, 'eventContent.event_content_input.origin_stream_type.id'))
        ) {
            this.originStreamTypeId = convertToInt(_get(nextProps.GraphQLOptionsData, 'eventContent.event_content_input.origin_stream_type.id'));
        }

        const streamLanguages = _get(nextProps.GraphQLOptionsData, 'streamLanguages', false);

        if (streamLanguages) {
            this.setField('cc_stream_language', {
                options: streamLanguages.map((option) => {
                    const languageCodeString = option.language.code ? `(${option.language.code})` : '';
                    const text = `${option.name} ${languageCodeString}`;

                    return {
                        id: option.id,
                        key: option.id,
                        value: option.id,
                        text,
                    };
                }),
            });
        }
        this.hasInput = _get(nextProps.GraphQLOptionsData, 'eventContent.has_input', false);
        this.mediaconnectOriginFeedsHasCc =
            _get(nextProps.GraphQLOptionsData,
                'eventContent.event_content_input.mediaconnect_origin_entry_point.mediaconnect_origin_feeds',
                []
            ).some((feed) => feed.has_cc);
        this.udpOriginEntryPointHasCc = _get(nextProps.GraphQLOptionsData,
            'eventContent.event_content_input.udp_origin_entry_point.has_cc',
            false);


        /**
         * if encoder type is changed
         */
        if (nextProps.formValues.encoder_type !== this.props.formValues.encoder_type) {
            this.encoderTypeId = convertToInt(nextProps.formValues.encoder_type);
            this.encoderTypeDependencies();
        }

        /**
         * if cdn is changed
         */
        if (nextProps.formValues.cdn !== this.props.formValues.cdn) {
            this.cdnId = convertToInt(nextProps.formValues.cdn);
            this.setAkamaiCdnIngestMethodField(true);
        }
    }

    prepareDataForSubmit = (data) => {
        const params = Object.assign({}, data, {
            distributionTypeId: this.distributionTypeId,
            originStreamTypeId: this.originStreamTypeId,
            product: this.product,
        });

        return (this.props.formParams.isAddForm) ? prepareDataForCreate(params) : prepareDataForUpdate(params);
    };

    renderSaveButton = () => {
        const params = {};

        if (!this.props.formParams.isAddForm) {
            params.content = 'Save';
        }

        return super.renderSaveButton(params);
    };
}

export default withRouter(withApollo(EventContentTargetForm));
