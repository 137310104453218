export const HELPER_SESSION_MINUTE_INTERVAL = 5;

const avcmpConfig = {
    apolloClient: {
        networkInterface: {
            uri: '/graphql',
        },
        fetchPolicy: 'cache-and-network',
    },
    app: {
        defaultLimitItemsOnList: 50,
        notification: {
            timeout: 5000, // time to close notification window [ms]
        },
    },
};

export default avcmpConfig;
