import {withRouter} from "react-router";
import {get as _get} from "lodash";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {renderArchivingButton} from "../../../utils/archiving";
import {convertToInt} from "../../../utils/helpers";

import {ToggleArchiveStateProductionCompany} from "../../../graphql/productionCompany/mutation.graphql";

export class ProductionCompanyForm extends DefaultForm {
    componentDidMount() {
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('productionCompanies.index'));
        });
        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink("productionCompanies.edit", {
                id: response.data.createProductionCompany.id
            }));
        });
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                country: (data && data.country) ? convertToInt(data.country) : null,
                client: (data && data.client) ? convertToInt(data.client) : null,
            }
        )
    );

    renderErrors(errorData,) {
        super.renderErrors(
            errorData,
            'Production company',
            getLink("productionCompanies.index"),
            {size: 'tiny'}
        );
    }

    renderArchiveButton = () => (
        renderArchivingButton(
            {
                ...this.props,
                callbackLink: getLink('productionCompanies.index'),
                messageBoxName: 'productionCompanyMessage'
            },
            ToggleArchiveStateProductionCompany,
            {
                id: convertToInt(this.props.match.params.id),
                name: _get(this.props, 'GraphQLEntityData.productionCompany.name', null)
            }
        )
    );

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(ProductionCompanyForm);
