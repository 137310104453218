import {get as _get} from "lodash";
import PropTypes from "prop-types";

const getContent = (udpOriginEntryPoint) => {
    if (!udpOriginEntryPoint.tournaments && !udpOriginEntryPoint.content_categories) {
        return '';
    }

    const separator = ' > ';

    const tournaments = udpOriginEntryPoint.tournaments.map(tournament => ([
        tournament.tournament_category.sport.name,
        tournament.tournament_category.name,
        tournament.name
    ].join(separator)));

    const contentCategories = udpOriginEntryPoint.content_categories.map(contentCategory => ([
        contentCategory.parent.parent.name,
        contentCategory.parent.name,
        contentCategory.name
    ].join(separator)));

    return [...tournaments, ...contentCategories].sort((a, b) => (
        a.toLowerCase() > b.toLowerCase() ? 1 : -1
    )).join('; ');
};

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'udp_origin_entry_points',
    parseData: udpOriginEntryPoint => [
        udpOriginEntryPoint.id,
        udpOriginEntryPoint.name,
        udpOriginEntryPoint.description || '',
        _get(udpOriginEntryPoint, 'encoding_datacenter.name', ''),
        udpOriginEntryPoint.url,
        _get(udpOriginEntryPoint, 'failover_udp_origin_entry_point.name', ''),
        getContent(udpOriginEntryPoint),
    ],
    path: 'udpOriginEntryPoint.index.path',
    titles: [
        "ID",
        "Name",
        "Description",
        "Encoding datacenter",
        "URL",
        "Failover",
        "Content",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
};
