import React from "react";
import PropTypes from "prop-types";

import Table from "../../app/components/Table";

import {columns, rowRenderer} from "../utils/productHelper";

const ProductTable = ({products = [], loading = true}) => {
    return (
        <div className="products__table">
            <Table
                name="productsList"
                columns={columns}
                data={products}
                loading={loading}
                noDataText="No products found"
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    )
};

ProductTable.propTypes = {
    products: PropTypes.array,
    loading: PropTypes.bool
};

export default ProductTable;
