import {get as _get} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { IconEdit } from '@appComponents/IconCollection';
import Table from '@appComponents/Table';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {getLink} from '@appComponents/Link';
import Authorization from '@appComponents/Authorization';
import {convertToInt} from '@utils/helpers';

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {label: 'Name', sorting: true},
    'country': {
        label: 'Country',
        sorting: (row) => (null === row.country) ? '' : row.country.name.capitalizeFirstLetter(),
    },
    'property_licences_count': {
        label: '#Property licences',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
        sorting: (row) => (convertToInt(row.property_licences_count)),
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    resources={RESOURCES.LICENSOR}
                    privileges={[CONST.SECURITY_PRIVILEGES_UPDATE, CONST.SECURITY_PRIVILEGES_READ]}
                    link={{
                        name: 'licensors.edit',
                        params: {id : _get(row, 'id', null)},
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case 'country' :
            return (
                <div>
                    {(null === row.country || undefined === row.country)
                        ? ''
                        : row.country.name.capitalizeFirstLetter()
                    }
                </div>
            );
        case 'property_licences_count' :
            return (
                <div>
                    {row.property_licences_count}
                </div>
            );
        case 'actions' :
            return (
                <Authorization
                    resources={RESOURCES.LICENSOR}
                    privileges={[CONST.SECURITY_PRIVILEGES_UPDATE, CONST.SECURITY_PRIVILEGES_READ]}
                >
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={
                            getLink('licensors.edit', {id: row.id})
                        } resource={RESOURCES.LICENSOR} />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};

const LicensorTable = (props) => {
    const { licensors, loading } = props;

    return (
        <Table
            name='licensorsList'
            columns={columns}
            data={licensors}
            loading={loading}
            noDataText='No licensors found'
            rowRenderer={rowRenderer}
        />
    );
};

LicensorTable.defaultProps = {
    licensors: [],
};

LicensorTable.propTypes = {
    licensors: PropTypes.array.isRequired,
    onLicensorDelete: PropTypes.func,
    loading: PropTypes.bool,
};

export default LicensorTable;
