import {
    get as _get,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getLink} from '@appComponents/Link';
import StepsContent from '@appComponents/StepsContent';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import {routes} from '@constants/routes';
import {
    EventMasterBookingsFiltersData,
    GetEventMasterBookingsForView,
} from '@graphql/bookings/query';
import {showModal} from '@utils/modal';

import EventMasterBookingsTable from '../components/EventMasterBookingsTable';
import EventMasterBookingsFilter from '../components/EventMasterBookingsFilters';
import HeaderRenderer from '../../app/components/HeaderRenderer';
import MasterBookingListButtons from '../components/MasterBookingListButtons';
import EventMasterBookingGeoRestrictionsStep
    from './EventMasterBookingGeoRestrictionsStep';
import EventMasterBookingGeoRestrictionsGeneralInformationForm
    from '../forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsGeneralInformationForm';
import EventMasterBookingGeoRestrictionsGeneralInformationModel
    from '../forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsGeneralInformationModel';
import EventMasterBookingGeoRestrictionsGeneralInformationEditModel
    from '../forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsGeneralInformationEditModel';
import {exportExcel as eventMasterBookingsExportExcel} from '../utils/export/eventMasterBookingsExcel';

export class EventMasterBookings extends React.Component {
    static propTypes = {
        EventMasterBookingsFilters: PropTypes.object.isRequired,
        EventMasterBookingsList: PropTypes.object.isRequired,
        eventId: PropTypes.number,
        filters: PropTypes.object,
        history: PropTypes.object.isRequired,
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired,
        modal: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
    };

    static defaultProps = {
        filters: {
            clients: [],
            countries: [],
            products: [],
            deviceCategories: [],
            bookingTypes: [],
        },
        eventId: null,
    };

    state = {
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
    };

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }

        this.loadModal(this.props.match.path);
    }

    componentDidUpdate(prevProps) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.events.bookings.index.path,
            modalChanged = this.props.modal.isVisible !== prevProps.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible;

        if (urlChanged && urlIsNotIndex) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (modalChanged && modalIsNotVisible) {
            this.props.history.push(
                getLink('events.masterBookings.index', {
                    id: this.props.match.params.id,
                })
            );
        }
    }

    loadModal(path, params = {}) {
        const eventMasterBookingsGeoRestrictionsPath = routes.events.masterBookings;
        let form = EventMasterBookingGeoRestrictionsGeneralInformationForm,
            formParams = {};

        if (path === eventMasterBookingsGeoRestrictionsPath.add.path) {
            formParams = Object.assign({}, {
                optionsVariables: {
                    event: convertToInt(this.props.match.params.id),
                },
            });

            return this.loadAddModalForm(
                createForm(EventMasterBookingGeoRestrictionsGeneralInformationModel, form, formParams)
            );
        } else if (path === eventMasterBookingsGeoRestrictionsPath.edit.path
            || path === eventMasterBookingsGeoRestrictionsPath.editGeoRestrictions.path) {

            if (!isUrlParamValid(this.props.match.params.masterBookingId)) {
                return showModal({
                    isVisible: true,
                    content: renderModalError('Event master booking',
                        getLink('events.masterBooking.index',
                            {id: this.props.match.params.id})),
                });
            }

            formParams = Object.assign({}, {
                id: convertToInt(this.props.match.params.masterBookingId) || convertToInt(params.masterBookingId),
                optionsVariables: {
                    event: convertToInt(this.props.match.params.id),
                },
            });

            return this.loadEditModalForm(
                {
                    tabs: [
                        {
                            key: 'step1',
                            menuItem: {content: 'General information', disabled: false},
                            pane: {
                                content: createForm(
                                    EventMasterBookingGeoRestrictionsGeneralInformationEditModel,
                                    form,
                                    formParams
                                ),
                            },
                            url: getLink('events.masterBookings.edit', {
                                id: convertToInt(this.props.match.params.id),
                                masterBookingId: convertToInt(this.props.match.params.masterBookingId)
                                    || convertToInt(params.masterBookingId),
                            }),
                        },
                        {
                            key: 'step2',
                            menuItem: {content: 'Geo restrictions', disabled: false},
                            pane: {
                                content: <EventMasterBookingGeoRestrictionsStep
                                    masterBookingId={convertToInt(this.props.match.params.masterBookingId)}
                                    eventId={convertToInt(this.props.match.params.id)}
                                />,
                            },
                            url: getLink('events.masterBookings.editGeoRestrictions', {
                                id: convertToInt(this.props.match.params.id),
                                masterBookingId: convertToInt(this.props.match.params.masterBookingId)
                                    || convertToInt(params.masterBookingId),
                            }),
                        },
                    ],
                },
                formParams
            );
        }
    }

    loadAddModalForm = (Form) => {
        this.props.Modal.setModal({
            canBeDismissed: true,
            content: <StepsContent
                title={'Add new master booking'}
                addMode={true}
                tabs={[
                    {key: 'step1', menuItem: {content: 'General information', disabled: false}, pane: {content: Form}},
                    {key: 'step2', menuItem: {content: 'Geo restrictions', disabled: true}, pane: {content: null}},
                ]}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    loadEditModalForm = (Forms) => {
        this.props.Modal.setModal({
            canBeDismissed: true,
            content: <StepsContent
                title={'Edit master booking'}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    render() {
        const eventId = convertToInt(this.props.match.params.id),
            loading = (
                this.props.EventMasterBookingsList.loading
                || this.props.EventMasterBookingsFilters.loading
                || this.props.loadingRefetch
            ),
            masterBookings = _get(this.props, 'EventMasterBookingsList.masterBookings', []);

        return <div>
            <HeaderRenderer
                buttons={MasterBookingListButtons}
                buttonsProps={{eventId: eventId}}
                exportExcelParams={eventMasterBookingsExportExcel(masterBookings, eventId)}
                filters={EventMasterBookingsFilter}
                filtersProps={{
                    data: {
                        clients:  _get(this.props, 'EventMasterBookingsFilters.clients', [])
                            .mapDataForDropdownWithIntVal(),
                        countries: _get(this.props, 'EventMasterBookingsFilters.countries', [])
                            .mapDataForDropdown('key'),
                        products: _get(this.props, 'EventMasterBookingsFilters.products', [])
                            .mapDataForDropdownWithIntVal(),
                        bookingTypes: _get(this.props, 'EventMasterBookingsFilters.bookingTypes', [])
                            .mapDataForDropdownWithIntVal(),
                        deviceCategories: _get(this.props, 'EventMasterBookingsFilters.deviceCategories', [])
                            .mapDataForDropdownWithIntVal(),
                    },
                    loading: loading,
                }}
                loading={loading}
                messagesBoxNames='eventMasterBookingsMessage'
            />
            <Segment basic className='--table'>
                <EventMasterBookingsTable
                    eventId={this.props.eventId}
                    loading={loading}
                    masterBookings={masterBookings}
                />
            </Segment>
        </div>;
    }
}

let EventsMasterBookingsWithQuery = compose(
    graphql(GetEventMasterBookingsForView, {
        options: (props) => {
            let defaultFilters = EventMasterBookings.defaultProps.filters,
                filters  = props.filters;

            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    eventId: props.eventId,
                    bookingTypes: (filters.bookingTypes)
                        ? filters.bookingTypes
                        : defaultFilters.bookingTypes,
                    clients: (filters.clients)
                        ? filters.clients
                        : defaultFilters.clients,
                    countries: (filters.countries)
                        ? filters.countries
                        : defaultFilters.countries,
                    products: (filters.products)
                        ? filters.products
                        : defaultFilters.products,
                    deviceCategories: (filters.deviceCategories)
                        ? filters.deviceCategories
                        : defaultFilters.deviceCategories,
                },
            };
        },
        name: 'EventMasterBookingsList',
    }),
    graphql(EventMasterBookingsFiltersData, {
        name: 'EventMasterBookingsFilters',
        options: () => {
            return {
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
            };
        },
    })
)(FilterQueryWrapper(EventMasterBookings, {
    queryForRefresh: 'EventMasterBookingsList',
    filterUrls: ['events.masterBookings.index'],
}));

const mapStateToProps = (state) => {
    return ({
        filters: getSearchFilters(state, 'EventMasterBookings', EventMasterBookings.defaultProps.filters),
        modal: state.modal,
    });
};
const mapDispatchToProps = mapModulesToProps(['Modal']);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(EventsMasterBookingsWithQuery, EventMasterBookings.defaultProps.filters)
));
