import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {Header} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {useQuery} from '@apollo/client';
import {withApollo} from '@apollo/client/react/hoc';

import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getLink} from '@appComponents/Link';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import Table from '@appComponents/Table';
import BlackoutZonePostalCodesButtons from '@modules/blackoutZone/components/BlackoutZonePostalCodesButtons';
import BlackoutZonePostalCodesFilters, {DEFAULT_FILTERS_VALUES} from '@modules/blackoutZone/components/BlackoutZonePostalCodesFilters';
import BlackoutZonePostalCodesForm from '@modules/blackoutZone/forms/BlackoutZonePostalCodesForm';
import BlackoutZonePostalCodesModel from '@modules/blackoutZone/forms/BlackoutZonePostalCodesModel';
import {columns} from '@modules/blackoutZone/utils/blackoutZonePostalCodes';
import {exportExcel} from '@modules/blackoutZone/utils/export/blackoutZonePostalCodesExcel';
import {DeleteBlackoutZonePostalCodes} from '@graphql/blackoutZonePostalCodes/mutation';
import {GetBlackoutZonePostalCodesForTable} from '@graphql/blackoutZonePostalCodes/query';
import {createForm, loadModalForm} from '@utils/forms';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import useEffectForFilters from '@utils/hooks/useEffectForFilters';
import {routes} from '@constants/routes';
import {isUrlParamValid} from '@utils/helpers';

const BlackoutZonePostalCodes = (props) => {
    const blackoutZoneId = props.match.params.id,
        [selection, setSelection] = useState([]),
        [isRefetching, setIsRefetching] = useState(false);

    const {
        data: {
            postalCodes,
        } = {},
        loading: loadingPostalCodes = true,
        refetch: refetchBlackoutZonePostalCodes,
    } = useQuery(GetBlackoutZonePostalCodesForTable, {
        variables: {
            blackoutZones: [blackoutZoneId],
            search: props.filters.search,
            country: props.filters.country,
            countrySubdivision: props.filters.countrySubdivision,
        },
        onCompleted: () => {
            if (props.match.path === routes.blackoutZones.edit.postalCodes.add.path) {
                addPostalCodesHandler();
            }
        },
    });

    useEffectForFilters(postalCodes, props.filters.lastClickSubmit, refetchBlackoutZonePostalCodes, setIsRefetching);

    const isLoading = () => {
        return loadingPostalCodes || isRefetching;
    };

    const addPostalCodesHandler = () => {
        if (!isUrlParamValid(props.match.params.id)) {
            return;
        }
        const indexUrl = getLink('blackoutZones.edit.postalCodes.index', {id: blackoutZoneId}),
            addUrl = getLink('blackoutZones.edit.postalCodes.add', {id: blackoutZoneId}),
            Form = createForm(BlackoutZonePostalCodesModel, BlackoutZonePostalCodesForm, {
                id: blackoutZoneId,
                setIsRefetching: setIsRefetching,
                dataRequest: false,
                redirectUrl: indexUrl,
                Modal: props.Modal,
                optionsVariables: {
                    id: blackoutZoneId
                },
            }),
            content = (<div>
                <Header content='Add new postal codes'/>
                <Form/>
            </div>);

        loadModalForm({
            url: addUrl,
            form: content,
            setModal: props.Modal.setModal,
            onClose: () => window.history.pushState({}, '', indexUrl),
            size: 'large',
        });
    };

    const removePostalCodesHandler = () => {
        if (isLoading()) {
            return;
        }

        if (_isEmpty(selection)) {
            props.Modal.setModalError({
                header: 'Remove postal codes',
                text: 'Please select at least one postal code.',
                isVisible: true,
                size: 'tiny',
            });

            return;
        }

        props.Modal.setModalConfirmation({
            header: <Header icon='trash' content='Remove postal codes'/>,
            text: 'Are you sure you want to remove these postal codes?',
            onYes: () => {
                setIsRefetching(true);
                props.client.mutate({
                    mutation: DeleteBlackoutZonePostalCodes,
                    awaitRefetchQueries: true,
                    variables: {
                        blackoutZone: blackoutZoneId,
                        postalCodes: selection,
                    },
                }).then((response) => {
                    refetchBlackoutZonePostalCodes()
                        .then(() => setIsRefetching(false))
                        .finally(props.Modal.setModal({isVisible: false, header: null}));

                    const success = _get(response, 'data.deleteBlackoutZonePostalCodes', false);

                    props.MessageBox.addMessage(
                        'BlackoutZoneEdit',
                        success
                            ? 'The postal codes have been deleted successfully.'
                            : 'The postal codes could not be deleted.',
                        null,
                        success ? 'success' : 'error',
                        success
                    );
                });
            },
        });
    };

    return (
        <div>
            <HeaderRenderer
                loading={isLoading()}
                filters={BlackoutZonePostalCodesFilters}
                filtersButtonName={'BlackoutZonePostalCodes'}
                messagesBoxNames={['BlackoutZonePostalCodes1', 'BlackoutZonePostalCodes2', 'BlackoutZonePostalCodes3']}
                buttons={BlackoutZonePostalCodesButtons}
                buttonsProps={{
                    loading: loadingPostalCodes || isRefetching,
                    addButtonText: 'Add new postal codes',
                    addPostalCodesHandler: addPostalCodesHandler,
                    removeButtonText: 'Remove',
                    removePostalCodesHandler: removePostalCodesHandler,
                    excelExport: exportExcel(postalCodes),
                }}
            />
            <Table
                loading={isLoading()}
                columns={columns}
                data={postalCodes}
                defaultSort={'country'}
                noDataText='No postal codes found'
                name='BlackoutZonePostalCodesList'
                selectable={true}
                multiple={true}
                getSelectedElements={(items) => setSelection(items)}
                pagination={true}
            />
        </div>
    );
};

BlackoutZonePostalCodes.propTypes = {
    filters: PropTypes.shape({
        search: PropTypes.string.isRequired,
        country: PropTypes.array.isRequired,
        countrySubdivision: PropTypes.array.isRequired,
        lastClickSubmit: PropTypes.number,
    }),
    MessageBox: PropTypes.object,
    Modal: PropTypes.object,
    client: PropTypes.object,
    match: PropTypes.object,
};

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'BlackoutZonePostalCodes', DEFAULT_FILTERS_VALUES),
    filterUrls: ['blackoutZones.edit.postalCodes.index'],
});

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal']);

const BlackoutZonePostalCodesWrapped = FilterQueryWrapper(BlackoutZonePostalCodes, {
    queryForRefresh: 'GetBlackoutZonePostalCodesForTable',
});

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(BlackoutZonePostalCodesWrapped, DEFAULT_FILTERS_VALUES)
)));
