import {get as _get} from 'lodash';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {showMessageBox} from "@utils/messageBox";

export class LcrChannelGeneralInformationForm extends DefaultForm {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.shape({
            path: PropTypes.string.isRequired,
        }),
    };

    componentDidMount() {
        if (this.props.match.path === routes.lcrChannel.add.path) {
            this.setField('has_lmt_support', {
                defaultValue: true,
            });
        }

        this.setCreateSuccessCallback((data) => {
            this.props.history.push(getLink('lcrChannel.edit.geoRestrictions', {
                id: _get(data, 'data.createLcrChannel.id', ''),
            }));
        });

        this.setCreateErrorCallback(() => {
            showMessageBox(
                'LcrChannelEdit',
                'The LCR Channel couldn\'t be saved.',
                'Unprocessable entity, please check if the name is unique.',
                'error',
                true
            );
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('lcrChannel.index'));
        });
    }

    prepareDataForSubmit = (data) => {
        return super.prepareDataForSubmit(data).lcrChannel;
    };

    renderCancelButton = () => null;
}

export default withRouter(LcrChannelGeneralInformationForm);
