import {
    get as _get
} from "lodash"

export const FIELD_REQUIRED = "Value is required and can't be empty";
export const ONE_SWITCH_ON = "At least one switch must be on.";
export const INVALID_DATE = 'Invalid date. Required date in format: YYYY-MM-DD';
export const INVALID_DATETIME = 'Invalid DateTime. Required format: YYYY-MM-DD HH:mm';
export const INVALID_TIME = 'Invalid time. Required time in format: HH:ii:ss';
export const INVALID_EMAIL_ADDRESS = 'Invalid email address';
export const INVALID_PHONE_NUMBER = 'Invalid phone number';
export const INVALID_URL = 'Invalid URL';
export const INVALID_CIDR = 'Invalid CIDR';
export const INVALID_CIDR_RANGE = 'CIDR which has a wider range than 16 IPs is not allowed.';
export const INVALID_SUBDIVISION = 'At least one subdivision must be selected by country.';
export const INVALID_CLIENT_TOGGLE_DROPDOWNS = "At least one of client's dropdowns must have an option selected.";
export const DIGITS_ONLY = 'Digits only allowed';
export const DIGITS_AND_FLOATS_ONLY = 'Only numbers or float are allowed';
export const DIGITS_AND_FLOAT_WITH_FIXED_DECIMAL_ONLY = (decimal) => `Only numbers or float with ${decimal} decimal places are allowed`;
export const NUMBER_NOT_IN_RANGE = (min, max) => `Value must be between ${min} and ${max}`;
export const NUMBER_OF_CHECKBOX_NOT_IN_RANGE = (min, max) => `Number of checkbox checked should be between ${min} and ${max}`;
export const TOO_LONG_STRING_MAX_LENGTH = (max) => `This value is too long. It should have ${max} characters or less.`;
export const TOO_SHORT_STRING = 'This value is too short';
export const START_DATE_BEFORE_END_DATE = 'End date must be later than the Start date';
export const ALPHANUMERIC_ONLY = 'Only letters and numbers are allowed';
export const ALPHANUMERIC_WITH_UNDERSCORE = 'Only letters, numbers and underscore are allowed';
export const ALPHANUMERIC_WITH_UNDERSCORE_AND_FORWARD_SLASH = 'Only letters, numbers, underscore and forward slashes are allowed';
export const ALPHANUMERIC_WITH_COMMAS_AND_WHITESPACES = 'Only letters, numbers, commas and whitespace characters are allowed';
export const ALPHANUMERIC_AT_ALPHANUMERIC = 'Invalid format. Required: [a-zA-Z0-9_]@[a-zA-Z0-9_]';

export const DELETED = (label, name = '') => (`The${label ? ` ${label}` : ''}${name ? ` "${name}"` : ''} has been deleted successfully.`);
export const DELETE_CONFIRMATION = (label, name = '') => (`Are you sure you want to delete${label ? ` the ${label}` : ''}${name ? ` "${name}"` : ''}?`);
export const DELETE_HEADER = (name) => (`Delete ${name}`);
export const DELETE_HEADER_ARGUMENT_FIRST = (name) => (`Delete ${name}`);
export const DELETE_IN_PROGRESS = (label) => (`The ${label} is being deleted...`);
export const DELETING = (label, name = '') => (`Deleting the${label ? ` ${label}` : ''}${name ? ` "${name}"` : ''}...`);
export const NOT_DELETED = (label, name = '') => (`The${label ? ` ${label}` : ''}${name ? ` "${name}"` : ''} could not be deleted.`);

export const ARCHIVE_HEADER = (name) => (`Archive ${name}`);
export const ARCHIVE_CONFIRMATION = (name) => (`Are you sure to archive ${name}?`);
export const ARCHIVE_PROGRESS = (name) => (`Archiving ${name} ...`);
export const ARCHIVE_SUCCESS = (name) => (`The ${name} has been archived successfully.`);
export const ARCHIVE_FAILED = (name) => (`The ${name} could not be archived.`);

export const UNARCHIVE_HEADER = (name) => (`Unarchive ${name}`);
export const UNARCHIVE_CONFIRMATION = (name) => (`Are you sure to unarchive ${name}?`);
export const UNARCHIVE_PROGRESS = (name) => (`Unarchiving ${name} ...`);
export const UNARCHIVE_SUCCESS = (name) => (`The ${name} has been unarchived successfully.`);
export const UNARCHIVE_FAILED = (name) => (`The ${name} could not be unarchived.`);

export const USER_GRANT_APP_HEADER = (grant) => grant ? 'Grant access to AV-CMP' : 'Revoke access to AV-CMP';
export const USER_GRANT_APP_CONFIRMATION = (grant) => grant ? 'Are you sure you want to grant access to AV-CMP to this user?' : 'Are you sure you want to revoke access to AV-CMP for this user?';
export const USER_GRANT_APP_PROGRESS = 'Updating access to AV-CMP...';
export const USER_GRANT_APP_SUCCESS = 'The access to AV-CMP has been updated successfully.';
export const USER_GRANT_APP_ERROR = 'Error while changing access to AV-CMP';

export const SAVED = (label, name = '') => (`The${label ? ` ${label}` : ''}${name ? ` "${name}"` : ''} has been saved successfully.`);
export const NOT_SAVED = (label, name = '') => (`The${label ? ` ${label}` : ''}${name ? ` "${name}"` : ''} could not be saved.`);

export const NOT_CREATED = (label, name = '') => (`The${label ? ` ${label}` : ''}${name ? ` "${name}"` : ''} could not be created.`);

export const FORM_ERROR = (name, haveOrHas = 'has') => (`The ${name} ${haveOrHas} not been saved. Please correct the highlighted fields below.`);
export const FORM_OPTIONS_QUERY_ERROR = 'There was an error in options query.';
export const WIZARD_STEP_VALIDATION_TEXT = "Can't go to next step. Please correct the highlighted fields below.";
export const WIZARD_TABS_VALIDATION_TEXT = (name) => (`The ${name} has not been saved. Please correct the highlighted fields in the other tabs.`);
export const NO_STREAM_NAMES_TEXT = 'No stream names available.';
export const NO_ASSOC_CDN_CONFIGS = 'This encoding target is not associated to any client CDN configuration.';
export const NO_ASSOC_VOD_STORAGES = 'This encoding target is not associated to any third party client VOD storage.';
export const NOT_AUTHORIZED = "You are not authorised to access this page";

const FORM_PARAMS_ID_KEY = 'formParams.id';

export const EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT = {
    error: (data) => {
        const id = _get(data, FORM_PARAMS_ID_KEY, "");

        return `The event${id ? ` ${id}` : ''} could not be saved.`
    },
    success: (data, dbResponse) => {
        let id = _get(data, FORM_PARAMS_ID_KEY, false);

        if (!id) {
            id = _get(
                dbResponse,
                "data.createSrEvent.event.id",
                _get(dbResponse,
                    "data.createAvEvent.event.id",
                    ""
                )
            )
        }

        return `The event${id ? ` ${id}` : ''} has been saved successfully.`
    }
};

export const EVENT_DELETE_STATUS_TEXT_OBJECT = {
    failed: (data) => (`The event ${_get(data, FORM_PARAMS_ID_KEY, "")} could not be deleted.`),
    header: "Delete event",
    confirmation: (data) => (`Are you sure you want to delete the event ${_get(data, FORM_PARAMS_ID_KEY, "")}?`),
    progress: (data) => (`Deleting the event ${_get(data, FORM_PARAMS_ID_KEY, "")}...`),
    success: (data) => (`The event ${_get(data, FORM_PARAMS_ID_KEY, "")} has been deleted successfully.`)
};

export const CIDR = "CIDR";

export const CANCEL_BULK_CLIENT_BOOKINGS = (numOfBookings) => (`Are you sure you want to cancel these ${numOfBookings} bookings?`);
