export const linksBody = `
id
description
url
`;


export const propertyLicenceLinksList = `
query PropertyLicenceLinks($propertyLicenceId : ID!) {
    propertyLicenceLinks(
        filters: {
          propertyLicence: $propertyLicenceId
        }
    ) {
      ${linksBody}
    }
}`;

export const propertyLicenceLinksCreateMutation = `
mutation CreatePropertyLicenceLink (
    $property_licence_id: Int!
    $description: String!
    $url: String!
  ) {
    createPropertyLicenceLink (
      property_licence: $property_licence_id
      description: $description
      url: $url
    ) {
      ${linksBody}
    }
  }
`;

export const propertyLicenceLinksUpdateMutation = `
mutation UpdatePropertyLicenceLink (
    $id: ID!
    $description: String
    $url: String
  ) {
    updatePropertyLicenceLink (
      id: $id
      description: $description
      url: $url
    ) {
      ${linksBody}
    }
  }
`;

export const propertyLicenceLinksDeleteMutation = `
mutation DeletePropertyLicenceLink($id:ID) {
    deletePropertyLicenceLink(id:$id) 
  }
`;
