import {has as _has} from "lodash";
import PropTypes from "prop-types";

import {contentRows} from "../../components/PropertyLicenceContentsRow";

export const exportExcel = (dataExport) => {
    const getSportOrFirstLevelCategory = (propertyLicence) => (
        _has(propertyLicence, 'sport.name') ? propertyLicence.sport.name : propertyLicence.content_category.name
    );

    const getTournamentsOrContentCategories = (propertyLicence) => {
        return [].concat(contentRows(propertyLicence).map(el => el.text)).sort((a, b) => (
            a.toLowerCase() > b.toLowerCase() ? 1 : -1
        )).join('; ');
    };

    const getYesOrNo = (data) => (data ? 'Yes' : 'No');

    return {
        dataExport: dataExport,
        filename: 'property_licences',
        parseData: propertyLicence => [
            propertyLicence.id,
            propertyLicence.name,
            propertyLicence.official_name,
            propertyLicence.licensor.name,
            propertyLicence.licensees.map(licensee => licensee.name).join(', '),
            propertyLicence.property_licence_type.name,
            getSportOrFirstLevelCategory(propertyLicence),
            getTournamentsOrContentCategories(propertyLicence),
            propertyLicence.content_tier.name,
            propertyLicence.content_package_type.name,
            propertyLicence.content_manager_user.name,
            propertyLicence.property_licence_status.name,
            getYesOrNo(propertyLicence.has_accreditation),
            getYesOrNo(propertyLicence.is_archived),
        ],
        path: 'propertyLicences.index.path',
        titles: [
            "ID",
            "Name",
            "Official name",
            "Licensor",
            "Licensee(s)",
            "Type",
            "Sport / 1st level category",
            "Content",
            "Tier",
            "Content package type",
            "Content manager",
            "Status",
            "Accreditation?",
            "Archived?",
        ],
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
