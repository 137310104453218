import React from 'react';

import Form from '@appComponents/ReduxFormControls';
import {IconInfoCircle} from '@appComponents/IconCollection';
import {LCR_CHANNEL} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {geoRestrictionWithTerritoryValidation} from '@modules/geoRestriction/validatorGeoRestriction';
import {UpdateLcrChannelCountriesAndSubdivisions} from '@graphql/lcr/mutation';
import {GeoRestrictionsDropdownOptionsForCountries} from '@graphql/eventGeoRestriction/query';
import {GetLcrChannelById} from '@graphql/lcr/query';

export default function LcrChannelGeoRestrictionsModel() {
    return {
        entityDataMapKey: 'lcrGeoRestrictions',
        entityLabel: 'geo restrictions',
        formName: 'lcrGeoRestrictions',
        label: 'geo restrictions',
        resources: LCR_CHANNEL,
        showChangelog: false,
        title: null,
        validate: geoRestrictionWithTerritoryValidation,
        messages: {
            boxName: {
                delete: 'LcrChannelEdit',
                error: 'LcrChannelEdit',
                success: 'LcrChannelEdit',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'lcrChannel.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            selection_type: {
                dataMapKey: 'lcrChannel.territory_selection_type.id',
                optionsKey: 'territorySelectionTypes',
                props: {
                    component: Form.SemanticSelect,
                    allowClear: true,
                    label: 'Selection type',
                    name: 'selection_type',
                    description: <IconInfoCircle
                        tooltip={"Leave empty if you don't want to define any geo restriction."}
                    />,
                },
            },
            countries: {
                optionsKey: 'continents',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Countries',
                    name: 'countries',
                    required: true,
                },
            },
            subdivisions: {
                optionsKey: 'countries',
                props: {
                    className: 'geoRestrictions',
                    component: Form.SemanticCheckboxTree,
                    hidden: true,
                    label: 'Subdivisions',
                    name: 'subdivisions',
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: null,
            deleteEntity: null,
            updateEntity: UpdateLcrChannelCountriesAndSubdivisions,
        },
        query: {
            getOptions: GeoRestrictionsDropdownOptionsForCountries,
            getEntity: GetLcrChannelById,
        },
    };
}
