import {Dropdown} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

import {EVENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import Authorization, {AuthorizationAny} from '@appComponents/Authorization';

const eventCreateUpdateAuthorizationArray = [
    {[EVENT]: SECURITY_PRIVILEGES_UPDATE},
    {[EVENT]: SECURITY_PRIVILEGES_CREATE},
];

const presetUpdateAuthorizationObject = {
    [EVENT]: SECURITY_PRIVILEGES_UPDATE,
};

const srEventCreateAuthorizationObject = {
    [EVENT]: SECURITY_PRIVILEGES_CREATE,
};

export const EventChildEventsHeaderButtons = ({
    createSportradarEventsFormOpen = () => null,
    applyPresetModalOpen = () => null,
}) => (
    <div className='content__buttons'>
        <AuthorizationAny authorizationObjects={eventCreateUpdateAuthorizationArray}>
            <Dropdown button text='Selection'>
                <Dropdown.Menu direction='left'>
                    <Authorization authorization={presetUpdateAuthorizationObject}>
                        <Dropdown.Item
                            text={'Apply preset'}
                            data-idkey={'applyPresetToEvents'}
                            onClick={applyPresetModalOpen}
                        />
                    </Authorization>
                    <Authorization authorization={srEventCreateAuthorizationObject}>
                        <Dropdown.Item
                            text={'Create Sportradar events'}
                            data-idkey={'createSportradarEvents'}
                            onClick={createSportradarEventsFormOpen}
                        />
                    </Authorization>
                </Dropdown.Menu>
            </Dropdown>
        </AuthorizationAny>
    </div>
);

EventChildEventsHeaderButtons.propTypes = {
    createSportradarEventsFormOpen: PropTypes.func,
    applyPresetModalOpen: PropTypes.func,
};

