import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';
import {
    SECURITY_PRIVILEGES_UPDATE,
    TERRITORY_SELECTION_TYPE_BLACKLIST,
    TERRITORY_SELECTION_TYPE_WHITELIST,
} from '@constants/variables';
import {GetTournamentsFilteredByTournamentCategories} from '@graphql/tournament/query';
import {GetTournamentCategoriesFilteredBySports} from '@graphql/tournamentCategory/query';

import {deleteTournament} from '../utils/contentRestrictions';
import client from '../../../apolloClient';

class TournamentForm extends DefaultForm {
    renderDeleteButton = () => null

    prepareDataForSubmit(data) {
        const tournamentsToSave = _get(this.props, 'GraphQLOptionsData.lcrChannel.tournaments', [])
            .map(item => {
                if (item.id === this.props.formParams.id) {
                    return data.tournament;
                }

                return item.id;
            });

        tournamentsToSave.push(data.tournament);

        return {...data,
            id: this.props.formParams.optionsVariables.lcrId,
            tournaments: tournamentsToSave,
        };
    }

    getTournamentField = (tournamentCategory) => {
        this.setField('tournament', {
            disabled: true,
            loading: true,
        });

        client.query({
            query: GetTournamentsFilteredByTournamentCategories,
            variables: {
                tournamentCategories: [convertToInt(tournamentCategory)],
            },
        }).then((response) => {
            let restrictedTournaments = _get(this.props, 'GraphQLOptionsData.lcrChannel.tournaments', [])
                .map(item => item.id);

            if (this.props.formParams.id) {
                restrictedTournaments = [];
            }

            this.setField('tournament', {
                options: response.data.tournaments.filter(
                    (item) => {
                        return -1 >= restrictedTournaments.indexOf(item.id);
                    }),
                loading: false,
                disabled: false,
            });
        });
    }

    componentDidMount() {
        if (this.props.formParams.id) {
            this.setField('sport', {disabled: true});
        }

        this.setField(['tournament_category', 'tournament'], {
            disabled: true,
        });

        this.setOnChangeCallback({
            'sport': (sportField) => {
                this.setField(['sport', 'tournament_category', 'tournament'], {disabled: true});
                this.setField('tournament_category', {loading: true});

                const lcrChannel = _get(this.props, 'GraphQLOptionsData.lcrChannel', {});

                client.query({
                    query: GetTournamentCategoriesFilteredBySports,
                    variables: {
                        sports: [convertToInt(sportField.value)],
                    },
                }).then((response) => {
                    const tournamentCategories = {};

                    lcrChannel.lcr_channel_tournament_categories.forEach((item) => {
                        if (tournamentCategories[item.tournament_category.sport.id]) {
                            tournamentCategories[item.tournament_category.sport.id].push(item.tournament_category.id);
                        } else {
                            tournamentCategories[item.tournament_category.sport.id] = [item.tournament_category.id];
                        }
                    });

                    let options = response.data.tournamentCategories;

                    if (TERRITORY_SELECTION_TYPE_WHITELIST === convertToInt(lcrChannel.tournament_category_selection_type.id)) {
                        options = options.filter((item) => {
                            return tournamentCategories[sportField.value] && -1 < tournamentCategories[sportField.value].indexOf(item.value);
                        });
                    }

                    if (TERRITORY_SELECTION_TYPE_BLACKLIST === convertToInt(lcrChannel.tournament_category_selection_type.id)) {
                        options = response.data.tournamentCategories.filter((item) => {
                            return !(tournamentCategories[sportField.value] && (-1 < tournamentCategories[sportField.value].indexOf(item.value)));
                        });
                    }

                    this.setField('tournament_category',{options});

                }).finally(() => {
                    this.setField(['sport', 'tournament_category'], {disabled: false, loading: false});
                });
            },
            'tournament_category': (tournamentField) => {
                this.getTournamentField(tournamentField.value);
            },
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelTournamentContent');
        });

        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelTournamentContent');
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetLcrChannelTournamentContent');
        });
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const lcrChannelOptions = _get(nextProps, 'GraphQLOptionsData.lcrChannel', null);

        let options = [];

        if (lcrChannelOptions) {
            const sportSelectionType = _get(lcrChannelOptions, 'sport_selection_type.id', null);

            switch (convertToInt(sportSelectionType)) {
                case TERRITORY_SELECTION_TYPE_WHITELIST:
                    options = _get(lcrChannelOptions, 'lcr_channel_sports', []).map((item) => item.sport);
                    break;
                case TERRITORY_SELECTION_TYPE_BLACKLIST: {
                    const lcrChannelSportBlacklist = _get(nextProps, 'GraphQLOptionsData.lcrChannel.lcr_channel_sports', []).map((item) => item.sport.id);
                    options = _get(nextProps, 'GraphQLOptionsData.sports', []).filter((item) => {
                        return -1 >= lcrChannelSportBlacklist.indexOf(item.id);
                    });
                }
                    break;
                default:
                    options = _get(nextProps, 'GraphQLOptionsData.sports', []);
                    break;
            }

            this.setField('sport', {
                options,
            });
        }
    }

    deleteEntity = () => {
        deleteTournament({
            lcrId: this.props.formParams.optionsVariables.lcrId,
            id: this.props.formParams.id,
        });
    }

    customAuthorization = (userResourcePrivilages) => (
        (userResourcePrivilages & SECURITY_PRIVILEGES_UPDATE) > 0
    )
}

export default TournamentForm;
