import DefaultForm from "../../app/components/DefaultForm";

import {convertToInt} from "../../../utils/helpers";

export class EventContentChangeEncodingStatusForm extends DefaultForm {
    renderDeleteButton = () => null;

    prepareDataForSubmit(data) {
        return {
            id: convertToInt(data.id),
            encoding_status: convertToInt(data.encoding_status),
        };
    }
}

export default EventContentChangeEncodingStatusForm;
