import validators from '../../../utils/validators';
import {ORIGIN_HOSTING_PROVIDER_AMAZON} from "../../app/constants/variables";

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.host, 'host'));
    Object.assign(errors, validators.required(values.location, 'location'));
    Object.assign(errors, validators.required(values.rtmp_origin_entry_point_type, 'rtmp_origin_entry_point_type'));
    Object.assign(errors, validators.required(values.origin_hosting_provider, 'origin_hosting_provider'));
    Object.assign(errors, validators.time(values.start_time, 'start_time'));
    Object.assign(errors, validators.time(values.stop_time, 'stop_time'));

    if (ORIGIN_HOSTING_PROVIDER_AMAZON === parseInt(values.origin_hosting_provider)) {
        Object.assign(errors, validators.required(values.amazon_instance, 'amazon_instance'));
        Object.assign(errors, validators.required(values.amazon_region, 'amazon_region'));
    }

    return errors;
};

export default validate;
