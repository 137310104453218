import EventBookingClipsEditModel from '@modules/eventBooking/forms/EventBookingClipsEditModel';

export default function ClientEventBookingClipsEditModel() {
    let clientEventBookingClipsEditModel = EventBookingClipsEditModel();

    clientEventBookingClipsEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    clientEventBookingClipsEditModel.editForm = true;
    clientEventBookingClipsEditModel.mutationOptions = {
        delete: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
        update: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
    };

    return clientEventBookingClipsEditModel;
}
