import ContentCategoryModel from "./ContentCategoryModel";

import {getSuffixWithNumber} from "../../../utils/ordinalNumberSuffix";

export default function ContentCategoryModelEdit() {
    const contentCategoryModelEdit = ContentCategoryModel();

    contentCategoryModelEdit.title = (data, type, props) => (
        `Edit ${getSuffixWithNumber(props.formParams.level)} level category`
    );

    return contentCategoryModelEdit;
}
