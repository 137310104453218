import React from 'react';
import PropTypes from 'prop-types';

import RenderDeviceCategoriesCheckboxes from "./RenderDeviceCategoriesCheckboxes";

const RenderDeviceCategoriesField = (props) => {
    return (
        <div>
            <label className={(props.meta.touched && !props.meta.valid) ? "error" : ""}>
                {props.label}{props.required && "*"}
            </label>
            <div>
                {RenderDeviceCategoriesCheckboxes(props.deviceCategories, props.defaultValue)}
                <div className="error info">{(props.meta.touched && !props.meta.valid) ? props.meta.error : ''}</div>
            </div>
        </div>
    )
};

RenderDeviceCategoriesField.propTypes = {
    defaultValue: PropTypes.array.isRequired,
    deviceCategories: PropTypes.array.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    meta: PropTypes.object
};
RenderDeviceCategoriesField.defaultProps = {
    label: "Device categories",
};

export default RenderDeviceCategoriesField;
