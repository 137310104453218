import Link from "../../app/components/Link";
import React from 'react';

import {Authorization} from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";

import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";

const authorizationObject = {
    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_CREATE_READ
};

const PropertyLicenceIndexButtons = () => (
    <Authorization authorization={authorizationObject}>
        <div className="content__buttons">
            <Link name="propertyLicences.add">
                <ButtonAdd>Add new property licence</ButtonAdd>
            </Link>
        </div>
    </Authorization>
);

export default PropertyLicenceIndexButtons;
