import PropTypes from "prop-types";

export const exportExcel = (dataExport, propertyLicenceId) => ({
    dataExport: dataExport,
    filename: `property_licence_${propertyLicenceId}_event_presets`,
    parseData: eventPreset => [
        eventPreset.id,
        eventPreset.name,
        eventPreset.event.id,
    ],
    path: 'propertyLicences.eventPresets.index.path',
    titles: [
        'ID',
        'Name',
        'Reference event ID',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    propertyLicenceId: PropTypes.number.isRequired,
};
