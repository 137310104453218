import React from "react";
import PropTypes from "prop-types";
import {Button, Icon} from "semantic-ui-react";

export const EventChildNotificationHeaderButton = ({
    showMasterEventNotificationModal = () => null,
    isHidden = false
}) => (
    <div className="content__buttons">
        {
            !isHidden && <Button hide={isHidden} onClick={showMasterEventNotificationModal}>
                <Icon className="envelope outline"/>Send notification
            </Button>
        }
    </div>
);

EventChildNotificationHeaderButton.propTypes = {
    showMasterEventNotificationModal: PropTypes.func,
    isHidden: PropTypes.bool
}
