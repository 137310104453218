import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";

import RtmpOriginEntryPointForm from '../forms/RtmpOriginEntryPointForm';
import RtmpOriginEntryPointModel from "../forms/RtmpOriginEntryPointModel";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";

const RtmpOriginEntryPointEdit = ({match: {params: {id = null}}}) => {
    const Form = React.useMemo(
        () => (createForm(RtmpOriginEntryPointModel, RtmpOriginEntryPointForm, {id: convertToInt(id)})),
        [id]
    );

    return <Form/>;
};

RtmpOriginEntryPointEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    })
};

export default withRouter(RtmpOriginEntryPointEdit);
