import React from 'react';

import apolloClient from "../../../../apolloClient";

import urls from '../../../app/constants/urls';

import {GetCountryByIp} from '../../../../graphql/country/query.graphql';

export const prepareIpDataRow = (ipData) => {
    return ipData.ipAddress && ipData.country ? `${ipData.ipAddress} (${ipData.country})` : 'Not detected';
};

export const ipInfoDataForTooltip = (ipV4, ipV6) => {
    const ipV4Data = prepareIpDataRow(ipV4),
        ipV6Data = prepareIpDataRow(ipV6);

    return (
        <div>
            <ul>
                <li><b>IPv4:</b> {ipV4.isErrorOccurred ? 'Error occurred' : ipV4Data}</li>
                <li><b>IPv6:</b> {ipV6.isErrorOccurred ? 'Error occurred' : ipV6Data}</li>
            </ul>
        </div>
    );
};

export const getUserCountryByIp = async (clientIp) => {
    return apolloClient.query({
        query: GetCountryByIp,
        variables: {
            clientIp: clientIp,
        },
    });
};

export const getClientIpV4 = () => {
    return getClientIp(
        urls.services.getIpV4,
        /clientip>([\d+.]+)<\/clientip/
    );
};

export const getClientIpV6 = () => {
    return getClientIp(
        urls.services.getIpV6,
        /clientip>([0-9a-f:]+)<\/clientip/
    );
};

export const getClientIpV6orV4 = async () => {
    const ipV6 = await getClientIpV6();

    if (ipV6) {
        return ipV6;
    }

    const ipV4 = await getClientIpV4();

    if (ipV4) {
        return ipV4;
    }

    return "";
};

const getClientIp = async (getIpUrl, ipRegex) => {
    try {
        const responseObject = await fetch(getIpUrl);

        if (!responseObject) {
            return null;
        } else {
            const responseText = await responseObject.text();

            return responseText.match(ipRegex) ? responseText.match(ipRegex)[1] : '';
        }
    } catch (err) {
        return null;
    }
};
