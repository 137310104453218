import {
    get as _get,
} from 'lodash';

import {getIdsFromChildren} from '@utils/helpers';
import GeoRestrictionsForm from '@modules/geoRestriction/forms/GeoRestrictionsForm';

export class LcrGeoRestrictionsForm extends GeoRestrictionsForm {
    prepareDataForSubmit = (data) => {
        return {
            id: data.id,
            territory_selection_type: data.selection_type || null,
            countries: getIdsFromChildren(data.countries),
            country_subdivisions: getIdsFromChildren(data.subdivisions),
        };
    }

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'lcrChannel', {});
    };

    renderCancelButton = () => null;
    renderDeleteButton = () => null;
    renderAdditionalButtons = () => null;

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );
}
