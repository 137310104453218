import PropTypes from 'prop-types';
import React from 'react';

import StepsContent from '@appComponents/StepsContent';
import {cidrFormTabs} from '@utils/CidrHelpers';
import {createForm} from '@utils/forms';

import IpBlacklistingForm from '../forms/IpBlacklistingForm';
import IpBlacklistingModel from '../forms/IpBlacklistingModel';

export function IpBlacklistingEdit({setMutationSuccessCallback}) {
    const content = createForm(
            IpBlacklistingModel,
            IpBlacklistingForm,
            {
                setMutationSuccessCallback,
            }
        ),
        tabs = cidrFormTabs(content);

    return <StepsContent
        tabs={tabs}
        hideHeader={true}
        hideMenu={true}
        title={'Add new CIDR'}
    />;
}

IpBlacklistingEdit.propTypes = {
    setMutationSuccessCallback: PropTypes.func.isRequired,
};

IpBlacklistingEdit.displayName = 'IpBlacklistingEdit';

export default IpBlacklistingEdit;
