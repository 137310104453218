export const changeLogRevisionFilterQuery = `
query ChangelogRevisionFilterQuery (

  $from: DatetimeTzInputType
  $to: DatetimeTzInputType
  $elementId: Int
  $elementName: String
  $actionTypes: [ID]
  $securedResources: [ID]
  $users: [ID]
)
{
  changelogRevisions (filters: {
    from: $from
    to: $to
    elementId: $elementId
    elementName: $elementName
    actionTypes: $actionTypes
    securedResources: $securedResources
    users: $users
  }) {
    id
    secured_resource {
      id
      name
      path
    }
    changelog_action_type {
      id
      name
    }
    element_id
    element_name
    datetime
    user {
      id
      name
    }
    ip
  }
}`;

export const changeLogFilterQuery = `
query changeLogFilters{
  securedResources {
    id
    value : id,
    text : name
  }
  changelogActionTypes {
    id
    value : id,
    text : name
  }
  changelogUsers {
    id
    value: id
    text: name
  }
}`;