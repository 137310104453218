import {get as _get, has as _has} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {showErrorMessage, showSuccessMessageAfterMutation} from '@utils/CidrHelpers';
import {convertToInt} from '@utils/helpers';
import {GetWhitelistOverlapInBlackListedIp} from '@graphql/clientWhitelistedIp/query';

import WhitelistedIpModel from './WhitelistedIpModel';
import WhitelistedIpFormConfirmation from '../components/WhitelistedIpFormConfirmation';

export class WhitelistedIpForm extends DefaultForm {
    static propTypes = {
        formParams: PropTypes.shape({
            id: PropTypes.string,
            optionsVariables: PropTypes.shape({
                client: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            }),
            setMutationSuccessCallback: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {
        formParams: {
            id: null,
            optionsVariables: {
                client: null,
            },
        },
    };

    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            showSuccessMessageAfterMutation(
                'whitelistedIpMessage',
                _get(response, this.getCidrPath(response, 'create'), null)
            );
            this.setMutationSuccess();
        });

        this.setCreateErrorCallback(() => {
            this.setState(() => ({defaultForm_loading: false}));
            showErrorMessage(
                WhitelistedIpModel().messages.boxName.error,
                _get(this.props, 'formValues.cidr', null)
            );
        });

        this.setUpdateSuccessCallback((response) => {
            showSuccessMessageAfterMutation(
                'whitelistedIpMessage',
                _get(response, this.getCidrPath(response, 'update'), null)
            );
            this.setMutationSuccess();
        });

        this.setUpdateErrorCallback(() => {
            this.setState(() => ({defaultForm_loading: false}));
            showErrorMessage(
                WhitelistedIpModel().messages.boxName.error,
                _get(this.props, 'formValues.cidr', null)
            );
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const {
                GraphQLEntityData,
                GraphQLOptionsData
            } = this.props,
            isLoading = (GraphQLEntityData && GraphQLEntityData.loading) || GraphQLOptionsData.loading;

        if (!isLoading && !this.state.areProductsDropdownGenerated) {
            this.setDefaultValueForProductDropdown(_get(GraphQLEntityData, 'clientWhitelistedIp.products', []));
        }
    }

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            this.props.Model.label,
            getLink('clients.whitelistedIps.index', {id: this.getClientId()}),
            {size: 'tiny'}
        );
    }

    getClientId = () => {
        return _get(this.props, 'formParams.optionsVariables.client', null);
    };

    setDefaultValueForProductDropdown = (products) => {
        this.setField('products', {
            defaultValue: products.map((product) => (
                product.id
            )),
        });

        this.setState(() => ({areProductsDropdownGenerated: true}));
    };

    prepareDataForSubmit = (data) => (
        Object.assign({}, data, {
            client: this.getClientId(),
            ip_version: convertToInt(data.ip_version || null),
            description: data.description ? data.description : null,
        })
    );

    onFormSubmit(data) {
        this.setState(() => ({
            defaultForm_loading: true,
        }));

        if (_has(data, 'id')) {
            super.onFormSubmit(data);
        } else {
            this.runApolloRequest('query', {
                fetchPolicy: 'network-only',
                query: GetWhitelistOverlapInBlackListedIp,
                variables: {
                    cidr: data.cidr
                }
            }).then((response) => {
                const overlappedBlackListedIps = _get(response, 'data.overlappingBlacklistedIps', []);

                if (0 < overlappedBlackListedIps.length) {
                    data.client = this.getClientId();
                    this.setState(() => ({loading: false}));

                    this.setConfirmationStep(overlappedBlackListedIps, data);
                    this.setState(() => ({defaultForm_loading: false}));
                } else {
                    super.onFormSubmit(data);
                }
            });
        }
    }

    setConfirmationStep(overlappedBlackListedIps, data) {
        this.props.stepsMethods.setStep({
            id: 'step2',
            menuItem: {content: '', hidden: true},
            pane: {
                content: <WhitelistedIpFormConfirmation
                    CreateWhitelistedIp={this.props.CreateWhitelistedIp}
                    dataToSave={data}
                    overlappingClientWhitelistedIps={overlappedBlackListedIps}
                    setMutationSuccess={this.setMutationSuccess}
                    isAvApiAccess={this.props.formParams.isAvApiAccess}
                />,
            }
        });
        this.props.stepsMethods.showStep('step2');
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderDeleteButton = () => null;

    setMutationSuccess = () => {
        this.props.formParams.setMutationSuccessCallback(Date.now());
    };

    getCidrPath(response, operationType) {
        if (response.data[`${operationType}ClientWhitelistedIp`]) {
            return `data.${operationType}ClientWhitelistedIp.cidr`;
        } else if (response.data[`${operationType}ClientAvapiWhitelistedIp`]) {
            return `data.${operationType}ClientAvapiWhitelistedIp.cidr`;
        }

        return null;
    }
}

export default WhitelistedIpForm;
