import PropTypes from 'prop-types';
import React from 'react';
import {Field} from "redux-form";

import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";
import Form from "../../app/components/ReduxFormControls";

const EventMasterBookingsFilterComponent = (props) => {
    return (
        <div className="eventMasterBookings__filters filter__controls__container">
            <Field
                component={Form.ReduxSelect}
                name="clients"
                placeholder="Client"
                options={props.data.clients}
                search
                loading={props.loading}
                multiple
                className="medium"
                disabled={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventMasterBookings, 'clients')}
            />
            <Field
                component={Form.ReduxSelect}
                name="products"
                placeholder="Product"
                options={props.data.products}
                search
                loading={props.loading}
                multiple
                disabled={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventMasterBookings, 'products')}
            />
            <Field
                className="--customWidth --deviceCategories"
                component={Form.ReduxSelect}
                name="deviceCategories"
                placeholder="Device category"
                options={props.data.deviceCategories}
                search
                loading={props.loading}
                multiple
                disabled={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventMasterBookings, 'deviceCategories')}
            />
            <Field
                component={Form.ReduxSelect}
                name="countries"
                placeholder="Country"
                options={props.data.countries}
                search
                loading={props.loading}
                multiple
                disabled={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventMasterBookings, 'countries')}
            />
            <Field
                component={Form.ReduxSelect}
                name="bookingTypes"
                placeholder="Type"
                options={props.data.bookingTypes}
                search
                loading={props.loading}
                multiple
                disabled={props.loading}
                setValue={props.restoreFilterValues(props.filters.EventMasterBookings, 'bookingTypes')}
            />
        </div>
    )
};

EventMasterBookingsFilterComponent.propTypes = {
    data: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func,
};

export default FiltersWrapper(EventMasterBookingsFilterComponent, {name: "EventMasterBookings"});
