import React from "react";
import PropTypes from "prop-types";
import {Header} from "semantic-ui-react";

import Authorization from "../../../app/components/Authorization";
import Link from "../../../app/components/Link";
import {ButtonAdd} from "../../../app/components/ButtonCollection";

import {CLIENT_PRODUCT_GEOLOCATION_COUNTRY} from "../../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE} from "../../../app/constants/variables";

const ButtonHeader = (props) => {
    const {clientId, productShortName} = props;

    return (
        <Header>
            <div className="productGeolocations__indexButtons content__buttons">
                <Authorization resources={CLIENT_PRODUCT_GEOLOCATION_COUNTRY} privileges={SECURITY_PRIVILEGES_CREATE}>
                    <Link name={`clients.products.${productShortName}.geolocation.add`} params={{id: clientId}}>
                        <ButtonAdd>Add new country</ButtonAdd>
                    </Link>
                </Authorization>
            </div>
        </Header>
    );
};

ButtonHeader.propTypes = {
    clientId: PropTypes.number.isRequired,
    productShortName: PropTypes.string.isRequired,
};
ButtonHeader.displayName = 'ButtonHeader';

export default ButtonHeader;
