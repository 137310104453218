import PropTypes from 'prop-types';
import React from 'react';
import {Dimmer, Divider, Grid, Loader} from 'semantic-ui-react';

import {convertToInt} from '@utils/helpers';
import {IconDelete, IconInfoCircleChangelog} from '@appComponents/IconCollection';
import {Authorization, hasPrivileges} from '@appComponents/Authorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

import RightScopeStreamConstraintsForm from '../forms/RightScopeStreamConstraintsForm';

const columns = {
    product: {label: 'Product', width: 2},
    device_category: {label: 'Device category', width: 2},
    max_bitrate: {label: 'Max bitrate', width: 2},
    max_resolution: {label: 'Max resolution', width: 3},
    max_player_size: {label: 'Max player size', width: 2},
    actions: {label: '', width: 5},
};

class RightScopeStreamConstraintsTable extends React.Component {
    state = {
        activeEditableFields: null,
        authorization: {
            update: hasPrivileges({[RESOURCES.PROPERTY_LICENCE_STREAM_CONSTRAINT]: CONST.SECURITY_PRIVILEGES_UPDATE}),
            create: hasPrivileges({[RESOURCES.PROPERTY_LICENCE_STREAM_CONSTRAINT]: CONST.SECURITY_PRIVILEGES_CREATE}),
        },
    };

    activateEditableCell = (event) => {
        this.setState({
            activeEditableFields: convertToInt(event.currentTarget.dataset.id),
        });
    };

    deactivateEditableCell = () => {
        this.setState({
            activeEditableFields: null,
        });
    };

    renderHeader = () => {
        const cells = Object.keys(columns).map((keys) => {
            return (
                <Grid.Column key={keys} width={columns[keys].width}>
                    {columns[keys].label}
                </Grid.Column>
            );
        });

        return (
            <Grid className='editableTable__header'>
                <Grid.Row>
                    {cells}
                </Grid.Row>
            </Grid>
        );
    };

    renderButtonsForEditableRowInViewMode = (rightsScopeStreamConstraint) => {
        return (
            <div className='actions-list-icon'>
                <Authorization authorization={{[RESOURCES.PROPERTY_LICENCE_STREAM_CONSTRAINT]: CONST.SECURITY_PRIVILEGES_DELETE}}>
                    <IconDelete icon='trash'
                        onClick={this.props.onDelete}
                        onClickElement={rightsScopeStreamConstraint.id}
                        data-id={rightsScopeStreamConstraint.id}
                    />
                </Authorization>
                <IconInfoCircleChangelog
                    tooltipProps={{hoverable: true, position: 'top right'}}
                    resources={RESOURCES.PROPERTY_LICENCE_STREAM_CONSTRAINT}
                    data={rightsScopeStreamConstraint}
                />
            </div>
        );
    };

    renderEditableGrids = () => {
        return this.props.rightsScopeStreamConstraints.map((data, index) => {
            return (
                [
                    <Divider key={index}/>,
                    (this.state.activeEditableFields === convertToInt(data.id))
                        ? this.renderEditForm(data)
                        : this.renderEditableGrid(data),
                ]
            );
        });
    };

    renderEditableGrid = (data) => {
        const gridClass = this.state.authorization.update ? 'editableTable__trigger' : '',
            onClickCallback = this.state.authorization.update ? this.activateEditableCell : null;

        return (
            <Grid key={`editable_grid_${data.id}`} verticalAlign='middle'>
                <Grid.Row  data-id={data.id} onClick={onClickCallback} className={gridClass}>
                    <Grid.Column width={2} >
                        {data.product.name}
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {data.device_category.name}
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {data.max_bitrate ? `${data.max_bitrate} Kbps` : ''}
                    </Grid.Column>
                    <Grid.Column width={3} >
                        {data.max_resolution_width || data.max_resolution_height
                            ? <span>{data.max_resolution_width } x {`${data.max_resolution_height} px` || '–'}</span>
                            : ''
                        }
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {data.max_player_size ? `${data.max_player_size}%` : ''}
                    </Grid.Column>
                    <Grid.Column width={5} >
                        {this.renderButtonsForEditableRowInViewMode(data)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    renderAddForm = () => {
        const maxPossibilities = this.props.products.length * this.props.deviceCategories.length;

        if (maxPossibilities === this.props.rightsScopeStreamConstraints.length) {
            return null;
        }

        return (
            <RightScopeStreamConstraintsForm
                formData={{
                    deviceCategories: this.props.deviceCategories,
                    usedProductsAndCategories: this.props.rightsScopeStreamConstraints,
                    products: this.props.products,
                }}
                form='form_rssc_add'
                onAdd={this.props.onAdd}
                loading={this.props.loading}
            />
        );
    };

    renderEditForm = (data) => {
        return (
            <RightScopeStreamConstraintsForm
                form='form_rssc'
                loading={this.props.loading}
                formData={{
                    deviceCategories: this.props.deviceCategories,
                    products: this.props.products,
                    usedProductsAndCategories: this.props.rightsScopeStreamConstraints,
                    rightsScopeStreamConstraints: {
                        id: data.id,
                        product: data.product,
                        device_category: data.device_category,
                        max_bitrate: data.max_bitrate,
                        max_resolution_width: data.max_resolution_width,
                        max_resolution_height: data.max_resolution_height,
                        max_player_size: data.max_player_size,
                    },
                }}
                initialValues={{
                    product: data.product.id,
                    device_category: data.device_category.id,
                }}
                key={`edit_form_${data.id}`}
                onUpdate={this.props.onUpdate}
                onDeactivateEditableCell={this.deactivateEditableCell}
            />
        );
    };

    render() {
        return (
            <div>
                <Dimmer inverted active={this.props.loading}>
                    <Loader/>
                </Dimmer>
                {this.renderHeader()}
                {this.state.authorization.create && this.renderAddForm()}
                {this.renderEditableGrids()}
            </div>
        );
    }
}

RightScopeStreamConstraintsTable.defaultProps = {
    deviceCategories: [],
    rightsScopeStreamConstraints: [],
    products: [],
};

RightScopeStreamConstraintsTable.propTypes = {
    deviceCategories: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    rightsScopeStreamConstraints: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(PropTypes.object),
};

export default RightScopeStreamConstraintsTable;
