import PropTypes from "prop-types";
import React from "react";

import {Authorization} from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";

import {THIRD_PARTY_CLIENT_CDN} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE} from "../../app/constants/variables";

const ThirdPartyCdnsIndexButtons = ({client}) => (
    <Authorization authorization={{[THIRD_PARTY_CLIENT_CDN]: SECURITY_PRIVILEGES_CREATE}}>
        <Link name={"clients.products.thirdParty.cdns.add"} params={{id: client}}>
            <div className="content__buttons">
                <ButtonAdd>Add new CDN</ButtonAdd>
            </div>
        </Link>
    </Authorization>
);

ThirdPartyCdnsIndexButtons.propTypes = {
    client: PropTypes.number.isRequired,
};

export default ThirdPartyCdnsIndexButtons;
