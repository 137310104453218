import EventContentTargetModel from "./EventContentTargetModel";

import {GetEncodingTarget} from "../../../graphql/encodingTarget/query.graphql";

export default function EventContentTargetEditModel() {
    let eventContentTargetModel = EventContentTargetModel();

    eventContentTargetModel.title = `Edit Encoding Target`;
    eventContentTargetModel.query.getEntity = GetEncodingTarget;

    eventContentTargetModel.fields.encoder_type.props.disabled = true;
    eventContentTargetModel.fields.distribution.props.disabled = true;
    eventContentTargetModel.fields.stream_delay_type.props.disabled = true;

    return eventContentTargetModel
}
