import configuredStore from '../../../../src/store';
import {setModalError} from "../../../actions";

const setErrorModal = (data) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(setModalError(data));
        }
    )
};

export const showErrorNoMatchesSelected = () => {
    setErrorModal({
        header: "Invalid selection to create Sportradar events",
        text: "There are no matches selected.",
        isVisible: true,
        size: 'large',
    })
};

export const showErrorWrongMatchesSelected = () => {
    setErrorModal({
        header: "Invalid selection to create Sportradar events",
        text: "Multi event creation is possible only for a selection of matches which have media rights through " +
            "at least one common property licence.\n Please correct your selection.",
        isVisible: true,
        size: 'large',
    })
};
