import * as actionTypes from '../actions/actionTypes';
import {get as _get} from "lodash";

export const initialState = {debugMode: false};

const streamPlaybackReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_STREAM_PLAYBACK_DEBUG_MODE:
            return Object.assign({}, state, {debugMode: !state.debugMode});
        case "persist/REHYDRATE": {
            const savedData = _get(action, "payload.app.streamPlayback", false) || initialState;

            return {
                ...savedData
            };
        }
        default:
            return state;
    }
};

export default streamPlaybackReducer;
