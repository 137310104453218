
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MediaConnectOriginEntryPointData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MediaConnectOriginEntryPoint"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"encoding_datacenter"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"clients"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"products"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"mediaconnect_origin_feeds"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"feed_number"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"has_cc"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"has_scte35"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"has_scte104"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":414}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment MediaConnectOriginEntryPointData on MediaConnectOriginEntryPoint {\n    ...BasicData\n    description\n    encoding_datacenter {\n        ...BasicData\n    }\n    clients {\n        ...BasicData\n    }\n    products {\n        ...BasicData\n    }\n    mediaconnect_origin_feeds {\n        id\n        url\n        feed_number\n        has_cc\n        has_scte35\n        has_scte104\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
