import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';

import {convertToInt} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Authorization from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {getCountriesAndSubdivisionsWithTooltip} from '@utils/countryHelpers';
import {showMessageBox} from '@utils/messageBox';
import {renderModalError} from '@utils/forms';

const authorizationObject = {
    [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ,
};

export const prepareStringForExcelSorting = (row) => (row.name);

export const showErrorNotificationMessage = () => {
    showMessageBox(
        'BlackoutZoneIndex',
        'Failed to fetch filter data',
        'There was some error while fetching data for filters',
        'error',
        true
    );
};

export const renderBlackoutZonesModalError = (message) => (
    renderModalError(
        'Blackout Zones',
        getLink('blackoutZones.index'),
        message
    )
);
renderBlackoutZonesModalError.displayName = 'renderBlackoutZonesModalError';

export const blackoutZoneColumns = {
    'id': {
        label: 'ID',
        sorting: row => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
            className: '--noPadding',
        },
        content: {align: 'center'},
    },
    'name': {
        label: 'Name',
        sorting: row => row.name,
        header: {
            align: 'left',
            isCollapsed: true,
        },
        content: {align: 'left'},
        className: '--width-250px',
    },
    'countries_and_subdivision': {
        label: 'Countries and Subdivisions',
        sorting: row => renderCountriesForExcelExport(row),
        header: {
            align: 'left',
            isCollapsed: true,
        },
        content: {align: 'left'},
        className: '--width-400px',
    },
    'postal_code': {
        label: '#Postal codes',
        sorting: row => convertToInt(row.postal_codes_count),
        header: {
            align: 'left',
            isCollapsed: true,
        },
        content: {align: 'left'},
        className: '--width-250px',
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
            className: '--noPadding',
        },
        content: {align: 'center'},
    },
};

export const renderLinkToEditAndDescription = (row, isName = false) => {
    return (
        <div>
            <LinkWithAuthorization
                authorization={authorizationObject}
                link={{
                    name: 'blackoutZones.edit.generalInformation',
                    params: {id: row.id},
                }}
            >
                {isName ? row.name : row.id}
            </LinkWithAuthorization>
            {
                isName && !_isEmpty(row.description) ? <p className='muted'>{row.description}</p> : null
            }
        </div>
    );
};

export const getCountriesFromPostalCodes = (postalCodes) => {
    const countries = [];

    postalCodes.forEach((postalCode) => {
        if (!countries.includes(postalCode.country)) {
            countries.push(postalCode.country);
        }
    });

    return countries;
};

export const getCountriesNames = (countries) => {
    const countriesNames = [];

    for (const country of countries) {
        if (-1 === countriesNames.indexOf(country.name)) {
            countriesNames.push(country.name);
        }
    }

    return countriesNames.sort();
};

export const renderPostalCodes = (row) => {
    return convertToInt(row.postal_codes_count);
};

export const renderCountriesForExcelExport = (row) => {
    const countriesNames = [],
        subdivisions = {};

    for(const subdivision of row.country_subdivisions) {
        if (!subdivisions[subdivision.country.id]) {
            subdivisions[subdivision.country.id] = [];
        }
        subdivisions[subdivision.country.id].push(subdivision.name);
    }

    for(const country of row.countries) {
        let text = country.name;

        if (subdivisions[country.id]) {
            text += ` - (${subdivisions[country.id].join(', ')})`;
        }

        countriesNames.push(text);
    }

    return countriesNames.join(', ');
};

export const rowRenderer = (column, row, props) => {
    const {mappedIsoCodes} = props;

    switch (column) {
        case 'id': {
            return renderLinkToEditAndDescription(row);
        }
        case 'name': {
            return renderLinkToEditAndDescription(row, true);
        }
        case 'countries_and_subdivision': {
            return getCountriesAndSubdivisionsWithTooltip(row, mappedIsoCodes);
        }
        case 'postal_code': {
            return renderPostalCodes(row);
        }
        case 'actions': {
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            link={getLink('blackoutZones.edit.generalInformation', {id: row.id})}
                            resource={RESOURCES.BLACKOUT_ZONE}
                        />
                    </div>
                </Authorization>
            );
        }
        default: {
            return null;
        }
    }
};
