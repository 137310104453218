import React from 'react';
import PropTypes from "prop-types";

import {ValuesDashRedLabel} from "../../../../../../../utils/decorators";

export const getFieldsList = (event) => {
    return [
        {
            name: 'Cancelled',
            value: <ValuesDashRedLabel options={{value: event.sr_event.match.is_cancelled, valueColorRed: 'Yes'}} />
        },
        {
            name: 'Postponed',
            value: <ValuesDashRedLabel options={{value: event.sr_event.match.is_postponed, valueColorRed: 'Yes'}} />
        },
        {
            name: 'Walkover',
            value: <ValuesDashRedLabel options={{value: event.sr_event.match.is_walkover, valueColorRed: 'Yes'}} />
        },
    ];
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                is_cancelled: PropTypes.bool,
                is_postponed: PropTypes.bool,
                is_walkover: PropTypes.bool
            })
        })
    }).isRequired
};

getFieldsList.defaultProps = {
    event: {
        sr_event: {
            match: {
                is_cancelled: null,
                is_postponed: null,
                is_walkover: null
            }
        }
    }
}
