import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 100}, 'name'));

    Object.assign(errors, validators.required(values.stream_name, 'stream_name'));
    Object.assign(errors, validators.maxLength({value: values.stream_name, maxLength: 100}, 'stream_name'));

    return errors;
};

export default validate;
