import {get as _get} from "lodash";
import PropTypes from "prop-types";

import {renderModalError} from "../../../utils/forms";
import {getSuffixWithNumber} from "../../../utils/ordinalNumberSuffix";

import {routes} from "../../app/constants/routes";

export const renderContentCategoryModalError = () => {
    return renderModalError('Content category', routes.content_categories.index.path)
};
renderModalError.displayName = 'renderContentCategoryModalError';

export const prepareMessageText = (data = {}, errorFlag = false) => {
    const level = _get(data, 'formParams.level', 1),
        name = _get(data, 'formValues.name', ''),
        resultText = errorFlag ? 'not been saved' : 'been saved successfully';

    return `The ${getSuffixWithNumber(level)} level category "${name}" has ${resultText}.`;
}

prepareMessageText.propTypes = {
    data: PropTypes.object,
    error: PropTypes.bool
};
