import PropTypes from "prop-types";
import React from "react";

import {get as _get} from 'lodash';
import {getSportradarMasterEventOptionsQuery} from "../../../graphql/events/query.graphql";
import {
    CreateSportradarMasterEvent,
    DeleteSportradarEvent,
    UpdateSportradarMasterEvent
} from "../../../graphql/events/mutation.graphql"
import * as formUtils from "../utils/formHelper";
import * as MESSAGES from "../../app/constants/messages";
import {getEventDefaultFields} from "./EventModel";
import Form from "../../app/components/ReduxFormControls";
import EventTournamentProductMatrix from "../components/EventTournamentProductMatrix";
import validateForSportradarMasterEvent from "./validatorMasterEvent"
import {EVENT} from "../../app/constants/resources";

export default function SportradarMasterEventModel() {
    let renderText = (props) => {
        let text = _get(props, "data.text", "-");

        return <div>
            <label>{props.label}</label>
            <div>{text ? text : "-"}</div>
        </div>
    };

    renderText.propTypes = {
        label: PropTypes.string
    };

    let SportradarMasterEventModel = {
        formName: 'SportradarMasterEventModel',
        entityDataMapKey: "event",
        resources: EVENT,
        validate: validateForSportradarMasterEvent,
        title: (props, type, data) => {
            let masterMatchId = _get(data, "formParams.optionsVariables.masterMatchId",  null);

            return `Create Sportradar master event (master match ID ${masterMatchId})`
        },
        label: "Event",
        dataMap: {
            event: {
                property_licence: {
                    id: null
                },
                start_datetime: "",
                end_datetime: "",
                sr_event: {
                    synchronise_times: true
                },
            }
        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'event',
                error: 'event'
            },
            text: MESSAGES,
            delete: MESSAGES.EVENT_DELETE_STATUS_TEXT_OBJECT,
            create: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT,
            update: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT
        },
        fields: {
            property_licence: {
                dataMapKey: "event.property_licence.id",
                optionsKey: 'propertyLicencesForMasterMatch',
                props: {
                    component: formUtils.renderPropertyLicenceField,
                    required: true,
                    label: "Property licence",
                    name: "property_licence",
                    placeholder: "Select property licence",
                    type: "select"
                }
            },
            sport: {
                props: {
                    component: renderText,
                    label: "Sport",
                    name: "sport"
                }
            },
            place: {
                props: {
                    component: renderText,
                    label: "Venue",
                    name: "place"
                }
            },
            tournament: {
                props: {
                    component: EventTournamentProductMatrix,
                    label: "Tournaments",
                    required: true,
                    name: "tournament_products"
                }
            },
            synchronise_times: {
                dataMapKey: "event.sr_event.synchronise_times",
                props: {
                    component: Form.SemanticInput,
                    description: null,
                    defaultValue: true,
                    label: 'Synchronise the times with Sportradar?',
                    name: 'synchronise_times',
                    type: 'checkbox',
                }
            }
        },
        query: {
            getOptions: getSportradarMasterEventOptionsQuery,
        },
        mutation: {
            createEntity: CreateSportradarMasterEvent,
            updateEntity: UpdateSportradarMasterEvent,
            deleteEntity: DeleteSportradarEvent
        }
    };

    Object.assign(SportradarMasterEventModel.fields, getEventDefaultFields());

    delete SportradarMasterEventModel.fields.master;

    SportradarMasterEventModel.fields.multiple[0].description.props.required = true;
    SportradarMasterEventModel.fields.end_datetime.props.required = true;

    return SportradarMasterEventModel
}
