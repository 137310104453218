import React from 'react';

import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import {Authorization} from '@appComponents/Authorization';
import Link from '@appComponents/Link';

const WnsStreamIndexButtons = () => (
    <div className='content__buttons'>
        <Authorization authorization={{[RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_CREATE}}>
            <Link name={'wnsStreams.add'}>
                <ButtonAdd>Add new World Number Service stream</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

export default WnsStreamIndexButtons;
