import {isEmpty as _isEmpty} from 'lodash';

export const prepareMediaconnectOriginFeeds = (data) => {
    const feeds = [
        {
            id: data.primary_feed.id,
            url: data.primary_feed,
            output_url: data.primary_feed_output_url || null,
            arn: data.primary_feed_arn || null,
            feed_number: 1,
            has_cc: data.primary_feed_close_captioning ? data.primary_feed_close_captioning : false,
            has_scte35: data.primary_feed_ad_support ? data.primary_feed_ad_support : false,
            ad_splice_config: data.primary_feed_ad_support && data.primary_feed_ad_splice_config
                ? data.primary_feed_ad_splice_config : null,
            has_scte104: data.primary_feed_feed_splice ? data.primary_feed_feed_splice : false,
        },
    ];

    if (!_isEmpty(data.secondary_feed)) {
        feeds[1] = {
            id: data.secondary_feed.id,
            url: data.secondary_feed || false,
            output_url: data.secondary_feed_output_url || null,
            arn: data.secondary_feed_arn || null,
            feed_number: 2,
            has_cc: data.secondary_feed_close_captioning ? data.secondary_feed_close_captioning :  false,
            has_scte35: data.secondary_feed_ad_support ?? false,
            ad_splice_config: data.secondary_feed_ad_support && data.secondary_feed_ad_splice_config
                ? data.secondary_feed_ad_splice_config : null,
            has_scte104: data.secondary_feed_feed_splice ? data.secondary_feed_feed_splice : false,
        };
    }

    return feeds;
};
