import {
    findIndex as _findIndex,
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {getGeoRestrictionsForDeviceCategories} from '@utils/countryHelpers';
import {
    DEVICE_CATEGORY_DESKTOP,
    DEVICE_CATEGORY_MOBILE,
    DEVICE_CATEGORY_SMART_TV,
} from '@constants/variables';
import {PreFillGeoRestrictions} from '@graphql/eventBooking/query';
import GeoRestrictionsForm from '@modules/geoRestriction/forms/GeoRestrictionsForm';
import {getCountryTreeOptions, getSubdivisionTreeOptions} from '@modules/events/utils/eventGeoRestriction';
import {getDeviceNameById} from '@modules/events/utils/eventsBookingGeoRestriction';

export class EventBookingGeoRestrictionsForm extends GeoRestrictionsForm {
    countryTreeOptions = null;
    subdivisionTreeOptions = null;
    ARRAY_WITH_DEVICES = [DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV];

    prepareDataForSubmit = (data) => {
        const preparedData = this.props.Model.editForm
            ? this.props.stepsMethods.getForms()['step1'].formValues
            : this.props.previousStepData;

        return Object.assign({}, {
            id: convertToInt(this.props.formParams.id),
            booking_type: convertToInt(preparedData.booking_type),
            client: convertToInt(preparedData.client),
            distribution: convertToInt(preparedData.distribution),
            client_package: preparedData.client_package ? convertToInt(preparedData.client_package) : null,
            price: null === preparedData.price ? null : parseFloat(preparedData.price),
            auto_update_price: null === preparedData.auto_update_price ? false : preparedData.auto_update_price,
            auto_update_geo_restrictions: preparedData.auto_update_geo_restrictions || false,
            invoice_status: !preparedData.invoice_status_toggle ? convertToInt(preparedData.invoice_status) : null,
            booking_geo_restrictions: getGeoRestrictionsForDeviceCategories(data),
        });
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {
                GraphQLOptionsData: {
                    continents,
                    countries,
                    distribution,
                },
                formValues: {
                    desktop_switch: desktopSwitchValues,
                    mobile_switch: mobileSwitchValue,
                    smart_tv_switch: smartTvSwitchValue,
                },
            } = nextProps,
            entityData = _get(nextProps, 'GraphQLEntityData', {}),
            restrictions = !_isEmpty(entityData) ? this.getEntityGeoRestrictions(entityData) : {},
            desktopRestrictions = this.getDeviceCategoryRestrictions(restrictions, DEVICE_CATEGORY_DESKTOP.toString()),
            mobileRestrictions = this.getDeviceCategoryRestrictions(restrictions, DEVICE_CATEGORY_MOBILE.toString()),
            smartTvRestrictions = this.getDeviceCategoryRestrictions(restrictions, DEVICE_CATEGORY_SMART_TV.toString());

        if (entityData) {
            if (!this.state.desktopCountriesGenerated
                && continents
                && -1 < desktopRestrictions
                && 0 < restrictions[desktopRestrictions].country_iso_alpha2_codes.length
            ) {
                this.setCountryEntityGeoRestrictions(DEVICE_CATEGORY_DESKTOP, {
                    continents: continents,
                    geoRestrictions: restrictions[desktopRestrictions].country_iso_alpha2_codes,
                });

                this.setState(() => ({desktopCountriesGenerated: true}));
            }

            if (!this.state.mobileCountriesGenerated
                && continents
                && -1 < mobileRestrictions
                && 0 < restrictions[mobileRestrictions].country_iso_alpha2_codes.length
            ) {
                this.setCountryEntityGeoRestrictions(DEVICE_CATEGORY_MOBILE, {
                    continents: continents,
                    geoRestrictions: restrictions[mobileRestrictions].country_iso_alpha2_codes,
                });

                this.setState(() => ({mobileCountriesGenerated: true}));
            }

            if (!this.state.smartTvCountriesGenerated
                && continents
                && -1 < smartTvRestrictions
                && 0 < restrictions[smartTvRestrictions].country_iso_alpha2_codes.length
            ) {
                this.setCountryEntityGeoRestrictions(DEVICE_CATEGORY_SMART_TV, {
                    continents: continents,
                    geoRestrictions: restrictions[smartTvRestrictions].country_iso_alpha2_codes,
                });

                this.setState(() => ({smartTvCountriesGenerated: true}));
            }

            this.ARRAY_WITH_DEVICES.forEach((deviceCategoryId) => {
                let deviceName = '';
                const deviceByRestriction = {
                    [DEVICE_CATEGORY_DESKTOP]: desktopRestrictions,
                    [DEVICE_CATEGORY_MOBILE]: mobileRestrictions,
                    [DEVICE_CATEGORY_SMART_TV]: smartTvRestrictions,
                };

                if (DEVICE_CATEGORY_SMART_TV === deviceCategoryId) {
                    deviceName = 'smartTv';
                } else {
                    deviceName = getDeviceNameById(deviceCategoryId);
                }

                if (!this.state[`${deviceName}SubdivisionsGenerated`]
                    && countries
                    && -1 < deviceByRestriction[deviceCategoryId]
                    && this.checkSubdivisionsAnyCountry(
                        countries,
                        restrictions[deviceByRestriction[deviceCategoryId]].country_iso_alpha2_codes
                    )
                ) {
                    this.setSubdivisionsEntityGeoRestrictions(deviceCategoryId, {
                        countries: countries,
                        countriesGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_iso_alpha2_codes,
                        subdivisionsGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_subdivision_iso_codes,
                    });

                    this.setState(() => ({[`${deviceName}SubdivisionsGenerated`]: true}));
                }

                if (this.state[`${deviceName}SubdivisionsGenerated`]
                    && !this.state[`${deviceName}SubdivisionsLoaded`]
                    && !_isEmpty(
                        _get(
                            restrictions[deviceByRestriction[deviceCategoryId]],
                            'country_subdivision_iso_codes',
                            []
                        )
                    )
                ) {
                    this.setSubdivisionsEntityGeoRestrictions(deviceCategoryId, {
                        countries: countries,
                        countriesGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_iso_alpha2_codes,
                        subdivisionsGeoRestrictions: restrictions[deviceByRestriction[deviceCategoryId]].country_subdivision_iso_codes,
                    });

                    this.setState(() => ({[`${deviceName}SubdivisionsLoaded`]: true}));
                }
            });
        }

        if (!this.state.deviceCategoriesGenerated && distribution) {
            let isChanged = false;

            this.ARRAY_WITH_DEVICES.forEach((deviceCategoryId) => {
                if (-1 === _findIndex(
                    distribution.product.device_categories,
                    {'id': deviceCategoryId.toString()}
                )) {
                    this.setField(`${getDeviceNameById(deviceCategoryId)}_switch`, {
                        hidden: true,
                    });
                } else if (!isChanged) {
                    isChanged = true;

                    this.setField(`${getDeviceNameById(deviceCategoryId)}_switch`, {
                        divClassName: null,
                    });
                }
            });
            this.setState(() => ({deviceCategoriesGenerated: true}));
        }

        if (!this.state.preFillGeoRestrictionsGenerated
            && !nextProps.formParams.id
            && countries
            && continents
            && nextProps.previousStepData
            && nextProps.previousStepData.distribution
            && nextProps.previousStepData.client_package) {

            this.getPreFillGeoRestrictions(
                convertToInt(nextProps.previousStepData.distribution),
                convertToInt(nextProps.previousStepData.client_package),
                {continents: continents, countries: countries}
            );

            this.setState(() => ({preFillGeoRestrictionsGenerated: true}));
        }

        this.setOnChangeCallback({
            desktop_switch: (data) => {
                this.handleSwitchChanged(DEVICE_CATEGORY_DESKTOP, data.value);
            },
            mobile_switch: (data) => {
                this.handleSwitchChanged(DEVICE_CATEGORY_MOBILE, data.value);
            },
            smart_tv_switch: (data) => {
                this.handleSwitchChanged(DEVICE_CATEGORY_SMART_TV, data.value);
            },
        });

        this.setField('geo_restrictions_error_content', {
            hidden: [desktopSwitchValues, mobileSwitchValue, smartTvSwitchValue].every((value) => {
                return false !== value;
            }),
        });

        this.setCountriesOnChangeCallback(
            countries, 'desktop_countries', 'desktop_subdivisions'
        );
        this.setCountriesOnChangeCallback(
            countries, 'mobile_countries', 'mobile_subdivisions'
        );
        this.setCountriesOnChangeCallback(
            countries, 'smart_tv_countries', 'smart_tv_subdivisions'
        );

        this.props.stepsMethods.setForm('step2', nextProps);
    }

    setCountryEntityGeoRestrictions(deviceId, data) {
        const deviceName = getDeviceNameById(deviceId);

        this.setField(`${deviceName}_switch`, {
            checked: true,
            defaultValue: true,
        });
        this.setField(`${deviceName}_countries`, {
            hidden: false,
            options: getCountryTreeOptions({
                continents: data.continents,
                selectedCountries: data.geoRestrictions,
            }),
        });
    }

    setSubdivisionsEntityGeoRestrictions(deviceId, data) {
        const deviceName = getDeviceNameById(deviceId);

        this.setField(`${deviceName}_subdivisions`, {
            hidden: false,
            options: getSubdivisionTreeOptions({
                countries: data.countries,
                selectedCountries: data.countriesGeoRestrictions || [],
                selectedSubdivisions: data.subdivisionsGeoRestrictions || [],
            }),
        });
    }

    getPreFillGeoRestrictions = (distributionId, clientPackageId, data) => {
        this.setState(() => ({defaultForm_loading: true}));

        return this.props.client.query({
            query: PreFillGeoRestrictions,
            fetchPolicy: 'network-only',
            variables: {
                distribution: distributionId,
                clientPackage: clientPackageId,
            },
        }).then((response) => {
            const preFillGeoRestrictions = response.data.preFillGeoRestrictions;

            preFillGeoRestrictions.forEach((geoRestriction) => {
                this.setCountryEntityGeoRestrictions(convertToInt(geoRestriction.device_category), {
                    continents: data.continents,
                    geoRestrictions: geoRestriction.countries,
                });

                if (this.checkSubdivisionsAnyCountry(data.countries, geoRestriction.countries)) {
                    this.setSubdivisionsEntityGeoRestrictions(convertToInt(geoRestriction.device_category), {
                        countries: data.countries,
                        countriesGeoRestrictions: geoRestriction.countries,
                        subdivisionsGeoRestrictions: geoRestriction.subdivisions || [],
                    });
                }
            });
        }).catch((response) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    "Can't get pre-fill geo restrictions for selected distribution and client package.",
                    response.message, 'error');
        }).finally(
            () => {
                this.setState(() => ({defaultForm_loading: false}));
            }
        );
    };

    handleSwitchChanged (deviceId, value) {
        const {
            GraphQLOptionsData: {
                continents,
                countries,
            },
        } = this.props;

        const deviceName = getDeviceNameById(deviceId);
        const switchFieldName = `${deviceName}_switch`;
        const dropdownCountriesFieldName = `${deviceName}_countries`;
        const dropdownSubdivisionsFieldName = `${deviceName}_subdivisions`;
        let continentsList = [],
            countriesList = [];

        if (!value) {
            if(!this.countryTreeOptions) {
                this.countryTreeOptions = getCountryTreeOptions({
                    continents: continents,
                    selectedCountries: [],
                });
            }

            if(!this.subdivisionTreeOptions) {
                this.subdivisionTreeOptions = getSubdivisionTreeOptions({
                    countries: countries,
                    selectedCountries: [],
                    selectedSubdivisions: [],
                });
            }

            continentsList = this.countryTreeOptions;
            countriesList = this.subdivisionTreeOptions;
        }

        this.setField(switchFieldName, {
            checked: !value,
            defaultValue: !value,
        });
        this.setField(dropdownCountriesFieldName, {
            hidden: value,
            options: continentsList,
        });
        this.setField(dropdownSubdivisionsFieldName, {
            hidden: true,
            options: countriesList,
        });
    }

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'booking.booking_geo_restrictions', {});
    };

    getDeviceCategoryRestrictions = (restrictions, deviceCategory) => {
        return _findIndex(restrictions, { 'device_category': { 'id': deviceCategory}});
    };

    checkSubdivisionsAnyCountry = (countries = [], selectedCountries = []) => {
        for (let key = 0; key < countries.length; key++) {
            if (selectedCountries.includes(countries[key].value)) {
                return true;
            }
        }

        return false;
    };
}

export default EventBookingGeoRestrictionsForm;
