import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Icon, Loader, Popup} from 'semantic-ui-react';

import StreamsPlayback from '@modules/liveControl/components/StreamsPlayback';
import {HeaderEventContent} from '@modules/liveControl/views/StreamsPlayback/HeaderEventContent';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {STREAM_DELAY_TYPE_LIVE_NAME} from '@constants/variables';

export const AvPlayerIndex = ({
    avPlayerParams,
    eventContentDetails,
    match,
    Modal,
    streamInfo: {
        product = null,
        distributionType = null,
        streamDelayType = null,
        protocol = null,
        bitrate = 0,
        isOriginStream = false,
        isManifest = false,
        client = null,
    },
    wnsDetails,
    url,
    id,
}) => {
    const [isOverlayHidden, setIsOverlayHidden] = useState(false);

    useEffect(() => {
        if (document.getElementById('playerLibrary')) {
            loadPlayer(true);

            return;
        }

        const playerScript = document.createElement('script');

        playerScript.async = false;
        playerScript.src = 'https://avplayer-cdn.akamaized.net/dist/latest/avvpl-player.js';
        playerScript.id = 'playerLibrary';

        document.body.appendChild(playerScript);

        playerScript.onload = () => {
            loadPlayer();
        };
    }, []);

    const loadPlayer = (reload = false) => {
        const filteredAvPlayerParams = {
            id: 'playerContainer',
            loglevel: 5,
            mute: true,
            streamUrl: url,
        };

        if (avPlayerParams) {
            Object.keys(avPlayerParams).forEach((key) => {
                if (null !== avPlayerParams[key] && !['type', '__typename'].includes(key)) {
                    filteredAvPlayerParams[key] = avPlayerParams[key];
                }
            });
        }

        if (!reload) {
            const script = document.createElement('script');
            script.async = false;

            script.innerHTML = `player = null; 
                function loadPlayer(params) {player = new avvpl.setupPlayer(params)};
                function reloadPlayer(params) { player.remove(); loadPlayer(params) };
                loadPlayer(${JSON.stringify(filteredAvPlayerParams)})`;

            document.body.appendChild(script);
        } else {
            window.reloadPlayer(filteredAvPlayerParams);
        }
    };

    const getStreamInfo = () => {
        const manifestOrBitrate = (isManifest)
            ? 'Manifest'
            : `${bitrate / 1000} kbps`;

        streamDelayType = (streamDelayType && STREAM_DELAY_TYPE_LIVE_NAME !== streamDelayType)
            ? ` (${streamDelayType})`
            : '';

        return (isOriginStream)
            ? `Origin stream (${eventContentDetails.origin_stream_type_name})`
            : `${product} ${distributionType}${streamDelayType} - ${protocol} - ${manifestOrBitrate}`;
    };

    const showPlaybackModal = () => {
        Modal.setModal({
            className: 'streamPlayback',
            content: (
                <StreamsPlayback
                    closeModal={closePlaybackModal}
                    eventContentId={convertToInt(match.params.eventContentId)}
                    eventId={convertToInt(match.params.id)}
                    modal={Modal}
                    newWindow={false}
                />
            ),
            isVisible: true,
            size: 'large',
        });
    };

    const closePlaybackModal = () => {
        Modal.setModal({isVisible: false});
    };

    const href = `${match.url}/debug${client ? '?clientId=' + client : ''}`;

    return (
        <>
            <link rel='stylesheet' type='text/css' href='https://avplayer-cdn.akamaized.net/dist/latest/avvpl-player.css'/>
            <div id='playerOverlay'>
                <div className={isOverlayHidden ? 'overlayHidden' : ''}>
                    {eventContentDetails && <HeaderEventContent eventContentDetails={eventContentDetails} />}
                    {wnsDetails && (
                        <div className='--marginTop-10 --marginLeft-1-5em --marginBottom-10 small --auto_width --text-left --block'>
                            <div className='--flex --flexWrap'>
                                {wnsDetails} ({id})
                            </div>
                        </div>
                    )}
                    <div>
                        {eventContentDetails && getStreamInfo()}
                        {wnsDetails && 'DASH - Manifest'}
                        <Popup
                            content='Open in Debug Player'
                            position='bottom center'
                            trigger={
                                <a href={href} rel='noopener noreferrer'>
                                    <Icon className='bug streamInfoIcon' />
                                </a>
                            }
                        />
                        {eventContentDetails && (
                            <Popup
                                content='Switch to another stream'
                                position='bottom center'
                                trigger={<Icon className='list streamInfoIcon' onClick={showPlaybackModal} />}
                            />
                        )}
                    </div>
                </div>
                <Popup
                    content={`${(isOverlayHidden) ? 'Pin' : 'Hide'} the overlay`}
                    position='bottom center'
                    trigger={(isOverlayHidden)
                        ? <Icon className='map pin playerIcon' onClick={() => setIsOverlayHidden(false)} />
                        : <Icon className='close playerIcon' onClick={() => setIsOverlayHidden(true)} />
                    }
                />
            </div>
            <div id='playerContainer' className='playerContainer' />
            <div id='reloading' className='playerContainer'>
                <Loader size='massive' />
            </div>
        </>
    );
};

AvPlayerIndex.propTypes = {
    avPlayerParams: PropTypes.object.isRequired,
    eventContentDetails: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            eventContentId: PropTypes.string,
            id: PropTypes.string,
        }),
        url: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
    streamInfo: PropTypes.shape({
        product: PropTypes.string,
        distributionType: PropTypes.string,
        streamDelayType: PropTypes.string,
        protocol: PropTypes.string,
        bitrate: PropTypes.number,
        isOriginStream: PropTypes.bool,
        isManifest: PropTypes.bool,
        client: PropTypes.number,
    }),
    url: PropTypes.string.isRequired,
    wnsDetails: PropTypes.string,
    id: PropTypes.string,
};

AvPlayerIndex.defaultProps = {
    streamInfo: {
        product: null,
        distributionType: null,
        streamDelayType: null,
        protocol: null,
        bitrate: 0,
        isOriginStream: false,
        isManifest: false,
        client: null,
    },
};

export default connect(null, mapModulesToProps(['Modal']))(withRouter(AvPlayerIndex));
