import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useQuery} from '@apollo/client';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import ClientPackagesFilters from '@modules/client/components/ClientPackagesFilters';
import {clientPackagesExportExcel} from '@modules/client/utils/export/clientPackagesIndexExcel';
import {convertBooleanValueForFilters, getSearchFilters, reverseBooleanValueForFilters} from '@utils/filters';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import ClientPackagesTable from '@modules/client/components/ClientPackagesTable';
import {GetPackages} from '@graphql/clientPackages/query';

export const DEFAULT_FILTERS_VALUES = {
    search: null,
    client: [],
    clientIsDisabled: null,
    product: null,
    productIsDisabled: null,
    clientPackageType: [],
    bookingTypes: [],
    contentPackageSelectionType: [],
    eventContentVariants: [],
    from: null,
    to: null,
    geoRestrictions: null,
    isPremium: null,
    isHq: null,
    isTest: null,
    isDisabled: null,
};

export function ClientPackagesIndex(props) {
    const filters = props.filters,
        queryVariables = {
            search: filters.search ? filters.search : DEFAULT_FILTERS_VALUES.search,
            client: filters.client ? filters.client.map(Number) : DEFAULT_FILTERS_VALUES.client,
            clientIsDisabled: reverseBooleanValueForFilters(filters.clientIsDisabled),
            product: filters.product ? filters.product.map(Number) : DEFAULT_FILTERS_VALUES.product,
            productIsDisabled: reverseBooleanValueForFilters(filters.productIsDisabled),
            clientPackageType: (filters.clientPackageType)
                ?
                filters.clientPackageType.map(Number)
                :
                DEFAULT_FILTERS_VALUES.clientPackageType,
            bookingTypes: filters.bookingTypes ? filters.bookingTypes.map(Number) : DEFAULT_FILTERS_VALUES.bookingTypes,
            contentPackageSelectionType: filters.contentPackageSelectionType
                ?
                filters.contentPackageSelectionType.map(Number)
                :
                DEFAULT_FILTERS_VALUES.contentPackageSelectionType,
            eventContentVariants: filters.eventContentVariants
                ?
                filters.eventContentVariants.map(Number)
                :
                DEFAULT_FILTERS_VALUES.eventContentVariants,
            from: filters.from ? filters.from + ' 00:00:00' : DEFAULT_FILTERS_VALUES.from,
            to: filters.to ? filters.to + ' 00:00:00' : DEFAULT_FILTERS_VALUES.to,
            geoRestrictions: (filters.geoRestrictions || 0 === filters.geoRestrictions)
                ?
                filters.geoRestrictions
                :
                DEFAULT_FILTERS_VALUES.geoRestrictions,
            isPremium: convertBooleanValueForFilters(filters.isPremium),
            isHq: convertBooleanValueForFilters(filters.isHq),
            isTest: convertBooleanValueForFilters(filters.isTest),
            isDisabled: reverseBooleanValueForFilters(filters.isDisabled),
        };
    const {
        data: {
            clientPackages,
        } = {},
        loading: isAllClientPackagesDataLoading = true,
    } = useQuery(GetPackages,
        {
            variables: {
                ...queryVariables,
            },
            fetchPolicy: 'no-cache',
            refetch: filters.lastClickSubmit,
        }
    );

    return (
        <div>
            <HeaderRenderer
                messagesBoxNames='ClientPackagesIndex'
                pageTitle='Client packages'
                filtersProps={{loading: isAllClientPackagesDataLoading}}
                filters={ClientPackagesFilters}
                exportExcelParams={clientPackagesExportExcel(clientPackages)}
                filtersButtonName='ClientPackages'
            />
            <ClientPackagesTable
                data={clientPackages}
                loading={isAllClientPackagesDataLoading}
            />
        </div>
    );
}

ClientPackagesIndex.propTypes = {
    filters: PropTypes.object.isRequired,
};

const ClientPackagesFilterWrapped = FilterQueryWrapper(ClientPackagesIndex, {
    queryForRefresh: 'GetPackages',
    filterUrls: ['clientPackages'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'ClientPackages', DEFAULT_FILTERS_VALUES),
});

export default connect(mapStateToProps)(FilterUrlParamsWrapper(ClientPackagesFilterWrapped, DEFAULT_FILTERS_VALUES, 'clientPackages'));
