import React from 'react';

import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {Authorization} from '@appComponents/Authorization';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

const AdSpliceIndexButtons = () => {
    const authorizationObject = {
        [RESOURCES.AD_SPLICE_CONFIG]: CONST.SECURITY_PRIVILEGES_CREATE,
    };

    return (
        <div className='content__buttons'>
            <Authorization authorization={authorizationObject}>
                <Link name='adSplice.add'>
                    <ButtonAdd>{'Add new ad splice config'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default AdSpliceIndexButtons;
