import {
    get as _get,
    isEmpty as _isEmpty
} from "lodash";

import {convertToInt} from "../../../utils/helpers";
import validators from '../../../utils/validators';

import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from "../../app/constants/variables";

const validate = (values, props) => {
    const errors = {};
    const requiredSubdivisionCountries = _get(props.GraphQLOptionsData, 'countries', []);

    Object.assign(errors, validators.required(values.selection_type, 'selection_type'));

    if (TERRITORY_SELECTION_TYPE_WORLDWIDE !== convertToInt(values.selection_type)) {
        Object.assign(errors, validators.required(values.countries, 'countries'));
    }

    if (!_isEmpty(requiredSubdivisionCountries) && _get(values, 'countries', []).length) {
        Object.assign(errors, validators.requiredAtLeastOneSubdivisionPerCountry({
            countries: values.countries,
            subdivisions: values.subdivisions,
            required: requiredSubdivisionCountries.map((country) => {
                return country.id;
            })
        }, 'subdivisions'));
    }

    return errors;
};

export default validate;
