import {
    GetClientProductSpottForForm,
    GetClientProductSpottOptions
} from '@graphql/clientProductSpott/query';
import {
    CreateClientProductSpott,
    DeleteClientProductSpott,
    UpdateClientProductSpott
} from '@graphql/clientProductSpott/mutation';
import Form from '@appComponents/ReduxFormControls';

import ClientProductModel from './ClientProductModel';
import {renderHeader} from './clientProductFormUtils';
import validate from './validatorClientProductSpott';

export default function ClientProductSpottModel() {
    let clientProductSpottModel = ClientProductModel();

    clientProductSpottModel.deleteId = "clientProductsSpott.0.id";
    clientProductSpottModel.entityDataMapKey = "clientProductsSpott.0";
    clientProductSpottModel.entityLabel = "product SpOTT";
    clientProductSpottModel.validate = validate;
    clientProductSpottModel.query = {
        getEntity: GetClientProductSpottForForm,
        getOptions: GetClientProductSpottOptions,
    };
    clientProductSpottModel.mutation = {
        createEntity: CreateClientProductSpott,
        deleteEntity: DeleteClientProductSpott,
        updateEntity: UpdateClientProductSpott,
    };

    clientProductSpottModel.dataMap = {
        clientProductsSpott: {
            id: "",
            is_spott_synchronization_disabled: false,
            ...clientProductSpottModel.dataMap
        },
    };

    clientProductSpottModel.remapBaseDatamapKeys(clientProductSpottModel);

    clientProductSpottModel.fields = Object.assign({}, clientProductSpottModel.fields, {
        header: {
            props: {
                component: renderHeader,
                label: "Header",
                name: "header",
                defaultValue: "SpOTT specific settings",
            }
        },
        has_additional_cdn_bucket: {
            dataMapKey: "clientProductsSpott.0.is_spott_synchronization_disabled",
            props: {
                component: Form.SemanticInput,
                label: "Disable SpOTT synchronization",
                name: "is_spott_synchronization_disabled",
                type: "checkbox",
            },
        },
    });

    return clientProductSpottModel;
}
