import React from 'react';  // eslint-disable-line no-unused-vars

import {eventStreamFormOptionsQuery, streamQuery} from '@graphql/streams/streams';
import {STREAM as RESOURCE_STREAM} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {CreateStream, UpdateStream, DeleteStream} from '@graphql/streams/mutation';
import Form from '@appComponents/ReduxFormControls';

import validate from './validatorEventStreams';

export default function EventStreamModel() {
    const SeparatorComponent = () => (
        <div style={{'width': '15px','margin-left': '12px','margin-right': '8px'}}>x</div>
    );

    SeparatorComponent.displayName = 'SeparatorComponent';

    return {
        deleteId: 'stream.id',
        entityDataMapKey: 'stream',
        entityLabel: 'stream',
        formName: 'Stream',
        resources: RESOURCE_STREAM,
        showChangeLog: true,
        validate: validate,
        title: () =>  'Add new stream',
        dataMap: {
            stream: {
                akamai_cp_code: '',
                akamai_rtmp_distr_region: {
                    id: '',
                },
                bitrate_audio: '',
                bitrate_video: '',
                frame_rate: '',
                resolution_width: '',
                resolution_height: '',
                duration: '',
                device_category: {
                    id: '',
                },
                encoding_target: {
                    id: '',
                },
                stream_format: {
                    id: '',
                },
                stream_name: '',
                stream_protocol: {
                    id: '',
                },
            },
        },
        messages: {
            boxName: {
                delete: 'eventStreamsMessage',
                error: 'eventStreamsMessage',
                success: 'eventStreamsMessage',
            },
            text: {
                ...MESSAGES,
                DELETE_HEADER_ARGUMENT_FIRST: () => MESSAGES.DELETE_HEADER('stream'),
                DELETE_IN_PROGRESS: () => MESSAGES.DELETING('stream'),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'stream.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            encoding_target_id: {
                dataMapKey: 'stream.encoding_target.id',
                optionsKey: 'encodingTargets',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Encoding target',
                    required: true,
                    name: 'encoding_target',
                },
            },
            device_category_id: {
                dataMapKey: 'stream.device_category.id',
                optionsKey: 'deviceCategories',
                props: {
                    component: Form.SemanticSelect,
                    disabled: true,
                    label: 'Device category',
                    name: 'device_category',
                    required: true,
                },
            },
            stream_format_id: {
                dataMapKey: 'stream.stream_format.id',
                optionsKey: 'streamFormats',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Format',
                    required: true,
                    name: 'stream_format',
                },
            },
            stream_protocol_id: {
                dataMapKey: 'stream.stream_protocol.id',
                optionsKey: 'streamProtocols',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Protocol',
                    required: true,
                    name: 'stream_protocol',
                },
            },
            bitrate_video: {
                dataMapKey: 'stream.bitrate_video',
                props: {
                    component: Form.SemanticInput,
                    name: 'bitrate_video',
                    label: 'Video bitrate',
                    labelDescription: {content: 'bps'},
                    type: 'number',
                    required: true,
                },
            },
            bitrate_audio: {
                dataMapKey: 'stream.bitrate_audio',
                props: {
                    component: Form.SemanticInput,
                    name: 'bitrate_audio',
                    label: 'Audio bitrate',
                    labelDescription: {content: 'bps'},
                    type: 'number',
                    required: true,
                },
            },
            frame_rate: {
                dataMapKey: 'stream.frame_rate',
                props: {
                    component: Form.SemanticInput,
                    name: 'frame_rate',
                    label: 'Frame rate',
                    labelDescription: {content: 'fps'},
                    type: 'number',
                },
            },
            inline: [
                {
                    resolution_width: {
                        dataMapKey: 'stream.resolution_width',
                        props: {
                            component: Form.SemanticInput,
                            name: 'resolution_width',
                            label: 'Resolution',
                            labelDescription: {content: 'px'},
                            type: 'number',
                        },
                    },
                },
                {
                    separator: {
                        props: {
                            component: SeparatorComponent,
                        },
                    },
                },
                {
                    resolution_height: {
                        dataMapKey: 'stream.resolution_height',
                        props: {
                            component: Form.SemanticInput,
                            name: 'resolution_height',
                            labelDescription: {content: 'px'},
                            type: 'number',
                        },
                    },
                },
            ],
            duration: {
                dataMapKey: 'stream.duration',
                props: {
                    component: Form.SemanticInput,
                    name: 'duration',
                    label: 'Duration',
                    labelDescription: {content: 'ms'},
                    type: 'number',
                },
            },
            stream_name: {
                dataMapKey: 'stream.stream_name',
                props: {
                    component: Form.SemanticInput,
                    name: 'stream_name',
                    label: 'Stream name',
                    type: 'input',
                    required: true,
                },
            },
            akamai_cp_code: {
                dataMapKey: 'stream.akamai_cp_code',
                props: {
                    component: Form.SemanticInput,
                    name: 'akamai_cp_code',
                    label: 'Akamai CP Code',
                    type: 'input',
                    size: 'small',
                    hidden: true,
                },
            },
            akamai_rtmp_distr_region_id: {
                dataMapKey: 'stream.akamai_rtmp_distr_region.id',
                optionsKey: 'akamaiRtmpDistrRegions',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Akamai RTMP distribution region',
                    required: true,
                    name: 'akamai_rtmp_distr_region',
                    hidden: true,
                },
            },
        },
        mutation: {
            createEntity: CreateStream,
            deleteEntity: DeleteStream,
            updateEntity: UpdateStream,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['EventStreamsQuery'],
            },
            update: {
                refetchQueries: ['EventStreamsQuery'],
            },
            delete: {
                refetchQueries: ['EventStreamsQuery'],
            },
        },
        query: {
            getEntity: streamQuery,
            getOptions: eventStreamFormOptionsQuery,
        },
    };
}
