import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {refetchQueryByName} from '@utils/apollo';

const errorKeys = {
    countryFulfilled: 'countryFulfilled',
    countrySubdivisionFulfilled: 'countrySubdivisionFulfilled',
    restrictedSubdivisions: 'restrictedSubdivisions',
    restrictedCountries: 'restrictedCountries',
    notFound: 'notFound',
};

class BlackoutZonePostalCodesForm extends DefaultForm {
    static propTypes = {
        history: PropTypes.object,
    };

    redirectToIndex = () => window.history.pushState({}, '', this.props.Model.indexUrl);

    getErrorRestrictedHeader = (key) => {
        let label = 'country';

        if (errorKeys.restrictedSubdivisions === key) {
            label = 'subdivisions';
        }

        return `The postal codes could not be added because the following postal codes
                                    belong to ${label} of the Blackout Zone. Such an overlap is not allowed.`;
    }

    getErrorRestrictedDescription = (key, elements = []) => {
        let label = 'country';

        if (errorKeys.restrictedSubdivisions === key) {
            label = 'subdivision';
        }

        const errorString = elements[key].map(
            (element) => (`${element[label]}: ${element.postalCodes.join(', ')}\n`)
        ).join('');

        return `${errorString}`;
    }

    getErrorFulfilledHeader = (key, elements = []) => {
        let header = 'The postal codes could not be added because the Blackout Zone would include all of the postal codes of the';

        if (errorKeys.countryFulfilled === key) {
            header += ` country ${elements[key]}.`;
        } else if (errorKeys.countrySubdivisionFulfilled === key) {
            header += ' following subdivisions:';
        }

        return header;
    }

    getErrorFulfilledDescription = (key, errors = []) => {
        let description = 'You should use the country selection instead.';

        if (errorKeys.countrySubdivisionFulfilled === key) {
            description = `${errors[key].join(', ')}.\nYou should use the subdivision selection instead.`;
        }

        return description;
    }

    componentDidMount = () => {
        this.setUpdateSuccessCallback(
            (response) => {
                const errors = _get(response, 'data.addBlackoutZonePostalCodes.errors', null);

                if (errors) {
                    let count = 1;

                    Object.keys(errors).forEach((key) => {
                        let header = '',
                            description = '';

                        switch (key) {
                            case errorKeys.countryFulfilled:
                            case errorKeys.countrySubdivisionFulfilled:
                                header = this.getErrorFulfilledHeader(key, errors);
                                break;
                            case errorKeys.restrictedSubdivisions:
                            case errorKeys.restrictedCountries:
                                header = this.getErrorRestrictedHeader(key);
                                break;
                            case errorKeys.notFound:
                                header = `The postal codes could not be added because the following postal codes 
                                    do not exist in our postal code database for the selected country:`;
                        }

                        switch (key) {
                            case errorKeys.countrySubdivisionFulfilled:
                            case errorKeys.countryFulfilled:
                                description = this.getErrorFulfilledDescription(key, errors);
                                break;
                            case errorKeys.restrictedSubdivisions:
                            case errorKeys.restrictedCountries: {
                                description = this.getErrorRestrictedDescription(key, errors);
                                break;
                            }
                            case errorKeys.notFound: {
                                description = errors[key].join(',');
                            }
                        }

                        this.props.MessageBox
                            .addMessage(`BlackoutZonePostalCodes${count}`,
                                header, description, 'error', true);

                        count++;
                    });
                } else {
                    this.props.MessageBox
                        .addMessage('BlackoutZonePostalCodes1',
                            'The postal codes have been added successfully.', null, 'success', true);

                    this.props.Model.setIsRefetching(true);
                    refetchQueryByName('GetBlackoutZonePostalCodesForTable');
                }
                this.redirectToIndex();
                this.props.Model.setIsRefetching(false);
            }
        );
    }

    prepareDataForSubmit = (data) => {
        return {...data, id: this.props.formParams.id};
    }

    onCancel = () => {
        this.props.Modal.setModal({
            content: null,
            isVisible: false,
        });
        this.props.history.push(getLink('blackoutZones.edit.postalCodes.index',{
            id: this.props.formParams.id,
        }));
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const countriesHavingPostalCodes = _get(nextProps, 'GraphQLOptionsData.countriesHavingPostalCodes', false),
            blackoutZoneCountries = _get(nextProps, 'GraphQLOptionsData.blackoutZone.countries', []);

        if (!this.state.optionsLoaded && countriesHavingPostalCodes) {
            const restrictedCountries = blackoutZoneCountries.flatMap(
                    (country) => (!country.handle_geo_restrictions_on_subdivision_level) ? [country.id] : []
                ),
                optionsForCountryInput = countriesHavingPostalCodes.filter(
                    (country) => (-1 === restrictedCountries.indexOf(country.value))
                );

            this.setField('country', {
                options: optionsForCountryInput,
            });

            this.setState(() => ({
                optionsLoaded: true,
            }));
        }
    }

    renderCancelButton = (props) => super.renderCancelButton({...props, type: 'button', onClick: this.onCancel});

    renderDeleteButton = () => null;
}

export default withRouter(BlackoutZonePostalCodesForm);
