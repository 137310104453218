import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import {TitleFromQuery} from "../../app/components/HeaderWithQuery";

import mapModulesToProps from "../../../utils/mapModulesToProps";
import {
    RenderBackToListButton,
} from "../utils/productLayoutHelper";

import ProductEdit from "../views/ProductEdit";

import navigation from "../../app/constants/navigation";
import {routes} from "../../app/constants/routes";

import {GetProductName} from "../../../graphql/product/query.graphql";


export const ProductLayout = ({
    match: {
        params: {
            id = null,
        }
    },
    Menu,
}) => {

    React.useEffect(() => {
        Menu.storeMenu(navigation.eventAdministration.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                pageTitle={
                    <TitleFromQuery
                        query={GetProductName}
                        variables={{id}}
                        errorBackLink={routes.products.index.path}
                        path={"product.name"}
                        entity={"product"}
                    />
                }
                messagesBoxNames={"productMessageError"}
                buttons={RenderBackToListButton}
            />
            {
                <ProductEdit/>
            }
        </div>
    );
};
ProductLayout.propTypes = {
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
    match: PropTypes.object
};
ProductLayout.displayName = 'ProductLayout';

export default connect(null, mapModulesToProps(['Menu']))(ProductLayout)
