import GeoRestrictionsFormEditModel from "../../geoRestriction/forms/GeoRestrictionsFormEditModel";

import validatorProductGeoRestriction from "./validatorProductGeoRestriction";

import {CLIENT_PRODUCT} from "../../app/constants/resources";
import * as MESSAGES from "../../app/constants/messages";

import {GetClientProductLctForGeoRestrictionsForm} from "../../../graphql/clientProductLct/query.graphql";
import {UpdateGeoRestrictionsClientProductLct} from "../../../graphql/clientProductLct/mutation.graphql";

export default function ProductLctGeoRestrictionsModel() {
    let productLctGeoRestrictionsModel = GeoRestrictionsFormEditModel();

    productLctGeoRestrictionsModel.entityDataMapKey = "clientProductLct";
    productLctGeoRestrictionsModel.entityLabel = "geo restrictions";
    productLctGeoRestrictionsModel.formName = "product LCT";
    productLctGeoRestrictionsModel.label = "geo restrictions";
    productLctGeoRestrictionsModel.resources = CLIENT_PRODUCT;
    productLctGeoRestrictionsModel.validate = validatorProductGeoRestriction;
    productLctGeoRestrictionsModel.messages = {
        boxName: {
            delete: 'clientMessage',
            error: 'clientMessage',
            success: 'clientMessage',
        },
        text: {
            ...MESSAGES,
            SAVED: () => 'The geo restrictions have been saved successfully.',
            FORM_ERROR: () => 'The geo restrictions have not been saved. Please correct the highlighted fields below.',
        },
    };
    productLctGeoRestrictionsModel.fields.id.dataMapKey = "clientProductLct.id";
    productLctGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductLct,
    };
    productLctGeoRestrictionsModel.query.getEntity = GetClientProductLctForGeoRestrictionsForm;

    delete productLctGeoRestrictionsModel.deleteId;
    delete productLctGeoRestrictionsModel.mutationOptions;

    return productLctGeoRestrictionsModel;
}
