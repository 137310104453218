import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    if (values.match_id) {
        Object.assign(errors, validators.minLength({
            value: values.match_id,
            minLength: 7,
            errorMessage: 'Type at least 7 digits'
        }, 'match_id'));

        Object.assign(errors, validators.digitsOnly(values.match_id, 'match_id'));
    }

    return errors;
};

export default validate;
