import React from 'react';
import PropTypes from 'prop-types';

/**
 * Display
 * - Content category
 *
 * for NON-SPORTRADAR EVENT
 * @param event
 * @param sportImage
 * @returns {*}
 */
export const NonSportradarEvent = ({ event, sportImage }) => {
    return (
        <div key={`second-line-${event.id}`}>
            {sportImage}
            <span>
                {event.content_category_level_1_name ? event.content_category_level_1_name : '(No content category lev 1)'} {'> '}
                {event.content_category_level_2_name ? event.content_category_level_2_name : '(No content category lev 2)'} {'> '}
                {event.content_category_level_3_name ? event.content_category_level_3_name : '(No content category lev 3)'}
                {event.sport_has_court && event.stadium_name ? ` - ${event.stadium_name}` : ''}
            </span>
        </div>
    );
};

NonSportradarEvent.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        content_category_level_1_name: PropTypes.string,
        content_category_level_2_name: PropTypes.string,
        content_category_level_3_name: PropTypes.string,
        stadium_name: PropTypes.string,
        sport_has_court: PropTypes.bool,
    }).isRequired,
    sportImage: PropTypes.element,
};

NonSportradarEvent.defaultProps = {
    event: {
        content_category_level_1_name: null,
        content_category_level_2_name: null,
        content_category_level_3_name: null,
    },
    sportImage: React.createElement('div'),
};
