import {GetEventEventBookingForGeoRestrictionsForm} from '@graphql/eventEventBooking/query';
import {UpdateEventBooking, DeleteEventBooking} from '@graphql/eventEventBooking/mutation';

import EventEventBookingGeoRestrictionModel from './EventEventBookingGeoRestrictionModel';

export default function EventEventBookingGeoRestrictionsEditModel() {
    const eventEventBookingGeoRestrictionsEditModel = EventEventBookingGeoRestrictionModel();

    eventEventBookingGeoRestrictionsEditModel.deleteId = 'eventEventBooking.id';
    eventEventBookingGeoRestrictionsEditModel.editForm = true;
    eventEventBookingGeoRestrictionsEditModel.mutation = {
        updateEntity: UpdateEventBooking,
        deleteEntity: DeleteEventBooking,
    };
    eventEventBookingGeoRestrictionsEditModel.query.getEntity = GetEventEventBookingForGeoRestrictionsForm;

    return eventEventBookingGeoRestrictionsEditModel;
}
