import validatorClientPackageGeoRestriction from "../validatorClientPackageGeoRestriction";

import BaseGeoRestrictionsFormModel from "../../../geoRestriction/forms/GeoRestrictionsFormModel";

import {CLIENT_PACKAGE_GEO_RESTRICTION} from "../../../app/constants/resources";

import {CreateClientPackageGeoRestriction} from "../../../../graphql/clientPackageGeoRestriction/mutation.graphql";
import {GetEventGeoRestrictionForForm} from "../../../../graphql/eventGeoRestriction/query.graphql";

export default function GeoRestrictionsModel() {
    let geoRestrictionsModel = BaseGeoRestrictionsFormModel();

    geoRestrictionsModel.entityDataMapKey = "clientPackageGeoRestriction";
    geoRestrictionsModel.formName = "ClientPackageGeoRestriction";
    geoRestrictionsModel.resources = CLIENT_PACKAGE_GEO_RESTRICTION;
    geoRestrictionsModel.validate = validatorClientPackageGeoRestriction;
    geoRestrictionsModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    geoRestrictionsModel.fields.id.dataMapKey = "clientPackage.id";

    geoRestrictionsModel.mutation = {
        createEntity: CreateClientPackageGeoRestriction,
    };
    geoRestrictionsModel.mutationOptions = {
        create: {
            refetchQueries: ['GetClientPackageGeoRestrictionsForTable'],
        },
        update: {
            refetchQueries: ['GetClientPackageGeoRestrictionsForTable'],
        },
        delete: {
            refetchQueries: ['GetClientPackageGeoRestrictionsForTable'],
        },
    };
    geoRestrictionsModel.query.getEntity = GetEventGeoRestrictionForForm;

    return geoRestrictionsModel;
}
