import {convertToInt} from '@utils/helpers';

const STATUS_BOOKED = 'Booked';
const STATUS_CANCELLED = 'Cancelled';
const STATUS_NOT_BOOKED = 'Not booked';

export const getBookingStatusForOfferings = () => ([
    {text: STATUS_BOOKED, value: STATUS_BOOKED},
    {text: STATUS_CANCELLED, value: STATUS_CANCELLED},
    {text: STATUS_NOT_BOOKED, value: STATUS_NOT_BOOKED},
]);

export const getBookingStatusForOfferingsDefaultValues = () => ([
    STATUS_CANCELLED, STATUS_NOT_BOOKED,
]);

export const getBookingFiltersIsCancelledStatus = () => ([
    {
        key: 1,
        text: 'Cancelled',
        value: 1,
    },
    {
        key: 0,
        text: 'Not cancelled',
        value: 0,
    },
]);

export const getBookingFormParams = (id, bookingId, params, isEvent = false) => {
    const optionVariableKey = isEvent ? 'event' : 'client',
        formParamsObject = Object.assign({}, {
            id: convertToInt(bookingId) || convertToInt(params.bookingId),
            optionsVariables: {
                [optionVariableKey]: convertToInt(id),
                isArchived: null,
            },
        }),
        linkParams = {
            id: convertToInt(id),
            bookingId: convertToInt(bookingId) || convertToInt(params.bookingId),
        };

    return [formParamsObject, linkParams];
};
