import {get as _get} from "lodash";

import BaseGeoRestrictionsForm from "../../../geoRestriction/forms/GeoRestrictionsForm";

import {convertToInt, getIdsFromChildren, prepareClientPackageUsages} from "../../../../utils/helpers";

class GeoRestrictionsForm extends BaseGeoRestrictionsForm {
    prepareDataForSubmit = (data) => {
        const countries = getIdsFromChildren(data.countries),
            subdivisions = getIdsFromChildren(data.subdivisions),
            preparedData = this.props.Model.editForm
                ? this.props.stepsMethods.getForms()["step1"].formValues
                : this.props.previousStepData;

        return Object.assign({},{
            id: this.props.formParams.id,
            client_package: this.props.formParams.optionsVariables.client_package,
            usages: prepareClientPackageUsages(preparedData.usages),
            notes: preparedData.notes || "",
            territory_selection_type: convertToInt(data.selection_type),
            countries: countries,
            country_subdivisions: subdivisions,
        });
    };

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'clientPackageGeoRestriction', {});
    };

    renderSaveButton = () => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                content: "Add geo restriction",
            })
        }

        return super.renderSaveButton();
    };
}

export default GeoRestrictionsForm;
