import * as filters from '../events/filters';
import {changelogData} from '../changelogData';
import {BasicData} from "../fragments";

export const streamQuery = `
    query streamQuery ($id: ID!) {
        stream(id: $id) {
            id
            akamai_cp_code
            bitrate_audio
            bitrate_video
            frame_rate
            resolution_width
            resolution_height
            duration
            device_category {
                id
            }
            encoding_target {
                id
            }
            stream_format {
                id
            }
            stream_name
            stream_protocol {
                id
            }
            akamai_rtmp_distr_region {
                id
            }
            ${changelogData}
        }
    }
`;

export const eventStreamsFiltersQuery = `
    query EventStreamsFiltersQuery($eventId: Int!) {
        ${filters.contentFilter}
        ${filters.deviceCategoriesFilter}
        ${filters.distributionTypesFilter}
        ${filters.productsFilter}
        ${filters.streamDelayTypesFilter}
        ${filters.streamFormatsFilter}
        ${filters.streamProtocolsFilter}
        ${filters.streamStatusesFilter}
        ${filters.eventContentTypesFilter}
        ${filters.eventContentVariantsFilter}
    }
`;

export const eventStreamFormOptionsQuery = `
    query EventStreamFormDropdownsQuery($eventId: Int!) {
        ${filters.streamFormatsFilter}
        encodingTargets(filters: {event: $eventId}) {
            id
            value: id
            text: id
            cdn {
              id
            }
            encoding_target_sr {
              id
            }
            encoder_type {
                id
            }
            stream_delay_type {
              id
              name
            }
            distribution {
              id
              product {
                short_name
                id
              }
              distribution_type {
                name
                id
              }
              event_content {
                id
                name
                event_content_type {
                  id
                  name
                }
                event_content_variant {
                  ${BasicData}
                  short_name
                }
              }
            }
        }
        ${filters.streamProtocolsFilter}
        ${filters.akamaiRtmpDistrRegionsFilter}
    }
`;

export const deviceCategoriesForDropdown = `
    query DeviceCategoriesForDropdown(
        $encodingTargetId: ID!
    ) {
      encodingTarget(id: $encodingTargetId) {
        device_categories {
          id
          name
        }
      }
    }
`;
