import React from "react";
import {get as _get, has as _has} from "lodash";
import PropTypes from "prop-types";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {Header} from "semantic-ui-react";

import MasterEventAlertsTable from "../components/MasterEventAlertsTable";
import MasterEventNotificationModel from "../../notifications/forms/MasterEventNotificationModel";
import MasterEventNotificationForm from "../../notifications/forms/MasterEventNotificationForm";

import {createForm} from "../../../utils/forms";

import {GetMasterEventNotifications} from "../../../graphql/masterEvent/query.graphql"

class EventChildAlertDetails extends React.Component{
    Form = createForm(
        MasterEventNotificationModel,
        MasterEventNotificationForm,
        {
            optionsVariables: {
                eventId: this.props.eventId,
                eventsIds: this.props.eventsIds
            }
        }
    );

    render() {
        let Form = this.Form;

        return (
            <div className="ClientsAlertModal">
                <Header content="Send notification to clients"/>
                <div className="masterEventAlertsContainer">
                    <MasterEventAlertsTable
                        loading={this.props.EventChildAlertDetails.loading}
                        alerts={this.props.EventChildAlertDetails.masterEventNotifications}
                    />
                    <Form
                        eventStartDatetime={this.props.eventStartDatetime}
                        eventDescription={this.props.eventDescription}
                    />
                </div>
            </div>
        );
    }
}

EventChildAlertDetails.propTypes = {
    EventChildAlertDetails: PropTypes.object,
    eventId: PropTypes.number.isRequired,
    eventsIds: PropTypes.array.isRequired,
    eventStartDatetime: PropTypes.string,
    eventDescription: PropTypes.string
};

EventChildAlertDetails.defaultProps = {
    EventChildAlertDetails: {
        loading: false,
        masterEventNotifications: []
    },
    eventStartDatetime: null,
    eventDescription: null
};

const EventChildAlertDetailsWithQuery = graphql(GetMasterEventNotifications, {
    options: (props) => {
        return {
            fetchPolicy: "network-only",
            variables: {
                eventId: props.eventId
            },
        }
    },
    name: "EventChildAlertDetails"
})(EventChildAlertDetails);

const mapStateToProps = (state) => {
    let eventStartDatetime = null,
        eventDescription = null;

    if (_has(state, `app.entities.event.start_datetime`)) {
        eventStartDatetime = _get(state, `app.entities.event.start_datetime`);
    }

    if (_has(state, `app.entities.event.description`)) {
        eventDescription = _get(state, `app.entities.event.description`);
    }

    return ({
        eventStartDatetime: eventStartDatetime,
        eventDescription: eventDescription
    })
};

export default connect(mapStateToProps)(EventChildAlertDetailsWithQuery)
