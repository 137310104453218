import PropTypes from "prop-types";
import React from "react";
import {Tab} from "semantic-ui-react";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";

export class ProductAdd extends React.PureComponent {
    static propTypes = {
        form: PropTypes.func.isRequired,
        hasTabs: PropTypes.bool,
        model: PropTypes.func.isRequired,
        productId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        hasTabs: true,
    };

    render() {
        let Form = createForm(this.props.model, this.props.form, {
            isAddForm: true,
            optionsVariables: {
                product: convertToInt(this.props.productId),
            },
        });

        return (
            this.props.hasTabs
                ? (
                    <Tab
                        className="form__tabs"
                        menu={{secondary: true}}
                        panes={[{
                            menuItem: 'Settings',
                            render: () => (
                                <Tab.Pane>
                                    <Form/>
                                </Tab.Pane>
                            ),
                        }]}
                    />
                )
                : <Form/>
        );
    }
}

export default ProductAdd;
