import GeoRestrictionsFormEditModel from "../../geoRestriction/forms/GeoRestrictionsFormEditModel";

import validatorProductGeoRestriction from "./validatorProductGeoRestriction";

import * as MESSAGES from "../../app/constants/messages";
import {CLIENT_PRODUCT} from "../../app/constants/resources";

import {UpdateGeoRestrictionsClientProductLci} from "../../../graphql/clientProductLci/mutation.graphql";
import {GetClientProductLciForGeoRestrictionsForm} from "../../../graphql/clientProductLci/query.graphql";

export default function ProductLciGeoRestrictionsModel() {
    let productLciGeoRestrictionsModel = GeoRestrictionsFormEditModel();

    productLciGeoRestrictionsModel.entityDataMapKey = "clientProductLci";
    productLciGeoRestrictionsModel.entityLabel = "geo restrictions";
    productLciGeoRestrictionsModel.formName = "product LCI";
    productLciGeoRestrictionsModel.label = "geo restrictions";
    productLciGeoRestrictionsModel.resources = CLIENT_PRODUCT;
    productLciGeoRestrictionsModel.validate = validatorProductGeoRestriction;
    productLciGeoRestrictionsModel.messages = {
        boxName: {
            delete: 'clientMessage',
            error: 'clientMessage',
            success: 'clientMessage',
        },
        text: {
            ...MESSAGES,
            SAVED: () => 'The geo restrictions have been saved successfully.',
            FORM_ERROR: () => 'The geo restrictions have not been saved. Please correct the highlighted fields below.',
        },
    };
    productLciGeoRestrictionsModel.fields.id.dataMapKey = "clientProductLci.id";
    productLciGeoRestrictionsModel.mutation = {
        updateEntity: UpdateGeoRestrictionsClientProductLci,
    };
    productLciGeoRestrictionsModel.query.getEntity = GetClientProductLciForGeoRestrictionsForm;

    delete productLciGeoRestrictionsModel.deleteId;
    delete productLciGeoRestrictionsModel.mutationOptions;

    return productLciGeoRestrictionsModel;
}
