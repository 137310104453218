import React from 'react';
import {Popup} from 'semantic-ui-react';

import {IconQuestionCircle} from '@appComponents/IconCollection';
import Form from '@appComponents/ReduxFormControls';
import {
    CreateClientProductLcpp,
    DeleteClientProductLcpp,
    UpdateClientProductLcpp
} from '@graphql/clientProductLcpp/mutation';
import {
    GetClientProductLcppForForm,
    GetClientProductLcppOptions
} from '@graphql/clientProductLcpp/query';

import {renderHeader} from './clientProductFormUtils';
import validate from './validatorClientProductLcpp';
import ClientProductModel from './ClientProductModel';

export default function ClientProductLcppModel() {
    let clientProductLcppModel = ClientProductModel();

    clientProductLcppModel.deleteId = "clientProductsLcpp.0.id";
    clientProductLcppModel.entityDataMapKey = "clientProductsLcpp.0";
    clientProductLcppModel.entityLabel = "product LCP-P";
    clientProductLcppModel.validate = validate;
    clientProductLcppModel.query = {
        getEntity: GetClientProductLcppForForm,
        getOptions: GetClientProductLcppOptions,
    };
    clientProductLcppModel.mutation = {
        createEntity: CreateClientProductLcpp,
        deleteEntity: DeleteClientProductLcpp,
        updateEntity: UpdateClientProductLcpp,
    };

    clientProductLcppModel.dataMap = {
        clientProductsLcpp: {
            id: "",
            clip_lifetime: "",
            notification_api_url: "",
            api_key: "",
            ...clientProductLcppModel.dataMap
        },
    };

    clientProductLcppModel.remapBaseDatamapKeys(clientProductLcppModel);

    clientProductLcppModel.fields = Object.assign({}, clientProductLcppModel.fields, {
        header: {
            props: {
                component: renderHeader,
                label: "Header",
                name: "header",
                defaultValue: "LCP-P specific settings",
            }
        },
        clip_lifetime: {
            dataMapKey: "clientProductsLcpp.0.clip_lifetime",
            props: {
                component: Form.SemanticInput,
                label: "Clip lifetime",
                labelDescription: {content: "min"},
                min: 1,
                name: "clip_lifetime",
                props: {
                    description: (
                        <Popup
                            className="--text-left"
                            content={`
                                Leave this value empty if the access to the clips should expire only when the 
                                client package expires.
                            `}
                            hoverable
                            position="right center"
                            trigger={<IconQuestionCircle className="help --marginLeft-50px" />}
                            wide="very"
                        />
                    ),
                },
                type: "number",
            },
        },
        notification_api_url: {
            dataMapKey: "clientProductsLcpp.0.notification_api_url",
            props: {
                component: Form.SemanticInput,
                label: "Notification API URL",
                name: "notification_api_url",
            },
        },
        api_key: {
            dataMapKey: "clientProductsLcpp.0.api_key",
            props: {
                component: Form.SemanticInput,
                label: "API key",
                name: "api_key",
            },
        },
    });

    return clientProductLcppModel;
}
