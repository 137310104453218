import {Label} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {IconEdit} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';

import ContentConfigurationLink from './ContentConfigurationLink';
import {
    ENCODING_STATUS_ENCODING,
    ENCODING_STATUS_ERROR,
    ENCODING_STATUS_PREPARING,
} from '../../../app/constants/variables';

function StatusLabel(props) {
    let color = null;
    let encodingStatus = _get(props.eventContentInput, 'encoding_status', null);
    const encodingStatusId = encodingStatus ? convertToInt(encodingStatus.id) : null;

    if (!encodingStatus) {
        color = null;
        encodingStatus = {name: 'Not started'};
    }

    if (encodingStatusId === ENCODING_STATUS_ENCODING) {
        color = 'green';
    }

    if (encodingStatusId === ENCODING_STATUS_PREPARING || encodingStatusId === ENCODING_STATUS_ERROR) {
        color = 'red';
    }

    return (
        <>
            {
                color ?
                    <Label color={color} size={'medium'}>{encodingStatus.name}</Label>
                    : encodingStatus.name
            }
            &nbsp;&nbsp;
            {
                <ContentConfigurationLink
                    contentId={convertToInt(props.contentId)}
                    eventId={convertToInt(props.eventId)}
                    linkName='changeEncodingStatus'
                    icon={<IconEdit className={'black'}/>}
                    text=''
                />
            }
        </>
    );
}

StatusLabel.propTypes = {
    contentId: PropTypes.number,
    eventId: PropTypes.number,
    eventContentInput: PropTypes.object,
};

export default StatusLabel;
