import React from 'react';
import PropTypes from "prop-types";
import {Dropdown} from "semantic-ui-react";

/**
 * Open "usage types" popup to save data in "event_usage_type"
 */
function OpenUsageTypesPopup({handleOpenPopupEventUsageTypes}) {
    return (
        <Dropdown.Item
            icon="cog"
            key={`openEventUsageTypes`}
            onClick={handleOpenPopupEventUsageTypes}
            text="Usage types"
        />
    )
}

OpenUsageTypesPopup.propTypes = {
    handleOpenPopupEventUsageTypes: PropTypes.func.isRequired
};

export default OpenUsageTypesPopup;
