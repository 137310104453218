import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';

export const formatToSaveRightScopeOptions = (rightScopeOptions, filterByUsageTypeSupport = true) => {
    const type = filterByUsageTypeSupport ? 'usage_type' : 'event_content_variant', relationsToSave = [];

    rightScopeOptions.forEach((rightScopeOption) => (
        rightScopeOption.children.forEach((option) => {
            relationsToSave.push({
                'id': convertToInt(option.id),
                'product': convertToInt(rightScopeOption.value),
                [type]: convertToInt(option.value),
            });
        })
    ));

    return relationsToSave;
};

formatToSaveRightScopeOptions.propTypes = {
    rightScopeOptions: PropTypes.array.isRequired
}
