import PropTypes from "prop-types";

import {convertToInt} from "../../../../utils/helpers";

import {PROPERTY_LICENCE_TYPE_SPORTRADAR} from "../../../app/constants/variables";

export const exportExcel = (dataExport, propertyLicenceId, propertyLicenceTypeId) => {
    return {
        dataExport: dataExport,
        filename: `property_licence_${propertyLicenceId}_content`,
        parseData: content => [
            (null !== content.tournament)
                ? content.tournament.tournament_category.name
                : content.content_category.parent.name,
            (null !== content.tournament)
                ? content.tournament.name
                : content.content_category.name,
            content.content_tier.name,
        ],
        path: 'propertyLicences.content.index.path',
        titles: [
            (PROPERTY_LICENCE_TYPE_SPORTRADAR === convertToInt(propertyLicenceTypeId))
                ? "Category"
                : "2nd level category",
            (PROPERTY_LICENCE_TYPE_SPORTRADAR === convertToInt(propertyLicenceTypeId))
                ? "Tournament"
                : "3rd level category",
            "Tier",
        ],
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
