import {
    isEmpty as _isEmpty
} from "lodash";

import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {},
        valuesFields = Object.keys(values);

    if (!_isEmpty(valuesFields)) {
        valuesFields.forEach((valueField) => {
            if (true === values[valueField]) {
                Object.assign(errors, validators.requiredAtLeastOneStreamDropdownPerClientToggle(
                    {values, valueField},
                    valueField)
                );
            }
        });
    }

    return errors;
};

export default validate;
