import PropTypes from "prop-types";

export const exportExcel = (dataExport, rtmpOriginEntryPointId, rtmpOriginEntryPointHost) => ({
    dataExport: dataExport,
    filename: `rtmp_origin_entry_point_${rtmpOriginEntryPointId}_applications`,
    parseData: application => [
        application.id,
        application.name,
        application.rtmp_origin_entry_point_application_type.name,
        `rtmp://${rtmpOriginEntryPointHost}/${application.rtmp_origin_entry_point_application_type.name}/`,
    ],
    path: 'rtmpOriginEntryPoint.applications.index.path',
    titles: [
        "ID",
        "Name",
        "Type",
        "URI",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    rtmpOriginEntryPointHost: PropTypes.string.isRequired,
    rtmpOriginEntryPointId: PropTypes.string.isRequired,
};
