import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import Link from "../../app/components/Link";
import {ButtonAdd} from "../../app/components/ButtonCollection";

import {CONTACT_PERSON, PRODUCTION_COMPANY} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_CREATE_READ, SECURITY_PRIVILEGES_READ} from "../../app/constants/variables";

export const contactPersonIndexButtonsAuthorizationObject = {
    [PRODUCTION_COMPANY]: SECURITY_PRIVILEGES_READ,
    [CONTACT_PERSON]: SECURITY_PRIVILEGES_CREATE_READ
};

const ContactPersonsIndexButtons = (id) => {
    return (
        <div className="content__buttons">
            <Authorization authorization={contactPersonIndexButtonsAuthorizationObject}>
                <Link name={'productionCompanies.contactPersons.add'} params={id}>
                    <ButtonAdd>{'Add new contact person'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

ContactPersonsIndexButtons.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ContactPersonsIndexButtons;
