import {
    find as _find,
    get as _get,
    sortBy as _sortBy,
} from 'lodash';

import {gql, withApollo} from 'react-apollo';


import {deviceCategoriesForDropdown} from '@graphql/streams/streams';
import {
    CDN_AKAMAI, CDN_AKAMAI_2,
    DISTRIBUTION_TYPE_LIVE,
    ENCODER_TYPE_MANUAL,
    ORIGIN_STREAM_TYPE_RTMP,
    PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT,
    PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT,
    PRODUCT_LCT,
    PRODUCT_SPOTT,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import DefaultForm from '@appComponents/DefaultForm';
import {getEventContentVariantSteppedText} from '@utils/eventContentVariant/eventContentVariant';

class EventStreamForm extends DefaultForm {
    state = {
        ...this.state,
        optionsDeviceCategoriesLoaded: false,
    };

    addDeviceCategoriesMessageBoxError = (error) => {
        this.props.MessageBox.addMessage(
            'formInnerErrorMessage',
            "Can't get device categories for selected encoding target.",
            error.message,
            'error'
        );
    }

    componentDidMount() {
        super.componentDidMount();

        this.setOptionParsers({
            encoding_target: (encodingTargetsData) => {
                let encodingTargets = [];

                for (const encodingTarget of encodingTargetsData) {
                    const streamDelayTypeName = _get(encodingTarget, 'stream_delay_type.name', null);
                    const encodingTargetLabel = getEventContentVariantSteppedText(encodingTarget.distribution.event_content, encodingTarget.distribution)
                    + ` ${streamDelayTypeName ? ` - ${encodingTarget.stream_delay_type.name}` : ''} (${encodingTarget.id})`;

                    encodingTargets.push({
                        id: encodingTarget.id,
                        key: encodingTarget.id,
                        text: encodingTargetLabel,
                        value: encodingTarget.id,
                    });
                }

                encodingTargets = _sortBy(encodingTargets, 'id');

                return encodingTargets;
            },
        });

        this.setOnChangeCallback({
            encoding_target: (data) => {
                this.getDeviceCategories(data.value).then((response) => {
                    const availableDeviceCategories = response.map(({value})=>value),
                        selectedDeviceCategory = this.props.formValues.device_category,
                        isSelectedDeviceCategoryAvailable = availableDeviceCategories.includes(selectedDeviceCategory);
                    this.setField('device_category', {
                        disabled: false,
                        loading: false,
                        defaultValue: isSelectedDeviceCategoryAvailable ? selectedDeviceCategory : null,
                        options: response,
                    });
                }).catch((error) => {
                    this.addDeviceCategoriesMessageBoxError(error);
                });

                this.setAkamaiFieldsVisibility(this.props, data);

                this.preFillStreamForm(this.props, data);
            },
            stream_protocol: (data) => {
                this.setAkamaiFieldsVisibility(this.props, data);
            },
        });

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    componentDidUpdate(prevProps) {
        /**
         Because there is no way to wrap a lifecycle method, it's necessary to use both componentDidUpdate method from parent
         and receivedPropsValidator from parent which is equivalent of super.componentWillReceiveProps before refactoring
         instead of one wrapper method.
         **/
        super.componentDidUpdate();
        super.receivedPropsValidator(prevProps);


        if (null !== this.props.formParams.id
            && this.props.GraphQLEntityData.stream !== prevProps.GraphQLEntityData.stream) {

            const stream = this.props.GraphQLEntityData.stream;

            this.setField('id', {
                value: this.props.formParams.id,
            });

            if (!this.state.optionsDeviceCategoriesLoaded) {
                this.getDeviceCategories(stream.encoding_target.id)
                    .then((response) => {
                        this.setField('device_category', {
                            disabled: false,
                            loading: false,
                            value: stream.device_category.id,
                            options: response,
                        });
                    }).catch((error) => {
                        this.addDeviceCategoriesMessageBoxError(error);
                    });
            }

            this.setAkamaiFieldsVisibility(this.props);
        }
    }

    getDeviceCategories = (targetId) => {
        this.setField('device_category', {
            disabled: true,
            loading: true,
            value: null,
            options: [],
        });

        this.setState(() => ({
            defaultFormButtons_loading: true,
        }));

        return this.runApolloRequest('query', {
            fetchPolicy: 'cache-first',
            query: gql(deviceCategoriesForDropdown),
            variables: {
                encodingTargetId: targetId,
            },
        }).then((response) => {
            this.setState(() => ({
                defaultFormButtons_loading: false,
                optionsDeviceCategoriesLoaded: true,
            }));

            return this.prepareDeviceCategories(response.data.encodingTarget.device_categories);
        }).catch((error) => {
            this.setState(() => ({
                defaultFormButtons_loading: false,
            }));

            this.addDeviceCategoriesMessageBoxError(error);
        });
    };

    setAkamaiFieldsVisibility = (props, data = {}) => {
        const encodingTarget = _find(props.GraphQLOptionsData.encodingTargets,
            (el) => convertToInt(el.id) === convertToInt('encoding_target' === data.name ? data.value : props.formValues.encoding_target)
        );

        this.setField(['akamai_cp_code', 'akamai_rtmp_distr_region'], {
            hidden: (ORIGIN_STREAM_TYPE_RTMP !== convertToInt('stream_protocol' === data.name ? data.value : props.formValues.stream_protocol))
                || false === [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(_get(encodingTarget, 'cdn.id', null))),
        });
    };

    prepareDeviceCategories = (data) => {
        return data.map((item) => {
            return {
                id: item.id,
                value: item.id,
                text: item.name,
                key: `deviceCategory_${item.id}`,
            };
        });
    };

    preFillStreamForm = (props, data = {}) => {
        const encodingTarget = _find(props.GraphQLOptionsData.encodingTargets,
            (el) => convertToInt(el.id) === convertToInt('encoding_target' === data.name ? data.value : props.formValues.encoding_target)
        );

        if (
            convertToInt(encodingTarget.encoder_type.id) === ENCODER_TYPE_MANUAL &&
            convertToInt(encodingTarget.distribution.distribution_type.id) === DISTRIBUTION_TYPE_LIVE
        ) {
            if (convertToInt(encodingTarget.distribution.product.id) === PRODUCT_LCT) {
                Object.keys(PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT).forEach(
                    key => this.setField(key, {defaultValue: PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT[key]})
                );
                this.setField(['akamai_cp_code', 'akamai_rtmp_distr_region'], {hidden: false});
            }

            if (
                convertToInt(encodingTarget.distribution.product.id) === PRODUCT_SPOTT
            ) {
                Object.keys(PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT).forEach(
                    key => this.setField(key, {defaultValue: PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT[key]})
                );
            }
        }

    };


    prepareDataForSubmit = (data) => {
        const encodingTarget = _find(this.props.GraphQLOptionsData.encodingTargets,
            (el) => convertToInt(el.id) === convertToInt(data.encoding_target));
        const akamaiAdditionalFieldsCondition = (
            ORIGIN_STREAM_TYPE_RTMP === convertToInt(data.stream_protocol)
            && [CDN_AKAMAI, CDN_AKAMAI_2].includes(convertToInt(_get(encodingTarget, 'cdn.id', null)))
        );

        const dataToSave = {
            encoding_target: convertToInt(data.encoding_target),
            device_category: convertToInt(data.device_category),
            stream_name: data.stream_name,
            bitrate_video: convertToInt(data.bitrate_video),
            bitrate_audio: convertToInt(data.bitrate_audio),
            frame_rate: data.frame_rate ? convertToInt(data.frame_rate) : null,
            resolution_width: data.resolution_width ? convertToInt(data.resolution_width) : null,
            resolution_height: data.resolution_height ? convertToInt(data.resolution_height) : null,
            duration: data.duration ? convertToInt(data.duration) : null,
            stream_format: convertToInt(data.stream_format),
            stream_protocol: convertToInt(data.stream_protocol),
            akamai_cp_code: akamaiAdditionalFieldsCondition ? _get(data, 'akamai_cp_code', null) : null,
            akamai_rtmp_distr_region: akamaiAdditionalFieldsCondition ? convertToInt(data.akamai_rtmp_distr_region) : null,
        };

        if (data.id) {
            dataToSave.id = convertToInt(data.id);
        }

        return dataToSave;
    }
}

export default withApollo(EventStreamForm);
