import {convertToInt} from "../../../utils/helpers";

export const columns = {
    "id": {
        label: "ID",
        sorting: (row) => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "country": {
        label: "Country",
        sorting: (row) => (row.country === null) ? "" : row.country.name.capitalizeFirstLetter()
    },
    "client": {
        label: "Client",
        sorting: (row) => (row.client === null) ? "" : row.client.name,
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};
