import Form from "../../app/components/ReduxFormControls";

import * as MESSAGES from "../../app/constants/messages";
import {EVENT_CONTENT} from "../../app/constants/resources";

import {UpdateEventContentInputEncodingStatus} from "../../../graphql/eventContent/mutation.graphql";
import {
    GetEventContentChangeEncodingStatusOptions,
    GetEventContentForChangeEncodingStatusForm
} from "../../../graphql/eventContent/query.graphql";

export default function EventContentChangeEncodingStatusModel() {
    return {
        entityLabel: "encoding status",
        entityDataMapKey: 'eventContent',
        formName: "eventContentChangeEncodingStatus",
        resources: EVENT_CONTENT,
        title: () => null,
        dataMap: {
            eventContent: {
                id: "",
                event_content_input: {
                    encoding_status: {
                        id: "",
                    },
                },
            },
        },
        messages: {
            boxName: {
                error: 'formInnerErrorMessage',
                success: 'eventsConfiguration',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                dataMapKey: "eventContent.id",
                props: {
                    component: Form.IdField,
                    label: "",
                    name: "id",
                    hidden: true,
                },
            },
            encoding_status: {
                dataMapKey: "eventContent.event_content_input.encoding_status.id",
                optionsKey: "encodingStatuses",
                props: {
                    allowClear: true,
                    component: Form.SemanticSelect,
                    label: "Encoding status",
                    name: "encoding_status",
                    placeholder: "Not started",
                },
            },
        },
        query: {
            getEntity: GetEventContentForChangeEncodingStatusForm,
            getOptions: GetEventContentChangeEncodingStatusOptions,
        },
        mutation: {
            updateEntity: UpdateEventContentInputEncodingStatus,
        },
        mutationOptions: {
            update: {
                refetchQueries: ['GetEventContents'],
            }
        },
    };
}
