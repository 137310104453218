import React from 'react';
import PropTypes from "prop-types";

import {PropertyLicence} from "../../../../../components/EventsTabsLayout/Details/EventDetails/column2/PropertyLicence";
import {SportImage} from "../../../../../components/EventsTabsLayout/Details/EventDetails/column2/SportImage";
import {TournamentsMasterSrEvent} from "../../../../../components/EventsTabsLayout/Details/EventDetails/column2/TournamentsMasterSrEvent";

import {category} from "../fields/column2/category";
import {firstLevelCategory} from "../fields/column2/firstLevelCategory";
import {secondLevelCategory} from "../fields/column2/secondLevelCategory";
import {sport} from "../fields/column2/sport";
import {thirdLevelCategory} from "../fields/column2/thirdLevelCategory";
import {tournament} from "../fields/column2/tournament";

export const getFieldsList = (event) => {
    let fieldsColumn = [
        {
            name: 'Property licence',
            value: <PropertyLicence event={event} />
        },
        {
            name: event.sr_event
                ? 'Sport'
                : '1st level category',
            value: event.sr_event
                ? sport(event)
                : firstLevelCategory(event),
            image: event.sr_event
                ? <SportImage event={event} />
                : null
        }
    ];

    if (event.is_master && event.sr_event) {
        fieldsColumn.push(
            {
                name: "Tournaments",
                value: <TournamentsMasterSrEvent event={event} />
            }
        )
    } else {
        fieldsColumn.push(
            {
                name: event.sr_event
                    ? 'Category'
                    : '2nd level category',
                value: event.sr_event
                    ? category(event)
                    : secondLevelCategory(event)
            },
            {
                name: event.sr_event
                    ? 'Tournament'
                    : '3rd level category',
                value: event.sr_event
                    ? tournament(event)
                    : thirdLevelCategory(event)
            }
        )
    }

    return fieldsColumn;
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.object,
        is_master: PropTypes.bool
    }).isRequired
};

getFieldsList.defaultProps = {
    event: {
        sr_event: null,
        is_master: false
    }
}
