import {GetEventGeoRestrictionForForm} from '@graphql/eventGeoRestriction/query';
import {
    UpdateEventGeoRestriction,
    DeleteEventGeoRestriction,
} from '@graphql/eventGeoRestriction/mutation';

import EventGeoRestrictionsFormModel from './EventGeoRestrictionsFormModel';

export default function EventGeoRestrictionsFormEditModel() {
    const eventGeoRestrictionsFormModel = EventGeoRestrictionsFormModel();

    eventGeoRestrictionsFormModel.editForm = true;
    eventGeoRestrictionsFormModel.deleteId = 'eventGeoRestriction.id';
    eventGeoRestrictionsFormModel.mutation = {
        updateEntity: UpdateEventGeoRestriction,
        deleteEntity: DeleteEventGeoRestriction,
    };
    eventGeoRestrictionsFormModel.query.getEntity = GetEventGeoRestrictionForForm;

    return eventGeoRestrictionsFormModel;
}
