import {
    get as _get,
    has as _has,
    sortBy as _sortBy,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';

import {gql, graphql, withApollo} from 'react-apollo';
import {routes} from '@constants/routes';
import {getLink} from '@appComponents/Link';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {SPORT_BADMINTON, SPORT_TENNIS} from '@constants/variables';
import {GetModalLoadingContent} from '@appComponents/Modal';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetChildEvents} from '@graphql/events/query';
import {getDataForMultipleEventCreation} from '@graphql/propertyLicences';
import {setErrorModal} from '@utils/modal';
import { getTvChannelNamesFromChildEvent } from '@utils/tvChannels';

import EventChildEventsTable from '../components/EventChildEventsTable';
import EventChildAlertDetails from './EventChildAlertDetails';
import {EventChildEventsHeaderButtons} from '../components/EventChildEventsHeaderButtons';
import MultipleEventCreationModalContent from './ChildEvents/MultipleEventCreationModalContent';
import {EventChildNotificationHeaderButton} from '../components/EventChildNotificationHeaderButton';
import {exportExcel as eventChildEventExportExcel} from '../utils/export/eventChildEventsExcel';
import {showErrorNoMatchesSelected, showErrorWrongMatchesSelected} from '../utils/multipleEventCreation';
import {
    getIdsFromChildEventsSelectionForMultiplePresetApply,
} from './common/EventsMultipleActions';
import {showMultipleEventPresetApplyModal} from '../utils/multipleEventActions';

const EventChildEvents = (props) => {
    let defaultMatchIds = [];

    const childEventsList = _get(props, "data.eventChildEvents", []),
        loading = _get(props, "data.loading", false),
        defaultMatchIdsString = _get(props, "match.params.matchIds", null),
        modalIsVisible = useRef(false);
    let tvChannelsExists = false,
        labelCourtVenue =  ([SPORT_TENNIS, SPORT_BADMINTON].includes(convertToInt(props.eventMasterMatchSportID)))
            ? "Court"
            : "Venue";

    childEventsList.every(childEvent => {
        if (getTvChannelNamesFromChildEvent(childEvent)) {
            tvChannelsExists = true;

            return false;
        }

        return true;
    });

    if (tvChannelsExists) {
        labelCourtVenue += ' / TV Channel';
    }

    if (defaultMatchIdsString) {
        defaultMatchIds = defaultMatchIdsString.split("+").map((matchId) => convertToInt(matchId));
    }

    const [selectedElements, selectElement] = useState([]),
        [matchesIds, setMatchesIds] = useState(defaultMatchIds),
        [isNotificationButtonVisible, setNotificationButtonVisible] = useState(false);

    useEffect(() => {
        if (props.match.path === routes.events.childEvents.add.multiple.path) {
            showMutipleEventCreationModal()
        }

        if (props.match.path === routes.events.childEvents.presetMultiple.path) {
            showMultipleEventPresetApplyModal(
                "GetChildEvents",
                props.match.params.eventsIds.split("+").map((eventId) => convertToInt(eventId)),
            );
        }
    }, [props.match.path]);

    useEffect(() => {
        if (props.match.path === routes.events.childEvents.add.multiple.path
            && props.match.path === routes.events.childEvents.presetMultiple.path
        ) {
            return;
        }

        if (modalIsVisible.current
            && !props.modal.isVisible
        ) {
            props.history.push(getLink("events.childEvents.index", {
                id: props.eventId
            }));
            modalIsVisible.current = false;
        }

        if (props.modal.isVisible) {
            modalIsVisible.current = true;
        }
    }, [props.modal.isVisible]);

    useEffect(() => {
        if (!props.data.eventChildEvents) {
            return;
        }

        selectElement([]);
        setNotificationButtonVisible(checkNotificationButtonVisibility(props.data.eventChildEvents));
    }, [props.data.eventChildEvents]);

    const showMasterEventNotificationModal = () => {
        if (!selectedElements.length) {
            setErrorModal({
                header: "Invalid selection to send notification.",
                text: "There are no events selected.",
                isVisible: true,
                size: 'large',
            });

            return;
        }

        const isEventSelectionCorrect = selectedElements.every((item) => (item.split("-")[1]));

        if (!isEventSelectionCorrect) {
            setErrorModal({
                header: "Invalid selection to send notification.",
                text: "Send notification is possible only for a selection of events.\n Please correct your selection.",
                isVisible: true,
                size: 'large',
            });
        } else {
            const childEventsIds = selectedElements.map((item) => {
                return item.split("-")[1];
            });

            props.Modal.setModal({
                header: false,
                isVisible : true,
                size: "large",
                content : <EventChildAlertDetails
                    eventId={convertToInt(props.eventId)}
                    eventsIds={childEventsIds}
                />
            });
        }
    };

    const createSportradarEventsFormOpen = () => {
        if (0 === selectedElements.length) {
            showErrorNoMatchesSelected();

            return;
        }

        const matchesIdsArray = [],
            isMatchesListCorrect = !selectedElements.some(
                (matchString) => {
                    const matchStringSplitted = matchString.split('-');

                    if (matchStringSplitted[1]) {
                        return true;
                    }

                    matchesIdsArray.push(matchStringSplitted[0]);

                    return false;
                }
            );

        if (!isMatchesListCorrect) {
            showErrorWrongMatchesSelected();

            return;
        }

        setMatchesIds(matchesIdsArray);

        props.history.push(getLink("events.childEvents.add.multiple", {
            id: props.eventId,
            matchIds: matchesIdsArray.join("+")
        }));
    };

    const checkNotificationButtonVisibility = (eventsList = []) => {
        return (
            eventsList.some((event) => (event.id.split('-')[1]))
        );
    }

    const showMutipleEventCreationModal = () => {
        props.Modal.setModal({
            content: GetModalLoadingContent(),
            isVisible: true,
            size: 'large',
        });

        props.client.query({
            query: gql(getDataForMultipleEventCreation),
            fetchPolicy: "network-only",
            variables: {
                matches: matchesIds
            }
        }).then(({data}) => {
            if (0 === data.propertyLicencesForMultipleMatchesDropdown.length) {
                showErrorWrongMatchesSelected();
            } else {
                const sortedMatches = _sortBy(data.matches, ['start_datetime']);

                props.Modal.setModal({
                    content: <MultipleEventCreationModalContent
                        data={{...data, matches: sortedMatches}}
                        matchesIds={matchesIds}
                        queryForRefetch={"GetChildEvents"}
                        modelObjectExtension={
                            {messages: {
                                boxName: {
                                    delete: 'childEventsIndex',
                                    success: 'childEventsIndex',
                                    error: 'childEventsIndex',
                                }}
                            }
                        }
                    />,
                    isVisible: true,
                    size: 'large',
                });
            }

        }).catch(() => {
            showErrorWrongMatchesSelected();
        });
    };

    const applyPresetModalOpen = () => {
        const eventsIds = getIdsFromChildEventsSelectionForMultiplePresetApply(selectedElements);

        eventsIds && props.history.push(getLink("events.childEvents.presetMultiple", {
            id: props.eventId,
            eventsIds: eventsIds.join("+")
        }));
    };

    return (
        <div>
            <HeaderRenderer
                messagesBoxNames="childEventsIndex"
                exportExcelParams={eventChildEventExportExcel(childEventsList, props.eventId, labelCourtVenue)}
                loading={loading}
                contentButtons={EventChildEventsHeaderButtons}
                contentButtonsProps={{createSportradarEventsFormOpen, applyPresetModalOpen}}
                buttons={EventChildNotificationHeaderButton}
                buttonsProps={{isHidden: !isNotificationButtonVisible, showMasterEventNotificationModal}}
            />
            <Segment basic className="--table --noPadding --noMargin">
                <EventChildEventsTable
                    url={routes.events.childEvents.index.path}
                    getSelectedElements={selectElement}
                    eventChilds={childEventsList}
                    labelForVenue={labelCourtVenue}
                    loading={loading}
                />
            </Segment>
        </div>
    );
};

EventChildEvents.propTypes = {
    data: PropTypes.object,
    eventId: PropTypes.number.isRequired,
    eventMasterMatchSportID: PropTypes.number,
    Modal: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

EventChildEvents.defaultProps = {
    data: {
        eventChildEvents: [],
        loading: false
    },
    eventMasterMatchSportID: null,
};

const EventChildEventsWithQuery = compose(
    graphql(GetChildEvents, {
        options: (props) => {
            return {
                fetchPolicy : "no-cache",
                variables: {
                    eventId: props.eventId
                }
            }
        }
    })
)(EventChildEvents);

const mapStateToProps = (state) => {
    let eventMasterMatchSportID = null;

    if (_has(state, `app.entities.event.sr_event.master_match.sport.id`)) {
        eventMasterMatchSportID = _get(state, `app.entities.event.sr_event.master_match.sport.id`);
    }

    return ({
        eventMasterMatchSportID: convertToInt(eventMasterMatchSportID) || null,
        modal: state.modal
    })
};

export default withRouter(
    withApollo(connect(mapStateToProps, mapModulesToProps(['Modal']))(EventChildEventsWithQuery))
)
