import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";

export const setClientProductsEntity = ({clientData, clientProducts, entityName, setEntity}) => {
    setEntity({
        "name": entityName,
        "data": Object.assign({}, clientData, {
            clientProducts: Object.assign([], clientData.clientProducts, clientProducts),
        }),
    });
};

export const deleteSuccessCallback = ({clientData, entityName, formParamsId, historyPush, setEntity}) => {
    const clientProducts = clientData.clientProducts.filter(clientProduct => (
        formParamsId !== convertToInt(clientProduct.id)
    ));

    setEntity({
        name: entityName,
        data: {
            clientProducts: clientProducts,
            id: clientData.id,
            name: clientData.name,
        },
    });

    historyPush(getLink("clients.edit", {id: clientData.id}));
};
