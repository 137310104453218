import PropTypes from "prop-types";

import {PULL, PUSH} from "../../../app/constants/variables";

export const exportExcel = (dataExport, clientId, type) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_third_party_${(PUSH === type ? PUSH : PULL)}_stream_names`,
    parseData: thirdPartyStreamName => [
        thirdPartyStreamName.id,
        (PUSH === type)
            ? thirdPartyStreamName.third_party_client_cdn.name
            : thirdPartyStreamName.third_party_client_pull_origin.name,
        thirdPartyStreamName.stream_name,
        thirdPartyStreamName.description || '',
    ],
    path: `clients.products.thirdParty.streamNames${(
        PUSH === type
            ? PUSH.capitalizeFirstLetter()
            : PULL.capitalizeFirstLetter()
    )}.index.path`,
    titles: [
        "ID",
        (PUSH === type) ? "CDN" : "Pull origin",
        "Stream name",
        "Description",
    ],
});

exportExcel.propTypes = {
    clientId: PropTypes.number.isRequired,
    dataExport: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
};
