import React from "react";

import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link"
import Authorization from "../../app/components/Authorization";

import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";

const UdpOriginEntryPointIndexButtons = () => {
    const authorizationObject = {
        [RESOURCES.UDP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_CREATE_READ
    };

    return (
        <div className="content__buttons">
            <Authorization authorization={authorizationObject}>
                <Link name="udpOriginEntryPoint.add">
                    <ButtonAdd>{"Add new UDP origin entry point"}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

export default UdpOriginEntryPointIndexButtons;
