import PropTypes from 'prop-types';

export const exportExcel = (dataExport, countryId) => ({
    dataExport: dataExport,
    filename: `country_${countryId}_subdivisions`,
    parseData: dataRow => [
        dataRow.id,
        dataRow.name,
        dataRow.iso_code,
        dataRow.num_postal_codes ? dataRow.num_postal_codes : 0,
    ],
    path: 'countries.subdivisions',
    titles: [
        'ID',
        'Name',
        'ISO code',
        'Postal codes',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
