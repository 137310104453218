import React from 'react';
import PropTypes from 'prop-types';

import {renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import {ButtonBackToList} from '@appComponents/ButtonCollection';
import {CONTACT_PERSON, LICENSOR} from '@constants/resources';

import ContactPersonIndex from '../views/ContactPersonIndex';
import LicensorEdit from '../views/LicensorEdit';

export const createTabs = (id, path, name) => {
    return (routes.licensors.add.path === path)
        ? {
            [routes.licensors.add.path]: {
                key: routes.licensors.add.path,
                url: 'licensors.add',
                text: 'General information',
                render: <LicensorEdit/>,
                resource: LICENSOR,
            },
        }
        : {
            [routes.licensors.edit.path]: {
                key: routes.licensors.edit.path,
                url: 'licensors.edit',
                urlParams: {id: id},
                text: 'General information',
                render: <LicensorEdit/>,
                resource: LICENSOR,
            },
            [routes.licensors.contactPerson.index.path]: {
                key: routes.licensors.contactPerson.index.path,
                url: 'licensors.contactPerson.index',
                urlParams: {id: id},
                text: 'Contact persons',
                render: <ContactPersonIndex name={name}/>,
                resource: CONTACT_PERSON,
            },
        };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createLicensorTabs';

export const RenderBackToListButton = React.memo(() => {
    return <ButtonBackToList name={'licensors.index'} />;
});
RenderBackToListButton.displayName = 'RenderBackToListButton';

export const renderHeaderTitle = ({path, licensorName = ''}) => {
    if (routes.licensors.add.path === path) {
        return 'Add new licensor';
    }
    else if (licensorName) {
        return `Licensor ${licensorName}`;
    }

    return 'Licensor';
};
renderHeaderTitle.propTypes = {
    licensorName: PropTypes.string,
    path: PropTypes.string.isRequired,
};
renderHeaderTitle.displayName = 'renderHeaderTitle';

export const renderLicensorModalError = () => {
    return renderModalError('Licensor', routes.licensors.index.path);
};
renderModalError.displayName = 'renderLicensorModalError';
