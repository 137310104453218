import React from 'react';
import {Icon, Message} from 'semantic-ui-react';

const UsersTableMessage = () => (
    <Message icon info>
        <Icon name='info circle'/>
        <Message.Content>
            AV-CMP uses Sportradar Common-IAM as authentication system.<br />
            Please use the form below to search in the Common-IAM database for the user to be added.
        </Message.Content>
    </Message>
);

export default UsersTableMessage;
