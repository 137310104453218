import PropTypes from "prop-types";
import {get as _get} from "lodash";

export const getFieldsList = (event) => {
    return [
        {
            id: _get(event, 'sr_event.match.match_status.id', null),
            name: 'Status',
            value: _get(event, 'sr_event.match.match_status.name', '-')
        },
        {
            name: 'Started at',
            value: _get(event, 'sr_event.match.started_datetime', '-')
        },
        {
            name: 'Ended at',
            value: _get(event, 'sr_event.match.ended_datetime', '-')
        }
    ];
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                match_status: PropTypes.shape({
                    name: PropTypes.string
                }),
                started_datetime: PropTypes.string,
                ended_datetime: PropTypes.string
            })
        })
    }).isRequired
};

getFieldsList.defaultProps = {
    event: {
        sr_event: {
            match: {
                match_status: {
                    name: null
                },
                started_datetime: null,
                ended_datetime: null
            }
        }
    }
}
