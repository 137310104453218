import moment from "moment";

import validators from '../../../utils/validators';
import {TRIGGER_DATE_OPTION, TRIGGER_MARGIN_OPTION} from "../../app/constants/variables";

export const validateStartForm = (values, props) => {
    const errors = {};
    const {timezone} = props;

    if (values.trigger_type === TRIGGER_MARGIN_OPTION) {
        Object.assign(errors, validators.digitsOnly(values.encoding_start_margin, 'encoding_start_margin'));

        const valueWithTz = moment.tz(values.event_content_start_datetime, timezone);

        if (moment.tz(timezone).isAfter(valueWithTz.subtract(values.encoding_start_margin, "minutes"))) {
            errors.encoding_start_margin = "Start time cannot be earlier than the current time."
        }
    }

    if (values.trigger_type === TRIGGER_DATE_OPTION) {
        Object.assign(errors, validators.dateTime(values.encoding_start_datetime, 'encoding_start_datetime'));

        if (moment.tz(timezone).isAfter(moment.tz(values.encoding_start_datetime, timezone))) {
            errors.encoding_start_datetime = "Start time cannot be earlier than the current time."
        }
    }

    return errors;
};

export const validateEndForm = (values, props) => {
    const errors = {};
    const {timezone} = props;

    if (values.trigger_type === TRIGGER_MARGIN_OPTION) {
        Object.assign(errors, validators.digitsOnly(values.encoding_end_margin, 'encoding_end_margin'));

        const valueWithTz = moment.tz(values.event_content_end_datetime, timezone);

        if (moment.tz(timezone).isAfter(valueWithTz.add(values.encoding_end_margin, "minutes"))) {
            errors.encoding_end_margin = "End time cannot be earlier than the current time."
        }
    }

    if (values.trigger_type === TRIGGER_DATE_OPTION) {
        Object.assign(errors, validators.dateTime(values.encoding_end_datetime, 'encoding_end_datetime'));

        if (moment.tz(timezone).isAfter(moment.tz(values.encoding_end_datetime, timezone))) {
            errors.encoding_end_datetime = "End time cannot be earlier than the current time."
        }
    }

    return errors;
};
