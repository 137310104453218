import {validate as clientProductValidate} from './validatorClientProduct';
import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, clientProductValidate(values));

    Object.assign(errors, validators.required(values.cdn_price, 'cdn_price'));

    Object.assign(errors, validators.floatAndNumbersWithFixedDecimal({value: values.cdn_price}, 'cdn_price'));

    if (true === values.has_additional_cdn_bucket) {
        Object.assign(errors, validators.required(values.additional_cdn_bucket_size_gb, 'additional_cdn_bucket_size_gb'));
        Object.assign(errors, validators.digitsOnly(values.additional_cdn_bucket_size_gb, 'additional_cdn_bucket_size_gb'))
    }

    if (new Date(values.client_product_start_date) > new Date(values.client_product_end_date)) {
        Object.assign(errors, {client_product_end_date: "End date must be later than the Start date"});
    }

    Object.assign(errors, validators.date(values.client_product_start_date, "client_product_start_date"));
    Object.assign(errors, validators.date(values.client_product_end_date, "client_product_end_date"));
    Object.assign(errors, validators.required(values.client_product_start_date, "client_product_start_date"));
    Object.assign(errors, validators.required(values.client_product_end_date, "client_product_end_date"));

    return errors;
};

export default validate;
