import {get as _get, isEmpty as _isEmpty, isNil as _isNil} from 'lodash';
import moment from 'moment';
import {Label} from 'semantic-ui-react';
import React from 'react';

import {getCompetitorsNameFromEvent} from "@utils/competitorName";
import {getSportNameFromEvent, getSportObject, isCourtSport} from '@utils/sport';
import {getStadiumOrVenueNameFromEvent} from "@utils/stadium";
import {getTournamentCategoryNameFromEvent, getTournamentNameFromEvent} from "@utils/tournament";
import {addHasMatchIdToStructure} from "@utils/eventObject";
import {renderEventStatusName} from '@utils/status';
import { getTvChannelNamesFromChildEvent, getTvChannelNamesFromEvent } from '@utils/tvChannels';


export const eventRowRenderer = (column, row) => {
    switch (column) {
        case 'start_datetime':
            return moment(row[column]).format('YYYY-MM-DD HH:mm');
        case 'eventId':
            return row.id;
        case 'matchId':
            return _get(row, 'sr_event.match.id', '-');
        case 'sport':
            return getSportNameFromEvent(row);
        case 'category':
            return getTournamentNameFromEvent(row);
        case 'tournament':
            return getTournamentCategoryNameFromEvent(row);
        case 'stadium': {
            const tvChannels = getTvChannelNamesFromEvent(row);

            return (
                <>
                    {getStadiumOrVenueNameFromEvent(row)}
                    {tvChannels &&
                        <p>{tvChannels}</p>
                    }
                </>
            );
        }
        case 'competitors':
            return (
                <div>
                    <div>
                        {getCompetitorsNameFromEvent(addHasMatchIdToStructure(row))}
                    </div>
                    <div>
                        {_isNil(row.description)
                            ? ''
                            : <Label style={{backgroundColor: `#${row.color}`}} size='mini'>{row.description}</Label>
                        }
                    </div>
                </div>
            );
        case 'status':
            return renderEventStatusName(row.event_status.id, row.event_status.name);
        case 'coverage':
            return getCoverage(row.products);
        case 'notes':
            return row.notes;
        case 'content_category_lvl_1':
            return row.av_event.content_category.parent.parent.name;
        case 'content_category_lvl_2':
            return row.av_event.content_category.parent.name;
        case 'content_category_lvl_3':
            return row.av_event.content_category.name;
        default:
            return '-';
    }
};

export const columnsForAvEvents = (events = []) => {
    let venueColumnName = 'Venue';

    events.every(event => {
        if (getTvChannelNamesFromEvent(event)) {
            venueColumnName += ' / TV Channel';

            return false;
        }

        return true;
    });

    return {
        'start_datetime': {
            label: 'Date/time',
            sorting: true,
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
                singleLine: true,
            },
        },
        'eventId': {
            label: 'Event ID',
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
            sorting: (row) => row.id,
        },
        'content_category_lvl_1': {
            label: '1st level category',
            sorting: (row) => _get(row, 'av_event.content_category.parent.parent.name', ''),
        },
        'content_category_lvl_2': {
            label: '2nd level category',
            sorting: (row) => _get(row, 'av_event.content_category.parent.name', ''),
        },
        'content_category_lvl_3': {
            label: '3rd level category',
            sorting: (row) => _get(row, 'av_event.content_category.name', ''),
        },
        'stadium': {
            label: venueColumnName,
            sorting: (row) => `${getStadiumOrVenueNameFromEvent(row)} ${getTvChannelNamesFromEvent(row)}`,
        },
        'competitors': {
            label: 'Competitors',
            sorting: (row) => getCompetitorsNameFromEvent(row),
        },
        'status': {
            label: 'Status',
            sorting: (row) => row.event_status.name,
        },
        'coverage': {
            label: 'Coverage',
            sorting: (row) => getCoverage(row.products),
        },
        'notes': {
            label: 'Notes',
            sorting: (row) => row.notes,
        },
    };
};

export const columnsForSrEvents = (events = []) => {
    const sportObject = getSportObject(events[0]);
    let courtVenueColumnName = (!_isEmpty(sportObject) && isCourtSport(sportObject.id)) ? 'Court' : 'Venue';

    events.every(event => {
        if (getTvChannelNamesFromEvent(event)) {
            courtVenueColumnName += ' / TV Channel';

            return false;
        }

        return true;
    });

    return {
        'start_datetime': {
            label: 'Date/time',
            sorting: true,
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
                singleLine: true,
            },
        },
        'eventId': {
            label: 'Event ID',
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
            sorting: (row) => row.id,
        },
        'matchId': {
            label: 'Match ID',
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
            sorting: (row) => (_get(row, 'sr_event.match.id', null)),
        },
        'sport': {
            label: 'Sport',
            sorting: (row) => getSportNameFromEvent(row),
        } ,
        'category': {
            label: 'Category',
            sorting: (row) => getTournamentCategoryNameFromEvent(row),
        } ,
        'tournament': {
            label: 'Tournament',
            sorting: (row) => getTournamentNameFromEvent(row),
        } ,
        'stadium': {
            label: courtVenueColumnName,
            sorting: (row) => `${getStadiumOrVenueNameFromEvent(row)} ${getTvChannelNamesFromEvent(row)}`,
        } ,
        'competitors': {
            label: 'Competitors',
            sorting: (row) => getCompetitorsNameFromEvent(row),
        } ,
        'status': {
            label: 'Status',
            sorting: (row) => row.event_status.name,
        } ,
        'coverage': {
            label: 'Coverage',
            sorting: (row) => getCoverage(row.products),
        } ,
        'notes': {
            label: 'Notes',
        },
    };
};

export const matchColumns = (matches) => {
    let courtVenueColumnName = 'Venue / Court';

    matches.every(event => {
        if (getTvChannelNamesFromChildEvent(event)) {
            courtVenueColumnName += ' / TV Channel';

            return false;
        }

        return true;
    });

    return {
        'start_datetime': {
            label: 'Date/time',
            sorting: true,
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
                singleLine: true,
            },
        },
        'id': {
            label: 'Match ID',
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
            sorting: true,
        },
        'sport': {
            label: 'Sport',
            sorting: (row) => row.tournament.tournament_category.sport.name,
        },
        'category': {
            label: 'Category',
            sorting: (row) => row.tournament.tournament_category.name,
        },
        'tournament': {
            label: 'Tournament',
            sorting: (row) => row.tournament.name,
        },
        'stadium': {
            label: courtVenueColumnName,
            sorting: (row) => `${_get(row, 'stadium.name', '')} ${getTvChannelNamesFromChildEvent(row)}`,
        },
        'competitors': {
            label: 'Competitors',
            sorting: (row) => getCompetitorsNameFromEvent({sr_event: row}),
        },
    };
};

export const matchRowRenderer = (column, row) => {
    switch (column) {
        case 'start_datetime':
            return moment(row[column]).format('YYYY-MM-DD HH:mm');
        case 'sport':
            return row.tournament.tournament_category.sport.name;
        case 'category':
            return row.tournament.tournament_category.name;
        case 'tournament':
            return row.tournament.name;
        case 'stadium': {
            const tvChannels = getTvChannelNamesFromChildEvent(row);

            return (
                <>
                    {_get(row, 'stadium.name', '')}
                    {tvChannels &&
                        <p>{tvChannels}</p>
                    }
                </>
            );
        }
        case 'competitors':
            return getCompetitorsNameFromEvent({sr_event: row});
        default:
            return row[column];
    }
};

const getCoverage = (products) => {
    return products.length
        ? products.map(element => element.short_name).join(', ')
        : '';
};
