import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import {get as _get} from 'lodash';

import {convertToInt} from '@utils/helpers';
import SegmentHeader from '@appComponents/SegmentHeader';
import ListingInfo from '@appComponents/ListingInfo';

import StartDatetime from './EventsConfigurationEncodingInformation/StartDatetime';
import EndDatetime from './EventsConfigurationEncodingInformation/EndDatetime';
import StatusLabel from './EventsConfigurationEncodingInformation/StatusLabel';
import HeaderButtons from './EventsConfigurationEncodingInformation/HeaderButtons';

const EventsConfigurationEncodingInformation = (props) => {
    const {content, eventData, encodingTargets} = props;
    const contentId = convertToInt(content.id);
    const eventId = convertToInt(eventData.id);

    function parseEncodingData() {
        if (!content) {
            return null;
        }

        return [
            [
                {
                    name: 'Start time',
                    value: <StartDatetime content={content} eventData={eventData}/>,
                },
                {
                    name: 'End time',
                    value: <EndDatetime content={content} eventData={eventData}/>,
                },
            ],
            [
                {
                    name: 'Status',
                    value: <StatusLabel contentId={contentId} eventId={eventId} eventContentInput={content.event_content_input}/>,
                },
                {
                    name: 'Redundancy type',
                    value: _get(content, 'event_content_input.encoding_redundancy_type.name'),
                },
            ],
            [
                {
                    name: 'Encoder',
                    value: _get(content, 'event_content_input.encoder', null),
                },
                {
                    name: 'Message',
                    value: _get(content, 'event_content_input.encoding_message', null),
                    hidden: null === _get(content, 'event_content_input.encoding_message', null),
                },
            ],
        ];
    }

    const parsedData = parseEncodingData();

    return (
        <div className='eventsConfigurationEncodingInformation'>
            <SegmentHeader buttons={
                <HeaderButtons content={content} eventData={eventData} encodingTargets={encodingTargets}/>
            }> Encoding information </SegmentHeader>
            {parsedData && <div>
                <Grid columns={parsedData.length}>
                    {parsedData.map((columnFields, columnNumber) => (
                        <Grid.Column key={columnNumber}>
                            <ListingInfo data={columnFields.filter(field => !field.hidden)}/>
                        </Grid.Column>
                    ))}
                </Grid>
            </div>}
        </div>
    );
};

EventsConfigurationEncodingInformation.propTypes = {
    content: PropTypes.object,
    eventData: PropTypes.object,
    encodingTargets: PropTypes.array,
    timezone: PropTypes.string,
};

export default EventsConfigurationEncodingInformation;
