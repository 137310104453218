import React from "react";

const NotAuthorized = () => (
    <div>
        <h1 className="not-authorized-page__header">Not authorized</h1>
        <div>
            <p className="not-authorized-page__description">You are not authorized to access this page.</p>
        </div>
    </div>
);

export default NotAuthorized;
