import React from "react";
import {Dropdown} from "semantic-ui-react";
import PropTypes from "prop-types";

import {gql} from "react-apollo";
import {ENCODING_TARGET, LIVE_CONTROL} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {convertToInt} from "../../../../utils/helpers";
import {
    setSlateIdViaVideoPlatformController,
    updateLiveControlTargetsSlates
} from "../../../../graphql/liveControl/actions";

const authorizationLiveControlEncodingTargetUpdateObject = {
    [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_UPDATE,
};

class SlateButton extends React.PureComponent {
    static propTypes = {
        client: PropTypes.object,
        getTargetIDsAndFirstValueByBoolColumn: PropTypes.func,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        row: PropTypes.object
    };

    state = {
        loading: false,
    };

    componentDidMount() {
        let targets = this.getTargets(this.props);

        this.updateState(this.props, targets);
    }

    componentDidUpdate(prevProps, prevState) {
        let targets = this.getTargets(prevProps);

        if (!prevState.targets || targets.value !== prevState.targets.value) {
            this.updateState(prevProps, targets);
        }
    }

    getTargets = (props) => (props.getTargetIDsAndFirstValueByBoolColumn("has_slate_support", props.row, "slate.id"));

    getSlates = (props) => (
        [{
            id: 0,
            key: 0,
            value: 0,
            text: "No slate",
            content: "No slate",
        }].concat(props.row.slates)
    );

    updateState = (props, targets) => {
        let slates = this.getSlates(props);

        this.setState(() => ({
            value: convertToInt(targets.value) || 0,
            slates: slates,
            targets: targets,
        }));
    };

    setSlateInTargets = (data) => {
        const targetIds = this.state.targets.ids;

        let value = data.value === 0 ? null : parseInt(data.value, 10);

        this.setState(() => ({
            loading: true
        }));
        this.props.client.mutate({
            mutation: gql(updateLiveControlTargetsSlates(
                targetIds,
                "slate"
            )),
            variables: {
                val: value
            }
        }).then(() => {
            this.setState(() => ({
                value: data.value,
                loading: false
            }))
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                "liveControl",
                "An error occurred while trying to update the slate.",
                `${error}`,
                "error"
            );
        })
    };

    changeSlate = (event, data) => {
        if (this.state.value === data.value) {
            return
        }

        let value = data.value === 0 ? null : parseInt(data.value, 10);

        if (!this.props.row.encoding_status_id) {
            this.setSlateInTargets(data);

            return
        }

        this.props.client.mutate({
            mutation: gql(setSlateIdViaVideoPlatformController),
            variables: {
                eventContentId: parseInt(this.props.row.event_content_id, 10),
                encodingDatacenterName: this.props.row.encoding_datacenter_name,
                slateId: value
            },
        }).then(() => {
            this.setSlateInTargets(data)
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                "liveControl",
                `An error occurred while trying to change the slate via the `
                + "Video Platform Controller API.",
                `${error}`,
                "error"
            );
        })
    };

    render() {
        return (
            <Authorization authorization={authorizationLiveControlEncodingTargetUpdateObject}>
                <Dropdown
                    loading={this.state.loading}
                    labeled button simple
                    className={"rightIcon"}
                    onChange={this.changeSlate}
                    options={(this.state.loading) ? [] : this.state.slates}
                    active={this.state.value}
                    value={this.state.value}
                />
            </Authorization>
        );
    }
}

export default SlateButton
