import Form from "../../../app/components/ReduxFormControls";

import {UpdateEventUsageTypes} from "../../../../graphql/eventUsageTypes/mutation.graphql";

import * as MESSAGES from "../../../app/constants/messages";
import {EVENT as RESOURCE_EVENT} from "../../../app/constants/resources";

export default function EventUsageTypesModel() {
    return {
        entityDataMapKey: 'eventUsageTypes',
        entityLabel: 'event',
        content: "Save",
        formName: "EventUsageTypes",
        resources: RESOURCE_EVENT,
        showChangeLog: false,
        mutation: {
            updateEntity: UpdateEventUsageTypes
        },
        query: {},
        title: 'Event usage types',
        dataMap: {
            event: {
                id: "",
            },
            usage_types: []
        },
        messages: {
            boxName: {
                success: 'event',
                error: 'event'
            },
            text: {...MESSAGES, SAVED: () => "The usage types have been saved successfully."}
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "event.id",
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                }
            },
            usages: {
                optionsKey: "usage_types",
                props: {
                    component: Form.SemanticCheckboxTree,
                    label: "Usage types",
                    name: "usage_types",
                },
            },
        }
    }
}
