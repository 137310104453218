import React from "react";
import PropTypes from "prop-types"

import ContentCategoryForm from "../forms/ContentCategoryForm";
import ContentCategoryModel from "../forms/ContentCategoryModel";
import ContentCategoryModelEdit from "../forms/ContentCategoryModelEdit";

import {createForm} from "../../../utils/forms";

const ContentCategoryEdit = ({id, level, parentId = null, refreshData}) => {
    const Form = React.useMemo(
        () => (createForm(
            id ? ContentCategoryModelEdit : ContentCategoryModel,
            ContentCategoryForm,
            {id, level, parentId, refreshData}
        )),
        [id]
    );

    return <Form/>;
};

ContentCategoryEdit.propTypes = {
    id: PropTypes.number,
    level: PropTypes.number.isRequired,
    parentId: PropTypes.number,
    refreshData: PropTypes.func.isRequired
};

export default ContentCategoryEdit;
