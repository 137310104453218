import PropTypes from 'prop-types';
import React from 'react';
import {Grid, Header, Popup} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {CircleButtonAdd} from '@appComponents/ButtonCollection';
import {convertToInt} from '@utils/helpers';
import {getSuffixWithNumber} from '@utils/ordinalNumberSuffix';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

import ContentCategoryTable from './ContentCategoryTable';

const createReadAuthorizationObject = {
    [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

export class ContentCategoryLevel extends React.Component {
    constructor(props) {
        super(props);

        let dataValues = {};

        if (props.data) {
            props.data.forEach(data => {
                dataValues[data.id] = data;
            });
        }

        this.state = {
            data: dataValues.data,
        };
    }

    createCategory = (event) => {
        event.stopPropagation();
        let dataset = event.currentTarget.dataset;
        const parent = this.props.parent;
        const parentQueryString = parent ? `?parentId=${parent}` : '';
        this.props.editCategory({level: convertToInt(dataset.level)});
        window.history.pushState({}, '', `/content-categories/level-${dataset.level}/add${parentQueryString}`);
    };

    editCategory = (row) => {
        this.props.editCategory(this.state.data[convertToInt(row.id)]);
        window.history.pushState({}, '', `/content-categories/level-${row.level}/${row.id}`);
    };

    componentDidUpdate(prevProps) {
        let dataValues = {};

        if (this.props.data && this.props.data !== prevProps.data) {
            this.props.data.forEach((data)=> {
                dataValues[data.id] = data;
            });

            this.setState(() => ({
                data: dataValues,
            }));
        }
    }

    render() {
        let props = {rowOnClick : this.props.rowOnClick};

        if (3 === this.props.level) {
            props = {};
        }

        return (
            <Grid.Column>
                <div className='table-header-container'>
                    <Header as='h3' className='table-header-container__header'>
                        {`${getSuffixWithNumber(this.props.level)} level categories`}
                    </Header>
                    <Authorization authorization={createReadAuthorizationObject}>
                        <Popup
                            content={`Add ${getSuffixWithNumber(this.props.level)} level category`}
                            position='bottom right'
                            trigger={
                                <CircleButtonAdd
                                    data-id={null}
                                    data-level={this.props.level}
                                    onClick={this.createCategory}
                                    className='table-header-container__button plus circle'
                                    size='large'
                                />
                            }
                        />
                    </Authorization>

                    <div className='clearfix'/>
                </div>
                <ContentCategoryTable
                    selected={3 === this.props.level ? [] : this.props.selected}
                    data={this.props.data}
                    level={this.props.level}
                    loading={this.props.loading}
                    rowRendererProps={{
                        editCallback: this.editCategory,
                        deleteCallback: this.props.deleteCategory,
                    }}
                    {...props}
                />
            </Grid.Column>
        );
    }
}

ContentCategoryLevel.propTypes = {
    editCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    data: PropTypes.array,
    loading: PropTypes.bool,
    rowOnClick: PropTypes.func,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    selected: PropTypes.array,
    parent: PropTypes.number,
};

export default ContentCategoryLevel;
