import {
    get as _get,
} from 'lodash';
import {withRouter} from 'react-router-dom';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {renderArchivingButton} from '@utils/archiving';
import {ToggleIsArchiveStateBlackoutZone} from '@graphql/blackoutZone/mutation';

class GeneralInformationForm extends DefaultForm {
    renderCancelButton = () => null;

    prepareDataForSubmit = (data) => {
        return super.prepareDataForSubmit(data).blackoutZone;
    };

    componentDidMount() {
        this.setFallbackRoute(getLink('blackoutZones.index'));
        this.setCreateSuccessCallback((data) => {
            this.props.history.push(getLink('blackoutZones.edit.countriesAndSubdivisions', {
                id: _get(data, 'data.createBlackoutZone.id', ''),
            }));
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('blackoutZones.index'));
        });
    }

    renderArchiveButton = () => (renderArchivingButton(
        {
            ...this.props,
            messageBoxName: this.props.Model.messages.boxName.success,
        },
        ToggleIsArchiveStateBlackoutZone,
        {
            id: _get(this.props, 'match.params.id', null),
            name: _get(this.props, 'GraphQLEntityData.blackoutZone.name', null),
        }
    ));
}

export default withRouter(GeneralInformationForm);
