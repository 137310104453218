import PropTypes from 'prop-types';
import React from 'react';
import {get as _get} from 'lodash';
import {Dimmer, Loader} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {convertToInt, getParsedId} from '@utils/helpers';
import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import Authorization from '@appComponents/Authorization';
import {IconEdit, IconGlobe} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {
    MASTER_BOOKING,
    CLIENT,
} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {GetMappedIsoCodes} from '@graphql/country/query';

const authorizationMasterBookingObject = {
    [MASTER_BOOKING]: SECURITY_PRIVILEGES_READ,
};

const authorizationClientObject = {
    [CLIENT]: SECURITY_PRIVILEGES_READ,
};

const EventMasterBookingsTable = (props) => {
    const {data, masterBookings, eventId, loading} = props;

    const rowRenderer = (column, row) => {
        const bookingGeoRestrictions = _get(row, 'master_booking_geo_restrictions', []),
            deviceCategoriesCountries = parseDeviceCategories(data.mappedIsoCodes, bookingGeoRestrictions);

        switch (column) {
            case 'masterBookingId': {
                return (
                    <LinkWithAuthorization
                        authorization={authorizationMasterBookingObject}
                        link={{
                            name: 'events.masterBookings.edit',
                            params: {
                                id: eventId,
                                masterBookingId: row.id,
                            },
                        }}
                    >
                        {row.id}
                    </LinkWithAuthorization>
                );
            }
            case 'client': {
                return (
                    <LinkWithAuthorization
                        authorization={authorizationClientObject}
                        link={{
                            name: 'clients.edit',
                            params: {id : row.client.id},
                        }}
                    >
                        {row.client.name}
                    </LinkWithAuthorization>
                );
            }
            case 'product': {
                return row.product.short_name;
            }
            case 'deviceCategories':
                return renderDeviceCategories(deviceCategoriesCountries);
            case 'countries':
                return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesCountries, data.mappedIsoCodes);
            case 'numberOfCountries':
                return renderNumberOfCountries(deviceCategoriesCountries);
            case 'client_package': {
                return (row.client && row.client.id && row.client_package && row.client_package.id)
                    && <LinkWithAuthorization
                        authorization={authorizationClientObject}
                        link={{
                            name: 'clients.packages.edit',
                            params: {id : _get(row, 'client.id'), packagesId: _get(row, 'client_package.id')},
                        }}
                    >
                        {_get(row, 'client_package.name', null)}
                    </LinkWithAuthorization>;
            }
            case 'actions': {
                return (
                    <Authorization
                        authorization={authorizationMasterBookingObject}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit size='large' link={
                                getLink('events.masterBookings.edit',
                                    {id: eventId, masterBookingId: convertToInt(row.id)}
                                )}
                            resource={MASTER_BOOKING}
                            />
                            <IconGlobe size='large' link={
                                getLink('events.masterBookings.editGeoRestrictions',
                                    {id : eventId, masterBookingId: convertToInt(row.id)}
                                )}
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    const sortDeviceCategories = (rowData) => {
        if (rowData.master_booking_geo_restrictions.length) {
            return rowData.master_booking_geo_restrictions.map(function(masterBookingGeoRestriction){
                return _get(masterBookingGeoRestriction, 'device_category.name', '');
            }).join('');
        }

        return '';
    };

    const columns = {
        'masterBookingId': {
            label: 'ID',
            sorting: row => getParsedId(row),
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        'client': {
            label: 'Client',
            sorting: row => row.client.name,
        },
        'product': {
            label: 'Product',
            sorting: row => row.product.short_name,
        },
        'deviceCategories': {
            label: 'Device categories',
            sorting: row => sortDeviceCategories(row),
        },
        'countries': {
            label: 'Countries',
            sorting: false,
            className: 'noPadding',
        },
        'numberOfCountries': {
            label: '#Countries',
            sorting: false,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
            className: 'noPadding',
        },
        'client_package': {
            label: 'Package',
            sorting: row => row.client_package.name,
        },
        'booking_type': {
            label: 'Type',
            sorting: row => row.booking_type.name,
        },
        'creation_datetime': {
            label: 'Time',
            sorting: row => row.creation_datetime,
            header: {
                align: 'center',
                isCollapsed: false,
            },
            content: {
                align: 'center',
            },
        },
        'actions': {
            label: 'Actions',
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
    };

    if (loading) {
        return (
            <Dimmer inverted active={true}>
                <Loader/>
            </Dimmer>
        );
    }

    return (
        <div className='eventMasterBookings__table'>
            <Table
                className='mini'
                name='eventMasterBookingsList'
                columns={columns}
                data={masterBookings}
                loading={loading}
                pagination={false}
                noDataText='No master bookings found'
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

EventMasterBookingsTable.propTypes = {
    eventId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    loading: PropTypes.bool,
    masterBookings: PropTypes.array,
};
EventMasterBookingsTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
    loading: false,
    masterBookings: [],
};
EventMasterBookingsTable.displayName = 'EventMasterBookingsTable';

export default graphql(GetMappedIsoCodes)(EventMasterBookingsTable);
