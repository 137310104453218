import {find as _find, join as _join} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from "react-apollo";

import Authorization from "../../app/components/Authorization";
import {getLink} from "../../app/components/Link";
import {IconAngleRight, IconEdit, IconGlobe} from "../../app/components/IconCollection";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import Table from "../../app/components/Table";

import {getSortingTerritories, getTerritoriesWithTooltip} from "../../../utils/countryHelpers";
import {convertToInt} from "../../../utils/helpers";

import * as CONSTANT from "../../app/constants/variables";
import {CLIENT_PACKAGE_GEO_RESTRICTION} from "../../app/constants/resources";

import {GetMappedIsoCodes} from "../../../graphql/country/query.graphql";

const columns = {
    "id": {
        label: "ID",
        sorting: true,
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "usages": {
        label: "Usage",
        sorting: (row) => prepareUsages(row.usages).map((deviceCategory) =>
            deviceCategory.name + ' > ' + _join(deviceCategory.way_of_transmissions, ' / ')
        )
    },
    "countries": {
        label: "Territories",
        sorting: (row) => getSortingTerritories(row),
    },
    "notes": {label: "Notes", sorting: (row) => row.notes.capitalizeFirstLetter()},
    "actions": {
        label: "Actions",
        header: {
            align: "center",
        },
        content: {
            align: "center"
        }
    }
};

const renderUsages = (usages) => {
    return (
        <div>
            {prepareUsages(usages).map((deviceCategory, i) =>
                <p key={i} className='--marginBottom-5'>
                    {deviceCategory.name} <IconAngleRight/> {_join(deviceCategory.way_of_transmissions, ' / ')}
                </p>
            )}
        </div>
    );
};

const prepareUsages = (usages) => {
    let usagesArray = [];

    usages.map((usage) => {
        let deviceCategory = _find(usagesArray, {name: usage.device_category.name});

        (deviceCategory)
            ? deviceCategory.way_of_transmissions.push(usage.way_of_transmission.name)
            : usagesArray.push({
                name: usage.device_category.name,
                way_of_transmissions: [usage.way_of_transmission.name]
            });
    });

    return usagesArray;
};

const GeoRestrictionsTable = (props) => {
    const {clientId, clientPackageId, data, geoRestrictions, loading} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
                return (
                    <LinkWithAuthorization
                        resources={CLIENT_PACKAGE_GEO_RESTRICTION}
                        privileges={CONSTANT.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: "clients.packages.geoRestrictions.edit",
                            params: {
                                id: clientId,
                                packagesId: clientPackageId,
                                geoRestrictionId: row.id
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case "usages":
                return renderUsages(row[column]);
            case "countries":
                return getTerritoriesWithTooltip(row, data.mappedIsoCodes);
            case "notes":
                return row[column];
            case "actions": {
                return (
                    <Authorization
                        resources={CLIENT_PACKAGE_GEO_RESTRICTION}
                        privileges={CONSTANT.SECURITY_PRIVILEGES_UPDATE}
                    >
                        <div className="actions-list-icon">
                            <IconEdit
                                link={getLink('clients.packages.geoRestrictions.edit', {
                                    id: clientId,
                                    packagesId: clientPackageId,
                                    geoRestrictionId: convertToInt(row.id),
                                })}
                                size="large"
                            />
                            <IconGlobe
                                link={getLink('clients.packages.geoRestrictions.editGeoRestrictions', {
                                    id: clientId,
                                    packagesId: clientPackageId,
                                    geoRestrictionId: convertToInt(row.id),
                                })}
                                size="large"
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="geo_restrictions__table">
            <Table
                columns={columns}
                data={geoRestrictions}
                loading={loading}
                name="geoRestrictionsList"
                noDataText="No geo restrictions found"
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

GeoRestrictionsTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    clientPackageId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        })
    }),
    geoRestrictions: PropTypes.array,
    loading: PropTypes.bool
};
GeoRestrictionsTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {}
        }
    },
    geoRestrictions: [],
    loading: false
};
GeoRestrictionsTable.displayName = 'GeoRestrictionsTable';

export default graphql(GetMappedIsoCodes)(GeoRestrictionsTable);
