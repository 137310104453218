import {debounce as _debounce, isEmpty as _isEmpty} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {gql, graphql, withApollo} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {reduxForm} from "redux-form";
import {Button, Header} from "semantic-ui-react";

import {FormWrapper} from "../../app/components/HOCFormWrapper";
import {getLink} from "../../app/components/Link";
import Form from "../../app/components/ReduxFormControls";
import Authorization from "../../app/components/Authorization";

import * as formUtils from "../../../utils/forms";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import validate from "./validator";
import {validatorTypes} from '../../../utils/validators';
import * as MESSAGES from "../../app/constants/messages";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

import {bookmakersListQuery} from "../../../graphql/bookmaker";
import * as clientGraphQl from "../../../graphql/clients/index";

class ClientForm extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientData: PropTypes.object,
        CreateClient: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        DeleteClient: PropTypes.func,
        Entity: PropTypes.shape({
            setEntity: PropTypes.func.isRequired,
        }),
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        history: PropTypes.object,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        QueryBookmakers: PropTypes.any,
        submitting: PropTypes.bool,
        UpdateClient: PropTypes.func,
    };

    static defaultProps = {
        clientData: null,
    };

    constructor(props) {
        super(props);

        const clientTypes = [
            { value: 1, key: 'Betting' },
            { value: 2, key: 'Non-betting' },
        ];

        this.state = {
            loadingBookmakers: false,
            bookmakerOption: [],
            clientTypesOption: clientTypes,
            clientTypesSelected: [],
            notificationEmailRecipients: []
        };
    }

    componentWillReceiveProps(nextProps) {
        let bookmakerOption = [];
        let clientTypes = [];
        let selectedClientTypes = [];
        let notificationEmailRecipients = [];
        let is_lbc_stream_playback_enabled = false;

        if (!this.state.dataReceived && nextProps.dataForForm.client.id) {

            if (nextProps.dataForForm.client.client_types) {
                selectedClientTypes = nextProps.dataForForm.client.client_types.map(
                    (client_types) => parseInt(client_types.id, 10)
                );
            }

            if (nextProps.dataForForm.clientTypes && !_isEmpty(nextProps.dataForForm.clientTypes)) {
                clientTypes = nextProps.dataForForm.clientTypes.map((clientType) =>
                    ({value: parseInt(clientType.value, 10), key: clientType.text}));
            }

            if (this.state.bookmakerOption.length) {
                bookmakerOption = this.state.bookmakerOption;
            }
            else if (nextProps.formData.client !== undefined && nextProps.formData.client.bookmaker !== null) {
                bookmakerOption = [{
                    key: parseInt(nextProps.formData.client.bookmaker.id),
                    text: `${nextProps.formData.client.bookmaker.name} (${nextProps.formData.client.bookmaker.id})`,
                    value: nextProps.formData.client.bookmaker.id,
                }];
            }

            if (nextProps.dataForForm.client.is_lbc_stream_playback_enabled === true) {
                is_lbc_stream_playback_enabled = true;
            }

            if (nextProps.dataForForm.client.notification_email_recipients
                && !_isEmpty(nextProps.dataForForm.client.notification_email_recipients)) {
                notificationEmailRecipients = nextProps.dataForForm.client.notification_email_recipients;
            }

            this.setState(() => ({
                dataReceived: true,
                bookmakerOption: bookmakerOption,
                clientTypesOption: !_isEmpty(clientTypes) ? clientTypes : this.state.clientTypesOption,
                clientTypesSelected: selectedClientTypes,
                is_lbc_stream_playback_enabled: is_lbc_stream_playback_enabled,
                notificationEmailRecipients: notificationEmailRecipients
            }));
        }
    }

    onFormSubmit(data) {
        const dataToSave = Object.assign({}, this.props.dataMap.client, data, {
            bookmaker: data.bookmaker !== undefined ? parseInt(data.bookmaker) : null,
            crm_id: data.crm_id !== undefined ? parseInt(data.crm_id) : null,
            country: parseInt(data.country_id),
            is_lbc_stream_playback_enabled: this.state.is_lbc_stream_playback_enabled !== undefined ?
                this.state.is_lbc_stream_playback_enabled : false,
            notification_email_recipients: this.state.notificationEmailRecipients,
        });

        const redirectAfterCreating = (data) => {
            this.props.history.push(getLink('clients.edit', {id: data.data.createClient.id}));
        };

        const updateClientDataInStore = (data) => {
            this.props.Entity.setEntity({
                name: "client",
                data: Object.assign({}, this.props.clientData, {
                    bookmaker: data.data.updateClient.bookmaker,
                }),
            });
        };

        return formUtils.onSubmit({
            dataToSave,
            actions: { create: this.props.CreateClient, update: this.props.UpdateClient },
            message: {
                box: this.props.MessageBox,
                boxName: { success: 'clientMessage', error: 'clientMessage' },
                text: MESSAGES,
                entityLabel: 'client',
                entityName: data.name
            },
            callback: {
                created: redirectAfterCreating,
                updated: updateClientDataInStore,
            }
        });
    }

    deleteClient = () => {
        const redirectAfterDeleting = () => {
            this.props.history.replace(`/clients`);
        };

        return formUtils.onDelete({
            id: parseInt(this.props.formData.client.id, 10),
            action: this.props.DeleteClient,
            modal: this.props.Modal,
            message: {
                box: this.props.MessageBox,
                boxName: { success: 'clientMessageIndex', error: 'clientMessage' },
                text: {
                    ...MESSAGES,
                    DELETE_IN_PROGRESS: () => MESSAGES.DELETING('client', this.props.dataForForm.client.name)
                },
                entityLabel: 'client',
                entityName: this.props.dataForForm.client.name
            },
            callback: {
                deleted: redirectAfterDeleting
            }
        });
    };

    deleteButtonClick = (e) => {
        e.preventDefault();

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={MESSAGES.DELETE_HEADER('client')} />,
            onYes: this.deleteClient,
            text: `Are you sure you want to delete the client "${this.props.dataForForm.client.name}"?`
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (this.props.dataForForm.client.id !== undefined && this.props.dataForForm.client.id !== "") {
            deleteButton =
                <Button onClick={this.deleteButtonClick}
                    color="red"
                    disabled={this.props.submitting}
                    icon="trash"
                    content="Delete"/>
        }

        return deleteButton;
    };

    searchBookmaker = (e, target) => {
        const searchString = target.searchQuery || "";

        if (searchString.length >= 3 || String(parseInt(searchString)) === searchString){
            this.setState(() => ({loadingBookmakers: true}));
            this.props.client.query({
                fetchPolicy: "network-and-cache",
                query: gql`${bookmakersListQuery}`,
                variables: {
                    search: searchString
                }
            }).then((data) => {
                const bookmakerOption = data.data.bookmakers.map((bookmaker) =>
                    ({
                        key: parseInt(bookmaker.value, 10),
                        text: `${bookmaker.text} (${bookmaker.value})` ,
                        value: bookmaker.value
                    }));

                this.setState(() => ({
                    loadingBookmakers: false,
                    bookmakerOption: bookmakerOption
                }));
            }).catch(() => {
                this.setState(() => ({
                    loadingBookmakers: false,
                    bookmakerOption: []
                }));
            });
        }
    };

    toggleOption = (data, props) => {
        this.setState(() => ({
            [props.input.name]: data.checked
        }))
    };

    onChangeNotificationEmailRecipients = (data) => {
        if (undefined !== data) {
            this.setState(() => ({
                notificationEmailRecipients: data
            }));
        }
    };

    render() {
        return (
            <Form.Create
                loading={(this.props.formData !== undefined) ? this.props.formData.loading : false}
                onSubmit={this.props.handleSubmit((values) => {return this.onFormSubmit(values)})}
            >
                <Form.IdField
                    defaultValue={this.props.dataForForm.client.id}
                />
                <Form.FormRowBookmaker
                    allowClear
                    loading={this.state.loadingBookmakers}
                    onSearchChange={_debounce(this.searchBookmaker, 1300)}
                    options={this.state.bookmakerOption}
                    value={this.props.dataForForm.client}
                />
                <Form.FormRowName
                    defaultValue={this.props.dataForForm.client.crm_id}
                    label="CRM ID"
                    name="crm_id"
                    width="one"
                />
                <Form.FormRowName
                    defaultValue={this.props.dataForForm.client.name}
                    required
                />
                <Form.FormRowName
                    defaultValue={this.props.dataForForm.client.brands}
                    label="Brands"
                    name="brands"
                />
                <Form.FormRowAddress
                    defaultValue={this.props.dataForForm.client.address}
                />
                <Form.FormRowCountry
                    options={ this.props.dataForForm.countries }
                    value={ this.props.dataForForm.client }
                />
                <Form.FormRow
                    className="inline"
                    component={Form.SemanticCheckboxList}
                    defaultValue={this.state.clientTypesSelected}
                    label="Type"
                    name="client_types"
                    options={this.state.clientTypesOption}
                    required
                />
                <Form.FormRowVatNumber
                    defaultValue={this.props.dataForForm.client.vat_number}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.client.is_internal || false}
                    label="Internal client"
                    name="is_internal"
                    type="checkbox"
                />
                <Form.FormRow
                    addItemShortcuts={[',', ';']}
                    component={Form.SemanticInput}
                    defaultValueMultiple={this.state.notificationEmailRecipients}
                    label="Notification email recipients"
                    name="notification_email_recipients"
                    type="multiple"
                    onChange={this.onChangeNotificationEmailRecipients}
                    renderLabelParams={{
                        className: 'invalid',
                        condition: validatorTypes.email.validate
                    }}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    name="is_lbc_stream_playback_enabled"
                    label="Stream playback in LBC"
                    onCheckboxChange={this.toggleOption}
                    checked={this.state.is_lbc_stream_playback_enabled}
                    type="toggle"
                />
                <Form.FormRowNotes
                    defaultValue={this.props.dataForForm.client.notes}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.client.is_disabled || false}
                    label="Disabled"
                    name="is_disabled"
                    type="checkbox"
                />
                <Form.FormRowChangelog
                    resources={RESOURCES.CLIENT}
                    data={this.props.dataForForm.client}
                />
                <div className="formRow form__footer">
                    <label/>
                    <Button
                        color="blue"
                        content="Save"
                        disabled={this.props.submitting}
                        icon="save"
                        loading={this.props.submitting}
                        type="submit"
                    />
                    <Authorization
                        privileges={CONST.SECURITY_PRIVILEGES_DELETE}
                        resources={RESOURCES.CLIENT}
                    >
                        {this.renderDeleteButton()}
                    </Authorization>
                </div>
            </Form.Create>
        );
    }
}

const ClientMutations = {
    CreateClient: gql`${clientGraphQl.clientCreateMutation}`,
    DeleteClient: gql`${clientGraphQl.clientDeleteMutation}`,
    UpdateClient: gql`${clientGraphQl.clientUpdateMutation}`
};

const ClientFormWithRedux = reduxForm({
    form: 'Client',
    validate
})(withRouter(ClientForm));

const mapDispatchToProps = mapModulesToProps(['Entity']);

const mapStateToProps = (state) => ({
    clientData: undefined !== state.app.entities.client ? state.app.entities.client : null,
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(
    graphql(ClientMutations.CreateClient, {name: 'CreateClient'}),
    graphql(ClientMutations.DeleteClient, {name: 'DeleteClient'}),
    graphql(ClientMutations.UpdateClient, {name: 'UpdateClient'}),
)(FormWrapper({
    client: {
        crm_id: null,
        id: "",
        name: "",
        brands: "",
        address: "",
        vat_number: "",
        notes: "",
        notification_email_recipients: [],
        is_lbc_stream_playback_enabled: false,
        country: {id : ""},
        client_types: [],
        bookmaker: {id : ""},
        is_disabled: null,
        is_internal: null,
        creation_datetime: "",
        creation_user: {
            id: "",
            name: "",
        },
        update_datetime: "",
        update_user: {
            id: "",
            name: "",
        }
    },
    bookmakers: [],
    countries:[],
    clientTypes:[]
}, (withApollo)(ClientFormWithRedux))));
