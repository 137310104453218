import PropTypes from "prop-types";
import React from "react";
import {withRouter} from "react-router";

import SlateEdit from "../views/SlateEdit"
import SlateIndex from "../views/SlateIndex"

import {routes} from "../../app/constants/routes";

const SlateLayout = (props) => {
    const {match, technicalSetupId} = props,
        slateRoutes = routes.propertyLicences.technicalSetup.slates;

    return (match.path === slateRoutes.add.path || match.path === slateRoutes.edit.path)
        ? <SlateEdit technicalSetupId={technicalSetupId}/>
        : <SlateIndex technicalSetupId={technicalSetupId}/>;
};

SlateLayout.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }),
    technicalSetupId: PropTypes.number.isRequired,
};

export default withRouter(SlateLayout)
