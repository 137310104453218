import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';
import {get as _get} from 'lodash';
import {useQuery} from '@apollo/client';

import {withApollo} from 'react-apollo';
import {FiltersWrapper, getSelectedValues} from '@appComponents/HOCFiltersWrapper';
import {getBookingFiltersIsCancelledStatus} from '@utils/booking';
import {GetDropdownData} from '@graphql/eventsEventBookings/query';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import Form from '@appComponents/ReduxFormControls';

export const DEFAULT_FILTERS_VALUES = {
    clients: [],
    productsDropdown: [],
    eventContentTypes: [],
    matchEventTypes: [],
    deviceCategories: [],
    countries: [],
    bookingTypes: [],
    isCancelled: 0,
};

export const EventsEventBookingsFilters = ({form, filters, tableLoading, restoreFilterValues}) => {
    const {EventsEventBookings} = filters;

    const {data, loading, error} = useQuery(GetDropdownData, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            const filterFieldsToUpdate = [
                'clients',
                'productsDropdown',
                'eventContentTypes',
                'matchEventTypes',
                'deviceCategories',
                'countries',
                'bookingTypes',
            ];

            filterFieldsToUpdate.forEach((fieldName) => {
                const values = restoreFilterValues(EventsEventBookings, fieldName, []);
                handleFiltersChange(data, values, fieldName);
            });
        },
    });

    if (error) {
        showErrorModal({
            header: 'Failed to fetch event bookings filter data',
            text: error,
            errorButtonText: 'Back to dashboard',
            hideButtons: false,
            redirectLink: getLink('dashboard'),
            errorButtonIcon: 'arrow circle left',
            size: 'large',
        });

        return null;
    }

    const generateDropdownData =
        (filterData, filterFieldName) => _get(filterData, filterFieldName, []);

    const handleFiltersChange = (data, selectedValues, filterFieldName) => {
        const filterDataValues = generateDropdownData(data, filterFieldName);
        const values = getSelectedValues(selectedValues, filterDataValues);

        form.changeValue(filterFieldName, values);
    };

    const filterFields = [
        {
            name: 'clients',
            placeholder: 'Client',
            options: generateDropdownData(data, 'clients'),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'clients'),
        },
        {
            name: 'productsDropdown',
            placeholder: 'Product',
            options: generateDropdownData(data, 'productsDropdown'),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'productsDropdown'),
        },
        {
            name: 'eventContentTypes',
            placeholder: 'Event content type',
            options: generateDropdownData(data, 'eventContentTypes').sort((a, b) => a.text.localeCompare(b.text)),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'eventContentTypes'),
        },
        {
            name: 'matchEventTypes',
            placeholder: 'Match event type',
            options: generateDropdownData(data, 'matchEventTypes'),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'matchEventTypes'),
        },
        {
            name: 'deviceCategories',
            placeholder: 'Device category',
            options: generateDropdownData(data, 'deviceCategories'),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'deviceCategories'),
        },
        {
            name: 'countries',
            placeholder: 'Country',
            options: generateDropdownData(data, 'countries'),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'countries'),
        },
        {
            name: 'bookingTypes',
            placeholder: 'Type',
            options: generateDropdownData(data, 'bookingTypes'),
            multiple: true,
            search: true,
            onChange: (selectedValues) => handleFiltersChange(data, selectedValues, 'bookingTypes'),
        },
        {
            name: 'isCancelled',
            placeholder: 'Cancellation status',
            options: getBookingFiltersIsCancelledStatus(),
            multiple: false,
            search: false,
            defaultValue: 0,
        },
    ];

    return (
        <>
            {filterFields.map((field) => (
                <div key={field.name} className='eventBookings__filters filter__controls__container'>
                    <Field
                        component={Form.ReduxSelect}
                        name={field.name}
                        placeholder={field.placeholder}
                        options={field.options}
                        search={field.search}
                        loading={loading || tableLoading}
                        multiple={field.multiple}
                        disabled={loading}
                        onChange={field.onChange}
                        setValue={restoreFilterValues(EventsEventBookings, field.name, field.defaultValue)}
                    />
                </div>
            ))}
        </>
    );
};

EventsEventBookingsFilters.displayName = 'EventsEventBookingsFilters';

EventsEventBookingsFilters.propTypes = {
    data: PropTypes.shape({
        bookingTypes: PropTypes.array,
        clients: PropTypes.array,
        countries: PropTypes.array,
        deviceCategories: PropTypes.array,
        eventContentTypes: PropTypes.array,
        matchEventTypes: PropTypes.array,
        products: PropTypes.array,
    }),
    filters: PropTypes.objectOf(PropTypes.object),
    tableLoading: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
    form: PropTypes.object,
};

export default FiltersWrapper(withApollo(EventsEventBookingsFilters), {
    name: 'EventsEventBookings',
    initialValues: DEFAULT_FILTERS_VALUES,
});
