import React from 'react';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
/* eslint import/no-unresolved: 0 */
import moment from 'moment';
import {Header} from 'semantic-ui-react';
import {reduxForm} from 'redux-form';

import {compose, graphql, gql} from 'react-apollo';
import {hideModal} from '@utils/modal';
import Form from '@appComponents/ReduxFormControls';
import {FormWrapper} from '@appComponents/HOCFormWrapper';
import {ButtonCancel, ButtonSave} from '@appComponents/ButtonCollection';
import MessageBox from '@appComponents/MessageBox';
import {showMessageBox} from '@utils/messageBox';
import {
    ENCODING_START_MARGIN,
    TRIGGER_DATE_OPTION,
    TRIGGER_MANUAL_OPTION,
    TRIGGER_MARGIN_OPTION,
} from '@constants/variables';
import {ModalHeaderEventContentInformation} from '@modules/events/views/common/ModalHeaderEventContentInformation';
import {updateEventContent as GraphQLupdateEventContent} from '@graphql/liveControl/index';
import {submit} from '@modules/events/utils/eventsConfigurationEncodingInformation';

import {validateStartForm} from './validator';

class ModifyEncodingStartDatetimeForm extends React.Component {
    static propTypes = {
        change: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        valid: PropTypes.bool,
        submitFailed: PropTypes.bool,
        UpdateEventContent: PropTypes.func,
        successCallback: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let selectedOption = TRIGGER_DATE_OPTION;

        const data = props.formData;

        if (null === data.encoding_start_margin && null === data.encoding_start_datetime) {
            selectedOption = TRIGGER_MANUAL_OPTION;
        } else if (null !== data.encoding_start_margin) {
            selectedOption = TRIGGER_MARGIN_OPTION;
        }

        this.state = {
            selected: selectedOption,
        };
    }

    componentDidUpdate() {
        if (!this.props.valid && this.props.submitFailed && !this.state.defaultForm_errorShowed) {
            showMessageBox('formInnerErrorMessage',
                null, 'The encoding start time has not been saved. Please correct the highlighted fields below.', 'error');
            this.setState(() => ({
                defaultForm_errorShowed: true,
            }));
        }
    }

    componentDidMount() {
        this.props.change('trigger_type', this.state.selected);
    }

    handleChange = ({input}) => {
        input.onChange(input.value);

        this.setState(() => ({
            selected: input.value,
        }));
    };

    renderDatepicker = () => (
        <div className='modifyEncodingDatetime-byDate--datepicker'>
            <Form.FormField
                className='--minimalWidth --datetimeWithoutSeconds'
                name='encoding_start_datetime'
                component={Form.SemanticInput}
                configuration={{
                    showTimeSelect: true,
                    timeFormat: 'HH:mm',
                    timeIntervals: 5,
                    minDate: moment(),
                }}
                defaultValue={_get(this.props.dataForForm, 'encoding_start_datetime', null)}
                type='date'
            />
        </div>
    );

    onFormSubmit = (data) => {
        let dataToSave = Object.assign({}, this.props.dataMap, data);

        let eventContentUpdateVariables = {
            id: dataToSave.event_content_id,
            eventContentInput: {
                encoding_start_datetime: null,
                encoding_start_margin: null,
            },
        };

        if (this.state.selected === TRIGGER_MARGIN_OPTION) {
            eventContentUpdateVariables.eventContentInput.encoding_start_margin = parseInt(data.encoding_start_margin, 10);
        } else if (this.state.selected === TRIGGER_DATE_OPTION) {
            eventContentUpdateVariables.eventContentInput.encoding_start_datetime = data.encoding_start_datetime + ':00';
        }

        return submit({
            promise: this.props.UpdateEventContent,
            variables: eventContentUpdateVariables,
            eventId: this.props.formData.event_id,
            successCallback: this.props.successCallback,
        });
    };

    render() {
        return (
            <div>
                <Header>Modify encoding start time</Header>
                <ModalHeaderEventContentInformation event={this.props.formData}/>
                <MessageBox name='formInnerErrorMessage' className='--formInnerError'/>
                <Form.Create
                    onSubmit={this.props.handleSubmit(this.onFormSubmit)}
                >
                    <Form.FormField
                        name='event_content_id'
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.event_content_id}
                        hidden={true}
                    />
                    <Form.FormField
                        name='event_content_start_datetime'
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.event_content_start_datetime}
                        hidden={true}
                    />
                    <div className='modifyEncodingDatetime-triggerManually'>
                        <Form.FormField
                            name='trigger_type'
                            component={Form.RenderRadio}
                            handleChange={this.handleChange}
                            selected={this.state.selected === TRIGGER_MANUAL_OPTION}
                            disabled={this.props.submitting}
                            type='radio'
                            label='Trigger manually (no start time)'
                            value={TRIGGER_MANUAL_OPTION}
                        />
                    </div>
                    <div className='modifyEncodingDatetime-byMargin'>
                        <label>
                            <Form.FormField
                                name='trigger_type'
                                component={Form.RenderRadio}
                                handleChange={this.handleChange}
                                selected={this.state.selected === TRIGGER_MARGIN_OPTION}
                                disabled={this.props.submitting}
                                type='radio'
                                value={TRIGGER_MARGIN_OPTION}
                            />
                            <Form.FormField
                                name='encoding_start_margin'
                                className='modifyEncodingDatetime-minutes'
                                type='number'
                                onlyDigitsAllowed
                                min={0}
                                component={Form.SemanticInput}
                                defaultValue={this.props.dataForForm.encoding_start_margin || ENCODING_START_MARGIN}
                            />
                            <span>minutes before the expected event content start time</span>
                        </label>
                    </div>
                    <div className='modifyEncodingDatetime-byDate'>
                        <label className='--flex'>
                            <Form.FormField
                                name='trigger_type'
                                component={Form.RenderRadio}
                                handleChange={this.handleChange}
                                selected={this.state.selected === TRIGGER_DATE_OPTION}
                                disabled={this.props.submitting}
                                type='radio'
                                value={TRIGGER_DATE_OPTION}
                            />
                            <span>Fixed time: </span>
                            {this.renderDatepicker()}
                        </label>
                    </div>
                    <div className='formRow form__footer'>
                        <label/>
                        <ButtonSave
                            loading={this.props.submitting}
                            disabled={this.props.submitting}>
                            Save
                        </ButtonSave>
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={() => hideModal()}
                        >
                            Cancel
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </div>
        );
    }
}


export default compose(
    graphql(gql(GraphQLupdateEventContent), {name: 'UpdateEventContent'})
)(FormWrapper({
    event_content_id: '',
    encoding_start_margin: '',
    encoding_start_datetime: '',
    event_content_start_datetime: '',
    trigger_type: '',
}, reduxForm({form: 'ModifyEncodingDatetime', validate: validateStartForm})(ModifyEncodingStartDatetimeForm)));
