import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'blackout_zones',
    parseData: postalCode => [
        postalCode.id,
        postalCode.country.name,
        _get(postalCode, 'country_subdivision.name', ''),
        postalCode.code,
    ],
    path: 'blackoutZones.edit.postalCodes.index',
    titles: [
        'Id',
        'Country',
        'Subdivision',
        'Code',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
