
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SdiOriginEntryPointData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SdiOriginEntryPoint"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"input_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"encoding_datacenter"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SdiOriginEntryPointDataForForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SdiOriginEntryPoint"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SdiOriginEntryPointData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"preview_url"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChangelogData"},"directives":[]}]}}],"loc":{"start":0,"end":327}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment SdiOriginEntryPointData on SdiOriginEntryPoint {\n    ...BasicData\n    description\n    input_id\n    encoding_datacenter {\n        ...BasicData\n    }\n}\n\nfragment SdiOriginEntryPointDataForForm on SdiOriginEntryPoint {\n    ...SdiOriginEntryPointData\n    preview_url\n    ...ChangelogData\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
