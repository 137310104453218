import React from 'react';

import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {CLIP_CONFIGURATION} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

const authorizationObject = {
    [CLIP_CONFIGURATION]: SECURITY_PRIVILEGES_CREATE,
};

const ClipConfigurationIndexButtons = () => {
    return (
        <div className='content__buttons'>
            <Authorization authorization={authorizationObject}>
                <Link name={'clipConfiguration.add.addOnSportLevel'}>
                    <ButtonAdd>Add new clip configuration on sport level</ButtonAdd>
                </Link>
                <Link name={'clipConfiguration.add.addOnTournamentLevel'}>
                    <ButtonAdd>Add new clip configuration on tournament level</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default ClipConfigurationIndexButtons;
