import {LCR_CHANNEL} from '@constants/resources';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {
    UpdateLcrChannelSportTerritory,
    UpdateLcrChannelTournamentCategoryTerritory,
    UpdateLcrChannelTournamentTerritory,
} from '@graphql/lcr/mutation';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from '@constants/variables';

const TerritorySelectionModel = (type = null) => (
    () => {
        const optionObject = {
            label: '',
            updateEntityMutation: null,
            entityDataMapKey: null,
        };

        switch (type) {
            case 'sports':
                optionObject.label = 'sports selection type';
                optionObject.updateEntityMutation = UpdateLcrChannelSportTerritory;
                break;
            case 'categories':
                optionObject.label = 'categories selection type';
                optionObject.updateEntityMutation = UpdateLcrChannelTournamentCategoryTerritory;
                break;
            case 'tournaments':
                optionObject.label = 'tournaments selection type';
                optionObject.updateEntityMutation = UpdateLcrChannelTournamentTerritory;
                break;
        }

        return {
            formName: `LcrAdministration-contentRestrictions-${optionObject.label}`,
            title: null,
            label: optionObject.label,
            entityLabel: optionObject.label,
            resources: LCR_CHANNEL,
            editForm: true,
            showChangeLog: false,
            dataRequest: false,
            dataMap: {
                lcrAdministration: {
                    id: null,
                    'territory_selection_type': TERRITORY_SELECTION_TYPE_WORLDWIDE,
                },
            },
            fields: {
                id: {
                    component: Form.IdField,
                    props: {
                        hidden: true,
                        label: '',
                        name: 'id',
                    },
                },
                territory_selection_type: {
                    optionsKey: 'selectionTypes',
                    props: {
                        className: '--small-width',
                        component: Form.SemanticSelect,
                        defaultValue: TERRITORY_SELECTION_TYPE_WORLDWIDE,
                        required: true,
                        label: 'Selection type',
                        name: 'territory_selection_type',
                    },
                },
            },
            messages: {
                boxName: {
                    success: `lcrChannelContentRestriction-${type}`,
                    error: `lcrChannelContentRestriction-${type}`,
                },
                text: {
                    ...MESSAGES,
                },
            },
            mutation: {
                updateEntity: optionObject.updateEntityMutation,
            },
            query: {},
        };
    }
);

export default TerritorySelectionModel;
