import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const ProductThirdPartyStreamNamesPullTable = (props) => {
    const {editClick, loading, productThirdPartyStreamNamesPull} = props;

    const columns = {
        "id": {
            label: "ID",
            sorting: true,
            header: {
                align: "center",
                isCollapsed: true,
            },
            content: {
                align: "center",
            },
        },
        "third_party_client_pull_origin": {
            label: "Pull origin",
            sorting: (row) => row.third_party_client_pull_origin.name.capitalizeFirstLetter(),
        },
        "stream_name": {
            label: "Stream name",
            sorting: (row) => row.stream_name.capitalizeFirstLetter(),
        },
        "description": {
            label: "Description",
            sorting: (row) => row.description ? row.description.capitalizeFirstLetter() : "",
        },
        "actions": {
            label: "Actions",
            header: {
                align: "center",
                isCollapsed: true,
            },
            content: {
                align: "center",
            },
        },
    };

    const renderNameOfFirstLevelDependency = (row, column) => {
        return (
            <div>
                {(row[column] === null || row[column] === undefined)
                    ? ""
                    : row[column].name
                }
            </div>
        );
    };

    const linkToModal = (event) => {
        editClick(event.target.dataset.id);
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "stream_name":
                return (
                    <Authorization
                        notAuthorized={<span>{row[column]}</span>}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.THIRD_PARTY_CLIENT_PULL_STREAM_NAME}
                    >
                        <p className="link-to-modal" data-id={row.id} onClick={linkToModal}>{row[column]}</p>
                    </Authorization>
                );
            case "third_party_client_pull_origin" :
                return renderNameOfFirstLevelDependency(row, column);
            case "actions": {
                return (
                    <Authorization
                        combinedResources={false}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.THIRD_PARTY_CLIENT_PULL_STREAM_NAME}
                    >
                        <div className="actions-list-icon">
                            <IconEdit onClick={editClick} onClickElement={row.id} size="large" />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="product_third_party_stream_names_pull__table">
            <Table
                columns={columns}
                data={productThirdPartyStreamNamesPull}
                loading={loading}
                name="productThirdPartyStreamNamesPullList"
                noDataText="No stream names found"
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ProductThirdPartyStreamNamesPullTable.defaultProps = {
    productThirdPartyStreamNamesPull: [],
};

ProductThirdPartyStreamNamesPullTable.propTypes = {
    editClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    productThirdPartyStreamNamesPull: PropTypes.array,
};

export default ProductThirdPartyStreamNamesPullTable;
