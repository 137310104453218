import React from "react";
import PropTypes from "prop-types";
import {Dropdown, Icon} from "semantic-ui-react";
import {connect} from "react-redux";
import {
    isEqual as _isEqual,
    isFunction as _isFunction,
} from "lodash"
import classnames from "classnames";

import {ReduxComponent} from "./ReduxComponent";

export class ReduxSelect extends ReduxComponent {
    static propTypes = {
        options: PropTypes.array,
        onKeyUp: PropTypes.func
    };

    static defaultProps = {
        options: []
    };

    constructor(props) {
        super(props);

        let value = this.parseValue(props.value),
            defaultValue = this.parseValue(props.defaultValue),
            allowClear = true,
            selected = null;

        if (undefined === props.value && undefined !== props.defaultValue) {
            value = defaultValue;
            selected = defaultValue;
        }

        if (undefined !== props.allowClear) {
            allowClear = props.allowClear
        }

        this.state = {
            value: value,
            forceClear: false,
            allowClear: allowClear,
            defaultValue: defaultValue,
            options: props.options,
            setValue: props.setValue,
            visited: false,
            selected: selected
        };

        this.parseValue = this.parseValue.bind(this);
    }

    componentWillMount() {
        let value = this.state.value;

        if (undefined !== this.props.setValue) {
            value = this.changeValue(this.props.setValue, false);
        }

        this.setState(() => ({
            value: value,
            selected: value
        }));
    }

    parseValue(value) {
        return super.parseValue(value, this.props.multiple)
    }

    changeValue = (value, setState = true) => {
        const val = this.parseValue(value);

        if (setState) {
            this.setState(() => ({
                selected: val
            }));
        }

        this.props.input.onChange(val);
        this.userChangeHandler(val);

        return val;
    };

    userChangeHandler = (value) => {
        if (_isFunction(this.props.onChangeValue)) {
            this.props.onChangeValue(null, value, this.props)
        }
    };

    onKeyUp = (event, data) => {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(event, data);
        }
    };

    componentWillReceiveProps(nextProps) {
        let value = this.parseValue(nextProps.input.value);

        if (nextProps.selected && !_isEqual(nextProps.selected, this.state.selected)) {
            this.setState(() => ({
                selected: nextProps.selected
            }));

            this.changeValue(nextProps.selected);

            return
        }

        if (!_isEqual(nextProps.setValue, this.state.setValue)) {
            let setValue = nextProps.setValue;

            this.setState(() => ({
                setValue: setValue,
            }));
            this.changeValue(setValue);

            return
        }

        if (!_isEqual(nextProps.defaultValue, this.props.defaultValue) && !this.state.visited) {
            let defaultValue = this.parseValue(nextProps.defaultValue);
            this.setState(() => ({
                defaultValue: defaultValue,
                visited: true
            }));
            this.changeValue(defaultValue);

            return
        }

        if (!_isEqual(value, this.state.value)) {
            this.userChangeHandler(value)
        }

        this.setState(() => ({
            value: value
        }));
    }

    resetToEmptyValue = () => {
        this.setState(() => ({
            forceClear: true
        }), () => {
            this.changeValue(null)
        });
    };

    onChange = (event, data) => {
        let value =  this.parseValue(data.value);

        this.setState(() => ({
            value: value
        }));
        this.userChangeHandler(value);
        this.props.input.onChange(value)
    };

    removeTypenameFromOptions = () => {
        if (this.props.options && this.props.options !== []) {
            return this.props.options.map((item) => {
                const newItem = Object.assign({}, item);
                delete newItem.__typename;

                return newItem;
            });
        }

        return [];
    };

    render() {
        const isClearSelectButtonVisible = this.state.allowClear
            && this.state.value !== undefined && this.state.value.length !== 0 && !this.props.meta.submitting
            && !this.props.loading && !this.props.disabled,
            clearSelectButtonClassName = `reset-select ${isClearSelectButtonVisible ? '' : 'hidden'}`;
        let className = this.props.className || "";
        const isSelectOnBlur = this.props.selectOnBlur || false;

        className += this.isError(this.props) ? " error" : "";

        return (
            <div className={classnames({'hidden': this.props.hidden}, `wrapper-${this.props.input.name}`)}>
                <div className={'dropdown-select input-container'}>
                    <div className="form__field-wrapper">
                        <Dropdown
                            selection
                            loading={this.props.loading}
                            className={classnames(className + " small", isClearSelectButtonVisible ? 'withRightPadding' : '')}
                            multiple={this.props.multiple}
                            onChange={this.onChange}
                            search={this.props.search}
                            options={this.removeTypenameFromOptions()}
                            selected={this.state.selected}
                            selectOnBlur={isSelectOnBlur}
                            placeholder={this.props.placeholder}
                            value={this.state.value}
                            disabled={this.props.disabled}
                            onSearchChange={this.onKeyUp}
                        />
                        <div className={clearSelectButtonClassName} onClick={this.resetToEmptyValue}>
                            <Icon name="close"/>
                        </div>
                    </div>
                </div>
                {this.renderErrorMessage(this.props)}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const value = (
        state.form[props.meta.form]
        && state.form[props.meta.form].values
        && state.form[props.meta.form].values[props.input.name]
    )
        ? state.form[props.meta.form].values[props.input.name]
        : null;

    return {
        storedValue : value
    }
};

export default connect(mapStateToProps)(ReduxSelect);
