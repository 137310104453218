import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {convertToInt} from "../../../../../../../../utils/helpers";
import {RedLabel} from "../../../../../../../../utils/decorators";

import {RTS_COVERAGE_CONFIRMED} from "../../../../../../../app/constants/variables";

/**
 * Value for "RTS scout data"
 */
export const RtsScoutData = ({event}) => {
    const rtsCoverageId = _get(event, 'sr_event.match.rts_coverage.id', null),
        rtsConfirmed = (RTS_COVERAGE_CONFIRMED === convertToInt(rtsCoverageId));

    return (rtsConfirmed && event.sr_event.match.rts_coverage)
        ? <RedLabel label={'RTS'} />
        : 'No'
};

RtsScoutData.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                rts_coverage: PropTypes.shape({
                    id: PropTypes.string
                })
            })
        })
    }).isRequired
};

RtsScoutData.defaultProps = {
    event: {
        sr_event: {
            match: {
                rts_coverage: {
                    id: null
                }
            }
        }
    }
}
