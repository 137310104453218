import PropTypes from 'prop-types';
import React from 'react';
import {Field} from "redux-form";

import Form from "../../app/components/ReduxFormControls";
import {FiltersWrapper} from "../../app/components/HOCFiltersWrapper";

const RtmpOriginStreamNamesFilter = ({
    data: {
        isArchived = [],
    },
    loading,
    filters,
    restoreFilterValues,
}) => {
    let isArchivedValue = restoreFilterValues(filters.rtmpOriginStreamNames, 'isArchived', 0);

    if ('' !== isArchivedValue) {
        isArchivedValue = isArchivedValue ? 1 : 0;
    }

    return (
        <div className="rtmpOriginStreamNames__filters filter__controls__container">
            <Field
                component={Form.ReduxInput}
                name="search"
                setValue={restoreFilterValues(filters.rtmpOriginStreamNames, 'search')}
                disabled={loading}
                placeholder="Search..."
            />
            <Field
                component={Form.ReduxSelect}
                className="isArchived"
                disabled={loading}
                setValue={isArchivedValue}
                loading={loading}
                name="isArchived"
                options={isArchived}
                placeholder="Archive status"
                selection
            />
        </div>
    );
}

RtmpOriginStreamNamesFilter.propTypes = {
    data: PropTypes.shape({
        isArchived: PropTypes.array,
    }),
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(RtmpOriginStreamNamesFilter, {name: "rtmpOriginStreamNames", initialValues: {isArchived: 0}});
