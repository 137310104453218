import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const columns = {
    "id": {
        label: "ID",
        sorting: true,
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
    "name": {label: "Name", sorting: true},
    "encoding_datacenter": {
        label: "Encoding datacenter",
        sorting: (row) => (!row.encoding_datacenter.name)
            ? ""
            : row.encoding_datacenter.name.capitalizeFirstLetter(),
    },
    "encoding_job_profile": {
        label: "Encoding job profile",
        sorting: (row) => (!row.encoding_job_profile.name)
            ? ""
            : row.encoding_job_profile.name.capitalizeFirstLetter(),
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
};

const ThirdPartyPullOriginsTable = (props) => {
    const {editClick, loading, thirdPartyClientPullOrigins} = props;

    const linkToModal = (event) => {
        editClick(event.target.dataset.id);
    };

    const renderNameOfFirstLevelDependency = (row, column) => {
        return (
            <div>
                {(row[column] === null || row[column] === undefined)
                    ? ""
                    : row[column].name
                }
            </div>
        );
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "name":
                return (
                    <Authorization
                        notAuthorized={<span>{row[column]}</span>}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN}
                    >
                        <p className="link-to-modal" data-id={row.id} onClick={linkToModal}>{row[column]}</p>
                    </Authorization>
                );
            case "encoding_datacenter":
            case "encoding_job_profile":
                return renderNameOfFirstLevelDependency(row, column);
            case "actions": {
                return (
                    <Authorization
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN}
                    >
                        <div className="actions-list-icon">
                            <IconEdit onClick={editClick} onClickElement={row} size="large"/>
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="third_party_pull_origins__table">
            <Table
                name="thirdPartyClientPullOriginsList"
                noDataText="No pull origins found"
                columns={columns}
                data={thirdPartyClientPullOrigins}
                loading={loading}
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

ThirdPartyPullOriginsTable.defaultProps = {
    thirdPartyClientPullOrigins: [],
};

ThirdPartyPullOriginsTable.propTypes = {
    editClick: PropTypes.func,
    loading: PropTypes.bool,
    thirdPartyClientPullOrigins: PropTypes.array,
};

export default ThirdPartyPullOriginsTable;
