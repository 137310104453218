import Form from '@appComponents/ReduxFormControls';
import {
    GetClientProductLcoForForm,
    GetClientProductLcoOptions
} from '@graphql/clientProductLco/query';
import {
    CreateClientProductLco,
    DeleteClientProductLco,
    UpdateClientProductLco
} from '@graphql/clientProductLco/mutation';

import ClientProductModel from './ClientProductModel';
import {renderHeader} from './clientProductFormUtils';
import validate from './validatorClientProductLco';

export default function ClientProductLcoModel() {
    let clientProductLcoModel = ClientProductModel();

    clientProductLcoModel.deleteId = "clientProductsLco.0.id";
    clientProductLcoModel.entityDataMapKey = "clientProductsLco.0";
    clientProductLcoModel.entityLabel = "product LCO";
    clientProductLcoModel.validate = validate;
    clientProductLcoModel.query = {
        getEntity: GetClientProductLcoForForm,
        getOptions: GetClientProductLcoOptions,
    };
    clientProductLcoModel.mutation = {
        createEntity: CreateClientProductLco,
        deleteEntity: DeleteClientProductLco,
        updateEntity: UpdateClientProductLco,
    };

    clientProductLcoModel.dataMap = {
        clientProductsLco: {
            id: "",
            cdn_price: "",
            has_additional_cdn_bucket: false,
            additional_cdn_bucket_size_gb: "",
            ...clientProductLcoModel.dataMap
        },
    };

    clientProductLcoModel.remapBaseDatamapKeys(clientProductLcoModel);

    clientProductLcoModel.fields = Object.assign({}, clientProductLcoModel.fields, {
        header: {
            props: {
                component: renderHeader,
                label: "Header",
                name: "header",
                defaultValue: "LCO specific settings",
            }
        },
        cdn_price: {
            dataMapKey: "clientProductsLco.0.cdn_price",
            props: {
                component: Form.SemanticInput,
                label: "CDN price",
                labelDescription: {content: "€"},
                name: "cdn_price",
                required: true,
            },
        },
        has_additional_cdn_bucket: {
            dataMapKey: "clientProductsLco.0.has_additional_cdn_bucket",
            props: {
                component: Form.SemanticInput,
                label: "Add additional CDN bucket",
                name: "has_additional_cdn_bucket",
                type: "checkbox",
            },
        },
        additional_cdn_bucket_size_gb: {
            dataMapKey: "clientProductsLco.0.additional_cdn_bucket_size_gb",
            props: {
                component: Form.SemanticInput,
                hidden: true,
                label: "Additional CDN bucket size",
                labelDescription: {content: "Gb"},
                min: 1,
                name: "additional_cdn_bucket_size_gb",
                required: true,
                type: "number",
            },
        },
    });

    return clientProductLcoModel;
}
