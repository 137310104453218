import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../app/components/Table';

export const columns = {
    "property_name": {
        label: "Property",
        sorting: true,
    },
    "original_value": {
        label: "Original value",
        sorting: true,
    },
    "new_value": {
        label: "New value",
        sorting: true,
    }
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case "property_name":
        case "original_value":
        case "new_value":
            return row[column];
        default:
            return null
    }
};

const ChangeLogDetailsTable = (props) => {
    const {changelogChanges} = props;

    return (
        <div className="changelogChangesTable">
            <Table
                columns={columns}
                data={changelogChanges}
                name="detailsList"
                noDataText="No details found"
                pagination={false}
            />
        </div>
    );
};

ChangeLogDetailsTable.propTypes = {
    changelogChanges: PropTypes.array,
    loading: PropTypes.bool,
};

export default ChangeLogDetailsTable;
