import React from 'react';
import PropTypes from "prop-types";
import {merge as _merge} from "lodash";

import {MatchesSimpleTable} from "../components/EventsMultiple/MatchesSimpleTable";

import EventsMultipleCreationModel from "../forms/EventsMultipleCreationModel";
import EventsMultipleCreationForm from "../forms/EventsMultipleCreationForm";

import {createForm} from "../../../utils/forms";
import {mapPropertyLicencesForDropdown} from "../../../utils/propertyLicence";

export const EventsMultipleCreation = ({
    data: {
        eventStatuses,
        matches,
        propertyLicencesForMultipleMatchesDropdown,
    },
    matchesIds,
    modelObjectExtension,
    queryForRefetch
}) => {
    const propertyLicenceForDropdown = mapPropertyLicencesForDropdown(propertyLicencesForMultipleMatchesDropdown);
    const Form = createForm(
        () => (_merge(EventsMultipleCreationModel(), modelObjectExtension)),
        EventsMultipleCreationForm,
        {
            propertyLicencesOptions: propertyLicenceForDropdown,
            eventStatuses: eventStatuses,
            matches: matchesIds,
            queryForRefetch: queryForRefetch
        }
    );

    return <React.Fragment>
        <div className="ui form">
            <MatchesSimpleTable
                matches={matches}
            />
        </div>
        <div>
            <Form />
        </div>
    </React.Fragment>
};

EventsMultipleCreation.propTypes = {
    data: PropTypes.object,
    matchesIds: PropTypes.array,
    modelObjectExtension: PropTypes.object,
    queryForRefetch: PropTypes.string
};

EventsMultipleCreation.defaultProps = {
    data: {},
    matchesIds: [],
    modelObjectExtension: {},
    queryForRefetch: ""
}
