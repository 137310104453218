import {withRouter} from 'react-router-dom';
import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt, renderPageTitle} from '@utils/helpers';
import {adSpliceOptionParser} from '@utils/dropdownOptionParser';

export class AdSpliceForm extends DefaultForm {

    componentDidMount() {
        const link = this.createLinkToIndexPage();

        this.setCreateSuccessCallback(() => {
            this.props.history.push(link);
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(link);
        });

        this.setOptionParsers({
            ad_splice: adSpliceOptionParser,
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        let adSpliceName = null;

        if (null !== this.props.formParams.id) {
            adSpliceName = _get(this.props.GraphQLEntityData, "adSpliceConfig.name", "");
        }

        this.props.formParams.setPageTitle(
            renderPageTitle({
                id: this.props.formParams.id,
                name: adSpliceName,
                entity: this.props.Model.entityLabel,
            })
        );
    }

    createLinkToIndexPage = () => (
        getLink('adSplice.index')
    );

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.dataMap[this.props.Model.entityDataMapKey],
                {
                    id: data.id,
                    name: data.name || null,
                    ad_splice: data.ad_splice,
                    duration: data.duration ? convertToInt(data.duration) : null,
                    // 0 is a valid value for ad_start_offset and ad_duration_offset, used == instead of === because It can be number and also string
                    ad_start_offset: (data.ad_start_offset || 0 == data.ad_start_offset) ? convertToInt(data.ad_start_offset) : null,
                    ad_duration_offset: (data.ad_duration_offset || 0 == data.ad_duration_offset) ? convertToInt(data.ad_duration_offset) : null,
                }
            );
    };

    renderErrors() {
        super.renderErrors(
            'The requested Ad splice config could not be found',
            this.props.Model.entityLabel,
            this.createLinkToIndexPage()
        );
    }

    renderCancelButton = () => null;

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});
}

export default withRouter(AdSpliceForm);

