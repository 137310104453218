import {Popup} from 'semantic-ui-react';
import React from "react"

import Form from "../../../app/components/ReduxFormControls";
import {IconQuestionCircle} from "../../../app/components/IconCollection";

import validate from "./../validatorMasterBookings";
import {renderBookingUserInformation} from "../helpers/booking";

import {MASTER_BOOKING} from "../../../app/constants/resources";
import * as MESSAGES from "../../../app/constants/messages";

import {GetEventMasterBookingOptionsForForm} from "../../../../graphql/bookings/query.graphql";

export default function EventMasterBookingGeoRestrictionsGeneralInformationModel() {
    const informationTooltip = <Popup
        trigger={<IconQuestionCircle className="help" />}
        content={`Select this box if you want the geo restrictions to be automatically updated when changes in 
            media-rights, client packages or event settings affect the geo restrictions of this booking.`}
        hoverable
        position='right center'
    />;

    return {
        entityDataMapKey: 'masterBooking',
        entityLabel: 'master booking',
        formName: 'MasterBookingGeneralInformation',
        label: 'master booking',
        editForm: false,
        resources: MASTER_BOOKING,
        showChangeLog: true,
        title: null,
        validate: validate,
        messages: {
            boxName: {
                delete: 'event',
                success: 'event',
                error: 'event'
            },
            text: MESSAGES
        },
        dataMap: {
            masterBooking: {
                client_package: null,
                auto_update_geo_restrictions: true,
            }
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "masterBooking.id",
                props: {
                    name: 'id'
                }
            },
            client: {
                dataMapKey: "masterBooking.client",
                optionsKey: "clients",
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    label: "Client",
                    name: "client",
                    size: 'small'
                }
            },
            product: {
                dataMapKey: "masterBooking.product",
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    label: "Product",
                    disabled: true,
                    name: "product",
                    size: 'small',
                }
            },
            client_package: {
                dataMapKey: "masterBooking.client_package.id",
                props: {
                    component: Form.SemanticSelect,
                    label: "Client package",
                    name: "client_package",
                    disabled: true,
                    allowClear: true,
                    size: 'small',
                }
            },
            auto_update_geo_restrictions: {
                dataMapKey: "masterBooking.auto_update_geo_restrictions",
                props: {
                    component: Form.SemanticInput,
                    label: 'Auto-update geo restrictions',
                    name: 'auto_update_geo_restrictions',
                    type: 'checkbox',
                    props: {
                        description: informationTooltip
                    }
                }
            },
            booked_by: {
                dataMapKey: "masterBooking.booked_by",
                props: {
                    component: renderBookingUserInformation,
                    label: 'Booked by',
                    name: 'booked_by',
                    type: 'text',
                }
            },
        },
        mutation: {},
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventMasterBookingsForView'],
            },
            update: {
                refetchQueries: ['GetEventMasterBookingsForView'],
            },
            delete: {
                refetchQueries: ['GetEventMasterBookingsForView'],
            }
        },
        query: {
            getOptions: GetEventMasterBookingOptionsForForm
        },
    }
}
