import PropTypes from 'prop-types';
import {useState} from 'react';
import {withRouter} from 'react-router';
import {withApollo} from '@apollo/client/react/hoc';

import {routes} from '@constants/routes';
import {overlapConfirmationModal} from '@utils/CidrHelpers';
import {convertToInt} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {showModal} from '@utils/modal';
import {CreateBlacklistedIp} from '@graphql/blacklistedIp/mutation';
import * as MESSAGES from '@constants/messages';

import {cidrOverlapDetailsRenderer} from '../utils/ipBlacklistingHelper';

const IpBlacklistingFormConfirmation = ({
    client,
    clientWhitelistedIps,
    formData,
    history,
    setMutationSuccess,
    stepsMethods: {
        showStep,
    },
}) => {
    const [isLoading, setIsLoading] = useState(false),
        data = Object.assign({}, formData, {
            ip_version: convertToInt(formData.ip_version),
        }),
        onClickNo = () => showStep('step1'),
        onClickYes = () => {
            setIsLoading(true);

            client.mutate({
                mutation: CreateBlacklistedIp,
                variables: data,
            }).then(() => {
                setIsLoading(false);
                setMutationSuccess(Date.now());
                showModal({
                    isVisible: false,
                    hideButtons: false,
                });
                showMessageBox(
                    'ipBlacklistingIndex',
                    MESSAGES.SAVED(MESSAGES.CIDR),
                    null,
                    'success'
                );
                history.push(routes.ipBlacklisting.index.path);
            }).catch((error) => {
                setIsLoading(false);
                showStep('step1');
                showMessageBox(
                    'formInnerErrorMessage',
                    MESSAGES.FORM_ERROR(MESSAGES.CIDR),
                    `${error}`,
                    'error'
                );
            });
        };

    return overlapConfirmationModal(
        `This CIDR "${formData.cidr}" overlaps the following whitelisted client CIDRs:`,
        cidrOverlapDetailsRenderer(clientWhitelistedIps),
        'Are you sure you want to add this new blacklisted CIDR?',
        onClickYes,
        onClickNo,
        isLoading
    );
};

IpBlacklistingFormConfirmation.propTypes = {
    client: PropTypes.object.isRequired,
    clientWhitelistedIps: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setMutationSuccess: PropTypes.func.isRequired,
    stepsMethods: PropTypes.shape({
        showStep: PropTypes.func.isRequired,
    }),
};

export default withRouter(withApollo((IpBlacklistingFormConfirmation)));
