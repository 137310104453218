import PropTypes from 'prop-types';
import React from 'react';

import {IconEdit} from "../../app/components/IconCollection";
import Table from "../../app/components/Table";
import Authorization from "../../app/components/Authorization";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";
import {getParsedId} from "../../../utils/helpers";

const columns = {
    "id": {
        label: "ID",
        sorting: row => getParsedId(row),
        header: {
            align: "center",
            isCollapsed: true
        },
        content: {
            align: "center"
        }
    },
    "name": {label: "Name", sorting: true},
    "contact_person_type": {
        label: "Type",
        sorting: true
    },
    "position": {label: "Position", sorting: true},
    "email_address": {label: "Email address", sorting: true},
    "phone_number_office": {label: "Phone number (office)", sorting: true},
    "phone_number_mobile": {label: "Phone number (mobile)", sorting: true},
    "skype_name": {label: "Skype name", sorting: true},
    "actions": {
        label: "Actions",
        header: {
            align: "center"
        },
        content: {
            align: "center"
        }
    }
};

const renderNameOfFirstLevelDependency = (row, column) => {
    return (
        <div>
            {(row[column] === null || row[column] === undefined)
                ? ""
                : row[column].name
            }
        </div>
    );
};

const ContactPersonsTable = (props) => {
    const {contactPersons, editClick, loading} = props;

    const linkToModal = (event) => {
        editClick(event.target.dataset.id);
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "name":
                return (
                    <Authorization
                        resources={RESOURCES.CONTACT_PERSON}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        notAuthorized={<span>{row[column]}</span>}
                    >
                        <span className="link-to-modal" data-id={row.id} onClick={linkToModal}>{row[column]}</span>
                    </Authorization>
                );
            case "contact_person_type" :
                return renderNameOfFirstLevelDependency(row, column);
            case "actions": {
                return (
                    <Authorization
                        resources={RESOURCES.CONTACT_PERSON}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    >
                        <div className="actions-list-icon">
                            <IconEdit onClick={editClick} onClickElement={row.id} size="large"/>
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="contact_persons__table">
            <Table
                columns={columns}
                data={contactPersons}
                loading={loading}
                name="contactPersonsList"
                noDataText="No contact persons found"
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ContactPersonsTable.defaultProps = {
    contactPersons: []
};

ContactPersonsTable.propTypes = {
    contactPersons: PropTypes.array.isRequired,
    editClick: PropTypes.func,
    loading: PropTypes.bool
};

export default ContactPersonsTable;
