import React from 'react';
import PropTypes from 'prop-types';

import Authorization from "../Authorization";

class Button extends React.Component {
    static propTypes = {
        auth: PropTypes.shape({
            privileges: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.number
            ]),
            resources: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.number
            ]),
        }),
        button: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        children: PropTypes.object.isRequired,
    };
    static defaultProps = {
        label: "Add"
    };

    renderAuthorized = () => {

        return (
            <Authorization resources={this.props.auth.resources} privileges={this.props.auth.privileges}>
                {this.props.children}
            </Authorization>
        )
    };

    render() {

        return (
            (
                this.props.auth !== undefined
                && this.props.auth.privileges !== undefined
                && this.props.auth.resources !== undefined
            )
                ? this.renderAuthorized()
                : this.props.children
        )
    }
}

export default Button;
