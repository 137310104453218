
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RightScopeUsagesData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RightsScope"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"includes_all_content"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"start_date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"end_date"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":109}};
    doc.loc.source = {"body":"fragment RightScopeUsagesData on RightsScope {\n    id\n    includes_all_content\n    start_date\n    end_date\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
