import {get as _get} from "lodash";
import {withRouter} from "react-router";

import ProductGeoRestrictionsForm from "./ProductGeoRestrictionsForm";

import {convertToInt} from "../../../utils/helpers";

export class ProductLciGeoRestrictionsForm extends ProductGeoRestrictionsForm {
    getEntityClientProductId = () => {
        return convertToInt(_get(this.props.GraphQLEntityData, 'clientProductsLci[0].id', 0));
    };

    getEntityGeoRestrictions = (entityData) => {
        return _get(entityData, 'clientProductsLci[0].client_product', {});
    };
}

export default withRouter(ProductLciGeoRestrictionsForm);
