import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {isEmpty as _isEmpty} from 'lodash';
import {Button, Header, Icon} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import Authorization from '@appComponents/Authorization';
import Link from '@appComponents/Link';
import * as MESSAGES from '@constants/messages';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {refetchQueryByName} from '@utils/apollo';
import {showMessageBox} from '@utils/messageBox';
import {UserGranted} from '@graphql/user/query';
import {GrantAppAccess, RevokeAppAccess} from '@graphql/user/mutation';
import mapModulesToProps from '@utils/mapModulesToProps';
import {
    MESSAGE_BOX_USERS_AFTER_GRANT_ERROR_NAME, MESSAGE_BOX_USERS_AFTER_GRANT_NAME,
} from '@modules/user/utils/variables';

const authorizationObject = {
    [RESOURCES.USER] : CONST.SECURITY_PRIVILEGES_READ_UPDATE,
};

export class UserEditButtons extends React.Component{
    static propTypes = {
        id: PropTypes.string.isRequired,
        DataUserAppAccess: PropTypes.shape({
            user: PropTypes.object,
            loading: PropTypes.bool.isRequired,
            refetch: PropTypes.func.isRequired,
        }),
        GrantAppAccess: PropTypes.func.isRequired,
        RevokeAppAccess: PropTypes.func.isRequired,
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
            setModalConfirmation: PropTypes.func.isRequired,
        }).isRequired,
    };

    data = {
        email: null,
        showAppGrantToggle: false,
        hasAppAccess: false,
    }

    componentWillReceiveProps(props) {
        if (props !== this.props && false === props.DataUserAppAccess.loading && !_isEmpty(props.DataUserAppAccess.user?.iam_user)) {
            this.data.showAppGrantToggle = !props.DataUserAppAccess.user.iam_user.blocked;
            this.data.email = props.DataUserAppAccess.user.email;
            this.data.hasAppAccess = props.DataUserAppAccess.user.iam_user.accessGranted;
        }
    }

    toggleActionRequest = () => {
        const grant = !this.data.hasAppAccess;

        const {
            DataUserAppAccess,
            GrantAppAccess,
            RevokeAppAccess,
            Modal: {setModal},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: MESSAGES.USER_GRANT_APP_PROGRESS,
        });

        return (grant ? GrantAppAccess : RevokeAppAccess)({
            variables: {
                email: this.data.email,
            },
        }).then(() => {
            showMessageBox(MESSAGE_BOX_USERS_AFTER_GRANT_NAME, MESSAGES.USER_GRANT_APP_SUCCESS, null, 'success');
            setModal({isVisible: false});
            DataUserAppAccess.refetch();
            refetchQueryByName('GetUserWithAccessInformation', true);
        }).catch((error) => {
            setModal({isVisible: false});
            showMessageBox(MESSAGE_BOX_USERS_AFTER_GRANT_ERROR_NAME, MESSAGES.USER_GRANT_APP_ERROR, `${error}`, 'error');
        });
    }


    toggleAccessClick = (e) => {
        e.preventDefault();

        this.props.Modal.setModalConfirmation({
            header: <Header content={MESSAGES.USER_GRANT_APP_HEADER(!this.data.hasAppAccess)} />,
            onYes: this.toggleActionRequest,
            text: MESSAGES.USER_GRANT_APP_CONFIRMATION(!this.data.hasAppAccess),
        });
    };

    render() {
        return (
            <div className='content__buttons'>
                {!this.data.showAppGrantToggle ? null :
                    <Authorization authorization={authorizationObject}>
                        <Button onClick={this.toggleAccessClick}>
                            {this.data.hasAppAccess ? 'Revoke access to AV-CMP' : 'Grant access to AV-CMP'}
                        </Button>
                    </Authorization>}
                <Link name={'users.index'}>
                    <Button className='button-back'>
                        <Icon className='arrow circle left'/>{'Back to list'}
                    </Button>
                </Link>
            </div>
        );
    }
}

const UserEditButtonsWithQuery = compose(
    graphql(UserGranted, {
        skip: (props) => props.match.path.includes('from-existing-iam-account'),
        options: ({id}) => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            variables: { id: id},
        }),
        name: 'DataUserAppAccess',
    }),
    graphql(GrantAppAccess, {name: 'GrantAppAccess'}),
    graphql(RevokeAppAccess, {name: 'RevokeAppAccess'})
)(UserEditButtons);

const mapDispatchToProps = mapModulesToProps(['Modal']);

export default withRouter(connect(null, mapDispatchToProps)(UserEditButtonsWithQuery));
