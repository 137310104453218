import PropTypes from 'prop-types';
import React from 'react';

import {routes} from '@constants/routes';
import {convertToInt} from '@utils/helpers';
import {setActivePath} from '@utils/forms';
import * as RESOURCE from '@constants/resources';
import {EXPORT_EXCEL_URL_FRAGMENT} from '@constants/variables';

import AccreditationEdit from '../views/AccreditationEdit';
import MatchSelectionPeriods from '../views/MatchSelectionPeriods';
import PropertyLicenceContent from '../views/PropertyLicenceContent';
import PropertyLicenceEdit from '../views/PropertyLicenceEdit';
import PropertyLicenceEventPresets from '../views/PropertyLicenceEventPresets';
import PropertyLicenceEvents from '../views/PropertyLicenceEvents';
import PropertyLicenceLinksAndAttachments from '../views/PropertyLicenceLinksAndAttachments';
import PropertyLicencePricing from '../views/PropertyLicencePricing';
import RightsScopes from '../views/RightsScopes';
import TechnicalSetupTabsLayout from '../layouts/TechnicalSetupTabsLayout';


export const createTabs = (
    id,
    path,
    {hasAccreditation = null}
) => (
    (routes.propertyLicences.add.path === path)
        ? {
            [routes.propertyLicences.add.path]: {
                key: routes.propertyLicences.add.path,
                url: 'propertyLicences.add',
                text: 'General information',
                render: <PropertyLicenceEdit/>,
            },
        }
        : {
            [routes.propertyLicences.edit.path]: {
                key: routes.propertyLicences.edit.path,
                url: 'propertyLicences.edit',
                urlParams: {id: id},
                text: 'General information',
                render: <PropertyLicenceEdit/>,
            },
            [routes.propertyLicences.content.index.path]: {
                key: routes.propertyLicences.content.index.path,
                url: 'propertyLicences.content.index',
                urlParams: {id: id},
                text: 'Content',
                render: <PropertyLicenceContent propertyLicenceId={convertToInt(id)}/>,
                resource: RESOURCE.PROPERTY_LICENCE_CONTENT,
            },
            [routes.propertyLicences.accreditation.index.path]: {
                key: routes.propertyLicences.accreditation.index.path,
                url: 'propertyLicences.accreditation.index',
                urlParams: {id: id},
                text: 'Accreditation',
                render: <AccreditationEdit />,
                hidden: !hasAccreditation,
                resource: RESOURCE.CONTACT_PERSON,
            },
            [routes.propertyLicences.rightsScopes.index.path]: {
                key: routes.propertyLicences.rightsScopes.index.path,
                url: 'propertyLicences.rightsScopes.index',
                urlParams: {id: id},
                text: 'Rights scope',
                render: <RightsScopes/>,
                resource: RESOURCE.RIGHT_SCOPE,
            },
            [routes.propertyLicences.matchSelectionPeriods.index.path]: {
                key: routes.propertyLicences.matchSelectionPeriods.index.path,
                url: 'propertyLicences.matchSelectionPeriods.index',
                urlParams: {id: id},
                text: 'Match selection periods',
                render: <MatchSelectionPeriods/>,
                resource: RESOURCE.MATCH_SELECTION_PERIOD,
            },
            [routes.propertyLicences.pricing.index.path]: {
                key: routes.propertyLicences.pricing.index.path,
                url: 'propertyLicences.pricing.index',
                urlParams: {id: id},
                text: 'Pricing',
                render: <PropertyLicencePricing/>,
            },
            [routes.propertyLicences.technicalSetup.index.path]: {
                key: routes.propertyLicences.technicalSetup.index.path,
                url: 'propertyLicences.technicalSetup.index',
                urlParams: {id: id},
                text: 'Technical setup',
                render: <TechnicalSetupTabsLayout/>,
                resource: RESOURCE.PROPERTY_LICENCE_TECH_SETUP,
            },
            [routes.propertyLicences.linksAndAttachments.index.path]: {
                key: routes.propertyLicences.linksAndAttachments.index.path,
                url: 'propertyLicences.linksAndAttachments.index',
                urlParams: {id: id},
                text: 'Links and attachments',
                render: <PropertyLicenceLinksAndAttachments/>,
                resource: RESOURCE.PROPERTY_LICENCE,
            },
            [routes.propertyLicences.events.index.path]: {
                key: routes.propertyLicences.events.index.path,
                url: 'propertyLicences.events.index',
                urlParams: {id: id},
                text: 'Events',
                render: <PropertyLicenceEvents/>,
                resource: RESOURCE.EVENT,
            },
            [routes.propertyLicences.eventPresets.index.path]: {
                key: routes.propertyLicences.eventPresets.index.path,
                url: 'propertyLicences.eventPresets.index',
                urlParams: {id: id},
                text: 'Event presets',
                render: <PropertyLicenceEventPresets propertyLicenceId={convertToInt(id)}/>,
                resource: RESOURCE.EVENT_PRESET,
            },
        }
);
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    accreditationContactPersonId: PropTypes.number,
    hasAccreditation: PropTypes.bool,
};
createTabs.displayName = 'createPropertyLicenceTabs';

export const prepareActivePath = (path) => {
    const propertyLicenceRoute = routes.propertyLicences;

    if ([
        propertyLicenceRoute.technicalSetup.slates.index.path,
        propertyLicenceRoute.technicalSetup.slates.add.path,
        propertyLicenceRoute.technicalSetup.slates.edit.path,
        propertyLicenceRoute.technicalSetup.overlays.index.path,
        propertyLicenceRoute.technicalSetup.overlays.add.path,
        propertyLicenceRoute.technicalSetup.overlays.edit.path,
    ].includes(path)) {
        return propertyLicenceRoute.technicalSetup.index.path;
    } else if (path === `${propertyLicenceRoute.rightsScopes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`) {
        return propertyLicenceRoute.rightsScopes.index.path;
    } else if (path === `${propertyLicenceRoute.matchSelectionPeriods.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`) {
        return propertyLicenceRoute.matchSelectionPeriods.index.path;
    } else if (path === `${propertyLicenceRoute.eventPresets.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`) {
        return propertyLicenceRoute.eventPresets.index.path;
    } else if (path === `${propertyLicenceRoute.events.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`) {
        return propertyLicenceRoute.events.index.path;
    } else if (path === `${propertyLicenceRoute.content.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`) {
        return propertyLicenceRoute.content.index.path;
    }

    return setActivePath(path, [
        propertyLicenceRoute.eventPresets,
        propertyLicenceRoute.matchSelectionPeriods,
        propertyLicenceRoute.rightsScopes,
        propertyLicenceRoute.technicalSetup,
    ]);
};
prepareActivePath.propTypes = {
    path: PropTypes.string.isRequired,
};
prepareActivePath.displayName = 'preparePropertyLicenceActivePath';
