import React from 'react';
import {get as _get} from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';

import Footer from '@appComponents/Footer';
import Modal from '@appComponents/Modal';
import Sidebar from '@appComponents/Sidebar';
import Header from '@appComponents/Header';
import RedirectRouteHandler, {RouterHandlerWithRedux as RouteHandler} from '@appComponents/RouteHandler';
import InfoBoxes from '@appComponents/InfoBoxes';
import redirectData from '@constants/redirectData';

import NotFound from './NotFound';
import Authenticate from './Authenticate';
import ErrorBoundary from './ErrorBoundary';
import {routes} from '../constants/routes';
import {EXPORT_EXCEL_URL_FRAGMENT} from '../constants/variables';
import {setScrollPosition} from '../../../actions';

const renderRoutes = (routes) => {
    return Object.keys(routes).map((key) => {
        const routesProps = routes[key];

        if (routesProps.path === undefined) {
            return renderRoutes(routesProps);
        }

        if (routesProps.isDisabled) {
            return null;
        }

        // don't go thru the construct of the component when it is already rendered
        return [
            <RouteHandler
                {...routesProps}
                exact
                key='router'
            />,
            routesProps.excelExport &&
            <RouteHandler
                {...routesProps}
                path={`${routesProps.path}${EXPORT_EXCEL_URL_FRAGMENT}`}
                exact
                key='router'
            />,
        ];
    });
};

class ApplicationView extends React.Component {
    static propTypes = {
        setScroll: PropTypes.func.isRequired,
        scroll: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.siteHeader = React.createRef();
        this.siteFooter = React.createRef();
        this.mainContainer = React.createRef();
        this.contentContainer = React.createRef();
        this.routesToRender = renderRoutes(routes);
        this.redirectRoutes = this.routesToRedirect();
        this.resizeObserver = null;
        this.setScrollTimeout = null;
    }

    componentDidMount() {
        try {
            const headerAndFooterHeight = this.siteHeader.current.offsetHeight + this.siteFooter.current.offsetHeight;

            this.resizeObserver = new ResizeObserver(event => {
                const scrollOffset = _get(this.props, `scroll.${window.location.pathname}${encodeURI(window.location.search)}`, 0);

                if (0 < scrollOffset
                    && event[0].target.offsetHeight > scrollOffset
                    && event[0].target.offsetHeight + headerAndFooterHeight >= this.mainContainer.current.offsetHeight + scrollOffset
                ) {
                    this.mainContainer.current && this.mainContainer.current.scroll({top: scrollOffset, behavior: 'smooth'});
                    this.clearObserver();
                }

                if (0 === scrollOffset) {
                    this.clearObserver();
                }
            });

            this.resizeObserver.observe(this.contentContainer.current);
        } catch (error) {
            console.info('Resize observer behaviour is not supported by the browser.'); // eslint-disable-line no-console
        }
    }

    clearObserver() {
        this.resizeObserver && this.resizeObserver.unobserve(this.contentContainer.current);
        this.resizeObserver = null;
    }

    componentWillUnmount() {
        this.clearObserver();
    }

    shouldComponentUpdate() {
        return false;
    }

    onScrollHandler = (event) => {
        const scrollOffset = event.currentTarget.scrollTop;

        clearTimeout(this.setScrollTimeout);

        this.setScrollTimeout = setTimeout(() => {
            this.props.setScroll({
                scrollOffset: scrollOffset,
                pathname: window.location.pathname + encodeURI(window.location.search),
            });
        }, 400);
    };

    routesToRedirect = () => {
        return Object.keys(redirectData).map((key) => {
            return <RouteHandler
                key={key}
                path={key}
                public={true}
                component={(props) => {
                    return <RedirectRouteHandler {...props} {...redirectData[key]} />;
                }}
            />;
        });
    }

    render() {
        return (
            <Router>
                <ErrorBoundary>
                    <div className={'avcmp__wrapper'}>
                        <Modal/>
                        <Sidebar className='avcmp__sidebar'/>
                        <main className='avcmp__main'
                            ref={this.mainContainer}
                            onScroll={this.onScrollHandler}
                        >
                            <div ref={this.siteHeader} className='avcmp__header'>
                                <Header/>
                            </div>
                            <div
                                ref={this.contentContainer}
                                className='avcmp__content content'
                            >
                                <InfoBoxes/>
                                <Switch>
                                    {this.redirectRoutes}
                                    {this.routesToRender}
                                    <Route exact path='/' component={Authenticate}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            </div>
                            <div ref={this.siteFooter} className='avcmp__footer'>
                                <Footer/>
                            </div>
                        </main>
                    </div>
                </ErrorBoundary>
            </Router>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setScroll: (params) => dispatch(setScrollPosition(params)),
});

const mapStateToProps = (state) => {
    return {
        scroll: state.app.scroll,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationView);
