import {withApollo} from "react-apollo";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";

class ClientAvApiKeyForm extends DefaultForm {
    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (null !== nextProps.formParams.id && nextProps.GraphQLEntityData.clientApiKey) {
            const clientApiKey = nextProps.GraphQLEntityData.clientApiKey;

            this.setField("id", {defaultValue: nextProps.formParams.id});
            this.setField("description", {defaultValue: clientApiKey.description});
        }
    }

    renderSaveButton = () => {
        if (this.props.formParams.id) {
            return super.renderSaveButton();
        }

        return super.renderSaveButton({content: "Generate"})
    };

    renderDeleteButton = () => {
        return null;
    };

    prepareDataForSubmit = (data) => {
        let dataToSave;

        if (data.id) {
            dataToSave = {
                id: convertToInt(data.id),
                description: data.description || null,
            };
        } else {
            dataToSave = Object.assign({}, data, {
                bookmaker: convertToInt(this.props.formParams.bookmakerId),
                client: convertToInt(this.props.formParams.clientId),
                environment: convertToInt(data.environment),
                description: data.description || null,
            });
        }

        return dataToSave;
    };

    renderErrors(errorData) {
        const link = getLink("clients.apiKey.index", {
            id: this.props.formParams.clientId || null
        });

        super.renderErrors(errorData, 'API key', link);
    }
}

export default withApollo(ClientAvApiKeyForm);
