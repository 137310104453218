import React from "react";

import {IconWithTooltip} from "../../app/components/IconCollection";

export const isDefaultDescription = () =>
    <IconWithTooltip
        name='info circle'
        popupProps={{wide: 'very'}}
        tooltip='Select if you want this encoding job profile to be the default one for the given product and distribution type.'
    />;
