import React from 'react';
import {Header, Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {createForm} from '@utils/forms';
import {convertToInt} from '@utils/helpers';

import PackageContentTierGeoRestrictionsModel from './PackageContentTierGeoRestrictionsModel';
import {PackageContentTierGeoRestrictionsForm} from './PackageContentTierGeoRestrictionsForm';

export class PackageContentTierGeoRestrictionsLayout extends React.PureComponent {
    static propTypes = {
        clientPackageId: PropTypes.number,
        formData: PropTypes.object.isRequired,
        header: PropTypes.string,
        entityName: PropTypes.string.isRequired,
        match: PropTypes.shape({
            path: PropTypes.string.isRequired,
        }),
        clientPackageName: PropTypes.string,
        reloadTable: PropTypes.func.isRequired,
        backToIndexUrl: PropTypes.func,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
    };
    constructor(props) {
        super(props);
    }

    renderForm = () => {
        const Form = createForm(
            PackageContentTierGeoRestrictionsModel,
            PackageContentTierGeoRestrictionsForm, {
                idOfRow: convertToInt(_get(this.props, 'id', null)),
                client_package: this.props.clientPackageId,
                sport: this.props.formData?.sport?.id,
                content_tier: this.props.formData?.content_tier?.id,
                tournament_category: this.props.formData?.tournament_category?.id,
                property_licence: this.props.formData?.property_licence?.id,
                tournament: this.props.formData?.tournament?.id,
                content_category: this.props.formData?.content_category?.id,
                booking_type: this.props.formData?.booking_type?.id,
                event_booking_type: this.props.formData?.event_booking_type?.id,
                num_bookable_events: this.props.formData?.num_bookable_events,
                event_price: this.props.formData?.event_price,
                clientPackageName: this.header,
                reloadTable: this.props.reloadTable,
                entityName: this.props.entityName,
                backToIndexUrl: this.props.backToIndexUrl,
                is_active: this.props.formData?.is_active,
            });

        return <Form/>;
    };

    render() {
        return (
            <div>
                <Header as='h3'>{this.props.header}</Header>
                <Segment basic className='content__segment--margin-negative-0 pad-0'>
                    {this.renderForm()}
                </Segment>
            </div>
        );
    }
}

export default withRouter(PackageContentTierGeoRestrictionsLayout);
