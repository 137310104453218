import React from 'react';
import PropTypes from "prop-types";
import {Segment} from "semantic-ui-react";

import ProductGeolocationsTable from "../../components/ProductGeolocationsTable";

import ButtonHeader from "./ButtonHeader"
import MessageBox from "./MessageBox"

const ProductGeolocationIndex = (props) => {
    const {
        clientId,
        loading,
        productGeolocationCountries,
        productShortName
    } = props;

    return (
        <div>
            <MessageBox/>
            <ButtonHeader
                clientId={clientId}
                productShortName={productShortName}
            />
            <Segment basic className="--table">
                <ProductGeolocationsTable
                    className={"productGeolocations__table"}
                    clientId={clientId}
                    productGeolocations={productGeolocationCountries}
                    loading={loading}
                    productUrlKey={productShortName}
                />
            </Segment>
        </div>
    );
};

ProductGeolocationIndex.propTypes = {
    clientId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    productGeolocationCountries: PropTypes.arrayOf(PropTypes.object),
    productShortName: PropTypes.string.isRequired,
};
ProductGeolocationIndex.defaultProps = {
    productGeolocationCountries: []
};
ProductGeolocationIndex.displayName = 'ProductGeolocationIndex';

export default ProductGeolocationIndex;
