import {withRouter} from "react-router";

import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";

export class SdiOriginEntryPointForm extends DefaultForm {
    setLinkToIndexPage = () => (
        this.props.history.push(getLink('sdiOriginEntryPoints.index'))
    );

    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setUpdateSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
        this.setDeleteSuccessCallback(() => {
            this.setLinkToIndexPage();
        });
    }

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            {
                encoding_datacenter: (data && data.encoding_datacenter)
                    ? convertToInt(data.encoding_datacenter)
                    : null
            }
        )
    );

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            'SDI origin entry point',
            getLink('sdiOriginEntryPoints.index'),
            {size: 'tiny'}
        );
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: "Save"})
    );

    renderCancelButton = () => null;
}

export default withRouter(SdiOriginEntryPointForm);
