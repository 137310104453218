import {Button} from 'semantic-ui-react';
import {map as _map, isEmpty as _isEmpty, orderBy as _orderBy} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';
import {getGeoRestrictionsForDeviceCategories} from '@utils/countryHelpers';
import {BOOKING_TYPE_AVCMP_MANUAL} from '@constants/variables';

export const getEventBookingDataFromAllSteps = (data, eventBookingId) => {
    let eventBookingEventContentTypes = [];

    const stepsData = eventBookingId
        ? Object.assign(
            {},
            data.getForms()['step1'].formValues,
            data.getForms()['step2'].formValues,
            data.getForms()['step3'].formValues,
            data.getForms()['step4'].formValues
        )
        : data;

    stepsData.clips.map((clip) => {
        eventBookingEventContentTypes.push({
            'event_content_type': convertToInt(clip.value),
            'match_event_types': _map(clip.children, (matchEventType) => ( matchEventType.id )),
        });
    });

    return Object.assign({}, {
        id: convertToInt(eventBookingId),
        booking_type: BOOKING_TYPE_AVCMP_MANUAL,
        product: convertToInt(stepsData.product),
        client: convertToInt(stepsData.client),
        client_package: stepsData.client_package ? convertToInt(stepsData.client_package) : null,
        auto_update_geo_restrictions: stepsData.auto_update_geo_restrictions || false,
        is_cancelled: stepsData.is_cancelled || false,
        event_booking_geo_restrictions: getGeoRestrictionsForDeviceCategories(stepsData),
        event_booking_event_content_types: eventBookingEventContentTypes,
        event: stepsData.eventId,
    });
};

export const getAdditionalBackButton = (editForm, showPreviousStep) => {
    return !editForm && <Button
        color={'grey'}
        content={'Back'}
        icon={'angle left'}
        labelPosition={'left'}
        onClick={showPreviousStep}
    />;
};

export const generateTree = (eventContentTypes, selectedValues) => {
    let treeData = [];
    const selectAll = _isEmpty(selectedValues);

    eventContentTypes.forEach((eventContentType) => {
        const eventContentTypeId = eventContentType.event_content_type.id;
        let selectedSecondLevelValues = [];

        if (!selectAll) {
            selectedSecondLevelValues = selectedValues
                .find((item) => {
                    if (item.firstLevelTree === eventContentTypeId) {
                        return item;
                    }
                });
        } else {
            selectedSecondLevelValues = eventContentType.event_content_type;
        }

        treeData.push({
            value: eventContentTypeId,
            selected: selectedSecondLevelValues ,
            label: eventContentType.event_content_type.name,
            name: eventContentType.event_content_type.name,
            children: !selectedSecondLevelValues ?
                generateSecondLevelTree(_orderBy(eventContentType.match_event_types, 'name', ['asc']), [], selectAll) :
                generateSecondLevelTree(_orderBy(eventContentType.match_event_types, 'name', ['asc']), selectedSecondLevelValues.secondLevelTree, selectAll),
        });
    });

    return treeData;
};

generateTree.propTypes = {
    eventContentTypes: PropTypes.object.isRequired,
    eventsSelected: PropTypes.array.isRequired,
    selectedMap: PropTypes.instanceOf(Map).isRequired,
    treeSavedMap: PropTypes.instanceOf(Map).isRequired,
};

export const generateSecondLevelTree = (matchEventTypes, selectedValues, selectAll) => {
    const secondLevel = [];

    matchEventTypes.forEach((matchEventType) => {
        const matchEventTypeId = matchEventType.id;

        secondLevel.push({
            id: matchEventTypeId,
            value: matchEventTypeId,
            label: matchEventType.name,
            name: 'match_event_type',
            selected: selectAll ? selectAll : isMatchItemsSelected2ndLevel(matchEventTypeId, selectedValues),
        });
    });

    return secondLevel;
};

generateSecondLevelTree.propTypes = {
    eventContentType: PropTypes.object.isRequired,
    treeSavedMap: PropTypes.instanceOf(Map).isRequired,
};

const isMatchItemsSelected2ndLevel = (matchEventTypeId, selectedValues) => {
    return !!selectedValues.includes(matchEventTypeId);
};

isMatchItemsSelected2ndLevel.propTypes = {
    matchEventTypeId: PropTypes.number.isRequired,
    selectedValues: PropTypes.instanceOf(Map).isRequired,
};
