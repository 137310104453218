import Form from '@appComponents/ReduxFormControls';
import {EVENT_GEO_RESTRICTION} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {GetBlackoutZonesForDropdown} from '@graphql/blackoutZone/query';
import {CreateEventGeoRestriction} from '@graphql/eventGeoRestriction/mutation';

import validatorEventGeoRestriction from '../validatorEventGeoRestriction';

export default function EventGeoRestrictionsFormBlackoutZonesModel() {
    return {
        entityDataMapKey: 'eventGeoRestriction',
        entityLabel: 'geo restriction',
        formName: 'EventGeoRestrictionBlackoutZones',
        label: 'geo restriction',
        resources: EVENT_GEO_RESTRICTION,
        showChangelog: true,
        validate: validatorEventGeoRestriction,
        title: null,
        dataMap: {
            eventGeoRestriction: {
                blackoutZones: [],
            },
        },
        messages: {
            boxName: {
                delete: 'event',
                error: 'event',
                success: 'event',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'eventGeoRestriction.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            blackoutZones: {
                dataMapKey: 'eventGeoRestriction.blackout_zones',
                optionsKey: 'blackoutZones',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Blackout Zones',
                    name: 'blackoutZones',
                    allowClear: true,
                    multiple: true,
                },
            },
        },
        mutation: {
            createEntity: CreateEventGeoRestriction,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            update: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
            delete: {
                refetchQueries: ['GetEventGeoRestrictionsForTable'],
            },
        },
        query: {
            getOptions: GetBlackoutZonesForDropdown,
        },
    };
}
