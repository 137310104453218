import {find as _find, isArray as _isArray, join as _join} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc';

import Authorization from '@appComponents/Authorization';
import {getLink} from '@appComponents/Link';
import {IconAngleRight, IconEdit, IconGlobe, IconShield} from '@appComponents/IconCollection';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {convertToInt} from '@utils/helpers';
import {getSortingTerritories, getTerritoriesWithTooltip} from '@utils/countryHelpers';
import {sortByTextValue} from '@utils/sorters';
import {generateBlackoutZonesRow} from '@utils/blackoutZones';
import {GetMappedIsoCodes} from '@graphql/country/query';
// eslint-disable-next-line import/no-named-as-default
import routes from '@constants/routes';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup'

const authorizationObject = {
    [RESOURCES.EVENT_GEO_RESTRICTION]: CONST.SECURITY_PRIVILEGES_READ,
};

const EventGeoRestrictionTable = (props) => {
    const {data, eventGeoRestrictions, eventId, loading} = props;

    const columns = {
        'id': {
            label: 'ID',
            sorting: (row) => parseInt(row.id, 10),
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        'clients': {
            label: 'Clients',
            sorting: (row) => prepareClients(row, 'clients'),
        },
        'event_contents': {
            label: 'Contents',
            sorting: (row) => prepareEventContents(row, 'event_contents'),
        },
        'usages': {
            label: 'Usage',
            sorting: (row) => prepareUsages(row.usages).map((product) =>
                product.device_categories.map((deviceCategory) =>
                    product.short_name +
                    ' > ' + deviceCategory.name +
                    ' > ' + _join(deviceCategory.way_of_transmissions, ' / ')
                )
            ),
        },
        'countries': {
            label: 'Territories',
            sorting: (row) => getSortingTerritories(row),
        },
        'blackoutZones': {
            label: 'Blackout Zones',
            sorting: row => {
                return row.blackout_zones.map((blackoutZone) => blackoutZone.name).sort().join(', ');
            },
        },
        'notes': {
            label: 'Notes',
            sorting: (row) => row.notes ? row.notes : '',
        },
        'actions': {
            label: 'Actions',
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
    };

    const prepareClients = (row, column) => {
        const clientsArray = [];

        row[column].map((client) => {
            clientsArray.push(client.name);
        });

        switch (parseInt(row.client_selection_type.id, 10)) {
            case CONST.CLIENT_SELECTION_TYPE_ALL:
                return 'All';
            case CONST.CLIENT_SELECTION_TYPE_BLACKLIST:
                return 'All except ' + _join(clientsArray, ', ');
            case CONST.CLIENT_SELECTION_TYPE_WHITELIST:
            default:
                return _join(clientsArray, ', ');
        }
    };

    const prepareEventContents = (row, column) => {
        let eventContents = 'All';

        if (!row.includes_all_event_contents) {
            eventContents = row[column].map((eventContent) =>
                ({
                    name: eventContent.event_content_type.name + ((eventContent.name) ? ' - ' + eventContent.name : ''),
                    variant: eventContent.event_content_variant})
            );
            eventContents = sortByTextValue(eventContents, 'name');
        }

        return (!_isArray(eventContents))
            ? eventContents
            : eventContents.map((eventContent, i) =>
                <p key={i} className='--noMargin'>{eventContent.name}
                    <EventContentVariantPopup eventContentVariant={eventContent.variant}/>
                </p>
            );
    };

    const renderUsages = (usages) => {
        return (
            prepareUsages(usages).map((product) =>
                product.device_categories.map((deviceCategory, i) =>
                    <p key={i} className='--noMargin'>
                        {product.name}<IconAngleRight/>
                        <span key={i}>
                            {deviceCategory.name}
                            <IconAngleRight/>
                            {_join(deviceCategory.way_of_transmissions, ' / ')}
                        </span>
                    </p>
                )
            )
        );
    };

    const prepareUsages = (usages) => {
        let usagesArray = [];

        usages.map((usage) => {
            let product = _find(usagesArray, {name: usage.product.short_name}),
                deviceCategory = (product)
                    ? _find(product.device_categories, {name: usage.device_category.name})
                    : undefined;

            (product)
                ? (deviceCategory)
                    ? deviceCategory.way_of_transmissions.push(usage.way_of_transmission.name)
                    : product.device_categories.push({
                        name: usage.device_category.name,
                        way_of_transmissions: [usage.way_of_transmission.name],
                    })
                : usagesArray.push({
                    name: usage.product.short_name,
                    device_categories: [{
                        name: usage.device_category.name,
                        way_of_transmissions: [usage.way_of_transmission.name],
                    }],
                });
        });

        return usagesArray;
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
                return (
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'events.geoRestrictions.editGeneralInformation',
                            params: {
                                id: eventId,
                                restrictionId: convertToInt(row.id),
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'clients':
                return prepareClients(row, column);
            case 'event_contents':
                return prepareEventContents(row, column);
            case 'usages':
                return renderUsages(row[column]);
            case 'countries':
                return getTerritoriesWithTooltip(row, data.mappedIsoCodes);
            case 'blackoutZones':
                return generateBlackoutZonesRow(row.blackout_zones);
            case 'actions': {
                return (
                    <Authorization
                        authorization={authorizationObject}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit
                                link={getLink('events.geoRestrictions.editGeneralInformation', {
                                    id: eventId,
                                    restrictionId: convertToInt(row.id),
                                })}
                                size='large'
                                resource={RESOURCES.EVENT_GEO_RESTRICTION}
                            />
                            <IconGlobe
                                link={getLink('events.geoRestrictions.editGeoRestrictions', {
                                    id: eventId,
                                    restrictionId: convertToInt(row.id),
                                })}
                                size='large'
                                resource={RESOURCES.EVENT_GEO_RESTRICTION}
                            />
                            <IconShield
                                link={getLink('events.geoRestrictions.blackoutZones', {
                                    id: eventId,
                                    restrictionId: convertToInt(row.id),
                                })}
                                size='large'
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className='event_geo_restriction__table'>
            <Table
                url={routes.events.geoRestrictions.index.path}
                columns={columns}
                data={eventGeoRestrictions}
                loading={loading}
                name='eventGeoRestrictionsList'
                noDataText='No geo restrictions found'
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

EventGeoRestrictionTable.propTypes = {
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    eventGeoRestrictions: PropTypes.array,
    eventId: PropTypes.number.isRequired,
    loading: PropTypes.bool,
};
EventGeoRestrictionTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
    eventGeoRestrictions: [],
    loading: false,
};
EventGeoRestrictionTable.displayName = 'EventGeoRestrictionTable';

export default graphql(GetMappedIsoCodes)(EventGeoRestrictionTable);
