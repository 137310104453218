import * as actionTypes from '../actions/actionTypes';
import {get as _get} from "lodash";

const initialState = {
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_MESSAGE_BOX : {
            const newState = Object.assign({}, state, {[action.data.name] : {...action.data}})

            return newState
        }

        case actionTypes.CLEAR_MESSAGE_BOX : {
            delete state[action.data.name];

            const newState = Object.assign({}, state);

            return newState
        }

        case actionTypes.SET_CLOSED_TIMESTAMP: {
            return Object.assign({}, state, {
                closedDatetimes: Object.assign({}, state.closedDatetimes, {[action.data.name]: action.data.timestamp}),
            });
        }

        case "persist/REHYDRATE": {
            const savedData = _get(action, "payload.messages.closedDatetimes", false) || initialState;

            return {
                ...state,
                ...savedData
            };
        }

        default:
            return state
    }
};

export default messageReducer;
