import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';

import {columns, rowRenderer} from '../utils/userHelper';

export const UserTable = ({data = [], loading = true}) => {
    return (
        <div className='users__table'>
            <Table
                name='usersList'
                columns={columns}
                data={data}
                loading={loading}
                noDataText='No users found'
                rowRenderer={rowRenderer}
                defaultSort={'name'}
            />
        </div>
    );
};

UserTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

export default UserTable;
