import {
    filter as _filter,
    get as _get,
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Header, Icon, Popup} from 'semantic-ui-react';
import moment from 'moment';

import {getLink} from '@appComponents/Link';
import {DELETE_CONFIRMATION, NOT_DELETED} from '@constants/messages';
import {IconWithTooltip} from '@appComponents/IconCollection';
import {
    DEBUG_PLATFORM_LINK,
    SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
    SECURITY_PRIVILEGES_DELETE,
    SECURITY_PRIVILEGES_READ,
    SECURITY_PRIVILEGES_CREATE,
} from '@constants/variables';
import TriggerAutobookerButton from '@modules/autobooker/components/TriggerAutobookerButton';
import EventContentStreams from '@modules/liveControl/components/StreamsPlayback';
import EventContentAlertSteps from '@modules/eventContentAlert/views/EventContentAlertSteps';
import {convertToInt, getEncodingLogsInVideoManagementPlatformLink} from '@utils/helpers';
import PopupTable from '@appComponents/PopupTable';
import {showMessageBox} from '@utils/messageBox';
import {
    BOOKING,
    EVENT_BOOKING,
    EVENT_CONTENT,
    EVENT_CONTENT_ALERT,
    OFFERING,
    STREAM,
} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup';

import {CANT_DELETE_CONTENT} from '../constants/messages';
import {isBeingEncodedQueryHandler, renderModalErrorObject} from '../utils/eventContentHelper';
import {hideInLiveControlRenderer} from '../utils/eventsContentTitle';
import {hasProperOriginStreamType} from "@modules/liveControl/utils/helpers";

const authorizationCreateAlertObject = {
    [EVENT_CONTENT_ALERT]: SECURITY_PRIVILEGES_CREATE,
};

const authorizationDeleteEventContentObject = {
    [EVENT_CONTENT]: SECURITY_PRIVILEGES_DELETE,
};

const authorizationTriggerAutobookerObject = {
    [BOOKING]: SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
    [OFFERING]: SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
    [EVENT_BOOKING]: SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE,
};

const authorizationStreamReadObject = {
    [STREAM]: SECURITY_PRIVILEGES_READ,
};

export default class EventsContentTitle extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        deleteEventContent: PropTypes.func.isRequired,
        eventData: PropTypes.object.isRequired,
        eventId: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        hasStream: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        Modal: PropTypes.object,
    };

    disableAccordionToggle(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    removeHandler = (event) => {
        this.disableAccordionToggle(event);

        const variables = {
            id: this.props.data.id,
            is_event: false,
        };

        isBeingEncodedQueryHandler(variables)
            .then((result) => {
                if (result.data.isBeingEncoded) {
                    this.props.Modal.setModalError(renderModalErrorObject(CANT_DELETE_CONTENT));
                } else {
                    this.props.Modal.setModalConfirmation({
                        header : <Header icon='trash' content='Delete event content' />,
                        text : DELETE_CONFIRMATION(this.getTitle()),
                        onYes : () => {
                            this.props.deleteEventContent(convertToInt(this.props.data.id));
                            this.props.Modal.setModal({isVisible: false});
                        },
                    });
                }
            })
            .catch((error) => {
                showMessageBox(
                    'eventLayout',
                    NOT_DELETED('Content'),
                    `${error}`,
                    'error'
                );
            });
    };

    closeModal = () => {
        this.props.Modal.setModal({
            isVisible: false,
        });
    };

    editHandler = (event) => {
        this.disableAccordionToggle(event);

        const {
            data: {
                id,
            },
            eventId,
        } = this.props;

        this.props.history.replace(
            getLink(
                'events.configuration.content.edit',
                {
                    id: eventId,
                    contentId: id,
                }
            )
        );
    };

    openVMPEncodingLog = (event) => {
        this.disableAccordionToggle(event);

        const url = getEncodingLogsInVideoManagementPlatformLink(
            this.props.data.id,
            this.props.data.event_content_input.encoding_datacenter.name
        );

        window.open(url, '_blank');
    };

    openDebugPlatformInterface = (event) => {
        this.disableAccordionToggle(event);

        const url = `${DEBUG_PLATFORM_LINK}/#/events/event/${this.props.data.id}`;
        window.open(url, '_blank');
    };

    openModalToSendAlertToClients = (event) => {
        this.disableAccordionToggle(event);

        this.props.Modal.setModal({
            isVisible: true,
            size: 'large',
            content:
                <EventContentAlertSteps
                    eventContentStartDatetime={this.props.data.start_datetime}
                    eventContentId={convertToInt(this.props.data.id)}
                    messageBoxName='targetsInfo'
                />,
        });
    };

    openModalToOutputStream = (event) => {
        this.disableAccordionToggle(event);

        this.props.Modal.setModal({
            className: 'streamPlayback',
            isVisible: true,
            size: 'large',
            content:
                <EventContentStreams
                    eventId={convertToInt(this.props.eventId)}
                    eventContentId={convertToInt(this.props.data.id)}
                    closeModal={this.closeModal}
                    modal={this.props.Modal}
                />,
        });
    };

    getTitle = () => {
        const {
            data: {
                id,
                name,
                event_content_type: {
                    name: contentTypeName,
                },
            },
        } = this.props;

        return _filter([id, contentTypeName, name]).join(' - ');
    };

    titleRenderer = () => {
        return (
            <span>{this.getTitle()}</span>
        );
    };

    clockOrDurationRenderer = () => {
        const {
            data: {
                start_datetime,
                end_datetime,
                video_duration,
                video_length,
            },
        } = this.props;
        const startDateTime = start_datetime ? moment(start_datetime).format('YYYY-MM-DD HH:mm') : null;
        const endDateTime = end_datetime ? moment(end_datetime).format('YYYY-MM-DD HH:mm') : null;
        const startEndDateInfo = (
            <span className='eventContent__headerInfo'>
                <Icon className='clock'/>
                {_filter([startDateTime, endDateTime]).join(' - ')}
            </span>
        );

        const videoLengthInfo = () => {
            if (!video_length && !video_duration) {
                return '';
            }

            let durationText;

            if (video_length) {
                durationText = `${video_length}min`;
            } else {
                const videoDuration = video_duration && moment.duration(video_duration),
                    hoursText = (0 < videoDuration.hours()) ? `${videoDuration.hours()}h ` : '',
                    minutesText = (0 < videoDuration.minutes() || hoursText) ? `${videoDuration.minutes()}min ` : '';

                durationText = `${hoursText}${minutesText}${videoDuration.seconds()}s`;
            }

            return (
                <span className='eventContent__headerInfo --align-flex-end'>
                    <Icon className='history --rotated-180deg'/>
                    <span>{durationText}</span>
                </span>
            );
        };

        return (
            <span className='eventContent__headerInfo --align-flex-end'>
                {start_datetime && startEndDateInfo}
                {videoLengthInfo()}
            </span>
        );
    };

    is247StreamRenderer = () => {
        const {
            data: {
                is_247_stream,
            },
        } = this.props;

        return (
            is_247_stream
                ? (
                    <span className='eventContent__headerInfo'>
                        <Icon className='sync alternate' />
                        24/7
                    </span>
                )
                : null
        );
    };

    mainContentRenderer = () => {
        const {
            data: {
                is_main_content,
            },
        } = this.props;

        return (
            is_main_content
                ? (
                    <span className='eventContent__headerInfo'>
                        <Icon className='check'/>
                        Main content of the event
                    </span>
                )
                : null
        );
    };

    isWscRenderer = () => {
        const {
            wsc_video_id,
            wsc_video_md5,
        } = this.props.data;

        return (
            (wsc_video_id || wsc_video_md5) && (
                <Popup
                    className='--noPadding'
                    flowing
                    trigger={
                        <span className='eventContent__headerInfo'>
                            WSC
                        </span>
                    }
                    hoverable
                >
                    <PopupTable rows={[
                        {
                            header: 'WSC video ID:',
                            body: wsc_video_id || '-',
                        },
                        {
                            header: 'WSC video md5:',
                            body: wsc_video_md5 || '-',
                        },
                    ]} />
                </Popup>
            )
        );
    };

    renderIconOutputStreamModal = (popupProps) => {
        if (this.props.loading) {
            return (<i aria-hidden='true' className='spinner loading icon'/>);
        }

        return (
            (this.props.hasStream || hasProperOriginStreamType(this.props.data.event_content_input?.origin_stream_type.id)) &&
            <IconWithTooltip
                name='play circle outline'
                onClick={this.openModalToOutputStream}
                tooltip='Stream playback'
                popupProps={popupProps}
            />
        );
    };

    render() {
        const {data} = this.props;
        const additionalIconsVisibility = data.has_input &&
            _get(data, 'event_content_input.encoding_first_start_datetime', null);
        const popupProps = {position: 'top right'};
        const isContentInPast = 1 < moment().diff(moment(data.start_datetime), 'h');

        return (
            <div className='eventContent__header --align-flex-end'>
                {this.titleRenderer()}
                <EventContentVariantPopup eventContentVariant={this.props.data.event_content_variant}/>
                {this.clockOrDurationRenderer()}
                {this.is247StreamRenderer()}
                {this.mainContentRenderer()}
                {hideInLiveControlRenderer(this.props.data.hide_in_live_control)}
                {this.isWscRenderer()}
                <div className='eventContent__headerButtons'>
                    <IconWithTooltip
                        name='setting'
                        onClick={this.editHandler}
                        tooltip='Configure the content'
                        popupProps={popupProps}
                        resource={EVENT_CONTENT}
                    />
                    {
                        additionalIconsVisibility &&
                        <>
                            <IconWithTooltip
                                name='bug'
                                onClick={this.openDebugPlatformInterface}
                                tooltip='Debug platform'
                                popupProps={popupProps}
                            />
                        </>
                    }
                    <Authorization authorization={authorizationCreateAlertObject}>
                        <IconWithTooltip
                            name='warning circle'
                            onClick={this.openModalToSendAlertToClients}
                            tooltip='Send alert to clients'
                            popupProps={popupProps}
                        />
                    </Authorization>
                    <Authorization authorization={authorizationStreamReadObject}>
                        {this.renderIconOutputStreamModal(popupProps)}
                    </Authorization>
                    <Authorization authorization={authorizationTriggerAutobookerObject}>
                        <span onClick={this.disableAccordionToggle}>
                            <TriggerAutobookerButton
                                id={data.id}
                                entity='content'
                                messageBoxName='event'
                                popupProps={popupProps}
                                uiType='icon'
                                disabled={isContentInPast}
                            />
                        </span>
                    </Authorization>
                    <Authorization authorization={authorizationDeleteEventContentObject}>
                        <IconWithTooltip
                            name='trash'
                            onClick={this.removeHandler}
                            tooltip='Delete the content'
                            popupProps={popupProps}
                        />
                    </Authorization>
                </div>
            </div>
        );
    }
}
