import PropTypes from "prop-types";
import {get as _get} from "lodash";

import {convertToInt} from "./helpers";
import {getSport} from "./sport";

import {SPORTS_COURT_LIST} from "../modules/app/constants/variables";

/**
 * Display label for Venue/Court
 */
export const venueCourtLabel = (event) => {
    const eventSportId = convertToInt(getSport(event).id);

    return (event.sr_event && SPORTS_COURT_LIST.includes(eventSportId))
        ? 'Court'
        : 'Venue';
};

venueCourtLabel.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.object
    }).isRequired
};

venueCourtLabel.defaultProps = {
    event: {
        sr_event: null
    }
};

/**
 * Value for Venue/Court
 * @param event
 * @returns {string}
 */
export const venueCourtValue = (event) => {
    let venueCourtName,
        city;

    /**
     * Sportradar
     */
    if (event.sr_event) {
        /**
         * For Sportradar, master event
         */
        if (event.is_master) {
            venueCourtName = _get(event.sr_event, 'master_match.stadium.name', '');
            city = _get(event.sr_event, 'master_match.stadium.city.name', null);
            /**
             * For Sportradar, no master event
             */
        } else {
            if (event._hasMatchID) {
                venueCourtName = _get(event.sr_event, 'match.stadium.name', '');
                city = _get(event.sr_event, 'match.stadium.city.name', null);
            } else {
                venueCourtName = _get(event.sr_event, 'stadium.name', '');
                city = _get(event.sr_event, 'stadium.city.name', null);
            }
        }
    }
    /**
     * Non-sportradar event
     */
    else if (event.av_event) {
        venueCourtName = _get(event, 'av_event.venue.name', '');
        city = _get(event, 'av_event.venue.city.name', null);
    }

    /**
     * If there is city, display in format "stadium/venue - city"
     * for both types (master / no-master)
     */
    if (city) {
        return `${venueCourtName} - ${city}`;
    } else {
        return `${venueCourtName || '-'}`;
    }
};

venueCourtValue.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                stadium: PropTypes.shape({
                    city: PropTypes.shape({
                        name: PropTypes.string
                    }),
                    name: PropTypes.string
                })
            }),
            master_match: PropTypes.shape({
                stadium: PropTypes.shape({
                    city: PropTypes.shape({
                        name: PropTypes.string
                    }),
                    name: PropTypes.string
                })
            })
        }),
        av_event: PropTypes.shape({
            venue: PropTypes.shape({
                city: PropTypes.shape({
                    name: PropTypes.string
                }),
                name: PropTypes.string
            })
        })
    }).isRequired
};

venueCourtValue.defaultProps = {
    event: {
        sr_event: {
            match: {
                stadium: {
                    city: {
                        name: null
                    },
                    name: null
                }
            },
            master_match: {
                stadium: {
                    city: {
                        name: null
                    },
                    name: null
                }
            }
        },
        av_event: {
            venue: {
                city: {
                    name: null
                },
                name: null
            }
        }
    }
}
