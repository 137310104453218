import React from 'react';
import {
    get as _get,
    isEmpty as _isEmpty,
    union as _union,
    uniqBy as _uniqBy,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {getGeoRestrictionsForDeviceCategoriesOnMasterBookings} from '@utils/countryHelpers';
import {createForm, getFormMessageForWizard} from '@utils/forms';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {GetBookingProductsByClientAndEvent} from '@graphql/product/query';
import {GetPackagesByClientAndProduct} from '@graphql/clientPackages/query';

import {EventMasterBookingGeoRestrictionsForm}
    from '../EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsForm';
import EventMasterBookingGeoRestrictionsModel
    from '../EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsModel';

export class EventMasterBookingGeoRestrictionsGeneralInformationForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        return {
            id: data.id ? convertToInt(data.id) : null,
            client: convertToInt(data.client),
            product: convertToInt(data.product),
            client_package: data.client_package ? convertToInt(data.client_package) : null,
            auto_update_geo_restrictions: data.auto_update_geo_restrictions || false,
            master_booking_geo_restrictions: data.master_booking_geo_restrictions || [],
        };
    };

    onFormSubmit = (data) => {
        if (!this.props.Model.editForm) {
            this.setState(() => ({
                defaultForm_errorShowed: false,
            }));

            const Form = createForm(
                EventMasterBookingGeoRestrictionsModel,
                EventMasterBookingGeoRestrictionsForm,
                {
                    id: data.id,
                    optionsVariables: {
                        event: this.props.formParams.optionsVariables.event,
                        product: convertToInt(this.props.formValues.product),
                    },
                });

            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
            this.props.stepsMethods.setStep({
                id: 'step2',
                menuItem: {disabled: false},
                pane: {content: <Form previousStepData={data}/>},
            });
            this.props.stepsMethods.showStep('step2');
        } else {
            if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
                this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                    _get(this.props, 'Model.label', _get(this.props, 'Model.formName', ''))
                        .toLocaleLowerCase()
                ));
            } else {
                return super.onFormSubmit(Object.assign({}, data, {
                    master_booking_geo_restrictions: getGeoRestrictionsForDeviceCategoriesOnMasterBookings(
                        this.props.stepsMethods.getForms()['step2'].formValues
                    ),
                }));
            }
        }
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (null !== nextProps.formParams.id && nextProps.GraphQLEntityData) {
            const masterBooking = _get(nextProps, 'GraphQLEntityData.masterBooking', null);

            if (masterBooking && !this.state.hasClientPackage) {
                this.setState(() => ({
                    hasClientPackage: true,
                }));

                this.setClientPackages(masterBooking.client.id, masterBooking.product.id);
            }
        } else {
            this.setField('auto_update_geo_restrictions', {
                defaultValue: true,
            });
        }

        this.props.stepsMethods.setForm('step1', nextProps);
    }

    componentDidMount() {
        this.setOnChangeCallback({
            client: (data) => {
                this.setField('product', {
                    loading: true,
                    disabled: true,
                    defaultValue: null,
                    options: [],
                });

                this.setField('client_package', {
                    defaultValue: null,
                    disabled: true,
                    options: [],
                });

                this.props.client.query({
                    fetchPolicy: 'no-cache',
                    query: GetBookingProductsByClientAndEvent,
                    variables: {
                        clientIds: [convertToInt(data.value)],
                        event: this.props.formParams.optionsVariables.event,
                    },
                }).then((response) => {
                    this.setField('product', {
                        loading: false,
                        disabled: false,
                        options: this.parseProducts(
                            _get(response, 'data.event.sr_event.master_event_tournament_products', []),
                            _get(response, 'data.masterBookings', [])
                        ),
                    });
                });
            },
            product: (data) => {
                if (!data.value) {
                    return;
                }

                this.setClientPackages(
                    this.props.formValues.client,
                    data.value
                );
            },
        });
    }

    parseProducts = (products, bookedProducts) => {
        let bookedProductsArray = [];
        const bookableProducts = [];

        bookedProducts.forEach((item) => {
            bookedProductsArray = _union(bookedProductsArray, [item.product.id]);
        });

        _uniqBy(products, 'product.id').forEach((item) => {
            if (-1 === bookedProductsArray.indexOf(item.product.id)) {
                bookableProducts.push({
                    id: item.product.id,
                    value: convertToInt(item.product.id),
                    text: item.product.name,
                });
            }
        });

        return bookableProducts;
    };

    setClientPackages = (clientId, productId) => {
        this.setField('client_package', {
            loading: true,
            disabled: true,
        });

        this.props.client.query({
            fetchPolicy: 'network-only',
            query: GetPackagesByClientAndProduct,
            variables: {
                clientId: [convertToInt(clientId)],
                productId: [convertToInt(productId)],
            },
        }).then((response) => {
            this.setField('client_package', {
                loading: false,
                disabled: false,
                options: response.data.clientPackages,
            });
        });
    };

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    renderErrors(errorData, childLabel = null, childLink = null, childModalProps = {}) {
        const label = childLabel || 'Master booking',
            link = childLink || getLink('events.masterBookings.index', {
                id: this.props.formParams.optionsVariables.event || null,
            }),
            modalProps = childModalProps || {size: 'tiny'};

        super.renderErrors(errorData, label, link, modalProps);
    }

    renderSaveButton = (props) => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                ...props,
                content: 'Next',
                icon: 'angle double right',
                labelPosition: 'right',
            });
        }

        return super.renderSaveButton();
    };
}

export default EventMasterBookingGeoRestrictionsGeneralInformationForm;
