import {changelogData} from '../changelogData';
import {clientTypeList} from "../clientType"
import {countryList} from "../country"

export const clients = `
  clients (
    filters: {
      search: $search
      country: $countries
      clientTypes: $clientTypes
      products: $products
      isDisabled: $isDisabled
      isInternal: $isInternal
  }) {
    id
    crm_id
    name
    brands
    is_disabled
    is_internal
    bookmaker {
      id
      name
    }
    country {
      id
      name
    }
    client_types {
      id
      name
    }
    client_whitelisted_ips {
      id
      is_disabled
    }
    client_products {
      product {
        short_name
      }
      is_trial
      is_disabled
      client_packages_count
    }
    creation_datetime
  }
`;

export const clientSearchQuery = `
  query clients (
    $search : String
    $countries: [ID]
    $clientTypes: [Int]
    $products: [Int]
    $isDisabled: NullableBoolean
    $isInternal: NullableBoolean
  ) {
    ${clients}
  }
`;

export const clientFilterQuery = `
  query clientFilters {
    countries {
      id
      value: id 
      text: name
    }
    clientTypes {
      id
      value: id 
      text: name
    }
    products {
      id
      value: id 
      text: name
    }
  }
`;

export const clientForStore = `
  query ClientQuery ($id: ID!) {
    client (id: $id) {
      id
      name
      api_key
      client_products {
        id 
        is_trial
        is_disabled
        product {
          id
          short_name
          has_geolocation_support
          has_event_booking_support
          has_hq_support
          product_event_content_variants {
            event_content_variant {
                id
            }
          }
        }
        third_party_client_product {
          id
          third_party_client_types {
            id
            name
          }
        }
      }
      bookmaker {
        id
      }
      client_drm_setup {
        id
      }
    }
  }
`;

export const client = `
  query clientQuery ($id: ID!) {
    client (id: $id) {
      id
      crm_id
      name
      brands
      address
      vat_number
      notes
      is_disabled
      is_internal
      notification_email_recipients
      is_lbc_stream_playback_enabled
      bookmaker {
        id
        name
      }
      country {
        id
        name
      }
      client_types {
        id
        name
      }
      ${changelogData}
    }
    ${countryList}
    ${clientTypeList}
  }
`;

const clientMutationVariableDeclaration = `
  $bookmaker: Int
  $crm_id: Int
  $name: String!
  $brands: String
  $address: String
  $country: Int!
  $client_types: [Int]
  $vat_number: String
  $notes: String
  $notification_email_recipients: CommaSeparatedStringToArray
  $is_lbc_stream_playback_enabled: Boolean
  $is_disabled: Boolean
  $is_internal: Boolean
`;

const clientMutationVariableDefinition = `
  bookmaker: $bookmaker
  crm_id: $crm_id
  name: $name
  brands: $brands
  address: $address
  country: $country
  clientTypes: $client_types
  vat_number: $vat_number
  notes: $notes
  notification_email_recipients: $notification_email_recipients
  is_lbc_stream_playback_enabled: $is_lbc_stream_playback_enabled
  is_disabled: $is_disabled
  is_internal: $is_internal
`;

const clientMutationResponse = `
  id
  crm_id
  name
  brands
  address
  vat_number
  notes
  notification_email_recipients
  is_lbc_stream_playback_enabled
  is_disabled
  is_internal
  bookmaker {
    id
    name
  }
  country {
    id
    name
  }
  client_types {
    id
    name
  }
  ${changelogData}
`;

export const clientCreateMutation = `
  mutation CreateClient (
    ${clientMutationVariableDeclaration}
  ) {
    createClient (
      ${clientMutationVariableDefinition}
    ) {
      ${clientMutationResponse}
    }
  }
`;

export const clientUpdateMutation = `
  mutation UpdateClient (
    $id: ID!
    ${clientMutationVariableDeclaration}
  ) {
    updateClient (
      id: $id
      ${clientMutationVariableDefinition}
    ) {
      ${clientMutationResponse}
    }
  }
`;

export const clientDeleteMutation = `
  mutation DeleteClient ($id: ID!) {
    deleteClient (id: $id)
  }
`;

export const clientApiKeyUpdateMutation = `
  mutation UpdateClient (
    $id: ID!
    $api_key: String
  ) {
    updateClient (
      id: $id
      api_key: $api_key
    ) {
      id
      api_key
    }
  }
`;
