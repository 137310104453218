import React from 'react';
import {sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import {Button, Dimmer, Divider, Grid, Header, Loader, Segment} from 'semantic-ui-react';

import {hideModal, showModal} from '@utils/modal';
import EventContentMultiAudioForm from '@modules/events/forms/EventContentMultiAudioForm';
import EventContentMultiAudioModal from '@modules/events/components/EventContentMultiAudioModal';
import {refetchQueryByName} from '@utils/apollo';
import {IconDelete, IconInfoCircleChangelog} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import {Authorization, hasPrivileges} from '@appComponents/Authorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {showMessageBox} from '@utils/messageBox';

const columns = {
    tracks: {label: 'Track', width: 3},
    channels: {label: 'Channels', width: 5},
    streamLanguage: {label: 'Language', width: 5},
    actions: {label: '', width: 3},
};

class EventContentMultiAudioTable extends React.Component {
    static propTypes = {
        properties: PropTypes.array,
        audios: PropTypes.array,
        tracks: PropTypes.array,
        channels: PropTypes.array,
        streamLanguages: PropTypes.array,
        encodingTargetId: PropTypes.number,
    };

    static defaultProps = {
        audios: [],
        tracks: [],
        channels: [],
        streamLanguages: [],
    };

    constructor() {
        super();

        this.state = {
            activeEditableFields: null,
            audios: [],
            streamLanguages: [],
            encodingTargetsSr: [],
            loading: false,
            hasMultiAudio: true,
            authorization: {
                update: hasPrivileges({[RESOURCES.AUDIO]: CONST.SECURITY_PRIVILEGES_UPDATE}),
            },
        };
    }

    addMessageSuccessSave = () => {
        showMessageBox(
            'eventAudioMessage',
            null,
            'The audio has been saved successfully.',
            'success'
        );
    }

    addMessageNoMultiAudio = () => {
        showMessageBox(
            'eventAudioMessage',
            null,
            'Only one audio can be configured because the encoding job profile ' +
            'of this encoding target does not support multi-audio.',
            'error',
            true
        );
    }

    addMessageHasAudioWithoutLanguage = (add) => {
        let onAddMessage = '';

        if (true === add) {
            onAddMessage = 'Please define a language for the new audio you want to add.';
        }

        if (false === add) {
            onAddMessage = 'Please define a language for the existing audio before to be able to add a new one.';
        }

        showMessageBox(
            'eventAudioMessage',
            null,
            'When multiple audios are configured, all of them must have a language defined. ' + onAddMessage,
            'error',
            true
        );
    }

    getMultiAudioSupport = (encodingTargetsSr) => {

        if (encodingTargetsSr && 0 < encodingTargetsSr.length) {
            return encodingTargetsSr[0].encoding_job_profile.has_multi_audio_support;
        }

        return false;
    }

    hasEmptyLanguage = (audios) => {

        for (const audio of audios) {
            if (!audio.stream_language) {

                return true;
            }
        }

        return false;
    }

    componentWillReceiveProps(nextProps) {
        const {DataEventContentMultiAudio: {audios, streamLanguages, encodingTargetsSr}} = nextProps.properties;

        this.setState(() => ({
            audios: audios ? _sortBy(audios, 'creation_datetime') : [],
            streamLanguages,
            encodingTargetsSr,
            encodingTargetId: nextProps.properties.encodingTargetId,
        }));
    }

    activateEditableCell = (event) => {
        this.setState({
            activeEditableFields: convertToInt(event.currentTarget.dataset.id),
        });
    };

    deactivateEditableCell = () => {
        this.setState({
            activeEditableFields: null,
        });
    };

    renderHeader = () => {
        const cells = Object.keys(columns).map((keys) => {
            return (
                <Grid.Column key={keys} width={columns[keys].width}>
                    {columns[keys].label}
                </Grid.Column>
            );
        });

        return (
            <Grid className='editableTable__header --audio_header'>
                <Grid.Row>
                    {cells}
                </Grid.Row>
            </Grid>
        );
    };

    handleMultiAudioAdd = () => {
        this.setState(() => ({loading: true}));

        const dataToSave = Object.assign({}, {
            encodingTargetId: convertToInt(this.state.encodingTargetId),
            trackNumber: convertToInt(this.props.properties.audioToSave.trackNumber),
            channels: this.props.properties.audioToSave.channels ? this.props.properties.audioToSave.channels : [],
            streamLanguage:
                convertToInt(this.props.properties.audioToSave.streamLanguage)
                    ? convertToInt(this.props.properties.audioToSave.streamLanguage) : '',
        });

        const {
            Form: {clearForm},
        } = this.props.properties;

        if (this.hasEmptyLanguage(this.state.audios)) {
            this.addMessageHasAudioWithoutLanguage(false);
            this.setState(() => ({loading: false}));

            return;
        }

        if (1 <= this.state.audios.length && !convertToInt(this.props.properties.audioToSave.streamLanguage)) {
            this.addMessageHasAudioWithoutLanguage(true);
            this.setState(() => ({loading: false}));

            return;
        }

        if (!this.getMultiAudioSupport(this.state.encodingTargetsSr)) {
            if (1 <= this.state.audios.length) {
                this.addMessageNoMultiAudio();
                this.setState(() => ({loading: false}));

                return;

            }
        }

        return this.props.properties.CreateMutation({
            variables: {...dataToSave},
        }).then(() => {
            this.addMessageSuccessSave();
            refetchQueryByName('EventContentMultiAudios');
            refetchQueryByName('GetEncodingTargetsForDistributions');
            refetchQueryByName('GetEventContentEncodingConfigurationChanged');

            clearForm('form_ema_add');

            this.setState(() => ({loading: false}));
        }).catch(this.showErrorMultiAudioNotSaved);
    };

    reRenderModal = () => {
        showModal({
            isVisible: true,
            content: <EventContentMultiAudioModal encodingTargetId={this.props.properties.encodingTargetId} key={2}/>,
            size: 'small',
        });
    }

    handleMultiAudioDelete = (id) => {
        this.props.properties.Modal.setModalConfirmation({
            header: <Header icon='trash' content='Delete audio'/>,
            text: 'Are you sure you want to remove this audio?',
            onYes: () => (this.deleteMultiAudio(id)),
            onNo: () => (this.reRenderModal()),
        });
    };

    handleMultiAudioUpdate = () => {
        this.setState(() => ({loading: true}));
        const audioToSave = this.props.properties.audioToUpdate;
        const dataToUpdate = Object.assign({}, {
            id: convertToInt(audioToSave.id),
            trackNumber: convertToInt(audioToSave.trackNumber),
            channels: audioToSave.channels.sort((a, b) => (a - b)),
            streamLanguage: convertToInt(audioToSave.streamLanguage) ? convertToInt(audioToSave.streamLanguage) : '',
            encodingTargetId: convertToInt(this.props.properties.encodingTargetId),
        });

        if (1 < this.state.audios.length && !convertToInt(audioToSave.streamLanguage)) {
            this.addMessageHasAudioWithoutLanguage(false);

            this.setState(() => ({loading: false}));

            return;
        }

        return this.props.properties.UpdateMutation({
            variables: {...dataToUpdate},
        }).then(() => {
            this.addMessageSuccessSave();
            refetchQueryByName('EventContentMultiAudios');
            refetchQueryByName('GetEncodingTargetsForDistributions');
            refetchQueryByName('GetEventContentEncodingConfigurationChanged');

            this.setState(() => ({loading: false}));
        }).catch(
            this.showErrorMultiAudioNotSaved
        );
    };

    showErrorMultiAudioNotSaved = (error) => {
        this.props.properties.MessageBox.addMessage(
            'eventAudioMessage',
            'The audio could not be saved.',
            `${error}`,
            'error'
        );

        this.setState(() => ({loading: false}));
    };

    deleteMultiAudio(multiAudioId) {
        const dataToDelete = Object.assign({}, {
            id: multiAudioId,
        });
        const {
            MessageBox: {addMessage},
        } = this.props.properties;

        this.props.properties.Modal.setModalConfirmation({
            text: 'The audio is being deleted...',
        });

        return this.props.properties.DeleteMutation({
            variables: {...dataToDelete},
        }).then(() => {
            addMessage('eventAudioMessage',
                null,
                'The audio has been deleted successfully.',
                'success'
            );

            refetchQueryByName('GetEncodingTargetsForDistributions');
            refetchQueryByName('GetEventContentEncodingConfigurationChanged');
            this.reRenderModal();

        }).catch((error) => {
            addMessage(
                'eventAudioMessage',
                'The audio could not be deleted.',
                `${error}`,
                'error'
            );
        });
    }

    renderButtonsForEditableRowInViewMode = (props, multiAudioId) => {
        return (
            <div className='actions-list-icon'>
                <Authorization authorization={{
                    [RESOURCES.AUDIO]: CONST.SECURITY_PRIVILEGES_DELETE,
                }}>
                    <IconDelete icon='trash'
                        onClick={() => {
                            this.handleMultiAudioDelete(multiAudioId.id);
                        }}
                        onClickElement={multiAudioId.id}
                    />
                </Authorization>
                <IconInfoCircleChangelog
                    tooltipProps={{hoverable: true, position: 'top right'}}
                    resources={RESOURCES.AUDIO}
                    data={multiAudioId}
                />
            </div>
        );
    };

    getStreamLanguageById = (id) => {
        let language;

        this.state.streamLanguages.forEach((streamLanguage) => {
            if (id === streamLanguage.id) {
                const languageCodeString = streamLanguage.language.code ? `(${streamLanguage.language.code})` : '';

                language = `${streamLanguage.name} ${languageCodeString}`;
            }
        });

        return language;
    }

    renderEditableGrids = () => {
        return this.state.audios.map((data, index) => {
            return (
                [
                    <Divider key={`divider_${index}`}/>,
                    (this.state.activeEditableFields === convertToInt(data.id))
                        ? this.renderEditForm(data)
                        : this.renderEditableGrid(data),
                ]
            );
        });
    };

    renderEditableGrid = (data) => {
        const gridClass = this.state.authorization.update ? 'editableTable__trigger --audio_modal_row' : '--audio_modal_row',
            onClickCallback = this.state.authorization.update ? this.activateEditableCell : null;

        return (
            <Grid key={data.id} verticalAlign='middle'>
                <Grid.Row data-id={data.id} onClick={onClickCallback} className={gridClass}>
                    <Grid.Column width={columns.tracks.width}>
                        {data.track_number}
                    </Grid.Column>
                    <Grid.Column width={columns.channels.width}>
                        {data.channels.toString()}
                    </Grid.Column>
                    <Grid.Column width={columns.streamLanguage.width}>
                        {this.getStreamLanguageById(data.stream_language ? data.stream_language.id : '')}
                    </Grid.Column>
                    <Grid.Column width={columns.actions.width}>
                        {this.renderButtonsForEditableRowInViewMode(this.props, data)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    renderAddForm = () => {
        return (
            <EventContentMultiAudioForm
                form='form_ema_add'
                formData={{
                    streamLanguages: this.state.streamLanguages,
                    audios: this.state.audios,
                    hasMultiAudioSupport: this.getMultiAudioSupport(this.state.encodingTargetsSr),
                }}
                onAdd={this.handleMultiAudioAdd}
                loading={this.state.loading}
            />
        );
    };

    renderEditForm = (data) => {
        return (
            <EventContentMultiAudioForm
                form='form_ema_update'
                loading={this.state.loading}
                formData={{
                    streamLanguages: this.state.streamLanguages,
                    audios: {
                        id: data.id,
                        trackNumber: data.track_number,
                        channels: data.channels,
                        streamLanguages: data.stream_language,
                    },
                    hasMultiAudioSupport: this.getMultiAudioSupport(this.state.encodingTargetsSr),
                }}
                onUpdate={this.handleMultiAudioUpdate}
                onDeactivateEditableCell={this.deactivateEditableCell}
            />
        );
    };

    render() {
        return (
            <div>
                <Dimmer inverted active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <Header content='Audio configuration'/>
                {this.renderHeader()}
                {this.renderAddForm()}
                {this.renderEditableGrids()}
                <Segment className='--multiAudioModalFooter'>
                    <Button onClick={hideModal} icon='close' content='Close'/>
                </Segment>
            </div>
        );
    }
}

export default EventContentMultiAudioTable;
