import PropTypes from 'prop-types';
import React from 'react';

import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';

const authorizationObject = {
    [RESOURCES.PROPERTY_LICENCE] : CONST.SECURITY_PRIVILEGES_READ,
    [RESOURCES. RIGHT_SCOPE] : CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

const RightsScopeIndexButtons = (props) => (
    <div className='content__buttons'>
        <Authorization authorization={authorizationObject}>
            <Link name={'propertyLicences.rightsScopes.add'} params={{id: props.propertyLicenceId}}>
                <ButtonAdd>{'Add new rights scope'}</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

RightsScopeIndexButtons.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
};

export default RightsScopeIndexButtons;
