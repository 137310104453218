import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import {HAS_HQ} from '@constants/staticDropdowns';
import {withClientBookingsFilters} from '@modules/booking/HOC/withClientBookingsFilters';
import {getBookingFiltersIsCancelledStatus} from '@utils/booking';
import Form from '@appComponents/ReduxFormControls';
import {BookingsFiltersFields} from '@modules/booking/components/BookingsFiltersFields';

const ClientBookingsFilters = (filtersProps) => {
    const {
        clientFilters,
        loading,
        restoreFilterValues,
        data: {
            products,
            eventContentTypes,
            eventContentVariants,
            distributionTypes,
            deviceCategories,
            countries,
            clientPackages,
            bookingTypes,
            invoiceStatuses,
        },
    } = filtersProps;

    return (
        <div className='filter__controls__container'>
            <BookingsFiltersFields {...filtersProps} />
            <Field
                className='--customWidth --contentType'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='eventContentTypes'
                placeholder='Content type'
                options={eventContentTypes}
                search
                setValue={restoreFilterValues(clientFilters, 'eventContentTypes')}
            />
            <Field
                className='--customWidth --contentVariant'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='eventContentVariants'
                placeholder='Content variant'
                options={eventContentVariants}
                search
                setValue={restoreFilterValues(clientFilters, 'eventContentVariants')}
            />
            <Field
                className='--customWidth --products'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='products'
                placeholder='Product'
                options={products}
                search
                setValue={restoreFilterValues(clientFilters, 'products')}
            />
            <Field
                className='--customWidth --distributionTypes'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='distributionTypes'
                placeholder='Distribution type'
                options={distributionTypes}
                search
                setValue={restoreFilterValues(clientFilters, 'distributionTypes')}
            />
            <Field
                className='--customWidth --deviceCategories'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='deviceCategories'
                placeholder='Device category'
                options={deviceCategories}
                search
                setValue={restoreFilterValues(clientFilters, 'deviceCategories')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='countries'
                placeholder='Country'
                options={countries}
                search
                setValue={restoreFilterValues(clientFilters, 'countries')}
            />
            <Field
                className='medium'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='clientPackages'
                placeholder='Package'
                options={clientPackages}
                search
                setValue={restoreFilterValues(clientFilters, 'clientPackages')}
            />
            <Field
                className='--customWidth --isHq'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                selection
                name='isHq'
                placeholder='HQ?'
                options={HAS_HQ}
                setValue={restoreFilterValues(clientFilters, 'isHq')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='bookingTypes'
                placeholder='Type'
                options={bookingTypes}
                search
                setValue={restoreFilterValues(clientFilters, 'bookingTypes')}
            />
            <Field
                className='--customWidth --invoiceStatus'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='invoiceStatuses'
                placeholder='Invoice status'
                options={invoiceStatuses}
                search
                setValue={restoreFilterValues(clientFilters, 'invoiceStatuses')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isCancelled'
                placeholder='Cancellation status'
                options={getBookingFiltersIsCancelledStatus()}
                search
                setValue={restoreFilterValues(clientFilters, 'isCancelled', 0)}
            />
        </div>
    );
};

ClientBookingsFilters.propTypes = {
    clientFilters: PropTypes.object,
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func,
    data: PropTypes.shape({
        products: PropTypes.array,
        eventContentTypes: PropTypes.array,
        eventContentVariants: PropTypes.array,
        distributionTypes: PropTypes.array,
        deviceCategories: PropTypes.array,
        countries: PropTypes.array,
        clientPackages: PropTypes.array,
        bookingTypes: PropTypes.array,
        invoiceStatuses: PropTypes.array,
    }),
};

export const ClientBookingsFiltersComponent = withClientBookingsFilters(ClientBookingsFilters, 'ClientBookings');
