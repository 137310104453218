import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {debounce as _debounce, isEmpty as _isEmpty} from 'lodash';
import {connect} from 'react-redux';

import {withApollo} from 'react-apollo';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetCitiesForDropdown} from '@graphql/city/query';

class VenuesFilters extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        data: PropTypes.shape({
            cities: PropTypes.array,
        }),
        filters: PropTypes.object,
        loading: PropTypes.bool,
        MessageBox: PropTypes.object,
        restoreFilterValues: PropTypes.func.isRequired,
    };

    constructor(props) {
        super();

        this.state = {
            cities: props.data.cities.mapDataForDropdownWithIntVal(),
            citiesLoading: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_isEmpty(this.props.data.cities) && _isEmpty(this.state.cities)
            && this.props.data.cities !== prevProps.data.cities
            && this.state.cities !== prevState.cities) {
            this.setState(() => ({
                cities: this.props.data.cities.mapDataForDropdownWithIntVal(),
            }));
        }
    }

    onCityChange = (event, value) => {
        const searchString = value.searchQuery.trim();

        if (searchString.length >= 3) {
            this.setState(() => ({
                citiesLoading: true,
            }));

            this.getCitiesFromGraphQL(searchString);
        }
    };

    getCitiesFromGraphQL = _debounce((search) => {
        this.props.client.query({
            query: GetCitiesForDropdown,
            variables: {
                search: search,
            },
        }).then((result) => {
            let cities = result.data.cities.mapDataForDropdownWithIntVal();

            this.setState(() => ({
                cities: cities,
                citiesLoading: false,
            }));
        }).catch((error) => {
            this.setState(() => ({
                cities: [],
                citiesLoading: false,
            }));

            this.props.MessageBox.addMessage(
                'venueMessage',
                'Failed to fetch cities filter data',
                `${error}`,
                'error'
            );
        });
    }, 1300);

    render() {
        const loading = this.props.loading;

        return (
            <div className="venues__filters filter__controls__container">
                <Field
                    component={Form.ReduxInput}
                    name="search"
                    placeholder="Search..."
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.Venues, 'search')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name="city"
                    placeholder="City (type at least 3 char.)"
                    options={this.state.cities}
                    search
                    loading={this.state.citiesLoading}
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.Venues, 'city')}
                    onKeyUp={this.onCityChange}
                    allowClear
                />
            </div>
        );
    }
}

export default FiltersWrapper(
    withApollo(connect(null, mapModulesToProps(['MessageBox']))(VenuesFilters)),
    {name: 'Venues'}
);
