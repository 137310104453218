import {changelogData} from "../changelogData";

export const eventPreset = `
query EventPresetQuery($id: ID!) {
  eventPreset(id: $id) {
    id
    name
    ${changelogData}
  }
}`;

export const eventPresets = `
query EventPresetsQuery($propertyLicence: [ID]) {
  eventPresets(filters: {propertyLicence: $propertyLicence}) {
    id
    name
    event {
      id
    }
  }
}`;

export const eventPresetUpdateMutation = `
mutation updateEventPreset (
  $id : ID!
  $name: String
) {
  updateEventPreset (
    id: $id
    name: $name
  ) {
    id
    name
  }
}`;

export const eventPresetDeleteMutation = `
mutation deleteEventPreset($id : ID!) {
  deleteEventPreset(id : $id)
}`;
