import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {saveMessage, setModal, setModalConfirmation, storeEntity, removeEntity} from "../../../actions/index";

/**
 * @ignore
 */
export function FormWrapper(dataMap, Component) {
    const mapDispatchToProps = (dispatch) => {
        return {
            MessageBox: {
                addMessage: (name, header, text, type) => {
                    dispatch(saveMessage({ name, header, text, type }))
                }
            },
            Entity: {
                setEntity: (entity) => {
                    dispatch(storeEntity(entity))
                },
                removeEntity: (name) => {
                    dispatch(removeEntity(name))
                },
            },
            Modal: {
                setModal: (params) => {
                    dispatch(setModal(params))
                },
                setModalConfirmation: (params) => {
                    dispatch(setModalConfirmation(params))
                }
            }
        }
    };

    class Form extends React.Component {
        formHelper = {};

        getPopulatedData(data, map) {
            let dataForForm = {};

            for (let key in map) {
                if (data[key] === undefined) {
                    dataForForm[key] = map[key]
                } else {
                    dataForForm[key] = data[key]
                }

                if (dataForForm && Object.prototype.toString.call( dataForForm[key] ) === "[object Object]") {
                    dataForForm[key] = this.getPopulatedData(dataForForm[key], map[key])
                } else {
                    if (data[key] === undefined || (
                        data[key] === null
                        && Object.prototype.toString.call( map[key] ) === "[object Object]")
                    )
                    {
                        dataForForm[key] = map[key]
                    } else {
                        dataForForm[key] = data[key]
                    }
                }
            }

            return dataForForm
        }

        constructor(props) {
            super(props);

            this.state = {data: {}};

            let dataForForm = this.getPopulatedData(props.formData, dataMap);

            this.state = {data: {...dataForForm}}
        }

        componentWillReceiveProps(props) {

            let dataForForm = this.getPopulatedData(props.formData, dataMap);

            this.setState({ data: {...dataForForm} });
        }

        render() {
            return (
                <Component { ...this.props } dataMap={dataMap} dataForForm={ {...this.state.data} } formHelper={ this.formHelper }/>
            )
        }
    }

    Form.propTypes = {
        formData: PropTypes.object
    };

    return connect(null, mapDispatchToProps)(Form)
}

