import {
    get as _get,
} from 'lodash';

import {LCR_CHANNEL} from '@constants/resources';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {CreateLcrChannelSport, DeleteLcrChannelSport, UpdateLcrChannelSport} from '@graphql/lcr/mutation';
import {GetLcrChannelSportData, GetLcrChannelSportFormOptionsData} from '@graphql/lcr/query';
import validators from '@utils/validators';

const SportModel = () => (
    {
        formName: 'LcrAdministration-contentRestrictions-sports',
        title: (data, type, props) => {
            let title = 'Add new sport';

            const name = _get(props, 'GraphQLEntityData.lcrChannelSport.sport.name', '');

            if (props.formParams.id) {
                title = `Sport "${name}"`;
            }

            return title;
        },
        entityDataMapKey: 'lcrChannelSport.sport',
        label: 'sport',
        entityLabel: 'sport',
        resources: LCR_CHANNEL,
        editForm: true,
        showChangeLog: false,

        validate: (values) => {
            const errors = {};

            Object.assign(errors, validators.required(values.sport, 'sport'));
            Object.assign(errors, validators.required(values.content_tiers, 'content_tiers'));

            return errors;
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'lcrChannelSport.id',
                props: {
                    component: Form.SemanticInput,
                    label: '',
                    name: 'id',
                },
            },
            sport: {
                dataMapKey: 'lcrChannelSport.sport.id',
                props: {
                    className: '--small-width',
                    component: Form.SemanticSelect,
                    required: true,
                    label: 'Sport',
                    name: 'sport',
                },
            },
            content_tiers: {
                optionsKey: 'contentTiers',
                dataMapKey: 'lcrChannelSport.content_tiers',
                props: {
                    component: Form.SemanticCheckboxList,
                    required: true,
                    label: 'Tiers',
                    name: 'content_tiers',
                },
            },
        },
        messages: {
            boxName: {
                success: 'lcrChannelContentRestriction-sports',
                error: 'lcrChannelContentRestriction-sports',
            },
            text: {
                ...MESSAGES,
            },
        },
        mutation: {
            createEntity: CreateLcrChannelSport,
            deleteEntity: DeleteLcrChannelSport,
            updateEntity: UpdateLcrChannelSport,
        },
        query: {
            getEntity: GetLcrChannelSportData,
            getOptions: GetLcrChannelSportFormOptionsData,
        },
    }
);

export default SportModel;
