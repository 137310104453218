import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Dimmer, Loader} from "semantic-ui-react";

import {convertToInt, isUrlParamValid} from '@utils/helpers';

import RoleResourceForm from "../forms/RoleResourceForm";
import RoleResourceModel from "../forms/RoleResourceModel";

import {createForm} from "../../../utils/forms";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import {renderRoleModalError} from "../utils/roleTabsLayoutHelper";

import {GetRoleResources} from "../../../graphql/role/query.graphql";

export const RoleResourceIndex = ({
    match: {
        params: {
            id,
        },
    },
    Modal: {
        setModal,
    },
    Resources: {
        loading,
        role,
        securedResources,
    },
}) => {
    if (loading) {
        return (
            <Dimmer inverted active>
                <Loader/>
            </Dimmer>
        )
    }

    if ("undefined" === typeof role
        || "undefined" === typeof securedResources
        || !isUrlParamValid(id)) {
        setModal({
            isVisible : true,
            content : renderRoleModalError()
        });

        return <React.Fragment></React.Fragment>;
    }

    const model = RoleResourceModel(role.resources, securedResources, role.name);
    const FormToRender = React.useMemo(
        () => (createForm(() => (model), RoleResourceForm, {entityId: convertToInt(id)})),
        []
    );

    return (
        <FormToRender />
    );
};

RoleResourceIndex.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
    Resources: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        role: PropTypes.object,
        securedResources: PropTypes.array,
    }),
};

RoleResourceIndex.defaultProps = {
    Resources: {
        role: {},
        securedResources: [],
    },
};

const RoleResourcesWithQuery = compose(
    graphql(GetRoleResources, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "network-only",
                variables: {
                    id: props.match.params.id
                }
            }
        },
        name: "Resources"
    })
)(RoleResourceIndex);

export default withRouter(connect(null, mapModulesToProps(['Modal']))(RoleResourcesWithQuery));
