export const bookmakersList = `bookmakers (filters: {
    client: $client
    search: $search
  }) {
    id,
    name,
    key : id,
    value : id,
    text : name
}`;

export const bookmakersDropdownList = `bookmakers(filters: {search: $search, hide_assigned:true}) {
    id,
    name,
    key : id,
    value : id,
    text : name
}`;


export const bookmakersListQuery = `
query DropdownListBookmaker($search: String!) {
    ${bookmakersDropdownList}
}`;
