import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {connect} from 'react-redux';

/**
 * @ignore
 */
class ShowCurrentDateTime extends Component {
    static propTypes = {
        timezone: PropTypes.string,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        this.format = 'YYYY-MM-DD, HH:mm';
        this.state = {
            time: moment().format(this.format),
            timezone: null,
        };
    }

    /**
     * @ignore
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.timezone !== prevState.timezone) {
            return {timezone: nextProps.timezone};
        } else return null;
    }

    /**
     * @ignore
     */
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }

    /**
     * @ignore
     */
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    /**
     * @ignore
     */
    tick() {
        this.setState({
            time: moment.tz(this.state.timezone).format(this.format),
        });
    }

    /**
     * @ignore
     */
    render() {
        return this.state.timezone ? (
            <p className="timedate-clock">{this.state.time}</p>
        ) : null;
    }
}

export default connect((state) => {
    return {timezone: state.timezone.timezone};
})(ShowCurrentDateTime);
