import React from 'react';
import {get as _get} from 'lodash';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';

import routes from '@constants/routes';
import Table from '@appComponents/Table';
import {
    sportsRowRenderer,
    sportsTableColumns,
    categoriesRowRenderer,
    categoriesTableColumns,
    tournamentsRowRenderer,
    tournamentsTableColumns,
} from '@modules/lcr/utils/contentRestrictions';
import {
    GetLcrChannelSportContent,
    GetLcrChannelTournamentCategoryContent,
    GetLcrChannelTournamentContent,
} from '@graphql/lcr/query';

export const ContentRestrictionTable = ({type, lcrChannelId}) => {
    const options = {};

    switch (type) {
        case 'sports':
            options['dataPath'] = 'lcrChannel.lcr_channel_sports';
            options['tableRowRenderer'] = sportsRowRenderer;
            options['dataQuery'] = GetLcrChannelSportContent;
            break;
        case 'categories':
            options['dataPath'] = 'lcrChannelTournamentCategories';
            options['tableRowRenderer'] = categoriesRowRenderer;
            options['dataQuery'] = GetLcrChannelTournamentCategoryContent;
            break;
        case 'tournaments':
            options['dataPath'] = 'lcrChannel.tournaments';
            options['tableRowRenderer'] = tournamentsRowRenderer;
            options['dataQuery'] = GetLcrChannelTournamentContent;
            break;
        default:
            return null;
    }

    const noDataText = `No ${type} found`,
        {data, loading} = useQuery(options.dataQuery, {
            variables: {
                lcrChannelId: lcrChannelId,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache',
        }),
        dataForTable = _get(data, options.dataPath, []);

    switch (type) {
        case 'sports':
            options['tableColumns'] = sportsTableColumns;
            break;
        case 'categories':
            options['tableColumns'] = categoriesTableColumns;
            break;
        case 'tournaments':
            options['tableColumns'] = tournamentsTableColumns;
            break;
    }

    return <Table
        pagination={false}
        loading={loading}
        columns={options.tableColumns}
        data={dataForTable}
        noDataText={noDataText}
        name={`${type}LcrAdministrationList`}
        rowRenderer={options.tableRowRenderer}
        rowRendererProps={{id: lcrChannelId}}
        url={routes.lcrChannel.edit.contentRestrictions.index.path}
    />;
};

ContentRestrictionTable.propTypes = {
    type: PropTypes.string,
    lcrChannelId: PropTypes.string,
};
