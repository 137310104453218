import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorEventContentCdnConfigurations";
import * as MESSAGES from "../../app/constants/messages";
import * as CUSTOM_MESSAGES from "./../constants/eventContentClientCdnConfigurationMessages";
import {CLIENT_CDN_CONFIGURATION} from "../../app/constants/resources";

import {
    addClientCdnConfigurationMutation,
    getOptionsForCdnConfigurationForm
} from "../../../graphql/events/eventContentCdnConfiguration";

export default function EventContentCdnConfigurationModel() {
    return {
        entityDataMapKey: "cdn_configuration",
        entityLabel: "cdn_configuration",
        formName: "EventContentCdnConfiguration",
        resources: CLIENT_CDN_CONFIGURATION,
        showChangelog: false,
        validate: validate,
        showValidationError: false,
        title: () => "Add new client CDN configurations",
        dataMap: {
            cdn_configuration: {
                cdn_configurations: [],
            },
        },
        messages: {
            boxName: {
                delete: 'eventsConfiguration',
                error: 'eventsConfiguration',
                success: 'eventsConfiguration',
            },
            text: Object.assign({}, MESSAGES, CUSTOM_MESSAGES),
        },
        fields: {
            cdn_configuration: {
                dataMapKey: "cdn_configuration.cdn_configurations",
                optionsKey: "cdnConfigurations",
                props: {
                    component: Form.SemanticSelect,
                    label: "",
                    multiple: true,
                    name: "cdn_configurations",
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: addClientCdnConfigurationMutation,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['GetEncodingTargetsForDistributions', 'GetEventContentEncodingConfigurationChanged'],
            },
        },
        query: {
            getOptions: getOptionsForCdnConfigurationForm,
        },
    };
}
