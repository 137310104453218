import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Segment} from 'semantic-ui-react';
import {get as _get} from 'lodash';

import {graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import navigation from '@constants/navigation';
import {getSearchFilters} from '@utils/filters';
import {getCountriesForDropdown} from '@utils/countryHelpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {GetAvCompetitorsForTable} from '@graphql/avCompetitor/query';
import {GetCountries} from '@graphql/country/query';

import CompetitorsTable from '../components/CompetitorsTable';
import CompetitorsIndexButtons from '../components/CompetitorsIndexButtons';
import CompetitorsFilters from '../components/CompetitorsFilters';
import {exportExcel} from '../utils/export/competitorsIndexExcel';

export class CompetitorsIndex extends React.Component {
    static propTypes = {
        DataAvCompetitors: PropTypes.shape({
            avCompetitors: PropTypes.array,
            loading: PropTypes.bool,
        }),
        DataCountries: PropTypes.shape({
            countries: PropTypes.array,
            loading: PropTypes.bool,
        }),
        filters: PropTypes.shape({
            search: PropTypes.string,
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        Menu: PropTypes.object,
    };

    static defaultProps = {
        DataAvCompetitors: {
            avCompetitors: [],
            loading: false,
        },
        DataCountries: {
            countries: [],
            loading: false,
        },
        filters: {
            search: '',
            countries: [],
        },
        Menu: {},
    };

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.eventAdministration.key);
    }

    render() {
        const competitors = _get(this.props, 'DataAvCompetitors.avCompetitors', []),
            countriesDropdownList = getCountriesForDropdown(
                _get(this.props, 'DataCountries.countries', [])
            ),
            loading = (
                this.props.DataAvCompetitors.loading
                || this.props.DataCountries.loading
                || this.props.loadingRefetch
            );

        return (
            <div>
                <HeaderRenderer
                    buttons={CompetitorsIndexButtons}
                    exportExcelParams={exportExcel(competitors)}
                    filtersButtonName='AvCompetitors'
                    filtersProps={{loading: loading}}
                    loading={loading}
                    messagesBoxNames='competitorMessage'
                    pageTitle='Competitors'
                />
                <CompetitorsFilters
                    data={{
                        countries: countriesDropdownList,
                    }}
                    loading={loading}
                />
                <div className='clearfix'/>
                <Segment basic className='--table'>
                    <CompetitorsTable
                        data={competitors}
                        loading={loading}
                    />
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'AvCompetitors', CompetitorsIndex.defaultProps.filters),
});

const CompetitorsWithQuery = compose(
    graphql(GetAvCompetitorsForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    search: props.filters.search || '',
                    country_id: props.filters.countries || CompetitorsIndex.defaultProps.filters.countries,
                },
            };
        },
        name: 'DataAvCompetitors',
    }),
    graphql(GetCountries, {
        options: () => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        name: 'DataCountries',
    })
)(FilterQueryWrapper(CompetitorsIndex, {
    queryForRefresh: 'DataAvCompetitors',
    filterUrls: ['competitors.index'],
}));

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(
    FilterUrlParamsWrapper(CompetitorsWithQuery, CompetitorsIndex.defaultProps.filters)
);
