
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MatchSelectionPeriodData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MatchSelectionPeriod"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contents"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PropertyLicenceContentData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"includes_all_content"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"start_date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"end_date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"num_matches_agreed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"num_matches_commitment"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"num_matches_budget"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"notes"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":328}};
    doc.loc.source = {"body":"#import \"../propertyLicenceContent/fragment.graphql\"\n\nfragment MatchSelectionPeriodData on MatchSelectionPeriod {\n    ...BasicData\n    contents {\n        ...PropertyLicenceContentData\n    }\n    includes_all_content\n    start_date\n    end_date\n    num_matches_agreed\n    num_matches_commitment\n    num_matches_budget\n    notes\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../propertyLicenceContent/fragment.graphql").definitions));


      module.exports = doc;
    
