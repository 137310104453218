import PropTypes from 'prop-types';

import ClipConfigurationSportLevelForm from '@modules/clipConfiguration/forms/ClipConfigurationSportLevelForm';
import ClipConfigurationTournamentLevelForm
    from '@modules/clipConfiguration/forms/ClipConfigurationTournamentLevelForm';
import {displayFormModal, getClipConfigName} from '@modules/clipConfiguration/utils/formHelpers';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import ClipConfigurationSportLevelEditModel
    from '@modules/clipConfiguration/forms/ClipConfigurationSportLevelEditModel';
import ClipConfigurationTournamentLevelEditModel
    from '@modules/clipConfiguration/forms/ClipConfigurationTournamentLevelEditModel';
const ClipConfigurationAddModalContent = (props) => {
    let title = 'Clip configuration - AV-CMP',
        model = ClipConfigurationSportLevelEditModel,
        form = ClipConfigurationSportLevelForm;

    getClipConfigName(props.match.params.id)
        .then((result) => {
            title = `Clip configuration - ${result.data.clipConfig.name} - AV-CMP`;

            if (0 < result.data.clipConfig.tournaments.length) {
                model = ClipConfigurationTournamentLevelEditModel;
                form = ClipConfigurationTournamentLevelForm;
            }

            displayFormModal(props, title, model, form, {id: props.match.params.id});
        }).catch(() => {
            showErrorModal({
                header: 'Page not found',
                redirectLink: getLink('clipConfiguration.index'),
                text: `The requested clip configuration could not be found.`,
                errorButtonIcon: 'arrow circle left',
                errorButtonText: 'Back to list',
            });
        });
};

ClipConfigurationAddModalContent.propTypes = {
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default ClipConfigurationAddModalContent;
