import React from "react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {Segment} from "semantic-ui-react";
import {connect} from 'react-redux';
import { graphql} from "react-apollo";

import PathValidator from './PathValidator';
import Time from './HeaderComponents/Time.jsx';
import User from './HeaderComponents/User.jsx';
import Timezone from './HeaderComponents/Timezone.jsx';
import {setTimezone} from "../../../actions/index";

import {GetUserSettingsForHeader} from "../../../graphql/user/query.graphql";

/**
 * @ignore
 */
class Header extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        location: PropTypes.object.isRequired,
        setTimezone: PropTypes.func.isRequired,
        UserSettings: PropTypes.object,
    };

    static defaultProps = {
        data: {},
    };

    state = {
        timezone: ""
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        let timezone = '';

        if (nextProps.data && nextProps.data.userSettings) {
            const {userSettings} = nextProps.data;

            if (userSettings.timezone) {
                timezone = userSettings.timezone;
            } else if (userSettings.autodetected_timezone) {
                timezone = userSettings.autodetected_timezone;
            } else {
                timezone = 'UTC';
            }
        }

        if (timezone !== prevState.timezone) {
            return {
                timezone: timezone,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.timezone !== prevState.timezone) {
            this.props.setTimezone(this.state.timezone);
        }
    }

    render () {
        if (!this.props.data || !this.props.data.userSettings) {
            return null;
        }

        const {userSettings} = this.props.data;

        return (
            <Segment basic className="page-header">
                <div className="page-header__breadcrumb">
                    <PathValidator pathname={this.props.location.pathname} />
                </div>
                <div className="page-header__widgets">
                    <Time timezone={this.state.timezone}/>
                    <Timezone timezone={this.state.timezone} />
                    <User name={(userSettings && userSettings.name) ? userSettings.name : '..'}/>
                </div>
            </Segment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        setTimezone: (timezone) => {dispatch(setTimezone(timezone))}
    })
};

const HeaderWithQuery = graphql(GetUserSettingsForHeader)(Header);

export default withRouter(connect(null, mapDispatchToProps)(HeaderWithQuery));
