import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.third_party_client_cdn_type, 'third_party_client_cdn_type'));
    Object.assign(errors, validators.required(values.primary_gateway, 'primary_gateway'));
    Object.assign(errors, validators.required(values.encoding_job_profile, 'encoding_job_profile'));

    return errors;
};

export default validate;
