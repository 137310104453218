import PropTypes from 'prop-types';
import {Image} from 'semantic-ui-react';
import React from 'react';
import {get as _get} from 'lodash';

import {IconWithTooltip} from '@appComponents/IconCollection';
import {convertToInt, getEncodingLogsInVideoManagementPlatformLink} from '@utils/helpers';

import ContentConfigurationLink from './ContentConfigurationLink';
import {shouldShowLinkToReleaseAkamaiStreamId} from '../../utils/eventsConfigurationEncodingInformation';

function HeaderButtons(props) {
    const additionalIconsVisibility = _get(props.content.event_content_input, 'encoding_first_start_datetime', null);
    const showIconToReleaseAkamaiStreamId = shouldShowLinkToReleaseAkamaiStreamId(props.content.distributions, props.encodingTargets, props.content.event_content_input);

    return (
        <>
            {
                additionalIconsVisibility &&
                <IconWithTooltip
                    name='file alternate outline'
                    onClick={(event) =>{
                        event.preventDefault();
                        event.stopPropagation();

                        const url = getEncodingLogsInVideoManagementPlatformLink(
                            props.content.id,
                            props.content.event_content_input.encoding_datacenter.name
                        );

                        window.open(url, '_blank');
                    }}
                    tooltip='Encoding logs'
                    popupProps={{position: 'top right'}}
                />
            }
            {
                showIconToReleaseAkamaiStreamId &&
                <ContentConfigurationLink
                    contentId={convertToInt(props.content.id)}
                    eventId={convertToInt(props.eventData.id)}
                    linkName='releaseAkamaiStreamId'
                    icon={<Image className='akamai' inline={true} size='tiny' src={'/images/logo_akamai.png'}/>}
                    text='Release Akamai stream ID (SpOTT)'
                />
            }
        </>
    );
}

HeaderButtons.propTypes = {
    content: PropTypes.object,
    eventData: PropTypes.object,
    encodingTargets: PropTypes.array,
};

export default HeaderButtons;
