import PropTypes from 'prop-types';

export const exportExcel = (dataExport) => (
    {
        dataExport: dataExport,
        filename: 'licensors',
        parseData: licensor => [
            licensor.id,
            licensor.name,
            licensor.country ? licensor.country.name.capitalizeFirstLetter() : '',
            licensor.property_licences_count,
        ],
        path: 'licensors.index.path',
        titles: [
            'ID',
            'Name',
            'Country',
            '#Property licences',
        ],
    }
);

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
