import validators from '@utils/validators';
import {
    LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH,
    LIVE_INGEST_METHOD_AKAMAI_STREAM_PACKAGING,
} from '@constants/variables';

const validate = (values, props) => {
    const errors = {};

    Object.assign(errors, validators.required(values.encoding_datacenter, 'encoding_datacenter'));
    Object.assign(errors, validators.required(values.stream_name, 'stream_name'));
    Object.assign(errors, validators.maxLength({value: values.stream_name, maxLength: 200}, 'stream_name'));

    if (values.stream_name && (200 >= values.stream_name.length)) {
        if (LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH === props.formParams.liveIngestMethodId) {
            Object.assign(errors, validators.alphanumericWithOptionalUnderscore(values.stream_name, 'stream_name'));
        } else {
            Object.assign(errors, validators.alphanumericAtAlphanumeric(values.stream_name, 'stream_name'));
        }
    }

    if ([LIVE_INGEST_METHOD_AKAMAI_STREAM_PACKAGING, LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH].includes(
        props.formParams.liveIngestMethodId
    )) {
        Object.assign(errors, validators.required(values.stream_protocol, 'stream_protocol'));
    }

    return errors;
};

export default validate;
