import PropTypes from "prop-types";
import React from "react";
import {withRouter} from "react-router";

import ClientAnalyticsSetupIndex from "../views/ClientAnalyticsSetupsIndex";
import ClientAnalyticsSetupLabelsIndex from "../views/ClientAnalyticsSetupLabelsIndex";
import Tabs from "../../app/components/Tabs";

import {routes} from "../../app/constants/routes";
import {EXPORT_EXCEL_URL_FRAGMENT} from "../../app/constants/variables";

class ClientAnalyticsSetupTabsLayout extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    componentWillMount() {
        this.setState(() => this.getClientTabsState());
    }

    componentWillReceiveProps(nextProps) {
        this.setState(() => this.getClientTabsState(nextProps));
    }

    getClientTabsState = (props) => {
        if (!props) {
            props = this.props;
        }

        const clientId = parseInt(this.props.match.params.id, 10),
            clientAnalyticsSetupRoutes = routes.clients.clientAnalyticsSetup,
            clientAnalyticsSetupLabelRoutes = clientAnalyticsSetupRoutes.labels

        let active = props.match.path;

        if (
            active === clientAnalyticsSetupLabelRoutes.add.path
            || active === clientAnalyticsSetupLabelRoutes.edit.path
            || active === `${clientAnalyticsSetupLabelRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = clientAnalyticsSetupLabelRoutes.index.path;
        }

        let tabs = {
            [clientAnalyticsSetupRoutes.index.path]: {
                key: clientAnalyticsSetupRoutes.index.path,
                render: <ClientAnalyticsSetupIndex clientId={clientId} />,
                text: "General settings",
                url: "clients.clientAnalyticsSetup.index",
                urlParams: {id: props.match.params.id},
            },
            [clientAnalyticsSetupLabelRoutes.index.path]: {
                key: clientAnalyticsSetupLabelRoutes.index.path,
                render: <ClientAnalyticsSetupLabelsIndex
                    clientId={clientId}
                />,
                text: "Labels",
                url: "clients.clientAnalyticsSetup.labels.index",
                urlParams: {id: props.match.params.id},
            },
        };

        return {
            active: active,
            tabs: tabs,
        };
    };

    render() {
        return (
            <div className="clientAnalyticsSetupTabs">
                <Tabs active={this.state.active} items={this.state.tabs} level={2}/>
            </div>
        );
    }
}

export default withRouter(ClientAnalyticsSetupTabsLayout);
