import React from 'react';
import {Popup} from "semantic-ui-react";

export const wrapWithEnvironmentPopup = (item, currentEnvironmentName, sidebarWidthCollapsed) => (
    <Popup
        className={'navigation__popup'}
        content={`You are using the "${currentEnvironmentName}" environment.`}
        wide
        inverted
        style={{
            left: sidebarWidthCollapsed,
            right: 'auto'
        }}
        position='bottom left'
        trigger={item}
    />
);
