import {get as _get} from "lodash";

import {SET_TABLE_PARAMS} from "../actions/actionTypes";

const initialState = {
};

const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TABLE_PARAMS: {
            const newState = {...state}

            newState[action.params.name] = {
                ...state[action.params.name],
                ...action.params.tableParams
            }

            return newState;
        }
        case "persist/REHYDRATE": {
            const savedData = _get(action, "payload.app.table", false) || initialState;

            return {
                ...state,
                ...savedData
            };
        }
        default:
            return state
    }
}

export default tableReducer;
