import React from "react";
import {
    union as _union,
    isEqual as _isEqual,
    without as _without
} from "lodash"
import PropTypes from "prop-types";
import classnames from "classnames";
import {Dimmer, Form as SemanticForm, Icon, Loader, Popup} from "semantic-ui-react";

import {TreeBehaviourComponent} from "./TreeBehaviourComponent";

/**
 * Render list of checkboxes in tree representation
 *
 * For every level of inheritance classname __level[level number] is added.
 * CSS currently handle 3 level of inheritance by adding margin left
 */
export class SemanticCheckboxTree extends TreeBehaviourComponent {
    /**
     * @property {Boolean} hidden Param for hidding list
     * @property {Array<Object>} options Array of objects <br/>
     * <pre>
     * [
     *  {
     *      key: 1,
     *      value: "Value 1", - {number | string}
     *      name: "Name", - {String}
     *      selected: true, - {Boolean}
     *      hidden: false, - {Boolean}
     *      disabled: false, - {Boolean}
     *      children: [
     *          {
     *              key: 3,
     *              value: "Value 3",
     *              name: "Name 3"
     *              children: [
     *                  ...
     *              ],
     *              ...
     *          }
     *      ]
     *  },
     *  {
     *      key: 2,
     *      value: "Value 2",
     *      name: "Name"
     *  }
     * ]
     * </pre>
     */
    static propTypes = {
        className: PropTypes.string,
        hidden: PropTypes.bool,
        noDataText: PropTypes.string,
        options: PropTypes.array,
        onChangeCallback: PropTypes.func,
        returnParsedTreeValues: PropTypes.bool,
        showNoDataText: PropTypes.bool,
        disabled: PropTypes.bool,
        allowChildless: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        noDataText: null,
        onChangeCallback: null,
        returnParsedTreeValues: false,
        showNoDataText: false,
        allowChildless: false,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        this.state = {
            options: props.options,
            valuesTree: [],
            selected: [],
            indeterminate: [],
            selectedValues: {},
            expanded: [],
        };

        this.renderCheckboxTree = this.renderCheckboxTree.bind(this);
        this.expandClick = this.expandClick.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
    }

    /**
     * @ignore
     */
    componentDidMount() {
        let mapOptions = this.mapOptions(this.props.options, this.props.allowChildless),
            data = this.getTreeData(mapOptions);

        /**
         * @ignore
         */
        this.setState(() => {
            return {
                options: this.props.options,
                valuesTree: mapOptions.values,
                selected: data.selected,
                indeterminate: data.indeterminate,
                selectedValues: {},
                expanded: [],
            }
        });

        let valuesToReturn = this.parseValuesToReturn(mapOptions.selected);

        this.props.input.onChange(valuesToReturn);
    }

    /**
     * @ignore
     */
    componentWillReceiveProps(nextProps) {
        if (!_isEqual(nextProps.options, this.state.options)) {
            let mapOptions = this.mapOptions(nextProps.options, nextProps.allowChildless),
                data = this.getTreeData(mapOptions),
                newState = {
                    options: nextProps.options,
                    valuesTree: mapOptions.values,
                    selected: data.selected,
                    indeterminate: data.indeterminate,
                };

            if(0 === nextProps.options.length) {
                newState.expanded = [];
                newState.indeterminate = [];
            }

            this.setState(() => (
                newState
            ));

            let valuesToReturn = this.parseValuesToReturn(mapOptions.selected);

            this.props.input.onChange(valuesToReturn);
        }
    }

    /**
     * @ignore
     */
    expandClick(event) {
        let expanded = this.state.expanded.slice(0),
            id = event.currentTarget.dataset.id;

        if (this.state.expanded.indexOf(id) > -1) {
            expanded.splice(this.state.expanded.indexOf(id), 1)
        } else {
            expanded.push(id)
        }

        this.setState(() => ({
            expanded: expanded
        }))
    }

    /**
     * @ignore
     */


    /**
     * @ignore
     */
    toggleCheckbox = (event) => {
        let path = "",
            selected = this.state.selected;

        if (typeof(event) === "object") {
            let dataset = event.currentTarget.dataset;

            path = dataset.key;
        } else {
            path = event
        }

        let selectedIndex = selected.indexOf(path);

        if (selected.indexOf(path) > -1) {
            selected.splice(selectedIndex, 1);

            if (this.state.valuesTree[path]) {
                selected = _without(selected, ...this.state.valuesTree[path]);
            }
        } else {
            if (this.state.valuesTree[path]) {
                selected = _union(selected, this.state.valuesTree[path])
            }

            selected = _union(selected, [path]);
        }

        let intersectData = this.checkIntersection(path, selected, this.state.valuesTree);
        let valuesToReturn = this.parseValuesToReturn(intersectData.selected);

        selected = _union(selected, intersectData.selected);

        this.props.input.onChange(valuesToReturn);

        this.setState(() => ({
            indeterminate: intersectData.indeterminate,
            selected: selected
        }));

        if (this.props.onChangeCallback) {
            if (true === this.props.returnParsedTreeValues) {
                this.props.onChangeCallback(null, {name: this.props.input.name, data: valuesToReturn});
            } else {
                this.props.onChangeCallback(event, selected);
            }
        }
    };

    /**
     * @ignore
     */
    renderChildren(node, parent, level) {
        let key = `${this.removeDashes(node.name)}-${this.removeDashes(node.value)}`.replace(/[\s,.]/g, "&&");

        if (parent) {
            key = `${parent}.${key}`;
        }

        if (node.children && this.state.expanded.indexOf(key) > -1) {
            return (
                <div className={`children-group --level${level}`}>
                    {this.renderCheckboxTree(node.children, key, level)}
                </div>
            );
        }

        return null
    }

    /**
     * @ignore
     */
    renderCheckboxTree(options, parent, level) {
        if (!level) {
            level = 1;
        }

        return options.map(node => {
            const countryMaxLength = 26;
            let label = (node.label) ? node.label : node.name,
                name = "default";

            if (node.name) {
                name = node.name
            }

            let newKey = `${this.removeDashes(name)}-${this.removeDashes(node.value)}`.replace(/[\s,.]/g, "&&"),
                key = `${parent}.${newKey}`;

            if (
                ("CountrySubdivision" === node.__typename || "Country" === node.__typename)
                && 1 < level
                && countryMaxLength < label.length
            ) {
                let shortLabel = label.slice(0, countryMaxLength).split(" ").filter((value) => {
                    return 1 < value.length;
                }).join(' ') + "...";

                label = (
                    <Popup
                        trigger={<label>{shortLabel}</label>}
                        position={"top center"}
                        content={label}
                    />
                );
            }

            if (!parent) {
                key = newKey
            }

            let treeIsVisibleClass = "";
            let treeIsExpandedClass = "right";

            if (this.state.expanded.indexOf(key) > -1) {
                treeIsExpandedClass = "down"
            }

            if (!node.children || !node.children.length) {
                treeIsVisibleClass = "--no-visible"
            }

            return <div key={key} className={classnames(`checkboxTree__level --level${level}`, {'hidden': node.hidden})}>
                <Icon
                    className={`chevron ${treeIsExpandedClass} small ${treeIsVisibleClass}`}
                    data-id={key}
                    onClick={this.expandClick}
                />
                <SemanticForm.Checkbox
                    checked={-1 !== this.state.selected.indexOf(key) && !node.disabled}
                    data-key={key}
                    data-parent={parent}
                    data-value={node.value}
                    disabled={this.props.meta.submitting || this.props.disabled || node.disabled}
                    indeterminate={this.state.indeterminate.indexOf(key) !== -1 && this.state.selected.indexOf(key) === -1}
                    key={node.value}
                    label={label}
                    onChange={this.toggleCheckbox}
                    name={`${name}.${node.value}`}
                />
                {this.renderChildren(node, parent, level + 1)}
            </div>
        })
    }

    /**
     * @returns
     * Store:<br/><br/>
     * <pre>
     * [
     *  {
     *      key: 1,
     *      value: "Value 1", - {number | string}
     *      name: "Name", - {String}
     *      selected: true, - {Boolean}
     *      children: [
     *          {
     *              key: 3,
     *              value: "Value 3",
     *              name: "Name 3"
     *              children: [
     *                  ...
     *              ],
     *              ...
     *          }
     *      ]
     *  },
     *  {
     *      key: 2,
     *      value: "Value 2",
     *      name: "Name"
     *  }
     * ]
     * </pre>
     *
     */
    renderTree() {
        return (
            <div className={`${this.props.hidden ? 'hidden' : ''} ${this.props.className ? this.props.className : ''}`}>
                {this.renderLabel(this.props)}
                <div className="input-container">
                    <div className="checkboxTree">
                        {this.props.loading
                        && <Dimmer active inverted>
                            <Loader active inline size='small'/>
                        </Dimmer>
                        }
                        {this.renderCheckboxTree(this.state.options)}
                    </div>
                    <div className="error">{this.isError(this.props) ? this.props.meta.error : ''}</div>
                </div>
            </div>

        )
    }

    render() {
        return this.props.showNoDataText && this.props.noDataText
            ? <div>{this.props.noDataText}</div>
            : this.renderTree();
    }
}

export default SemanticCheckboxTree;
