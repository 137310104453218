import {changelogData} from "../changelogData";
import {BasicData} from "../fragments";

const eventIncidentFragment = `
            id
            event_incident_reason {
                id
                name
                event_incident_causer {
                    id
                    name
                    event_incident_location {
                        id
                        name
                    }
                }
            }
            affects_all_output_streams
            lasted_entire_duration
            start_datetime
            end_datetime
            count_as_downtime
            comment
            event_contents {
                id
                name
                event_content_type {
                    id
                    name
                }
            }
            event_incident_scopes {
                id
                encoding_target {
                    id
                    distribution {
                        id
                        event_content {
                            id
                            name
                        }
                        product {
                            id
                            short_name
                        }
                        distribution_type {
                            id
                            name
                        }
                    }
                    stream_delay_type {
                        id
                        name
                    }
                }
                stream_protocol {
                    id
                    name
                }
            }
`;

const causersQueryFragment = `
eventIncidentCausers(filters: {eventIncidentLocation: $locationId}) {
    id
    key: id
    text: name
    value: id
}`;

const reasonsQueryFragment = `
eventIncidentReasons(filters: {eventIncidentCauser: $causerId}) {
    id
    key: id
    text: name
    value: id
}`;

const contentsQueryFragement = `
eventContents(filters: {event: $eventId}) {
    id
    name
    event_content_type {
        id
        name          
    }
    event_content_variant {
        ${BasicData}
        short_name
    }
}`;

const locationsQueryFragment = `
eventIncidentLocations {
    id
    text: name
    value: id
    key: id
}`;

export const createEventIncidentMutation = `
    mutation createEventIncident(
        $event_incident_reason: Int!
        $affects_all_output_streams: Boolean
        $lasted_entire_duration: Boolean
        $start_datetime: DatetimeTzInputType
        $end_datetime: DatetimeTzInputType
        $count_as_downtime: Boolean
        $comment: String
        $event_contents: [Int]
        $event_incident_scopes: [EventIncidentScopeInput]
    ) {
        createEventIncident(
            event_incident_reason: $event_incident_reason
            affects_all_output_streams: $affects_all_output_streams
            lasted_entire_duration: $lasted_entire_duration
            start_datetime: $start_datetime
            end_datetime: $end_datetime
            count_as_downtime: $count_as_downtime
            comment: $comment
            event_contents: $event_contents
            event_incident_scopes: $event_incident_scopes
        ) {
            ${eventIncidentFragment}
        }
    }
`;

export const updateEventIncidentMutation = `
    mutation udpateEventIncident(
        $id: ID!
        $event_incident_reason: Int!
        $affects_all_output_streams: Boolean
        $lasted_entire_duration: Boolean
        $start_datetime: DatetimeTzInputType
        $end_datetime: DatetimeTzInputType
        $count_as_downtime: Boolean
        $comment: String
        $event_contents: [Int]
        $event_incident_scopes: [EventIncidentScopeInput]
    ) {
        updateEventIncident(
            id: $id                    
            event_incident_reason: $event_incident_reason
            affects_all_output_streams: $affects_all_output_streams
            lasted_entire_duration: $lasted_entire_duration
            start_datetime: $start_datetime
            end_datetime: $end_datetime
            count_as_downtime: $count_as_downtime
            comment: $comment          
            event_contents: $event_contents    
            event_incident_scopes: $event_incident_scopes
        ) {
            ${eventIncidentFragment}
        }
    }
`;

export const deleteEventIncidentMutation =  `
    mutation deleteEventIncident(
        $id: ID!
    ) {
        deleteEventIncident(id: $id)
    }    
`;

export const optionsForEditEventIncident = `
query eventIncidentsEditOptionsQuery($eventId: Int, $locationId: Int, $causerId: Int){
        ${locationsQueryFragment}
        ${contentsQueryFragement}
        ${causersQueryFragment}
        ${reasonsQueryFragment}
    }
`;

export const optionsForEventIncident = `
query eventIncidentsOptionsQuery($eventId: Int){
    ${locationsQueryFragment}
    ${contentsQueryFragement}
}`;

export const getEventIncidentCausersQuery = `
query eventIncidentCausers($locationId: Int) {
    ${causersQueryFragment}
}`;

export const getEventIncidentReasonsQuery = `
query eventIncidentReasons($causerId: Int) {
    ${reasonsQueryFragment}
}`;

export const getStreamsQuery = `
query getStreamsQuery($eventContents: [ID]) {
    streams(filters: {eventContents: $eventContents}) {
        id
        stream_protocol{
          id
          name
        }
        encoding_target {
          id
          distribution {
          id
          event_content {
            id
            name
            event_content_type {
              id
              name
            }
            event_content_variant {
              ${BasicData}
              short_name
            }
          }
          distribution_type {
            id
            name
          }
          product {
            id
            short_name
          }
        }
        stream_delay_type {
          id
          name
        }
      }
    }
}`;

export const getEventIncident = `
query getEventIncident($id: ID) {
    eventIncident(id: $id) {
        ${eventIncidentFragment}
        ${changelogData}
    }
}`;
