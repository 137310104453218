import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {get as _get} from 'lodash';
import moment from 'moment/moment';
import {useHistory} from 'react-router-dom';

import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories, renderNumberOfCountries,
} from '@utils/countryHelpers';
import Authorization from '@appComponents/Authorization';
import {convertToInt} from '@utils/helpers';
import {GetMappedIsoCodes} from '@graphql/country/query';
import Table from '@appComponents/Table';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {IconWithTooltip} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import EventBookingCancellationIcon from '@modules/booking/views/BookingCancellationIcon';
import {ToggleIsCancelledEventBooking} from '@graphql/eventBooking/mutation';
import {refetchQueryByName} from '@utils/apollo';
import {
    bookingsFields,
    clientPackageName,
    eventFields,
    geoFields,
    productShortName,
} from '@modules/booking/utils/clientBookingsFields';
import {columnObjectAlignLeft} from '@utils/tableHelpers';
import {EVENT_BOOKING} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {getTableRowIsDisabledClassName} from '@modules/booking/utils/bookingsTable';

import {renderContentCategoryLevelColumn} from '../utils/clientBookingTable';

export const columns = {
    'event_booking_id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.event_booking_id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    ...eventFields,
    ...bookingsFields,
    'competitors': {
        label: 'Competitors',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    ...productShortName,
    'clips': {
        label: 'Clips',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    ...geoFields,
    'blackout_zones': {label: 'Blackout Zones', sorting: false, className: 'noPadding'},
    ...clientPackageName,
    'booking_type_name': {
        label: 'Type',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'event_booking_creation_datetime': {
        label: 'Time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'actions': {
        label: 'Actions',
        ...columnObjectAlignLeft,
    },
};

const ClientEventBookingsTable = (props) => {
    const {clientEventBookings, clientId, data, loading} = props;
    const history = useHistory();
    const rowRenderer = (column, row) => {
        const eventBookingGeoRestrictions = _get(row, 'event_booking_geo_restrictions', []),
            deviceCategoriesData = parseDeviceCategories(data.mappedIsoCodes, eventBookingGeoRestrictions);

        switch (column) {
            case 'event_booking_id':
            case 'event_id':
            case 'match_id':
            case 'sport_name':
            case 'content_tier_name':
            case 'product_short_name':
            case 'booking_type_name':
            case 'event_booking_creation_datetime':
            case 'clips':
                return row[column];
            case 'start_datetime':
                return (
                    <div>
                        <div>{row[column] && moment(row.start_datetime).format('YYYY-MM-DD HH:mm')}</div>
                        <div>{row.end_datetime && moment(row.end_datetime).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                );
            case 'content_category_level_1_name':
                return renderContentCategoryLevelColumn(row, 'sport', 1);
            case 'content_category_level_2_name':
                return renderContentCategoryLevelColumn(row, 'tournament_category', 2);
            case 'content_category_level_3_name':
                return renderContentCategoryLevelColumn(row, 'tournament', 3);
            case 'competitors':
                return (
                    <div>
                        <LinkWithAuthorization
                            newWindow={true}
                            resources={RESOURCES.EVENT}
                            privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                            link={{
                                name: 'events.configuration.index',
                                params: {id: convertToInt(row.event_id)},
                            }}
                        >
                            {row.competitors ? row.competitors : null}
                        </LinkWithAuthorization>
                    </div>
                );
            case 'geo_restrictions_device':
                return renderDeviceCategories(deviceCategoriesData);
            case 'geo_restrictions_country':
                return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesData, data.mappedIsoCodes);
            case 'geo_restrictions_country_count':
                return renderNumberOfCountries(deviceCategoriesData);
            case 'blackout_zones':
                return renderBlackoutZonesTableRowCell(deviceCategoriesData);
            case 'client_package_name':
                return (
                    _get(row, 'client_package_name', '') ?
                        <LinkWithAuthorization
                            newWindow={true}
                            resources={RESOURCES.CLIENT_PACKAGE}
                            privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                            link={{
                                name: 'clients.packages.edit',
                                params: {
                                    id: clientId,
                                    packagesId: convertToInt(row.client_package_id),
                                },
                            }}
                        >
                            {row.client_package_name ? row.client_package_name : '-'}
                        </LinkWithAuthorization> : '-');
            case 'actions':
                return (
                    <Authorization
                        resources={EVENT_BOOKING}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                    >
                        <div className="actions-list-icon">
                            <IconWithTooltip
                                iconProps={{
                                    size: 'large',
                                    className: '--withPointer',
                                    corner: 'top right',
                                }}
                                name='edit'
                                onClick={() => {
                                    history.push(getLink('clients.eventBookings.edit', {
                                        id: props.clientId, eventId: row.event_id, eventBookingId: row.event_booking_id,
                                    }));
                                }}
                                tooltip={'General information'}
                                popupProps={{position: 'top right'}}
                            />
                            <IconWithTooltip
                                iconProps={{
                                    size: 'large',
                                    className: '--withPointer',
                                    corner: 'top right',
                                }}
                                name='video file'
                                onClick={() => {
                                    history.push(getLink('clients.eventBookings.clips', {
                                        id: props.clientId, eventBookingId: row.event_booking_id,
                                    }));
                                }}
                                tooltip={'Clips'}
                                popupProps={{position: 'top right'}}
                            />
                            <IconWithTooltip
                                iconProps={{
                                    size: 'large',
                                    className: '--withPointer',
                                    corner: 'top right',
                                }}
                                name='globe'
                                onClick={() => {
                                    history.push(getLink('clients.eventBookings.editGeoRestrictions', {
                                        id: props.clientId, eventBookingId: row.event_booking_id
                                    }));
                                }}
                                tooltip={'Geo restrictions'}
                                popupProps={{position: 'top right'}}
                            />
                            <IconWithTooltip
                                iconProps={{
                                    size: 'large',
                                    className: '--withPointer',
                                    corner: 'top right',
                                }}
                                name='shield'
                                onClick={() => {
                                    history.push(getLink('clients.eventBookings.blackoutZones', {
                                        id: props.clientId, eventBookingId: row.event_booking_id
                                    }));
                                }}
                                tooltip={'Blackout Zones'}
                                popupProps={{position: 'top right'}}
                            />
                            <EventBookingCancellationIcon
                                bookingId={row.id}
                                isCancelled={row.is_cancelled}
                                entityName="event booking"
                                messageBoxName="clientMessage"
                                successCallback={() => {
                                    refetchQueryByName('GetClientEventBookingsData');
                                }}
                                mutationChangeCancellationStatus={ToggleIsCancelledEventBooking}
                            />
                        </div>
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Table
                className='mini'
                columns={columns}
                data={clientEventBookings}
                defaultSort={'start_datetime'}
                showLimitWarning={true}
                dataLimitWarning={10000}
                loading={loading}
                name='clientEventBookingsList'
                noDataText='No event bookings found'
                padded={false}
                rowRendererProps={{history}}
                rowRenderer={rowRenderer}
                rowClassname={getTableRowIsDisabledClassName}
            />
        </>
    );
};

ClientEventBookingsTable.propTypes = {
    clientEventBookings: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    loading: PropTypes.bool.isRequired,
    url: PropTypes.string,
};
ClientEventBookingsTable.defaultProps = {
    clientEventBookings: [],
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
};
ClientEventBookingsTable.displayName = 'ClientEventBookingsTable';

export default graphql(GetMappedIsoCodes)(ClientEventBookingsTable);
