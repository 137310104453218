import {
    get as _get,
    has as _has,
    isArray as _isArray,
    isFunction as _isFunction,
} from "lodash"
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Message} from "semantic-ui-react";

import {clearMessage, setClosedTimestamp} from "../../../actions/index";
import config from '../../../avcmpConfig';

/**
 * @ignore
 */
export class MessageBox extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        messages: PropTypes.object,
        MessageBox: PropTypes.object,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onClose: PropTypes.func,
        timezone: PropTypes.string,
    };

    static defaultProps = {
        timezone: null
    };

    dataForBox = {
        header : "",
        type : "",
        text : ""
    };

    constructor(props) {
        super(props);

        let isHidden = true;

        if (props.messages[props.name] !== undefined) {
            this.setTimeoutForBox(props);

            this.dataForBox = props.messages[props.name];
            isHidden = false;
        }

        this.state = {
            hidden: isHidden
        };

        this.hideMessageBox = this.hideMessageBox.bind(this)
    }

    componentWillReceiveProps(props) {
        if (props.messages !== undefined && props.messages[props.name] !== undefined) {
            this.setState(function() {
                return {
                    hidden: false
                }
            });

            this.dataForBox = props.messages[props.name];

            this.setTimeoutForBox(props);
        } else {
            this.setState({ hidden: true });
        }

    }

    componentWillUnmount() {
        this.hideMessageBox();
    }

    setTimeoutForBox = (props) => {
        if (!_get(props, `messages[${props.name}].stayVisible`, false)) {
            window.setTimeout(() => {
                this.hideMessageBox()
            }, config.app.notification.timeout)
        }
    };

    hideMessageBox(onDismiss = false) {
        if (this.props.messages[this.props.name] !== undefined) {
            if (_get(this.props, `messages[${this.props.name}].addClosedTimestamp`, false) && onDismiss) {
                this.props.MessageBox.setClosedTimestamp(
                    this.props.name,
                    moment.utc()
                );
            }

            this.props.MessageBox.clear(this.props.name);
            this.setState({ hidden: true });

            if (_isFunction(this.props.onClose)) {
                this.props.onClose(this.props);
            }
        }
    }

    dismissMessageBox = () => {
        this.hideMessageBox(true);
    };

    render() {
        const className = this.props.className || "",
            descriptionLines = !_isArray(this.dataForBox.text)
                ? [this.dataForBox.text]
                : this.dataForBox.text;

        return (
            <div className={`message-box-container ${className} ${this.state.hidden && '--is-hidden'}`}>
                <Message
                    size="small"
                    success={"success" === this.dataForBox.type}
                    error={"error" === this.dataForBox.type}
                    info={"info" === this.dataForBox.type}
                    hidden={ this.state.hidden }
                    onDismiss={this.dismissMessageBox}
                    warning={"warning" === this.dataForBox.type}
                >
                    <div className="content">
                        {this.dataForBox.header && <div className="header">{this.dataForBox.header}</div>}
                        {descriptionLines.map((text, index) => (text ? <p key={index}>{text}</p> : null))}
                    </div>
                </Message>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        MessageBox : {
            clear: (name) => {
                dispatch(clearMessage({name: name}))
            },
            setClosedTimestamp: (name, timestamp) => {
                dispatch(setClosedTimestamp({name: name, timestamp: timestamp}));
            },
        }
    }
};

const mapStateToProps = (state) => {
    let timezone = null;

    if (_has( state, 'timezone.timezone')) {
        timezone = _get(state, 'timezone.timezone');
    }

    return {
        messages: state.messages,
        timezone: timezone,
    }
};

const MessageBoxWithRedux = connect(mapStateToProps, mapDispatchToProps)(MessageBox);

export default MessageBoxWithRedux
