import PropTypes from 'prop-types';
import React from 'react';

import {IconEdit, Icon} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {convertToInt} from '@utils/helpers';
import {SECURITY_PRIVILEGES_READ, TABLE_DATA_LIMIT} from '@constants/variables';
import Authorization from '@appComponents/Authorization';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

const authorizationObject = {
    [RESOURCES.OVERLAY]: CONST.SECURITY_PRIVILEGES_READ,
};

const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'overlay_type': {
        label: 'Type',
        sorting: true,
    },
    'name': {
        label: 'Name',
        sorting: true,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};

const OverlayTable = (props) => {
    const {loading, propertyLicenceId, overlays} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'overlay_type.name':
            case 'name':
                return (
                    <LinkWithAuthorization
                        privileges={SECURITY_PRIVILEGES_READ}
                        link={{
                            name: 'propertyLicences.technicalSetup.overlays.edit',
                            params: {
                                id: propertyLicenceId,
                                overlayId: convertToInt(row.id),
                            },
                        }}
                        authorization={authorizationObject}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'actions': {
                return (
                    <Authorization authorization={authorizationObject}>
                        <div className='actions-list-icon'>
                            <IconEdit
                                link={getLink('propertyLicences.technicalSetup.overlays.edit', {
                                    id: propertyLicenceId,
                                    overlayId: convertToInt(row.id),
                                })}
                                size='large'
                                resource={RESOURCES.OVERLAY}
                            />
                            <Icon
                                name='external'
                                onClick={(url) => window.open(url, '_blank')}
                                onClickElement={row.url}
                                size='large'
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <Table
            name='overlayList'
            columns={columns}
            data={overlays}
            loading={loading}
            noDataText='No overlay found'
            rowRenderer={rowRenderer}
            pagination={false}
            limit={TABLE_DATA_LIMIT}
        />
    );
};

OverlayTable.propTypes = {
    propertyLicenceId: PropTypes.number.isRequired,
    overlays: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

OverlayTable.defaultProps = {
    overlays: [],
};

export default OverlayTable;
