import {GetEventGeoRestrictionForForm} from '@graphql/eventGeoRestriction/query';
import {
    UpdateEventGeoRestriction,
    DeleteEventGeoRestriction,
} from '@graphql/eventGeoRestriction/mutation';

import EventGeoRestrictionsFormGeneralInformationModel from './EventGeoRestrictionsFormGeneralInformationModel';

export default function EventGeoRestrictionsFormGeneralInformationEditModel() {
    const eventGeoRestrictionsFormGeneralInformationModel = EventGeoRestrictionsFormGeneralInformationModel();

    eventGeoRestrictionsFormGeneralInformationModel.editForm = true;
    eventGeoRestrictionsFormGeneralInformationModel.deleteId = 'eventGeoRestriction.id';
    eventGeoRestrictionsFormGeneralInformationModel.mutation = {
        updateEntity: UpdateEventGeoRestriction,
        deleteEntity: DeleteEventGeoRestriction,
    };
    eventGeoRestrictionsFormGeneralInformationModel.query.getEntity = GetEventGeoRestrictionForForm;

    return eventGeoRestrictionsFormGeneralInformationModel;
}
