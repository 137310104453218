import {changelogData} from "../changelogData";

const thirdPartyStreamNamesPushIndexResponseFragment = `
  id
  third_party_client_cdn {
    id
    name
  }
  stream_name
  description
`;

const thirdPartyStreamNamesPushFullResponseFragment = `
  ${thirdPartyStreamNamesPushIndexResponseFragment}
  ${changelogData}
`;

const thirdPartyStreamNamesPushMutationVariablesDefinitionFragment = `
  $stream_name: String!
  $description: String
  $third_party_client_cdn: Int!
`;

const thirdPartyStreamNamesPushMutationVariablesFragment = `
  stream_name: $stream_name
  description: $description
  third_party_client_cdn: $third_party_client_cdn
`;

export const thirdPartyStreamNamesPushIndex = `
query ThirdPartyStreamNamesPushIndex (
  $thirdPartyClientCdn: [Int]
) {
  thirdPartyClientStreamNamesPush (filters: {
    thirdPartyClientCdn: $thirdPartyClientCdn
  }) {
    ${thirdPartyStreamNamesPushIndexResponseFragment}
  }
}`;

export const thirdPartyStreamNamePush = `query thirdPartyStreamNamePush ($id: ID!) {
    thirdPartyClientStreamNamePush(id: $id) {
      ${thirdPartyStreamNamesPushFullResponseFragment}
    }
  }
`;

export const createThirdPartyStreamNamesPush = `
mutation CreateThirdPartyStreamNamesPush (
  ${thirdPartyStreamNamesPushMutationVariablesDefinitionFragment}
) {
  createThirdPartyClientStreamNamePush (
    ${thirdPartyStreamNamesPushMutationVariablesFragment}
  ) {
    ${thirdPartyStreamNamesPushFullResponseFragment}
  }
}
`;

export const updateThirdPartyStreamNamesPush = `
mutation UpdateThirdPartyStreamNamesPush (
  $id: ID!
  ${thirdPartyStreamNamesPushMutationVariablesDefinitionFragment}
) {
  updateThirdPartyClientStreamNamePush (
    id: $id
    ${thirdPartyStreamNamesPushMutationVariablesFragment}
  ) {
    ${thirdPartyStreamNamesPushFullResponseFragment}
  }
}
`;

export const deleteThirdPartyStreamNamesPush = `
mutation DeleteThirdPartyStreamNamesPush (
  $id: ID!
) {
  deleteThirdPartyClientStreamNamePush (
    id: $id
  )
}
`;
