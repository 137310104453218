import React from "react";
import {Button, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";
import Form from "../../app/components/ReduxFormControls";

class LiveControlAutoRefresh extends React.Component {
    static propTypes = {
        autoRefreshInterval: PropTypes.number.isRequired,
        setPollingInterval: PropTypes.func.isRequired,
        refetchLiveControl: PropTypes.func.isRequired,
        restoreFilterValues: PropTypes.func.isRequired,
    };

    state = {
        options: [
            {key: "ar-disabled", text: "Disabled", value: 0},
            {key: "ar-20s", text: "20 seconds", value: 20},
            {key: "ar-1m", text: "1 minute", value: 60},
            {key: "ar-3m", text: "3 minutes", value: 180},
            {key: "ar-5m", text: "5 minutes", value: 300},
        ]
    };

    /**
     * @param event
     * @param data
     */
    onIntervalChange = (event, data) => {
        this.props.setPollingInterval(data);
    };

    onRefreshButtonClick = () => {
        this.props.refetchLiveControl();
    };

    render() {
        return (
            <div className="LiveControlAutoRefresh filters__container">
                <label htmlFor='autorefresh_interval' className="LiveControlAutoRefresh__title">Auto-refresh:</label>
                <div className="LiveControlAutoRefresh__interval">
                    <div className="dropdown-select input-container">
                        <Form.Create>
                            <Field
                                component={Form.ReduxSelect}
                                name="autorefresh_interval"
                                className="autorefresh_interval"
                                options={this.state.options}
                                defaultValue={this.props.autoRefreshInterval}
                                onChangeValue={this.onIntervalChange}
                                setValue={this.props.autoRefreshInterval}
                                allowClear={false}
                            />
                        </Form.Create>
                    </div>
                </div>
                <div className="LiveControlAutoRefresh__refresh">
                    <Popup
                        key="manual-refresh"
                        trigger={<Button onClick={this.onRefreshButtonClick} icon='refresh'/>}
                        flowing
                        hoverable
                    >
                        Manual refresh
                    </Popup>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: 'filtersliveControlAutorefresh'
})(LiveControlAutoRefresh);
