import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import { ButtonAdd } from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link"
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const EventsBookingsButtons = (props) => {
    const {eventId} = props;

    return (
        <div className="eventBookings__indexButtons content__buttons">
            <Authorization resources={RESOURCES.BOOKING} privileges={CONST.SECURITY_PRIVILEGES_CREATE}>
                <Link name="events.bookings.add" params={{id: eventId}}>
                    <ButtonAdd>Add new booking</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

EventsBookingsButtons.propTypes = {
    eventId: PropTypes.number.isRequired,
};

export default EventsBookingsButtons;
