import {find as _find, has as _has} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint import/no-unresolved: 0 */
import {gql, graphql} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Header} from "semantic-ui-react";

import {renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {isUrlParamValid} from '@utils/helpers';

import ContactPersonsTable from "../components/ContactPersonsTable";
import ContactPersonForm from "../forms/ContactPersonForm";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import {getLink} from "../../app/components/Link";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {CONTACT_PERSON_INDEX_BUTTONS_ADD} from "../constants/messages";
import {routes} from "../../app/constants/routes";
import * as CONSTANT from "../../app/constants/variables";

import * as contactPersonGraphQl from "../../../graphql/clients/contactPerson"

export class ContactPersonsIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientId: PropTypes.number.isRequired,
        DataContactPersons: PropTypes.object.isRequired,
        DataContactPersonTypes: PropTypes.object.isRequired,
        DeleteContactPerson: PropTypes.func,
        history: PropTypes.object,
        match: PropTypes.object,
        Modal: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            contactPersons: undefined,
            contactPersonTypes: undefined
        };
    }

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            DataContactPersons: {contactPersons},
            DataContactPersonTypes: {contactPersonTypes}
        } = nextProps;

        let nextState = {};

        if (contactPersonTypes && !this.state.contactPersonTypes) {
            nextState.contactPersonTypes = contactPersonTypes.map((contentPersonType) => ({
                key: contentPersonType.value,
                text: contentPersonType.text,
                value: contentPersonType.value
            }));
        }

        if (contactPersons) {
            nextState.contactPersons = contactPersons;

            const contactPersonsRoutes = routes.clients.contactPersons;

            if (nextProps.match.path === contactPersonsRoutes.add.path) {
                this.openModal({});
            } else if (nextProps.match.path === contactPersonsRoutes.edit.path) {
                const contactPerson = contactPersons.filter(
                    item => parseInt(item.id) === parseInt(nextProps.match.params.contactPersonId)
                ).pop();

                this.openModal(contactPerson);
            }
        }

        this.setState(() => (nextState));
    }

    resetIndexUrl = () => {
        window.history.pushState({}, '', getLink('clients.contactPersons.index', {id: this.props.clientId}));
    };

    openModal = (id) => {
        const {
            Modal: {setModal}
        } = this.props;

        let data,
            modalLink,
            error = false;

        if (!isNaN(id)) {
            data = _find(this.state.contactPersons, {id: id});
            modalLink = getLink('clients.contactPersons.edit', {
                contactPersonId: id,
                id: this.props.clientId
            });
        } else if (_has(id, 'id')
            && isUrlParamValid(this.props.match.params.id)
            && isUrlParamValid(this.props.match.params.contactPersonId)) {

            data = id;
            modalLink = getLink('clients.contactPersons.edit', {
                contactPersonId: id.id,
                id: this.props.clientId
            });
        } else if (!this.props.match.params.contactPersonId
            && isUrlParamValid(this.props.match.params.id)) {
            data = {};
            modalLink = getLink('clients.contactPersons.add', {id: this.props.clientId});
        } else if (!isUrlParamValid(this.props.match.params.id)) {
            return;
        } else {
            modalLink = getLink('clients.contactPersons.edit', {
                contactPersonId: this.props.match.params.contactPersonId,
                id: this.props.clientId
            });
            error =  true;
        }

        window.history.pushState({}, '', modalLink);

        const content= (error ?
            renderModalError('Contact person', getLink('clients.contactPersons.index', {id: this.props.clientId})) :
            <ContactPersonForm
                clientId={this.props.clientId}
                formData={{contactPerson: data, contactPersonTypes: this.state.contactPersonTypes}}
                resetIndexUrl={this.resetIndexUrl}
            />
        );

        setModal({
            content: content,
            isVisible: true,
            onClose: this.resetIndexUrl
        })
    };

    render() {
        return (
            <div>
                <Header>
                    <div className="indexButtons">
                        <ButtonAdd onClick={this.openModal}>{CONTACT_PERSON_INDEX_BUTTONS_ADD}</ButtonAdd>
                    </div>
                </Header>
                <ContactPersonsTable
                    contactPersons={this.state.contactPersons}
                    editClick={this.openModal}
                    loading={this.props.DataContactPersons.loading}
                />
            </div>
        );
    }
}

const ContactPersonsQuery = gql(contactPersonGraphQl.contactPersonIndex);
const ContactPersonTypesQuery = gql(contactPersonGraphQl.contactPersonTypes);

const ContactPersonsWithGraphQL = compose(
    graphql(ContactPersonsQuery, {
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    clientId: props.clientId
                },
            }
        },
        name: "DataContactPersons"
    }),
    graphql(ContactPersonTypesQuery, {
        options: () => {
            return {
                fetchPolicy: "cache-and-network",
                notifyOnNetworkStatusChange: true,
                variables: {
                    id: [
                        CONSTANT.CONTACT_PERSON_TYPE_CLIENT_CONTACT,
                        CONSTANT.CONTACT_PERSON_TYPE_CLIENT_KEY_ACCOUNT_MANAGER,
                        CONSTANT.CONTACT_PERSON_TYPE_CLIENT_SALES_REPRESENTATIVE
                    ]
                }
            }
        },
        name: "DataContactPersonTypes"
    })
)(ContactPersonsIndex);

const mapStateToProps = (state) => {
    return {
        client: state.app.entities.client
    }
};
const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal']);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactPersonsWithGraphQL));
