import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React from 'react';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Dimmer, Loader, Segment} from 'semantic-ui-react';
import {
    get as _get, some as _some, isEmpty as _isEmpty
} from 'lodash';

import {gql, graphql, withApollo} from 'react-apollo';
import SegmentHeader from '@appComponents/SegmentHeader';
import mapModulesToProps from '@utils/mapModulesToProps';
import {createForm, renderModalError} from '@utils/forms';
import {eventIncidentsQuery} from '@graphql/events/eventsReport';
import {GetEventContents} from '@graphql/events/query';
import {EVENT_INCIDENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';
import {routes} from '@constants/routes';
import {getLink} from '@appComponents/Link';
import ButtonAdd from '@appComponents/ButtonsCollection/ButtonAdd';
import {Authorization} from '@appComponents/Authorization';
import {isUrlParamValid} from '@utils/helpers';
import {showModal} from '@utils/modal';

import EventsIncidentEditModel from '../forms/EventsIncidentEditModel';
import EventsIncidentEditForm from '../forms/EventsIncidentEditForm';
import EventsIncidentModel from '../forms/EventsIncidentModel';
import EventsIncidentForm from '../forms/EventsIncidentForm';
import EventsReportModel from '../forms/EventsReportModel';
import EventsReportForm from '../forms/EventsReportForm';
import EventIncidentsTable from '../components/EventIncidentsTable';
import {convertToInt} from "../../../utils/helpers";

const authorizationObject = {
    [EVENT_INCIDENT]: SECURITY_PRIVILEGES_CREATE,
};

class EventsReport extends React.Component {
    static propTypes = {
        DataEventIncidents: PropTypes.object.isRequired,
        DataEventContent: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        modal: PropTypes.object,
        history: PropTypes.object,
        eventContents: PropTypes.object,
    };

    Form = null;

    constructor() {
        super();

        this.state = {
            eventIncidents: [],
            eventContents: [],
            shouldOpenModal: true,
            loading: true,
        };
    }

    loadEventsIncidentFormModal(urlObject) {
        this.setState(() => ({
            shouldOpenModal: false,
        }));

        let Form = null;

        if (urlObject.path === routes.events.incidents.add.path) {
            Form = createForm(
                EventsIncidentModel,
                EventsIncidentForm,
                {
                    optionsVariables: {
                        eventId: parseInt(urlObject.params.id, 10),
                    },
                }
            );
        }

        if (urlObject.path === routes.events.incidents.edit.path) {
            Form = createForm(
                EventsIncidentEditModel,
                EventsIncidentEditForm,
                {

                    id: urlObject.params.incidentId,
                    optionsVariables: {
                        eventId: parseInt(urlObject.params.id, 10),
                    },
                }
            );
        }

        Form && this.props.Modal.setModal({
            header: false,
            isVisible: true,
            content: <Form/>,
            size: 'small',
        });
    }

    componentDidMount() {
        if (this.props.match.path === routes.events.incidents.add.path
            || this.props.match.path === routes.events.incidents.edit.path) {
            this.loadEventsIncidentFormModal(this.props.match);
        }

        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }

        if (!isUrlParamValid(this.props.match.params.incidentId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Incident', getLink(`events.report`, {
                    id: this.props.match.params.id,
                })),
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            DataEventIncidents: { eventIncidents },
            DataEventContent: { eventContents },
            modal,
            match,
            history,
        } = this.props;

        const urlChanged = prevProps.match.path !== match.path;
        const urlIsNotIndex = match.path !== routes.events.report.path;
        const shouldOpenModal = urlChanged && urlIsNotIndex;

        shouldOpenModal && this.loadEventsIncidentFormModal(match);

        const modalChanged = modal.isVisible !== prevProps.modal.isVisible;
        const modalIsNotVisible = !modal.isVisible;
        const modalWasClosed = modalChanged && modalIsNotVisible;

        if (modalWasClosed) {
            history.push(getLink('events.report', { id: match.params.id }));
        }

        if (eventIncidents && eventIncidents !== this.state.eventIncidents) {
            this.setState({ eventIncidents });
        }

        if (eventContents && eventContents !== this.state.eventContents) {
            this.setState({ eventContents });
        }

        if (eventContents !== prevProps.DataEventContent.eventContents) {
            this.setState({
                loading: false,
            });
        }
    }

    renderForm = () => {
        if (this.Form) {
            return this.Form;
        }

        let Form = createForm(
            EventsReportModel,
            EventsReportForm, {
                dataRequest: true,
                optionsVariables: {event: parseInt(this.props.match.params.id, 10)},
                id: parseInt(this.props.match.params.id, 10),
            }
        );

        this.Form = <Form/>;

        return this.Form;
    };

    hasDistributions = eventContents => _some(eventContents, ec => _get(ec, 'distributions.length', 0) > 0);

    render() {

        if (this.state.loading) {
            return (
                <Dimmer inverted active>
                    <Loader/>
                </Dimmer>
            );
        }

        if (_isEmpty(this.props.DataEventContent) || !this.hasDistributions(this.state.eventContents)) {
            return <div>The report is not available because the event has no event contents with distributions.</div>;
        }

        return (
            <div className='eventReport'>
                <SegmentHeader
                    buttons={(
                        <Authorization
                            authorization={authorizationObject}
                        >
                            <ButtonAdd
                                label={'Add new incident'}
                                link={{
                                    name: 'events.incidents.add',
                                    params: {
                                        id: this.props.match.params.id,
                                    },
                                }}
                            />
                        </Authorization>
                    )}
                    dividing={true}
                >
                    Incidents
                </SegmentHeader>
                <Segment basic className='--table'>
                    <EventIncidentsTable
                        eventId={parseInt(this.props.match.params.id, 10)}
                        eventIncidents={this.state.eventIncidents}
                        loading={_get(this.props, 'DataEventIncidents.loading', false)}
                    />
                </Segment>
                {this.renderForm()}
            </div>
        );
    }
}

const EventsReportWithGraphQL = compose(
    graphql(gql(eventIncidentsQuery), {
        options: (props) => {
            return {
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: {
                    event: parseInt(props.match.params.id, 10),
                },
            };
        },
        name: 'DataEventIncidents',
    }),
    graphql(GetEventContents, {
        options : (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy : 'network-only',
                variables: {
                    event: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataEventContent',
    }),
)(EventsReport);

const mapDispatchToProps = mapModulesToProps(['Modal']);
const masStateToProps = (state) => ({
    modal: state.modal,
});

export default withRouter(connect(masStateToProps, mapDispatchToProps)(withApollo(EventsReportWithGraphQL)));
