import PropTypes from "prop-types";
import moment from "moment";

import {renderTournamentsAndCategories} from "../../components/ClientMasterBookingsTable";

import {getBookingUserInformationForExcelExport} from "../../../events/forms/helpers/booking";
import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions
} from "../../../../utils/countryHelpers";

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_master_bookings`,
    parseData: clientMasterBooking => [
        clientMasterBooking.master_booking_id,
        clientMasterBooking.event_id,
        clientMasterBooking.master_match_id,
        (
            (null === clientMasterBooking.start_datetime || undefined === clientMasterBooking.start_datetime)
                ? ""
                : moment(clientMasterBooking.start_datetime, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
        )
        +
        (
            (null === clientMasterBooking.end_datetime || undefined === clientMasterBooking.end_datetime)
                ? ""
                : ` - ${moment(clientMasterBooking.end_datetime, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')}`
        ),
        clientMasterBooking.sport_name,
        renderTournamentsAndCategories(clientMasterBooking.tournament_category_names, true),
        renderTournamentsAndCategories(clientMasterBooking.tournament_names, true),
        clientMasterBooking.event_description,
        clientMasterBooking.product_short_name,
        getListOfDeviceCategoriesForGeoRestrictions(clientMasterBooking.master_booking_geo_restrictions).join(', '),
        getListOfCodesForDeviceCategories(clientMasterBooking.master_booking_geo_restrictions),
        getListOfCountryCodesForDeviceCategories(clientMasterBooking.master_booking_geo_restrictions).length.toString(),
        clientMasterBooking.client_package ? clientMasterBooking.client_package.name : '',
        clientMasterBooking.booking_type_name,
        clientMasterBooking.master_booking_creation_datetime,
        getBookingUserInformationForExcelExport(clientMasterBooking.booked_by),
    ],
    path: 'clients.masterBookings.index.path',
    titles: [
        "ID",
        "Event ID",
        "Master match ID",
        "Date/Time",
        "Sport",
        "Categories",
        "Tournaments",
        "Description",
        "Product",
        "Device categories",
        "Countries",
        "#Countries",
        "Package",
        "Type",
        "Time",
        "Booked by",
    ]
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired
}
