import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc';

import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {
    getBasicEventBookingsColumnsForBookingsTable} from '@modules/client/utils/clientBookingTable';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';
import {formattedPriceOrEmptyValue} from '@utils/helpers';
import {EMPTY_VALUE_DASH} from '@constants/variables';
import {columnObjectAlignCenter} from '@utils/tableHelpers';
import {IconCheckmark} from '@appComponents/IconCollection';

const columns = {
    ...getBasicEventBookingsColumnsForBookingsTable('offering'),
    'is_hq': {
        label: 'HQ',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'price': {
        label: 'Price',
        sorting: true,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        },
    },
    'booking_status': {
        label: 'Booking status',
        sorting: row => row.booking_status,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'time': {
        label: 'Time',
        sorting: row => row.creation_datetime,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const EventsOfferingsTable = (props) => {
    const {data, eventOfferings, loading} = props;

    const renderClientPackage = (row) => {
        if(row.client_package){
            return (
                <LinkWithAuthorization
                    resources={RESOURCES.CLIENT_PACKAGE}
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    link={{
                        name: 'clients.packages.edit',
                        params: {id : row.client.id, packagesId: row.client_package.id},
                    }}
                >
                    {row.client_package.name}
                </LinkWithAuthorization>
            );
        }

        return '-';
    };

    const rowRenderer = (column, row) => {
        const offeringGeoRestrictions = _get(row, 'offering_geo_restrictions', []),
            deviceCategoriesCountries = parseDeviceCategories(data.mappedIsoCodes, offeringGeoRestrictions);

        switch (column) {
            case 'offeringId':
                return row.id;
            case 'countries':
                return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesCountries, data.mappedIsoCodes);
            case 'numberOfCountries':
                return renderNumberOfCountries(deviceCategoriesCountries);
            case 'blackoutZones':
                return renderBlackoutZonesTableRowCell(deviceCategoriesCountries);
            case 'deviceCategories':
                return renderDeviceCategories(deviceCategoriesCountries);
            case 'client':
                return (
                    <LinkWithAuthorization
                        resources={RESOURCES.CLIENT}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'clients.edit',
                            params: {id : row.client.id},
                        }}
                    >
                        {row.client.name}
                    </LinkWithAuthorization>
                );
            case 'is_hq':
                return row.is_hq ? <IconCheckmark /> : null;
            case 'price':
                return formattedPriceOrEmptyValue(row.price, EMPTY_VALUE_DASH);
            case 'time':
                return row.creation_datetime;
            case 'distributionType':
                return row.distribution.distribution_type.name;
            case 'content':
                return <EventContentVariantPopupWithName eventContent={row.distribution.event_content}/>
            case 'client_package':
                return renderClientPackage(row);
            case 'product':
                return row.distribution.product.short_name;
            default:
                return null;
        }
    };

    return (
        <div className='eventOfferings__table'>
            <Table
                className='mini'
                name='eventOfferingsList'
                columns={columns}
                data={eventOfferings}
                loading={loading}
                pagination={false}
                noDataText='No offerings found'
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

EventsOfferingsTable.propTypes = {
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    eventOfferings: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};
EventsOfferingsTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
    eventOfferings: [],
};
EventsOfferingsTable.displayName = 'EventsOfferingsTable';

export default graphql(GetMappedIsoCodes)(EventsOfferingsTable);
