import React from 'react';
import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {createForm, getFormMessageForWizard} from '@utils/forms';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {ClientProductsForEventEventBooking} from '@graphql/product/query';
import EventBookingClipsModel from '@modules/eventBooking/forms/EventBookingClipsModel';
import EventBookingClipsForm from '@modules/eventBooking/forms/EventBookingClipsForm';
import {onCloseModal, populateClientPackages} from '@modules/eventBooking/forms/helpers/helpers';

export class EventBookingGeneralInformationAddForm extends DefaultForm {
    prepareDataForSubmit = () => {
        const preparedData = Object.assign(
            {},
            this.props.stepsMethods.getForms()['step1'].formValues
        );

        return Object.assign({}, {
            eventId: this.props.formParams.optionsVariables.eventId,
            product: convertToInt(preparedData.product),
            client: convertToInt(preparedData.client),
            client_package: this.props.formValues.client_package ? this.props.formValues.client_package : null,
            auto_update_geo_restrictions: preparedData.auto_update_geo_restrictions || false,
        });
    };

    onFormSubmit = () => {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', ''))
                    .toLocaleLowerCase()
            ));
        }

        const setMutationSuccessCallback = this.props.formParams.setMutationSuccessCallback;
        const generalInformationFormData = this.prepareDataForSubmit();
        const ClipsForm = createForm(
            EventBookingClipsModel,
            EventBookingClipsForm,
            {
                setMutationSuccessCallback,
                optionsVariables: {
                    event: generalInformationFormData.eventId,
                    product: convertToInt(generalInformationFormData.product),
                },
            });
        this.props.stepsMethods.setStep({
            id: 'step2',
            menuItem: {disabled: false},
            pane: {
                content: <ClipsForm previousStepData={generalInformationFormData}/>,
            },
        });

        this.props.stepsMethods.showStep('step2');
    };

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    componentDidMount() {
        this.setOnChangeCallback({
            client: (data) => {
                this.setProducts(data.value);
            },
            product: (data) => {
                this.setClientPackages(this.props.formValues.client, data.value);
            },
            auto_update_geo_restrictions: (data) => {
                this.setField('auto_update_geo_restrictions', {
                    value: !data.value,
                    defaultValue: !data.value,
                });
            },
        });

        this.setField('auto_update_geo_restrictions', {
            defaultValue: true,
        });
    }

    componentDidUpdate(prevProps) {
        this.props.stepsMethods.setForm('step1', prevProps);
    }

    setClientPackages = (clientId, productId) => {
        if (_isEmpty(clientId) || _isEmpty(productId)) {
            this.setField('client_package', {
                defaultValue: null,
                disabled: true,
            });

            return;
        }

        populateClientPackages(this.setField, this.runApolloRequest, clientId, productId);
    };

    setProducts = (clientId) => {
        this.setField('product', {
            defaultValue: null,
            options: null,
            disabled: true,
            loading: true,
        });

        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: ClientProductsForEventEventBooking,
            variables: {
                client: convertToInt(clientId),
                event: this.props.formParams.optionsVariables.eventId,
            },
        }).then((response) => {
            this.setField('product', {
                disabled: false,
                loading: false,
                options: response.data.productsForEventBookings,
            });
        }).catch((response) => {
            this.props.MessageBox.addMessage(
                'formInnerErrorMessage',
                'Can\'t get products',
                response.message,
                'error'
            );
        });
    };

    onCancel = () => {
        onCloseModal(
            this.props.formParams.optionsVariables.event,
            this.props.formParams.optionsVariables.client,
            this.props.Modal.setModal,
            this.props
        );
    };

    renderCancelButton = (props) => super.renderCancelButton({
        ...props,
        type: 'button',
        onClick: this.onCancel,
    });

    renderSaveButton = (props) => {
        return super.renderSaveButton({
            ...props,
            content: 'Next',
            icon: 'angle right',
            labelPosition: 'right',
        });
    };
}

EventBookingGeneralInformationAddForm.propTypes = {
    Modal: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default withRouter(EventBookingGeneralInformationAddForm);
