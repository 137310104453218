import PropTypes from 'prop-types';
import React from 'react';

import Authorization from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import {MATCH_SELECTION_PERIOD} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

const MatchSelectionPeriodIndexButtons = (props) => (
    <div className='content__buttons'>
        <Authorization authorization={{[MATCH_SELECTION_PERIOD]: SECURITY_PRIVILEGES_CREATE}}>
            <Link name={'propertyLicences.matchSelectionPeriods.add'} params={{id: props.propertyLicenceId}}>
                <ButtonAdd>{'Add new match selection period'}</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

MatchSelectionPeriodIndexButtons.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
};

export default MatchSelectionPeriodIndexButtons;
