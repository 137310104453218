import validators from '../../../utils/validators';

const validateBulkChange = (values) => {
    const errors = {};

    if (values.price) {
        Object.assign(errors, validators.floatAndNumbersWithFixedDecimal({value: values.price}, 'price'));
    }

    return errors;
};

export default validateBulkChange;
