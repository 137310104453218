import ToggleListWithDropdown from "./ToggleListWithDropdown";

import PreSectionMessageBox from "../../views/ThirdPartyClients/PreSectionMessageBox";
import FormHeader from "../../views/ThirdPartyClients/FormHeader";

import * as MESSAGES from "../../../app/constants/messages";
import {ENCODING_TARGET} from "../../../app/constants/resources";

import validate from "../validatorThirdPartyClients";

import {GetThirdPartyClientsForOptions} from "../../../../graphql/clientProductThirdParty/query.graphql"
import {GetThirdPartyClientsForForm} from "../../../../graphql/eventContentsThirdParty/query.graphql"
import {SaveThirdPartyClientConfiguration} from "../../../../graphql/thirdPartyClients/mutation.graphql"

export default function ThirdPartyClientsModel() {
    return {
        formName: "formThirdPartyClient",
        formClassName: "thirdPartyClientForm",
        title: () => ("Third party clients configuration"),
        resources: ENCODING_TARGET,
        validate: validate,
        messages: {
            boxName: {
                success: 'event',
                error: 'event'
            },
            text: {
                ...MESSAGES,
                SAVED: () => MESSAGES.SAVED('third party clients configuration'),
                NOT_SAVED: () => MESSAGES.NOT_SAVED('third party clients configuration')
            }
        },
        query: {
            getOptions: GetThirdPartyClientsForOptions,
            getEntity: GetThirdPartyClientsForForm
        },
        mutation: {
            createEntity: SaveThirdPartyClientConfiguration,
            updateEntity: SaveThirdPartyClientConfiguration
        },
        mutationOptions: {
            create: {
                refetchQueries: [
                    'GetEncodingTargetsForDistributions',
                    'GetThirdPartyClientsForList',
                    'GetEventContentEncodingConfigurationChanged',
                ],
            },
            update: {
                refetchQueries: [
                    'GetEncodingTargetsForDistributions',
                    'GetThirdPartyClientsForList',
                    'GetEventContentEncodingConfigurationChanged',
                ],
            },
        },
        fields: {
            preSectionMessageBox: {
                props: {
                    name: "preSectionMessageBox",
                    hidden: true,
                    component: () => PreSectionMessageBox(),
                }
            },
            pushHeader: {
                props: {
                    name: "pushHeader",
                    text: "Push",
                    component: (props) => FormHeader(props),
                }
            },
            pushClients: {
                props: {
                    name: "pushClients",
                    streamType: "push",
                    component: ToggleListWithDropdown,
                }
            },
            pullHeader: {
                props: {
                    name: "pullHeader",
                    text: "Pull",
                    component: (props) => FormHeader(props),
                }
            },
            pullClients: {
                props: {
                    name: "pullClients",
                    streamType: "pull",
                    component: ToggleListWithDropdown,
                }
            },
        }
    }
}
