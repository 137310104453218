import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { ORIGIN_STREAM_TYPE_SRT } from '@constants/variables';

export const SrtFailoverOriginEntryPointNotDefined = ({row = {}}) => {
    if (row.event_content_input_id
        && ORIGIN_STREAM_TYPE_SRT === row.origin_stream_type_id
        && !_isEmpty(row.srt_origin_entry_point_url)
        && _isEmpty(row.failover_srt_origin_entry_point_url)
    ) {
        return (
            <Popup
                trigger={
                    <Icon color='red' name='warning sign' className='--height-100-percent --marginLeft-0-5-em' />
                }
                flowing
                content='This SRT origin entry point has no failover defined!'
                hoverable
            />
        );
    }

    return null;
};

SrtFailoverOriginEntryPointNotDefined.propTypes = {
    row: PropTypes.object.isRequired,
};
