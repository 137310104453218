export function getSuffixWithNumber(number) {
    let suffix = "th",
        lastDigit = number.toString().slice(-1);

    if (number > 10 && number < 14) {
        return number+suffix;
    }

    switch (lastDigit) {
        case "1":
            suffix = "st";
            break;
        case "2":
            suffix = "nd";
            break;
        case "3":
            suffix = "rd";
            break;
    }

    return number+suffix;
}