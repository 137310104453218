import PropTypes from 'prop-types';
import {get as _get} from "lodash";

import {addHasMatchIdToStructure} from "../../../../utils/eventObject";
import {convertToInt} from "../../../../utils/helpers";
import {getCompetitorsNameFromEvent} from "../../../../utils/competitorName";

import {COMPETITOR_NOT_DEFINED_BOTH} from "../../../app/constants/variables";

/**
 * Get header title parameters to render event title
 *
 * @param {Object} event
 *
 * @returns {{
 *          competitorsIds:
 *              (
 *                  null|
 *                  {
 *                      homeCompetitorId: (null|number)
 *                      awayCompetitorId: (null|number)
 *                  }
 *              )
 *          title: (string)
 * }}
 */
export const getHeaderTitleParameters = (event) => {
    const headerParams = {
        competitorsIds: null,
        title: null
    };

    // For master events description is mandatory
    if (event.is_master) {
        headerParams['title'] = event.description;

        return headerParams;
    }

    /**
     * Add "_hasMatchID" for title and competitors ids logic
     * @type {Object}
     */
    const eventDataWithMatchIdFlag = addHasMatchIdToStructure(event);

    headerParams['competitorsIds'] = getComepetitorsIds(eventDataWithMatchIdFlag);
    headerParams['title'] = getHeaderTitleForNonMasterEvent(eventDataWithMatchIdFlag);

    return headerParams;
};

getHeaderTitleParameters.propTypes = {
    event: PropTypes.object.isRequired
};

/**
 * Get header title for non master event
 *
 * @param {Object} eventDataWithMatchIdFlag Event with "_hasMatchID" (boolean) flag
 *
 * @returns {string}
 */
const getHeaderTitleForNonMasterEvent = (eventDataWithMatchIdFlag) => {
    const competitorsTitle = getCompetitorsNameFromEvent(eventDataWithMatchIdFlag);

    /**
     * If both the home competitor and the away competitor are undefined
     */
    if (COMPETITOR_NOT_DEFINED_BOTH === competitorsTitle) {
        // and if there is a description, then display the description
        if (eventDataWithMatchIdFlag.description) {
            return eventDataWithMatchIdFlag.description;
        }

        return COMPETITOR_NOT_DEFINED_BOTH;
    }

    return competitorsTitle;
};

getHeaderTitleForNonMasterEvent.propTypes = {
    eventDataWithMatchIdFlag: PropTypes.object.isRequired
};

/**
 * Get competitors id to display flags near competitors
 *
 * @param {Object} eventWithMatchFlag
 *
 * @returns {{awayCompetitorId: (null|number), homeCompetitorId: (null|number)}}
 */
const getComepetitorsIds = (eventWithMatchFlag) => {
    let homeCompetitorId = null,
        awayCompetitorId = null;

    if (eventWithMatchFlag._hasMatchID) {
        homeCompetitorId = _get(eventWithMatchFlag, 'sr_event.match.home_competitor.id', null);
        awayCompetitorId = _get(eventWithMatchFlag, 'sr_event.match.away_competitor.id', null);
    } else {
        homeCompetitorId = _get(eventWithMatchFlag, 'sr_event.home_competitor.id', null);
        awayCompetitorId = _get(eventWithMatchFlag, 'sr_event.away_competitor.id', null);
    }

    return {
        homeCompetitorId: homeCompetitorId ? convertToInt(homeCompetitorId) : null,
        awayCompetitorId: awayCompetitorId ? convertToInt(awayCompetitorId) : null
    }
};

getComepetitorsIds.propTypes = {
    eventWithMatchFlag: PropTypes.object.isRequired
}
