import {gql} from '@apollo/client';
import {find as _find, get as _get, sortBy as _sortBy, uniq as _uniq,} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {
    getMasterEvents as GraphQLgetMasterEvents,
    getNonSportradarMasterEvents as GraphQLgetNonSportradarMasterEvents,
    getPresetList as GraphQLPresetList,
} from '@graphql/events/events';
import {rightsScopesDates as GraphQLRightsScopes} from '@graphql/propertyLicences/rightsScopes';
import {GetStadiumsByTournaments} from '@graphql/stadium/query';
import {setErrorModal} from '@utils/modal';
import {transformMomentDatetimeToDate} from '@utils/date';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {COLOR_DEFAULT, EVENT_NON_SPORTRADAR_TYPE, EVENT_SPORTRADAR_TYPE,} from '@constants/variables';

import {SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID} from './SportradarExistingEventByMatchModel';
import {renderModalErrorObject, setCanDeleteState} from '../utils/eventContentHelper';
import RightScope from './logic/RightScope';
import {CANT_DELETE_EVENT} from '../constants/messages';

class EventForm extends DefaultForm {
    addPresetDataForSubmit = (dataToSave, formData = {}) => {
        dataToSave.preset = {};

        if (!formData.preset_save) {
            return dataToSave;
        }

        const preset = {
            name: formData.preset_name,
        };

        if (formData.preset_id) {
            preset.id = convertToInt(formData.preset_id);
        }

        dataToSave.preset = preset;

        return dataToSave;
    };

    backToEventPage = () => {
        this.props.history.push(getLink("events.configuration.index", {id: this.props.formParams.id}));
    };

    getPresetsForPropertyLicence = (propertyLicenceId = null, eventId = null) => {
        this.setField("event_preset", {
            loading: true,
            defaultValue: null
        });

        let presetsListObject = {};

        this.runApolloRequest('query', {
            fetchPolicy: "network-only",
            query: gql(GraphQLPresetList),
            variables: {
                propertyLicence: propertyLicenceId && [convertToInt(propertyLicenceId)],
                excludeEvent: eventId && parseInt(eventId, 10)
            }
        }).then((response) => {
            let optionsForDropdown = [],
                presetsObject = {};

            let usedPreset = _get(this.state, `defaultForm_data.event.event_preset.id`, null);

            response.data.eventPresets.forEach((preset) => {
                presetsListObject[preset.id] = preset;

                optionsForDropdown.push({
                    key: preset.id,
                    value: preset.id,
                    text: preset.name + (usedPreset === preset.id ? " (last applied preset)" : "")
                });

                presetsObject[preset.id] = preset.event;

            });

            this.setState(() => ({
                presetsListObject
            }));

            this.setField("event_preset", {
                disabled: false,
                loading: false,
                options: optionsForDropdown
            });
        });
    };

    getContentCategoriesOptionsForDropdown = (propertyLicenceId, contentCategories, rightsScopes) => {
        let categoriesWithRightScopes = [],
            contentCategoriesArray = _get(contentCategories, `${propertyLicenceId}`, []);

        if (rightsScopes.all) {
            categoriesWithRightScopes = contentCategoriesArray;

            return categoriesWithRightScopes
        }

        contentCategoriesArray.forEach((item) => {
            if (rightsScopes[parseInt(item.id)]) {
                categoriesWithRightScopes.push(item)
            }
        });

        return categoriesWithRightScopes
    };

    setContentCategoryOptions = (propertyLicenceId, options = this.state.contentCategoriesOptions) => {
        this.setField("content_category", {
            disabled: true,
            loading: true,
            defaultValue: null
        });

        this.getRightsScopes(propertyLicenceId).then((response) => {
            if (!propertyLicenceId) {
                return []
            }

            this.setField("content_category", {
                disabled: false,
                loading: false,
                options: this.getContentCategoriesOptionsForDropdown(propertyLicenceId, options, response)
            });
        });
    };

    setCompetitorField = (competitor, eventType = EVENT_SPORTRADAR_TYPE) => {
        let eventKey = "sr_event";

        if (eventType === EVENT_NON_SPORTRADAR_TYPE) {
            eventKey = "av_event"
        }

        const competitorObject = _get(this.state.defaultForm_data, `event.${eventKey}.${competitor}`, null);

        competitorObject && this.setField(`${competitor}`, {
            options: [{
                key: competitorObject.id,
                text: `${competitorObject.name} (${competitorObject.id})`,
                value: competitorObject.id,
            }]
        });
    };

    getStadiumsByTournamentId = (tournamentId) => (
        this.runApolloRequest('query', {
            fetchPolicy: "network-and-cache",
            query: GetStadiumsByTournaments,
            variables: {
                tournaments: [convertToInt(tournamentId)],
            },
        })
    );

    getStadiumsByTournamentData = (tournamentId) => (
        this.getStadiumsByTournamentId(tournamentId).then((response) => (
            sortDropdownOptionsAlphanumerically(response.data.stadiums.map(stadium => ({
                key: stadium.id,
                text: `${stadium.name}${stadium.city ? ` - ${stadium.city.name}` : ""} (${stadium.id})`,
                value: stadium.id,
            })))
        )).catch(() => {
            this.props.MessageBox.addMessage("formInnerErrorMessage", null, "Can't get stadiums data.", "error");
        })
    );

    getMasterEventsByTournament = (tournamentId) => {
        return this.runApolloRequest('query', {
            fetchPolicy: "cache-first",
            query: gql(GraphQLgetMasterEvents),
            variables: {
                tournamentId: parseInt(tournamentId, 10)
            }
        }).then((response) => {
            return response.data.events
        }).catch(() => {
            this.props.MessageBox
                .addMessage("formInnerErrorMessage",
                    null, "Can't load master events.", "error");
        });
    };

    getMasterEventsByContentCategory = (contentCategoryId) => {
        return this.runApolloRequest('query', {
            fetchPolicy: "cache-first",
            query: gql(GraphQLgetNonSportradarMasterEvents),
            variables: {
                contentCategory: parseInt(contentCategoryId, 10)
            }
        }).then((response) => {
            return response.data.events
        }).catch(() => {
            this.props.MessageBox
                .addMessage("formInnerErrorMessage",
                    null, "Can't load master events.", "error");
        });
    };

    getRightsScopes = (propertyLicenceId) => {
        return this.runApolloRequest('query', {
            fetchPolicy: "network-only",
            query: gql(GraphQLRightsScopes),
            variables: {
                propertyLicenceId: parseInt(propertyLicenceId, 10)
            }
        }).then((response) => {
            return this.setRightScopes(response.data.rightsScopes);
        }).catch(() => {
            this.setFormMessage("Can't get right scopes for selected property licence.");
        });
    };

    setRightScopes(data, key) {
        let rightsScopes = RightScope.perapreRightScopes(data, key);

        this.setState(() => ({
            rightsScopes: rightsScopes
        }));

        return rightsScopes
    }

    filterEndDatetime = (date) => {
        date = transformMomentDatetimeToDate(date);

        let startDatetime = _get(this.props, "formValues.start_datetime", null);

        if (startDatetime) {
            startDatetime = startDatetime.substring(0,10);
        }

        return date.isSameOrAfter(startDatetime);
    };

    getProductsForCoverage = (propertyLicenceId) => {
        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: gql(GraphQLRightsScopes),
            variables: {
                propertyLicenceId: parseInt(propertyLicenceId, 10),
            },
        }).then(({data}) => {
            const productsByPropertyLicence = (data.rightsScopes).flatMap(({usages, id, start_date, end_date, contents}) => {
                let contentsId;

                //non sportradar event
                if (this.props.Model.fields.content_category_id) {
                    contentsId = (contents.map(({content_category}) => {
                        return {
                            contentCategoryId: content_category.id,
                        };
                    }));
                }

                //sportradar event
                if (this.props.Model.fields.tournament) {
                    contentsId = (contents.map(({tournament}) => {
                        return {
                            tournamentId: tournament.id,
                        };
                    }));
                }

                return usages.reduce((products, {product}) => {
                    if (product.has_event_booking_support) {
                        products.push({
                            value: product.id,
                            key: product.short_name,
                            rightScopeId: id,
                            start_date: start_date,
                            end_date: end_date,
                            contents: contentsId,
                        });
                    }

                    return products;
                }, []);
            });
            this.setState(() => ({
                productsFromUsages: productsByPropertyLicence,
            }));
        }).catch(() => {
            this.setFormMessage('Can\'t get right scopes for selected property licence.');
        });
    };

    showCoverage = (date, productsFromUsages, selectedContent) => {
        const filteredProductsByContent = (selectedContent, productsFromUsages) => {
            return productsFromUsages.filter((products) => {
                if (0 !== products.contents.length) {
                    return products.contents.some(item => {
                        //non sportradar event
                        if (this.props.Model.fields.content_category_id) {
                            return convertToInt(item.contentCategoryId) === selectedContent;
                        }

                        //sportradar event
                        if (this.props.Model.fields.tournament) {
                            return item.tournamentId == selectedContent;
                        }
                    });
                } else {
                    return productsFromUsages;
                }
            });
        };
        const filteredProductsByDate = (selectedDate, productsFromUsagesFilteredByContent) => {
            const selectedDateObject = new Date (selectedDate);
            const filteredProducts = [];

            for (const obj of productsFromUsagesFilteredByContent) {
                const startDate = new Date(obj.start_date);
                const endDate = new Date(obj.end_date);

                if (selectedDateObject >= startDate && selectedDateObject <= endDate) {
                    filteredProducts.push({key: obj.key, value: obj.value});
                }
            }

            const productsWithoutDuplicates = (_uniq(filteredProducts
                .map(({value, key}) => JSON.stringify({value, key}))).map(str => JSON.parse(str)));

            return productsWithoutDuplicates;
        };

        const productsToCoverageByContent = filteredProductsByContent(selectedContent, productsFromUsages);
        const productsToCoverageByDate = filteredProductsByDate(date, productsToCoverageByContent);

        this.setState(() => ({
            productsToCoverageFilteredByContentAndDate: productsToCoverageByDate ?? [],
        }));

        if (productsToCoverageByDate.length) {
            this.setField('coverage', {
                hidden: false,
                options: productsToCoverageByDate,
            });
        }

        if (0 === productsToCoverageByDate.length) {
            this.hideCoverage();
        }
    }

    hideCoverage = () => {
        this.setField('coverage', {
            hidden: true,
            options: null,
        });
    }

    componentWillMount() {
        super.componentWillMount();

        this.setOptionParsers({
            property_licence: (data) => {
                let propertyLicencesList = [];

                for (let i = 0; i < data.length; i++) {
                    const propertyLicence = data[i];
                    let contents = _get(propertyLicence,"contents", []);

                    if (contents && contents.length > 0
                        || this.props.Model.formId === SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
                        propertyLicencesList.push({
                            id: propertyLicence.id,
                            key: propertyLicence.id,
                            text: `${propertyLicence.name} - ${propertyLicence.licensor.name}`,
                            value: propertyLicence.id
                        })
                    }
                }

                return _sortBy(propertyLicencesList, (item) => item.text.toLocaleLowerCase());
            },
        });

        this.setOnChangeCallback({
            "preset_save": (data) => {
                let isPresetOverwriteHidden = true;

                if(!data.value) {
                    isPresetOverwriteHidden = false
                }

                this.setField("preset_name", {
                    hidden: isPresetOverwriteHidden
                });

                this.setField("preset_overwrite", {
                    hidden: !(!isPresetOverwriteHidden && !!this.props.formValues.event_preset)
                });
            },
            "preset_overwrite": (data) => {
                let eventPresetId = null;

                if (!data.value) {
                    eventPresetId = this.props.formValues.event_preset
                }

                this.setField("preset_id", {
                    defaultValue: eventPresetId
                })
            },
            "event_preset": (data) => {
                let eventPreset = _get(this.state, `presetsListObject.[${data.value}].event`),
                    color = _get(eventPreset, "color", COLOR_DEFAULT),
                    isPresetOverwriteHidden = true;

                if (data.value) {
                    isPresetOverwriteHidden = false;
                }

                isPresetOverwriteHidden = !(!isPresetOverwriteHidden && !!this.props.formValues.preset_save);

                this.setField("preset_overwrite", {
                    hidden: isPresetOverwriteHidden
                });

                let presetId = null;

                if (this.props.formValues.preset_overwrite) {
                    presetId = data.value
                }

                this.setField("preset_id", {
                    defaultValue: presetId
                });

                if (color) {
                    this.setField("color", {
                        defaultValue: "#" + color,
                    });
                }

                this.setField("description", {
                    defaultValue: _get(eventPreset, "description", null),
                });

                this.setField("event_status", {
                    defaultValue: _get(this.props, 'formValues.event_status', null),
                    value: _get(this.props, 'formValues.event_status', null),
                });

                this.setField("notes", {
                    defaultValue: _get(eventPreset, "notes", ""),
                });

                this.setField("preset_name", {
                    defaultValue: _get(this.state, `presetsListObject.[${data.value}].name`, null),
                });

                let presetMasterEventId = _find(_get(this.state, "defaultForm_fields.master.props.options", []),
                    {value:  _get(eventPreset, "master_event.id", null)});

                this.setField("master_event_id", {
                    defaultValue: _get(presetMasterEventId, "value", null),
                });

                this.setField("as_additional_contents", {
                    hidden: !data.value
                });

                const presetProductCoverage = _get(this.state, `presetsListObject.[${data.value}].event.products`, []);

                this.setState(() => ({
                    presetProducts: presetProductCoverage,
                }), () => {
                    this.setField('coverage', {
                        defaultValue: presetProductCoverage.map((product) => convertToInt(product.id)),
                        timestamp: Date.now(),
                    });
                });
            },
        });

        this.setField("start_datetime", {
            configuration: {
                ..._get(this.props, 'Model.fields.start_datetime.props.configuration', {}),
                filterDate: this.filterStartDatetime,
            }
        });

        this.setField("end_datetime", {
            configuration: {
                ..._get(this.props, 'Model.fields.start_datetime.props.configuration', {}),
                filterDate: this.filterEndDatetime,
            }
        });

        this.setValuesParsers({
            color: (value) => {
                if (value) {
                    return "#" + value
                }

                return null
            }
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink("events.configuration.index", {id: response.data.createSrEvent.event.id}));
        });
    }

    setStateHandler = (stateObject) => {
        this.setState(() => (stateObject));
    };

    componentDidMount() {
        const eventId = _get(
            this.props,
            'formParams.id',
            null
        );

        'undefined' == typeof this.state.canDelete
        && eventId
        && setCanDeleteState(eventId, this.setStateHandler, true);
    }

    redirectToEventEditPage = () => {
        window.location.href = getLink('events.edit', {
            id: _get(this.props, 'formParams.id', null),
        });
    };

    renderDeleteButton() {
        return this.state.canDelete
            ? super.renderDeleteButton()
            : super.renderDeleteButton({
                onClick: () => setErrorModal(
                    renderModalErrorObject(CANT_DELETE_EVENT, this.redirectToEventEditPage)
                ),
            });
    }
}

export default EventForm;
