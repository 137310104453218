/**
 * Render input for Redux
 */
import * as React from "react";
import PropTypes from "prop-types";
import {Form as SemanticForm, Icon} from "semantic-ui-react";
import {get as _get, isEmpty as _isEmpty, isEqual as _isEqual} from 'lodash';
import classnames from "classnames";

import {ReduxComponent} from "./ReduxComponent";

/**
 * Render select ( dropdown ) for Redux
 */
export class SemanticSelect extends ReduxComponent {
    /**
     *  @property {Boolean} allowClear If set to true will show X icon that after click will clear selection
     *  @property {Boolean} allowEmpty If set to true will allow empty value to be set
     *  @property {String | Number | Array} defaultValue Values that are chosen by default ( initial ),
     *  <div class="color:red">Array is used only when multiple option is set to true</div>
     *  @property {Boolean} disabled Parameter used to disable select
     *  @property {Boolean} loading Parameter used to show loader and disable select
     *  @property {Boolean} multiple Allowing select to be multiple choice
     *  @property {Function} onChangeSelect Callback that is called when select was changed, in first parameter values of select will be send
     *  @property {Array<Object>} options Array of options for select
     *  <pre>
     *      [
     *          {
     *              value: 2,
     *              text: "text"
     *          },
     *          {
     *              value: "test value",
     *              text: "Text for value"
     *          }
     *      ]
     *  </pre>
     *  @property {String} placeholder Placeholder ;)
     */
    static propTypes = {
        allowClear: PropTypes.bool,
        allowEmpty: PropTypes.bool,
        className: PropTypes.string,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.array
        ]),
        disabled: PropTypes.bool,
        description: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        input: PropTypes.object,
        loading: PropTypes.bool,
        meta: PropTypes.object,
        multiple: PropTypes.bool,
        onChangeSelect: PropTypes.func,
        onSearchChangeSelect: PropTypes.func,
        options: PropTypes.array,
        optionsSubKey: PropTypes.string,
        resetToDefaultValue: PropTypes.bool,
        placeholder: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
        ]),
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);

        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onSearchChangeSelect = this.onSearchChangeSelect.bind(this);

        let value = this.props.multiple ? [] : "";

        if (props.defaultValue) {
            value = props.defaultValue;
        }

        /**
         * @ignore
         */
        this.state = {
            defaultValue: this.props.defaultValue,
            value: value,
            visited: false
        }
    }

    componentDidMount() {
        if (!_isEmpty(this.state.value) && !this.state.visited) {
            this.props.input.onChange(this.state.value);
        }
    }

    /**
     * @ignore
     */
    setValuesToState(data) {
        this.setState(() => (data))
    }

    /**
     * @ignore
     */
    resetValue = (setEmpty) => {
        let value = this.props.multiple ? [] : "";

        if (this.props.defaultValue && this.props.resetToDefaultValue && !setEmpty) {
            value = this.props.defaultValue;
        }

        if (this.props.onChangeSelect) {
            this.props.onChangeSelect(null, {name: this.props.input.name, value: value});
        }

        const nextState = {value};

        if (setEmpty) {
            nextState.visited = false;
        }

        this.setValuesToState(nextState);

        this.props.input.onChange(value);
    };

    resetToEmptyValue = () => {
        this.resetValue(true)
    };

    /**
     * @ignore
     */
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.timestamp
            && nextProps.timestamp !== this.state.timestamp) {

            this.setState(() => ({
                timestamp: nextProps.timestamp
            }));

            if (nextProps.defaultValue !== this.state.value) {

                if (!nextProps.defaultValue) {
                    this.resetValue(true);
                } else {
                    this.props.input.onChange(nextProps.defaultValue);
                    this.setValuesToState({value: nextProps.defaultValue, visited: true, defaultValue: nextProps.defaultValue});
                }

                return
            }
        }

        if ((!nextProps.input.value || nextProps.input.value.length === 0) && nextState.visited
            && _isEqual(nextProps.defaultValue, nextState.defaultValue)
            && (!_isEqual(nextProps.input.value, nextState.value) && (!nextState.value || nextState.value.length === 0))
        ) {
            this.resetValue(false);

            return
        }

        if (!_isEqual(nextProps.defaultValue, nextState.defaultValue)) {
            this.props.input.onChange(nextProps.defaultValue);
            this.setValuesToState({value: nextProps.defaultValue, visited: true, defaultValue: nextProps.defaultValue});

            return
        }

        this.props.input.onChange(nextState.value);
    }

    /**
     * @ignore
     */
    onChangeSelect(event, data) {
        this.setValuesToState({
            value: data.value,
            visited: true
        });
        this.props.input.onChange(data.value);

        if (this.props.onChangeSelect) {
            this.props.onChangeSelect(event, data);
        }
    }

    /**
     * @ignore
     */
    onSearchChangeSelect(event, data) {
        this.props.onSearchChangeSelect(event, data);
    }

    /**
     * @ignore
     */
    getOptionsForSelect(options) {
        let optionsForSelect = [];

        if (options !== undefined) {
            optionsForSelect = options.map(this.mapOptionsToFormSelect, this);
        }

        return optionsForSelect;
    }

    /**
     * @ignore
     */
    mapOptionsToFormSelect(item) {
        if (this.props.optionsSubKey && item[this.props.optionsSubKey]) {
            return {
                key: item[this.props.optionsSubKey].value,
                value: item[this.props.optionsSubKey].value,
                text: item[this.props.optionsSubKey].text
            }
        }

        return {
            key: item.value,
            value: item.value,
            text: item.text
        }
    }

    /**
     * @ignore
     */
    render() {
        const isClearSelectButtonVisible = this.props.allowClear
                && null !== this.state.value && _get(this, 'state.value', []).length !== 0 && !this.props.meta.submitting,
            clearSelectButtonClassName = `reset-select ${isClearSelectButtonVisible ? '' : 'hidden'}`;
        const isSelectOnBlur = this.props.selectOnBlur || false

        return (
            <div className={classnames({
                'hidden': this.props.hidden,
                'error': this.isError(this.props)
            })}>
                {this.renderLabel(this.props)}
                <div className={classnames('dropdown-select input-container --' + this.props.input.name,
                    { '--description': this.props.description })}>
                    <div className="form__field-wrapper">
                        <SemanticForm.Dropdown
                            className={classnames(
                                this.props.className,
                                '--' + this.props.input.name
                            )}
                            disabled={this.props.disabled ? this.props.disabled : this.props.meta.submitting}
                            error={this.isError(this.props)}
                            loading={this.props.loading}
                            minCharacters={this.props.minCharacters !== undefined ? this.props.minCharacters : 1}
                            multiple={(this.props.multiple)}
                            name={this.props.input.name}
                            onChange={this.onChangeSelect}
                            onSearchChange={this.props.onSearchChangeSelect}
                            options={this.getOptionsForSelect(this.props.options)}
                            placeholder={this.props.placeholder || ''}
                            search
                            selection
                            selectOnBlur={isSelectOnBlur}
                            value={this.state.value}
                        />
                        <div className="input_description">{this.props.description}</div>
                    </div>
                    {this.renderErrorMessage(this.props)}
                    {this.props.customWarning && (
                        <span className="field--customWarning">{this.props.customWarning}</span>
                    )}
                    {!this.props.disabled && <div className={clearSelectButtonClassName} onClick={this.resetToEmptyValue}>
                        <Icon name="close"/>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

SemanticSelect.displayName = "SemanticSelect";

export default SemanticSelect;
