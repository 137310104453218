import React from 'react';
import PropTypes from 'prop-types';
import {includes as _includes} from 'lodash';

import {renderPageTitle} from '@utils/helpers';
import {renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import {USER} from '@constants/resources';

import AddUserFromExistingIamAccount from '../components/AddUserFromExistingIamAccount';
import EditUserResources from '../components/EditUserResources';
import EditUserGeneralInformation from '../components/EditUserGeneralInformation';

export const createTabs = (id, path) => {
    if (path === routes.users.add_user_from_existing_iam_account.path) {
        return {
            [routes.users.add_user_from_existing_iam_account.path]: {
                key: routes.users.add_user_from_existing_iam_account.path,
                url: 'users.add_user_from_existing_iam_account',
                text: 'General information',
                render: <AddUserFromExistingIamAccount/>,
            },
        };
    }

    return {
        [routes.users.edit.path]: {
            key: routes.users.edit.path,
            url: 'users.edit',
            urlParams: {id: id},
            text: 'General information',
            render: <EditUserGeneralInformation/>,
            resource: USER,
        },
        [routes.users.edit_resources.path]: {
            key: routes.users.edit_resources.path,
            url: 'users.edit_resources',
            urlParams: {id: id},
            text: 'Resources',
            render: <EditUserResources/>,
            resource: USER,
        },
    };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createUserTabs';

export const getRenderPageTitle = (id, userName, path) => (
    (_includes([
        routes.users.add_user_from_existing_iam_account.path,
    ], path))
        ? renderPageTitle({entity: 'user'})
        : renderPageTitle({
            id: id,
            name: userName,
            entity: 'user',
        })
);

getRenderPageTitle.propTypes = {
    id: PropTypes.string.isRequired,
    userName: PropTypes.string,
    path: PropTypes.string.isRequired,
};

export const renderUserModalError = () => (
    renderModalError('User', routes.users.index.path)
);

renderUserModalError.displayName = 'renderUserModalError';
