import EventBookingGeneralInformationEditModel
    from '@modules/eventBooking/forms/EventBookingGeneralInformationEditModel';

export default function ClientEventBookingGeneralInformationEditModel() {
    let ClientEventBookingGeneralInformationEditModel = EventBookingGeneralInformationEditModel();

    ClientEventBookingGeneralInformationEditModel.messages.boxName = {
        delete: 'clientMessage',
        error: 'clientMessage',
        success: 'clientMessage',
    };
    ClientEventBookingGeneralInformationEditModel.editForm = true;
    ClientEventBookingGeneralInformationEditModel.mutationOptions = {
        delete: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
        update: {
            refetchQueries: ['GetClientEventBookingsData'],
        },
    };

    return ClientEventBookingGeneralInformationEditModel;
}
