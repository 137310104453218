import {HELPER_SESSION_MINUTE_INTERVAL} from "../avcmpConfig";
import urls from "../modules/app/constants/urls";
import responseStatus from "../modules/app/constants/responseStatuses";

class HelperSession {
    /**
     * Send request to server and check if sesssion exists
     */
    verifyCheckerResponse = () => {
        fetch(urls.authValidate, {
            credentials: "same-origin"
        })
            .then(response => {
                if (responseStatus.HTTP_FORBIDDEN === response.status) {
                    throw new Error("Session expired");
                } else if (responseStatus.HTTP_OK !== response.status) {
                    throw new Error("Bad response from server")
                }
            });
    };

    checkSessionWithInterval = (interval = HELPER_SESSION_MINUTE_INTERVAL) => {
        setInterval(() => {
            this.verifyCheckerResponse();
        }, interval * 60 * 1000);
    };

    startListener = () => {
        this.checkSessionWithInterval();
    }
}

export default HelperSession;
