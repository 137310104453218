import {generateIdNameFieldsByDataMapKey} from '@utils/fieldsGenerator';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {CONTACT_PERSON} from '@constants/resources';
import {GetContactPersonForForm} from '@graphql/contactPerson/query';
import {
    CreateContactPerson,
    DeleteContactPerson,
    UpdateContactPerson,
} from '@graphql/contactPerson/mutation';

import validate from './validator';

export default function ContactPersonModel() {
    const CONTACT_PERSON_STRING = 'contact person';

    return {
        entityDataMapKey: 'contactPerson',
        entityLabel: CONTACT_PERSON_STRING,
        formName: CONTACT_PERSON_STRING,
        label: CONTACT_PERSON_STRING,
        resources: CONTACT_PERSON,
        showChangelog: true,
        title: () =>  'Add new contact person',
        validate: validate,
        dataMap: {
            contactPerson: {
                id: '',
                name: '',
                position: '',
                address: '',
                email_address: '',
                phone_number_office: '',
                phone_number_mobile: '',
                skype_name: '',
                notes: '',
            },
        },
        messages: {
            boxName: {
                delete: 'contactPerson',
                error: 'contactPerson',
                success: 'contactPerson',
            },
            text: MESSAGES,
        },
        fields: {
            ...generateIdNameFieldsByDataMapKey('contactPerson'),
            position: {
                dataMapKey: 'contactPerson.position',
                props: {
                    component: Form.SemanticInput,
                    label: 'Position',
                    name: 'position',
                },
            },
            address: {
                dataMapKey: 'contactPerson.address',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Address',
                    name: 'address',
                    row: 3,
                },
            },
            email_address: {
                dataMapKey: 'contactPerson.email_address',
                props: {
                    component: Form.SemanticInput,
                    label: 'Email address',
                    name: 'email_address',
                },
            },
            phone_number_office: {
                dataMapKey: 'contactPerson.phone_number_office',
                props: {
                    component: Form.SemanticInput,
                    label: 'Phone number (office)',
                    name: 'phone_number_office',
                },
            },
            phone_number_mobile: {
                dataMapKey: 'contactPerson.phone_number_mobile',
                props: {
                    component: Form.SemanticInput,
                    label: 'Phone number (mobile)',
                    name: 'phone_number_mobile',
                },
            },
            skype_name: {
                dataMapKey: 'contactPerson.skype_name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Skype name',
                    name: 'skype_name',
                },
            },
            notes: {
                dataMapKey: 'contactPerson.notes',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Notes',
                    name: 'notes',
                    row: 3,
                },
            },
        },
        mutation: {
            createEntity: CreateContactPerson,
            deleteEntity: DeleteContactPerson,
            updateEntity: UpdateContactPerson,
        },
        query: {
            getEntity: GetContactPersonForForm,
        },
    };
}
