import {changelogData} from '../changelogData';
import {BasicData} from '../fragments';

export const originStreamStatusList = `
originStreamStatuses{
    id
    name
    key : id
    value : id
    text : name
}`;

export const invoiceStatusList = `
invoiceStatuses {
    id
    name
    key: id
    value: id
    text: name
}`;

export const eventContent = `
  eventContents(filters: {event: $event}){
    id
    distributions{
      product{
        id
        short_name
        is_invoiceable
      }
    }
  }
`;

export const eventInvoiceBody = `
    id
    product {
      id
      short_name
    }
    invoice_status{
      id
      name
    }
    ${changelogData}
`;

export const eventInvoiceStatus = `
  eventInvoiceStatuses(filters: {event: $event}) {
    ${eventInvoiceBody}
  }
`;

export const dropDownLists = `
query dropDownLists($event: Int){
    ${originStreamStatusList}
    ${invoiceStatusList}
    ${eventInvoiceStatus}
    ${eventContent}
}`;

export const eventsReportBody = `
    id
    origin_stream_status{
      id
      name
      key: id
      value: id
      text: name
    }
    comment
    event_issue_types{
        id
        name
        description
    }
    ${changelogData}
`;

export const updateEventReportMutation = `
mutation reportMutation(
      $id: ID!
      $event: Int!
      $origin_stream_status: Int!
      $comment: String
      $event_issue_types: [Int]
      $invoice_statuses: [EventInvoiceStatusInput]
) {
    updateEventReport(
        id: $id
        origin_stream_status: $origin_stream_status
        comment: $comment
        event_issue_types: $event_issue_types
    ) {
        ${eventsReportBody}
    }
    saveEventInvoiceStatuses(
        event: $event
        product_invoice_statuses: $invoice_statuses
    ) {
        ${eventInvoiceBody}
    }
}`;

export const createEventReportMutation = `
    mutation reportMutation(
      $event: Int!
      $origin_stream_status: Int!
      $comment: String
      $invoice_statuses: [EventInvoiceStatusInput]!
      $event_issue_types:[Int]!
    ) {
      createEventReport(
        event: $event
        origin_stream_status: $origin_stream_status
        comment: $comment
        event_issue_types: $event_issue_types
      ) {
        ${eventsReportBody}
      }
      saveEventInvoiceStatuses(
        event: $event
        product_invoice_statuses: $invoice_statuses
      ) {
        ${eventInvoiceBody}
      }
    }
`;

export const eventIncidentsQuery = `
query eventIncidents (
    $event: Int
) {
    eventIncidents (filters: {
        event: $event
    }) {
        id
        event_incident_reason {
            id
            name
            event_incident_causer {
                id
                name
                event_incident_location {
                    id
                    name
                }
            }
        }
        affects_all_output_streams
        lasted_entire_duration
        start_datetime
        end_datetime
        count_as_downtime
        event_contents {
            id
          	name
          	event_content_type {
              	id
              	name
            }
            event_content_variant {
                ${BasicData}
                short_name
            }
        }
        event_incident_scopes {
            id
            encoding_target {
                id
              	distribution {
                  	id
                  	event_content {
                      	id
                      	name
                    }
                  	product {
                      	id
                      	short_name
                    }
                  	distribution_type {
                      	id
                      	name
                    }
                }
              	stream_delay_type {
                  	id
                  	name
                }
            }
            stream_protocol {
                id
                name
            }
        }
    }
}
`;
