import {connect} from "react-redux";
import {withRouter} from "react-router";

import ClientProductForm from "./ClientProductForm";
import {getLink} from "../../app/components/Link";

import {deleteSuccessCallback, setClientProductsEntity} from "../utils/clientProducts";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";

import {PRODUCT_LCR} from "../../app/constants/variables";

class ClientProductLcrForm extends ClientProductForm {
    componentDidMount() {
        super.componentDidMount();

        const entityName = "client",
            params = {
                clientData: this.props.clientData,
                entityName,
                setEntity: this.props.Entity.setEntity,
            };

        this.setCreateSuccessCallback((response) => {
            this.props.Entity.setEntity({
                name: entityName,
                data: Object.assign({}, this.props.clientData, {
                    clientProducts: this.props.clientData.clientProducts.concat(
                        response.data.createClientProductLcr.client_product
                    ),
                }),
            });

            this.props.history.push(getLink("clients.products.lcr.editGeoRestrictions", {
                id: this.props.match.params.id,
            }));
        });

        this.setUpdateSuccessCallback((response) => {
            const clientProductLcrIndex = this.props.clientData.clientProducts.findIndex(clientProduct =>
                    PRODUCT_LCR === convertToInt(clientProduct.product.id)
                ),
                clientProducts = {};

            clientProducts[clientProductLcrIndex] = Object.assign({},
                response.data.updateClientProductLcr.client_product
            );
            params.clientProducts = clientProducts;

            setClientProductsEntity(params);
        });

        this.setDeleteSuccessCallback(() => {
            params.formParamsId = this.props.formParams.id;
            params.historyPush = this.props.history.push;

            deleteSuccessCallback(params);
        });
    }

    componentDidUpdate() {
        const {
            formParams: {isAddForm},
            GraphQLEntityData: {
                clientProductsLcr,
                loading: entityLoading,
            } = {
                entityLoading: false,
            },
            GraphQLOptionsData: {
                lcrClientTypes,
                loading: optionsLoading,
            },
        } = this.props;

        let nextProps;

        if (!optionsLoading && !entityLoading && !this.state.dataReceived) {
            if (!isAddForm) {
                nextProps = Object.assign({}, this.props, {
                    clientProduct: clientProductsLcr[0].client_product,
                });

                this.setField("id", {defaultValue: convertToInt(clientProductsLcr[0].id)});
                this.setField("lcr_client_types", {
                    defaultValue: clientProductsLcr[0].lcr_client_types.map(lcrClientType =>
                        convertToInt(lcrClientType.id)
                    ),
                    options: lcrClientTypes,
                });
            }

            super.componentDidUpdate(nextProps);
        }
    }

    prepareDataForSubmit(data) {
        data = Object.assign({}, data, {product: PRODUCT_LCR});

        const dataToSubmit = super.prepareDataForSubmit(data);

        return Object.assign({}, dataToSubmit, {lcr_client_types: data.lcr_client_types});
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity']),
    mapStateToProps = (state) => ({clientData: state.app.entities.client || null});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientProductLcrForm));
