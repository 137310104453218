import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

export const exportExcel = (dataExport) => (
    {
        dataExport: dataExport,
        filename: "changelog",
        parseData: changelog => [
            changelog.datetime,
            _get(changelog, 'secured_resource.name', ''),
            changelog.element_id.toString(),
            changelog.element_name,
            _get(changelog, 'changelog_action_type.name', ''),
            _get(changelog, 'user.name', ''),
            changelog.ip
        ],
        path: 'changelog.index.path',
        titles: [
            "Date/time",
            "Resource",
            "Element ID",
            "Element name",
            "Action",
            "User",
            "IP"
        ]
    }
);

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired
}
