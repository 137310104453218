import PropTypes from 'prop-types';
import React from 'react';
import {get as _get} from 'lodash';
import {Label} from "semantic-ui-react";

import Authorization from "../../app/components/Authorization";
import PropertyLicenceContentsRow, {contentRows} from "./PropertyLicenceContentsRow";
import Table from "../../app/components/Table";
import {getLink} from "../../app/components/Link";
import {IconEdit} from "../../app/components/IconCollection";

import {
    columns,
    linkToPropertyLicenseEdit,
    renderCheckbox,
    renderLicensees,
    renderNameOfFirstLevelDependency,
    renderSportOrTopCategory
} from "../utils/propertyLicenceTableHelper";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const authorizationObject = {
    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ
};

const PropertyLicenceTable = (props) => {
    const {loading} = props,
        propertyLicences = props.propertyLicences.map(el => ({
            ...el,
            contentSortValue: contentRows(el).map(content => content.text).join(",")
        }));

    const rowRenderer = (column, row) => {
        const officialName = _get(row, 'official_name', null),
            isTvStreaming = _get(row, 'is_tv_streaming', false);

        switch (column) {
            case "id":
                return linkToPropertyLicenseEdit(row[column], row.id);
            case "name":
                return (
                    <React.Fragment>
                        {linkToPropertyLicenseEdit(row[column], row.id)}
                        {isTvStreaming && <Label className="--marginLeft-0-5-em" color="grey" size="mini">
                            TV Streaming
                        </Label>}
                        {officialName && <p className={"muted"}>{officialName}</p>}
                    </React.Fragment>
                );
            case "sport" :
                return renderSportOrTopCategory(row);
            case "licensees" :
                return renderLicensees(row);
            case "licensor" :
            case "content_tier" :
            case "content_package_type" :
            case "content_manager_user" :
            case "property_licence_type" :
            case "content_category" :
            case "property_licence_status" :
                return renderNameOfFirstLevelDependency(row, column);
            case "contents" :
                return PropertyLicenceContentsRow(row);
            case "has_accreditation" :
                return renderCheckbox(row, column);
            case "actions" :
                return (
                    <Authorization authorization={authorizationObject}>
                        <div className="actions-list-icon">
                            <IconEdit
                                size="large"
                                link={getLink('propertyLicences.edit', {id: row.id})}
                                resource={RESOURCES.PROPERTY_LICENCE}
                            />
                        </div>
                    </Authorization>
                );
            default:
                return null
        }
    };

    return (
        <div className="propertyLicences__table">
            <Table
                noDataText="No property licences found"
                name="propertyLicencesList"
                columns={columns}
                data={propertyLicences}
                loading={loading}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

PropertyLicenceTable.defaultProps = {
    propertyLicences: []
};

PropertyLicenceTable.propTypes = {
    propertyLicences: PropTypes.array.isRequired,
    loading: PropTypes.bool
};

export default PropertyLicenceTable;
