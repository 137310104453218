import {isEmpty as _isEmpty, isUndefined as _isUndefined} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Segment} from 'semantic-ui-react';

import {withApollo} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import Tabs from '@appComponents/Tabs';
import {GetThirdPartyClientDataForStore} from '@graphql/thirdPartyClients/query';
import {EXPORT_EXCEL_URL_FRAGMENT, PRODUCT_THIRD_PARTY} from '@constants/variables';
import {routes} from '@constants/routes';
import mapModulesToProps from '@utils/mapModulesToProps';
import {convertToInt} from '@utils/helpers';
import {showErrorModal} from '@utils/modal';

import ClientProductThirdPartyForm from '../forms/ClientProductThirdPartyForm';
import ClientProductThirdPartyModel from '../forms/ClientProductThirdPartyModel';
import {ProductAdd} from '../views/ProductAdd';
import ProductEdit from '../views/ProductEdit';
import ProductThirdPartyGeoRestrictionsEdit from '../views/ProductThirdPartyGeoRestrictionsEdit';
import ProductThirdPartyStreamNamesPullIndex from '../views/ProductThirdPartyStreamNamesPullIndex';
import ProductThirdPartyStreamNamesPushIndex from '../views/ProductThirdPartyStreamNamesPushIndex';
import ThirdPartyCdnsIndex from  '../views/ThirdPartyCdnsIndex';
import ThirdPartyPullOriginsIndex from '../views/ThirdPartyPullOriginsIndex';
import ThirdPartyVodStoragesIndex from '../views/ThirdPartyVodStoragesIndex';
import ProductGeolocations from  '../components/ProductGeolocations';
import * as CLIENT_TYPES from '../constants/thirdPartyClientTypes';


class ClientProductThirdPartyLayout extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
        clientProductThirdParty: PropTypes.shape({
            product: PropTypes.shape({
                has_geolocation_support: PropTypes.bool
            }),
            third_party_client_product: PropTypes.shape({
                id: PropTypes.string
            }),
        }).isRequired,
        Entity: PropTypes.object,
        hasCdns: PropTypes.bool,
        hasPullOrigins: PropTypes.bool,
        hasVodStorages: PropTypes.bool,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        shouldOpenModal: PropTypes.bool,
    };

    static defaultProps = {
        clientProductThirdParty: {
            product: {
                has_geolocation_support: false
            },
            third_party_client_product: {
                id: null
            }
        },
        shouldOpenModal: false,
    };

    constructor() {
        super();

        this.state = {
            loading: false,
        };
    }

    componentWillMount() {
        this.setState(() => this.getProductThirdPartyTabsState());
        this.getThirdPartyClientCdnsAndPullOriginsFromGraphQL();
    }

    componentWillReceiveProps(nextProps) {
        this.setState(() => this.getProductThirdPartyTabsState(nextProps));
    }

    checkRouteIsAllowed(activeRoute, props, showCdnTab, showPullOriginTab, showVodStorageTab) {
        if (props.hasCdns === undefined || props.hasPullOrigins === undefined || undefined === props.hasVodStorages) {
            return true;
        }

        switch (activeRoute) {
            case routes.clients.products.thirdParty.cdns.index.path:
                return showCdnTab;
            case routes.clients.products.thirdParty.pullOrigins.index.path:
                return showPullOriginTab;
            case routes.clients.products.thirdParty.streamNamesPush.index.path:
                return showCdnTab && props.hasCdns;
            case routes.clients.products.thirdParty.streamNamesPull.index.path:
                return showPullOriginTab && props.hasPullOrigins;
            case routes.clients.products.thirdParty.vodStorages.index.path:
                return showVodStorageTab;
            default:
                return true;
        }
    }

    getThirdPartyClientCdnsAndPullOriginsFromGraphQL = () => {
        if (_isUndefined(this.props.clientProductThirdParty.third_party_client_product)) {
            return;
        }

        this.setState(() => {
            return {
                loading: true,
            };
        });

        this.props.client.query({
            fetchPolicy: "cache-first",
            query: GetThirdPartyClientDataForStore,
            variables: {
                clientProductThirdParty: convertToInt(this.props.clientProductThirdParty.third_party_client_product.id),
            },
        }).then((data) => {
            if (data) {
                this.prepareThirdPartyClientCdnsForStore(data.data.thirdPartyClientCdns);
                this.prepareThirdPartyClientPullOriginsForStore(data.data.thirdPartyClientPullOrigins);
                this.prepareThirdPartyClientVodStoragesForStore(data.data.thirdPartyClientVodStorages);
            }

            this.setState(() => {
                return {
                    loading: false,
                };
            });
        }).catch(() => {
            this.setState(() => ({loading: false}));

            showErrorModal({
                header: 'Third party was not found',
                text: 'Third party was not found',
                errorButtonText: 'Back to clients',
                hideButtons: false,
                redirectLink: getLink('clients.index'),
                errorButtonIcon: 'arrow circle left',
            });
        });
    };

    prepareThirdPartyClientCdnsForStore = (thirdPartyClientCdnsForStore) => {
        this.props.Entity.setEntity({
            data: {
                cdns: thirdPartyClientCdnsForStore,
            },
            name: 'thirdPartyClientCdns',
        });
    };

    prepareThirdPartyClientPullOriginsForStore = (thirdPartyClientPullOriginsForStore) => {
        this.props.Entity.setEntity({
            data: {
                pullOrigins: thirdPartyClientPullOriginsForStore,
            },
            name: 'thirdPartyClientPullOrigins',
        });
    };

    prepareThirdPartyClientVodStoragesForStore = (thirdPartyClientVodStoragesForStore) => {
        this.props.Entity.setEntity({
            data: {
                vodStorages: thirdPartyClientVodStoragesForStore,
            },
            name: 'thirdPartyClientVodStorages',
        });
    };

    getProductThirdPartyTabsState = (props) => {
        if (_isUndefined(props)) {
            props = this.props;
        }

        let active = props.match.path,
            tabs = {};

        const productsThirdPartyRoutes = routes.clients.products.thirdParty;

        if (
            active === productsThirdPartyRoutes.cdns.index.path
            || active === productsThirdPartyRoutes.cdns.add.path
            || active === productsThirdPartyRoutes.cdns.edit.path
            || active === `${productsThirdPartyRoutes.cdns.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = productsThirdPartyRoutes.cdns.index.path;
        }

        if (active === productsThirdPartyRoutes.pullOrigins.index.path
            || active === productsThirdPartyRoutes.pullOrigins.add.path
            || active === productsThirdPartyRoutes.pullOrigins.edit.path
            || active === `${productsThirdPartyRoutes.pullOrigins.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = productsThirdPartyRoutes.pullOrigins.index.path;
        }

        if (active === productsThirdPartyRoutes.streamNamesPush.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPush.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPush.add.path
            || active === productsThirdPartyRoutes.streamNamesPush.edit.path
        ) {
            active = productsThirdPartyRoutes.streamNamesPush.index.path;
        }

        if (active === productsThirdPartyRoutes.streamNamesPull.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPull.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPull.add.path
            || active === productsThirdPartyRoutes.streamNamesPull.edit.path
        ) {
            active = productsThirdPartyRoutes.streamNamesPull.index.path;
        }

        if (active === productsThirdPartyRoutes.vodStorages.index.path
            || active === `${productsThirdPartyRoutes.vodStorages.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.vodStorages.add.path
            || active === productsThirdPartyRoutes.vodStorages.edit.path
        ) {
            active = productsThirdPartyRoutes.vodStorages.index.path;
        }

        let showCdnTab = false,
            showPullOriginTab = false,
            showVodStorageTab = false;

        if (!_isEmpty(props.clientProductThirdParty)) {
            const types = props.clientProductThirdParty.third_party_client_product.third_party_client_types;

            showCdnTab = types.find((type) => (CLIENT_TYPES.CLIENT_TYPE_PUSH === convertToInt(type.id)));
            showPullOriginTab = types.find((type) => (CLIENT_TYPES.CLIENT_TYPE_PULL === convertToInt(type.id)));
            showVodStorageTab = types.find((type) => (CLIENT_TYPES.CLIENT_TYPE_VOD === convertToInt(type.id)));
        }

        if (
            !this.checkRouteIsAllowed(active, props, showCdnTab, showPullOriginTab, showVodStorageTab)
            && null !== this.checkRouteIsAllowed(active, props, showCdnTab, showPullOriginTab, showVodStorageTab)
        ) {
            const returnLink = getLink('clients.edit', {id: this.props.match.params.id});

            showErrorModal({
                header: "Can't access the given URL",
                text: "Can't access the given URL",
                errorButtonText: 'Back to client',
                hideButtons: false,
                redirectLink: returnLink,
                errorButtonIcon: 'arrow circle left',
            });
        }

        if (_isEmpty(props.clientProductThirdParty)) {
            tabs = {
                [routes.clients.products.thirdParty.add.path]: {
                    key: routes.clients.products.thirdParty.add.path,
                    url: "clients.products.thirdParty.add",
                    urlParams: {id: this.props.match.params.id},
                    text: "Settings",
                    render: <ProductAdd
                        form={ClientProductThirdPartyForm}
                        hasTabs={false}
                        model={ClientProductThirdPartyModel}
                        productId={PRODUCT_THIRD_PARTY}
                    />,
                },
            };
        } else {
            tabs = {
                [routes.clients.products.thirdParty.edit.path]: {
                    key: routes.clients.products.thirdParty.edit.path,
                    url: "clients.products.thirdParty.edit",
                    urlParams: {id: this.props.match.params.id},
                    text: "Settings",
                    render: <ProductEdit
                        form={ClientProductThirdPartyForm}
                        model={ClientProductThirdPartyModel}
                        productId={PRODUCT_THIRD_PARTY}
                    />,
                },
                [routes.clients.products.thirdParty.editGeoRestrictions.path]: {
                    key: routes.clients.products.thirdParty.editGeoRestrictions.path,
                    url: "clients.products.thirdParty.editGeoRestrictions",
                    urlParams: {id: this.props.match.params.id},
                    text: "Geo restrictions",
                    render: <ProductThirdPartyGeoRestrictionsEdit
                        clientProductId={convertToInt(props.clientProductThirdParty.id)}
                    />,
                },
                [routes.clients.products.thirdParty.geolocation.index.path]: {
                    key: routes.clients.products.thirdParty.geolocation.index.path,
                    hidden: !props.clientProductThirdParty.product.has_geolocation_support,
                    url: "clients.products.thirdParty.geolocation.index",
                    urlParams: {id: this.props.match.params.id},
                    text: "Geolocation",
                    render: <ProductGeolocations
                        clientProductId={convertToInt(props.clientProductThirdParty.id)}
                        productShortName={"thirdParty"}
                    />,
                },
            };

            if (showCdnTab) {
                tabs = Object.assign(tabs, {
                    [routes.clients.products.thirdParty.cdns.index.path]: {
                        key: routes.clients.products.thirdParty.cdns.index.path,
                        url: "clients.products.thirdParty.cdns.index",
                        urlParams: {id: this.props.match.params.id},
                        text: "CDNs",
                        render: <ThirdPartyCdnsIndex
                            prepareThirdPartyClientCdnsForStore={this.prepareThirdPartyClientCdnsForStore}
                        />
                    }
                });
            }

            if (showPullOriginTab) {
                tabs = Object.assign(tabs, {
                    [routes.clients.products.thirdParty.pullOrigins.index.path]: {
                        key: routes.clients.products.thirdParty.pullOrigins.index.path,
                        url: "clients.products.thirdParty.pullOrigins.index",
                        urlParams: {id: this.props.match.params.id},
                        text: "Pull origins",
                        render: <ThirdPartyPullOriginsIndex
                            clientId={convertToInt(this.props.match.params.id)}
                            prepareThirdPartyClientPullOriginsForStore={this.prepareThirdPartyClientPullOriginsForStore}
                        />
                    }
                });
            }

            if (showCdnTab && props.hasCdns) {
                tabs = Object.assign(tabs, {
                    [routes.clients.products.thirdParty.streamNamesPush.index.path]: {
                        key: routes.clients.products.thirdParty.streamNamesPush.index.path,
                        url: "clients.products.thirdParty.streamNamesPush.index",
                        urlParams: {id: this.props.match.params.id},
                        text: "Stream names (push)",
                        render: <ProductThirdPartyStreamNamesPushIndex />,
                    },
                });
            }

            if (showPullOriginTab && props.hasPullOrigins) {
                tabs = Object.assign(tabs, {
                    [routes.clients.products.thirdParty.streamNamesPull.index.path]: {
                        key: routes.clients.products.thirdParty.streamNamesPull.index.path,
                        url: "clients.products.thirdParty.streamNamesPull.index",
                        urlParams: {id: this.props.match.params.id},
                        text: "Stream names (pull)",
                        render: <ProductThirdPartyStreamNamesPullIndex />,
                    },
                });
            }

            if (showVodStorageTab) {
                tabs = Object.assign(tabs, {
                    [routes.clients.products.thirdParty.vodStorages.index.path]: {
                        key: routes.clients.products.thirdParty.vodStorages.index.path,
                        url: "clients.products.thirdParty.vodStorages.index",
                        urlParams: {id: this.props.match.params.id},
                        text: "VOD storages",
                        render: <ThirdPartyVodStoragesIndex
                            clientId={convertToInt(this.props.match.params.id)}
                            prepareThirdPartyClientVodStoragesForStore={this.prepareThirdPartyClientVodStoragesForStore}
                        />,
                    }
                });
            }
        }

        if ('geolocation' === props.match.path.split("/")[5]) {
            active = productsThirdPartyRoutes.geolocation.index.path;

            if (!props.clientProductThirdParty.product.has_geolocation_support) {
                const returnLink = getLink("clients.products.thirdParty.edit",{id: this.props.match.params.id});

                showErrorModal({
                    header: 'This product does not have geolocation support',
                    errorButtonText: 'Back to settings',
                    hideButtons: false,
                    redirectLink: returnLink,
                    errorButtonIcon: 'arrow circle left',
                });
            }
        }

        return {
            active: active,
            tabs: tabs,
        };
    };

    render() {
        return (
            (this.state.loading)
                ? <Segment basic loading={true} padded="very" />
                : <Tabs active={this.state.active} items={this.state.tabs} level={2}
                    segmentClassName="--marginTopNegative-15 --paddingTop-0"/>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity', 'MessageBox', 'Modal']);

const mapStateToProps = (state) => {
    let hasCdns,
        hasPullOrigins,
        hasVodStorages;

    const cdnsInStore = state.app.entities.thirdPartyClientCdns,
        pullOriginsInStore = state.app.entities.thirdPartyClientPullOrigins,
        vodStoragesInStore = state.app.entities.thirdPartyClientVodStorages;

    if (!_isUndefined(cdnsInStore)) {
        hasCdns = !_isEmpty(cdnsInStore.cdns);
    }

    if (!_isUndefined(pullOriginsInStore)) {
        hasPullOrigins = !_isEmpty(pullOriginsInStore.pullOrigins);
    }

    if (!_isUndefined(vodStoragesInStore)) {
        hasVodStorages = !_isEmpty(vodStoragesInStore.vodStorages);
    }

    return {
        hasCdns: hasCdns,
        hasPullOrigins: hasPullOrigins,
        hasVodStorages: hasVodStorages,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withApollo(ClientProductThirdPartyLayout)));
