import WhitelistedIpModel from "./WhitelistedIpModel";

export default function WhitelistedIpModelEdit() {
    const whitelistedIpModelEdit = WhitelistedIpModel(),
        cidrName = (data) => (data.clientWhitelistedIp ? `"${data.clientWhitelistedIp.cidr}"` : "");

    whitelistedIpModelEdit.title = (data) => (`CIDR ${cidrName(data)}`);
    whitelistedIpModelEdit.fields.ip_version.props.disabled = true;
    whitelistedIpModelEdit.fields.cidr.props.disabled = true;

    return whitelistedIpModelEdit;
}
