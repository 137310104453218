import EventsNonSportradarMasterModel from "./EventsNonSportradarMasterModel";
import Form from "../../app/components/ReduxFormControls";
import validate from "./validator";
import {REQUIRE_THREE_LETTERS_PLACEHOLDER} from "../../app/constants/variables";
import {applyPresetFieldsForModel, presetFieldsForModel} from "./EventModel";

export default function NonSportradarEventModel(isEdit = false) {
    const model = EventsNonSportradarMasterModel();
    const presetFields = applyPresetFieldsForModel(isEdit);

    model.fields.event_preset = presetFields.event_preset;

    if (true === isEdit) {
        model.fields.as_additional_contents = presetFields.as_additional_contents;
    }

    model.validate = validate;

    model.title = (data, type) => {
        if (data && data.event && type === "edit") {
            return `Edit non-Sportradar event ${data.event.id}`;
        }

        return "Create non-Sportradar event";
    };

    model.fields.home_competitor = {
        dataMapKey: "event.av_event.home_competitor.id",
        props: {
            component: Form.SemanticSelect,
            type: "select",
            placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
            label: "Home competitor",
            name: "home_competitor",
            allowClear: true,
        }
    };

    model.fields.away_competitor = {
        dataMapKey: "event.av_event.away_competitor.id",
        props: {
            component: Form.SemanticSelect,
            type: "select",
            placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
            label: "Away competitor",
            name: "away_competitor",
            allowClear: true,
        }
    };

    model.fields.master_event = {
        dataMapKey: "event.master_event.id",
        props: {
            allowClear: true,
            component: Form.SemanticSelect,
            label: "Master",
            name: "master_event",
            disabled: true,
        }
    };

    delete model.fields.multiple[0].description.props.required;
    delete model.fields.end_datetime.props.required;

    model.fields = {...model.fields, ...presetFieldsForModel()};

    return model;
}
