
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClientAvapiWhitelistedIps"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClientAvapiWhitelistedIp"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ip_version"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"cidr"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"disable_datetime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_disabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"creation_datetime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"creation_user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"update_datetime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"update_user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BasicData"},"directives":[]}]}}]}}],"loc":{"start":0,"end":346}};
    doc.loc.source = {"body":"#import \"../interface.graphql\"\n\nfragment ClientAvapiWhitelistedIps on ClientAvapiWhitelistedIp {\n    id\n    ip_version {\n        ...BasicData\n    }\n    cidr\n    description\n    disable_datetime\n    is_disabled\n    creation_datetime\n    creation_user {\n        ...BasicData\n    }\n    update_datetime\n    update_user {\n        ...BasicData\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../interface.graphql").definitions));


      module.exports = doc;
    
