import {orderBy as _orderBy} from 'lodash';

export const adSpliceOptionParser = (valuesForDropdown) => {
    return valuesForDropdown.map(({id, splice_id, name, value}) => (
        {
            key: id,
            name: name,
            value: value,
            text: `${name} (${splice_id}) `,
        })
    );
};

export const getEnabledOrAssignedOptions = (dropdownOptions, assignedDropdownOption) => {
    return dropdownOptions.filter(dropdownOption =>
        !dropdownOption.is_disabled || dropdownOption.value === assignedDropdownOption
    );
};

export const adSpliceConfigOptionParser = (valuesForDropdown) => {
    return _orderBy(valuesForDropdown.map(({id, name, value}) => (
        {
            key: id,
            name: name,
            value: value,
            text: name,
        })
    ),['name'],['asc']);
};
