/**
 * Get detailed content categories list in format
 * content category lvl 1 > content category lvl 2 > content category lvl 3
 * prepared for dropdown
 *
 * @param contentCategories {(null|array)}
 *
 * @returns {array}
 */
export const prepareContentCategoriesForDropdown = (contentCategories = []) => (contentCategories.map((item) => ({
    key: item.id.toString(),
    text: `${item.parent.parent.name} > ${item.parent.name} > ${item.name}`,
    value: item.id.toString(),
})));
