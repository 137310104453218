import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.type, 'type'));
    Object.assign(errors, validators.required(values.template, 'template'));
    Object.assign(errors, validators.required(values.message, 'message'));

    return errors;
};

export default validate;