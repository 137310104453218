import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.tournament_category, 'tournament_category'));
    Object.assign(errors, validators.required(values.tournament, 'tournament'));
    Object.assign(errors, validators.required(values.content_category_2, 'content_category_2'));
    Object.assign(errors, validators.required(values.content_category_3, 'content_category_3'));
    Object.assign(errors, validators.required(values.content_tier, 'content_tier'));

    return errors;
};

export default validate;
