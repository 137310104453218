import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import PropertyLicenceForm from '../forms/PropertyLicenceForm';
import PropertyLicenceModel from '../forms/PropertyLicenceModel';

const PropertyLicenceEdit = ({match: {params: {id = null}}}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Property Licence', routes.propertyLicences.index.path),
        });
    }

    const renderForm = () => {
        const Form = createForm(
            () => PropertyLicenceModel({id}),
            PropertyLicenceForm, {
                id: convertToInt(id),
            }
        );

        return <Form/>;
    };

    return (
        React.useMemo(() => renderForm(), [id])
    );
};

PropertyLicenceEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

export default withRouter(PropertyLicenceEdit);
