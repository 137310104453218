import {validate as clientProductValidate} from './validatorClientProduct';
import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, clientProductValidate(values));
    Object.assign(errors, validators.urlOptional(values.notification_api_url, 'notification_api_url', undefined, '.+'));

    if (values.clip_lifetime) {
        Object.assign(errors, validators.digitsOnly(values.clip_lifetime, 'clip_lifetime'));
    }

    return errors;
};

export default validate;
