import {map as _map, sortBy as _sortBy, uniq as _uniq} from "lodash";
import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {gql, graphql, withApollo} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Header} from "semantic-ui-react";

import {getLink} from "../../app/components/Link";
import RightScopeStreamConstraintsTable from "../components/RightScopeStreamConstraintsTable";

import {refetchQueryByName} from "../../../utils/apollo";
import {renderModalError} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from '../../../utils/mapModulesToProps';

import {NOT_SAVED, SAVED} from "../../app/constants/messages";

import * as rightScopeStreamConstraintsGraphQl from "../../../graphql/propertyLicences/rightsScopesStreamConstraints";

class RightScopeStreamConstraints extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deviceCategories:  [],
            loading: false,
            products:  [],
            productsOriginal:  [],
            rightScopeStreamConstraints:  [],
            rightScopeStreamConstraintsOriginal:  [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
                DataRightScopeStreamConstraints: {rightsScopeStreamConstraints, rightsScope, deviceCategories, products, error}
            } = nextProps,
            nextState = {};

        if (error !== undefined) {
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError('Right scope', getLink('propertyLicences.rightsScopes.index', {
                    id: this.props.match.params.id
                }))
            });
        }

        if (deviceCategories && this.state.deviceCategories !== deviceCategories) {
            nextState.deviceCategories = deviceCategories;
        }

        if (rightsScope && products && this.state.productsOriginal !== products) {
            const usagesProducts = _uniq(_map(rightsScope.usages, 'product.id'));

            nextState.productsOriginal = products;
            nextState.products = products.filter(product => usagesProducts.includes(product.id));
        }

        if (rightsScopeStreamConstraints
            && this.state.rightsScopeStreamConstraintsOriginal !== rightsScopeStreamConstraints
        ) {
            nextState.rightsScopeStreamConstraintsOriginal = rightsScopeStreamConstraints;
            nextState.rightsScopeStreamConstraints = _sortBy(rightsScopeStreamConstraints, "product.name");
        }

        this.setState(() => (nextState));
    }

    handleStreamConstraintsAdd = () => {
        this.setState(() => ({loading: true}));

        const dataToSave = Object.assign({}, {
            rightsScopeId: convertToInt(this.props.rightsScopeId),
            productId: convertToInt(this.props.streamConstraintToSave.product),
            deviceCategoryId: convertToInt(this.props.streamConstraintToSave.device_category),
            maxBitrate: convertToInt(this.props.streamConstraintToSave.max_bitrate),
            maxResolutionWidth: convertToInt(this.props.streamConstraintToSave.max_resolution_width),
            maxResolutionHeight: convertToInt(this.props.streamConstraintToSave.max_resolution_height),
            maxPlayerSize: convertToInt(this.props.streamConstraintToSave.max_player_size)
        });
        const {
            MessageBox: { addMessage },
            Form: { clearForm }
        } = this.props;

        return this.props.CreateMutation({
            variables: {...dataToSave}
        }).then(() => {
            addMessage("propertyLicenceMessage", "The stream constraint has been saved successfully.", null,"success");
            refetchQueryByName("RightScopeStreamConstraints");

            clearForm('form_rssc_add');

            this.setState(() => ({loading: false}));
        }).catch(this.showStreamConstraintUpdateError);
    };

    handleStreamConstraintsDelete = (id) => {
        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content="Delete stream constraint"/>,
            text: `Are you sure you want to delete this stream constraint?`,
            onYes: () => (this.deleteStreamConstraint(id))
        });
    };

    handleStreamConstraintsUpdate = (event, data) => {
        this.setState(() => ({loading: true}));

        const streamConstraintToSave = this.props.streamConstraintToUpdate;
        const dataToUpdate = Object.assign({}, {
            id: convertToInt(streamConstraintToSave.id),
            productId: convertToInt(streamConstraintToSave.product || data['data-product']),
            deviceCategoryId: convertToInt(streamConstraintToSave.device_category || data['data-device']),
            maxBitrate: convertToInt(streamConstraintToSave.max_bitrate),
            maxResolutionWidth: convertToInt(streamConstraintToSave.max_resolution_width),
            maxResolutionHeight: convertToInt(streamConstraintToSave.max_resolution_height),
            maxPlayerSize: convertToInt(streamConstraintToSave.max_player_size)
        });
        const {
            MessageBox: { addMessage }
        } = this.props;

        this.props.UpdateMutation({
            variables: {...dataToUpdate}
        }).then(() => {
            addMessage("propertyLicenceMessage", SAVED('stream constraint'), null, "success");

            this.setState(() => ({loading: false}));
        }).catch(this.showStreamConstraintUpdateError);
    };

    showStreamConstraintUpdateError = (error) => {
        this.props.MessageBox.addMessage(
            "propertyLicenceMessage",
            NOT_SAVED("stream constraint"),
            `${error}`,
            "error"
        );

        this.setState(() => ({loading: false}));
    };

    deleteStreamConstraint(streamConstraintId) {
        const dataToDelete = Object.assign({}, {
            id: streamConstraintId
        });
        const {
            Modal: { setModal },
            MessageBox: { addMessage }
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: "The stream constraint is being deleted..."
        });

        return this.props.DeleteMutation({
            variables: {...dataToDelete}
        }).then(() => {
            setModal({isVisible: false});
            addMessage("propertyLicenceMessage", "The stream constraint has been deleted successfully.", null, "success");
            refetchQueryByName("RightScopeStreamConstraints");
        }).catch((error) => {
            setModal({isVisible: false});
            addMessage("propertyLicenceMessage", "The stream constraint could not be deleted.", `${error}`, "error");
        });
    }

    render() {
        const loading = this.props.DataRightScopeStreamConstraints.loading || this.state.loading;

        return(
            <div className="rightsScopeStreamContainer editableTable">
                <RightScopeStreamConstraintsTable
                    loading={loading}
                    onAdd={this.handleStreamConstraintsAdd}
                    onDelete={this.handleStreamConstraintsDelete}
                    onUpdate={this.handleStreamConstraintsUpdate}
                    deviceCategories={this.state.deviceCategories}
                    products={this.state.products}
                    rightsScopeStreamConstraints={this.state.rightsScopeStreamConstraints}
                />
            </div>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox', 'Form']);

const mapStateToProps = (state) => {
    let streamConstraintToSave = null;
    let streamConstraintToUpdate = null;

    if(state.form.form_rssc !== undefined) {
        streamConstraintToUpdate = (state.form.form_rssc.values);
    }

    if(state.form.form_rssc_add !== undefined) {
        streamConstraintToSave = (state.form.form_rssc_add.values);
    }

    return {
        streamConstraintToSave: streamConstraintToSave,
        streamConstraintToUpdate: streamConstraintToUpdate
    };
};

RightScopeStreamConstraints.propTypes = {
    match: PropTypes.object,
    Modal: PropTypes.object.isRequired,
    MessageBox: PropTypes.object.isRequired,
    DataRightScopeStreamConstraints: PropTypes.object,
    Form: PropTypes.object.isRequired,
    rightsScopeId: PropTypes.number,
    streamConstraintToSave: PropTypes.object,
    streamConstraintToUpdate: PropTypes.object,
    CreateMutation: PropTypes.func,
    DeleteMutation:PropTypes.func,
    UpdateMutation:PropTypes.func
};

const ListQuery = gql(rightScopeStreamConstraintsGraphQl.rightScopeStreamConstraint);
const CreateMutation = gql(rightScopeStreamConstraintsGraphQl.CreateMutation);
const DeleteMutation = gql(rightScopeStreamConstraintsGraphQl.DeleteMutation);
const UpdateMutation = gql(rightScopeStreamConstraintsGraphQl.UpdateMutation);

const RightScopeStreamConstraintsWithApollo = withApollo(RightScopeStreamConstraints);
const RightScopeStreamConstraintsWithStore = connect(mapStateToProps, mapDispatchToProps)(RightScopeStreamConstraintsWithApollo);

export default compose(
    graphql(ListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "network-only",
                variables: {
                    id: convertToInt(props.rightsScopeId),
                    rightsScopeId: convertToInt(props.rightsScopeId),
                },
            }
        },
        name: "DataRightScopeStreamConstraints"
    }),

    graphql(CreateMutation, { name: 'CreateMutation' }),
    graphql(DeleteMutation, { name: 'DeleteMutation' }),
    graphql(UpdateMutation, { name: 'UpdateMutation' })
)(withRouter(RightScopeStreamConstraintsWithStore));
