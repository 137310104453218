import {
    find as _find,
    get as _get,
    has as _has,
    sortBy as _sortBy,
} from 'lodash';

import {gql, withApollo} from 'react-apollo';
import DefaultForm from '@appComponents/DefaultForm';
import {
    getEventIncidentCausersQuery,
    getEventIncidentReasonsQuery,
    getStreamsQuery,
} from '@graphql/events/eventsIncident';
import {convertToInt} from '@utils/helpers';
import {getEventContentText, getEventContentVariantSteppedText} from '@utils/eventContentVariant/eventContentVariant';

export class EventsIncidentForm extends DefaultForm {
    constructor(props) {
        super(props);

        this.getScopes = this.getScopes.bind(this);
    }

    getCausersByLocation = (locationId) => {
        if (!locationId) {
            return;
        }

        this.setField(['causer', 'event_incident_reason'], {
            disabled: true,
            defaultValue: null,
        });

        this.setField('causer', {
            loading: true,
        });

        this.runApolloRequest('query', {
            query: gql(getEventIncidentCausersQuery),
            variables: {
                locationId: parseInt(locationId, 10),
            },
        }).then(data => {
            this.setField('causer', {
                disabled: false,
                loading: false,
                options: _get(data, 'data.eventIncidentCausers', []),
            });
        }).catch((error) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    'Can\'t get causers for selected location.',
                    error.message, 'error');
        });
    };

    getScopes(eventContentsArray = [], selected = {}) {
        if (0 === eventContentsArray.length) {
            this.setField('event_incident_scopes', {
                options: [],
                loading: false,
            });

            return [];
        }

        let eventContentsIds = eventContentsArray.map((value) => (parseInt(value, 10)));

        this.setField('event_incident_scopes', {
            loading: true,
        });

        this.setField('event_contents', {
            disabled: true,
            loading: true,
        });

        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: gql(getStreamsQuery),
            variables: {
                eventContents: eventContentsIds,
            },
        }).then(data => {
            this.setField('event_incident_scopes', {
                options: this.parseScopesToTree(_get(data, 'data.streams', []), selected),
                loading: false,
                disabled: false,
            });

            this.setField('event_contents', {
                disabled: false,
                loading: false,
            });
        }).catch((error) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    'Can\'t get scopes for selected events.',
                    error.message, 'error');

            this.setField('event_contents', {
                disabled: true,
                loading: false,
            });
        });
    }

    parseScopesToTree = (data = [], selected = {}) => {
        let streamsObjectTree = {};

        data.forEach((stream) => {
            let encodingTarget = _get(stream, 'encoding_target', {}),
                streamProtocol = _get(stream, 'stream_protocol', {}),
                eventContent = _get(encodingTarget, 'distribution.event_content', {}),
                eventContentType = _get(eventContent, 'event_content_type', {}),
                product = _get(encodingTarget, 'distribution.product', {}),
                distributionType = _get(encodingTarget, 'distribution.distribution_type', {}),
                streamDelayType = _get(encodingTarget, 'stream_delay_type', {});
            let key = 's-' + eventContentType.id + '-'
                + eventContent.id + '-'
                + product.id + '-'
                + distributionType.id + '-'
                + (streamDelayType ? streamDelayType.id + '-' : '')
                + encodingTarget.id;

            let sortKey = eventContentType.name
                + (eventContent.name ? ' - ' + eventContent.name : '')
                + product.id + '-'
                + distributionType.id + '-'
                + (streamDelayType ? streamDelayType.id : '');

            if (!_has(streamsObjectTree, key)) {

                const label = getEventContentVariantSteppedText(eventContent, stream.encoding_target.distribution)
                    + `${streamDelayType ? ' - ' + streamDelayType.name : ''} (${encodingTarget.id})`;

                streamsObjectTree[key] = {
                    key: key,
                    value: encodingTarget.id,
                    name: 'encoding_target',
                    label: label,
                    sortKey: sortKey,
                    children: [],
                };
            }

            const streamKey = `sp-${encodingTarget.id}-${streamProtocol.id}`;

            if (!_find(streamsObjectTree[key]['children'], {'key': streamKey})) {
                streamsObjectTree[key]['children'].push({
                    key: streamKey,
                    value: streamProtocol.id,
                    name: 'stream_protocol',
                    selected: (selected[streamKey]),
                    label: streamProtocol.name,
                });
            }
        });

        let scopesTree = Object.values(streamsObjectTree);

        return _sortBy(scopesTree, (scope) => (scope.sortKey));
    };

    getReasonsByCauser = (causerId) => {
        if (!causerId) {
            return;
        }

        this.setField('event_incident_reason', {
            disabled: true,
            loading: true,
        });

        this.runApolloRequest('query', {
            query: gql(getEventIncidentReasonsQuery),
            variables: {
                causerId: parseInt(causerId, 10),
            },
        }).then(data => {
            this.setField('event_incident_reason', {
                disabled: false,
                loading: false,
                options: _get(data, 'data.eventIncidentReasons', []),
            });
        }).catch((error) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    'Can\'t get reasons for selected causer.',
                    error.message, 'error');
        });
    };

    parseScopesTree = (scopesTree) => {
        let scopesArray = [];

        scopesTree.forEach((encodingTarget) => {
            encodingTarget.children.forEach((streamProtocol) => {
                scopesArray.push({
                    encoding_target: convertToInt(encodingTarget.value),
                    stream_protocol: convertToInt(streamProtocol.value),
                });
            });
        });

        return 0 === scopesArray.length ? null : scopesArray;
    };

    prepareDataForSubmit = (data) => {
        let dataToSave = Object.assign({}, this.props.Model.dataMap, data);

        delete (dataToSave.causer);
        delete (dataToSave.location);

        dataToSave.event_incident_reason = parseInt(data.event_incident_reason, 10);
        dataToSave.event_contents = (0 === data.event_contents.length || null === data.event_contents
            ? null
            : data.event_contents.map(eventContent => parseInt(eventContent, 10)));
        dataToSave.event_incident_scopes =
            data.affects_all_output_streams ? null : this.parseScopesTree(data.event_incident_scopes);

        if (dataToSave.lasted_entire_duration) {
            dataToSave.start_datetime = null;
            dataToSave.end_datetime = null;
        }

        return dataToSave;
    };

    componentDidMount() {
        this.setOnChangeCallback({
            location: (data) => {
                this.getCausersByLocation(data.value);
            },
            causer: (data) => {
                this.getReasonsByCauser(data.value);
            },
            lasted_entire_duration: (data) => {
                let props = {
                    disabled: false,
                    hidden: false,
                };

                if (!data.value) {
                    props = {
                        disabled: true,
                        hidden: true,
                    };
                }

                this.setField(['start_datetime', 'end_datetime'], props);
            },
            affects_all_output_streams: (data) => {
                let props = {
                    hidden: false,
                    disabled: false,
                };

                if (!data.value) {
                    props.hidden = true;
                    props.disabled = true;
                }
                this.setField('event_incident_scopes', props);
            },
            event_contents: (data) => {
                this.getScopes(data.value);
            },
        });

        this.setOptionParsers({
            event_contents: (data) => {
                let options = [];

                data.forEach((option) => {
                    options.push({
                        key: option.id,
                        value: option.id,
                        text: getEventContentText(option),
                    });
                });

                return options;
            },
        });
    }
}

export default withApollo(EventsIncidentForm);
