import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.email(values.email_address, 'email_address'));
    Object.assign(errors, validators.maxLength({value: values.name, maxLength: 50}, 'name'));
    Object.assign(errors, validators.maxLength({value: values.email_address, maxLength: 100}, 'email_address'));
    Object.assign(errors, validators.maxLength({value: values.phone_number_office, maxLength: 50}, 'phone_number_office'));
    Object.assign(errors, validators.maxLength({value: values.phone_number_mobile, maxLength: 50}, 'phone_number_mobile'));
    Object.assign(errors, validators.maxLength({value: values.skype_name, maxLength: 50}, 'skype_name'));
    Object.assign(errors, validators.maxLength({value: values.position, maxLength: 50}, 'position'));
    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.phoneNumber(values.phone_number_office, 'phone_number_office'));
    Object.assign(errors, validators.phoneNumber(values.phone_number_mobile, 'phone_number_mobile'));

    return errors;
};

export default validate;
