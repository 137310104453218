export const tournamentCategoriesQuery = `
tournamentCategories(filters: {sport: $sports}) {
    id
    name
    sport {
        id
    }
}`;

export const tournamentCategoriesFilteredBySports = `
query tournamentCategories($sports: [Int]) {
  ${tournamentCategoriesQuery}
}`;
