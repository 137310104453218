import {generateContactInformationFieldsByDataMapKey} from '@utils/fieldsGenerator';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {LICENSEE} from '@constants/resources';
import {GetLicenseeForForm} from '@graphql/licensee/query';
import {GetCountries} from '@graphql/country/query';
import {CreateLicensee, DeleteLicensee, UpdateLicensee} from '@graphql/licensee/mutation';

import validate from './validator';

export default function LicenseeModel() {
    return {
        entityDataMapKey: 'licensee',
        entityLabel: 'licensee',
        formName: 'licensee',
        label: 'Licensee',
        resources: LICENSEE,
        showChangelog: true,
        title: null,
        validate: validate,
        dataMap: {
            licensee: {
                id : '',
                name: '',
                country_id: {id : ''},
                address: '',
                email_address: '',
                phone_number: '',
                fax_number: '',
                vat_number: '',
                notes: '',
            },
        },
        messages: {
            boxName: {
                delete: 'licenseeMessage',
                error: 'licensee',
                success: 'licenseeMessage',
            },
            text: MESSAGES,
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'licensee.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'licensee.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                    autoFocus: true,
                },
            },
            address: {
                dataMapKey: 'licensee.address',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Address',
                    name: 'address',
                    row: 3,
                },
            },
            ...generateContactInformationFieldsByDataMapKey('licensee'),
            notes: {
                dataMapKey: 'licensee.notes',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Notes',
                    name: 'notes',
                    row: 3,
                },
            },
        },
        mutation: {
            createEntity: CreateLicensee,
            deleteEntity: DeleteLicensee,
            updateEntity: UpdateLicensee,
        },
        query: {
            getEntity: GetLicenseeForForm,
            getOptions: GetCountries,
        },
    };
}
