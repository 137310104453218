import {getLink} from "../../../app/components/Link";

import EventMasterBookingGeoRestrictionsGeneralInformationForm
    from "../../../events/forms/EventMasterBookingGeoRestrictions/EventMasterBookingGeoRestrictionsGeneralInformationForm";

export class ClientMasterBookingGeoRestrictionsGeneralInformationForm extends EventMasterBookingGeoRestrictionsGeneralInformationForm {
    renderErrors(errorData) {
        const link = getLink("clients.masterBookings.index", {
            id: this.props.formParams.optionsVariables.client || null,
        });

        super.renderErrors(errorData, 'Booking', link, {size: 'tiny'});
    }
}

export default ClientMasterBookingGeoRestrictionsGeneralInformationForm;
