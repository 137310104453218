import {getCurrentEnvironmentByHostname} from "./environmentHelper";
import navigation from '../constants/navigation';

export const navigationWithoutCurrentEnv = () => {
    const hostname = window.location.hostname.toLowerCase(),
        currentEnvironment = getCurrentEnvironmentByHostname(hostname);

    if (currentEnvironment.isStaging) {
        delete navigation.externalLinks.submenu['stg'];
    } else if (currentEnvironment.isDev) {
        delete navigation.externalLinks.submenu['dev'];
    } else if (currentEnvironment.isProduction) {
        delete navigation.externalLinks.submenu['prod'];
    }

    return navigation;
};