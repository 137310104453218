import classnames from 'classnames';
import React from 'react';

import SemanticSelect from '@appComponents/ReduxFormControlsComponents/SemanticSelect';

class ClientPackageDropdown extends SemanticSelect {
    render() {
        if (this.props.isCommonProduct) {
            return super.render();
        }

        return (
            <div className={classnames({
                'hidden': this.props.hidden,
                'error': this.isError(this.props),
            })}>
                {this.renderLabel(this.props)}
                <div>The bookings must belong to the same product to modify the client package.</div>
            </div>
        );
    }
}

export default ClientPackageDropdown;
