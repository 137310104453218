import PropTypes from 'prop-types';
import React from 'react';
import {Popup} from 'semantic-ui-react';

import {IconBan} from '@appComponents/IconCollection';

export const DisableCidrIcon = (props) => {
    const {onClick, onClickElement} = props;

    return (
        <Popup
            content='Disable this CIDR.'
            position='top right'
            trigger={
                <IconBan flipped='vertically' onClick={onClick} onClickElement={onClickElement} size='large'/>
            }
        />
    );
};

DisableCidrIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    onClickElement: PropTypes.object.isRequired,
};
