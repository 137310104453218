import PropTypes from 'prop-types';
import React from 'react';

import {getLink} from '@appComponents/Link';
import {renderModalError} from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import {routes} from '@constants/routes';
import {hasPrivileges} from '@appComponents/Authorization';
import * as CONST from '@constants/variables';
import * as RESOURCE from '@constants/resources';

import {TechnicalSetupProducts} from '../views/TechnicalSetupProducts';
import TechnicalSetupEdit from '../views/TechnicalSetupEdit';
import SlateLayout from '../layouts/SlateLayout';
import OverlayLayout from '../layouts/OverlayLayout';

export const createTabs = (
    propertyLicenceId,
    createdId = null,
    existingId = null,
    isTechnicalSetupCreated = false
) => {
    const technicalSetupId = convertToInt(existingId || createdId),
        renderSlateTab = technicalSetupId && hasPrivileges({[RESOURCE.SLATE]: CONST.SECURITY_PRIVILEGES_READ});
    let indexTechnicalSetupId = technicalSetupId;

    if (isTechnicalSetupCreated && !existingId) {
        indexTechnicalSetupId = null;
    }

    return {
        [routes.propertyLicences.technicalSetup.index.path]: {
            key: routes.propertyLicences.technicalSetup.index.path,
            url: 'propertyLicences.technicalSetup.index',
            urlParams: {id: propertyLicenceId},
            text: 'General settings',
            render: <TechnicalSetupEdit propertyLicenceId={propertyLicenceId} technicalSetupId={indexTechnicalSetupId}/>,
        },
        [routes.propertyLicences.technicalSetup.products.path]: {
            key: routes.propertyLicences.technicalSetup.products.path,
            url: 'propertyLicences.technicalSetup.products',
            urlParams: {id: propertyLicenceId},
            text: 'Products',
            render: technicalSetupId
                ? <TechnicalSetupProducts
                    propertyLicenceId={propertyLicenceId}
                    technicalSetupId={technicalSetupId}
                />
                : null,
            hidden: !technicalSetupId,
        },
        [routes.propertyLicences.technicalSetup.slates.index.path]: {
            key: routes.propertyLicences.technicalSetup.slates.index.path,
            url: 'propertyLicences.technicalSetup.slates.index',
            urlParams: {id: propertyLicenceId},
            text: 'Slates',
            render: renderSlateTab ? <SlateLayout technicalSetupId={technicalSetupId}/> : null,
            hidden: !renderSlateTab,
        },
        [routes.propertyLicences.technicalSetup.overlays.index.path]: {
            key: routes.propertyLicences.technicalSetup.overlays.index.path,
            url: 'propertyLicences.technicalSetup.overlays.index',
            urlParams: {id: propertyLicenceId},
            text: 'Overlays',
            render: technicalSetupId ? <OverlayLayout technicalSetupId={technicalSetupId}/> : null,
            hidden: !technicalSetupId,
        },
    };
};
createTabs.propTypes = {
    propertyLicenceId: PropTypes.number.isRequired,
    createdId: PropTypes.number,
    existingId: PropTypes.number,
    isTechnicalSetupCreated: PropTypes.bool,
};
createTabs.displayName = 'createTechnicalSetupTabs';

export const renderTechnicalSetupTabsModalError = (propertyLicenceId) => (
    renderModalError(
        'Technical setup',
        getLink('propertyLicences.technicalSetup.index', {id: propertyLicenceId}),
        null,
        'Back to general settings'
    )
);
renderTechnicalSetupTabsModalError.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
};
renderModalError.displayName = 'renderTechnicalSetupTabsModalError';
