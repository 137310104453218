import {findIndex as _findIndex, get as _get, isEmpty as _isEmpty} from "lodash";

import DefaultForm from "../../app/components/DefaultForm";

import {convertToInt} from "../../../utils/helpers";
import {refetchQueryByName} from '../../../utils/apollo';

import {DROPDOWN_DEFAULT_VALUE} from "./TechnicalSetup/Products/ProductField";

export class TechnicalSetupProductsForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetPropertyLicencesTechSetupProductsData');
        });
    }

    prepareFields = (data, actionCallback) => (
        Object.values(this.props.Model.fields)
            .filter(field => (field.props.name.startsWith("product_")))
            .forEach((productField) => {
                const productIndex = _findIndex(data, (item) => (
                        item.product.short_name === productField.props.data.product.short_name
                    )),
                    productStatusId = _get(data[productIndex], "productStatus.id", null);

                actionCallback(productStatusId, productField.props.name);
            })
    );

    setFieldCallback = (productStatusId, productFieldName) => {
        this.setField(productFieldName, {
            "dropdownDefaultValue": productStatusId || DROPDOWN_DEFAULT_VALUE,
            "dropdownHidden": !productStatusId,
            "toggleChecked": !!productStatusId,
        });
    };

    resetFieldToDefaultCallback = (productStatusId, productFieldName) => {
        if (null === productStatusId) {
            this.resetFieldToDefault(productFieldName);
        }
    };

    setDefaultDataForSubmit = (data) => {
        this.prepareFields(data, this.resetFieldToDefaultCallback);
    };

    componentDidUpdate(prevProps) {
        const techSetupProducts = this.props.GraphQLEntityData.propertyLicenceTechSetupProducts;

        if (
            (techSetupProducts !== prevProps.GraphQLEntityData.propertyLicenceTechSetupProducts)
            || (!this.state.areTogglesGenerated && !_isEmpty(techSetupProducts))
        ) {
            this.prepareFields(techSetupProducts, this.setFieldCallback);
            this.setState(() => ({areTogglesGenerated: true}));
        }
    }

    prepareDataForSubmit(data) {
        const productsWithStatus = [];

        for (const key in data) {
            if (key.match("toggle") && data[key]) {
                const productId = key.split("_").pop();

                productsWithStatus.push({
                    product: convertToInt(productId),
                    product_status: convertToInt(data[`status_${productId}`])
                })
            }
        }

        return Object.assign({}, {
            propertyLicenceTechSetup: convertToInt(data.id),
            productsWithStatus: productsWithStatus,
        });
    }

    renderCancelButton = () => null;

    renderDeleteButton = () => null;
}
