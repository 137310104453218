import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';

import {setModal, setModalConfirmation, setModalError} from '@actions';

import configuredStore from '../store';

const setModalHandler = (data) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(setModal(data));
        }
    );
};

export const setErrorModal = (data) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(setModalError(data));
        }
    );
};

export const showErrorModal = (data) => {
    setModalHandler({size: 'tiny', ...data, isVisible: true, type: 'error'});
};

export const showDarkLoadingModal = (loaderText) => {
    setModalHandler({
        content: <Loader size="massive">{loaderText}</Loader>,
        isVisible: true,
        dimmer: {className: "--dark-background-color modal--no-transition"}
    });
};

export const hideModal = () => {
    setModalHandler({
        isVisible: false,
    });
};

export const showModal = (data) => {
    setModalHandler(data);
};

export const showModalConfirmation = (data) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(setModalConfirmation(data));
        }
    );
};

const getModalLoadingContent = () => (
    <div className="content">
        <Dimmer inverted active={true}>
            <Loader/>
        </Dimmer>
    </div>
);

export const showLoadingModal = (params = {}) => {
    configuredStore.dispatch(
        function (dispatch) {
            dispatch(setModal({
                content: getModalLoadingContent(),
                isVisible: true,
                size: params.size || 'large',
            }));
        }
    );
};
