import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    if (values.report_accessibility_date) {
        Object.assign(errors, validators.date(values.report_accessibility_date, "report_accessibility_date"));
    }

    return errors;
};

export default validate;
