import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.trackNumber, 'trackNumber'));
    Object.assign(errors, validators.required(values.streamLanguage, 'streamLanguage'));

    return errors;
};

export default validate;
