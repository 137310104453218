import DefaultForm from "../../app/components/DefaultForm";
import {getLink} from "../../app/components/Link";

import {convertToInt} from "../../../utils/helpers";
import * as formUtils from "../../../utils/forms";

class ProductGeolocationsForm extends DefaultForm {
    componentDidMount() {
        this.setOnChangeCallback({
            has_geolocation_check: (data) => {
                this.handleHasGeolocationCheckChanged(!data.value);

                this.setField("geolocation_check_method", {hidden: data.value});
            },
            geolocation_check_method: (data) => {
                this.handleGeolocationCheckMethodChanged(data.value);
            }
        });

        if (this.props.formParams.id) {
            this.setField("country", {disabled: true});
        }
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const dropdownCountries = nextProps.GraphQLOptionsData.clientProductGeolocationDropdownCountries;
        const dropdownGeolocationCheckMethods = nextProps.GraphQLOptionsData.geolocationCheckMethods;

        if (dropdownCountries && this.props.GraphQLOptionsData.clientProductGeolocationDropdownCountries !== dropdownCountries) {
            this.setField("country", {options: dropdownCountries});
        }

        if (dropdownGeolocationCheckMethods && this.props.GraphQLOptionsData.geolocationCheckMethods !== dropdownGeolocationCheckMethods) {
            this.setField("geolocation_check_method", {options: dropdownGeolocationCheckMethods});
        }

        if (!nextProps.formValues.has_geolocation_check) {
            this.setField("geolocation_check_method", {hidden: true});
        }

        if(
            null !== nextProps.formParams.id
            && nextProps.GraphQLEntityData
            && !nextProps.GraphQLEntityData.loading
            && !nextProps.GraphQLOptionsData.loading
            && !this.state.dataLoaded
            && nextProps.GraphQLEntityData.clientProductGeolocationCountry
        ) {
            let clientProductGeolocationCountry = nextProps.GraphQLEntityData.clientProductGeolocationCountry;

            this.handleHasGeolocationCheckChanged(clientProductGeolocationCountry.has_geolocation_check);

            if (clientProductGeolocationCountry?.geolocation_check_method?.id) {
                this.handleGeolocationCheckMethodChanged(clientProductGeolocationCountry.geolocation_check_method.id);
            }

            this.setState(() => ({dataLoaded: true}));
        }
    }

    handleHasGeolocationCheckChanged = (value) => {
        this.setField("has_geolocation_check", {
            checked: value,
            defaultValue: value,
        });
    };

    handleGeolocationCheckMethodChanged = (value) => {
        this.setField("geolocation_check_method", {
            defaultValue: value,
        });
    };

    prepareDataForSubmit = (data) => {
        if (!data) {
            return {};
        }

        let result = {
            enforcement_date: data.enforcement_date ? data.enforcement_date : null,
            has_geolocation_check: data.has_geolocation_check,
            country_id: convertToInt(data.country),
            client_product_id: this.props.formParams.clientProductId,
            geolocation_check_method_id: data.has_geolocation_check ? convertToInt(data.geolocation_check_method) : null,
        };

        if (data.id) {
            result.id = convertToInt(data.id)
        }

        return result;
    };

    renderErrors(errorData) {
        const errors = errorData.graphQLErrors;

        if (1 === errors.length && "ClientProductGeolocationCountry not found." === errors[0].message) {
            this.props.Modal.setModal({
                isVisible: true,
                content: formUtils.renderModalError(
                    'Country',
                    getLink(`clients.products.${this.props.formParams.productShortName}.geolocation.index`, {
                        id: this.props.formParams.clientId || null,
                    })
                ),
            });
        } else {
            super.renderErrors(errorData);
        }
    }
}

export default ProductGeolocationsForm;
