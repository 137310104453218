import {GetClipsForForm} from '@graphql/eventEventBooking/query';
import {UpdateEventBooking, DeleteEventBooking} from '@graphql/eventEventBooking/mutation';

import EventBookingClipsModel from './EventBookingClipsModel';

export default function EventBookingClipsEditModel() {
    const eventBookingClipsEditModel = EventBookingClipsModel();

    eventBookingClipsEditModel.deleteId = 'eventEventBooking.id';
    eventBookingClipsEditModel.editForm = true;
    eventBookingClipsEditModel.mutation = {
        updateEntity: UpdateEventBooking,
        deleteEntity: DeleteEventBooking,
    };
    eventBookingClipsEditModel.query.getEntity = GetClipsForForm;

    return eventBookingClipsEditModel;
}
