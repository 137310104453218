import PropTypes from 'prop-types';
import React from 'react';
import {Segment} from "semantic-ui-react";
import {withRouter} from "react-router";

import ProductLctGeoRestrictionsForm from "../forms/ProductLctGeoRestrictionsForm";
import ProductLctGeoRestrictionsModel from "../forms/ProductLctGeoRestrictionsModel";

import {createForm} from "../../../utils/forms";

export class ProductLctGeoRestrictionsEdit extends React.PureComponent {
    static propTypes = {
        clientProductId: PropTypes.number.isRequired,
    };

    renderForm = () => {
        const Form = createForm(
            ProductLctGeoRestrictionsModel,
            ProductLctGeoRestrictionsForm, {
                dataRequest: true,
                id: this.props.clientProductId,
            });

        return <Form/>;
    };

    render() {
        return (
            <div className="client_products_geo_restrictions">
                <Segment basic>
                    {this.renderForm()}
                </Segment>
            </div>
        );
    }
}

export default withRouter(ProductLctGeoRestrictionsEdit)
