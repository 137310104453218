import React from 'react';

import {lintToAllContent} from "../utils/propertyLicenceHelper";

export const contentRows = (row) => row.contents.map(function (content) {
    let label = "";
    let parentLabel = "";

    if (content.content_category) {
        label = content.content_category.name;

        if (null !== content.content_category.parent) {
            parentLabel = content.content_category.parent.name;
        }
    } else if (content.tournament) {
        label =  content.tournament.name;

        if (null !== content.tournament.tournament_category) {
            parentLabel = content.tournament.tournament_category.name
        }
    } else {
        return false;
    }

    const contentRows = `${parentLabel} > ${label} (${content.content_tier.name})`;

    return {
        text: contentRows,
        html: (<div key={`PropertyLicenceContent_${content.id}`}>{contentRows}</div>)
    };
});

const PropertyLicenceContentsRow = (row) => {
    if (null === row.contents) {
        return null;
    }

    const sliceContentCount = 10;

    const sortedContentToRender = [].concat(contentRows(row).map(el => el.html)).sort((a, b) => (
        a.props.children.toLowerCase() > b.props.children.toLowerCase() ? 1 : -1
    ));

    if (sliceContentCount >= sortedContentToRender.length) {
        return (
            <div>
                {sortedContentToRender}
            </div>
        );
    }

    return (
        <div>
            {sortedContentToRender.slice(0, sliceContentCount)}
            {lintToAllContent(sortedContentToRender.length - sliceContentCount, row.id)}
        </div>
    );
};

export default PropertyLicenceContentsRow;
