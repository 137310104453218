import {
    GetClientProductLctForForm,
    GetClientProductLctOptions
} from '@graphql/clientProductLct/query';
import {
    CreateClientProductLct,
    DeleteClientProductLct,
    UpdateClientProductLct
} from '@graphql/clientProductLct/mutation';

import ClientProductModel from './ClientProductModel';
import validate from './validatorClientProductLct';

export default function ClientProductLctModel() {
    let clientProductLctModel = ClientProductModel();

    clientProductLctModel.deleteId = "clientProductsLct.0.id";
    clientProductLctModel.entityDataMapKey = "clientProductsLct.0";
    clientProductLctModel.entityLabel = "product LCT";
    clientProductLctModel.validate = validate;
    clientProductLctModel.query = {
        getEntity: GetClientProductLctForForm,
        getOptions: GetClientProductLctOptions,
    };
    clientProductLctModel.mutation = {
        createEntity: CreateClientProductLct,
        deleteEntity: DeleteClientProductLct,
        updateEntity: UpdateClientProductLct,
    };

    clientProductLctModel.dataMap = {
        clientProductsLct: {
            id: "",
            ...clientProductLctModel.dataMap
        },
    };

    clientProductLctModel.remapBaseDatamapKeys(clientProductLctModel);

    return clientProductLctModel;
}
