import React from "react";
import PropTypes from "prop-types";

import {getEventContentTypeName} from "../../../liveControl/utils/helpers";
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup'

/**
 * Information about
 * - event content type name
 * @param event
 */
export const EventContentType = ({event}) => {
    const eventContentTypeName = getEventContentTypeName(event);
    const eventContentVariant = {
        id: event.event_content_variant_id,
        name: event.event_content_variant_name,
        short_name: event.event_content_variant_short_name,
    };

    return (
        <div className="--paddingLeft-0-2-em">
            {eventContentTypeName}
            {` (${event.event_content_id})`}
            <EventContentVariantPopup eventContentVariant={eventContentVariant}/>
        </div>
    )
};

EventContentType.propTypes = {
    event: PropTypes.shape({
        event_content_id: PropTypes.number.isRequired,
        event_content_type_name: PropTypes.string.isRequired,
        event_content_name: PropTypes.string,
        event_content_variant_id: PropTypes.number,
        event_content_variant_name: PropTypes.string,
        event_content_variant_short_name: PropTypes.string,
    }).isRequired
};
