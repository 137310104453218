import {isEmpty as _isEmpty, union as _union} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {Form as SemanticForm} from "semantic-ui-react";

import Form from "../../app/components/ReduxFormControls";
import {hasPrivileges} from "../../app/components/Authorization";

import {generatePrivileges} from "../../role/forms/helpers/roleResourceModelHelper";
import {prepareCheckBoxOptions} from "../forms/helpers/editUserResourceFormHelper";

import {USER} from "../../app/constants/resources";
import {SECURITY_PRIVILEGES_UPDATE} from "../../app/constants/variables";

export const UserResourceField = ({
    data: {user_resources = {}, roles_resources = {}, securedResource = {}},
    checkboxesHidden = true,
    toggleChecked = false,
    disabled = false,
}) => {
    if (_isEmpty(securedResource)) {
        return (
            <React.Fragment />
        )
    }

    const [isCheckboxesHidden, setIsCheckboxesHidden] = React.useState(true),
        [isToggleChecked, setIsToggleChecked] = React.useState(false),
        resourceId = securedResource.id;

    let defaultPrivilegesValue = generatePrivileges(roles_resources, resourceId);

    const hiddenPrivileges = defaultPrivilegesValue,
        options = prepareCheckBoxOptions(resourceId, defaultPrivilegesValue);

    React.useEffect(() => {
        setIsToggleChecked(toggleChecked)
        changeCheckboxesVisibility({checked: toggleChecked})
    }, [toggleChecked]);

    React.useEffect(() => {
        setIsCheckboxesHidden(checkboxesHidden)
    }, [checkboxesHidden]);

    const changeCheckboxesVisibility = ({checked}) => {
        setIsCheckboxesHidden((true !== checked))
    };

    const switchToggle = (props, {checked}) => {
        setIsToggleChecked(checked)
    };

    if (user_resources) {
        defaultPrivilegesValue = _union(generatePrivileges(user_resources, resourceId), defaultPrivilegesValue);
    }

    return (
        <SemanticForm.Group inline>
            <Form.FormField
                defaultValue={isToggleChecked}
                checked={isToggleChecked}
                disabled={disabled}
                component={Form.SemanticInput}
                label={securedResource.name}
                divClassName={'--auto_width --flex --paddingRight-3rem --toggle-label-padding-top-0'}
                id={securedResource.id}
                inline
                name={`toggle_${securedResource.id}`}
                onCheckboxChange={changeCheckboxesVisibility}
                onClick={switchToggle}
                type={"toggle"}
            />
            <Form.FormField
                component={Form.SemanticCheckboxList}
                className={"--checkbox-padding-top-0"}
                defaultValue={defaultPrivilegesValue}
                value={defaultPrivilegesValue}
                hidden={isCheckboxesHidden}
                disabled={!hasPrivileges({
                    [USER]: SECURITY_PRIVILEGES_UPDATE
                })}
                inline
                name={`privilege_${securedResource.id}`}
                options={options}
            />
            <Form.FormField
                component={Form.SemanticCheckboxList}
                defaultValue={hiddenPrivileges}
                value={hiddenPrivileges}
                hidden={true}
                inline
                name={`privilege_hidden_${securedResource.id}`}
                options={options}
            />
        </SemanticForm.Group>
    )
}
UserResourceField.propTypes = {
    checkboxesHidden: PropTypes.bool,
    data: PropTypes.shape({
        user_resources: PropTypes.array,
        roles_resources: PropTypes.array,
        securedResource: PropTypes.object,
    }),
    toggleChecked: PropTypes.bool,
    disabled: PropTypes.bool,
};
