import PropTypes from 'prop-types';
import React from 'react';

import Table from '@appComponents/Table';
import {rowRenderer} from '@modules/client/utils/lcppClientNotificationLog';

import {getEventLcppClientNotificationLogColumns} from '../utils/eventsLcppClientNotificationLog';

const EventsLcppClientNotificationLogTable = ({lcppClientNotificationsLog = [], loading}) => (
    <Table
        columns={getEventLcppClientNotificationLogColumns()}
        data={lcppClientNotificationsLog}
        loading={loading}
        name='NotificationHistoryList'
        noDataText='No notification history found'
        rowRenderer={rowRenderer}
    />
);

EventsLcppClientNotificationLogTable.propTypes = {
    lcppClientNotificationsLog: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

EventsLcppClientNotificationLogTable.displayName = 'EventsLcppClientNotificationLogTable';

export default EventsLcppClientNotificationLogTable;
