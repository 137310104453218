import * as actionTypes from '../actions/actionTypes';

export const initialState = {
    timezone: "",
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const timezoneReducer = (state = initialState, action = null) => {
    switch (action.type) {
        case actionTypes.SET_TIMEZONE: {
            return Object.assign({}, state, {timezone: action.data});
        }

        default:
            return state
    }
};

export default timezoneReducer;
