import validators from "../../../utils/validators";

const validate = (values, props) => {
    const errors = {};

    Object.assign(errors, validators.alphanumeric(values.code, 'code'));

    if (props.formData.clientLabels.filter((clientLabel) => (
        clientLabel.code === values.code && clientLabel.id !== values.id
    )).length > 0) {
        Object.assign(errors, {'code': 'Code needs to be unique.'});
    }

    Object.assign(errors, validators.required(values.name, 'name'));

    return errors;
};

export default validate;
