import React from "react";

import * as CONST from "../../app/constants/variables";
import * as RESOURCES from "../../app/constants/resources";
import { ButtonAdd } from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";
import Authorization from "../../app/components/Authorization";

const CompetitorsIndexButtons = () => {
    return (
        <div className="content__buttons">
            <Authorization resources={RESOURCES.AV_COMPETITOR} privileges={CONST.SECURITY_PRIVILEGES_CREATE}>
                <Link name="competitors.add">
                    <ButtonAdd>{"Add new competitor"}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    )
};

export default CompetitorsIndexButtons;
