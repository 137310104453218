import React from 'react';
import {Icon, Message} from 'semantic-ui-react';

export const IpBlacklistingIndexInfoMessage = () => {
    return (
        <Message icon info>
            <Icon name='info circle'/>
            <Message.Content>
                This blacklist is for disallowing stream URL generation in the Client APIs for some IP addresses.
                The end-users behind these IP addresses will not be able to stream any content booked by clients.
                <br/>
                This feature is not for blacklisting the IP addresses of the client servers which need to access the
                Client APIs!
            </Message.Content>
        </Message>
    );
};
