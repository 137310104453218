import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';

import {renderRoleModalError} from '../utils/roleTabsLayoutHelper';

export class RoleForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('roles.resources.index', {
                id: response.data.createRole.id,
            }));
        });
        this.setUpdateSuccessCallback(() => {
            this.props.history.push(getLink('roles.edit', {
                id: this.props.match.params.id,
            }));
        });
    }

    prepareDataForSubmit = (data) => {
        return Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data);
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderCancelButton = () => null;

    renderDeleteButton = () => null;

    static getDerivedStateFromProps(nextProps) {
        const showModal = Boolean(_get(nextProps, 'GraphQLEntityData.error'));

        if (showModal) {
            nextProps.Modal.setModal({
                isVisible: true,
                content: renderRoleModalError(),
            });
        }

        return {showModal};
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();
        super.receivedPropsValidator(prevProps);
    }
}

export default withRouter(RoleForm);
