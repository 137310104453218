import PropTypes from 'prop-types';
import React from 'react';

import * as RESOURCE from '@constants/resources';
import {routes} from '@constants/routes';

import RightsScopeEdit from '../views/RightsScopeEdit';
import RightsScopeGeoRestrictionsEdit from '../views/RightsScopeGeoRestrictionsEdit';
import RightScopeStreamConstraints from '../views/RightScopeStreamConstraints';

export const createTabs = (id, path, rightsScopeId = null) => {
    const rightsScopeRoutes = routes.propertyLicences.rightsScopes;

    return (rightsScopeRoutes.add.path === path)
        ? {
            [rightsScopeRoutes.add.path]: {
                key: rightsScopeRoutes.add.path,
                url: 'propertyLicences.rightsScopes.add',
                urlParams: {id},
                text: 'General information',
                render: <RightsScopeEdit/>,
                resource: RESOURCE.RIGHT_SCOPE,
            },
        }
        : {
            [rightsScopeRoutes.editGeneralInformation.path]: {
                key: rightsScopeRoutes.editGeneralInformation.path,
                url: 'propertyLicences.rightsScopes.editGeneralInformation',
                urlParams: {id, rightsScopeId},
                text: 'General information',
                render: <RightsScopeEdit/>,
                resource: RESOURCE.RIGHT_SCOPE,
            },
            [rightsScopeRoutes.editGeoRestrictions.path]: {
                key: rightsScopeRoutes.editGeoRestrictions.path,
                url: 'propertyLicences.rightsScopes.editGeoRestrictions',
                urlParams: {id, rightsScopeId},
                text: 'Geo restrictions',
                render: <RightsScopeGeoRestrictionsEdit/>,
                resource: RESOURCE.RIGHT_SCOPE,
            },
            [rightsScopeRoutes.editStreamConstraints.path]: {
                key: rightsScopeRoutes.editStreamConstraints.path,
                url: 'propertyLicences.rightsScopes.editStreamConstraints',
                urlParams: {id, rightsScopeId},
                text: 'Stream constraints',
                render: <RightScopeStreamConstraints rightsScopeId={parseInt(rightsScopeId)}/>,
                resource: RESOURCE.PROPERTY_LICENCE_STREAM_CONSTRAINT,
            },
        };
};
createTabs.propTypes = {
    id: PropTypes.string.isRequired,
    rightsScopeId: PropTypes.string,
    path: PropTypes.string.isRequired,
};
createTabs.displayName = 'createRightsScopeTabs';
