import PropTypes from 'prop-types';

import Form from '@appComponents/ReduxFormControls';

export const getUserFieldsCommonProps = (key) => {
    const userFieldsCommonProps = {
        iam_first_name_text: {
            component: Form.TextPlaceholder,
            label: 'First name',
            name: 'iam_first_name',
            type: 'text',
        },
        iam_surname_text: {
            component: Form.TextPlaceholder,
            label: 'Surname',
            name: 'iam_surname',
            type: 'text',
        },
        iam_nickname_text: {
            component: Form.TextPlaceholder,
            label: 'Nickname',
            name: 'iam_nickname',
            type: 'text',
        },
        iam_email_text: {
            component: Form.EmailPlaceholder,
            label: 'Email',
            name: 'iam_email',
            type: 'text',
        },
        name: {
            hidden: true,
            label: '',
            name: 'name',
        },
        email: {
            hidden: true,
            label: '',
            name: 'email',
        },
        roles: {
            component: Form.SemanticCheckboxList,
            label: 'Roles',
            name: 'roles',
            required: true,
        },
        has_ava_admin_access: {
            component: Form.SemanticInput,
            label: 'AVA admin access',
            name: 'has_ava_admin_access',
            type: 'toggle',
            checked: false,
        },
        last_login_ip: {
            component: Form.TextPlaceholder,
            label: 'Last login',
            name: 'last_login_ip',
            type: 'text',
        },
    };

    return userFieldsCommonProps[key];
};

getUserFieldsCommonProps.propTypes = {
    key: PropTypes.string.isRequired,
};

export const getUserAccessInformationCommonProps = (key, props={}) => {
    const userAccessInformationCommonProps = {
        access_request_reference: {
            component: Form.SemanticInput,
            label: 'Access request reference',
            name: 'access_request_reference',
            type: 'input',
            ...props,
        },
        job_description: {
            component: Form.SemanticInput,
            label: 'Job description',
            name: 'job_description',
            type: 'input',
            ...props,
        },
        manager: {
            component: Form.SemanticInput,
            label: 'Manager',
            name: 'manager',
            type: 'input',
            ...props,
        },
        additional_info: {
            component: Form.SemanticTextArea,
            label: 'Additional information',
            name: 'additional_info',
            type: 'input',
            ...props,
        },
    };

    return userAccessInformationCommonProps[key];
};

getUserAccessInformationCommonProps.propTypes = {
    key: PropTypes.string.isRequired,
    props: PropTypes.object,
};
