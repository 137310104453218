import {get as _get} from "lodash";

import SportradarNonExistingEventModel from "./SportradarNonExistingEventModel";
import * as eventsGraphQl from "../../../graphql/events/events";
import Form from "../../app/components/ReduxFormControls";

export default function SportradarNonExistingEventEditModel() {
    const SportradarNonExistingEventEditModel = SportradarNonExistingEventModel(true);

    SportradarNonExistingEventEditModel.query.getEntity = eventsGraphQl.event;
    SportradarNonExistingEventEditModel.fields.property_licence.props.disabled = true;
    SportradarNonExistingEventEditModel.editForm = true;
    SportradarNonExistingEventEditModel.mutation.deleteEntity = eventsGraphQl.srEventDeleteMutation;
    SportradarNonExistingEventEditModel.fields.id = {
        dataMapKey: "event.sr_event.id",
        props: {
            component: Form.SemanticInput,
            hidden: true,
            name: "id"
        }
    };
    SportradarNonExistingEventEditModel.fields.court.props.disabled = false;
    SportradarNonExistingEventEditModel.fields.end_datetime.props.disabled = false;
    SportradarNonExistingEventEditModel.fields.status.props.hidden = true;

    SportradarNonExistingEventEditModel.title
        = (data) => `Edit Sportradar event ${_get(data, "event.id", null)}`;

    return SportradarNonExistingEventEditModel;
}
