import classNames from 'classnames';
import {
    get as _get,
    has as _has,
    isArray as _isArray,
    intersectionWith as _intersectionWith,
    isBoolean as _isBoolean,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    isNil as _isNil,
    isObject as _isObject,
    isString as _isString,
    isFunction as _isFunction,
    sortBy as _sortBy,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Table as SemanticTable, Menu, Icon, Loader, Dimmer, Dropdown, Checkbox, Message} from 'semantic-ui-react';

import {
    getParamFromSearchUrl,
    setParamsToSearchUrl,
    urlSearchStringToObject,
} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {TABLE_DATA_LIMIT} from '@constants/variables';
import {setTableParamsToStore} from '@actions';

import TableRow from './TableComponents/Row';
import appConfig from '../../../avcmpConfig';
import MessageBox from './MessageBox';

export const ASC = 'ASC',
    DESC = 'DESC';

const DEFAULT_PARAMS_CLIP_CONFIG= {
    order: ASC,
    orderBy: 'name',
};

/**
 * @ignore
 */
export class Table extends React.Component {
    static propTypes = {
        adjustHeight: PropTypes.func,
        className: PropTypes.string,
        columns: PropTypes.object.isRequired,
        data: PropTypes.array,
        dataOriginal: PropTypes.array,
        dataLimitWarning: PropTypes.number,
        defaultSort: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        editable: PropTypes.bool,
        getSelectedElements: PropTypes.func,
        isCompact: PropTypes.bool,
        limit: PropTypes.number,
        loading: PropTypes.bool,
        match: PropTypes.object,
        multiple: PropTypes.bool,
        name: PropTypes.string.isRequired,
        noDataText: PropTypes.string,
        noMessageBox: PropTypes.bool,
        pagination: PropTypes.bool,
        rowClassname: PropTypes.func,
        rowOnClick: PropTypes.func,
        rowRenderer: PropTypes.func,
        rowRendererProps: PropTypes.object,
        selectable: PropTypes.bool,
        selected: PropTypes.array,
        showLimitWarning: PropTypes.bool,
        size: PropTypes.string,
        structured: PropTypes.bool,
        setTableParamsToStore: PropTypes.func.isRequired,
        url: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string,
        }),
        defaultSortDirection: PropTypes.string,
    };

    static defaultProps = {
        className: null,
        dataOriginal: [],
        defaultSort: null,
        getSelectedElements: () => null,
        isCompact: true,
        noDataText: 'No data found',
        rowRendererProps: null,
        size: 'small',
        structured: false,
    };

    constructor(props) {
        super(props);

        let data = [];

        if (props.data && 0 < props.data.length) {
            data = props.data;
        }

        const showLimitWarning = _get(this.props, 'showLimitWarning', false),
            limit = this.getLimitPerPage();

        let order, orderBy;

        if ('/clip-configuration/:id' !== props.match.path) {
            order = this.getOrder();
            orderBy = this.getOrderBy();
        }

        this.state = {
            url: props.url || props.match.path,
            column: orderBy,
            direction: order,
            page: this.getPage(),
            limit: limit,
            data: data,
            dataReceived: [],
            showLimitWarning: showLimitWarning,
            selected: this.props.selected || [],
            maxPageCount: 1,
            listIndexUpperLimit: 0,
            preventSetTableState: false,
        };

        if ('/clip-configuration' !== props.match.path) {
            orderBy && setParamsToSearchUrl({orderBy});
            order && setParamsToSearchUrl({order});
        }

        if (limit && limit !== appConfig.app.defaultLimitItemsOnList) {
            setParamsToSearchUrl({pageLimit: limit});
        }

        showLimitWarning && data.length >= props.dataLimitWarning && this.showLimitWarningBox(data);

        this.sortData = this.sortData.bind(this);
        this.toggleRows = this.toggleRows.bind(this);
        this.changeLimit = this.changeLimit.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changePageButton = this.changePageButton.bind(this);
        this.changePageDropdownAction = this.changePageDropdownAction.bind(this);
        this.selectRow = this.selectRow.bind(this);

        if (this.props.getSelectedElements) {
            this.props.getSelectedElements(this.state.selected);
        }
    }

    getPage() {
        let page = getParamFromSearchUrl('page', 'int');

        const savedPage = _get(this.props, 'tableParams.page', null);

        if (null == page && savedPage) {
            page = savedPage;
        }

        return page ? page : 1;
    }

    getOrderBy() {
        let orderBy = getParamFromSearchUrl('orderBy');

        const savedOrderBy = _get(this.props, 'tableParams.orderBy', null);

        if (null == orderBy && savedOrderBy) {
            orderBy = savedOrderBy;
        }

        if (!_get(this.props, `columns.${orderBy}`, false)) {
            return null;
        }

        return orderBy;
    }

    getOrder() {
        let order = getParamFromSearchUrl('order');

        const savedOrder = _get(this.props, 'tableParams.order', null);

        if (null == order && savedOrder) {
            order = savedOrder;
        }

        if (ASC !== order && DESC !== order) {
            return null;
        }

        return order;
    }

    getLimitPerPage() {
        const limit = getParamFromSearchUrl('pageLimit', 'int'),
            savedLimit = _get(this.props, 'tableParams.limit', null);

        if (limit) {
            return limit;
        } else if (savedLimit) {
            return savedLimit;
        }

        return appConfig.app.defaultLimitItemsOnList;
    }

    parseValueForSort = (value) => {
        let sortValue = value;

        if (_has(value, 'name')) {
            sortValue = _get(value, 'name', null);
        } else if (_isArray(value)) {
            sortValue = value.join('-');
        } else if ('' === value || undefined === value) {
            sortValue = null;
        }

        if (_isString(sortValue)) {
            sortValue = sortValue.toLowerCase();
        } else if (_isObject(sortValue)) {
            sortValue = null;
        }

        return sortValue;
    };

    getSortValue = (valueA, valueB, sortDirection) => {
        if ((null === valueB) && !_isBoolean(valueB)) {
            return -1;
        }

        if ((null === valueA) && !_isBoolean(valueA)) {
            return 1;
        }

        return this.getSortValueDirection(valueA, valueB, sortDirection);
    };

    getSortValueDirection = (valueA, valueB, sortDirection) => {
        if (valueA > valueB) {
            return (DESC === sortDirection) ? -1 : 1;
        }

        if (valueA < valueB) {
            return (DESC === sortDirection) ? 1 : -1;
        }

        return 0;
    };

    /**
     * Method to sort tables
     * should be passed to every sorting column
     *
     * Rules:
     * 1) When row[column] is a string or object with 'name' property pass "sorting: true"
     * 2) When row[column] is an object specify sorting function which returns the same string
     *  which is displayed in a cell
     * 3) Use getParsedId function from helpers.js when possible
     */

    sortData(column, data, returnInversed, sort = null) {
        if (!data) {
            return [];
        } else if (!column) {
            return Array.from(data);
        }

        const sortDirection = sort || this.state.direction,
            dataToSort = Array.from(data);

        const sortedData = [...dataToSort].sort(function (a, b) {
            let valueA = a[column],
                valueB = b[column];

            if (this.props.columns[column] && 'function' === typeof (this.props.columns[column].sorting)) {
                valueA = this.props.columns[column].sorting(a);
                valueB = this.props.columns[column].sorting(b);
            }

            valueA = this.parseValueForSort(valueA);
            valueB = this.parseValueForSort(valueB);

            return this.getSortValue(valueA, valueB, sortDirection);
        }.bind(this));

        if (DESC === sortDirection) {
            const nonEmpty = sortedData.filter(
                el => null !== el[column] && el[column] !== undefined && !_isBoolean(el[column])
            );

            for (let i = 0; i < nonEmpty.length; i++) {
                sortedData[i] = nonEmpty[i];
            }
        }

        return sortedData;
    }

    handleSort(column) {
        if (!column) {
            if (!this.state.column && this.props.defaultSort) {
                if (_isFunction(this.props.defaultSort)) {
                    this.setState(() => ({
                        data: _sortBy(this.state.data, [this.props.defaultSort]),
                    }));

                    return;
                }

                this.handleSort(this.props.defaultSort);
            } else if (this.state.column) {
                this.setParamsToSearchUrlFromState();
            }
        } else {
            let direction = ASC;

            if (column === this.state.column) {
                direction = (DESC === this.state.direction) ? ASC : DESC;
            } else if (column === this.props.defaultSort && this.props.defaultSortDirection) {
                direction = this.props.defaultSortDirection;
            }

            const tableParams = {
                orderBy: column,
                order: direction,
            };

            this.setState(
                function (state) {
                    return {
                        column: column,
                        direction: direction,
                        data: this.sortData(column, state.data, state.column === column, direction),
                    };
                },
                'function' === typeof _get(this.props, 'adjustHeight', null)
                    ? this.props.adjustHeight
                    : null
            );

            setParamsToSearchUrl(tableParams);
            this.props.setTableParamsToStore(tableParams);
        }
    }

    setParamsToSearchUrlFromState = () => {
        const additionalParams = {},
            pathName = window.location.pathname;

        if (1 < this.state.page) {
            additionalParams['page'] = this.state.page;
        }

        if (appConfig.app.defaultLimitItemsOnList !== this.state.limit) {
            additionalParams['pageLimit'] = this.state.limit;
        }

        if ('/clip-configuration' === pathName) {
            const anyDefaultParamsChanged = DEFAULT_PARAMS_CLIP_CONFIG.order !== this.state.direction
                || DEFAULT_PARAMS_CLIP_CONFIG.orderBy !== this.state.column;

            setParamsToSearchUrl({
                orderBy: anyDefaultParamsChanged ? this.state.column : null,
                order: anyDefaultParamsChanged ? this.state.direction : null,
                ...additionalParams,
            });

            return;
        } else if ('/clip-configuration/add-on-sport-level' === pathName
            || '/clip-configuration/add-on-tournament-level' === pathName
            || '/clip-configuration/:id' === this.props.match.path) {
            setParamsToSearchUrl({
                orderBy: null,
                order: null,
                ...additionalParams,
            });

            return;
        }

        setParamsToSearchUrl({
            orderBy: this.state.column,
            order: this.state.direction,
            ...additionalParams,
        });
    };

    toggleRows() {
        const toggledRows = [];

        if (this.state.selected.length !== this.state.data.length) {
            this.state.data.forEach((row) => {
                toggledRows.push(row.id);
            });
        }

        this.setState(() => ({
            preventSetTableState: true,
            selected: toggledRows,
        }), () => {
            if (this.props.getSelectedElements) {
                this.props.getSelectedElements(toggledRows);
            }
        });
    }

    renderHeaderRow() {
        const headerContent = [];
        let selectAllCheckbox = null;

        if (this.props.multiple) {
            const intersection = _intersectionWith(
                this.state.data,
                this.state.selected,
                (data, selectedId) => (selectedId === data.id)
            );

            selectAllCheckbox = (
                <Checkbox
                    className='--checkbox-small'
                    onClick={this.toggleRows}
                    checked={this.state.data.length === intersection.length}
                    indeterminate={
                        0 < intersection.length
                        && intersection.length !== this.state.data.length
                    }
                />
            );
        }

        if (this.props.selectable) {
            headerContent.push(
                <SemanticTable.HeaderCell key={'select-all'} textAlign='center'>
                    {selectAllCheckbox}
                </SemanticTable.HeaderCell>
            );
        }

        for (const columnKey in this.props.columns) {
            const column = this.props.columns[columnKey],
                isSortingColumn = (undefined !== column.sorting && column.sorting),
                rowProps = {};
            let sortIcon = null;

            if (columnKey === this.state.column) {
                const iconDirection = (DESC === this.state.direction) ? 'down' : 'up';
                sortIcon = <Icon className={`table__sort triangle ${iconDirection}`}/>;
            } else {
                if (isSortingColumn) {
                    sortIcon = <Icon className='table__sort sort --unordered'/>;
                }
            }

            const textAlign = this.getSettingForColumn(columnKey, 'header.align'),
                isCollapsed = this.getSettingForColumn(columnKey, 'header.isCollapsed'),
                isHidden = this.getSettingForColumn(columnKey, 'isHidden', false),
                headerClassName = this.getSettingForColumn(columnKey, 'header.className', ''),
                className = classNames(sortIcon ? '--sortable' : '--unsortable', headerClassName);

            if (!isHidden) {
                headerContent.push(
                    <SemanticTable.HeaderCell
                        textAlign={textAlign}
                        collapsing={isCollapsed}
                        key={this.props.name + columnKey}
                        {...rowProps}
                        onClick={(isSortingColumn) ? this.handleSort.bind(this, columnKey) : null}
                        className={className}
                    >
                        <div className='table__header-cell-wrapper'>
                            <span className='table__header-name'>
                                {'function' === typeof column.label ? column.label() : column.label}
                                {column.subLabel
                                    ? <span><br/>{column.subLabel}</span>
                                    : ''
                                }
                            </span>
                            {sortIcon}
                        </div>
                    </SemanticTable.HeaderCell>
                );
            }
        }

        return (
            headerContent
        );
    }

    selectRow(data) {
        if (this.props.rowOnClick !== undefined) {
            this.props.rowOnClick(data);
        }

        if (!this.props.selectable && this.props.rowOnClick === undefined) {
            return;
        }
    }

    editRow(id) {
        if (!this.props.editable) {
            return;
        }
        this.props.editable(id);

    }

    onChangeCheckbox = (event, data) => {
        const indexOfElement = this.state.selected.indexOf(data['data-id']);
        let selectedElements = this.state.selected;

        if (undefined !== this.props.multiple && this.props.multiple) {
            if (0 <= indexOfElement) {
                selectedElements.splice(indexOfElement, 1);
            } else {
                selectedElements.push(data['data-id']);
            }
        } else {
            selectedElements = [data['data-id']];
        }

        if (this.props.getSelectedElements) {
            this.props.getSelectedElements(selectedElements);
        }

        this.setState(() => ({
            preventSetTableState: true,
            selected: selectedElements,
        }));
    };

    getSettingForColumn = (columnKey, path, defaultValue = null) => (
        _get(this.props, `columns.${columnKey}.${path}`, defaultValue)
    );

    renderDataRows() {
        const data = this.state.data;

        if (data === undefined || null === data || 0 === data.length) {
            return null;
        }

        const dataRows = [];

        let startIndex = 0,
            limitForLoop = this.state.data.length;

        if (false !== this.props.pagination) {
            startIndex = (this.state.page * this.state.limit - this.state.limit);
            limitForLoop = startIndex + this.state.limit;

            if (limitForLoop > this.state.data.length) {
                limitForLoop = this.state.data.length;
            }
        }

        for (let i = startIndex; i < limitForLoop; i++) {
            const dataRow = [],
                rowClassNames = [];

            if (this.props.selectable) {
                dataRow.push(
                    <SemanticTable.Cell key={`select-${data[i].id}`} textAlign='center'>
                        <Checkbox
                            checked={0 <= this.state.selected.indexOf(data[i].id)}
                            className='--checkbox-small'
                            data-id={data[i].id}
                            onChange={this.onChangeCheckbox}
                        />
                    </SemanticTable.Cell>
                );
            }

            for (const columnKey in this.props.columns) {
                let cellContent = null;

                if ('function' === typeof (this.props.rowRenderer)) {
                    cellContent = this.props.rowRenderer(columnKey, data[i], this.props.rowRendererProps);
                }

                if (null === cellContent) {
                    cellContent = data[i][columnKey];

                    if (_isObject(cellContent)) {
                        cellContent = _get(cellContent, 'name', '');
                    }
                }

                if (cellContent === undefined) {
                    cellContent = <div>Missing settings for this cell</div>;
                }

                const textAlign = this.getSettingForColumn(columnKey, 'content.align'),
                    singleLine = this.getSettingForColumn(columnKey, 'content.singleLine'),
                    isHidden = this.getSettingForColumn(columnKey, 'isHidden', false),
                    className = this.getSettingForColumn(columnKey, 'className', ''),
                    isHiddenCell = data[i][columnKey] && data[i][columnKey].hidden || false;

                if (!isHidden && !isHiddenCell) {
                    const rowSpan = (data[i][columnKey] && data[i][columnKey].rowSpan) ? data[i][columnKey].rowSpan : 1;

                    dataRow.push(
                        <SemanticTable.Cell
                            className={('function' === typeof className) ? className(data[i]) : className}
                            key={i + columnKey}
                            singleLine={singleLine}
                            textAlign={textAlign}
                            rowSpan={rowSpan}
                        >
                            {cellContent}
                        </SemanticTable.Cell>
                    );
                }
            }

            if (data[i].is_disabled) {
                rowClassNames.push('--is-disabled');
            }

            if (data[i].is_archived) {
                rowClassNames.push('--is-archived');
            }

            if (true === _isFunction(this.props.rowClassname)) {
                rowClassNames.push(this.props.rowClassname(data[i]));
            }

            let tableRowKey = this.props.name + data[i].id;

            if (data[i].id.value) {
                tableRowKey = this.props.name + data[i].id.value;
            }

            dataRows.push(
                <TableRow
                    className={rowClassNames.join(' ')}
                    key={tableRowKey}
                    data={data[i]}
                    onClick={this.selectRow}
                >
                    {dataRow}
                </TableRow>
            );
        }

        return dataRows;
    }

    changePage(page, setUrl = true) {
        const urlQueryObject = urlSearchStringToObject(window.location.search);

        this.setState(function (state, props) {
            return {
                page: page,
                indexListUpperLimit: (state.limit * page <= props.data.length)
                    ? state.limit * page : props.data.length,
            };
        },
        this.props.adjustHeight && 'function' === typeof this.props.adjustHeight
            ? this.props.adjustHeight
            : null
        );

        urlQueryObject.page = page;

        setUrl && setParamsToSearchUrl(urlQueryObject, true);
    }

    changePageDropdownAction(event, data) {
        this.changePage(data.value);
    }

    changePageButton(event, data) {
        this.changePage(data.index);
    }

    changeLimit(event, data) {
        const limit = data.value,
            maxPageCount = Math.ceil(this.state.data.length / limit),
            page = this.getPage(),
            tableParamsForUrl = {
                ...urlSearchStringToObject(window.location.search),
                pageLimit: limit,
            };

        this.props.setTableParamsToStore({
            limit: limit,
        });

        if (1 !== page && 1 < maxPageCount) {
            tableParamsForUrl.page = page > maxPageCount ? maxPageCount : page;
        }

        tableParamsForUrl.pageLimit = limit;

        setParamsToSearchUrl(tableParamsForUrl, true);

        this.setState(function (state, props) {
            return {
                limit: limit,
                maxPageCount: maxPageCount,
                indexListUpperLimit: (limit <= props.data.length) ? limit : props.data.length,
            };
        },
        this.props.adjustHeight && 'function' === typeof this.props.adjustHeight
            ? this.props.adjustHeight
            : null
        );
    }

    setTableState = () => {
        const {page, limit, order, orderBy} = this.getUrlTableParams();

        this.setState(
            function (state, props) {
                if (state.preventSetTableState) {
                    return {preventSetTableState: false};
                } else {
                    const dataLength = (undefined === props.data || null === props.data) ? 1 : props.data.length,
                        sortedData = this.sortData(orderBy, props.data, false, order),
                        maxPageCount = Math.ceil(dataLength / limit);

                    return {
                        data: sortedData,
                        dataOriginal: props.data,
                        selected: (
                            JSON.stringify(this.state.dataOriginal) === JSON.stringify(props.data)
                            && JSON.stringify(props.selected) === JSON.stringify(this.props.selected)
                        )
                            ? this.state.selected
                            : (props.selected || []),
                        page: ((maxPageCount < page) ? maxPageCount : page),
                        limit: limit,
                        direction: order,
                        column: orderBy,
                        maxPageCount: maxPageCount,
                        indexListUpperLimit: (state.limit * state.page <= dataLength)
                            ? state.limit * state.page : dataLength,
                    };
                }
            }, this.handleSort);
    };

    componentWillMount() {
        this.setTableState();
    }

    getUrlTableParams() {
        let {page, limit} = this.state;
        const order = this.state.direction,
            orderBy = this.state.column,
            pageFromUrl = this.getPage(),
            limitPerPageFromUrl = this.getLimitPerPage(),
            orderByFromUrl = this.getOrderBy(),
            orderFromUrl = this.getOrder();

        if (pageFromUrl && pageFromUrl !== page) {
            page = pageFromUrl;
        }

        if (limitPerPageFromUrl && limitPerPageFromUrl !== this.state.limit) {
            limit = limitPerPageFromUrl;
        }

        return {
            page,
            limit,
            orderBy: orderByFromUrl || orderBy,
            order: orderFromUrl || order,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loading
            || (
                _isArray(nextProps.data)
                && _isArray(this.props.data)
                && (nextProps.data.length !== this.props.data.length)
            )
        ) {
            this.setState(() => ({
                showLimitWarning: true,
            }));
        }

        if (this.state.url === this.props.match.path
            || !_isEqual(this.state.dataOriginal, nextProps.data)
        ) {
            this.setTableState();
        }

        if (
            this.props.loading
            && !nextProps.loading
            && nextProps.getSelectedElements
        ) {
            const selected = nextProps.selected || [];

            this.setState(() => ({selected: selected}));
            nextProps.getSelectedElements(selected);
        }

        if (this.state.showLimitWarning && this.props.showLimitWarning) {
            this.showLimitWarningBox(nextProps);
        }
    }

    showLimitWarningBox = (props) => {
        if (!props.data) {
            return;
        }

        const limitData = _get(this.props, 'dataLimitWarning', TABLE_DATA_LIMIT),
            dataArray = _get(props, 'data', []),
            limitMessage = _get(this.props, 'limitMessage',
                `The number of results displayed is limited to ${limitData}. Please use filters to narrow your search.`);

        if ((!dataArray ? [] : dataArray).length >= limitData && !props.loading) {
            showMessageBox(
                this.props.name + '_warningBox',
                null,
                limitMessage,
                'error',
                true
            );

            this.setState(() => ({
                showLimitWarning: false,
            }));
        }
    };

    renderPaginatorPageButtons() {
        const pageButtons = [],
            limitPerPageList = [
                {key: 5, value: 5, text: 5},
                {key: 10, value: 10, text: 10},
                {key: 20, value: 20, text: 20},
                {key: 50, value: 50, text: 50},
                {key: 100, value: 100, text: 100},
            ],
            pageNumberList = [],
            recordFrom = (1 < this.state.page) ? ((this.state.page - 1) * this.state.limit + 1) : 1;

        let recordTo = this.state.page * this.state.limit;

        if (this.state.data.length <= (this.state.page * this.state.limit)) {
            recordTo = this.state.data.length;
        } else if (1 === this.state.page) {
            recordTo = this.state.limit;
        }

        pageButtons.push(
            <div className='page-info' key={`${this.props.name}-pageinfo`}>
                <strong>{`${recordFrom} - ${recordTo} of ${this.state.data.length}`}</strong>
            </div>
        );

        pageButtons.push(
            <div key={`${this.props.name}-limit-change-dropdown`}>
                <Dropdown
                    onChange={this.changeLimit}
                    value={this.state.limit} text={`Limit: ${this.state.limit}`}
                    item
                    scrolling
                    options={limitPerPageList}
                    upward
                />
            </div>
        );

        if (this.state.data === undefined || this.state.data.length < this.state.limit) {
            return pageButtons;
        }

        for (let i = 0; i < this.state.maxPageCount; i++) {
            pageNumberList[i] = {key: i + 1, value: i + 1, text: i + 1};
        }

        pageButtons.push(
            <div key={`${this.props.name}-page-change-dropdown`}>
                <Dropdown
                    onChange={this.changePageDropdownAction}
                    value={this.state.page} text={`Page: ${this.state.page}`}
                    item
                    button
                    scrolling
                    options={pageNumberList}
                    className={'pagination__page-change-dropdown'}
                />
            </div>
        );

        if (1 !== this.state.page) {
            if (1 < this.state.maxPageCount) {
                pageButtons.push(
                    <Menu.Item as='a' index={1} key={`${this.props.name}-first-page`} icon
                        onClick={this.changePageButton}>
                        <Icon name='angle double left'/>
                    </Menu.Item>
                );
            }

            pageButtons.push(
                <Menu.Item as='a' index={this.state.page - 1} key={`${this.props.name}-previous-page`} icon
                    onClick={this.changePageButton}>
                    <Icon name='angle left'/>
                </Menu.Item>
            );
        }

        for (let i = this.state.page - 3; i < this.state.page; i++) {
            if (0 < i) {
                pageButtons.push(
                    <Menu.Item as='a' index={i} key={`${this.props.name}-page-${i}`}
                        onClick={this.changePageButton}>{i}</Menu.Item>
                );
            }
        }

        pageButtons.push(
            <Menu.Item as='a' key={`${this.props.name}-page-${this.state.page}`} disabled>
                {this.state.page}
            </Menu.Item>
        );

        for (let i = this.state.page; i < this.state.page + 3 && i < this.state.maxPageCount; i++) {
            pageButtons.push(
                <Menu.Item key={`${this.props.name}-page-${i + 1}`} as='a' index={i + 1}
                    onClick={this.changePageButton}>
                    {i + 1}
                </Menu.Item>
            );
        }

        if (this.state.page < this.state.maxPageCount) {
            pageButtons.push(
                <Menu.Item
                    key={`${this.props.name}-page-next`}
                    as='a'
                    index={this.state.page + 1}
                    icon
                    onClick={this.changePageButton}
                >
                    <Icon name='angle right'/>
                </Menu.Item>
            );

            pageButtons.push(
                <Menu.Item
                    as='a'
                    key={`${this.props.name}-page-last`}
                    icon
                    index={this.state.maxPageCount}
                    onClick={this.changePageButton}
                >
                    <Icon name='angle double right'/>
                </Menu.Item>
            );
        }

        return pageButtons;
    }

    renderPaginationContainer() {
        return (
            <SemanticTable.HeaderCell
                colSpan={Object.keys(this.props.columns).length + (true === this.props.selectable ? 1 : 0)}>
                <Menu floated='right' pagination>
                    {this.renderPaginatorPageButtons()}
                </Menu>
            </SemanticTable.HeaderCell>
        );
    }

    renderTable = () => {
        return (
            <div>
                <MessageBox name={this.props.name + '_warningBox'}/>
                <div className='table-container'>
                    <SemanticTable
                        compact={this.props.isCompact}
                        size={this.props.size}
                        sortable
                        celled
                        striped
                        selectable
                        structured={this.props.structured}
                        className={this.props.className}
                    >
                        <SemanticTable.Header>
                            <SemanticTable.Row>
                                {this.renderHeaderRow()}
                            </SemanticTable.Row>
                        </SemanticTable.Header>
                        <SemanticTable.Body>
                            {this.renderDataRows()}
                        </SemanticTable.Body>
                        {
                            false === this.props.pagination
                                ? null
                                : <SemanticTable.Footer>
                                    <SemanticTable.Row>
                                        {this.renderPaginationContainer()}
                                    </SemanticTable.Row>
                                </SemanticTable.Footer>
                        }
                    </SemanticTable>
                </div>
            </div>
        );
    };

    renderNoDataMessage = () => {
        if (this.props.noMessageBox) {
            return (
                <div>{this.props.noDataText}</div>
            );
        } else {
            return (
                <div className='table-container'>
                    <Message>
                        <Message.Header>
                            {this.props.noDataText}
                        </Message.Header>
                    </Message>
                </div>
            );
        }
    };

    render() {
        const {data, loading} = this.props;

        if (loading) {
            return (
                <Dimmer inverted active>
                    <Loader/>
                </Dimmer>
            );
        } else if (_isNil(data) || _isEmpty(data)) {
            return this.renderNoDataMessage();
        } else {
            return this.renderTable();
        }
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        setTableParamsToStore: (params) => {
            return dispatch(setTableParamsToStore({tableParams: {...params}, name: props.name}));
        },
    };
};

const mapStateToProps = (state, props) => {
    return {
        tableParams: state.app.table[props.name],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
