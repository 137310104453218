import PropTypes from "prop-types";

export const exportExcel = (dataExport, clientId) => ({
    dataExport: dataExport,
    filename: `client_${clientId}_third_party_cdns`,
    parseData: thirdPartyCdn => [
        thirdPartyCdn.id,
        thirdPartyCdn.name,
        thirdPartyCdn.third_party_client_cdn_type.name,
        thirdPartyCdn.encoding_job_profile.name,
    ],
    path: 'clients.products.thirdParty.cdns.index.path',
    titles: [
        "ID",
        "Name",
        "Type",
        "Encoding job profile",
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired
};
