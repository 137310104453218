import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dimmer, Divider, Grid, Header, Loader} from 'semantic-ui-react';

import {hasPrivileges} from '@appComponents/Authorization';

import {getEditableGridFormWithRedux} from './EditableGridForm';

class EditableGridTable extends React.Component {
    static defaultProps = {
        rows: [],
    };

    static propTypes = {
        columns: PropTypes.object.isRequired,
        formName: PropTypes.string.isRequired,
        headerText: PropTypes.string,
        loading: PropTypes.bool,
        onCreate: PropTypes.func,
        onDelete: PropTypes.func,
        onUpdate: PropTypes.func,
        rows: PropTypes.array,
        tableClassName: PropTypes.string,
        validator: PropTypes.func.isRequired,
        authorizationObjects: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeEditableFields: null,
            authorization: {
                create: hasPrivileges(props.authorizationObjects.create),
                update: hasPrivileges(props.authorizationObjects.update),
                delete: hasPrivileges(props.authorizationObjects.delete),
            },
        };
    }

    activateEditableCell = (event) => {
        this.setState({
            activeEditableFields: parseInt(event.currentTarget.dataset.id, 10)
        });
    };

    deactivateEditableCell = () => {
        this.setState({
            activeEditableFields: null,
        });
    };

    renderHeader = () => {
        let cells = Object.keys(this.props.columns).map((keys) => {
            return (
                <Grid.Column key={keys} width={this.props.columns[keys].width}>
                    <strong>{this.props.columns[keys].label}</strong>
                </Grid.Column>
            );
        });

        return (
            <Grid columns={this.props.columns.length}>
                <Grid.Row>
                    {cells}
                </Grid.Row>
            </Grid>
        );
    };

    renderButtonsForEditableRowInViewMode = (id) => {
        return (
            <Button.Group>
                <Button
                    icon='trash'
                    onClick={this.props.onDelete}
                    type='button'
                    data-id={id}
                />
            </Button.Group>
        );
    };

    renderEditableGrids = () => {
        return this.props.rows.map((data, index) => {
            return (
                [
                    <Divider key={`divider_${index}`}/>,
                    (this.state.activeEditableFields === parseInt(data.id, 10))
                        ? this.renderEditForm(data)
                        : this.renderEditableGrid(data),
                ]
            );
        });
    };

    rowRenderer = (data) => {
        const onClickCallback = this.state.authorization.update ? this.activateEditableCell : null;

        return Object.keys(this.props.columns).map((key) => {
            return (
                <Grid.Column
                    data-id={data.id}
                    key={`${key}_${data.id}`}
                    onClick={onClickCallback}
                    width={this.props.columns[key].width}
                >
                    {key === 'actions' ?  this.state.authorization.delete && this.renderButtonsForEditableRowInViewMode(data.id) : data[key]}
                </Grid.Column>
            );
        });
    };

    renderEditableGrid = (data) => {
        const gridClass = this.state.authorization.update ? '--clickable' : '';

        return (
            <Grid key={data.id} columns={this.props.columns.length} verticalAlign='middle' className={gridClass}>
                <Grid.Row>
                    {this.rowRenderer(data)}
                </Grid.Row>
            </Grid>
        );
    };

    renderAddForm = () => {
        const formProps = {
            formData: {},
            columns: this.props.columns,
            validator: this.props.validator,
            formName: this.props.formName,
            onCreate: this.props.onCreate,
        };

        return getEditableGridFormWithRedux(formProps);
    };

    renderEditForm = (data) => {
        const formProps = {
            formData: data,
            columns: this.props.columns,
            validator: this.props.validator,
            formName: this.props.formName + '_edit_form_' + data.id,
            onUpdate: this.props.onUpdate,
            onDeactivateEditableCell: this.deactivateEditableCell,
        };

        return this.state.authorization.update && getEditableGridFormWithRedux(formProps);
    };

    render() {
        return (
            <div className={this.props.tableClassName}>
                {
                    (this.props.headerText && '' !== this.props.headerText)
                        ? (
                            <Header as='h3' dividing>
                                {this.props.headerText}
                            </Header>
                        )
                        : null
                }
                <Dimmer inverted active={this.props.loading}>
                    <Loader/>
                </Dimmer>
                {this.renderHeader()}
                {this.state.authorization.create && this.renderAddForm()}
                {this.renderEditableGrids()}
            </div>
        );
    }
}

export default EditableGridTable;
