import React from 'react';
import PropTypes from "prop-types";
import {Button as SemanticButton, Icon} from "semantic-ui-react";

import Link from "../Link";
import Button from "./Button";

const ButtonAdd = (props) => {

    return (
        <Button
            auth={props.auth}
        >
            <Link name={props.link.name} params={props.link.params}>
                <SemanticButton size="small" {...props.button} >
                    <Icon className="add circle" />
                    {props.label}
                </SemanticButton>
            </Link>
        </Button>
    );
};
ButtonAdd.propTypes = {
    auth: PropTypes.shape({
        privileges: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.number
        ]),
        resources: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.number
        ]),
    }),
    button: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    label: PropTypes.string,
    link: PropTypes.shape({
        name: PropTypes.string.isRequired,
        params: PropTypes.object,
    }),
};
ButtonAdd.defaultProps = {
    label: "Add"
};
ButtonAdd.displayName = 'ButtonAdd';

export default ButtonAdd;
