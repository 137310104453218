import {tournamentCategoriesQuery} from "../tournamentCategories";
import {contentTiersDropdown} from "../contentTier";
import {contentCategoriesFilteredByLevelAndParentsQuery} from "../content";

export const contentBody = `
    id
    tournament {
        id
        name
        tournament_category {
            id
            name
            sport {
                id
                name
            }
        }
    }
    content_category {
        id
        name
        parent {
            id
            name
        }
    }
    content_tier {
        id
        name
    }
`;

export const propertyLicenceContents = `propertyLicenceContents(filters: {propertyLicence: $propertyLicenseId}) {
    ${contentBody}
}`;

export const propertyLicenceContentList = `
query PropertyLicenceContents($propertyLicenceId : ID!) {
    propertyLicenceContents(
        filters: {
          propertyLicence: $propertyLicenceId
        }
    ) {
        ${contentBody}
    }
}`;

export const propertyLicenceContentDropdownData = `
query PropertyLicenceContents($sports : [Int]) {
    ${contentTiersDropdown}
    ${tournamentCategoriesQuery}
}`;

export const propertyLicenceContentTypeOtherDropdownData = `
query PropertyLicenceContents($parents : [Int], $level: Int) {
    ${contentTiersDropdown}
    ${contentCategoriesFilteredByLevelAndParentsQuery}
}`;

export const propertyLicenceContentCreateMutation = `
mutation
  createPropertyLicenceContent(
    $property_licence: Int!
    $tournament: Int
    $content_category: Int
    $content_tier: Int!
  ) {
      createPropertyLicenceContent(
        property_licence: $property_licence
        tournament: $tournament
        content_category: $content_category
        content_tier: $content_tier
      ) {
        ${contentBody}
      }
    }


`;

export const propertyLicenceContentUpdateMutation = `
mutation
  updatePropertyLicenceContent(
    $id: ID!
    $content_tier: Int!
  ) {
      updatePropertyLicenceContent(
        id: $id
        content_tier: $content_tier
      ) {
        ${contentBody}
      }
    }

`;

export const propertyLicenceContentDeleteMutation = `
mutation deletePropertyLicenceContent($id : ID!) {
    deletePropertyLicenceContent(id : $id)
}
`;