import PropTypes from "prop-types";
import React from "react";
import {Popup} from "semantic-ui-react";

import Authorization from "../../app/components/Authorization";
import {
    IconBan,
    IconCheckCircle,
    IconEdit,
    IconExclamationCircle,
    IconQuestionCircle,
    IconX
} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import Table from "../../app/components/Table";
import {convertToInt} from "../../../utils/helpers";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const columns = {
    "id": {
        label: "ID",
        sorting: (row) => convertToInt(row.id),
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
    "environment": {label: "Environment", sorting: true},
    "api_key": {label: "API key", sorting: true},
    "description": {label: "Description", sorting: true},
    "creation_datetime": {
        label: "Generation time",
        sorting: true,
        header: {
            align: "center",
        },
        content: {
            align: "center",
        },
    },
    "creation_user": {label: "Generated by", sorting: (row) => row.creation_user ? row.creation_user.name : null},
    "status": {
        label: "Status",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
    "actions": {
        label: "Actions",
        header: {
            align: "center",
            isCollapsed: true,
        },
        content: {
            align: "center",
        },
    },
};

const ApiKeyTable = (props) => {
    const {clientApiKeys, editClick} = props;

    const renderStatusColumn = (column, row) => {
        let icon = <IconQuestionCircle size="large" color="red" />,
            statusMessage = row['status_message'].split('\\n').map((message, index) => (
                <div key={index}>{message}</div>
            ));

        if (row[column]) {
            icon = row['is_bookmaker_equal']
                ? <IconCheckCircle size="large" color="green" />
                : <IconExclamationCircle size="large" color="red" />;
        } else if (false === row[column]) {
            icon = <IconExclamationCircle size="large" color="orange" />;
        }

        return (
            <Popup
                content={statusMessage}
                key={Date.now()}
                position="left center"
                style={{textAlign: "left"}}
                trigger={icon}
            />
        );
    };

    const renderActionsColumn = (row) => {
        let icon = null;

        if (row['status']) {
            icon = <IconBan flipped="vertically" size="large" onClick={editClick} onClickElement={row} />;
        } else if (false === row['status']) {
            icon = <IconX size="large" onClick={editClick} onClickElement={row} />;
        }

        return (
            <div className="actions-list-icon">
                <IconEdit
                    link={getLink('clients.apiKey.edit', {id: props.clientId, clientApiKeyId: row.id})}
                    size="large"
                />
                <Popup
                    content={row['action_message']}
                    key={Date.now()}
                    position="left center"
                    style={{textAlign: "left"}}
                    trigger={icon}
                />
            </div>
        );
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
            case "api_key":
            case "description":
            case "creation_datetime":
                return row[column];
            case "environment":
            case "creation_user":
                return row[column] ? row[column].name : null;
            case "status" :
                return renderStatusColumn(column, row);
            case "actions" :
                return (
                    <Authorization
                        resources={RESOURCES.CLIENT_API_KEY}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    >
                        {renderActionsColumn(row)}
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className="clientApiKeys__table">
            <Table
                name="clientApiKeysList"
                columns={columns}
                data={clientApiKeys}
                noDataText="No API keys found"
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

ApiKeyTable.defaultProps = {
    clientApiKeys: [],
};

ApiKeyTable.propTypes = {
    clientApiKeys: PropTypes.array,
    clientId: PropTypes.string.isRequired,
    editClick: PropTypes.func.isRequired,
};

export default ApiKeyTable;
