import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Field} from 'redux-form';
import {useQuery} from '@apollo/client';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {GetCountries} from '@graphql/country/query';
import {IS_ARCHIVED} from '@constants/staticDropdowns';

import {showErrorNotificationMessage} from '../utils/blackoutZone';

export const BlackoutZoneFilters = ({
    restoreFilterValues,
    filters,
    loading: loadingFilters,
}) => {
    const {
        data: {
            countries,
        } = {},
        error: errorFetchingCountriesData,
        loading: isCountriesLoading = true,
    } = useQuery(GetCountries);

    useMemo(()=> {
        errorFetchingCountriesData && showErrorNotificationMessage();
    }, [errorFetchingCountriesData]);

    const loading = isCountriesLoading || loadingFilters;

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={restoreFilterValues(filters.BlackoutZone, 'search') || ''}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='countries'
                options={countries}
                placeholder='Country'
                search
                setValue={restoreFilterValues(filters.BlackoutZone, 'countries')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isArchived'
                placeholder='Archive status'
                options={IS_ARCHIVED}
                setValue={restoreFilterValues(
                    filters.BlackoutZone,
                    'isArchived',
                    0
                )}
                selection
            />
        </div>
    );
};

BlackoutZoneFilters.propTypes = {
    filters: PropTypes.shape({
        BlackoutZone: PropTypes.object.isRequired,
    }),
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(BlackoutZoneFilters, {name: 'BlackoutZone', initialValues: {isArchived: 0}});
