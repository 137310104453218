import validators from '../../../utils/validators';

const validate = (values) => {
    const errors = {};
    Object.assign(errors, validators.dateTime(values.from, "from"));
    Object.assign(errors, validators.dateTime(values.to, "to"));

    return errors;
};

export default validate;
