import React from "react"; //eslint-disable-line no-unused-vars

import Form from "../../app/components/ReduxFormControls";

import validate from "./validatorClientAnalyticsSetupLabels";
import * as MESSAGES from "../../app/constants/messages";
import {CLIENT_LABEL} from "../../app/constants/resources";

import {
    clientLabelCreateMutation, clientLabelDeleteMutation, clientLabelQuery, clientLabelUpdateMutation
} from "../../../graphql/clients/clientLabels";

export default function ClientAnalyticsSetupLabelModel() {
    return {
        deleteId: "clientLabel.id",
        entityDataMapKey: "clientLabel",
        entityLabel: "label",
        formName: "Client Analytics Setup Label",
        resources: CLIENT_LABEL,
        showChangeLog: true,
        validate: validate,
        title: () =>  "Add new label",
        dataMap: {
            clientLabel: {
                code: "",
                name: "",
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
                DELETE_CONFIRMATION: (label, name = '') => (
                    `Are you sure you want to delete the label ${name ? ` "${name}"` : ''}?`
                ),
                DELETE_HEADER_ARGUMENT_FIRST: () => MESSAGES.DELETE_HEADER('label'),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: "clientLabel.id",
                props: {
                    label: '',
                    name: "id",
                    hidden: true,
                },
            },
            code: {
                dataMapKey: "clientLabel.code",
                props: {
                    component: Form.SemanticInput,
                    name: "code",
                    label: "Code",
                    type: "input",
                    required: true,
                    autoFocus: true
                },
            },
            name: {
                dataMapKey: "clientLabel.name",
                props: {
                    component: Form.SemanticInput,
                    name: "name",
                    label: "Name",
                    type: "input",
                    required: true,
                },
            },
        },
        mutation: {
            createEntity: clientLabelCreateMutation,
            deleteEntity: clientLabelDeleteMutation,
            updateEntity: clientLabelUpdateMutation,
        },
        mutationOptions: {
            create: {refetchQueries: ['ClientLabelsQuery']},
            update: {refetchQueries: ['ClientLabelsQuery']},
            delete: {refetchQueries: ['ClientLabelsQuery']},
        },
        query: {
            getEntity: clientLabelQuery,
        },
    };
}
