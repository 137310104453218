import PropTypes from "prop-types";
import React from "react";
/* eslint import/no-unresolved: 0 */
import {graphql, withApollo} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {Segment} from "semantic-ui-react";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import {getLink} from "../../app/components/Link";
import ThirdPartyVodStoragesIndexButtons from "../components/ThirdPartyVodStoragesIndexButtons";
import ThirdPartyVodStoragesTable from "../components/ThirdPartyVodStoragesTable";

import ThirdPartyClientVodStorageForm from "../forms/ThirdPartyClientVodStorageForm";
import ThirdPartyClientVodStorageModel from "../forms/ThirdPartyClientVodStorageModel";
import ThirdPartyClientVodStorageModelEdit from "../forms/ThirdPartyClientVodStorageModelEdit";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import {exportExcel} from "../utils/export/thirdPartyVodStoragesIndexExcel";

import {routes} from "../../app/constants/routes";
import {DISTRIBUTION_TYPE_RECORDING, PRODUCT_THIRD_PARTY} from "../../app/constants/variables";

import {GetThirdPartyVodStoragesForTable} from "../../../graphql/thirdPartyClientVodStorage/query.graphql";

export class ThirdPartyVodStoragesIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
        clientId: PropTypes.number.isRequired,
        DataThirdPartyVodStorages: PropTypes.shape({
            loading: PropTypes.bool,
            thirdPartyClientVodStorages: PropTypes.arrayOf(PropTypes.object),
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.object.isRequired,
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        prepareThirdPartyClientVodStoragesForStore: PropTypes.func.isRequired,
        thirdParty: PropTypes.number.isRequired,
    };

    static defaultProps = {
        DataThirdPartyVodStorages: {
            loading: false,
            thirdPartyClientVodStorages: [],
        },
    };

    state = {
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.clients.products.thirdParty.vodStorages.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        return {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        };
    }

    componentDidMount() {
        this.loadModal(this.props.match.path)
    }

    componentDidUpdate() {
        if (this.state.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params)
        }

        if (this.state.modalWasClosed) {
            this.props.history.push(
                getLink("clients.products.thirdParty.vodStorages.index", {
                    id: this.props.match.params.id,
                })
            );
        }
    }

    loadModal(path, params = {}) {
        const variables = {
            optionsVariables: {
                products: [PRODUCT_THIRD_PARTY],
                distributionTypes: [DISTRIBUTION_TYPE_RECORDING],
            },
            thirdParty: this.props.thirdParty,
        };

        if (path === routes.clients.products.thirdParty.vodStorages.add.path) {
            return this.loadModalForm(createForm(
                ThirdPartyClientVodStorageModel,
                ThirdPartyClientVodStorageForm,
                {...variables}
            ), params);
        } else if (path === routes.clients.products.thirdParty.vodStorages.edit.path) {
            return this.loadModalForm(createForm(
                ThirdPartyClientVodStorageModelEdit,
                ThirdPartyClientVodStorageForm,
                {
                    clientId: this.props.match.params.id,
                    id: this.props.match.params.thirdPartyClientVodStorageId || params.thirdPartyClientVodStorageId,
                    ...variables
                }
            ), params);
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            canBeDismissed: true,
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });
    };

    render() {
        return (
            <Segment
                basic
                className="--marginTopNegative-15 --paddingTop-0"
                loading={this.props.DataThirdPartyVodStorages.loading}
            >
                <HeaderRenderer
                    exportExcelParams={exportExcel(
                        this.props.DataThirdPartyVodStorages.thirdPartyClientVodStorages,
                        this.props.clientId
                    )}
                    buttons={ThirdPartyVodStoragesIndexButtons}
                    buttonsProps={{clientId: this.props.clientId}}
                    loading={this.props.DataThirdPartyVodStorages.loading}
                />
                <ThirdPartyVodStoragesTable
                    clientId={this.props.clientId}
                    loading={this.props.DataThirdPartyVodStorages.loading}
                    thirdPartyClientVodStorages={this.props.DataThirdPartyVodStorages.thirdPartyClientVodStorages}
                />
            </Segment>
        );
    }
}

const ThirdPartyVodStoragesWithGraphQL = compose(
    graphql(GetThirdPartyVodStoragesForTable, {
        skip: props => (null === props.thirdParty),
        options: (props) => {
            return {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
                variables: {
                    clientProductThirdParty: props.thirdParty,
                },
            };
        },
        name: "DataThirdPartyVodStorages",
    })
)(ThirdPartyVodStoragesIndex);

const mapStateToProps = (state) => {
    let thirdPartyClientProduct = null;

    if (state.app.entities.client.clientProducts) {
        thirdPartyClientProduct = state.app.entities.client.clientProducts.find((clientProduct) => (
            PRODUCT_THIRD_PARTY === convertToInt(clientProduct.product.id)
        ));
    }

    return {
        client: state.app.entities.client,
        modal: state.modal,
        thirdParty: thirdPartyClientProduct
            ? convertToInt(thirdPartyClientProduct.third_party_client_product.id)
            : null,
    };
};
const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox']);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ThirdPartyVodStoragesWithGraphQL)));
