import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {ButtonBackToList} from '@appComponents/ButtonCollection';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {routes} from '@constants/routes';
import Tabs from '@appComponents/Tabs';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {GetLcrChannelNameById} from '@graphql/lcr/query';
import LcrChannelGeoRestrictionsModel from '@modules/lcr/forms/LcrChannelGeoRestrictionsModel';
import {LcrGeoRestrictionsForm} from '@modules/lcr/forms/LcrChannelGeoRestrictionsForm';
import {isUrlParamValid} from '@utils/helpers';

import LcrChannelGeneralInformationModel from '../forms/LcrChannelGeneralInformationModel';
import LcrChannelGeneralInformationForm from '../forms/LcrChannelGeneralInformationForm';
import ContentRestrictions from '../components/ContentRestrictions';

export const LcrChannelEdit = () => {
    const match = useRouteMatch();

    const backToListButton = () => <ButtonBackToList name='lcrChannel.index'/>,
        id = match.params.id,
        Form = createForm(LcrChannelGeneralInformationModel, LcrChannelGeneralInformationForm, {
            id: id,
        }),
        tabs = {
            [routes.lcrChannel.edit.generalInformation.path]: {
                key: routes.lcrChannel.edit.generalInformation.path,
                url: 'lcrChannel.edit.generalInformation',
                urlParams: {id},
                text: 'General settings',
                render: <Form/>,
            },
        };

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('LCR Channel', routes.lcrChannel.index.path),
        });
    }

    let activeTabDefault = match.path;

    if (routes.lcrChannel.add.path === match.path) {
        activeTabDefault = routes.lcrChannel.edit.generalInformation.path;
        tabs[routes.lcrChannel.edit.generalInformation.path]['urlParams'] = {id: null};
    } else {
        const GeoRestrictionsForm = createForm(LcrChannelGeoRestrictionsModel, LcrGeoRestrictionsForm, {
            id: match.params.id,
        });

        tabs[routes.lcrChannel.edit.geoRestrictions.path] = {
            key: routes.lcrChannel.edit.geoRestrictions.path,
            url: 'lcrChannel.edit.geoRestrictions',
            urlParams: {id},
            text: 'Geo restrictions',
            render: <GeoRestrictionsForm />,
        };

        tabs[routes.lcrChannel.edit.contentRestrictions.index.path] = {
            key: routes.lcrChannel.edit.contentRestrictions.index.path,
            url: 'lcrChannel.edit.contentRestrictions.index',
            urlParams: {id},
            text: 'Content restrictions',
            render: <ContentRestrictions lcrId={id} path={match.path}/>,
        };
    }

    const [activeTab, setActiveTab] = useState(activeTabDefault);

    useEffect(() => {
        if (routes.lcrChannel.add.path === match.path) {
            return;
        }

        if (routes.lcrChannel.edit.geoRestrictions.path === match.path) {
            setActiveTab(routes.lcrChannel.edit.geoRestrictions.path);

            return;
        }

        if (routes.lcrChannel.edit.contentRestrictions.index.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.sports.add.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.sports.edit.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.categories.add.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.categories.edit.path === match.path
            || routes.lcrChannel.edit.contentRestrictions.tournaments.add.path === match.path
        ) {
            setActiveTab(routes.lcrChannel.edit.contentRestrictions.index.path);

            return;
        }

        setActiveTab(match.path);
    }, [match.path]);

    return (
        <>
            <HeaderRenderer
                buttons={backToListButton}
                messagesBoxNames='LcrChannelEdit'
                pageTitle={<TitleFromQuery
                    query={GetLcrChannelNameById}
                    variables={{id}}
                    errorBackLink={routes.lcrChannel.index.path}
                    path={'lcrChannel.name'}
                    entity={'LCR channel'}
                />}
            />
            <Tabs active={activeTab} items={tabs}/>
        </>
    );
};

LcrChannelEdit.displayName = 'LcrChannelEdit';

export default LcrChannelEdit;
