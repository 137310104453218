import React from 'react';

import Authorization from '@appComponents/Authorization';
import { ButtonAdd } from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

const authorizationObject = {
    [RESOURCES.USER] : CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

const UserIndexButtons = () => {
    return (
        <div className='content__buttons'>
            <Authorization
                authorization={authorizationObject}
            >
                <Link name='users.add'>
                    <ButtonAdd>{'Add new user'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default UserIndexButtons;
