import validators from '../../../utils/validators';
import {TERRITORY_SELECTION_TYPE_WORLDWIDE} from "../../app/constants/variables";

export const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.usages, 'usages'));
    Object.assign(errors, validators.required(values.territory_selection_type, 'territory_selection_type'));

    Object.assign(errors, validators.date(values.start_date, 'start_date'));
    Object.assign(errors, validators.date(values.end_date, 'end_date'));
    Object.assign(errors, validators.required(values.start_date, "start_date"));
    Object.assign(errors, validators.required(values.end_date, "end_date"));

    Object.assign(errors, validators.startDateBeforeEndDate({
        startDate: values.start_date,
        endDate: values.end_date,
    }, "end_date"));

    if (TERRITORY_SELECTION_TYPE_WORLDWIDE !== parseInt(values.territory_selection_type)) {
        Object.assign(errors, validators.required(values.countries, 'countries'));
    }

    return errors;
};
