import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import SegmentHeader from '@appComponents/SegmentHeader';
import {getLink} from '@appComponents/Link';

import MatchSelectionPeriodForm from '../forms/MatchSelectionPeriodForm';
import MatchSelectionPeriodModel from '../forms/MatchSelectionPeriodModel';

const MatchSelectionPeriodEdit = ({match: {params: {id, matchSelectionPeriodId = null}}}) => {
    if (!isUrlParamValid(matchSelectionPeriodId)) {
        showModal({
            isVisible: true,
            content: renderModalError('Match selection period',
                getLink(`propertyLicences.matchSelectionPeriods.index`, {
                    id: id,
                })
            ),
        })
    }


    const Form = React.useMemo(
            () => (createForm(
                MatchSelectionPeriodModel,
                MatchSelectionPeriodForm,
                {
                    id: convertToInt(matchSelectionPeriodId),
                    optionsVariables: {
                        propertyLicenceId: convertToInt(id),
                    },
                }
            )),
            [matchSelectionPeriodId]
        ),
        headerText = matchSelectionPeriodId ? 'Edit match selection period' : 'Add match selection period';

    return (
        <div>
            <SegmentHeader buttons={
                <RenderBackToListButton
                    name={'propertyLicences.matchSelectionPeriods.index'}
                    params={{id}}
                    text={' Back to match selection periods'}
                />
            }>
                {headerText}
            </SegmentHeader>
            <Form/>
        </div>
    );
};

MatchSelectionPeriodEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            matchSelectionPeriodId: PropTypes.string
        })
    })
};

export default withRouter(MatchSelectionPeriodEdit);
