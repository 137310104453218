export const matchProperties = [
    {
        key: 'homeCompetitor_1',
        text: 'Has unique home competitor',
        value: 'homeCompetitor_1',
    },
    {
        key: 'homeCompetitor_0',
        text: 'Has no unique home competitor',
        value: 'homeCompetitor_0',
    },
    {
        key: 'awayCompetitor_1',
        text: 'Has unique away competitor',
        value: 'awayCompetitor_1',
    },
    {
        key: 'awayCompetitor_0',
        text: 'Has no unique away competitor',
        value: 'awayCompetitor_0',
    },
    {
        key: 'hasBlacklistedCompetitor_1',
        text: 'Has blacklisted competitor',
        value: 'hasBlacklistedCompetitor_1',
    },
    {
        key: 'hasBlacklistedCompetitor_0',
        text: 'Has no blacklisted competitor',
        value: 'hasBlacklistedCompetitor_0',
    },
    {
        key: 'rtsCoverage_1',
        text: 'Has RTS',
        value: 'rtsCoverage_1',
    },
    {
        key: 'rtsCoverage_0',
        text: 'Has no RTS',
        value: 'rtsCoverage_0',
    },
    {
        key: 'hasLiveScout_1',
        text: 'Has scout',
        value: 'hasLiveScout_1',
    },
    {
        key: 'hasLiveScout_0',
        text: 'Has no scout',
        value: 'hasLiveScout_0',
    },
    {
        key: 'hasLiveOdds_1',
        text: 'Has Live Odds',
        value: 'hasLiveOdds_1',
    },
    {
        key: 'hasLiveOdds_0',
        text: 'Has no Live Odds',
        value: 'hasLiveOdds_0',
    },
    {
        key: 'isCancelled_1',
        text: 'Is cancelled',
        value: 'isCancelled_1',
    },
    {
        key: 'isCancelled_0',
        text: 'Is not cancelled',
        value: 'isCancelled_0',
    },
    {
        key: 'isPostponed_1',
        text: 'Is postponed',
        value: 'isPostponed_1',
    },
    {
        key: 'isPostponed_0',
        text: 'Is not postponed',
        value: 'isPostponed_0',
    },
    {
        key: 'isWalkover_1',
        text: 'Is walkover',
        value: 'isWalkover_1',
    },
    {
        key: 'isWalkover_0',
        text: 'Is not walkover',
        value: 'isWalkover_0',
    },
    {
        key: 'isInterrupted_1',
        text: 'Is interrupted',
        value: 'isInterrupted_1',
    },
    {
        key: 'isInterrupted_0',
        text: 'Is not interrupted',
        value: 'isInterrupted_0',
    },
    {
        key: 'isSuspended_1',
        text: 'Is suspended',
        value: 'isSuspended_1',
    },
    {
        key: 'isSuspended_0',
        text: 'Is not suspended',
        value: 'isSuspended_0',
    },
    {
        key: 'isAbandoned_1',
        text: 'Is abandoned',
        value: 'isAbandoned_1',
    },
    {
        key: 'isAbandoned_0',
        text: 'Is not abandoned',
        value: 'isAbandoned_0',
    },
    {
        key: 'isRetired_1',
        text: 'Is retired',
        value: 'isRetired_1',
    },
    {
        key: 'isRetired_0',
        text: 'Is not retired',
        value: 'isRetired_0',
    },
    {
        key: 'isTvStreaming_1',
        text: 'Is TV Streaming',
        value: 'isTvStreaming_1',
    },
    {
        key: 'isTvStreaming_0',
        text: 'Is not TV Streaming',
        value: 'isTvStreaming_0',
    },
];
