import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getLink} from '@appComponents/Link';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import StepsContent from '@appComponents/StepsContent';
import {routes} from '@constants/routes';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {getSearchFilters} from '@utils/filters';
import {createForm} from '@utils/forms';
import mapModulesToProps from '@utils/mapModulesToProps';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import EventMasterBookingGeoRestrictionsStep from '@modules/events/views/EventMasterBookingGeoRestrictionsStep';
import {
    GetClientMasterBookings,
    GetClientMasterBookingsDropdownData,
} from '@graphql/clientMasterBooking/query';

import {exportExcel as clientMasterBookingsExportExcel} from '../utils/export/clientMasterBookingsExcel';
import ClientMasterBookingGeoRestrictionsGeneralInformationEditModel
    from '../forms/ClientMasterBookingGeoRestrictions/ClientMasterBookingGeoRestrictionsGeneralInformationEditModel';
import ClientMasterBookingGeoRestrictionsGeneralInformationForm
    from '../forms/ClientMasterBookingGeoRestrictions/ClientMasterBookingGeoRestrictionsGeneralInformationForm';
import ClientMasterBookingsTable from '../components/ClientMasterBookingsTable';
import ClientMasterBookingsFilters from '../components/ClientMasterBookingsFilters';


class ClientMasterBookings extends Component {
    static propTypes = {
        DataClientMasterBookings: PropTypes.shape({
            clientMasterBookings: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool.isRequired,
        }),
        dataDropDown: PropTypes.shape({
            bookingTypes: PropTypes.arrayOf(PropTypes.object),
            clientPackages: PropTypes.arrayOf(PropTypes.object),
            countries: PropTypes.arrayOf(PropTypes.object),
            deviceCategories: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool.isRequired,
            products: PropTypes.arrayOf(PropTypes.object),
            propertyLicences: PropTypes.arrayOf(PropTypes.object),
            sports: PropTypes.arrayOf(PropTypes.object),
        }),
        filters: PropTypes.shape({
            bookingType: PropTypes.arrayOf(PropTypes.string),
            clientPackage: PropTypes.arrayOf(PropTypes.string),
            country: PropTypes.arrayOf(PropTypes.string),
            deviceCategory: PropTypes.arrayOf(PropTypes.string),
            from: PropTypes.string,
            product: PropTypes.arrayOf(PropTypes.string),
            propertyLicence: PropTypes.arrayOf(PropTypes.number),
            sport: PropTypes.arrayOf(PropTypes.string),
            to: PropTypes.string,
            tournamentCategory: PropTypes.arrayOf(PropTypes.number),
            tournament: PropTypes.arrayOf(PropTypes.number),
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
                masterBookingId: PropTypes.string,
            }),
            path: PropTypes.string.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        DataClientMasterBookings: {
            clientMasterBookings: [],
            loading: false,
        },
        dataDropDown: {
            bookingTypes: [],
            clientPackages: [],
            countries: [],
            deviceCategories: [],
            loading: false,
            products: [],
            propertyLicences: [],
            sports: [],
        },
        filters: {
            bookingType: [],
            clientPackage: [],
            country: [],
            deviceCategory: [],
            from: moment().format("YYYY-MM-DD 00:00"),
            product: [],
            propertyLicence: [],
            sport: [],
            to: null,
            tournamentCategory: [],
            tournament: [],
        },
        match: {
            params: {
                masterBookingId: null
            }
        }
    };

    state = {
        bookingTypes: [],
        clientMasterBookings: [],
        clientMasterBookingsOriginal: [],
        clientPackages: [],
        countries: [],
        deviceCategories: [],
        products: [],
        propertyLicences: [],
        propertyLicencesOriginal: [],
        sports: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataClientMasterBookings: {clientMasterBookings},
            dataDropDown: {
                bookingTypes,
                clientPackages,
                countries,
                deviceCategories,
                products,
                propertyLicences,
                sports,
            },
        } = nextProps;

        let newState = {};

        if (bookingTypes && bookingTypes !== prevState.bookingTypes) {
            newState.bookingTypes = bookingTypes;
        }

        if (clientPackages && clientPackages !== prevState.clientPackages) {
            newState.clientPackages = clientPackages;
        }

        if (countries && countries !== prevState.countries) {
            newState.countries = countries;
        }

        if (deviceCategories && deviceCategories !== prevState.deviceCategories) {
            newState.deviceCategories = deviceCategories;
        }

        if (products && products !== prevState.products) {
            newState.products = products;
        }

        if (propertyLicences && propertyLicences !== prevState.propertyLicencesOriginal) {
            newState.propertyLicencesOriginal = propertyLicences;
            newState.propertyLicences = mapPropertyLicencesForDropdown(propertyLicences);
        }

        if (sports && sports !== prevState.sports) {
            newState.sports = sortDropdownOptionsAlphanumerically(sports);
        }

        if (clientMasterBookings && clientMasterBookings !== prevState.clientMasterBookingsOriginal) {
            let resultBookings = [];

            clientMasterBookings.forEach((clientMasterBooking) => {
                resultBookings.push(ClientMasterBookings.createBookingForTable(clientMasterBooking));
            });

            newState.clientMasterBookingsOriginal = clientMasterBookings;
            newState.clientMasterBookings = resultBookings;
        }

        return newState;
    }

    componentDidMount() {
        this.loadModal(this.props.match.path);
    }

    componentDidUpdate(prevProps) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.clients.bookings.index.path,
            modalChanged = this.props.modal.isVisible !== prevProps.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible;

        if (urlChanged && urlIsNotIndex) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (modalChanged && modalIsNotVisible) {
            this.props.history.push(
                getLink("clients.masterBookings.index", {
                    id: this.props.match.params.id
                })
            );
        }
    }

    static createBookingForTable = (booking) => ({
        id: booking.id,
        master_booking_id: booking.id,
        event_id: booking.event.id,
        event_description: booking.event.description,
        master_match_id: booking.master_match.master_match_id,
        start_datetime: booking.event.start_datetime,
        end_datetime: booking.event.end_datetime,
        sport_name: booking.master_match.sport.name,
        tournament_category_names: ClientMasterBookings.getTournamentCategoryOrTournamentNames(booking),
        tournament_names: ClientMasterBookings.getTournamentCategoryOrTournamentNames(booking, 'tournament'),
        client_package: booking.client_package,
        product_short_name: booking.product.short_name,
        master_booking_geo_restrictions: booking.master_booking_geo_restrictions,
        booking_type_name: booking.booking_type.name,
        master_booking_creation_datetime: booking.creation_datetime,
        booked_by: booking.booked_by,
    });

    static getTournamentCategoryOrTournamentNames = (booking, type = 'category') => {
        let masterMatchTournaments = booking.master_match.master_match_tournaments;

        if (masterMatchTournaments.length > 0) {
            return masterMatchTournaments.map((masterMatchTournament) => (
                type === 'tournament'
                    ? masterMatchTournament.tournament.name
                    : masterMatchTournament.tournament.tournament_category.name
            ));
        }

        return masterMatchTournaments;
    };

    loadModal(path, params = {}) {
        const clientMasterBookingsGeoRestrictionsPath = routes.clients.masterBookings;
        let formParams = {};

        if (path === clientMasterBookingsGeoRestrictionsPath.edit.path
            || path === clientMasterBookingsGeoRestrictionsPath.editGeoRestrictions.path) {
            formParams = Object.assign({}, {
                id: convertToInt(this.props.match.params.masterBookingId) || convertToInt(params.masterBookingId),
                optionsVariables: {
                    client: convertToInt(this.props.match.params.id),
                    id: this.props.match.params.id,
                },
            });

            return this.loadEditModalForm(
                {
                    tabs: [
                        {
                            key: "step1",
                            menuItem: {content: "General information", disabled: false },
                            pane: {
                                content: createForm(
                                    ClientMasterBookingGeoRestrictionsGeneralInformationEditModel,
                                    ClientMasterBookingGeoRestrictionsGeneralInformationForm,
                                    formParams
                                )
                            },
                            url: getLink('clients.masterBookings.edit', {
                                id: convertToInt(this.props.match.params.id),
                                masterBookingId: convertToInt(this.props.match.params.masterBookingId)
                                    || convertToInt(params.masterBookingId)
                            })
                        },
                        {
                            key: "step2",
                            menuItem: {content: "Geo restrictions", disabled: false },
                            pane: {
                                content: <EventMasterBookingGeoRestrictionsStep
                                    masterBookingId={convertToInt(this.props.match.params.masterBookingId)}
                                />
                            },
                            url: getLink('clients.masterBookings.editGeoRestrictions', {
                                id: convertToInt(this.props.match.params.id),
                                masterBookingId: convertToInt(this.props.match.params.masterBookingId)
                                    || convertToInt(params.masterBookingId)
                            })
                        }
                    ],
                },
                formParams
            );
        }
    }

    loadEditModalForm = (Forms) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={"Edit master booking"}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    render() {
        const clientId = convertToInt(this.props.match.params.id),
            loading = (
                this.props.DataClientMasterBookings.loading
                || this.props.dataDropDown.loading
                || this.props.loadingRefetch
            ),
            filtersProps = {
                additionalFilterId: this.props.match.params.id,
                data: {
                    bookingTypes: this.state.bookingTypes,
                    clientPackages: this.state.clientPackages,
                    countries: this.state.countries,
                    deviceCategories: this.state.deviceCategories,
                    products: this.state.products,
                    propertyLicences: this.state.propertyLicences,
                    sports: this.state.sports
                },
                loading: loading
            };

        return (
            <div>
                <HeaderRenderer
                    exportExcelParams={clientMasterBookingsExportExcel(this.state.clientMasterBookings, clientId)}
                    loading={loading}
                    filters={ClientMasterBookingsFilters}
                    filtersProps={filtersProps}
                    buttonsProps={{clientID: clientId}}
                />
                <Segment basic className="--table">
                    <ClientMasterBookingsTable
                        clientMasterBookings={this.state.clientMasterBookings}
                        clientId={convertToInt(this.props.match.params.id)}
                        loading={loading}
                    />
                </Segment>
            </div>
        );
    }
}

const ClientMasterBookingsWithQuery = compose(
    graphql(GetClientMasterBookings, {
        options: (props) => {
            let defaultFilters = ClientMasterBookings.defaultProps.filters;

            let bookingType = defaultFilters.bookingType,
                clientPackage = defaultFilters.clientPackage,
                country = defaultFilters.country,
                deviceCategory = defaultFilters.deviceCategory,
                product = defaultFilters.product,
                sport = defaultFilters.sport,
                tournamentCategory = defaultFilters.tournamentCategory,
                tournament = defaultFilters.tournament;

            if (props.filters.bookingType) {
                bookingType = props.filters.bookingType.map(bookingType => convertToInt(bookingType));
            }

            if (props.filters.clientPackage) {
                clientPackage = props.filters.clientPackage.map(clientPackage => convertToInt(clientPackage));
            }

            if (props.filters.country) {
                country = props.filters.country;
            }

            if (props.filters.deviceCategory) {
                deviceCategory = props.filters.deviceCategory.map(deviceCategory => convertToInt(deviceCategory));
            }

            if (props.filters.product) {
                product = props.filters.product.map(product => convertToInt(product));
            }

            if (props.filters.sport) {
                sport = props.filters.sport.map(sport => convertToInt(sport));
            }

            if (props.filters.tournamentCategory) {
                tournamentCategory = props.filters.tournamentCategory.map(
                    tournamentCategory => convertToInt(tournamentCategory)
                );
            }

            if (props.filters.tournament) {
                tournament = props.filters.tournament.map(tournament => convertToInt(tournament));
            }

            return {
                fetchPolicy: "cache-and-network",
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: convertToInt(props.match.params.id),
                    bookingType: bookingType,
                    clientPackage: clientPackage,
                    country: country,
                    deviceCategory: deviceCategory,
                    from : `${props.filters.from}:00` || `${defaultFilters.from}:00`,
                    product: product,
                    propertyLicence: props.filters.propertyLicence || defaultFilters.propertyLicence,
                    sport: sport,
                    to : props.filters.to ? `${props.filters.to}:00` : defaultFilters.to,
                    tournamentCategory: tournamentCategory,
                    tournament: tournament,
                },
            };
        },
        name: "DataClientMasterBookings",
    }),
    graphql(GetClientMasterBookingsDropdownData, {
        options: (props) => {
            return {
                fetchPolicy: "cache-and-network",
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: [convertToInt(props.match.params.id)],
                },
            };
        },
        name: "dataDropDown",
    })
)(FilterQueryWrapper(ClientMasterBookings, {
    queryForRefresh: "DataClientMasterBookings",
    filterUrls: ["clients.masterBookings.index"]
}));

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, `ClientMasterBookings${ownProps.match.params.id}`, ClientMasterBookings.defaultProps.filters),
    modal: state.modal
});

const mapDispatchToProps = mapModulesToProps(['Modal']);

export default withRouter((connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(ClientMasterBookingsWithQuery, ClientMasterBookings.defaultProps.filters)
)));
