import {withRouter} from 'react-router-dom';

import DefaultForm from '@appComponents/DefaultForm';

class CountryGeneralInformationForm extends DefaultForm {
    renderCancelButton = () => null;
    renderArchiveButton = () => null;
    renderSaveButton = () => null;
    renderDeleteButton = () => null;
    prepareDataForSubmit = () => ({});
}

export default withRouter(CountryGeneralInformationForm);


