import PropTypes from "prop-types";
import React from "react";

import Authorization from "../../app/components/Authorization";
import {IconEdit} from "../../app/components/IconCollection";
import {getLink} from "../../app/components/Link";
import LinkWithAuthorization from "../../app/components/LinkWithAuthorization";
import Table from "../../app/components/Table";

import {CLIENT_ANALYTICS_SETUP_LABLES_TABLE_NO_DATA} from "../constants/messages";
import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const ClientAnalyticsSetupLabelsTable = (props) => {
    const columns = {
        "id": {
            label: "ID",
            sorting: (row) => parseInt(row.id, 10),
            header: {
                align: "center",
                isCollapsed: true,
            },
            content: {
                align: "center",
            },
        },
        "code": {
            label: "Code",
            sorting: true,
        },
        "name": {
            label: "Name",
            sorting: true,
        },
        "actions": {
            label: "Actions",
            header: {
                align: "center",
                isCollapsed: true,
            },
            content: {
                align: "center",
            },
        },
    };

    const {clientId, clientLabels, loading} = props;

    const rowRenderer = (column, row) => {
        switch (column) {
            case "id":
                return (
                    <LinkWithAuthorization
                        link={{
                            name: "clients.clientAnalyticsSetup.labels.edit",
                            params: {id: clientId, clientLabelId: row.id}
                        }}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.CLIENT_LABEL}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case "code":
            case "name":
                return row[column];
            case "actions" :
                return (
                    <Authorization
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.CLIENT_LABEL}
                    >
                        <div className="actions-list-icon">
                            <IconEdit size="large" link={
                                getLink('clients.clientAnalyticsSetup.labels.edit', {
                                    id: clientId,
                                    clientLabelId: row.id,
                                })
                            }/>
                        </div>
                    </Authorization>
                );
            default:
                return null;
        }
    };

    return (
        <div className="clientLabels__table">
            <Table
                columns={columns}
                data={clientLabels}
                loading={loading}
                name="clientLabelsList"
                noDataText={CLIENT_ANALYTICS_SETUP_LABLES_TABLE_NO_DATA}
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

ClientAnalyticsSetupLabelsTable.defaultProps = {
    clientLabels: [],
};

ClientAnalyticsSetupLabelsTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    clientLabels: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

export default ClientAnalyticsSetupLabelsTable;
