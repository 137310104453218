import { combineReducers } from 'redux';

import entities from "./entities";
import menu from './menu';
import paginator from './paginator';
import security from './security';
import filterReducer from "./filter";
import scroll from "./scroll";
import table from "./table";
import streamPlaybackReducer from "./streamPlayback";

const appReducer = combineReducers({
    entities,
    filters: filterReducer,
    menu,
    paginator,
    security,
    scroll,
    table,
    streamPlayback: streamPlaybackReducer,
});

export default appReducer;
