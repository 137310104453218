import React from "react";

import Authorization from "../../app/components/Authorization";
import {ButtonAdd} from "../../app/components/ButtonCollection";
import Link from "../../app/components/Link";

import * as RESOURCES from "../../app/constants/resources";
import * as CONST from "../../app/constants/variables";

const SdiOriginEntryPointsIndexButtons = () => {
    const authorizationObject = {
        [RESOURCES.SDI_ORIGIN_ENTRY_POINTS]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
    };

    return (
        <div className="content__buttons">
            <Authorization authorization={authorizationObject}>
                <Link name={"sdiOriginEntryPoints.add"}>
                    <ButtonAdd>{'Add new SDI origin entry point'}</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default SdiOriginEntryPointsIndexButtons;
