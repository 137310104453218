import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import UdpOriginEntryPointForm from '../forms/UdpOriginEntryPointForm';
import UdpOriginEntryPointModel from '../forms/UdpOriginEntryPointModel';
import UdpOriginEntryPointEditButtons from '../components/UdpOriginEntryPointEditButtons';

export const UdpOriginEntryPointEdit = ({
    match: {
        params: {id = null},
    },
}) => {
    const [pageTitle, setPageTitle] = React.useState('UDP origin entry point');

    let buttonProps = {name: 'udpOriginEntryPoints.index'};

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('Udp Origin Entry Point', routes.udpOriginEntryPoint.index.path),
        });
    }

    const Form = React.useMemo(() => (
        createForm(
            UdpOriginEntryPointModel,
            UdpOriginEntryPointForm, {
                id: convertToInt(id),
                setPageTitle: setPageTitle,
            }
        )
    ), []);

    return (
        <div>
            <HeaderRenderer
                buttons={UdpOriginEntryPointEditButtons}
                buttonsProps={buttonProps}
                messagesBoxNames='udpOriginEntryPointMessageError'
                pageTitle={pageTitle}
            />
            <Form/>
        </div>
    );
};
UdpOriginEntryPointEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};
UdpOriginEntryPointEdit.displayName = 'UdpOriginEntryPointEdit';

export default withRouter(UdpOriginEntryPointEdit);
