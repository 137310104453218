import {get as _get, has as _has} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {getLink} from '@appComponents/Link';
import {showErrorMessage, showSuccessMessageAfterMutation} from '@utils/CidrHelpers';
import {convertToInt} from '@utils/helpers';
import {GetBlackListedIpOverlapInWhitelist} from '@graphql/blacklistedIp/query';

import IpBlacklistingModel from './IpBlacklistingModel';
import IpBlacklistingFormConfirmation from '../components/IpBlacklistingFormConfirmation';
import DefaultForm from '../../app/components/DefaultForm';

export class IpBlacklistingForm extends DefaultForm {
    static propTypes = {
        formParams: PropTypes.shape({
            id: PropTypes.string,
            setMutationSuccessCallback: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {
        formParams: {
            id: null,
        },
    };

    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            showSuccessMessageAfterMutation(
                'ipBlacklistingIndex',
                _get(response, 'data.createBlacklistedIp.cidr', null)
            );
            this.setMutationSuccess();
        });

        this.setCreateErrorCallback(() => {
            showErrorMessage(
                IpBlacklistingModel().messages.boxName.error,
                _get(this.props, 'formValues.cidr', null),
                false
            );
        });

        this.setUpdateSuccessCallback((response) => {
            showSuccessMessageAfterMutation(
                'ipBlacklistingIndex',
                _get(response, 'data.updateBlacklistedIp.cidr', null)
            );
            this.setMutationSuccess();
        });

        this.setUpdateErrorCallback(() => {
            this.setState(() => ({defaultForm_loading: false}));
            showErrorMessage(
                IpBlacklistingModel().messages.boxName.error,
                _get(this.props, 'formValues.cidr', null),
                false
            );
        });
    }

    renderDeleteButton = () => null;

    prepareDataForSubmit = (data) => (
        Object.assign({}, data, {
            ip_version: convertToInt(data.ip_version),
        })
    );

    renderErrors(errorData) {
        super.renderErrors(
            errorData,
            this.props.Model.label,
            getLink('ipBlacklisting.index'),
            {size: 'tiny'}
        );
    }

    onFormSubmit(data) {
        if (_has(data, 'id')) {
            this.setState(() => ({
                defaultForm_loading: true,
            }));

            super.onFormSubmit(data);
        } else {
            this.runApolloRequest('query', {
                fetchPolicy: 'network-only',
                query: GetBlackListedIpOverlapInWhitelist,
                variables: {
                    cidr: data.cidr,
                },
            }).then((response) => {
                const clientWhitelistedIps = _get(response, 'data.overlappingClientWhitelistedIps', []);

                if (0 < clientWhitelistedIps.length) {
                    this.setConfirmationStep(clientWhitelistedIps, data);
                } else {
                    super.onFormSubmit(data);
                }
            });
        }
    }

    setConfirmationStep(clientWhitelistedIps, data) {
        this.props.stepsMethods.setStep({
            id: 'step2',
            menuItem: {content: '', hidden: true},
            pane: {
                content: <IpBlacklistingFormConfirmation
                    clientWhitelistedIps={clientWhitelistedIps}
                    formData={data}
                    setMutationSuccess={this.setMutationSuccess}
                />,
            },
        });
        this.props.stepsMethods.showStep('step2');
    }

    setMutationSuccess = () => {
        this.props.formParams.setMutationSuccessCallback(Date.now());
    };
}

export default IpBlacklistingForm;
