import {getLink} from "../../app/components/Link";

import GeoRestrictionsForm from "../../geoRestriction/forms/GeoRestrictionsForm";

import {convertToInt, getIdsFromChildren} from "../../../utils/helpers";

class ProductGeoRestrictionsForm extends GeoRestrictionsForm {
    prepareDataForSubmit = (data) => {
        const countries = getIdsFromChildren(data.countries),
            subdivisions = getIdsFromChildren(data.subdivisions);

        return Object.assign({}, {
            id: this.getEntityClientProductId(),
            territory_selection_type: convertToInt(data.selection_type),
            countries: countries,
            country_subdivisions: subdivisions,
        });
    };

    setFormMessage = (message, boxName) => {
        super.setFormMessage(
            `The geo restrictions have not been saved. 
            Please correct the highlighted fields below.`
            , boxName)
    };

    renderErrors(errorData) {
        const link = getLink("clients.edit", {
            id: this.props.formParams.id || null,
        });

        super.renderErrors(
            errorData,
            this.props.Model.formName.capitalizeFirstLetter() + ' geo restrictions',
            link,
            {
                size: 'tiny',
            }
        );
    }

    renderAdditionalButtons = () => {
        return null;
    };

    renderCancelButton() {
        return null;
    }

    renderDeleteButton = () => {
        return null;
    };
}

export default ProductGeoRestrictionsForm;
