import React from 'react';
import PropTypes from "prop-types";
import {Button, Popup} from "semantic-ui-react";

const ActionButton = (props) => {
    const handleClick = () => {
        if (props.onClick) {
            if (undefined !== props.actionParams) {
                return props.onClick(props.actionParams);
            }

            return props.onClick();
        }
    };

    const button = <Button {...props.button} onClick={handleClick}/>;

    if (props.popup) {
        return (
            <Popup
                position="top right"
                trigger={<span>{button}</span>}
                {...props.popup}
            />
        );
    }

    return button;
};

ActionButton.propTypes = {
    onClick: PropTypes.func,
    actionParams: PropTypes.object,
    button: PropTypes.object,
    popup: PropTypes.object
};

export default ActionButton;
