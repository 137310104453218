import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/order
import {compose} from 'redux';

/* eslint import/no-unresolved: 0 */
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Header, Segment} from 'semantic-ui-react';
import {find as _find, sortBy as _sortBy} from 'lodash';

import {gql, graphql, withApollo} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import mapModulesToProps from '@utils/mapModulesToProps';
import * as MESSAGES from '@constants/messages';
import * as propertyLicenceContentGraphQl from '@graphql/propertyLicences/content';
import {PROPERTY_LICENCE_TYPE_OTHER, SPORT_TENNIS} from '@constants/variables';
import {contentCategoriesFilteredByLevelAndParents} from '@graphql/content';
import {tournamentsDropdown} from '@graphql/tournaments';

import {exportExcel} from '../utils/export/propertyLicenceContentExcel';
import PropertyLicenceContentTable from '../components/PropertyLicenceContentTable';


class PropertyLicenceContent extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        contentCategoriesThirdLevelLoading: PropTypes.bool,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
        }),
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
        propertyLicenceId: PropTypes.number,
        DataPropertyLicenceContent: PropTypes.object.isRequired,
        PropertyLicenceContentCreateMutation: PropTypes.func,
        PropertyLicenceContentDeleteMutation: PropTypes.func,
        PropertyLicenceContentUpdateMutation: PropTypes.func,
        propertyLicenceFromStore: PropTypes.object,
        tournamentsLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            contentTiers: undefined,
            propertyLicenceContents: undefined,
            tournamentCategories: undefined,
            tournaments: undefined,
            contentCategoriesSecondLevel: undefined,
            contentCategoriesThirdLevel: undefined,
            loading: true,
            contentCategoryThirdLevelValue: null,
            tournamentValue: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        const {DataPropertyLicenceContent: {propertyLicenceContents}} = nextProps;

        if (propertyLicenceContents) {
            this.setState(() => ({propertyLicenceContents: propertyLicenceContents}));
        }

        if (this.props.propertyLicenceFromStore !== nextProps.propertyLicenceFromStore) {
            this.getMainDataFromGraphQL(nextProps);
        }
    }

    componentDidMount() {
        if (this.props.propertyLicenceFromStore) {
            this.getMainDataFromGraphQL(this.props);
        }
    }

    getMainDataFromGraphQL(props) {
        if (props.propertyLicenceFromStore) {
            const propertyLicenceType = parseInt(props.propertyLicenceFromStore.property_licence_type_id);
            let query = DropdownDataQuery,
                variables = {
                    sports: [parseInt(props.propertyLicenceFromStore.sport_id)],
                };

            if (propertyLicenceType === PROPERTY_LICENCE_TYPE_OTHER) {
                query = DropdownDataTypeOtherQuery;
                variables = {
                    level: 2,
                    parents: [parseInt(props.propertyLicenceFromStore.content_category_id)]
                };
            }

            props.client.query({
                query: query,
                variables: variables,
            }).then((data) => {
                this.setState(() => ({
                    loading: false,
                    contentTiers: data.data.contentTiers,
                    tournamentCategories: data.data.tournamentCategories
                        ? sortDropdownOptionsAlphanumerically(
                            data.data.tournamentCategories.mapDataForDropdownWithIntVal()
                        )
                        : [],
                    contentCategoriesSecondLevel: data.data.contentCategories
                        ? sortDropdownOptionsAlphanumerically(
                            data.data.contentCategories.mapDataForDropdownWithIntVal()
                        )
                        : []
                }));
            });
        }
    }

    getTournamentsFromGraphQL(tournamentCategoryId) {
        this.setState(() => {
            return {
                tournamentsLoading: true,
            }
        });

        this.props.client.query({
            query: gql(tournamentsDropdown),
            variables: {
                tournamentCategory: [convertToInt(tournamentCategoryId)]
            }
        }).then((data) => {
            let tournaments = data.data.tournaments;

            if (parseInt(this.props.propertyLicenceFromStore.sport_id) === SPORT_TENNIS) {
                tournaments = tournaments.filter((item) => (
                    0 === tournaments.filter((tournament) => (
                        null !== tournament.parent && tournament.parent.id === item.id
                    )).length
                ));
            }

            this.setState(() => {
                return {
                    tournamentsLoading: false,
                    tournaments: sortDropdownOptionsAlphanumerically(tournaments),
                }
            });
        });
    }

    getCategoriesThirdLevelFromGraphQL(secondLevelId) {
        this.setState(() => {
            return {
                contentCategoriesThirdLevelLoading: true,
            }
        });

        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 3,
                parents: [secondLevelId]
            }
        }).then((data) => {
            const categories = data.data.contentCategories
                ? sortDropdownOptionsAlphanumerically(data.data.contentCategories.mapDataForDropdown())
                : [];

            this.setState(() => {
                return {
                    contentCategoriesThirdLevelLoading: false,
                    contentCategoriesThirdLevel: categories,
                }
            });
        });
    }

    handlePropertyLicenceContentAdd = (data) => {
        this.setState(() => ({loading: true}));

        let tournament = null;
        let content_category = null;
        let content_tier = null;

        if(data.tournament !== undefined) {
            tournament = parseInt(data.tournament);
        }

        if(data.content_category_3 !== undefined) {
            content_category = parseInt(data.content_category_3);
        }

        if(data.content_tier !== undefined) {
            content_tier = parseInt(data.content_tier);
        }

        this.props.PropertyLicenceContentCreateMutation({
            variables: {
                property_licence: this.props.propertyLicenceId,
                tournament: tournament,
                content_category: content_category,
                content_tier: content_tier,
            }
        }).then(() => {
            this.props.MessageBox.addMessage(
                "propertyLicenceContentMessage",
                MESSAGES.SAVED("property licence content"),
                null,
                "success"
            );
            refetchQueryByName("PropertyLicenceContents");
            this.setState(() => ({
                contentCategoryThirdLevelValue: null,
                tournamentValue: null,
                loading: false,
            }));
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                "propertyLicenceContentMessage",
                MESSAGES.NOT_SAVED("property licence"),
                `${error}`,
                "error"
            );
            this.setState(() => ({loading: false}));
        });
    };

    handlePropertyLicenceContentDelete = (event, data) => {
        const entity = _find(this.state.propertyLicenceContents, {'id' : data['data-id']});

        const output = [
            entity.tournament !== null && entity.tournament.tournament_category !== null ? entity.tournament.tournament_category.name : null,
            entity.tournament !== null ? entity.tournament.name: null,
            entity.content_category !== null && entity.content_category.parent !== null ? entity.content_category.parent.name : null,
            entity.content_category !== null ? entity.content_category.name: null,
            entity.content_tier.name
        ].filter(function(val) { return val !== null; }).join(", ");

        this.props.Modal.setModalConfirmation({
            header: <Header icon="trash" content={MESSAGES.DELETE_HEADER("property licence content")}/>,
            text: MESSAGES.DELETE_CONFIRMATION(output) +
                "\nThis will also delete the settings and activation status specifically set for this content in the client packages.",
            onYes: () => (this.deletePropertyLicenceContent(data['data-id']))
        });
    };

    deletePropertyLicenceContent(propertyLicenceContentId) {
        const dataToDelete = Object.assign({}, {
            id: propertyLicenceContentId
        });
        const {
            Modal: { setModal },
            MessageBox: { addMessage }
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING("property licence content")
        });

        this.props.PropertyLicenceContentDeleteMutation({
            variables: {...dataToDelete}
        }).then(() => {
            setModal({isVisible: false});
            addMessage(
                "propertyLicenceContentMessage",
                MESSAGES.DELETED("property licence content"),
                null,
                "success"
            );
            refetchQueryByName("PropertyLicenceContents");
        }).catch((error) => {
            setModal({isVisible: false});
            addMessage(
                "propertyLicenceContentMessage",
                MESSAGES.NOT_DELETED("property licence content"),
                `${error}`,
                "error"
            );
        });
    }


    handlePropertyLicenceContentUpdate = (data) => {
        this.setState(() => ({loading: true}));

        this.props.PropertyLicenceContentUpdateMutation({
            variables: {
                id: data.id,
                content_tier: parseInt(data.content_tier),
            }
        }).then(() => {
            this.setState(() => ({loading: false}));
            this.props.MessageBox.addMessage(
                "propertyLicenceContentMessage",
                MESSAGES.SAVED("property licence content"),
                null,
                "success"
            );
            refetchQueryByName("PropertyLicenceContents");
        }).catch((error) => {
            this.setState(() => ({loading: false}));
            this.props.MessageBox.addMessage(
                "propertyLicenceContentMessage",
                MESSAGES.NOT_SAVED("property licence content"),
                `${error}`,
                "error"
            );
        });
    };

    handleTournamentCategoryChange = (event, data) => {
        if (data.value) {
            this.getTournamentsFromGraphQL(data.value);
            this.setState(() => {
                return {
                    tournamentValue: null
                }
            });

            if (event) {
                event.stopPropagation();
            }
        }

        this.setState(() => ({
            tournaments: undefined,
            tournamentValue: null
        }));
    };

    handleTournamentChange = (event, data) => {
        this.setState(() => {
            return {
                tournamentValue: data.value
            }
        });
    };

    handleContentCategorySecondLevelChange = (event, data) => {
        if (data.value) {
            this.getCategoriesThirdLevelFromGraphQL(data.value);

            if (event) {
                event.stopPropagation();
            }
        }

        this.setState(() => ({
            contentCategoriesThirdLevel: [],
            contentCategoryThirdLevelValue: null
        }));
    };

    handleContentCategoryThirdLevelChange = (event, data) => {
        this.setState(() => {
            return {
                contentCategoryThirdLevelValue: data.value
            }
        });
    };

    render() {
        const storePropertyLicence = this.props.propertyLicenceFromStore,
            loading = (this.props.DataPropertyLicenceContent.loading || this.state.loading),
            loadingSegment = (loading || !storePropertyLicence);

        let contents = this.state.propertyLicenceContents;

        if (storePropertyLicence) {
            if (parseInt(storePropertyLicence.property_licence_type_id) === PROPERTY_LICENCE_TYPE_OTHER) {
                contents = _sortBy(contents, ["content_category.parent.name", "content_category.name"])
            } else {
                contents = _sortBy(contents, ["tournament.tournament_category.name", "tournament.name"])
            }
        }

        return (
            <Segment basic loading={loadingSegment}>
                <div>
                    <HeaderRenderer
                        exportExcelParams={(!loadingSegment && exportExcel(contents, this.props.match.params.id, storePropertyLicence.property_licence_type_id))}
                        loading={loading}
                        messagesBoxNames="propertyLicenceContentMessage"
                    />
                    <PropertyLicenceContentTable
                        contentTiers={this.state.contentTiers}
                        loading={loading}
                        onAdd={this.handlePropertyLicenceContentAdd}
                        onContentCategorySecondLevelChange={this.handleContentCategorySecondLevelChange}
                        onContentCategoryThirdLevelChange={this.handleContentCategoryThirdLevelChange}
                        onDelete={this.handlePropertyLicenceContentDelete}
                        onTournamentCategoryChange={this.handleTournamentCategoryChange}
                        onTournamentChange={this.handleTournamentChange}
                        onUpdate={this.handlePropertyLicenceContentUpdate}
                        propertyLicence={storePropertyLicence}
                        propertyLicenceContent={contents}
                        tournamentCategories={this.state.tournamentCategories}
                        tournaments={this.state.tournaments}
                        tournamentsLoading={this.state.tournamentsLoading}
                        contentCategoriesSecondLevel={this.state.contentCategoriesSecondLevel}
                        contentCategoriesThirdLevel={this.state.contentCategoriesThirdLevel}
                        contentCategoriesThirdLevelLoading= {this.state.contentCategoriesThirdLevelLoading}
                        contentCategoryThirdLevelValue={this.state.contentCategoryThirdLevelValue}
                        tournamentValue={this.state.tournamentValue}
                    />
                </div>
            </Segment>
        );
    }
}

const DropdownDataQuery = gql(propertyLicenceContentGraphQl.propertyLicenceContentDropdownData);
const DropdownDataTypeOtherQuery = gql(propertyLicenceContentGraphQl.propertyLicenceContentTypeOtherDropdownData);
const PropertyLicenceContentListQuery = gql(propertyLicenceContentGraphQl.propertyLicenceContentList);
const PropertyLicenceContentCreateMutation = gql(propertyLicenceContentGraphQl.propertyLicenceContentCreateMutation);
const PropertyLicenceContentDeleteMutation = gql(propertyLicenceContentGraphQl.propertyLicenceContentDeleteMutation);
const PropertyLicenceContentUpdateMutation = gql(propertyLicenceContentGraphQl.propertyLicenceContentUpdateMutation);

const mapStateToProps = (state) => {
    return {
        propertyLicenceFromStore: state.app.entities.propertyLicence,
    }
};

const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox', 'Entity']);

const PropertyLicenceContentWithApollo = withApollo(PropertyLicenceContent);
const PropertyLicenceContentWithStore = connect(mapStateToProps, mapDispatchToProps)(PropertyLicenceContentWithApollo);

export default compose(
    graphql(PropertyLicenceContentListQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: "network-only",
                variables: {
                    propertyLicenceId: props.propertyLicenceId
                },
            }
        },
        name: "DataPropertyLicenceContent"
    }),
    graphql(PropertyLicenceContentCreateMutation, { name: 'PropertyLicenceContentCreateMutation' }),
    graphql(PropertyLicenceContentDeleteMutation, { name: 'PropertyLicenceContentDeleteMutation' }),
    graphql(PropertyLicenceContentUpdateMutation, { name: 'PropertyLicenceContentUpdateMutation' }),
)(withRouter(PropertyLicenceContentWithStore));
