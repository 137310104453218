import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown} from 'semantic-ui-react';

import {Authorization} from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import ExcelExportButton from '@appComponents/ExcelExportButton';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

const BlackoutZonePostalCodesButtons = (props) => (
    <div className='content__buttons'>
        <Authorization authorization={{
            [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
        }}>
            <ButtonAdd onClick={props.addPostalCodesHandler}>{props.addButtonText}</ButtonAdd>
            <Dropdown button text='Selection'>
                <Dropdown.Menu>
                    <Dropdown.Item
                        text={props.removeButtonText}
                        data-idkey={'removeBlackoutZonePostalCodes'}
                        onClick={props.removePostalCodesHandler}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </Authorization>
        <ExcelExportButton exportExcelParams={props.excelExport} loading={props.loading}/>
    </div>
);

BlackoutZonePostalCodesButtons.propTypes = {
    loading: PropTypes.bool.isRequired,
    addButtonText: PropTypes.string.isRequired,
    addPostalCodesHandler: PropTypes.func.isRequired,
    removeButtonText: PropTypes.string,
    removePostalCodesHandler: PropTypes.func.isRequired,
    excelExport: PropTypes.object.isRequired,
};

export default BlackoutZonePostalCodesButtons;
